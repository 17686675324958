import * as React from 'react';

import { Button } from '@components';

import { OrderRequestFooter } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/SendOrderRequestEmail/OrderRequestFooter';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { Button as ShadcnButton } from '@frontend/shadcn/components/ui/button';
import styles from './STASendItems.scss';

interface IProps {
  title: string | React.ReactElement;
  emailComposer: React.ReactElement;
  emailPreview: React.ReactElement;
  onCancel?(): void;
  emailComposerRef: React.RefObject<any>;
  recipientCount: number;
  onContinueWithoutMessage?(): void;
  continueWithoutMessageButtonProps?: React.ComponentProps<typeof ShadcnButton>;
  sendToRecipientsButtonProps?: React.ComponentProps<typeof ShadcnButton>;
  nextReviewButtonProps?: React.ComponentProps<typeof ShadcnButton>;
}

export const STAEmailComposer: React.FC<Readonly<IProps>> = ({
 title, emailComposer, emailPreview, onCancel, emailComposerRef, recipientCount, onContinueWithoutMessage, continueWithoutMessageButtonProps, sendToRecipientsButtonProps, nextReviewButtonProps,
}) => {
  const { [ClientFeature.FLEXIBLE_PROJECT]: isFlexEnabled } = useClientFeatures();

  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTop}>{title}</div>
        <div className={styles.contentMain}>
          {emailComposer}
          {emailPreview}
        </div>
      </div>
      {!isFlexEnabled && onCancel && (
        <div className={styles.footer}>
          <Button label="Cancel" onClick={onCancel} theme="light" className={styles.button} />
        </div>
      )}
      {isFlexEnabled && (
        <OrderRequestFooter
          emailComposerRef={emailComposerRef}
          recipientCount={recipientCount}
          onCancel={onCancel}
          onContinueWithoutMessage={onContinueWithoutMessage}
          continueWithoutMessageButtonProps={continueWithoutMessageButtonProps}
          sendToRecipientsButtonProps={sendToRecipientsButtonProps}
          nextReviewButtonProps={nextReviewButtonProps}
          showNextReviewIcon
          showBackIcon
        />
      )}
    </>
  );
};
