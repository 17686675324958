import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { CalendarIcon } from 'lucide-react';
import { cx } from 'class-variance-authority';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import moment, { Moment } from 'moment';
import { FormAsyncAction, IShopifyFormElementProps, OfferFormAsyncActions } from '../../../../../../../types';

interface IProps extends IShopifyFormElementProps<'endDate'> {
  startDate: Moment;
  setOfferEndDate: React.Dispatch<React.SetStateAction<boolean>>;
  handleFormAsyncActions: (action: FormAsyncAction) => void;
  value: Moment;

}

export const RefreshOfferEndDate: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  startDate,
  handleFormAsyncActions,
  value
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(value);
  const handleDateChange = (date: Date | undefined) => {
    if (!date) {
      setSelectedDate(null);
      return;
    }
    const now = moment();
    const momentDate = moment.utc(moment(date).set({
      hour: now.hour(),
      minute: now.minute(),
      second: now.second(),
    }));
    setSelectedDate(momentDate);

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: {
        key: name,
        value: momentDate,
      },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: {
        key: 'isEndDateEnable',
        value: !!momentDate,
      },
    });
  };

  return (
    <Form.Item name={name}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cx(
              'w-[250px] justify-start text-left font-normal',
              !selectedDate && 'text-muted-foreground'
            )}
            disabled={disabled}
          >
            {selectedDate ? selectedDate.format('MMM D, YYYY') : 'Pick a date'}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={selectedDate?.toDate()}
            onSelect={handleDateChange}
            disabled={(date) => moment(date).isBefore(startDate, 'day')}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </Form.Item>
  );
});

RefreshOfferEndDate.displayName = 'RefreshOfferEndDate';
