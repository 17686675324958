import * as React from 'react';
import { Toast, IToastRefHandles } from '@components';
import { ErrorBoundary } from './ErrorBoundary';

const BuggyCounter: React.FC = () => {
  const [counter, setCounter] = React.useState(0);

  if (counter === 5) {
    throw new Error('Simulated error at count 5!');
  }

  return <button onClick={() => setCounter((c) => c + 1)}>Count: {counter} (Throws at 5)</button>;
};

export const ErrorBoundaryTest: React.FC = () => {
  const toastRef = React.useRef<IToastRefHandles>(null);

  const showToast = (type: 'info' | 'warning' | 'success' | 'error') => {
    toastRef.current?.showMessage({
      type,
      content: `This is a ${type} toast message`,
      duration: 3000,
    });
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Error Boundary Test</h2>
      <ErrorBoundary id="test-counter" name="CounterBoundary">
        <BuggyCounter />
      </ErrorBoundary>

      <div style={{ marginTop: '30px' }}>
        <h2>Toast Test</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button onClick={() => showToast('info')}>Show Info Toast</button>
          <button onClick={() => showToast('success')}>Show Success Toast</button>
          <button onClick={() => showToast('warning')}>Show Warning Toast</button>
          <button onClick={() => showToast('error')}>Show Error Toast</button>
        </div>
      </div>

      <Toast useFresh ref={toastRef} />
    </div>
  );
};
