import { TemplateName } from '../TemplatesPage/TemplatesPage';

export const CAMPAIGN_TO_TEMPLATE_MAP = {
  'Product Launch': TemplateName.ProductGifting,
  'Ambassadors': TemplateName.AmbassadorProject,
  'Influencer Content': TemplateName.InfluencerCampaign,
  'Custom Campaign': 'Custom Project',
} as const;

export const TEMPLATE_TO_CAMPAIGN_MAP = {
  [TemplateName.InfluencerCampaign]: 'Influencer Content',
  [TemplateName.AmbassadorProject]: 'Ambassadors',
  [TemplateName.ProductGifting]: 'Product Launch',
  'Custom Project': 'Custom Campaign',
} as const;

export const CustomWorkletTemplate = {
  specKey: null,
  specLogoURL: 'https://storage.googleapis.com/aspirex-static-files/new_logo.png',
  specTitle: 'Custom Stage',
  specURI: null,
} as const;

export const WorkletTypes = {
  WELCOME_EMAIL: 'Welcome Email',
  BRIEFS: 'Briefs',
  CONTRACT: 'Contract',
  MEMBER_INFORMATION: 'Member Information',
  SALES_LINKS: 'Sales Links',
  PROMO_CODES: 'Promo Codes',
  SALES_LINKS_AND_PROMO: 'Sales Links + Promo Codes',
  CREATOR_PRODUCT_SELECTION: 'Creator Product Selection',
  PRODUCT_REQUEST_FORM: 'Product Request Form',
  PRODUCT_FULFILLMENT: 'Product Fulfillment',
  PRODUCT_CATALOG: 'Product Catalog',
  CONTENT: 'Content',
  TRACK_POSTS: 'Track Posts',
  PAYMENTS: 'Payments',
  CUSTOM: 'Custom',
} as const;
