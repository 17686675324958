import { logger } from '@common';
import { ColumnMetadata } from './selectedColumnsToColumnDef';
import { RowType } from '..';

interface Member {
  name?: string | null;
  instagramUsername?: string | null;
  tiktokUsername?: string | null;
  youtubeUsername?: string | null;
  pinterestUsername?: string | null;
  [key: string]: string | null | undefined;
}

export type SocialInsightsData = {
  member?: Member | null;
  [key: string]: unknown;
};

interface FormattableObject {
  name?: string;
  value?: string;
  label?: string;
  [key: string]: string | undefined;
}

/**
 * Formats a single value for CSV export
 * @param value - The value to format
 * @param rowType - The type of row (used for special formatting)
 * @param record - The full data record (used for accessing nested fields)
 * @returns Formatted string value
 */
/**
 * Escapes and quotes a string value for CSV
 * @internal Exported for testing purposes
 */
export const escapeCSVValue = (value: string): string => {
  // If value contains quotes, commas, or newlines, it needs to be quoted and escaped
  if (value.includes('"') || value.includes(',') || value.includes('\n')) {
    // Escape quotes by doubling them
    const escaped = value.replace(/"/g, '""');
    return `"${escaped}"`;
  }
  return value;
};

/**
 * Formats a single value for CSV export
 * @internal Exported for testing purposes
 */
export const formatValueForCsv = (
  value: string | number | Date | FormattableObject | string[] | unknown[] | unknown | null | undefined,
  rowType: number,
  record: SocialInsightsData,
): string => {
  // Handle null or undefined
  if (value === null || value === undefined) {
    return '';
  }

  let formattedValue = '';

  // Special handling based on rowType
  switch (rowType) {
    case RowType.MEMBER:
      // For member columns, use the member name
      formattedValue = record.member?.name || '';
      break;

    case RowType.INSTAGRAM_HANDLE:
      // For Instagram handle columns
      formattedValue = record.member?.instagramUsername || '';
      break;

    case RowType.SOCIAL_HANDLES:
      // For social handles columns, join all available social media handles
      const handles = [];
      if (record.member?.instagramUsername) handles.push('Instagram');
      if (record.member?.tiktokUsername) handles.push('TikTok');
      if (record.member?.youtubeUsername) handles.push('YouTube');
      if (record.member?.pinterestUsername) handles.push('Pinterest');
      formattedValue = handles.join(', ');
      break;

    case RowType.CURRENCY:
        const usersLocale = 'en-US'; // Intl.DateTimeFormat().resolvedOptions().locale;
        const currencyFormatted = value && new Intl.NumberFormat(usersLocale, {
          style: 'currency',
          currency: 'USD', // TODO: Get currency from user settings
          maximumFractionDigits: 0,
        }).format(value as number) || '-';
      formattedValue = currencyFormatted;
      break;

    case RowType.PERCENTAGE:
      const percentageFormatted = value && new Intl.NumberFormat(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(value as number) || '-';
      formattedValue = percentageFormatted;
      break;

    default:
      // For objects, try to extract a meaningful string representation
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          formattedValue = value.join(', ');
        } else if (value instanceof Date) {
          // If it's a date object
          formattedValue = value.toISOString().split('T')[0];
        } else {
          // For other objects, try to use a common property or stringify
          const obj = value as FormattableObject;
          formattedValue = obj.name || obj.value || obj.label || '';
          if (!formattedValue && Object.keys(obj).length > 0) {
            try {
              formattedValue = JSON.stringify(obj);
            } catch (e) {
              formattedValue = '[Complex Object]';
            }
          }
        }
      } else if (typeof value === 'number') {
        // Format numbers with commas for thousands and escape if it contains commas
        const formatted = value.toLocaleString();
        return formatted.includes(',') ? `"${formatted}"` : formatted;
      } else if (typeof value === 'string') {
        // Remove HTML tags if present
        formattedValue = value.replace(/<[^>]*>/g, '');
      } else {
        formattedValue = String(value);
      }
  }

  // Escape and quote non-numeric values
  return escapeCSVValue(formattedValue);
};

/**
 * Helper function to access nested object properties using dot notation
 * @param obj - The object to access
 * @param path - The path to the property (e.g. "total.likes")
 * @returns The value at the given path or undefined if not found
 * @internal Exported for testing purposes
 */
export const getNestedValue = (obj: Record<string, unknown> | null | undefined, path: string): unknown => {
  if (!obj || !path) return undefined;

  // Split the path by dots to get individual property names
  const properties = path.split('.');
  let value: Record<string, unknown> | unknown = obj;

  // Traverse the object following the path
  for (const prop of properties) {
    if (value === null || value === undefined) return undefined;
    if (typeof value === 'object') {
      value = (value as Record<string, unknown>)[prop];
    } else {
      return undefined;
    }
  }

  return value;
};

/**
 * Generates CSV content from data and column definitions
 * @param data - Array of data records
 * @param columns - Array of column metadata
 * @returns CSV formatted string
 */
export const generateCsvContent = (data: SocialInsightsData[], columns: ColumnMetadata[]): string => {
  // Debugging log for development
  if (process.env.NODE_ENV !== 'production') {
    logger.debug('CSV Export: Columns to export:', columns);
    logger.debug('CSV Export: Sample record:', data.length > 0 ? data[0] : 'No data');
  }

  // Create header row - escape header values properly
  const headerRow = columns.map((col) => escapeCSVValue(col.header)).join(',');

  // Create data rows with proper CSV formatting
  const dataRows = data.map((record, index) => {
    try {
      // Process each column for this record
      const rowValues = columns.map((column) => {
        try {
          // Get the column ID to access data
          const accessorKey = column.accessorKey || column.id;

          // Handle special row types directly
          if (column.rowType === RowType.MEMBER) {
            return formatValueForCsv(record.member?.name || '', column.rowType, record);
          }

          if (column.rowType === RowType.INSTAGRAM_HANDLE) {
            return formatValueForCsv(record.member?.instagramUsername || '', column.rowType, record);
          }

          if (column.rowType === RowType.SOCIAL_HANDLES) {
            // Generate a formatted list of social handles
            const handles = [];
            if (record.member?.instagramUsername) handles.push('Instagram');
            if (record.member?.tiktokUsername) handles.push('TikTok');
            if (record.member?.youtubeUsername) handles.push('YouTube');
            if (record.member?.pinterestUsername) handles.push('Pinterest');

            // Return a comma-separated list, properly escaped for CSV
            return escapeCSVValue(handles.join(', '));
          }

          // Special handling for social media usernames in subcolumns
          if (accessorKey.startsWith('member.') && accessorKey.endsWith('Username')) {
            const platform = accessorKey.split('.')[1]; // e.g., "instagramUsername"
            return escapeCSVValue(record.member?.[platform] || '');
          }

          // For all other columns, use dot notation to access nested properties
          let rawValue;
          if (accessorKey.includes('.')) {
            // Use dot notation accessor for nested properties like "total.likes"
            rawValue = getNestedValue(record, accessorKey);
          } else {
            // Use direct access for non-nested properties
            rawValue = record[accessorKey];
          }

          // Format and return the value
          return formatValueForCsv(rawValue, column.rowType, record);
        } catch (colError) {
          logger.error(`Error processing column ${column.header} for row ${index}:`, colError);
          return `"Error: ${column.header} data unavailable"`;
        }
      });

      return rowValues.join(',');
    } catch (rowError) {
      logger.error(`Error processing row ${index}:`, rowError);
      return columns.map(() => '""').join(','); // Empty row in case of error
    }
  });

  // Combine header and data rows
  return [headerRow, ...dataRows].join('\n');
};

/**
 * Triggers a CSV file download
 * @param content - CSV content as string
 * @param filename - Name of the file to download
 */
export const downloadCsv = (content: string, filename: string): void => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Combines generation and download of CSV file
 * @param data - Array of data records
 * @param columns - Array of column metadata
 * @param filename - Name of the file to download
 */
export const exportDataToCsv = (
  data: SocialInsightsData[],
  columns: ColumnMetadata[],
  filename: string = 'data-export.csv',
): void => {
  const csvContent = generateCsvContent(data, columns);
  downloadCsv(csvContent, filename);
};
