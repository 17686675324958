import {
  message,
} from '@revfluence/fresh';
import {
  isEmpty,
  isFunction,
  isNil,
} from 'lodash';
import * as React from 'react';

import {
  EventName,
  logger,
} from '@common';
import {
  LightningFilledIcon,
} from '@frontend/app/components';
import {
  TCondition,
  TMember,
  TProject,
  TTask,
  TWorkItem,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import {
  useCustomCTA,
} from '@frontend/app/containers/Projects/hooks';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import { useEventContext } from '@frontend/app/context';
import { useGetInstalledApplicationIds } from '@frontend/app/hooks';

import {
  useCheckInWorkItemsMutation,
} from '@frontend/app/hooks';

import { useMessagingContext } from '@frontend/hooks';

import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { OFFER_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  CONTRACT_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { SplitButton } from '@frontend/app/refresh-components/SplitButton';
import { ProjectsPageState, Task } from '../../constants';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';
import { checkTaskIdHasTabs } from '../../utils';

const { useMemo, useState } = React;

const messageContainerFixed = {
  position: 'fixed',
  top: '10px',
  left: '0',
  right: '0',
  margin: 'auto',
};
interface IProps {
  disabled?: boolean;
  showBulkCTA?: boolean;
  onCheckIn?: () => void;
  onOpenApplication: (workItem: TWorkItem, applicationId: string, task: TTask) => void;
  onSkip: () => void;
  onMoveStage?: () => void;
  pageState?: ProjectsPageState;
  project: TProject;
  refetchData: () => Promise<unknown> | void;
  selectedMemberIds?: TMember['id'][];
  selectedWorkItems?: TWorkItem[];
  selectedWorkItemIds?: TWorkItem['id'][];
  task: TTask;
  tasks?: TTask[];
  worklets?: TWorklet[];
  conditions?: TCondition[];
  openAddToCollectionModal(modalType: ModalType): void;
  showSelectOffer?: (value: boolean) => void;
  migrateToGraphQL?: boolean;
  offers?: GetOfferById_offer[];
  onMemberApplicantOperationSuccess?: (memberIds?: IMember['id'][], operation?: MemberApplicantOperation) => void;
}

// eslint-disable-next-line react/display-name
export const CTAButton: React.FC<IProps> = React.memo(({
  disabled,
  onCheckIn,
  onOpenApplication,
  refetchData,
  selectedWorkItems,
  selectedWorkItemIds,
  task = {} as TTask,
  tasks = [],
  showBulkCTA,
  project,
  selectedMemberIds,
  migrateToGraphQL,
  offers,
}) => {
  const addEvent = useEventContext();
  const {
    showErrorMessage,
  } = useMessagingContext();
  const installedApps = useGetInstalledApplicationIds();

  const hasMembersSelected = useMemo(
    () => !isEmpty(selectedMemberIds),
    [selectedMemberIds],
  );

  const isFalseWorkItemSelected = useMemo(
    () => selectedMemberIds.length !== selectedWorkItems.length,
    [selectedMemberIds, selectedWorkItems],
  );

  /**
   * Check in
   */
  const [
    checkInWorkItems,
    { loading: isCheckingIn },
  ] = useCheckInWorkItemsMutation();

  const customCTAAction = useCustomCTA(project?.id, isEmpty(task) ? undefined : task as TTask);
  const [isLoadingCustomBulkCTA, setIsLoadingCustomBulkCTA] = useState(false);

  const handleCtaButtonClicked = React.useCallback(async (taskId: string) => {
    const task = tasks.find((t) => t.taskId === taskId);
    const metadata = task?.taskMetaData;
    const applicationId = metadata?.serviceId;
    if (metadata?.bulkCTAText === 'Create Promo Codes' && migrateToGraphQL) {
      for (const offer of offers) {
        if (offer.promos.length && (!offer.isNewFlow || !offer.promos[0].defaultPayoutId)) {
          message.info({
            content: 'Please upgrade your connected promo offer via Sales Tracking to continue generating promo codes.',
            style: messageContainerFixed,
          }, 5);
          return;
        }
      }
    }
    if (metadata?.bulkCTAText === 'Create Sales Links' && migrateToGraphQL) {
      for (const offer of offers) {
        if (offer.links.length && !offer.links[0].defaultPayoutId) {
          message.info({
            content: 'Please upgrade your connected offer through Sales Tracking to continue creating links.',
            style: messageContainerFixed,
          }, 5);
          return;
        }
      }
    }
    if (
      (metadata?.bulkCTAText === 'Create Promo Codes' && !isNil(offers.find((offer) => offer.type === OFFER_TYPE.PROMO_CODE)?.archivedDate))
      || (metadata?.bulkCTAText === 'Create Sales Links' && !isNil(offers.find((offer) => offer.type === OFFER_TYPE.LINK)?.archivedDate))
    ) {
      message.info({
        content: `The connected offer is Archived. Please change the offer to generate ${metadata?.bulkCTAText === 'Create Promo Codes' ? 'promo codes' : 'sales links'}.`,
        style: messageContainerFixed,
      }, 5);
      return;
    }
    if (metadata?.bulkCTAText === 'Send Contracts' && !installedApps[CONTRACT_APP_ID]) {
      message.info({
        content: 'Contract app is not installed on your account. Please contact support to get started.',
        style: messageContainerFixed,
      }, 5);
      return;
    }
    if (isEmpty(selectedWorkItemIds) || isEmpty(selectedWorkItems)) {
      logger.warn('No selected workItems');
      return;
    } if (isEmpty(task)) {
      logger.warn('No task');
      return;
    }
    const isMarkAsDone = (
      metadata
      && !metadata.serviceId
      && !metadata.singleCTAText
      && !metadata.bulkCTAText
    );
    if (!isMarkAsDone) {
      if (isFunction(customCTAAction)) {
        try {
          setIsLoadingCustomBulkCTA(true);
          await customCTAAction(selectedWorkItems);
        } catch (error) {
          logger.error('Error calling custom action', { error });
          showErrorMessage('There was an error when triggering the CTA. Please reach out to support.');
        } finally {
          setIsLoadingCustomBulkCTA(false);
        }
        await refetchData();
        return;
      }
      onOpenApplication(null, applicationId, task);
    } else {
      logger.debug('Marking as done', { selectedWorkItemIds });
      await checkInWorkItems({
        variables: {
          workItemIds: selectedWorkItemIds,
          outputDataJSON: {
            [`${task.taskId}_data`]: {},
          },
        },
      });
      logger.debug('Successfully marked as done', { selectedWorkItemIds });
      addEvent(
        EventName.MovedCaseStage,
        {
          action: 'mark_done',
          case_count: selectedWorkItemIds.length,
          from_task_id: task.taskId,
        },
      );
      await refetchData();
      if (isFunction(onCheckIn)) {
        onCheckIn();
      }
    }
  }, [addEvent, checkInWorkItems, customCTAAction, installedApps, migrateToGraphQL, offers, onCheckIn, onOpenApplication, refetchData, selectedWorkItemIds, selectedWorkItems, showErrorMessage, tasks]);

  const ctaBtnItems = useMemo(() => {
    if (checkTaskIdHasTabs(task?.taskId, [Task.Done, Task.NoDeliverables, Task.NoPost, Task.NotDue])) {
      return [];
    }
    const isLoading = (
      isCheckingIn
      || isLoadingCustomBulkCTA
    );
    const isPrimaryButtonDisabled = (
      disabled
      || isLoading
    );
    if (!checkTaskIdHasTabs(task?.taskId, [Task.NeedsAction, Task.All])) {
      const metadata = task?.taskMetaData;
      const isBulkCTA = (
        metadata
        && metadata.serviceId
        && metadata.bulkCTAText
      );
      const isMarkAsDone = (
        metadata
        && !metadata.serviceId
        && !metadata.singleCTAText
        && !metadata.bulkCTAText
      );
      const isPrimary = (
        isBulkCTA
        || isMarkAsDone
      );
      if (!isPrimary) {
        return [];
      }
      return [
        {
          label: (
            <>
              <LightningFilledIcon
                size={16}
              />
              {metadata?.bulkCTAText || 'Mark as Done'}
            </>
          ),
          onClick: () => handleCtaButtonClicked(task.taskId),
          disabled: isPrimaryButtonDisabled || !hasMembersSelected,
        },
      ];
    }

    return tasks?.filter((t) => t.workletSpecUri?.startsWith('CustomWorklet-') || t.taskMetaData?.bulkCTAText)
      ?.map((t, index) => {
        const isLoading = (
          isCheckingIn
          || isLoadingCustomBulkCTA
        );
        const isPrimaryButtonDisabled = (
          disabled
          || isLoading
        );

        const isDifferentTasks = selectedWorkItems?.some((selectedWorkItem) => selectedWorkItem.taskId !== t.taskId);

        const label = index === 0 ? (
          <>
            <LightningFilledIcon
              size={16}
            />
            {t.taskMetaData.bulkCTAText || 'Mark as Done'}
          </>
        ) : (t.taskMetaData.bulkCTAText || 'Mark as Done');

        return {
          label,
          onClick: () => handleCtaButtonClicked(t.taskId),
          disabled: isPrimaryButtonDisabled || !hasMembersSelected || isDifferentTasks || isFalseWorkItemSelected,
        };
      }) || [];
  }, [disabled, handleCtaButtonClicked, hasMembersSelected, isCheckingIn, isFalseWorkItemSelected, isLoadingCustomBulkCTA, selectedWorkItems, task.taskId, task?.taskMetaData, tasks]);

  return (
    <>
      {showBulkCTA && (
        <SplitButton
          items={ctaBtnItems}
          variant="primary"
        />
        )}
    </>
  );
});
