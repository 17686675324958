import * as React from 'react';
import cx from 'classnames';
import {
  map, isEmpty, size, uniq, filter, find, each, orderBy, first,
} from 'lodash';
import { format } from 'date-fns';

import { LoadSpinner } from '@components';
import {
  useGetMemberActivities,
  useGetApplicationsByIds,
  useInstagramDMFlag,
  useClientFeatureEnabled,
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import {
  GetMemberQuery_member as IMember,
} from '@frontend/app/queries/types/GetMemberQuery';
import { MessageType } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { INSTAGRAM_USERNAME_FIELD } from '@frontend/applications/SocialPostApp/appFields';

import { GetMemberActivitiesQuery_activities } from '@frontend/app/queries/types/GetMemberActivitiesQuery';

import { ClockIcon } from '@revfluence/fresh-icons/regular/esm';

import { MemberActivityItem } from './MemberActivityItem';
import { MemberIGDMActivityItem } from './MemberIGDMActivityItem';

import styles from './MemberActivities.scss';
import { ActivityFilters } from './types';
import { EmptyState } from './components/EmptyState';

const { useEffect, useMemo, useRef } = React;

interface IProps {
  member: IMember;
  className?: string;
  renderLoading?: () => JSX.Element;
  selectedFilter?: ActivityFilters,
}

export const MemberActivities: React.FC<IProps> = React.memo((props) => {
  const {
    member,
    renderLoading,
    selectedFilter = ActivityFilters.AppNotifications,
  } = props;

  const listRef = useRef<HTMLDivElement>();

  const messageTypes = useMemo(() => {
    switch (selectedFilter) {
      case ActivityFilters.AppNotifications:
        return Object.values(MessageType)
          .filter((t) => ![
            MessageType.GMAIL,
            MessageType.OUTLOOK,
            MessageType.IGDM,
          ].includes(t));
      case ActivityFilters.Messages:
        return [
          MessageType.GMAIL,
          MessageType.OUTLOOK,
          MessageType.IGDM,
        ];
      default:
        return Object.values(MessageType);
    }
  }, [selectedFilter]);

  const { activities, loading } = useGetMemberActivities({
    variables: {
      id: Number(member.id),
      messageTypes,
    },
  });

  const { isInstagramDMEnabled } = useInstagramDMFlag();
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);

  const filteredActivities = useMemo(() => (
    !isInstagramDMEnabled && !showInstagramMeta
      ? filter(activities, (activity) => activity.type !== MessageType.IGDM)
      : activities
  ), [
    activities,
    isInstagramDMEnabled,
    showInstagramMeta,
  ]);

  const {
    data: {
      schemas = null,
    } = {},
    loading: loadingSchemas,
  } = useMemberFieldSchemasQuery();

  const instagramField = useMemo(() => find(schemas, { name: INSTAGRAM_USERNAME_FIELD }), [schemas]);
  const applicationIds = useMemo(() => uniq(map(activities, (activity) => activity.applicationId)), [activities]);

  const {
    data: {
      applications = [],
    } = {},
  } = useGetApplicationsByIds(size(applicationIds) > 0 ? applicationIds : [], {
    fetchPolicy: 'no-cache',
  });

  const groupedActivities: [string, GetMemberActivitiesQuery_activities[]][] = useMemo(() => {
    const groupedRecords = {};

    each(filteredActivities, (activity) => {
      const formattedDate = format(new Date(activity.internalDate), 'y-MM-dd');

      if (!groupedRecords[formattedDate]) {
        groupedRecords[formattedDate] = [];
      }

      groupedRecords[formattedDate].push(activity);
    });

    const sortedRecords = orderBy(
      Object.entries<GetMemberActivitiesQuery_activities[]>(groupedRecords),
      first,
      'desc',
    );

    return sortedRecords as [string, GetMemberActivitiesQuery_activities[]][];
  }, [filteredActivities]);

  const items = useMemo(() => {
    if (loading || isEmpty(groupedActivities)) {
      return null;
    }

    return map(groupedActivities, ([groupDate, items]) => {
      const formattedDate = format(new Date(groupDate), 'PP');

      return (
        <div key={groupDate} className={styles.groupItems}>
          <div className={styles.groupItemsTitle}>
            {formattedDate}
          </div>
          <div>
            {
              map(
                orderBy(items, (m) => m.internalDate, 'asc'),
                (message) => (
                  message.type === MessageType.IGDM ? (
                    <MemberIGDMActivityItem
                      key={message.id}
                      className={styles.item}
                      member={member}
                      message={message}
                      applications={applications}
                      instagramFieldId={instagramField?.id}
                    />
                  ) : (
                    <MemberActivityItem
                      key={message.id}
                      className={styles.item}
                      memberName={member.name}
                      message={message}
                      applications={applications}
                    />
                  )
                ),
              )
              }
          </div>
        </div>
      );
    });
  }, [applications, groupedActivities, instagramField?.id, loading, member]);

  useEffect(() => {
    if (loading || loadingSchemas || isEmpty(filteredActivities)) {
      return;
    }

    setTimeout(() => {
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 500);
  }, [filteredActivities, loading, loadingSchemas]);

  if (loading && loadingSchemas && renderLoading) {
    return renderLoading();
  }

  return (
    <div
      className={cx(styles.MemberActivities, { [styles.emptyActivitySection]: !loading && isEmpty(filteredActivities) })}
      ref={listRef}
    >
      {loading && (
        <LoadSpinner />
      )}
      {!loading && isEmpty(filteredActivities) && (
        <EmptyState
          title="No Activity"
          description="When you collaborate and communicate with this member, activity will appear here."
          icon={ClockIcon}
        />
      )}
      {items}
    </div>
  );
});
