export interface IAgreement {
  canceled_ts: number;
  id: number;
  project_id: number;
  campaign_name: string;
  program_id: number;
  status: 'agreed' | 'publisher_modified' | 'manager_modified' | 'invalid';
  created_at: number;
  agreed_ts?: number;
  latest_iteration_id: number;
  project_link?: string | null;
  price?: number;
  amount_marked_paid?: number;
  type?: string;
  canceled?: boolean;
}

export const AGREEMENT_STATUS_MAP = {
  agreed: 'Agreed',
  publisher_modified: 'Pending',
  manager_modified: 'Pending',
  invalid: 'Unknown',
};
