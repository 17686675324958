import * as React from 'react';
import {
  Alert,
  Button,
} from '@revfluence/fresh';
import {
  PlusIcon,
  UserPlusIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  filter,
  findIndex,
  isNumber,
  map,
  orderBy,
  toLower,
  includes,
} from 'lodash';
import { useHistory } from 'react-router-dom';

import { ContentCard } from '@frontend/app/components/ContentCard/ContentCard';
import { LoadingCard } from '@frontend/app/components/ContentCard/LoadingCard';
import {
  useClientFeatureEnabled,
  useCommunitiesQuery,
  useMemberSearchCountQuery,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { RefreshContentCard } from '@frontend/app/components/ContentCard/RefreshContentCard';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import { IHomePageSection } from '../types';

import styles from '../components/GetStarted.scss';

const FALLBACK_GROUP_URL = 'https://storage.googleapis.com/aspirex-static-files/home/groups.png';
const FALLBACK_FAVORITES_URL = 'https://storage.googleapis.com/aspirex-static-files/home/favorites.png';

export const useHomePageGroups = (
  socialDiscoveryAppId: string,
): IHomePageSection => {
  const groupsQuery = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const {
    searchText,
    inputValue,
    handleSearchChange,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'groups',
      metadata: {
        source: 'homepage_refresh_ui',
      },
    },
  });

  const retryGroupsQuery = React.useCallback(() => {
    groupsQuery.refetch();
  }, [groupsQuery]);

  const allContactCountQuery = useMemberSearchCountQuery({
    variables: {
      query: {
        communityId: null,
        isContact: true,
      },
    },
  });

  const history = useHistory();
  const goToNewGroup = React.useCallback((e) => {
    e.preventDefault();
    history.push('communities/new');
  }, [history]);
  const goToFindCreators = React.useCallback((e) => {
    e.preventDefault();
    history.push(`app/${socialDiscoveryAppId}`);
  }, [history, socialDiscoveryAppId]);

  return React.useMemo((): IHomePageSection => {
    const actions = [
      ...(refreshUi ? [
        <div className={styles.searchContainer} key="search">
          <SearchInput
            value={inputValue}
            onChange={handleSearchChange}
            placeholder="Search groups..."
            isLoading={isSearchLoading}
          />
        </div>,
      ] : []),
      refreshUi ? (
        <ShadCnBtn
          key="find_creators"
          onClick={goToFindCreators}
          variant="outlineHeader"
          className="text-primary hover:text-primary"
        >
          <UserPlusIcon className="mr-2" />
          Find Creators
        </ShadCnBtn>
      ) : (
        <Button
          key="find_creators"
          href={`app/${encodeURIComponent(socialDiscoveryAppId)}`}
          onClick={goToFindCreators}
          type="default"
          style={{ fontWeight: 'normal' }}
        >
          <UserPlusIcon />
          <span>Find Creators</span>
        </Button>
      ),
      refreshUi ? (
        <ShadCnBtn
          key="new_group"
          onClick={goToNewGroup}
          variant="outlineHeader"
          className="text-primary hover:text-primary"
        >
          <PlusIcon className="mr-2" />
          New Group
        </ShadCnBtn>
      ) : (
        <Button
          key="new_group"
          href="communities/new"
          onClick={goToNewGroup}
          type="default"
          style={{ fontWeight: 'normal' }}
        >
          <PlusIcon />
          <span>New Group</span>
        </Button>
      ),
    ];
    const title = 'Groups';

    if (groupsQuery.loading && !groupsQuery.data) {
      return {
        actions,
        cards: [
          <LoadingCard key="group_loading_1" size="small" />,
          <LoadingCard key="group_loading_2" size="small" />,
          <LoadingCard key="group_loading_3" size="small" />,
          <LoadingCard key="group_loading_4" size="small" />,
          <LoadingCard key="group_loading_5" size="small" />,
          <LoadingCard key="group_loading_6" size="small" />,
        ],
        title,
      };
    }

    if (groupsQuery.error) {
      return {
        actions,
        cards: [],
        error: (
          <Alert
            action={(
              <Button
                onClick={retryGroupsQuery}
                size="small"
                type="ghost"
              >
                Retry
              </Button>
            )}
            message="Oops! Something went wrong fetching your list of groups."
            showIcon
            type="error"
          />
        ),
        title,
      };
    }

    const { communities } = groupsQuery.data;

    const myFavoritesIndex = findIndex(communities, { title: 'My Favorites' });
    let favoriteGroup = null;
    if (myFavoritesIndex >= 0) {
      favoriteGroup = communities[myFavoritesIndex];
    }
    if (searchText && !includes(toLower(favoriteGroup?.title), toLower(searchText))) {
      favoriteGroup = null;
    }

    const orderedGroups = orderBy(
      filter(communities, (_, idx) => idx !== myFavoritesIndex)
        .filter((group) => !searchText || group.title?.toLowerCase().includes(searchText.toLowerCase())),
      ['memberCount', 'title', 'id'],
      ['desc', 'asc', 'asc'],
    );

    let allContactsDescription = '';
    if (!allContactCountQuery.loading && !allContactCountQuery.error && isNumber(allContactCountQuery.data?.count)) {
      const { data: { count } } = allContactCountQuery;
      if (count === 1) {
        allContactsDescription = '1 Member';
      } else if (count < 1000) {
        allContactsDescription = `${count} Members`;
      } else {
        const roundedCount = Math.round(count / 100);
        if (roundedCount % 10 === 0) {
          allContactsDescription = `${(roundedCount / 10).toFixed(0)}K Members`;
        } else {
          allContactsDescription = `${(roundedCount / 10).toFixed(1)}K Members`;
        }
      }
    }

    let cards = [];
    const isAllContactsVisible = !searchText || includes('all contacts', searchText);
    if (isAllContactsVisible) {
      cards = [
        refreshUi ? (
          <RefreshContentCard
            cardId="group-refresh-content"
            description={allContactsDescription}
            href="member_table?communityId="
            key="refresh_content_group"
            image={{
              fallbackUrl: FALLBACK_GROUP_URL,
              title: 'All Contacts',
              url: FALLBACK_GROUP_URL,
            }}
            notificationCount={0}
            settingsHref="communities/refresh_contacts/settings"
            size="small"
            title="All Contacts"
          />
        ) : (
          <ContentCard
            cardId="group-all-contacts"
            description={allContactsDescription}
            href="member_table?communityId="
            key="all_contacts_group"
            image={{
              fallbackUrl: FALLBACK_GROUP_URL,
              title: 'All Contacts',
              url: FALLBACK_GROUP_URL,
            }}
            notificationCount={0}
            settingsHref="communities/all_contacts/settings"
            size="small"
            title="All Contacts"
          />
        ),
      ];
    }

    if (favoriteGroup) {
      cards.push(
        refreshUi ? (
          <RefreshContentCard
            cardId={`group-${favoriteGroup.id}`}
            description={
              favoriteGroup.memberCount === 1
                ? '1 Member'
                : `${favoriteGroup.memberCount} Members`
            }
            href={`member_table?communityId=${encodeURIComponent(favoriteGroup.id)}`}
            key={favoriteGroup.id.toString()}
            image={{
              fallbackUrl: FALLBACK_FAVORITES_URL,
              title: 'Favorites',
              url: favoriteGroup.splashImageUrl || FALLBACK_FAVORITES_URL,
            }}
            notificationCount={0}
            settingsHref={`communities/${encodeURIComponent(favoriteGroup.id)}/edit`}
            size="small"
            title="Favorites"
          />
        ) : (
          <ContentCard
            cardId={`group-${favoriteGroup.id}`}
            description={
              favoriteGroup.memberCount === 1
                ? '1 Member'
                : `${favoriteGroup.memberCount} Members`
            }
            href={`member_table?communityId=${encodeURIComponent(favoriteGroup.id)}`}
            key={favoriteGroup.id.toString()}
            image={{
              fallbackUrl: FALLBACK_FAVORITES_URL,
              title: 'Favorites',
              url: favoriteGroup.splashImageUrl || FALLBACK_FAVORITES_URL,
            }}
            notificationCount={0}
            settingsHref={`communities/${encodeURIComponent(favoriteGroup.id)}/edit`}
            size="small"
            title="Favorites"
          />
        ),
      );
    }

    return {
      actions,
      cards: cards.concat(map(orderedGroups, (group) => {
        const {
          id,
          memberCount,
          splashImageUrl,
          title,
        } = group;

        let image = {
          fallbackUrl: FALLBACK_GROUP_URL,
          title,
          url: 'https://www.example.com/fallback',
        };
        if (splashImageUrl) {
          image = {
            fallbackUrl: FALLBACK_GROUP_URL,
            title,
            url: splashImageUrl,
          };
        }

        let description = '1 Member';
        if (memberCount !== 1) {
          description = `${memberCount.toLocaleString()} Members`;
        }

        return (
          refreshUi ? (
            <RefreshContentCard
              cardId={`group-${id}`}
              description={description}
              href={`member_table?communityId=${encodeURIComponent(id)}`}
              key={id.toString()}
              image={image}
              notificationCount={0}
              settingsHref={`communities/${encodeURIComponent(id)}/edit`}
              size="small"
              title={title}
            />
          ) : (
            <ContentCard
              cardId={`group-${id}`}
              description={description}
              href={`member_table?communityId=${encodeURIComponent(id)}`}
              key={id.toString()}
              image={image}
              notificationCount={0}
              settingsHref={`communities/${encodeURIComponent(id)}/edit`}
              size="small"
              title={title}
            />
          )
        );
      })),
      title,
    };
  }, [
    allContactCountQuery,
    groupsQuery,
    searchText,
    goToFindCreators,
    goToNewGroup,
    refreshUi,
    retryGroupsQuery,
    socialDiscoveryAppId,
    handleSearchChange,
    inputValue,
    isSearchLoading,
  ]);
};
