import React, {
 createContext, useContext, useReducer, useMemo,
} from 'react';
import { TWorklet } from '../types';

// Enums
export enum ProgramTimePeriod {
  PER_MONTH = 'PER_MONTH',
  PER_YEAR = 'PER_YEAR',
  TOTAL = 'TOTAL',
}

export enum PreferredCreatorTypes {
  CUSTOMERS = 'CUSTOMERS',
  MACRO_CREATORS = 'MACRO_CREATORS',
  MICRO_CREATORS = 'MICRO_CREATORS',
  MID_TIER_CREATORS = 'MID_TIER_CREATORS',
  NANO_CREATORS = 'NANO_CREATORS',
  UGC_CREATORS = 'UGC_CREATORS',
}

export enum ContentPostTypes {
  CUSTOM_WORK = 'CUSTOM_WORK',
  IMAGES = 'IMAGES',
  INSTAGRAM_POST = 'INSTAGRAM_POST',
  INSTAGRAM_REEL = 'INSTAGRAM_REEL',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  PINTEREST_PIN = 'PINTEREST_PIN',
  TIKTOK_CAROUSEL = 'TIKTOK_CAROUSEL',
  TIKTOK_VIDEO = 'TIKTOK_VIDEO',
  VIDEOS = 'VIDEOS',
  YOUTUBE_SHORT = 'YOUTUBE_SHORT',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
}

export enum CompensationMethod {
  COMMISSION = 'COMMISSION',
  DISCOUNT = 'DISCOUNT',
  FREE_PRODUCT = 'FREE_PRODUCT',
}

export enum DiscountType {
  AMOUNT_OFF = 'AMOUNT_OFF',
  BUY_X_GET_Y = 'BUY_X_GET_Y',
  FREE_SHIPPING = 'FREE_SHIPPING',
  PERCENT_OFF = 'PERCENT_OFF',
}

export enum ValueType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export type TWorkletWithoutTypename = Omit<TWorklet, '__typename'>;
// State interface
export interface CampaignState {
  programId?: number;
  startDate?: Date;
  endDate?: Date;
  selectedCampaignType?: string;
  budget?: number;
  budgetTimePeriod?: ProgramTimePeriod;
  salesGoal?: number;
  salesGoalTimePeriod?: ProgramTimePeriod;
  creatorCount?: number;
  creatorsTimePeriod?: ProgramTimePeriod;
  creatorDescription?: string[];
  creatorPreference?: PreferredCreatorTypes[];
  contentPrefPostTypes?: ContentPostTypes[];
  compensationMethod?: CompensationMethod;
  discountType?: DiscountType;
  commissionValue?: number;
  commissionValueType?: ValueType;
  discountValue?: number;
  discountValueType?: ValueType;
  campaignImage?: string;
  campaignImageFile?: File;
  campaignOwner?: string;
  listToMarketplace?: boolean;
  templateName?: string;
  templateLogoURL?: string;
  worklets?: TWorkletWithoutTypename[];
  contentReviewEnabled?: boolean;
  contentReviewers?: string[];
  gcrHideApprovalAction?: boolean;
  campaignName?: string;
  hasUnsavedChanges?: boolean;
  duplicateCampaignId?: number;
  isFlexibleSpec?: boolean;
}

// Action types
type ActionType =
  | { type: 'SET_PROGRAM_ID'; payload: number }
  | { type: 'SET_CAMPAIGN_TYPE'; payload: string }
  | { type: 'SET_DATES'; payload: { startDate: Date; endDate?: Date } }
  | { type: 'SET_BUDGET'; payload: { budget: number; timePeriod: ProgramTimePeriod } }
  | { type: 'SET_SALES_GOAL'; payload: { goal: number; timePeriod: ProgramTimePeriod } }
  | { type: 'SET_CREATOR_COUNT'; payload: { count: number; timePeriod: ProgramTimePeriod } }
  | { type: 'SET_CREATOR_DESCRIPTION'; payload: string[] }
  | { type: 'SET_CREATOR_PREFERENCE'; payload: PreferredCreatorTypes[] }
  | { type: 'SET_CONTENT_POST_TYPES'; payload: ContentPostTypes[] }
  | { type: 'SET_COMPENSATION_METHOD'; payload: CompensationMethod }
  | { type: 'SET_COMMISSION_DETAILS'; payload: { value: number; valueType: ValueType } }
  | { type: 'SET_DISCOUNT_DETAILS'; payload: { value: number; type: DiscountType; valueType: ValueType } }
  | { type: 'SET_CAMPAIGN_IMAGE'; payload: string }
  | { type: 'SET_CAMPAIGN_IMAGE_FILE'; payload: File }
  | { type: 'SET_CAMPAIGN_OWNER'; payload: string }
  | { type: 'SET_LIST_TO_MARKETPLACE'; payload: boolean }
  | { type: 'SET_TEMPLATE_DETAILS'; payload: {
      templateName: string;
      worklets?: TWorkletWithoutTypename[];
    } }
  | { type: 'SET_CONTENT_REVIEW_ENABLED'; payload: boolean }
  | { type: 'SET_CONTENT_REVIEWERS'; payload: string[] }
  | { type: 'SET_GCR_HIDE_APPROVAL_ACTION'; payload: boolean }
  | { type: 'SET_CAMPAIGN_NAME'; payload: string }
  | { type: 'SET_HAS_UNSAVED_CHANGES'; payload: boolean }
  | { type: 'SET_ALL_CAMPAIGN_DATA'; payload: CampaignState }
  | { type: 'SET_WORKLETS'; payload: {
    worklets: TWorkletWithoutTypename[];
    hasUnsavedChanges: boolean;
  } }
  | { type: 'SET_DUPLICATE_CAMPAIGN_ID'; payload: number }
  | { type: 'SET_FLEXIBLE_SPEC'; payload: boolean };

// Initial state
const initialState: CampaignState = {
  startDate: new Date(),
  creatorDescription: [],
  hasUnsavedChanges: false,
};

// Reducer
function campaignReducer(state: CampaignState, action: ActionType): CampaignState {
  switch (action.type) {
    case 'SET_PROGRAM_ID':
      return {
        ...state,
        programId: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CAMPAIGN_TYPE':
      return {
        ...state,
        selectedCampaignType: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_DATES':
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        hasUnsavedChanges: true,
      };
    case 'SET_BUDGET':
      return {
        ...state,
        budget: action.payload.budget,
        budgetTimePeriod: action.payload.timePeriod,
        hasUnsavedChanges: true,
      };
    case 'SET_SALES_GOAL':
      return {
        ...state,
        salesGoal: action.payload.goal,
        salesGoalTimePeriod: action.payload.timePeriod,
        hasUnsavedChanges: true,
      };
    case 'SET_CREATOR_COUNT':
      return {
        ...state,
        creatorCount: action.payload.count,
        creatorsTimePeriod: action.payload.timePeriod,
        hasUnsavedChanges: true,
      };
    case 'SET_CREATOR_DESCRIPTION':
      return {
        ...state,
        creatorDescription: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CREATOR_PREFERENCE':
      return {
        ...state,
        creatorPreference: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CONTENT_POST_TYPES':
      return {
        ...state,
        contentPrefPostTypes: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_COMPENSATION_METHOD':
      return {
        ...state,
        compensationMethod: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_COMMISSION_DETAILS':
      return {
        ...state,
        commissionValue: action.payload.value,
        commissionValueType: action.payload.valueType,
        hasUnsavedChanges: true,
      };
    case 'SET_DISCOUNT_DETAILS':
      return {
        ...state,
        discountValue: action.payload.value,
        discountType: action.payload.type,
        discountValueType: action.payload.valueType,
        hasUnsavedChanges: true,
      };
    case 'SET_CAMPAIGN_IMAGE':
      return {
        ...state,
        campaignImage: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CAMPAIGN_IMAGE_FILE':
      return {
        ...state,
        campaignImageFile: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CAMPAIGN_OWNER':
      return {
        ...state,
        campaignOwner: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_LIST_TO_MARKETPLACE':
      return {
        ...state,
        listToMarketplace: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_TEMPLATE_DETAILS':
      return {
        ...state,
        templateName: action.payload.templateName,
        worklets: action.payload.worklets,
        hasUnsavedChanges: true,
      };
    case 'SET_CONTENT_REVIEW_ENABLED':
      return {
        ...state,
        contentReviewEnabled: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CONTENT_REVIEWERS':
      return {
        ...state,
        contentReviewers: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_GCR_HIDE_APPROVAL_ACTION':
      return {
        ...state,
        gcrHideApprovalAction: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_CAMPAIGN_NAME':
      return {
        ...state,
        campaignName: action.payload,
        hasUnsavedChanges: true,
      };
    case 'SET_HAS_UNSAVED_CHANGES':
      return {
        ...state,
        hasUnsavedChanges: action.payload,
      };
    case 'SET_ALL_CAMPAIGN_DATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_WORKLETS':
      return {
        ...state,
        worklets: action.payload.worklets,
        hasUnsavedChanges: action.payload.hasUnsavedChanges || state.hasUnsavedChanges,
      };
    case 'SET_DUPLICATE_CAMPAIGN_ID':
      return {
        ...state,
        duplicateCampaignId: action.payload,
      };
    case 'SET_FLEXIBLE_SPEC':
      return {
        ...state,
        isFlexibleSpec: action.payload,
      };
    default:
      return state;
  }
}

// Update the context type to match the spread value shape
interface CampaignContextType extends CampaignState {
  setProgramId: (id: number) => void;
  setCampaignType: (type: string) => void;
  setDates: (startDate: Date, endDate?: Date) => void;
  setBudget: (budget: number, timePeriod: ProgramTimePeriod) => void;
  setSalesGoal: (goal: number, timePeriod: ProgramTimePeriod) => void;
  setCreatorCount: (count: number, timePeriod: ProgramTimePeriod) => void;
  setCreatorDescription: (description: string[]) => void;
  setCreatorPreference: (preference: PreferredCreatorTypes[]) => void;
  setContentPostTypes: (types: ContentPostTypes[]) => void;
  setCompensationMethod: (method: CompensationMethod) => void;
  setCommissionDetails: (value: number, valueType: ValueType) => void;
  setDiscountDetails: (value: number, type: DiscountType, valueType: ValueType) => void;
  setCampaignImage: (image: string) => void;
  setCampaignImageFile: (file: File) => void;
  setCampaignOwner: (owner: string) => void;
  setListToMarketplace: (list: boolean) => void;
  setTemplateDetails: (templateName: string, worklets?: TWorkletWithoutTypename[]) => void;
  setContentReviewEnabled: (enabled: boolean) => void;
  setContentReviewers: (reviewers: string[]) => void;
  setGcrHideApprovalAction: (hide: boolean) => void;
  setCampaignName: (name: string) => void;
  setHasUnsavedChanges: (hasChanges: boolean) => void;
  setAllCampaignData: (data: CampaignState) => void;
  setWorklets: (worklets: TWorkletWithoutTypename[], hasUnsavedChanges?: boolean) => void;
  setDuplicateCampaignId: (id: number) => void;
  setFlexibleSpec: (isFlexibleSpec: boolean) => void;
}

const CampaignContext = createContext<CampaignContextType | undefined>(undefined);

export const CampaignProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(campaignReducer, initialState);

  const actions = useMemo(() => ({
    setProgramId: (id: number) =>
      dispatch({ type: 'SET_PROGRAM_ID', payload: id }),
    setCampaignType: (type: string) =>
      dispatch({ type: 'SET_CAMPAIGN_TYPE', payload: type }),
    setDates: (startDate: Date, endDate?: Date) =>
      dispatch({ type: 'SET_DATES', payload: { startDate, endDate } }),
    setBudget: (budget: number, timePeriod: ProgramTimePeriod) =>
      dispatch({ type: 'SET_BUDGET', payload: { budget, timePeriod } }),
    setSalesGoal: (goal: number, timePeriod: ProgramTimePeriod) =>
      dispatch({ type: 'SET_SALES_GOAL', payload: { goal, timePeriod } }),
    setCreatorCount: (count: number, timePeriod: ProgramTimePeriod) =>
      dispatch({ type: 'SET_CREATOR_COUNT', payload: { count, timePeriod } }),
    setCreatorDescription: (description: string[]) =>
      dispatch({ type: 'SET_CREATOR_DESCRIPTION', payload: description }),
    setCreatorPreference: (preference: PreferredCreatorTypes[]) =>
      dispatch({ type: 'SET_CREATOR_PREFERENCE', payload: preference }),
    setContentPostTypes: (types: ContentPostTypes[]) =>
      dispatch({ type: 'SET_CONTENT_POST_TYPES', payload: types }),
    setCompensationMethod: (method: CompensationMethod) =>
      dispatch({ type: 'SET_COMPENSATION_METHOD', payload: method }),
    setCommissionDetails: (value: number, valueType: ValueType) =>
      dispatch({ type: 'SET_COMMISSION_DETAILS', payload: { value, valueType } }),
    setDiscountDetails: (value: number, type: DiscountType, valueType: ValueType) =>
      dispatch({ type: 'SET_DISCOUNT_DETAILS', payload: { value, type, valueType } }),
    setCampaignImage: (image: string) =>
      dispatch({ type: 'SET_CAMPAIGN_IMAGE', payload: image }),
    setCampaignImageFile: (file: File) =>
      dispatch({ type: 'SET_CAMPAIGN_IMAGE_FILE', payload: file }),
    setCampaignOwner: (owner: string) =>
      dispatch({ type: 'SET_CAMPAIGN_OWNER', payload: owner }),
    setListToMarketplace: (list: boolean) =>
      dispatch({ type: 'SET_LIST_TO_MARKETPLACE', payload: list }),
    setTemplateDetails: (templateName: string, worklets?: TWorkletWithoutTypename[]) =>
      dispatch({ type: 'SET_TEMPLATE_DETAILS', payload: { templateName, worklets } }),
    setContentReviewEnabled: (enabled: boolean) =>
      dispatch({ type: 'SET_CONTENT_REVIEW_ENABLED', payload: enabled }),
    setContentReviewers: (reviewers: string[]) =>
      dispatch({ type: 'SET_CONTENT_REVIEWERS', payload: reviewers }),
    setGcrHideApprovalAction: (hide: boolean) =>
      dispatch({ type: 'SET_GCR_HIDE_APPROVAL_ACTION', payload: hide }),
    setCampaignName: (name: string) =>
      dispatch({ type: 'SET_CAMPAIGN_NAME', payload: name }),
    setHasUnsavedChanges: (hasChanges: boolean) =>
      dispatch({ type: 'SET_HAS_UNSAVED_CHANGES', payload: hasChanges }),
    setAllCampaignData: (data: CampaignState) =>
      dispatch({ type: 'SET_ALL_CAMPAIGN_DATA', payload: data }),
    setWorklets: (worklets: TWorkletWithoutTypename[], hasUnsavedChanges?: boolean) =>
      dispatch({ type: 'SET_WORKLETS', payload: { worklets, hasUnsavedChanges } }),
    setDuplicateCampaignId: (id: number) =>
      dispatch({ type: 'SET_DUPLICATE_CAMPAIGN_ID', payload: id }),
    setFlexibleSpec: (isFlexibleSpec: boolean) =>
      dispatch({ type: 'SET_FLEXIBLE_SPEC', payload: isFlexibleSpec }),
  }), []);

  const contextValue = useMemo(() => ({
    ...state,
    ...actions,
  }), [state, actions]);

  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

// Custom hook for using the campaign context
export const useCampaign = () => {
  const context = useContext(CampaignContext);
  if (context === undefined) {
    throw new Error('useCampaign must be used within a CampaignProvider');
  }
  return context;
};
