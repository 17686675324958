import { format } from 'date-fns';
import { DateRangeFormData } from './date-range-form.types';

export const formatDisplayDate = (dateString: string) => {
  const date = new Date(dateString);
  // Adjust for timezone offset to ensure we display the correct date
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return format(utcDate, 'MMM d, yyyy');
};

export const getDisplayText = (dateRange?: DateRangeFormData['dateRange']) => {
  if (dateRange?.from && dateRange?.to) {
    return `${formatDisplayDate(dateRange.from)} — ${formatDisplayDate(dateRange.to)}`;
  }
  return 'Select date range';
};
