import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { getColorFromName, getInitials } from '@frontend/app/refresh-components/NameCell';

interface MemberProps extends React.HTMLAttributes<HTMLAnchorElement> {
  image?: string;
  name: string;
  url: string;
}

const Member: React.FC<MemberProps> = ({ name, image, url, className, ...props }) => (
  <Link to={url} className={cn('flex gap-1 items-center px-2 text-primary hover:text-primary', className)} {...props}>
    <Avatar className="w-6 h-6">
      <AvatarImage src={image || '/default-avatar.png'} alt={name} />
      <AvatarFallback
        className={cn(
            'flex items-center justify-center h-full w-full text-white font-semibold text-[10px]',
            getColorFromName(name),
          )}
      >
        {getInitials(name)}
      </AvatarFallback>
    </Avatar>
    <span className="overflow-hidden text-ellipsis whitespace-nowrap hover:underline">{name}</span>
  </Link>
);

export default Member;
