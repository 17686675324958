import gql from 'graphql-tag';

const GET_PAYMENT_DASHBOARD_WIDGET_DATA = gql`
  query GetPaymentDashboardWidgetDataQuery($filters: PaymentDashboardFilterInput!) {
    paymentSummary: getPaymentDashboardWidgetData(filters: $filters) {
      paidForBriefs {
        amount
        count
        percentChange
      }
      paidFromSalesTracking {
        amount
        count
        percentChange
      }
      processingPayments {
        amount
        count
        percentChange
      }
      totalAmountSpent {
        amount
        count
        percentChange
      }
      totalPayment {
        amount
        count
        percentChange
      }
    }
  }
`;

export default GET_PAYMENT_DASHBOARD_WIDGET_DATA;
