import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Link, Shield, Tv, Check, X, Trash2, AlertCircle, Pen, Tag, Globe } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@frontend/shadcn/components/ui/accordion';
import { P } from '@frontend/shadcn/components/typography/p';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import Text from '@frontend/app/refresh-components/Text';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from '@frontend/shadcn/components/ui/alert-dialog';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { H4 } from '@frontend/shadcn/components/typography/h4';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { CircleCheckIcon } from '@revfluence/fresh-icons/solid/esm';
import { Label } from '@frontend/shadcn/components/ui/label';
import { UtmSheet } from './UtmSheet';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormValues,
  OfferFormAsyncActions,
  OfferFormModes,
} from '../../../../../../types';

interface IProps {
  formValues: IShopifyPromoCodeFormValues;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  shopifyStoreName: string;
  hasMembers?: boolean;
  isOfferPromoLink?: boolean;
  mode?: OfferFormModes;
  isLandingPageEnabled?: boolean;
  saveStatus: string;
}

const RefreshLinksForPromos: React.FC<Readonly<IProps>> = ({
  formValues: values,
  handleFormAsyncActions,
  shopifyStoreName,
  mode,
  isOfferPromoLink,
  hasMembers,
  isLandingPageEnabled,
  saveStatus,
}) => {
  const history = useHistory();
  const [_redirectError, setRedirectError] = useState(false);
  const { secureCodes, utmSupportLinkForPromo: isUtmSupport, shopifyLandingPage } = useClientFeatures();
  const [initialPromoLinkState] = useState(values.isPromoLink);
  const [initialOfferPromoLinkState] = useState(isOfferPromoLink);
  const [localPromoLinkEnabled, setLocalPromoLinkEnabled] = useState(values.isPromoLink);
  const [localRedirectUrl, setLocalRedirectUrl] = useState(values.shopifyRedirectUrl || '');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUtmDrawerOpen, setIsUtmDrawerOpen] = useState(false);
  const [initialLandingPageState] = useState(values.isLandingPageEnabled);
  const [initialSecureCodesState] = useState(values.isSecureCodes);
  useEffect(() => {
    if (!values.shopifyRedirectUrl || isEmpty(values.shopifyRedirectUrl)) {
      setRedirectError(false);
    }
  }, [values, setRedirectError]);

  useEffect(() => {
    if (isOfferPromoLink) {
      setLocalPromoLinkEnabled(true);
    }
  }, [isOfferPromoLink]);

  const isSwitchDisabled = useMemo(() => {
    if (initialOfferPromoLinkState && mode !== OfferFormModes.Create) return true;

    if (mode === OfferFormModes.Edit) {
      if (initialPromoLinkState && saveStatus === 'saved') {
        return true;
      }

      if (!initialPromoLinkState) {
        return false;
      }
    }

    return false;
  }, [initialOfferPromoLinkState, mode, initialPromoLinkState, saveStatus]);

  const handleSwitch = () => {
    const newValue = !localPromoLinkEnabled;
    setLocalPromoLinkEnabled(newValue);
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isPromoLink', value: newValue },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isLandingPageEnabled', value: false },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isSecureCodes', value: false },
    });
  };

  const handleLandingPageSwitch = () => {
    if (isCreatorStoreDisabled) return;

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isLandingPageEnabled', value: true },
    });

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'shopifyRedirectUrl', value: '' },
    });
    setLocalRedirectUrl('');
    setLocalPromoLinkEnabled(true);
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isPromoLink', value: true },
    });
  };

  const handleDiscountcodeSwitch = () => {
    if (isDiscountLinkDisabled) return;

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isLandingPageEnabled', value: false },
    });
  };

  const isPromoCodesDisabled = useMemo(() => {
    if (hasMembers) return true;

    if (mode === OfferFormModes.Edit) {
      if (initialSecureCodesState && hasMembers) {
        return true;
      }
    }

    return false;
  }, [hasMembers, mode, initialSecureCodesState]);

  const isSecureCodesDisabled = useMemo(() => {
    if (hasMembers) return true;
    return !localPromoLinkEnabled;
  }, [hasMembers, localPromoLinkEnabled]);

  const handleSecureCodes = useCallback(
    (checked: boolean) => {
      if (isSecureCodesDisabled) return;

      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'isSecureCodes', value: checked },
      });

      if (checked) {
        setLocalPromoLinkEnabled(true);
        handleFormAsyncActions({
          action: OfferFormAsyncActions.UPDATE_FIELD,
          payload: { key: 'isPromoLink', value: true },
        });
      }
    },
    [handleFormAsyncActions, isSecureCodesDisabled],
  );

  const handlePromoCodes = () => {
    if (isPromoCodesDisabled) return;

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isSecureCodes', value: false },
    });
  };

  const handleRedirectUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newUrl = event.target.value;
      setLocalRedirectUrl(newUrl);

      try {
        const url = new URL(newUrl);
        if (url.hostname !== shopifyStoreName) {
          setRedirectError(true);
        } else {
          setRedirectError(false);
        }
      } catch {
        setRedirectError(true);
      }
    },
    [shopifyStoreName, setRedirectError],
  );

  const handleCancelEdit = () => {
    setLocalRedirectUrl(values.shopifyRedirectUrl || '');
    setIsEditing(false);
  };

  const handleDeleteRedirectUrl = () => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'shopifyRedirectUrl', value: '' },
    });
    setIsDeleteDialogOpen(false);
    setLocalRedirectUrl('');
  };

  const handleLandingPageNavigation = () => {
    const location = history.location;
    const newPathname = location.pathname.replace('/edit', '/setupLandingPage');
    history.push({ ...location, pathname: newPathname });
  };

  const isDiscountLinkDisabled = useMemo(() => {
    if (values.isMultipleShopifySyncEnabled) return true;

    if (mode === OfferFormModes.Edit) {
      if (initialLandingPageState) {
        return true;
      }
    }

    return !localPromoLinkEnabled;
  }, [
    values.isMultipleShopifySyncEnabled,
    mode,
    initialLandingPageState,
    localPromoLinkEnabled
  ]);

  const isCreatorStoreDisabled = useMemo(() => {
    if (values.isMultipleShopifySyncEnabled || (hasMembers && isLandingPageEnabled)) {
      return true;
    }

    return !localPromoLinkEnabled;
  }, [
    values.isMultipleShopifySyncEnabled,
    hasMembers,
    isLandingPageEnabled,
    localPromoLinkEnabled
  ]);

  const DiscountLinkfeatures = [
    {
      id: 'discount',
      title: 'Discount Link',
      description: 'Unique links for members',
      icon: <Link className="h-4 w-4 text-white" />,
      iconBg: 'bg-magenta-4',
      isSelected: !values.isLandingPageEnabled && localPromoLinkEnabled,
      onSelect: handleDiscountcodeSwitch,
      disabled: isDiscountLinkDisabled,
      info: "Customers redirected to your store's homepage",
      showRedirectUrl: true,
      hidden: false,
      actions: (isSelected: boolean) => (
        <div className="flex gap-1 ml-auto">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className={`w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md ${!isSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isSelected) return;
                  setIsEditing(true);
                }}
                disabled={!isSelected}
              >
                <Pen className="h-3 w-3 text-grey-6" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Edit redirect url</p>
            </TooltipContent>
          </Tooltip>
          {values.shopifyRedirectUrl && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className={`w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md ${!isSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isSelected) return;
                    setIsDeleteDialogOpen(true);
                  }}
                  disabled={!isSelected}
                >
                  <Trash2 className="h-3 w-3 text-red-500" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Remove redirect URL</p>
              </TooltipContent>
            </Tooltip>
          )}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className={`w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md ${!isSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isSelected) return;
                  setIsUtmDrawerOpen(true);
                }}
                disabled={!isSelected}
              >
                <Globe className="h-3 w-3 text-grey-6" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add UTMs to links</p>
            </TooltipContent>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'creator',
      title: 'CreatorStores™',
      description: 'Co-branded landing pages',
      icon: <Tv className="h-4 w-4 text-white" />,
      iconBg: 'bg-blue-4',
      isSelected: values.isLandingPageEnabled,
      onSelect: handleLandingPageSwitch,
      disabled: isCreatorStoreDisabled,
      info: 'Members will receive link to their CreatorStore.',
      showRedirectUrl: false,
      hidden: !shopifyLandingPage,
      actions: (isSelected: boolean) => (
        <div className="flex gap-1 ml-auto">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className={`w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md ${!isSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isSelected) return;
                  handleLandingPageNavigation();
                }}
                disabled={!isSelected || mode === OfferFormModes.Create || values.isMultipleShopifySyncEnabled || !values.landingPageUrl}
              >
                <Pen className="h-3 w-3 text-grey-6" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Open Store Editor</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className={`w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md ${!isSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isSelected) return;
                  setIsUtmDrawerOpen(true);
                }}
                disabled={!isSelected}
              >
                <Globe className="h-3 w-3 text-grey-6" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add UTMs to links</p>
            </TooltipContent>
          </Tooltip>
        </div>
      ),
    },
  ];

  const promoCodeOptions = [
    {
      id: 'promo',
      title: 'Promo Codes',
      description: 'Fixed code for members',
      icon: <Tag className="h-4 w-4 text-white" />,
      iconBg: 'bg-blue-4',
      isSelected: !values.isSecureCodes,
      onSelect: handlePromoCodes,
      disabled: isPromoCodesDisabled,
    },
    {
      id: 'secure',
      title: 'SecureCodes™',
      description: 'One-time use codes',
      icon: <Shield className="h-4 w-4 text-white" />,
      iconBg: 'bg-green-4',
      isSelected: values.isSecureCodes,
      onSelect: () => handleSecureCodes(true),
      disabled: isSecureCodesDisabled,
    },
  ];

  return (
    <>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="links-section">
          <AccordionTrigger className="hover:no-underline p-0 m-0">
            <div className="flex flex-col gap-1">
              <P className="text-grey-6" fontWeight="font-medium">
                Discount Links & SecureCodes
              </P>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <P className="text-grey-4 mb-4 p-0 mt-0">
              Enable the option to generate unique discount links for creators. These links can be shared directly or lead to a personalized creator store.{' '}
              <a className="text-grey-5 hover:underline text-grey-6" href="https://help.aspireiq.com/en/collections/11732453-shopify-discount-links" target="_blank">Learn More</a>
            </P>
            <div className="py-2 flex items-center gap-2">
              <Switch
                checked={localPromoLinkEnabled}
                onCheckedChange={handleSwitch}
                disabled={isSwitchDisabled}
              />
              <Label>Generate links for each promo code</Label>
            </div>
            <Separator className="my-4" />

            <div className="flex flex-col gap-2 w-full">
              <Text visualVariant="label">Link Type</Text>
              <div className="grid grid-cols-2 gap-4 relative px-1 w-full">
                {DiscountLinkfeatures.map((feature) => (
                  <Card
                    key={feature.id}
                    className={[
                      'w-full p-0 border-none rounded-xl relative overflow-visible',
                      feature.disabled && !feature.isSelected ? 'opacity-50 cursor-not-allowed' : '',
                      feature.hidden ? 'hidden' : ''
                    ].join(' ')}
                    onClick={() => !feature.disabled && feature.onSelect()}
                  >
                    <CardContent
                      className={`flex flex-col p-0 rounded-xl ${feature.isSelected ? 'bg-grey-2' : 'bg-grey-1'}`}
                    >
                      <div className={[
                        'flex items-center gap-3 px-3 py-2 rounded-t-xl rounded-b-xl border',
                        feature.isSelected ? 'bg-white border-grey-6' : 'bg-grey-0 border-grey-2',
                        feature.disabled && !feature.isSelected ? 'cursor-not-allowed' : 'cursor-pointer hover:border-grey-4',
                      ].join(' ')}
                      >
                        <div className={`flex-shrink-0 flex w-8 h-8 items-center justify-center ${feature.iconBg} rounded-lg`}>
                          {feature.icon}
                        </div>
                        <div className="flex flex-col items-start min-w-0">
                          <div className="font-medium text-[14px] leading-6 text-grey-6">{feature.title}</div>
                          <div className="inline-flex items-center gap-1">
                            <div className="text-[12px] leading-5 text-grey-5">{feature.description}</div>
                            {/* <Tooltip>
                              <TooltipTrigger>
                                <CircleInfoIcon className="h-3 w-3 text-grey-3" />
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{feature.info}</p>
                              </TooltipContent>
                            </Tooltip> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`px-3 py-2 ${feature.isSelected ? 'bg-grey-2' : 'bg-grey-1'} w-full rounded-b-xl`}
                      >
                        {feature.showRedirectUrl && !isEditing && (
                          <div className="flex items-center justify-between w-full">
                            <div className="min-w-0 flex-1 max-w-[calc(100%-100px)] flex items-center">
                              {values.shopifyRedirectUrl ? (
                                <Tooltip>
                                  <TooltipTrigger className="w-full block">
                                    <p className="text-[12px] leading-5 text-grey-5 truncate m-0">
                                      Redirect Link: {values.shopifyRedirectUrl}
                                    </p>
                                  </TooltipTrigger>
                                  <TooltipContent side="bottom" className="max-w-[500px]">
                                    <p className="text-[12px] break-all">
                                      Redirect Link: {values.shopifyRedirectUrl}
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              ) : (
                                <Tooltip>
                                  <TooltipTrigger className="w-full text-left">
                                    <p className="text-[12px] leading-5 text-grey-5 truncate m-0">
                                      {feature.info}
                                    </p>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p className="text-[12px]">{feature.info}</p>
                                  </TooltipContent>
                                </Tooltip>
                              )}
                            </div>
                            <div className="flex items-center gap-1 flex-shrink-0">
                              {feature.actions(feature.isSelected)}
                            </div>
                          </div>
                        )}
                        {!feature.showRedirectUrl && (
                          <div className="flex items-center justify-between w-full">
                            <div className="min-w-0 flex-1 max-w-[calc(100%-100px)] flex items-center">
                              <Tooltip>
                                <TooltipTrigger className="w-full block">
                                  <p className="text-[12px] leading-5 text-grey-5 truncate m-0">
                                    {feature.info}
                                  </p>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>{feature.info}</p>
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <div className="flex items-center gap-1 flex-shrink-0">
                              {feature.actions(feature.isSelected)}
                            </div>
                          </div>
                        )}
                        {feature.showRedirectUrl && isEditing && (
                          <div className="flex gap-2 w-full items-center">
                            <Input
                              className="w-full bg-grey-0 border-grey-2"
                              placeholder="Enter Redirect URL"
                              value={localRedirectUrl}
                              onChange={handleRedirectUrl}
                            />
                            <>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    size="icon"
                                    className="w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFormAsyncActions({
                                        action: OfferFormAsyncActions.UPDATE_FIELD,
                                        payload: { key: 'shopifyRedirectUrl', value: localRedirectUrl },
                                      });
                                      setIsEditing(false);
                                    }}
                                  >
                                    <Check className="h-3 w-3 text-grey-5" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Add</p>
                                </TooltipContent>
                              </Tooltip>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    size="icon"
                                    className="w-6 h-6 p-0 bg-grey-0 hover:bg-grey-1 rounded-md"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCancelEdit();
                                    }}
                                  >
                                    <X className="h-3 w-3 text-red-500" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Cancel</p>
                                </TooltipContent>
                              </Tooltip>
                            </>
                          </div>
                        )}
                      </div>
                    </CardContent>
                    {feature.isSelected && (
                      <div className="inline-flex h-5 items-center justify-center absolute -top-1.5 -right-1.5 bg-white rounded-lg border-2 border-solid border-white z-20">
                        <CircleCheckIcon className="h-5 w-5 text-grey-6" />
                      </div>
                    )}
                  </Card>
                ))}
              </div>
            </div>

            {/* Promo Code Options */}
            {secureCodes && (
              <div className="mt-4 flex flex-col gap-2">
                <Text visualVariant="label">Promo Code Type</Text>
                <div className="flex items-start gap-4 relative px-1">
                  {promoCodeOptions.map((option) => (
                    <Card
                      key={option.id}
                      className={[
                        'flex-1 p-0 border rounded-xl relative',
                        option.isSelected ? 'bg-white border-grey-6' : 'bg-grey-0 border-grey-2',
                        option.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                      ].join(' ')}
                      onClick={() => !option.disabled && option.onSelect()}
                    >
                      <CardContent className="flex items-center gap-2 p-3">
                        <div className={`flex w-8 h-8 items-center justify-center p-2 ${option.iconBg} rounded-lg`}>
                          {option.icon}
                        </div>
                        <div className="flex flex-col items-start">
                          <div className="font-medium-100 text-[#16282d]">{option.title}</div>
                          <div className="inline-flex items-center gap-1">
                            <div className="font-regular-75-caption text-grey-5">{option.description}</div>
                            {/* <Tooltip>
                              <TooltipTrigger>
                                <CircleInfoIcon className="h-3 w-3 text-grey-3" />
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Learn more about {option.title}</p>
                              </TooltipContent>
                            </Tooltip> */}
                          </div>
                        </div>
                        {option.isSelected && (
                          <div className="inline-flex h-5 items-center justify-center absolute -top-1.5 -right-1.5 bg-white rounded-lg border-2 border-solid border-white">
                            <CircleCheckIcon className="h-5 w-5 text-grey-6" />
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            )}

            {isUtmSupport && (
              <div>
                <UtmSheet
                  values={values}
                  handleFormAsyncActions={handleFormAsyncActions}
                  isOpen={isUtmDrawerOpen}
                  onClose={() => setIsUtmDrawerOpen(false)}
                />
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent className="w-[444px] p-8">
          <AlertDialogHeader className="flex flex-row items-center gap-3">
            <AlertCircle className="h-6 w-6 text-yellow-500" />
            <H4 className="text-grey-6">Are you sure you want to remove the redirect URL?</H4>
          </AlertDialogHeader>
          <P className="text-grey-5">
            Removing the link will not impact any existing members' links; it will only apply to new members added to
            this offer.
          </P>
          <AlertDialogFooter className="pt-6">
            <Button
              type="button"
              variant="outline"
              className="rounded-lg border-grey-3 px-4 py-1"
              onClick={() => setIsDeleteDialogOpen(false)}
            >
              <span className="font-medium-100 text-grey-6">Cancel</span>
            </Button>
            <Button type="button" variant="primary" onClick={handleDeleteRedirectUrl}>
              <span className="font-medium-100 text-grey-0">Remove</span>
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RefreshLinksForPromos;
