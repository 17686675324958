/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fvij_157 {
  display: flex;
}

._justify-content-space-between_1fvij_161 {
  justify-content: space-between;
}

._tabular-nums_1fvij_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fvij_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContentFilters_1fvij_178 ._filterLabel_1fvij_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ContentFilters_1fvij_178 ._filterLabel_1fvij_178 {
  margin-top: 20px;
  margin-bottom: 8px;
  display: table;
}
._ContentFilters_1fvij_178 ._filterLabel_1fvij_178 > span {
  display: table-cell;
}
._ContentFilters_1fvij_178 ._filterLabel_1fvij_178 ._helpIcon_1fvij_196, ._ContentFilters_1fvij_178 ._filterLabel_1fvij_178 ._favouriteIcon_1fvij_196 {
  margin-top: -3px;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
}
._ContentFilters_1fvij_178 ._tagInput_1fvij_202 {
  border-radius: 20px;
}

._dropdown_1fvij_206 {
  max-width: 260px;
}
.ant-refresh ._dropdown_1fvij_206 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

._networkOption_1fvij_214 {
  display: flex;
  align-items: center;
}
._networkOption_1fvij_214 ._networkName_1fvij_218 {
  padding-right: 8px;
  padding-left: 8px;
}
._networkOption_1fvij_214 * {
  display: flex;
  align-items: center;
}

._helpIcon_1fvij_196 {
  color: #ea92af;
}

._favouriteIcon_1fvij_196 {
  color: #f1515f;
}

._searchContainer_1fvij_235 {
  width: 100%;
  padding: 0 !important;
}
._searchContainer_1fvij_235 [class*=searchIcon],
._searchContainer_1fvij_235 [class*=clearButton] {
  color: #4F5256 !important;
}
._searchContainer_1fvij_235 .ant-dropdown-menu-item,
._searchContainer_1fvij_235 .ant-select-item {
  width: 100% !important;
  padding: 0 !important;
}
._searchContainer_1fvij_235 .ant-input-affix-wrapper {
  width: 100% !important;
  min-width: 100% !important;
}
._searchContainer_1fvij_235 .ant-input-affix-wrapper[aria-invalid=true] {
  border-color: var(--error-color);
}

._searchError_1fvij_256 {
  color: #4f5256;
  font-size: 12px;
  padding-top: 0.75rem;
  margin: 0;
  width: 100%;
}