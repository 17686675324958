import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_VARIANT_META_FIELD_VALUES } from '../queries/productDetails';
import { GetVariantMetaFieldValues, GetVariantMetaFieldValuesVariables } from '../queries/types/GetVariantMetaFieldValues';

type IOptions = QueryHookOptions<GetVariantMetaFieldValues, GetVariantMetaFieldValuesVariables>;

export function useGetVariantMetaFieldValues(options: IOptions) {
  const {
    loading, data, error,
  } = useQuery<GetVariantMetaFieldValues, GetVariantMetaFieldValuesVariables>(
    GET_VARIANT_META_FIELD_VALUES,
    options,
  );

  return {
    isVariantMetaFieldsLoading: loading,
    variantMetaFields: data?.getVariantMetaFieldValues,
    variantMetaFieldsError: error,
  };
}
