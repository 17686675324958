import * as React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { AppModalNav } from '@frontend/applications/Shared/components/AppModalNav';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import NewSocialPost from '../components/NewSocialPost';
import MemberSocialPostList from '../components/MemberSocialPostList';
import MemberInsightsAuth from '../components/MemberInsightsAuth';
import UnassignedSocialPostList from '../components/UnassignedSocialPostList';

const { useEffect, useMemo } = React;

interface ISocialPostAppDeepLinkParams {
  context?: string;
  mentionId?: string;
  action?: string;
}

interface IProps {
  deepLinkParameters?: ISocialPostAppDeepLinkParams;
}

const SocialPostAppModal: React.FunctionComponent<IProps> = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { workflowActionParameters } = useApplication();

  const { deepLinkParameters } = props;
  useEffect(() => {
    if (deepLinkParameters?.context === 'assignPost' && deepLinkParameters?.mentionId) {
      history.replace({
        ...location,
        search: `?postId=${deepLinkParameters.mentionId}`,
        pathname: `${match.url}/posts`,
      });
    }
  }, [deepLinkParameters, history, location, match]);

  const navSettings = [
    {
      route: 'posts',
      displayName: 'All Posts',
      component: MemberSocialPostList,
    },
    {
      route: 'unassigned',
      displayName: 'Unassigned Posts',
      component: UnassignedSocialPostList,
    },
    {
      route: 'auth',
      displayName: 'Account Verification',
      component: MemberInsightsAuth,
    },
    {
      route: 'add_post',
      displayName: 'Add New Post',
      component: NewSocialPost,
    },
  ];

  const defaultRoute = useMemo(() => {
    if (deepLinkParameters?.action === 'view_posts') {
      return 'posts';
    } else if (deepLinkParameters?.action === 'review_posts') {
      return 'unassigned';
    } else if (deepLinkParameters?.action === 'verification') {
      return 'auth';
    }
    return isEmpty(workflowActionParameters) ? 'posts' : 'unassigned';
  }, [workflowActionParameters, deepLinkParameters]);

  return (
    <AppModalNav navLinks={navSettings} defaultRoute={defaultRoute} />
  );
};

export default SocialPostAppModal;
