import React from 'react';

interface InstagramProps extends React.HTMLAttributes<HTMLDivElement> {
  isVerified: boolean;
  handle: string;
}

const Instagram: React.FC<InstagramProps> = ({ handle, isVerified }) => (
  <div className="flex gap-1 items-center px-2">
    <a
      href={`https://instagram.com/${handle}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:underline"
    >
      {handle}
    </a>
    {isVerified && (
      <span
        className="text-blue-600"
        aria-label="Verified Account"
        title="Verified Account"
      >
        <svg
          viewBox="0 0 24 24"
          className="w-4 h-4 fill-current"
        >
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
        </svg>
      </span>
    )}
  </div>
);

export default Instagram;
