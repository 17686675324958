import { ApolloProvider } from '@apollo/client';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { Eye, Plus, X } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { FindAndImportProvider } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { useAppContext } from '@frontend/context/AppContext';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Label } from '@frontend/shadcn/components/ui/label';
import { VariableInput } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/VariableInput';
import {
  CreatorVariableKey,
  getVariableDisplay,
  convertTemplateToFrontend,
  convertTemplateToBackend,
} from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/variables';
import { ExportProductModal } from '../../Basic/components/ExportProductModal';

const VARIABLE_OPTIONS = [CreatorVariableKey.NAME].map(getVariableDisplay);

type ProductDetails = {
  id: string;
  title: string;
  imgUrl?: string;
  imageUrl?: string;
  description?: string;
  productUrl?: string;
};

const FeaturedProductContent: React.FC = () => {
  const { landingPageData, setLandingPageData, clearFieldError } = useLandingPageContext();
  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();
  const [isFeaturedProduct, setIsFeaturedProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductDetails>(
    landingPageData?.featureProduct || null,
  );
  const [headingValue, setHeadingValue] = useState(
    convertTemplateToFrontend(landingPageData.products.featuredProductHeading || ''),
  );
  const { pfaApolloClient } = useAppContext();
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);

  // Initialize switch state from context
  useEffect(() => {
    setIsFeaturedProduct(landingPageData?.products?.isFeaturedProductEnable ?? false);
  }, [landingPageData?.products?.isFeaturedProductEnable]);

  const handleSelectProduct = () => {
    setIsLocalModalOpen(true);
    setIsFindAndImportOpen(true);
  };

  const handleCloseModal = () => {
    setIsLocalModalOpen(false);
    setIsFindAndImportOpen(false);
  };

  const handleSwitchChange = (checked: boolean) => {
    setIsFeaturedProduct(checked);

    // Update landing page data with the new enabled state
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        isFeaturedProductEnable: checked,
      },
    }));

    // Clear feature product error when disabling the hero feature
    if (!checked) {
      clearFieldError('products.featuredProduct');
    }
  };

  const handleRemoveProduct = () => {
    // Clear local state
    setSelectedProduct(null);

    // Clear product from landing page data
    setLandingPageData((prev) => ({
      ...prev,
      featureProduct: null,
    }));
  };

  const handleHeadingChange = (value: string) => {
    setHeadingValue(value);
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        featuredProductHeading: convertTemplateToBackend(value),
      },
    }));
  };

  const handleShowRatingsChange = (checked: boolean) => {
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        showProductRating: checked,
      },
    }));
  };

  // const handleShowTagChange = (checked: boolean) => {
  //   setLandingPageData((prev) => ({
  //     ...prev,
  //     products: {
  //       ...prev.products,
  //       showTag: checked
  //     },
  //   }));
  // };

  return (
    <div className="flex flex-col items-start gap-4 px-4 w-full">
      <div className="flex items-center gap-2 w-full">
        <Switch id="featured-product" checked={isFeaturedProduct} onCheckedChange={handleSwitchChange} />
        <label htmlFor="featured-product" className="text-sm font-medium">
          Show Featured Product
        </label>
      </div>
      {isFeaturedProduct && (
        <>
          {selectedProduct?.id ? (
            <Card className="p-2 w-full">
              <CardContent className="p-0">
                <div className="flex items-center gap-3">
                  <div
                    className="w-[50px] h-[50px] rounded-lg bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${
                        selectedProduct.imageUrl || selectedProduct?.imgUrl || '/frame-427320058.png'
                      })`,
                    }}
                  />
                  <div className="flex items-center justify-between flex-1">
                    <h3 className="text-grey-6 text-[14px] font-medium leading-6">{selectedProduct.title}</h3>
                    <div className="flex items-center gap-1">
                      <Button variant="ghost" size="icon" className="h-8 w-8 rounded-lg">
                        <a href={selectedProduct.productUrl} target="_blank" rel="noreferrer" className="flex text-primary hover:text-grey-5">
                          <Eye className="h-4 w-4 text-neutral-color-palettegray-9" />
                        </a>
                      </Button>
                      <Button variant="ghost" size="icon" className="h-8 w-8 rounded-lg" onClick={handleRemoveProduct}>
                        <X className="h-4 w-4 text-neutral-color-palettegray-9" />
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <Card className="w-full border-2 border-dashed border-grey-2 bg-grey-0">
              <CardContent className="flex items-center justify-center p-4 gap-2">
                <Plus className="h-4 w-4 text-grey-5" />
                <span onClick={handleSelectProduct} className="text-sm font-medium cursor-pointer text-grey-5">
                  Select Product
                </span>
              </CardContent>
            </Card>
          )}
          <p className="text-xs text-[#5d6974]">
            Note: Only one featured product can be shared between the basic section and the product section. if you
            change it here, the product in basic section will also be updated.
            {/* Uncomment once we have link for redirect URL */}
            {/* <a href="#" className="underline text-[#16282d] ml-1">
              Learn more
            </a> */}
          </p>

          {/* TODO: Add back in when we have a way to show the product rating */}
          <div className="items-center gap-2 w-full hidden">
            <Checkbox
              id="show-ratings"
              checked={landingPageData.products.showProductRating}
              onCheckedChange={handleShowRatingsChange}
            />
            <label htmlFor="show-ratings" className="font-medium text-sm text-grey-6">
              Show Product Ratings
            </label>
          </div>

          {/* <div className="flex items-center gap-2 w-full">
            <Checkbox
              id="show-tag"
              checked={landingPageData.products.showTag}
              onCheckedChange={handleShowTagChange}
            />
            <label htmlFor="show-tag" className="font-medium text-sm text-grey-6">
              Show Tag
            </label>
          </div> */}
          <div className="flex flex-col items-start w-full gap-2">
            <Label htmlFor="section-heading" className="text-grey-5 text-xs font-medium leading-5">
              Featured Product Heading
            </Label>
            <VariableInput
              id="section-heading"
              value={headingValue}
              onChange={handleHeadingChange}
              variables={VARIABLE_OPTIONS}
              placeholder="Enter section heading"
              tooltipText="Insert variable"
            />
          </div>
        </>
      )}
      {isFindAndImportOpen && isLocalModalOpen && (
        <ApolloProvider client={pfaApolloClient}>
          <FindAndImportProvider>
            <ExportProductModal
              setSelectedProduct={setSelectedProduct}
              onClose={handleCloseModal}
            />
          </FindAndImportProvider>
        </ApolloProvider>
      )}
    </div>
  );
};

export default FeaturedProductContent;
