import gql from 'graphql-tag';

export const GET_DASHBOARD_STATS_QUERY = gql`

  query GetDashboardStatistics($startDate: DateTime, $endDate: DateTime, $offerType: STATS_OFFER_TYPE, $selectedFilters: [OFFER_STATUS_TYPE!]) {
    summary: advertiserStatSummary(startDate: $startDate, endDate: $endDate, statsOfferType: $offerType, selectedFilters: $selectedFilters) {
      avgSale
      clicks
      conversions
      members
      offers
      payoutEarned
      payoutMade
      sales
    }
  }
`;
