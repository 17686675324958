import React, { useEffect } from 'react';
import { useTaskTrackerQuery } from '@frontend/app/hooks';
import { Alert, AlertDescription, AlertTitle } from '@frontend/shadcn/components/ui/alert';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { Progress } from '@frontend/shadcn/components/ui/progress';

const POLLING_INTERVAL = 2000;

interface ProgressAlertProps {
  percentage: number
}
const ProgressAlert: React.FC<ProgressAlertProps> = ({ percentage }) => {
  return (
    <Alert className="bg-blue-1 border-blue-3 rounded-2xl">
      <CircleInfoIcon className="!text-blue-5" />
      <AlertTitle className="text-blue-6 font-medium">Adding members to program...</AlertTitle>
      <AlertDescription className="mt-2">
        <Progress value={percentage} className="h-2 bg-blue-2" indicatorClassName="bg-blue-5" />
        <span className="text-sm text-blue-5 mt-1 inline-block">{percentage}% complete</span>
      </AlertDescription>
    </Alert>
  );
};

interface AddMembersProgressProps {
  taskTrackerId: string;
  onSyncStatusChange?: (isSyncing: boolean) => void;
}
export const AddMembersProgress: React.FC<AddMembersProgressProps> = ({ taskTrackerId, onSyncStatusChange }) => {
  const { data: taskData, startPolling, stopPolling } = useTaskTrackerQuery({
    variables: { id: taskTrackerId },
    skip: !taskTrackerId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const progressPercentage = taskData?.tracker.taskProgress.progressPercentage;

    if (progressPercentage != null && progressPercentage !== 100) {
      startPolling(POLLING_INTERVAL);
      onSyncStatusChange?.(true);
    } else if (progressPercentage === 100) {
      stopPolling();
      onSyncStatusChange?.(false);
    }
  }, [taskData, onSyncStatusChange, startPolling, stopPolling]);

  return (
    <div className="px-2 pb-4">
      <ProgressAlert
        percentage={Math.floor(taskData?.tracker.taskProgress.progressPercentage || 0)}
      />
    </div>
  );
};
