/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3bjuo_157 {
  display: flex;
}

._justify-content-space-between_3bjuo_161 {
  justify-content: space-between;
}

._tabular-nums_3bjuo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_3bjuo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3bjuo_157 {
  display: flex;
}

._justify-content-space-between_3bjuo_161 {
  justify-content: space-between;
}

._tabular-nums_3bjuo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_3bjuo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SelectOffers_3bjuo_355 ._nextButton_3bjuo_355 {
  border-color: #167cf4;
  background-color: #167cf4;
}
._SelectOffers_3bjuo_355 ._nextButton_3bjuo_355:focus, ._SelectOffers_3bjuo_355 ._nextButton_3bjuo_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._SelectOffers_3bjuo_355 ._nextButton_3bjuo_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._SelectOffers_3bjuo_355 ._icon_3bjuo_368 {
  max-width: 1.875rem;
}