import { FormInstance } from 'antd';
import React, { useMemo, useState } from 'react';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { AccordionContent, AccordionTrigger, AccordionItem, Accordion } from '@frontend/shadcn/components/ui/accordion';
import { H4 } from '@frontend/shadcn/components/typography/h4';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import {
  IShopifyPromoCodeFormOptions,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  TDisabledMap,
  FormAsyncAction,
  PROMO_OFFER_TYPES,
} from '../../../../../types';
import { RefreshNewOfferActiveDate } from '../../../FormGroup';
import RefreshNamingStrategy from './NamingStrategy/RefreshNamingStrategy';
import RefreshEnableMultipleShopify from './MultipleShopify/RefreshEnableMultipleShopify';
import RefreshLinksForPromos from './SharableLinkAndSecureCode/RefreshLinksForPromos';
import PurchaseRestriction from './PurchaseRestrictions/PurchaseRestrictions';

interface IProps {
  disabled: TDisabledMap<IShopifyPromoCodeFormValues>;
  values: IShopifyPromoCodeFormValues;
  formOptionData: IShopifyPromoCodeFormOptions;
  isSubscriptionEnable?: boolean;
  mode: OfferFormModes;
  formRef: FormInstance;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[];
  connectedClients: GetConnectedShopify_clientConnections[];
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  hasMembers?: boolean;
  shopifyStoreName?: string;
  isOfferPromoLink?: boolean;
  isLandingPageEnabled?: boolean;
  saveStatus: string;
}

// accept offer_spurce as a prop
export const AdditionalSettingsShopify = ({
  disabled,
  values,
  formOptionData,
  isSubscriptionEnable,
  mode,
  formRef,
  connectedAdvertiserForSync,
  connectedClients,
  handleFormAsyncActions,
  hasMembers,
  shopifyStoreName,
  isOfferPromoLink,
  isLandingPageEnabled,
  saveStatus,
}: IProps) => {
  const { linkShopifyOffer, enableMultipleShopify: isEnabledMultipleShopify } = useClientFeatures();
  const isLinkedShopifyOfferSelected = linkShopifyOffer && values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER;
  const [offerEndDate, setOfferEndDate] = useState(values.endDate !== null);
  const dateErrorMsg =
    values.endDate === null
      ? ''
      : values.activeDate.isSameOrAfter(values.endDate)
        ? 'Start date must be before end date'
        : '';

  const isAtLeastOneConnected = connectedClients?.some((item) => item.isPrimary === false && item.isConnected === true);
  const connectedStore: Set<string> = new Set<string>();
  const connectedSubscriptionEnabled = useMemo(() => {
    if (values.isMultipleShopifySyncEnabled && connectedClients?.length) {
      const connectedAdvertiserIds = values.clientsForSync.map((obj) => obj.advertiserId);

      // check for primary store subscription eligibility
      const primaryStore = connectedClients.find((conn) => conn.isPrimary === true);
      if (primaryStore && !primaryStore.isSubscriptionEligible) {
        connectedStore.add(primaryStore.label);
      }

      // First collect all ineligible connections
      connectedAdvertiserIds.forEach((advertiserId) => {
        const connection = connectedClients.find((conn) => conn.connectedAdvertiserId === advertiserId);
        if (connection && !connection.isSubscriptionEligible) {
          connectedStore.add(connection.label);
        }
      });

      // Then check if all are eligible
      const allEligible = connectedAdvertiserIds.every((advertiserId) => {
        const connection = connectedClients.find((conn) => conn.connectedAdvertiserId === advertiserId);
        return connection ? connection.isSubscriptionEligible : false;
      });

      return allEligible && isSubscriptionEnable;
    } else {
      return isSubscriptionEnable;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    connectedClients,
    values.isMultipleShopifySyncEnabled,
    values.clientsForSync.length,
    isSubscriptionEnable,
    connectedStore,
  ]);

  return (
    <div className="space-y-2">
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1" className="border-0 m-0">
          <AccordionTrigger className="hover:no-underline p-0 m-0">
            <H4 className="mb-0">Additional Settings  </H4>
          </AccordionTrigger>
          <AccordionContent>
            <Separator className="my-2" />
            <RefreshLinksForPromos
              formValues={values}
              handleFormAsyncActions={handleFormAsyncActions}
              shopifyStoreName={shopifyStoreName}
              hasMembers={hasMembers}
              isOfferPromoLink={isOfferPromoLink}
              mode={mode}
              isLandingPageEnabled={isLandingPageEnabled}
              saveStatus={saveStatus}
            />

            {!isLinkedShopifyOfferSelected && (<PurchaseRestriction
              connectedSubscriptionEnabled={connectedSubscriptionEnabled}
              connectedStore={connectedStore}
              collections={formOptionData.productCollectionOptions}
              handleFormAsyncActions={handleFormAsyncActions}
              values={values}
              disabled={disabled}
            />)}
            {!isLinkedShopifyOfferSelected && (
              <RefreshNewOfferActiveDate
                values={values}
                dateErrorMsg={dateErrorMsg}
                offerEndDate={offerEndDate}
                setOfferEndDate={setOfferEndDate}
                handleFormAsyncActions={handleFormAsyncActions}
              />
            )}
            <RefreshNamingStrategy values={values} disabled={disabled} mode={mode} />

            {isEnabledMultipleShopify && isAtLeastOneConnected && (
              <RefreshEnableMultipleShopify
                values={values}
                isLinkedShopifyOfferSelected={isLinkedShopifyOfferSelected}
                handleFormAsyncActions={handleFormAsyncActions}
                mode={mode}
                formRef={formRef}
                connectedAdvertiserForSync={connectedAdvertiserForSync}
                hasMembers={hasMembers}
              />
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
