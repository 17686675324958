import React from 'react';
import { networkOptions } from '../../filters-form';

interface UseNetworkBadges {
  networks: string[];
}

export const useNetworkBadges = ({ networks }: UseNetworkBadges) => {
  const networkBadges = React.useMemo(() => networkOptions.filter((network) => networks.includes(network.value)), [
    networks,
  ]);

  return {
    networkBadgesProps: {
      data: networkBadges,
    }
  };
};
