import * as React from 'react';
import { isNumber, map } from 'lodash';

import {
  SearchIcon,
  SendOfferIcon,
  ThumbsUpIcon,
  FavoriteBorderIcon,
  RefreshIcon,
  ViewIcon,
  GroupIcon,
  ClickIcon,
  UniqueImpressions,
  EstImpressionsLabel,
} from '@components';
import { largeNumberAbbreviator } from '@frontend/utils';
import { IPostCardSocialPost } from './PostCard';

import styles from './EngageList.scss';

interface IProps {
  post: IPostCardSocialPost;
  tempYoutubeDemoAccountMode?: boolean;
}

interface IDisplayStat {
  key: string;
  statValue: string;
}

const showEstimatedImpressions = (post: IPostCardSocialPost) => {
  const estimatedImpressions = Number(post.estimated_impressions);
  const hasEstimatedImpressions = isNumber(estimatedImpressions) && estimatedImpressions > 0;
  const impressions = Number(post.impressions);
  const hasImpressions = isNumber(impressions) && impressions > 0;
  // has impressions, hide estimative
  if (hasImpressions) {
    return false;
  }
  // has estimations
  if (hasEstimatedImpressions) {
    return true;
  }
  // have neither, hide estimative
  if ((!hasImpressions && !hasEstimatedImpressions)) {
    return false;
  }
};

const EngageList: React.FunctionComponent<IProps> = React.memo((props) => {
  const { post, tempYoutubeDemoAccountMode } = props;
  const showEstImpressions = showEstimatedImpressions(post);

  const STATS_TO_DISPLAY = {
    instagram: ['likes', 'comments', 'impressions'],
    instagram_reel: ['views', 'likes', 'comments'],
    instagram_story: ['impressions', 'unique_impressions', 'estimated_impressions'],
    instagram_video: ['views', 'likes', 'comments'],
    youtube_mention: ['views', 'likes'],
    youtube_dedicated: ['views', 'likes'],
    youtube_short: ['views', 'likes'],
    blog_mention: ['reach'],
    blog_dedicated: ['reach'],
    twitter_post: ['reach', 'likes', 'shares'],
    facebook_post: ['reach', 'likes', 'comments'],
    pinterest: ['reach', 'favorites', 'closeups', 'clicks'],
    tiktok_video: ['views', 'likes', 'comments', 'shares'],
  };

  const statsToDisplayForPost = STATS_TO_DISPLAY[post.post_type];
  const stats: IDisplayStat[] = map(statsToDisplayForPost, (stat) => ({
    key: stat,
    statValue: post[stat],
  })).filter((stat) => {
    if (showEstImpressions && stat.key === 'impressions') {
      return false;
    }
    if (!showEstImpressions && stat.key === 'estimated_impressions') {
      return false;
    }
    return true;
  });

  const shouldShowStat = function (stat) {
    return !isNaN(parseInt(stat.statValue, 10));
  };
  const hideIcons = tempYoutubeDemoAccountMode && ['youtube_mention', 'youtube_dedicated', 'youtube_short'].includes(post.post_type);

  return (
    <ul className={styles.engageList}>
      {map(stats, (stat, index) => (shouldShowStat(stat) && (
        <li key={index} className={styles.engageItem}>
          {stat.key === 'comments' && <SendOfferIcon />}
          {!hideIcons && stat.key === 'likes'
            && (post.post_type !== 'facebook_post' ? (
              <FavoriteBorderIcon />
            ) : (
              <ThumbsUpIcon />
            ))}
          {stat.key === 'favorites' && <FavoriteBorderIcon />}
          {(stat.key === 'reposts' || stat.key === 'shares') && <RefreshIcon />}
          {!hideIcons && stat.key === 'views' && <ViewIcon />}
          {stat.key === 'impressions' && <ViewIcon />}
          {stat.key === 'estimated_impressions' && <ViewIcon />}
          {stat.key === 'clicks' && <ClickIcon />}
          {stat.key === 'reach' && <GroupIcon />}
          {stat.key === 'closeups' && <SearchIcon />}
          {stat.key === 'unique_impressions' && <UniqueImpressions />}
          <div className={styles.engageAmount}>
            {largeNumberAbbreviator(stat.statValue)}
          </div>
          {hideIcons && (
            <div className={styles.engageAmount}>
              {stat.key}
            </div>
          )}
          {stat.key === 'estimated_impressions' && <EstImpressionsLabel onlyIcon iconSize={12} />}
        </li>
      )))}
    </ul>
  );
});

EngageList.displayName = 'EngageList';

export default EngageList;
