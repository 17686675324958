import gql from 'graphql-tag';

import { TABLE_CONFIGURATION_FRAGMENT } from './fragments'; // Import fragments if necessary

export const SAVE_TABLE_CONFIGURATION = gql`
  mutation SaveTableConfigurationMutation($data: TableConfigurationInput!) {
    tableConfiguration: saveTableConfiguration(data: $data) {
      ...TableConfigurationFragment
    }
  }
  ${TABLE_CONFIGURATION_FRAGMENT}
`;
