import React from 'react';
import { useFragment, graphql, FragmentType } from '@frontend/gql/social';
import { PercentChangeBadge } from '../../../../components/percent-change-badge';
import { formatNumber } from '../../../../utils/formatNumber';
import { CardStat, CardStatLabel, CardStatRow } from '../../../../components/card-stat';

const currentIntervalFragment = graphql(`
  fragment Members_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalCreators
  }
`);

const previousIntervalFragment = graphql(`
  fragment Members_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalCreators
  }
`);

type MembersProps = {
  currentInterval: FragmentType<typeof currentIntervalFragment> | null | undefined;
  previousInterval: FragmentType<typeof previousIntervalFragment> | null | undefined;
};

export const Members = ({ currentInterval, previousInterval }: MembersProps) => {
  const currentIntervalData = useFragment(currentIntervalFragment, currentInterval);
  const previousIntervalData = useFragment(previousIntervalFragment, previousInterval);

  const currentMembers = currentIntervalData?.totalCreators ?? 0;
  const previousMembers = previousIntervalData?.totalCreators ?? 0;
  const percentChange = previousMembers && currentMembers ? ((currentMembers - previousMembers) / previousMembers) * 100 : 'NA';

  return (
    <div className="col-span-1 items-center">
      <CardStatLabel>Members</CardStatLabel>
      <CardStatRow>
        <CardStat className="text-xl leading-none">{formatNumber(currentMembers)}</CardStat>
        <PercentChangeBadge percent={percentChange} />
      </CardStatRow>
    </div>
  );
};
