import * as React from 'react';
import { OFFER_TRACKING_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { OfferInputHeader } from '../CommonSection/FormElements/RefreshForm/OfferElementHeader';
import { RefreshOfferPayoutOptions } from '../CommonSection/FormElements/RefreshForm/RefreshOfferPayoutOptions';
import { IRefreshPayoutProps } from '../../utils/OfferFormUtils';
import { RefreshOfferConversionType } from './FormElements/RefreshForm/RefreshOfferConversionType';
import { RefreshOfferConversionTracking } from './FormElements/RefreshForm/RefreshOfferConversionTracking';
import { OfferFormToolTips } from '../../../OfferFormToolTip';
import { RefreshOfferUrl } from './FormElements/RefreshForm/RefreshOfferUrl';
import { RefreshOfferUrlThirdPartySelection } from './FormElements/RefreshForm/RefreshOfferUrlThirdPartySelection';

export const RefreshAffiliateLinkSection: React.FC<Readonly<IRefreshPayoutProps>> = (props) => {
  const { affiliateLinkData, disabledAffiliatesLinks } = props;
  const conversionTrackingTypeEvents = React.useMemo(
    () => ({
      onFocus: () => props.onFieldFocused(OfferFormToolTips.conversionTrackingInfo, true),
      onBlur: () => props.onFieldFocused(null, true),
      onMouseEnter: () => props.onFieldFocused(OfferFormToolTips.conversionTrackingInfo, false),
      onMouseLeave: () => props.onFieldFocused(OfferFormToolTips.conversionTrackingInfo, false),
    }),
    [props],
  );
  const urlEvents = React.useMemo(
    () => ({
      onFocus: () => props.onFieldFocused(OfferFormToolTips.urlInfo, true),
      onBlur: () => props.onFieldFocused(null, true),
      onMouseEnter: () => props.onFieldFocused(OfferFormToolTips.urlInfo, false),
      onMouseLeave: () => props.onFieldFocused(null, false),
    }),
    [props],
  );
  const isPostBack = affiliateLinkData.conversionTrackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID
    || affiliateLinkData.conversionTrackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID;
  return (
    <>
      <div>

        <div className="flex justify-between">
          <div className="w-[70%] max-w-[494px]">
            <RefreshOfferUrl
              charCount={props.url?.length || 0}
              conversionTrackingType={affiliateLinkData.conversionTrackingType}
              validateSameDomain={disabledAffiliatesLinks.conversionTrackingType}
              validateSameParam={disabledAffiliatesLinks.conversionTrackingType && isPostBack}
              currentUrl={props.initialUrl}
              disabled={disabledAffiliatesLinks.url}
              events={urlEvents}
              value={affiliateLinkData.url}
              name="url"
            />
          </div>
          <div className="w-[30%] max-w-[190px]">
            <RefreshOfferConversionType
              disabled={disabledAffiliatesLinks.conversionType}
              name="conversionType"
              handleFormAsyncActions={props.handleFormAsyncActions}
              value={affiliateLinkData.conversionType}
            />
          </div>

        </div>

        <RefreshOfferConversionTracking
          disabled={disabledAffiliatesLinks.conversionTrackingType}
          events={conversionTrackingTypeEvents}
          name="conversionTrackingType"
          handleFormAsyncActions={props.handleFormAsyncActions}
          value={affiliateLinkData.conversionTrackingType}
        />
        <RefreshOfferUrlThirdPartySelection values={affiliateLinkData} handleFormAsyncActions={props.handleFormAsyncActions} />
      </div>
      <div className="flex justify-between">
        <OfferInputHeader text="Payouts" />
      </div>
      <RefreshOfferPayoutOptions
        {...props}
      />
    </>
  );
};
