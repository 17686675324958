import { Label } from '@frontend/shadcn/components/ui/label';
// import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { Input } from '@frontend/shadcn/components/ui/input';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
// import { AlignLeftIcon, AlignCenterIcon, AlignRightIcon } from 'lucide-react';
import React from 'react';

// const alignmentOptions = [
//   { value: 'left', label: 'Left', icon: AlignLeftIcon },
//   { value: 'center', label: 'Center', icon: AlignCenterIcon },
//   { value: 'right', label: 'Right', icon: AlignRightIcon },
// ];

const defaultProductCardSettings = {
  textAlignment: 'left',
  cornerRadius: 8, // numeric default
};

export default function ProductCard(): JSX.Element {
  const { landingPageData, setLandingPageData } = useLandingPageContext();

  // const handleAlignmentChange = (value: string) => {
  //   setLandingPageData((prev) => ({
  //     ...prev,
  //     style: {
  //       ...prev.style,
  //       productCard: {
  //         ...(prev.style?.productCard || defaultProductCardSettings),
  //         textAlignment: value,
  //       },
  //     },
  //   }));
  // };

  const handleCornerRadiusChange = (value: string) => {
    const numValue = parseFloat(value);
    const processedValue = Number.isNaN(numValue) ? defaultProductCardSettings.cornerRadius : numValue;

    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        productCard: {
          ...(prev.style?.productCard || defaultProductCardSettings),
          cornerRadius: processedValue,
        },
      },
    }));
  };

  // Safe access to product card settings with fallback
  const getProductCardSetting = (key: string) => landingPageData.style?.productCard?.[key] ?? defaultProductCardSettings[key];

  return (
    <div className="flex flex-col gap-4 px-4">
      {/* <div className="flex flex-col gap-2">
        <Label className="text-neutralgrey-5 text-xs font-medium">
          Text Alignment
        </Label>
        <ToggleGroup
          type="single"
          value={getProductCardSetting('textAlignment')}
          onValueChange={handleAlignmentChange}
          className="flex items-center justify-start gap-1 bg-neutralgrey-0 p-1 rounded-lg border border-neutralgrey-2 max-w-[200px]"
        >
          {alignmentOptions.map((option) => (
            <ToggleGroupItem
              key={option.value}
              value={option.value}
              aria-label={option.label}
              className="flex-1 h-8 data-[state=on]:bg-gray-100 data-[state=on]:shadow-sm hover:bg-neutralgrey-1 rounded"
            >
              <option.icon className="h-4 w-4 mx-auto" />
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
      </div> */}

      <div className="flex flex-col gap-2">
        <Label className="text-grey-5 text-xs font-medium">
          Corner Radius
        </Label>
        <div className="flex items-center bg-grey-0 rounded-lg border border-grey-2">
          <Input
            type="number"
            min={0}
            value={getProductCardSetting('cornerRadius')}
            onChange={(e) => handleCornerRadiusChange(e.target.value)}
            className="border-0 text-grey-6 focus:ring-0 focus:outline-none focus-visible:ring-0"
          />
          <span className="pr-3 text-grey-4 text-xs">px</span>
        </div>
      </div>
    </div>
  );
}
