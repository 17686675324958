import React from 'react';
import { Segment } from '../../../../../../gql/social/graphql';

interface UseSegmentBadgeProps {
  segments?: Segment[] | null;
}

export const useSegmentBadge = ({ segments }: UseSegmentBadgeProps) => {
  const segmentBadge = React.useMemo(() => {
    return {
      segments,
    };
  }, [segments]);

  return {
    segmentBadgeProps: segmentBadge,
  };
};
