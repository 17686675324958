import * as React from 'react';
import { Button, Popover, Typography } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './SocialPostTag.scss';

interface ISocialPostTag {
  clientId: string;
  memberId: number;
  appId: string;
  postType: string;
  isFailed?: boolean;
  isInsightsAuthed: boolean;
  programNames?: string[];
  communityNames?: string[];
  onUnassignPost?: () => void;
  onUnassignGroup?: () => void;
}

export const SocialPostTag: React.FC<Readonly<ISocialPostTag>> = ({
  clientId,
  memberId,
  appId,
  postType,
  isFailed,
  isInsightsAuthed,
  programNames,
  communityNames,
  onUnassignPost,
  onUnassignGroup,
}) => {
  const authRedirect = `/client/${clientId}/members/${memberId}/app/${appId}/auth`;

  const isInstagramStory = postType === 'instagram_story';

  const showIgStoryNeedsAuth = isInstagramStory && isFailed;
  const igStoryNeedsAuthExplanation = (
    <div className={styles.subBody}>
      This user will need to authenticate their Instagram account in order to save the image preview for this post in Aspire.
      {' '}
      <Typography.Link
        href={authRedirect}
        rel="noopener noreferrer"
      >
        Ask for Authentication
      </Typography.Link>
    </div>
  );

  const showIgStoryNeedsBackup = isInstagramStory && !isInsightsAuthed && !isFailed;
  const igStoryNeedsBackupExplanation = (
    <div className={styles.subBody}>
      Instagram stories must be authed within the 24 hour period they are live or we cannot save the image preview.
    </div>
  );

  const postTypeDisplayName = {
    instagram: 'Instagram Post',
    instagram_video: 'Instagram Post',
    instagram_reel: 'Instagram Reel',
    instagram_story: 'Instagram Story',
    twitter_post: 'Twitter Post',
    facebook: 'Facebook Post',
    pinterest: 'Pinterest Post',
    youtube_mention: 'YouTube Video',
    youtube_dedicated: 'YouTube Video',
    youtube_short: 'YouTube Short',
    tiktok_video: 'TikTok Post',
    blog: 'Blog Post',
  }[postType];

  const programUnassigned = programNames && programNames.length > 0;
  const groupUnassigned = communityNames && communityNames.length > 0;

  const popoverInfo = (
    <div className={styles.popover}>
      <div className={styles.grouping}>
        <div className={styles.title}>
          Project
        </div>
        <div className={styles.body}>
          {programUnassigned ? programNames.join(', ') : 'Unassigned'}
        </div>
        {programUnassigned && <button onClick={onUnassignPost} className={styles.unassign}>Unassign</button>}
      </div>
      <div className={styles.grouping}>
        <div className={styles.title}>
          Group
        </div>
        <div className={styles.body}>
          {groupUnassigned ? communityNames.join(', ') : 'Unassigned'}
        </div>
        {groupUnassigned && <button onClick={onUnassignGroup} className={styles.unassign}>Unassign</button>}
      </div>
      <div className={styles.grouping}>
        <div className={styles.title}>
          Type
        </div>
        <div className={styles.body}>
          {postTypeDisplayName}
        </div>
        {showIgStoryNeedsAuth && (igStoryNeedsBackupExplanation)}
        {showIgStoryNeedsBackup && (igStoryNeedsAuthExplanation)}
      </div>
    </div>
  );

  const danger = isInstagramStory && !isInsightsAuthed && !isFailed;

  return (
    <>
      <Popover
        content={popoverInfo}
        placement="rightTop"
      >
        <Button
          danger={danger}
          size="small"
          icon={<CircleInfoIcon />}
        />
      </Popover>
    </>
  );
};
