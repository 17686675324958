import React from 'react';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { useHistory } from 'react-router-dom';
import { TProject } from '../types';
import { CampaignTypeSection } from './sections/CampaignTypeSection';
import { WorkflowStagesSection } from './sections/WorkflowStagesSection';
import { AboutSection } from './sections/AboutSection';
import { GoalsSection } from './sections/GoalsSection';
import { CreatorsSection } from './sections/CreatorsSection';
import { ContentSection } from './sections/ContentSection';
import { CompensationSection } from './sections/CompensationSection';
import { useCampaign } from './CampaignContext';
import { SaveCampaignCTA } from './components/SaveCampaignCTA';

interface Props {
  mode?: 'add' | 'edit';
  project?: TProject;
  onSave?: () => void;
  refetchProjects?: () => void;
}

export const CampaignForm: React.FC<Props> = ({
  mode = 'add',
  project,
  onSave,
  refetchProjects,
}) => {
  const { templateName, worklets } = useCampaign();

  const history = useHistory();

  return (
    <>
      <AppHeader
        title="New Campaign"
        backButtonFunction={history.goBack}
      />
      <div className="flex flex-col gap-6 p-6 bg-gray-50 h-screen w-full max-w-[800px] mx-auto">
        {/* Header Section */}
        <SaveCampaignCTA
          mode={mode}
          project={project}
          onSave={onSave}
          refetchProjects={refetchProjects}
        />

        {/* Campaign Type Section */}
        <CampaignTypeSection />

        {/* Stages Section */}
        {(templateName || worklets?.length) && <WorkflowStagesSection defaultWorklets={worklets?.length ? worklets : undefined} />}

        {/* About Section */}
        <AboutSection />

        {/* Goals Section */}
        <GoalsSection />

        {/* Creators Section */}
        <CreatorsSection />

        {/* Content Section */}
        <ContentSection />

        {/* Compensation Section */}
        <CompensationSection />
      </div>
    </>
  );
};
