import * as React from 'react';
import { ClientFeature } from '@frontend/app/constants';

interface IClientFeatureContext {
  [key: string]: boolean;
}

interface ClientFeatureResponse {
  id: number;
  name: string;
  state: string;
}

const defaultContext: IClientFeatureContext = Object.fromEntries(
  Object.values(ClientFeature).map((feature) => [feature, false]),
);

const ClientFeatureContext = React.createContext<IClientFeatureContext>(defaultContext);

interface IClientFeatureProviderProps {
  clientFeatures: ClientFeatureResponse[];
  children: React.ReactNode;
}

export function ClientFeatureProvider({ clientFeatures, children }: IClientFeatureProviderProps) {
  const processedFeatures = React.useMemo(() => {
    // Initialize all features to false
    const flags = Object.values(ClientFeature).reduce(
      (acc, feature) => ({
        ...acc,
        [feature]: false,
      }),
      {},
    );

    // Ensure the state is saved as a boolean
    clientFeatures?.forEach((feature) => {
      flags[feature.name] = feature.state === 'true';
    });

    return flags;
  }, [clientFeatures]);

  return (
    <ClientFeatureContext.Provider value={processedFeatures}>
      {children}
    </ClientFeatureContext.Provider>
  );
}

export function useClientFeatures() {
  const context = React.useContext(ClientFeatureContext);
  if (!context) {
    throw new Error('useClientFeatures must be used within a ClientFeatureProvider');
  }
  return context;
}
