import React from 'react';
import { Card, Col, Row } from '@revfluence/fresh';
import { LoadSpinner } from '@frontend/app/components';
import { OFFER_PRICE_RULE_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { LandingPageHeroSection } from './LandingPageHeroSection';
import {
  HeroSectionObject, FeaturedProductObject, AllProductObject, DEVICE_TYPE, getCurrencySymbol,
} from '../../utils/LandingPagePreviewUtils';
import { useLandingPageContext } from '../../context/LandingPageContext';
import { LandingPageFeaturesProductSection } from './LandingPageFeaturedProductSection';
import { LandingPagePreviewAllProductSection } from './LandingPagePreviewAllProductSection';
import './LandingPreview.scss';

interface LandingPagePreviewProps {
  DeviceType: DEVICE_TYPE;
}
export const LandingPagePreview = ({ DeviceType }: LandingPagePreviewProps) => {
  const { landingPageData } = useLandingPageContext();
  if (!landingPageData) {
    return (
      <Row align="middle" justify="center">
        <Col>
          <LoadSpinner />
          ;
        </Col>
      </Row>
    );
  }
  const landingPageStyles = landingPageData.style || undefined;
  const heroSectionObject: HeroSectionObject = {
    creatorTag: landingPageData.basic?.creatorsTag,
    offerTitle: landingPageData.basic?.title || '',
    offerDescription: landingPageData.basic?.description || '',
    showProductRating: landingPageData.basic.featuredProductDiscount?.showProductRating || false,
    heroImage: landingPageData?.featureProduct?.imageUrl || '',
    heroProductName: landingPageData?.featureProduct?.title || '',
    heroProductDesc: landingPageData?.featureProduct?.description || '',
    heroProductRating: landingPageData?.featureProduct?.rating?.toString() || '0',
    heroProductReviews: landingPageData?.featureProduct?.reviewCount?.toString() || '0',
    heroProductPrice: landingPageData?.featureProduct?.price?.toString() || '',
    heroProductDiscountedPrice: landingPageData?.featureProduct?.discountedPrice?.toString() || '',
    heroProductUrl: landingPageData?.featureProduct?.productUrl || '',
    discountButtonText: landingPageData.basic?.discountOfferButton?.title || '',
    offerDiscount: `${landingPageData.discountType === OFFER_PRICE_RULE_TYPE.AMOUNT ? getCurrencySymbol(landingPageData.currency) : ''}${landingPageData.offerDiscount || '0'}${landingPageData.discountType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '%' : ''}`,
    discountButtonLink: 'https://example.com',
    carouselData: landingPageData?.featuredContent?.urls?.map((item) => ({ src: item.src, type: item.type })) || [],
    creatorName: 'JOHN DOE',
    isFeaturedProductEnable: landingPageData.basic.featuredProductDiscount.addHeroFeatureProduct || false,
    isFeaturedContentEnabled: landingPageData.featuredContent?.isFeaturedContentEnabled || false,
    backgroundColor: landingPageStyles?.backgroundSetting.heroSectionBackgroundColor || '#FFFFFF',
    landingPageStyles,
    currency: getCurrencySymbol(landingPageData.currency),
    heroSectionBackgroundImage: DeviceType === DEVICE_TYPE.DESKTOP ? landingPageStyles?.backgroundSetting.heroSectionBackgroundImageUrl?.desktop : landingPageStyles?.backgroundSetting.heroSectionBackgroundImageUrl?.mobile,
  };
  const FeaturedProductObject: FeaturedProductObject = {
    featuredProductTitle: landingPageData.products?.featuredProductHeading || '',
    showProductRating: landingPageData.products?.showProductRating || false,
    featureProductName: landingPageData?.featureProduct?.title || '',
    featureProductDesc: landingPageData?.featureProduct?.description || '',
    featureProductImage: landingPageData?.featureProduct?.imageUrl || '',
    featureProductRating: landingPageData?.featureProduct?.rating?.toString() || '0',
    featureProductReviews: landingPageData?.featureProduct?.reviewCount?.toString() || '0',
    featureProductPrice: landingPageData?.featureProduct?.price?.toString() || '',
    heroProductDiscountedPrice: landingPageData?.featureProduct?.discountedPrice?.toString() || '',
    discountButtonText: landingPageData.basic?.discountOfferButton?.title || '',
    offerDiscount: `${landingPageData.discountType === OFFER_PRICE_RULE_TYPE.AMOUNT ? getCurrencySymbol(landingPageData.currency) : ''}${landingPageData.offerDiscount || '0'}${landingPageData.discountType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '%' : ''}`,
    discountButtonLink: '',
    creatorName: 'JOHN DOE',
    landingPageStyles,
    currency: getCurrencySymbol(landingPageData.currency),
    heroProductUrl: landingPageData?.featureProduct?.productUrl || '',
  };
  const AllProductObject: AllProductObject = {
    title: landingPageData.products?.setting?.sectionHeading || '',
    products: landingPageData?.products?.allProducts?.products || [],
    showProductRating: landingPageData.products?.allProducts.style.showProductRating || false,
    showProductPrice: landingPageData.products?.allProducts.style.showProductPrice || false,
    landingPageStyles,
    currency: getCurrencySymbol(landingPageData.currency),
    offerDiscount: landingPageData.offerDiscount || '0',
    discountType: landingPageData.discountType || OFFER_PRICE_RULE_TYPE.AMOUNT,
  };

  return (
    <Card className={`offer-landing-page-preview ${DeviceType === DEVICE_TYPE.MOBILE ? 'mobile-view' : ''}`} bodyStyle={{ padding: 0 }}>
      <div className="customer-s-landing" style={{ background: landingPageStyles?.backgroundSetting.pageBackgroundColor || '#FFFFFF' }}>
        {/* Hero section start */}
        <LandingPageHeroSection data={heroSectionObject} />
        {/* Hero section end */}

        {/* Featured products section start */}
        {(landingPageData.products?.isFeaturedProductEnable && landingPageData?.featureProduct?.title) && (<LandingPageFeaturesProductSection data={FeaturedProductObject} />)}
        {/* Featured products section end */}

        {/* All products section start */}
        <LandingPagePreviewAllProductSection data={AllProductObject} />
        {/* All products section end */}

      </div>
    </Card>
  );
};
