import React from 'react';
import {
 Button, Typography, Row, Col,
} from 'antd';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import styles from '../createAdditionalDeepLinks.scss';
import IndividualView from './IndividualView';
import BulkView from './BulkView';
import { DeepLinkType } from '../createAdditionalDeepLinks';
import { TMemberTableRow } from '../../../types';

interface IProps {
  selectedMembers?: readonly TMemberTableRow[];
  setBulkDeepLink: (bulkDeepLink: DeepLinkType[]) => void;
  individualDeepLinks: DeepLinkType[];
  setIndividualDeepLinks: (individualDeepLinks: DeepLinkType[]) => void;
  isBulkCreate: boolean;
  setBulkCreate: (isBulkCreate: boolean) => void;
  offerData?: GetOfferById_offer;
}

const { Title, Text } = Typography;

const ManageDeepLinkViews: React.FC<IProps> = ({
  isBulkCreate,
  setBulkCreate,
  selectedMembers,
  setBulkDeepLink,
  individualDeepLinks,
  setIndividualDeepLinks,
  offerData,
}) => {
  const handleInputChange = (id: number, label: string, url: string) => {
    const updatedLinks = individualDeepLinks.map((link: DeepLinkType) => {
      if (link.affiliateOfferLinkId === id) {
        return {
          ...link,
          deepLinkLabel: label,
          deepLinkUrl: url,
        };
      }
      return link;
    });

    // If no existing link was found, add a new one
    if (!updatedLinks.some((link: DeepLinkType) => link.affiliateOfferLinkId === id)) {
      updatedLinks.push({
        affiliateOfferLinkId: id,
        deepLinkLabel: label,
        deepLinkUrl: url,
        isBrandCreated: true,
      });
    }

    // Now we assign the result to setIndividualDeepLinks
    setIndividualDeepLinks(updatedLinks);
  };

  const handleBulkInputChange = (deepLinkLabel: string, deepLinkUrl: string) => {
    if (!selectedMembers || selectedMembers.length === 0) return;

    const updatedBulkDeepLinks = selectedMembers.map((member) => ({
      affiliateOfferLinkId: member.selectedAffliate.affiliateOfferId,
      deepLinkLabel,
      deepLinkUrl,
      isBrandCreated: true, // Default to true
    }));

    // Update the bulkDeepLink state with the array of deep links
    setBulkDeepLink(updatedBulkDeepLinks);
  };

  return (
    <div className={styles.createLinkContainer}>
      <Title level={5}>Create Additional Deep Links</Title>
      <Text>Create additional deep links for selected members. Customize each link or use the same link for multiple members. </Text>
      <Row justify="space-between" align="middle" className="mt-4">
        <Col>
          <Text strong>
            {selectedMembers?.length ?? 0}
            {' '}
            {(selectedMembers?.length ?? 0) === 1 ? 'Member' : 'Members'}
            {' '}
            Selected
          </Text>
        </Col>
        <Col>
          <Button type="link" onClick={() => setBulkCreate(!isBulkCreate)}>
            {isBulkCreate ? 'Customize per member' : 'Switch to bulk creation'}
          </Button>
        </Col>
      </Row>
      {/* Render the IndividualView or BulkView based on the isBulkCreate state */}
      {selectedMembers && selectedMembers.length > 0 ? (
        <>
          {!isBulkCreate ? (
            <IndividualView
              selectedMembers={selectedMembers}
              deepLinks={individualDeepLinks}
              handleInputChange={handleInputChange}
              offerData={offerData}
            />
          ) : (
            <BulkView onInputChange={handleBulkInputChange} offerData={offerData} />
          )}
        </>
      ) : (
        <Text className="flex justify-center align-center m-2">No Members Selected</Text>
      )}
    </div>
  );
};

export default ManageDeepLinkViews;
