import * as React from 'react';

import { cn } from '@/shadcn/lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => (
  <input
    type={type}
    className={cn(
        'flex h-8 w-full rounded-lg border border-input hover:border-grey-4 bg-transparent px-3 py-1 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder-grey-3 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 shadow-none',
        className,
      )}
    ref={ref}
    {...props}
  />
  ));
Input.displayName = 'Input';

export { Input };
