import * as React from 'react';
import { useMemo, useEffect } from 'react';
import {
  Col,
  IRowProps,
  Row,
  Typography,
  TypographyProps,
} from '@revfluence/fresh';
import cx from 'classnames';
import {
  isEmpty,
  map,
} from 'lodash';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import { IHomePageSection } from '../types';

import styles from './GetStarted.scss';

interface IProps {
  groups: IHomePageSection;
  metrics: React.ReactNode;
  projects: IHomePageSection;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: Fix in Node upgrade typing bash!
  onClickNewProject: TypographyProps['onClick'];
  welcome: React.ReactNode;
  refreshUi: boolean;
}

interface ICard {
  props: {
    title?: string;
    [key: string]: string | number | boolean | null | undefined | React.ReactNode;
  };
}

const titleGutter: IRowProps['gutter'] = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const contentGutter: IRowProps['gutter'] = [{
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
}, {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
}];

const RESPONSIVE_CARD_SIZES = {
  groups: {
    lg: 4,
    md: 8,
    sm: 12,
    xs: 12,
  },
  projects: {
    lg: 8,
    md: 12,
    sm: 24,
    xs: 24,
  },
};

const HOME_PAGE_SECTIONS = ['projects', 'groups'] as const;

export const Layout = (props: IProps) => {
  const {
    groups,
    metrics,
    onClickNewProject,
    projects,
    welcome,
    refreshUi,
  } = props;

  const {
    searchText: projectSearchText,
    inputValue: projectInputValue,
    handleSearchChange: handleProjectSearchChange,
    error: projectError,
    checkNoResults: checkProjectNoResults,
    isLoading: isProjectSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'projects',
      metadata: {
        source: 'homepage_old_design',
      },
    },
  });

  const {
    searchText: groupSearchText,
    inputValue: groupInputValue,
    handleSearchChange: handleGroupSearchChange,
    error: groupError,
    checkNoResults: checkGroupNoResults,
    isLoading: isGroupSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'groups',
      metadata: {
        source: 'homepage_old_design',
      },
    },
  });

  const filteredProjectCards = useMemo(() => {
    if (!projectSearchText) {
      return projects.cards;
    }
    return projects.cards?.filter((card: ICard) =>
      card.props.title?.toLowerCase().includes(projectSearchText.toLowerCase()));
  }, [projects.cards, projectSearchText]);

  const filteredGroupCards = useMemo(() => {
    if (!groupSearchText) {
      return groups.cards;
    }
    return groups.cards?.filter((card: ICard) =>
      card.props.title?.toLowerCase().includes(groupSearchText.toLowerCase()));
  }, [groups.cards, groupSearchText]);

  useEffect(() => {
    checkProjectNoResults(filteredProjectCards?.length > 0);
  }, [filteredProjectCards?.length, checkProjectNoResults]);

  useEffect(() => {
    checkGroupNoResults(filteredGroupCards?.length > 0);
  }, [filteredGroupCards?.length, checkGroupNoResults]);

  const sectionContents = (
    sectionKey: 'projects' | 'groups',
    cards: IHomePageSection['cards'],
    error: IHomePageSection['error'],
    searchError?: string | null,
  ): React.ReactNode => {
    if (error) {
      return (
        <Col
          lg={{ span: 12, offset: 6 }}
          sm={{ span: 18, offset: 3 }}
          xs={24}
        >
          {error}
        </Col>
      );
    }

    if (searchError) {
      return (
        <Col xs={24}>
          <Typography.Paragraph>{searchError}</Typography.Paragraph>
        </Col>
      );
    }

    if (isEmpty(cards)) {
      if (sectionKey !== 'projects') {
        return null;
      }

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        <Col align="center" xs={24}>
          <Typography.Paragraph>
            No projects found.
            {' '}
            <Typography.Link
              href="projects/new/templates"
              onClick={onClickNewProject}
            >
              Create New Project
            </Typography.Link>
          </Typography.Paragraph>
        </Col>
      );
    }

    return map(cards, (card, index) => (
      <Col
        key={`${sectionKey}-card-${index + 1}`}
        lg={RESPONSIVE_CARD_SIZES[sectionKey].lg}
        md={RESPONSIVE_CARD_SIZES[sectionKey].md}
        sm={RESPONSIVE_CARD_SIZES[sectionKey].sm}
        xs={RESPONSIVE_CARD_SIZES[sectionKey].xs}
      >
        {card}
      </Col>
    ));
  };

  return (
    <div className={styles.Wrapper}>
      <div className={refreshUi ? 'bg-primary py-12 px-8' : cx(styles.MetricsSection, styles.spacing)}>
        {welcome}
        {metrics && (
          <Row className={styles.sectionContent}>
            <Col flex="auto">
              {metrics}
            </Col>
          </Row>
        )}
      </div>
      <div className={cx(styles.OtherSections, styles.spacing)}>
        {map(HOME_PAGE_SECTIONS, (sectionKey) => {
          let section;
          switch (sectionKey) {
            case 'groups':
              section = {
                ...groups,
                cards: filteredGroupCards,
              };
              break;
            case 'projects':
              section = {
                ...projects,
                cards: filteredProjectCards,
              };
              break;
            default:
              break;
          }

          const {
            actions,
            cards,
            error,
            title,
          } = section;

          return (
            <React.Fragment key={sectionKey}>
              <Row
                align="middle"
                className={styles.sectionHeader}
                gutter={titleGutter}
              >
                <Col flex="auto">
                  <Typography.Title className={styles.title} level={3}>
                    {title}
                  </Typography.Title>
                </Col>
                {sectionKey === 'projects' && (
                  <Col>
                    <div className={styles.searchContainer}>
                      <SearchInput
                        value={projectInputValue}
                        onChange={handleProjectSearchChange}
                        placeholder="Search projects..."
                        id="projects-search"
                        aria-invalid={!!projectError}
                        aria-errormessage={projectError ? 'projects-search-error' : undefined}
                        isLoading={isProjectSearchLoading}
                      />
                    </div>
                  </Col>
                )}
                {sectionKey === 'groups' && (
                  <Col>
                    <div className={styles.searchContainer}>
                      <SearchInput
                        value={groupInputValue}
                        onChange={handleGroupSearchChange}
                        placeholder="Search groups..."
                        id="groups-search"
                        aria-invalid={!!groupError}
                        aria-errormessage={groupError ? 'groups-search-error' : undefined}
                        isLoading={isGroupSearchLoading}
                      />
                    </div>
                  </Col>
                )}
                {map(actions, (action, index) => (
                  <Col key={`${sectionKey}-action-${index + 1}`}>
                    {action}
                  </Col>
                ))}
              </Row>
              <Row
                className={styles.sectionContent}
                gutter={contentGutter}
                wrap
              >
                {sectionContents(
                  sectionKey,
                  cards,
                  error,
                  sectionKey === 'projects' ? projectError : groupError,
                )}
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
