import * as React from 'react';
import {
  map,
  size,
  chain,
  upperFirst,
  filter,
  includes,
  forEach,
  isString,
  groupBy,
  isNumber,
  find,
  isFunction,
} from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import {
  Modal, Button, Select, List, Checkbox, Input,
} from '@revfluence/fresh';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import { SelectedColumnsInput } from '@frontend/app/types/globalTypes';
import { IApplication } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { IColumnVisibility } from '@frontend/app/types/Columns';
import { useFuzzySearchByKeys, useSaveSegmentColumns } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { MemberPageSegment as ISegment } from '@frontend/app/queries/fragments/types/MemberPageSegment';
import { IRenderParams, ISortableItem, SortableList } from '@frontend/app/components/SortableList/SortableList';
import { PredefinedSegmentsQuery_segments as IPredefinedSegment } from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { IColumn } from '@frontend/app/types/Columns';
import { IField, IFieldOption, FieldSource } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import DraggableList, { ListItem, SubListItem } from '@frontend/app/refresh-components/DraggableList';
import { ColumnKey } from '@frontend/app/containers/Projects/constants';
import styles from './EditProjectColumns.scss';
import { FieldOption } from './FieldOption';

const {
  useState, useCallback, useMemo, useEffect,
} = React;

interface IProps {
  communityId?: number;
  segment?: ISegment | IPredefinedSegment;
  fields: IField[];
  appsWithFields: IApplication[];
  initialSelectedColumns?: IColumn[];
  defaultColumns?: IColumn[];
  visibility?: IColumnVisibility;
  className?: string;
  predefinedSegmentId: string;
  isLoadingSegments?: boolean;
  fieldById: Map<string, IField>;
  onSelectedColumnsUpdated?: (selectedColumns: IColumn[]) => void;
  onChange?(columnVisibility: IColumnVisibility);
  onColumnsUpdated?: (reset: boolean) => void;
  isFlexExpandableColumns?: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  excludeColumns?: ColumnKey[];
}

const MSG_DURATION = 3000;

type TSortableColumnItem = ISortableItem<IField>;

const SELECT_ALL_FIELD = 'select_all';
export const FIXED_FIELDS = ['name', 'email'];

const getFieldSource = (field: IField): FieldSource =>
  (isString(field?.source) ? field?.source : field?.source?.id || 'unknown') as FieldSource;

export const EditProjectColumns: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    initialSelectedColumns,
    defaultColumns,
    fields,
    appsWithFields,
    predefinedSegmentId,
    isLoadingSegments,
    fieldById,
    onColumnsUpdated,
    isFlexExpandableColumns,
    showModal,
    setShowModal,
    excludeColumns,
  } = props;
  const [selectedSource, setSelectedSource] = useState<FieldSource>(FieldSource.All);
  const [selectedColumns, setSelectedColumns] = useState<IField[]>([]);
  const [selectedFieldsBySource, setSelectedFieldsBySource] = useState<Map<FieldSource, Set<IField['field']>>>(
    new Map<FieldSource, Set<IField['field']>>(),
  );
  const [searchText, setSearchText] = useState<string>();
  const [filteredFields, setFilteredFields] = useState<IField[]>([]);
  const [searchResult, setSearchResult] = useState<IField[]>([]);
  const [fieldsBySource, setFieldsBySource] = useState<Map<FieldSource, IField[]>>(new Map<FieldSource, IField[]>());
  const searchDataSource = useFuzzySearchByKeys<IField>(fields, ['headerName']);
  const [saveSegmentColumns, { loading: isSavingColumns }] = useSaveSegmentColumns();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();

  // group fields by source
  useEffect(() => {
    const result = new Map<FieldSource, IField[]>();
    result.set(FieldSource.All, []);
    forEach(fields, (field) => {
      if (excludeColumns?.includes(field.field as ColumnKey)) {
        return;
      }
      const source = getFieldSource(field);
      if (!result.has(source)) {
        result.set(source, []);
      }
      result.get(source).push(field);
      result.get(FieldSource.All).push(field);
    });
    setFieldsBySource(result);
  }, [fields, excludeColumns]);

  const fixedColumns = useMemo(() => filter(initialSelectedColumns, (f) => includes(FIXED_FIELDS, f?.field)), [
    initialSelectedColumns,
  ]);

  const readonlyColumns = useMemo(() => filter(initialSelectedColumns, (f) => f?.readonly), [initialSelectedColumns]);

  const readonlyColumnsBySource = useMemo(() => {
    const grouped = groupBy(readonlyColumns, (f) => getFieldSource(f));
    grouped[FieldSource.All] = readonlyColumns;
    return grouped;
  }, [readonlyColumns]);

  const initSelectedColumns = useCallback(
    (cols: IField[]) => {
      const updatedSelection = new Map<FieldSource, Set<IField['field']>>();
      const nonFixedColumns = filter(cols, (f) => !includes(FIXED_FIELDS, f?.field));
      const columns = [...fixedColumns, ...nonFixedColumns];
      forEach(columns, (column) => {
        if (!column) return;
        // Skip excluded columns
        if (excludeColumns?.includes(column.field as ColumnKey)) {
          return;
        }
        const source = getFieldSource(column);
        if (!updatedSelection.has(source)) {
          updatedSelection.set(source, new Set<IField['field']>());
        }
        if (!updatedSelection.has(FieldSource.All)) {
          updatedSelection.set(FieldSource.All, new Set<IField['field']>());
        }
        updatedSelection.get(source).add(column.field);
        updatedSelection.get(FieldSource.All).add(column.field);

        if (size(fieldsBySource.get(source)) === size(updatedSelection.get(source))) {
          updatedSelection.get(source).add(SELECT_ALL_FIELD);
        }
        if (size(fieldsBySource.get(FieldSource.All)) === size(updatedSelection.get(FieldSource.All))) {
          updatedSelection.get(FieldSource.All).add(SELECT_ALL_FIELD);
        }
      });
      setSelectedFieldsBySource(updatedSelection);
      setSelectedColumns(columns);
    },
    [fieldsBySource, fixedColumns, setSelectedFieldsBySource, setSelectedColumns, excludeColumns],
  );

  const selectedColumnsInput: SelectedColumnsInput = useMemo(() => {
    const projectColumns = filter(selectedColumns, (column: IField) => column?.source === FieldSource.Project);
    const memberFieldSchemaColumns = filter(selectedColumns, (column: IField) => isNumber(column?.schemaId));
    const dbColumns = filter(
      selectedColumns,
      (column: IField) => !column?.schemaId && column?.source !== FieldSource.Project,
    );
    const order = map(selectedColumns, (column: IField) => ({
      dbColumn: !column?.schemaId && column?.source !== FieldSource.Project ? column?.field : null,
      memberFieldSchemaId: isNumber(column?.schemaId) ? column?.schemaId : null,
      projectColumn: column?.source === FieldSource.Project ? column.field : null,
    }));
    const memberFieldSchemaIds = memberFieldSchemaColumns.reduce((ids, column) => {
      // Add parent column's schema ID if it exists
      if (isNumber(column?.schemaId)) {
        ids.push(column.schemaId);
      }

      // Add schema IDs from sub-columns if they exist
      if (column?.subColumns?.length > 0) {
        column.subColumns.forEach((subCol) => {
          if (isNumber(subCol?.schemaId)) {
            ids.push(subCol.schemaId);
          }
        });
      }

      return ids;
    }, []);
    return {
      dbColumns: map(dbColumns, (f) => f?.field),
      memberFieldSchemaIds,
      projectColumns: map(projectColumns, (f) => f?.field),
      order,
    };
  }, [selectedColumns]);

  // load initial selections of the columns
  useEffect(() => {
    // Filter out excluded columns before initializing
    const filteredColumns = initialSelectedColumns?.filter((col) => !excludeColumns?.includes(col?.field as ColumnKey));
    initSelectedColumns(filteredColumns);
  }, [initSelectedColumns, initialSelectedColumns, excludeColumns]);

  // filter columns when typing in search box
  useEffect(() => {
    let filtered = fieldsBySource.get(selectedSource);
    if (searchText) {
      const search = new Set<IField['field']>(map(searchResult, (f) => f.field));
      filtered = filter(filtered, (f: IField) => search.has(f.field));
    }
    setFilteredFields(filtered);
  }, [searchText, selectedSource, fieldsBySource, searchResult]);

  const selectedCountBySource = useMemo(() => {
    let count = size(selectedFieldsBySource.get(selectedSource));
    if (selectedFieldsBySource.get(selectedSource)?.has(SELECT_ALL_FIELD)) {
      count -= 1;
    }
    return count;
  }, [selectedSource, selectedFieldsBySource]);

  const sources = useMemo(
    () => [
      ...map(FieldSource, (source) => ({
        label: source,
        value: source,
      })),
      ...map(appsWithFields, (app) => ({
        label: app.name,
        value: app.id,
      })),
    ],
    [appsWithFields],
  );
  const selectAllOption = useMemo(() => {
    const selectedFields = selectedFieldsBySource.get(selectedSource);
    const totalFieldsCount = size(fieldsBySource.get(selectedSource));
    const readonlyFieldsCount = size(readonlyColumnsBySource[selectedSource] || []);

    // Get actual selected count (excluding SELECT_ALL_FIELD)
    const selectedCount = selectedFields?.has(SELECT_ALL_FIELD)
      ? size(selectedFields) - 1
      : size(selectedFields);

    // Show "Unselect All" if all selectable fields are selected
    const showUnselectAll = selectedCount >= (totalFieldsCount - readonlyFieldsCount);

    const label = showUnselectAll ? 'Unselect All' : 'Select All';

    return {
      label,
      value: {
        headerName: label,
        field: SELECT_ALL_FIELD,
        subColumns: [],
        hidden: true,
      },
    };
  }, [selectedSource, selectedFieldsBySource, fieldsBySource, readonlyColumnsBySource]);

  const fieldOptions = useMemo(() => {
    const options = chain(filteredFields)
      .filter((field) => !field.hidden)
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .map((col) => ({
        label: upperFirst(col.headerName),
        value: col,
      }))
      .value();

    const fixedOptions = filter(options, (option) => includes(FIXED_FIELDS, option.value.field));
    const nonFixedOptions = filter(options, (option) => !includes(FIXED_FIELDS, option.value.field));

    return searchText ? [...fixedOptions, ...nonFixedOptions] : [selectAllOption, ...fixedOptions, ...nonFixedOptions];
  }, [searchText, selectAllOption, filteredFields]);

  const renderSelectedColumnItem = useCallback(
    (renderParam: IRenderParams<IField>) => <>{renderParam.item.data.headerName}</>,
    [],
  );

  const selectedColumnItems: TSortableColumnItem[] = useMemo(
    () =>
      map(
        selectedColumns,
        (column: IField): TSortableColumnItem => ({
          id: column?.field,
          data: column,
          render: renderSelectedColumnItem,
          disableSort: false,
          removable: !includes(['name', 'work_item__cta', 'email'], column?.field) && !column?.readonly,
        }),
      ),
    [selectedColumns, renderSelectedColumnItem],
  );

  const selectedNestedColumnsItems: ListItem[] = useMemo(() => {
    const allSubColumnFields = new Set(
      selectedColumns
        .filter((col) => col?.subColumns?.length > 0)
        .flatMap((col) => col?.subColumns?.map((sub) => sub.field) || []),
    );
    return map(
      selectedColumns,
      (column: IField): ListItem => {
        if (allSubColumnFields.has(column?.field)) {
          return null;
        }
        const subColumns = column?.subColumns
          ? column.subColumns.filter((subColumn) => selectedColumns.some((selected) => selected.field === subColumn.field))
          : [];
        return {
          id: column?.field || column?.dbName,
          content: (
            <div className="flex flex-col gap-2">
              <div className="text-grey-6 text-sm">{column?.headerName || column?.dbName}</div>
              <div className="flex flex-col gap-1">
                {subColumns.map((subColumn) => (
                  <div key={subColumn.field} className="text-xs text-gray-8">
                    {subColumn.headerName}
                  </div>
                ))}
              </div>
            </div>
          ),
          isFixed: includes([...FIXED_FIELDS, 'work_item__cta', 'email'], column?.field),
          isRemovable: !includes([...FIXED_FIELDS, 'work_item__cta', 'email'], column?.field),
          data: column,
        };
      },
    ).filter(Boolean);
  }, [selectedColumns]);

  const handleUpdateSortNested = useCallback(
    (newItems) => {
      // Create a map of all existing columns by field for quick lookup
      const existingColumnsMap = new Map(
        selectedColumns.map((column) => [column.field || column.dbName, column]),
      );

      // Create a set of fields that are in the new order
      const newItemFields = new Set(newItems.map((item) => item.id));

      // First, handle the reordered items while maintaining their full data
      const reorderedColumns = newItems.map((item) => {
        // Get the complete column data from existing columns
        const existingColumn = existingColumnsMap.get(item.id);
        return existingColumn || item.data;
      });

      // Then, find any columns that weren't in the new order but exist in selectedColumns
      const missingColumns = selectedColumns.filter((column) => {
        const fieldId = column.field || column.dbName;
        return !newItemFields.has(fieldId);
      });

      // Combine reordered columns with any missing columns
      const updatedSelectedColumns = [...reorderedColumns, ...missingColumns];

      setSelectedColumns(updatedSelectedColumns);
    }, [selectedColumns, setSelectedColumns],
  );

  const isAllFieldIndeterminate = useMemo(() => {
    const selectedFieldsCount = size(selectedFieldsBySource.get(selectedSource));
    const totalFieldsCount = size(fieldsBySource.get(selectedSource));
    const readonlyFieldsCount = size(readonlyColumnsBySource[selectedSource] || []);

    // Subtract 1 from selectedFieldsCount if SELECT_ALL_FIELD is present
    const actualSelectedCount = selectedFieldsBySource.get(selectedSource)?.has(SELECT_ALL_FIELD)
      ? selectedFieldsCount - 1
      : selectedFieldsCount;

    return actualSelectedCount > readonlyFieldsCount && actualSelectedCount < totalFieldsCount;
  }, [selectedSource, selectedFieldsBySource, fieldsBySource, readonlyColumnsBySource]);

  const reset = useCallback(() => {
    setSelectedSource(FieldSource.All);
    setSearchText('');
    setFilteredFields(fields);
    document.getElementsByClassName(styles.columnsList)[0]?.scroll({ top: 0 });
  }, [fields, setSelectedSource, setSearchText, setFilteredFields]);

  const handleResetToDefault = useCallback(() => {
    // Filter out excluded columns before resetting to default
    const filteredDefaultColumns = defaultColumns?.filter((col) => !excludeColumns?.includes(col.field as ColumnKey));
    initSelectedColumns(filteredDefaultColumns);
    reset();
  }, [defaultColumns, initSelectedColumns, reset, excludeColumns]);

  const handleSave = useCallback(async () => {
    try {
      await saveSegmentColumns({
        variables: {
          metadata: {
            predefinedSegmentId,
            columns: selectedColumnsInput,
          },
        },
      });
      showSuccessMessage('Columns successfully updated', MSG_DURATION);
      if (isFunction(onColumnsUpdated)) {
        onColumnsUpdated(true);
      }
      reset();
      setShowModal(false);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    predefinedSegmentId,
    selectedColumnsInput,
    saveSegmentColumns,
    setShowModal,
    onColumnsUpdated,
    showSuccessMessage,
    showErrorMessage,
    reset,
  ]);

  const handleCancel = useCallback(() => {
    initSelectedColumns(initialSelectedColumns);
    reset();
    setShowModal(false);
  }, [setShowModal, initialSelectedColumns, initSelectedColumns, reset]);

  const handleColumnSearch = useCallback(
    (e) => {
      const updatedText = e.target.value;
      setSearchText(updatedText);
      setSearchResult(searchDataSource(updatedText));
    },
    [setSearchText, setSearchResult, searchDataSource],
  );

  const handleColumnSourceChange = useCallback(
    (selected: string) => {
      setSelectedSource(selected as FieldSource);
    },
    [setSelectedSource],
  );

  const handleItemRemoved = useCallback(
    (newItems: TSortableColumnItem[], removedItem: TSortableColumnItem) => {
      const updatedSelectedColumns = map(newItems, (item) => item.data);
      setSelectedColumns(updatedSelectedColumns);
      const updatedSelection = new Map<FieldSource, Set<IField['field']>>(selectedFieldsBySource);
      const field = fieldById.get(removedItem.id);
      updatedSelection.get(selectedSource).delete(field.field);
      updatedSelection.get(selectedSource).delete(SELECT_ALL_FIELD);
      if (selectedSource === FieldSource.All) {
        const source = getFieldSource(field);
        updatedSelection.get(source).delete(field.field);
        updatedSelection.get(source).delete(SELECT_ALL_FIELD);
      } else {
        updatedSelection.get(FieldSource.All).delete(field.field);
        updatedSelection.get(FieldSource.All).delete(SELECT_ALL_FIELD);
      }
      setSelectedFieldsBySource(updatedSelection);
    },
    [fieldById, selectedSource, selectedFieldsBySource, setSelectedFieldsBySource, setSelectedColumns],
  );
  const handleItemRemovedNested = useCallback(
    (item: ListItem<{}> | SubListItem<{}>) => {
      // Update selectedFieldsBySource
      const updatedSelection = new Map<FieldSource, Set<IField['field']>>(selectedFieldsBySource);
      const field = fieldById.get(item.id);

      // Handle case where field might not exist
      if (!field) {
        return;
      }

      // Remove from selected source
      updatedSelection.get(selectedSource)?.delete(field.field);
      updatedSelection.get(selectedSource)?.delete(SELECT_ALL_FIELD);

      // Handle All source vs specific source
      if (selectedSource === FieldSource.All) {
        const source = getFieldSource(field);
        updatedSelection.get(source)?.delete(field.field);
        updatedSelection.get(source)?.delete(SELECT_ALL_FIELD);
      } else {
        updatedSelection.get(FieldSource.All)?.delete(field.field);
        updatedSelection.get(FieldSource.All)?.delete(SELECT_ALL_FIELD);
      }

      // Update selectedColumns by filtering out the removed item
      const updatedSelectedColumns = selectedColumns.filter((column) => column.field !== field.field && column.dbName !== item.id);

      setSelectedFieldsBySource(updatedSelection);
      setSelectedColumns(updatedSelectedColumns);
    },
    [
      fieldById,
      selectedSource,
      selectedFieldsBySource,
      selectedColumns,
      setSelectedFieldsBySource,
      setSelectedColumns,
    ],
);

  const handleUpdateSort = useCallback(
    (newItems: TSortableColumnItem[]) => {
      const updatedSelectedColumns = map(newItems, (item) => item.data);
      setSelectedColumns(updatedSelectedColumns);
    },
    [setSelectedColumns],
  );

  const handleColumnSelection = useCallback(
    (column) => {
      if (size(filteredFields) === 0) return;

      const updatedSelection = new Map<FieldSource, Set<IField['field']>>(selectedFieldsBySource);

      if (column.field === SELECT_ALL_FIELD) {
        // Check if ALL fields are currently selected (excluding readonly and fixed fields)
        const totalSelectableFields = filteredFields.length - size(readonlyColumnsBySource[selectedSource]);
        const currentSelectedCount = size(updatedSelection.get(selectedSource)) -
          (updatedSelection.get(selectedSource)?.has(SELECT_ALL_FIELD) ? 1 : 0);

        // If not all fields are selected, then select all
        const shouldSelectAll = currentSelectedCount < totalSelectableFields;

        if (shouldSelectAll) {
          // Select all including sub-columns
          const fieldsToAdd = new Set<string>();

          filteredFields.forEach((field) => {
            fieldsToAdd.add(field.field);
            // Add sub-columns if present
            if (field.subColumns?.length) {
              field.subColumns.forEach((sub) => fieldsToAdd.add(sub.field));
            }
          });

          if (selectedSource !== FieldSource.All) {
            // Add to specific source
            updatedSelection.get(selectedSource).clear();
            fieldsToAdd.forEach((field) => updatedSelection.get(selectedSource).add(field));
            // Add to All source
            fieldsToAdd.forEach((field) => updatedSelection.get(FieldSource.All).add(field));
          } else {
            // Add to all sources
            fieldsBySource.forEach((fields, source) => {
              if (!updatedSelection.has(source)) {
                updatedSelection.set(source, new Set<string>());
              }
              fields.forEach((f) => {
                if (fieldsToAdd.has(f.field)) {
                  updatedSelection.get(source).add(f.field);
                }
              });
            });
          }

          // Add SELECT_ALL_FIELD marker
          updatedSelection.get(selectedSource).add(SELECT_ALL_FIELD);
        } else {
          // Unselect all - keep only readonly and fixed columns
          if (selectedSource !== FieldSource.All) {
            // Clear current source selection except readonly and fixed fields
            const currentSelection = updatedSelection.get(selectedSource);
            const fieldsToKeep = new Set([...FIXED_FIELDS, 'work_item__cta', 'email']);

            // Create new set with only fixed fields
            const newSelection = new Set<string>();
            currentSelection.forEach((field) => {
              if (fieldsToKeep.has(field) || readonlyColumnsBySource[selectedSource]?.some((col) => col.field === field)) {
                newSelection.add(field);
              }
            });
            updatedSelection.set(selectedSource, newSelection);

            // Remove non-fixed fields from All source
            updatedSelection.get(FieldSource.All).forEach((field) => {
              if (!fieldsToKeep.has(field) && !readonlyColumnsBySource[FieldSource.All]?.some((col) => col.field === field)) {
                updatedSelection.get(FieldSource.All).delete(field);
              }
            });
          } else {
            // Clear all sources except readonly and fixed fields
            updatedSelection.forEach((selection, source) => {
              const sourceReadonly = readonlyColumnsBySource[source] || [];
              const fieldsToKeep = new Set([...FIXED_FIELDS, 'work_item__cta', 'email']);

              // Create new set with only fixed and readonly fields
              const newSelection = new Set<string>();
              selection.forEach((field) => {
                if (fieldsToKeep.has(field) || sourceReadonly.some((col) => col.field === field)) {
                  newSelection.add(field);
                }
              });
              updatedSelection.set(source, newSelection);
            });
          }

          // Add back readonly columns
          forEach(readonlyColumns, (column) => {
            const source = getFieldSource(column);
            updatedSelection.get(source).add(column.field);
            updatedSelection.get(FieldSource.All).add(column.field);
          });
        }
      } else {
        // Handle individual column selection (existing logic)
        if (updatedSelection.get(selectedSource)?.has(column.field)) {
          // Unselect column and its sub-columns
          updatedSelection.get(selectedSource).delete(column.field);
          if (column.subColumns?.length) {
            column.subColumns.forEach((sub) => {
              updatedSelection.get(selectedSource).delete(sub.field);
              updatedSelection.get(FieldSource.All).delete(sub.field);
            });
          }
          updatedSelection.get(selectedSource).delete(SELECT_ALL_FIELD);

          if (selectedSource === FieldSource.All) {
            const source = getFieldSource(column);
            updatedSelection.get(source)?.delete(column.field);
            updatedSelection.get(source)?.delete(SELECT_ALL_FIELD);
          } else {
            updatedSelection.get(FieldSource.All).delete(column.field);
            updatedSelection.get(FieldSource.All).delete(SELECT_ALL_FIELD);
          }
        } else {
          // Select column
          if (!updatedSelection.get(selectedSource)) {
            updatedSelection.set(selectedSource, new Set<IField['field']>());
          }
          updatedSelection.get(selectedSource).add(column.field);

          if (selectedSource === FieldSource.All) {
            const source = getFieldSource(column);
            if (!updatedSelection.get(source)) {
              updatedSelection.set(source, new Set<IField['field']>());
            }
            updatedSelection.get(source).add(column.field);
          } else {
            updatedSelection.get(FieldSource.All).add(column.field);
          }

          // Check if all fields are selected to add SELECT_ALL_FIELD
          const currentSourceFields = fieldsBySource.get(selectedSource);
          const allFieldsSelected = currentSourceFields.every((f) =>
            updatedSelection.get(selectedSource).has(f.field) ||
            includes(FIXED_FIELDS, f.field) ||
            f.readonly
          );

          if (allFieldsSelected) {
            updatedSelection.get(selectedSource).add(SELECT_ALL_FIELD);
          }
        }
      }

      // Update selected columns
      const newSelectedColumns = [
        ...filter(selectedColumns, (col) => updatedSelection.get(FieldSource.All).has(col.field)),
        ...filter(
          fields,
          (f) =>
            updatedSelection.get(FieldSource.All).has(f.field) &&
            !find(selectedColumns, (col) => col.field === f.field)
        ),
      ];

      setSelectedColumns(newSelectedColumns);
      setSelectedFieldsBySource(updatedSelection);
    },
    [
      selectedSource,
      selectedFieldsBySource,
      filteredFields,
      fieldsBySource,
      selectedColumns,
      readonlyColumns,
      readonlyColumnsBySource,
      fields,
      setSelectedColumns,
      setSelectedFieldsBySource,
    ],
  );

  const renderItem = (item: IFieldOption, index: number) => {
    const column = item.value;
    const isIntederminate = column.field === SELECT_ALL_FIELD && isAllFieldIndeterminate;
    const isChecked = selectedFieldsBySource.get(selectedSource)?.has(column.field);
    return (
      <Checkbox
        key={`${column.field}-${index}`}
        checked={isChecked}
        indeterminate={isIntederminate}
        onChange={handleColumnSelection.bind(null, column)}
        disabled={includes(FIXED_FIELDS, column.field) || column.readonly}
      >
        <div className={styles.columnLabel}>
          {`${item.value.headerName}${column.field === SELECT_ALL_FIELD ? ` (${selectedCountBySource})` : ''}`}
        </div>
      </Checkbox>
    );
  };

  return (
    <>
      <Modal
        width={700}
        title={<p className={styles.modalHeader}>Edit Columns</p>}
        open={showModal}
        onOk={handleSave}
        onCancel={handleCancel}
        okText="Save"
        footer={[
          <div key="footerButtons" className={styles.modalFooter}>
            <div key="resetToDefault">
              <Button loading={isLoadingSegments} onClick={handleResetToDefault}>
                Reset to Default
              </Button>
            </div>
            <div key="cancelSave">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button loading={isSavingColumns} type="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={styles.subTitle}>Your changes will apply to this stage in this project for all users.</div>
        <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.columnTitle}>Add Columns</div>
            <Select className={styles.columnSourceSelect} value={selectedSource} onChange={handleColumnSourceChange}>
              {map(sources, (source) => (
                <Select.Option key={source.label} value={source.value}>
                  {source.label}
                </Select.Option>
              ))}
            </Select>
            <Input
              className={styles.columnSearch}
              value={searchText}
              allowClear
              prefix={<SearchOutlined />}
              onChange={handleColumnSearch}
              placeholder="Search"
              autoFocus
            />
            {isFlexExpandableColumns ? (
              <div className="space-y-2 h-80 overflow-y-auto mt-5">
                {fieldOptions.map((field, index) => (
                  <FieldOption
                    key={index}
                    field={field}
                    selectedSource={selectedSource}
                    selectedFieldsBySource={selectedFieldsBySource}
                    handleColumnSelection={handleColumnSelection}
                  />
                ))}
              </div>
            ) : (
              <List<IFieldOption>
                className={styles.columnsList}
                size="small"
                bordered={false}
                dataSource={fieldOptions}
                renderItem={(item, index) => <List.Item>{renderItem(item, index)}</List.Item>}
              />
            )}
          </div>
          <div className={styles.column}>
            <div className={styles.columnTitle}>Selected Columns</div>
            <div className={styles.selectedColumns}>
              {!isFlexExpandableColumns ? (
                // TODO (rl) make sure that the styling is still correct here when feature flag enabled
                <SortableList<IField>
                  fixedItemCount={size(FIXED_FIELDS)}
                  itemClassName={styles.selectedColumnItem}
                  onChange={handleUpdateSort}
                  onItemRemoved={handleItemRemoved}
                  options={selectedColumnItems}
                  withHoverBackgroundColor
                  flex
                />
              ) : (
                <DraggableList
                  initialItems={selectedNestedColumnsItems}
                  onItemsChange={handleUpdateSortNested}
                  onItemRemoved={handleItemRemovedNested}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

EditProjectColumns.displayName = 'EditProjectColumns';
