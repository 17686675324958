import 'css-chunk:src/applications/AffiliatesApp/components/MembersWizard/components/MembersWizard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_zzr0s_157",
  "justify-content-space-between": "_justify-content-space-between_zzr0s_161",
  "tabular-nums": "_tabular-nums_zzr0s_165",
  "MembersWizard": "_MembersWizard_zzr0s_169",
  "inputWrapper": "_inputWrapper_zzr0s_169",
  "linkInput": "_linkInput_zzr0s_169",
  "codeInput": "_codeInput_zzr0s_169",
  "wizardNextButton": "_wizardNextButton_zzr0s_610",
  "statsCard": "_statsCard_zzr0s_626",
  "alert": "_alert_zzr0s_630",
  "activeDatesForm": "_activeDatesForm_zzr0s_633",
  "dateTimePicker": "_dateTimePicker_zzr0s_640",
  "icon": "_icon_zzr0s_643",
  "confirmClose": "_confirmClose_zzr0s_646",
  "membersListWarning": "_membersListWarning_zzr0s_647",
  "title": "_title_zzr0s_664",
  "buttons": "_buttons_zzr0s_668",
  "urlInputWarning": "_urlInputWarning_zzr0s_730",
  "urlInputError": "_urlInputError_zzr0s_733",
  "codeHasError": "_codeHasError_zzr0s_736",
  "enterCodeLabel": "_enterCodeLabel_zzr0s_747",
  "wizardButton": "_wizardButton_zzr0s_750",
  "hidden": "_hidden_zzr0s_753",
  "tablePadding": "_tablePadding_zzr0s_767",
  "fullWidth": "_fullWidth_zzr0s_770",
  "projectSelect": "_projectSelect_zzr0s_773",
  "emptyContainerMargin": "_emptyContainerMargin_zzr0s_776",
  "loading": "_loading_zzr0s_779",
  "show": "_show_zzr0s_1"
};