import React, { useCallback } from 'react';
import map from 'lodash/map';
import {
  Form, Typography, Button,
 } from '@revfluence/fresh';
import { v4 as uuidv4 } from 'uuid';
import styles from './FiscalYears.scss';
import FiscalYear, { FiscalYear as FiscalYearType } from './components/FiscalYear';

const { Text } = Typography;

interface IProps {
    fiscalYears: FiscalYearType[];
    handleFiscalYearsChange: (fiscalYears: FiscalYearType[]) => void;
}

const FiscalYears: React.FC<IProps> = ({ fiscalYears, handleFiscalYearsChange }) => {
    const handleFiscalYearChange = useCallback((updatedFiscalYear) => {
        const updatedFiscalYears = fiscalYears.map((fiscalYear) => {
            if (fiscalYear.year === updatedFiscalYear.year) {
                return {
                    ...fiscalYear,
                    ...updatedFiscalYear,
                };
            }
            return fiscalYear;
        });
        handleFiscalYearsChange(updatedFiscalYears);
    }, [fiscalYears, handleFiscalYearsChange]);

    const addFiscalYear = useCallback(() => {
        // Filter out 'N/A' years and get the maximum year
        const validYears = fiscalYears
            .filter((fy) => fy.year !== 'N/A')
            .map((fy) => parseInt(fy.year, 10));

        const nextYear = validYears.length > 0
            ? Math.max(...validYears) + 1
            : new Date().getFullYear();

        const newFiscalYear = {
            id: uuidv4(),
            yearName: `FY ${nextYear}`,
            year: nextYear.toString(),
            isArchived: false,
            isCurrentYear: false,
            isNew: true,
        };
        const updatedFiscalYears = [...fiscalYears, newFiscalYear];
        handleFiscalYearsChange(updatedFiscalYears);
    }, [fiscalYears, handleFiscalYearsChange]);

    return (
      <Form.Item
        name="fiscalYears"
      >
        <div className={styles.fiscalYearsContainer}>
          <div className={styles.fiscalYearFieldInfo}>
            <Text className={styles.fiscalYearTitle}>Fiscal years</Text>
            <Text className={styles.referenceText}>Define your Fiscal Years below.</Text>
          </div>
          <div className={styles.fiscalYearsAndAddButton}>
            {map(fiscalYears, (fiscalYear) => (
              <FiscalYear
                key={fiscalYear.id}
                value={fiscalYear}
                handleFiscalYearChange={handleFiscalYearChange}
                handleFiscalYearsChange={handleFiscalYearsChange}
                fiscalYears={fiscalYears}
              />
            ))}
            <Button type="link" className={styles.addFiscalYearButton} onClick={addFiscalYear}>+ Add Fiscal Year</Button>
          </div>
        </div>

      </Form.Item>
    );
  };

export default FiscalYears;
