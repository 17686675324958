import {
  OFFER_CONVERSION_TYPE,
  OFFER_PAYOUT_TYPE,
  OFFER_PAYOUT_TYPE_PROMO,
  OFFER_PRICE_RULE_TYPE,
  OFFER_PROMO_USAGE_LIMIT_RULE,
  OFFER_SOURCE,
  OFFER_STATUS,
  OFFER_TRACKING_TYPE,
} from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { FormInstance } from 'antd';
import { trim } from 'lodash';
import moment from 'moment';
import {
  FormAsyncAction,
  IAffiliateLinksFormValues,
  ICommonFormElementProps,
  ICommonFormValues,
  IPayoutVariant,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  PrefixTypeStrategy,
  TDisabledMap,
  TFormValues,
} from '../types';

interface IEvents {
  onBlur: () => void;
  onFocus: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}
export interface IRefreshPayoutProps extends ICommonFormElementProps<'payoutType'> {
  events: {
    onBlur: IEvents['onBlur'];
    options: {
      [OFFER_PAYOUT_TYPE.CONVERSION]: IEvents;
      [OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]: IEvents;
      [OFFER_PAYOUT_TYPE.SALE]: IEvents;
      [OFFER_PAYOUT_TYPE.CLICK]: IEvents;
    };
  };
  formattedConversionType: string;
  showCostPerClick: boolean;
  value: ICommonFormValues['payoutType'] | ICommonFormValues['payoutOptions'];
  source: OFFER_SOURCE;
  isNewFlow: boolean;
  mode: OfferFormModes;
  payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO;
  formRef: FormInstance;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  onFieldFocused?: (type: string, selected: boolean) => void;
  affiliateLinkData?: IAffiliateLinksFormValues;
  shopifyData?: IShopifyPromoCodeFormValues;
  disabledAffiliatesLinks?: TDisabledMap<IAffiliateLinksFormValues>;
  disabledShopify?: TDisabledMap<IShopifyPromoCodeFormValues>;
  url?: string;
  initialUrl?: string;
  handleOfferTypeChange?: (value: string) => void;
}

export const getOfferStatusColor = (status: string) => {
  switch (status) {
    case OFFER_STATUS.ACTIVE:
      return 'bg-green-4';
    case OFFER_STATUS.PAUSED:
      return 'bg-yellow-4';
    case 'expired':
      return 'bg-red-4';
    default:
      return 'bg-grey-4';
  }
};

export const getUniqueRefreshTagColor = (index: number) => {
  const colors = [
    'bg-purple-4',
    'bg-blue-4',
    'bg-teal-4',
    'bg-green-4',
    'bg-lime-4',
    'bg-yellow-4',
    'bg-orange-4',
    'bg-pink-4',
    'bg-magenta-4',
    'bg-grey-4',
  ];

  return colors[index] ?? 'bg-red-4';
};
export const getPayoutLabel = (payout) => {
  if (!payout.label) return null;

  let label = payout.label;

  switch (payout.payoutType) {
    case OFFER_PAYOUT_TYPE.CONVERSION:
      label += ` ($${payout.flatPayout || 0})`;
      break;
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      label += ` ($${payout.flatPayout || 0} + ${payout.percentPayout || 0}%)`;
      break;
    case OFFER_PAYOUT_TYPE.SALE:
      label += ` (${payout.percentPayout || 0}%)`;
      break;
    case OFFER_PAYOUT_TYPE.CLICK:
      label += ` ($${payout.flatPayout || 0})`;
      break;
    default:
      break;
  }

  return label;
};
export const getDiscountLabel = (payout) => {
  if (!payout.isDefault) return null;

  switch (payout.payoutType) {
    case OFFER_PAYOUT_TYPE.CONVERSION:
      return `$${payout.flatPayout || 0} Discount`;
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      return `$${payout.flatPayout || 0} + ${payout.percentPayout || 0}% Discount`;
    case OFFER_PAYOUT_TYPE.SALE:
      return `${payout.percentPayout || 0}% Discount`;
    case OFFER_PAYOUT_TYPE.CLICK:
      return `$${payout.flatPayout || 0} Discount`;
    default:
      return null;
  }
};

export const getUsageLimitMessage = (
  specialLimitOnePerSale?: boolean,
  specialLimitUsageCapEnabled?: boolean,
  specialLimitNewCustomersOnly?: boolean,
  specialLimitUsageCapAmount?: string,
): string => {
  const values = {
    specialLimitOnePerSale,
    specialLimitUsageCapEnabled,
    specialLimitNewCustomersOnly,
    specialLimitUsageCapAmount,
  };
  if (values.specialLimitOnePerSale && values.specialLimitUsageCapEnabled && values.specialLimitNewCustomersOnly) {
    return `Limit of ${
      values.specialLimitUsageCapAmount || 0
    } uses, one per customer (For new customers only)`;
  }
  if (values.specialLimitOnePerSale && values.specialLimitNewCustomersOnly) {
    return 'One use per customer (For new customers only)';
  }
  if (values.specialLimitOnePerSale && values.specialLimitUsageCapEnabled) {
    return `Limit of ${values.specialLimitUsageCapAmount || 0} uses, one per customer`;
  }
  if (values.specialLimitOnePerSale) {
    return 'One use per customer';
  }
  if (values.specialLimitNewCustomersOnly) {
    return 'One use per customer (For new customers only)';
  }
  if (values.specialLimitUsageCapEnabled) {
    return `Usage cap enabled ${values.specialLimitUsageCapAmount}`;
  }
  return 'No usage limits';
};

export const getDiscountCombinationMessage = (
  productDiscounts?: boolean,
  orderDiscounts?: boolean,
  shippingDiscounts?: boolean,
): string => {
  const values = { productDiscounts, orderDiscounts, shippingDiscounts };

  if (!values.productDiscounts && !values.orderDiscounts && !values.shippingDiscounts) {
    return 'Can’t combine it with other offers';
  }
  if (values.productDiscounts && values.orderDiscounts && values.shippingDiscounts) {
    return 'Combines with product, order, and shipping discounts';
  }
  if (values.productDiscounts && values.orderDiscounts) {
    return 'Combines with product and order discounts';
  }
  if (values.productDiscounts && values.shippingDiscounts) {
    return 'Combines with product and shipping discounts';
  }
  if (values.orderDiscounts && values.shippingDiscounts) {
    return 'Combines with order and shipping discounts';
  }
  if (values.productDiscounts) {
    return 'Combines with product discount';
  }
  if (values.orderDiscounts) {
    return 'Combines with order discount';
  }
  if (values.shippingDiscounts) {
    return 'Combines with shipping discount';
  }

  return 'No discount combination available'; // Fallback, should never reach here
};

export const generateLinkUrl = (value: IAffiliateLinksFormValues, trimUrl = false) => {
  const queryParams = [];

  if (value.utmSource) queryParams.push(`utm_source=${value.utmSource}`);
  if (value.utmMedium) queryParams.push(`utm_medium=${value.utmMedium}`);
  if (value.utmCampaign) queryParams.push(`utm_campaign=${value.utmCampaign}`);
  if (value.utmContent) queryParams.push(`utm_content=${value.utmContent}`);
  if (value.utmTerm) queryParams.push(`utm_term=${value.utmTerm}`);

  if (value.customUTMParameters?.length) {
    const validParams = value.customUTMParameters
      .filter((param) => param.key && param.value) // Filter out entries with undefined/empty key or value
      .map((param) => `${param.key}=${param.value}`);

    if (validParams.length) {
      queryParams.push(validParams.join('&'));
    }
  }

  let finalUrl = queryParams.length > 0 ? `${value.url}?${queryParams.join('&')}` : value.url;

  if (trimUrl && finalUrl.length > 70) {
    finalUrl = `${finalUrl.substring(0, 70)}...`;
  }

  return finalUrl;
};

export const getSamplePromoCode = (values: IShopifyPromoCodeFormValues) => {
  if (values.source !== OFFER_SOURCE.SHOPIFY) return 'JohnDoe';

  const examplePromoCode = (() => {
    switch (values.prefixType) {
      case PrefixTypeStrategy.INSTAGRAM_ONLY:
        return 'Instagramusername';
      case PrefixTypeStrategy.FULL_NAME_ONLY:
        return 'johnDoe';
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY:
        return 'JDoe';
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY:
        return 'johnd';
      default:
        return 'JohnDoe';
    }
  })();
  const isShopifyPromoCodeValues = (
    val: IAffiliateLinksFormValues | IShopifyPromoCodeFormValues,
  ): val is IShopifyPromoCodeFormValues => 'codePrefix' in val && 'codeSuffix' in val;

  if (isShopifyPromoCodeValues(values)) {
    return trim(`${values.codePrefix ?? ''}${examplePromoCode}${values.codeSuffix ?? ''}`.toUpperCase());
  }

  return trim(examplePromoCode.toUpperCase());
};

export const TRACKED_KEYS = [
  'codeSuffix',
  'description',
  'flatPayout',
  'groupName',
  'imageUrl',
  'name',
  'payoutType',
  'payoutOptions',
  'percentPayout',
  'prefixType',
  'priceRuleAmount',
  'priceRuleType',
  'specialLimitNewCustomersOnly',
  'specialLimitOnePerSale',
  'specialLimitUsageCapAmount',
  'specialLimitUsageCapEnabled',
  'usageLimitAmount',
  'usageLimitRule',
  'productCollections',
  'status',
  'activeTime',
  'activeDate',
  'endDate',
  'endTime',
  'recurringCycleLimitAmount',
  'offerCodePurchaseRestrictionsRule',
  'isEndDateEnable',
  'purchaseType',
  'isMultipleShopifySyncEnabled',
  'isSameDiscountMultipleShopify',
  'multiShopifyEnabled',
  'clientsForSync',
  'isUngrouped',
  'lockEditing',
  'productDiscounts',
  'shippingDiscounts',
  'orderDiscounts',
  'isPromoLink',
  'isLandingPageEnabled',
  'landingPageUrl',
  'shopifyRedirectUrl',
  'isSecureCodes',
  'linkedShopifyOfferId',
  'offerType',
] as const;

export const compareValues = (obj1: TFormValues, obj2: TFormValues) => {
  const filteredObj1 = {};
  const filteredObj2 = {};

  const normalizeDate = (date) => {
    if (!date) return date;
    const d = new Date(date);
    // Round to nearest minute by setting seconds and milliseconds to 0
    d.setSeconds(0, 0);
    return d.getTime();
  };

  TRACKED_KEYS.forEach((key) => {
    if (key in obj1) {
      if (key === 'activeDate' || key === 'activeTime' || key === 'endDate' || key === 'endTime') {
        filteredObj1[key] = normalizeDate(obj1[key]);
        filteredObj2[key] = normalizeDate(obj2[key]);
      } else {
        filteredObj1[key] = obj1[key];
        filteredObj2[key] = obj2[key];
      }
    }
  });
  // cossole different values
  const differences = {};
  TRACKED_KEYS.forEach((key) => {
    if (JSON.stringify(filteredObj1[key]) !== JSON.stringify(filteredObj2[key])) {
      differences[key] = {
        initial: filteredObj1[key],
        current: filteredObj2[key],
      };
    }
  });
  return JSON.stringify(filteredObj1) !== JSON.stringify(filteredObj2);
};

export const tuneData: IAffiliateLinksFormValues = {
  conversionTrackingType: OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK,
  conversionType: OFFER_CONVERSION_TYPE.CONVERSIONS,
  description: 'Sample description',
  expirationDate: moment().add(1, 'year'),
  flatPayout: '12',
  imageUrl: null,
  icon: null,
  name: 'Affiliate offer',
  payoutOptions: [
    {
      id: 64,
      payoutType: OFFER_PAYOUT_TYPE.CONVERSION,
      label: 'Default',
      flatPayout: 12,
      percentPayout: null,
      isMemberAdded: false,
      createdDate: null,
      isDefault: true,
    },
  ],
  payoutType: OFFER_PAYOUT_TYPE.CONVERSION,
  percentPayout: '0',
  source: OFFER_SOURCE.TUNE,
  status: OFFER_STATUS.ACTIVE,
  url: 'http://website.com?transaction_id={transaction_id}&dd={transaction_id}',
  isAdvanceUrlEnable: true,
  utmSource: '{creator_name}',
  utmMedium: '{project_name}',
  utmCampaign: '{project_name}',
  utmContent: '',
  utmTerm: '',
  customUTMParameters: [
    {
      key: 'sample',
      value: '{member_id}',
    },
  ],
  multipleDomain: ['https://www.amazon.ca', 'https://www.amazon.com'],
  creatorDeeplink: true,
  allowMultipleConversions: false,
  thirdPartyTracking: null,
  trackThirdPartyPayout: false,
  isThirdPartyIntegration: false,
};
export const shopifyData: IShopifyPromoCodeFormValues = {
  codeSuffix: '',
  description: 'Affiliate promo offer Description',
  flatPayout: null,
  groupName: '',
  imageUrl: null,
  icon: null,
  name: 'Affiliate promo offer',
  payoutType: null,
  payoutOptions: [
    {
      label: '',
      isDefault: true,
      payoutType: 'SALE',
      isMemberAdded: false,
    },
  ],
  percentPayout: null,
  prefixType: null,
  priceRuleAmount: null,
  priceRuleType: OFFER_PRICE_RULE_TYPE.AMOUNT,
  source: OFFER_SOURCE.SHOPIFY,
  specialLimitNewCustomersOnly: false,
  specialLimitOnePerSale: false,
  specialLimitUsageCapAmount: null,
  specialLimitUsageCapEnabled: false,
  usageLimitAmount: null,
  usageLimitRule: OFFER_PROMO_USAGE_LIMIT_RULE.NONE,
  productCollections: [],
  status: OFFER_STATUS.ACTIVE,
  activeTime: null,
  activeDate: null,
  endDate: null,
  endTime: null,
  recurringCycleLimitAmount: 0,
  offerCodePurchaseRestrictionsRule: 'Limit discount to the first payment',
  isEndDateEnable: false,
  isNewFlow: true,
  purchaseType: 'One-time',
  isMultipleShopifySyncEnabled: false,
  isSameDiscountMultipleShopify: true,
  multiShopifyEnabled: false,
  clientsForSync: [],
  connectedAdvertiserForSync: [],
  allClientForSync: [],
  isUngrouped: false,
  lockEditing: [],
  productDiscounts: false,
  shippingDiscounts: false,
  orderDiscounts: false,
  isPromoLink: false,
  isLandingPageEnabled: false,
  landingPageUrl: null,
  shopifyRedirectUrl: null,
  isSecureCodes: false,
  linkedShopifyOfferId: '',
  isReadOnly: false,
  offerType: 'amountOff',
  isSuffixSelected: false,
  isPrefixSelected: false,
};
export const defaultPropsForShopifySectionTest: IRefreshPayoutProps = {
  disabled: false,
  events: {
    onBlur: () => {}, // Provide a no-op function
    options: {
      CONVERSION: {
        onBlur(): void {
          throw new Error('Function not implemented.');
        },
        onFocus(): void {
          throw new Error('Function not implemented.');
        },
        onMouseEnter(): void {
          throw new Error('Function not implemented.');
        },
        onMouseLeave(): void {
          throw new Error('Function not implemented.');
        },
      },
      CONVERSION_AND_SALE: {
        onBlur(): void {
          throw new Error('Function not implemented.');
        },
        onFocus(): void {
          throw new Error('Function not implemented.');
        },
        onMouseEnter(): void {
          throw new Error('Function not implemented.');
        },
        onMouseLeave(): void {
          throw new Error('Function not implemented.');
        },
      },
      SALE: {
        onBlur(): void {
          throw new Error('Function not implemented.');
        },
        onFocus(): void {
          throw new Error('Function not implemented.');
        },
        onMouseEnter(): void {
          throw new Error('Function not implemented.');
        },
        onMouseLeave(): void {
          throw new Error('Function not implemented.');
        },
      },
      CLICK: {
        onBlur(): void {
          throw new Error('Function not implemented.');
        },
        onFocus(): void {
          throw new Error('Function not implemented.');
        },
        onMouseEnter(): void {
          throw new Error('Function not implemented.');
        },
        onMouseLeave(): void {
          throw new Error('Function not implemented.');
        },
      },
    },
  },
  formattedConversionType: 'Conversion',
  name: 'payoutType',
  showCostPerClick: false,
  value: [
    {
      label: '',
      isDefault: true,
      payoutType: 'SALE',
      isMemberAdded: false,
    },
  ],
  source: OFFER_SOURCE.SHOPIFY,
  payoutType: null,
  isNewFlow: true,
  mode: OfferFormModes.Create,
  shopifyData, // Keep the rest unchanged // Keep the rest unchanged
  disabledShopify: {
    codeSuffix: false,
    conversionType: false,
    description: false,
    flatPayout: false,
    groupName: false,
    imageUrl: false,
    icon: false,
    name: false,
    payoutType: false,
    percentPayout: false,
    prefixType: false,
    priceRuleAmount: false,
    priceRuleType: false,
    specialLimitNewCustomersOnly: false,
    specialLimitOnePerSale: false,
    specialLimitUsageCapAmount: false,
    specialLimitUsageCapEnabled: false,
    usageLimitAmount: false,
    usageLimitRule: false,
    productCollections: false,
    codePrefix: false,
    purchaseType: true,
    status: true,
    activeTime: true,
    activeDate: true,
    endDate: true,
    endTime: true,
    offerCodePurchaseRestrictionsRule: true,
    recurringCycleLimitAmount: true,
    isEndDateEnable: false,
    isPrefixSelected: false,
    isSuffixSelected: false,
    multiShopifyEnabled: false,
    clientsForSync: false,
    payoutOptions: false,
    handleFormAsyncActions: false,
    connectedAdvertiserForSync: false,
    isSameDiscountMultipleShopify: false,
    isMultipleShopifySyncEnabled: false,
    allClientForSync: false,
    isNewFlow: false,
    isUngrouped: false,
    lockEditing: false,
    productDiscounts: false,
    shippingDiscounts: false,
    orderDiscounts: false,
    offerType: false,
    linkedShopifyOfferId: false,
    isReadOnly: true,
    isPromoLink: false,
    isLandingPageEnabled: false,
    landingPageUrl: false,
    isSecureCodes: false,
    isAdvancedUrlEnabled: false,
    utmCampaign: false,
    utmMedium: false,
    utmSource: false,
    utmContent: false,
    shopifyRedirectUrl: false,
    utmTerm: false,
    customUTMParameters: false,
    utmFields: false,
  }, // Keep the rest unchanged
  formRef: undefined,
};

export const getPayoutTagInitials = (payoutType: string) => {
  switch (payoutType) {
    case OFFER_PAYOUT_TYPE.CONVERSION:
      return 'CPA';
    case OFFER_PAYOUT_TYPE.CLICK:
      return 'CPC';
    case OFFER_PAYOUT_TYPE.SALE:
      return 'CPS';
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      return 'CPA+CPS';
    default:
      return '';
  }
};

export const getUniqueColorForPayout = (type: string) => {
  switch (type) {
    case OFFER_PAYOUT_TYPE.CONVERSION:
      return 'bg-magenta-2';
    case OFFER_PAYOUT_TYPE.CLICK:
      return 'bg-yellow-2';
    case OFFER_PAYOUT_TYPE.SALE:
      return 'bg-blue-2';
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      return 'bg-purple-2';
    default:
      return 'bg-grey-2';
  }
};

export const getToolTipContent = (
  tempPayoutTitles: string,
  tempFatPayout: number | null,
  tempPercentPayout: number | null,
  payouts: IPayoutVariant[],
  setDisableAddPayout: (disabled: boolean) => void,
  tempPayoutType: OFFER_PAYOUT_TYPE,
  payoutMode: string,
): string[] => {
  const errors: string[] = [];
  // Validate Payout Title
  if (tempPayoutTitles.trim() === '') {
    errors.push('Payout title is required.');
  }

  if (payoutMode === 'create' && payouts.some((payout) => payout.label.toLowerCase().trim() === tempPayoutTitles.toLowerCase().trim())) {
    errors.push('Payout title must be unique.');
  }

  if (/[^a-zA-Z0-9\s]/.test(tempPayoutTitles)) {
    errors.push('Payout title cannot contain special characters.');
  }

  // Validate Payout Amounts Based on Type
  const requiresFlat = [
    OFFER_PAYOUT_TYPE.CLICK,
    OFFER_PAYOUT_TYPE.CONVERSION,
    OFFER_PAYOUT_TYPE.THOUSAND_IMPRESSIONS,
  ].includes(tempPayoutType);

  const requiresPercent = tempPayoutType === OFFER_PAYOUT_TYPE.SALE;

  const requiresBoth = tempPayoutType === OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE;

  if (requiresFlat && (tempFatPayout === null || tempFatPayout < 0)) {
    errors.push('Flat payout is required and must be non-negative.');
  }

  if (requiresPercent && (tempPercentPayout === null || tempPercentPayout < 0)) {
    errors.push('Percent payout is required and must be non-negative.');
  }

  if (requiresBoth) {
    if (tempFatPayout === null || tempFatPayout < 0) {
      errors.push('Flat payout is required and must be non-negative.');
    }
    if (tempPercentPayout === null || tempPercentPayout < 0) {
      errors.push('Percent payout is required and must be non-negative.');
    }
  }

  // Disable button if any validation fails
  setDisableAddPayout(errors.length > 0);

  return errors;
};

export const trackingMethodLabels: Record<OFFER_TRACKING_TYPE, string> = {
  [OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK]: 'Javascript Postback',
  [OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID]: 'Server Postback w/ Transaction ID',
  [OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID]: 'Server Postback w/ Partner ID',
  [OFFER_TRACKING_TYPE.HTTPS_IMAGE_PIXEL]: 'HTTPS Image Pixel',
  [OFFER_TRACKING_TYPE.HTTPS_IFRAME_PIXEL]: 'HTTPS iFrame Pixel',
};
