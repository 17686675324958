import * as React from 'react';
import Helmet from 'react-helmet';
import { PostHogProvider } from 'posthog-js/react';
// import { hot } from 'react-hot-loader/root';

import { config } from '@config/index';
import { appConfig } from '@frontend/app/config';
import { App, PublicPages } from '@frontend/app/containers';
import { AuthProvider } from '@frontend/context/authContext';
import { AppContextProvider } from '@frontend/context/AppContext';
import { MessagingContextProvider } from '@frontend/context/MessagingContext';

import { IHelmetMetaTags } from '../types/MetaTags';

const helmetHead = {
  script: [],
};

interface IMainProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientInfo?: any;
  customMeta?: IHelmetMetaTags;
  customTitle?: string;
}

const Main: React.FunctionComponent<IMainProps> = (props) => {
  const defaultMeta = [...appConfig.app.head?.meta];
  const meta = props.customMeta ? [...defaultMeta, ...props.customMeta] : defaultMeta;

  const title = props.customTitle || appConfig.app.head.title;

  return (
    <MessagingContextProvider>
      <Helmet {...appConfig.app} {...appConfig.app.head} meta={meta} title={title} {...helmetHead}>
        <link rel="stylesheet" href="/public/tailwind.css" />
      </Helmet>
      <PostHogProvider
        apiKey={config.posthogApiKey}
        options={{
          api_host: config.posthogHost,
          capture_pageview: false,
          capture_pageleave: true,
        }}
      >
        {props.clientInfo ? (
          <AuthProvider clientInfo={props.clientInfo}>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </AuthProvider>
        ) : (
          <PublicPages />
        )}
      </PostHogProvider>
    </MessagingContextProvider>
  );
};

// export default hot(Main);
export default Main;
