import * as React from 'react';
import {
 Button, Dropdown, Menu, Select, Typography,
} from '@revfluence/fresh';
import { DeleteFilled } from '@ant-design/icons';
import CurrencyInput from '@frontend/app/components/CurrencyInput';
import { CheckIcon, EllipsisVerticalIcon } from '@revfluence/fresh-icons/solid';
import { BudgetGranularity } from '@frontend/app/types/Budget';
import styles from './FiscalYearProperties.module.scss';
import { BudgetActionMode, FiscalYearOption } from '../../types/IBudget';

const { Text } = Typography;
const { useMemo, useCallback } = React;

interface IProps {
  id: string;
  fiscalYear: number | string;
  totalBudget: number;
  fiscalYearsOptions: FiscalYearOption[];
  granularity: BudgetGranularity;
  deleteFiscalYear: (id: string) => void;
  updateAmountForFiscalYear: (id: string, amount: number) => void;
  updateFiscalYear: (id: string, fiscalYear: number | string) => void;
  showDelete: boolean;
  mode: BudgetActionMode;
  updateGranularityForFiscalYear: (id: string, granularity: BudgetGranularity) => void;
  showFiscalYear?: boolean;
}

const FiscalYearProperties: React.FC<IProps> = (props) => {
  const {
    id,
    totalBudget,
    fiscalYear,
    fiscalYearsOptions,
    granularity,
    deleteFiscalYear,
    showDelete,
    updateAmountForFiscalYear,
    updateFiscalYear,
    updateGranularityForFiscalYear,
    mode,
    showFiscalYear = true,
  } = props;
  const handleMenuChange = useCallback(
    (e) => {
      updateGranularityForFiscalYear(id, e.key as BudgetGranularity);
    },
    [updateGranularityForFiscalYear, id],
  );
  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuChange}>
        <Menu.Item
          key={BudgetGranularity.QUARTERLY}
          icon={granularity === BudgetGranularity.QUARTERLY ? <CheckIcon /> : null}
        >
          Quarterly
        </Menu.Item>
        <Menu.Item
          key={BudgetGranularity.YEARLY}
          icon={granularity === BudgetGranularity.YEARLY ? <CheckIcon /> : null}
        >
          Yearly
        </Menu.Item>
      </Menu>
    ),
    [granularity, handleMenuChange],
  );
  return (
    <>
      <div className={styles.fiscalYearPropertiesContainer}>
        {showFiscalYear && (
        <div className={styles.fiscalYear}>
          <Text>Fiscal Year</Text>
          <Select
            value={fiscalYear}
            onChange={(e) => updateFiscalYear(id, e)}
            disabled={mode === BudgetActionMode.EDIT}
          >
            {fiscalYearsOptions.map((option) => (
              <Select.Option value={option.value} key={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        )}
        <div className={styles.budgetAmount}>
          <Text>Budget Amount</Text>
          <CurrencyInput
            value={totalBudget}
            width="100%"
            onChange={(amount) => updateAmountForFiscalYear(id, amount)}
          />
        </div>
        <div>
          {fiscalYear !== 'N/A' && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button icon={<EllipsisVerticalIcon />} style={{ width: '40px' }} />
            </Dropdown>
          )}
        </div>
        <div className={styles.deleteIcon}>
          {showDelete && <Button icon={<DeleteFilled size={16} />} onClick={() => deleteFiscalYear(id)} />}
        </div>
      </div>
    </>
  );
};

export default FiscalYearProperties;
