import React from 'react';
import { useProgramsQuery, useCommunitiesQuery } from '@frontend/app/hooks';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { Project, Group } from '../types';

interface FilterFormData {
  projects: Project[];
  groups: Group[];
  isFetchingProjects: boolean;
  isFetchingGroups: boolean;
  activeProjectIds: number[];
}

export const useFilterFormData = (): FilterFormData => {
  const { data: { programs = [] } = {}, loading: isFetchingProjects } = useProgramsQuery();
  const { data: { communities: groups = [] } = {}, loading: isFetchingGroups } = useCommunitiesQuery();

  const activeProjects = React.useMemo(
    () => [...programs].filter((program) => program.status === ProjectStatus.Active),
    [programs],
  );

  const mappedProjects = React.useMemo(
    () => [...programs].sort((a, b) => a.title.localeCompare(b.title)).map((p) => ({ id: p.id, name: p.title })),
    [programs],
  );

  const mappedGroups = React.useMemo(() => groups.map((g) => ({ id: g.id, name: g.title })), [groups]);

  const activeProjectIds = React.useMemo(() => activeProjects.map((p) => p.id), [activeProjects]);

  return {
    projects: mappedProjects,
    groups: mappedGroups,
    isFetchingProjects,
    isFetchingGroups,
    activeProjectIds,
  };
};
