import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { ChartNetworkIcon } from '@revfluence/fresh-icons/regular/esm';
import { SectionCard } from '../components/SectionCard';
import { CampaignWorkletsList } from '../CampaignWorkletsList';
import { TWorkletWithoutTypename } from '../CampaignContext';

interface WorkflowStagesSectionProps {
  defaultWorklets?: TWorkletWithoutTypename[];
  parentSpecKey?: string;
  projectId?: number;
}

export const WorkflowStagesSection: React.FC<WorkflowStagesSectionProps> = ({
  defaultWorklets,
  parentSpecKey,
  projectId,
}) => (
  <SectionCard>
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <ChartNetworkIcon />
          <P>Stages</P>
        </div>
        <H3 className="font-medium mb-0">Manage and track campaign progress with stages</H3>
        <span>
          Workflow stages enable you to track and manage tasks as well as member statuses throughout the campaign.
        </span>
      </div>

      <CampaignWorkletsList
        defaultWorklets={defaultWorklets}
        parentSpecKey={parentSpecKey}
        projectId={projectId}
      />
    </div>
  </SectionCard>
);
