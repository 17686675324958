import React from 'react';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';

interface PaymentDetailSectionProps {
  paymentDetails: GetPaymentDetailsByIdsQuery_payments[];
}

export const PaymentDetailSection: React.FC<PaymentDetailSectionProps> = ({ paymentDetails }) => {
  if (paymentDetails?.length !== 1) return null;
  const selectedPayment = paymentDetails?.[0];

  return (
    <section>
      <p className="m-0 text-sm font-medium">Payment Details</p>
      <Separator className="my-2" />
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span className="text-gray-400">Amount</span>
          <span>{`$${selectedPayment?.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0'} USD`}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-400">Type</span>
          <span>{selectedPayment?.type}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-400">Processed By</span>
          <span>{selectedPayment?.initiatedBy}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-400">Payment ID</span>
          <span>{selectedPayment?.paymentId}</span>
        </div>
      </div>
    </section>
  );
};
