import * as React from 'react';

import {
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  List,
  Row,
  Typography,
} from '@revfluence/fresh';
import { TCheckoffGuidelines } from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage/ContentDetails/types';
import { toolbarModules, toolbarOptions } from '@frontend/app/utils/richTextEditormodules';
import { isRichTextEmpty } from '@frontend/app/utils/richTextEditorUtils';
import { RichTextEditor } from '../RichTextEditor';
import styles from './Comments.module.scss';

interface IProps {
  instructionText: string
  onCancel(): void;
  comment: string;
  onCommentChange(comment: string): void;
  guidelineCheckOffs: TCheckoffGuidelines[];
  onGuidelineChange(guidelines: TCheckoffGuidelines[]): void;
  submissionCTA: string;
  onSubmissionClick(): void;
  instructionDescription?: string | null;
  isAdminRole?: boolean;

}

export const ActionFeedbackForm = ({
  onCancel,
  comment,
  onCommentChange,
  guidelineCheckOffs,
  onGuidelineChange,
  submissionCTA,
  onSubmissionClick,
  instructionText,
  instructionDescription,
  isAdminRole,
}: IProps) => (
  <>
    <div style={{ marginTop: '24px' }} />

    {!isAdminRole && (
      <Typography.Title level={5} style={{ fontSize: '14px' }}>
        {instructionText}
      </Typography.Title>
    )}

    <Collapse
      expandIconPosition="start"
      defaultActiveKey={['guideline-category-0']}
      className={styles.feedbackFormCollapsePanel}
    >
      {guidelineCheckOffs.map(({ title, id, rules }, category) => (
        <Collapse.Panel
          header={title}
          extra={<Badge count={rules.filter((r) => r.required).length} />}
          key={`guideline-category-${id}`}
        >
          <List style={{ backgroundColor: '#ffffff', margin: '-16px' }}>
            {rules.map(({ description, required, id }, index) => (
              <List.Item
                style={{ padding: '16px' }}
                key={`guideline-rule-${category}-${id}`}
              >
                <Checkbox
                  checked={required}
                  onChange={(e) => {
                    guidelineCheckOffs[category].rules[index].required = e.target.checked;

                    onGuidelineChange([...guidelineCheckOffs]);
                  }}
                >
                  <Typography.Text>{description}</Typography.Text>
                </Checkbox>
              </List.Item>
            ))}
          </List>
        </Collapse.Panel>
      ))}
    </Collapse>

    <Divider
      style={{
        width: 'calc(100% + 48px)',
        marginRight: '-24px',
        marginLeft: '-24px',
      }}
    />
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text>
          {instructionText}
          {instructionDescription && isAdminRole && (
            <Typography.Text type="secondary">
              {' '}
              (optional)
            </Typography.Text>
          )}
        </Typography.Text>
      </Col>
      <Col span={24} className={styles.instructionDesc}>
        {instructionDescription && (
          <Typography.Text type="secondary">
            {instructionDescription}
          </Typography.Text>
        )}
      </Col>
      <Col span={24}>
        <RichTextEditor
          toolbarOptions={toolbarOptions}
          toolbarModules={toolbarModules}
          boundSelector="#commentEditor"
          placeholder="Enter your message..."
          value={comment}
          onChange={(e) => onCommentChange(e)}
          className={styles.commentEditor}
        />
      </Col>
      <Col span={24}>
        <Row gutter={8} justify="end">
          <Col>
            <Button onClick={onCancel}>Cancel</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={onSubmissionClick}
              disabled={isAdminRole ? false : isRichTextEmpty(comment)}
            >
              {submissionCTA}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
