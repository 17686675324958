import React, { useState, useEffect } from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Textarea } from '@frontend/shadcn/components/ui/textarea';
import { cn } from '@frontend/shadcn/lib/utils';

interface ExpandableTextInputProps {
  /** The current value of the input */
  value: string;
  /** Callback fired when the value changes */
  onChange: (value: string) => void;
  /** Optional CSS classes to apply to the container */
  className?: string;
  /** Label for the input field */
  label: string;
  /** Text to show on add button */
  addButtonText?: string;
  /** Text to show on remove button */
  removeButtonText?: string;
  /** Placeholder text for the textarea */
  placeholder?: string;
  /** ID for the input field - used for accessibility */
  id?: string;
  /** Whether the input is required */
  required?: boolean;
  /** Maximum length of the text */
  maxLength?: number;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Error message to display */
  error?: string;
  /** Initial expanded state */
  defaultExpanded?: boolean;
  /** Minimum height of the textarea */
  minHeight?: number;
  /** Maximum number of preview lines */
  previewLines?: number;
}

export function ExpandableTextInput({
  value,
  onChange,
  className,
  label,
  addButtonText = `Add ${label}`,
  removeButtonText = `Remove ${label}`,
  placeholder = `Enter ${label.toLowerCase()} here...`,
  id = 'expandable-input',
  required = false,
  maxLength,
  disabled = false,
  error,
  defaultExpanded = false,
  minHeight = 80,
  previewLines = 3,
}: ExpandableTextInputProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded || !!value);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (value && !isExpanded) {
      setIsExpanded(true);
    }
  }, [value, isExpanded]);

  const handleRemove = () => {
    if (disabled) return;
    onChange('');
    setIsExpanded(false);
    setIsEditing(false);
  };

  const handleAdd = () => {
    if (disabled) return;
    setIsExpanded(true);
    setIsEditing(true);
  };

  const handlePreviewClick = () => {
    if (disabled) return;
    setIsEditing(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handlePreviewClick();
    }
  };

  return (
    <div className={cn('space-y-2', className)} role="region" aria-label={label}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {isExpanded ? (
            <Button
              variant="ghost"
              onClick={handleRemove}
              className="px-0 hover:bg-transparent"
              disabled={disabled}
              aria-label={removeButtonText}
            >
              <span className="text-sm font-medium hover:underline">- {removeButtonText}</span>
            </Button>
          ) : (
            <Button
              variant="ghost"
              onClick={handleAdd}
              className="flex items-center gap-2 px-0 hover:bg-transparent"
              disabled={disabled}
              aria-label={addButtonText}
            >
              <span className="text-sm font-medium hover:underline">+ {addButtonText}</span>
            </Button>
          )}
          {required && <span className="text-destructive text-sm">*</span>}
        </div>
        {maxLength && value && (
          <span className="text-xs text-muted-foreground">
            {value.length}/{maxLength}
          </span>
        )}
      </div>

      {isExpanded && (
        <>
          {!isEditing && value ? (
            <div
              role="button"
              tabIndex={0}
              onClick={handlePreviewClick}
              onKeyDown={handleKeyPress}
              className={cn(
                'w-full p-3 rounded-md border border-input bg-background text-sm',
                'text-muted-foreground cursor-pointer hover:border-accent-foreground transition-colors',
                `line-clamp-${previewLines}`,
                disabled && 'opacity-50 cursor-not-allowed',
                error && 'border-destructive',
              )}
              style={{ minHeight }}
              aria-label={`Click to edit ${label.toLowerCase()}`}
            >
              {value}
            </div>
          ) : (
            <div className="space-y-1">
              <Textarea
                id={id}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={cn(
                  'w-full placeholder:text-grey-3 placeholder:font-normal',
                  error && 'border-destructive',
                  disabled && 'opacity-50 cursor-not-allowed',
                )}
                style={{ minHeight }}
                placeholder={placeholder}
                autoFocus={isEditing}
                disabled={disabled}
                maxLength={maxLength}
                aria-invalid={!!error}
                aria-required={required}
                aria-describedby={error ? `${id}-error` : undefined}
              />
              {error && (
                <p className="text-sm text-destructive" id={`${id}-error`} role="alert">
                  {error}
                </p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
