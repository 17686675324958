import {
  Button, Card, Col, Empty, Row, Select, Space, Typography,
} from '@revfluence/fresh';
import React from 'react';
import { ChartPieIcon } from '@revfluence/fresh-icons/regular/esm';
import { SettingOutlined } from '@ant-design/icons';
import { TBudgetFiscalPeriod } from '@frontend/applications/PaymentsApp/types';
import { useGetBudgetFiscalPeriod } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalPeriod';
import { LoadSpinner } from '@components';
import { DataFormat } from '@frontend/applications/AffiliatesApp/utils';
import { useGetProjectBudgetDashboardData } from '@frontend/app/hooks/budgetAllocation/useGetProjectBudgetDashboardData';
import { BuildingColumnsIcon } from '@revfluence/fresh-icons/solid';
import { useHistory } from 'react-router-dom';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { Empty as RefreshEmpty } from '@frontend/app/refresh-components';
import BudgetStatisticsBar from '../../BudgetPage/components/BudgetStatisticsBar';
import { IBudgetStat } from '../../BudgetPage/types/IBudgetStat';
import styles from './WidgetProjectBudget.module.scss';
import { IBudget } from '../../BudgetPage/types/IBudget';

const {
  memo, useMemo, useState, useEffect,
} = React;
const { Option } = Select;
const { Title, Text, Paragraph } = Typography;
interface IProps {
  programId: number | null
}

export const WidgetProjectBudget: React.FC<IProps> = memo((props) => {
  const { programId } = props;
  const history = useHistory();
  const [financialYear, setFinancialYear] = useState<TBudgetFiscalPeriod[]>([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState<TBudgetFiscalPeriod | null>(null);
  const [stats, setStats] = useState<IBudgetStat[]>([]);
  const [budgets, setBudgets] = useState<IBudget[]>([]);

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const { loading: budgetFiscalPeriodLoading, budgetFiscalPeriod } = useGetBudgetFiscalPeriod({});
  const { loading: loadingProjectBudgetDashboardData, projectBudgetDashboardData } = useGetProjectBudgetDashboardData({
    skip: !programId || !selectedFinancialYear || !selectedFinancialYear.fiscalYear,
    variables: selectedFinancialYear && selectedFinancialYear.fiscalYear ? {
      programId,
      fiscalYear: selectedFinancialYear.fiscalYear,
    } : undefined,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!budgetFiscalPeriodLoading && budgetFiscalPeriod.length) {
      setFinancialYear(budgetFiscalPeriod);
      setSelectedFinancialYear(budgetFiscalPeriod.find((period) => period.isCurrentYear) || null);
    }
  }, [budgetFiscalPeriodLoading, budgetFiscalPeriod]);

  useEffect(() => {
    if (!loadingProjectBudgetDashboardData && projectBudgetDashboardData) {
      if (projectBudgetDashboardData?.budgetAccounts?.length) {
        setBudgets(projectBudgetDashboardData.budgetAccounts.map((projectBudget) => ({
          key: projectBudget.budgetId,
          id: projectBudget.budgetId,
          source: projectBudget.budgetName,
          projectBudget: projectBudget.totalAmount,
          totalSpend: projectBudget.spentAmount,
          remainingBudget: projectBudget.availableAmount,
          quarterDistributions: projectBudget.quarterDistributions,
        })));
      } else {
        setBudgets([]);
      }
      const newStats: IBudgetStat[] = [
        {
          value: projectBudgetDashboardData.sourceCount,
          label: 'Budget Accounts',
          dataFormat: DataFormat.Integer,
        },
        {
          value: projectBudgetDashboardData.totalAmount,
          label: "Project's Budget",
          dataFormat: DataFormat.Money,
          prefix: '$',
        },
        {
          value: projectBudgetDashboardData.spentAmount,
          label: 'Spent in Project',
          dataFormat: DataFormat.Money,
          prefix: '$',
        },
        {
          value: projectBudgetDashboardData.availableAmount,
          label: 'Available in Project',
          dataFormat: DataFormat.Money,
          prefix: '$',
        },
      ];
      setStats(newStats);
    }
  }, [loadingProjectBudgetDashboardData, projectBudgetDashboardData]);

  const isSingleFiscalYear = useMemo(() => (
    financialYear?.length === 1 && financialYear[0].fiscalYear.toString() === 'N/A'
  ), [financialYear]);

  const cardTitle = useMemo(() => (
    <Row align="middle">
      <Col sm={24} md={12}>
        { isRefreshUIEnabled ? (
          <div className="flex items-center gap-2">
            <ChartPieIcon fontSize={16} className="text-icon-grey" />
            <span className="font-medium text-base">Budget Breakdown</span>
          </div>
        ) : (
          <Title level={5}>
            <Row align="middle" style={{ gap: '8px' }}>
              <ChartPieIcon />
              <Text>Project Budget Breakdown</Text>
            </Row>
          </Title>
        )}
      </Col>
      <Col sm={24} md={12}>
        {
          !!financialYear.length && !isSingleFiscalYear && (
            <Row justify="end" align="middle" style={{ gap: '10px' }}>
              <Select value={selectedFinancialYear.fiscalYear} style={{ width: 120 }} onChange={(e) => setSelectedFinancialYear(financialYear.find((fy) => fy.fiscalYear === e))}>
                {
                  financialYear.map((year) => <Option key={year.fiscalYear} value={year.fiscalYear}>{year.fiscalYearLabel}</Option>)
                }
              </Select>
              <Button
                className={styles.settingsButton}
                icon={<SettingOutlined />}
                onClick={() => programId && history.push(`/projects/${programId}/settings/budget`)}
              />
            </Row>
          )
        }
      </Col>
    </Row>
  ), [isRefreshUIEnabled, financialYear, selectedFinancialYear?.fiscalYear, programId, history, isSingleFiscalYear]);
  const emptyState = useMemo(() => (
    <Row justify="center">
      {isRefreshUIEnabled ? (
        <RefreshEmpty
          icon={BuildingColumnsIcon}
          title="No budget assigned to this project"
          description="Once you assign budget, you can view this project’s budget performance here."
          primaryCtaProps={{
            text: 'Setup Budget',
            onClick: () => programId && history.push(`/projects/${programId}/settings/budget`),
          }}
        />
        ) : (
          <Empty
            size="small"
            image={<BuildingColumnsIcon />}
            description={(
              <>
                <Title level={5}>
                  No budget assigned to this project
                </Title>
                <Paragraph type="secondary">
                  Once you assign budget, you can view this project’s budget performance here.
                </Paragraph>

                <Row justify="center">
                  <Space size={12}>
                    <Button type="primary" onClick={() => programId && history.push(`/projects/${programId}/settings/budget`)}>Setup Budget</Button>
                  </Space>
                </Row>
              </>
                )}
          />
        )}
    </Row>
  ), [history, isRefreshUIEnabled, programId]);

  if (budgetFiscalPeriodLoading || budgetFiscalPeriodLoading) {
    return <LoadSpinner />;
  }

  if (isRefreshUIEnabled) {
    return (
      <ShadCnCard>
        <CardHeader>
          {cardTitle}
        </CardHeader>
        <CardContent>
          {
            budgets.length ? (
              <Row justify="space-between" gutter={16}>
                <Col span={24}>
                  <BudgetStatisticsBar stats={stats} />
                </Col>
              </Row>
            ) : emptyState
          }
        </CardContent>
      </ShadCnCard>
    );
  }
  return (
    <Card
      headStyle={{ border: 'none', padding: '0px' }}
      className={styles.projectBudgetContainer}
      bodyStyle={{ padding: '0px' }}
      title={cardTitle}
    >
      {
        budgets.length ? (
          <Row justify="space-between" gutter={16}>
            {/* Will use this later */}
            {/*
              <Col xs={24} lg={8}>
                  <BudgetStatistics label="Total Budget" status={BudgetStatus.OVER_BUDGET} tooltip="Total Budget" />
              </Col>
              <Col xs={24} lg={8}>
                <BudgetStatistics label="Spent Analysis" status={BudgetStatus.OVER_SPENT} tooltip="Spent Analysis" />
              </Col>
              <Col xs={24} lg={8}>
                <BudgetStatistics label="Committed Budget" tooltip="Committed Budget" />
              </Col>
            */}
            <Col span={24}>
              <BudgetStatisticsBar stats={stats} />
            </Col>
          </Row>
        ) : emptyState
      }
    </Card>
  );
});

export default WidgetProjectBudget;
WidgetProjectBudget.displayName = 'WidgetProjectBudget';
