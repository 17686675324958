import * as React from 'react';
import { Form, Checkbox, Radio } from 'antd';
import { Alert, Typography } from '@revfluence/fresh';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from '../../../OfferForm.scss';
import { TrackPayout } from './TrackPayout';

const THIRD_PARTY_TRACKING = {
  IMPACT: 'IMPACT',
  AWIN: 'AWIN',
  SHAREASALE: 'SHAREASALE',
};

const OfferUrlThirdPartySelection = ({ values, handleFormAsyncActions }) => {
  const { thirdPartyTrackingUrl } = useClientFeatures();
  const { Text } = Typography;

  // Function to reset UTM fields and disable them when third-party tracking is enabled
  const resetUTMFields = () => {
    handleFormAsyncActions({
      action: 'UpdateField',
      payload: {
        key: 'isAdvanceUrlEnable',
        value: '',
      },
    });
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;

    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'isThirdPartyIntegration', value: !!checked },
    });

    if (checked) {
      resetUTMFields();
      handleFormAsyncActions({
        action: 'UpdateField',
        payload: { key: 'thirdPartyTracking', value: THIRD_PARTY_TRACKING.IMPACT },
      });
    } else {
      handleFormAsyncActions({
        action: 'UpdateField',
        payload: { key: 'thirdPartyTracking', value: null },
      });
      handleFormAsyncActions({
        action: 'UpdateField',
        payload: { key: 'trackThirdPartyPayout', value: false },
      });
    }
  };

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;

    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'thirdPartyTracking', value: selectedValue },
    });
  };

  return (
    <>
      {thirdPartyTrackingUrl && (
        <div>
          <Form.Item>
            <Checkbox onChange={handleCheckboxChange} checked={values.isThirdPartyIntegration}>
              This tracking URL was generated through an affiliate platform outside of Aspire.
            </Checkbox>

            <div className={styles.indentationMargin}>
              <Radio.Group
                className={`${styles.radioGroup} ${styles.marginBottom8}`}
                onChange={handleRadioChange}
                value={values.thirdPartyTracking}
              >
                <Radio
                  value={THIRD_PARTY_TRACKING.IMPACT}
                  disabled={!values.isThirdPartyIntegration}
                  className={styles.radio}
                >
                  Impact
                </Radio>
                <Radio value={THIRD_PARTY_TRACKING.AWIN} disabled className={styles.radio}>
                  {' '}
                  Awin
                </Radio>
                <Radio value={THIRD_PARTY_TRACKING.SHAREASALE} disabled className={styles.radio}>
                  ShareASale
                </Radio>
              </Radio.Group>

              <div>
                <Alert
                  message=""
                  showIcon
                  description={
                    <Text>Please be sure to select the correct platform as this will impact all link generation for this offer.</Text>
                  }
                  type="warning"
                  className={styles.marginOnAlert}
                />
              </div>
            </div>
            {values.isThirdPartyIntegration && (
              <TrackPayout values={values} handleFormAsyncActions={handleFormAsyncActions} />
            )}
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default OfferUrlThirdPartySelection;
