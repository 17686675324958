import { useCallback, useMemo } from 'react';
import { useQuery } from 'urql';
import { getSocialAnalyticsPosts } from '../graphql';
import { QueryVariables } from '../types';

interface UsePostsDataProps {
  clientId: string | undefined;
  tab: string | undefined;
  queryVariables: QueryVariables;
}

export const usePostsData = ({ clientId, tab, queryVariables }: UsePostsDataProps) => {
  const [{ data: postsData, fetching: fetchingPosts }, refetchPosts] = useQuery({
    query: getSocialAnalyticsPosts,
    variables: {
      clientId: clientId || '',
      startDate: queryVariables.startDate || '',
      endDate: queryVariables.endDate || '',
      postTypes: queryVariables.postTypes || [],
      includeEstimates: queryVariables.includeEstimates || false,
      // includeUnassigned: !queryVariables.includeUnassigned || false,
      networks: queryVariables.networks || [],
      projectIds: queryVariables.projectIds,
      groupIds: queryVariables.groupIds,
      memberIds: queryVariables.memberIds,
      segments: queryVariables.segments,
    },
    pause: !clientId || tab !== 'posts',
  });

  const handleRefresh = useCallback(() => {
    if (tab === 'posts') {
      refetchPosts({ requestPolicy: 'network-only' });
    }
  }, [refetchPosts, tab]);

  // Apply client-side filtering for memberIds
  const filteredData = useMemo(() => {
    if (!postsData?.client?.socialAnalytics?.posts) {
      return postsData;
    }

    const { memberIds } = queryVariables;
    if (!memberIds?.length) {
      return postsData;
    }

    const memberIdSet = new Set(memberIds);
    const filteredPosts = postsData.client.socialAnalytics.posts.results.filter(
      (post) => post.member && memberIdSet.has(post.member.id),
    );

    return {
      ...postsData,
      client: {
        ...postsData.client,
        socialAnalytics: {
          ...postsData.client.socialAnalytics,
          posts: {
            ...postsData.client.socialAnalytics.posts,
            results: filteredPosts,
            pagination: {
              ...postsData.client.socialAnalytics.posts.pagination,
              totalCount: filteredPosts.length,
            },
          },
        },
      },
    };
  }, [postsData, queryVariables]);

  return {
    data: filteredData,
    fetching: fetchingPosts,
    handleRefresh,
  };
};
