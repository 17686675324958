import { useMemo } from 'react';
import {
 find, isUndefined, keyBy, map, size, toNumber, upperFirst,
} from 'lodash';
import { Operator } from '@frontend/app/types/MemberSearch';
import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import { useFeatureFlagVerbiage } from '@frontend/app/hooks';
import { pluralize } from '@frontend/app/utils/strings';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { FieldType } from '@frontend/app/types/Fields';
import moment from 'moment';
import numeral from 'numeral';
import { IFilter } from './model';

export const useFilterItemRenderValue = (filter: IFilter, fields: IField[]): string => {
  const verbiage = useFeatureFlagVerbiage();

  const fieldsByIdentifier = useMemo(
    () => keyBy(fields, 'field'),
    [fields],
  );

  return useMemo(() => {
    const operator = find([
      Operator.EQUAL,
      Operator.NOT_EQUAL,
      Operator.BETWEEN,
      Operator.GREATER_THAN,
      Operator.LESS_THAN,
      Operator.IN_THE_LAST,
      Operator.IN_THE_NEXT,
      Operator.MORE_THAN_AGO,
      Operator.CONTAINS,
      Operator.NOT_NULL,
      Operator.IS_NULL,
    ], (op) => !isUndefined(filter[op]));

    const value = filter[operator];

    const {
      memberFieldSchemaId,
      column,
      relation,
      relationProp,
    } = filter;

    switch (column) {
      case SpecialFilters.PROGRAMS: {
        const count = size(value?.programIds);
        if (operator === Operator.NOT_NULL) {
          return `Has ${verbiage.Programs}`;
        } else if (operator === Operator.IS_NULL) {
          return `Has no ${verbiage.Programs}`;
        } else if (operator === Operator.NOT_EQUAL) {
          return `${count} ${pluralize(count, verbiage.Program, verbiage.Programs)} excluded`;
        }
        return `${count} ${pluralize(count, verbiage.Program, verbiage.Programs)} selected`;
      }

      case SpecialFilters.COMMUNITIES: {
        const count = size(value?.communityIds);
        if (operator === Operator.NOT_NULL) {
          return `Has ${verbiage.Communities}`;
        } else if (operator === Operator.IS_NULL) {
          return `Has no ${verbiage.Communities}`;
        } else if (operator === Operator.NOT_EQUAL) {
          return `${count} ${pluralize(count, verbiage.Community, verbiage.Communities)} excluded`;
        }
        return `${count} ${pluralize(count, verbiage.Community, verbiage.Communities)} selected`;
      }

      case SpecialFilters.INVITED_PROGRAMS: {
        const count = size(value?.programIds);
        if (operator === Operator.NOT_NULL) {
          return `Has Invited ${verbiage.Programs}`;
        } else if (operator === Operator.IS_NULL) {
          return `Has no Invited ${verbiage.Programs}`;
        } else if (operator === Operator.NOT_EQUAL) {
          return `Not invited to ${count} ${pluralize(count, verbiage.program, verbiage.programs)}`;
        }
        return `Invited to ${count} ${pluralize(count, verbiage.program, verbiage.programs)}`;
      }

      case SpecialFilters.SUBMITTED_PROGRAMS: {
        const count = size(value?.programIds);
        if (operator === Operator.NOT_NULL) {
          return `Has Submitted ${verbiage.Programs}`;
        } else if (operator === Operator.IS_NULL) {
          return `Has no Submitted ${verbiage.Programs}`;
        } else if (operator === Operator.NOT_EQUAL) {
          return `Not submitted to ${count} ${pluralize(count, verbiage.program, verbiage.programs)}`;
        }
        return `Submitted to ${count} ${pluralize(count, verbiage.program, verbiage.programs)}`;
      }

      case SpecialFilters.REJECTED_PROGRAMS: {
        const count = size(value?.programIds);
        if (operator === Operator.NOT_NULL) {
          return `Has Rejected ${verbiage.Programs}`;
        } else if (operator === Operator.IS_NULL) {
          return `Has no Rejected ${verbiage.Programs}`;
        } else if (operator === Operator.NOT_EQUAL) {
          return `Not rejected from ${count} ${pluralize(count, verbiage.program, verbiage.programs)}`;
        }
        return `Rejected from ${count} ${pluralize(count, verbiage.program, verbiage.programs)}`;
      }

      case SpecialFilters.ACTIVATIONS: {
        const count = size(value);
        if (operator === Operator.NOT_NULL) {
          return 'Has Activations';
        } else if (operator === Operator.IS_NULL) {
          return 'Has no Activations';
        } else if (operator === Operator.NOT_EQUAL) {
          return `${count} ${pluralize(count, 'Activation')} excluded`;
        }
        return `${count} ${pluralize(count, 'Activation')} selected`;
      }

      case SpecialFilters.TAGS: {
        const count = size(value);
        if (operator === Operator.NOT_NULL) {
          return 'Has Tags';
        } else if (operator === Operator.IS_NULL) {
          return 'Has no Tags';
        } else if (operator === Operator.NOT_EQUAL) {
          return `${count} ${pluralize(count, 'Tag')} excluded`;
        }
        return `${count} ${pluralize(count, 'Tag')} selected`;
      }

      case SpecialFilters.HIGHLIGHTS: {
        const count = size(value);
        if (operator === Operator.NOT_NULL) {
          return 'Has Highlights';
        } else if (operator === Operator.IS_NULL) {
          return 'Has no Highlights';
        } else if (operator === Operator.NOT_EQUAL) {
          return `${count} ${pluralize(count, 'Highlight')} excluded`;
        }
        return `${count} ${pluralize(count, 'Highlight')} selected`;
      }

      case SpecialFilters.OWNERS: {
        const count = size(value);
        if (operator === Operator.NOT_NULL) {
          return 'Has Owners';
        } else if (operator === Operator.IS_NULL) {
          return 'Has no Owners';
        } else if (operator === Operator.NOT_EQUAL) {
          return `${count} ${pluralize(count, 'Owner')} excluded`;
        }
        return `${count} ${pluralize(count, 'Owner')} selected`;
      }

      case SpecialFilters.OVERDUE_REQUIREMENTS: {
        return `${value ? 'Has' : 'No'} due requirements`;
      }

      case SpecialFilters.INCOMPLETE_REQUIREMENTS: {
        return `${value ? 'Has' : 'No'} open requirements`;
      }

      case SpecialFilters.APPLICANT_SOURCE: {
        if (operator === Operator.NOT_NULL) {
          return 'Has source';
        } else if (operator === Operator.IS_NULL) {
          return 'Has no source';
        } else if (!operator) {
          return 'Has source';
        }

        break;
      }

      case SpecialFilters.DATA_SOURCES: {
        return `Sourced from ${value}`;
      }
    }

    const field = memberFieldSchemaId
      ? fieldsByIdentifier[memberFieldSchemaId]
      : (column ? fieldsByIdentifier[column] : fieldsByIdentifier[relation]);
    const name = field ? `${field.headerName}${relationProp ? ` ${upperFirst(relationProp)}` : ''}` : '';
    let formattedValue: string | string[] = value;

    if (field?.type === FieldType.DATE) {
      if (operator === Operator.BETWEEN) {
        formattedValue = map(value, (val) => moment(val).utc().format('YYYY/MM/DD'));
      } else if (
        operator === Operator.IN_THE_LAST || operator === Operator.IN_THE_NEXT || operator === Operator.MORE_THAN_AGO
      ) {
        formattedValue = value && value.toString();
      } else {
        formattedValue = moment(value).utc().format('YYYY/MM/DD');
      }
    } else if (field?.type === FieldType.ARRAY) {
      if (operator === Operator.EQUAL || operator === Operator.CONTAINS) {
        formattedValue = value?.join(', ');
      }
    } else if (field?.type === FieldType.DYNAMIC_SELECT) {
      if (operator === Operator.EQUAL || operator === Operator.NOT_EQUAL) {
        formattedValue = value?.join(', ');
      }
    } else if (field?.type === FieldType.ANNUAL) {
      if (operator === Operator.BETWEEN) {
        formattedValue = map(value, (val) => moment(val).utc().format('MMM D'));
      } else if (
        operator === Operator.IN_THE_LAST || operator === Operator.IN_THE_NEXT || operator === Operator.MORE_THAN_AGO
      ) {
        formattedValue = value && value.toString();
      } else {
        formattedValue = moment(value).utc().format('MMM D');
      }
    } else if (field && field.type === FieldType.NUMBER) {
      if (operator === Operator.BETWEEN) {
        formattedValue = map(value, (val) => val?.toString() || val);
      } else {
        formattedValue = value && value.toString();
      }
    } else if (field && field.type === FieldType.PERCENTAGE) {
      if (operator === Operator.BETWEEN) {
        formattedValue = map(value, (val) => numeral(val).format('0.[00]%'));
      } else {
        formattedValue = numeral(value).format('0.[00]%');
      }
    } else if (field?.type === FieldType.BOOLEAN) {
      formattedValue = value ? 'Yes' : 'No';
    }

    const isPlural = !isNaN(toNumber(formattedValue))
      ? toNumber(formattedValue) !== 1
      : false;

    switch (operator) {
      case Operator.NOT_EQUAL:
        return `${upperFirst(name)} is not ${formattedValue}`;

      case Operator.BETWEEN:
        return `${upperFirst(name)} between ${formattedValue[0]} - ${formattedValue[1]}`;

      case Operator.CONTAINS:
        return `${upperFirst(name)} contains ${formattedValue}`;

      case Operator.GREATER_THAN:
        if (FieldType.DATE === field?.type || FieldType.ANNUAL === field?.type) {
          return `${upperFirst(name)} after ${formattedValue}`;
        }
        return `${upperFirst(name)} greater than ${formattedValue}`;

      case Operator.LESS_THAN:
        if (FieldType.DATE === field?.type || FieldType.ANNUAL === field?.type) {
          return `${upperFirst(name)} before ${formattedValue}`;
        }
        return `${upperFirst(name)} less than ${formattedValue}`;

      case Operator.IN_THE_LAST:
        return `${upperFirst(name)} in the last ${formattedValue} day${isPlural ? 's' : ''}`;

      case Operator.IN_THE_NEXT:
        return `${upperFirst(name)} in the next ${formattedValue} day${isPlural ? 's' : ''}`;

      case Operator.MORE_THAN_AGO:
        return `${upperFirst(name)} more than ${formattedValue} day${isPlural ? 's' : ''} ago`;

      case Operator.NOT_NULL:
        return `${upperFirst(name)} has value`;

      case Operator.IS_NULL:
        return `${upperFirst(name)} has no value`;

      default:
        return `${upperFirst(name)} is ${formattedValue}`;
    }
  }, [filter, fieldsByIdentifier, verbiage]);
};
