import * as React from 'react';
import { useState } from 'react';
import { find, isEmpty } from 'lodash';
import cx from 'classnames';

import { KlaviyoSyncSettings, ResourceType } from '@frontend/app/types/globalTypes';
import { LoadSpinner } from '@components';
import {
  useClientFeatureEnabled,
 useGetKlaviyoSyncSettings, useGetResources, useGetRunningKlaviyoSync, useStartKlaviyoSyncMutation, useUpdateKlaviyoSyncSettings,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { Typography } from '@revfluence/fresh';
import styles from '@frontend/applications/GoogleDriveApp/pages/GdriveSettingsPage.scss';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import KlaviyoSettingsPageEmpty from './KlaviyoSettingsPageEmpty';
import KlaviyoSyncContainer from '../container/KlaviyoSyncContainer';
import SyncProgressContainer from '../container/SyncProgressContainer';
import { useGetTableData } from '../hooks/useGetTableColumns';
import ProjectGroupTable from '../components/ProjectGroupTable';

const { useMemo } = React;

const DEFAULT_CONFIG = {
  groups: [],
  projects: [],
  automatedSync: false,
  syncAllMembers: true,
};

const KlaviyoSettingsPage: React.FC = React.memo(() => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [taskTrackerId, setTaskTrackerId] = React.useState<number>();
  const type = ResourceType.KLAVIYO;
  const { resources, loading, refetch } = useGetResources({
    fetchPolicy: 'no-cache',
  });

  const advancedSettingsEnabled = useClientFeatureEnabled(ClientFeature.KLAVIYO_ADVANCED_SETTINGS);

  const {
    loading: isSettingsLoading,
    refetch: refetchConfigSettings,
      data: {
        getSyncSettings = null,
      } = {},
  } = useGetKlaviyoSyncSettings({
    fetchPolicy: 'network-only',
});

  const [updateSettingsMutation] = useUpdateKlaviyoSyncSettings({
    onCompleted: () => {
      refetchConfigSettings();
    },
  });

  const [startSyncMutation] = useStartKlaviyoSyncMutation();
  const startSync = (projects: number[] = [], groups: number[] = []) => {
    startSyncMutation({
      variables: {
        groups,
        projects,
      },
      onCompleted: (data) => {
        setTaskTrackerId(data.syncJobStatus.id);
      },
    });
    setIsSyncing(true);
  };

  const startSingleSync = async (projects: number[] = [], groups: number[] = []) => {
    await startSyncMutation({
      variables: {
        groups,
        projects,
      },
    });
  };
  useGetRunningKlaviyoSync({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getRunningKlaviyoSync.id > 0) {
          setTaskTrackerId(data.getRunningKlaviyoSync.id);
      }
    },
  });

  const klaviyoResource = useMemo(() => find(resources, (r) => !r.authProvider.userRevokedAccess && r.type === type), [
    resources,
    type,
  ]);
  const config = useMemo(() => {
    if (isSettingsLoading) {
      return null;
    }
    if (isEmpty(getSyncSettings?.config) || getSyncSettings?.config.automatedSync === null) {
      return DEFAULT_CONFIG;
    }
    return getSyncSettings.config;
  },
    [getSyncSettings, isSettingsLoading]);
  const {
    projectRows,
    groupRows,
    totalGroupCount,
    totalProjectCount,
    groupColumnDef,
    currentGroupPage,
    setCurrentGroupPage,
    currentProjectPage,
    setCurrentProjectPage,
    pageSize,
    projectColumnDef,
    loading: rowsLoading,
  } = useGetTableData({
    startSync: startSingleSync,
    config,
    updateSettingsMutation,
    isSettingsLoading,
  });

  const updateSyncSettings = (settings: Partial<KlaviyoSyncSettings>) => {
    updateSettingsMutation({
      variables: {
        config: {
          syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects: config.projects, ...settings,
        },
      },
    });
  };

  return (
    <>
      <Typography.Title level={2}>Klaviyo</Typography.Title>
      <Typography.Text className={cx(styles.text)}>Sync member data to Klaviyo.</Typography.Text>
      <div>
        {loading && !klaviyoResource && <LoadSpinner />}
        {!loading && !klaviyoResource && <KlaviyoSettingsPageEmpty type={type} />}
        {klaviyoResource && (
        <KlaviyoSyncContainer
          refetchResources={refetch}
          activeAccount={klaviyoResource}
          startSync={startSync}
          inProgress={isSyncing}
        />
)}
        {klaviyoResource && taskTrackerId && (
          <SyncProgressContainer
            syncJobId={taskTrackerId}
            onSyncStatusChange={setIsSyncing}
          />
        )}
      </div>
      {advancedSettingsEnabled && (
        <>
          <h2 className="text-xl font-semibold mb-5">Advanced Settings</h2>
          <div className="flex gap-2 mb-4">
            <Switch
              checked={config?.syncAllMembers}
              onCheckedChange={(value) => { updateSyncSettings({ syncAllMembers: value }); }}
            />
            <div>Sync all members created or updated to Klaviyo</div>
          </div>
        </>
      )}
      {advancedSettingsEnabled && !config?.syncAllMembers && (
        <div className="pl-xl">
          <div className="text-grey-4"> Select projects and groups to sync to Klaviyo. Only members created or updated in these selected projects and groups will sync</div>
          <div className="h-6" />
          <ProjectGroupTable
            projectRows={projectRows}
            groupRows={groupRows}
            totalGroupCount={totalGroupCount}
            totalProjectCount={totalProjectCount}
            groupColumnDef={groupColumnDef}
            currentGroupPage={currentGroupPage}
            setCurrentGroupPage={setCurrentGroupPage}
            currentProjectPage={currentProjectPage}
            setCurrentProjectPage={setCurrentProjectPage}
            pageSize={pageSize}
            projectColumnDef={projectColumnDef}
            rowsLoading={rowsLoading}
          />
          <div className="h-6" />
        </div>
      )}
      <div className="flex gap-2 mb-4 pb-6">
        <Switch
          checked={config?.automatedSync}
          onCheckedChange={(value) => { updateSyncSettings({ automatedSync: value }); }}
        />
        <div>Enable daily auto sync</div>
      </div>
    </>
  );
});

KlaviyoSettingsPage.displayName = 'KlaviyoSettingsPage';

export default KlaviyoSettingsPage;
