import { MutationHookOptions, useMutation } from '@apollo/client';
import { DELETE_BRAND_CATALOG_BY_ID_MUTATION } from '../queries/brandCatalog';
import { DeleteBrandCatalogById, DeleteBrandCatalogByIdVariables } from '../queries/types/DeleteBrandCatalogById';

type IOptions = MutationHookOptions<DeleteBrandCatalogById, DeleteBrandCatalogByIdVariables>;

export const useDeleteBrandCatalogById = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<DeleteBrandCatalogById, DeleteBrandCatalogByIdVariables>(
    DELETE_BRAND_CATALOG_BY_ID_MUTATION,
    options,
  );

  return {
    deleteBrandCatalogById: mutate,
    isDeletingBrandCatalogById: loading,
  };
};
