import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_TALENT_AGENTS_BY_AGENT_MEMBER_IDS_QUERY } from '@frontend/app/queries';
import { GetTalentAgentsByAgentMemberIds, GetTalentAgentsByAgentMemberIdsVariables } from '@frontend/app/queries/types/GetTalentAgentsByAgentMemberIds';

type IOptions = QueryHookOptions<GetTalentAgentsByAgentMemberIds, GetTalentAgentsByAgentMemberIdsVariables>;

export const useGetTalentAgentsByAgentMemberIds = (options: IOptions = {}) => (
  useQuery<GetTalentAgentsByAgentMemberIds, GetTalentAgentsByAgentMemberIdsVariables>(GET_TALENT_AGENTS_BY_AGENT_MEMBER_IDS_QUERY, options)
);
