import React from 'react';
import {
 Button, Card, message, Modal,
} from '@revfluence/fresh';
import { useHistory, useParams } from 'react-router-dom';
import { TrashIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetCollectionDetails } from '../hooks/useGetCollectionDetails';
import styles from './CollectionDetails.scss';
import { TabContainer } from '../TabContainer/TabContainer';
import { ProductsTable } from '../../components/ProductsTable/ProductsTable';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { pfaV2Routes } from '../../routes';
import { ProductDetailsDrawerProvider } from '../ProductDetails/ProductDetailsDrawerContext';
import { useDeleteImportedCollectionById } from '../hooks/useDeleteImportedCollectionById';

export const CollectionDetails = () => {
  const { collectionId } = useParams<{ collectionId: string }>();

  const history = useHistory();

  const { collectionDetails } = useGetCollectionDetails({
    variables: {
      collectionId: Number(collectionId),
    },
  });

  const { deleteImportedCollectionById, isDeletingImportedCollectionById } = useDeleteImportedCollectionById({
    onCompleted: (data) => {
      if (data?.deleteImportedCollectionById) {
        message.success('Collection deleted successfully');
        history.push(pfaV2Routes.settings.imports);
      } else {
        message.error('Failed to delete collection');
      }
    },
    onError: (error) => {
      message.error(error?.message || 'Failed to delete collection');
    },
    update: (cache) => {
      cache.evict({ fieldName: 'getImportedCollections' });
      cache.evict({ fieldName: 'getImportedCollectionsStats' });
      cache.evict({ fieldName: 'getImportedProductsStats' });
      cache.gc();
    },
  });

  return (
    <div className={styles.CollectionDetails}>
      <PageHeader
        title={collectionDetails.name}
        image={collectionDetails.images?.[0] || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'}
        backButtonUrl={pfaV2Routes.settings.imports}
        rightContent={(
          <Button
            danger
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                title: 'Are you sure you want to delete this collection?',
                icon: <TriangleExclamationIcon />,
                okText: 'Yes',
                okType: 'danger',
                onOk: () => {
                  deleteImportedCollectionById({ variables: { collectionId: Number(collectionId) } });
                },
              });
            }}
            loading={isDeletingImportedCollectionById}
            icon={<TrashIcon />}
          />
        )}
      />
      <TabContainer>
        <Card>
          {/* <Row gutter={[16, 16]} className={styles.metricsRow}>
            <Col flex={1}>
              <StatsRenderer title="Products" {...stats.totalProducts} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Variants" {...stats.totalVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="In Stock Variants" {...stats.inStockVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Out of Stock Variants" {...stats.outOfStockVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Low Inventory (<10)" {...stats.lowStockVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Pending Products" {...stats.pendingProducts} />
            </Col>
          </Row> */}
          <ProductDetailsDrawerProvider>
            <ProductsTable />
          </ProductDetailsDrawerProvider>
        </Card>
      </TabContainer>
    </div>
  );
};
