import * as React from 'react';
import {
  capitalize, isEmpty, join, map, split,
} from 'lodash';
import { FormInstance } from 'antd/lib/form';
import { OfferFormToolTips } from '@affiliates/components';
import { OFFER_CONVERSION_TYPE, OFFER_PAYOUT_TYPE, OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import {
  FormAsyncAction, IAffiliateLinksFormValues, IShopifyPromoCodeFormOptions, IShopifyPromoCodeFormValues, OfferFormModes, TDisabledMap, TFormValues,
} from '../../types';
import { RefreshOfferName } from './FormElements/RefreshForm/RefreshOfferName';
import { RefreshOfferStatus } from './FormElements/RefreshForm/RefreshOfferStatus';
import { RefreshOfferImage } from './FormElements/RefreshForm/RefreshOfferImage';
import { RefreshOfferDescription } from './FormElements/RefreshForm/RefreshOfferDescription';
import { RefreshShopifySection } from '../ShopifyPromoCodeSectionNew/FormGroup/RefreshShopifySection';
import { RefreshAffiliateLinkSection } from '../AffiliateLinkSection/RefreshAffiliateLinkSection';
import { AdditionalSettings } from './FormElements/RefreshForm/AdditionalSettings';

const { useMemo } = React;

interface IProps {
  disabled: TDisabledMap<TFormValues>;
  disabledAffiliatesLinks: TDisabledMap<IAffiliateLinksFormValues>;
  disabledShopify: TDisabledMap<IShopifyPromoCodeFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  showCostPerClick: boolean;
  values: TFormValues;
  isNewFlow: boolean;
  mode: OfferFormModes;
  formRef: FormInstance;
  hasMembers: boolean;
  initialValues: TFormValues;
  handleFormAsyncActions?: (value: FormAsyncAction) => void,
  onChangeImage?: (file: File) => void;
  source: OFFER_SOURCE;
  initialUrl: string;
  affiliateLinkData: IAffiliateLinksFormValues;
  shopifyData: IShopifyPromoCodeFormValues;
  formOptionData: IShopifyPromoCodeFormOptions;
  isMigrationEnabled: boolean;
  isSubscriptionEnable: boolean;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[];
  connectedClients: GetConnectedShopify_clientConnections[],
  shopifyStoreName: string;
  hasAffiliateLink: boolean;
  isDeleted: boolean;
  setOfferImage: (url: string) => void;
  initialAllowedDomains: string[];
  saveStatus: string;
}

const singularize = (str: string): string => {
  if (!isEmpty(str) && str.charAt(str.length - 1).toLowerCase() === 's') {
    return str.slice(0, -1);
  }
  return str;
};

export const RefreshCommonSection: React.FC<Readonly<IProps>> = ({
  disabled,
  onFieldFocused,
  showCostPerClick,
  values,
  isNewFlow,
  mode,
  formRef,
  handleFormAsyncActions,
  onChangeImage,
  source,
  disabledAffiliatesLinks,
  disabledShopify,
  initialUrl,
  affiliateLinkData,
  shopifyData,
  formOptionData,
  isSubscriptionEnable,
  connectedAdvertiserForSync,
  connectedClients,
  shopifyStoreName,
  hasAffiliateLink,
  setOfferImage,
  initialAllowedDomains,
  saveStatus,
}) => {
  const formattedConversionType = useMemo(() => {
    if (values.source !== OFFER_SOURCE.TUNE) {
      return singularize(capitalize(OFFER_CONVERSION_TYPE.CONVERSIONS));
    }
    if (values.conversionType) {
      return singularize(join(map(split(values.conversionType, '_'), capitalize), ' '));
    }
    return singularize(capitalize(OFFER_CONVERSION_TYPE.CONVERSIONS));
  }, [values]);
  const offerPayoutOptionsEvents = useMemo(() => ({
    onBlur: () => onFieldFocused(null, true),
    options: {
      [OFFER_PAYOUT_TYPE.CONVERSION]: {
        onFocus: () => onFieldFocused(OfferFormToolTips.ppcInfo, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(OfferFormToolTips.ppcInfo, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
      [OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]: {
        onFocus: () => onFieldFocused(OfferFormToolTips.cpccpsInfo, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(OfferFormToolTips.cpccpsInfo, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
      [OFFER_PAYOUT_TYPE.SALE]: {
        onFocus: () => onFieldFocused(OfferFormToolTips.cps, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(OfferFormToolTips.cps, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
      [OFFER_PAYOUT_TYPE.CLICK]: {
        onFocus: () => onFieldFocused(null, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(null, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
    },
  }), [onFieldFocused]);

  return (
    <>
      <Card className="flex flex-col items-start gap-4 p-6 mx-auto mt-6 w-full">
        <CardContent className="p-0 w-full flex justify-between gap-x-8">
          <div className="w-[70%] max-w-[494px]">
            <RefreshOfferName
              charCount={values.name?.length || 0}
              disabled={disabled.name}
              name="name"
              source={values.source}
            />
            <RefreshOfferDescription
              disabled={disabled.description}
              name="description"
              value={values.description}
              handleFormAsyncActions={handleFormAsyncActions}
            />
            <RefreshOfferStatus
              disabled={false}
              name="status"
              value={values.status}
              handleFormAsyncActions={handleFormAsyncActions}
            />
          </div>
          <div className="w-[30%] max-w-[190px] flex justify-end">
            <RefreshOfferImage
              onChangeImage={onChangeImage}
              val={values.imageUrl}
              source={values.source}
              icon={values.icon}
              setOfferImage={setOfferImage}
              handleFormAsyncActions={handleFormAsyncActions}
            />
          </div>
        </CardContent>
      </Card>
      <Card className="flex items-start gap-4 p-6 mx-auto mt-6 w-full">
        <CardContent className="p-0 w-full flex flex-col justify-between gap-x-8 mb-[-8px]">

          {source === OFFER_SOURCE.SHOPIFY && (
            <RefreshShopifySection
              disabled={disabled.payoutType}
              events={offerPayoutOptionsEvents}
              formattedConversionType={formattedConversionType}
              name="payoutType"
              showCostPerClick={showCostPerClick}
              value={values.payoutOptions}
              source={values.source}
              payoutType={values.payoutType}
              isNewFlow={isNewFlow}
              mode={mode}
              formRef={formRef}
              handleFormAsyncActions={handleFormAsyncActions}
              onFieldFocused={onFieldFocused}
              shopifyData={shopifyData}
              disabledShopify={disabledShopify}
            />
          )}

          {source === OFFER_SOURCE.TUNE && (
            <RefreshAffiliateLinkSection
              disabled={disabled.payoutType}
              events={offerPayoutOptionsEvents}
              formattedConversionType={formattedConversionType}
              name="payoutType"
              showCostPerClick={showCostPerClick}
              value={values.payoutOptions}
              source={values.source}
              payoutType={values.payoutType}
              isNewFlow={isNewFlow}
              mode={mode}
              formRef={formRef}
              handleFormAsyncActions={handleFormAsyncActions}
              affiliateLinkData={affiliateLinkData}
              disabledAffiliatesLinks={disabledAffiliatesLinks}
              url={affiliateLinkData.url}
              initialUrl={initialUrl}
              onFieldFocused={onFieldFocused}
            />
          )}
        </CardContent>
      </Card>

      <Card className="flex items-start gap-4 py-4 px-6 mx-auto mt-6 w-full">
        <CardContent className="p-0 w-full flex flex-col justify-between gap-x-8 mb-[-8px]">
          <AdditionalSettings
            source={source}
            disabledShopify={disabledShopify}
            disabledAffiliatesLinks={disabledAffiliatesLinks}
            onFieldFocused={onFieldFocused}
            values={values}
            formOptionData={formOptionData}
            isSubscriptionEnable={isSubscriptionEnable}
            connectedAdvertiserForSync={connectedAdvertiserForSync}
            handleFormAsyncActions={handleFormAsyncActions}
            hasMembers={hasAffiliateLink}
            mode={mode}
            formRef={formRef}
            connectedClients={connectedClients}
            shopifyStoreName={shopifyStoreName}
            isOfferPromoLink={shopifyData.isPromoLink}
            isLandingPageEnabled={shopifyData.isLandingPageEnabled}
            initialAllowedDomains={initialAllowedDomains}
            saveStatus={saveStatus}
          />

        </CardContent>
      </Card>
    </>

  );
};
