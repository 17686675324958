import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { map, uniq, slice } from 'lodash';

import {
  ILicensedContent,
  IMasonryItemProps,
  FavoriteBorderIcon,
  FavoriteIcon,
  SearchIcon,
  Button,
  Checkbox,
  Image,
  LazyImage,
  Tooltip,
  Video,
  NetworkIcon,
  getLicensedContentImage,
  getLicensedContentVideo,
  isLicensedContentUploaded,
} from '@components';

import useConvertToJpg from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage/utils/useConvertHeicToJpg';
import { permissionOptions } from './constants';

import styles from './ContentTile.scss';

const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

// ConvertedImage component to handle HEIC conversion
const ConvertedImage = ({ 
  content,
  className,
  onSizeDetected,
  onLoad,
  onError
}: { 
  content: ILicensedContent; 
  className?: string;
  onSizeDetected?: () => void;
  onLoad?: () => void;
  onError?: () => void;
}) => {
  const contentType = content.downloadable_media?.[0]?.content_type;
  const isHEIC = contentType === 'image/heic' || contentType === 'image/heif';
  const src = getLicensedContentImage(content, 300, !isHEIC);

  const convertedSrc = useConvertToJpg(src);
  
  return (
    <Image
      className={className}
      src={convertedSrc || src}
      onSizeDetected={onSizeDetected}
      onLoad={onLoad}
      onError={onError}
    />
  );
};

export interface IContentTileProps extends IMasonryItemProps {
  content: ILicensedContent;
  onSearchClick?(event: React.MouseEvent);
  onAssignClick?(event: React.MouseEvent);
  onEditTagsClick(event: React.MouseEvent);
  onRemoveTagClick(tag: string);
  onTagClick(tag: string, event: React.MouseEvent);

  showVisitManageLink: boolean;
  onVisitManageClick(event: React.MouseEvent);
  onVisitProfileClick(event: React.MouseEvent);
  onViewDetailClick(event: React.MouseEvent);

  selected: boolean;
  onSelectedChange(selected: boolean);

  liked: boolean;
  toggleLikedContent?();

  isQa: boolean;
  onVisitAdminClick(event: React.MouseEvent);
  showCreateFeatures: boolean;

  classNames?: string[];
}
type TDefaultProp = 'onItemHeightChanged' | 'onAssignClick' | 'classNames';
interface IState {
  videoLoadFailed: boolean;
  mediaLoaded: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ContentTile extends React.Component<IContentTileProps, IState> {
  public static defaultProps: Pick<IContentTileProps, TDefaultProp> = {
    onItemHeightChanged: () => undefined,
    onAssignClick: () => undefined,
    classNames: [],
  };

  public static defaultHeight = 500;

  private ref: React.RefObject<HTMLDivElement>;

  private moreTagRef: React.RefObject<HTMLDivElement>;

  private permissionsRef: React.RefObject<HTMLDivElement>;

  private height: number;

  constructor(props: IContentTileProps) {
    super(props);

    this.ref = React.createRef();
    this.moreTagRef = React.createRef();
    this.permissionsRef = React.createRef();
    this.height = 300;

    this.state = {
      videoLoadFailed: false,
      mediaLoaded: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      content,
      classNames,
      onViewDetailClick,
      showVisitManageLink,
      onVisitManageClick,
      selected,
      onSelectedChange,
      liked,
      isQa,
      onVisitAdminClick,
      onVisitProfileClick,
    } = this.props;
    const { mediaLoaded, videoLoadFailed } = this.state;

    return (
      <div ref={this.ref} className={cx(classNames.concat(styles.ContentTile))}>
        <div className={styles.media} onClick={onViewDetailClick}>
          {(content.content_type === 'image' || videoLoadFailed) && (
            <ConvertedImage
              className={styles.image}
              content={content}
              onSizeDetected={this.onMediaDimensionDetected}
              onLoad={this.onMediaLoaded}
              onError={this.onMediaLoaded}
            />
          )}
          {content.content_type === 'video' && !videoLoadFailed && (
            <Video
              src={getLicensedContentVideo(content)}
              classNames={[styles.video]}
              onDataLoaded={this.onMediaLoaded}
              onDimensionDetected={this.onMediaDimensionDetected}
              onError={this.onVideoError}
            />
          )}
          {mediaLoaded && (
            <div>
              <Checkbox
                className={styles.checkbox}
                checked={selected}
                onChange={onSelectedChange}
              />
              {this.props.showCreateFeatures && (
                <>
                  <SearchIcon onClick={this.handleSearchClick} className={styles.search} />
                  <div
                    className={cx(styles.like, {
                      [styles.liked]: liked,
                    })}
                    onClick={this.toggleLike}
                  >
                    <FavoriteBorderIcon className={styles.favoriteOutline} />
                    <FavoriteIcon className={styles.favorite} />
                  </div>
                  <div className={styles.typeWrapper}>
                    <div
                      className={cx(styles.type, {
                        [styles.imageType]: content.content_type === 'image',
                        [styles.videoType]: content.content_type === 'video',
                      })}
                    />
                  </div>
                </>
              )}
              {!this.props.showCreateFeatures && this.props.onAssignClick && (
                <Button
                  className={styles.assignButton}
                  label="Re-assign..."
                  theme="primary"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.props.onAssignClick(event);
                  }}
                />
              )}
            </div>
          )}
          <div className={styles.permission}>
            <div className={styles.lockIcon} />
            <div ref={this.permissionsRef} className={styles.permissionText}>
              {permissionOptions[content.permission_level]}
            </div>
            {content.permission_notes && (
              <Tooltip tooltipColor="black" placement="bottom" mountRef={this.permissionsRef}>
                <div className={styles.permissionNotesHeader}>License Rights:</div>
                {content.permission_notes}
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.details}>
          <Avatar content={content} onVisitProfileClick={onVisitProfileClick} />
          <UserInfo content={content} onVisitProfileClick={onVisitProfileClick} />
          <div className={styles.actions}>
            {showVisitManageLink && content.project_id && (
              <div onClick={onVisitManageClick} className={styles.chat} />
            )}
            {content.social_post && (
              <a
                href={content.social_post.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.visit} />
              </a>
            )}
          </div>
        </div>
        {isQa && (
          <div onClick={onVisitAdminClick} className={styles.admin}>
            Admin Page
          </div>
        )}
        {this.renderTagList()}
      </div>
    );
  }

  /**
   * @private
   * Renders the tag list.
   *
   * @return {JSX}
   */
  private renderTagList() {
    const {
 content, onEditTagsClick, onRemoveTagClick, onTagClick,
} = this.props;

    const tags = uniq([...(content.vision_tags || []), ...(content.tags || [])]);
    const displayTags = slice(tags, 0, 10);
    const remainingTags = slice(tags, 10);

    return (
      <div className={styles.TagList}>
        <div onClick={onEditTagsClick} className={cx(styles.tag, styles.addTag)}>
          <div className={styles.plusIcon} />
        </div>
        {displayTags.length === 0 && <span className={styles.addTagText}>Add Tags</span>}
        {map(displayTags, (tag) => (
          <div
            key={tag}
            className={cx(styles.tag, {
              [styles.visionTag]: (content.vision_tags || []).includes(tag),
            })}
            onClick={(e) => onTagClick(tag, e)}
          >
            {tag}
            <div className={styles.removeTag} onClick={(e) => {
              e.stopPropagation();
              onRemoveTagClick(tag);
            }}>
              <div className={styles.removeTagImage} />
            </div>
          </div>
        ))}
        {remainingTags.length > 0 && (
          <span>
            <div ref={this.moreTagRef} className={cx(styles.tag, styles.moreTag)}>
              <div className={styles.ellipsisIcon} />
            </div>
            <Tooltip placement="top" mountRef={this.moreTagRef}>
              {map(remainingTags, (tag) => (
                <div
                  key={tag}
                  className={cx(styles.tag, {
                    [styles.visionTag]: (content.vision_tags || []).includes(tag),
                  })}
                >
                  {tag}
                  <div className={styles.removeTag} onClick={(e) => {
                    e.stopPropagation();
                    onRemoveTagClick(tag);
                  }}>
                    <div className={styles.removeTagImage} />
                  </div>
                </div>
              ))}
            </Tooltip>
          </span>
        )}
      </div>
    );
  }

  /**
   * Handler for when video load failed, use image instead.
   */
  private onVideoError = () => {
    this.setState({
      videoLoadFailed: true,
    });
  };

  /**
   * @private
   * Callback for when image's natural size is detected.
   */
  private onMediaDimensionDetected = () => {
    const { onItemHeightChanged } = this.props;
    const node = this.ref.current;
    if (!node) {
      return;
    }

    this.height = node.getBoundingClientRect().height;

    onItemHeightChanged(this.height);
  };

  private handleSearchClick = (event: React.MouseEvent<SVGElement>) => {
    const { onSearchClick } = this.props;

    event.stopPropagation();

    onSearchClick(event);
  };

  private toggleLike = (event: React.MouseEvent<HTMLDivElement>) => {
    const { toggleLikedContent } = this.props;

    event.stopPropagation();

    toggleLikedContent();
  };

  /**
   * @private
   * Callback for when the media(image/video) is loaded.
   */
  private onMediaLoaded = () => {
    const node = this.ref.current;
    if (!node) {
      return;
    }

    this.setState({
      mediaLoaded: true,
    });
  };
}

/**
 * @class
 * @extends {React.FunctionComponent}
 * The user avatar.
 */
const Avatar: React.FunctionComponent<{
  content: ILicensedContent;
  onVisitProfileClick(event: React.MouseEvent);
}> = ({ content, onVisitProfileClick }) => {
  let avatarImage = null;
  if (content.social_account) {
    avatarImage = (
      <a
        href={content.social_account.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LazyImage
          className={styles.avatarImage}
          src={content.social_account.profile_image}
          fallbackSrc={defaultAvatar}
        />
      </a>
    );
  } else if (content.publisher) {
    avatarImage = (
      <a onClick={onVisitProfileClick}>
        <LazyImage
          className={styles.avatarImage}
          src={content.publisher.profile_picture}
          fallbackSrc={defaultAvatar}
        />
      </a>
    );
  } else if (content.member && content.member.profile_picture) {
    avatarImage = (
      <a onClick={onVisitProfileClick}>
        <LazyImage
          className={styles.avatarImage}
          src={content.member.profile_picture}
          fallbackSrc={defaultAvatar}
        />
      </a>
    );
  } else if (ASSETS) {
    avatarImage = <LazyImage className={styles.avatarImage} src={defaultAvatar} />;
  }

  return (
    <div className={styles.avatar}>
      {avatarImage}
      {content.social_account && (
        <div className={styles.source}>
          <NetworkIcon identifier={content.social_account.network_identifier} />
        </div>
      )}
    </div>
  );
};

/**
 * @class
 * @extends {React.FunctionComponent}
 * The user info section.
 */
const UserInfo: React.FunctionComponent<{
  content: ILicensedContent;
  onVisitProfileClick(event: React.MouseEvent);
}> = ({ content, onVisitProfileClick }) => {
  let userInfo = null;
  if (content.social_account) {
    userInfo = (
      <a
        href={content.social_account.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content.social_account.name}
      </a>
    );
  } else if (isLicensedContentUploaded(content) && content.uploaded_by) {
    userInfo = (
      <a>
        Uploaded by
        {content.uploaded_by.email}
      </a>
);
  } else if (
    ['content_review', 'additional_images', 'extra_content'].includes(content.source)
    && content.publisher
  ) {
    userInfo = <a onClick={onVisitProfileClick}>{content.publisher.display_name}</a>;
  } else if (content.member) {
    userInfo = <span>{content.member.name}</span>;
  }

  const dateCreated = content.social_account ? content.social_post.created_ts : content.created_ts;

  return (
    <div className={styles.info}>
      <div className={styles.name}>{userInfo}</div>
      <div className={styles.date}>{format(dateCreated * 1000, 'MMM dd, yyyy')}</div>
    </div>
  );
};
