import * as React from 'react';
import cx from 'classnames';
import {
  isEmpty,
  map,
} from 'lodash';

import { SquareKanbanIcon } from '@revfluence/fresh-icons/regular/esm';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import Empty from '@frontend/app/refresh-components/Empty';
import styles from './GetStarted.scss';
import { IHomePageSection } from '../types';

interface IProps {
  groups: IHomePageSection;
  metrics: React.ReactNode;
  projects: IHomePageSection;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: Fix in Node upgrade typing bash!
  welcome: React.ReactNode;
  refreshUi: boolean;
}

const HOME_PAGE_SECTIONS = ['projects', 'groups'] as const;

export const RefreshLayout = (props: IProps) => {
  const {
    groups,
    metrics,
    projects,
    welcome,
    refreshUi,
  } = props;
  const SectionContents: React.FC<{
    sectionKey: 'projects' | 'groups';
    cards: IHomePageSection['cards'];
    error: IHomePageSection['error'];
  }> = ({ sectionKey, cards, error }) => {
    if (error) {
      return (
        <div className="flex items-center justify-center">
          {error}
        </div>
      );
    }
    if (isEmpty(cards)) {
      if (!['projects', 'groups'].includes(sectionKey)) {
        return null;
      }

      const title = sectionKey === 'projects' ? 'No Projects Found' : 'No Groups Found';
      const description = sectionKey === 'projects' ? 'Create a project to start working with creators' : 'Create a group and start adding contacts to it';

      return (
        <div className="flex flex-col items-center justify-center w-full h-full text-center">
          <Empty icon={SquareKanbanIcon} title={title} description={description} />
        </div>
      );
    }

    const gridClasses = sectionKey === 'groups'
        ? 'sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-6'
        : 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4';

    return (
      <div className={`grid gap-6 ${gridClasses}`}>
        {map(cards, (card, index) => (
          <div
            key={`${sectionKey}-card-${index + 1}`}
            className="w-full"
          >
            {card}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className={styles.Wrapper}>
      <div className={refreshUi ? 'bg-primary py-12 px-8' : cx(styles.MetricsSection, styles.spacing)}>
        {welcome}
        {metrics && (
          <div className="flex">
            <div className="flex-auto">
              {metrics}
            </div>
          </div>

        )}
      </div>
      <div className="flex flex-col py-12 2xl:px-8 px-6">
        {map(HOME_PAGE_SECTIONS, (sectionKey) => {
          let section;
          switch (sectionKey) {
            case 'groups':
              section = groups;
              break;
            case 'projects':
              section = projects;
              break;
            default:
              break;
          }
          if (!section) {
            throw new Error(`Unhandled section key: "${sectionKey}".`);
          }

          const {
            actions,
            cards,
            error,
            title,
          } = section;
          return (
            <React.Fragment key={sectionKey}>
              <div className="flex items-center mb-4 mt-12">
                <div className="flex-1">
                  <H3>{title}</H3>
                </div>
                {map(actions, (action, index) => (
                  <div key={`${sectionKey}-action-${index + 1}`} className="ml-4">
                    {action}
                  </div>
                ))}
              </div>
              <SectionContents
                sectionKey={sectionKey}
                cards={cards}
                error={error}
              />
            </React.Fragment>

          );
        })}
      </div>
    </div>
  );
};
