import cx from 'classnames';
import {
  includes, isEmpty, keyBy, map, mapValues, trim,
} from 'lodash';
import * as React from 'react';

import {
  Button, CheckCircleIcon, IconButton, Notice, SpinnerIcon, TrashcanIcon,
} from '@components';

import { MenuList } from '@frontend/app/components';
import { GetResourcesQuery_resources as IResource } from '@frontend/app/queries/types/GetResourcesQuery';
import { useAuth } from '@frontend/context/authContext';
import { Modal } from '@revfluence/fresh';
import {
  Alert, Button as RButton, Col, Input, Row, Space, Switch, Tag, Typography,
} from '@revfluence/fresh';

import { LinkIcon, TrashIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { useAppContext } from '@frontend/context/AppContext';
import { ApolloProvider } from '@apollo/client';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import ShopifyStoreDisconnectionAlert from '@frontend/applications/AffiliatesApp/components/ShopifyStoreDisconnectionAlert/ShopifyStoreDisconnectionAlert';
import { logger } from '@common';
import { useAddShopifyAccount } from '../hooks/useAddShopifyAccount';
import { useRevokeShopifyAccount } from '../hooks/useRevokeShopifyAccount';

import styles from './ShopifyAccountList.scss';
import { AllShopifyConnection, OnChnageInput } from '../pages/types';
import { ShopifyShippingLines } from './ShopifyShippingLines';

interface IProps {
  activeAccounts: IResource[];
  isEnabledMultipleShopify: boolean;
  className?: string;
  onAccountRemoved?: (account: IResource) => void;
  allShopifyConnection?: AllShopifyConnection[];
  enableChangeButton?: boolean;
  showNoticeMessage?: string;
  onChangeValue?: (input: OnChnageInput) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  closeConnection?: () => Promise<void>
  closeClientShopifyConnections?: () => Promise<void>
}

const { useCallback, useState, useMemo } = React;

const getConnectionErrors = (account: IResource) => {
  if (!account.scopes) {
    return [];
  }
  const { externalScopes } = account;
  const hasPromoCodesInstalled = !accountMissingPromoCodes(account);
  const hasPFAInstalled = !accountMissingProductFullfilment(account);
  const errorMessages = [];
  if (hasPromoCodesInstalled && !hasPromoCodeScopes(account.externalScopes)) {
    errorMessages.push('Use our product fulfilment tool to gift products to your members with the click of a button');
  }
  if (hasPFAInstalled && !(includes(externalScopes, 'read_products') && includes(externalScopes, 'write_orders'))) {
    errorMessages.push('Create Promo codes');
  }
  return errorMessages;
};
const hasPromoCodeScopes = (resourceScopes: string[]) =>
  (includes(resourceScopes, 'read_orders') || includes(resourceScopes, 'write_orders'))
  && includes(resourceScopes, 'write_price_rules')
  && includes(resourceScopes, 'write_discounts');

const accountMissingPromoCodes = (account: IResource) => {
  if (!account.scopes) {
    return true;
  }
  const resourceScopes = account.scopes.split(',').map((s) => trim(s).toLowerCase());
  return !hasPromoCodeScopes(resourceScopes);
};

const accountMissingProductFullfilment = (account: IResource) => {
  if (!account.scopes) {
    return true;
  }
  const resourceScopes = account.scopes.split(',').map((s) => trim(s).toLowerCase());
  return !(includes(resourceScopes, 'read_products') && includes(resourceScopes, 'write_orders'));
};

const RemoveShopfiyAccountConfirmModal = (confirmAction: () => void) => Modal.confirm({
  title: 'Shopify Account',
  okText: 'Remove',
  cancelText: 'Cancel',
  onOk: confirmAction,
});

export const ShopifyAccountList: React.FC<IProps> = (props) => {
  const { user } = useAuth();
  const {
    activeAccounts,
    isEnabledMultipleShopify,
    onAccountRemoved,
    allShopifyConnection,
    enableChangeButton,
    showNoticeMessage,
    onChangeValue,
    onSubmit,
    onCancel,
    closeConnection,
    closeClientShopifyConnections,
  } = props;

  const [revokingId, setRevokingId] = useState<number>(null);
  const { revokeAccess, loading: isRevoking } = useRevokeShopifyAccount();

  const { pfaApolloClient } = useAppContext();

  const [selectedId, setSelectedId] = useState(1);

  const shippingLineSettingsEnabled = useClientFeatureEnabled(ClientFeature.SHIPPING_LINE_SETTINGS);

  const hasReadLocationsScope = activeAccounts.every((account) => account.scopes?.includes('read_locations') ?? false);

  const MenuItems = [
    {
      id: 1,
      title: 'My Accounts',
    },
    ...(shippingLineSettingsEnabled ? [{
      id: 2,
      title: 'Custom Shipping Lines',
    }] : []),
  ];

  const handleRemoveAccount = useCallback(
    (account: IResource) => {
      RemoveShopfiyAccountConfirmModal(() => {
        setRevokingId(account.id);

        (async () => {
          try {
            await revokeAccess({
              variables: {
                resourceId: account.id,
                ignoreRequestContextUserId: true,
              },
            });
            await closeClientShopifyConnections();
            if (isEnabledMultipleShopify) {
              await closeConnection();
            }
            onAccountRemoved?.(account);
          } catch (error) {
            logger.error('Failed to revoke access', error);
          } finally {
            setRevokingId(null);
          }
        })();
      });
    },
    [setRevokingId, revokeAccess, onAccountRemoved, isEnabledMultipleShopify, closeConnection, closeClientShopifyConnections],
  );

  const addAccount = useAddShopifyAccount();

  const handleAddAccount = useCallback(
    (account: IResource) => {
      const name = account.authProvider.userExternalId.replace(new RegExp('.myshopify.com$'), '');
      addAccount({
        shop: name,
        supportPromoCodes: true,
        supportCreateOrder: true,
        supportPromoCodeCustomerSegmentation: true,
        shoplessEnabled: false,
      });
    },
    [addAccount],
  );

  const connectionErrors = useMemo(
    () => mapValues(keyBy(activeAccounts, 'id'), (account) => getConnectionErrors(account)),
    [activeAccounts],
  );

  const handleRemoveAndReconnect = useCallback(async (activeAccount: IResource) => {
    try {
      await revokeAccess({
        variables: {
          resourceId: activeAccount.id,
          ignoreRequestContextUserId: true,
        },
      });
      await closeClientShopifyConnections();
      if (isEnabledMultipleShopify) {
        await closeConnection();
      }
      onAccountRemoved?.(activeAccount);

      const name = activeAccount.authProvider.userExternalId.replace(/.myshopify.com$/, '');
      await addAccount({
        shop: name,
        supportPromoCodes: true,
        supportCreateOrder: true,
        supportPromoCodeCustomerSegmentation: true,
        shoplessEnabled: false,
      });
    } catch (error) {
      logger.error('Failed to revoke/add account', error);
    } finally {
      setRevokingId(null);
    }
  }, [addAccount, closeClientShopifyConnections, closeConnection, isEnabledMultipleShopify, onAccountRemoved, revokeAccess]);

  const handleAccounts = useMemo((): { [accountId: string]: () => void } => {
    const callbacks = {};
    for (const account of activeAccounts) {
      callbacks[account.id] = () => handleAddAccount(account);
    }
    return callbacks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccounts]);

  const primaryShopifyConnection: AllShopifyConnection = allShopifyConnection.find((s) => s.isPrimary);
  const secondaryShopifyConnection: AllShopifyConnection[] = allShopifyConnection.filter((s) => !s.isPrimary).sort((a, b) => b.clientId.localeCompare(a.clientId));
  return (
    <div className={cx(styles.ShopifyAccountList, props.className)}>
      <div className={styles.left}>
        <MenuList
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          className={(styles as any).menu}
          selectedId={selectedId}
          items={MenuItems}
          onSelectItem={(id) => setSelectedId(id)}
        />
      </div>
      {selectedId === 1 && (
      <div className={cx(styles.accounts, styles.sectionGap)}>
        {!hasReadLocationsScope && <ShopifyStoreDisconnectionAlert
          hasMissingScopes
          refreshUi
          handleAccountReconnect={() => handleRemoveAndReconnect(activeAccounts[0])}
        />}
        {isEnabledMultipleShopify && (
        <>
          {!!showNoticeMessage.length && (
          <Row className={styles.alertMessage}>
            <Alert className={styles.alert} message={showNoticeMessage} banner />
          </Row>
            )}
          <Row>
            <Col span={24} className={cx(styles.sectionGap)}>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <Typography.Text className={cx(styles.sectionHeader)}>Primary Shopify Account</Typography.Text>
                </Col>
                <Col className={cx(styles.ctaButtonsContainer)}>
                  {enableChangeButton && <RButton type="default" danger={false} onClick={() => onCancel()}>Cancel</RButton>}
                  <RButton type="primary" disabled={!(enableChangeButton && !showNoticeMessage)} danger={false} onClick={() => onSubmit()}>Save</RButton>
                </Col>
              </Row>
              {
                  primaryShopifyConnection && (
                    <Row className={cx(styles.connectionContainer)}>
                      <Col span={18} className={cx(styles.connectionInnerContainer)}>
                        <Input
                          style={{ width: '50%' }}
                          type="text"
                          value={primaryShopifyConnection.label}
                          maxLength={60}
                          status={primaryShopifyConnection.isActive && !primaryShopifyConnection.label.length ? 'error' : ''}
                          suffix={primaryShopifyConnection.isActive && !primaryShopifyConnection.label.length ? <TriangleExclamationIcon /> : <></>}
                          placeholder="Add a label"
                          onChange={(e) => onChangeValue({ clientId: primaryShopifyConnection.clientId, field: 'label', value: e.target.value })}
                        />
                        <Space>
                          <LinkIcon color="#000000" />
                          <Typography.Link>
                            https://
                            {primaryShopifyConnection.url}
                          </Typography.Link>
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Tag color="lime" style={{ float: 'right' }}>Primary Store</Tag>
                      </Col>
                    </Row>
                  )
                }

            </Col>
          </Row>
          <Row>
            <Col span={24} className={cx(styles.sectionGap)}>
              <Row>
                <Typography.Text className={cx(styles.sectionHeader)}>Connected Shopify Stores</Typography.Text>
              </Row>
              {
                  secondaryShopifyConnection.length ? secondaryShopifyConnection.filter((c) => !c.isPrimary).map((connection) => (
                    <Row className={cx(styles.connectionContainer, connection.isActive ? '' : styles.connectionContainerSecondary)} key={connection.clientId}>
                      <Col span={18} className={cx(styles.connectionInnerContainer)}>
                        <Input
                          style={{ width: '50%' }}
                          type="text"
                          value={connection.label}
                          maxLength={60}
                          status={connection.isActive && !connection.label.length ? 'error' : ''}
                          suffix={connection.isActive && !connection.label.length ? <TriangleExclamationIcon /> : <></>}
                          placeholder="Add a label"
                          onChange={(e) => onChangeValue({ clientId: connection.clientId, field: 'label', value: e.target.value })}
                        />
                        <Space>
                          <LinkIcon color="#000000" />
                          <Typography.Link>
                            https://
                            {connection.url}
                          </Typography.Link>
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Switch disabled={connection.isRevoked} style={{ float: 'right' }} checked={connection.isActive} onChange={(e) => onChangeValue({ clientId: connection.clientId, field: 'isActive', value: e })} />
                      </Col>
                      {connection.isRevoked && (
                      <Typography.Text type="danger">
                        {' '}
                        <TriangleExclamationIcon />
                        {' '}
                        This store connection may not work. Please retry connecting the Shopify store.
                      </Typography.Text>
)}
                    </Row>
                  ))
                    : (
                      <Row>
                        <Typography.Text>No Connected clients</Typography.Text>
                      </Row>
                    )
                }
            </Col>
          </Row>
          <Row>
            <Col span={24} className={cx(styles.sectionGap, styles.sectionHeaderContainer)}>
              <Row>
                <Typography.Text className={cx(styles.sectionHeader)}>Remove Shopify Account</Typography.Text>
              </Row>
              <Row>
                <Typography.Paragraph className={cx(styles.removeAccountMessage)}>Removing shopify store will remove all the connected stores also. Your offers will no longer be synced to the stores but will remain active till you manage them in shopify account. If you connect the same account again, other connected store will be automatically be there.</Typography.Paragraph>
                <RButton type="default" danger icon={<TrashIcon />} onClick={() => handleRemoveAccount(activeAccounts[0])}>
                  Remove Account
                </RButton>
              </Row>
            </Col>
          </Row>
        </>
        )}

        {!isEnabledMultipleShopify && map(activeAccounts, (account) => {
          const revoked = account.authProvider.systemRevokedAccess;
          return (
            <div key={account.id} className={cx(styles.listItem, { [styles.disabled]: isRevoking })}>
              <div className={cx(styles.store, { [styles.error]: revoked })}>
                <div className={styles.icon}>
                  {!revoked && <CheckCircleIcon size={14} className={styles.checkmark} />}
                  <span><ShopifyIcon fontSize={20} /></span>
                </div>
                <div className={styles.title}>{account.authProvider.userExternalDisplayId}</div>
                <div className={styles.remove}>
                  {accountMissingPromoCodes(account) && (
                    <a
                      className={styles.enablePromoCodesLink}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAccounts[account.id];
                      }}
                    >
                      Enable Promo Codes
                    </a>
                  )}

                  {revokingId === account.id ? (
                    <IconButton icon={<SpinnerIcon size={22} />} disabled />
                  ) : (
                    <IconButton icon={<TrashcanIcon size={22} />} onClick={() => handleRemoveAccount(account)} />
                  )}
                </div>
              </div>
              {connectionErrors && !isEmpty(connectionErrors[account.id]) && (
                <Notice type="error" showDivider className={styles.errorNotice}>
                  <div className={styles.noticeContent}>
                    <div className={styles.title}>
                      Please reconnect your Shopify account,
                      {' '}
                      {account.authProvider.userExternalDisplayId}
                    </div>
                    <div className={styles.text}>
                      {map(connectionErrors[account.id], (error) => (
                        <div key={error}>{error}</div>
                      ))}
                    </div>
                    <Button
                      theme="light"
                      label="Reconnect"
                      className={styles.reconnect}
                      round={false}
                      fullWidth={false}
                      onClick={handleAccounts[account.id]}
                      disabled={account.userId !== user.sub}
                    />
                  </div>
                </Notice>
              )}
              {revoked && (
                <Notice type="error" showDivider className={styles.errorNotice}>
                  <div className={styles.noticeContent}>
                    <div className={styles.title}>
                      We are having errors syncing your account,
                      {' '}
                      {account.authProvider.userExternalDisplayId}
                    </div>
                    <div className={styles.text}>Please try authorizing your account again</div>
                    <Button
                      theme="light"
                      label="Reconnect"
                      className={styles.reconnect}
                      round={false}
                      fullWidth={false}
                      onClick={() => handleAddAccount(account)}
                      disabled={account.userId !== user.sub}
                    />
                  </div>
                </Notice>
              )}
            </div>
          );
        })}
      </div>
      )}
      {
        shippingLineSettingsEnabled && selectedId === 2 && (
          <ApolloProvider client={pfaApolloClient}>
            <ShopifyShippingLines />
          </ApolloProvider>
        )
      }
    </div>
  );
};
