import * as React from 'react';
import { Tag } from '@frontend/app/refresh-components';
import { usePayment } from '../PaymentContext';
import { PaymentStatus, PaymentType } from '../../constants';

interface IProps {}

const QuickFilterSection: React.FunctionComponent<IProps> = () => {
  const { programs, tags, budgets, users, filter, setFilter } = usePayment();

  const filterItems = React.useMemo(() => {
    const selectedStatuses = filter.status.map((status) => ({
      id: `status-${status}`,
      label: status,
      type: 'status' as const,
      originalId: status,
    }));

    const selectedPaymentTypes = filter.paymentTypes.map((type) => ({
      id: `paymentType-${type}`,
      label: type,
      type: 'paymentType' as const,
      originalId: type,
    }));

    const selectedPrograms = programs
      .filter((program) => filter.programIds.includes(program.id))
      .map((program) => ({
        id: `program-${program.id}`,
        label: program.title,
        type: 'program' as const,
        originalId: program.id,
      }));

    const selectedTags = tags
      .filter((tag) => filter.tagIds.includes(tag.id))
      .map((tag) => ({
        id: `tag-${tag.id}`,
        label: tag.name,
        type: 'tag' as const,
        originalId: tag.id,
      }));

    const selectedBudgets = budgets
      .filter((budget) => filter.budgetIds.includes(budget.id))
      .map((budget) => ({
        id: `budget-${budget.id}`,
        label: budget.name,
        type: 'budget' as const,
        originalId: budget.id,
      }));

    const selectedUsers = users
      .filter((user) => filter.userIds.includes(String(user.id)))
      .map((user) => ({
        id: `user-${user.id}`,
        label: user.name,
        type: 'user' as const,
        originalId: user.id,
      }));

    return [
      ...selectedStatuses,
      ...selectedPaymentTypes,
      ...selectedPrograms,
      ...selectedTags,
      ...selectedBudgets,
      ...selectedUsers
    ];
  }, [filter, programs, tags, budgets, users]);

  const handleRemove = (item: { type: string; originalId: number | string | PaymentStatus | PaymentType }) => {
    setFilter((prev) => {
      switch (item.type) {
        case 'status':
          return {
            ...prev,
            status: prev.status.filter((status) => status !== item.originalId),
          };
        case 'paymentType':
          return {
            ...prev,
            paymentTypes: prev.paymentTypes.filter((type) => type !== item.originalId),
          };
        case 'program':
          return {
            ...prev,
            programIds: prev.programIds.filter((id) => id !== item.originalId),
          };
        case 'tag':
          return {
            ...prev,
            tagIds: prev.tagIds.filter((id) => id !== item.originalId),
          };
        case 'budget':
          return {
            ...prev,
            budgetIds: prev.budgetIds.filter((id) => id !== item.originalId),
          };
        case 'user':
          return {
            ...prev,
            userIds: prev.userIds.filter((id) => id !== String(item.originalId)),
          };
        default:
          return prev;
      }
    });
  };

  if (filterItems.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-2 w-full">
      {filterItems.map((item) => (
        <Tag key={item.id} label={item.label} isRemovable onRemove={() => handleRemove(item)} bgColor="bg-gray-200" />
      ))}
    </div>
  );
};

export default QuickFilterSection;
