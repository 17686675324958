/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_c2gtw_157 {
  display: flex;
}

._justify-content-space-between_c2gtw_161 {
  justify-content: space-between;
}

._tabular-nums_c2gtw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_c2gtw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferTable_c2gtw_178 ._disabledRow_c2gtw_178 {
  opacity: 0.5;
}
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181 {
  align-items: center;
  display: flex;
}
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181 svg {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181 .anticon {
  color: #1a1818;
}
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181:active svg,
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181:active .anticon, ._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181:hover svg,
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181:hover .anticon, ._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181:focus svg,
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181:focus .anticon {
  color: #40a9ff;
}
._OfferTable_c2gtw_178 ._addDropDownButton_c2gtw_181 .anticon:last-child {
  margin-left: 1rem;
}

._offerFilter_c2gtw_201 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  display: flex;
  align-items: center;
}

.offerTable .ant-pagination-item, .offerTable .ant-pagination-jump-next, .offerTable .ant-pagination-jump-prev {
  display: none;
}
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):not(.ant-dropdown-menu-item-danger):hover .ant-btn-primary > span {
  color: #fff !important;
}