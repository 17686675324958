import { ContentType } from '@frontend/app/types/globalTypes';

/**
 * Encodes only the filename portion of a URL while preserving the rest of the URL structure.
 * This is particularly useful for URLs with special characters in filenames.
 *
 * @param {string} url - The URL to process
 * @return {string} The URL with properly encoded filename
 */
export const encodeUrlFilename = (url: string): string => {
  if (!url) return url;

  try {
    // Special case for URLs with Base64 encoding pattern in filename
    if (url.includes('/=?UTF-8?B?')) {
      const prefix = url.substring(0, url.lastIndexOf('/') + 1);
      const filename = url.substring(url.lastIndexOf('/') + 1);
      return prefix + encodeURIComponent(filename);
    }

    // Handle simple case for relative URLs or potentially malformed URLs
    if (!url.includes('://')) {
      // For relative URLs, just split by '/' and encode the last part
      const parts = url.split('/');
      const filename = parts.pop() || '';

      // Check if the filename has query parameters
      const filenameWithoutQuery = filename.split('?')[0];
      const queryPart = filename.includes('?') ? filename.substring(filename.indexOf('?')) : '';

      try {
        // Try to decode first to prevent double encoding
        const decodedFilename = decodeURIComponent(filenameWithoutQuery);
        const encodedFilename = encodeURIComponent(decodedFilename);
        return [...parts, encodedFilename + queryPart].join('/');
      } catch (e) {
        // If decoding fails, encode the original
        const encodedFilename = encodeURIComponent(filenameWithoutQuery);
        return [...parts, encodedFilename + queryPart].join('/');
      }
    }

    // For standard URLs, use the URL class
    let parsedUrl: URL;
    try {
      parsedUrl = new URL(url);

      // Normal case - encode just the filename portion
      const pathParts = parsedUrl.pathname.split('/');
      const filename = pathParts.pop() || '';

      // Decode the filename first in case it's already encoded to prevent double-encoding
      let decodedFilename;
      try {
        decodedFilename = decodeURIComponent(filename);
      } catch (e) {
        decodedFilename = filename;
      }

      // Encode the filename
      const encodedFilename = encodeURIComponent(decodedFilename);

      // Reconstruct the pathname
      parsedUrl.pathname = [...pathParts, encodedFilename].join('/');

      return parsedUrl.toString();
    } catch (e) {
      // Fallback to simple string splitting for URLs that can't be parsed by URL class

      // Split URL into base and query parts
      const [basePart, queryPart] = url.split(/\?(.+)/);

      // For the base part, encode just the filename
      const urlParts = basePart.split('/');
      const filename = urlParts.pop() || '';

      // Try to decode first to prevent double encoding
      let decodedFilename;
      try {
        decodedFilename = decodeURIComponent(filename);
      } catch (e) {
        decodedFilename = filename;
      }

      const encodedFilename = encodeURIComponent(decodedFilename);
      const encodedBasePart = [...urlParts, encodedFilename].join('/');

      // Reconstruct the URL with query part if it exists
      return queryPart ? `${encodedBasePart}?${queryPart}` : encodedBasePart;
    }
  } catch (e) {
    // Return original URL if any error occurs
    console.warn('Error encoding URL filename:', e);
    return url;
  }
};

export const isInstagram = (
  type: ContentType,
): boolean => {
  switch (type) {
    case ContentType.INSTAGRAM:
    case ContentType.INSTAGRAM_POST:
    case ContentType.INSTAGRAM_REEL:
    case ContentType.INSTAGRAM_STORY:
    case ContentType.INSTAGRAM_VIDEO:
      return true;
    default:
      return false;
  }
};

export const isTiktok = (
  type: ContentType,
): boolean => {
  switch (type) {
    case ContentType.TIKTOK_VIDEO:
      return true;
    default:
      return false;
  }
};
