import React, { useEffect, useState } from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import Text from '@frontend/app/refresh-components/Text';
import { CheckCircleFilled, ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { SAVE_LANDING_PAGE_DATA } from '@frontend/applications/AffiliatesApp/queries/saveLandingPageMutation';
import { useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';
import { GET_LANDING_PAGE_DATA } from '@frontend/applications/AffiliatesApp/queries/getLandingPageData';
import { useLandingPageContext } from '../context/LandingPageContext';
import { validateLandingPageData } from '../validations/validators';
import { hasContentChanged } from '../utils/contentChangeUtils';

enum SaveStatus {
  UNSAVED = 'unsaved',
  SAVED = 'saved',
}

const APIActions = ({ offerId }) => {
  const intOfferId = parseInt(offerId, 10);

  const {
 data, loading, error, refetch,
} = useQuery(GET_LANDING_PAGE_DATA, {
    variables: { offerId: intOfferId },
    skip: !intOfferId,
    fetchPolicy: 'network-only',
  });

  const [createOfferLandingPage] = useMutation(SAVE_LANDING_PAGE_DATA);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.UNSAVED);
  const {
    landingPageData,
    setLandingPageData,
    setFieldErrors,
    clearFieldErrors,
    initialData,
    setInitialData,
  } = useLandingPageContext();
  const [isSaving, setIsSaving] = useState(false);

  // Helper function to recursively remove __typename
  const removeTypename = React.useCallback((obj) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeTypename(item));
    } else if (obj !== null && typeof obj === 'object') {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (key !== '__typename') {
          acc[key] = removeTypename(value);
        }
        return acc;
      }, {});
    }
    return obj;
  }, []);

  useEffect(() => {
    if (data?.getOfferLandingPage) {
      const sanitizedData = removeTypename(data.getOfferLandingPage.config);
      setLandingPageData(sanitizedData);
      setInitialData(sanitizedData);
    }
  }, [data, setLandingPageData, setInitialData, removeTypename]);

  useEffect(() => {
    setSaveStatus(hasContentChanged(landingPageData, initialData)
      ? SaveStatus.UNSAVED
      : SaveStatus.SAVED);
  }, [landingPageData, initialData]);

  const handleSave = async () => {
    if (saveStatus === SaveStatus.SAVED) {
      message.info('No changes detected. Please make updates before saving.', 3);
      return;
    }

    clearFieldErrors();
    const validationErrors = validateLandingPageData(landingPageData);

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      message.error('Please fix all highlighted errors before saving');
      return;
    }

    setIsSaving(true);
    try {
      const variables = {
        config: {
          ...landingPageData,
        },
        status: 'ACTIVE',
        offerId: intOfferId,
      };

      await createOfferLandingPage({
        variables,
        onCompleted: async (response) => {
          if (response.createOfferLandingPage.id) {
            setSaveStatus(SaveStatus.SAVED);
            message.success('CreatorStore saved successfully!');
            await refetch();
          } else {
            alert(`Error: ${response.data.createOfferLandingPage.message}`);
          }
        },
      });
    } catch (error) {
      message.error(error?.message || 'Failed to save CreatorStore. Response not successful.', 3);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  if (loading) return <p>Loading...</p>;
  if (error) {
return (
  <p>
    Error:
    {error.message}
  </p>
);
}

  return (
    <>
      <span className="items-center flex gap-1" key="save-status">
        {isSaving ? (
          <>
            <LoadingOutlined style={{ color: '#1677ff' }} />
            <Text visualVariant="caption" className="text-blue-500">
              Saving...
            </Text>
          </>
        ) : saveStatus === SaveStatus.UNSAVED ? (
          <>
            <ExclamationCircleFilled style={{ color: '#F0D694' }} />
            <Text visualVariant="caption" className="text-yellow-3">
              Unsaved Changes
            </Text>
          </>
        ) : (
          <>
            <CheckCircleFilled style={{ color: '#8BC34A' }} />
            <Text visualVariant="caption" className="text-green-500">
              Saved Changes
            </Text>
          </>
        )}
      </span>
      <Button
        key="cancel"
        variant="outlineHeader"
        size="sm"
        onClick={handleCancel}
        disabled={isSaving}
      >
        Cancel
      </Button>
      <Button
        key="save"
        variant="outline"
        size="sm"
        onClick={handleSave}
        disabled={isSaving}
      >
        {isSaving ? (
          <>
            <LoadingOutlined className="mr-2" />
            Saving...
          </>
        ) : (
          'Save & Publish'
        )}
      </Button>
    </>
  );
};

export default APIActions;
