import React, { useEffect, useState } from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { Input } from '@frontend/shadcn/components/ui/input';
import {
 CircleDollarIcon, TagIcon, GiftIcon, TruckIcon, BagShoppingIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import Switcher from '@frontend/app/refresh-components/Switcher';
import { SectionCard } from '../components/SectionCard';
import { useCampaign } from '../CampaignContext';
import { CompensationMethod, DiscountType, ValueType } from '../CampaignContext';

export const CompensationSection: React.FC = () => {
  const {
    compensationMethod,
    discountType,
    commissionValue,
    commissionValueType,
    discountValue,
    discountValueType,
    setCompensationMethod,
    setDiscountDetails,
    setCommissionDetails,
  } = useCampaign();

  const handleCompensationMethodChange = (method: CompensationMethod) => {
    setCompensationMethod(method);
    setDiscountDetails(undefined, undefined, undefined);
    setCommissionDetails(undefined, undefined);
    setInputValue('');
  };

  const [inputValue, setInputValue] = useState((compensationMethod === CompensationMethod.COMMISSION ? commissionValue : discountValue) || '');

  useEffect(() => {
    setInputValue((compensationMethod === CompensationMethod.COMMISSION ? commissionValue : discountValue) || '');
  }, [compensationMethod, commissionValue, discountValue]);

  return (
    <SectionCard>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <CircleDollarIcon />
            <P>Compensation</P>
          </div>
          <H3 className="font-medium mb-0">How are creators rewarded?</H3>
        </div>

        <div className="space-y-6 mt-6">
          <div>
            <p className="text-sm text-muted-foreground mb-3">Compensation</p>
            <div className="flex gap-2">
              <label
                className={cn(
                  'flex items-center gap-2 px-4 py-2 rounded-lg border cursor-pointer hover:border-primary transition-all h-8',
                  compensationMethod === CompensationMethod.COMMISSION && 'border-primary bg-primary/5',
                )}
              >
                <input
                  type="radio"
                  value={CompensationMethod.COMMISSION}
                  checked={compensationMethod === CompensationMethod.COMMISSION}
                  onChange={(e) => handleCompensationMethodChange(e.target.value as CompensationMethod)}
                  className="hidden"
                />
                <CircleDollarIcon fontSize={12} />
                <span className="text-sm">Commission</span>
              </label>

              <label
                className={cn(
                  'flex items-center gap-2 px-4 py-2 rounded-lg border cursor-pointer hover:border-primary transition-all h-8',
                  compensationMethod === CompensationMethod.DISCOUNT && 'border-primary bg-primary/5',
                )}
              >
                <input
                  type="radio"
                  value={CompensationMethod.DISCOUNT}
                  checked={compensationMethod === CompensationMethod.DISCOUNT}
                  onChange={(e) => handleCompensationMethodChange(e.target.value as CompensationMethod)}
                  className="hidden"
                />
                <TagIcon fontSize={12} />
                <span className="text-sm">Discount</span>
              </label>

              <label
                className={cn(
                  'flex items-center gap-2 px-4 py-2 rounded-lg border cursor-pointer hover:border-primary transition-all h-8',
                  compensationMethod === CompensationMethod.FREE_PRODUCT && 'border-primary bg-primary/5',
                )}
              >
                <input
                  type="radio"
                  value={CompensationMethod.FREE_PRODUCT}
                  checked={compensationMethod === CompensationMethod.FREE_PRODUCT}
                  onChange={(e) => handleCompensationMethodChange(e.target.value as CompensationMethod)}
                  className="hidden"
                />
                <GiftIcon fontSize={12} />
                <span className="text-sm">Free Product</span>
              </label>
            </div>
          </div>

          {compensationMethod === CompensationMethod.DISCOUNT && (
            <div>
              <p className="text-sm text-muted-foreground mb-3">Discount Type</p>
              <div className="flex gap-2">
                <label
                  className={cn(
                    'flex items-center gap-2 px-4 py-2 rounded-lg border cursor-pointer hover:border-primary transition-all h-8',
                    discountType === DiscountType.AMOUNT_OFF && 'border-primary bg-primary/5',
                  )}
                >
                  <input
                    type="radio"
                    value={DiscountType.AMOUNT_OFF}
                    checked={discountType === DiscountType.AMOUNT_OFF}
                    onChange={(e) => setDiscountDetails(
                      discountValue || 0,
                      e.target.value as DiscountType,
                      discountValueType || ValueType.FIXED,
                    )}
                    className="hidden"
                  />
                  <TagIcon fontSize={12} />
                  <span className="text-sm">Amount off</span>
                </label>

                <label
                  className={cn(
                    'flex items-center gap-2 px-4 py-2 rounded-lg border cursor-pointer hover:border-primary transition-all h-8',
                    discountType === DiscountType.FREE_SHIPPING && 'border-primary bg-primary/5',
                  )}
                >
                  <input
                    type="radio"
                    value={DiscountType.FREE_SHIPPING}
                    checked={discountType === DiscountType.FREE_SHIPPING}
                    onChange={(e) => setDiscountDetails(0, e.target.value as DiscountType, ValueType.FIXED)}
                    className="hidden"
                  />
                  <TruckIcon fontSize={12} />
                  <span className="text-sm">Free Shipping</span>
                </label>

                <label
                  className={cn(
                    'flex items-center gap-2 px-4 py-2 rounded-lg border cursor-pointer hover:border-primary transition-all h-8',
                    discountType === DiscountType.BUY_X_GET_Y && 'border-primary bg-primary/5',
                  )}
                >
                  <input
                    type="radio"
                    value={DiscountType.BUY_X_GET_Y}
                    checked={discountType === DiscountType.BUY_X_GET_Y}
                    onChange={(e) => setDiscountDetails(0, e.target.value as DiscountType, ValueType.FIXED)}
                    className="hidden"
                  />
                  <BagShoppingIcon fontSize={12} />
                  <span className="text-sm">Buy X get Y</span>
                </label>
              </div>
            </div>
          )}

          {(compensationMethod === CompensationMethod.COMMISSION
            || (compensationMethod === CompensationMethod.DISCOUNT && discountType === DiscountType.AMOUNT_OFF)) && (
            <div>
              <p className="text-sm text-muted-foreground mb-3">
                {compensationMethod === CompensationMethod.COMMISSION ? 'Commission Value' : 'Discount Value'}
              </p>
              <div className="flex items-center gap-2">
                <div className="relative flex-1 max-w-[300px]">
                  {((compensationMethod === CompensationMethod.COMMISSION ? commissionValueType : discountValueType) === ValueType.FIXED) && (
                    <span className="absolute left-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">$</span>
                  )}
                  <Input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*\.?\d*$/.test(inputValue)) {
                        const newValue = inputValue === '' ? null : Number(inputValue);
                        setInputValue(inputValue);
                        if (compensationMethod === CompensationMethod.COMMISSION) {
                          setCommissionDetails(newValue, commissionValueType || ValueType.PERCENTAGE);
                        } else {
                          setDiscountDetails(newValue, DiscountType.AMOUNT_OFF, discountValueType || ValueType.FIXED);
                        }
                      }
                    }}
                    placeholder={`Enter ${compensationMethod === CompensationMethod.COMMISSION ? 'percentage' : 'amount'}`}
                    className={cn(
                      'pr-8',
                      ((compensationMethod === CompensationMethod.COMMISSION ? commissionValueType : discountValueType) === ValueType.FIXED) && 'pl-8',
                    )}
                  />
                  {((compensationMethod === CompensationMethod.COMMISSION ? commissionValueType : discountValueType) === ValueType.PERCENTAGE) && (
                    <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">%</span>
                  )}
                </div>
                <Switcher
                  type="single"
                  items={[
                    { key: ValueType.PERCENTAGE, label: '%' },
                    { key: ValueType.FIXED, label: '$' },
                  ]}
                  activeKeys={[(compensationMethod === CompensationMethod.COMMISSION ? commissionValueType : discountValueType) || ValueType.PERCENTAGE]}
                  onChange={(values) => {
                    const newType = values[0] as ValueType;
                    if (compensationMethod === CompensationMethod.COMMISSION) {
                      setCommissionDetails(commissionValue || 0, newType);
                    } else {
                      setDiscountDetails(discountValue || 0, DiscountType.AMOUNT_OFF, newType);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </SectionCard>
  );
};
