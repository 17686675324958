import { ColumnDefinitionInput } from '../components/table';

const EXCLUDED_COLUMN_IDS = ['member', 'socialAccounts'] as const;

/**
 * Collects all visible column IDs from a column structure, including nested subColumns
 */
export const collectVisibleColumnIds = (columns: ColumnDefinitionInput[] | undefined | null): Set<string> => {
  const visibleIds = new Set<string>();

  if (!columns) {
    return visibleIds;
  }

  const addVisibleIds = (column: ColumnDefinitionInput) => {
    if (!column.hidden && !EXCLUDED_COLUMN_IDS.includes(column.id as typeof EXCLUDED_COLUMN_IDS[number])) {
      visibleIds.add(column.id);
    }
    if (column.subColumns) {
      column.subColumns.forEach(addVisibleIds);
    }
  };

  columns.forEach(addVisibleIds);
  return visibleIds;
};

/**
 * Creates a flat map of all columns by their IDs, including nested subColumns
 */
export const flattenColumns = (
  columns: ColumnDefinitionInput[] | undefined | null,
): Map<string, ColumnDefinitionInput> => {
  const columnMap = new Map<string, ColumnDefinitionInput>();

  if (!columns) {
    return columnMap;
  }

  const addToMap = (column: ColumnDefinitionInput) => {
    columnMap.set(column.id, column);
    if (column.subColumns) {
      column.subColumns.forEach(addToMap);
    }
  };

  columns.forEach(addToMap);
  return columnMap;
};

interface IncludeOptions {
  [key: string]: boolean;
}

/**
 * Processes columns recursively, calling the provided callback for each valid column
 * A valid column is one that:
 * 1. Is not in EXCLUDED_COLUMN_IDS
 * 2. Has a platform.metric ID format
 * 3. Is not hidden
 */
const processValidColumns = (
  columns: ColumnDefinitionInput[] | undefined | null,
  columnsMap: Map<string, ColumnDefinitionInput>,
  callback: (column: ColumnDefinitionInput) => void,
): void => {
  if (!columns) {
    return;
  }

  const processColumn = (column: ColumnDefinitionInput) => {
    const selectedColumn = columnsMap.get(column.id);
    const mergedColumn = { ...column, ...selectedColumn };
    if (
      !EXCLUDED_COLUMN_IDS.includes(mergedColumn.id as typeof EXCLUDED_COLUMN_IDS[number]) &&
      mergedColumn.id.includes('.') &&
      !mergedColumn.hidden
    ) {
      callback(mergedColumn);
    }

    if (selectedColumn?.isColumnExpanded && column.subColumns) {
      column.subColumns.forEach(processColumn);
    }
  };

  columns.forEach(processColumn);
};

/**
 * Generates platform-specific include options based on column visibility and expansion state
 */
export const generateIncludeOptions = (
  availableColumns: ColumnDefinitionInput[] | undefined | null,
  selectedColumns: ColumnDefinitionInput[] | undefined | null,
): IncludeOptions => {
  const visibleColumnIds = collectVisibleColumnIds(selectedColumns);
  const selectedColumnsMap = flattenColumns(selectedColumns);
  const includeOptions: IncludeOptions = {};

  if (!availableColumns) {
    return includeOptions;
  }

  processValidColumns(availableColumns, selectedColumnsMap, (column) => {
    const [platform, metric] = column.id.split('.');
    const includeKey = `include${platform.charAt(0).toUpperCase() + platform.slice(1)}${
      metric.charAt(0).toUpperCase() + metric.slice(1)
    }`;
    includeOptions[includeKey] = visibleColumnIds.has(column.id);
  });

  return includeOptions;
};

export const getColumnIds = (columns: ColumnDefinitionInput[]): string[] => {
  const columnIds: string[] = [];
  const columnsMap = flattenColumns(columns);

  processValidColumns(columns, columnsMap, (column) => {
    columnIds.push(column.id);
  });

  return columnIds;
};
