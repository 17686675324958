import React from 'react';
import { TooltipContent as TooltipContentShadcn } from '@frontend/shadcn/components/ui/tooltip';
import { TooltipArrow as TooltipArrowRadix, Portal as TooltipPortal } from '@radix-ui/react-tooltip';
import { cn } from '@frontend/shadcn/lib/utils';

export const TooltipHeader = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('pt-2 pb-0 px-3', className)} {...props} />,
);
TooltipHeader.displayName = 'TooltipHeader';

export const TooltipTitle = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('text-sm font-medium mb-4', className)} {...props} />,
);
TooltipTitle.displayName = 'TooltipTitle';

export const TooltipBody = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('px-3 pb-2 flex flex-col gap-4', className)} {...props} />
  ),
);
TooltipBody.displayName = 'TooltipBody';

export const TooltipItem = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn(className)} {...props} />,
);
TooltipItem.displayName = 'TooltipItem';

export const TooltipItemValue = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-xs font-normal text-primaryMuted', className)} {...props} />
  ),
);
TooltipItemValue.displayName = 'TooltipItemValue';

export const TooltipItemKey = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('text-xs font-medium mb-2', className)} {...props} />,
);
TooltipItemKey.displayName = 'TooltipItemKey';

export const TooltipArrow = React.forwardRef<SVGSVGElement, React.ComponentProps<typeof TooltipArrowRadix>>(
  ({ className, ...props }, ref) => (
    <TooltipArrowRadix ref={ref} className={cn('fill-white', className)} width={16} height={8} {...props} />
  ),
);
TooltipArrow.displayName = 'TooltipArrow';

export const TooltipContent = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof TooltipContentShadcn>>(
  ({ className, ...props }, ref) => (
    <TooltipPortal>
      <TooltipContentShadcn
        ref={ref}
        className={cn('max-w-[240px] bg-white text-primary rounded-lg shadow-lg border-none bg-opacity-100', className)}
        {...props}
      />
    </TooltipPortal>
  ),
);
TooltipContent.displayName = 'TooltipContent';
