import React from 'react';
import { useFilterFormData } from '../../filters-form/hooks/useFilterFormData';

interface UseProjectBadges {
  projectIds: number[];
}

export const useProjectBadges = ({ projectIds }: UseProjectBadges) => {
  const { projects } = useFilterFormData();

  const projectBadges = React.useMemo(() => projects?.filter((project) => projectIds.includes(project.id)), [
    projects,
    projectIds,
  ]);

  return {
    projectBadgesProps: {
      data: projectBadges,
    },
  };
};
