import * as React from 'react';
import {
  groupBy,
  sortBy,
} from 'lodash';

import {
  FacebookIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,
  SnapchatIcon,
} from '@revfluence/fresh-icons/brands/esm';
import {
  BlogIcon,
  ImageIcon,
  VideoIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { PhotoFilmIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Typography,
  Collapse,
  Space,
} from '@revfluence/fresh';
import { ContentType } from '@frontend/app/types/globalTypes';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import PostInstructions from '@frontend/applications/TermsApp/components/shared/PostInstructions/PostInstructions';

import { getContentTypeLabel } from '../../utils';
import { DueDateType, IContentGuidelinesWithDueDates } from '../../types/ContentGuidelines';
import {
  IContentGuidelineInstruction,
  ISortableGuideline,
  IContentGuidelineTitle,
} from '../../hooks/useState/actions';

import styles from './PostList.scss';
import ContentApproval from './ContentApproval';
import PostAttachments from '../../../shared/PostAttachments/PostAttachments';
import DueDateList from './DueDateList';
import { TDateRequirement } from '../../types/ContentDateRequirements';
import { BrandedContentToggle } from './BrandedContentToggle';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { useMemo, useCallback } = React;

interface IProps {
  daysToApproveContent: number;
  contentGuidelinesWithDueDate: Array<IContentGuidelinesWithDueDates>;
  onAddContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void;
  onToggleReviewBeforeSubmission: (
    requiresReviewBeforeSubmission: boolean,
    contentGuidelineInstanceId: number,
  ) => void;
  onToggleRequiresBrandedContentTag: (
    requiresBrandedContentTag: boolean,
    contentGuidelineInstanceId: number,
  ) => void;
  onToggleReviewWithinThreeDays: (
    requiresReviewWithinThreeDaysOfCreatorSubmission: boolean,
    contentGuidelineInstanceId: number,
  ) => void;
  onAddAttachment: (
    attachment: TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments,
    id: number,
  ) => void;
  onRemoveAttachment: (
    attachments: Array<TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments>,
    id: number,
  ) => void;
  onUpdateDueDate: (
    dueDateType: DueDateType,
    contentGuidelineInstanceId: number,
    dueDateIndex: number,
    dueDate: TDateRequirement,
  ) => void;
  onSortGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
  linkInsightsAlertForBrandedContent: boolean;
  linkBCAAccountAlert: boolean;
}
const PostList: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuidelinesWithDueDate,
    daysToApproveContent,
    onAddContentGuidelineInstruction,
    onToggleReviewBeforeSubmission,
    onToggleReviewWithinThreeDays,
    onToggleRequiresBrandedContentTag,
    onAddAttachment,
    onRemoveAttachment,
    onUpdateDueDate,
    onSortGuideline,
    onUpdateContentGuidelineTitle,
    linkBCAAccountAlert,
    linkInsightsAlertForBrandedContent,
  } = props;
  const getPostTitle = useCallback(
    (postCount: number, type: ContentType) => `${postCount.toLocaleString()} ${getContentTypeLabel(postCount, type)}`,
    [],
  );
  const factoryOnToggleBCT = (id: number) => (toggle: boolean) => onToggleRequiresBrandedContentTag(
    toggle,
    id,
  );
  const getIconByType = useCallback((type: ContentType): React.ReactElement => {
    switch (type) {
      case ContentType.INSTAGRAM_POST:
      case ContentType.INSTAGRAM:
        return <InstagramIcon />;
      case ContentType.INSTAGRAM_REEL:
        return <InstagramIcon />;
      case ContentType.INSTAGRAM_STORY:
        return <InstagramIcon />;
      case ContentType.INSTAGRAM_VIDEO:
        return <InstagramIcon />;
      case ContentType.ADDITIONAL_IMAGES:
        return <ImageIcon />;
      case ContentType.ADDITIONAL_VIDEOS:
        return <VideoIcon />;
      case ContentType.BLOG_DEDICATED:
        return <BlogIcon />;
      case ContentType.BLOG_MENTION:
        return <BlogIcon />;
      case ContentType.FACEBOOK_POST:
        return <FacebookIcon />;
      case ContentType.OTHER:
        return <ImageIcon />;
      case ContentType.PINTEREST_PIN:
      case ContentType.PINTEREST:
        return <PinterestIcon />;
      case ContentType.SNAPCHAT_STORY:
        return <SnapchatIcon />;
      case ContentType.TIKTOK_VIDEO:
        return <TiktokIcon />;
      case ContentType.TWITTER_POST:
        return <TwitterIcon />;
      case ContentType.YOUTUBE_DEDICATED:
      case ContentType.YOUTUBE_MENTION:
      case ContentType.YOUTUBE_SHORT:
        return <YoutubeIcon />;
      case ContentType.FLEXIBLE:
        return <PhotoFilmIcon />;
      default:
        throw new Error(`Unhandled content type: "${type}"`);
    }
  }, []);

  const posts = useMemo(
    () => {
      const contentGuidelinesGroupByType = sortBy(groupBy(contentGuidelinesWithDueDate, 'type'), 'networkType');
      let postList: IContentGuidelinesWithDueDates[] = [];
      Object.keys(contentGuidelinesGroupByType).forEach(
        (type: ContentType) => {
          postList = postList.concat(contentGuidelinesGroupByType[type]);
        },
      );
      return postList;
    },
    [contentGuidelinesWithDueDate],
  );

  return (
    <div className={styles.PostList}>
      {posts.map((post) => (
        <Space
          key={post.contentGuidelineInstanceId}
          direction="vertical"
        >
          <Title level={4}>
            <Text>
              {getIconByType(post.type)}
              {' '}
              {getPostTitle(post.dueDates.completeDate.length, post.type)}
            </Text>
          </Title>
          <Collapse>
            <Panel header={post.label} key={post.contentGuidelineInstanceId}>
              <div className={styles.collapseContent}>
                <PostInstructions
                  id={post.contentGuidelineInstanceId}
                  isReadOnly={false}
                  guidelines={post.guidelines}
                  onAddContentGuidelineInstruction={onAddContentGuidelineInstruction}
                  onSortGuideline={onSortGuideline}
                  onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
                />
                <PostAttachments
                  id={post.contentGuidelineInstanceId}
                  attachments={post.attachments}
                  onAddAttachment={onAddAttachment}
                  onRemoveAttachment={onRemoveAttachment}
                />
              </div>
            </Panel>
          </Collapse>
          <BrandedContentToggle
            contentType={post.type}
            onToggle={factoryOnToggleBCT(post.contentGuidelineInstanceId)}
            checked={post.requiresBrandedContentTag}
            linkBCAAccountAlert={linkBCAAccountAlert}
            linkInsightsAlertForBrandedContent={linkInsightsAlertForBrandedContent}
          />
          <ContentApproval
            daysToApproveContent={daysToApproveContent}
            requiresReviewBeforeSubmission={post.requiresReviewBeforeSubmission}
            requiresReviewWithinThreeDaysOfCreatorSubmission={post.requiresReviewWithinThreeDaysOfCreatorSubmission}
            contentGuidelineInstanceId={post.contentGuidelineInstanceId}
            onToggleReviewBeforeSubmission={onToggleReviewBeforeSubmission}
            onToggleReviewWithinThreeDays={onToggleReviewWithinThreeDays}
          />
          <DueDateList
            requiresReviewBeforeSubmission={post.requiresReviewBeforeSubmission}
            dueDates={post.dueDates}
            contentGuidelineInstanceId={post.contentGuidelineInstanceId}
            onUpdateDueDate={onUpdateDueDate}
          />
        </Space>
      ))}
    </div>
  );
});

PostList.displayName = 'Posts';

export default PostList;
