import * as React from 'react';
import { useProgramsQuery, useFuzzySearchByKeys } from '@frontend/app/hooks';
import { SelectList } from '../../SelectList';

interface IValue {
  programIds: number[];
  programStatus: string;
}

interface IProps {
  defaultValue: IValue;
  onChange(value: IValue);
  visible?: boolean;
}

const SEARCH_KEYS = ['title'];

export const ProgramInclusion: React.FC<IProps> = (props) => {
  const {
    data: {
      programs = null,
    } = {},
  } = useProgramsQuery({ skip: !props.visible });

  const handleSearch = useFuzzySearchByKeys(programs, SEARCH_KEYS);

  const handleChange = (selectedProgramIds: number[]) => {
    props.onChange({ ...props.defaultValue, programIds: selectedProgramIds });
  };

  type TOption = typeof programs[0];

  return (
    <SelectList
      showSearch
      onSearchRequest={handleSearch}
      options={programs}
      defaultSelectedIds={props.defaultValue ? props.defaultValue.programIds : []}
      mapOptionToId={(option: TOption) => option.id}
      mapOptionToLabel={(option: TOption) => option.title}
      onChange={handleChange}
    />
  );
};

ProgramInclusion.defaultProps = {
  visible: true,
};
