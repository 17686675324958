import { RichTextEditor } from '@frontend/app/components';
import { toolbarModules, toolbarOptions } from '@frontend/app/utils/richTextEditormodules';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Label } from '@frontend/shadcn/components/ui/label';
import { cn } from '@frontend/shadcn/lib/utils';
import React from 'react';

export default function TitleDescriptionContent(): JSX.Element {
  const {
    landingPageData, setLandingPageData, fieldErrors, clearFieldError,
} = useLandingPageContext();

  const handleInputChange = (key: string, value: string) => {
    setLandingPageData((prev) => ({
      ...prev,
      basic: {
        ...prev.basic,
        [key]: value,
      },
    }));
    clearFieldError(`basic.${key}`);
  };

  return (
    <div className="flex flex-col gap-4 px-4 w-full max-w-2xl">
      {/* Title Section */}
      <div className="flex flex-col gap-2">
        <Label className="text-grey-5 text-sm font-medium">Title</Label>
        <Input
          onChange={(e) => handleInputChange('title', e.target.value)}
          value={landingPageData.basic.title || ''}
          className={cn(
            'bg-grey-0 border-grey-2 text-grey-6',
            fieldErrors?.basic?.title && 'border-red-500 focus-visible:ring-red-500',
          )}
        />
        {fieldErrors?.basic?.title && <p className="text-sm text-red-500 mt-1">{fieldErrors.basic.title}</p>}
      </div>

      {/* Description Section */}
      <div className="flex flex-col gap-2">
        <Label className="text-grey-5 text-sm font-medium">Description</Label>
        <div className={cn('rounded-md', fieldErrors?.basic?.description && 'ring-1 ring-red-500')}>
          <RichTextEditor
            toolbarOptions={toolbarOptions}
            toolbarModules={toolbarModules}
            boundSelector="#commentEditor"
            placeholder="Enter your message..."
            value={landingPageData.basic.description || ''}
            onChange={(value) => handleInputChange('description', value)}
            key={landingPageData.basic.description ? 'description-populated' : 'description-empty'}
          />
        </div>
        {fieldErrors?.basic?.description && (
          <p className="text-sm text-red-500 mt-1">{fieldErrors.basic.description}</p>
        )}
      </div>
    </div>
  );
}
