import * as React from 'react';
import { UserAvatar } from '@frontend/app/components';
import { useGetCurrentClient } from '@frontend/app/hooks';
import { filter, first, map } from 'lodash';
import { MultiSelect } from '../MultiSelect/MultiSelect';

interface TeamMemberSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  includeGuests?: boolean;
  className?: string;
  placeholder?: string;
}

export const TeamMemberSelect: React.FC<TeamMemberSelectProps> = ({
  includeGuests = false,
  className,
  placeholder = 'Select teammates...',
  value,
  onChange,
}) => {
  const { client } = useGetCurrentClient();

  const managers = React.useMemo(() => {
    if (includeGuests) {
      return client?.users;
    }

    return filter(client?.users, (u) => {
      const clientsData = u.auth0User?.appMetadata?.clients || {};
      const role = first(clientsData[client.id]?.roles) as string;
      return ['admin:default', 'manager:admin', 'manager:default'].includes(role);
    });
  }, [client, includeGuests]);

  const options = React.useMemo(
    () =>
      map(managers, (u) => ({
        label: (
          <div className="flex items-center gap-2">
            <UserAvatar className="h-5 w-5" size={18} name={u.name} />
            <span>{u.name}</span>
          </div>
        ),
        value: u.id,
        searchValue: u.name,
      })),
    [managers],
  );

  return (
    <MultiSelect
      className={className}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  );
};
