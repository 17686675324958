import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Label } from '@frontend/shadcn/components/ui/label';

interface IProps extends IShopifyFormElementProps<'codePrefix'> {
  onAddPrefixChange: (checked: boolean) => void;
  checked: boolean;
}

export const RefreshAddPrefix: React.FC<Readonly<IProps>> = React.memo(({
  onAddPrefixChange,
  checked,
}) => {
  const handleCheckboxChange = (checked: boolean) => {
    onAddPrefixChange(checked);
  };
  return (
    <Form.Item className="flex items-center gap-2 !mb-0">
      <div className="flex items-center">
        <Checkbox onCheckedChange={handleCheckboxChange} checked={checked} />
        <Label className="ml-2">Add Prefix</Label>
      </div>
    </Form.Item>
  );
});
RefreshAddPrefix.displayName = 'RefreshAddPrefix';
