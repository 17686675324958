import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { VariableInput } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/VariableInput';
import {
  OfferVariableKey,
  getVariableDisplay,
  convertTemplateToFrontend,
  convertTemplateToBackend,
} from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/variables';
import { Label } from '@frontend/shadcn/components/ui/label';
import React, { useState } from 'react';

const VARIABLE_OPTIONS = [OfferVariableKey.DISCOUNT].map(getVariableDisplay);

export default function DiscountButtonContent() {
  const { landingPageData, setLandingPageData, fieldErrors } = useLandingPageContext();

  // Convert backend template to frontend display when initializing
  const [inputValue, setInputValue] = useState(
    convertTemplateToFrontend(landingPageData.basic.discountOfferButton.title || ''),
  );

  const handleInputChange = (value: string) => {
    setInputValue(value);
    // Convert to backend format before saving
    setLandingPageData((prev) => ({
      ...prev,
      basic: {
        ...prev.basic,
        discountOfferButton: {
          ...prev.basic.discountOfferButton,
          title: convertTemplateToBackend(value),
        },
      },
    }));
  };

  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="flex flex-col gap-2">
        <Label className="text-grey-5 text-sm font-medium">Discount Button Text</Label>
        <VariableInput
          id="discount-input"
          value={inputValue}
          onChange={handleInputChange}
          variables={VARIABLE_OPTIONS}
          placeholder="Get {{Offer Discount}} Off"
          tooltipText="Insert dynamic discount variable"
          error={!!fieldErrors?.basic?.discountOfferButton?.title}
        />
        {fieldErrors?.basic?.discountOfferButton?.title && (
          <p className="text-sm text-red-500">{fieldErrors.basic.discountOfferButton.title}</p>
        )}
      </div>
    </div>
  );
}
