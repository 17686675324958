import * as React from 'react';
import { useState } from 'react';

import { Pagination } from '@frontend/app/components';
import { DataTable } from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import {
    Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/shadcn/components/ui/tabs';

export interface IKlaviyoTableRow {
    name: string,
    id: number,
    keepSynced: boolean,
}

interface IProps {
    rowsLoading: boolean,
    totalGroupCount: number,
    totalProjectCount: number,
    currentGroupPage: number,
    currentProjectPage: number,
    setCurrentGroupPage: (page: number) => void,
    setCurrentProjectPage: (page: number) => void,
    pageSize: number,
    projectColumnDef: ColumnDef<{ name: string, id: number, keepSynced: boolean }>[],
    groupColumnDef: ColumnDef<{ name: string, id: number, keepSynced: boolean }>[],
    projectRows: IKlaviyoTableRow[],
    groupRows: IKlaviyoTableRow[],
}

const ProjectGroupTable: React.FC<IProps> = ({
    rowsLoading,
    totalGroupCount,
    totalProjectCount,
    currentGroupPage,
    currentProjectPage,
    setCurrentGroupPage,
    setCurrentProjectPage,
    pageSize,
    projectColumnDef,
    groupColumnDef,
    projectRows,
    groupRows,
  }) => {
    const [openTab, setOpenTab] = useState('projects');
    return (
      <Tabs value={openTab} defaultValue="projects">
        <TabsList>
          <TabsTrigger asChild value="projects">
            <div
              className="cursor-pointer text-muted-foreground hover:text-foreground"
              onClick={() => setOpenTab('projects')}
            >
              Projects
            </div>
          </TabsTrigger>
          <TabsTrigger asChild value="groups">
            <div
              className="cursor-pointer text-muted-foreground hover:text-foreground"
              onClick={() => setOpenTab('groups')}
            >
              Groups
            </div>
          </TabsTrigger>
        </TabsList>
        <div className="h-6" />

        <div>
          {openTab === 'groups' && (
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              {totalGroupCount}
              {' '}
              groups
            </div>

            <Pagination
              total={totalGroupCount}
              page={currentGroupPage}
              setPage={setCurrentGroupPage}
              pageSize={pageSize}
              showTotal
            />
          </div>
        )}
          {openTab === 'projects' && (
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              {totalProjectCount}
              {' '}
              projects
            </div>
            <Pagination
              total={totalProjectCount}
              page={currentProjectPage}
              setPage={setCurrentProjectPage}
              pageSize={pageSize}
              showTotal
            />
          </div>
        )}
        </div>
        <TabsContent value="projects">
          {!rowsLoading && <DataTable columns={projectColumnDef} data={projectRows} />}
        </TabsContent>
        <TabsContent value="groups">
          {!rowsLoading && <DataTable columns={groupColumnDef} data={groupRows} />}
        </TabsContent>
      </Tabs>
  );
};

export default ProjectGroupTable;
