import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import React from 'react';
import { FormInstance } from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { AdditionalSettingsShopify } from '../../../ShopifyPromoCodeSectionNew/FormElements/RefreshForm/AdditionalSettings/AdditionalSettingsShopify';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormOptions,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  TDisabledMap,
  TFormValues,
  IAffiliateLinksFormValues,
} from '../../../../types';
import AdditionalSettingsTune from '../../../AffiliateLinkSection/FormElements/RefreshForm/AdditionalSettings/AdditionalSettingsTune';

interface IProps {
  source: OFFER_SOURCE;
  disabledShopify: TDisabledMap<IShopifyPromoCodeFormValues>;
  disabledAffiliatesLinks: TDisabledMap<IAffiliateLinksFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  values: TFormValues;
  formOptionData: IShopifyPromoCodeFormOptions;
  isSubscriptionEnable?: boolean;
  mode: OfferFormModes;
  formRef: FormInstance;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[];
  connectedClients: GetConnectedShopify_clientConnections[];
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  hasMembers?: boolean;
  shopifyStoreName?: string;
  isOfferPromoLink?: boolean;
  isLandingPageEnabled?: boolean;
  initialAllowedDomains: string[];
  saveStatus: string;
}
// accept offer_spurce as a prop
export const AdditionalSettings = ({
  source,
  disabledShopify,
  disabledAffiliatesLinks,
  onFieldFocused,
  values,
  formOptionData,
  isSubscriptionEnable,
  mode,
  formRef,
  connectedAdvertiserForSync,
  connectedClients,
  handleFormAsyncActions,
  hasMembers,
  shopifyStoreName,
  isOfferPromoLink,
  isLandingPageEnabled,
  initialAllowedDomains,
  saveStatus,
}: IProps) => {
  return source === OFFER_SOURCE.SHOPIFY ? (
    <AdditionalSettingsShopify
      disabled={disabledShopify}
      values={values as IShopifyPromoCodeFormValues}
      formOptionData={formOptionData}
      isSubscriptionEnable={isSubscriptionEnable}
      mode={mode}
      formRef={formRef}
      connectedAdvertiserForSync={connectedAdvertiserForSync}
      connectedClients={connectedClients}
      handleFormAsyncActions={handleFormAsyncActions}
      hasMembers={hasMembers}
      shopifyStoreName={shopifyStoreName}
      isOfferPromoLink={isOfferPromoLink}
      isLandingPageEnabled={isLandingPageEnabled}
      saveStatus={saveStatus}
    />
  ) : (
    <AdditionalSettingsTune
      values={values as IAffiliateLinksFormValues}
      disabled={disabledAffiliatesLinks}
      handleFormAsyncActions={handleFormAsyncActions}
      initialAllowedDomains={initialAllowedDomains}
      hasMembers={hasMembers}
      onFieldFocused={onFieldFocused}
    />
  );
};
