import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';
import { CalendarIcon } from 'lucide-react';
import moment from 'moment';

import { FormAsyncAction, IAffiliateLinksFormElementProps, OfferFormAsyncActions } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { OfferInputHeader } from '@frontend/applications/AffiliatesApp/components/OfferForm/FormSections/CommonSection/FormElements/RefreshForm/OfferElementHeader';
import { P } from '@frontend/shadcn/components/typography/p';

interface IProps extends IAffiliateLinksFormElementProps<'expirationDate'> {
  handleFormAsyncActions: (action: FormAsyncAction) => void;
  values: moment.Moment;
}

export function RefreshOfferEndDate({ name, disabled, handleFormAsyncActions, values }: IProps): JSX.Element {
  const selectedDate = values ? moment(values) : moment();
  const [open, setOpen] = React.useState(false);

  const handleChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      const momentDate = moment(selectedDate);
      if (!momentDate.isValid()) {
        console.error('Invalid date selected');
        return;
      }
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: name, value: momentDate },
      });
      setOpen(false);
    }
  };
    return (
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="offer-end-date">
          <AccordionTrigger className="hover:no-underline p-0 mt-2">
            <div className="flex flex-col gap-1">
              <P className="text-grey-6" fontWeight="font-medium">Offer End Date</P>
            </div>
          </AccordionTrigger>
          <AccordionContent className="w-full max-w-[230px]">
            <p className="text-sm font-normal text-grey-4 mb-3">Set End Date of the offer</p>
            <OfferInputHeader text="End Date" />
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <div className="relative w-full">
                  <Input
                    className="pr-10 cursor-pointer min-h-8"
                    value={selectedDate.format('MMM DD, YYYY')}
                    readOnly
                    onClick={() => setOpen(true)}
                    disabled={disabled}
                    data-testid="offer-end-date-input"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer" size={18} onClick={() => setOpen(true)} />
                </div>
              </PopoverTrigger>
              <PopoverContent align="start" className="p-0 w-auto outline-none">
                <Calendar
                  mode="single"
                  selected={selectedDate.toDate()}
                  onSelect={handleChange}
                  className="rounded-md border shadow"
                  disabled={disabled}
                  fromDate={moment().endOf('day').toDate()}
                />
              </PopoverContent>
            </Popover>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
}
