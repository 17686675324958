import { Select, SelectContent, SelectItem, SelectTrigger } from '@frontend/shadcn/components/ui/select';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { CircleQuestionIcon } from '@revfluence/fresh-icons/solid/esm';
import React from 'react';

const NeedHelpDropdown = ({ source }) => {
  const firstItemHref =
    source === OFFER_SOURCE.TUNE
      ? 'https://help.aspireiq.com/en/articles/9662931-how-to-create-a-link-tracking-offer-generate-unique-links'
      : 'https://help.aspireiq.com/en/articles/9662813-how-to-create-a-promo-code-offer-generate-unique-codes';

  const handleSelect = (value) => {
    const links = {
      setup: firstItemHref,
      payout: 'https://help.aspireiq.com/en/articles/8674368-how-to-create-multiple-payout-options-within-offers',
      'learn-more': 'https://help.aspireiq.com/en/collections/2632994-sales-tracking',
    };

    if (links[value]) {
      window.open(links[value], '_blank');
    }
  };

  return (
    <Select onValueChange={handleSelect}>
      <SelectTrigger className="flex items-center space-x-2 text-base text-grey-0 focus:outline-none hover:bg-transparent hover:text-grey-0 border-0">
        <CircleQuestionIcon fontSize={16} className="text-primary-foreground" />
        <span>Need Help?</span>
      </SelectTrigger>
      <SelectContent className="w-72 p-2 bg-white shadow-md rounded-lg">
        <SelectItem value="setup"> {source === OFFER_SOURCE.SHOPIFY ? 'Setting Up a Promo Code Offer' : 'Setting Up a Link Offer'}</SelectItem>
        <SelectItem value="payout">Creating Payout Tiers</SelectItem>
        <SelectItem value="learn-more">Learn More</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default NeedHelpDropdown;
