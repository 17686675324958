import React, { useMemo, useEffect, useCallback } from 'react';
import {
 Card, Col, Row, Select, Space, Typography,
} from '@revfluence/fresh';
import { BookIcon, CartShoppingIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetCatalogs } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { useGetSelectionCriteria } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetSelectionCriteria';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { useRouteMatch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { GET_PROJECT_CONFIG_BY_TYPE_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries';
import { GetProjectConfigByProjectIdAndType } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProjectConfigByProjectIdAndType';
import { useSendCatalogContext } from './SendCatalogContext';
import styles from './SendCatalog.scss';

const { Title } = Typography;

export const BulkSelection = () => {
  const {
    selectedCatalog, setSelectedCatalog, selectedRule, setSelectedRule,
  } = useSendCatalogContext();
  const {
    params: {
      projectId,
    },
  } = useRouteMatch<{ projectId: string }>();
  const { catalogs } = useGetCatalogs();
  const { projectConfig: projectConfigFromBE } = useGetProjectConfigByType({
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !projectId,
  });

  const apolloClient = useApolloClient();
  const projectConfigFromCache = apolloClient.readQuery<GetProjectConfigByProjectIdAndType>({
    query: GET_PROJECT_CONFIG_BY_TYPE_QUERY,
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
  });
  const projectConfig = projectConfigFromCache?.projectConfig || projectConfigFromBE;
  const { criteria, loading: isCriteriaLoading } = useGetSelectionCriteria({
    variables: {
      brandCatalogId: selectedCatalog,
    },
    skip: !selectedCatalog,
  });

  const filteredCatalogs = useMemo(() => catalogs.filter((catalog) =>
    projectConfig?.brandCatalogs.includes(catalog.id)), [catalogs, projectConfig?.brandCatalogs]);

  const handleCatalogChange = useCallback((value: number) => {
    setSelectedCatalog(value);
    setSelectedRule(null);
  }, [setSelectedCatalog, setSelectedRule]);

  const handleRuleChange = useCallback((value: number) => {
    setSelectedRule(value);
  }, [setSelectedRule]);

  // Auto-select first catalog if only one is available
  useEffect(() => {
    if (filteredCatalogs.length === 1 && !selectedCatalog) {
      handleCatalogChange(Number(filteredCatalogs[0].id));
    }
  }, [filteredCatalogs, selectedCatalog, handleCatalogChange]);

  // Auto-select first rule if only one is available
  useEffect(() => {
    if (criteria?.length === 1 && !selectedRule) {
      handleRuleChange(Number(criteria[0].id));
    }
  }, [criteria, selectedRule, handleRuleChange]);

  return (
    <>
      <Card className={styles.selectContainer}>
        <Space direction="vertical" size="middle">
          <Row justify="space-between">
            <Col>
              <Space>
                <BookIcon />
                <Title level={5}>Select Catalog</Title>
              </Space>
            </Col>
          </Row>
          <Select
            placeholder="Select a Catalog"
            className={styles.select}
            value={selectedCatalog}
            onChange={(value) => handleCatalogChange(value)}
          >
            {filteredCatalogs.map((catalog) => (
              <Select.Option value={catalog.id} key={catalog.id}>
                {catalog.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Card>
      <Card className={styles.selectContainer}>
        <Space direction="vertical" size="middle">
          <Row justify="space-between">
            <Col>
              <Space>
                <CartShoppingIcon />
                <Title level={5}>Selection Rule</Title>
              </Space>
            </Col>
          </Row>
          <Select
            value={selectedRule}
            className={styles.select}
            onChange={(value) => handleRuleChange(value)}
            disabled={!isCriteriaLoading && (!selectedCatalog || !criteria.length)}
            placeholder={isCriteriaLoading ? 'Loading...' : !selectedCatalog || criteria?.length ? 'Select a Rule' : 'No rules available'}
            loading={isCriteriaLoading}
          >
            {criteria.map((rule) => (
              <Select.Option value={Number(rule.id)} key={Number(rule.id)}>
                {rule.label}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Card>
    </>
  );
};
