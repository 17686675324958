import * as React from 'react';
import { Alert } from 'antd';
import {
  isEmpty, isNull, keys, map,
} from 'lodash';

import {
  IRowData, ITableColumnConfig, LoadSpinner,
  Table,
} from '@components';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { IColumnsType } from '@revfluence/fresh';
import { arrangeTableColumns } from '@frontend/applications/AffiliatesApp/utils/columnOrder';
import { DataFormat, formatValue } from '@frontend/applications/AffiliatesApp/utils';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { TProgram } from '../types';
import { TState } from '../hooks/useAddMembers/state';
import { isOfferShopify, isOfferTune } from '../utils';
import AffiliateTableSTA from '../../AffiliateTableSTA/AffiliateTableSTA';

const { useMemo } = React;

const TABLE_WIDTH = 924;
const CHECKBOX_COL_WIDTH = 56;

interface IProps {
  offerSource: OFFER_SOURCE;
  markProgramsSelected: (programs: readonly TProgram[]) => void;
  programs: readonly TProgram[] | null;
  selectedPrograms: TState['selectedPrograms'];
  isNewFlow: boolean;
}

export const SelectPrograms: React.FC<Readonly<IProps>> = (props) => {
  const {
    markProgramsSelected,
    programs,
    offerSource,
    selectedPrograms,
    isNewFlow,
  } = props;
  const isShopify = useMemo(() => isOfferShopify(offerSource), [offerSource]);
  const isTune = useMemo(() => isOfferTune(offerSource), [offerSource]);
  const { isProjects } = useClientFeatures();
  const programLabel = isProjects ? 'Project' : 'Program';
  const data = useMemo(() => map(programs, (c): IRowData => ({
    ...c,
    id: c.id.toString(),
    key: c.id.toString(),
    disableSelection: c.memberCount <= c.memberCountWithCodes,
    _raw: c,
  })), [programs]);
  const columnConfig: ITableColumnConfig[] = useMemo(() => {
    const columns: ITableColumnConfig[] = [{
      headerName: `${programLabel}s`,
      field: 'name',
      width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 150,
    },
    {
      cellType: 'numeric',
      headerName: `Members in ${programLabel}`,
      field: 'memberCount',
      formatValue: (data) => formatValue(DataFormat.Integer, data),
      justify: 'flex-end',
      width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
    },
    {
      cellType: 'numeric',
      headerName: 'Members In Offer',
      field: 'memberCountInOffer',
      formatValue: (data) => formatValue(DataFormat.Integer, data),
      justify: 'flex-end',
      width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
    }];
    if (isShopify) {
      columns.push({
        cellType: 'numeric',
        headerName: 'Members With Codes',
        field: 'memberCountWithCodes',
        formatValue: (data) => formatValue(DataFormat.Integer, data),
        justify: 'flex-end',
        width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
      });
    }
    if (isTune) {
      columns.push({
        cellType: 'numeric',
        headerName: 'Members With Links',
        field: 'memberCountWithLinks',
        formatValue: (data) => formatValue(DataFormat.Integer, data),
        justify: 'flex-end',
        width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
      });
    }
    return columns;
  }, [isShopify, isTune, programLabel]);
  const newColumnConfig: IColumnsType<TProgram> = useMemo(() => {
    const columns: IColumnsType<TProgram> = [{
      title: `${programLabel}s`,
      key: 'name',
      dataIndex: 'name',
      width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 150,
    },
    {
      title: `Members in ${programLabel}`,
      key: 'memberCount',
      dataIndex: 'memberCount',
      align: 'left',
      width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
    },
    {
      title: 'Members In Offer',
      key: 'memberCountInOffer',
      dataIndex: 'memberCountInOffer',
      align: 'left',
      width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
    }];
    if (isShopify) {
      columns.push({
        title: 'Members With Codes',
        key: 'memberCountWithCodes',
        dataIndex: 'memberCountWithCodes',
        align: 'left',
        width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
      });
    }
    if (isTune) {
      columns.push({
        title: 'Members With Links',
        key: 'memberCountWithLinks',
        dataIndex: 'memberCountWithLinks',
        align: 'left',
        width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 50,
      });
    }
    return columns;
  }, [isShopify, isTune, programLabel]);

  const selectedCommunityIds = useMemo(
    () => map(keys(selectedPrograms), (c) => c.toString()),
    [selectedPrograms],
  );

  if (isNull(programs)) {
    return (
      <LoadSpinner />
    );
  }

  if (isEmpty(programs)) {
    return (
      <Alert message={`No ${programLabel}s found.`} showIcon type="info" />
    );
  }
  const rowSelection = {
    onChange: (_, selectedRows) => {
      markProgramsSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.disableSelection,
      name: record.name,
      hideSelectAll: true,
      defaultChecked: true,
      disableSelection: false,
    }),
  };
  return (
    <>
      {
        isNewFlow ? (
          <AffiliateTableSTA<IRowData>
            dataSource={data}
            columns={arrangeTableColumns(newColumnConfig)}
            rowSelection={rowSelection}
            pagination={false}
          />
) : (
  <Table
    columns={columnConfig}
    config={{
            allowSearch: false,
            configurableColumns: false,
            reorderableColumns: false,
            rowHeight: 56,
            scrollable: true,
            selectable: true,
            striped: false,
          }}
    data={data}
    initialSelectedIds={selectedCommunityIds}
    onSelectedDataChange={markProgramsSelected}
    paddingBottom={100}
  />
)
      }
    </>
  );
};
