import * as React from 'react';
import classNames from 'classnames';
import { Tooltip } from '@revfluence/fresh';

interface IProps {
  align?: 'left' | 'center' | 'right';
  className?: string;
  width?: string | number;
  tooltip?: string;
}

export const EllipsisLabel: React.FC<React.PropsWithChildren<IProps>> = ({
  align = 'left',
  className = '',
  width = 100,
  tooltip,
  children,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  // Check if the content is overflowing
  React.useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      setIsOverflowing(contentElement.scrollWidth > contentElement.clientWidth);
    }
  }, [children, width]);

  const content = (
    <div
      ref={contentRef}
      className={classNames(
        'overflow-hidden whitespace-nowrap text-ellipsis',
        {
          'text-left': align === 'left',
          'text-center': align === 'center',
          'text-right': align === 'right',
        },
        'text-grey-6',
        className,
      )}
      style={{ width: typeof width === 'number' ? `${width}px` : width }}
    >
      {children}
    </div>
  );

  if (!isOverflowing || !tooltip) {
    return content;
  }

  return (
    <Tooltip title={tooltip}>
      {content}
    </Tooltip>
  );
};
