import * as React from 'react';
import { Input, InputRef } from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import cx from 'classnames';
import { SpinnerIcon } from './SpinnerIcon';

import styles from './SearchInput.scss';

interface SearchInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  /** Current value of the search input */
  value: string;

  /** Callback fired when the search input value changes
   * @param event - The change event containing the new value
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /** Placeholder text shown when the input is empty
   * @default "Search..."
   */
  placeholder?: string;

  /** CSS class name for custom styling */
  className?: string;

  /** ID of the element containing the search results. Used for accessibility */
  resultsId?: string;

  /** Whether the search is currently loading */
  isLoading?: boolean;
}

/**
 * SearchInput component provides a search box with a magnifying glass icon
 * and clear button for filtering content.
 *
 * @example
 * ```tsx
 * <SearchInput
 *   value={searchTerm}
 *   onChange={handleSearch}
 *   placeholder="Search..."
 *   resultsId="search-results"
 * />
 * ```
 */
const SearchInputComponent = React.forwardRef<InputRef, SearchInputProps>(({
  value,
  onChange,
  placeholder = 'Search...',
  className,
  resultsId,
  isLoading = false,
}, ref) => (
  <div className={cx(styles.searchWrapper, className)}>
    {isLoading ? (
      <SpinnerIcon
        className={styles.searchIcon}
        aria-hidden
        size={16}
      />
    ) : (
      <MagnifyingGlassIcon
        className={styles.searchIcon}
        aria-hidden="true"
      />
    )}
    <Input
      ref={ref}
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={styles.searchInput}
      role="searchbox"
      aria-label={placeholder}
      aria-controls={resultsId}
    />
    {value && (
    <button
      type="button"
      onClick={() => onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
      className={styles.clearButton}
      aria-label="Clear search"
    >
      ×
    </button>
    )}
  </div>
));

SearchInputComponent.displayName = 'SearchInput';

export const SearchInput = SearchInputComponent;
