import gql from 'graphql-tag';

export const TABLE_CONFIGURATION_FRAGMENT = gql`
  fragment TableConfigurationFragment on TableConfiguration {
    id
    userId
    clientId
    tableName
    columnDefinitions
    createdDate
    updatedDate
    version
  }
`;
