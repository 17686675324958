import * as React from 'react';
import cn from 'classnames';

import {
  Typography, Divider, Space, Badge,
} from '@revfluence/fresh';
import { ScaleBalancedIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  InstagramIcon, PinterestIcon, TiktokIcon, TwitterIcon, YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';

import Statistics from '../../../../../components/Statistics';

import styles from '../TabContent.module.scss';
import { IInvestmentValues, IReportingPageProps } from '../../../Reporting';
import { ValueRow } from '../ValueRow';
import { InvestmentRow } from '../InvestmentRow';

const { Title, Text } = Typography;

interface IImpressionsProps {
  impressionsValue: IReportingPageProps['impressionsValues'];
  investmentValue: IInvestmentValues;
  impressionsTotalValue: number;
  igStoryEstimationEnabled?: boolean;
}

const Impressions = ({
  impressionsValue,
  investmentValue,
  impressionsTotalValue,
  igStoryEstimationEnabled,
}: IImpressionsProps) => (
  <Space direction="vertical" className={cn(styles.TabContent)} size="small">
    <Title level={4}>Awareness Value</Title>
    <Title level={5}>
      <ScaleBalancedIcon style={{ marginRight: '8px' }} />
      How it's calculated
    </Title>
    <Divider />
    <Space
      direction="vertical"
      size={13}
      style={{
        overflowY: 'scroll', maxHeight: 300, paddingRight: 8, width: '100%',
      }}
    >
      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Posts
        </Text>
        <ValueRow title="Views" value={impressionsValue.instagramPosts.views} />
        <ValueRow title="Potential Reach" value={impressionsValue.instagramPosts.reach} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Reels
        </Text>
        <ValueRow title="Views" value={impressionsValue.instagramReels.views} />
        <ValueRow title="Potential Reach" value={impressionsValue.instagramReels.reach} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Stories
        </Text>
        <ValueRow title="Impressions" value={impressionsValue.instagramStories.impressions} />
        {(igStoryEstimationEnabled || impressionsValue.instagramStories.estimatedImpressions.value > 0)
          && <ValueRow title="Est. Impressions" value={impressionsValue.instagramStories.estimatedImpressions} />}
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Videos
        </Text>
        <ValueRow title="Views" value={impressionsValue.instagramVideos.views} />
        <ValueRow title="Potential Reach" value={impressionsValue.instagramVideos.reach} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <TiktokIcon />
          {' '}
          Tiktok
        </Text>
        <ValueRow title="Views" value={impressionsValue.tiktok.views} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <YoutubeIcon />
          {' '}
          YouTube Videos
        </Text>
        <ValueRow title="Views" value={impressionsValue.youtube.views} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <YoutubeIcon />
          {' '}
          YouTube Shorts
        </Text>
        <ValueRow title="Views" value={impressionsValue.youtubeShorts.views} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <PinterestIcon />
          {' '}
          Pinterest
        </Text>
        <ValueRow title="Impressions" value={impressionsValue.pinterest.impressions} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <TwitterIcon />
          {' '}
          Twitter
        </Text>
        <ValueRow title="Potential Reach" value={impressionsValue.twitter.reach} />
      </div>
    </Space>

    <Divider />
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.plum)} />
        Awareness Total Value
      </Text>
      <Statistics value={impressionsTotalValue.toFixed(2)} prefix="$" size="default" />
    </Space>
    <Divider />
    <InvestmentRow values={investmentValue} />
    <Divider />
    <Space>
      <Title level={5}>
        Awareness Net Value
      </Title>
      <Title level={5}>
        <Statistics value={(impressionsTotalValue - investmentValue.total).toFixed(2)} prefix="$" size="large" strong />
      </Title>
    </Space>
  </Space>
);

export default Impressions;
