import React from 'react';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Tooltip } from '@revfluence/fresh';
import { tagColors } from '../hooks';
import { simpleHash } from '../containers/Settings/ProductFulfillment/utils';

interface BadgeGroupProps {
  items: string[]
  withColor?: boolean
}
const BadgeGroup = ({ items, withColor = true }: BadgeGroupProps) => {
  if (!items || items.length === 0) {
    return null;
  }

  const firstProduct = items[0];
  const remainingProducts = items.length - 1;

  if (!firstProduct) {
    return null;
  }

  return (
    <div className="flex gap-2 relative">
      <Badge
        variant="secondary"
        style={withColor && tagColors[simpleHash(firstProduct) % tagColors.length]}
        className="min-h-6 h-auto"
      >
        {firstProduct}
      </Badge>
      {remainingProducts > 0 && (
        <Tooltip title={
          <div className="flex flex-col gap-1 flex-wrap">
            {items.slice(1).map((item) => (
              <Badge
                key={item}
                variant="secondary"
                style={withColor && tagColors[simpleHash(item) % tagColors.length]}
                className="min-h-6 h-auto"
              >
                {item}
              </Badge>
              ))}
          </div>
        }
        >
          <Badge variant="secondary" className="cursor-pointer min-h-6 h-auto">
            +
            {remainingProducts}
          </Badge>
        </Tooltip>
      )}
    </div>
  );
};

export default BadgeGroup;
