import * as React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { isEmpty, values, includes } from 'lodash';

import { useProjectsApp } from '@frontend/app/containers/Projects/hooks';
import {
  useClientFeatureEnabled, useGetCurrentClient, useGetReporting,
} from '@frontend/app/hooks';
import { TChartFilterTypes } from '@frontend/app/components/Charts/components/ROI/LineChart';
import { DateRangesLabel, DATE_RANGES, getDateRange } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';
import { HistoricalDataGroupBy } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';

import { LoadSpinner } from '@components';
import { IInvestmentValues, ReportingLayout } from '../../pages/Reporting/Reporting';
import { getCompareDateRange } from '../../pages/Reporting/components/Header/Header';
import { PageError } from '../../pages/Reporting/components/PageError/PageError';

import {
  useInstagramStatus,
  useReportingHeader,
  useChartLabel,
  useImpactChart,
  useHowItsCalculated,
  useMetricsSecondary,
  useMetricsTabs,
} from '../../hooks';

const { useState, useCallback } = React;
function useSearchParams(): URLSearchParams {
  const { search } = useLocation<{ filter: string }>();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const formatDateToQuery = (date: Date | undefined, startOfDay: boolean) => {
  const time = startOfDay ? '00:00:00' : '23:59:59';
  return date
    // Format date to UTC, ignores user timezone
    ? `${formatISO(date, { representation: 'date' })}T${time}Z`
    : undefined;
};

const getGroupBy = (value: string): HistoricalDataGroupBy => {
  switch (value) {
    case DateRangesLabel.YEAR_TO_DATE:
    case DateRangesLabel.LAST_6_MONTHS:
    case DateRangesLabel.QUARTER_TO_DATE:
    case DateRangesLabel.LAST_QUARTER:
    case DateRangesLabel.ALL_TIME:
      return HistoricalDataGroupBy.MONTH;
    case DateRangesLabel.LAST_WEEK:
    case DateRangesLabel.THIS_WEEK:
      return HistoricalDataGroupBy.DAY;
    case DateRangesLabel.MONTH_TO_DATE:
    case DateRangesLabel.LAST_MONTH:
      return HistoricalDataGroupBy.WEEK;
    default:
      return HistoricalDataGroupBy.MONTH;
  }
};

export type HandleSettingsChange = (partialUpdate: Partial<IReportingSettings>) => void;
export interface IReportingSettings {
  dateRanges: [Date?, Date?],
  compareRange: [Date?, Date?],
  projectIdsList: { label: string, value: number }[],
  dateRangeLabel: string;
}

const reportingInitialSettings: IReportingSettings = {
  dateRanges: getDateRange(DATE_RANGES.LAST_6_MONTHS),
  compareRange: getCompareDateRange(DATE_RANGES.LAST_6_MONTHS),
  projectIdsList: [],
  dateRangeLabel: DateRangesLabel.LAST_6_MONTHS,
};

export const getPercentDiffVariables = (dateRange: [Date, Date], compareRange: [Date, Date], disableTrends: boolean) => {
  const [startDate, endDate] = dateRange;
  const [compareStartDate, compareEndDate] = compareRange;
  if (disableTrends) {
    // in All time and customDate filter with no compare date we want to hide the trends.
    return {
      compareStartDate: null,
      compareEndDate: null,
      percentDiffStartDate: null,
      percentDiffEndDate: null,
    };
  }
  return {
    compareStartDate: formatDateToQuery(compareStartDate, true),
    compareEndDate: formatDateToQuery(compareEndDate, false),
    percentDiffStartDate: formatDateToQuery(startDate, true),
    percentDiffEndDate: formatDateToQuery(endDate, false),
  };
};

const getCustomDateGroupBy = (settings: IReportingSettings): HistoricalDataGroupBy => {
  const { dateRanges } = settings;
  const [startDate, endDate] = dateRanges;
  const dateDiff = moment(endDate).diff(moment(startDate), 'days');
  if (dateDiff <= 14) {
    return HistoricalDataGroupBy.DAY;
  } else if (dateDiff <= 60) {
    return HistoricalDataGroupBy.WEEK;
  } else {
    return HistoricalDataGroupBy.MONTH;
  }
};

export const ReportingContainer = () => {
  const [settings, setSettings] = useState<IReportingSettings>(reportingInitialSettings);
  const handleSettingsChange: HandleSettingsChange = useCallback((settingsUpdate) => {
    setSettings((settingsState) => ({ ...settingsState, ...settingsUpdate }));
  }, [setSettings]);
  const clientLookup = useGetCurrentClient();
  const { projects, isLoading: loadingProjects } = useProjectsApp();
  const mappedProjects = projects.map(({ title, id }) => ({ label: title, value: id }));
  const getDateRange = useCallback((range: [Date?, Date?]): [Date, Date] => {
    const { client } = clientLookup;
    const clientStartDate = new Date(client?.createdDate) || new Date();
    const [startDate, endDate] = range;
    return [startDate || clientStartDate, endDate || new Date()];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, clientLookup]);
  const [startDate, endDate] = getDateRange(settings.dateRanges);
  const AlertBanner = useInstagramStatus(clientLookup?.client?.id);
  const isCustomDateFilter = !includes(values(DateRangesLabel), settings.dateRangeLabel);
  const disableTrendsDateFilter = (isCustomDateFilter && settings.compareRange[0] == undefined);
  const [compareStartDate, compareEndDate] = getDateRange(settings.compareRange);

  const programIds = settings.projectIdsList.map((project) => project.value);
  const queryVariables = {
    startDate: formatDateToQuery(startDate, true),
    endDate: formatDateToQuery(endDate, false),
    programIds: isEmpty(programIds) ? null : programIds,
    ...getPercentDiffVariables(
      [startDate, endDate],
      [compareStartDate, compareEndDate],
      settings.dateRangeLabel === DateRangesLabel.ALL_TIME || disableTrendsDateFilter,
    ),
  };
  const { client } = clientLookup;
  const groupBy = !isCustomDateFilter ? getGroupBy(settings.dateRangeLabel) : getCustomDateGroupBy(settings);
  const { data, loading, error } = useGetReporting({
    variables: { ...queryVariables, groupBy },
    skip: !client?.createdDate,
  });
  const igStoryEstimationEnabled = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const query = useSearchParams();
  const isLoading = (
    loading
    || clientLookup.loading
    || igStoryEstimationEnabled === undefined
    || refreshUi === undefined
  );
  const reporting = data?.reporting;
  const dateRange = getDateRange(settings.dateRanges);
  const trends = {
    content: reporting?.content.totalCount,
    cpe: reporting?.social.totals.cpe,
    cpm: reporting?.social.totals.cpm,
    tmv: reporting?.social.totals.tmv,
    engagements: reporting?.social.totals.engagements,
    impressions: reporting?.social.totals.impressions,
    posts: reporting?.social.totals.postCount,
    conversions: reporting?.salesTracking.totals.conversions,
    creators: reporting?.social.totals.creatorCount,
    reach: reporting?.social.totals.reach,
    views: reporting?.social.totals.views,
  };
  const impact = {
    roi: reporting?.impact.roi,
    content: reporting?.impact.content,
    impressions: reporting?.impact.impressions,
    engagements: reporting?.impact.engagements,
    sales: reporting?.impact.sales,
    investment: reporting?.impact.investment,
    total: reporting?.impact.total,
  };
  const contentValues = {
    images: reporting?.content.image,
    longVideos: reporting?.content.longFormVideo,
    shortVideos: reporting?.content.shortFormVideo,
  };
  const impressionsValues = {
    instagramPosts: reporting?.social.instagramPost,
    instagramReels: reporting?.social.instagramReel,
    instagramStories: reporting?.social.instagramStory,
    instagramVideos: reporting?.social.instagramVideo,
    youtube: reporting?.social.youtube,
    youtubeShorts: reporting?.social.youtubeShort,
    tiktok: reporting?.social.tiktok,
    pinterest: reporting?.social.pinterest,
    twitter: reporting?.social.twitter,
  };
  const engagementValues = {
    instagramPosts: reporting?.social.instagramPost,
    instagramReels: reporting?.social.instagramReel,
    instagramStories: reporting?.social.instagramStory,
    instagramVideos: reporting?.social.instagramVideo,
    tiktok: reporting?.social.tiktok,
    youtube: reporting?.social.youtube,
    youtubeShorts: reporting?.social.youtubeShort,
    pinterest: reporting?.social.pinterest,
    twitter: reporting?.social.twitter,
  };
  const salesValues = {
    links: reporting?.salesTracking.linkTotals,
    promos: reporting?.salesTracking.promoTotals,
  };
  const investmentValues: IInvestmentValues = {
    payments: reporting?.investment.payments.value,
    productCost: reporting?.investment.productCost.value,
    total: reporting?.impact.investment.value,
  };
  const filter = query.get('filter');
  const ImpactChart = useImpactChart({
    engagements: reporting?.impact.engagements.historicalData,
    content: reporting?.impact.content.historicalData,
    impressions: reporting?.impact.impressions.historicalData,
    sales: reporting?.impact.sales.historicalData,
    investment: reporting?.impact.investment.historicalData,
    dateRange,
    type: getGroupBy(settings.dateRangeLabel),
    filter: query.get('filter') as TChartFilterTypes,
    loading: isLoading,
  });
  const ChartLabel = useChartLabel({
    type: query.get('filter'),
    dateRange,
    timeLabel: settings.dateRangeLabel,
    loading: isLoading,
    isDateFilter: isCustomDateFilter,
  });
  const HowItsCalculated = useHowItsCalculated({
    contentValues,
    engagementValues,
    filter,
    igStoryEstimationEnabled,
    impact,
    impressionsValues,
    investmentValues,
    loading: isLoading,
    salesValues,
  });

  const MetricsSecondary = useMetricsSecondary({
    trends,
    igStoryEstimationEnabled,
    cardSubtitle: settings.dateRangeLabel,
    loading: isLoading,
    showTrend: isCustomDateFilter ? !disableTrendsDateFilter : settings.dateRangeLabel !== DateRangesLabel.ALL_TIME,
  });
  const MetricsTabs = useMetricsTabs({
    impact,
    filter,
    igStoryEstimationEnabled,
    loading: isLoading,
    cardSubtitle: settings.dateRangeLabel,
    showTrend: isCustomDateFilter ? !disableTrendsDateFilter : settings.dateRangeLabel !== DateRangesLabel.ALL_TIME,
  });
  const ReportingHeader = useReportingHeader({
    clientStartDate: new Date(clientLookup.client?.createdDate) || new Date(),
    projects: mappedProjects,
    dateRangeLabel: settings.dateRangeLabel,
    loading: loadingProjects,
    onSettingChange: handleSettingsChange,
    selectedProjects: settings.projectIdsList,
    dateRange: settings.dateRanges,
    isDateFilter: isCustomDateFilter,
    refreshUi,
  });
  if (error) {
    return <PageError Header={ReportingHeader} startDate={startDate} endDate={endDate} />;
  }
  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <ReportingLayout
      Alert={AlertBanner}
      Header={ReportingHeader}
      ImpactChart={ImpactChart}
      ChartLabel={ChartLabel}
      HowItsCalculated={HowItsCalculated}
      MetricsSecondary={MetricsSecondary}
      MetricsTabs={MetricsTabs}
    />
  );
};
