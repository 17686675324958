import * as React from 'react';
import {
  Form,
} from '@revfluence/fresh';

import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { FormAsyncAction, IShopifyFormElementProps, IShopifyPromoCodeFormValues, OfferFormAsyncActions } from '../../../../types';

interface IProps extends IShopifyFormElementProps<'specialLimitUsageCapEnabled'> {
  value: IShopifyPromoCodeFormValues['specialLimitUsageCapEnabled'];
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  secureCodeValue?: boolean;
  specialLimitUsageCapAmount?: string;

}

export const RefreshOfferCodeLimitCodeUses: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  handleFormAsyncActions,
  secureCodeValue,
  specialLimitUsageCapAmount,
}) => {
  const [isChecked, setIsChecked] = React.useState(secureCodeValue || value);
  const [inputValue, setInputValue] = React.useState<string | null>(specialLimitUsageCapAmount);

  React.useEffect(() => {
    if (secureCodeValue) {
      setInputValue('1');
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'specialLimitUsageCapAmount', value: '1' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secureCodeValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numValue = e.target.value ? e.target.value : null;
    setInputValue(numValue);
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'specialLimitUsageCapAmount', value: numValue },
    });
  };

  return (
    <>
      <Form.Item className="!m-0" name={name} valuePropName="checked">
        <div className="flex items-center gap-2">
          <Checkbox
            id="limitNewCustomer"
            checked={isChecked}
            onCheckedChange={(checked) => setIsChecked(checked === true)}
            disabled={disabled}
          />
          <label
            htmlFor="limitNewCustomer"
            className="text-sm font-medium text-primary pointer-events-none"
          >
            Limit number of times code can be used in total
          </label>
        </div>
      </Form.Item>
      {isChecked && (
        <div className="px-[26px]">
          <div className="flex items-center relative">
            <span className="absolute text-grey-4 left-3 top-[6px]">#</span>
            <Input
              disabled={disabled}
              min={1}
              onKeyDown={UseNaturalNumbers}
              placeholder="1"
              type="number"
              className="pl-8 max-w-[180px] px-[26px]"
              value={inputValue ?? ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </>
  );
});
RefreshOfferCodeLimitCodeUses.displayName = 'RefreshOfferCodeLimitCodeUses';
