import * as React from 'react';
import cx from 'classnames';
import omitDeep from 'omit-deep-lodash';
import {
 first, filter, isEmpty, map,
} from 'lodash';
import { logger } from '@common';
import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import {
  Space,
  Typography,
  Collapse,
  Divider,
  Input,
  message,
  Alert,
} from '@revfluence/fresh';
import {
  CircleCheckIcon,
  TriangleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  useState as termsTemplateUseState,
} from '@frontend/app/containers/Projects/TermsPage/components/hooks/useState/useState';
import Compensation from '@frontend/app/containers/Projects/TermsPage/components/Compensation';
import DeliverableList from '@frontend/app/containers/Projects/TermsPage/components/DeliverableList';
import ContentTypeList from '@frontend/app/containers/Projects/TermsPage/components/ContentTypeList';
import PanelHeader, { PanelKey } from '@frontend/app/containers/Projects/TermsPage/components/PanelHeader';
import {
  PaymentTypes,
  TContentUsageRights,
} from '@frontend/applications/TermsApp/components/BulkTerms/types/CollaborationDetails/index';
import
  SelectContentUsageRights
from '@frontend/applications/TermsApp/components/shared/SelectContentUsageRights/SelectContentUsageRights';
import
  TextAreaContentUsageRights
  from '@frontend/applications/TermsApp/components/shared/TextAreaContentUsageRights/TextAreaContentUsageRights';
import ContentGuidelines
  from '@frontend/app/containers/Projects/TermsPage/components/ContentGuidelines';
import {
  ContentUsageRightsType,
  TermsType,
  ContentType,
  ContentGuidelineScope,
  ResourceType,
} from '@frontend/app/types/globalTypes';
import { IEmailEditorState, EmailEditor } from '@frontend/app/components/MessageComposer/EmailEditor';
import { ActionTypes } from '@frontend/app/containers/Projects/TermsPage/components/types/actionTypes';
import { LoadingBulkTerms } from '@frontend/applications/TermsApp/components/BulkTerms/LoadingBulkTerms';
import {
  useUpdateAdvanceTermsTemplateMutation,
  useGetContentGuidelineTemplatesForProject,
  useUpdateBasicTermsTemplateMutation,
  useGetTermsSettingsForProgram,
  useClientFeatureEnabled,
  useGetWaitForAgreementTerms,
  useGetTermsName,
} from '@frontend/app/hooks';
import { useTermsConfigQuery } from '@frontend/applications/TermsApp/containers/BulkTerms/useTermsConfigQuery';
import { FormatPayment } from '@frontend/app/utils/payment';
import { TermsWizard, IProps as TermsWizardProps } from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard';
import SelectContentGuideline from '@frontend/app/containers/Projects/TermsPage/components/SelectContentGuideline';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import Intro
  from '@frontend/app/containers/Projects/TermsPage/components/Intro';
import { IApplicationContainerHandle } from '@frontend/app/containers/Application/ApplicationContainer';
import {
  EmailComposer,
  useManageAttachments,
  IAttachment,
} from '@frontend/app/components';
import { IPreviewConfig } from '@frontend/app/components/MessageComposer/EmailComposer';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';
import SwitchTermsType from '@frontend/applications/TermsApp/components/shared/SwitchTermsType/SwitchTermsType';
import { IState, TContentGuideline } from '@frontend/app/containers/Projects/TermsPage/components/types/state';
import { useResourceContext } from '@frontend/app/context';
import { ClientFeature } from '@frontend/app/constants';

const { Panel } = Collapse;
const {
  useCallback,
  useEffect,
  useMemo,
  useImperativeHandle,
  useState,
} = React;

const ADDITIONAL_TERMS_MESSAGE_HTML = `<div>The creator agrees that:</div>
<ul>
  <li>All content will reflect the creator's honest views and experiences with the brand's products and services</li>
  <li>They will at all times comply with all applicable laws, rules, regulations, and guides</li>
  <li>They will not submit or post content that violates, infringes, or misappropriates any rights of any third parties</li>
</div>
`;
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './TermsTemplate.module.scss';
import ProjectBudgetInfo from './ProjectBudgetInfo';

interface IProps {
  isEditing: boolean;
  memberIds: number[];
  projectId: number;
  termsType: TermsType;
  agreementId?: string;
  onClose: () => void;
  onSwitchTermsType: () => void;
  onSendTerms?: (state: IState) => Promise<boolean>;
}

const TermsTemplate = React.memo(React.forwardRef<IApplicationContainerHandle, IProps>((props, ref) => {
  const {
    memberIds,
    projectId,
    termsType,
    agreementId,
    onClose,
    onSwitchTermsType,
    onSendTerms,
    isEditing,
  } = props;
  const isBasicTermsEnabled = useClientFeatureEnabled(ClientFeature.BASIC_TERMS);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isSendingTerms = memberIds.length > 0 && !props.agreementId;
  const isEditingTerms = memberIds.length > 0 && props.agreementId;
  const { state, dispatch } = termsTemplateUseState();
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const [dueDateIncrement, setDueDateIncrement] = useState<number>(30);
  const [continueWithoutMessageLoading, setContinueWithoutMessageLoading] = useState<boolean>(false);

  const { [ClientFeature.FLEXIBLE_PROJECT]: isFlexEnabled } = useClientFeatures();

  const {
    attachments,
    setInitialAttachments,
    handleSelectedFilesChange,
    handleDeleteAttachment,
  } = useManageAttachments({
    onAttachmentsChange: () => {
      dispatch({
        type: ActionTypes.UpdateEmailAttachments,
        emailAttachments: attachments,
      });
    },
  });

  useImperativeHandle(ref, () => ({
    showWarningOnClose: () => state.hasUnsavedChanges,
  }), [
    state.hasUnsavedChanges,
  ]);

  const {
    activeEmailResources: resources,
  } = useResourceContext();

  const composableResources = useMemo(() => (
    filter(resources, (resource) => resource.type !== ResourceType.IGDM)
  ), [resources]);

  const { loading: loadingContentGuidelines, data: guidelinesData } = useGetContentGuidelineTemplatesForProject({
    skip: !projectId,
    variables: {
      programId: projectId,
    },
    fetchPolicy: 'no-cache',
  });

  const flexibleContentGuidelines = useMemo(() => {
    if (guidelinesData) {
      const {
        contentGuidelines,
        defaultContentGuidelines,
      } = guidelinesData.guidelines;
        const flexibleContentGuidelinesForProgram = (contentGuidelines || []).filter((guideline) => guideline.type === ContentType.FLEXIBLE);
        const defaultFlexibleContentGuidelines = defaultContentGuidelines.filter((guideline) => guideline.type === ContentType.FLEXIBLE);
        return (flexibleContentGuidelinesForProgram || []).concat(defaultFlexibleContentGuidelines);
    }
    return [];
  }, [guidelinesData]);

  const {
    loading: termsForSendingTermsLoading,
    data: {
      termsTemplate: termsTemplateForSendingTerms = null,
    } = {},
  } = useGetTermsSettingsForProgram({
    variables: {
      programId: projectId,
      termsType,
    },
    skip: !projectId || isEditingTerms,
    fetchPolicy: 'no-cache',
  });

  const {
    error: errorLoadingTerms,
    loading: termsWaitForAgreementLoading,
    data: {
      termsTemplate: termsTemplateForWaitingForAgreement = null,
    } = {},
  } = useGetWaitForAgreementTerms({
    variables: {
      agreementId,
    },
    skip: !isEditingTerms,
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (errorLoadingTerms) {
      // stringify the error to see the graphql actual error
      logger.error('Error loading existing terms');
      logger.error(JSON.stringify(errorLoadingTerms, null, 2));
      message.error({
        icon: <TriangleExclamationIcon />,
        className: 'closable',
        content: 'Something went wrong while loading Brief, please contact support if this issue continues',
      });
    }
  }, [
    errorLoadingTerms,
  ]);
  const termsTemplate = useMemo(
    () => termsTemplateForSendingTerms || termsTemplateForWaitingForAgreement,
    [
      termsTemplateForWaitingForAgreement,
      termsTemplateForSendingTerms,
    ],
  );

  useEffect(() => {
    setInitialAttachments(termsTemplate?.emailTemplate?.attachments || []);
  }, [setInitialAttachments, termsTemplate?.emailTemplate?.attachments]);

  const termsLoading = useMemo(
    () => termsForSendingTermsLoading || termsWaitForAgreementLoading,
    [
      termsWaitForAgreementLoading,
      termsForSendingTermsLoading,
    ],
  );

  const { data, loading: termsConfigLoading } = useTermsConfigQuery({
    variables: {
      memberIds,
      programId: projectId,
    },
    skip: !projectId,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      !loadingContentGuidelines
      && !termsConfigLoading
      && !termsLoading
      && guidelinesData
      && termsTemplate
      && termsTemplate.emailTemplate
    ) {
      const {
        contentGuidelines,
        defaultContentGuidelines,
        memberContentGuidelines,
        termsContentGuidelines,
      } = guidelinesData.guidelines;
      const allContentGuidelines = [...contentGuidelines, ...defaultContentGuidelines, ...memberContentGuidelines, ...termsContentGuidelines];
      const contentGuideline: TContentGuideline = allContentGuidelines.find((guideline) => guideline.id === termsTemplate.contentGuidelineId);
      dispatch({
        type: ActionTypes.SetSettingsTerms,
        emailSubject: termsTemplate.emailTemplate.subject,
        compensationCommissionAvailable: termsTemplate.compensationCommissionAvailable,
        compensationCommissionValue: termsTemplate.compensationCommissionValue,
        compensationFreeProductAvailable: termsTemplate.compensationFreeProductAvailable,
        compensationFreeProductValue: termsTemplate.compensationFreeProductValue,
        compensationPaymentAvailable: termsTemplate.compensationPaymentAvailable,
        compensationPaymentValue: termsTemplate.compensationPaymentValue,
        noCompensationAvailable: termsTemplate.noCompensationAvailable,
        contentUsageRightType: termsTemplate.contentUsageRightType,
        contentUsageRightValue: termsTemplate.contentUsageRightValue,
        contentGuideline: omitDeep(contentGuideline, '__typename'),
        contentRequirements: omitDeep({ ...termsTemplate.contentRequirements }, '__typename'),
        introMessage: termsTemplate.introMessage,
        defaultActiveKey: !isBasicTermsEnabled
          ? [PanelKey.Compensation, PanelKey.ContentUsageRights, PanelKey.EmailTemplate]
          : [],
      });
    }
  }, [
    termsLoading,
    termsTemplate,
    isBasicTermsEnabled,
    loadingContentGuidelines,
    guidelinesData,
    termsConfigLoading,
    dispatch,
  ]);

  const [updateAdvanceTermsTemplate, {
    loading: savingAdvanceTermsTemplate,
  }] = useUpdateAdvanceTermsTemplateMutation({
    onCompleted() {
      dispatch({ type: ActionTypes.UpdateHasUnsavedChanges, hasUnsavedChanges: false });
      onClose();
      message.success({
        icon: <CircleCheckIcon />,
        content: 'Settings successfully saved',
        duration: 2,
      });
    },
    onError() {
      message.error({
        icon: <TriangleExclamationIcon />,
        className: 'closable',
        content: 'Something went wrong. Please try again later.',
      });
    },
  });

  const [updateBasicTermsTemplate, {
    loading: savingBasicTermsTemplate,
  }] = useUpdateBasicTermsTemplateMutation({
    onCompleted() {
      dispatch({ type: ActionTypes.UpdateHasUnsavedChanges, hasUnsavedChanges: false });
      onClose();
      message.success({
        icon: <CircleCheckIcon />,
        content: 'Settings successfully saved',
        duration: 2,
      });
    },
    onError() {
      message.error({
        icon: <TriangleExclamationIcon />,
        className: 'closable',
        content: 'Something went wrong. Please try again later.',
      });
    },
  });

  const handleShowHideNewPrice = useCallback((showHideNewPrice: boolean) => {
    dispatch({ type: ActionTypes.UpdateShowHideNewPrice, showHideNewPrice });
    dispatch({ type: ActionTypes.ToggleNoCompensation, toggleNoCompensation: false });
  }, [dispatch]);

  const handleUpdateNewPrice = useCallback((updatedNewPrice: number) => {
    const fixedPrice = FormatPayment(updatedNewPrice);
    dispatch({ type: ActionTypes.UpdateNewPrice, newPrice: fixedPrice });
  }, [dispatch]);

  const handleShowHideDescription = useCallback((paymentType: PaymentTypes, showHideDescription: boolean) => {
    dispatch({ type: ActionTypes.UpdateShowHidePaymentOption, paymentType, showHideDescription });
    dispatch({ type: ActionTypes.ToggleNoCompensation, toggleNoCompensation: false });
  }, [dispatch]);

  const handleUpdateDescription = useCallback((paymentType: PaymentTypes, description: string) => {
    dispatch({ type: ActionTypes.UpdatePaymentOptionDescription, paymentType, description });
  }, [dispatch]);

  const handleUpdateContentRight = useCallback(
    (contentRightUpdated: TContentUsageRights) => {
      dispatch({ type: ActionTypes.UpdateContentRight, contentRight: contentRightUpdated });
    }, [dispatch],
  );

  const handleSubjectChange = useCallback(
    (emailSubject: string) => {
      dispatch({ type: ActionTypes.UpdateEmailSubject, emailSubject });
    }, [dispatch],
  );

  const handleEditorStateChange = useCallback(
    (emailContent: IEmailEditorState) => {
      dispatch({ type: ActionTypes.UpdateEmailContent, emailContent });
    }, [
      dispatch,
    ],
  );

  const handlePanelChange = useCallback((defaultActiveKey: Array<string>) => {
    dispatch({ type: ActionTypes.UpdateDefaultActiveKey, defaultActiveKey });
  }, [dispatch]);

  const handleToggleDueDatesChange = useCallback((areNoDueDatesChecked: boolean) => {
    dispatch({ type: ActionTypes.ToggleDueDates, areNoDueDatesChecked });
  }, [dispatch]);

  const handleAddDeliverableDueDateChange = useCallback(() => {
    dispatch({ type: ActionTypes.AddDeliverableDueDate, days: dueDateIncrement });
    setDueDateIncrement(dueDateIncrement + 15);
  }, [dispatch, dueDateIncrement]);

  const handleRemoveDeliverableDueDateChange = useCallback((index: number) => {
    dispatch({ type: ActionTypes.RemoveDeliverableDueDate, index });
  }, [dispatch]);

  const handleUpdateDeliverableDueDateChange = useCallback((index: number, value: number) => {
    dispatch({ type: ActionTypes.UpdateDeliverableDueDate, index, value });
  }, [dispatch]);

  const handleAddContentTypeChange = useCallback((contentType: ContentType) => {
    dispatch({ type: ActionTypes.AddContentType, contentType });
  }, [dispatch]);

  const handleRemoveContentTypeChange = useCallback((contentType: ContentType) => {
    dispatch({ type: ActionTypes.RemoveContentType, contentType });
  }, [dispatch]);

  const handleSelectContentGuidelineChange = useCallback((contentGuideline: TContentGuideline) => {
    dispatch({ type: ActionTypes.SelectContentGuideline, contentGuideline });
  }, [dispatch]);

  const handleUpdateContentGuidelineLabelChange = useCallback((label: string) => {
    dispatch({ type: ActionTypes.UpdateContentGuidelineLabel, label });
  }, [dispatch]);

  const handleUpdateContentGuidelineInstructionChange = useCallback((
    contentGuidelineInstruction: IContentGuidelineInstruction,
  ) => {
    dispatch({
      type: ActionTypes.UpdateContentGuidelineInstruction,
      contentGuidelineInstruction,
    });
  }, [dispatch]);

  const handleUpdateContentGuidelineTitleChange = useCallback((
    contentGuidelineTitle: IContentGuidelineTitle,
  ) => {
    dispatch({
      type: ActionTypes.UpdateContentGuidelineTitle,
      contentGuidelineTitle,
    });
  }, [dispatch]);

  const handleSortContentGuidelineChange = useCallback((
    sortableGuideline: ISortableGuideline,
  ) => {
    dispatch({
      type: ActionTypes.SortContentGuideline,
      sortableGuideline,
    });
  }, [dispatch]);

  const handleAddContentGuidelineAttachmentChange = useCallback((
    attachment: Attachment,
  ) => {
    dispatch({
      type: ActionTypes.AddContentGuidelineAttachment,
      attachment,
    });
  }, [dispatch]);

  const handleDeleteContentGuidelineAttachmentChange = useCallback((
    attachments: Array<Attachment>,
  ) => {
    dispatch({
      type: ActionTypes.DeleteContentGuidelineAttachment,
      attachments,
    });
  }, [dispatch]);

  const handleAttachmentsChange = useCallback(
    (emailAttachments: IAttachment[]) => {
      dispatch({ type: ActionTypes.UpdateEmailAttachments, emailAttachments });
    }, [dispatch],
  );

  const handleUpdateIntroChange = useCallback((introMessage: string) => {
    dispatch({ type: ActionTypes.UpdateIntro, introMessage });
  }, [dispatch]);

  const handleAddDeliverableWithNoDueDateChange = useCallback(() => {
    dispatch({ type: ActionTypes.AddDeliverableWithNoDueDate });
  }, [dispatch]);

  const handleRemoveDeliverableWithNoDueDateChange = useCallback(() => {
    dispatch({ type: ActionTypes.RemoveDeliverableWithNoDueDate });
  }, [dispatch]);

  const handlePreviewConfigChange = useCallback(
    (config: IPreviewConfig) => {
      setPreviewConfig(config);
    }, [setPreviewConfig],
  );

  const handleResourceIdChange = useCallback(
    (resourceId: number) => {
      dispatch({ type: ActionTypes.UpdateEmailAuthor, resourceId });
    }, [dispatch],
  );

  const handleAdditionalCcChange = useCallback(
    (additionalCc: Partial<IMember>[]) => {
      dispatch({ type: ActionTypes.UpdateAdditionalCc, additionalCc });
    }, [dispatch],
  );

  const handleToggleNoCompensation = useCallback((toggleNoCompensation: boolean) => {
    dispatch({
      type: ActionTypes.ToggleNoCompensation,
      toggleNoCompensation,
    });
    dispatch({
      type: ActionTypes.UpdateShowHidePaymentOption,
      paymentType: PaymentTypes.FreeProduct,
      showHideDescription: false,
    });
    dispatch({
      type: ActionTypes.UpdateShowHidePaymentOption,
      paymentType: PaymentTypes.Commission,
      showHideDescription: false,
    });
    dispatch({
      type: ActionTypes.UpdateShowHideNewPrice,
      showHideNewPrice: false,
    });
  }, [dispatch]);

  const handleSendTerms = useCallback(
    async (error) => {
      dispatch({ type: ActionTypes.ToggleSaving });
      dispatch({ type: ActionTypes.UpdateHasUnsavedChanges, hasUnsavedChanges: false });
      let status = false;
      if (state.resourceId) {
        status = await onSendTerms(state);
      } else {
        status = await onSendTerms(
          {
            ...state,
            resourceId: first(composableResources).id,
          },
        );
      }
      if (status === false) {
        message.error(`Error sending brief: ${error?.message}`);
      } else {
        message.success(`Sent ${memberIds.length} brief${memberIds.length === 1 ? '' : 's'}.`);
      }
      onClose();
    },
    [
      state,
      dispatch,
      memberIds,
      onSendTerms,
      onClose,
      composableResources,
    ],
  );

  // New function to handle sending terms without email details
  const handleSendTermsWithoutMessage = useCallback(
    async (error) => {
      // Use separate loading state for "Continue without message" button
      setContinueWithoutMessageLoading(true);
      dispatch({ type: ActionTypes.UpdateHasUnsavedChanges, hasUnsavedChanges: false });

      // Create a modified state without email details
      const stateWithoutEmail = {
        ...state,
        skipEmailDetails: true, // Add a flag to indicate skipping email details
      };

      let status = false;
      if (state.resourceId) {
        status = await onSendTerms(stateWithoutEmail);
      } else {
        status = await onSendTerms({
          ...stateWithoutEmail,
          resourceId: first(composableResources)?.id,
        });
      }

      // Reset loading state
      setContinueWithoutMessageLoading(false);

      if (status === false) {
        message.error(`Error sending brief: ${error?.message}`);
      } else {
        message.success(`Sent ${memberIds.length} brief${memberIds.length === 1 ? '' : 's'} without message.`);
      }
      onClose();
    },
    [
      state,
      dispatch,
      memberIds,
      onSendTerms,
      onClose,
      composableResources,
    ],
  );

  const handleSave = useCallback(
    () => {
      const termsTemplateData = {
        programId: projectId,
        compensationCommissionAvailable: state.compensation.commission.toggleDescription,
        compensationCommissionValue: state.compensation.commission.description,
        compensationFreeProductAvailable: state.compensation.freeProduct.toggleDescription,
        compensationFreeProductValue: state.compensation.freeProduct.description,
        compensationPaymentAvailable: state.compensation.payment.toggleNewPrice,
        compensationPaymentValue: state.compensation.payment.newPrice
          ? state.compensation.payment.newPrice
          : 0,
        noCompensationAvailable: state.compensation.noCompensation.toggleNoCompensation,
        contentUsageRightType: ContentUsageRightsType[state.contentRight.type],
        contentUsageRightValue: state.contentRight.text,
        emailTemplateId: termsTemplate?.emailTemplate?.id,
        default: termsTemplate?.default,
        messageTemplateInput: {
          subject: state.emailSubject,
          text: state.emailContent ? JSON.stringify(state.emailContent.raw) : termsTemplate?.emailTemplate?.text,
          attachments: map(attachments, (attachment) => ({
            id: attachment.id,
            name: attachment.name,
            url: attachment.fileUrl,
            size: attachment.size,
            type: attachment.type,
            uuid: attachment.uuid,
            fileUrl: attachment.fileUrl,
            localSrc: attachment.localSrc,
          })),
        },
        termsType,
      };
      if (termsType == TermsType.ADVANCED) {
        updateAdvanceTermsTemplate({
          variables: {
            id: termsTemplate?.id,
            data: {
              ...termsTemplateData,
            },
          },
        });
      } else {
        updateBasicTermsTemplate({
          variables: {
            id: termsTemplate?.id,
            data: {
              ...termsTemplateData,
              contentGuideline: omitDeep({
                ...state.contentGuideline,
                scope: ContentGuidelineScope.PROGRAM,
              }, '__typename'),
              contentRequirements: state.contentRequirements,
              introMessage: state.introMessage,
            },
          },
        });
      }
    }, [
      state,
      termsType,
      termsTemplate?.default,
      termsTemplate?.id,
      termsTemplate?.emailTemplate,
      projectId,
      attachments,
      updateAdvanceTermsTemplate,
      updateBasicTermsTemplate,
    ],
  );

  const { singularTermsName, pluralTermsName } = useGetTermsName();
  const footerSettings = useMemo((): TermsWizardProps['footerSettings'] => {
    const hasComposableResources = !isEmpty(composableResources);
    if (
      loadingContentGuidelines
      || termsLoading
      || termsConfigLoading
      || (termsTemplate && termsTemplate.__typename === 'FlexibleBrief' && termsTemplate.canceled)
    ) {
      return {
        hideFooter: true,
      };
    }
    let tooltipMessage = '';
    if (!hasComposableResources) {
      tooltipMessage += 'Please connect an email account to edit this brief. ';
    }
    if (termsType === TermsType.BASIC) {
      if (
        (state.contentRequirements.areDueDatesSelected && state.contentRequirements.deliverablesDueDates.length === 0)
        || (!state.contentRequirements.areDueDatesSelected && state.contentRequirements.deliverablesWithNoDueDates === 0)
      ) {
        tooltipMessage += 'Please select at least one deliverable. ';
      }
      if (state.contentRequirements.contentTypes.length === 0) {
        tooltipMessage += 'Please select at least one content type. ';
      }
    }
    let nextButtonLabel = 'Save';
    if (memberIds.length > 0) {
      const membersCount = memberIds.length;
      const sendTermsString = isEditing ? `Send Edited ${singularTermsName} to` : `Send ${singularTermsName} to`;
      nextButtonLabel = membersCount === 1 ? `${sendTermsString} ${membersCount} Member` : `${sendTermsString} ${membersCount} Members`;
    }
    let hasEmptyCompensation = false;
    if ((
      state.compensation.payment.toggleNewPrice
      && (!state.compensation.payment.newPrice || state.compensation.payment.newPrice <= 0)
    )
    || (
      state.compensation.commission.toggleDescription
      && state.compensation.commission.description === ''
    )
    || (
      state.compensation.freeProduct.toggleDescription
      && state.compensation.freeProduct.description === ''
    )) {
      hasEmptyCompensation = true;
      tooltipMessage += 'Please fill out compensation.';
    }

    if (isSendingTerms
      && !state.compensation.payment.toggleNewPrice
      && !state.compensation.commission.toggleDescription
      && !state.compensation.freeProduct.toggleDescription
      && !state.compensation.noCompensation.toggleNoCompensation) {
        hasEmptyCompensation = true;
        tooltipMessage += 'Please fill out compensation.';
      }

    const hasEmptyContentRequirements = (state.contentRequirements.areDueDatesSelected && state.contentRequirements.deliverablesDueDates.length === 0)
      || (!state.contentRequirements.areDueDatesSelected && state.contentRequirements.deliverablesWithNoDueDates === 0)
      || state.contentRequirements.contentTypes.length === 0;

    const hasMissingDueDates = state.contentRequirements.areDueDatesSelected
      && state.contentRequirements.deliverablesDueDates.some((deliverable) => !deliverable);

    if (hasMissingDueDates) {
      tooltipMessage += 'Please add missing due dates.';
    }

    // Base button disabled state based on form validation
    const isButtonDisabled = hasEmptyCompensation || hasEmptyContentRequirements || hasMissingDueDates || !hasComposableResources;

    // Check if any save/send operation is in progress
    const isSaving = savingAdvanceTermsTemplate || savingBasicTermsTemplate || state.saving;

    return {
      hideFooter: false,
      prevButtonDisabled: false,
      // Disable "Send Brief" button when it has validation errors OR when "Continue without message" is loading
      nextButtonDisabled: memberIds.length !== 0
      ? isButtonDisabled || continueWithoutMessageLoading
      : (
        !state.hasUnsavedChanges
        || (termsType === TermsType.BASIC && hasEmptyContentRequirements)
        || hasEmptyCompensation
        || hasMissingDueDates
        || !hasComposableResources
        || continueWithoutMessageLoading
      ),
      prevButtonLabel: 'Cancel',
      nextButtonLabel,
      nextButtonLoading: isSaving && !continueWithoutMessageLoading,
      prevButtonOnClick: onClose,
      nextButtonOnClick: memberIds.length === 0 ? handleSave : handleSendTerms,
      progress: {
        current: 1,
        total: 1,
      },
      showNextButtonIcon: false,
      showPrevButtonIcon: false,
      showTooltipMessage: (
        hasEmptyCompensation
        || hasMissingDueDates
        || (termsType === TermsType.BASIC && hasEmptyContentRequirements)
        || !hasComposableResources
      ),
      showProgress: false,
      tooltipMessage,
      showPrevButton: !!isBasicTermsEnabled,
      // Add new properties for "Continue without message" button
      showContinueWithoutMessageButton: memberIds.length > 0 && isFlexEnabled,
      continueWithoutMessageButtonLabel: 'Continue without message',
      continueWithoutMessageButtonOnClick: handleSendTermsWithoutMessage,
      // Disable "Continue without message" button when it has validation errors OR when "Send Brief" is loading
      continueWithoutMessageButtonDisabled: isButtonDisabled || (isSaving && !continueWithoutMessageLoading),
      continueWithoutMessageButtonLoading: continueWithoutMessageLoading,
    };
  }, [
    memberIds?.length,
    termsType,
    termsLoading,
    termsConfigLoading,
    savingAdvanceTermsTemplate,
    savingBasicTermsTemplate,
    state.hasUnsavedChanges,
    state.contentRequirements,
    state.saving,
    loadingContentGuidelines,
    isBasicTermsEnabled,
    isSendingTerms,
    state.compensation,
    isEditing,
    onClose,
    handleSave,
    handleSendTerms,
    handleSendTermsWithoutMessage,
    singularTermsName,
    termsTemplate,
    composableResources,
    isFlexEnabled,
    continueWithoutMessageLoading,
  ]);

  if (loadingContentGuidelines || termsLoading || termsConfigLoading || !termsTemplate) {
    return (
      <LoadingBulkTerms footerSettings={footerSettings} />
    );
  }
  return (
    <TermsWizard footerSettings={footerSettings}>
      { isSendingTerms && isBasicTermsEnabled && (
        <SwitchTermsType
          text={`Switch to Standard ${pluralTermsName}`}
          onSwitchTermsType={onSwitchTermsType}
        />
      )}
      {termsTemplate && termsTemplate.__typename === 'FlexibleBrief' && termsTemplate.canceled && (
        <Alert
          message={`Brief canceled by ${termsTemplate.canceled_by} on ${termsTemplate.date_canceled}`}
          type="error"
        />
      )}
      <Collapse defaultActiveKey={state.defaultActiveKey} ghost onChange={handlePanelChange}>
        { termsType === TermsType.BASIC && isBasicTermsEnabled && (
          <>
            <Panel
              header={(
                <PanelHeader
                  panelKey={PanelKey.Intro}
                  state={state}
                />
              )}
              key={PanelKey.Intro}
            >
              <Intro
                intro={state.introMessage}
                onUpdateIntro={handleUpdateIntroChange}
              />
            </Panel>
            <Divider />
            <Panel
              header={(
                <PanelHeader
                  panelKey={PanelKey.ContentRequirements}
                  state={state}
                />
              )}
              key={PanelKey.ContentRequirements}
            >
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={[24, 24]}
              >
                <ContentTypeList
                  contentTypes={state.contentRequirements.contentTypes}
                  onAddContentType={handleAddContentTypeChange}
                  onRemoveContentType={handleRemoveContentTypeChange}
                />
                <DeliverableList
                  deliverablesDueDates={state.contentRequirements.deliverablesDueDates}
                  deliverablesWithNoDueDates={state.contentRequirements.deliverablesWithNoDueDates}
                  areDueDatesSelected={state.contentRequirements.areDueDatesSelected}
                  onToggleDueDates={handleToggleDueDatesChange}
                  onAddDeliverableDueDate={handleAddDeliverableDueDateChange}
                  onRemoveDeliverableDueDate={handleRemoveDeliverableDueDateChange}
                  onUpdateDeliverableDueDate={handleUpdateDeliverableDueDateChange}
                  onAddDeliverableWithNoDueDate={handleAddDeliverableWithNoDueDateChange}
                  onRemoveDeliverableWithNoDueDate={handleRemoveDeliverableWithNoDueDateChange}
                />
              </Space>
            </Panel>
            <Divider />
            <Panel
              header={(
                <Space
                  direction="horizontal"
                  style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                >
                  <PanelHeader
                    panelKey={PanelKey.ContentGuidelines}
                    state={state}
                  />

                  <SelectContentGuideline
                    contentGuideline={state.contentGuideline}
                    contentGuidelines={
                      [
                        ContentGuidelineScope.TERMS,
                        ContentGuidelineScope.MEMBER,
                      ].includes(state.contentGuideline?.scope)
                      ? [...flexibleContentGuidelines, state.contentGuideline]
                      : flexibleContentGuidelines
                    }
                    onUpdateContentGuideline={handleSelectContentGuidelineChange}
                  />
                </Space>
              )}
              key={PanelKey.ContentGuidelines}
            >
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={[24, 24]}
              >
                <ContentGuidelines
                  contentGuideline={state.contentGuideline}
                  onUpdateContentGuidelineLabel={handleUpdateContentGuidelineLabelChange}
                  onUpdateContentGuidelineInstruction={handleUpdateContentGuidelineInstructionChange}
                  onUpdateContentGuidelineTitle={handleUpdateContentGuidelineTitleChange}
                  onSortContentGuideline={handleSortContentGuidelineChange}
                  onAddContentGuidelineAttachment={handleAddContentGuidelineAttachmentChange}
                  onDeleteContentGuidelineAttachment={handleDeleteContentGuidelineAttachmentChange}
                />
              </Space>
            </Panel>
            <Divider />
          </>
        )}
        <Panel
          header={(
            <PanelHeader
              panelKey={PanelKey.Compensation}
              state={state}
              termsType={termsType}
            />
          )}
          key={PanelKey.Compensation}
        >
          <div className={styles.compensationContainer}>
            <Compensation
              compensation={state.compensation}
              onUpdateDescription={handleUpdateDescription}
              onUpdateNewPrice={handleUpdateNewPrice}
              onToggleNewPrice={handleShowHideNewPrice}
              onToggleDescription={handleShowHideDescription}
              onToggleNoCompensation={handleToggleNoCompensation}
            />
            {
              isBudgetAllocationEnabled && !!memberIds?.length && (
                <div className={styles.projectBudgetInfoContainer}>
                  <ProjectBudgetInfo
                    projectId={projectId}
                    paymentAmount={
                      (state?.compensation?.payment?.toggleNewPrice && state?.compensation?.payment?.newPrice * memberIds.length) ?? 0
                    }
                  />
                </div>
              )
            }
          </div>
        </Panel>
        <Divider />
        <Panel
          header={(
            <Space
              direction="horizontal"
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            >
              <PanelHeader
                panelKey={PanelKey.ContentUsageRights}
                state={state}
              />
              <SelectContentUsageRights
                contentRight={state.contentRight}
                contentRightsTemplates={data?.termsConfig?.settings?.contentUsageRights}
                onUpdateContentRight={handleUpdateContentRight}
              />
            </Space>
          )}
          key={PanelKey.ContentUsageRights}
        >
          <TextAreaContentUsageRights
            contentRight={state.contentRight}
            onUpdateContentRight={handleUpdateContentRight}
          />
        </Panel>
        <Divider />
        { termsType === TermsType.BASIC && isBasicTermsEnabled && (
          <>
            <Panel
              header={(
                <PanelHeader
                  panelKey={PanelKey.AdditionalTerms}
                  state={state}
                />
              )}
              key={PanelKey.AdditionalTerms}
            >
              <div
                dangerouslySetInnerHTML={{ __html: ADDITIONAL_TERMS_MESSAGE_HTML }}
              />
            </Panel>
            <Divider />
          </>
        )}
        <Panel
          header={(
            <PanelHeader
              panelKey={isSendingTerms ? PanelKey.WriteMessage : PanelKey.EmailTemplate}
              state={state}
            />
          )}
          key={isSendingTerms ? PanelKey.WriteMessage : PanelKey.EmailTemplate}
          forceRender
        >
          <Space
            direction="vertical"
            style={isSendingTerms || isEditingTerms ? { width: '100%' } : null}
            className={cx({
              [styles.EmailComposer]: !isSendingTerms && !isEditingTerms,
            })}
          >
            {
              (isSendingTerms || isEditingTerms) && (
                <>
                  <EmailComposer
                    additionalVariables={{ LINK_TO_TERMS: { label: 'Link To Brief' } }}
                    subjectIsEditable
                    initialMessage={termsTemplate.emailTemplate.text}
                    currentValue={JSON.stringify(state.emailContent?.raw)}
                    memberQuery={{
                      communityIds: [-999],
                      includeMemberIds: data?.termsConfig?.members?.map((member) => member.id) || [],
                    }}
                    memberCount={data?.termsConfig?.members?.length}
                    onMessageSent={() => {}}
                    className={cx(styles.EmailComposer, {
                      [styles.hide]: !!previewConfig,
                    })}
                    initialAttachments={termsTemplate?.emailTemplate?.attachments || []}
                    subject={termsTemplate.emailTemplate.subject}
                    onSubjectChange={handleSubjectChange}
                    onEditorStateChange={handleEditorStateChange}
                    onAttachmentsChanged={handleAttachmentsChange}
                    onResourceIdChange={handleResourceIdChange}
                    onAdditionalCcStateChange={handleAdditionalCcChange}
                    hideSendButton
                    onPreview={handlePreviewConfigChange}
                  />
                  {
                    previewConfig && (
                      <EmailPreviewer
                        canSendMessages={false}
                        previewConfig={previewConfig}
                        onBack={() => setPreviewConfig(null)}
                        memberCount={data?.termsConfig?.members?.length}
                        memberQuery={{
                          communityIds: [-999],
                          includeMemberIds: data?.termsConfig?.members?.map((member) => member.id) || [],
                        }}
                        disableRemoveRecipient
                      />
                    )
                  }
                </>
              )
            }
            {
              (!isSendingTerms && !isEditingTerms) && (
                <>
                  <Space
                    direction="horizontal"
                    className={styles.EmailComposerHeader}
                  >
                    <Typography.Text type="secondary">Subject:</Typography.Text>
                    <Input
                      placeholder="Enter email subject"
                      size="large"
                      value={state.emailSubject}
                      onChange={(e) => {
                        handleSubjectChange(e.target.value);
                      }}
                    />
                  </Space>
                  <EmailEditor
                    initialValue={termsTemplate.emailTemplate.text}
                    onChange={handleEditorStateChange}
                    enableVariableReplacement
                    additionalVariables={{ LINK_TO_TERMS: { label: 'Link To Brief' } }}
                    showProgramVariables
                    attachments={attachments}
                    onAttachmentsSelected={handleSelectedFilesChange}
                    onDeleteAttachment={handleDeleteAttachment}
                  />
                </>
              )
            }
          </Space>
        </Panel>
      </Collapse>
    </TermsWizard>
  );
}));

TermsTemplate.displayName = 'TermsTemplate';

export default TermsTemplate;
