import 'css-chunk:src/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesLoading/ContentGuidelinesLoading.module.scss';export default {
  "spacing-xxxl": "80px",
  "spacing-xxl": "48px",
  "spacing-xl": "32px",
  "spacing-lg": "24px",
  "spacing-md": "16px",
  "spacing-sm": "12px",
  "spacing-xs": "8px",
  "spacing-xxs": "4px",
  "loadingContentContainer": "_loadingContentContainer_u7fk8_23",
  "loadingContentRow": "_loadingContentRow_u7fk8_30",
  "loadingContentWrapper": "_loadingContentWrapper_u7fk8_34",
  "contentGuidelinesLoadingHeader": "_contentGuidelinesLoadingHeader_u7fk8_41",
  "loadingLineXXS": "_loadingLineXXS_u7fk8_47",
  "loadingButton": "_loadingButton_u7fk8_54"
};