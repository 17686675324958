import React, { useEffect, useState } from 'react';
import AffiliateTableSTA from '@frontend/applications/AffiliatesApp/components/AffiliateTableSTA/AffiliateTableSTA';
import { Card } from '@revfluence/fresh';
import { useHistory } from 'react-router-dom';
import { useTableProps } from './useTableProps';
import { useGetCatalogs } from '../hooks/useGetCatalogs';
import { pfaV2Routes } from '../../routes';
import styles from './CatalogsTable.scss';
import { useGetAllCatalogStats } from '../hooks/useGetAllCatalogStats';

export const CatalogsTable = () => {
  const history = useHistory();

  const [searchText, setSearchText] = useState<string>('');

  const { catalogs, refetch: refetchCatalog } = useGetCatalogs();

  const { allCatalogStats, loading: isAllCatalogStatsLoading, refetch: refetchAllCatalogStats } = useGetAllCatalogStats();

  const {
    columnConfig, tableData, pagination, searchBox, headerActions,
  } = useTableProps({
    catalogs,
    allCatalogStats,
    isAllCatalogStatsLoading,
    searchText,
    setSearchText,
    refetchCatalog,
    refetchAllCatalogStats,
  });

  useEffect(() => {
    refetchCatalog();
    refetchAllCatalogStats();
  }, [refetchCatalog, refetchAllCatalogStats]);

  return (
    <Card className={styles.CatalogsTable}>
      <AffiliateTableSTA
        dataSource={tableData}
        columns={columnConfig}
        pagination={pagination}
        headerActions={headerActions}
        searchBox={searchBox}
        searchText={searchText}
        className="offerTable"
        enableEditColumn
        onRow={(record) => ({
          onClick: () => {
            history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', record.id));
          },
        })}
        scroll={{ y: 'calc(100vh - 390px)' }}
      />
    </Card>
  );
};
