import * as React from 'react';
import { CircleInfoIcon, LockIcon } from '@revfluence/fresh-icons/solid/esm';
import cx from 'classnames';
import { OFFER_FORM_LOCKING_KEY } from '@frontend/applications/AffiliatesApp/types';
import { OFFER_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Alert, AlertDescription, AlertTitle } from '@frontend/shadcn/components/ui/alert';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';
import { P } from '@frontend/shadcn/components/typography/p';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormValues,
  OfferFormAsyncActions,
  PROMO_OFFER_TYPES,
} from '../../../../../../types';
import { RefreshOfferActiveDate, RefreshOfferEndDate } from '../../..';
import styles from '../../../../../../OfferForm.scss';

interface IProps {
  values: IShopifyPromoCodeFormValues;
  offerEndDate: boolean;
  dateErrorMsg: string;
  setOfferEndDate: React.Dispatch<React.SetStateAction<boolean>>;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
}

export const RefreshNewOfferActiveDate: React.FC<Readonly<IProps>> = React.memo((props) => {
  const { values, dateErrorMsg, setOfferEndDate, handleFormAsyncActions } = props;
  const lockEditing = [
    OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_CLIENTS_FOR_SYNC,
    OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_MULTIPLE_SHOPIFY_ENABLED,
    OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_SAME_DISCOUNT_MS,
  ];
  const isDisabled =
    lockEditing.some((item) => values.lockEditing.includes(item)) ||
    values.status === OFFER_STATUS.PAUSED ||
    values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER ||
    values.isSecureCodes ||
    values.isLandingPageEnabled;
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="links-section">
        <AccordionTrigger className="hover:no-underline p-0 mt-2">
          <div className="flex flex-col gap-1">
            <P className="text-grey-6" fontWeight="font-medium">Offer Active Dates</P>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div>
            <p className="text-grey-4 mb-4">Set offer start and end dates</p>
          </div>

          {lockEditing.some((item) => values.lockEditing.includes(item)) && (
            <div className={styles.marginOnAlert}>
              <Alert variant="warning">
                <LockIcon scale={1.5} />
                <AlertTitle>Date settings are currently locked</AlertTitle>
                <AlertDescription>
                  Date settings are currently locked to 'Customize Dates' while offer sync preferences for Shopify
                  stores are being edited. To modify the date settings to 'Unified Date', please update or cancel the
                  changes to the offer sync option.
                </AlertDescription>
              </Alert>
            </div>

          )}
          <div className="flex gap-3">
            <div>
              <div className={styles.CustomTextColor}>
                <Label>Start Date</Label>
              </div>
              <RefreshOfferActiveDate
                disabled={values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER}
                name="activeDate"
                handleFormAsyncActions={handleFormAsyncActions}
                endDate={values.endDate}
                value={values.activeDate}
              />
            </div>
            <div>
              <div className={styles.CustomTextColor}>
                <Label>End Date (optional)</Label>
              </div>
              <RefreshOfferEndDate
                disabled={values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER}
                name="endDate"
                startDate={values.activeDate}
                setOfferEndDate={setOfferEndDate}
                handleFormAsyncActions={handleFormAsyncActions}
                value={values.endDate}
              />
            </div>
          </div>

          <div
            className={cx('flex items-center gap-2', {
              [styles.disableRadio]:
                lockEditing.some((item) => values.lockEditing.includes(item)) || values.status === OFFER_STATUS.PAUSED,
            })}
          >
            <Checkbox
              disabled={isDisabled || values.isSecureCodes}
              checked={values.isUngrouped}
              onCheckedChange={(checked) =>
                handleFormAsyncActions({
                  action: OfferFormAsyncActions.UPDATE_FIELD,
                  payload: { key: 'isUngrouped', value: checked },
                })}
            />
            <span className="flex items-center gap-2">
              <Label htmlFor="customDates" className="text-sm font-medium text-grey-6">
                Use Customized dates for each promo codes
              </Label>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <CircleInfoIcon className="w-3 h-3 text-grey-3" />
                  </TooltipTrigger>
                  <TooltipContent className="bg-grey-6 text-grey-0 p-2 max-w-[300px] text-sm">
                    Each promo code within this offer will be assigned to individual discount groups in Shopify
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </span>
          </div>

          <span className={styles.errorMessage}>{dateErrorMsg}</span>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
});
RefreshNewOfferActiveDate.displayName = 'RefreshNewOfferActiveDate';
