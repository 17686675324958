/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1eais_157 {
  display: flex;
}

._justify-content-space-between_1eais_161 {
  justify-content: space-between;
}

._tabular-nums_1eais_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1eais_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ComposeModal_1eais_178 ._dialog_1eais_178 ._title_1eais_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1eais_157 {
  display: flex;
}

._justify-content-space-between_1eais_161 {
  justify-content: space-between;
}

._tabular-nums_1eais_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1eais_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ComposeModal_1eais_178 ._dialog_1eais_178 ._title_1eais_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._workflowButton_1eais_375 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._workflowButton_1eais_375 {
  min-width: 2.25rem;
}
._workflowButton_1eais_375 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._workflowButton_1eais_375:focus, ._workflowButton_1eais_375:hover {
  border-color: #167cf4;
  color: #167cf4;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailComposerModal_1eais_428 {
  font-weight: bold;
  letter-spacing: 0.00625rem;
}

._ComposeModal_1eais_178 {
  z-index: 1010 !important;
}
._ComposeModal_1eais_178 ._content_1eais_436 {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
._ComposeModal_1eais_178 ._dialog_1eais_178 {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 !important;
}
._ComposeModal_1eais_178 ._dialog_1eais_178 ._title_1eais_178 {
  border-bottom: 0.0625rem solid #dadcde;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 3rem;
}
._ComposeModal_1eais_178 ._dialog_1eais_178 ._title_1eais_178 ._composerSpace_1eais_457 {
  width: 100%;
  justify-content: space-between;
}
._ComposeModal_1eais_178 ._messageForm_1eais_461 {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
._ComposeModal_1eais_178 ._messageForm_1eais_461._hide_1eais_467 {
  display: none;
}

._confirmationModalOverlay_1eais_471 {
  z-index: 1100 !important;
}

._confirmationModalContent_1eais_475 {
  z-index: 1110 !important;
}

._wrapper_1eais_479._disabled_1eais_479 ._workflowButton_1eais_375 {
  border-color: #bfbfbf !important;
  background-color: #f5f5f5 !important;
  cursor: no-drop;
  pointer-events: none;
}