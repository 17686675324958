import {
  CatalogSelectionRuleConditionOperationType,
  CatalogSelectionRuleConditionQuantityType,
} from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface Condition {
  conditionId: string;
  conditionName: string;
  isRequired: boolean;
  productQuantityType: CatalogSelectionRuleConditionQuantityType;
  quantity: number;
  operation: CatalogSelectionRuleConditionOperationType;
  collections: Array<{ id: string; name: string }>;
  vendors: string[];
  productTypes: string[];
  priceMin: number;
  priceMax: number;
  tags: string[];
  categories: string[];
  options: Array<{ name: string; values: string[] }>;
  productMetaFields: Array<{ metaFieldId: string, name: string; values: string[] }>;
  variantMetaFields: Array<{ metaFieldId: string, name: string; values: string[] }>;
}

export type KeyAndValue =
  | {
      collections: Array<{ id: string; name: string }>;
    }
  | {
      vendors: string[];
    }
  | {
      productTypes: string[];
    }
  | {
      tags: string[];
    }
  | {
      categories: string[];
    }
  | {
      priceMin: number;
    }
  | {
      priceMax: number;
    };

export interface SelectionCriteriaContextProps {
  isDrawerOpen: boolean;
  selectedCriteriaId: number | null;
  checkedCriteriaIds: number[];
  conditions: Condition[];
  setIsDrawerOpen: (payload: { isDrawerOpen: boolean; selectedCriteriaId?: number }) => void;
  setSelectedCriteriaId: (selectedCriteriaId: number | null) => void;
  addCondition: () => void;
  removeCondition: (conditionId: string) => void;
  updateCondition: (conditionId: string, condition: Partial<Condition>) => void;
  addConditionField: (conditionId: string, keyAndValue: KeyAndValue) => void;
  removeConditionField: (conditionId: string, keyAndValue: KeyAndValue) => void;
  setOptionConditionField: (conditionId: string, name: string, values: string[]) => void;
  setConditions: (conditions: Condition[]) => void;
  setCheckedCriteriaIds: (id: number, checked: boolean) => void;
  setBulkCheckedCriteriaIds: (ids: number[]) => void;
  setProductMetaFields: (productMetaFields: { conditionId: string, metaFieldId: string, name: string; values: string[] }) => void;
  setVariantMetaFields: (variantMetaFields: { conditionId: string, metaFieldId: string, name: string; values: string[] }) => void;
}
export type SelectionCriteriaContextState = Omit<
  SelectionCriteriaContextProps,
  | 'setIsDrawerOpen'
  | 'setSelectedCriteriaId'
  | 'addCondition'
  | 'removeCondition'
  | 'updateCondition'
  | 'addConditionField'
  | 'removeConditionField'
  | 'setOptionConditionField'
  | 'setConditions'
  | 'setCheckedCriteriaIds'
  | 'setBulkCheckedCriteriaIds'
  | 'setProductMetaFields'
  | 'setVariantMetaFields'
>;

const initialState: SelectionCriteriaContextState = {
  isDrawerOpen: false,
  selectedCriteriaId: null,
  conditions: [],
  checkedCriteriaIds: [],
};

export const SelectionCriteriaContext = createContext<SelectionCriteriaContextProps>({
  ...initialState,
  setIsDrawerOpen: () => {},
  setSelectedCriteriaId: () => {},
  addCondition: () => {},
  removeCondition: () => {},
  updateCondition: () => {},
  addConditionField: () => {},
  removeConditionField: () => {},
  setOptionConditionField: () => {},
  setConditions: () => {},
  setCheckedCriteriaIds: () => {},
  setBulkCheckedCriteriaIds: () => {},
  setProductMetaFields: () => {},
  setVariantMetaFields: () => {},
});

export enum ActionType {
  SetIsDrawerOpen = 'SET_IS_DRAWER_OPEN',
  SetSelectedCriteriaId = 'SET_SELECTED_CRITERIA_ID',
  AddCondition = 'ADD_CONDITION',
  RemoveCondition = 'REMOVE_CONDITION',
  UpdateCondition = 'UPDATE_CONDITION',
  AddConditionField = 'ADD_CONDITION_FIELD',
  RemoveConditionField = 'REMOVE_CONDITION_FIELD',
  SetOptionConditionField = 'SET_OPTION_CONDITION_FIELD',
  SetConditions = 'SET_CONDITIONS',
  SetCheckedCriteriaIds = 'SET_CHECKED_CRITERIA_IDS',
  SetBulkCheckedCriteriaIds = 'SET_BULK_CHECKED_CRITERIA_IDS',
  SetProductMetaFields = 'SET_PRODUCT_META_FIELDS',
  SetVariantMetaFields = 'SET_VARIANT_META_FIELDS',
}

export type Action =
  | {
      type: ActionType.SetIsDrawerOpen;
      payload: { isDrawerOpen: boolean; selectedCriteriaId?: number };
    }
  | {
      type: ActionType.SetSelectedCriteriaId;
      payload: number | null;
    }
  | {
      type: ActionType.AddCondition;
    }
  | {
      type: ActionType.RemoveCondition;
      payload: string;
    }
  | {
      type: ActionType.UpdateCondition;
      payload: { conditionId: string; condition: Partial<Condition> };
    }
  | {
      type: ActionType.AddConditionField;
      payload: { conditionId: string; keyAndValue: KeyAndValue };
    }
  | {
      type: ActionType.RemoveConditionField;
      payload: { conditionId: string; keyAndValue: KeyAndValue };
    }
  | {
      type: ActionType.SetOptionConditionField;
      payload: { conditionId: string; name: string; values: string[] };
    }
  | {
      type: ActionType.SetConditions;
      payload: Condition[];
    }
  | {
      type: ActionType.SetCheckedCriteriaIds;
      payload: { id: number, checked: boolean }
    }
  | {
      type: ActionType.SetBulkCheckedCriteriaIds;
      payload: number[];
    }
  | {
      type: ActionType.SetProductMetaFields;
      payload: { conditionId: string; metaFieldId: string, name: string; values: string[] };
    }
  | {
      type: ActionType.SetVariantMetaFields;
      payload: { conditionId: string; metaFieldId: string, name: string; values: string[] };
    };

export const SelectionCriteriaProvider: React.FC = ({ children }) => {
  const reducer = (state: SelectionCriteriaContextState, action: Action): SelectionCriteriaContextState => {
    switch (action.type) {
      case ActionType.SetIsDrawerOpen:
        return {
          ...state,
          isDrawerOpen: action.payload.isDrawerOpen,
          selectedCriteriaId:
            action.payload.selectedCriteriaId === undefined
              ? state.selectedCriteriaId
              : action.payload.selectedCriteriaId,
        };
      case ActionType.SetSelectedCriteriaId:
        return {
          ...state,
          selectedCriteriaId: action.payload,
        };
      case ActionType.AddCondition:
        return {
          ...state,
          conditions: [
            ...state.conditions,
            {
              conditionId: uuidv4(),
              conditionName: `Condition ${state.conditions.length + 1}`,
              isRequired: true,
              productQuantityType: CatalogSelectionRuleConditionQuantityType.MAXIMUM,
              quantity: 1,
              operation: CatalogSelectionRuleConditionOperationType.AND,
              collections: [],
              vendors: [],
              productTypes: [],
              priceMin: null,
              priceMax: null,
              tags: [],
              categories: [],
              options: [],
              productMetaFields: [],
              variantMetaFields: [],
            },
          ],
        };
      case ActionType.RemoveCondition:
        return {
          ...state,
          conditions: state.conditions.filter((condition) => condition.conditionId !== action.payload),
        };
      case ActionType.UpdateCondition:
        return {
          ...state,
          conditions: state.conditions.map((condition) => {
            if (condition.conditionId === action.payload.conditionId) {
              return {
                ...condition,
                ...action.payload.condition,
              };
            }
            return condition;
          }),
        };
      case ActionType.AddConditionField:
        return {
          ...state,
          conditions: state.conditions.map((condition) => {
            if (condition.conditionId === action.payload.conditionId) {
              return {
                ...condition,
                ...action.payload.keyAndValue,
              };
            }
            return condition;
          }),
        };
      case ActionType.RemoveConditionField:
        return {
          ...state,
          conditions: state.conditions.map((condition) => {
            if (condition.conditionId === action.payload.conditionId) {
              return {
                ...condition,
                ...action.payload.keyAndValue,
              };
            }
            return condition;
          }),
        };
      case ActionType.SetOptionConditionField: {
        const condition = state.conditions.find((c) => c.conditionId === action.payload.conditionId);
        if (!condition) {
          return state;
        }
        const option = condition.options.find((o) => o.name === action.payload.name);
        const newOptions = condition.options.map((o) => ({ ...o }));
        if (option) {
          newOptions.forEach((o) => {
            if (o.name === action.payload.name) {
              o.values = action.payload.values;
            }
          });
        } else {
          newOptions.push({
            name: action.payload.name,
            values: action.payload.values,
          });
        }
        return {
          ...state,
          conditions: state.conditions.map((c) => {
            if (c.conditionId === action.payload.conditionId) {
              return {
                ...c,
                options: newOptions,
              };
            }
            return c;
          }),
        };
      }
      case ActionType.SetConditions:
        return {
          ...state,
          conditions: action.payload,
        };
      case ActionType.SetCheckedCriteriaIds: {
        const { id, checked } = action.payload;
        if (checked) {
          return {
            ...state,
            checkedCriteriaIds: [...state.checkedCriteriaIds, id],
          };
        }
        return {
          ...state,
          checkedCriteriaIds: state.checkedCriteriaIds.filter((c) => c !== id),
        };
      }
      case ActionType.SetBulkCheckedCriteriaIds:
        return {
          ...state,
          checkedCriteriaIds: action.payload,
        };
      case ActionType.SetProductMetaFields: {
        const condition = state.conditions.find((c) => c.conditionId === action.payload.conditionId);
        if (!condition) {
          return state;
        }
        const metaField = condition.productMetaFields.find((o) => o.metaFieldId === action.payload.metaFieldId);
        const newMetaFields = condition.productMetaFields.map((o) => ({ ...o }));
        if (metaField) {
          newMetaFields.forEach((o) => {
            if (o.metaFieldId === action.payload.metaFieldId) {
              o.values = action.payload.values;
            }
          });
        } else {
          newMetaFields.push({
            name: action.payload.name,
            values: action.payload.values,
            metaFieldId: action.payload.metaFieldId,
          });
        }
        return {
          ...state,
          conditions: state.conditions.map((c) => {
            if (c.conditionId === action.payload.conditionId) {
              return {
                ...c,
                productMetaFields: newMetaFields,
              };
            }
            return c;
          }),
        };
      }
      case ActionType.SetVariantMetaFields: {
        const condition = state.conditions.find((c) => c.conditionId === action.payload.conditionId);
        if (!condition) {
          return state;
        }
        const metaField = condition.variantMetaFields.find((o) => o.metaFieldId === action.payload.metaFieldId);
        const newMetaFields = condition.variantMetaFields.map((o) => ({ ...o }));
        if (metaField) {
          newMetaFields.forEach((o) => {
            if (o.metaFieldId === action.payload.metaFieldId) {
              o.values = action.payload.values;
            }
          });
        } else {
          newMetaFields.push({
            name: action.payload.name,
            values: action.payload.values,
            metaFieldId: action.payload.metaFieldId,
          });
        }
        return {
          ...state,
          conditions: state.conditions.map((c) => {
            if (c.conditionId === action.payload.conditionId) {
              return {
                ...c,
                variantMetaFields: newMetaFields,
              };
            }
            return c;
          }),
        };
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setIsDrawerOpen: (payload: { isDrawerOpen: boolean; selectedCriteriaId: number }) => {
        dispatch({ type: ActionType.SetIsDrawerOpen, payload });
      },
      setSelectedCriteriaId: (selectedCriteriaId: SelectionCriteriaContextState['selectedCriteriaId']) => {
        dispatch({ type: ActionType.SetSelectedCriteriaId, payload: selectedCriteriaId });
      },
      addCondition: () => {
        dispatch({ type: ActionType.AddCondition });
      },
      removeCondition: (conditionId: string) => {
        dispatch({ type: ActionType.RemoveCondition, payload: conditionId });
      },
      updateCondition: (conditionId: string, condition: Partial<Condition>) => {
        dispatch({ type: ActionType.UpdateCondition, payload: { conditionId, condition } });
      },
      addConditionField: (conditionId: string, keyAndValue: KeyAndValue) => {
        dispatch({ type: ActionType.AddConditionField, payload: { conditionId, keyAndValue } });
      },
      removeConditionField: (conditionId: string, keyAndValue: KeyAndValue) => {
        dispatch({ type: ActionType.RemoveConditionField, payload: { conditionId, keyAndValue } });
      },
      setOptionConditionField: (conditionId: string, name: string, values: string[]) => {
        dispatch({ type: ActionType.SetOptionConditionField, payload: { conditionId, name, values } });
      },
      setConditions: (conditions: Condition[]) => {
        dispatch({ type: ActionType.SetConditions, payload: conditions });
      },
      setCheckedCriteriaIds: (id: number, checked: boolean) => {
        dispatch({ type: ActionType.SetCheckedCriteriaIds, payload: { id, checked } });
      },
      setBulkCheckedCriteriaIds: (ids: number[]) => {
        dispatch({ type: ActionType.SetBulkCheckedCriteriaIds, payload: ids });
      },
      setProductMetaFields: (productMetaFields: { conditionId: string; metaFieldId: string, name: string; values: string[] }) => {
        dispatch({ type: ActionType.SetProductMetaFields, payload: productMetaFields });
      },
      setVariantMetaFields: (variantMetaFields: { conditionId: string; metaFieldId: string, name: string; values: string[] }) => {
        dispatch({ type: ActionType.SetVariantMetaFields, payload: variantMetaFields });
      },
    }),
    [],
  );

  const memoizedValue = useMemo<SelectionCriteriaContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <SelectionCriteriaContext.Provider value={memoizedValue}>{children}</SelectionCriteriaContext.Provider>;
};

export const useSelectionCriteriaContext = () => useContext(SelectionCriteriaContext);
