import { isEmpty } from 'lodash';
import { SortingFn } from '@tanstack/react-table';
import { MemberInsights } from '@frontend/gql/social/graphql';
import { ColumnDefinitionInput, MappedColumn } from '..';

/**
 * Metadata structure for table columns
 */
export interface ColumnMetadata {
  id: string;
  header: string;
  rowType: number; // Using number instead of enum for better compatibility
  accessorKey?: string;
}

/**
 * Represents a flattened column structure that extends the base column definition
 */
export type FlattenedColumn = Omit<
  ColumnDefinitionInput,
  'hidden' | 'subColumns' | 'order' | 'onToggleClick' | 'greyBackground'
> & {
  isSubColumn: boolean;
  hasSubColumns: boolean;
};

/**
 * Type definition for columns that can be enriched with additional properties
 */
type EnrichableColumn = Pick<
  ColumnDefinitionInput,
  | 'id'
  | 'enableSorting'
  | 'size'
  | 'minSize'
  | 'maxSize'
  | 'borderColor'
  | 'header'
  | 'rowType'
  | 'isColumnExpanded'
  | 'isExpandableColumn'
  | 'subColumns'
  | 'accessorFn'
  | 'enableGlobalFiltering'
> &
  Partial<Pick<FlattenedColumn, 'isSubColumn' | 'hasSubColumns'>>;

  const customSortingFn: SortingFn<MemberInsights> = (rowA, rowB, columnId) => {
    const valueA = rowA.getValue(columnId);
    const valueB = rowB.getValue(columnId);

    // Treat "-" as undefined or empty
    const normalizedA = valueA === '-' ? undefined : valueA;
    const normalizedB = valueB === '-' ? undefined : valueB;

    if (normalizedA == null && normalizedB == null) return 0;
    if (normalizedA == null) return -1; // Push null/undefined/"-" to the bottom
    if (normalizedB == null) return 1;

    return normalizedA > normalizedB ? 1 : -1;
  };

/**
 * Processes selected columns and returns a flattened structure
 * @param selectedColumns - Array of column definitions to process
 * @param includeHidden - Whether to include hidden columns in the output
 * @returns Array of flattened column structures
 */
export const processSelectedColumns = (selectedColumns: ColumnDefinitionInput[], includeHidden = false) => {
  const visibleColumns = includeHidden ? selectedColumns : selectedColumns.filter((col) => !col.hidden);

  return visibleColumns.flatMap((data) => _flattenColumnsBase(data, includeHidden));
};

/**
 * Converts selected columns into column definitions suitable for table rendering
 * @param selectedColumns - Array of column definitions to convert
 * @param handleExpandColumn - Callback function to handle column expansion
 * @returns Array of mapped columns ready for table use
 */
export const selectedColumnsToColumnDefInput = (
  selectedColumns: ColumnDefinitionInput[],
  handleExpandColumn: (columndId: string) => void,
): MappedColumn[] => {
  const visibleColumns = selectedColumns.filter((col) => !col.hidden);

  const processColumn = (
    data: ColumnDefinitionInput,
    inheritedBorderColor = '',
    isSubColumn = false,
  ): MappedColumn[] => {
    if (data.hidden) return [];

    const borderColor = data.borderColor || inheritedBorderColor;
    const mappedColumn = _enrichColumnForTable(data, handleExpandColumn);

    if (isSubColumn) {
      mappedColumn.meta.greyBackground = true;
      mappedColumn.meta.draggable = false;
    }

    mappedColumn.isSubColumn = isSubColumn;

    const subcolumns =
      data.isColumnExpanded && data.subColumns?.length
        ? data.subColumns.flatMap((subcolumn) => processColumn(subcolumn, borderColor, true)) // Recursively process subcolumns
        : [];

    return [
      {
        ...mappedColumn,
        meta: {
          ...mappedColumn.meta,
          headerGroupBorder: { borderColor },
        },
      },
      ...subcolumns,
    ];
  };

  return visibleColumns.flatMap((col) => processColumn(col));
};

/**
 * Extracts metadata from selected columns for CSV export
 * @param selectedColumns - Array of column definitions to process
 * @param includeHidden - Whether to include hidden columns in the output
 * @returns Array of column metadata objects suitable for CSV export
 */
export const selectedColumnsToCSVMetadata = (
  selectedColumns: ColumnDefinitionInput[],
  includeHidden = false,
): ColumnMetadata[] => {
  const columns = processSelectedColumns(selectedColumns, includeHidden);

  // Remove any duplicate columns that might have the same ID
  const uniqueColumns = Array.from(new Map(columns.map((col) => [col.id, col])).values());

  return uniqueColumns.map((columnData) => ({
    id: columnData.id,
    header: columnData.header,
    rowType: columnData.rowType,
    // Make sure accessorKey matches the id for proper data access
    accessorKey: columnData.id,
  }));
};

/**
 * Internal function to flatten the column hierarchy into a linear structure
 * @param data - Column definition to flatten
 * @param includeHidden - Whether to include hidden columns
 * @param inheritedBorderColor - Border color inherited from parent column
 * @param isSubColumn - Whether the current column is a sub-column
 * @param parentHeader - Parent column's header for nested columns
 * @returns Array of flattened column structures
 * @internal
 */
export const _flattenColumnsBase = (
  data: ColumnDefinitionInput,
  includeHidden = false,
  inheritedBorderColor = '',
  isSubColumn = false,
  parentHeader = '',
): FlattenedColumn[] => {
  if (!includeHidden && data.hidden) return [];

  const borderColor = data.borderColor || inheritedBorderColor;
  const header = parentHeader ? `${parentHeader} - ${data.header}` : data.header;

  const baseColumn = {
    id: data.id,
    enableSorting: data.enableSorting,
    size: data.size,
    minSize: data.minSize,
    maxSize: data.maxSize,
    borderColor,
    header,
    rowType: data.rowType,
    isColumnExpanded: data.isColumnExpanded,
    isExpandableColumn: data.isExpandableColumn,
    isSubColumn,
    hasSubColumns: !isEmpty(data.subColumns),
  };

  const subcolumns =
    data.isColumnExpanded && data.subColumns?.length
      ? data.subColumns.flatMap((subcolumn) => _flattenColumnsBase(subcolumn, includeHidden, borderColor, true, header))
      : [];

  return [baseColumn, ...subcolumns];
};

/**
 * Internal function to enrich column data with additional properties for table use
 * @param columnData - Base column data to enrich
 * @param handleExpandColumn - Callback function to handle column expansion
 * @returns Enriched column structure ready for table use
 * @internal
 */
export const _enrichColumnForTable = (
  columnData: EnrichableColumn,
  handleExpandColumn: (columndId: string) => void,
): MappedColumn => {
  return {
    id: columnData.id,
    enableSorting: columnData.enableSorting,
    sortingFn: customSortingFn,
    accessorFn: columnData.accessorFn,
    size: columnData.size,
    minSize: columnData.minSize,
    maxSize: columnData.maxSize,
    enableGlobalFilter: columnData.enableGlobalFiltering || false,
    meta: {
      ...(columnData.isSubColumn === true && { greyBackground: true }),
      headerGroupBorder: {
        borderColor: columnData.borderColor,
      },
      onToggleClick:
        !isEmpty(columnData.subColumns) || columnData.hasSubColumns === true
          ? () => handleExpandColumn(columnData.id)
          : null,
      isColumnExpanded: columnData.isColumnExpanded ?? false,
      isExpandableColumn: columnData.isExpandableColumn ?? false,
    },
    header: columnData.header,
    rowType: columnData.rowType,
  };
};
