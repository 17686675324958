'use client';

import React, { useEffect } from 'react';
import { format, isEqual } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';

import { cn } from '@frontend/shadcn/lib/utils';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@frontend/shadcn/components/ui/popover';

interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
  startDate?: Date;
  endDate?: Date;
  onRangeChange?: (range: { from: Date; to?: Date }) => void;
}

export function DatePickerWithRange({
  className,
  startDate,
  endDate,
  onRangeChange,
}: DatePickerWithRangeProps) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: startDate || new Date(),
    to: endDate,
  });

  const handleSelect = (range: DateRange | undefined) => {
    const from = range?.from;
    let to = range?.to;
    if (isEqual(from, to)) {
      to = undefined;
    }
    setDate({ from, to });
    if (range && onRangeChange) {
      onRangeChange({ from, to });
    }
  };

  useEffect(() => {
    setDate({
      from: startDate,
      to: endDate,
    });
  }, [startDate, endDate]);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            className={cn(
              'w-[300px] justify-start text-left font-normal bg-transparent',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')}
                  {' '}
                  -
                  {' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                <>
                  {format(date.from, 'LLL dd, y')}
                  {' '}
                  -
                  <span className="text-grey">
                    &nbsp;End date
                  </span>
                </>
              )
            ) : (
              <>
                <span className="text-grey">
                  Start date
                </span>
                {' '}
                -
                <span className="text-grey">
                  &nbsp;End date
                </span>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSelect}
            numberOfMonths={2}
            disabled={{
              before: new Date(),
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
