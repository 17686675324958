import { useCallback, useState } from 'react';
import { logger } from '@common';
import { useClient } from 'urql';
import { SocialNetwork, SocialPostType } from '@frontend/gql/social/graphql';
import { getAllSocialAnalyticsMembers } from '../graphql';
import { exportDataToCsv } from '../components/table/utils/csvExport';
import { defaultColumns } from '../components/table/config/defaultColumns';
import { selectedColumnsToCSVMetadata } from '../components/table/utils/selectedColumnsToColumnDef';

interface UseExportFullMembersCsvProps {
  clientId: string;
  queryVariables: {
    startDate: string;
    endDate: string;
    postTypes: SocialPostType[];
    includeEstimates: boolean;
    networks: SocialNetwork[];
    projectIds: number[];
    groupIds: number[];
  };
}

// Helper function to expand all columns and their subcolumns
const expandAllColumns = (columns: typeof defaultColumns): typeof defaultColumns => {
  return [...columns].map((column) => ({
    ...column,
    hidden: false,
    isColumnExpanded: true,
    subColumns: column.subColumns ? expandAllColumns([...column.subColumns]) : undefined,
  }));
};

export const useExportFullMembersCsv = ({ clientId, queryVariables }: UseExportFullMembersCsvProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const client = useClient();

  const handleExportFullCsv = useCallback(async () => {
    if (isExporting) return;

    setIsExporting(true);

    try {
      // Execute the query to get all data
      const result = await client.query(getAllSocialAnalyticsMembers, {
        ...queryVariables,
        clientId,
      });

      if (result.error) {
        throw new Error('Failed to fetch data for export');
      }

      const memberInsights = result.data?.client?.socialAnalytics?.members?.results;

      if (!memberInsights || memberInsights.length === 0) {
        logger.warn('No member insights data available for export');
        setIsExporting(false);
        return;
      }

      // Expand all columns and their subcolumns for the full export
      // Expand all columns and their subcolumns for the full export
      const expandedColumns = expandAllColumns(defaultColumns);
      const columnMetadata = selectedColumnsToCSVMetadata(expandedColumns, true);

      // Format filename with date and time for uniqueness
      const now = new Date();
      const dateStr = now.toISOString().split('T')[0];
      const filename = `Social Analytics Members Full Export ${dateStr}.csv`;

      // Add a slight delay to ensure UI updates before potentially resource-intensive export
      setTimeout(() => {
        try {
          // Export the data with all records and all columns
          exportDataToCsv(memberInsights, columnMetadata, filename);
        } catch (innerError) {
          logger.error('Error during full CSV generation:', innerError);
        } finally {
          setIsExporting(false);
        }
      }, 100);
    } catch (error) {
      logger.error('Error preparing full CSV export:', error);
      setIsExporting(false);
    }
  }, [isExporting, client, clientId, queryVariables]);

  return {
    handleExportFullCsv,
    isExporting,
  };
};
