import * as React from 'react';
import cx from 'classnames';
import { RawDraftContentState } from 'draft-js';
import { size } from 'lodash';

import { EmailComposer, IPreviewConfig, IEmailComposer } from '@frontend/app/components';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';
import { ApolloProvider } from '@apollo/client';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useAppContext } from '@frontend/context/AppContext';

import styles from './EmailComposer.scss';

interface ICPSEmailComposerProps {
  className?: string;
  getInitialState?(): [string | null, RawDraftContentState | null];
  memberIds: number[];
  beforeMessageSent?(): void | Promise<void>;
  customSend?(messageParams: SendMessageInput): void | Promise<void>;
  sendButtonText?: string;
  hideSendButton?: boolean;
  hideActions?: boolean;
}

export const _CPSEmailComposer = React.forwardRef<IEmailComposer, ICPSEmailComposerProps>((props, ref) => {
  const {
 memberIds, beforeMessageSent, getInitialState, sendButtonText, customSend,
} = props;

  const { closeModal } = useApplication();

  const messageFormRef = React.useRef<IEmailComposer>(null);
  const [previewConfig, setPreviewConfig] = React.useState<IPreviewConfig>(null);
  const [[initialSubject, initialMessage]] = React.useState(getInitialState());

  // Forward the ref to the EmailComposer
  React.useImperativeHandle(ref, () => ({
    sendMessage: () => messageFormRef.current?.sendMessage(),
    reviewMessage: () => messageFormRef.current?.reviewMessage(),
    backToEdit: () => messageFormRef.current?.backToEdit(),
  }));

  const emailPreview = React.useMemo(() => {
    if (previewConfig) {
      return (
        <EmailPreviewer
          previewConfig={previewConfig}
          memberCount={size(memberIds)}
          memberQuery={{
            includeMemberIds: memberIds,
          }}
          onBack={() => setPreviewConfig(null)}
          onConfirm={() => {
            setPreviewConfig(null);
            messageFormRef.current.sendMessage(true);
          }}
          disableRemoveRecipient
          sendButtonText={sendButtonText}
          hideActions={props.hideActions}
        />
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds, previewConfig]);

  return (
    <>
      <EmailComposer
        ref={messageFormRef}
        className={cx(props.className, {
          [styles.hide]: !!previewConfig,
        })}
        subject={initialSubject}
        initialMessage={initialMessage}
        hasTopBorder={false}
        beforeMessageSent={beforeMessageSent}
        onMessageSent={closeModal}
        memberQuery={{
          communityIds: [-999],
          includeMemberIds: memberIds,
        }}
        memberCount={size(memberIds)}
        source="productfulfillment"
        onPreview={setPreviewConfig}
        sendButtonText={sendButtonText}
        customSend={customSend}
        allowSendingAsSeparate
        enableExpandingEmailEditor
        hideSendButton={props.hideSendButton}
        hideActions={props.hideActions}
      />
      {emailPreview}
    </>
  );
});

_CPSEmailComposer.displayName = '_CPSEmailComposer';

export interface IParentEmailComposerProps extends ICPSEmailComposerProps {
}

export const CPSEmailComposer = React.forwardRef<IEmailComposer, IParentEmailComposerProps>((props, ref) => {
  const { apolloClient } = useAppContext();

  return (
    <ApolloProvider client={apolloClient}>
      <_CPSEmailComposer
        {...props}
        ref={ref}
      />
    </ApolloProvider>
  );
});

CPSEmailComposer.displayName = 'CPSEmailComposer';
