import {
  map,
  some,
} from 'lodash';

import { caseInsensitiveStringSort } from '../../ReviewAndSummary/ReviewTable/utils';
import {
  IInputMember,
  IMember,
  SOCIAL_MEMBER_FIELD_MAP,
} from '../../types/Member';
import {
  ILoadInitialStateAction,
  IState,
  Step,
} from '../../types/state';
import { getInitialState } from './getInitialState';

export const loadInitialState = (state: IState, action: ILoadInitialStateAction): IState => {
  const initialState = getInitialState(state.featureFlags);
  const newState = {
    ...initialState,
    emailSubject: action.emailSubject,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        payment: {
          ...state.collaborationDetails.reward.payment,
          newPrice: action.compensationPaymentValue,
          showHideNewPrice: action.compensationPaymentAvailable,
        },
        freeProduct: {
          ...state.collaborationDetails.reward.freeProduct,
          description: action.compensationFreeProductValue,
          showHideDescription: action.compensationFreeProductAvailable,
        },
        commission: {
          ...state.collaborationDetails.reward.commission,
          description: action.compensationCommissionValue,
          showHideDescription: action.compensationCommissionAvailable,
        },
        noCompensation: {
          ...state.collaborationDetails.reward.noCompensation,
          showHideDescription: action.noCompensationAvailable,
        },
      },
      contentRight: {
        type: action.contentUsageRightType,
        text: action.contentUsageRightValue,
      },
    },
    members: map(action.members, (member: IInputMember): IMember => ({
      ...member,
      ...member.fields,
      additionalImages: [],
      additionalVideos: [],
      blogPostMentions: [],
      blogPosts: [],
      comment: null,
      customWork: [],
      disabled: false,
      facebookPosts: [],
      instagramPosts: [],
      instagramReels: [],
      instagramStories: [],
      instagramVideos: [],
      key: member.id.toString(),
      paymentAmount: action.compensationPaymentValue,
      recommendedPaymentAmount: null,
      pinterestPins: [],
      selected: true,
      snapchatStories: [],
      tiktokVideos: [],
      twitterTweets: [],
      youtubeVideos: [],
      youtubeProductMentions: [],
      youtubeShorts: [],
      flexibleDeliverable: [],
    })).sort(caseInsensitiveStringSort('name')),
  };

  // check if any member can fulfill any of the content requirements
  const memberCanFulfill = some(
    action.contentGuidelineTemplates,
    ({ type }) => {
      if (!(type in SOCIAL_MEMBER_FIELD_MAP)) {
        return false;
      }
      const memberField = SOCIAL_MEMBER_FIELD_MAP[type];
      return some(newState.members, (member) => member[memberField] !== null);
    },
  );
  if (!memberCanFulfill) {
    // no member can fulfill any of the content templates
    return {
      ...newState,
      errorStepDescription: 'Please return to the project page.',
      errorStepMessage: 'None of the members selected can fulfill the requirements.',
      step: Step.ErrorStep,
    };
  }

  return newState;
};
