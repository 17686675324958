import { SHOPIFY_APP_ID } from '@frontend/app/constants/applicationIds';
import { useResourceContext } from '@frontend/app/context';
import { Alert, Button, Space } from '@revfluence/fresh';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ShadCnAlert from '@frontend/app/refresh-components/Alert';

interface ShopifyStoreDisconnectionProps {
  refreshUi?: boolean;
  hasMissingScopes?: boolean;
  handleAccountReconnect?: () => void;
}

export const ShopifyStoreDisconnectionAlert: React.FC<ShopifyStoreDisconnectionProps> = ({ refreshUi, hasMissingScopes, handleAccountReconnect }) => {
  const { isAllShopifyNotConnected } = useResourceContext();
  const history = useHistory();

  const handleNavigation = useCallback(() => {
    history.push(`/settings/${SHOPIFY_APP_ID}`);
  }, [history]);

  if (hasMissingScopes) {
    return (
      <ShadCnAlert
        title="Shopify Connection Update Needed"
        description="As part of the ongoing improvements to Shopify integration features, we need additional scopes to be authorized for some of your stores. Please reconnect your stores."
        onClick={handleAccountReconnect}
        buttonText="Reconnect"
        closeable
        type="info"
      />
    );
  }
  return (
    isAllShopifyNotConnected
    && (refreshUi ? (
      <ShadCnAlert
        title="Shopify Store Connection Issue"
        description="We have detected an issue with your connected Shopify store. To ensure seamless operation, please reconnect your store."
        onClick={handleNavigation}
        buttonText="Reconnect"
        closeable
      />
    ) : (
      <Alert
        message="Shopify Store Connection Issue"
        type="error"
        showIcon
        description="We have detected an issue with your connected Shopify store. To ensure seamless operation, please reconnect your store."
        action={(
          <Space>
            <Button size="small" type="ghost" onClick={handleNavigation}>
              Reconnect Your Shopify Store
            </Button>
          </Space>
        )}
        style={{ marginBottom: 16 }}
      />
    ))
  );
};

export default ShopifyStoreDisconnectionAlert;
