import React, { useMemo, useEffect } from 'react';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { Link } from 'react-router-dom';
import { useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@/shadcn/components/ui/tooltip';
import { Button } from '@/shadcn/components/ui/button';
import { SecondaryNavProps } from '../../Layout';
import {
  MenuHeader,
  MenuHeaderLayout,
  MenuItemContent,
  MenuLayout,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuTitle,
  MenuSearch,
} from '../menuLayout';

export const SecondaryProjectsNav = ({ onItemSelected, projects }: SecondaryNavProps) => {
  const {
    searchText,
    inputValue,
    handleSearchChange: handleSearchChangeFromHook,
    error: searchError,
    checkNoResults,
  } = useSearchWithDebounce();

  const handleSearchChange = (value: string) => {
    handleSearchChangeFromHook({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
  };

  const filteredProjects = useMemo(() =>
    projects.filter((project) =>
      project.title?.toLowerCase().includes(searchText.toLowerCase())),
    [projects, searchText]);

  useEffect(() => {
    checkNoResults(filteredProjects.length > 0);
  }, [filteredProjects.length, checkNoResults]);

  return (
    <MenuLayout>
      <MenuHeaderLayout>
        <MenuHeader>Projects</MenuHeader>
        <Button
          size="xs"
          variant="outline"
          className="ml-auto text-muted-foreground hover:text-foreground group gap-1.5"
          onClick={onItemSelected}
          asChild
        >
          <Link to="/projects/new/templates">
            <PlusIcon className="h-3 w-3 font-semibold group-hover:text-foreground" />
            <span className="text-xs font-semibold group-hover:text-foreground">New</span>
          </Link>
        </Button>
      </MenuHeaderLayout>

      <MenuSearch
        value={inputValue}
        onChange={handleSearchChange}
        placeholder="Search projects..."
        entity="projects"
      />

      <TooltipProvider>
        <MenuList>
          {searchError ? (
            <div
              className="text-error text-xs px-4 py-3 m-0 w-full"
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <MenuListItem>
                <MenuItemContent>
                  <MenuTitle>{searchError}</MenuTitle>
                </MenuItemContent>
              </MenuListItem>
            </div>
          ) : (
            filteredProjects.map((project) => (
              <MenuListItem key={project.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <MenuLink onClick={onItemSelected} to={`/projects/${project.id}`}>
                      <Avatar className="h-9 w-9 flex rounded-md">
                        <AvatarImage src={project?.splashImageUrl} alt="Avatar" className="object-cover" />
                        <AvatarFallback className="rounded-md">{project?.title?.charAt(0)}</AvatarFallback>
                      </Avatar>
                      <MenuItemContent>
                        <MenuTitle>{project?.title}</MenuTitle>
                      </MenuItemContent>
                    </MenuLink>
                  </TooltipTrigger>
                  <TooltipContent className="z-[190]">{project?.title}</TooltipContent>
                </Tooltip>
              </MenuListItem>
            ))
          )}
        </MenuList>
      </TooltipProvider>
    </MenuLayout>
  );
};
