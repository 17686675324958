import 'css-chunk:src/components/widgets/Invite/Invite.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "buttonWidth": "80",
  "popoverWidth": "320",
  "flex": "_flex_10vm3_157",
  "justify-content-space-between": "_justify-content-space-between_10vm3_161",
  "tabular-nums": "_tabular-nums_10vm3_165",
  "Invite": "_Invite_10vm3_169",
  "isFetching": "_isFetching_10vm3_213",
  "loading": "_loading_10vm3_220",
  "hideButton": "_hideButton_10vm3_224",
  "select": "_select_10vm3_224",
  "inviteButton": "_inviteButton_10vm3_228",
  "withButton": "_withButton_10vm3_234",
  "selectButton": "_selectButton_10vm3_241",
  "withBorder": "_withBorder_10vm3_245",
  "isFocused": "_isFocused_10vm3_270",
  "inviting": "_inviting_10vm3_293",
  "wait": "_wait_10vm3_296",
  "disabled": "_disabled_10vm3_300",
  "popover": "_popover_10vm3_304",
  "popoverContentWrapper": "_popoverContentWrapper_10vm3_308",
  "shown": "_shown_10vm3_311",
  "message": "_message_10vm3_311",
  "popoverContent": "_popoverContent_10vm3_308",
  "option": "_option_10vm3_321",
  "noSelect": "_noSelect_10vm3_341",
  "communityOption": "_communityOption_10vm3_344",
  "programOption": "_programOption_10vm3_354",
  "noPrograms": "_noPrograms_10vm3_357",
  "accountLink": "_accountLink_10vm3_380",
  "ineligible": "_ineligible_10vm3_385",
  "error": "_error_10vm3_388",
  "noCommunities": "_noCommunities_10vm3_392",
  "new": "_new_10vm3_414",
  "invited": "_invited_10vm3_415",
  "approved": "_approved_10vm3_416",
  "tooltip": "_tooltip_10vm3_428",
  "ammendAction": "_ammendAction_10vm3_435",
  "searchContainer": "_searchContainer_10vm3_443",
  "searchOption": "_searchOption_10vm3_447",
  "searchError": "_searchError_10vm3_464",
  "show": "_show_10vm3_311"
};