import 'css-chunk:src/app/containers/MessagingApp/MessageList/ThreadAssignees/styles.scss';export default {
  "ThreadAssignees": "_ThreadAssignees_1orli_1",
  "popoverTrigger": "_popoverTrigger_1orli_1",
  "editIcon": "_editIcon_1orli_1",
  "header": "_header_1orli_11",
  "sharedEmail": "_sharedEmail_1orli_24",
  "popoverContent": "_popoverContent_1orli_28",
  "popoverList": "_popoverList_1orli_32",
  "popoverElementWrapper": "_popoverElementWrapper_1orli_41",
  "popoverElement": "_popoverElement_1orli_41"
};