import * as React from 'react';
import { useHighlightsQuery, useFuzzySearchByKeys } from '@frontend/app/hooks';
import { SelectList } from '../../SelectList';

interface IProps {
  defaultValue: number[];
  onChange(value: number[]);
}

const SEARCH_KEYS = ['name'];

export const HighlightInclusion: React.FC<IProps> = (props) => {
  const {
    data: {
      highlights = null,
    } = {},
  } = useHighlightsQuery();

  const handleSearch = useFuzzySearchByKeys(highlights, SEARCH_KEYS);

  type TOption = typeof highlights[0];

  return (
    <SelectList
      showSearch
      onSearchRequest={handleSearch}
      options={highlights}
      defaultSelectedIds={props.defaultValue}
      mapOptionToId={(option: TOption) => option.id}
      mapOptionToLabel={(option: TOption) => option.name}
      onChange={props.onChange}
    />
  );
};
