import React from 'react';
import { FiscalYearBudgetBreakdown as FiscalYearBudgetBreakdownType } from '@frontend/app/types/Budget';
import { Select, Typography } from '@revfluence/fresh';
import { GetBudgetAccountDistribution_budgetAccountDistribution } from '@frontend/app/queries/types/GetBudgetAccountDistribution';
import CurrencyInput from '../../../CurrencyInput';
import styles from './FiscalYearProperties.scss';
import FiscalYearAdditionalConfig from './components/FiscalYearAdditionalConfig';

const { Option } = Select;

const { Text } = Typography;

const FiscalYearProperties = ({
  fiscalYearBudget,
  handleFiscalYearBudgetChange,
  fiscalYearsBudget,
  budgetAccountDistribution,
  parentBudgetId,
  parentBudget,
  fiscalYearsOptions,
}: {
  fiscalYearBudget: FiscalYearBudgetBreakdownType;
  fiscalYearsOptions: { year: number; label: string }[];
  handleFiscalYearBudgetChange: (fiscalYearBudget: FiscalYearBudgetBreakdownType) => void;
  fiscalYearsBudget: [FiscalYearBudgetBreakdownType];
  budgetAccountDistribution: GetBudgetAccountDistribution_budgetAccountDistribution[];
  parentBudgetId?: number;
  parentBudget: GetBudgetAccountDistribution_budgetAccountDistribution
}) => {
  const currentFiscalYearLabel = fiscalYearsOptions.find(
    (fiscalYear) => fiscalYear.year === fiscalYearBudget.fiscalYear,
  )?.label;
  const filteredFiscalYearsOptions = fiscalYearsOptions.filter(
    (option) =>
      !fiscalYearsBudget.some((budget) => budget.fiscalYear === option.year && budget.fiscalYear !== fiscalYearBudget.fiscalYear),
  );
  const handleFiscalYearChange = (value) => {
    const parentBudgetFiscalYear = parentBudget?.fiscalYearsBudget?.find((fy) => fy.fiscalYear === value);
    const updatedGranularity = parentBudgetFiscalYear ? parentBudgetFiscalYear.granularity : fiscalYearBudget.granularity;
    handleFiscalYearBudgetChange({ ...fiscalYearBudget, fiscalYear: value, granularity: updatedGranularity });
  };

  const handletotalAmountChange = (value) => {
    handleFiscalYearBudgetChange({ ...fiscalYearBudget, totalAmount: value });
  };

  const isSingleFiscalYear = fiscalYearsOptions.length === 1 && fiscalYearsOptions[0].year.toString() === 'N/A';
  return (
    <div className={styles.fiscalYearBudget}>
      {!isSingleFiscalYear && (
        <div>
          <Text>Fiscal Year</Text>
          <Select value={fiscalYearBudget.fiscalYear} onChange={handleFiscalYearChange} disabled={!fiscalYearBudget.isNew}>
            {filteredFiscalYearsOptions.map((option) => (
              <Option value={option.year} key={option.year}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}
      <div>
        <Text>
          What's your overall budget for
          {currentFiscalYearLabel}
          ?
        </Text>
        <CurrencyInput value={fiscalYearBudget.totalAmount} width="100%" onChange={handletotalAmountChange} />
      </div>
      {fiscalYearBudget.fiscalYear.toString() !== 'N/A' && (
        <div>
          <FiscalYearAdditionalConfig
            parentBudgetId={parentBudgetId}
            fiscalYearBudget={fiscalYearBudget}
            handleFiscalYearBudgetChange={handleFiscalYearBudgetChange}
            budgetAccountDistribution={budgetAccountDistribution}
          />
        </div>
      )}
    </div>
  );
};

export default FiscalYearProperties;

FiscalYearProperties.displayName = 'FiscalYearProperties';
