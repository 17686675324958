import {
 isNumber, toLower, trim, isString, size, includes,
} from 'lodash';
import { isValid, format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import { ColumnDef } from '@tanstack/react-table';
import { ColumnKey } from '@frontend/app/containers/Projects/constants';
import { formatMessageDateWithTime } from '@frontend/app/utils';
import { FlexMemberTableRow } from './hooks/useFlexMembersTableColumns';

export const sortStr = (a: string, b: string) => {
  const lowerA = toLower(trim(a));
  const lowerB = toLower(trim(b));
  if (lowerA === '' || lowerA === null || lowerA === undefined) return 1;
  if (lowerB === '' || lowerB === null || lowerB === undefined) return -1;

  if (lowerA === lowerB) return 0;
  return lowerA < lowerB ? -1 : 1;
};

export const formatDate = (val: number | string) => {
  let parsedDate;
  if (!val) return '';

  if (isString(val)) {
    parsedDate = Date.parse(val as string);
  } else if (isNumber(val)) {
    const length = size(`${val}`);
    if (!includes([10, 13], length)) {
      return '';
    }
    parsedDate = new Date(length === 10 ? (val * 1000) : val);
  }

  return isValid(parsedDate) ? format(parsedDate, 'PP') : '';
};

type ValueFormatter = (row: FlexMemberTableRow, key: string) => string;

const formatters: Record<string, ValueFormatter> = {
  [ColumnKey.LastMessage]: (row) => {
    const lastMessage = row?.member?.lastMessage;
    return lastMessage ? formatMessageDateWithTime(lastMessage.internalDate) : '';
  },
  [ColumnKey.WorkletTaskName]: (row, key) => row[key]?.taskName || '',
  name: (row) => row.name || '',
  tags: (row) => row.member?.tags?.map((tag) => tag.name).join(', ') || '',
  owners: (row) => row.member?.owners?.map((owner) => owner.name).join(', ') || '',
  communities: (row) => row.member?.communities?.map((community) => community.title).join(', ') || '',
  programs: (row) => row.member?.programs?.map((program) => program.title).join(', ') || '',
  [ColumnKey.CTA]: (row) => {
    const ctaData = row[ColumnKey.CTA];
    if (!ctaData) return '';
    return ctaData.taskMetaData?.singleCTAText || ctaData.workItem?.taskMetaData?.singleCTAText || 'Mark as Done';
  },
  [ColumnKey.WorkletName]: (row) => {
    const workletName = row[ColumnKey.WorkletName];
    if (!workletName) return '';
    return workletName.taskName || '';
  },
};

const formatCurrency = (value: number | string | undefined): string =>
  (value ? `$${value}` : '');

const getFieldValue = (row: FlexMemberTableRow, key: string): string =>
  row[key] || '';

export function prepareExportData(data: FlexMemberTableRow[], columns: ColumnDef<FlexMemberTableRow, unknown>[]) {
  // Filter out complex columns and sub-columns of expandable columns
  const filteredColumns = columns.filter((column) => {
    const columnId = column.id?.toString() || '';
    const complexColumnIds = [
      'offers',
      'deliverables',
      'orders',
      'briefs'
    ];

    // Check if this is a sub-column of an expandable column
    const isSubColumn = (column as any).meta?.greyBackground === true;

    return !complexColumnIds.includes(columnId as ColumnKey) &&
           !complexColumnIds.includes(columnId.toLowerCase()) &&
           !isSubColumn;
  });

  return data.map((row) => {
    const rowData = {};

    filteredColumns.forEach((column) => {
      const columnId = column.id;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accessorKey = (column as any).accessorKey;
      const key = accessorKey || columnId;

      if (!key) return;

      let value = '';
      if (key in formatters) {
        value = formatters[key](row, key);
      } else if (key.includes('pfaTotalCost') || key.includes('briefPayment')) {
        value = formatCurrency(row[key]);
      } else if (key.includes('deliverableLiveLink')) {
        const link = row[key];
        value = link !== '-' ? link : '';
      } else {
        value = getFieldValue(row, key);
      }

      rowData[columnId] = value;
    });

    return rowData;
  });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function exportToCSV(data: any[], columns: ColumnDef<FlexMemberTableRow, unknown>[], filename: string) {
  const csvData = prepareExportData(data, columns);

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(csvData);
}
