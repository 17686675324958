import React, { useMemo, useState } from 'react';
import {
 Drawer, Input, List,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  TOfferMember,
} from '../MemberTable/types';

interface DeletedMembersDrawerProps {
  isVisible: boolean;
  onClose: () => void;
  deletedMembers: readonly TOfferMember[];
}

const DeletedMembersDrawer: React.FC<DeletedMembersDrawerProps> = ({
  isVisible,
  onClose,
  deletedMembers,
}) => {
  const [searchDeletedMember, setSearchDeletedMember] = useState<string>('');

  const filteredDeletedMembers = useMemo(() => deletedMembers
      .filter((member) => member.name)
      .filter((member) => member.name.toLowerCase().includes(searchDeletedMember.toLowerCase())), [deletedMembers, searchDeletedMember]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <Drawer title="Removed Members List" placement="right" onClose={onClose} open={isVisible}>
      <Input
        placeholder="Search Removed Members"
        value={searchDeletedMember}
        onChange={(e) => setSearchDeletedMember(e.target.value)}
        style={{ marginBottom: '16px' }}
        prefix={<SearchOutlined />}
      />

      {/* Deleted Members List */}
      <List
        dataSource={filteredDeletedMembers}
        renderItem={(member) => {
          const name = member.name;
          const randomColor = getRandomColor();
          return (
            <List.Item>
              <div className="flex items-center">
                {member.imageUrl ? (
                  <img src={member.imageUrl} alt={name} className="w-10 h-10 rounded-full object-cover mr-3" />
                ) : (
                  <div
                    className="w-10 h-10 rounded-full flex items-center justify-center text-white font-bold mr-3"
                    style={{ backgroundColor: randomColor }}
                  >
                    {name
                      .split(' ')
                      .map((word) => word[0].toUpperCase())
                      .join('')}
                  </div>
                )}

                <span className="text-lg font-semibold">{name}</span>
              </div>
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
};

export default DeletedMembersDrawer;
