import {
  Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@frontend/shadcn/components/ui/accordion';
import React from 'react';
import { Separator } from '@frontend/shadcn/components/ui/separator';
// import { ArrowUpRightFromSquare } from 'lucide-react';
import SettingsContent from './components/SettingsContent';
import FeaturedContentProduct from './components/FeaturedContent';
import { useLandingPageContext } from '../../../../context/LandingPageContext';

const FeaturedContent: React.FC = () => {
  const { landingPageData } = useLandingPageContext();
  const accordionItems = [
    {
      value: 'settings',
      title: 'Settings',
      content: <SettingsContent />,
    },
  ];

  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="flex flex-col p-4 rounded-xl">
        <h1 className="text-[16px] leading-7 font-medium text-grey-6 mb-0"> Featured Content </h1>
        <div className="flex text-sm flex-col">
          <span className="text-[#a2adb9] text-sm font-medium">
            You may feature up to 6 pieces of content, including creator-specific content.
          </span>
          {/* TODO: Add help center link */}
          {/* <a href="#" className="inline-flex items-center gap-1 text-neutralgrey-6 underline hover:opacity-90">
            Help Center <ArrowUpRightFromSquare className="h-4 w-4" />
          </a> */}
        </div>
      </div>
      <FeaturedContentProduct />
      {landingPageData.featuredContent.isFeaturedContentEnabled && (accordionItems.map((item, index) => (
        <React.Fragment key={item.value}>
          <Accordion type="single" collapsible className="w-full py-0">
            <AccordionItem value={item.value} className="border-0">
              <AccordionTrigger className="bg-neutral-color-palettegray-1 rounded-2xl px-4 hover:no-underline py-2">
                <div className="flex items-center gap-2">
                  <span className="text-grey-6 text-base font-medium">{item.title}</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>{item.content}</AccordionContent>
            </AccordionItem>
          </Accordion>
          {index < accordionItems.length - 1 && <Separator className="w-full h-0.5" />}
        </React.Fragment>
      )))}
    </div>
  );
};

export default FeaturedContent;
