import * as React from 'react';
import {
 filter, keys, map, mapValues,
} from 'lodash';

import {
 TNetworkIconType, NetworkIcon, NetworkSvgIcon, Checkbox, Popover,
 IconSize,
} from '@components';

import cx from 'classnames';
import styles from './Filters.scss';

const { useRef, useState } = React;

export const networkReducer = (state, action) => {
  const localState = state.networks;
  switch (action.type) {
    case 'CLEAR_ALL_NETWORKS': {
      const deselected = mapValues(localState.networks, () => false);
      return { networks: deselected, selected: 0 };
    }
    case 'TOGGLE_NETWORK': {
      return {
        ...localState,
        networks: {
          ...localState.networks,
          [action.payload]: !localState.networks[action.payload],
        },
        selected: localState.networks[action.payload]
          ? localState.selected - 1
          : localState.selected + 1,
      };
    }
    default: {
      return localState;
    }
  }
};

type TNetworkKey =
  | 'instagram'
  | 'instagram_reel'
  | 'instagram_video'
  | 'instagram_story'
  | 'pinterest'
  | 'youtube'
  | 'youtube_short'
  | 'twitter'
  | 'tiktok';

export const initialNetworkState: {
  [key in TNetworkKey]: boolean
} = {
  instagram: false,
  instagram_reel: false,
  instagram_video: false,
  instagram_story: false,
  pinterest: false,
  youtube: false,
  youtube_short: false,
  twitter: false,
  tiktok: false,
};

const NETWORK_CONFIG: {
  [key in TNetworkKey]: {
    id: string;
    icon: TNetworkIconType;
    label: string;
  };
} = {
  instagram: {
    id: 'instagram',
    icon: 'instagram',
    label: 'Instagram Posts',
  },
  instagram_reel: {
    id: 'instagram_reel',
    icon: 'instagram',
    label: 'Instagram Reels',
  },
  instagram_video: {
    id: 'instagram_video',
    icon: 'instagram',
    label: 'Instagram Videos',
  },
  instagram_story: {
    id: 'instagram_story',
    icon: 'instagram',
    label: 'Instagram Stories',
  },
  tiktok: {
    id: 'tiktok',
    icon: 'tiktok',
    label: 'TikTok',
  },
  pinterest: {
    id: 'pinterest',
    icon: 'pinterest',
    label: 'Pinterest',
  },
  youtube: {
    id: 'youtube',
    icon: 'youtube',
    label: 'YouTube Videos',
  },
  youtube_short: {
    id: 'youtube_short',
    icon: 'youtube',
    label: 'YouTube Shorts',
  },
  twitter: {
    id: 'twitter',
    icon: 'twitter',
    label: 'Twitter',
  },
};

export const NetworkFilter = ({ filters, setFilters, isDisabled, tempYoutubeDemoAccountMode=false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  // const { filters, setFilters } = useAnalyze();

  const size = tempYoutubeDemoAccountMode ? 28 : IconSize.SMALL;

  const { networks } = filters.networks;

  const toggleValue = (value) => {
    setFilters({ type: 'TOGGLE_NETWORK', payload: value });
  };
  const justSelected = filter(networks, (s) => s === true);
  const clearAll = () => {
    setFilters({ type: 'CLEAR_ALL_NETWORKS' });
  };

  const renderNetwork = (networkId: string, iconName: TNetworkIconType, label: string) => (
    <Checkbox
      className={styles.MenuItem}
      checked={networks[networkId]}
      onClick={() => toggleValue(networkId)}
      label={(
        <div className={styles.NetworkFilterLabelContainer}>
          <div className={cx(styles.NetworkFilterIcons, styles[iconName] || {})}>
          <NetworkIcon identifier={iconName} size={size} />
          </div>
          <span className={styles.NetworkFilterIconLabels}>
            { label }
          </span>
        </div>
        )}
    />
    );

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {justSelected.length === 0 ? (
            <NetworkSvgIcon size={16} />
          ) : (
            justSelected.length
          )}
        </span>
        <span className={styles.filterText}>
          {justSelected.length === keys(networks).length ? 'All' : ''}
          {' '}
          Networks
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <ul className={styles.MenuList}>
          <li className={styles.MenuItem} onClick={() => clearAll()}>
            Clear All
          </li>
          {
            map(NETWORK_CONFIG, (network) => (
              <li>
                {
                    renderNetwork(
                      network.id,
                      network.icon,
                      network.label,
                    )
                  }
              </li>
              ))
          }
        </ul>
      </Popover>
    </>
  );
};
