import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import { Provider as UrqlProvider } from 'urql';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import '@revfluence/fresh/dist/aui.min.css';
import 'simplebar/dist/simplebar.min.css';
import 'reactflow/dist/style.css';

import { OverlaySpinner } from '@components';
import { Home, GuestHome, SettingsContextProvider } from '@frontend/app/containers';
import { useAuth } from '@frontend/context/authContext';
import { ClientContextProvider, ResourceProvider, ProjectContextProvider } from '@frontend/app/context';
import { EventProvider } from '@frontend/app/context/EventContext';
import { useAppContext } from '@frontend/context/AppContext';
import useClient from '@frontend/applications/Social/useClient';
import { Toaster } from '@frontend/shadcn/components/ui/toaster';
import { ClientFeatureProvider } from '@frontend/context/ClientFeatureContext';
import { useGetClientAllFeatures } from '@frontend/app/hooks/useGetClientAllFeatures';
import PostHogPageviewTracker from '@frontend/app/context/PostHogPageviewTracker';
import { LoginView } from './LoginView';
import styles from './App.scss';

/**
 * @type {React.FunctionComponent}
 */

const ConnectedApp = () => {
  const { isGuest, clientInfo } = useAuth();
  const clientFeatures = useGetClientAllFeatures(clientInfo?.id);
  return (
    <ClientFeatureProvider clientFeatures={clientFeatures}>
      {!isGuest && <Home />}
      {isGuest && <GuestHome />}
    </ClientFeatureProvider>
  );
};

export const App: React.FunctionComponent = React.memo(() => {
  const { loading, isAuthenticated } = useAuth();
  const { apolloClient } = useAppContext();
  const socialClient = useClient();
  const queryClient = new QueryClient();
  return (
    <div className={styles.App}>
      <Toaster />
      {loading && <OverlaySpinner />}
      {!loading && !isAuthenticated && <LoginView />}
      {apolloClient && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        <ApolloHooksProvider client={apolloClient}>
          <UrqlProvider value={socialClient}>
            <QueryClientProvider client={queryClient}>
              <ClientContextProvider>
                <ResourceProvider>
                  <EventProvider>
                    <SettingsContextProvider>
                      <ProjectContextProvider>
                        <ConnectedApp />
                        <PostHogPageviewTracker />
                      </ProjectContextProvider>
                    </SettingsContextProvider>
                  </EventProvider>
                </ResourceProvider>
              </ClientContextProvider>
            </QueryClientProvider>
          </UrqlProvider>
        </ApolloHooksProvider>
      )}
    </div>
  );
});

App.displayName = 'App';
