import { useState } from 'react';
import { SortingState } from '@tanstack/react-table';
import { GetSocialAnalyticsPostsQuery, PostInsights } from '@frontend/gql/social/graphql';
import { GraphQLQueryVariables } from '../types';
import { PostTableColumn } from '../components/table/config/defaultPostTableColumns';

interface usePostsTabProps {
  queryVariables: GraphQLQueryVariables,
  fetching: boolean,
  data: GetSocialAnalyticsPostsQuery,
  setSelectedColumns: React.Dispatch<React.SetStateAction<PostTableColumn<PostInsights>[]>>,
  selectedColumns: PostTableColumn<PostInsights>[],
}

export const usePostsTab = ({
    queryVariables,
    fetching,
    data,
    setSelectedColumns,
    selectedColumns,
}: usePostsTabProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPartialLoading, setIsPartialLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    {
        desc: true,
        id: 'total.engagements',
    },
  ]);

  const handleSortingChange = (sorting: SortingState) => {
    setCurrentPage(0);
    setSorting(sorting);
  };

  return {
    postsTabProps: {
        data,
        isLoading: fetching,
        isPartialLoading: fetching && isPartialLoading,
        setIsPartialLoading,
        currentPage,
        setCurrentPage,
        sorting,
        setSorting: handleSortingChange,
        selectedColumns,
        setSelectedColumns,
        queryVariables,
    }
  };
};
