import * as React from 'react';
import { Col, Row } from 'antd';
import cx from 'classnames';
import { Typography, Space, Divider } from '@revfluence/fresh';
import styles from './Layout.scss';
import { IAdAccountsTableProps } from '../containers/AdAccountsTable';
import { IAllowlistingTableProps } from '../containers/AllowlistingTable';

interface ILayoutProps {
  MetaSettingsInfoCards: React.ReactNode;
  InstagramAccountsTable: React.ReactNode;
  AdAccountsTable: React.ReactElement<IAdAccountsTableProps>;
  AllowListingTable: React.ReactElement<IAllowlistingTableProps>;
}

const DividerTables: React.FC<{ display: boolean }> = ({ display }) => {
  if (display) {
    return (
      <Col xs={24}>
        <Divider />
      </Col>
    );
  }
  return null;
};

export const Layout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    AdAccountsTable,
    AllowListingTable,
    InstagramAccountsTable,
    MetaSettingsInfoCards,
  } = props;
  const showAdAccountsTableDivider = !AdAccountsTable.props.hidden;
  const showBusinessTableDivider = !AllowListingTable.props.hidden;
  return (
    <Space size={32} direction="vertical" style={{ paddingBottom: 40 }}>
      <Space size={24} direction="vertical" align="start">
        <Space size={16} direction="vertical" align="start">
          <Typography.Title level={3} className={styles.noMargin}>Instagram (Meta)</Typography.Title>
          <Typography.Title level={5} className={cx(styles.noMargin, styles.regular)}>
            To connect an Instagram account, you need to be the account owner or have admin permission for your Instagram account's Facebook page.
            {' '}
            <Typography.Link target="_blank" href="https://intercom.help/aspireiq_elevate/en/articles/8104130-how-to-connect-your-brand-s-instagram-account">
              Learn more
            </Typography.Link>
            {' '}
            about connecting Instagram.
          </Typography.Title>
        </Space>
        {MetaSettingsInfoCards}
      </Space>
      <Row>
        <Col xs={24}>
          {InstagramAccountsTable}
        </Col>
        <DividerTables display={showAdAccountsTableDivider} />
        <Col xs={24}>
          {AdAccountsTable}
        </Col>
        <DividerTables display={showBusinessTableDivider} />
        <Col xs={24}>
          {AllowListingTable}
        </Col>
      </Row>
    </Space>
  );
};
