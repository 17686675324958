import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { includes } from 'lodash';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { FIXED_FIELDS } from './EditProjectColumns';

const FieldOption = ({
  field,
  selectedSource,
  selectedFieldsBySource,
  handleColumnSelection,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasSubColumns = field.value?.subColumns?.length > 0;
  const subColumnsCount = field.value?.subColumns?.length || 0;

  const isFieldSelected = selectedFieldsBySource.get(selectedSource)?.has(field.value.field);
  const hasSelectedChildren = hasSubColumns && field.value.subColumns.some(
    (subColumn) => selectedFieldsBySource.get(selectedSource)?.has(subColumn.field),
  );

  // Auto-expand when parent is selected or any child is selected
  useEffect(() => {
    if (isFieldSelected || hasSelectedChildren) {
      setIsExpanded(true);
    }
  }, [isFieldSelected, hasSelectedChildren]);

  const handleParentSelection = () => {
    handleColumnSelection(field.value);

    if (hasSubColumns) {
      const willBeSelected = !isFieldSelected;
      field.value.subColumns.forEach((subColumn) => {
        const isSubColumnSelected = selectedFieldsBySource.get(selectedSource)?.has(subColumn.field);
        if (willBeSelected !== isSubColumnSelected) {
          handleColumnSelection(subColumn);
        }
      });
    }
    // Auto-expand when selecting parent
    if (!isFieldSelected) {
      setIsExpanded(true);
    }
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  if (!hasSubColumns) {
    return (
      <SingleFieldCheckbox
        field={field}
        selectedSource={selectedSource}
        selectedFieldsBySource={selectedFieldsBySource}
        handleColumnSelection={handleColumnSelection}
      />
    );
  }

  return (
    <div className="pb-2">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Checkbox
            className="w-5 h-5"
            onChange={handleParentSelection}
            checked={isFieldSelected}
          />
          <span
            className="text-sm font-medium cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {field.label}
            {
              subColumnsCount > 0 && (
                <span className="text-gray-600">
                  {` (${subColumnsCount})`}
                </span>
              )
            }
          </span>
        </div>
        <button
          type="button"
          onClick={handleExpandClick}
          className="p-1 hover:bg-gray-100 rounded-full"
        >
          {isExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </button>
      </div>

      {isExpanded && (
        <div className="mt-2 pl-7 space-y-1">
          {field.value.subColumns.map((subColumn, subIndex) => (
            <SubColumnCheckbox
              key={subIndex}
              subColumn={subColumn}
              selectedSource={selectedSource}
              selectedFieldsBySource={selectedFieldsBySource}
              handleColumnSelection={handleColumnSelection}
              disabled={!isFieldSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const SingleFieldCheckbox = ({
  field,
  selectedSource,
  selectedFieldsBySource,
  handleColumnSelection,
}) => (
  <div className="pb-2">
    <label className="flex items-center space-x-2">
      <Checkbox
        className="w-5 h-5"
        onChange={() => handleColumnSelection(field.value)}
        checked={selectedFieldsBySource.get(selectedSource)?.has(field.value.field)}
        disabled={includes([...FIXED_FIELDS, 'work_item__cta'], field.value.field)}
      />
      <span className="text-sm font-medium">{field.label}</span>
    </label>
  </div>
);

const SubColumnCheckbox = ({
  subColumn,
  selectedSource,
  selectedFieldsBySource,
  handleColumnSelection,
  disabled,
}) => (
  <label className="flex items-center space-x-2">
    <Checkbox
      className="w-5 h-5"
      onChange={() => handleColumnSelection(subColumn)}
      checked={selectedFieldsBySource.get(selectedSource)?.has(subColumn.field)}
      disabled={disabled}
    />
    <span className={`text-sm ${disabled ? 'text-gray-400' : ''}`}>
      {subColumn?.metaData?.labelName}
    </span>
  </label>
);

export { FieldOption, SingleFieldCheckbox, SubColumnCheckbox };
