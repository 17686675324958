import React from 'react';
import { CardHeader, CardTitle, CardContent, CardFooter } from '@frontend/shadcn/components/ui/card';
import { cn } from '@frontend/shadcn/lib/utils';

export const GraphCardHeader = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardHeader>>(
  ({ className, ...props }, ref) => <CardHeader ref={ref} className={cn('pb-2', className)} {...props} />,
);
GraphCardHeader.displayName = 'GraphCardHeader';

export const GraphCardHeaderRow = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center justify-between w-full', className)} {...props} />
  ),
);
GraphCardHeaderRow.displayName = 'GraphCardHeaderRow';

export const GraphCardTitle = React.forwardRef<HTMLHeadingElement, React.ComponentProps<typeof CardTitle>>(
  ({ className, ...props }, ref) => (
    <CardTitle ref={ref} className={cn('text-base font-medium', className)} {...props} />
  ),
);
GraphCardTitle.displayName = 'GraphCardTitle';

export const GraphCardContent = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardContent>>(
  ({ className, ...props }, ref) => <CardContent ref={ref} className={cn('', className)} {...props} />,
);
GraphCardContent.displayName = 'GraphCardContent';

export const GraphCardFooter = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardFooter>>(
  ({ className, ...props }, ref) => (
    <CardFooter ref={ref} className={cn('text-sm text-gray-500', className)} {...props} />
  ),
);
GraphCardFooter.displayName = 'GraphCardFooter';
