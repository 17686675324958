import React, { useEffect, useMemo } from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { PlusIcon, XmarkIcon, CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { GripVerticalIcon } from '@revfluence/fresh-icons/solid/esm';
import {
 DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { cn } from '@frontend/shadcn/lib/utils';
import { LoadSpinner } from '@frontend/app/components';
import {
 useGetAllWorkletsQuery, useGetSpecificationTemplateByNameQuery, useClientFeatureEnabled, useGetInstalledApplicationIds,
 useGetCountsForProjectQuery,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { Input } from '@frontend/shadcn/components/ui/input';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { TrashIcon } from '@revfluence/fresh-icons/regular/esm';
import { message, Spinner } from '@revfluence/fresh';
import { CONTRACT_APP_ID } from '@frontend/app/constants/applicationIds';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
 cloneDeep, reduce,
 sortBy,
} from 'lodash';
import { useMessagingContext } from '@frontend/hooks';
import { CAMPAIGN_TO_TEMPLATE_MAP, CustomWorkletTemplate, WorkletTypes } from './constants';
import { useCampaign, TWorkletWithoutTypename } from './CampaignContext';
import { WorkletSpecKey } from '../utils';
import { NewDeleteStageModal } from '../ProjectDetailsForm/NewDeleteStageModal';
import { useDeleteStage } from '../hooks/useDeleteStage';
import { hasWorkletsChanged } from './utils';
import { Task } from '../constants';

interface Worklet {
  specKey: string;
  specTitle: string;
  specLogoURL: string;
  specURI: string;
  isEditing?: boolean;
  isCustom?: boolean;
  viewed?: boolean;
  startTime?: string;
}

interface CampaignWorkletsListProps {
  defaultWorklets?: TWorkletWithoutTypename[];
  parentSpecKey?: string;
  projectId?: number;
}
export const CampaignWorkletsList: React.FC<CampaignWorkletsListProps> = ({
  defaultWorklets,
  parentSpecKey,
  projectId,
}) => {
  const { templateName, setWorklets: setContextWorklets } = useCampaign();
  const isPfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const installedApps = useGetInstalledApplicationIds();

  const mode = projectId ? 'edit' : 'add';

  const { showSuccessMessage, showErrorMessage } = useMessagingContext();

  const {
    modalVisible: deleteStageModalVisible,
    status: deletingStatus,
    completedPercent: deletingPercentage,
    totalCount: currentStageMemberCount,
    handleRemoveExistingWorklet,
    hideDeleteStageModal,
    skipWorklet,
  } = useDeleteStage(parentSpecKey, projectId, showSuccessMessage, showErrorMessage);

  const {
    data: {
      counts = undefined,
    } = {},
    loading: isCountsLoading,
    refetch: refetchCounts,
  } = useGetCountsForProjectQuery({
    variables: { projectId },
    skip: !projectId,
    fetchPolicy: 'network-only',
  });

  // Map the campaign name to template name for the API
  const apiTemplateName = templateName ? CAMPAIGN_TO_TEMPLATE_MAP[templateName] : undefined;

  // Get template and its worklet spec keys
  const { data: templateData } = useGetSpecificationTemplateByNameQuery({
    variables: { templateName: apiTemplateName },
    skip: !apiTemplateName || !!defaultWorklets,
  });

  const loadedTemplate = useMemo(() => {
    const result = templateData?.template;
    if (!isPfaV2Enabled || !result) {
      return result;
    }
    const cpsIndex = result.workletSpecKeys.indexOf(WorkletSpecKey.PFACreatorProductSelection);
    if (cpsIndex === -1) {
      return result;
    }
    const resultWithPfaV2 = cloneDeep(result);
    resultWithPfaV2.workletSpecKeys.splice(cpsIndex, 1, WorkletSpecKey.PFACreatorProductSelectionV2);
    return resultWithPfaV2;
  }, [templateData, isPfaV2Enabled]);

  // Get worklets based on template
  const { data: workletData, loading: isWorkletsLoading } = useGetAllWorkletsQuery({
    variables: {
      specKeys: loadedTemplate?.workletSpecKeys || [],
    },
    skip: !loadedTemplate?.workletSpecKeys,
  });

  // Get all available worklet types
  const { data: allWorkletTypes } = useGetAllWorkletsQuery();

  const sortedWorklets = useMemo(() => {
    return sortBy(allWorkletTypes?.worklets, 'specTitle');
  }, [allWorkletTypes]);

  const [worklets, setWorklets] = React.useState<Worklet[]>(defaultWorklets || []);

  // Calculate member counts
  const countsByWorklet = useMemo(() => reduce(worklets, (prev, worklet) => {
    if (!counts || !worklet.specURI) {
      return {
        ...prev,
        [worklet.specKey]: 0
      };
    }

    const workletCounts = counts[worklet.specURI];

    if (!workletCounts) {
      return {
        ...prev,
        [worklet.specKey]: 0
      };
    }

    // Check if there's a direct count under Task.All
    if (workletCounts[Task.All]?.total !== undefined) {
      return {
        ...prev,
        [worklet.specKey]: workletCounts[Task.All].total
      };
    }

    // If not, look for task-specific counts and use the first one with a total
    // This handles cases like SendBrandProductCatalogWorkletSpecification
    const taskKeys = Object.keys(workletCounts);
    for (const taskKey of taskKeys) {
      if (workletCounts[taskKey]?.total !== undefined) {
        return {
          ...prev,
          [worklet.specKey]: workletCounts[taskKey].total
        };
      }
    }

    // If no counts found, return 0
    return {
      ...prev,
      [worklet.specKey]: 0
    };
  }, {}), [worklets, counts]);

  // Update worklets when template changes
  React.useEffect(() => {
    if (mode === 'add') {
      if (!workletData?.worklets) {
        setWorklets([]);
        // Get the current hasUnsavedChanges value to preserve it
        setContextWorklets([]);
        return;
      }
      // Initialize worklets with editing state
      const initializedWorklets = workletData.worklets.map((worklet) => ({
        ...worklet,
        isEditing: false,
        isCustom: false,
      }));
      setWorklets(initializedWorklets);
      // Update context with worklet spec keys
      setContextWorklets(initializedWorklets);
    }
  }, [workletData?.worklets, templateName, loadedTemplate?.templateLogoURL, mode, setContextWorklets]);

  React.useEffect(() => {
    if (defaultWorklets?.length) {
      setWorklets(defaultWorklets);
      setContextWorklets(defaultWorklets, false);
    }
  }, [defaultWorklets, setContextWorklets]);

  useEffect(() => {
    refetchCounts();
  }, [refetchCounts, defaultWorklets]);

  const handleAddWorklet = () => {
    setIsAddingNew(true);
    setWorklets([...worklets, {
 specKey: null, specTitle: '', specLogoURL: '', specURI: '', isEditing: true,
}]);
  };

  const handleWorkletTypeSelect = (index: number, type: string) => {
    // Check if required app is installed
    if (type === WorkletTypes.CONTRACT && !installedApps[CONTRACT_APP_ID]) {
      // Show error message
      message.info('Contract app is not installed on your account. Please contact support to get started.', 5);
      return;
    }
    const selectedWorklet = allWorkletTypes?.worklets.find((w) => w.specTitle === type)
                          || (type === WorkletTypes.CUSTOM ? CustomWorkletTemplate : null);

    if (!selectedWorklet) return;

    const updatedWorklets = [...worklets];
    updatedWorklets[index] = {
      ...selectedWorklet,
      isEditing: true,
      isCustom: type === WorkletTypes.CUSTOM,
      specKey: type === WorkletTypes.CUSTOM ? null : selectedWorklet.specKey,
      specTitle: type === WorkletTypes.CUSTOM ? updatedWorklets[index].specTitle : selectedWorklet.specTitle,
    };

    setWorklets(updatedWorklets);
  };

  const handleSaveWorklet = (index: number) => {
    const updatedWorklets = [...worklets];
    const worklet = updatedWorklets[index];

    if (worklet.isCustom && !worklet.specTitle?.trim()) {
      message.error('Custom stage name is required');
      return;
    }

    updatedWorklets[index] = {
      ...worklet,
      isEditing: false,
    };

    setWorklets(updatedWorklets);

    const hasUnsavedChanges = hasWorkletsChanged(updatedWorklets, defaultWorklets || []);

    setContextWorklets(updatedWorklets, hasUnsavedChanges);
    setIsAddingNew(false);
  };

  const handleCancelEdit = (index: number) => {
    if (isAddingNew) {
      const newWorklets = [...worklets];
      newWorklets.pop();
      setWorklets(newWorklets);
      setContextWorklets(newWorklets, true);
    } else {
      const updatedWorklets = [...worklets];
      updatedWorklets[index].isEditing = false;
      setWorklets(updatedWorklets);
      setContextWorklets(updatedWorklets, true);
    }
    setIsAddingNew(false);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(worklets);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setWorklets(items);
    setContextWorklets(items, true);
  };

  const handleEditClick = (index: number) => {
    const updatedWorklets = [...worklets];
    updatedWorklets[index] = {
      ...updatedWorklets[index],
      isEditing: true,
    };
    setWorklets(updatedWorklets);
  };

  const handleDeleteWorklet = (index: number) => {
    if (mode === 'edit') {
      handleRemoveExistingWorklet(
        worklets[index].specKey,
        countsByWorklet[worklets[index].specKey],
      );
      return;
    }

    const newWorklets = [...worklets];
    newWorklets.splice(index, 1);
    setWorklets(newWorklets);
    setContextWorklets(newWorklets, true);
    setIsAddingNew(false);
  };

  const renderWorkletCard = (worklet: Worklet, index: number, dragHandleProps: DraggableProvidedDragHandleProps | null) => {
    const existingWorklet = defaultWorklets?.find((w) => w.specKey === worklet.specKey);

    const isCustomWorklet = worklet.isCustom || worklet.specURI.startsWith('CustomWorklet-');

    if (worklet.isEditing) {
      return (
        <div className="flex items-center gap-4">
          {mode === 'add' && (
            <div {...dragHandleProps}>
              <GripVerticalIcon className="text-border" />
            </div>
          )}
          <div className="flex items-end gap-4 flex-1">
            <div className="flex-1">
              <div className="flex flex-col gap-2">
                <span className="text-xs">Workflow Stage</span>
                <Select
                  value={isCustomWorklet ? WorkletTypes.CUSTOM : worklet.specTitle}
                  onValueChange={(value) => handleWorkletTypeSelect(index, value)}
                  disabled={mode === 'edit' && !!(existingWorklet && !worklet.isCustom)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select stage type" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortedWorklets?.map((type) => (
                      <SelectItem
                        key={type.specKey}
                        value={type.specTitle}
                        disabled={!!worklets.find((w) => w.specKey === type.specKey)}
                      >
                        <div className="flex items-center gap-2">
                          {type.specLogoURL && (
                          <img
                            src={type.specLogoURL}
                            alt=""
                            className="h-4 w-4 object-contain"
                          />
                      )}
                          <span>{type.specTitle}</span>
                        </div>
                      </SelectItem>
                ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {isCustomWorklet && (
            <div className="flex-1">
              <div className="flex flex-col gap-2">
                <span className="text-xs">Stage Name</span>
                <Input
                  placeholder="Enter stage name"
                  value={worklet.specTitle || ''}
                  onChange={(e) => {
                    const updatedWorklets = [...worklets];
                    updatedWorklets[index].specTitle = e.target.value;
                    setWorklets(updatedWorklets);
                  }}
                  disabled={mode === 'edit' && !!(existingWorklet && !worklet.isCustom)}
                />
              </div>
            </div>
          )}

            <div className="flex items-center gap-2 mb-[5px]">
              {!!existingWorklet && (
                <Button
                  variant="destructiveOutline"
                  size="smallIcon"
                  onClick={() => handleDeleteWorklet(index)}
                >
                  <TrashIcon />
                </Button>
              )}

              {!existingWorklet && (
                <Button variant="outline" size="smallIcon" onClick={() => handleCancelEdit(index)}>
                  <XmarkIcon />
                </Button>
              )}
              <Button
                size="smallIcon"
                onClick={() => handleSaveWorklet(index)}
                disabled={worklet.isCustom ? !worklet.specTitle?.trim() : !worklet.specKey}
              >
                <CheckIcon />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-4">
        {mode === 'add' && (
          <div {...dragHandleProps}>
            <GripVerticalIcon className="text-border" />
          </div>
        )}
        <div className="flex items-center gap-2 flex-1">
          <img
            src={worklet.specLogoURL || 'https://storage.googleapis.com/aspirex-static-files/new_logo.png'}
            alt=""
            className="w-8 h-8 rounded"
          />
          <span>{worklet.specTitle}</span>
        </div>
        <div className="flex items-center gap-2">
          {mode === 'edit' && (isCountsLoading ? (
            <Spinner size="small" />
          ) : (
            <span className="text-xs text-primaryMuted">
              {countsByWorklet[worklet.specKey] || 0}
              {' '}
              members
            </span>
          ))}
          <Button
            variant="link"
            onClick={() => handleEditClick(index)}
          >
            Edit
          </Button>
        </div>
      </div>
    );
  };

  if (isWorkletsLoading) {
    return <LoadSpinner />;
  }

  return (
    <div className="flex flex-col gap-4">
      <Button
        variant="outline"
        className="ml-auto"
        onClick={handleAddWorklet}
        disabled={isAddingNew}
      >
        <PlusIcon className="mr-2" />
        Add stage
      </Button>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="worklets">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
              {worklets.map((worklet, index) => (
                <Draggable
                  key={worklet.specKey || `new-${index}`}
                  draggableId={worklet.specKey || `new-${index}`}
                  index={index}
                  isDragDisabled={worklet.isEditing}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={cn(
                        'p-4 border border-border rounded-2xl',
                        snapshot.isDragging && 'opacity-50',
                        'hover:border-primary hover:shadow-lg transition-all',
                      )}
                    >
                      {renderWorkletCard(worklet, index, provided.dragHandleProps)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <NewDeleteStageModal
        open={deleteStageModalVisible}
        count={currentStageMemberCount}
        status={deletingStatus}
        percentage={deletingPercentage}
        onRequestClose={hideDeleteStageModal}
        onDelete={skipWorklet}
        isFlexibleSpec
      />
    </div>
  );
};
