import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import {
  isFunction, isNull, trimEnd, isNumber, isEmpty,
} from 'lodash';

import {
  Button, Row, Col, Typography, Space, Tooltip,
} from '@revfluence/fresh';
import { LinkSimpleIcon } from '@revfluence/fresh-icons/regular/esm';
import { LinkIcon, PenIcon } from '@revfluence/fresh-icons/solid/esm';
import { ConversionTrackingModal } from '@affiliates/components/ConversionTrackingModal/ConversionTrackingModal';
import {
  OFFER_STATUS,
  OFFER_CONVERSION_TYPE,
  OFFER_PAYOUT_TYPE,
  OFFER_PAYOUT_TYPE_PROMO,
  OFFER_SOURCE,
  OFFER_TRACKING_TYPE,
  OFFER_PRICE_RULE_TYPE,
} from '@affiliates/types/globalTypes';
import { DataFormat, formatValue } from '@affiliates/utils';
import { useLocation } from 'react-router-dom';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { ImageUpload } from './ImageUpload';
import { usePayoutLabel, usePayoutLabels } from './hooks';

import styles from './OfferSummaryCard.scss';
import { CustomUTMField, IPayoutVariant } from '../OfferForm';

const { Text, Link, Paragraph } = Typography;

const { useCallback, useMemo, useState } = React;

enum Status {
  Active,
  Expired,
  Draft,
  Paused,
  Archived,
}

interface IBaseProps {
  className?: string;
  description: string;
  expired: boolean;
  flatPayout: number | null;
  imageUrl: string | null;
  offerFormVariant: boolean;
  onChangeImage?: (file: File) => void;
  onClickEdit?: () => void;
  payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO | null;
  percentPayout: number | null;
  source: OFFER_SOURCE;
  status: OFFER_STATUS | null;
  title: string;
  offerForm?: boolean;
  isNewFlow?: boolean;
  payoutOptions?: IPayoutVariant[]
  isOfferArchived?: boolean;
  isReadOnly?: boolean;
}

interface IAffiliateLinksProps extends IBaseProps {
  conversionTrackingType: OFFER_TRACKING_TYPE | null;
  conversionType: OFFER_CONVERSION_TYPE | null;
  expirationDate: Date | null;
  pixelCode: string | null;
  postbackUrl: string | null;
  source: OFFER_SOURCE.TUNE;
  url: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmTerm?: string | null;
  customUTMParameters?: CustomUTMField[];
  isAdvanceUrlEnable?: boolean;
}

interface IShopifyPromoProps extends IBaseProps {
  priceRuleAmount: number | null;
  priceRuleType: OFFER_PRICE_RULE_TYPE | null;
  source: OFFER_SOURCE.SHOPIFY;
  startDate: Date | null;
  endDate: Date | null;
}

type TProps = IAffiliateLinksProps | IShopifyPromoProps;

const isAffiliateLinksOffer = (props: TProps): props is IAffiliateLinksProps => (
  props.source === OFFER_SOURCE.TUNE
);
const getOfferStatus = (props: TProps): Status => {
  if (props.isOfferArchived) {
    return Status.Archived;
  } else if (props.expired) {
    return Status.Expired;
  }
  switch (props.status) {
    case OFFER_STATUS.ACTIVE:
      return Status.Active;
    case OFFER_STATUS.PAUSED:
      return Status.Paused;
    default:
      return Status.Draft;
  }
};

export const RawOfferSummaryCard: React.FC<Readonly<TProps>> = React.memo((props) => {
  const {
    className,
    description,
    flatPayout,
    imageUrl,
    offerFormVariant,
    onChangeImage,
    onClickEdit,
    payoutType,
    percentPayout,
    source,
    title,
    offerForm,
    isNewFlow,
    payoutOptions,
    isReadOnly = false,
  } = props;
  const payoutLabel = usePayoutLabel(
    payoutType,
    flatPayout,
    percentPayout,
    props.source === OFFER_SOURCE.TUNE ? props.conversionType : null,
  );
  const { migrateToGraphQL } = useClientFeatures();
  const mPayoutLabels = usePayoutLabels(
    payoutOptions,
    migrateToGraphQL,
  );
  const editLink = useMemo(() => {
    if (!isFunction(onClickEdit)) {
      return null;
    }
    return (
      <Button
        className={styles.editBtn}
        icon={<PenIcon className={styles.editIcon} />}
        onClick={onClickEdit}
        size="small"
        disabled={isReadOnly}
      />
    );
  }, [onClickEdit, isReadOnly]);

  const bottomCenterLabel = useMemo(() => {
    switch (props.source) {
      case OFFER_SOURCE.TUNE:
        if (isNull(props.expirationDate)) {
          return null;
        }
        return (
          <Text className={styles.expirationDate}>
            Exp
            {' '}
            {format(new Date(props.expirationDate), 'MM/dd/yyyy')}
          </Text>
        );
      case OFFER_SOURCE.SHOPIFY: {
        if (!isNumber(props.priceRuleAmount)) {
          return null;
        }
        let label = '';
        switch (props.priceRuleType) {
          case OFFER_PRICE_RULE_TYPE.AMOUNT:
            label = `$${formatValue(DataFormat.Money, props.priceRuleAmount.toString())} off Code`;
            break;
          case OFFER_PRICE_RULE_TYPE.PERCENTAGE:
            label = `${formatValue(DataFormat.None, props.priceRuleAmount.toLocaleString())}% off Code`;
            break;
        }
        return (
          <Text
            className={styles.expirationDate}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            level={5}
          >
            {label}
          </Text>
        );
      }
    }
  }, [props]);
  const offerExpiration = useMemo(() => {
    let label = '';
    let value = '';
    if (!migrateToGraphQL) return null;
    if (props.source === OFFER_SOURCE.SHOPIFY && !isNewFlow) return null;
    switch (props.source) {
      case OFFER_SOURCE.TUNE:
        label = 'Expires On: ';
        if (!isNull(props.expirationDate)) {
          value = `${format(new Date(props.expirationDate), 'MMM d, yyyy h:mm a')}`;
        }
        break;
      case OFFER_SOURCE.SHOPIFY:
        label = 'Active From: ';
        if (isNull(props.startDate)) {
          break;
        }
        value += `${format(new Date(props.startDate), 'MMM d, yyyy h:mm a')}`;
        if (!isNull(props.endDate)) {
          value += ` - ${format(new Date(props.endDate), 'MMM d, yyyy h:mm a')}`;
        }
    }
    return (
      <Row className={styles.offerExpirationContainer}>
        <Text className={styles.offerLabel}>{label}</Text>
        <Space className={styles.offerValue}>
          {value}
        </Space>
      </Row>
    );
  }, [props, migrateToGraphQL, isNewFlow]);
  const offerDiscount = useMemo(() => {
    if (!migrateToGraphQL) return null;
    switch (props.source) {
      case OFFER_SOURCE.TUNE:
        return null;
      case OFFER_SOURCE.SHOPIFY: {
        if (!isNumber(props.priceRuleAmount)) {
          return null;
        }
        let label = '';
        switch (props.priceRuleType) {
          case OFFER_PRICE_RULE_TYPE.AMOUNT:
            label = `$${formatValue(DataFormat.Money, props.priceRuleAmount.toString())} off Code`;
            break;
          case OFFER_PRICE_RULE_TYPE.PERCENTAGE:
            label = `${formatValue(DataFormat.None, props.priceRuleAmount.toLocaleString())}% off Code`;
            break;
        }
        return (
          <Text className={styles.offerDiscountConatiner}>{label}</Text>
        );
      }
    }
  }, [props, migrateToGraphQL]);
  const offerStatus = getOfferStatus(props);
  const statusTag = useMemo(() => {
    if (isNull(offerStatus)) {
      return null;
    }
    let label = 'Active';
    let className = cx(styles.tag, styles.active, migrateToGraphQL ? styles.mPayout : '');
    switch (offerStatus) {
      case Status.Draft:
        label = 'Draft';
        className = cx(styles.tag, styles.draft, migrateToGraphQL ? styles.mPayout : '');
        break;
      case Status.Expired:
        label = 'Expired';
        className = cx(styles.tag, styles.expired, migrateToGraphQL ? styles.mPayout : '');
        break;
      case Status.Paused:
        className = cx(styles.tag, styles.paused, migrateToGraphQL ? styles.mPayout : '');
        label = 'Paused';
        break;
      case Status.Archived:
        className = cx(styles.tag, styles.archived, migrateToGraphQL ? styles.mPayout : '');
        label = 'Archived';
        break;
    }
    return (
      <Text
        className={className}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        level={5}
      >
        {label}
      </Text>
    );
  }, [offerStatus, migrateToGraphQL]);

  const [instructionsVisible, updateInstructionsVisibility] = useState(false);
  const showInstructions = useCallback((e) => {
    e.preventDefault();
    updateInstructionsVisibility(true);
  }, [updateInstructionsVisibility]);
  const hideInstructions = useCallback(() => {
    updateInstructionsVisibility(false);
  }, [updateInstructionsVisibility]);

  let url = null;
  if (props.source === OFFER_SOURCE.TUNE) {
    url = props.url;
    if (props.isAdvanceUrlEnable) {
      url = generateUrl(url, (props as IAffiliateLinksProps));
      url = decodeURIComponent(url);
      if (url && url == 'null') {
        url = '';
      }
    }
  }

  const additionalDetails = useMemo(() => {
    if (!url) {
      return null;
    }
    return (
      <Text className={styles.additionalDetailsWrapper}>
        <Link className={styles.url} href={url || '#'} rel="noreferrer" target="_blank">
          <div>
            {' '}
            <LinkSimpleIcon width={30} className={styles.linkIcon} />
          </div>
          <div>
            {' '}
            <span>
              {trimEnd(url, '/') || 'https://brand.com/shop'}

            </span>
          </div>
        </Link>
        <div className={styles.instructionsWrapper}>
          <div className={styles.divider}>
            |
          </div>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Link className={(styles as any).instructions} href="#" onClick={showInstructions}>
            Instructions
          </Link>
        </div>
      </Text>
    );
  }, [showInstructions, url]);
  const offerLinkUrl = useMemo(() => {
    if (!url) {
      return null;
    }
    return (
      <div style={{ marginTop: '8px' }}>
        <Row align="middle">
          <LinkIcon width={30} className={styles.linkIcon} />
          <Text style={{ fontSize: '14px', fontWeight: '600' }}>Offer URL:</Text>
        </Row>
        <Tooltip placement="top" title={trimEnd(url, '/') || 'https://brand.com/shop'}>
          <Paragraph ellipsis={{ rows: 2 }}>
            <Link className={styles.url} href={url || '#'} rel="noreferrer" target="_blank">
              {trimEnd(url, '/') || 'https://brand.com/shop'}
            </Link>
          </Paragraph>
        </Tooltip>
      </div>
    );
  }, [url]);
  const cardClassName = cx(
    styles.OfferSummaryCard,
    className,
    {
      [styles.offerFormVariant]: offerFormVariant,
    },
  );
  const location = useLocation();
  const isOfferDetailPage = useMemo(() => location.pathname.indexOf('/details') !== -1, [location.pathname]);
  return (
    <>
      {!migrateToGraphQL
        ? (
          <div className={cardClassName}>
            <div className={styles.body}>
              <div className={styles.offerImageContainer}>
                <ImageUpload
                  defaultImageUrl={imageUrl}
                  disabled={!isFunction(onChangeImage)}
                  largeImage={offerFormVariant}
                  onChangeFile={onChangeImage}
                  source={source}
                  offerForm={offerForm}
                />
              </div>
              <Text className={styles.bodyText}>
                <div className={styles.titleWrapper}>
                  <span className={styles.title}>
                    {title || 'No Offer Name'}
                  </span>
                  {editLink}
                </div>
                <div className={styles.descriptionWrapper}>
                  {description || 'No offer description yet'}
                </div>
                {additionalDetails}
              </Text>
            </div>
            <Text className={styles.footer}>
              <div className={styles.payout}>
                {payoutLabel}
              </div>
              {bottomCenterLabel}
              {statusTag}
            </Text>

          </div>
        )
        : (
          <>
            <Row className={styles.multiplePayoutSummaryCard}>
              <Col flex={1}>
                <Row justify="space-between">
                  <Col className={styles.imageContainer}>
                    <ImageUpload
                      defaultImageUrl={imageUrl}
                      disabled={!isFunction(onChangeImage)}
                      largeImage={offerFormVariant}
                      onChangeFile={onChangeImage}
                      source={source}
                      offerForm={offerForm}
                    />
                  </Col>
                  <Col>
                    <Space>
                      {
                        (source === OFFER_SOURCE.TUNE && isOfferDetailPage) && (
                          <Button
                            onClick={showInstructions}
                            size="small"
                          >
                            Instructions
                          </Button>
                        )
                      }
                      {editLink}
                    </Space>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  style={{ marginTop: '14px' }}
                >
                  <Col span={16}>
                    <Text className={styles.offerName}>{title || 'No Offer Name'}</Text>
                    {offerDiscount && <Text className={styles.offerDiscount}>{offerDiscount}</Text>}
                  </Col>
                  <Col span={6} className={styles.offerTag}>
                    {statusTag}
                  </Col>
                </Row>
                <Row>
                  <Paragraph
                    ellipsis={{ rows: 2 }}
                    className={styles.offerDescription}
                  >
                    <Tooltip placement="top" title={description || 'No offer description yet'}>
                      {description || 'No offer description yet'}
                    </Tooltip>
                  </Paragraph>
                </Row>
                {
                  !!mPayoutLabels.length && (
                    <Row className={styles.payoutsContainer}>
                      <Text className={styles.payoutsLabel}>Payouts</Text>
                      <div className={styles.payoutsValueContainer}>
                        {mPayoutLabels.map((payoutLabel, index) => (
                          <Text className={styles.payoutsValue} key={index}>
                            {payoutLabel}
                          </Text>
                        ))}
                      </div>
                    </Row>
                  )
                }
                {
                  offerExpiration
                }
              </Col>
            </Row>
            {offerLinkUrl}
          </>
        )}
      {isAffiliateLinksOffer(props) && (
        <ConversionTrackingModal
          offerTrackingType={props.conversionTrackingType}
          onCancel={hideInstructions}
          pixelCode={props.pixelCode}
          postbackUrl={props.postbackUrl}
          visible={instructionsVisible}
        />
      )}
    </>
  );
});

export const generateUrl = (url: string, props: IAffiliateLinksProps) => {
  if (!url) {
    return null;
  }
  try {
    const dynUrl = new URL(url);
    if (!isEmpty(props.utmSource)) {
      dynUrl.searchParams.set('utm_source', props.utmSource);
    }
    if (!isEmpty(props.utmMedium)) {
      dynUrl.searchParams.set('utm_medium', props.utmMedium);
    }
    if (!isEmpty(props.utmCampaign)) {
      dynUrl.searchParams.set('utm_campaign', props.utmCampaign);
    }
    if (!isEmpty(props.utmTerm)) {
      dynUrl.searchParams.set('utm_term', props.utmTerm);
    }
    if (!isEmpty(props.utmContent)) {
      dynUrl.searchParams.set('utm_content', props.utmContent);
    }
    const customUTMParameters = props?.customUTMParameters;
    if (customUTMParameters) {
      for (const utm of customUTMParameters) {
        if (!isEmpty(utm.key) && !isEmpty(utm.value)) {
          dynUrl.searchParams.set(utm.key, utm.value);
        }
      }
    }
    return dynUrl;
  } catch {
    return null;
  }
};
