import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import React from 'react';

export interface TOfferLink {
  payout?: number;
  link?: string;
  clicks?: number;
  cpc?: number;
  conversions?: number;
  offerName?: string;
  saleAmount?: number;
  avgSaleAmount?: number;
}

const OfferLinkCard: React.FC<TOfferLink> = (props) => {
  const {
    link,
    clicks,
    conversions,
    saleAmount,
    avgSaleAmount,
    payout,
    cpc,
    offerName,
  } = props;
  return (
    <Card className="p-4 bg-transparent rounded-lg flex flex-col gap-2">
      <div className="text-base font-medium">{offerName}</div>
      <a href={link} target="_blank" rel="noopener noreferrer" className="text-sm font-normal text-grey-6 underline">{link}</a>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Clicks</P>
        <P className="text-sm font-normal">{clicks || 0}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Conversions</P>
        <P className="text-sm font-normal">{conversions || 0}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Sales Amount</P>
        <P className="text-sm font-normal">{`$${saleAmount?.toFixed(2) || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Average Sale</P>
        <P className="text-sm font-normal">{`$${avgSaleAmount?.toFixed(2) || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Payout</P>
        <P className="text-sm font-normal">{`$${payout?.toFixed(2) || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">CPC</P>
        <P className="text-sm font-normal">{`$${cpc?.toFixed(2) || 0}`}</P>
      </div>
    </Card>
  );
};

export default OfferLinkCard;
