import React from 'react';
import {
  FormAsyncAction,
  IAffiliateLinksFormValues,
  TDisabledMap,

} from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { OfferFormToolTips } from '@frontend/applications/AffiliatesApp/components/OfferFormToolTip';
import { AccordionContent, AccordionTrigger, AccordionItem, Accordion } from '@frontend/shadcn/components/ui/accordion';
import { H4 } from '@frontend/shadcn/components/typography/h4';
import { RefreshOfferEndDate } from './components/RefreshOfferEndDate';
import { RefreshUTMSettings } from './components/RefreshUTMSettings';
import { RefreshDeepLink } from './components/RefreshDeepLink';
import RefreshAllowMultipleconversions from '../RefreshAllowMultipleConversions';

interface IProps {
  disabled: TDisabledMap<IAffiliateLinksFormValues>;
  values: IAffiliateLinksFormValues;
  handleFormAsyncActions: (action: FormAsyncAction) => void;
  initialAllowedDomains: string[];
  onFieldFocused: (field: OfferFormToolTips | null, isFocused: boolean) => void;
  hasMembers: boolean;

}
export default function AdditionalSettingsTune({
  disabled,
  values,
  handleFormAsyncActions,
  initialAllowedDomains,
  onFieldFocused,
  hasMembers,
}: IProps): JSX.Element {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1" className="!border-none">
        <AccordionTrigger className="hover:no-underline p-0 m-0">
          <H4 className="mb-0">Additional Settings</H4>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col">
            <RefreshOfferEndDate
              disabled={disabled.expirationDate}
              name="expirationDate"
              handleFormAsyncActions={handleFormAsyncActions}
              values={values.expirationDate}
            />
            <RefreshUTMSettings
              onFieldFocused={onFieldFocused}
              values={values}
              hasMembers={hasMembers}
              handleFormAsyncActions={handleFormAsyncActions}
            />
            <RefreshDeepLink
              disabled={false}
              name="multipleDomain"
              isCreatorDeeplinkAllowed={values.creatorDeeplink}
              initialAllowedDomains={initialAllowedDomains}
              handleFormAsyncActions={handleFormAsyncActions}
            />
            <RefreshAllowMultipleconversions
              values={values}
              handleFormAsyncActions={handleFormAsyncActions}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
