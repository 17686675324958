import * as React from 'react';
import {
  Form,
} from '@revfluence/fresh';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { CircleIcon } from '@revfluence/fresh-icons/solid/esm';
import { IShopifyFormElementProps, OfferFormAsyncActions } from '../../../../types';
import { OfferInputHeader } from './OfferElementHeader';

interface IProps extends IShopifyFormElementProps<'status'> {
  value: string;
}

export const RefreshOfferStatus: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  value,
  handleFormAsyncActions,
}) => {
  const rules = [
    { required: true, message: 'Please input your status!' },
  ];
  const onchange = (status: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'status', value: status },
    });
  };
  return (
    <>
      <OfferInputHeader text="Offer Status" />

      <Form.Item rules={rules} className="!m-0">
        <Select value={value} disabled={disabled} onValueChange={(value) => onchange(value)}>
          <SelectTrigger className="w-[180px]" data-testid="offer-status-select">
            <SelectValue placeholder="Select Offer Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ACTIVE" data-testid="offer-status-active">
              <div className="flex items-center gap-2">
                <CircleIcon color="#5DB884" fontSize={7} style={{ fill: '#5DB884' }} />
                Active
              </div>
            </SelectItem>
            <SelectItem value="PAUSED" data-testid="offer-status-paused">
              <div className="flex items-center gap-2">
                <CircleIcon color="#E2AB23" fontSize={7} style={{ fill: '#E2AB23' }} />
                Paused
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </Form.Item>

    </>
  );
});
