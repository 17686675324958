import * as React from 'react';
import { ArrowsRotateIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';

interface SpinnerProps extends React.HTMLAttributes<HTMLSpanElement> {
  size?: 'sm' | 'md' | 'lg';
  spinning?: boolean;
}

const sizeClasses = {
  sm: 'h-4 w-4',
  md: 'h-6 w-6',
  lg: 'h-8 w-8',
};

export const Spinner = React.forwardRef<HTMLSpanElement, SpinnerProps>(
  ({ className, size = 'md', spinning = false, ...props }, ref) => {
    return (
      <span ref={ref} role="status" className="flex items-center justify-center" {...props}>
        <ArrowsRotateIcon className={cn(spinning && 'animate-spin', sizeClasses[size], className)} aria-hidden="true" />
        <span className="sr-only">Loading...</span>
      </span>
    );
  },
);

Spinner.displayName = 'Spinner';
