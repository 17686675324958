import {
  TotalSpendCard_CurrentIntervalMetaSumInsightsFragment,
  TotalSpendCard_PreviousIntervalMetaSumInsightsFragment,
  TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment,
} from '@frontend/gql/social/graphql';
import { formatCurrency } from '../../../../../utils/formatCurrency';

export const calculateSpendMetrics = ({
  currentInterval,
  previousInterval,
  currentIntervalNotAspireSourced,
}: {
  currentInterval: TotalSpendCard_CurrentIntervalMetaSumInsightsFragment | null | undefined;
  previousInterval: TotalSpendCard_PreviousIntervalMetaSumInsightsFragment | null | undefined;
  currentIntervalNotAspireSourced:
    | TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment
    | null
    | undefined;
}): {
  formattedCurrentInterval: string | number | null | undefined;
  formattedCurrentIntervalNotAspireSourced: string | number | null | undefined;
  percentChange: number | 'NA';
} => {
  const currentIntervalAmount = currentInterval?.totalSpend?.amount;
  const currency = currentInterval?.totalSpend?.currency;
  const previousIntervalAmount = previousInterval?.totalSpend?.amount;
  const formattedCurrentInterval = currentIntervalAmount && formatCurrency(currentIntervalAmount, currency || 'USD');

  const currentIntervalNotAspireSourcedAmount = currentIntervalNotAspireSourced?.totalSpend?.amount;
  const formattedCurrentIntervalNotAspireSourced = currentIntervalNotAspireSourcedAmount && formatCurrency(currentIntervalNotAspireSourcedAmount, currency || 'USD');
  // calculate the percent change from previous month
  const percentChange = (currentIntervalAmount
      && previousIntervalAmount
      && ((currentIntervalAmount - previousIntervalAmount) / previousIntervalAmount) * 100)
    || 'NA';

  return {
    formattedCurrentInterval,
    formattedCurrentIntervalNotAspireSourced,
    percentChange,
  };
};
