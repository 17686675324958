import * as React from 'react';

import { Table } from '@affiliates/AspireUI/';
import { OFFER_SOURCE, OFFER_STATUS } from '@affiliates/types/globalTypes';
import { OfferNameCell } from '@affiliates/components';
import { Tooltip } from 'antd';
import styles from './SelectOffersTable.scss';

export interface ISelectOfferTableRow {
  key: number;
  nameData: {
    name: string;
    imageUrl: string;
    source: OFFER_SOURCE;
    iconName: string;
  };
  creationDate: string;
  status: OFFER_STATUS | string
  expired: boolean;
  programId: number | null;
  isOfferArchived: boolean;
}

export interface ICustomRow{
  children: React.ReactElement[];
  className: string;
  'data-row-key': number;
  onClick: () => void;
}
interface IProps {
  offerData: ISelectOfferTableRow[];
  onChange: (selectedRowKeys: React.Key[], selectedRows: ISelectOfferTableRow[]) => void;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'nameData',
    render: (nameData) => <OfferNameCell source={nameData.source} name={nameData.name} imageUrl={nameData.imageUrl} iconName={nameData.iconName} />,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creationDate',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];

export const SelectOffersTable: React.FC<IProps> = ({ offerData, onChange }) => {
  const rowSelection = {
    onChange,
    getCheckboxProps: (offer: ISelectOfferTableRow) => ({
      disabled: offer.status !== OFFER_STATUS.ACTIVE || offer.expired || offer.programId || offer.isOfferArchived,
      nameData: offer.nameData,
    }),
  };

  const customRow = (props: ICustomRow) => {
    const offer = offerData.find((row: ISelectOfferTableRow) => row.key == props['data-row-key']);
    if (offer.isOfferArchived) {
      return (
        <Tooltip title="Offer is archived">
          <tr {...props} />
        </Tooltip>
      );
    }
    if (offer.programId) {
      return (
        <Tooltip title="Offer is already associated to a project">
          <tr {...props} />
        </Tooltip>
      );
    }
    if (offer.status !== OFFER_STATUS.ACTIVE) {
      return (
        <Tooltip title="Offer is not active">
          <tr {...props} />
        </Tooltip>
      );
    }
    if (offer.expired) {
      return (
        <Tooltip title="Offer is expired">
          <tr {...props} />
        </Tooltip>
      );
    }
    return <tr {...props} />;
  };

  return (
    <div className={styles.SelectOffersTable}>
      <Table
        dataSource={offerData}
        columns={columns}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        pagination={false}
        components={{
          body: {
            row: customRow,
          },
        }}
      />
    </div>
  );
};
