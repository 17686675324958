import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Empty } from '@frontend/app/refresh-components';
import { ChartPieSimpleIcon, CircleInfoIcon } from '@revfluence/fresh-icons/solid/esm';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { Tooltip, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { ChartConfig, ChartContainer, ChartTooltip } from '@/shadcn/components/ui/chart';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  GraphCardHeader,
  GraphCardHeaderRow,
  GraphCardTitle,
  GraphCardContent,
} from '../../../components/graph-card';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemValue,
  TooltipArrow,
  TooltipContent,
} from '../../../components/tooltip';

export const memberDistributionFragment = graphql(`
  fragment MemberDistribution_ActiveMemberBreakdown on ActiveMemberBreakdown {
    segment
    count
  }
`);

interface MemberDistributionCardProps {
  data: (FragmentType<typeof memberDistributionFragment> | null)[] | null | undefined;
  className?: string;
  handleSelectSegment?: (segment: string) => void;
}

// Order based on follower count ranges
const SEGMENT_ORDER: { [key: string]: number } = {
  brandfans: 0, // < 2,500
  nano: 1, // < 25,000
  micro: 2, // < 60,000
  midtier: 3, // < 250,000
  macro: 4, // < 1,000,000
  mega: 5, // >= 1,000,000
  unknown: 6,
};

const SEGMENT_RANGES: { [key: string]: string } = {
  brandfans: 'with less than 2,500 followers',
  nano: 'between 2,500 and 24,999 followers',
  micro: 'between 25,000 and 59,999 followers',
  midtier: 'between 60,000 to 249,999 followers',
  macro: 'between 250,000 to 999,999 followers',
  mega: 'with over 1M followers',
  unknown: 'Unknown',
};

const UI_LABELS: { [key: string]: string } = {
  brandfans: 'Brand Fans',
  nano: 'Nano',
  micro: 'Micro',
  midtier: 'Mid-tier',
  macro: 'Macro',
  mega: 'Mega',
  unknown: 'Unknown',
};

const chartConfig = {} as ChartConfig;

const MemberDistributionCard = React.forwardRef<HTMLDivElement, MemberDistributionCardProps>(
  ({ data, className, handleSelectSegment }, ref) => {
    const [activeSegment, setActiveSegment] = React.useState<string | null>(null);
    const memberBreakdown = useFragment(
      memberDistributionFragment,
      data?.filter((item): item is NonNullable<typeof item> => item !== null),
    );

    const chartData = React.useMemo(() => {
      const mappedData =
        memberBreakdown?.filter((item) => item?.count > 0)?.map((item) => ({
          name: item.segment,
          value: item.count,
          fill:
            {
              brandfans: '#2E5AAC', // Darkest blue
              nano: '#4F7DBA', // Bright blue
              micro: '#55A2DF', // Light blue
              midtier: '#7FC4F3', // Medium light blue
              macro: '#A6D5F2', // Very light blue
              mega: '#C6E9F9', // Palest blue
              unknown: '#E5E8EB', // Gray
            }[item.segment.toLowerCase()] || '#E5E8EB',
        })) || [];

      // Sort based on the defined order
      const sortedData = mappedData.sort((a, b) => {
        const orderA = SEGMENT_ORDER[a.name.toLowerCase()] ?? 999;
        const orderB = SEGMENT_ORDER[b.name.toLowerCase()] ?? 999;
        return orderA - orderB;
      });

      // Debug log to verify order

      return sortedData;
    }, [memberBreakdown]);

    const totalMembers = React.useMemo(() => {
      return chartData.reduce((acc, curr) => acc + curr.value, 0);
    }, [chartData]);

    const activeSegmentData = React.useMemo(() => {
      if (!activeSegment) return null;
      const segmentData = chartData.find((item) => item.name === activeSegment);
      if (!segmentData) return null;
      return {
        ...segmentData,
        percentage: ((segmentData.value / totalMembers) * 100).toFixed(1),
      };
    }, [activeSegment, chartData, totalMembers]);

    return (
      <Card ref={ref} className={className}>
        <GraphCardHeader className="border-b">
          <GraphCardHeaderRow>
            <GraphCardTitle>Member Distribution</GraphCardTitle>
            <GraphCardTitle>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button type="button" aria-label="View member distribution information" data-dd-action-name="view-member-distribution-info">
                    <CircleInfoIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </TooltipTrigger>
                <TooltipContent role="tooltip" aria-live="polite">
                  <TooltipHeader>
                    <TooltipTitle>Member Distribution</TooltipTitle>
                  </TooltipHeader>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemValue>
                        This visualization displays the distribution of creators, categorized by their follower count at the time of posting. For creators with multiple posts, the maximum follower count across all their platforms is used for categorization.
                      </TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </GraphCardTitle>
          </GraphCardHeaderRow>
        </GraphCardHeader>
        <GraphCardContent className="pt-4">
          <div className="h-[300px] flex items-center justify-center">
            <ChartContainer config={chartConfig} className="h-[300px] w-full">
              {
                chartData?.length === 0 ? (
                  <div className="mt-24">
                    <Empty
                      icon={ChartPieSimpleIcon}
                      title="No spending data available."
                      description="Track your spending by creating and assigning budgets to campaigns."
                      size="lg"
                    />
                  </div>
                )
                : (
                  <PieChart>
                    <Pie
                      data={chartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius="50%"
                      maxRadius={5}
                      outerRadius="100%"
                      paddingAngle={2}
                      onMouseOver={(data) => setActiveSegment(data.name)}
                      onMouseLeave={() => setActiveSegment(null)}
                    >
                      {chartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.fill}
                          opacity={activeSegment === null || activeSegment === entry.name ? 1 : 0.5}
                          onClick={() => handleSelectSegment && handleSelectSegment(entry.name)}
                          className="cursor-pointer"
                        />
                      ))}
                      <Label
                        content={({ viewBox }) => {
                          if (viewBox && 'cx' in viewBox && typeof viewBox.cx === 'number') {
                            return (
                              <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                                <tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
                                  {formatNumberDefault(activeSegmentData?.value || totalMembers)}
                                </tspan>
                                <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
                                  {activeSegment ? (
                                    <tspan>{UI_LABELS[activeSegment.toLowerCase()]}</tspan>
                                  ) : (
                                    'All Members'
                                  )}
                                </tspan>
                              </text>
                            );
                          }
                        }}
                      />
                    </Pie>
                    <ChartTooltip
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0];
                          const name = data.name as string;

                          return (
                            <div className="bg-white border border-gray-200 p-2 shadow-lg rounded-lg text-gray-600">
                              <div className="text-sm font-medium">
                                <span>
                                  {UI_LABELS[name.toLowerCase()]}
                                </span>
                              </div>
                              <div className="text-sm">{`${formatNumberDefault(data.value as number)} members ${SEGMENT_RANGES[name.toLowerCase()]}`}</div>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                  </PieChart>
                )
              }
            </ChartContainer>
          </div>
          {
            totalMembers > 0 && (
              <div className="flex flex-wrap justify-center gap-2 mt-6">
                {chartData.map((item) => (
                  <Tooltip key={item.name}>
                    <TooltipTrigger className="flex items-center gap-1 px-1">
                      <div className="w-3 h-3" style={{ backgroundColor: item.fill }} />
                      <span className="text-[14px] text-muted-foreground">{UI_LABELS[item.name.toLowerCase()]}</span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-white border border-gray-200 p-2 shadow-lg rounded-lg w-max">
                      <div className="text-sm font-medium">
                        <span>{UI_LABELS[item.name.toLowerCase()]}</span>
                      </div>
                      <div className="text-sm">{`${formatNumberDefault(item.value as number)} members ${SEGMENT_RANGES[item.name.toLowerCase()]}`}</div>
                    </TooltipContent>
                  </Tooltip>
                ))}
              </div>
            )
          }
        </GraphCardContent>
      </Card>
    );
  },
);

MemberDistributionCard.displayName = 'MemberDistributionCard';

export default MemberDistributionCard;
