// FIXME: we use the upper case version of these values in a number of places
// e.g. agreement_iteration.products.post_type.
export type TPostType =
  | 'youtube_mention'
  | 'additional_images'
  | 'additional_videos'
  | 'dedicated_video'
  | 'youtube_dedicated'
  | 'youtube_short'
  | 'youtube'
  | 'instagram'
  | 'instagram_reel'
  | 'instagram_video'
  | 'instagram_story'
  | 'twitter_post'
  | 'twitter'
  | 'tiktok'
  | 'tiktok_video'
  | 'facebook_post'
  | 'facebook'
  | 'blog_mention'
  | 'blog_dedicated'
  | 'blog'
  | 'pinterest'
  | 'snapchat_story'
  | 'fixed_price'
  | 'other';

export const POST_TYPE_NAME: {
  [key in TPostType]?: string;
} = {
  instagram: 'Instagram Post',
  instagram_reel: 'Instagram Reel',
  instagram_story: 'Instagram Story',
  instagram_video: 'Instagram Video',
  youtube_mention: 'YouTube Video',
  youtube_dedicated: 'YouTube Video',
  youtube_short: 'YouTube Short',
  youtube: 'YouTube Video',
  facebook_post: 'Facebook',
  facebook: 'Facebook',
  pinterest: 'Pinterest',
  blog_mention: 'Blog',
  blog_dedicated: 'Blog',
  blog: 'Blog',
  twitter: 'Twitter',
  twitter_post: 'Twitter',
  tiktok: 'TikTok',
  tiktok_video: 'TikTok',
};

export const POST_TYPE_NAME_PLURAL: {
  [key in TPostType]?: string;
} = {
  instagram: 'Instagram Posts',
  instagram_reel: 'Instagram Reels',
  instagram_story: 'Instagram Stories',
  instagram_video: 'Instagram Videos',
  youtube_mention: 'YouTube Videos',
  youtube_short: 'YouTube Shorts',
  facebook_post: 'Facebook',
  pinterest: 'Pinterest',
  blog_mention: 'Blog',
  twitter_post: 'Twitter',
  tiktok: 'TikTok',
  tiktok_video: 'TikTok',
};

export type TReportField =
  | 'tmv'
  | 'impressions'
  | 'likes'
  | 'dislikes'
  | 'closeups'
  | 'comments'
  | 'favorites'
  | 'reactions'
  | 'shares'
  | 'unique_visitors'
  | 'exits'
  | 'taps'
  | 'clicks'
  | 'views'
  | 'roi_pct'
  | 'reach';
export const NETWORK_REPORTS_FIELDS: {
  [key in TPostType]?: TReportField[];
} = {
  instagram: ['impressions', 'likes', 'comments', 'favorites', 'roi_pct'],
  instagram_reel: ['views', 'likes', 'comments', 'roi_pct', 'favorites'],
  instagram_story: ['impressions', 'exits', 'taps', 'roi_pct'],
  instagram_video: ['views', 'likes', 'comments', 'roi_pct'],
  youtube_mention: ['views', 'likes', 'dislikes', 'comments', 'roi_pct'],
  youtube_short: ['views', 'likes', 'dislikes', 'comments', 'roi_pct'],
  facebook_post: ['likes', 'shares', 'comments', 'reactions', 'roi_pct'],
  pinterest: ['impressions', 'closeups', 'clicks', 'favorites', 'roi_pct'],
  blog_mention: ['unique_visitors', 'roi_pct'],
  twitter: ['tmv', 'likes', 'shares', 'roi_pct'],
};
