import { useQuery, QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';

import { generateMemberSearchGraphQLQuery, SelectedField } from '@frontend/app/queries';
import {
  MemberSearchQuery,
  MemberSearchQueryVariables,
} from '@frontend/app/queries/types/MemberSearchQuery';

type IOptions = QueryHookOptions<MemberSearchQuery, MemberSearchQueryVariables>;

export const useMemberSearchQuery = (options: IOptions = {}, selectedFields: Set<SelectedField> = new Set([])) => {
  const variables = useMemo(() => ({
    ...options.variables,
    includeTags: true,
    sortDirection: options.variables?.query?.orderBy?.direction || null,
  }), [options.variables]);

  return useQuery<MemberSearchQuery, MemberSearchQueryVariables>(
    generateMemberSearchGraphQLQuery(selectedFields),
    {
      ...options,
      variables
    }
  );
};
