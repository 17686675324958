import * as React from 'react';

import { isFunction } from 'lodash';
import { Input } from '@frontend/shadcn/components/ui/input';

const { useCallback } = React;

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  onChange?(value: string);
}

export const DaysRelative: React.FC<IProps> = (props) => {
  const { value, onChange } = props;

  const handleChangeValue = useCallback((event) => {
    if (isFunction(onChange)) {
      const { value } = event.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        onChange(event.target.value);
      }
    }
  }, [onChange]);

  return (
    <div className="flex gap-2 items-center">
      <Input
        value={value}
        onChange={handleChangeValue}
      />
      <span>days</span>
    </div>
  );
};
