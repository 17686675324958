import React from 'react';
import { Alert } from '@frontend/shadcn/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { OFFER_FORM_LOCKING_KEY } from '@frontend/applications/AffiliatesApp/types';
import { OFFER_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';
import { FormInstance } from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { P } from '@frontend/shadcn/components/typography/p';
import { Label } from '@frontend/shadcn/components/ui/label';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { IShopifyPromoCodeFormValues, OfferFormModes, FormAsyncAction } from '../../../../../../types';
import { RefreshOfferSync } from './components/RefreshOfferSync';
import { RefreshMultipleShopifyStores } from './components/RefreshMultipleShopifyStores';

interface IProps {
  values: IShopifyPromoCodeFormValues;
  isLinkedShopifyOfferSelected: boolean;
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  mode: OfferFormModes;
  formRef: FormInstance;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[];
  hasMembers: boolean;
}

const RefreshEnableMultipleShopify: React.FC<Readonly<IProps>> = ({
  values,
  isLinkedShopifyOfferSelected,
  handleFormAsyncActions,
  mode,
  formRef,
  connectedAdvertiserForSync,
  hasMembers,
}) => {
  const { shopifyLandingPage: isLandingPageEnabled } = useClientFeatures();
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="links-section">
        <AccordionTrigger className="hover:no-underline p-0 mt-2">
          <div className="flex flex-col gap-1">
            <P className="text-grey-6" fontWeight="font-medium">
              {isLinkedShopifyOfferSelected
                ? 'Connect other shopify stores discounts'
                : 'Sync offer to other shopify stores'}
            </P>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <Label className={isLandingPageEnabled ? 'text-gray-500' : ''}>
            {isLinkedShopifyOfferSelected ? (
              ''
            ) : (
              <span className="text-grey-4">
                Sync this promo code offer to other connected Shopify stores.{' '}
                <a
                  href="https://help.revfluence.com/en/articles/shopify-sync"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:text-primary underline hover:underline hover:bg-grey-1 font-medium"
                >
                  Learn More
                </a>
              </span>
            )}
          </Label>
          {values.isLandingPageEnabled && (
            <Alert variant="info" className="my-4">
              <AlertCircle className="h-4 w-4" />
              <div className="flex flex-col">
                <h4 className="font-medium">Multiple Shopify is not available</h4>
                <p className="text-sm text-muted-foreground">
                  We currently don't support multiple Shopify stores with Landing Pages. This feature will be available
                  soon.
                </p>
              </div>
            </Alert>
          )}

          <RefreshOfferSync
            disabled={
              !values.isNewFlow ||
              !!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) ||
              values.status === OFFER_STATUS.PAUSED ||
              values.isLandingPageEnabled ||
              (isLinkedShopifyOfferSelected && mode === OfferFormModes.Edit)
            }
            name="multiShopifyEnabled"
            handleFormAsyncActions={handleFormAsyncActions}
            isMultipleShopifySyncEnabled={values.isMultipleShopifySyncEnabled}
            hasMembers={hasMembers}
            isLinkedShopifyOfferSelected={isLinkedShopifyOfferSelected}
            isLandingPageEnabled={values.isLandingPageEnabled}
          />

          {values.isMultipleShopifySyncEnabled && (
            <div className="space-y-4 mt-2">
              <P className="font-medium">Select Shopify Stores</P>
              <RefreshMultipleShopifyStores
                disabled={
                  !!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) ||
                  values.status === OFFER_STATUS.PAUSED
                }
                mode={mode}
                values={values}
                handleFormAsyncActions={handleFormAsyncActions}
                formRef={formRef}
                connectedAdvertiserForSync={connectedAdvertiserForSync}
                offerType={values.offerType}
              />
            </div>
          )}

          {!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) && (
            <Alert variant="warning" className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <p className="text-sm">
                Offer sync is currently locked for editing while date settings are being modified. To change offer sync
                options, please update or cancel changes to date settings.
              </p>
            </Alert>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default RefreshEnableMultipleShopify;
