import {
 Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@frontend/shadcn/components/ui/accordion';
import React, { useState } from 'react';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { ProductsProvider } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { cn } from '@frontend/shadcn/lib/utils';
import FeaturedProductContent from './components/FeaturedProductContent';
import ManageAllProductsContent from './components/ManageAllProductsContent';
import SettingsContent from './components/SettingsContent';
import ProductCard from './components/ProductCard';
import { useLandingPageContext } from '../../../../context/LandingPageContext';

const Products: React.FC = () => {
  const { fieldErrors } = useLandingPageContext();
  const [openAccordions, setOpenAccordions] = useState<string[]>(['featured-product']);

  const accordionItems = [
    {
      value: 'featured-product',
      title: 'Featured Product',
      content: <FeaturedProductContent />,
      hasError: !!(fieldErrors.products?.featuredProduct?.id),
    },
    {
      value: 'manage-all-products',
      title: 'Manage All Products',
      content: <ManageAllProductsContent />,
      hasError: false,
    },
    {
      value: 'product-card',
      title: 'Product Card',
      content: <ProductCard />,
      hasError: false,
    },
    {
      value: 'settings',
      title: 'Settings',
      content: <SettingsContent />,
    },
  ];

  const handleAccordionChange = (value: string[]) => {
    setOpenAccordions(value);
  };

  return (
    <ProductsProvider>
      <div className="flex flex-col gap-4 py-4">
        <Card className="w-full border-none shadow-none">
          <CardContent className="flex flex-col gap-2 p-0">
            <h2 className="text-[16px] font-medium leading-7 text-[#16282D]">Products</h2>
            <p className="text-sm text-[#A2ADB9] leading-6">
              You can add products directly or select a collection. For a better customer experience, we recommend
              adding only products that are eligible for this offer to avoid any confusion.
              {/* TODO: Add help center link */}
              {/* <a href="#" className="inline-flex items-center text-[#16282D] underline hover:text-[#16282D]/90">
                Help Center <ArrowUpRightFromSquare className="ml-1 h-3 w-3" />
              </a> */}
            </p>
          </CardContent>
        </Card>
        {accordionItems.map((item, index) => (
          <React.Fragment key={item.value}>
            <Accordion
              type="multiple"
              value={openAccordions}
              onValueChange={handleAccordionChange}
              className="w-full py-0"
            >
              <AccordionItem
                value={item.value}
                className={cn(
                  'border-0',
                  item.hasError && 'ring-1 ring-red-500 rounded-2xl',
                )}
              >
                <AccordionTrigger className="bg-neutral-color-palettegray-1 rounded-2xl px-4 hover:no-underline py-2">
                  <div className="flex items-center gap-2">
                    <span className={cn(
                      'text-grey-6 text-base font-medium',
                      item.hasError && 'text-red-500',
                    )}
                    >
                      {item.title}
                    </span>
                    {item.hasError && (
                      <Badge
                        variant="destructive"
                        className="bg-red-100 text-red-500 text-xs font-medium"
                      >
                        Error
                      </Badge>
                    )}
                  </div>
                </AccordionTrigger>
                <AccordionContent>{item.content}</AccordionContent>
              </AccordionItem>
            </Accordion>
            {index < accordionItems.length - 1 && <Separator className="w-full h-0.5" />}
          </React.Fragment>
        ))}
      </div>
    </ProductsProvider>
  );
};

export default Products;
