import * as React from 'react';
import { mapValues, keyBy, each, reject } from 'lodash';

import { SelectedColumnsInput } from '@frontend/app/types/globalTypes';
import { IColumnVisibility, IColumn } from '@frontend/app/types/Columns';
import { TSegment, TPredefinedSegment } from '@frontend/app/types/MemberList';

const { useMemo, useState, useEffect } = React;

export const useColumnVisibility = (segment: TSegment | TPredefinedSegment, columns: IColumn[]) => {
  const defaultColumnVisibility = useMemo<IColumnVisibility>(() => {
    if (!segment) {
      return;
    }

    const columnsWithoutName = reject(columns, { field: 'name' });
    const columnsByKey = keyBy(columnsWithoutName, 'field');

    const segmentColumns: SelectedColumnsInput = segment.columns || {
      dbColumns: [],
      memberFieldSchemaIds: [],
    };

    const segmentColumnVisibility: IColumnVisibility = {};

    each(segmentColumns.dbColumns, (c) => {
      segmentColumnVisibility[c] = true;
    });

    each(segmentColumns.memberFieldSchemaIds, (c) => {
      segmentColumnVisibility[c] = true;
    });

    return mapValues(columnsByKey, (c) => segmentColumnVisibility[c.field] || false);
  }, [segment, columns]);

  const result = useState<IColumnVisibility>(defaultColumnVisibility || {});
  const [, setColumnVisiblity] = result;

  // Use a ref to track previous value to avoid dependency on columnVisibility
  const prevDefaultVisibilityRef = React.useRef<string>('');

  useEffect(() => {
    // Store JSON string of default visibility for comparison
    const newValue = JSON.stringify(defaultColumnVisibility);

    // Only update if values are different from last time effect ran
    if (defaultColumnVisibility && prevDefaultVisibilityRef.current !== newValue) {
      setColumnVisiblity(defaultColumnVisibility);
      prevDefaultVisibilityRef.current = newValue;
    }
  }, [defaultColumnVisibility, setColumnVisiblity]); // Include setColumnVisiblity to satisfy linter

  return result;
};
