import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_PAYMENTS_QUERY from '@frontend/app/queries/GetPaymentsQuery';
import { GetPaymentsQuery, GetPaymentsQueryVariables } from '@frontend/app/queries/types/GetPaymentsQuery';

type IOptions = QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>;

export const useGetPayments = (options: IOptions = {}) =>
  useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(
    GET_PAYMENTS_QUERY,
    options,
  );
