import gql from 'graphql-tag';

export const PRODUCT_V2_FRAGMENT = gql`
  fragment ProductV2Fragment on ProductV2 {
    id
    externalId
    name
    description
    inventory {
      totalInventory
      tracksInventory
    }
    totalVariants
    vendor
    status
    tags
    productType
    category
    images
    isInventorySynced
    metadata {
      priceMin
      priceMax
      currencyCode
    }
  }
`;
