import * as React from 'react';
import cx from 'classnames';
import {
  filter, map, isFunction,
} from 'lodash';

import { Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { EditIcon, SpinnerIcon } from '@components';

import { useFuzzySearchByKeys, useGetUsersQuery } from '@frontend/app/hooks';
import { SelectList, UserAvatar } from '@frontend/app/components';

import styles from './OwnerSelect.scss';

const {
  useState, useMemo, useCallback,
} = React;

interface IOwner {
  id: string;
  name: string;
}

interface IProps {
  ownerIds?: string[];
  owners?: IOwner[];
  multi?: boolean;
  onChangeOwners?(ownerIds: string[]);
  className?: string;
  label?: string;
  loading?: boolean;
  refreshUi?: boolean;
}

const SEARCH_KEYS = ['name'];

export const OwnerSelect: React.FC<IProps> = React.memo(({ label = 'Owners:', loading = false, ...props }) => {
  const [dropdownHasBeenShown, setDropdownHasBeenShown] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedOwnerIds, setSelectedOwnerIds] = useState<string[]>([]);

  const loadUsers = dropdownHasBeenShown || (props.ownerIds && !props.owners);

  const {
    loading: isLoadingAllUsers,
    data: {
      users: allUsers = null,
    } = {},
  } = useGetUsersQuery({
    skip: !loadUsers,
  });

  const handleSearch = useFuzzySearchByKeys(allUsers, SEARCH_KEYS);

  const handleChangeSelectedOption = useCallback((ownerIds: string[]) => {
    if (ownerIds.length === 0) return;
    setSelectedOwnerIds(ownerIds);
  }, []);

  const selectedOwners = useMemo(() => {
    if (selectedOwnerIds.length > 0) {
      return filter(allUsers, (user) => selectedOwnerIds.includes(user.id));
    }
    if (props.owners) {
      return props.owners;
    }
    return filter(allUsers, (user) => props.ownerIds?.includes(user.id));
  }, [allUsers, props.ownerIds, props.owners, selectedOwnerIds]);

  const ownerNames = useMemo(() => {
    const names = map(selectedOwners.slice(0, 2), 'name').join(', ');
    return {
      names,
      remainingCount: selectedOwners.length - 2,
    };
  }, [selectedOwners]);

  let overlay;

  if (isLoadingAllUsers || loading) {
    overlay = (
      <div className={styles.ownerList}>
        <SpinnerIcon size={18} className={styles.spinner} />
      </div>
    );
  } else if (allUsers) {
    type TOption = typeof allUsers[0];

    const mapOptionToLabel = (option: TOption) => (
      <div className={styles.ownerItem}>
        <UserAvatar size="small" name={option.name} />
        <span>{option.name}</span>
      </div>
    );

    overlay = (
      <SelectList
        multi={props.multi}
        searchPlaceholder="Search..."
        showSearch
        onSearchRequest={handleSearch}
        defaultSelectedIds={map(selectedOwners, 'id')}
        options={allUsers}
        mapOptionToId={(option: TOption) => option.id}
        mapOptionToLabel={mapOptionToLabel}
        onChange={handleChangeSelectedOption}
        className={styles.ownerList}
        contentClassName={styles.scroll}
      />
    );
  } else {
    overlay = <div className={styles.ownerList} />;
  }

  const handleDropdownVisible = (visible: boolean) => {
    if (visible && !dropdownHasBeenShown) {
      setDropdownHasBeenShown(true);
    }
    setDropdownVisible(visible);

    if (!visible && selectedOwnerIds.length > 0 && isFunction(props.onChangeOwners)) {
      // selectedOwnerIds changes only when user changes selection of current owners
      // we do not need to send update request when owners haven't changed
      props.onChangeOwners(selectedOwnerIds);
    }
  };

  return (
    <div className={cx(styles.OwnerSelect, props.className, { [styles.isSelected]: selectedOwners.length > 0 })}>
      {!props.refreshUi && (
        <>
          <UserOutlined className={styles.icon} />
          <span className={styles.label}>{label}</span>
        </>
      )}
      <Dropdown
        visible={dropdownVisible}
        overlay={overlay}
        trigger={['click']}
        placement="bottomLeft"
        onVisibleChange={handleDropdownVisible}
        overlayStyle={{ zIndex: 9500 }}
      >
        <div className={styles.name}>
          <span className={styles.names}>{ownerNames.names}</span>
          {ownerNames.remainingCount > 0 && (
            <span className={styles.count}>
              &nbsp;+
              {ownerNames.remainingCount}
            </span>
          )}
          {selectedOwners.length === 0 && 'Add...'}
          <EditIcon size={16} className={styles.edit} />
        </div>
      </Dropdown>
    </div>
  );
});
