import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  map, findIndex, first, each, orderBy,
} from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Select, IOption } from '@components';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';

import {
  ALL_MEMBERS_COMMUNITY_ID, MY_FAVORITES_TITLE,
} from '@frontend/app/constants';
import { sourcingGroups as allSourcingGroups } from '@frontend/app/constants/sourcingGroups';
import {
  useCommunitySwitcherContext,
  useCommunitiesQuery,
  useIsSourceGroupEnabled,
  useFeatureFlagVerbiage,
} from '@frontend/app/hooks';
import { GetCommunitiesQuery_communities as ICommunity } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { ALL_CONTACTS_IMAGE_URL } from '@frontend/app/constants/imageUrls';

import { CommunitySelectItem } from './CommunitySelectItem';
import { CommunityDropdownLabel } from './CommunityDropdownLabel';

import styles from './CommunityDropdown.scss';

const {
 useMemo, useEffect, useCallback, useRef,
} = React;

const ALL_MEMBERS: ICommunity = {
  __typename: 'Community',
  id: ALL_MEMBERS_COMMUNITY_ID,
  title: 'All Contacts',
  description: 'All Contacts',
  splashImageUrl: ALL_CONTACTS_IMAGE_URL,
  memberCount: 0,
  clientIds: [],
  createdDate: null,
  updatedDate: null,
  showApplicantReview: false,
};

interface IProps {
  optionAll?: boolean;
  useQueryParam?: boolean;
  showSourcingGroups?: boolean;
  disabled?: boolean;
  excludedSourceGroupIds?: string[];
  hideSelectedTitle?: boolean;
}

interface IOptionValue {
  sourcingId?: string;
  communityId?: number;
  imageUrl?: string;
  title?: string;
}

export const CommunityDropdown: React.FC<IProps> = React.memo((props) => {
  const initialLoadRef = useRef(true);
  const history = useHistory();
  const {
    loading,
    data: {
      communities = null,
    } = {},
  } = useCommunitiesQuery();

  const verbiage = useFeatureFlagVerbiage();

  const orderedCommunities = orderBy(communities, [
    (community) => (
      community.title === MY_FAVORITES_TITLE && -1
    ),
    'title']);

  const {
    selectedCommunityId,
    selectedSourcingId,
    setSelectedCommunityId,
    setSelectedSourcingId,
  } = useCommunitySwitcherContext();

  const allCommunities = useMemo(() => {
    if (!props.optionAll) {
      return orderedCommunities;
    }

    return [
      ALL_MEMBERS,
      ...(orderedCommunities || []),
    ];
  }, [orderedCommunities, props.optionAll]);

  const allSourcingGroupIds = useMemo(() => map(allSourcingGroups, (group) => group.id), []);

  const {
    enabled: areSourcingGroupsEnabled,
    loading: loadingSourcingGroupEnabled,
  } = useIsSourceGroupEnabled(allSourcingGroupIds, {
    skip: !props.showSourcingGroups,
  });

  const sourcingGroups = useMemo(() => {
    if (loadingSourcingGroupEnabled || !props.showSourcingGroups || !communities) {
      return [];
    }

    const enabledSourcingGroups = [];
    each(allSourcingGroups, (group, idx) => {
      if (areSourcingGroupsEnabled && areSourcingGroupsEnabled[idx] && !props.excludedSourceGroupIds?.includes(group?.id)) {
        enabledSourcingGroups.push(group);
      }
    });

    return enabledSourcingGroups;
  }, [
    props.showSourcingGroups,
    props.excludedSourceGroupIds,
    communities,
    loadingSourcingGroupEnabled,
    areSourcingGroupsEnabled,
  ]);

  const baseOptions = useMemo<IOption[]>(() => {
    const sourcingGroupOptions: IOption[] = map(sourcingGroups, (sourcingGroup) => ({
      label: (
        <CommunitySelectItem
          title={sourcingGroup.title}
          imageUrl={sourcingGroup.imageUrl}
        />
      ),
      value: {
        communityId: -1,
        sourcingId: sourcingGroup.id,
        imageUrl: sourcingGroup.imageUrl,
        title: sourcingGroup.title,
      } as IOptionValue,
    }));

    const communityOptions: IOption[] = map(allCommunities, (community) => ({
      label: (
        <CommunitySelectItem
          title={community.title}
          imageUrl={community.splashImageUrl}
        />
      ),
      value: {
        sourcingId: null,
        communityId: community.id,
        imageUrl: community.splashImageUrl,
        title: community.title,
      } as IOptionValue,
    }));

    const addNewGroupOption: IOption = {
      label: (
        <CommunitySelectItem
          title={`Create new ${verbiage.community}`}
          icon={<PlusOutlined size={24} />}
        />
      ),
      value: {
        communityId: -1,
      },
      onSelect: () => history.push('/communities/new'),
      optionClass: styles.isAddNew,
    };

    return [
      ...communityOptions,
      ...sourcingGroupOptions,
      addNewGroupOption,
    ];
  }, [sourcingGroups, allCommunities, history, verbiage.community]);

  const {
    searchText,
    inputValue,
    handleSearchChange,
    error,
    checkNoResults,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'groups',
      metadata: {
        source: 'members_groups_dropdown',
      },
    },
  });

  const searchOption = useMemo<IOption>(() => ({
    label: (
      <div className={styles.searchContainer} onClick={(e) => e.stopPropagation()}>
        <SearchInput
          value={inputValue}
          onChange={handleSearchChange}
          placeholder="Search groups..."
          aria-invalid={!!error}
          aria-errormessage={error ? 'community-search-error' : undefined}
          isLoading={isSearchLoading}
        />
        {error && (
          <div
            className={styles.searchError}
            id="community-search-error"
            role="alert"
          >
            {error}
          </div>
        )}
      </div>
    ),
    value: null,
    optionClass: styles.searchOption,
    disabled: !!error,
  }), [inputValue, handleSearchChange, error, isSearchLoading]);

  const filteredOptions = useMemo(() => {
    if (!searchText) {
      return [searchOption, ...baseOptions];
    }

    const filtered = baseOptions.filter((option) =>
      option.value?.title?.toLowerCase().includes(searchText.toLowerCase()));

    return [searchOption, ...filtered];
  }, [searchText, baseOptions, searchOption]);

  const selectedIndex = useMemo(() => {
    // Skip search option in the index calculation
    const optionsWithoutSearch = filteredOptions.slice(1);

    if (selectedSourcingId) {
      const idx = findIndex(optionsWithoutSearch, (option) => option.value?.sourcingId === selectedSourcingId);
      if (idx >= 0) {
        return idx + 1; // Add 1 to account for search option
      }
    }
    return findIndex(optionsWithoutSearch, (option) => option.value?.communityId === selectedCommunityId) + 1;
  }, [selectedCommunityId, selectedSourcingId, filteredOptions]);

  const handleChange = useCallback((value: IOptionValue) => {
    if (value.sourcingId) {
      setSelectedCommunityId(null);
      setSelectedSourcingId(value.sourcingId);
    } else {
      setSelectedSourcingId(null);
      setSelectedCommunityId(value.communityId);
    }
  }, [setSelectedSourcingId, setSelectedCommunityId]);

  useEffect(() => {
    // Only run this effect on initial load
    if (communities && selectedIndex < 0 && !searchText && initialLoadRef.current) {
      initialLoadRef.current = false; // Set to false after first run
      const community = first(allCommunities);
      if (community) {
        setSelectedSourcingId(null);
        setSelectedCommunityId(community.id);
      }
    }
  }, [
    communities,
    allCommunities,
    selectedIndex,
    searchText,
    setSelectedCommunityId,
    setSelectedSourcingId,
  ]);

  useEffect(() => {
    checkNoResults(filteredOptions.length > 1); // > 1 because of search option
  }, [filteredOptions.length, checkNoResults]);

  const labelElement = useMemo(() => {
    if (selectedIndex < 0) {
      return null;
    }

    const { value } = filteredOptions[selectedIndex];

    return (
      <CommunityDropdownLabel
        imageUrl={value?.imageUrl}
        title={!props.hideSelectedTitle ? value?.title : undefined}
        disabled={props.disabled}
      />
    );
  }, [selectedIndex, filteredOptions, props.hideSelectedTitle, props.disabled]);

  if (loading) {
    return null;
  }

  return (
    <Select
      theme="info"
      className={cx(styles.CommunityDropdown, { [styles.disabled]: props.disabled })}
      options={filteredOptions}
      onChange={handleChange}
      selectedIndex={selectedIndex}
      popoverProps={{
        minWidth: 240,
        maxWidth: 240,
        anchorOrigin: 'start',
        arrowPosition: 'start',
      }}
      round
      customLabelElement={labelElement}
      disabled={props.disabled}
    />
  );
});

CommunityDropdown.defaultProps = {
  optionAll: false,
  showSourcingGroups: false,
};
