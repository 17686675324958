import React, { useEffect } from 'react';
import { PencilIcon } from '@revfluence/fresh-icons/regular/esm';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Button } from '@frontend/shadcn/components/ui/button';
import { PlusIcon } from '@revfluence/fresh-icons/solid/esm';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';

import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { Spinner } from '@revfluence/fresh';
import { cn } from '@frontend/shadcn/lib/utils';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { Tag } from '@frontend/app/refresh-components';
import { usePayment } from '../PaymentContext';
import { BudgetSource } from '../constants';
import { IEditPayments } from './EditPaymentDrawer';
import { OtherBudgetPeriodEntry } from './OtherBudgetPeriodEntry';
import { ProjectBudgetPeriodEntry } from './ProjectBudgetPeriodEntry';

export enum Mode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

interface ConnectionsSectionProps {
  paymentDetails: GetPaymentDetailsByIdsQuery_payments[];
  editPayments: IEditPayments;
  setEditPayments: React.Dispatch<React.SetStateAction<IEditPayments>>;
  briefs: IAgreement[];
  isLoading: boolean;
}

// Helper components for better organization
const ConnectionField: React.FC<{
  label: string | React.ReactNode;
  editMode: boolean;
  value: React.ReactNode;
  editComponent: React.ReactNode;
}> = ({ label, editMode, value, editComponent }) => (
  <div className="flex justify-between items-center">
    <span className="text-gray-400">{label}</span>
    {editMode ? editComponent : <span>{value}</span>}
  </div>
);

export const ConnectionsSection: React.FC<ConnectionsSectionProps> = ({
  editPayments,
  setEditPayments,
  paymentDetails,
  briefs,
  isLoading,
}) => {
  const { groups, programs } = usePayment();
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isSinglePayment = paymentDetails.length === 1;
  const totalPaymentSum = isSinglePayment
    ? paymentDetails[0]?.amount
    : paymentDetails.reduce((sum, payment) => sum + payment.amount, 0);

  // Reset budget ID when budget source changes to PROJECT
  useEffect(() => {
    if (editPayments.budgetSource === BudgetSource.PROJECT) {
      setEditPayments((prev) => ({
        ...prev,
        budgetId: null,
      }));
    }
  }, [editPayments.budgetSource, setEditPayments]);

  // Add new useEffect to handle budget source based on projectId
  useEffect(() => {
    if (editPayments.mode === Mode.EDIT && !editPayments.projectId) {
      setEditPayments((prev) => ({
        ...prev,
        budgetSource: BudgetSource.OTHER,
      }));
    }
  }, [editPayments.mode, editPayments.projectId, setEditPayments]);

  // useEffect(() => {
  //   if (editPayments.mode === Mode.EDIT && editPayments.budgetSource === BudgetSource.PROJECT) {
  //     setEditPayments((prev) => ({
  //       ...prev,
  //       budgetPeriod: [
  //         {
  //           key: 'new',
  //           amount: totalPaymentSum,
  //           periodId: null,
  //           budgetId: null,
  //           granularityLabelForPayload: null,
  //         },
  //       ],
  //     }));
  //   }
  // }, [editPayments.projectId, editPayments.mode, setEditPayments]);

  // Don't render anything if paymentDetails is null or empty
  if (!paymentDetails || paymentDetails.length === 0) {
    return null;
  }

  // Extract selected values for display in VIEW mode
  const selectedGroup = isSinglePayment ? paymentDetails[0]?.paymentGroup : null;
  const selectedProject = isSinglePayment ? paymentDetails[0]?.project : null;
  const selectedBudget = isSinglePayment ? paymentDetails[0]?.budgets : null;
  const selectedBrief = isSinglePayment ? paymentDetails[0]?.brief : null;

  // Handler functions for select changes
  const handleGroupChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      groupId: value ? parseInt(value, 10) : null,
    }));
  };

  const handleProjectChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      projectId: value ? parseInt(value, 10) : null,
      // Reset both period arrays when project changes
      otherBudgetPeriod: [],
      projectBudgetPeriod: value
        ? [
            {
              key: `project-${uuidv4()}`,
              amount: totalPaymentSum,
              budgetId: undefined,
              periodId: undefined,
              granularityLabelForPayload: undefined,
            },
          ]
        : [],
    }));
  };

  const handleBriefChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      briefId: value ? parseInt(value, 10) : null,
    }));
  };

  const handleBudgetSourceChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      budgetSource: value as BudgetSource,
      // Reset the respective period array based on the new source
      otherBudgetPeriod: value === BudgetSource.OTHER ? [] : prev.otherBudgetPeriod,
      projectBudgetPeriod:
        value === BudgetSource.PROJECT
          ? [
              {
                key: `project-${uuidv4()}`,
                amount: totalPaymentSum,
                budgetId: undefined,
                periodId: undefined,
                granularityLabelForPayload: undefined,
              },
            ]
          : prev.projectBudgetPeriod,
    }));
  };

  // Format date helper function
  const formatDate = (dateString: string | number | null | undefined): string => {
    if (!dateString) return '-';

    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    } catch (e) {
      return String(dateString);
    }
  };

  // Toggle edit mode handler
  const toggleEditMode = () =>
    setEditPayments((prev) => ({ ...prev, mode: prev.mode === Mode.VIEW ? Mode.EDIT : Mode.VIEW }));

  return (
    <section className="w-full">
      <div className="flex justify-between items-center">
        <p className="m-0 text-sm font-medium">Connections</p>
        {editPayments.mode === Mode.VIEW && (
          <button
            type="button"
            className="flex items-center gap-1 hover:cursor-pointer text-grey-6 hover:text-grey-6 transition-colors"
            onClick={toggleEditMode}
          >
            <PencilIcon className="h-3 w-3 text-grey-6" />
            Edit
          </button>
        )}
      </div>

      <Separator className="my-2" />

      {isLoading ? (
        <div className="flex justify-center items-center h-full w-full">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {/* Group Selection */}
          <ConnectionField
            label="Group"
            editMode={editPayments.mode === Mode.EDIT}
            value={selectedGroup?.name || '-'}
            editComponent={
              <Select value={editPayments.groupId?.toString() || ''} onValueChange={handleGroupChange}>
                <SelectTrigger className="w-[170px] h-7 text-sm">
                  <SelectValue placeholder="Select Group" />
                </SelectTrigger>
                <SelectContent className="z-[9999]">
                  <SelectGroup>
                    {groups.map((group) => (
                      <SelectItem key={group.id} value={group.id.toString()}>
                        {group.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
          />

          {/* Project Selection */}
          <ConnectionField
            label="Project"
            editMode={editPayments.mode === Mode.EDIT}
            value={selectedProject?.name || '-'}
            editComponent={
              <Select value={editPayments.projectId?.toString() || ''} onValueChange={handleProjectChange}>
                <SelectTrigger className="w-[170px] h-7 text-sm">
                  <SelectValue placeholder="Select Project" />
                </SelectTrigger>
                <SelectContent className="z-[9999]">
                  <SelectGroup>
                    {programs.map((program) => (
                      <SelectItem key={program.id} value={program.id.toString()}>
                        {program.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
          />

          {/* Brief Selection */}
          {!isSinglePayment ? (
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Brief</span>
              <span className="text-xs text-yellow-5 font-normal">
                Connected automatically if only one brief is present
              </span>
            </div>
          ) : (
            <ConnectionField
              label="Brief"
              editMode={editPayments.mode === Mode.EDIT}
              value={selectedBrief?.createdDate ? formatDate(selectedBrief.createdDate) : '-'}
              editComponent={
                <Select value={editPayments.briefId?.toString() || ''} onValueChange={handleBriefChange}>
                  <SelectTrigger className="w-[170px] h-7 text-sm">
                    <SelectValue placeholder="Select Brief" />
                  </SelectTrigger>
                  <SelectContent className="z-[9999]">
                    <SelectGroup>
                      {briefs.length > 0 ? (
                        briefs.map((brief, index) => (
                          <SelectItem key={index} value={brief.id?.toString() || index.toString()}>
                            {brief?.created_at ? formatDate(brief.created_at * 1000) : `Brief ${index + 1}`}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem disabled value="no-brief">
                          No Brief
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              }
            />
          )}

          {/* Only show budget section if budget allocation is enabled */}
          {isBudgetAllocationEnabled && (
            <>
              {/* Budget Source Selection */}
              <ConnectionField
                label="Assigned to Budget"
                editMode={editPayments.mode === Mode.EDIT}
                value={
                  <div className="flex gap-2">
                    {editPayments.budgetSource === BudgetSource.PROJECT && selectedBudget?.length ? (
                      <Tag label="Using Project Budget" />
                    ) : (
                      selectedBudget
                        ?.filter((budget) => budget?.fiscalYear)
                        .map((budget) => <Tag key={budget.budgetId} label={budget.budgetName} />) || '-'
                    )}
                  </div>
                }
                editComponent={
                  <RadioGroup
                    value={editPayments.budgetSource}
                    onValueChange={handleBudgetSourceChange}
                    className="flex flex-row space-x-4"
                  >
                    <div className="flex items-center space-x-2">
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <RadioGroupItem
                              value={BudgetSource.PROJECT}
                              id="project"
                              disabled={!editPayments.projectId}
                            />
                          </div>
                        </TooltipTrigger>
                        {!editPayments.projectId && <TooltipContent>Please select a project first</TooltipContent>}
                      </Tooltip>
                      <Label
                        htmlFor="project"
                        className={cn(!editPayments.projectId && 'text-gray-400 cursor-not-allowed')}
                      >
                        Project's Budget
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value={BudgetSource.OTHER} id="other" />
                      <Label htmlFor="other">Others</Label>
                    </div>
                  </RadioGroup>
                }
              />

              {/* Budget settings in edit mode */}
              {editPayments.mode === Mode.EDIT && (
                <>
                  {editPayments.budgetSource === BudgetSource.OTHER && (
                    <div className="space-y-2">
                      {editPayments.otherBudgetPeriod.map((period) => (
                        <OtherBudgetPeriodEntry
                          key={period.key}
                          periodData={period}
                          setEditPayments={setEditPayments}
                          onRemove={() => {
                            setEditPayments((prev) => ({
                              ...prev,
                              otherBudgetPeriod: prev.otherBudgetPeriod.filter((p) => p.key !== period.key),
                            }));
                          }}
                        />
                      ))}

                      {(isSinglePayment || (!isSinglePayment && editPayments.otherBudgetPeriod.length === 0)) && (
                        <Button
                          variant="outline"
                          size="sm"
                          className="w-full mt-2"
                          onClick={() => {
                            setEditPayments((prev) => ({
                              ...prev,
                              otherBudgetPeriod: [
                                ...prev.otherBudgetPeriod,
                                {
                                  key: `new-${uuidv4()}`,
                                  amount: 0,
                                  budgetId: undefined,
                                  periodId: undefined,
                                  granularityLabelForPayload: undefined,
                                },
                              ],
                            }));
                          }}
                        >
                          <PlusIcon className="h-4 w-4 mr-2" />
                          Split Payment
                        </Button>
                      )}
                    </div>
                  )}

                  {editPayments.budgetSource === BudgetSource.PROJECT && (
                    <>
                      {editPayments.projectBudgetPeriod.map((period) => (
                        <ProjectBudgetPeriodEntry
                          key={period.key}
                          periodData={period}
                          setEditPayments={setEditPayments}
                          projectId={editPayments.projectId}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};
