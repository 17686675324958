import * as React from 'react';
import { intersection, isEmpty } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import createAuthClient, { Auth0Client } from '@auth0/auth0-spa-js';

import { getEnvironment, Environment } from '@frontend/utils/Envionment/Environment';
import { logger } from '@common';

export interface IClientInfo {
  name: string;
  id: string;
  logo: string;
}
interface IAuthContext {
  isGuest: boolean;

  clientInfo: IClientInfo;
  loading: boolean;
  isAuthenticated: boolean;
  user: IUser;
  token: string;
  authError: IAuthError;
  targetUrl: string;

  loginWithRedirect(pathBeforeRedirect?: string): Promise<void>;
  logout(): void;
}

type TAuthError = 'unauthorized' | 'internal_error';
export interface IAuthError {
  type: TAuthError;
  message: string;
}

export interface IUser {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;

  family_name?: string;
  given_name?: string;
  locale?: string;

  // auth0 related
  'https://aspirex.api.com/clients'?: string;
  'https://aspirex.api.com/roles'?: string[];
  'https://aspirex.api.com/gapiToken'?: string;
}

const { useState, useMemo, useCallback, useContext, useLayoutEffect, useEffect } = React;

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const { AUTH_API_AUDIENCE } = process.env;
let AUTH_DOMAIN: string;
let REDIRECT_URI: string;
if (typeof window !== 'undefined') {
  // TODO: FN-787 Move into environment variables.
  // determine auth0 tenant from domain name
  switch (getEnvironment()) {
    case Environment.PRODUCTION:
      // https://manage.auth0.com/dashboard/us/aspireiq/
      AUTH_DOMAIN = 'aspireiq.auth0.com';
      break;
    case Environment.STAGING:
      // https://manage.auth0.com/dashboard/us/aspireiq-staging/
      AUTH_DOMAIN = 'aspireiq-staging.auth0.com';
      break;
    default:
      // https://manage.auth0.com/dashboard/us/aspireiq-development/
      AUTH_DOMAIN = 'aspireiq-development.auth0.com';
  }

  REDIRECT_URI = `${window.location.origin}/auth_redirect`;
}

/**
 * Helper function which converts query string to json.
 */
const queryStringToJSON = () => {
  const pairs = location.search.slice(1).split('&');

  const result = {};
  pairs.forEach((pair) => {
    const parts = pair.split('=');
    result[parts[0]] = decodeURIComponent(parts[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

export const AuthContext = React.createContext<IAuthContext>(null);
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ clientInfo, children }) => {
  const posthog = usePostHog();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<IUser>(null);
  const [token, setToken] = useState<string>(null);
  const [authClient, setAuthClient] = useState<Auth0Client>(null);
  const [authError, setAuthError] = useState<IAuthError>(null);
  const [targetUrl, setTargetUrl] = useState<string>(null);

  const isGuest = useMemo(() => {
    if (!user) {
      return false;
    }
    const roles = user['https://aspirex.api.com/roles'];
    return isEmpty(intersection(roles, ['manager:default', 'manager:admin']));
  }, [user]);

  const loginWithRedirect = useCallback(
    async (pathBeforeRedirect?: string): Promise<void> => {
      await authClient.loginWithRedirect({
        redirect_uri: `${REDIRECT_URI}?id=${clientInfo?.id}`,
        prompt: 'select_account',
        appState: { targetUrl: pathBeforeRedirect },
      });
    },
    [authClient, clientInfo],
  );

  const logout = useCallback(() => {
    posthog.reset();
    if (authClient) {
      authClient.logout({
        returnTo: REDIRECT_URI,
      });
    } else {
      const auth0Client = new Auth0Client({
        audience: AUTH_API_AUDIENCE,
        domain: AUTH_DOMAIN,
        client_id: clientInfo.id,
        redirect_uri: REDIRECT_URI,
        leeway: 30,
        useRefreshTokens: true,
        cacheLocation: window.isUnderTest ? 'localstorage' : 'memory',
      });
      auth0Client.logout({
        returnTo: REDIRECT_URI,
      });
    }
  }, [authClient, clientInfo, posthog]);

  useIsomorphicLayoutEffect(() => {
    const onRedirectCallback = (appState) => {
      const savedParams = localStorage.getItem('savedParams');
      const queryParams = savedParams ? `?${savedParams}` : '';
      const newUrl = window.location.pathname + queryParams;

      if (appState && appState.targetUrl) {
        setTargetUrl(appState.targetUrl + queryParams);
      }
      window.history.replaceState({}, document.title, newUrl);
    };

    const initAuth = async () => {
      let authClient;
      try {
        authClient = await createAuthClient({
          audience: AUTH_API_AUDIENCE,
          domain: AUTH_DOMAIN,
          client_id: clientInfo.id,
          redirect_uri: REDIRECT_URI,
          leeway: 30,
          useRefreshTokens: true,
          cacheLocation: window.isUnderTest ? 'localstorage' : 'memory',
        });
      } catch (e) {
        setLoading(false);
        setIsAuthenticated(false);
        if (e.error === 'unauthorized') {
          setAuthError({
            type: e.error,
            message: e.error_description,
          });
        } else {
          logger.error('Internal error when authenticating with auth0:', e);
          setAuthError({
            type: 'internal_error',
            message: 'An unexpected error has occurred. Please contact help@aspireiq.com if this continues.',
          });
        }
        return;
      }

      setAuthClient(authClient);

      const search = queryStringToJSON();
      // if this is a 302
      if (search.error) {
        setAuthError({
          type: search.error,
          message: search.error_description,
        });
      } else if (search.code) {
        const { appState } = await authClient.handleRedirectCallback();
        onRedirectCallback(appState);
      } else {
        const paramsToSave = new URLSearchParams(window.location.search);
        paramsToSave.delete('code');
        paramsToSave.delete('state');
        localStorage.setItem('savedParams', paramsToSave.toString());
      }

      // check if user is authenticated
      // 1. from successful login redirect 2. from cache (refresh page)
      const isAuthenticated = await authClient.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await authClient.getUser();
        const token = await authClient.getTokenSilently();

        setUser(user);
        setToken(token);

        if (typeof DD_RUM !== 'undefined') {
          DD_RUM.onReady(function () {
            DD_RUM.setUser({
              id: user.sub,
              name: user.name,
              email: user.email,
              clientId: clientInfo?.id,
              clientName: clientInfo?.name,
            });
          });
        }
      }

      setLoading(false);
    };

    initAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isGuest,
        clientInfo,
        loading,
        isAuthenticated,
        user,
        token,
        authError,
        targetUrl,
        loginWithRedirect,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
