import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';

interface CellWithTooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  maxLength?: number;
  alwaysShowTooltip?: boolean;
}

export function CellWithTooltip({ value, maxLength = 25, alwaysShowTooltip = true }: CellWithTooltipProps) {
  // Return early for empty values
  if (!value) return <span>-</span>;

  // Convert non-string values to string
  const stringValue = typeof value !== 'string' ? String(value) : value;

  // Determine if we need to truncate
  const shouldTruncate = stringValue.length > maxLength;
  const displayText = shouldTruncate ? `${stringValue.substring(0, maxLength)}...` : stringValue;

  // If we should always show tooltip or text is truncated, wrap in tooltip
  if (alwaysShowTooltip || shouldTruncate) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-default whitespace-nowrap overflow-hidden text-ellipsis">{displayText}</span>
        </TooltipTrigger>
        <TooltipContent className="max-w-md whitespace-normal break-words">{stringValue}</TooltipContent>
      </Tooltip>
    );
  }

  // Fall back to simple display
  return <span>{displayText}</span>;
}
