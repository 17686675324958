import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { HandsClappingIcon, CircleInfoIcon } from '@revfluence/fresh-icons/solid/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import {} from '@radix-ui/react-tooltip';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
  HighlightCardHeaderRow,
} from '../../../components/highlight-cards';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemValue,
  TooltipArrow,
  TooltipContent,
} from '../../../components/tooltip';

export const engagementCardFragment = graphql(`
  fragment EngagementCard_TotalInsights on TotalInsights {
    engagements
  }
`);

interface EngagementCardProps {
  data: FragmentType<typeof engagementCardFragment> | null | undefined;
}

const EngagementCard: React.FC<EngagementCardProps> = ({ data }) => {
  const totalInsights = useFragment(engagementCardFragment, data);
  const value = totalInsights?.engagements ?? 0;

  return (
    <TooltipProvider>
      <Card>
        <HighlightCardHeader className="bg-pink-6" role="region" aria-label="Engagement metrics">
          <HighlightCardHeaderRow>
            <HighlightCardTitle>
              <HandsClappingIcon className="mr-2 text-[1.5rem]" aria-hidden="true" />
              <span className="text-base">Engagement</span>
            </HighlightCardTitle>
            <HighlightCardTitle>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    type="button"
                    aria-label="View engagement metrics information"
                    data-dd-action-name="view-engagement-info"
                  >
                    <CircleInfoIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </TooltipTrigger>
                <TooltipContent role="tooltip" aria-live="polite">
                  <TooltipHeader>
                    <TooltipTitle>Engagements</TooltipTitle>
                  </TooltipHeader>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemValue>Total likes, comments, saves, and shares. for any available post type.</TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </HighlightCardTitle>
          </HighlightCardHeaderRow>
        </HighlightCardHeader>
        <HighlightCardContent>
          <HighlightCardContentRow>
            <HighlightCardContentColumn>
              <HighlightCardStatRow>
                <HighlightCardStat
                  className="text-3xl leading-none"
                  role="status"
                  aria-label={`Total engagement count: ${formatNumberDefault(value)}`}
                  data-dd-action-name="engagement-stat"
                >
                  {formatNumberDefault(value)}
                </HighlightCardStat>
              </HighlightCardStatRow>
              <HighlightCardStatLabel>Total Engagement</HighlightCardStatLabel>
            </HighlightCardContentColumn>
          </HighlightCardContentRow>
        </HighlightCardContent>

        {/* Hidden table for screen readers with detailed engagement metrics */}
        <div className="sr-only">
          <table>
            <caption>Engagement Metrics by Platform</caption>
            <tbody>
              <tr>
                <td>Total likes, comments, saves, and shares. for any available post type.</td>
              </tr>
              <tr>
                <th scope="row">Total Engagements</th>
                <td>{formatNumberDefault(value)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </TooltipProvider>
  );
};

export default EngagementCard;
