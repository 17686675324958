import 'css-chunk:src/applications/ReportsApp/components/Card/Card.module.scss';export default {
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "card": "_card_1ryan_19",
  "dark": "_dark_1ryan_57",
  "icon": "_icon_1ryan_63",
  "title": "_title_1ryan_68"
};