import { UserAvatar } from '@frontend/app/components';
import * as React from 'react';
import { useState } from 'react';
import { CaretRightIcon } from '@revfluence/fresh-icons/solid';
import { Avatar, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { ArrowLeft } from 'lucide-react';
import { TabComponent } from '@frontend/app/refresh-components';
import { InstagramMenuAlert } from '@frontend/app/components';
import HeaderButton from './HeaderButton';

export interface HeaderTabItem {
  name: string;
  label: string;
  content?: React.ReactNode;
  disabled?: boolean;
}

interface IAppHeaderProps {
  title?: string;
  subTitle?: string;
  userImage?: string;
  memberName?: string;
  backButtonFunction?: () => void;
  icon?: React.ReactChild;
  imgIconUrl?: string;
  actions?: React.ReactNode[];
  tabs?: HeaderTabItem[];
  defaultTab?: string;
  handleTabClick?: (tab: string) => void;
  customTitle?: React.ReactNode;
  offerImage?: React.ReactNode;
}

export const AppHeader: React.FunctionComponent<IAppHeaderProps> = ({
  title,
  subTitle,
  icon,
  imgIconUrl,
  actions,
  userImage,
  memberName,
  backButtonFunction,
  tabs,
  handleTabClick,
  defaultTab,
  customTitle,
  offerImage,
}) => {
  const [activeKey, setActiveKey] = useState(defaultTab);

  const handleClick = (tab: string) => {
    setActiveKey(tab);
    handleTabClick(tab);
  };

  if (customTitle) {
    return (
      <div className="px-8 py-4 bg-primary">
        {customTitle}
      </div>
    );
  }

  return (
    <div className="px-8 bg-primary" id="refresh-app-header">
      <div className="flex justify-between items-center py-4">
        <div className="flex items-center space-x-2">
          {backButtonFunction && (
            <HeaderButton
              onClick={backButtonFunction}
              icon={<ArrowLeft className="h-4 w-4" />}
            />
          )}
          {icon && <span className="text-primary-foreground">{icon}</span>}
          {imgIconUrl && (
            <>
              <Avatar className="h-12 w-12 rounded-md">
                <AvatarImage src={imgIconUrl} className="object-cover" />
              </Avatar>
            </>
          )}
          {offerImage && offerImage}
          {(userImage || memberName) && <UserAvatar profilePicture={userImage} name={memberName} />}

          {/* TODO Replace with H1 component */}
          <h1 className="scroll-m-20 text-xl m-0 text-primary-foreground font-inter flex items-center">
            <span>
              {title}
            </span>
            {subTitle && (
              <>
                <CaretRightIcon className="mx-2 size-4" />
                {subTitle === 'Instagram (Meta)' ? (
                  <InstagramMenuAlert>{subTitle}</InstagramMenuAlert>
                ) : (
                  <span className="font-normal">{subTitle}</span>
                )}
              </>
            )}
          </h1>
        </div>
        {actions && <div className="flex space-x-4">{actions}</div>}
      </div>
      {tabs && (
        <div className="-ml-8">
          <TabComponent tabs={tabs} activeTab={activeKey} onTabChange={handleClick} hideContent />
        </div>
      )}
    </div>
  );
};
