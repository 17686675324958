import * as React from 'react';
import { message, Upload, UploadProps } from 'antd';
import { isFunction } from 'lodash';
import { Avatar, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { ArrowUpFromLine } from 'lucide-react';
import { RefreshOfferDefaultImage } from '@frontend/applications/AffiliatesApp/components/OfferImage/RefreshOfferDefaultImage';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { IconSelector } from './IconSelector';
import { FormAsyncAction, OfferFormAsyncActions } from '../../../../types';
import { OfferInputHeader } from './OfferElementHeader';
import { IconOption, iconOptions } from './iconMapping';

interface IProps {
  onChangeImage?: (file: File) => void;
  val: string;
  source: OFFER_SOURCE;
  setOfferImage: (url: string) => void;
  handleFormAsyncActions: (value: FormAsyncAction) => void,
  icon: string | null;
}

function getBase64(img): Promise<string | ArrayBuffer> {
  const reader = new FileReader();
  reader.readAsDataURL(img);
  return new Promise((resolve) => {
    reader.addEventListener('load', () => resolve(reader.result));
  });
}

export const RefreshOfferImage: React.FC<Readonly<IProps>> = React.memo(({
  onChangeImage,
  val,
  source,
  setOfferImage,
  handleFormAsyncActions,
  icon,
}) => {
  const [imageUrl, setImageUrl] = React.useState<string>(val);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [uploadType, setUploadType] = React.useState<'image' | 'icon'>('image');
  const [selectedIconName, setSelectedIconName] = React.useState<string | null>(icon);
  // Helper function to get icon option from name
  const getIconOptionByName = (name: string | null): IconOption | undefined => {
    if (!name) return undefined;
    return iconOptions.find((opt) => opt.name === name);
  };

  const handleChange = React.useCallback<UploadProps['onChange']>(async (info) => {
    const tmpImageUrl = await getBase64(info.file.originFileObj);
    setImageUrl(tmpImageUrl as string);
    setSelectedIconName(null); // Clear icon when image is uploaded
    setOfferImage(tmpImageUrl as string);
    onChangeImage(info.file.originFileObj as File);
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'imageUrl', value: tmpImageUrl as string },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'icon', value: null },
    });
    setIsPopoverOpen(false);
  }, [setImageUrl, onChangeImage, handleFormAsyncActions, setOfferImage]);

  const handleIconSelect = (icon: IconOption) => {
    setSelectedIconName(icon.name);
    setImageUrl(''); // Clear image when icon is selected
    setOfferImage('');
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'imageUrl', value: '' },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'icon', value: icon.name },
    });
    setIsPopoverOpen(false);
  };

  const clearImage = () => {
    setImageUrl('');
    setSelectedIconName(null);
    setOfferImage('');
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'imageUrl', value: '' },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'icon', value: '' },
    });
  };

  const renderUploadContent = () => {
    if (uploadType === 'icon') {
      return <IconSelector onSelectIcon={handleIconSelect} />;
    }

    const fileInfo = [
      'Supported File Format: PNG, JPEG, JPG',
      'Maximum File Size: 2 MB',
    ];

    if (imageUrl) {
      return (
        <div className="flex flex-col items-start gap-2 p-4">
          <Card className="w-full bg-grey-1 border-dashed border-grey-3">
            <CardContent className="flex flex-col items-center justify-center gap-4 p-4">
              <div className="relative w-full rounded-lg overflow-hidden flex flex-col">
                <img
                  src={imageUrl}
                  alt="Selected offer"
                  className="w-[150px] h-auto object-cover rounded-lg m-auto"
                />
                <Button
                  variant="link"
                  className="hover:bg-white/100 text-red-500 px-3 pt-2 text-sm rounded-md w-fit"
                  onClick={(e) => {
                    e.preventDefault();
                    clearImage();
                  }}
                >
                  Remove
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-start gap-2 p-4">
        <Card className="w-full bg-grey-1 border-dashed border-grey-3">
          <CardContent className="flex flex-col items-center justify-center gap-4 px-4 py-12">
            <Upload
              disabled={!isFunction(onChangeImage)}
              data-testid="file-upload"
              onChange={handleChange}
              accept="image/png, image/jpeg"
              showUploadList={false}
              multiple={false}
              beforeUpload={(file) => {
                const isValidType = file.type === 'image/png' || file.type === 'image/jpeg';
                const isValidSize = file.size / 1024 / 1024 <= 2;

                if (!isValidType) {
                  message.error('Only PNG and JPG files are allowed.');
                  return Upload.LIST_IGNORE;
                }

                if (!isValidSize) {
                  message.error('File size must be less than 2MB.');
                  return Upload.LIST_IGNORE;
                }

                return true;
              }}
            >
              <Button
                variant="outline"
                className="h-8 px-4 py-[5px] bg-grey-0 border-grey-2 text-grey-5 font-semibold-14px-22px"
              >
                <ArrowUpFromLine className="w-3 h-3 mr-2" />
                Upload Image
              </Button>
            </Upload>
            <div className="flex flex-col items-center w-full">
              {fileInfo.map((info, index) => (
                <p key={index} className="text-grey-4 font-regular-75-caption text-center">
                  {info}
                </p>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderImageContent = () => {
    if (selectedIconName) {
      return (
        <div className={`w-[48px] h-[48px] rounded-lg flex items-center justify-center ${source === OFFER_SOURCE.SHOPIFY ? 'bg-blue-4' : 'bg-magenta-4'}`}>
          <FontAwesomeIcon
            icon={getIconOptionByName(selectedIconName)?.icon}
            className="h-4 w-4 text-grey-0"
          />
        </div>
      );
    }

    if (imageUrl) {
      return (
        <Avatar className="w-[48px] h-[48px] rounded-md">
          <AvatarImage
            src={imageUrl}
            className="object-cover rounded-md"
          />
        </Avatar>
      );
    }

    return (
      <RefreshOfferDefaultImage
        imageUrl={imageUrl}
        source={source}
        icon={icon}
        className="w-[48px] h-[48px]"
        iconClassName="h-6 w-6"
      />
    );
  };

  return (
    <div data-testid="refresh-offer-image">
      <OfferInputHeader text="Image (optional)" tooltipText="image" />
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <div className="flex flex-col items-center border-2 py-3 px-6 border-dashed border-grey-2 rounded-xl">
            {renderImageContent()}
            <Button
              type="button"
              variant="link"
              className="mt-2 hover:bg-grey-2 rounded-lg"
            >
              Change
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0 rounded-xl">
          <header className="flex items-center justify-end gap-2.5 px-4 py-2">
            <div className="flex flex-col items-center gap-2.5 flex-1 grow rounded-xl">
              <ToggleGroup
                type="single"
                value={uploadType}
                onValueChange={(value) => setUploadType(value as 'image' | 'icon')}
                className="h-8 w-full bg-grey-2 rounded-lg flex items-center px-2 py-1"
              >
                <ToggleGroupItem
                  value="icon"
                  className="flex-1 h-full rounded-none py-1 data-[state=off]:bg-transparent data-[state=on]:bg-grey-0 data-[state=on]:rounded-lg"
                >
                  <span className="font-medium text-xs text-grey-6">Icon</span>
                </ToggleGroupItem>
                <ToggleGroupItem
                  value="image"
                  className="flex-1 h-full rounded-none py-1 data-[state=off]:bg-transparent data-[state=on]:bg-grey-0 data-[state=on]:rounded-lg"
                >
                  <span className="font-medium text-xs text-grey-6">Image</span>
                </ToggleGroupItem>
              </ToggleGroup>
            </div>
            <Button variant="outline" size="smallIcon" type="button" onClick={() => setIsPopoverOpen(false)} className="cursor-pointer hover:border-grey-5 hover:text-grey-5">
              <XmarkIcon />
            </Button>
          </header>
          {renderUploadContent()}
        </PopoverContent>
      </Popover>
    </div>
  );
});

RefreshOfferImage.displayName = 'RefreshOfferImage';
