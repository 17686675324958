import React from 'react';
import { ImageIcon, MegaphoneIcon, RocketIcon } from '@revfluence/fresh-icons/regular/esm';
import aspireIcon from '@frontend/app/assets/svgs/aspire_icon.svg';
import { TemplateName } from '../TemplatesPage/TemplatesPage';
import { CustomProjectTemplateName } from '../constants';
import { TWorkletWithoutTypename } from './CampaignContext';

export const getCampaignDescription = (templateName: string) => {
  switch (templateName) {
    case TemplateName.ProductGifting:
      return 'Spread the word of your latest product.';
    case TemplateName.AmbassadorProject:
      return 'Increase brand awareness with long term partnerships.';
    case TemplateName.InfluencerCampaign:
      return 'Generate content in exchange for compensation.';
    case CustomProjectTemplateName:
      return 'Create a unique campaign for your own workflow.';
    default:
      return '';
  }
};

export const getCampaignIcon = (templateName: string) => {
  switch (templateName) {
    case TemplateName.ProductGifting:
      return <RocketIcon fontSize={16} />;
    case TemplateName.AmbassadorProject:
      return <MegaphoneIcon fontSize={16} />;
    case TemplateName.InfluencerCampaign:
      return <ImageIcon fontSize={16} />;
    case CustomProjectTemplateName:
      return <img src={aspireIcon} className="w-6 h-auto rounded-full" />;
    default:
      return null;
  }
};

export const hasWorkletsChanged = (worklets: TWorkletWithoutTypename[], contextWorklets: TWorkletWithoutTypename[]) => {
  if (worklets.length !== contextWorklets.length) {
    return true;
  }

  return worklets.some((worklet, index) => worklet.specKey !== contextWorklets[index].specKey);
};
