._FilterItem_pocha_1 {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #f5f5f5;
  font-weight: 600;
}
._FilterItem_pocha_1:active, ._FilterItem_pocha_1:focus, ._FilterItem_pocha_1:hover {
  background-color: var(--primary-10);
  border-color: var(--primary-10);
  color: var(--primary-40);
}
._FilterItem_pocha_1:active .anticon, ._FilterItem_pocha_1:focus .anticon, ._FilterItem_pocha_1:hover .anticon {
  color: var(--primary-40);
}
._FilterItem_pocha_1:not(:active):not(:focus) {
  border-color: transparent;
}
._FilterItem_pocha_1 > span {
  margin: 0 !important;
}
._FilterItem_pocha_1 > span.anticon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0 calc(var(--spacing-sm) * -1) 0 var(--spacing-xxs) !important;
  color: var(--black);
  transition: color var(--animation-duration-base) var(--ease-in-out-quint);
}
._FilterItem_pocha_1 > span.anticon:hover {
  color: var(--ant-primary);
}