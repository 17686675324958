import * as React from 'react';
import { FC, ReactNode } from 'react';
import { Tooltip } from '@frontend/shadcn/components/ui/tooltip';
import { TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip';
import { P } from '@frontend/shadcn/components/typography/p';
import { CircleInfoIcon } from '@revfluence/fresh-icons/solid/esm';

interface OfferInputHeaderProps {
  text: string;
  tooltipText?: string;
  className?: string;
}

interface OfferInputHeaderWithBtnProps {
  text: string;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
}

export const OfferInputHeader: FC<OfferInputHeaderProps> = ({
    text,
    tooltipText,
    className,
}) => (
  <div className={`flex items-center mb-2 gap-2 ${className}`}>
    <P className="text-xs font-medium text-primary" fontWeight="font-medium">{text}</P>
    {tooltipText && (
    <Tooltip>
      <TooltipTrigger asChild>
        <CircleInfoIcon className="h-3 w-3 text-grey-4 cursor-pointer" />
      </TooltipTrigger>
      <TooltipContent className="max-w-xs break-words text-center bg-grey-6 text-grey-0 p-[6px] rounded-lg"><span>{tooltipText}</span></TooltipContent>
    </Tooltip>
            )}
  </div>
    );

export const OfferInputHeaderWithBtn: FC<OfferInputHeaderWithBtnProps> = ({
    text,
    onClick,
    icon,
    className,
}) => (
  <div className={`flex items-center mb-2 cursor-pointer gap-2 ${className}`} onClick={onClick}>
    {onClick && <div>{icon}</div>}
    <P className="text-sm font-medium text-primary underline" fontWeight="font-medium">{text}</P>
  </div>
    );
