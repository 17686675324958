import * as React from 'react';
import { useCommunitiesQuery, useFetchTags, useGetUsersQuery, useProgramsQuery } from '@frontend/app/hooks';
import { TagEntityType } from '@frontend/app/types/globalTypes';
import { useGetBudgetAccounts } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccounts';
import { isNumber } from 'lodash';
import { BudgetStats, BudgetTable, Header } from './components';
import { FilterDrawer } from './components/FilterDrawer';
import { usePayment } from './PaymentContext';
import { PaymentTagDialog } from './components/PaymentTagDialog';
import { EditPaymentDrawer } from './components/EditPaymentDrawer';
import QuickFilterSection from './components/QuickFilterSection';
import { useFetchPaymentSourceData } from '../useFetchPaymentSources';

const { useEffect } = React;

const ReFreshPayment: React.FunctionComponent = () => {
  const {
    setAvailableBalance,
    setUsers,
    setPrograms,
    setTags,
    setBudgets,
    setGroups,
    fetchPaymentDashboardData,
    fetchPayments,
    selectedRows,
    isEditPaymentDrawerOpen,
  } = usePayment();

  const { data: users, loading: isUsersLoading } = useGetUsersQuery();
  const { data: programs, loading: isProgramsLoading } = useProgramsQuery();
  const { loading: isTagsLoading, data: tags } = useFetchTags({
    variables: {
      entityType: TagEntityType.PAYMENT,
    },
  });
  const { budgetAccounts, loading: isBudgetsLoading } = useGetBudgetAccounts({
    variables: {},
  });

  const { loading: isCommunityLoading, data: communityData } = useCommunitiesQuery();

  const { data: paymentSourcesData } = useFetchPaymentSourceData();

  // Update users when available
  useEffect(() => {
    if (!isUsersLoading && users) {
      setUsers(users?.users || []);
    }
  }, [isUsersLoading, users, setUsers]);

  // Update programs when available
  useEffect(() => {
    if (!isProgramsLoading && programs) {
      const sortedPrograms = [...(programs?.programs || [])].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setPrograms(sortedPrograms);
    }
  }, [isProgramsLoading, programs, setPrograms]);

  // Update tags when available
  useEffect(() => {
    if (!isTagsLoading && tags) {
      setTags(tags?.tags || []);
    }
  }, [isTagsLoading, tags, setTags]);

  // Update budgets when available
  useEffect(() => {
    if (!isBudgetsLoading && budgetAccounts) {
      setBudgets(budgetAccounts || []);
    }
  }, [isBudgetsLoading, budgetAccounts, setBudgets]);

  useEffect(() => {
    if (!isCommunityLoading && communityData) {
      setGroups(communityData?.communities || []);
    }
  }, [isCommunityLoading, communityData, setGroups]);

  useEffect(() => {
    if (isNumber(paymentSourcesData?.balance_in_cents)) {
      setAvailableBalance(paymentSourcesData.balance_in_cents / 100);
    }
  }, [paymentSourcesData, setAvailableBalance]);

  // Fetch payment dashboard data and payments on initial load
  useEffect(() => {
    fetchPaymentDashboardData();
    fetchPayments();
  }, [fetchPaymentDashboardData, fetchPayments]);

  return (
    <>
      <Header />
      <div className="p-4 flex flex-col gap-4">
        <QuickFilterSection />
        <BudgetStats />
      </div>
      <div className="p-4 pt-0 w-full">
        <BudgetTable />
      </div>
      <FilterDrawer />
      {
        // @ts-ignore
        selectedRows.length > 0 && isEditPaymentDrawerOpen && <EditPaymentDrawer />
      }
      <PaymentTagDialog />
    </>
  );
};

export default ReFreshPayment;
