import * as React from 'react';
import cx from 'classnames';
import {
  isBoolean,
  get,
} from 'lodash';

import {
  Action as IAction,
} from './PaginationReducer';

import { Content } from './Content/Content';
import { Header } from './Header/Header';
import { useDetailedSocialAccount } from './hooks/useDetailedSocialAccount';
import { useSocialProfileContext } from './hooks/useSocialProfileContext';
import { ISocialProfileOverlayProps } from './SocialProfileOverlay';

const { useEffect } = React;
import styles from './SocialProfile.scss';

interface IProps extends ISocialProfileOverlayProps {
  headerActions: JSX.Element;
  paginationDispatch?: (value: IAction) => void;
}

export const SocialProfile: React.FC<IProps> = (props) => {
  const {
    socialAccount: socialAccountProp,
    brandInstagramUsername,
    headerActions,
    caller,
    showPlaceholder,
    paginationDispatch,
  } = props;
  const id = get(socialAccountProp, 'id', null);

  const {
    apiEndpoint,
    clientId,
    loadAccountDetails,
    network,
    reset,
    setDetailedSocialAccount,
    setFetchingDetailForId,
    setSocialAccount,
    show,
    showToastMessage,
  } = useSocialProfileContext();
  const {
    isFetching,
    detailedSocialAccount,
    error,
  } = useDetailedSocialAccount({
    apiEndpoint,
    id: loadAccountDetails ? id : null,
    brandInstagramUsername,
    clientId,
    caller,
  });

  // Reset on hidden state
  useEffect(() => {
    if (isBoolean(show) && !show) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // Update fetching id
  useEffect(() => {
    setFetchingDetailForId(isFetching ? id : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isFetching]);

  // Trigger toast on fetch error
  useEffect(() => {
    if (error) {
      showToastMessage({
        type: 'error',
        content: 'Unable to retrieve social account details',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // Update social account with detailedSocialAccount
  // Get raw 'id' value for a more reliable hook dependency check, instead of checking the whole object
  const detailedSocialAccountId = detailedSocialAccount?.id ?? null;
  useEffect(() => {
    if (detailedSocialAccountId) {
      setDetailedSocialAccount(detailedSocialAccount);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailedSocialAccountId]);

  // Set new socialAccount on prop update
  const username = socialAccountProp?.username;
  const detailedSocialAccountUsername = detailedSocialAccount?.username ?? null;
  useEffect(
    () => {
      if (
        (id && id !== detailedSocialAccountId)
        || (username && username !== detailedSocialAccountUsername)
      ) {
        setSocialAccount(socialAccountProp);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, username],
  );

  return (
    <div className={cx(styles.SocialProfile, styles[network])}>
      <Header
        paginationDispatch={paginationDispatch}
        headerActions={headerActions}
      />
      <Content showPlaceholder={showPlaceholder} />
    </div>
  );
};

SocialProfile.displayName = 'SocialProfile';
