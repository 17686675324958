import React from 'react';
import { BadgeProps } from '@frontend/shadcn/components/ui/badge';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Tooltip, TooltipTrigger, TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemKey,
  TooltipItemValue,
  TooltipContent,
} from '../../../components/tooltip';
import { DismissableBadge } from './dismissable-badge';
import { Segment } from '../../../../../gql/social/graphql';

interface SegmentBadgeProps extends BadgeProps {
  segments?: Segment[] | null;
  onDismiss: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SEGMENT_RANGES = {
  brandFans: 'with < 2,500 followers',
  nano: 'with < 25,000 followers',
  micro: 'with < 60,000 followers',
  midTier: 'with < 250,000 followers',
  macro: 'with < 1,000,000 followers',
  mega: 'with >= 1,000,000 followers',
  unknown: 'Unknown',
};

export const SegmentBadge = React.forwardRef<HTMLDivElement, SegmentBadgeProps>(({ segments, onDismiss, ...props }, ref) => {
  if (!segments || segments.length === 0) return null;

  return (
    <>
      {segments.map((segment) => {
        const segmentRange = SEGMENT_RANGES[segment];
        if (!segmentRange) return null;

        return (
          <TooltipProvider key={segment}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <DismissableBadge
                    ref={segments.indexOf(segment) === 0 ? ref : undefined}
                    key={segment}
                    variant="secondary"
                    className="mr-2 mb-2 cursor-default"
                    aria-label={`Segment filter: ${segment}`}
                    role="button"
                    data-dd-action-name="open-analytics-filters"
                    {...props}
                  >
                    <span>{segment}</span>
                    <button
                      onClick={onDismiss}
                      className="ml-1 h-4 w-4 rounded-full hover:bg-muted/50 flex items-center justify-center"
                      aria-label="Remove filter"
                      data-dd-action-name="dismiss-segment-filter"
                    >
                      <Cross2Icon className="h-3 w-3" />
                    </button>
                  </DismissableBadge>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <TooltipHeader>
                  <TooltipTitle>{segment}</TooltipTitle>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemKey>Range</TooltipItemKey>
                      <TooltipItemValue>{segmentRange}</TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                </TooltipHeader>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      })}
    </>
  );
});

export default SegmentBadge;

SegmentBadge.displayName = 'SegmentBadge';
