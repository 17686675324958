import * as React from 'react';
import { Image } from '@components';
import { encodeUrlFilename } from '@frontend/applications/TermsApp/components/BulkTerms/utils/contentTypeUtils';
import useConvertToJpg from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage/utils/useConvertHeicToJpg';
import { IPreviewerContent } from './contentPreviewerModel';
import { VideoContentDetail } from './Content/VideoContentDetail';
import { getDownloadableMedia } from './utils/getContentImage';
import styles from './ContentPreviewer.scss';

const { useState, useEffect } = React;

type IProps = {
  content: IPreviewerContent;
};

export const ContentDetailItem = (props: IProps) => {
  const { content } = props;
  const [mimeType, setMimeType] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const convertedToJpg = useConvertToJpg(encodeUrlFilename(getDownloadableMedia(content)));

  useEffect(() => {
    const determineMimeType = async (content: IPreviewerContent) => {
      if (content.type === 'application') {
        const response = await fetch(content.src);

        if (!response.ok) {
          throw new Error('Failed to fetch media url');
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        setMimeType(blob.type);
        setPreviewUrl(url);
      }
    };

    determineMimeType(content);
  }, [content]);

  const renderContent = () => {
    switch (content.type) {
      case 'video': {
        return (
          <VideoContentDetail
            key={content.src}
            src={content.src}
            showDuration
            controls
            className={styles.item}
          />
        );
      }

      case 'image': {
        return <Image src={convertedToJpg} className={styles.item} />;
      }

      case 'application': {
        if (mimeType === 'application/pdf') {
          return <embed src={previewUrl} className={styles.item} />;
        } else {
          return <div className={styles.notSupportPreviewMsg}>Preview not supported. Please download the file to view.</div>;
        }
      }

      default: {
        return <div className={styles.notSupportPreviewMsg}>Preview not supported. Please download the file to view.</div>;
      }
    }
  };

  if (content.type === 'application' && !mimeType) return null;

  return (
    <>{ renderContent() }</>
  );
};
