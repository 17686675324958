import React, { useMemo } from 'react';
import { lowerCase, map } from 'lodash';
import { Tag } from '@revfluence/fresh';
import styles from './CreatorProfile.scss';

interface ITag {
  tagName?: string;
  tagColor?: string;
}

interface TagsProps {
  tags: string[];
  eligibleTags: ITag[];
}

const Tags: React.FC<TagsProps> = ({ tags, eligibleTags = [] }) => {
  const tagsToShow = useMemo(() =>
    Object.values(eligibleTags || {}).filter((tag) =>
      new Set(tags.map(lowerCase)).has(lowerCase(tag.tagName))
    ), [tags, eligibleTags]);

  return tagsToShow.length ? (
    <div className={styles.tags}>
      {map(tagsToShow, (tag) => (
        <Tag key={tag.tagName} color={tag.tagColor}>{tag.tagName}</Tag>
      ))}
    </div>
  ) : null;
};

export default Tags;
