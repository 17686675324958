import React, { useMemo, useState } from 'react';
import {
 WidgetContainer, DataTable, Switcher, Empty,
} from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';

import {
  FileDown,
} from 'lucide-react';
import { ExportToCsv } from 'export-to-csv';
import { cva } from 'class-variance-authority';
import { Muted } from '@frontend/shadcn/components/typography/muted';
import { useGetStatsForAllProjects } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetStatsForAllProjects';
import { useHistory } from 'react-router-dom';
import { ArrowRightIcon, BoxOpenIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatDistanceStrict } from 'date-fns';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { cn } from '@/shadcn/lib/utils';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';
import { ProductFulfillmentDashboardTabEnum } from '../containers/ProductFulfillmentDashboard/constants';
import { EllipsisLabel } from '../refresh-components/EllipsisLabel';

type RowItem = {
  projectId: number;
  projectName: string;
  projectImage?: string;
  userId?: string;
  userName?: string;
  invited: number;
  waitingForCreator: number;
  directGifting: number;
  submitted: number;
  accepted: number;
  pending: number;
  waitingForFulfillment: number;
  inTransit: number;
  delivered: number;
};

const headerVariants = cva(
  'font-semibold text-[#1F1F21] flex items-center h-full -mx-2 -mb-px',
  {
    variants: {
      group: {
        view: 'justify-center',
      },
    },
  },
);

interface PFAFulfillmentTableProps {
  className?: string;
}
export default function PFAFulfillmentTable(props: PFAFulfillmentTableProps) {
  const [selectedTab, setSelectedTab] = useState<Array<'userId' | 'programId'>>(['programId']);

  const { className } = props;

  const { dateRangeSettings, setState } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const distanceInWords = formatDistanceStrict(startDate, endDate);

  const history = useHistory();

  const { statsForAllProjects, isStatsForAllProjectsLoading } = useGetStatsForAllProjects({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        groupBy: selectedTab[0],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const data: RowItem[] = useMemo<RowItem[]>(() => statsForAllProjects?.map((stat) => ({
      projectId: stat.projectId,
      projectName: stat.projectName,
      projectImage: stat.projectImage,
      userId: stat.userId,
      userName: stat.userName,
      invited: stat.totalInvited,
      waitingForCreator: stat.waitingCreator,
      directGifting: stat.directGifting,
      submitted: stat.submitted,
      accepted: stat.accepted,
      pending: stat.pending,
      waitingForFulfillment: stat.waitingFulfillment,
      inTransit: stat.inTransit,
      delivered: stat.delivered,
    })) || [], [statsForAllProjects]);

  const handleExportToCsv = () => {
    const options = {
      headers: [selectedTab[0] === 'programId' ? 'Project' : 'Owner', 'Invited', 'Waiting For Creator', 'Direct Gifting', 'Submitted', 'Accepted', 'Pending', 'Waiting For Fulfillment', 'In Transit', 'Delivered'],
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Projects Fulfillment Summary',
    };

    const csvExporter = new ExportToCsv(options);
    const dataToExport = data.map((projectData) => ({
      [selectedTab[0] === 'programId' ? 'Project' : 'Owner']:
        selectedTab[0] === 'programId' ? projectData.projectName : projectData.userName,
      invited: projectData.invited,
      waitingForCreator: projectData.waitingForCreator,
      directGifting: projectData.directGifting,
      submitted: projectData.submitted,
      accepted: projectData.accepted,
      pending: projectData.pending,
      waitingForFulfillment: projectData.waitingForFulfillment,
      inTransit: projectData.inTransit,
      delivered: projectData.delivered,
    }));

    csvExporter.generateCsv(dataToExport);
  };

  const columns: ColumnDef<RowItem>[] = [
    ...[selectedTab[0] === 'programId' ? {
        id: 'programId',
        accessorKey: 'projectName',
        header: () => <div className={headerVariants()}>Project</div>,
        size: 250,
        cell: ({ row }) => {
          const projectName = row.original.projectName as string;
          const projectImage = row.original.projectImage as string;
          return (
            <div className="flex gap-1 items-center">
              <Avatar className="h-[24px] w-[24px] rounded-none">
                {projectImage ? (
                  <AvatarImage src={projectImage} className="flex h-full w-full items-center justify-center rounded-lg" />
                ) : (
                  <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-sm text-secondary">
                    <img src="https://storage.googleapis.com/aspireiq-widgets" />
                  </AvatarFallback>
                )}
              </Avatar>
              <EllipsisLabel tooltip={projectName} width={220}>{projectName}</EllipsisLabel>
            </div>
          );
        },
      } : {
        accessorKey: 'userName',
        id: 'userId',
        header: () => <div className={headerVariants()}>Owner</div>,
        cell: ({ row }) => {
          const userName = row.original.userName as string;
          return <div>{userName}</div>;
        },
      },
    ],
    {
      accessorKey: 'invited',
      header: () => <div className={headerVariants()}>Invited</div>,
      cell: ({ row }) => {
        const invited = row.getValue('invited') as string;
        return <div>{invited}</div>;
      },
    },
    {
      accessorKey: 'waitingForCreator',
      header: () => <div className={headerVariants()}>Waiting For Creator</div>,
      cell: ({ row }) => {
        const waitingForCreator = row.getValue('waitingForCreator') as string;
        return <div>{waitingForCreator}</div>;
      },
    },
    {
      accessorKey: 'directGifting',
      header: () => <div className={headerVariants()}>Direct Gifting</div>,
      cell: ({ row }) => {
        const directGifting = row.getValue('directGifting') as string;
        return <div>{directGifting}</div>;
      },
    },
    {
      accessorKey: 'submitted',
      header: () => (
        <div
          className={headerVariants()}
        >
          Submitted
        </div>
      ),
      cell: ({ row }) => {
        const submitted = row.getValue('submitted') as string;
        return <div>{submitted}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-[#55A2DF]',
        },
      },
    },
    {
      accessorKey: 'accepted',
      header: () => (
        <div
          className={headerVariants()}
        >
          Accepted
        </div>
      ),
      cell: ({ row }) => {
        const accepted = row.getValue('accepted') as string;
        return <div>{accepted}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-[#55A2DF]',
        },
      },
    },
    {
      accessorKey: 'pending',
      header: () => (
        <div
          className={headerVariants()}
        >
          Pending
        </div>
      ),
      cell: ({ row }) => {
        const pending = row.getValue('pending') as string;
        return <div>{pending}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-[#55A2DF]',
        },
      },
    },
    {
      accessorKey: 'waitingForFulfillment',
      header: () => <div className={headerVariants()}>Waiting For Fulfillment</div>,
      cell: ({ row }) => {
        const waitingForFulfillment = row.getValue('waitingForFulfillment') as string;
        return <div>{waitingForFulfillment}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-[#E2AB23]',
        },
      },
    },
    {
      accessorKey: 'inTransit',
      header: () => <div className={headerVariants()}>In Transit</div>,
      cell: ({ row }) => {
        const inTransit = row.getValue('inTransit') as string;
        return <div>{inTransit}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-[#7D1C53]',
        },
      },
    },
    {
      accessorKey: 'delivered',
      header: () => <div className={headerVariants()}>Delivered</div>,
      cell: ({ row }) => {
        const delivered = row.getValue('delivered') as string;
        return <div>{delivered}</div>;
      },
      meta: {
        headerGroupBorder: {
          borderColor: 'border-b-[#74965A]',
        },
      },
    },
    {
      id: 'view',
      accessorKey: 'projectId',
      header: () => <div className={headerVariants({ group: 'view' })}>View</div>,
      size: 80,
      cell: ({ row }) => {
        const projectId = row.original.projectId;
        const userId = row.original.userId;

        if (!projectId && !userId) {
          return null;
        }

        const params = new URLSearchParams();
        params.set('tab', ProductFulfillmentDashboardTabEnum.Orders);

        const setFilters = () => {
          if (selectedTab[0] === 'userId') {
            setState({
              ownerIds: [userId],
              localOwnerIds: [userId],
            });
          }
          if (selectedTab[0] === 'programId') {
            setState({
              projectIds: [projectId],
              localProjectIds: [projectId],
            });
          }
        };

        return (
          <div className="flex items-center justify-center">
            <Button
              size="xs"
              variant="outline"
              onClick={() => {
                history.push({ search: params.toString() });
                setFilters();
              }}
              className="w-6 px-0"
              data-tooltip-id={`pfa-ff-tooltip-${projectId || userId}`}
              data-tooltip-content={`View ${selectedTab[0] === 'programId' ? 'Project' : 'Owner'} Orders`}
            >
              <ArrowRightIcon fontSize={12} />
            </Button>
            <ReactTooltip
              id={`pfa-ff-tooltip-${projectId || userId}`}
              place="top"
            />
          </div>
        );
      },
    },
  ];

  const widgetActions = (
    <div className="flex items-center gap-2">
      <Button size="icon" variant="outline" onClick={handleExportToCsv}>
        <FileDown className="h-4 w-4" />
      </Button>
      <Switcher
        items={[
          { label: 'Projects', key: 'programId' },
          { label: 'Owners', key: 'userId' },
        ]}
        type="single"
        activeKeys={selectedTab}
        onChange={(keys) => setSelectedTab(keys as Array<'userId' | 'programId'>)}
      />
    </div>
  );

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={BoxOpenIcon}
        title="No Orders Yet"
        description={`It looks like you haven't created any orders in the last ${distanceInWords}. Try adjusting the date range filter.`}
      />
    </div>
  );
  const mainContent = (
    <>
      <DataTable
        columns={columns}
        data={data}
        columnPinning={{
          left: selectedTab,
          right: ['view'],
        }}
        maxHeight="471px"
      />
      <div className="my-6 ml-3 flex gap-4">
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 rounded bg-[#55A2DF]" />
          <Muted>Review Status</Muted>
        </div>
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 rounded bg-[#E2AB23]" />
          <Muted>Fulfillment Status</Muted>
        </div>
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 rounded bg-[#74965A]" />
          <Muted>Delivery Status</Muted>
        </div>
      </div>
    </>
  );

  return (
    <WidgetContainer
      widgetTitle="Project Fulfillment Summary"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isStatsForAllProjectsLoading ? loadingContent : data.length ? mainContent : emptyContent}
    </WidgetContainer>
  );
}
