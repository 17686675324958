import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import React from 'react';
import { Tag } from '@frontend/app/refresh-components';

const statusColor = {
  'Review Needed': {
    bgColor: 'bg-yellow-2',
    textColor: 'text-[#16282D]',
  },
  'In GCR': {
    bgColor: 'bg-yellow-2',
    textColor: 'text-[#16282D]',
  },
  'Complete': {
    bgColor: 'bg-green-2',
    textColor: 'text-[#16282D]',
  },
  'Pending Submission': {
    bgColor: 'bg-blue-2',
    textColor: 'text-[#16282D]',
  },
};

export interface TDeliverable {
  status?: string;
  lastActivityDate?: string;
  nextDueDate?: string;
  liveLink?: string;
  guideLine?: string;
  title?: string;
  imageLink?: string;
  onClickDeliverable?: () => void;
}

const DeliverableCard: React.FC<TDeliverable> = (props) => {
  const { status, lastActivityDate, nextDueDate, title, imageLink, } = props;
  const imageUrl = title === 'image' ? imageLink : '';

  return (
    <Card className="p-0 bg-transparent rounded-lg flex flex-col">
      {imageUrl && <img src={imageUrl} alt="deliverable" className="w-full h-40 object-cover rounded-t-lg" />}

      <div className="p-4 flex flex-col gap-2">
        {
          title && <div className="text-base font-medium">{title}</div>
        }

        <div className="flex flex-row justify-between">
          <P className="text-sm font-normal text-grey-5">Status</P>
          {status ? (
            <Tag
              label={status}
              bgColor={statusColor[status]?.bgColor}
              textColor={statusColor[status]?.textColor}
            />
          ) : (
            <P className="text-sm font-normal">-</P>
          )}
        </div>
        <div className="flex flex-row justify-between">
          <P className="text-sm font-normal text-grey-5">Last Activity</P>
          <P className="text-sm font-normal">{lastActivityDate || '-'}</P>
        </div>

        <div className="flex flex-row justify-between">
          <P className="text-sm font-normal text-grey-5">Next Due Date</P>
          <P className="text-sm font-normal">{nextDueDate || '-'}</P>
        </div>
      </div>
    </Card>
  );
};

export default DeliverableCard;
