import React from 'react';

import { get } from "lodash";
import MemberColumn from '../columnDefs/Member';
import Generic from "../columnDefs/Generic";
import { Row } from '@tanstack/react-table';
import { PostInsights } from '@frontend/gql/social/graphql';
import { SocialMediaThumbnail } from '@frontend/applications/Social/components/thumbnail/SocialMediaThumbnail';

export const POST_TABLE_COLUMN_CONFIG_VERSION = 4; // must update this value if you change anything below

export interface PostTableColumn<T = any> {
  header: string;
  id: string;
  enableSorting?: boolean;
  enableGlobalFiltering?: boolean;
  hidden?: boolean;
  order?: number;
  size?: number;
  minSize?: number;
  maxSize?: number;
  accessorFn?: (row: T) => unknown;
  accessorKey?: string; // accessorKey or accessorFn should be set
  cell: (props: { row: Row<T> }) => React.ReactNode;
}

export const defaultPostColumns: PostTableColumn<PostInsights>[] = [
  {
    header: 'Content',
    id: 'content',
    accessorKey: 'content',
    enableSorting: true,
    hidden: false,
    order: 1,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'postPermalink');
      const memberName = get(row.original, 'member.name');
      const entityType = get(row.original, 'entityType');
      const entityNetwork = get(row.original, 'entityNetwork');
      return <SocialMediaThumbnail
        url={value}
        width="100%"
        height="100%"
        className="rounded-md object-contain"
        alt={`${memberName || 'Unknown'} - ${entityType} on ${entityNetwork}`}
        loadThumbnail
      />
    },
  },
  {
    header: 'Member',
    id: 'member',
    enableSorting: true,
    enableGlobalFiltering: true,
    hidden: false,
    size: 200,
    minSize: 50,
    maxSize: 300,
    order: 2,
    accessorFn: (row) => row.member?.name ?? "",
    cell: ({ row }) => {
      const member = get(row.original, 'member');
      return <MemberColumn
        name={member.name || ''}
        image={member.profilePicture || undefined}
        url={`/members/${row.original.memberId}`}
        aria-label={`Member: ${member.name || 'Unknown'}`}
        data-dd-action-name="member-table-member-click"
      />
    },
  },
  {
    header: 'Post Date',
    id: 'postDate',
    accessorKey: 'postDate',
    enableSorting: true,
    hidden: false,
    order: 3,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'postDate');
      return <Generic value={value} aria-label={`Post Date: ${value || 'Not available'}`} />
    },
  },
  {
    header: 'Project',
    id: 'project',
    enableSorting: true,
    hidden: false,
    order: 4,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'project');
      return <Generic value={value} aria-label={`Project: ${value || 'Not available'}`} />
    },
  },
  {
    header: 'Post Link',
    id: 'postPermalink',
    accessorKey: 'postPermalink',
    enableSorting: true,
    hidden: false,
    order: 5,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'postPermalink');
      return <a href={value} target="_blank" rel="noopener noreferrer">
        <Generic value={value} className="underline" aria-label={`Post Link: ${value || 'Not available'}`} />
      </a>;
    },
  },
  {
    header: 'Network',
    id: 'entityNetwork',
    accessorKey: 'entityNetwork',
    enableSorting: true,
    hidden: false,
    order: 6,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'entityNetwork');
      return <Generic value={value} aria-label={`Network: ${value || 'Not available'}`} />
    },
  },
  {
    header: 'Post Type',
    id: 'entityType',
    accessorKey: 'entityType',
    enableSorting: true,
    hidden: false,
    order: 7,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'entityType');
      return <Generic value={value} aria-label={`Post Type: ${value || 'Not available'}`} />
    },
  },
  {
    header: 'Engagement',
    id: 'total.engagements',
    accessorKey: 'total.engagements',
    enableSorting: true,
    hidden: false,
    order: 8,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'total.engagements');
      return <Generic align={'right'} value={value} aria-label={`Engagement: ${value || 'Not available'}`} />
    },
  },
  {
    header: 'Impressions',
    id: 'total.impressions',
    accessorKey: 'total.impressions',
    enableSorting: true,
    hidden: false,
    order: 9,
    minSize: 200,
    cell: ({ row }) => {
      const value = get(row.original, 'total.impressions');
      return <Generic align={'right'} value={value} aria-label={`Impressions: ${value || 'Not available'}`} />
    },
  },
];
