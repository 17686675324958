import * as React from 'react';
import * as qs from 'querystring';
import { isUndefined, omitBy } from 'lodash';

const { useCallback } = React;

const getFileUploadURL = async (
  token: string,
  serviceName: string,
  parentFolder: string,
  isTemp: boolean,
  fileName?: string,
) => {
  const config = omitBy({ serviceName, parentFolder, isTemp, fileName }, isUndefined);
  const resp = await fetch(
    `/api/object_storage/signed_url?${qs.stringify(config)}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );

  if (resp.status !== 200) {
    console.error(resp);
    throw new Error('Failed to get signed url.');
  }

  return resp.json();
};

export const useGetFileUploadURL = (serviceName: string, parentFolder?: string, isTemp: boolean = false) => useCallback((token: string, fileName?: string) => getFileUploadURL(token, serviceName, parentFolder, isTemp, fileName), [serviceName, parentFolder, isTemp]);
