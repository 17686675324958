import React, { useMemo } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerClose,
} from '@frontend/shadcn/components/ui/drawer';
import { Button } from '@frontend/shadcn/components/ui/button';
import { XmarkIcon } from '@revfluence/fresh-icons/solid';
import { P } from '@frontend/shadcn/components/typography/p';
import { cn } from '@frontend/shadcn/lib/utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@frontend/shadcn/components/ui/avatar';
import { UserIcon } from '@revfluence/fresh-icons/solid/esm';
import { ComplexColumns } from '../FlexMembersTable';
import { MemberTableRow } from '../hooks/useMembersTableColumns';
import BriefCard from './BriefCard';
import OrderCard from './OrderCard';
import DeliverableCard from './DeliverableCard';
import OfferLinkCard from './OfferLinkCard';
import OfferPromoCard, { PriceRuleType } from './OfferPromoCard';
import TalentManagerCard from './TalentManagerCard';

interface ComplexColumnsDrawerProps {
  selectedComplexColumn: {
    member: MemberTableRow | null;
    complexColumn: ComplexColumns | null;
  };
  setSelectedComplexColumn: React.Dispatch<
    React.SetStateAction<{
      member: MemberTableRow | null;
      complexColumn: ComplexColumns | null;
    }>
  >;
}

const ComplexColumnsDrawer: React.FC<ComplexColumnsDrawerProps> = ({
  selectedComplexColumn,
  setSelectedComplexColumn,
}) => {
  const { member, complexColumn } = selectedComplexColumn;

  const memberDetails = useMemo(() => {
    if (!member || !complexColumn) return null;

    return {
      name: member.member?.name || '',
      profilePicture: member.member?.profilePicture || '',
      briefs: member.allBriefs || [],
      orders: member.allOrders || [],
      deliverables: member.allDeliverables || [],
      offerLink: member.allOfferLinks || [],
      offerPromo: member.allOfferPromos || [],
      talentManagers: member.allTalentManagers || [],
    };
  }, [member, complexColumn]);

  if (!memberDetails) return null;

  const renderComplexColumnContent = () => {
    switch (complexColumn) {
      case ComplexColumns.Briefs:
        return memberDetails.briefs.map((brief, index) => (
          <BriefCard
            key={index}
            title={`Brief ${index + 1}`}
            status={brief.status}
            createdDate={brief.createdDate}
            agreedDate={brief.agreedDate}
            payment={brief.payment}
            updatedDate={brief.updatedDate}
          />
        ));

      case ComplexColumns.Orders:
        return memberDetails.orders.map((order, index) => (
          <OrderCard
            key={index}
            title={`Order ${index + 1}`}
            orderStatus={order.orderStatus}
            dateSent={order.dateSent}
            orderNumber={order.orderNumber}
            productsCount={order.productsCount}
            totalCost={order.totalCost}
            trackingNumber={order.trackingNumber}
          />
        ));

      case ComplexColumns.Deliverables:
        return memberDetails.deliverables.map((deliverable, index) => (
          <DeliverableCard
            key={index}
            status={deliverable.status}
            lastActivityDate={deliverable.lastActivityDate}
            nextDueDate={deliverable.nextDueDate}
            title={deliverable?.title}
            imageLink={deliverable?.imageLink}
          />
        ));

      case ComplexColumns.Offers:
        return (
          <>
            {memberDetails.offerLink.map((offerLink, index) => (
              <OfferLinkCard
                key={index}
                payout={offerLink.payout}
                link={offerLink.link}
                clicks={offerLink.clicks}
                cpc={offerLink.cpc}
                conversions={offerLink.conversions}
                avgSaleAmount={offerLink.avgSaleAmount}
                saleAmount={offerLink.saleAmount}
                offerName={offerLink.offerName}
              />
            ))}
            {memberDetails.offerPromo.map((offerPromo, index) => (
              <OfferPromoCard
                key={index}
                promoCode={offerPromo.promoCode}
                payout={offerPromo.payout}
                cps={offerPromo.cps}
                conversions={offerPromo.conversions}
                lastPayoutDate={offerPromo.lastPayoutDate}
                offerName={offerPromo.offerName}
                saleAmount={offerPromo.saleAmount}
                avgSaleAmount={offerPromo.avgSaleAmount}
                discountAmount={offerPromo.discountAmount}
                discountType={offerPromo.discountType as PriceRuleType}
              />
            ))}
          </>
        );

      case ComplexColumns.TalentManagers:
        return memberDetails.talentManagers.map((talentManager, index) => (
          <TalentManagerCard
            key={index}
            {...talentManager}
          />
        ));
      default:
        return null;
    }
  };

  return (
    <Drawer
      open={!!member}
      onOpenChange={() => setSelectedComplexColumn({ member: null, complexColumn: null })}
    >
      <DrawerContent
        className={cn(
          'fixed left-auto inset-y-0 mt-0 right-0 z-[9999] flex h-full w-[340px] flex-col border-l bg-background rounded-none p-0',
          'duration-300 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
          'bg-white backdrop-blur-sm',
          'top-[var(--header-height,0px)] h-[calc(100vh-var(--header-height,0px))]'
        )}
        data-vaul-no-drag
      >
        <DrawerHeader className="flex justify-between items-center border-b border-grey-3 px-4 py-4">
          <DrawerTitle className="text-[20px] font-medium m-0">
            {`All ${complexColumn}`}
          </DrawerTitle>
          <DrawerClose asChild>
            <Button
              variant="ghost"
              size="headerIcon"
              className="border border-transparent hover:border-grey-6 hover:bg-transparent"
            >
              <XmarkIcon className="h-4 w-4" />
            </Button>
          </DrawerClose>
        </DrawerHeader>

        <div className="flex-1 overflow-y-auto px-4">
          <div className="flex flex-row gap-2 items-center mt-4">
            <Avatar className="h-[32px] w-[32px] rounded-full">
              <AvatarImage
                src={memberDetails.profilePicture}
                className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-[20px] text-secondary"
              />
              <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg text-[20px] bg-grey-3">
                <UserIcon className="text-white" />
              </AvatarFallback>
            </Avatar>
            <P>{memberDetails.name}</P>
          </div>

          <div className="flex flex-col gap-4 mt-4 overflow-y-scroll">
            {renderComplexColumnContent()}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default ComplexColumnsDrawer;
