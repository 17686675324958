import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { WidgetContainer, DataTable, Empty } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { UserGroupIcon } from '@revfluence/fresh-icons/solid';
import { cn } from '@/shadcn/lib/utils';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

const { useState, useEffect } = React;
type RowItem = {
  id: string;
  name: string;
  creatorsCount: number;
  totalSpend: number;
  averageSpend: number;
};
interface SpendByCreatorSegmentWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function SpendByCreatorSegmentWidget(props: SpendByCreatorSegmentWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const [data, setData] = useState<RowItem[]>([]);

  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && budgetSummary?.summary?.memberSegmentSpendList) {
      const segments: RowItem[] = budgetSummary?.summary?.memberSegmentSpendList.map((segment, index) => ({
        id: index.toString(),
        averageSpend: segment.averageSpentAmount as number,
        creatorsCount: segment.totalMembers as number,
        name: segment.memberSegmentName as string,
        totalSpend: segment.spentAmount as number,
      }));
      setData(segments);
    }
  }, [budgetSummary, loading]);

  const showEmptyState = useMemo(() => {
    if (!data?.length) {
      return true;
    }
    return false;
  }, [data]);

  const columns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'name',
      header: () => <div className="font-semibold text-[#1F1F21]">Name</div>,
    },
    {
      accessorKey: 'creatorsCount',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Number of Creators</div>,
      cell: ({ row }) => {
        const creatorsCount = parseFloat(row.getValue('creatorsCount'));
        return <div className="text-right font-medium">{creatorsCount}</div>;
      },
    },
    {
      accessorKey: 'totalSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Total Spend</div>,
      cell: ({ row }) => {
        const totalSpend = parseFloat(row.getValue('totalSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalSpend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'averageSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Avg Spend</div>,
      cell: ({ row }) => {
        const averageSpend = parseFloat(row.getValue('averageSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(averageSpend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];

  const emptyState = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={UserGroupIcon}
        title="No creator segments spend found"
        description="You can track the spend on each type of creators segment you have."
      />
    </div>
  );
  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div
      className="w-full h-full flex flex-col justify-center items-center"
    >
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = <DataTable columns={columns} data={data} />;
  const content = showEmptyState ? emptyState : mainContent;
  return (
    <WidgetContainer
      widgetTitle="Spend by Creator Segment"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : content}
    </WidgetContainer>
  );
}
