import * as React from 'react';

import { IResource } from '@components';

import { useResourceContext } from '@frontend/app/context';
import { useInviteContext } from './useInviteContext';

const { useEffect, useCallback } = React;

export const useResources = () => {
  const { resourceContext, updateResourceContext } = useInviteContext();

  const { activeEmailResources, loading } = useResourceContext();
  const selectResourceId = useCallback(
    (resourceId: IResource['id']) => {
      updateResourceContext((c) => ({ ...c, resourceId }));
    },
    [updateResourceContext],
  );

  useEffect(() => {
    if (activeEmailResources && Array.isArray(activeEmailResources) && activeEmailResources.length > 0 && !resourceContext.resourceId) {
      const defaultResource: IResource = activeEmailResources[0];
      selectResourceId(defaultResource.id);
    }
  }, [activeEmailResources, selectResourceId, resourceContext.resourceId]);

  return {
    resources: activeEmailResources,
    resourceId: resourceContext.resourceId,
    selectResourceId,
    onRequestAddEmail: resourceContext.onRequestAddEmail,
    loading,
  };
};
