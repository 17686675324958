import * as React from 'react';

import { ICard } from '@frontend/applications/PaymentsApp/models';
import { Modal, ModalSize } from '@components';

import { Alert, Typography } from '@revfluence/fresh';
import { AddCardForm } from '../../../containers/AddCardForm';
import { CreditCardSelectBox } from '../../components/CreditCardSelectBox/CreditCardSelectBox';
import { CREDIT_CARD_SELECT_OPTIONS } from '../../components/CreditCardSelectBox/CreditCardSelectBox';
import { PayoutBreakdown as PayoutBreakdownComponent } from '../../components/PayoutBreakdown/PayoutBreakdown';
// import { AddCardFormMock } from '../../../containers/AddCardFormMock'; uncomment this when stripe isn't working

import { useBulkPaymentComputation } from '../../hooks/useBulkPaymentComputation';

import styles from './PaymentDetails.scss';

const { Link, Text } = Typography;
const { useState, useCallback, useEffect } = React;
interface IPaymentDetails {
  amountToPay: number;
  availableBalance: number;
  creditCards: ICard[];
  onCardAdded: Function;
  selectedCard: ICard;
  onCardSelected: Function;
  cardSelected: boolean;
  setCardSelected: React.Dispatch<React.SetStateAction<boolean>>;
  requireHandlingFee?: boolean;
}

export const PaymentDetails: React.FC<Readonly<IPaymentDetails>> = (props: IPaymentDetails) => {
  const {
    amountToPay,
    availableBalance,
    onCardSelected,
    creditCards,
    selectedCard,
    cardSelected,
    setCardSelected,
  } = props;

  const {
    insufficientBalance,
    amountToCharge,
    cardTransactionFee,
    totalAmountToCharge,
    totalAmountToPay,
    remainingBalance,
    amountToDeduct,
    handlingFee,
  } = useBulkPaymentComputation(amountToPay, availableBalance, props.requireHandlingFee);

  const [showAddCard, setShowAddCard] = useState<boolean>(false);
  const [isAddingCard, setIsAddingCard] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = useCallback(setShowAddCard.bind(this, false), []);

  const onChange = useCallback(
    (value) => {
      if (value == CREDIT_CARD_SELECT_OPTIONS.ADD_CARD) {
        setShowAddCard(true);
        setCardSelected(false);
      } else {
        setCardSelected(true);
        onCardSelected(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowAddCard, onCardSelected],
  );

  const handleRequestClose = useCallback(() => {
    if (!isAddingCard) {
      closeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingCard]);

  const onCardAdded = useCallback(() => {
    setShowAddCard(false);
    props.onCardAdded();
  }, [setShowAddCard, props]);

  useEffect(() => {
    if (creditCards.length > 0) {
      setCardSelected(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCards]);

  const NoBalanceAlertMessage = (
    <Text>
      A balance or credit card is required to make payments. Email
      {' '}
      <Link href="mailto:help@aspireiq.com">help@aspireiq.com</Link>
      {' '}
      if you would like to learn how to add funds
      to your balance.
    </Text>
  );

  const BalanceAvailAlertMessage = (
    <Text>
      Your available balance is sufficient to cover this payment. If in the future you do not have enough available balance for a payment, you can add a credit card to complete the payment.
    </Text>
  );

  return (
    <div className={styles.PayoutDetails}>
      <Modal
        width={ModalSize.Small}
        show={showAddCard}
        onRequestClose={handleRequestClose}
        dialogClassName={styles.addCardFormModalDialog}
      >
        {/* AddCardForm requires Stripe to be working properly so if you cannot do that locally sub with AddCardFormMock */}
        <AddCardForm onCardAdded={onCardAdded} setProgress={setIsAddingCard} />
      </Modal>
      {insufficientBalance && (
        <div className={styles.Alert}>
          <Alert
            description={NoBalanceAlertMessage}
            type="info"
          />
        </div>
      )}

      {availableBalance > totalAmountToPay && (
        <div className={styles.Alert}>
          <Alert
            description={BalanceAvailAlertMessage}
            type="info"
          />
        </div>
      )}

      <div className={styles.details}>
        <div className={styles.breakdown}>
          <PayoutBreakdownComponent
            amountToPayMembers={amountToPay}
            amountToDeduct={amountToDeduct}
            amountToCharge={amountToCharge}
            cardTransactionFee={cardTransactionFee}
            totalAmountToCharge={totalAmountToCharge}
            totalAmountToPay={totalAmountToPay}
            remainingBalance={remainingBalance}
            alert={!cardSelected && insufficientBalance}
            cardCharge={insufficientBalance}
            requireHandlingFee={props.requireHandlingFee}
            handlingFee={handlingFee}

          />
        </div>
        <div className={styles.cardSelector}>
          <CreditCardSelectBox
            selectedCard={selectedCard}
            creditCards={creditCards}
            onChange={onChange}
            disabled={!insufficientBalance}
            alert={!cardSelected && insufficientBalance}
          />
        </div>
      </div>
    </div>
  );
};
