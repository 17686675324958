import React from 'react';
import { useCurrency } from '../../../hooks/useCurrency';
import Generic from './Generic';

interface CurrencyProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number;
}

/**
 * A React functional component that displays a currency value.
 *
 * @component
 * @param {CurrencyProps} props - The props for the Currency component.
 * @param {number} props.value - The numeric value to be formatted as currency.
 * @returns {JSX.Element} A JSX element that renders the formatted currency value using the `Generic` component.
 *
 * @remarks
 * This component utilizes the `useCurrency` hook to format the provided numeric value into a currency string.
 */
const Currency: React.FC<CurrencyProps> = ({ value }) => {
  const { currencyFormatted } = useCurrency(value);

  return <Generic value={currencyFormatted} align="right" />;
};

export default Currency;
