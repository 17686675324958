import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_PAYMENT_DASHBOARD_WIDGET_DATA from '@frontend/app/queries/GetPaymentDashboardWidgetDataQuery';
import {
  GetPaymentDashboardWidgetDataQuery,
  GetPaymentDashboardWidgetDataQueryVariables,
} from '@frontend/app/queries/types/GetPaymentDashboardWidgetDataQuery';

type IOptions = QueryHookOptions<GetPaymentDashboardWidgetDataQuery, GetPaymentDashboardWidgetDataQueryVariables>;

export const useGetPaymentDashboardWidgetData = (options: IOptions = {}) =>
  useQuery<GetPaymentDashboardWidgetDataQuery, GetPaymentDashboardWidgetDataQueryVariables>(
    GET_PAYMENT_DASHBOARD_WIDGET_DATA,
    options,
  );
