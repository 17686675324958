import * as React from 'react';
import RefreshSpinner from '@frontend/app/assets/svgs/RefreshSpinner.svg';
import { cn } from '@frontend/shadcn/lib/utils';

export interface SpinnerProps {
    /**
     * Size of the spinner
     * @default default
     */
    size?: 'sm' | 'default' | 'lg',
    /**
     * Placement of the spinner
     * @default local
     */
    placement?: 'inline' | 'local' | 'global'
    /**
     * Delay before showing spinner in milliseconds
     * @default 1000
     */
    delay?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 'default', placement = 'local', delay = 1000 }) => {
    const [show, setShow] = React.useState<boolean>(delay === 0);

    React.useEffect(() => {
        const timeOutId = setTimeout(() => {
            setShow(true);
        }, delay);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [delay]);

    const sizeClass = React.useMemo(() => {
        switch (size) {
            case 'sm':
                return 'w-4';
            case 'default':
                return 'w-8';
            case 'lg':
                return 'w-10';
        }
    }, [size]);

    if (!show) {
        return null;
    }

    const SpinnerComp = <img className={cn('aspect-square animate-spin', sizeClass)} src={RefreshSpinner} />;

    if (placement === 'inline') {
       return SpinnerComp;
    }

    return (
      <div className={cn('bg-mask flex items-center justify-center top-0 left-0', placement === 'global' ? 'fixed w-screen h-screen' : 'absolute w-full h-full')}>
        {SpinnerComp}
      </div>
    );
};

export default Spinner;
