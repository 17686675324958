/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  ReactFlowProvider,
  MarkerType,
  Edge,
} from 'reactflow';
import omitDeep from 'omit-deep-lodash';
import AutomationState from '@frontend/app/containers/Projects/AutomationConfig/AutomationState/AutomationState';
import { MessageTemplateQuery_template_attachments as TemplateAttachment } from '@frontend/app/queries/types/MessageTemplateQuery';
import {
  NODE_BASE_HEIGHT,
  NODE_EDGE_COLOR,
  NODE_HEIGHT_OFFSET,
  UNSAVE_ALERT_CUSTOM_MODAL_PROPS,
  nodeTypes,
} from '@frontend/app/containers/Projects/AutomationConfig/constants';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import ConfigHeader from '@frontend/app/containers/Projects/AutomationConfig/ConfigHeader/ConfigHeader';
import {
  addUUIDs,
  createAutomationFlow,
  updateAutomationBluePrint,
  getAutomationVariables,
} from '@frontend/app/containers/Projects/AutomationConfig/utils';
import {
  useCreateAutomationMutation,
  useGetAutomationQuery,
  useGetNodeOptionsQuery,
  useGetTemplateQuery,
  useCreateRevisionMutation,
  useConfirmOnExit,
  useDisableAutomationMutation,
  useDeleteAutomationMutation,
  useResumeAutomationMutation,
  MessageTemplate,
  MessageTemplateInput,
  useUpdateMessageTemplateMutation,
  useCreateMessageTemplateMutation,
} from '@frontend/app/hooks';
import {
 isNil, isNull, isNumber, map,
} from 'lodash';
import { LoadSpinner } from '@components';
import ConfigDrawer from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ConfigDrawer';
import {
  AutomationBlueprint,
  AutomationNodeData,
  TActionNode,
  TConditionNode,
  TDelayNode,
  TemplateBlueprint,
  TSelectedNode,
} from '@frontend/app/containers/Projects/AutomationConfig/types';
import {
  addConditionNode,
  findNodeById,
  parseConditionPayload,
  removeConditionNode,
  getLeafNodeYPostionOffset,
  updateConditionNode,
} from '@frontend/app/containers/Projects/AutomationConfig/utils/condition';
import { IAutomationVariable } from '@frontend/app/types/automations/condition/AutomationVariable';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useMessagingContext } from '@frontend/hooks';

import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';

import { useCreateDefaultAutomationMessageTemplates } from '@frontend/app/hooks/automations/useCreateDefaultAutomationMessageTemplates';
import { AutomationStatus, AutomationActionNodeSubType } from '@frontend/app/types/globalTypes';
import { logger, EventName } from '@common';

import { ProjectsRouteRoot } from '../constants';

import styles from './AutomationConfig.scss';

const AutomationConfig = () => {
  const addEvent = useEventContext();
  const { fitView } = useReactFlow();
  const [blueprint, setBlueprint] = useState<AutomationBlueprint | TemplateBlueprint>(null);
  const [updatedTemplate, setUpdatedTemplate] = useState<MessageTemplate | MessageTemplateInput>(null);
  const [updateTemplate] = useUpdateMessageTemplateMutation();
  const [createTemplate] = useCreateMessageTemplateMutation();
  const [nodes, setNodes, onNodesChange] = useNodesState<AutomationNodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showState, setShowState] = useState<boolean>(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<TSelectedNode | null>(null);
  const [automationVariables, setAutomationVariables] = useState<IAutomationVariable[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [resetMessageTemplate, setResetMessageTemplate] = useState<boolean>(false);

  const history = useHistory();
  const {
    showError,
    showMessage,
    showErrorMessage,
  } = useMessagingContext();

  const {
    projectId,
    templateId,
    automationId,
  } = useParams<{
    projectId: string,
    templateId?: string,
    automationId?: string,
  }>();
  const {
    data: {
      getNodeOptions: {
        actionOptions,
        conditionOptions,
      },
    } = {
      getNodeOptions: {
        actionOptions: [],
        conditionOptions: [],
      },
    },
    loading: isLoadingAutomationNodeOptions,
    refetch: refetchNodeOptions,
  } = useGetNodeOptionsQuery({
    variables: {
      context: {
        automationId,
        templateId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: { template } = {
      template: null,
    },
    loading: isLoadingTemplate,
    refetch: refetchTemplate,
  } = useGetTemplateQuery({
    variables: {
      templateId,
      context: {
        programId: parseInt(projectId, 10),
      },
    },
    fetchPolicy: 'network-only',
    skip: isNil(templateId),
  });

  const {
    data: { automation } = {
      automation: null,
    },
    refetch: refetchAutomation,
    loading: isLoadingAutomation,
  } = useGetAutomationQuery({
    variables: {
      automationId,
    },
    skip: !automationId,
  });

  const [mutateFunction, { loading: isLoadingMessageTemplates }] = useCreateDefaultAutomationMessageTemplates();
  useEffect(() => {
    (async () => {
      try {
        const response = await mutateFunction();
        if (response?.data?.messageTemplates?.length > 0) {
          refetchNodeOptions();
          refetchTemplate();
          refetchAutomation();
        }
      } catch (e) {
        logger.error(e);
      }
    })();
  }, [mutateFunction, refetchNodeOptions, refetchTemplate, refetchAutomation]);

  const [createAutomation, { loading: isCreatingAutomation }] = useCreateAutomationMutation();
  const [createRevision, { loading: isUpdatingAutomation }] = useCreateRevisionMutation();
  const [disableAutomation] = useDisableAutomationMutation();
  const [deleteAutomation] = useDeleteAutomationMutation();
  const [resumeAutomation] = useResumeAutomationMutation();

  const isLoading =
    isLoadingMessageTemplates || isLoadingTemplate || isLoadingAutomation || isLoadingAutomationNodeOptions;

    const saveMessageTemplate = async (): Promise<number> => {
      try {
        let templateId;
        const preparedAttachments = map(updatedTemplate.attachments, (attachment: TemplateAttachment) => ({
          id: attachment.id,
          name: attachment.name,
          url: attachment.fileUrl,
          size: attachment.size,
          type: attachment.type,
          uuid: attachment.uuid,
          fileUrl: attachment.fileUrl,
          localSrc: attachment.localSrc,
        }));
        const isNewTemplate = !isNumber(updatedTemplate.id);
        if (isNewTemplate) {
          const { data: { template } } = await createTemplate({
            variables: {
              data: {
                title: updatedTemplate.title,
                subject: updatedTemplate.subject,
                text: updatedTemplate.text,
                attachments: preparedAttachments,
              },
            },
          });
          templateId = template.id;
          addEvent(EventName.MessageTemplates, {
            template_id: template.id,
            template_name: updatedTemplate.title,
            action: 'create',
          });
        } else {
          const { data: { template } } = await updateTemplate({
            variables: {
              id: updatedTemplate.id as number,
              data: {
                title: updatedTemplate.title,
                subject: updatedTemplate.subject,
                text: updatedTemplate.text,
                attachments: preparedAttachments,
              },
            },
          });
          templateId = template.id;
          addEvent(EventName.MessageTemplates, {
            template_id: template.id,
            template_name: updatedTemplate.title,
            action: 'save',
          });
        }
        setResetMessageTemplate(true);
        setUpdatedTemplate(null);
        refetchNodeOptions();
        setResetMessageTemplate(false);
        return templateId;
      } catch (error) {
        const msg = getErrorMessageFromGraphQL(error);
        showErrorMessage(msg);
      }
    };

  const onEdit = useCallback(() => {
    setIsEditing(true);

    setNodes((nodes) =>
      nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          isEditing: true,
          bluePrintName: node.data.bluePrintName,
        },
        position: {
          ...node.position,
          y: node.data.isLeafNode ? node.position.y + NODE_HEIGHT_OFFSET : node.position.y,
        },
        className: styles.automationNodeContainer,
      })),
    );

    setEdges((edges) =>
      edges.map((edge) => ({
        ...edge,
        style: {
          ...edge.style,
          stroke: NODE_EDGE_COLOR.EDITING,
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: NODE_EDGE_COLOR.EDITING,
        },
      })),
    );
  }, [setEdges, setNodes]);

  useConfirmOnExit({
    show: hasUnsavedChanges,
    okText: 'Continue',
    confirmMessage: 'Are you sure you want to leave this page?',
    customModalProps: UNSAVE_ALERT_CUSTOM_MODAL_PROPS,
  });

  const onNodeItemClick = useCallback((selectedNode: TSelectedNode) => {
    setSelectedNode({
      ...selectedNode,
      projectId: Number(projectId),
      bluePrintName: selectedNode.bluePrintName,
    });
    setIsDrawerVisible(true);
  }, [projectId]);

  useEffect(() => {
    fitView({
      nodes,
    });
  }, [nodes, fitView]);

  useEffect(() => {
    if (!isNull(automation)) {
      const automationBlueprint: AutomationBlueprint = omitDeep(automation, '__typename');
      setBlueprint(automationBlueprint);
      const automationVariables = getAutomationVariables(automationBlueprint);
      setAutomationVariables(automationVariables);

      const { nodes, edges } = createAutomationFlow(
        automation.id,
        null,
        automationBlueprint.triggers[0],
        styles.automationNodeContainer,
        onNodeItemClick,
      );

      setNodes(() =>
        nodes.map((node, index) => ({
          ...node,
          position: {
            ...node.position,
            y: node.data.isLeafNode
              ? index * NODE_BASE_HEIGHT + getLeafNodeYPostionOffset(nodes)
              : index * NODE_BASE_HEIGHT,
          },
          data: {
            ...node.data,
            bluePrintName: automationBlueprint?.name,
          },
        })),
      );

      setEdges(edges);
    } else if (!isNull(template)) {
      const templateBlueprint: TemplateBlueprint = omitDeep(template.blueprint, '__typename');
      let templateBlueprintTrigger = templateBlueprint.triggers[0];
      templateBlueprintTrigger = {
        ...templateBlueprintTrigger,
        root: addUUIDs(templateBlueprintTrigger.root),
        id: uuidv4(),
      };

      const templateBlueprintWithId = {
        ...templateBlueprint,
        triggers: [templateBlueprintTrigger],
      };

      setBlueprint(templateBlueprintWithId);

      const automationVariables = getAutomationVariables(templateBlueprintWithId as AutomationBlueprint);
      setAutomationVariables(automationVariables);

      const { nodes, edges } = createAutomationFlow(
        null,
        template.id,
        templateBlueprintWithId.triggers[0],
        styles.automationNodeContainer,
        onNodeItemClick,
      );

      setNodes(() =>
        nodes.map((node, index) => ({
          ...node,
          position: {
            ...node.position,
            y: node.data.isLeafNode
              ? index * NODE_BASE_HEIGHT + getLeafNodeYPostionOffset(nodes)
              : index * NODE_BASE_HEIGHT,
          },
          data: {
            ...node.data,
            bluePrintName: templateBlueprintWithId?.name,
          },
        })),
      );

      setEdges(edges);

      onEdit();
    }
  }, [onEdit, setEdges, automation, template, onNodeItemClick, setNodes]);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: '#fff' } }, eds));
    },
    [setEdges],
  );

  const onDrawerClose = () => {
    setUpdatedTemplate(null);
    setIsDrawerVisible(false);
  };

  const onDisableAutomation = async () => {
    await disableAutomation({
      variables: {
        automationId: automation.id,
      },
    });
    refetchAutomation({
      automationId,
    });
  };

  const onDeleteAutomation = async () => {
    await deleteAutomation({
      variables: {
        automationId: automation.id,
      },
    });

    history.replace({
      pathname: `${ProjectsRouteRoot}/${projectId}/settings/automation`,
    });
  };

  const onResumeAutomation = async () => {
    await resumeAutomation({
      variables: {
        automationId: automation.id,
      },
    });
    refetchAutomation({
      automationId,
    });
  };

  const findNodeBySubType = useCallback((node, subType: AutomationActionNodeSubType) => {
    if (node.subType === subType) {
      return node;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const result = findNodeBySubType(node.children[i], subType);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }, []);

  const addEmailTemplateIdToBlueprint = useCallback((blueprint, emailTemplateId: number) => {
    const sendEmailNode = findNodeBySubType(
      blueprint.triggers[0].root,
      AutomationActionNodeSubType.SEND_EMAIL,
    );
    if (sendEmailNode) {
      sendEmailNode.metadata = {
        ...sendEmailNode.metadata,
        templateId: emailTemplateId,
        brandCatalogId: sendEmailNode.metadata?.brandCatalogId,
        selectionRuleId: sendEmailNode.metadata?.selectionRuleId,
      };
    }
  }, [findNodeBySubType]);

  const onSave = async () => {
    let updatedTemplateId;
    if (updatedTemplate) {
      updatedTemplateId = await saveMessageTemplate();
      const blueprintCopy = JSON.parse(JSON.stringify(blueprint));
      addEmailTemplateIdToBlueprint(blueprintCopy, updatedTemplateId);
      setBlueprint(blueprintCopy);
    }
    try {
      if ('id' in blueprint && blueprint.id) {
        const blueprintInput = {
          description: blueprint.description,
          id: blueprint.id,
          metadata: blueprint.metadata,
          name: blueprint.name,
          programId: parseInt(projectId, 10),
          triggers: blueprint.triggers.map((trigger) => ({
            ...trigger,
            root: {
              ...trigger.root,
              metadata: {
                ...trigger.root.metadata,
                brandCatalogId: trigger.root.metadata?.brandCatalogId,
                selectionRuleId: trigger.root.metadata?.selectionRuleId,
              }
            }
          })),
        };
        await createRevision({
          variables: {
            blueprint: blueprintInput,
            options: {
              publish: automation?.status === AutomationStatus.ACTIVE,
            },
          },
          onCompleted() {
            refetchAutomation({
              automationId,
            });
            showMessage({
              type: 'success',
              content: 'Successfully updated automation',
            });
          },
          onError(error) {
            // TODO (an) Properly handle errors
            error.graphQLErrors = [];
            showError(error);
          },
        });
      } else {
        const {
          data: {
            automation: { id: automationId },
          },
        } = await createAutomation({
          variables: {
            blueprint: {
              ...blueprint,
              programId: parseInt(projectId, 10),
              triggers: blueprint.triggers.map((trigger) => ({
                ...trigger,
                root: {
                  ...trigger.root,
                  metadata: {
                    ...trigger.root.metadata,
                    brandCatalogId: trigger.root.metadata?.brandCatalogId,
                    selectionRuleId: trigger.root.metadata?.selectionRuleId,
                  }
                }
              })),
            },
            options: {
              publish: true,
            },
          },
          onCompleted() {
            showMessage({
              type: 'success',
              content: 'Successfully created automation',
            });
          },
          onError(error) {
            // TODO (an) Properly handle errors
            error.graphQLErrors = [];
            showError(error);
          },
        });
        refetchAutomation({
          automationId,
        });
      }

      setHasUnsavedChanges(false);
      setIsEditing(false);

      setNodes((nodes) =>
        nodes.map((node, index) => ({
          ...node,
          data: {
            ...node.data,
            isEditing: false,
            refreshNodeOptions: true,
            bluePrintName: node.data.bluePrintName,
          },
          position: {
            ...node.position,
            y: node.data.isLeafNode
              ? index * NODE_BASE_HEIGHT + getLeafNodeYPostionOffset(nodes)
              : index * NODE_BASE_HEIGHT,
          },
          className: `${styles.automationNodeContainer}`,
        })),
      );

      setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          style: {
            ...edge.style,
            stroke: NODE_EDGE_COLOR.PRIMARY,
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: NODE_EDGE_COLOR.PRIMARY,
          },
        })),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onNodeClick = (_, node) => {
    const targetNodeId = node.id;

    if (isEditing) {
      setNodes((nodes) =>
        nodes.map((node) => {
          const className =
            node.id === targetNodeId
              ? cx(styles.automationNodeContainer, styles.automationNodeOnFocus)
              : styles.automationNodeContainer;
          return {
            ...node,
            className,
          };
        }),
      );
    }
  };

  const toggleState = useCallback(() => {
    setShowState((state) => !state);
  }, []);

  const onAddConditionNode = (targetNodeId: string, conditionParentNodeUUID: string, newNode: TConditionNode) => {
    let updatedConditionTree: TConditionNode;
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === targetNodeId) {
          const currentCondtionTree = node.data.metadata;
          const targetConditionNode = findNodeById(currentCondtionTree, conditionParentNodeUUID);
          updatedConditionTree = addConditionNode(currentCondtionTree, conditionParentNodeUUID, {
            ...newNode,
            parentId: targetConditionNode?.uuid,
          });
          const updatedConditionPayload = parseConditionPayload(updatedConditionTree);
          setBlueprint(() => ({
            ...blueprint,
            triggers: [
              {
                ...blueprint.triggers[0],
                root: updateAutomationBluePrint(updatedConditionPayload, targetNodeId, blueprint.triggers[0].root),
              },
            ],
          }));
        }

        return {
          ...node,
          data: {
            ...node.data,
            metadata: node.id === targetNodeId ? updatedConditionTree : node.data.metadata,
          },
          position: {
            ...node.position,
            y: node.data.isLeafNode ? node.position.y + NODE_HEIGHT_OFFSET : node.position.y,
          },
        };
      }),
    );
    setHasUnsavedChanges(true);
    setIsDrawerVisible(false);
  };

  const onRemoveConditionNode = (targetNodeId: string, conditionParentNodeUUID: string, conditionNodeId: string) => {
    let updatedConditionTree = null;
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === targetNodeId) {
          const currentCondtionTree = node.data.metadata;
          updatedConditionTree = removeConditionNode(currentCondtionTree, conditionParentNodeUUID, conditionNodeId);
          const updatedConditionPayload = parseConditionPayload(updatedConditionTree);
          setBlueprint(() => ({
            ...blueprint,
            triggers: [
              {
                ...blueprint.triggers[0],
                root: updateAutomationBluePrint(updatedConditionPayload, targetNodeId, blueprint.triggers[0].root),
              },
            ],
          }));
        }

        return {
          ...node,
          data: {
            ...node.data,
            metadata: node.id === targetNodeId ? updatedConditionTree : node.data.metadata,
          },
          position: {
            ...node.position,
            y: node.data.isLeafNode ? node.position.y - NODE_HEIGHT_OFFSET : node.position.y,
          },
        };
      }),
    );

    setHasUnsavedChanges(true);
    setIsDrawerVisible(false);
  };

  const onUpdateConditionNode = (
    targetNodeId: string,
    conditionNodeId: string,
    updatedConditionNode: TConditionNode,
  ) => {
    let updatedConditionTree = null;

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === targetNodeId) {
          const currentCondtionTree = node.data.metadata;
          updatedConditionTree = updateConditionNode(currentCondtionTree, conditionNodeId, updatedConditionNode);
          const updatedConditionPayload = parseConditionPayload(updatedConditionTree);
          setBlueprint(() => ({
            ...blueprint,
            triggers: [
              {
                ...blueprint.triggers[0],
                root: updateAutomationBluePrint(updatedConditionPayload, targetNodeId, blueprint.triggers[0].root),
              },
            ],
          }));
        }

        return {
          ...node,
          data: {
            ...node.data,
            metadata: node.id === targetNodeId ? updatedConditionTree : node.data.metadata,
          },
        };
      }),
    );

    setHasUnsavedChanges(true);
    setIsDrawerVisible(false);
  };

  const onUpdateDelayNode = (targetNodeId: string, updatedDelayNode: TDelayNode) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === targetNodeId) {
          setBlueprint(() => ({
            ...blueprint,
            triggers: [
              {
                ...blueprint.triggers[0],
                root: updateAutomationBluePrint(updatedDelayNode, targetNodeId, blueprint.triggers[0].root),
              },
            ],
          }));
        }

        return {
          ...node,
          data: {
            ...node.data,
            metadata: node.id === targetNodeId ? updatedDelayNode : node.data.metadata,
          },
        };
      }),
    );

    setHasUnsavedChanges(true);
    setIsDrawerVisible(false);
  };

  const onUpdateActionNode = (targetNodeId: string, updatedActionNode: TActionNode) => {
    setBlueprint((prevBlueprint) => {
      const updatedRoot = updateAutomationBluePrint(updatedActionNode, targetNodeId, prevBlueprint.triggers[0].root);
      const updatedBlueprint = {
        ...prevBlueprint,
        triggers: [
          {
            ...prevBlueprint.triggers[0],
            root: updatedRoot,
          },
        ],
      };
      return updatedBlueprint;
    });

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === targetNodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              metadata: {
                ...node.data.metadata,
                ...updatedActionNode,
                brandCatalogId: updatedActionNode.brandCatalogId,
                selectionRuleId: updatedActionNode.selectionRuleId,
              },
            },
          };
        }
        return node;
      }),
    );

    setHasUnsavedChanges(true);
    setIsDrawerVisible(false);
  };

  if (isLoading) return <LoadSpinner />;

  return (
    <>
      <ConfigHeader
        automationStatus={automation?.status}
        isAutomation={!isNil(automation)}
        isEditing={isEditing}
        isSaving={isCreatingAutomation || isUpdatingAutomation}
        onEdit={onEdit}
        onSave={onSave}
        title={blueprint?.name}
        toggleState={toggleState}
        onDeleteAutomation={onDeleteAutomation}
        onDisableAutomation={onDisableAutomation}
        onResumeAutomation={onResumeAutomation}
      />
      <div className={styles.automationNodesContainer}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeClick={onNodeClick}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          fitView
        >
          {isEditing && <Background />}
          <Controls showInteractive={false} />
          {showState && !isNull(automation) && (
            <AutomationState
              setState={setShowState}
              automationId={automation.id}
              createdDate={automation.createdDate}
              updatedDate={automation.updatedDate}
            />
          )}
        </ReactFlow>
      </div>
      {!isNull(selectedNode) && (
        <ConfigDrawer
          visible={isDrawerVisible}
          onClose={onDrawerClose}
          onAddConditionNode={onAddConditionNode}
          onUpdateConditionNode={onUpdateConditionNode}
          onRmoveConditionNode={onRemoveConditionNode}
          onUpdateDelayNode={onUpdateDelayNode}
          onUpdateActionNode={onUpdateActionNode}
          onUpdateMessageTemplate={setUpdatedTemplate}
          conditionOptions={conditionOptions}
          actionOptions={actionOptions}
          automationVariables={automationVariables}
          selectedNode={selectedNode}
          resetMessageTemplate={resetMessageTemplate}
        />
      )}
    </>
  );
};

const AutomationConfigContainer = () => (
  <ReactFlowProvider>
    <AutomationConfig />
  </ReactFlowProvider>
);

export default AutomationConfigContainer;
