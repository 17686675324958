import { logger } from '@common';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useTaskProgressMonitor = () => {
  const { projectId: programId } = useParams<{ projectId: string }>();

  const [taskIdsByProgram, setTaskIdsByProgram] = useState<Record<string, string[]>>(() => {
    // Read from localStorage on initial load
    if (typeof window !== 'undefined') {
      const storedIds = localStorage.getItem('taskProgressIds');
      return storedIds ? JSON.parse(storedIds) : {};
    }
    return {};
  });

  const removeTaskId = (taskId: string) => {
    setTaskIdsByProgram((prev) => ({
      ...prev,
      [programId]: prev[programId]?.filter((id) => id !== taskId) || [],
    }));
  };

  const addTaskId = (taskId: string, programId: number) => {
    try {
      localStorage.setItem('taskProgressIds', JSON.stringify(taskIdsByProgram));
      setTaskIdsByProgram((prev) => ({
        ...prev,
        [programId]: [...(prev[programId] || []), taskId],
      }));
    } catch (error) {
      logger.error('Error adding task ID to localStorage:', error);
    }
  };

  // Get current program's task IDs
  const taskIdsForProgram = useMemo(() => (programId ? taskIdsByProgram[programId] || [] : []), [programId, taskIdsByProgram]);

  useEffect(() => {
    try {
      // Update localStorage whenever the task map changes
      localStorage.setItem('taskProgressIds', JSON.stringify(taskIdsByProgram));
    } catch (error) {
      logger.error('Error updating localStorage from useEffect:', error);
    }
  }, [taskIdsByProgram]);

  return {
    taskIds: taskIdsForProgram,
    removeTaskId,
    addTaskId,
  };
};
