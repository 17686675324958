import * as React from 'react';
import { FormItemProps } from 'antd/lib/form';
import { isEmpty, isString } from 'lodash';

import { Form } from '@revfluence/fresh';
import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import { useCharCounter } from '@affiliates/hooks';
import { CharCounter } from '@affiliates/components/OfferForm/CharCounter';
import styles from '@affiliates/components/OfferForm/OfferForm.scss';
import {
    createUrlObj, checkUrlsHasSameParam, checkUrlsHasSameDomain, getParamKeyFromValue,
} from '@affiliates/utils';
import { Input } from '@frontend/shadcn/components/ui/input';
import { IAffiliateLinksFormElementProps, IAffiliateLinksFormValues } from '../../../../types';
import { OfferInputHeader } from '../../../CommonSection/FormElements/RefreshForm/OfferElementHeader';

interface IProps extends IAffiliateLinksFormElementProps<'url'> {
    charCount: number;
    currentUrl?: string;
    validateSameDomain?: boolean;
    validateSameParam?: boolean;
    conversionTrackingType: IAffiliateLinksFormValues['conversionTrackingType'];
    events: {
        onBlur: () => void;
        onFocus: () => void;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
    };
}
const getUrlValidations = (

    conversionTrackingType: IProps['conversionTrackingType'],
    validateSameDomain: IProps['validateSameDomain'],
    validateSameParam: IProps['validateSameParam'],
    currentUrl: IProps['currentUrl'],
) => {
    const rules: FormItemProps['rules'] = [];
    if (!currentUrl) {
        return rules;
    }
    const url = createUrlObj(currentUrl);
    if (isString(url)) {
        return rules;
    }
    if (validateSameDomain) {
        rules.push({
            validator(_, value) {
                if (!checkUrlsHasSameDomain(url, value)) {
                    return Promise.reject(new Error(`URL must have the same domain as ${url.origin}`));
                }

                return Promise.resolve();
            },
        });
    }
    if (validateSameParam) {
        rules.push({
            validator(_, value) {
                const oldURL = url;
                const newURL = createUrlObj(value);
                switch (conversionTrackingType) {
                    case OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID: {
                        const { valid, paramString } = checkUrlsHasSameParam(oldURL, newURL, '{affiliate_id}');
                        if (!valid) {
                            return Promise.reject(new Error(`Offer URL must contain ${paramString}.`));
                        }
                        break;
                    }
                    case OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID: {
                        const { valid, paramString } = checkUrlsHasSameParam(oldURL, newURL, '{transaction_id}');
                        if (!valid) {
                            return Promise.reject(new Error(`Offer URL must contain ${paramString}.`));
                        }
                        break;
                    }
                }
                return Promise.resolve();
            },
        });
    }

    return rules;
};

export const RefreshOfferUrl: React.FC<Readonly<IProps>> = React.memo(({
    conversionTrackingType,
    disabled,
    events,
    name,
    charCount: initialCharCount,
    currentUrl,
    validateSameDomain,
    validateSameParam,
    value,
}) => {
    const urlValidations = getUrlValidations(conversionTrackingType, validateSameDomain, validateSameParam, currentUrl);

    let rules: FormItemProps['rules'];
    let placeholder = 'https://brand.com/shop';
    switch (conversionTrackingType) {
        case OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK:
        case OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID:
            placeholder = `${placeholder}?transaction_id={transaction_id}`;
            break;
        case OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID:
            placeholder = `${placeholder}?affiliate_id={affiliate_id}`;
            break;
    }

    const transactionIDErrorMessage = 'Offer URL must contain the variable {transaction_id} and your store’s parameter for transaction id. The query parameter name is going to depend on your implementation. Please confirm what the query parameter will be with the engineer implementing this. Note: If you’re using JavaScript Postback, your Offer URL must contain transaction_id={transaction_id}.';
    const partnerIDErrorMessage = 'Offer URL must contain the variable {affiliate_id} and your store’s parameter for affiliate id. The query parameter name is going to depend on your implementation. Please confirm what the query parameter will be with the engineer implementing this.';

    if (isEmpty(urlValidations)) {
        rules = [
            { required: true, message: 'Please input your offer url!' },
            { type: 'url', message: 'URL must be in the form of https://website.com or http://website.com' },
            { max: 2000, message: 'Offer link must be less than 2001 characters' },
            {
                validator: (_, value) => {
                    const url = createUrlObj(value);
                    switch (conversionTrackingType) {
                        case OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK:
                        case OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID:
                            if (isString(url) || getParamKeyFromValue(url, '{transaction_id}') === null) {
                                return Promise.reject(new Error(`${transactionIDErrorMessage}`));
                            }
                            break;
                        case OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID:
                            if (isString(url) || getParamKeyFromValue(url, '{affiliate_id}') === null) {
                                return Promise.reject(new Error(`${partnerIDErrorMessage}`));
                            }
                            break;
                    }
                    return Promise.resolve();
                },
            },
        ];
    } else {
        rules = urlValidations;
    }

    const { charCount, handleChange } = useCharCounter(initialCharCount);
    return (
      <>
        <OfferInputHeader text="Offer URL Tracking" tooltipText="Offer URL Tracking" />
        <Form.Item name={name} rules={rules} className={`${styles.hasCharacterCount} !mb-2`}>
          <div className="flex items-center">
            <Input
              data-testid="refresh-offerUrl-id"
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={handleChange}
              {...events}
            />
          </div>
        </Form.Item>
        {charCount >= 2000 && (
        <CharCounter
          charCount={charCount}
          maxCharCount={2000}
        />
            )}
      </>
    );
});
