import React from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { cn } from '@/shadcn/lib/utils';

interface TableHeaderPaginationProps extends React.HTMLAttributes<HTMLSpanElement> {
  currentPage: number;
  limit: number;
  totalCount: number;
}

const TableHeaderPagination = React.forwardRef<HTMLSpanElement, TableHeaderPaginationProps>(
  ({ currentPage, limit, totalCount, className, ...props }, ref) => {
    const start = currentPage * limit + 1;
    const end = Math.min((currentPage + 1) * limit, totalCount);

    return (
      <span ref={ref} className={cn('text-sm', className)} {...props}>
        {start}-{end} of {totalCount}
      </span>
    );
  },
);

TableHeaderPagination.displayName = 'TableHeaderPagination';

interface TableHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const TableHeader = React.forwardRef<HTMLDivElement, TableHeaderProps>(({ children, className, ...props }, ref) => (
  <div ref={ref} className={cn('p-4 space-y-4', className)} {...props}>
    {children}
  </div>
));

TableHeader.displayName = 'TableHeader';

interface TableHeaderLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const TableHeaderLayout = React.forwardRef<HTMLDivElement, TableHeaderLayoutProps>(
  ({ children, className, ...props }, ref) => (
    <div ref={ref} className={cn('flex justify-between items-center', className)} {...props}>
      {children}
    </div>
  ),
);

TableHeaderLayout.displayName = 'TableHeaderLayout';

interface TableHeaderSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const TableHeaderSection = React.forwardRef<HTMLDivElement, TableHeaderSectionProps>(
  ({ children, className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center space-x-2', className)} {...props}>
      {children}
    </div>
  ),
);

TableHeaderSection.displayName = 'TableHeaderSection';

interface TableHeaderLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
}

const TableHeaderLabel = React.forwardRef<HTMLSpanElement, TableHeaderLabelProps>(
  ({ children, className, ...props }, ref) => (
    <span ref={ref} className={cn('text-sm font-medium', className)} {...props}>
      {children}
    </span>
  ),
);

TableHeaderLabel.displayName = 'TableHeaderLabel';

interface TableHeaderIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

const TableHeaderIconButton = React.forwardRef<HTMLButtonElement, TableHeaderIconButtonProps>(
  ({ children, ...props }, ref) => (
    <Button ref={ref} variant="ghost" size="icon" {...props}>
      {children}
    </Button>
  ),
);

TableHeaderIconButton.displayName = 'TableHeaderIconButton';

export {
  TableHeader,
  TableHeaderLayout,
  TableHeaderSection,
  TableHeaderLabel,
  TableHeaderIconButton,
  TableHeaderPagination,
};
