import React from 'react';
import { Button, ButtonProps } from '@frontend/shadcn/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { ChevronDownIcon } from '@revfluence/fresh-icons/regular/esm';

interface SplitButtonItem {
  label: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export interface SplitButtonProps {
  items: SplitButtonItem[];
  variant?: ButtonProps['variant'];
}

export const SplitButton = ({ items, variant }: SplitButtonProps) => {
  const [primaryItem, ...secondaryItems] = items;

  if (!primaryItem) {
    return null;
  }

  if (items.length === 1) {
    return (
      <Button variant={variant} onClick={primaryItem.onClick} disabled={primaryItem.disabled}>
        {primaryItem.label}
      </Button>
    );
  }

  return (
    <div className="flex items-center">
      <Button variant={variant} className="rounded-r-none" onClick={primaryItem.onClick} disabled={primaryItem.disabled}>
        {primaryItem.label}
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant={variant} className="rounded-l-none border-l-0 px-2">
            <ChevronDownIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {secondaryItems.map((item, index) => (
            <DropdownMenuItem
              key={index}
              onClick={item.onClick}
              disabled={item.disabled}
            >
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
