import React from 'react';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Card } from '@frontend/shadcn/components/ui/card';
import { BadgeDollarIcon } from '@revfluence/fresh-icons/solid/esm';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { calculateRoasMetrics } from './utils/calculateRoasMetrics';
import { PercentChangeBadge } from '../../../../components/percent-change-badge';
import {
  AdHighlightCardHeader,
  AdHighlightCardTitle,
  AdHighlightCardContent,
  AdHighlightCardContentRow,
  AdHighlightCardContentColumn,
  AdHighlightCardStatRow,
  AdHighlightCardStat,
  AdHighlightCardStatLabel,
} from '..';

const roasCardCurrentIntervalFragment = graphql(`
  fragment RoasCard_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalRoas
  }
`);

const roasCardPreviousIntervalFragment = graphql(`
  fragment RoasCard_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalRoas
  }
`);

const roasCardCurrentIntervalNotAspireSourcedFragment = graphql(`
  fragment RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights on MetaSumInsights {
    totalRoas
  }
`);

type RoasCardProps = {
  currentInterval: FragmentType<typeof roasCardCurrentIntervalFragment> | null | undefined;
  previousInterval: FragmentType<typeof roasCardPreviousIntervalFragment> | null | undefined;
  currentIntervalNotAspireSourced:
    | FragmentType<typeof roasCardCurrentIntervalNotAspireSourcedFragment>
    | null
    | undefined;
};

const RoasCard = ({ currentInterval, previousInterval, currentIntervalNotAspireSourced }: RoasCardProps) => {
  const currentIntervalData = useFragment(roasCardCurrentIntervalFragment, currentInterval);
  const previousIntervalData = useFragment(roasCardPreviousIntervalFragment, previousInterval);
  const currentIntervalNotAspireSourcedData = useFragment(
    roasCardCurrentIntervalNotAspireSourcedFragment,
    currentIntervalNotAspireSourced,
  );

  const {
    percentChange,
    formattedCurrentIntervalRoas,
    formattedCurrentIntervalNotAspireSourcedRoas,
  } = calculateRoasMetrics({
    currentInterval: currentIntervalData,
    previousInterval: previousIntervalData,
    currentIntervalNotAspireSourced: currentIntervalNotAspireSourcedData,
  });

  return (
    <Card>
      <AdHighlightCardHeader className="bg-purple-6">
        <AdHighlightCardTitle>
          <BadgeDollarIcon className="mr-2" />
          <span>ROAS</span>
        </AdHighlightCardTitle>
      </AdHighlightCardHeader>
      <AdHighlightCardContent>
        <AdHighlightCardContentRow>
          <AdHighlightCardContentColumn className="col-span-3">
            <AdHighlightCardStatRow>
              <AdHighlightCardStat className="text-4xl leading-none">
                {currentIntervalData ? formattedCurrentIntervalRoas : <Skeleton className="w-12 h-8" />}
              </AdHighlightCardStat>
              <PercentChangeBadge percent={percentChange} />
            </AdHighlightCardStatRow>

            {/* Label Goes Here */}
            <AdHighlightCardStatLabel>Attributed to Aspire</AdHighlightCardStatLabel>
          </AdHighlightCardContentColumn>
          <AdHighlightCardContentColumn className="col-span-2">
            <AdHighlightCardStatRow>
              <AdHighlightCardStat>
                {currentIntervalNotAspireSourcedData ? (
                  formattedCurrentIntervalNotAspireSourcedRoas
                ) : (
                  <Skeleton className="w-12 h-4" />
                )}
              </AdHighlightCardStat>
            </AdHighlightCardStatRow>

            {/* Label Goes Here */}
            <AdHighlightCardStatLabel>Not Attributed to Aspire</AdHighlightCardStatLabel>
          </AdHighlightCardContentColumn>
        </AdHighlightCardContentRow>
      </AdHighlightCardContent>
    </Card>
  );
};

export default RoasCard;
