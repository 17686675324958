import * as React from 'react';
import cx from 'classnames';

import { UploadProps } from '@affiliates/AspireUI';
import { Upload } from '@revfluence/fresh';
import { OfferImage } from '@affiliates/components';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';

import Text from 'antd/lib/typography/Text';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './ImageUpload.scss';

const { useState, useCallback } = React;

function getBase64(img): Promise<string | ArrayBuffer> {
  const reader = new FileReader();
  reader.readAsDataURL(img);
  return new Promise((resolve) => {
    reader.addEventListener('load', () => resolve(reader.result));
  });
}

interface IProps {
  defaultImageUrl: string;
  className?: string;
  disabled?: boolean;
  largeImage: boolean;
  onChangeFile?(file: File);
  source: OFFER_SOURCE;
  offerForm?: boolean;
}

export const ImageUpload: React.FC<IProps> = (props) => {
  const [imageUrl, setImageUrl] = useState<string>(props.defaultImageUrl);
  const { offerSimplification: isOfferSimplification } = useClientFeatures();
  const { onChangeFile } = props;

  const handleChange = useCallback<UploadProps['onChange']>(async (info) => {
    const tmpImageUrl = await getBase64(info.file.originFileObj);
    setImageUrl(tmpImageUrl as string);
    onChangeFile(info.file.originFileObj as File);
  }, [setImageUrl, onChangeFile]);

  return (
    <div className={styles.imgContain}>
      <OfferImage
        className={cx(styles.img, { [styles.largeImage]: props.largeImage }, { 'h-10 w-10': isOfferSimplification })}
        imageUrl={imageUrl}
        source={props.source}
      />
      {props.offerForm && (
      <Upload
        disabled={props.disabled}
        className={cx(styles.ImageUpload, { [styles.disabled]: props.disabled })}
        onChange={handleChange}
        accept="image/*"
        showUploadList={false}
        multiple={false}
      >
        <div className={cx(props.className, styles.wrapper)}>
          {!props.disabled && !imageUrl && (
          <Text type="secondary" style={{ color: '#fdfdfd' }}>Upload image</Text>
          )}
          {imageUrl && (
          <Text type="secondary" style={{ color: '#fdfdfd' }}>Edit image</Text>
          )}
        </div>
      </Upload>
)}

    </div>
  );
};

ImageUpload.defaultProps = {
  disabled: false,
};
