import 'css-chunk:src/applications/SocialPostApp/pages/SocialAppDashboardPosts.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1wadj_157",
  "justify-content-space-between": "_justify-content-space-between_1wadj_161",
  "tabular-nums": "_tabular-nums_1wadj_165",
  "SocialAppDashboardPosts": "_SocialAppDashboardPosts_1wadj_178",
  "headerSection": "_headerSection_1wadj_178",
  "header": "_header_1wadj_178",
  "subHeader": "_subHeader_1wadj_188",
  "actionSection": "_actionSection_1wadj_215",
  "checkbox": "_checkbox_1wadj_218",
  "selectedCount": "_selectedCount_1wadj_222",
  "selector": "_selector_1wadj_227",
  "userFilterSelect": "_userFilterSelect_1wadj_231",
  "postsSection": "_postsSection_1wadj_234",
  "postsList": "_postsList_1wadj_241",
  "noPostsNotice": "_noPostsNotice_1wadj_244",
  "loadMoreButton": "_loadMoreButton_1wadj_247",
  "postsSpinner": "_postsSpinner_1wadj_253",
  "separator": "_separator_1wadj_257",
  "show": "_show_1wadj_1"
};