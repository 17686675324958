/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  isNil,
  keys,
  forEach,
  map,
  chain,
  get,
  isEmpty,
  isFunction,
} from 'lodash';
import {
  Card,
  Collapse,
  Button,
  Tooltip,
} from '@revfluence/fresh';
import { UserIcon } from '@revfluence/fresh-icons/regular/esm';
import { XmarkIcon, CircleInfoIcon } from '@revfluence/fresh-icons/solid/esm';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { EventName } from '@common';
import {
  useFeatureFlagVerbiage,
  useGetMemberQuery,
  useUpdateMemberMutation,
  useMemberFieldSchemasBySectionsQuery,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { ITag } from '@frontend/app/hooks';
import { useEventContext } from '@frontend/app/context';
import { MemberInput, MemberAgentUpsertInput } from '@frontend/app/types/globalTypes';
import {
  MemberFieldSchemasBySectionsQuery_sections,
  MemberFieldSchemasBySectionsQuery_sections_customData,
} from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';
import {
  MemberProgramsAndCommunitiesQuery_member_communities as ICommunity,
  MemberProgramsAndCommunitiesQuery_member_programs as IProgram,
} from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';
import { GetAllProjectsQuery_projects } from '@frontend/app/queries/types/GetAllProjectsQuery';

import { MemberOwners, UserAvatar } from '@frontend/app/components';
import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import { ClientFeature } from '@frontend/app/constants';
import {
  CustomData,
  Groups,
  MemberData,
  Notes,
  ApplicationData,
  Projects,
  SalesTracking,
  Social,
  Tags,
  Permissions,
  Section,
  SocialPerformance,
  TalentAgents,
} from './components';
import styles from './MemberDetails.scss';

const { useCallback, useMemo, useState } = React;

type IMember = GetMemberQuery_member;
type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_customData;

const sourceEvent = new Map<string, string>([
  ['member_details', EventName.EditMember],
]);

const defaultOpenPanels = ['1', '2', '4', '5', '6', '7', '9', '10', '11', '13', '14'];

const hasSectionSchemas = (memberSchemaSections: MemberFieldSchemasBySectionsQuery_sections, sectionName: string) => {
  const section = get(memberSchemaSections, sectionName, []);
  return !isEmpty(section);
};

interface Props {
  memberId?: number;
  member?: MemberInput;
  projects?: GetAllProjectsQuery_projects[];
  selectedCommunities?: ICommunity[];
  selectedPrograms?: IProgram[];
  selectedAgents?: MemberAgentUpsertInput[];
  selectedTags?: ITag[];
  source?: string;
  isMemberHeader?: boolean;
  isAddingMember?: boolean;
  onClose?: () => void;
  onRequestInsights?: () => void;
  onUpdateMember?: (member: MemberInput) => void;
  onChangeTags?(tags: ITag[]);
  onChangeCommunities?(communities: ICommunity[]);
  onChangePrograms?(programs: IProgram[]);
  onChangeAgents?(agents: MemberAgentUpsertInput[]);
  refetchMembers?(): void;
}

export const MemberDetails = (props: Props) => {
  const { refetchMembers } = props;
  const { showErrorMessage } = useMessagingContext();
  const history = useHistory();

  const {
    data: { member: memberFetched = null } = {},
    refetch,
    loading: loadingMember,
  } = useGetMemberQuery(props.memberId, {
    skip: !props.memberId,
    fetchPolicy: 'network-only',
  });

  let member: GetMemberQuery_member | MemberInput;
  if (props.memberId) {
    member = memberFetched as GetMemberQuery_member;
  } else {
    member = props.member as MemberInput;
  }
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);
  const inboxCCFeatureEnabled = useClientFeatureEnabled(ClientFeature.INBOX_CC);
  const isRefreshUI = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const {
    data: { sections: memberSchemaSections } = {},
    loading: loadingSchemas,
  } = useMemberFieldSchemasBySectionsQuery();

  const verbiage = useFeatureFlagVerbiage();

  const [updateMember] = useUpdateMemberMutation();

  const [hasInstagramInsights, setHasInstagramInsights] = useState(false);

  const addEvent = useEventContext();

  const handleRefetch = useCallback(() => {
    refetch();
    if (refetchMembers) {
      refetchMembers();
    }
  }, [refetch, refetchMembers]);

  const allSchemasById = useMemo<Record<number, MemberFieldSchema>>(() => {
    if (isNil(memberSchemaSections)) {
      return {};
    }

    const otherAppSchemas = chain(memberSchemaSections.otherApps)
      .map((appSection) => appSection.fields as MemberFieldSchema[])
      .flatten()
      .valueOf();
    const allSchemasById = chain(memberSchemaSections)
      .omit('otherApps')
      .values()
      .flatten()
      .concat(otherAppSchemas as any)
      .keyBy('id')
      .valueOf();

    return allSchemasById as any;
  }, [memberSchemaSections]);

  const handleSave = useCallback(
    async (value: MemberInput) => {
      // When adding a new member, notify parent components with changed values
      if (props.onUpdateMember) {
        props.onUpdateMember({
          ...props.member,
          ...value,
          fields: {
            ...props.member.fields,
            ...value.fields,
          },
        });
        return;
      }

      // persists updated member in DB with changed fields
      try {
        await updateMember({
          variables: {
            id: props.memberId,
            member: value,
          },
        });

        if (sourceEvent.has(props.source)) {
          const eventName = sourceEvent.get(props.source);
          const schemaIds = keys(value.fields);
          forEach(schemaIds, (schemaId) => {
            const schema = allSchemasById[schemaId];
            addEvent(
              eventName as EventName,
              {
                field_name: schema.name,
              },
            );
          });

          if (!isNil(value.email)) {
            addEvent(
              eventName as EventName,
              {
                field_name: 'email',
              },
            );
          }
        }

        await refetch();
      } catch (err) {
        if (err.message) {
          const message = err.message.replace(/GraphQL error: /, '');
          showErrorMessage(`Updating member failed. ${message}`);
        } else {
          showErrorMessage('Updating member failed due to unknown error.');
        }
        throw err;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId, allSchemasById, showErrorMessage, updateMember, props.source, addEvent, refetch],
  );

  const match = useRouteMatch();
  const header = useMemo(() => {
    if (!props.memberId) {
      return null;
    }

    if (props.isMemberHeader) {
      const profileUrl = (member as IMember)?.profilePicture;

      return (
        <div className={styles.header}>
          <div className={styles.headerUserInfo}>
            <UserAvatar
              profilePicture={profileUrl}
              name={member?.name}
              size={24}
            />
            <div className={styles.headerUserName}>
              {member?.name}
            </div>
            {isFunction(props.onClose) && (
              <Button
                shape="circle"
                onClick={props.onClose}
              >
                <XmarkIcon />
              </Button>
            )}
          </div>
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: `${match.url}/member/${props.memberId}`,
                });
              }}
            >
              <UserIcon />
              View Full Profile
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.header}>
        <div className={styles.headerUserInfo}>
          <UserIcon />
          <div className={styles.headerUserName}>
            Member Details
          </div>
          {props.onClose && (
            <Button
              shape="circle"
              onClick={props.onClose}
            >
              <XmarkIcon />
            </Button>
          )}
        </div>
      </div>
    );
  }, [props.isMemberHeader, props.memberId, props.onClose, member, history, match]);

  const isLoading = loadingMember || loadingSchemas;

  return (
    <Card
      className={styles.memberDetailsCard}
      bordered={false}
      bodyStyle={{ paddingTop: 0 }}
      headStyle={{ border: 0 }}
      title={header}
    >
      <Collapse
        ghost
        defaultActiveKey={defaultOpenPanels}
        expandIconPosition="end"
        className={styles.Collapse}
      >
        {(isRefreshUI && !props.isAddingMember) && (
          <Section
            key={14}
            header="Relationship Owners"
            loading={isLoading}
            render={() => (
              <MemberOwners memberId={props.memberId} isRefreshUi />
            )}
          />
        )}
        {!tempYoutubeDemoAccountMode && hasSectionSchemas(memberSchemaSections, 'social') && (
          <Section
            key={1}
            header="Social"
            expandable
            loading={isLoading}
            render={({ viewMore, setViewMoreVisibility, setIsSectionEmpty }) => (
              <Social
                member={member}
                schemas={memberSchemaSections?.social}
                onSave={handleSave}
                viewMore={viewMore}
                onViewMoreVisibilityChange={setViewMoreVisibility}
                setIsSectionEmpty={setIsSectionEmpty}
                onInstagramInsightsUpdated={setHasInstagramInsights}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'permissions') && !props.isAddingMember && (
          <Section
            key={2}
            header="Permissions"
            loading={isLoading}
            render={() => (
              <Permissions
                member={member}
                schemas={memberSchemaSections?.permissions}
                hasInstagramInsights={hasInstagramInsights}
                onRequestInsights={props.onRequestInsights}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'socialPerformance') && !props.isAddingMember && (
          <Section
            key={3}
            header="Social Performance"
            loading={isLoading}
            render={() => (
              <SocialPerformance
                member={member}
                schemas={memberSchemaSections?.socialPerformance}
              />
            )}
          />
        )}
        <Section
          key={4}
          header="Tags"
          loading={isLoading}
          render={() => (
            <Tags
              memberId={props.memberId}
              selectedTags={props.selectedTags}
              onChangeTags={props.onChangeTags}
            />
          )}
        />
        <Section
          key={5}
          header={verbiage.Programs}
          loading={isLoading}
          render={() => (
            <Projects
              memberId={props.memberId}
              projects={props.projects}
              selectedPrograms={props.selectedPrograms}
              onChangePrograms={props.onChangePrograms}
              isAddingMember={props.isAddingMember}
            />
          )}
        />
        <Section
          key={6}
          header={verbiage.Communities}
          loading={isLoading}
          render={() => (
            <Groups
              memberId={props.memberId}
              selectedCommunities={props.selectedCommunities}
              onChangeCommunities={props.onChangeCommunities}
            />
          )}
        />
        {inboxCCFeatureEnabled && (
          <Section
            key={13}
            header={(
              <>
                <div className={styles.agentHeader}>
                  Talent Managers
                  <Tooltip className={styles.agentInfoIcon} title="Add up to 3 talent managers to include in emails with this member.">
                    <CircleInfoIcon />
                  </Tooltip>
                </div>
              </>
            )}
            loading={isLoading}
            render={() => (
              <TalentAgents
                curMember={member}
                existingTalentAgents={(member as GetMemberQuery_member)?.talentAgents}
                refetch={handleRefetch}
                isAddingMember={props.isAddingMember}
                selectedAgents={props.selectedAgents}
                setSelectedAgents={props.onChangeAgents}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'recommendedPayments') && !props.isAddingMember && (
          <Section
            key={14}
            header="Recommended Payments"
            loading={isLoading}
            expandable
            render={({ viewMore, setViewMoreVisibility }) => (
              <ApplicationData
                member={member}
                schemas={memberSchemaSections?.recommendedPayments}
                placeholder="No recommended payment fields."
                collapsable
                onSave={handleSave}
                viewMore={viewMore}
                onViewMoreVisibilityChange={setViewMoreVisibility}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'memberData') && (
          <Section
            key={7}
            header="Member Data"
            expandable
            loading={isLoading}
            render={({ viewMore }) => (
              <MemberData
                member={member}
                schemas={memberSchemaSections?.memberData}
                onSave={handleSave}
                viewMore={viewMore}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'customData') && (
          <Section
            key={8}
            header="Custom Data"
            loading={isLoading}
            render={() => (
              <CustomData
                member={member}
                schemas={memberSchemaSections?.customData}
                onSave={handleSave}
              />
            )}
          />
        )}
        {!props.isAddingMember && (
          <Section
            key={9}
            header="Notes"
            expandable
            loading={isLoading}
            render={({ viewMore, setViewMoreVisibility }) => (
              <Notes
                memberId={props.memberId}
                viewMore={viewMore}
                onViewMoreVisibilityChange={setViewMoreVisibility}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'salesTracking') && !props.isAddingMember && (
          <Section
            key={10}
            header="Sales Tracking"
            expandable
            loading={isLoading}
            render={({ viewMore, setViewMoreVisibility }) => (
              <SalesTracking
                member={member}
                schemas={memberSchemaSections?.salesTracking}
                viewMore={viewMore}
                onViewMoreVisibilityChange={setViewMoreVisibility}
                memberId={props.memberId}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'payments') && !props.isAddingMember && (
          <Section
            key={11}
            header="Payment"
            loading={isLoading}
            render={() => (
              <ApplicationData
                member={member}
                schemas={memberSchemaSections?.payments}
                onSave={handleSave}
              />
            )}
          />
        )}
        {hasSectionSchemas(memberSchemaSections, 'socialAnalytics') && !props.isAddingMember && (
          <Section
            key={12}
            header="Social Analytics"
            expandable
            loading={isLoading}
            render={({ viewMore, setViewMoreVisibility }) => (
              <ApplicationData
                member={member}
                schemas={memberSchemaSections?.socialAnalytics}
                collapsable
                onSave={handleSave}
                viewMore={viewMore}
                onViewMoreVisibilityChange={setViewMoreVisibility}
              />
            )}
          />
        )}
        {!props.isAddingMember && map(memberSchemaSections?.otherApps, (section) => (
          <Section
            key={section.appId}
            header={section.appName}
            expandable
            loading={isLoading}
            render={({ viewMore, setViewMoreVisibility }) => (
              <ApplicationData
                member={member}
                schemas={section.fields}
                collapsable
                onSave={handleSave}
                viewMore={viewMore}
                onViewMoreVisibilityChange={setViewMoreVisibility}
              />
            )}
          />
        ))}
      </Collapse>
    </Card>
  );
};
