import * as React from 'react';
import { AutoComplete, Form, Input, Tooltip } from '@revfluence/fresh';
import { useState } from 'react';
import { UTMCustomFieldsProps } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { Wand2, XIcon } from 'lucide-react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Label } from '@frontend/shadcn/components/ui/label';

interface IProps extends UTMCustomFieldsProps<'advanceUrlSettingType'> {
  events: {
    onBlur: () => void;
    onFocus: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}

// eslint-disable-next-line react/display-name
export const RefreshUtmCustomFields: React.FC<Readonly<IProps>> = React.memo(
  ({ key, customName, disabled, index, remove }) => {
    const utmcustomStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      columnGap: '8px',
    };
    const handleInputChange = (newValue) => {
      setValue(newValue);
      setCustomText(newValue);
    };
    const dynamicUtmOption = {
      creatorHandle: '{creator_name}',
      instaHandle: '{creator_ig_username}',
      projectName: '{project_name}',
      linkOfferName: '{link_offer_name}',
      tikTokHandle: '{creator_tiktok_username}',
      memberId: '{member_id}',
    };
    const [, setValue] = useState('');
    const [customText, setCustomText] = useState('');
    const dataSource = ['Dynamic Parameter', ...Object.values(dynamicUtmOption)];
    const disabledOptionStyles = {
      color: 'var(--neutral-color-palette-gray-9, #1F1F21)',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '22px',
    };
    const getHashValidationRule = () => ({
      validator: (_, value) => {
        if (value && (value.includes('#') || value.includes(' ') || value.includes('='))) {
          return Promise.reject(new Error("Value cannot contain the '#', '=' or space character"));
        } else if (!value) {
          return Promise.reject(new Error('Cannot be empty'));
        }
        return Promise.resolve();
      },
    });
    return (
      <div>
        <Label>Custom Parameter</Label>
        <div key={key} style={utmcustomStyle}>
          <Tooltip title="Parameter Key">
            <Form.Item name={[customName, 'key']} rules={[getHashValidationRule()]} className="w-full !mb-0">
              <Input disabled={disabled} placeholder="Label" size="large" />
            </Form.Item>
          </Tooltip>
          <div className="w-full relative">
            <Tooltip title="Parameter Value">
              <Form.Item name={[customName, 'value']} rules={[getHashValidationRule()]} className="w-full !mb-0">
                <AutoComplete
                  value={customText}
                  onChange={handleInputChange}
                  disabled={disabled}
                  placeholder="Value"
                  size="large"
                  className="w-full"
                >
                  {dataSource.map((option, index) => (
                    <AutoComplete.Option
                      key={index}
                      value={option}
                      disabled={option === 'Dynamic Parameter'}
                      style={option === 'Dynamic Parameter' ? disabledOptionStyles : null}
                    >
                      {option}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Tooltip>
            <div className="absolute right-3 top-1/2 -translate-y-1/2">
              <Wand2 className="h-4 w-4 text-grey-6" />
            </div>
          </div>
          <div>
            <Tooltip title="Remove Parameter">
              {!disabled && (
                <Button type="button" variant="ghost" size="icon" onClick={() => remove(index)}>
                  <XIcon className="h-4 w-4" />
                </Button>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
    );
  },
);
