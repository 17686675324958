import * as React from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';

interface OrderRequestFooterProps {
  // Email composer ref
  emailComposerRef: React.RefObject<any>;

  // Button text
  cancelText?: string;
  continueWithoutMessageText?: string;
  nextReviewText?: string;
  backText?: string;
  recipientCount: number;

  // Button icons
  showCancelIcon?: boolean;
  showNextReviewIcon?: boolean;
  showBackIcon?: boolean;
  showSendToRecipientsIcon?: boolean;

  // Button visibility
  showCancelButton?: boolean;
  showContinueWithoutMessageButton?: boolean;
  showNextReviewButton?: boolean;
  showBackButton?: boolean;
  showSendToRecipientsButton?: boolean;

  // External actions
  onCancel: () => void;
  onContinueWithoutMessage: () => void;

  // Custom button props
  cancelButtonProps?: React.ComponentProps<typeof Button>;
  continueWithoutMessageButtonProps?: React.ComponentProps<typeof Button>;
  nextReviewButtonProps?: React.ComponentProps<typeof Button>;
  backButtonProps?: React.ComponentProps<typeof Button>;
  sendToRecipientsButtonProps?: React.ComponentProps<typeof Button>;
}

export const OrderRequestFooter: React.FC<OrderRequestFooterProps> = ({
  // Email composer ref
  emailComposerRef,

  // Button text
  cancelText = 'Cancel',
  continueWithoutMessageText = 'Continue without Message',
  nextReviewText = 'Next: Review Email',
  backText = 'Back',
  recipientCount,

  // Button icons
  showCancelIcon = false,
  showNextReviewIcon = false,
  showBackIcon = false,
  showSendToRecipientsIcon = false,

  // Button visibility
  showCancelButton = true,
  showContinueWithoutMessageButton = true,
  showNextReviewButton = true,
  showBackButton = true,
  showSendToRecipientsButton = true,

  // External actions
  onCancel,
  onContinueWithoutMessage,

  // Custom button props
  cancelButtonProps = {},
  continueWithoutMessageButtonProps = {},
  nextReviewButtonProps = {},
  backButtonProps = {},
  sendToRecipientsButtonProps = {},
}) => {
  const [isReviewMode, setIsReviewMode] = React.useState(false);

  const [isSending, setIsSending] = React.useState(false);

  const handleReviewMessage = () => {
    if (emailComposerRef?.current?.reviewMessage) {
      emailComposerRef.current.reviewMessage();
    }

    setIsReviewMode(true);
  };

  const handleBackToEdit = () => {
    if (emailComposerRef?.current?.backToEdit) {
      emailComposerRef.current.backToEdit();
    }

    setIsReviewMode(false);
  };

  const handleSendToRecipients = async () => {
    if (emailComposerRef?.current?.sendMessage) {
      setIsSending(true);
      await emailComposerRef.current.sendMessage();
      setIsSending(false);
    }
  };

  const recipientText = recipientCount === 1
    ? `Send to ${recipientCount} Recipient`
    : `Send to ${recipientCount} Recipients`;

  return (
    <div className="fixed bottom-0 right-0 w-[1000px] max-w-[1000px] z-[9999] flex justify-center items-center gap-3 p-8 border-t border-gray-200 bg-white">
      {isReviewMode ? (
        <>
          {showBackButton && (
            <Button
              variant="outline"
              onClick={handleBackToEdit}
              className="gap-2"
              {...backButtonProps}
            >
              {showBackIcon && <ChevronLeftIcon />}
              {backText}
            </Button>
          )}
          {showSendToRecipientsButton && (
            <Button
              variant="primary"
              onClick={handleSendToRecipients}
              className="gap-2"
              {...sendToRecipientsButtonProps}
              loading={isSending}
            >
              {recipientText}
              {showSendToRecipientsIcon && <ChevronRightIcon />}
            </Button>
          )}
        </>
      ) : (
        <>
          {showCancelButton && (
            <Button
              variant="outline"
              onClick={onCancel}
              className="gap-2"
              {...cancelButtonProps}
            >
              {showCancelIcon && <ChevronLeftIcon />}
              {cancelText}
            </Button>
          )}
          {showContinueWithoutMessageButton && (
            <Button
              variant="outline"
              onClick={onContinueWithoutMessage}
              {...continueWithoutMessageButtonProps}
            >
              {continueWithoutMessageText}
            </Button>
          )}
          {showNextReviewButton && (
            <Button
              variant="primary"
              onClick={handleReviewMessage}
              className="gap-2"
              {...nextReviewButtonProps}
            >
              {nextReviewText}
              {showNextReviewIcon && <ChevronRightIcon />}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
