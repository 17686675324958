import * as React from 'react';
import { Alert, Col, Row } from 'antd';
import {
  first, isBoolean, isEmpty, isNull, map,
} from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LoadSpinner } from '@components';

import { Notice, PageSection } from '@affiliates/AspireUI';
import {
  OfferDetails,
  MemberTable,
  ShopifyScopesMissing,
  OfferSummaryCard,
  OfferStatisticsCard,
} from '@affiliates/components';
import { EmailComposeButton, MembersWizard } from '@affiliates/containers';
import {
  ISTAComposerMemberFieldIds,
  MEMBER_FIRST_NAME,
  OfferDetailsQueriesStatus,
  STA_FIELD_NAME,
  useOfferDetailsQueries,
} from '@affiliates/hooks';
import { OFFER_SOURCE, OFFER_STATUS, UserInfoInput } from '@affiliates/types/globalTypes';
import { DateFilter, IDateRangeSettings } from '@frontend/app/components';
import { Button, Modal, Typography } from '@revfluence/fresh';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { ChartSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import { getConversionDetailsOfferBlocks } from '@frontend/app/utils/getConversionDetailsBlocks';
import moment from 'moment';
import { BoxArchiveIcon, CircleExclamationIcon } from '@revfluence/fresh-icons/regular';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { ManageOffer } from '../components/ManageOffer/ManageOffer';
import { MemberConversionDrawer } from '../components/MemberConversionDrawer';
import { PayoutSummaryCard } from '../components/PayoutSummaryCard';
import styles from '../components/OfferDetails/OfferDetails.scss';
import { MigrationModelContent } from '../components/MigrationModelContent';
import { OfferLogDrawer } from '../components/OfferLogDrawer';
import { IStatisticsItem } from '../components/StatisticsBlock/types';
import ShopifyStoreDisconnectionAlert from '../components/ShopifyStoreDisconnectionAlert/ShopifyStoreDisconnectionAlert';
import AlertBar from './Dashboard/LandingPageNavigation';
import { TOfferMember } from '../components/MemberTable/types';

const { useState, useEffect } = React;
interface IProps {
  baseUri: string;
  dateRangeSettings: IDateRangeSettings;
  shopifyAppId: string;
  staComposerMemberFieldIds: ISTAComposerMemberFieldIds;
  profile: UserInfoInput;
  brandName?: string;
  onDeletedMembersChange?: (members: readonly TOfferMember[]) => void;
  isDeleteAction: boolean;
  setIsDeleteAction: (value: boolean) => void;
}

interface IParams {
  offerId: string;
}
interface StateType {
  isUpdated: boolean;
}

function formatArchivedMessage(archivedDate: string | null, archivedUserName: string | null): string {
  if (!archivedDate || !archivedUserName) return '';

  return `This offer was archived on ${moment(archivedDate).format('MM/DD/YY')} by ${archivedUserName}. All offer insights are still available but you can no longer add new members.`;
}

export const OfferDetailsPage: React.FC<Readonly<IProps>> = (props) => {
  const {
    baseUri, dateRangeSettings, shopifyAppId, staComposerMemberFieldIds, profile, brandName, onDeletedMembersChange,
    isDeleteAction,
    setIsDeleteAction,
  } = props;

  const { Text, Title } = Typography;
  const location = useLocation<StateType | undefined>();
  const {
    migrateToGraphQL,
    enableMultipleShopify: isEnabledMultipleShopify,
    multiCurrency: isMulticurrency,
    archiveOffer,
    refreshUi,
  } = useClientFeatures();
  const { offerId: offerIdStr } = useParams<IParams>();
  const offerId = Number(offerIdStr);
  const { isManageDeepLinksAllowed, linkForPromo, shopifyLandingPage } = useClientFeatures();
  const {
    setOffer,
    isOfferLogDrawerVisible,
    setIsOfferLogDrawerVisible,
    showMigrationModal,
    setShowMigrationModal,
    isOldOffer,
    setOfferFormValue,
  } = useOfferDetailsContext();
  const queries = useOfferDetailsQueries(offerId, dateRangeSettings, isEnabledMultipleShopify);
  const history = useHistory();
  const [isNotifyModalOpen, openNotifyMemberModal] = useState(false);
  const state = location.state;
  const closeNotifyModal = () => {
    state.isUpdated = false;
    openNotifyMemberModal(false);
  };

  useEffect(() => {
    setOffer(null);
    setOfferFormValue(null);
    setIsOfferLogDrawerVisible(false);
  }, [setIsOfferLogDrawerVisible, setOffer, setOfferFormValue]);

  const [headerBlocks, setHeaderBlocks] = useState<IStatisticsItem[]>([]);
  const hasEffectRun = React.useRef(false);
  useEffect(() => {
    if (queries.status === OfferDetailsQueriesStatus.Ready) {
      setOffer(queries.offerDetails);
      dateRangeSettings.setEarliestDate(queries.offerDetails.createdDate);
    }
    if (!hasEffectRun.current && queries.status === OfferDetailsQueriesStatus.Ready) {
      const affiliateOfferLinkGenerated = first(queries.offerDetails.links)?.affiliateOfferLinkGenerated;
      hasEffectRun.current = true;
      if (affiliateOfferLinkGenerated && state && state?.isUpdated) {
        openNotifyMemberModal(true);
      }
    }
  }, [queries, dateRangeSettings, openNotifyMemberModal, state, setOffer]);
  useEffect(() => {
    if (
      queries.status === OfferDetailsQueriesStatus.Ready
      && queries.offerDetails?.archivedDate !== null
      && archiveOffer
    ) {
      Modal.warning({
        title: 'Archive Offer',
        content: formatArchivedMessage(
          queries.offerDetails?.archivedDate,
          queries.offerDetails?.archivedUser?.name,
        ),
        icon: <CircleExclamationIcon />,
        autoFocusButton: null,
        okText: 'Continue',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.status, archiveOffer]);

  useEffect(() => {
    if (queries.status === OfferDetailsQueriesStatus.Ready) {
      const source = !isEmpty(queries.offerDetails.promos) ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE;
      const isMultipleShopifyOffer = first(queries.offerDetails.promos)?.multiShopifyEnabled;
      setHeaderBlocks(
        getConversionDetailsOfferBlocks(
          queries?.offerMembers?.length,
          queries.offerStats,
          source,
          isMulticurrency,
          isMultipleShopifyOffer,
          queries.offerDetails.isPromoLink && linkForPromo,
        ),
      );
      onDeletedMembersChange?.(queries.deletedMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.status, isDeleteAction, onDeletedMembersChange, setIsDeleteAction]);

  const getOfferDetailsPageContents = () => {
    switch (queries.status) {
      case OfferDetailsQueriesStatus.Loading:
        return <LoadSpinner />;
      case OfferDetailsQueriesStatus.Failed:
        return <Notice type="error" message={queries.error.message} />;
      default:
        let offerStatus = null;
        let source = OFFER_SOURCE.SHOPIFY;
        let isMultipleShopifyOffer: boolean = false;
        if (!isEmpty(queries.offerDetails.promos)) {
          source = OFFER_SOURCE.SHOPIFY;
          isMultipleShopifyOffer = first(queries.offerDetails.promos)?.multiShopifyEnabled;
          if (queries.missingShopifyCredentials) {
            offerStatus = OFFER_STATUS.PAUSED;
          } else {
            offerStatus = first(queries.offerDetails.promos).status;
          }
        } else if (!isEmpty(queries.offerDetails.links) && !queries.offerDetails.isPromoLink) {
          source = OFFER_SOURCE.TUNE;
          offerStatus = first(queries.offerDetails.links).status;
        }
        let includedMemberIds = queries.memberTable.selectedAffiliateIds;
        if (isEmpty(includedMemberIds)) {
          includedMemberIds = map(queries.offerMembers, (m) => m.memberId);
        }
        const disableCompose = queries.memberTable.disableCompose
          || staComposerMemberFieldIds[MEMBER_FIRST_NAME] < 1
          || staComposerMemberFieldIds[STA_FIELD_NAME] < 1;
        const emailComposeButton = (
          <EmailComposeButton
            disableCompose={disableCompose}
            includeMemberIds={includedMemberIds}
            offer={queries.offerDetails}
            staComposerMemberFieldIds={staComposerMemberFieldIds}
            source={source}
          />
        );

        const emailUpdateComposeButton = (
          <EmailComposeButton
            disableCompose={disableCompose}
            includeMemberIds={includedMemberIds}
            offer={queries.offerDetails}
            staComposerMemberFieldIds={staComposerMemberFieldIds}
            source={source}
            profile={profile}
            isSourceEditOffer
            brandName={brandName}
          />
        );

        const includeFailedMembersWizard = !isEmpty(queries.failedMembers.members)
          && !(source === OFFER_SOURCE.SHOPIFY && queries.missingShopifyCredentials);

        const handleClickEditOffer = () => {
          if (source === OFFER_SOURCE.TUNE) {
            const link = first(queries.offerDetails.links);
            if (migrateToGraphQL && isNull(link.defaultPayoutId)) {
              setShowMigrationModal(true);
            } else {
              history.push({
                ...location,
                pathname: `${baseUri}/offers/${offerId}/edit`,
                state: {
                  isNewFlow: queries.offerDetails.isNewFlow,
                },
              });
            }
          }
          if (source === OFFER_SOURCE.SHOPIFY) {
            const promo = first(queries.offerDetails.promos);
            if (migrateToGraphQL) {
              if (queries.offerDetails.isNewFlow && promo.defaultPayoutId) {
                history.push({
                  ...location,
                  pathname: `${baseUri}/offers/${offerId}/edit`,
                  state: { isNewFlow: true, isMigrationEnabled: false },
                });
              } else {
                setShowMigrationModal(true);
              }
            } else {
              history.push({
                ...location,
                pathname: `${baseUri}/offers/${offerId}/edit`,
                state: {
                  isNewFlow: queries.offerDetails.isNewFlow,
                },
              });
            }
          }
        };

        const { earliestDate } = dateRangeSettings;
        return (
          <>
            {shopifyLandingPage && linkForPromo && queries.offerDetails.isLandingPageEnabled && (
              <AlertBar baseUri={baseUri} offerId={offerId} landingPageUrl={queries.offerDetails.landingPageUrl} isOfferExpired={queries.offerDetails.expired || queries.offerDetails?.archivedDate} />
            )}
            {queries.offerDetails.archivedDate && (
              <div className={styles.archiveOfferAlert}>
                <Alert
                  message={formatArchivedMessage(
                    queries.offerDetails?.archivedDate,
                    queries.offerDetails?.archivedUser?.name,
                  )}
                  type="warning"
                  icon={<BoxArchiveIcon />}
                  showIcon
                />
              </div>
            )}
            {migrateToGraphQL && !isOldOffer ? (
              <PageSection>
                <Row style={{ padding: '0px 16px' }}>
                  <Col span={24} style={{ marginBottom: '24px' }}>
                    <Row justify="space-between" align="middle">
                      <Row gutter={8}>
                        <Col>
                          {' '}
                          <ChartSimpleIcon fontSize={18} />
                        </Col>
                        <Col>
                          {' '}
                          <Title level={5}>Sales Stats</Title>
                        </Col>
                      </Row>
                      {!refreshUi && (
                        <Col>
                          <DateFilter earliestDate={earliestDate} settings={dateRangeSettings} />
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <PayoutSummaryCard
                    source={source}
                    blocks={headerBlocks}
                    storeStats={queries.multipleShopifyOfferStats}
                    isMemberSummary={false}
                  // baseUri={baseUri}
                  // offerId={offerId}
                  />
                </Row>
              </PageSection>
            ) : (
              <PageSection style={{ backgroundColor: '#f9fafb' }}>
                {migrateToGraphQL && isOldOffer && (
                  <Alert
                    message="Upgrade Your Offer: To continue editing or adding members to your offer, please upgrade to the latest sales tracking capabilities. Click 'Upgrade Now' to proceed. "
                    type="info"
                    showIcon
                    action={(
                      <Button size="small" type="ghost" onClick={() => setShowMigrationModal(true)}>
                        Upgrade Now
                      </Button>
                    )}
                    style={{ marginBottom: '8px' }}
                  />
                )}
                <Row gutter={32}>
                  <Col span={8}>
                    <div className={styles.OfferDetailsSummary}>
                      <OfferSummaryCard
                        missingShopifyCredentials={queries.missingShopifyCredentials}
                        onClickEdit={handleClickEditOffer}
                        offer={queries.offerDetails}
                        offerFormVariant={false}
                        source={source}
                        isNewFlow={queries.offerDetails.isNewFlow}
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    <OfferStatisticsCard
                      source={source}
                      stats={queries.offerStats}
                      isPromoLink={queries.offerDetails.isPromoLink}
                    />
                  </Col>
                </Row>
              </PageSection>
            )}
            {source === OFFER_SOURCE.SHOPIFY && isBoolean(queries.missingShopifyCredentials) && (
              queries.missingShopifyCredentials ? (
                <ShopifyScopesMissing
                  onClick={() => {
                    history.push({
                      ...location,
                      pathname: `/settings/${encodeURIComponent(shopifyAppId)}`,
                    });
                  }}
                />
              ) : (
                <ShopifyStoreDisconnectionAlert refreshUi={refreshUi} />

              )
            )}

            <PageSection className="bg-white">
              <MemberTable
                buttonActions={queries.memberTable.buttonActions}
                emailComposeButton={emailComposeButton}
                fixFailedMembers={queries.failedMembers.showFailedMembers}
                members={queries.offerMembers}
                missingShopifyCredentials={source === OFFER_SOURCE.SHOPIFY && queries.missingShopifyCredentials}
                offer={queries.offerDetails}
                currencies={queries.offerStats?.currencies}
                offerSource={source}
                offerStatus={offerStatus}
                onSelectMembers={queries.memberTable.onSelectMembers}
                selectedAffiliateIds={queries.memberTable.selectedAffiliateIds}
                failedPromoCodeErrors={queries.failedPromoCodeErrors}
                showStoreLevelAffiliateStats={queries.multipleShopifyAffiliateStats.visible}
                connectedStores={queries.multipleShopifyOfferStats.map((s) => ({
                  storeName: s.storeName,
                  clientShopifyConnectionId: s.clientShopifyConnectionId,
                }))}
                affiliatesStats={queries.multipleShopifyAffiliateStats.affiliatesStats}
                shopifyStoreName={queries.shopifyStoreName}
                onComplete={queries.refresh}
                setHeaderBlock={setHeaderBlocks}
                isMultipleShopifyOffer={isMultipleShopifyOffer}
              />
              <MembersWizard
                offerSource={source}
                mode={queries.updateMembers.mode}
                offer={queries.offerDetails}
                onClose={queries.updateMembers.onClose}
                onComplete={queries.refresh}
                selectedMembers={queries.updateMembers.selectedMembers}
                refreshDatesOnly={queries.updateMembers.refreshDatesOnly}
                visible={queries.updateMembers.visible}
                failedPromoCodeErrors={queries.failedPromoCodeErrors}
              />
              {includeFailedMembersWizard && (
                <MembersWizard
                  offerSource={source}
                  mode="refresh"
                  offer={queries.offerDetails}
                  onClose={queries.failedMembers.dismissFailedMembers}
                  onComplete={queries.refresh}
                  refreshDatesOnly={false}
                  selectedMembers={queries.failedMembers.members}
                  visible={queries.failedMembers.visible}
                  failedPromoCodeErrors={queries.failedPromoCodeErrors}
                />
              )}
              <ManageOffer
                onComplete={queries.refresh}
                offer={queries.offerDetails}
                visible={queries.updateManageOffer.visible}
                offerSource={source}
                selectedMembers={queries.updateMembers.selectedMembers}
                onClose={queries.updateManageOffer.onClose}
                manageDeepLinksAllowed={isManageDeepLinksAllowed}
              />
              <MemberConversionDrawer
                offer={queries.offerDetails}
                source={source}
                isOpen={queries.memberConversion.visible}
                selectedMemberConversion={queries.memberConversion.selectedMemberConversion}
                onClose={queries.memberConversion.onClose}
                refresh={queries.refresh}
                dateRangeSettings={dateRangeSettings}
              />

              <MigrationModelContent
                baseUri={baseUri}
                showMigrationModal={showMigrationModal}
                setShowMigrationModal={setShowMigrationModal}
                offer={queries.offerDetails}
                source={source}
                profile={profile}
                onComplete={queries.refresh}
              />

              <OfferLogDrawer
                offerId={queries.offerDetails.id}
                onClose={() => setIsOfferLogDrawerVisible(false)}
                open={isOfferLogDrawerVisible}
                source={source}
              />
              {!isEmpty(includedMemberIds) && (
                <Modal
                  title="Notify members"
                  open={isNotifyModalOpen}
                  onOk={closeNotifyModal}
                  onCancel={closeNotifyModal}
                  cancelText="Don’t Notify"
                  okText={emailUpdateComposeButton}
                  okType="primary"
                >
                  <Text>Do you want to send an email to notify all the members in this offer of the changes?</Text>
                  <br />
                  <Text>Changes will be directly reflected in the creator portal.</Text>
                </Modal>
              )}
            </PageSection>
          </>
        );
    }
  };

  return <OfferDetails>{getOfferDetailsPageContents()}</OfferDetails>;
};
