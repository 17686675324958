import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { CircleInfoIcon, MegaphoneIcon } from '@revfluence/fresh-icons/solid/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
  HighlightCardHeaderRow,
} from '../../../components/highlight-cards';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemValue,
  TooltipArrow,
  TooltipContent,
} from '../../../components/tooltip';

export const reachCardFragment = graphql(`
  fragment ReachCard_TotalInsights on TotalInsights {
    reach
  }
`);

interface ReachCardProps {
  data: FragmentType<typeof reachCardFragment> | null | undefined;
}

const ReachCard: React.FC<ReachCardProps> = ({ data }) => {
  const totalInsights = useFragment(reachCardFragment, data);
  const value = totalInsights?.reach ?? 0;

  return (
    <TooltipProvider>
      <Card>
        <HighlightCardHeader className="bg-teal-6" role="heading">
          <HighlightCardHeaderRow>
            <HighlightCardTitle>
              <MegaphoneIcon className="mr-2 text-[1.5rem]" aria-hidden="true" />
              <span className="text-base">Reach</span>
            </HighlightCardTitle>
            <HighlightCardTitle>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    type="button"
                    aria-label="View reach metrics information"
                    data-dd-action-name="view-reach-info"
                  >
                    <CircleInfoIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </TooltipTrigger>
                <TooltipContent role="tooltip" aria-live="polite">
                  <TooltipHeader>
                    <TooltipTitle>Reach</TooltipTitle>
                  </TooltipHeader>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemValue>Total number of unique users who viewed at least one post.</TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </HighlightCardTitle>
          </HighlightCardHeaderRow>
        </HighlightCardHeader>
        <HighlightCardContent>
          <HighlightCardContentRow>
            <HighlightCardContentColumn>
              <HighlightCardStatRow>
                <HighlightCardStat
                  className="text-3xl leading-none"
                  role="status"
                  aria-label={`Total reach count: ${formatNumberDefault(value)}`}
                  data-dd-action-name="reach-stat"
                >
                  {formatNumberDefault(value)}
                </HighlightCardStat>
              </HighlightCardStatRow>
              <HighlightCardStatLabel>Total Reach</HighlightCardStatLabel>
            </HighlightCardContentColumn>
          </HighlightCardContentRow>
        </HighlightCardContent>

        {/* Hidden table for screen readers with reach metrics */}
        <div className="sr-only">
          <table>
            <caption>Reach Metrics</caption>
            <tbody>
              <tr>
                <th scope="row">Total Reach</th>
                <td>{formatNumberDefault(value)} unique accounts have seen posts</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </TooltipProvider>
  );
};

export default ReachCard;
