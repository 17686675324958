import * as React from 'react';

import { Form } from '@revfluence/fresh';
import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { IAffiliateLinksFormElementProps, OfferFormAsyncActions } from '../../../../types';
import { trackingMethodLabels } from '../../../../utils/OfferFormUtils';

interface IProps extends IAffiliateLinksFormElementProps<'conversionTrackingType'> {
  events: {
    onBlur: () => void;
    onFocus: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}

export const RefreshOfferConversionTracking: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  handleFormAsyncActions,
  events,
  value,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer conversion tracking!' },
  ];
  const disabledText = disabled ? "Can't be modified since at least one of your affiliate links uses a deep link." : undefined;

  const onChange = (type: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value: type },
    });
  };
  return (
    <Form.Item rules={rules} extra={disabledText} className="!m-0   ">
      <Select value={value} disabled={disabled} onValueChange={onChange} {...events}>
        <SelectTrigger data-testid="refresh-conversionTracking-id" className="rounded-xl bg-grey-2 px-4 py-2 text-primary text-xs font-normal focus:outline-none focus:ring-0 focus:border-none h-6" style={{ width: 'max-content' }}>
          <SelectValue>
            {value ? `Tracking Method: ${trackingMethodLabels[value]}` : 'Tracking Method' }
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK}>Javascript Postback</SelectItem>
          <SelectItem value={OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID}>
            Server Postback w/ Transaction ID
          </SelectItem>
          <SelectItem value={OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID} data-testid="postback">
            Server Postback w/ Partner ID
          </SelectItem>
          <SelectItem value={OFFER_TRACKING_TYPE.HTTPS_IMAGE_PIXEL}> HTTPS Image Pixel</SelectItem>
          <SelectItem value={OFFER_TRACKING_TYPE.HTTPS_IFRAME_PIXEL}> HTTPS iFrame Pixel</SelectItem>
        </SelectContent>
      </Select>
    </Form.Item>
  );
});
