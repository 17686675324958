import gql from 'graphql-tag';
import { PRODUCT_VARIANT_FRAGMENT } from './fragments/productVariant';
import { PRODUCT_V2_FRAGMENT } from './fragments/productV2';

export const GET_IMPORTED_PRODUCTS = gql`
  query GetImportedProducts(
    $collectionId: Int!
    $page: Int
    $pageSize: Int
    $search: String
    $includeVariants: Boolean = false
    $sortBy: SortField
    $sortDirection: SortDirection
  ) {
    getProductsByCollectionId(
      collectionId: $collectionId
      page: $page
      pageSize: $pageSize
      search: $search
      includeVariants: $includeVariants
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      products {
        ...ProductV2Fragment
        productVariants @include(if: $includeVariants) {
          ...ProductVariantFragment
        }
      }
      totalProducts
    }
  }
  ${PRODUCT_V2_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
`;

export const GET_ALL_IMPORTED_PRODUCTS = gql`
  query GetAllImportedProducts(
    $page: Int
    $pageSize: Int
    $searchName: String
    $includeVariants: Boolean = false
  ) {
    getAllImportedProducts(
      page: $page
      pageSize: $pageSize
      searchName: $searchName
      includeVariants: $includeVariants
    ) {
      products {
        ...ProductV2Fragment
        productVariants @include(if: $includeVariants) {
          ...ProductVariantFragment
        }
      }
      totalProducts
    }
  }
  ${PRODUCT_V2_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
`;
