import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { CircleInfoIcon, ChartLineIcon } from '@revfluence/fresh-icons/solid/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipBody,
  TooltipContent,
  TooltipHeader,
  TooltipItem,
  TooltipItemValue,
  TooltipTitle,
  TooltipArrow,
} from '@frontend/applications/Social/components/tooltip';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
  HighlightCardHeaderRow,
} from '../../../components/highlight-cards';

export const tmvCardFragment = graphql(`
  fragment TMVCard_TotalInsights on TotalInsights {
    tmv
  }
`);

interface TMVCardProps {
  data: FragmentType<typeof tmvCardFragment> | null | undefined;
}

const TMVCard: React.FC<TMVCardProps> = ({ data }) => {
  const totalInsights = useFragment(tmvCardFragment, data);
  const value = totalInsights?.tmv ?? 0;

  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

  return (
    <TooltipProvider>
      <Card>
        <HighlightCardHeader className="bg-green-6" role="banner">
          <HighlightCardHeaderRow>
            <HighlightCardTitle>
              <ChartLineIcon className="mr-2 text-[1.5rem]" aria-hidden="true" />
              <span className="text-base">TMV</span>
            </HighlightCardTitle>
            <HighlightCardTitle>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    type="button"
                    aria-label="View total media value metrics information"
                    data-dd-action-name="view-tmv-info"
                  >
                    <CircleInfoIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </TooltipTrigger>
                <TooltipContent role="tooltip" aria-live="polite">
                  <TooltipHeader>
                    <TooltipTitle>Total Media Value</TooltipTitle>
                  </TooltipHeader>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemValue>
                        Total Media Value (TMV) represents the estimated monetary value of content performance,
                        calculated based on engagement, reach, and impressions across all selected posts. This value is
                        filtered by the selected dates, projects, and groups.
                      </TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </HighlightCardTitle>
          </HighlightCardHeaderRow>
        </HighlightCardHeader>
        <HighlightCardContent>
          <HighlightCardContentRow>
            <HighlightCardContentColumn>
              <HighlightCardStatRow>
                <HighlightCardStat
                  className="text-3xl leading-none"
                  role="status"
                  aria-label={`Total media value: ${formattedValue}`}
                  data-dd-action-name="tmv-stat"
                >
                  {formattedValue}
                </HighlightCardStat>
              </HighlightCardStatRow>
              <HighlightCardStatLabel>Total Media Value</HighlightCardStatLabel>
            </HighlightCardContentColumn>
          </HighlightCardContentRow>
        </HighlightCardContent>

        {/* Hidden table for screen readers with TMV metrics */}
        <div className="sr-only">
          <table>
            <caption>Total Media Value Metrics</caption>
            <tbody>
              <tr>
                <th scope="row">Total Media Value</th>
                <td>{formattedValue} filtered by selected dates, projects, and groups</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </TooltipProvider>
  );
};

export default TMVCard;
