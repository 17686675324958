import React, { useRef } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
  DrawerClose,
} from '@frontend/shadcn/components/ui/drawer';
import { XmarkIcon } from '@revfluence/fresh-icons/solid';
import { Button, ButtonProps } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { H4 } from '@frontend/shadcn/components/typography/h4';

interface HeaderProps {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}

export interface SideDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  trigger?: React.ReactNode;
  headerProps?: HeaderProps;
  footerButtons?: ButtonProps[];
  footerButtonAlignment?: 'left' | 'right' | 'center';
  children: React.ReactNode;
}

export const SideDrawer: React.FC<SideDrawerProps> = ({
  open,
  onOpenChange,
  trigger,
  headerProps,
  footerButtons,
  footerButtonAlignment = 'left',
  children,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const footerAlignmentClass = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  }[footerButtonAlignment];

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {trigger && <DrawerTrigger asChild>{trigger}</DrawerTrigger>}
      <DrawerContent className={cn(
        'fixed left-auto inset-y-0 mt-0 right-0 z-[9999] flex h-full w-[600px] flex-col border-l bg-background rounded-none p-0',
        'duration-300 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
        'bg-background backdrop-blur-sm',
        'top-[var(--header-height,0px)] h-[calc(100vh-var(--header-height,0px))]',
      )}
      >
        <div ref={headerRef}>
          <div className="flex items-center justify-between border-b border-grey-3 px-6 py-4">
            <div>
              {headerProps?.title && (
                <H4 className="font-medium mb-0">
                  {headerProps.icon && <span className="mr-2">{headerProps.icon}</span>}
                  {headerProps.title}
                </H4>
              )}
              {headerProps?.description && <p className="text-sm text-muted-foreground">{headerProps.description}</p>}
            </div>
            <DrawerClose asChild>
              <Button variant="ghost" size="headerIcon" className="border border-transparent hover:border-grey-6 hover:bg-transparent">
                <XmarkIcon className="h-4 w-4" />
              </Button>
            </DrawerClose>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">{children}</div>
        {footerButtons?.length > 0 && (
          <div className={`flex flex-row ${footerAlignmentClass} space-x-2 border-t border-grey-3 p-4 bg-gray-50`}>
            {footerButtons.map(({
 name, onClick, variant, disabled,
}, index) => (
  <Button key={index} onClick={onClick} variant={variant} disabled={disabled}>
    {name}
  </Button>
            ))}
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};
