import React from 'react';
import {
 WidgetContainer, MainMetric, SecondaryMetric, SecondaryCardMetric,
 ColorSwatch,
} from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import {
 Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, TooltipProps,
 YAxis,
} from 'recharts';
import { useGetOrdersCostSummary } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrderCostsSummary';
import { format, formatDistanceStrict } from 'date-fns';
import { largeNumberAbbreviatorWithDecimal } from '@frontend/utils';
import { isNil } from 'lodash';
import { useGetCurrencyCode } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCurrencyCode';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

interface PFAOrdersCostProps {
  className?: string;
}

const CustomTooltip = ({ active, payload }: TooltipProps<string, string>) => {
  if (active) {
    const total = largeNumberAbbreviatorWithDecimal(payload?.[0]?.payload?.totalOrderValue || 0);
    const average = largeNumberAbbreviatorWithDecimal(payload?.[0]?.payload?.averageOrderCost || 0);
    return (
      <div className="bg-primary-foreground rounded-lg shadow p-4 pt-2 flex flex-col gap-1">
        <span className="text-sm font-semibold mb-1">{payload?.[0]?.payload?.label}</span>
        {payload?.[0]?.payload?.label !== 'No Data' && (
          <>
            <div className="flex items-center gap-2">
              <ColorSwatch color="hsl(var(--chart-3))" />
              <span className="text-sm text-muted-foreground">
                Total Order Value $
                {total.value}
                {total.symbol}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <ColorSwatch color="hsl(var(--chart-4))" />
              <span className="text-sm text-muted-foreground">
                Average Order Cost $
                {average.value}
                {average.symbol}
              </span>
            </div>
          </>
        )}
      </div>
    );
  }
  return null;
};

export default function PFAOrdersCost({ className }: PFAOrdersCostProps) {
  const { dateRangeSettings } = usePFADashboardContext();

  const { currencyCode } = useGetCurrencyCode();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const distanceInWords = formatDistanceStrict(startDate, endDate);

  const { ordersCostSummary, isOrdersCostSummaryLoading } = useGetOrdersCostSummary({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const totalOrderData = ordersCostSummary?.orderIntervalGraphs?.map((graph) => ({
    label: `${format(new Date(graph.start), 'MMM dd')}-${format(new Date(graph.end), 'MMM dd')}`,
    totalOrderValue: graph.data.totalOrderValue,
    averageOrderCost: graph.data.averageOrderCost,
    totalOrderCount: graph.data.totalOrderCount,
    totalProductUnits: graph.data.totalProductUnits,
    maxOrderValue: graph.data.maxOrderValue,
    minOrderValue: graph.data.minOrderValue,
    color: 'hsl(var(--refresh-blue-6))',
  }));

  const isDataEmpty = !ordersCostSummary?.totalOrderValue;

  const emptyData = [...new Array(6)].map(() => ({
    label: 'No Data',
    totalOrderValue: Math.random() * 1000,
    averageOrderCost: Math.random() * 1000,
    totalOrderCount: 0,
    totalProductUnits: 0,
    maxOrderValue: 0,
    minOrderValue: 0,
    color: 'hsl(var(--refresh-grey-1))',
  }));

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between flex-wrap">
        <MainMetric
          heading="Total Order Value"
          value={ordersCostSummary?.totalOrderValue}
          size="small"
          icon={null}
          metricType="currency"
          className="flex-1"
          colorSwatch="hsl(var(--refresh-green-6))"
          trend={ordersCostSummary?.totalOrderValueTrend}
          subHeading={!isNil(ordersCostSummary?.totalOrderValueTrend) ? `in last ${distanceInWords}` : undefined}
          currencyCode={currencyCode}
        />
        <MainMetric
          heading="Total Order Costs"
          value={ordersCostSummary?.totalOrderCost}
          size="small"
          icon={null}
          metricType="currency"
          className="flex-1"
          colorSwatch="hsl(var(--refresh-green-6))"
          trend={ordersCostSummary?.totalOrderCostTrend}
          subHeading={!isNil(ordersCostSummary?.totalOrderCostTrend) ? `in last ${distanceInWords}` : undefined}
          currencyCode={currencyCode}
        />
        <MainMetric
          heading="Average Order Cost"
          value={ordersCostSummary?.averageOrderCost}
          size="small"
          icon={null}
          metricType="currency"
          className="flex-1"
          colorSwatch="hsl(var(--refresh-yellow-6))"
          trend={ordersCostSummary?.averageOrderCostTrend}
          subHeading={!isNil(ordersCostSummary?.averageOrderCostTrend) ? `in last ${distanceInWords}` : undefined}
          currencyCode={currencyCode}
        />
        <div className="flex-[100%] xl:flex-1">
          <ResponsiveContainer width={220} height={108}>
            <ComposedChart
              width={100}
              height={100}
              data={isDataEmpty ? emptyData : totalOrderData}
              barSize={24}
              barGap={4}
            >
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{
                  top: '-100%',
                }}
              />
              <YAxis yAxisId="bar" hide />
              <YAxis yAxisId="line" hide />
              <Bar yAxisId="bar" dataKey="totalOrderValue" fill={isDataEmpty ? 'hsl(var(--refresh-grey-1))' : 'hsl(var(--chart-3))'} />
              {!isDataEmpty && <Line yAxisId="line" dataKey="averageOrderCost" stroke="hsl(var(--chart-4))" strokeWidth={2} strokeDasharray="5 5" dot={null} />}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex items-center justify-between gap-2">
        <SecondaryCardMetric color="hsl(var(--refresh-teal))" className="flex-1">
          <SecondaryMetric
            heading="Total Product Units"
            value={ordersCostSummary?.totalProductUnits}
            size="xsmall"
            metricType="count"
          />
        </SecondaryCardMetric>
        <SecondaryCardMetric color="hsl(var(--refresh-magenta))" className="flex-1">
          <SecondaryMetric
            heading="Max Order Value"
            value={ordersCostSummary?.maxOrderValue}
            size="xsmall"
            metricType="currency"
            currencyCode={currencyCode}
          />
        </SecondaryCardMetric>
        <SecondaryCardMetric color="hsl(var(--refresh-yellow))" className="flex-1">
          <SecondaryMetric
            heading="Min Order Value"
            value={ordersCostSummary?.minOrderValue}
            size="xsmall"
            metricType="currency"
            currencyCode={currencyCode}
          />
        </SecondaryCardMetric>
      </div>
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Order Cost"
      className={`${className}`}
      bgColor="bg-primary-foreground"
      textColor="text-primary"
    >
      {isOrdersCostSummaryLoading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
