import * as React from 'react';
import cx from 'classnames';
import { ImageWithTag, SocialPostTag } from '@components';
import { useState, useEffect } from 'react';

import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import AccountSection from './AccountSection';
import ValueSection from './ValueSection';
import EngageList from './EngageList';
import styles from './PostCard.scss';

export interface IPostCardSocialPost {
  post_id: string;
  post_type: string;
  network: string;
  mention_id: number;
  reach: string;
  member_id?: number;
  datetime_posted?: string;
  profile_image_url?: string;
  community_ids?: number[];
  community_names?: string[];
  program_ids?: number[];
  program_names?: string[];
  social_account_name?: string;
  social_account_link?: string;
  image_url?: string;
  link?: string;
  creator: string;
  post_text?: string;
  post_image_tags?: string[];
  clicks?: string;
  closeups?: string;
  comments?: string;
  dislikes?: string;
  exits?: string;
  favorites?: string;
  impressions?: string;
  estimated_impressions?: string;
  likes?: string;
  shares?: string;
  taps?: string;
  unique_impressions?: string;
  views?: string;
  tmv?: string;
  roi_pct?: string;
  cost?: string;
  product_cost?: string;
}

interface IProps {
  clientId?: string;
  post: IPostCardSocialPost;
  isQa: boolean;
  hideValueSection?: boolean;
  fallbackSrc?: string;
  tempYoutubeDemoAccountMode?: boolean;
  onError?();
  onLoad?();
  onSizeDetected?(naturalWidth: number, naturalHeight: number);
}

/**
 * @type {React.FunctionComponent}
 */
export const PostCard: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    clientId,
    post,
    hideValueSection,
    isQa,
    tempYoutubeDemoAccountMode,
  } = props;
  const programNames = post.program_names || [];
  const communityNames = post.community_names || [];
  const [imageUrl, setImageUrl] = useState<string>(post.image_url);
  const [isImageFailed, setIsImageFailed] = useState<boolean>(false);

  const isInsightsAuthed = imageUrl?.includes('https://storage.googleapis.com/');
  const getFreshThumbnailUrl = async (postLink: string) => {
    try {
      const resp = await fetch(
        `https://graph.facebook.com/v22.0/instagram_oembed?url=${postLink}&media_url&access_token=491285117675871|W1lKVML_Y7cB-bylpoaEUADW4VY`,
      );

      if (!resp.ok) {
        throw new Error(`Instagram oembed failed with status: ${resp.status || 0}`);
      }

      const json = await resp.json();

      if (json.thumbnail_url) {
        return json.thumbnail_url;
      }
    } catch (e) {
      throw new Error(e);
    }
  };

    const getImageUrl = async () => {
      if (post.post_type !== 'instagram') {
        return;
      }

      if (post.post_type === 'instagram') {
        try {
          const resp = await fetch(post.image_url);
          if (resp.ok === true) {
            setImageUrl(post.image_url);
          } else {
            const image_url = await getFreshThumbnailUrl(post.link);
            setImageUrl(image_url);
          }
        } catch (e) {
          const image_url = await getFreshThumbnailUrl(post.link);
          setImageUrl(image_url);
          throw new Error(e);
        }
      }
    };

    useEffect(() => {
      setImageUrl(post.image_url);
      getImageUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.image_url]);
    return (
      <a
        className={styles.ClearLink}
        href={post.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.PostCard}>
          <section className={styles.contentImageContainer}>
            <div className={styles.contentContainer}>
              <div className={styles.TopShadow} />
              <ImageWithTag
                src={imageUrl}
                className={styles.imgContent}
                onError={() => setIsImageFailed(true)}
                Tag={(
                  <SocialPostTag
                    clientId={clientId}
                    appId={SOCIAL_POST_APP_ID}
                    memberId={post.member_id}
                    postType={post.post_type}
                    isInsightsAuthed={isInsightsAuthed}
                    isFailed={isImageFailed}
                    programNames={programNames}
                    communityNames={communityNames}
                  />
                )}
              />
              <div className={styles.captionContainer}>
                <span className={cx(styles.captionTxt, styles.truncate)}>
                  {post.post_text}
                </span>
              </div>
            </div>
          </section>
          <section className={styles.InfoContainer}>
            <EngageList post={post} tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode} />
            {!hideValueSection && <ValueSection post={post} />}
            <AccountSection isQa={isQa} post={post} tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode} />
          </section>
        </div>
      </a>
    );
  });

PostCard.displayName = 'PostCard';
