import * as React from 'react';
import { Form } from 'antd';
import { OFFER_TRACKING_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { P } from '@frontend/shadcn/components/typography/p';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';

const RefreshAllowMultipleconversions = ({ values, handleFormAsyncActions }) => {
  const { allowMultipleConversions } = useClientFeatures();

  const handleCheckboxChange = (checked: boolean) => {
    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'allowMultipleConversions', value: checked },
    });
  };

  if (
    !(
      values.conversionTrackingType === OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK ||
      values.conversionTrackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID
    ) ||
    !allowMultipleConversions
  ) {
    return null;
  }

  return (
    <>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="multiple-conversions">
          <AccordionTrigger className="hover:no-underline p-0 mt-2">
            <div className="flex flex-col gap-1">
              <P className="text-grey-6" fontWeight="font-medium">
                Multiple Conversions Settings
              </P>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <Form.Item className="!mb-0">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <Checkbox
                    id="allowMultipleConversions"
                    checked={values.allowMultipleConversions}
                    onCheckedChange={handleCheckboxChange}
                  />
                  <label
                    htmlFor="allowMultipleConversions"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Allow multiple conversions
                  </label>
                </div>
                <P className="text-grey-4 text-sm ml-6">
                  You can enable multiple conversions to be tracked within the same session if desired.
                </P>
              </div>
            </Form.Item>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default RefreshAllowMultipleconversions;
