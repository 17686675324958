import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Select, Skeleton, Space, Tabs, Button, Modal, Tooltip,
} from '@revfluence/fresh';
import {
 Route, Switch, useHistory, useParams,
} from 'react-router-dom';
import { CatalogStatus, CatalogType, CreateBrandCatalogInput } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useMessagingContext } from '@frontend/hooks';
import {
  GET_BRAND_CATALOGS_QUERY,
  GET_BRAND_CATALOG_BY_ID_QUERY,
} from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { useQueryParams } from '@frontend/app/hooks';
import { isNil } from 'lodash';
import { TrashIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { useDeleteBrandCatalogById } from '@frontend/applications/ProductFulfillmentApp/hooks/useDeleteBrandCatalogById';
import { Header } from './Header';
import { CatalogHomePage } from './CatalogHomePage';
import styles from './CatalogDetails.scss';
import { useCatalogDetailsContext } from './CatalogDetailsContext';
import { CollectionItems } from './CollectionItems';
import { BasicSelectionProvider } from '../CatalogSelectionModal/BasicSelectionContext';
import { EditCollectionItem } from './EditCollectionItem';
import { SelectionCriteria } from '../SelectionCriteria/SelectionCriteria';
import { SelectionCriteriaProvider } from '../SelectionCriteria/SelectionCriteriaContext';
import { useGetCatalogDetails } from '../hooks/useGetCatalogDetails';
import { useCreateCatalog } from '../hooks/useCreateCatalog';
import { useUpdateCatalog } from '../hooks/useUpdateCatalog';
import { pfaV2Routes } from '../../routes';
import { logger } from '../../../../../../../../common/Logger';
import { FeaturedProducts } from './FeaturedProducts';
import { AdvancedSelectionProvider } from '../AdvancedSelectionModal/AdvancedSelectionContext';
import { Customization } from './Customization';
import { useGetCatalogPreviewIframeUrl } from '../hooks/useGetCatalogPreviewIframeUrl';

export const CatalogDetails = () => {
  const history = useHistory();

  const {
    title, status, description, setStatus, setTitle, setDescription,
  } = useCatalogDetailsContext();

  const { catalogId } = useParams<{ catalogId: string }>();
  const queryParams = useQueryParams();
  const screen = queryParams.get('screen');

  const { catalog, refetch: refetchCatalog, loading: getCatalogDetailsLoading } = useGetCatalogDetails({
    variables: {
      id: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const { catalogPreviewIframeUrl, isCatalogPreviewIframeUrlLoading } = useGetCatalogPreviewIframeUrl({
    variables: {
      id: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const { showErrorMessage, showSuccessMessage, showInfoMessage } = useMessagingContext();

  const { deleteBrandCatalogById, isDeletingBrandCatalogById } = useDeleteBrandCatalogById({
    onCompleted: () => {
      showSuccessMessage('Catalog deleted successfully');
      history.push(pfaV2Routes.settings.brandCatalogs);
    },
    onError: (error) => {
      showErrorMessage(error?.message || 'Failed to delete catalog');
    },
  });

  const { createCatalog } = useCreateCatalog({
    onCompleted: (data) => {
      showSuccessMessage('New catalog created');
      history.push(
        pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', String(data?.createBrandCatalog?.id)),
      );
    },
    onError: (error) => {
      logger.error(error);
      showErrorMessage(error.message || 'Failed to create catalog');
    },
    refetchQueries: [GET_BRAND_CATALOGS_QUERY],
  });

  const { updateCatalog } = useUpdateCatalog({
    onCompleted: (data) => {
      showSuccessMessage('Catalog details updated');
      refetchCatalog({
        id: data?.updateBrandCatalog?.id,
      });
    },
    onError: (error) => {
      logger.error(error);
      showErrorMessage(error.message || 'Failed to update catalog');
    },
    refetchQueries: [GET_BRAND_CATALOG_BY_ID_QUERY],
  });

  const handleDeleteCatalog = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this catalog?',
      icon: <TriangleExclamationIcon />,
      okText: 'Yes',
      okType: 'danger',
      onOk: () => {
        deleteBrandCatalogById({ variables: { id: Number(catalogId) } });
      },
    });
  };

  const handleSaveCatalog = (newValues: Partial<CreateBrandCatalogInput> = {}) => {
    if (!isNil(newValues.name) && newValues.name === '') {
      showErrorMessage('Catalog name is required');
      return null;
    }
    showInfoMessage('Saving catalog details...');
    if (catalogId === 'new') {
      return createCatalog({
        variables: {
          input: {
            name: title,
            description,
            type: CatalogType.CUSTOM,
            status,
            ...newValues,
          },
        },
      });
    } else {
      return updateCatalog({
        variables: {
          input: {
            id: catalog.id,
            name: title,
            description,
            type: CatalogType.CUSTOM,
            status,
            ...newValues,
          },
        },
      });
    }
  };

  const handleStatusChange = (value: CatalogStatus) => {
    setStatus(value);
    handleSaveCatalog({ status: value });
  };

  useEffect(() => {
    if (catalogId !== 'new' && catalog.status) {
      setTitle(catalog.name);
      setStatus(catalog.status);
      setDescription(catalog.description);
    }
  }, [catalog, catalogId, setDescription, setStatus, setTitle]);

  useEffect(() => {
    if (catalogId === 'new') {
      setTitle('Name Your Catalog');
      setStatus(CatalogStatus.DRAFT);
      setDescription('Provide a description');
    }
  }, [catalogId, setDescription, setStatus, setTitle]);

  if (getCatalogDetailsLoading) {
    return (
      <div className={styles.CatalogDetails} data-testid="skeleton">
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.CatalogDetails}>
      <Header
        isDefaultCatalog={catalog.type === CatalogType.DEFAULT}
        backButtonUrl={pfaV2Routes.settings.brandCatalogs}
        rightContent={
          catalog.type === CatalogType.DEFAULT ? null : (
            <Space>
              <Select value={status} onChange={handleStatusChange}>
                <Select.Option value={CatalogStatus.DRAFT}>Draft</Select.Option>
                <Select.Option value={CatalogStatus.ACTIVE}>Active</Select.Option>
              </Select>
              {catalogId !== 'new' && (
                <Tooltip title="Delete catalog">
                  <Button
                    danger
                    icon={<TrashIcon />}
                    onClick={handleDeleteCatalog}
                    loading={isDeletingBrandCatalogById}
                  />
                </Tooltip>
              )}
            </Space>
          )
        }
        handleSaveCatalog={handleSaveCatalog}
      />
      <Tabs
        defaultActiveKey={screen || 'product-listing'}
        items={[
          {
            key: 'product-listing',
            label: 'Product Listing',
            children: (
              <Row justify="space-between" gutter={[24, 24]} wrap={false}>
                <Col flex={1}>
                  <Switch>
                    <Route path={pfaV2Routes.settings.brandCatalogCollections} exact>
                      <BasicSelectionProvider>
                        <AdvancedSelectionProvider>
                          <CollectionItems />
                        </AdvancedSelectionProvider>
                      </BasicSelectionProvider>
                    </Route>
                    <Route path={pfaV2Routes.settings.brandCatalogFeaturedProducts} exact>
                      <BasicSelectionProvider>
                        <FeaturedProducts />
                      </BasicSelectionProvider>
                    </Route>
                    <Route path={pfaV2Routes.settings.brandCatalogsDetailsCollection}>
                      <BasicSelectionProvider>
                        <AdvancedSelectionProvider>
                          <EditCollectionItem />
                        </AdvancedSelectionProvider>
                      </BasicSelectionProvider>
                    </Route>
                    <Route path={pfaV2Routes.settings.brandCatalogsCustomization}>
                      <Customization />
                    </Route>
                    <Route path={pfaV2Routes.settings.brandCatalogsDetails} exact>
                      <CatalogHomePage />
                    </Route>
                  </Switch>
                </Col>
                <Col>
                  {
                    catalogId !== 'new' && (isCatalogPreviewIframeUrlLoading
                      ? <Skeleton />
                      : (
                        <div className="px-6 2xl:px-20 py-6 bg-border">
                          <iframe
                            id="catalog-preview-iframe"
                            src={catalogPreviewIframeUrl}
                            width="375px"
                            height="667px"
                            title="Catalog Preview"
                          />
                        </div>
                      ))
                  }
                </Col>
              </Row>
            ),
          },
          {
            key: 'selection-criteria',
            label: 'Selection Criteria',
            children: (
              <SelectionCriteriaProvider>
                <SelectionCriteria />
              </SelectionCriteriaProvider>
            ),
          },
        ]}
      />
    </div>
  );
};
