/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17lsb_157 {
  display: flex;
}

._justify-content-space-between_17lsb_161 {
  justify-content: space-between;
}

._tabular-nums_17lsb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17lsb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 {
  width: 100%;
  max-width: 1200px;
  padding: 1.5rem;
  margin: 0 auto;
  display: flex;
  column-gap: 1.5rem;
}
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._config_17lsb_186 {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  row-gap: 20px;
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-word;
}
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._config_17lsb_186 h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: var(--gray-9);
}
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._form_17lsb_201 {
  width: 420px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.02), 0 6px 16px rgba(0, 0, 0, 0.02), 0 3px 6px -4px rgba(0, 0, 0, 0.04);
  border-radius: 0.375rem;
  flex-shrink: 0;
  padding: 24px 16px;
  height: fit-content;
}
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._form_17lsb_201 ._signUpForm_17lsb_210 {
  width: 100%;
  max-width: 420px;
  padding: 0;
  margin: 0;
  background-color: transparent !important;
}
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._form_17lsb_201 ._copyright_17lsb_217 {
  width: 100%;
  margin: 16px auto 0;
  font-size: 14px;
  color: var(--grey-100);
}
._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._form_17lsb_201 ._supportEmail_17lsb_223 {
  color: var(--ant-primary);
  text-decoration: none;
}
@media screen and (max-width: 800px) {
  ._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 {
    flex-direction: column;
    column-gap: 0;
  }
  ._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._form_17lsb_201 {
    width: 100%;
    margin-top: 20px;
  }
  ._QuickTemplate_17lsb_178 ._templateContent_17lsb_178 ._form_17lsb_201 ._signUpForm_17lsb_210 {
    max-width: 100%;
  }
}