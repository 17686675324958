import * as React from 'react';
import {
  Form,
} from '@revfluence/fresh';
import { Input } from '@frontend/shadcn/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { validateNonNegativeDecimal } from '../../../../utils';
import { FormAsyncAction, IShopifyFormElementProps, IShopifyPromoCodeFormValues, OfferFormAsyncActions, RecurringCycleLimit } from '../../../../types';

interface IProps extends IShopifyFormElementProps<'offerCodePurchaseRestrictionsRule'> {
  value: IShopifyPromoCodeFormValues['offerCodePurchaseRestrictionsRule'];
  handleFormAsyncActions: (value: FormAsyncAction) => void;
}

export const RefreshOfferRecurringPaymentForSubscriptions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  handleFormAsyncActions,
}) => {
  const rules = [
    { validator: validateNonNegativeDecimal },
    {
      validator: (_, value) => {
        if (value < 2) {
          return Promise.reject(new Error('Discount limit must be greater than 1'));
        }
        return Promise.resolve();
      },
    },
  ];
  const onChange = (status: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value: status },
    });
  };
  return (
    <Form.Item className="!m-0" label="Recurring payments for subscriptions">
      <RadioGroup disabled={disabled} name={name} onValueChange={(value) => onChange(value)} value={value}>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <RadioGroupItem value={RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT} id="firstPayment" />
            <label htmlFor="firstPayment" className="text-sm font-normal text-primary">
              {RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT}
            </label>
          </div>

          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value={RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS} id="multiplePayments" />
              <label htmlFor="multiplePayments" className="text-sm font-normal text-primary">
                {RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS}
              </label>
            </div>

            {value === RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS && (
              <div className="ml-6">
                <Form.Item className="!m-0" name="recurringCycleLimitAmount" rules={rules}>
                  <Input className="w-full max-w-[100px]" defaultValue={0} disabled={disabled} />
                </Form.Item>
                <p className="text-xs text-grey-4">Includes payment on first order.</p>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-2">
            <RadioGroupItem value={RecurringCycleLimit.DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS} id="allPayments" />
            <label htmlFor="allPayments" className="text-sm font-normal text-primary">
              {RecurringCycleLimit.DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS}
            </label>
          </div>
        </div>
      </RadioGroup>
    </Form.Item>
  );
});
RefreshOfferRecurringPaymentForSubscriptions.displayName = 'RefreshOfferRecurringPaymentForSubscriptions';
