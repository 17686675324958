import React, { useCallback, useMemo, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';
import {
  FormAsyncAction,
  IAffiliateLinksFormValues,
  OfferFormAsyncActions,
} from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { OfferFormToolTips } from '@frontend/applications/AffiliatesApp/components/OfferFormToolTip';
import { Alert, Drawer, Form } from 'antd';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { RefreshUtmCustomFields } from '@frontend/applications/AffiliatesApp/components/OfferForm/FormSections/ShopifyPromoCodeSectionNew/FormElements/RefreshForm/AdditionalSettings/SharableLinkAndSecureCode/RefreshUtmCustomFields';
import { RefreshUtmFields } from '@frontend/applications/AffiliatesApp/components/OfferForm/FormSections/ShopifyPromoCodeSectionNew/FormElements/RefreshForm/AdditionalSettings/SharableLinkAndSecureCode/RefreshUtmFields';
import { BookText, FileText, Flag, Globe, Plus, Radio } from 'lucide-react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { generateLinkUrl } from '@frontend/applications/AffiliatesApp/components/OfferForm/utils/OfferFormUtils';
import { P } from '@frontend/shadcn/components/typography/p';

interface IProps {
  onFieldFocused: (field: OfferFormToolTips | null, isFocused: boolean) => void;
  values: IAffiliateLinksFormValues;
  hasMembers: boolean;
  handleFormAsyncActions: (action: FormAsyncAction) => void;
}

export function RefreshUTMSettings({
  onFieldFocused,
  values,
  hasMembers,
  handleFormAsyncActions,
}: IProps): JSX.Element {
  const { editUtmFieldsEnabled } = useClientFeatures();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isThirdPartyIntegration = !!values?.isThirdPartyIntegration;
  const isProjectNameExistsInUtm = useCallback(() => {
    const keysToCheck = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'];
    const customKeysToCheck = values?.customUTMParameters?.map((param) => param.value);
    return (
      keysToCheck.some((key) => values[key] === '{project_name}') ||
      (customKeysToCheck && customKeysToCheck.includes('{project_name}'))
    );
  }, [values])();

  // helper function for UTM fields callbacks
  const generateUtmEvents = (onFieldFocused, tooltipKey) => ({
    onFocus: () => onFieldFocused(OfferFormToolTips[tooltipKey], true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips[tooltipKey], false),
    onMouseLeave: () => onFieldFocused(null, false),
  });

  const checkAdvancedUrlEnabled = () => {
    const isAnyFieldFilled =
      values.utmSource ||
      values.utmMedium ||
      values.utmCampaign ||
      values.utmTerm ||
      values.utmContent ||
      values.customUTMParameters?.some((param) => param.value);

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isAdvancedUrlEnabled', value: isAnyFieldFilled },
    });
  };

  const utmSource = useMemo(() => generateUtmEvents(onFieldFocused, 'utmSource'), [onFieldFocused]);
  const utmMedium = useMemo(() => generateUtmEvents(onFieldFocused, 'utmMedium'), [onFieldFocused]);
  const utmCampaign = useMemo(() => generateUtmEvents(onFieldFocused, 'utmCampaign'), [onFieldFocused]);
  const utmContent = useMemo(() => generateUtmEvents(onFieldFocused, 'utmContent'), [onFieldFocused]);
  const utmTerm = useMemo(() => generateUtmEvents(onFieldFocused, 'utmTerm'), [onFieldFocused]);
  const customUtmFields = useMemo(() => generateUtmEvents(onFieldFocused, 'utmTerm'), [onFieldFocused]);
  return (
    <>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="utm-settings-accordion">
          <AccordionTrigger
            className="hover:no-underline p-0 mt-2"
            data-testid="utm-settings-accordion"
          >
            <div className="flex flex-col gap-1">
              <P className="text-grey-6" fontWeight="font-medium">
                UTM Settings
              </P>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div>
              <div>
                <Form.Item className="!mb-0">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        onCheckedChange={(checked) => {
                          handleFormAsyncActions({
                            action: 'UpdateField',
                            payload: { key: 'isAdvanceUrlEnable', value: checked },
                          });
                          if (checked) {
                            setIsDrawerOpen(true);
                          }
                        }}
                        checked={values.isAdvanceUrlEnable}
                        disabled={(hasMembers && !editUtmFieldsEnabled) || isThirdPartyIntegration}
                      />
                      <span>Add Tracking UTMs to Links</span>
                    </div>
                    {values.isAdvanceUrlEnable && (
                      <Button
                        type="button"
                        variant="outline"
                        size="sm"
                        onClick={() => setIsDrawerOpen(true)}
                        className="ml-2 w-fit"
                        data-testid="utm-settings-button"
                      >
                        Edit UTM Parameters
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </div>
              <Drawer
                title="Add UTMs to Link"
                placement="right"
                width={580}
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
                data-testid="utm-settings-drawer"
              >
                {isDrawerOpen && (
                  <div className="flex flex-col gap-2">
                    {generateLinkUrl(values) && (
                      <Card className="bg-grey-1 rounded-2xl">
                        <CardContent className="flex flex-col gap-3 p-4">
                          <div className="flex flex-col gap-2">
                            <div className="flex items-start">
                              <div className="flex flex-col gap-[5px]">
                                <h2 className="font-medium-100 text-grey-4 text-[14px] leading-6">URL Preview</h2>
                              </div>
                            </div>
                            <div className="flex flex-col gap-6">
                              <div className="flex items-center">
                                <P className="text-grey-6">{generateLinkUrl(values)}</P>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                    {isProjectNameExistsInUtm && (
                      <div>
                        <Alert
                          message="Please ensure that your project name does not include the special characters '%' and '&'. If your project name currently contains these characters, kindly update it before generating links for your creator to prevent any issues."
                          description="If you've already generated links for project name with these characters and encountered issues, contact support for help"
                          type="warning"
                        />
                      </div>
                    )}
                    <RefreshUtmFields
                      name="advanceUrlSettingType"
                      label="Source"
                      keyName="utmSource"
                      events={utmSource}
                      icon={<Globe className="h-4 w-4" />}
                      onChange={checkAdvancedUrlEnabled}
                    />
                    <RefreshUtmFields
                      name="advanceUrlSettingType"
                      label="Medium"
                      keyName="utmMedium"
                      events={utmMedium}
                      icon={<Radio className="h-4 w-4" />}
                      onChange={checkAdvancedUrlEnabled}
                    />
                    <RefreshUtmFields
                      name="advanceUrlSettingType"
                      label="Campaign"
                      keyName="utmCampaign"
                      events={utmCampaign}
                      icon={<Flag className="h-4 w-4" />}
                      onChange={checkAdvancedUrlEnabled}
                    />
                    <RefreshUtmFields
                      name="advanceUrlSettingType"
                      label="Content"
                      keyName="utmContent"
                      events={utmContent}
                      icon={<BookText className="h-4 w-4" />}
                      onChange={checkAdvancedUrlEnabled}
                    />
                    <RefreshUtmFields
                      name="advanceUrlSettingType"
                      label="Term"
                      keyName="utmTerm"
                      events={utmTerm}
                      icon={<FileText className="h-4 w-4" />}
                      onChange={checkAdvancedUrlEnabled}
                    />
                    <Form.List name="customUTMParameters">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name }, index) => (
                            <RefreshUtmCustomFields
                              name="advanceUrlSettingType"
                              key={key}
                              customName={name}
                              disabled={hasMembers}
                              events={customUtmFields}
                              index={index}
                              remove={remove}
                            />
                          ))}
                          {fields.length < 3 ? (
                            <Form.Item name="button">
                              <Button
                                type="button"
                                variant="ghost"
                                className="h-auto p-0 text-grey-6 font-medium-100"
                                onClick={() => add({ key: '', value: '' })}
                                disabled={hasMembers}
                              >
                                <Plus className="h-3 w-3 mr-2" />
                                <span className="underline">Add Custom UTM</span>
                              </Button>
                            </Form.Item>
                          ) : (
                            <div className="text-sm text-grey-4 mt-2">You can add up to 3 custom parameters</div>
                          )}
                        </>
                      )}
                    </Form.List>
                  </div>
                )}
              </Drawer>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}
