import * as React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { Button } from '@frontend/shadcn/components/ui/button';
import { PlusIcon, XmarkIcon } from '@revfluence/fresh-icons/solid/esm';

interface ImageUploadProps {
  className?: string;
  disabled?: boolean;
  onFileSelected(file: File): void;
  onThumbnailSelected(imgStr: string): void;
  thumbnail?: string;
  thumbnailClassName?: string;
  display?: 'block' | 'inline' | 'expanded';
  uploadLabel?: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = React.memo(({

  className,
  disabled = false,
  onFileSelected,
  onThumbnailSelected,
  thumbnail,
  thumbnailClassName,
  display = 'block',
  uploadLabel = 'Upload',
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFilesSelected = React.useCallback(
    (files: FileList | null) => {
      if (!files?.length) return;

      const file = files[0];
      if (!file) return;

      onFileSelected(file);

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        onThumbnailSelected(e.target.result as string);
      };
      reader.readAsDataURL(file);
    },
    [onFileSelected, onThumbnailSelected],
  );

  const handleRemoveThumbnail = React.useCallback(() => {
    onFileSelected(null);
    onThumbnailSelected('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [onFileSelected, onThumbnailSelected]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDrop = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      handleFilesSelected(event.dataTransfer.files);
    },
    [handleFilesSelected],
  );

  const handleDragOver = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  return (
    <div
      className={cn(
        'flex items-start',
        {
          'inline-flex': display === 'inline',
          'w-full': display === 'expanded',
        },
        className,
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {thumbnail ? (
        <div className="relative">
          <Button
            variant="outline"
            size="icon"
            className="absolute -top-2 -right-2 h-6 w-6 rounded-full bg-background"
            onClick={handleRemoveThumbnail}
          >
            <XmarkIcon className="h-4 w-4" />
          </Button>
          <img
            src={thumbnail}
            className={cn(
              'h-[128px] min-w-[128px] max-w-[128px] rounded-lg object-cover',
              {
                'h-20 w-20': display === 'inline',
                'h-[172px] w-full': display === 'expanded',
              },
              thumbnailClassName,
            )}
          />
        </div>
      ) : (
        <div className="relative">
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept="image/jpeg,image/png"
            onChange={(e) => handleFilesSelected(e.target.files)}
            disabled={disabled}
          />
          <Button
            variant="outline"
            onClick={handleClick}
            disabled={disabled}
            className={cn(
              'h-[128px] w-[128px] flex flex-col items-center justify-center gap-2 border-dashed bg-muted/10',
              {
                'h-20 w-20': display === 'inline',
                'h-[172px] w-full': display === 'expanded',
              },
            )}
          >
            <PlusIcon className="h-6 w-6" />
            <span className="text-sm">{uploadLabel}</span>
          </Button>
        </div>
      )}
    </div>
  );
});

ImageUpload.displayName = 'ImageUpload';
