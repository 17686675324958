import * as React from 'react';
import { upperFirst } from 'lodash';
import {
  Select,
  Typography,
} from '@revfluence/fresh';
import {
  TContentUsageRights,
} from '@frontend/applications/TermsApp/components/BulkTerms/types/CollaborationDetails';
import { ContentUsageRightsType } from '@frontend/app/types/globalTypes';

const { Option } = Select;
const { useCallback } = React;

interface IProps {
  contentRightsTemplates: Array<TContentUsageRights>;
  contentRight: TContentUsageRights;
  onUpdateContentRight: (contentRight: TContentUsageRights) => void;
}

const SelectContentUsageRights: React.FC<IProps> = React.memo((props) => {
  const {
    contentRight,
    contentRightsTemplates,
    onUpdateContentRight,
  } = props;

  const handleChange = useCallback((type: string) => {
    const contentRightTemplateSelected = contentRightsTemplates.find(
      (contentRightTemplate) => contentRightTemplate.type === type,
    );
    onUpdateContentRight({
      type,
      text: contentRightTemplateSelected.text,
    });
  }, [onUpdateContentRight, contentRightsTemplates]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Select
      value={contentRight.type}
      style={{ width: 200 }}
      onChange={handleChange}
      onClick={handleClick}
    >
      {
        contentRightsTemplates?.map((contentUsageRight) => (
          <Option key={contentUsageRight.type} value={contentUsageRight.type}>
            <Typography.Text strong>
              {
              contentUsageRight.type !== ContentUsageRightsType.LIMITED
                ? upperFirst(contentUsageRight.type.toLowerCase())
                : 'Broad (Limited Term)'
              }
            </Typography.Text>
          </Option>
          ))
      }
    </Select>
  );
});

SelectContentUsageRights.displayName = 'SelectContentUsageRights';

export default SelectContentUsageRights;
