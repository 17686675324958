import React, { useState, useEffect, useRef, ReactNode, useCallback } from 'react';
import { X } from 'lucide-react';
import { Input } from '@frontend/shadcn/components/ui/input';
import { cn } from '@frontend/shadcn/lib/utils';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@frontend/shadcn/components/ui/select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';

interface ExpandableSearchProps {
  options?: string[];
  placeholder?: string;
  onSearch?: (term: string, selectedOption?: string) => void;
  className?: string;
  initialExpanded?: boolean;
  initialOption?: string;
  initialValue?: string;
  searchIcon?: ReactNode;
  clearIcon?: ReactNode;
  disabled?: boolean;
  disableSelect?: boolean;
  disableClear?: boolean;
  collapseOnOutsideClick?: boolean;
  width?: string;
  onExpandChange?: (expanded: boolean) => void;
  onClear?: () => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  variant?: 'default' | 'bordered' | 'minimal';
  tooltipText?: string;
}

export default function ExpandableSearch({
  options = [],
  placeholder = 'Search...',
  onSearch,
  className,
  initialExpanded = false,
  initialOption,
  initialValue = '',
  searchIcon = <MagnifyingGlassIcon />,
  clearIcon = <X size={16} />,
  disabled = false,
  disableSelect = false,
  disableClear = false,
  collapseOnOutsideClick = true,
  width = '300px',
  onExpandChange,
  onClear,
  inputProps,
  variant = 'default',
  tooltipText = 'Search',
}: ExpandableSearchProps) {
  const [expanded, setExpanded] = useState(initialExpanded);
  const [selectedOption, setSelectedOption] = useState(initialOption || (options.length > 0 ? options[0] : ''));
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Use useCallback for updateExpanded function
  const updateExpanded = useCallback((newExpandedState: boolean) => {
    setExpanded(newExpandedState);
    if (onExpandChange) {
      onExpandChange(newExpandedState);
    }
  }, [onExpandChange]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!collapseOnOutsideClick || disabled || isSelectOpen) {
        return;
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && searchTerm === '') {
        updateExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [searchTerm, isSelectOpen, collapseOnOutsideClick, disabled, updateExpanded]);

  // Focus input when expanded
  useEffect(() => {
    if (expanded && inputRef.current && !disabled) {
      inputRef.current.focus();
    }
  }, [expanded, disabled]);

  // Use useCallback for handleOptionChange
  const handleOptionChange = useCallback((value: string) => {
    setSelectedOption(value);
    setTimeout(() => {
      if (inputRef.current && !disabled) {
        inputRef.current.focus();
      }
    }, 0);
  }, [disabled]);

  // Add a new function to handle search submission
  const handleSearchSubmit = useCallback(() => {
    if (onSearch) {
      onSearch(searchTerm, selectedOption);
    }
  }, [onSearch, searchTerm, selectedOption]);

  // Update the input onChange to not trigger search immediately
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);

    // Optionally, you can add debounced search here if needed
    // For now, search will only trigger on Enter or explicit submission
  }, []);

  // Use useCallback for handleClear
  const handleClear = useCallback(() => {
    setSearchTerm('');
    if (onSearch) {
      onSearch('', selectedOption);
    }
    if (onClear) {
      onClear();
    }
    // Focus back on input after clearing
    if (inputRef.current && !disabled) {
      inputRef.current.focus();
    }
  }, [onSearch, selectedOption, onClear, disabled]);

  // Use useCallback for getContainerStyles
  const getContainerStyles = useCallback(() => {
    const baseStyles = 'flex items-center gap-2 transition-all';

    switch (variant) {
      case 'bordered':
        return cn(baseStyles, 'border-2 border-grey-6 rounded-lg bg-white focus-within:border-primary');
      case 'minimal':
        return cn(baseStyles, 'bg-transparent');
      default:
        // "default"
        return cn(
          baseStyles,
          'border border-grey-6 rounded-lg bg-white',
        );
    }
  }, [variant]);

  return (
    <div className={cn('relative', className)} ref={dropdownRef}>
      {/* Collapsed State - Search Icon Button */}
      {!expanded ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button onClick={() => !disabled && updateExpanded(true)} variant="ghost" size="icon" disabled={disabled}>
                {searchIcon}
              </Button>
            </TooltipTrigger>
            <TooltipContent>{tooltipText}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        // Expanded State - Full Width
        <div className={cn(getContainerStyles(), `w-[${width}]`)} style={{ width }}>
          {/* Select Dropdown */}
          {!disableSelect && options.length > 0 && (
            <div ref={selectRef}>
              <Select
                value={selectedOption}
                onValueChange={handleOptionChange}
                onOpenChange={(open) => setIsSelectOpen(open)}
                disabled={disabled}
              >
                <SelectTrigger className="px-3 py-1 text-gray-700 text-sm font-medium flex items-center border-0">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  {options.map((option) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          {/* Search Input */}
          <Input
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleInputChange}
            className="flex-grow bg-transparent text-gray-700 placeholder-gray-400 border-0 shadow-none focus:ring-0 focus-visible:ring-0 outline-none"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchSubmit();
              }
            }}
            disabled={disabled}
            {...inputProps}
          />

          {/* Clear Button */}
          {!disableClear && searchTerm && (
            <button
              onClick={handleClear}
              className="flex items-center justify-center h-full px-2 text-gray-400 hover:text-gray-600 transition"
              disabled={disabled}
              type="button"
            >
              {clearIcon}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
