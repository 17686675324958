import React, { useCallback, useMemo } from 'react';
import { ArrowLeftIcon } from '@revfluence/fresh-icons/regular/esm';
import { isEqual, isNull, replace, trim } from 'lodash';
import { Button, Typography } from '@revfluence/fresh';
import { UserAvatar } from '@frontend/app/components';
import { History } from 'history';
import CreatorStatusCell from '../CreatorStatus';
import StatusAction from './StatusAction';
import styles from './GroupCreatorDetail.scss';

const { Text, Title } = Typography;
const FALLBACK_GROUP_URL = 'https://storage.googleapis.com/aspirex-static-files/home/groups.png';

interface Community {
  title?: string | null;
  splashImageUrl?: string | null;
  memberFieldSchemas?: unknown;
}

interface MemberRecord {
  id: number;
  name?: string;
  profilePicture?: string;
  metaFields: {
    brand_approval?: string;
    [key: string]: unknown;
  };
}

interface CreatorTopbarProps {
  memberRecord: MemberRecord;
  groupId: string | null;
  history: History;
  community: Community | null;
  setBrandApprovalError: (error: boolean) => void;
  refetchMemberQuery: () => void;
  isContentApprover: boolean;
}

const CreatorTopbar: React.FC<CreatorTopbarProps> = ({
  memberRecord,
  groupId,
  history,
  community,
  setBrandApprovalError,
  refetchMemberQuery,
  isContentApprover,
}) => {
  const handleClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const isContentReview = useMemo(
    () =>
      isEqual(memberRecord?.metaFields.brand_approval, 'Approved') ||
      isEqual(memberRecord?.metaFields.brand_approval, 'Rejected'),
    [memberRecord],
  );

  const trimmedGroupName = useMemo(() => trim(replace(community?.title || '', '#AR', '')), [community]);
  return (
    <div className={styles.head}>
      <div className={styles.smallScreenHeader}>
        {!isNull(groupId) && (
          <div>
            <Button onClick={handleClick} icon={<ArrowLeftIcon />}>
              <span className={styles.backBtnText}>Back</span>
            </Button>
          </div>
        )}
        <div className={styles.smallScreenUserDetail}>
          <div className={styles.smallScreenuserAvatar}>
            <UserAvatar
              name={memberRecord?.name || ''}
              profilePicture={memberRecord?.profilePicture}
              className={styles.userAvatarBox}
            />
            <Title level={4}>{memberRecord?.name}</Title>
          </div>
          {/* Group panel */}
          {!isNull(groupId) && (
            <div className={styles.groupDetail}>
              <div className={styles.group}>
                <UserAvatar
                  className={styles.groupImage}
                  name={community?.title || ''}
                  profilePicture={community?.splashImageUrl || FALLBACK_GROUP_URL}
                />
                <Text className={styles.groupName}>{trimmedGroupName}</Text>
              </div>
              <div>
                <CreatorStatusCell memberRecord={memberRecord} />
              </div>
            </div>
          )}

          {!isContentReview && !isNull(groupId) && (
            <StatusAction
              member={memberRecord}
              fields={community?.memberFieldSchemas}
              refetchMemberQuery={refetchMemberQuery}
              setBrandApprovalError={setBrandApprovalError}
              isContentApprover={isContentApprover}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatorTopbar;
