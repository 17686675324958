import { useState, useCallback } from 'react';
import { TagAction } from '@frontend/app/types/globalTypes';

interface PaymentTagDialogState {
  isOpen: boolean;
  selectedPaymentIds: number[];
  action: TagAction | null;
  onSuccess?: () => void;
}

export function usePaymentTagDialog() {
  const [state, setState] = useState<PaymentTagDialogState>({
    isOpen: false,
    selectedPaymentIds: [],
    action: null,
    onSuccess: undefined,
  });

  const open = useCallback((paymentIds: number[], action: TagAction, onSuccess?: () => void) => {
    setState({
      isOpen: true,
      selectedPaymentIds: paymentIds,
      action,
      onSuccess,
    });
  }, []);

  const close = useCallback(() => {
    if (state.onSuccess) {
      state.onSuccess();
    }
    setState({
      isOpen: false,
      selectedPaymentIds: [],
      action: null,
      onSuccess: undefined,
    });
  }, [state]);

  return {
    isOpen: state.isOpen,
    selectedPaymentIds: state.selectedPaymentIds,
    action: state.action,
    open,
    close,
  };
}
