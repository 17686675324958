import * as React from 'react';
import { debounce, isEmpty, isNil } from 'lodash';
import { LoadSpinner } from '@components';

import { Notice } from '@affiliates/AspireUI';
import { OfferImage } from '@affiliates/components';
import { OFFER_TYPE, OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { Drawer } from '@frontend/applications/Shared/components/Drawer';
import { WorkflowEmptyOffers } from '@affiliates/components/WorkflowEmptyOffers';
import { ApplicationHeader } from '@frontend/app/containers/Application/ApplicationHeader';
import { GetOffersForSelectionQuery_offers } from '../../queries/types/GetOffersForSelectionQuery';
import { SelectOffersContainer } from '../../containers/SelectOffers/SelectOffersContainer';

import styles from './SetupOffers.scss';

const { useEffect, useState, useCallback } = React;

export interface ISetupOffers {
  offersQueryLoading: boolean;
  offersQueryError: boolean;
  offerType: OFFER_TYPE;
  visible: boolean;
  programId: number;
  offers: GetOffersForSelectionQuery_offers[] | undefined;
  offerSource: OFFER_SOURCE;
  onClose: () => void;
  onCreateNewOffer: () => void;
  onSelectedOfferChange: (key: React.Key) => void;
  onUseSelectedOffer: () => void;
  isSetup: boolean;
}

export const SetupOffers: React.FC<ISetupOffers> = ({
  offersQueryLoading,
  offersQueryError,
  offerType,
  visible,
  offers,
  offerSource,
  onClose,
  onCreateNewOffer,
  onSelectedOfferChange,
  onUseSelectedOffer,
  isSetup,
}) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(
      () => {
        setDimensions((dimensions) => {
          if (window.innerHeight === dimensions.height && window.innerWidth === dimensions.width) {
            return dimensions;
          }
          return { height: window.innerHeight, width: window.innerWidth };
        });
      },
      150,
      { trailing: true },
    ),
    [setDimensions],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const getDrawerContents = () => {
    if (offersQueryLoading) {
      return <LoadSpinner />;
    }

    if (offersQueryError || isNil(offers)) {
      return <Notice type="error" message="Oops! Something went wrong. Please refresh or try again later." />;
    }

    if (isEmpty(offers)) {
      return (
        <WorkflowEmptyOffers
          offerSource={offerType}
          onButtonClick={onCreateNewOffer}
          buttonText="Create New Offer"
        />
      );
    }
    return (
      <SelectOffersContainer
        isSetup={isSetup}
        offers={offers}
        offerSource={offerSource}
        onCreateNewOffer={onCreateNewOffer}
        onSelectedOfferChange={onSelectedOfferChange}
        onUseSelectedOffer={onUseSelectedOffer}
      />
    );
  };

  const headerName = offerSource == OFFER_SOURCE.SHOPIFY
    ? 'Sales Tracking Promo Code Setup'
    : 'Sales Tracking Link Setup';

  return (
    <Drawer
      width={dimensions.width < 1000 ? 800 : 1000}
      visible={visible}
      closable={false}
      maskClosable
      title={(
        <ApplicationHeader
          icon={<OfferImage source={offerSource} imageUrl={null} rounded iconName={null} />}
          name={headerName}
          goBack={onClose}
          closeModal={onClose}
        />
      )}
      onClose={onClose}
      destroyOnClose
    >
      <div className={styles.SetupOffers}>{getDrawerContents()}</div>
    </Drawer>
  );
};
