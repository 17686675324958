import * as React from 'react';
import { Avatar } from '@revfluence/fresh';
import { GiftIcon, TruckIcon } from '@revfluence/fresh-icons/regular/esm';

import { STA_WORKFLOW_ACTION } from '@affiliates/Main';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { OfferImage } from '@affiliates/components';
import { IApplicationHeaderProps } from '@frontend/app/containers/Application/ApplicationHeader';
import { RefreshMembers } from '@frontend/applications/AffiliatesApp/components/MembersWizard/components/Icons';

const { useMemo } = React;
/**
 * Example Adding more types:
 *  enum ADD_NEW_ENUM {
      field = 'VALUE',
    }
    type ADD_NEW_ENUM_LIST = `${ADD_NEW_ENUM}`;
    type Keys = STA_WORKFLOW_ACTIONS_LIST | ADD_NEW_ENUM_LIST;
    // or just:
    type LIST = 'VALUE' | 'VALUE 2';
    type Keys = STA_WORKFLOW_ACTIONS_LIST | LIST;
 */
type PFA_CREATOR_PRODUCT_SELECTION_ACTIONS_LIST = 'review_order_request' | 'send_order_request';
type OFFLINE_PFA_CREATOR_PRODUCT_SELECTION_ACTIONS_LIST = 'offline_review_order_request' | 'offline_send_order_request' | 'offline_fulfill_order';
type STA_WORKFLOW_ACTIONS_LIST = `${STA_WORKFLOW_ACTION}`;
type Keys = STA_WORKFLOW_ACTIONS_LIST | PFA_CREATOR_PRODUCT_SELECTION_ACTIONS_LIST | OFFLINE_PFA_CREATOR_PRODUCT_SELECTION_ACTIONS_LIST;
type Variations = {
  [key in Keys]: IApplicationHeaderProps | null;
};

const variations: Variations = {
  generate_promo: {
    name: 'Create Promo Codes',
    icon: <OfferImage rounded imageUrl={null} iconName={null} source={OFFER_SOURCE.SHOPIFY} />,
  },
  generate_link: {
    name: 'Create Sales Tracking Link',
    icon: <OfferImage rounded imageUrl={null} iconName={null} source={OFFER_SOURCE.TUNE} />,
  },
  fix_promo: {
    name: 'Update Promo Codes',
    icon: <RefreshMembers noMargin />,
  },
  send_promos: {
    name: 'Send Promo Codes',
    icon: <OfferImage rounded imageUrl={null} iconName={null} source={OFFER_SOURCE.SHOPIFY} />,
  },
  send_links: {
    name: 'Send Sales Links',
    icon: <OfferImage rounded imageUrl={null} iconName={null} source={OFFER_SOURCE.TUNE} />,
  },
  send_items: {
    name: 'Send Sales Tracking Items',
    icon: <OfferImage rounded imageUrl={null} iconName={null} source={OFFER_SOURCE.TUNE} />,
  },
  review_order_request: {
    name: 'Review Order Request:',
    icon: <Avatar icon={<GiftIcon />} style={{ backgroundColor: '#09375A' }} />,
  },
  send_order_request: {
    name: 'Send Order Request',
    icon: <Avatar icon={<GiftIcon />} style={{ backgroundColor: '#09375A' }} />,
  },
  offline_review_order_request: {
    name: 'Review Order Request',
    icon: <Avatar icon={<GiftIcon />} style={{ backgroundColor: '#09375A' }} />,
  },
  offline_send_order_request: {
    name: 'Send Order Request',
    icon: <Avatar icon={<GiftIcon />} style={{ backgroundColor: '#09375A' }} />,
  },
  offline_fulfill_order: {
    name: 'Mark as Shipped',
    icon: <Avatar icon={<TruckIcon />} style={{ backgroundColor: '#09375A' }} />,
  },
};

export const useApplicationHeaderVariation = (key: Keys, suffix: string = '') =>
  useMemo(() => {
    const variation = variations[key];
    if (!variation) {
      return null;
    }
    return {
      ...variation,
      name: `${variation.name} ${suffix}`,
    };
  }, [key, suffix]);
