import * as React from 'react';
import { map, isEmpty, isNull } from 'lodash';
import { format } from 'date-fns';

import { SelectOffers } from '@affiliates/components/SelectOffers/SelectOffers';

import { GetOffersForSelectionQuery_offers } from '../../queries/types/GetOffersForSelectionQuery';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { ISelectOfferTableRow } from '../../components/SelectOffersTable/SelectOffersTable';

const { useMemo } = React;

export interface ISelectOffers {
  offers: GetOffersForSelectionQuery_offers[];
  offerSource: OFFER_SOURCE;
  onSelectedOfferChange: (key: React.Key) => void;
  onUseSelectedOffer: () => void;
  onCreateNewOffer: () => void;
  isSetup: boolean;
}
export const SelectOffersContainer: React.FC<ISelectOffers> = ({
  offers,
  offerSource,
  onSelectedOfferChange,
  onUseSelectedOffer,
  onCreateNewOffer,
  isSetup,
}) => {
  const tableData: ISelectOfferTableRow[] = useMemo(
    () =>
      map(offers, (offer) => {
        const offerDetails = isEmpty(offer.promos) ? offer.links[0] : offer.promos[0];
        const row = {
          key: offer.id,
          nameData: { source: offerSource, name: offer.name, imageUrl: offer.imageUrl, iconName: offer.icon },
          creationDate: format(new Date(offer.createdDate), 'MM/dd/yyyy'),
          status: isNull(offer.archivedDate) ? offerDetails.status : 'Archive',
          expired: offer.expired,
          programId: offer.programId,
          isOfferArchived: !isNull(offer.archivedDate),
        };
        return row;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offers],
  );

  return (
    <SelectOffers
      offerSource={offerSource}
      offerData={tableData}
      onSelectedOfferChange={onSelectedOfferChange}
      onUseSelectedOffer={onUseSelectedOffer}
      onCreateNewOffer={onCreateNewOffer}
      isSetup={isSetup}
    />
  );
};
