import * as React from 'react';
import cx from 'classnames';
import {
  map,
  findIndex,
  slice,
  toNumber,
} from 'lodash';
import { useEffect } from 'react';

import {
  ICampaign,
} from '@components';
import {
  ImageIcon,
  VideoIcon,
  HelpIcon,
  TagIcon,
  NetworkSvgIcon,
  LockIcon,
  FlagIcon,
  SearchIcon,
} from '@components';
import {
  IconSelect,
  Input,
  Select,
  TokenInput,
  Tooltip,
} from '@components';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';

import { IMediaType, INetworkOption, IPermissionLevel } from './contentFiltersModel';
import { IContentFilters, IAdditionalFilterOptions } from './redux/models';
import { permissionOptions } from './constants';

const { useMemo, useRef } = React;

import styles from './ContentFilters.scss';
import { useLocation } from 'react-router-dom';
import { Checkbox } from '@revfluence/fresh';
import { CheckboxProps } from 'antd';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

const FIELD_MIN_WIDTH = 275;

interface IProps {
  filters: IContentFilters;
  campaigns: ICampaign[];
  networks: INetworkOption[];
  likedContentIds: string[];
  inputBuffer?: number;
  additionalFilterOptions?: IAdditionalFilterOptions[];

  onChange(filters: IContentFilters);
  className?: string;
}

const reusePermissions: IPermissionLevel[] = [
  { value: null, label: 'All Content' },
  ...map(permissionOptions, (label, value) => ({ label, value })),
];

const mediaTypes: IMediaType[] = [
  { value: null, icon: 'All' },
  { value: 'image', icon: <ImageIcon size={18} /> },
  { value: 'video', icon: <VideoIcon size={18} /> },
];

const HelpTooltip: React.FunctionComponent<{ tooltip: string }> = React.memo((props) => {
  const helpRef = useRef(null);
  return (
    <>
      <span ref={helpRef}>
        <HelpIcon size={22} className={styles.helpIcon} />
      </span>
      <Tooltip mountRef={helpRef} tooltipColor="black" maxWidth={320}>
        {props.tooltip}
      </Tooltip>
    </>
  );
});

const FilterLabel: React.FunctionComponent<{ label: string; tooltip?: string; icon?: JSX.Element }> = React.memo(
  (props) => (
    <div className={styles.filterLabel}>
      <span>{props.label}</span>
      {props.icon || null}
      {props.tooltip ? <HelpTooltip tooltip={props.tooltip} /> : null}
    </div>
  ),
);

const NetworkOption: React.FunctionComponent<INetworkOption> = React.memo((props) => (
  <div className={styles.networkOption}>
    <span>{props.icon}</span>
    <span className={styles.networkName}>{props.name}</span>
    {props.tooltip ? (
      <span>
        <HelpTooltip tooltip={props.tooltip} />
      </span>
    ) : null}
  </div>
));

const ContentFilters: React.FunctionComponent<IProps> = React.memo((props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('programId');
  const selectedProjectIdIndex = findIndex(
    props.additionalFilterOptions?.[0]?.options,
    (program) => program.value === toNumber(projectId)
  );

  const handleChangeQuery = (query: string) => {
    props.onChange({ ...props.filters, query });
  };

  const handleChangeCampaign = (campaignId: any) => {
    props.onChange({ ...props.filters, selectedCampaignId: campaignId });
  };

  const handleDynamicFilterChange = (key: string, identifier: any) => {
    props.onChange({ ...props.filters, [key]: identifier });
  };

  const handleChangeNetwork = (networkId: any) => {
    props.onChange({ ...props.filters, selectedNetworkId: networkId });
  };

  const handleChangeTags = (tags: string[]) => {
    props.onChange({ ...props.filters, selectedTags: tags });
  };

  const handleChangeReusePermissions = (permission: string) => {
    props.onChange({ ...props.filters, selectedPermissionLevel: permission });
  };

  const handleChangeMediaType = (mediaType: string) => {
    props.onChange({ ...props.filters, selectedMediaType: mediaType });
  };

  const handleChangeLegacySearch: CheckboxProps['onChange'] = (e) => {
    props.onChange({ ...props.filters, useLegacySearch: e.target.checked });
  };

  const campaignOptions = useMemo(() => {
    const result = map(props.campaigns, (campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }));
    result.unshift({ value: null, label: 'All' });
    return result;
  }, [props.campaigns]);

  const networksWithAll = useMemo(() => {
    const result = slice(props.networks);
    result.unshift({
      id: null,
      name: 'All',
      icon: <NetworkSvgIcon size={18} />,
    });
    return result;
  }, [props.networks]);

  const networkOptions = useMemo(() => map(networksWithAll, (network) => ({ value: network.id, label: network.name })), [networksWithAll]);

  const selectedCampaignIndex = useMemo(() => Math.max(findIndex(campaignOptions, { value: props.filters.selectedCampaignId }), 0), [props.filters.selectedCampaignId, campaignOptions]);

  const selectedNetworkIndex = useMemo(() => Math.max(findIndex(networkOptions, { value: props.filters.selectedNetworkId }), 0), [props.filters.selectedNetworkId, networkOptions]);

  const allowLegacySearch = useClientFeatureEnabled(ClientFeature.CONTENT_LIBRARY_LEGACY_SEARCH);
  const selectedReusePermissionIndex = useMemo(() => Math.max(
      findIndex(reusePermissions, { value: props.filters.selectedPermissionLevel }),
      0,
    ), [props.filters.selectedPermissionLevel]);

  const renderNetworkOption = (_, index) => <NetworkOption {...networksWithAll[index]} />;

  React.useEffect(() => {
    if (projectId) {
      handleDynamicFilterChange('programId', projectId);
    }
  }, [projectId]);

  const setDefaultSelectedProgram = (filterKey:string) => {
    if (filterKey === 'programId' && selectedProjectIdIndex !== -1) {
      return selectedProjectIdIndex;
    }
    return undefined;
  };

  const renderFilterWithSearch = (option: IAdditionalFilterOptions, index: number) => {
    const {
      searchText,
      inputValue,
      handleSearchChange,
      error,
      checkNoResults,
      isLoading: isSearchLoading,
    } = useSearchWithDebounce({
      searchAnalytics: {
        enabled: true,
        searchContext: option.filterKey === 'programId' ? 'projects' : 'groups',
        metadata: {
          source: 'content_filters',
        },
      },
    });

    const filteredOptions = React.useMemo(() => 
      option.options.filter((opt) => 
        !searchText || opt.label.toLowerCase().includes(searchText.toLowerCase())),
      [option.options, searchText]
    );

    useEffect(() => {
      checkNoResults(filteredOptions.length > 0);
    }, [filteredOptions.length, checkNoResults]);

    const renderOptionWithSearch = (opt, idx) => {
      if (idx === 0) {
        return (
          <div 
            className={styles.searchContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <SearchInput
              value={inputValue}
              onChange={handleSearchChange}
              placeholder={`Search ${option.title.toLowerCase()}...`}
              aria-invalid={!!error}
              aria-errormessage={error ? `search-error-${index}` : undefined}
              isLoading={isSearchLoading}
            />
            {error && (
              <div 
                className={styles.searchError}
                id={`search-error-${index}`}
                role="alert"
              >
                {error}
              </div>
            )}
          </div>
        );
      }
      return opt.label;
    };

    const handleOptionChange = (value: any) => {
      if (value !== 'search') {
        handleDynamicFilterChange(option.filterKey, value);
      }
    };

    const optionsWithSearch = [
      { 
        value: 'search', 
        label: '', 
        disabled: !!error
      },
      ...filteredOptions
    ];

    return (
      <div key={index}>
        <FilterLabel label={option.title} />
        <Select
          onChange={handleOptionChange}
          options={optionsWithSearch}
          labelIcon={<FlagIcon size={18} />}
          selectedIndex={undefined}
          defaultSelectedIndex={setDefaultSelectedProgram(option.filterKey)}
          round
          renderOptionElement={renderOptionWithSearch}
          onMenuClose={() => {}}
          popoverProps={{
            className: styles.dropdown,
            minWidth: FIELD_MIN_WIDTH,
          }}
        />
      </div>
    );
  };

  return (
    <div className={cx(styles.ContentFilters, props.className)}>
      <FilterLabel label="Search" />
      <Input
        onChange={handleChangeQuery}
        placeholder="Enter names and more..."
        buffer={props.inputBuffer}
        value={props.filters.query}
        icon={<SearchIcon size={18} />}
      />
      {map(props.additionalFilterOptions, renderFilterWithSearch)}
      {!props.additionalFilterOptions
      && (
      <>
        <FilterLabel label="Campaign(s)" />
        <Select
          onChange={handleChangeCampaign}
          options={campaignOptions}
          selectedIndex={selectedCampaignIndex}
          labelIcon={<FlagIcon size={18} />}
          popoverProps={{
            className: styles.dropdown,
            minWidth: FIELD_MIN_WIDTH,
          }}
          round
        />
        <FilterLabel label="Network/Source" />
        <Select
          onChange={handleChangeNetwork}
          options={networkOptions}
          renderOptionElement={renderNetworkOption}
          labelIcon={networksWithAll[selectedNetworkIndex].icon}
          selectedIndex={selectedNetworkIndex}
          popoverProps={{
            minWidth: FIELD_MIN_WIDTH,
          }}
          round
        />
      </>
)}
      <FilterLabel label="Filter by tag(s)" />
      <TokenInput
        onChange={handleChangeTags}
        tokens={props.filters.selectedTags}
        placeholder="Enter a tag..."
        icon={<TagIcon size={18} />}
        hidePopover
        className={styles.tagInput}
      />
      <FilterLabel label="Re-use permissions" />
      <Select
        onChange={handleChangeReusePermissions}
        options={reusePermissions}
        selectedIndex={selectedReusePermissionIndex}
        labelIcon={<LockIcon size={18} />}
        popoverProps={{
          minWidth: FIELD_MIN_WIDTH,
        }}
        round
      />
      <FilterLabel label="Media type" />
      <IconSelect
        onChange={handleChangeMediaType}
        options={mediaTypes}
        selectedOption={props.filters.selectedMediaType}
      />
      {allowLegacySearch && <>
        <FilterLabel label="Legacy Search" />
        <Checkbox
          onChange={handleChangeLegacySearch}
          checked={props.filters.useLegacySearch}
        />
      </>}
    </div>
  );
});

ContentFilters.defaultProps = {
  inputBuffer: 500,
};

export default ContentFilters;
