import gql from 'graphql-tag';
import { META_FIELD_FRAGMENT } from './fragments/metaField';

export const CREATE_IMPORTER_TASK_MUTATION = gql`
  mutation CreateImporterTask($params: CreateImporterTaskParams!) {
    createImporterTask(params: $params) {
      id
      status
    }
  }
`;

export const DELETE_IMPORTER_TASK_MUTATION = gql`
  mutation DeleteImporterTask($collectionId: Float!) {
    deleteWaitingImportTask(collectionId: $collectionId) {
      id
      collectionId
      status
    }
  }
`;

export const KILL_INPROGRESS_IMPORT_JOB_MUTATION = gql`
  mutation KillInProgressJobByCollectionId($collectionId: Int!) {
    killInProgressJobByCollectionId(collectionId: $collectionId)
  }
`;

export const GET_IMPORTED_COLLECTIONS_BY_CLIENT_ID_QUERY = gql`
  query GetImportedCollectionsByClientId {
    getImportedCollections {
      id
      userInfo {
        name
      }
      collectionId
      status
      operation
      collection {
        id
        externalId
        name
        productCount
        source
        images
        lastSyncDate
        metadata {
          handle
          importedProductCount
          importedVariantCount
        }
      }
    }
  }
`;

export const GET_IMPORTED_COLLECTIONS_STATUSES_QUERY = gql`
  query GetImportedCollectionsStatusesQuery {
    getImportedCollections {
      id
      collectionId
      status
      collection {
        externalId
        lastSyncDate
        metadata {
          importedProductCount
          importedVariantCount
        }
      }
    }
  }
`;

export const RSYNC_IMPORTED_COLLECTION_BY_ID_MUTATION = gql`
  mutation ResyncImportedCollectionById($collectionId: Int!, $syncProcessType: SyncProcessType) {
    resyncImportedCollectionById(id: $collectionId, syncProcessType: $syncProcessType) {
      id
      status
    }
  }
`;

export const CREATE_CUSTOM_COLLECTION_IMPORT_MUTATION = gql`
  mutation CustomCollectionImport($customCollectionName: String!, $excludeProductIds: [String!], $shopifyProductsFilter: ShopifyProductsFilterInput!) {
    customCollectionImport(customCollectionName: $customCollectionName, excludeProductIds: $excludeProductIds, shopifyProductsFilter: $shopifyProductsFilter) {
      id
      status
    }
  }
`;

export const GET_META_FIELDS_FROM_SHOPIFY_QUERY = gql`
  query GetMetaFieldsFromShopify($ownerType: ShopifyOwnerTypeEnum!) {
    getMetaFieldsFromShopify(ownerType: $ownerType) {
      productMetaFields {
        ...MetaFieldFragment
      }
      collectionMetaFields {
        ...MetaFieldFragment
      }
      variantMetaFields {
        ...MetaFieldFragment
      }
    }
  }
  ${META_FIELD_FRAGMENT}
`;

export const CREATE_OR_UPDATE_META_FIELDS_MUTATION = gql`
  mutation CreateOrUpdateMetaFields($metaFieldsMapping: MetaFieldsMappingInput!) {
    createOrUpdateMetaFieldsMapping(metaFieldsMapping: $metaFieldsMapping) {
      metaFieldsMapping {
        productMetaFields {
          ...MetaFieldFragment
        }
        collectionMetaFields {
          ...MetaFieldFragment
        }
        variantMetaFields {
          ...MetaFieldFragment
        }
      }
    }
  }
  ${META_FIELD_FRAGMENT}
`;

export const DELETE_IMPORTED_COLLECTION_BY_ID_MUTATION = gql`
  mutation DeleteImportedCollectionById($collectionId: Int!) {
    deleteImportedCollectionById(collectionId: $collectionId)
  }
`;
