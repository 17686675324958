import * as React from 'react';
import { Form, Select } from '@revfluence/fresh';
import { IShopifyFormElementProps, ProductCollections } from '@affiliates/components';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { OfferInputHeader } from '../../../CommonSection/FormElements/RefreshForm/OfferElementHeader';
import styles from '../../../../OfferForm.scss';

const { Option } = Select;

interface IProps extends IShopifyFormElementProps<'productCollections'> {
  collections: ProductCollections[] | null;
  disabled?: boolean;
  isCollection?: boolean;
}

export const RefreshOfferCodeCollectionOptions: React.FC<Readonly<IProps>> = React.memo(
  ({ collections, name, disabled = false, isCollection }) => {
    const [isCollectionEnabled, setIsCollectionEnabled] = React.useState(isCollection);
    const handlePurchaseRequirement = (value: boolean) => {
      setIsCollectionEnabled(value);
    };

    return (
      <div className="mt-2">
        <div className="flex gap-2 w-full mb-2">
          <Switch checked={isCollectionEnabled} onCheckedChange={handlePurchaseRequirement} disabled={isCollection} />
          <span className="text-sm font-medium">Limit discount to specific collections</span>
        </div>
        {isCollectionEnabled && (
          <div className="px-[26px]">
            <OfferInputHeader text="Select Collection(s)" />
            <Form.Item
              className="!m-0"
              name={name}
              rules={[{ required: isCollectionEnabled, message: 'Please select collection(s)' }]}
            >
              <Select
                placeholder="Select Collection's"
                size="middle"
                mode="multiple"
                notFoundContent={collections === null ? 'Collection not found' : null}
                allowClear
                style={{ width: '100%' }}
                disabled={disabled}
                className={styles.refreshDomainSelect}
                data-testid="refreshOfferCodeCollectionOptions"
              >
                {collections?.map((collection) => (
                  <Option key={collection.id} value={collection.id} className={`${styles.refreshDomainOption} hover:!bg-grey-1`}>
                    {collection.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>)}
      </div>
    );
  }
);

RefreshOfferCodeCollectionOptions.displayName = 'RefreshOfferCodeCollectionOptions';
