import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { useState } from 'react';
import { UTMFieldsProps } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { Wand2 } from 'lucide-react';
import { AutoComplete } from 'antd';
import { OfferFormTestIds as testIds } from '../../../../../../OfferFormTestIds';

interface IProps extends UTMFieldsProps<'advanceUrlSettingType'> {
  events: {
    onBlur: () => void;
    onFocus: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onChange?: (value: string) => void;
  };
  icon: React.ReactNode;
  onChange: () => void;
}

const placeholder = 'Enter a value or select a dynamic parameter';
const dynamicUtmOption = {
  creatorHandle: '{creator_name}',
  instaHandle: '{creator_ig_username}',
  projectName: '{project_name}',
  linkOfferName: '{link_offer_name}',
  tikTokHandle: '{creator_tiktok_username}',
  memberId: '{member_id}',
};
// eslint-disable-next-line react/display-name
export const RefreshUtmFields: React.FC<Readonly<IProps>> = React.memo(
  ({ label, keyName, events, required, icon, onChange }) => {
    const [customText, setCustomText] = useState('');

    const handleInputChange = (newValue: string) => {
      setCustomText(newValue);
      onChange();
    };

    const getRequiredRule = (inputName) => ({ required: true, message: `Please Enter ${inputName}` });

    const getHashValidationRule = () => ({
      validator: (_, value) => {
        const cleanValue = value?.replace(/\{\{.*?\}\}\}/g, '');
        if (cleanValue && (cleanValue.includes('#') || cleanValue.includes(' ') || cleanValue.includes('='))) {
          return Promise.reject(new Error("Value cannot contain the '#', '=' or space character"));
        }
        return Promise.resolve();
      },
    });

    const dataSource = ['Dynamic Parameter', ...Object.values(dynamicUtmOption)];

    const disabledOptionStyles = {
      color: 'var(--neutral-color-palette-gray-9, #1F1F21)',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '22px',
    };

    return (
      <div className="flex items-center rounded-lg border border-grey-2 overflow-hidden bg-grey-1 h-9">
        <div className="flex items-center gap-2 px-3 w-[124px] border-r border-grey-2">
          {icon}
          <span className="text-grey-6 font-medium-75-labels">{label}</span>
        </div>
        <div className="flex flex-1 items-center bg-grey-0 relative">
          <Form.Item
            name={keyName}
            rules={required ? [getRequiredRule(keyName), getHashValidationRule()] : [getHashValidationRule()]}
            className="!mb-0 w-full"
          >
            <AutoComplete
              data-testid={testIds.utmSource}
              value={customText}
              onChange={handleInputChange}
              placeholder={placeholder}
              size="middle"
              {...events}
              className="!border-0 p-0 h-auto text-grey-3 font-regular-100-paragraph w-full pr-10"
              key={keyName}
            >
              {dataSource.map((option, index) => (
                <AutoComplete.Option
                  key={index}
                  value={option}
                  disabled={option === 'Dynamic Parameter'}
                  style={option === 'Dynamic Parameter' ? disabledOptionStyles : null}
                >
                  {option}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <div className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
            <Wand2 className="h-4 w-4 text-grey-6" />
          </div>
        </div>
      </div>
    );
  },
);
