import { RowType } from "..";

type Platform = "Instagram" | "YouTube" | "Pinterest" | "Tiktok";

type SubColumnDefinition = {
  header: string;
  id: string;
  enableSorting: boolean;
  rowType: RowType;
  hidden: boolean;
  subColumns?: SubColumnDefinition[];
};

export const generateSubColumnConfig = (
  baseAccessorKey: string,
  rowType: RowType,
  excludePlatform: Platform[] = [],
  hidden: boolean = false
) => {
  const excludeSet = new Set(excludePlatform);

  const subColumns: SubColumnDefinition[] = [
    {
      header: "Instagram",
      id: `instagram.${baseAccessorKey}`,
      enableSorting: true,
      rowType,
      hidden,
      subColumns: [
        { header: "Instagram Post", id: `instagramPost.${baseAccessorKey}`, enableSorting: true, rowType, hidden },
        { header: "Instagram Reel", id: `instagramReel.${baseAccessorKey}`, enableSorting: true, rowType, hidden },
        { header: "Instagram Story", id: `instagramStory.${baseAccessorKey}`, enableSorting: true, rowType, hidden },
      ],
    },
    {
      header: "YouTube",
      id: `youtube.${baseAccessorKey}`,
      enableSorting: true,
      rowType,
      hidden,
      subColumns: [
        { header: "YouTube Video", id: `youtubeVideo.${baseAccessorKey}`, enableSorting: true, rowType, hidden },
        { header: "YouTube Short", id: `youtubeShort.${baseAccessorKey}`, enableSorting: true, rowType, hidden },
      ],
    },
    {
      header: "Pinterest",
      id: `pinterest.${baseAccessorKey}`,
      enableSorting: true,
      rowType,
      hidden,
    },
    {
      header: "Tiktok",
      id: `tiktok.${baseAccessorKey}`,
      enableSorting: true,
      rowType,
      hidden,
    },
  ];

  return subColumns.filter(column => !excludeSet.has(column.header as Platform));
};
