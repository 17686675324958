import * as React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';

import { Image } from '@revfluence/fresh';
import { LinkSimpleIcon, CertificateIcon } from '@revfluence/fresh-icons/regular/esm';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';

import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './OfferImage.scss';
import { RefreshOfferDefaultImage } from './RefreshOfferDefaultImage';

interface IProps {
  className?: string;
  imageUrl: string | null;
  source: OFFER_SOURCE;
  rounded?: boolean;
  iconName?: string | null;
}

export const OfferImage: React.FC<Readonly<IProps>> = (props) => {
  const {
    className,
    imageUrl,
    source,
    iconName,
  } = props;
  const { offerSimplification: isOfferSimplification } = useClientFeatures();
  if (isOfferSimplification) {
    return <RefreshOfferDefaultImage className={className} imageUrl={imageUrl} source={source} icon={iconName} iconClassName="h-4 w-4" />;
  }

  if (!isNull(imageUrl)) {
    return (
      <Image
        className={cx(className)}
        src={imageUrl}
      />
    );
  }

  let icon;
  let backgroundClass;
  switch (source) {
    case OFFER_SOURCE.SHOPIFY:
      backgroundClass = styles.promoCode;
      icon = <CertificateIcon className={styles.icon} />;
      break;
    case OFFER_SOURCE.TUNE:
      backgroundClass = styles.affiliateLink;
      icon = <LinkSimpleIcon className={styles.icon} />;
      break;
  }
  return (
    <div className={cx(styles.OfferImageFallback, backgroundClass, className, {
      [styles.rounded]: props.rounded,
    })}
    >
      {icon}
    </div>
  );
};
