import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Search } from 'lucide-react';
import { IconOption, iconOptions } from './iconMapping';

interface IconSelectorProps {
  onSelectIcon: (icon: IconOption) => void;
}

export const IconSelector: React.FC<IconSelectorProps> = ({ onSelectIcon }) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const filteredIcons = React.useMemo(() => {
    if (!searchQuery) return iconOptions;

    const query = searchQuery.toLowerCase();
    return iconOptions.filter((icon) =>
      icon.name.toLowerCase().includes(query) ||
      icon.keywords.some((keyword) => keyword.toLowerCase().includes(query))
    );
  }, [searchQuery]);

  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-grey-4 h-4 w-4" />
        <Input
          type="text"
          placeholder="Search Icons"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-9 bg-primary-foreground border-grey-3"
        />
      </div>
      <div className="grid grid-cols-10 gap-2 max-h-[400px] overflow-y-auto p-2">
        {filteredIcons.map((iconOption) => (
          <button
            key={iconOption.name}
            onClick={() => onSelectIcon(iconOption)}
            className="p-2 hover:bg-grey-2 rounded-md transition-colors flex items-center justify-center"
            title={iconOption.name}
          >
            <FontAwesomeIcon
              icon={iconOption.icon}
              className="h-4 w-4 text-grey-6"
            />
          </button>
        ))}
      </div>
    </div>
  );
};
