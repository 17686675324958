import * as React from 'react';
import cx from 'classnames';
import { map, pickBy } from 'lodash';

import { Checkbox } from '@components';
import { Popover } from '@components';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';

import styles from './SelectDropdown.scss';

const { useRef, useState, useEffect, useMemo } = React;

interface IProps {
  options: ISelectableObject[];
  setValue(identifier: string, values: string[]): void;
  title: string;
  identifier: string;
  singleSelect?: boolean;
  className?: string;
  isDisabled?: boolean;
  showSearch?: boolean;
  searchPlaceholder?: string;
}

export interface ISelectableObject {
  id: string | number;
  displayName: string;
  selected?: boolean;
}

export const SelectDropdown: React.FunctionComponent<IProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  const {
    options, title, identifier, setValue, singleSelect, isDisabled, showSearch, searchPlaceholder
  } = props;

  const {
    searchText,
    inputValue,
    handleSearchChange,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: title.toLowerCase(),
      metadata: {
        source: `${identifier}_dropdown`,
      },
    },
  });

  const toggleValue = (value) => {
    if (singleSelect) {
      setSelected({
        [value]: true,
      });
    } else {
      setSelected(Object.assign(selected, {
        [value]: !selected[value],
      }));
    }
    setSelectedCount(Object.keys(pickBy(selected)).length);
    setValue(identifier, Object.keys(pickBy(selected)));
  };

  useEffect(() => {
    const initialState = {};
    for (const option of options) {
      if (option.selected) {
        initialState[option.id] = true;
      }
    }
    setSelected(initialState);
    setSelectedCount(Object.keys(pickBy(initialState)).length);
  }, [options]);

  const filteredOptions = useMemo(() => {
    if (!showSearch || !searchText) {
      return options;
    }

    return options.filter((option) =>
      option.displayName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [options, searchText, showSearch]);

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, props.className, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [(styles as any).disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>{selectedCount}</span>
        <span className={styles.filterText}>{title}</span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        className={(styles as any).DateFilterPopover}
      >
        {showSearch && (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchInput
              value={inputValue}
              onChange={handleSearchChange}
              placeholder={searchPlaceholder}
              isLoading={isSearchLoading}
            />
          </div>
        )}
        <ul className={styles.MenuList}>
          {map(filteredOptions, (option) => (
            <li
              key={option.id}
              className={styles.MenuItem}
              onClick={() => toggleValue(option.id)}
            >
              <Checkbox
                onClick={() => toggleValue(option.id)}
                checked={selected[option.id]}
              />
              <span>{option.displayName}</span>
            </li>
            ))}
        </ul>
      </Popover>
    </>
  );
};

SelectDropdown.displayName = 'SelectDropdown';
