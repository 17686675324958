import { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from '@revfluence/fresh';
import { CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { isEmpty, trim, reduce } from 'lodash';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { useEventContext } from '@frontend/app/context';
import { EventName } from '@common';
import {
 useGetInstalledApplicationIds, useAddSpecificationMutation, useDeleteSpecificationMutation, useDeleteWorkletMutation,
 useUpdateSpecificationMutation,
 useGetTaskTrackersQuery,
 useUploadContent,
} from '@frontend/app/hooks';
import { useSaveProgramMutation } from '@frontend/app/containers/Communities/AddOrEditCommunity/hooks/useSaveProgramMutation';
import { SaveProgramMutationVariables } from '@frontend/app/queries/types/SaveProgramMutation';
import { useAuth } from '@frontend/context/authContext';
import { useTaskProgressMonitor } from '@frontend/app/hooks/memberList/useTaskProgressMonitor';
import { useCampaign } from '../CampaignContext';
import { useAddWorklets } from '../../hooks';
import { validateProjectSpec } from '../../utils';
import { TWorklet, TProject } from '../../types';

export interface UseCampaignFormReturn {
  isSubmitting: boolean;
  handleCreateOrUpdateCampaign: () => Promise<void>;
}

export const useCampaignForm = (
  mode: 'add' | 'edit',
  project?: TProject,
  onSave?: () => void,
  refetchProjects?: () => void,
): UseCampaignFormReturn => {
  const history = useHistory();
  const [saveProgram] = useSaveProgramMutation();
  const { showError, showErrorMessage, showSuccessMessage } = useMessagingContext();
  const addEvent = useEventContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const campaignContext = useCampaign();
  const { addWorklets } = useAddWorklets();
  const installedApps = useGetInstalledApplicationIds();
  const [addSpecification] = useAddSpecificationMutation();
  const [updateSpecification] = useUpdateSpecificationMutation();
  const [deleteSpecification] = useDeleteSpecificationMutation();
  const [deleteWorklet] = useDeleteWorkletMutation();

  const { clientInfo } = useAuth();

  const { refetch: refetchTaskTrackers } = useGetTaskTrackersQuery({
    variables: {
      params: {
        clientId: clientInfo?.id,
        url: '/v1/bulk_add_members_to_existing_flex_program',
      },
      limit: 1,
    },
    fetchPolicy: 'network-only',
    skip: true,
  });

  const { addTaskId } = useTaskProgressMonitor();

  const { user } = useAuth();
  const selectedOwner = campaignContext.campaignOwner || user.sub;

  // Rollback refs
  const recentlyAddedWorkletSpecKeysRef = useRef<string[]>([]);
  const recentlyAddedSpecificationSpecKeyRef = useRef<string>('');

  const clearRollbackSpecKeys = useCallback(() => {
    recentlyAddedWorkletSpecKeysRef.current = [];
    recentlyAddedSpecificationSpecKeyRef.current = '';
  }, []);

  const rollbackSpec = useCallback(async (): Promise<void> => {
    logger.debug('Rolling back created worklets and spec');
    if (recentlyAddedWorkletSpecKeysRef.current.length) {
      await Promise.all(
        recentlyAddedWorkletSpecKeysRef.current.map(async (specKey) => {
          if (!specKey) return;
          try {
            logger.debug(`Deleting worklet: ${specKey}`);
            await deleteWorklet({ variables: { specKey } });
            logger.debug(`Deleting specification: ${specKey}`);
            await deleteSpecification({ variables: { specKey } });
          } catch (error) {
            logger.error(`Unable to delete worklet specification for spec: ${specKey}`, error);
          }
        }),
      );
    }

    if (recentlyAddedSpecificationSpecKeyRef.current) {
      logger.debug(`Deleting specification: ${recentlyAddedSpecificationSpecKeyRef.current}`);
      try {
        await deleteSpecification({
          variables: {
            specKey: recentlyAddedSpecificationSpecKeyRef.current,
          },
        });
      } catch (error) {
        logger.error('Unable to delete parent specification', error);
      }
    }
    clearRollbackSpecKeys();
  }, [clearRollbackSpecKeys, deleteWorklet, deleteSpecification]);

  const createSpecification = useCallback(async (workletSpecKeys: string[]): Promise<string> => {
    logger.debug('Adding specification...', { workletSpecKeys });
    if (isEmpty(workletSpecKeys)) {
      return '';
    }

    const { data: { specKey = '' } = {} } = await addSpecification({
      variables: {
        workletSpecKeys,
      },
    });
    recentlyAddedSpecificationSpecKeyRef.current = specKey;
    logger.debug('Added specification', { specKey });
    return specKey;
  }, [addSpecification]);

  const updateExistingSpecification = useCallback(async (workletSpecKeys: string[]): Promise<string> => {
    if (isEmpty(workletSpecKeys)) {
      return null;
    }
    const { data: { specKey = null } = {} } = await updateSpecification({
      variables: {
        specKey: project?.specKey,
        workletSpecKeys,
      },
    });
    logger.debug('Updated specification', { specKey });
    return specKey;
  }, [project?.specKey, updateSpecification]);

  const createCustomWorklets = useCallback(async (
    customWorklets: TWorklet[],
  ): Promise<string[]> => {
    logger.debug('Adding worklets...', { customWorklets });
    const workletSpecKeys = !isEmpty(customWorklets)
      ? await addWorklets(customWorklets)
      : [];
    // Only keep the new worklets (to delete later if other operation/s fail)
    recentlyAddedWorkletSpecKeysRef.current = reduce(
      customWorklets,
      (result: string[], worklet, index) => {
        if (!worklet.specKey && workletSpecKeys[index]) {
          result.push(workletSpecKeys[index]);
        }
        return result;
      },
      [],
    );
    logger.debug('Added worklets', { workletSpecKeys });
    return workletSpecKeys;
  }, [addWorklets]);

  const validateForm = useCallback(() => {
    if (!campaignContext.campaignName) {
      showErrorMessage('Please enter a campaign name');
      return false;
    }

    if (isEmpty(campaignContext.worklets)) {
      showErrorMessage('Please add at least one workflow stage');
      return false;
    }

    return true;
  }, [campaignContext.campaignName, campaignContext.worklets, showErrorMessage]);

  const {
    error: uploadError,
    upload: uploadImage,
  } = useUploadContent({
    serviceName: 'community',
    parentFolder: 'temp',
  });

  useEffect(
    () => {
      if (uploadError && !uploadError.isUploadAborted) {
        logger.error(uploadError);
        showErrorMessage(uploadError.message);
      }
    },
    [uploadError, showErrorMessage],
  );

  const handleImageUpload = useCallback(
    async (imageFile: File) => {
      if (imageFile) {
        return uploadImage(imageFile, 'image', { imageCompressionEnabled: true });
      }
      return null;
    },
    [uploadImage],
  );

  const uploadImageToBucket = useCallback(async (): Promise<string> => {
    if (!campaignContext.campaignImage || project?.splashImageUrl === campaignContext.campaignImage) {
      return campaignContext.campaignImage || project?.splashImageUrl;
    }

    const splashImageUrl = await handleImageUpload(campaignContext.campaignImageFile);
    return splashImageUrl;
  }, [campaignContext.campaignImage, handleImageUpload, project?.splashImageUrl, campaignContext.campaignImageFile]);

  const handleCreateOrUpdateCampaign = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Create worklets first if needed
      // @ts-expect-error - false alarm, asking for __typename
      const workletKeys = await createCustomWorklets(campaignContext.worklets);

      // Validate worklet spec with installed apps
      if (workletKeys?.length) {
        const [error, errorMessage] = validateProjectSpec(workletKeys, installedApps, mode === 'add');
        if (error) {
          setIsSubmitting(false);
          showErrorMessage(errorMessage || 'There was an unexpected error.', 5000);
          return;
        }
      }

      let specKey = project?.specKey;
      if (!specKey) {
        specKey = await createSpecification(workletKeys || []);
      } else {
        specKey = await updateExistingSpecification(workletKeys || []);
      }

      const splashImageUrl = await uploadImageToBucket();

      const input: SaveProgramMutationVariables['program'] = {
        title: trim(campaignContext.campaignName),
        description: '',
        templateName: campaignContext.templateName,
        specKey: specKey || null,
        workletSpecKeys: workletKeys || [],
        isFlexibleSpec: campaignContext.isFlexibleSpec,
        id: mode === 'edit' ? project?.id : null,
        splashImageUrl,
        gcrEnabled: campaignContext.contentReviewEnabled,
        gcrApproverIds: campaignContext.contentReviewers,
        gcrHideApprovalAction: campaignContext.gcrHideApprovalAction,
        owner: selectedOwner,
        programMetadata: {
          programId: campaignContext.programId,
          startDate: campaignContext.startDate || new Date(), // default start date is today
          endDate: campaignContext.endDate,
          budget: campaignContext.budget,
          budgetTimePeriod: campaignContext.budgetTimePeriod,
          salesGoal: campaignContext.salesGoal,
          salesGoalTimePeriod: campaignContext.salesGoalTimePeriod,
          creatorCount: campaignContext.creatorCount,
          creatorsTimePeriod: campaignContext.creatorsTimePeriod,
          creatorDescription: campaignContext.creatorDescription,
          creatorPreference: campaignContext.creatorPreference,
          contentPrefPostTypes: campaignContext.contentPrefPostTypes,
          compensationMethod: campaignContext.compensationMethod,
          discountType: campaignContext.discountType,
          commissionValue: campaignContext.commissionValue,
          commissionValueType: campaignContext.commissionValueType,
          discountValue: campaignContext.discountValue,
          discountValueType: campaignContext.discountValueType,
        },
        applicationFormFields: {
          memberFieldSchemas: [],
          dbColumns: [{ name: 'email', label: 'Email', required: true }],
        },
        columns: {
          memberFieldSchemaIds: [],
          dbColumns: ['email'],
        },
      };

      const { data: { program } } = await saveProgram({
        variables: {
          program: input,
        },
      });

      if (program?.id) {
        if (mode === 'add') {
          addEvent(EventName.CreatedProject, {
            spec_size: workletKeys?.length || 0,
            spec: workletKeys,
            projectId: program.id,
            template: campaignContext.templateName,
          });

          message.success({
            icon: CheckIcon,
            content: 'Campaign created successfully',
            duration: 2,
          });

          clearRollbackSpecKeys();
          history.push(`/projects/${program.id}/overview`);
        } else {
          showSuccessMessage(`Successfully edited "${program.title}"`, 6000);
          if (onSave) {
            onSave();
          }
          if (refetchProjects) {
            refetchProjects();
          }
          const { data: taskTrackers } = await refetchTaskTrackers();
          if (taskTrackers?.trackers?.length) {
            addTaskId(taskTrackers.trackers[0].id, program.id);
          }
        }
      } else {
        logger.warn('Program is empty', { program });
        await rollbackSpec();
      }
    } catch (error) {
      logger.error(error);
      await rollbackSpec();

      const errorMessage = error?.graphQLErrors?.[0]?.message;
      if (errorMessage?.startsWith('Program with this title already exists')) {
        showErrorMessage('A campaign with this name already exists. Please choose a different name.');
      } else {
        showError(error);
      }
    } finally {
      setIsSubmitting(false);
      campaignContext.setHasUnsavedChanges(false);
    }
  }, [
    validateForm,
    mode,
    createCustomWorklets,
    campaignContext,
    installedApps,
    showErrorMessage,
    createSpecification,
    project?.specKey,
    project?.id,
    saveProgram,
    addEvent,
    clearRollbackSpecKeys,
    history,
    showSuccessMessage,
    onSave,
    refetchProjects,
    rollbackSpec,
    showError,
    selectedOwner,
    updateExistingSpecification,
    addTaskId,
    refetchTaskTrackers,
    uploadImageToBucket,
  ]);

  return {
    isSubmitting,
    handleCreateOrUpdateCampaign,
  };
};
