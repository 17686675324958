import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Input } from '@frontend/shadcn/components/ui/input';
import { cn } from '@frontend/shadcn/lib/utils';
import { ProgramTimePeriod } from '../containers/Projects/CampaignPage/CampaignContext';

interface ValueWithPeriodInputProps {
  value: number | null;
  period: ProgramTimePeriod;
  onValueChange: (value: number, period: ProgramTimePeriod) => void;
  placeholder?: string;
  className?: string;
  prefix?: string;
}

export function ValueWithPeriodInput({
  value,
  period,
  onValueChange,
  placeholder = '0',
  className,
  prefix = '$',
}: ValueWithPeriodInputProps) {
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <div className={cn('relative flex w-full', className)}>
      <div className="relative flex-1">
        {prefix && (
          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">
            {prefix}
          </span>
        )}
        <Input
          type="text"
          value={inputValue}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d*\.?\d*$/.test(inputValue)) {
              const newValue = inputValue === '' ? null : Number(inputValue);
              setInputValue(inputValue);
              onValueChange(newValue, period);
            }
          }}
          className={cn(prefix && 'pl-7', 'rounded-r-none border-r-0')}
          placeholder={placeholder}
        />
      </div>
      <Select
        value={period}
        onValueChange={(period: ProgramTimePeriod) => onValueChange(value || 0, period)}
      >
        <SelectTrigger className="w-[130px] rounded-l-none border-l-0">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={ProgramTimePeriod.PER_MONTH}>per month</SelectItem>
          <SelectItem value={ProgramTimePeriod.PER_YEAR}>per year</SelectItem>
          <SelectItem value={ProgramTimePeriod.TOTAL}>total</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
