/* eslint-disable quote-props */
import React from 'react';
import {
 BarChart, Bar, XAxis, YAxis, CartesianGrid,
} from 'recharts';
import { FragmentType, graphql, useFragment as getFragmentData } from '@frontend/gql/social';
import {
 ChartConfig, ChartContainer, ChartTooltip, ChartLegendContent, ChartLegend,
} from '@frontend/shadcn/components/ui/chart';
import { formatCurrency } from '../../../utils/formatCurrency';
import { ChartTooltipContent } from '../../../components/chart';

const AdSpendVsSalesChartFragment = graphql(`
  fragment AdSpendVsSalesChart_MetaIntervalInsights on MetaInsights {
    interval {
      date
    }
    spend {
      amount
      currency
    }
    purchase {
      amount
      currency
    }
  }
`);

type AdSpendVsSalesChartProps = {
  currentIntervalsIntervalInsights: (FragmentType<typeof AdSpendVsSalesChartFragment> | null | undefined)[] | null | undefined;
  currentIntervalsIntervalInsightsNotAspireSourced: (FragmentType<typeof AdSpendVsSalesChartFragment> | null | undefined)[] | null | undefined;
};

const AdSpendVsSalesChart = ({ currentIntervalsIntervalInsights, currentIntervalsIntervalInsightsNotAspireSourced }: AdSpendVsSalesChartProps) => {
  const currentIntervalData = currentIntervalsIntervalInsights?.map((interval) =>
    getFragmentData(AdSpendVsSalesChartFragment, interval)) || [];

  const currentIntervalNotAspireSourcedData = currentIntervalsIntervalInsightsNotAspireSourced?.map((interval) =>
    getFragmentData(AdSpendVsSalesChartFragment, interval)) || [];

  // Transform data for the chart
  const data = currentIntervalData.map((interval, index) => {
    const notAspireSourced = currentIntervalNotAspireSourcedData[index];
    const date = interval?.interval?.date ? new Date(interval.interval?.date) : new Date();
    return {
      month: date.toLocaleString('default', { month: 'short', day: 'numeric' }),
      'Spend Attributed to Aspire': interval?.spend?.amount || 0,
      'Spend Not Attributed to Aspire': notAspireSourced?.spend?.amount || 0,
      'Sales Attributed to Aspire': interval?.purchase?.amount || 0, // Replace with actual sales data when available
      'Sales Not Attributed to Aspire': notAspireSourced?.purchase?.amount || 0, // Replace with actual sales data when available
    };
  });

  const chartConfig = {
    'Spend Attributed to Aspire': {
      label: 'Spend Attributed to Aspire',
      color: 'hsl(var(--refresh-blue-4))',
    },
    'Spend Not Attributed to Aspire': {
      label: 'Spend Not Attributed to Aspire',
      color: 'hsl(var(--refresh-blue-3))',
    },
    'Sales Attributed to Aspire': {
      label: 'Sales Attributed to Aspire',
      color: 'hsl(var(--refresh-green-4))',
    },
    'Sales Not Attributed to Aspire': {
      label: 'Sales Not Attributed to Aspire',
      color: 'hsl(var(--refresh-green-3))',
    },
  } satisfies ChartConfig;

  console.log('data', data);
  return (
    <ChartContainer className="h-[300px]" config={chartConfig}>
      <BarChart barCategoryGap="20%" data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value}
        />
        <YAxis tickLine={false} axisLine={false} tickFormatter={(value) => formatCurrency(value, 'USD')} />
        <ChartTooltip content={<ChartTooltipContent valueFormatter={(item) => formatCurrency(Number(item.value), 'USD')} />} />
        <ChartLegend content={<ChartLegendContent />} />
        <Bar dataKey="Spend Attributed to Aspire" stackId="a" fill="hsl(var(--refresh-blue-4))" radius={[4, 4, 0, 0]} />
        <Bar dataKey="Spend Not Attributed to Aspire" stackId="a" fill="hsl(var(--refresh-blue-3))" radius={[4, 4, 0, 0]} />
        <Bar dataKey="Sales Attributed to Aspire" stackId="b" fill="hsl(var(--refresh-green-4))" radius={[4, 4, 0, 0]} />
        <Bar dataKey="Sales Not Attributed to Aspire" stackId="b" fill="hsl(var(--refresh-green-3))" radius={[4, 4, 0, 0]} />
      </BarChart>
    </ChartContainer>
  );
};

export default AdSpendVsSalesChart;
