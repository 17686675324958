import React, { useState } from 'react';
import { ImageIcon, Plus, Trash, GripVertical } from 'lucide-react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ApolloProvider } from '@apollo/client';
import { FindAndImportProvider } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { useAppContext } from '@frontend/context/AppContext';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { message } from 'antd';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { ExportProductModal } from './ExportProductModal';

const MAX_PRODUCTS_LIMIT = 12;

type ProductDetails = {
  description?: string;
  id: string;
  imgUrl?: string;
  imageUrl?: string;
  title: string;
  index?: number;
};

const ManageAllProductsContent: React.FC = () => {
  const { pfaApolloClient } = useAppContext();
  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);

  // Initialize selectedProducts from landingPageData (if available)
  const [selectedProducts, setSelectedProducts] = useState<ProductDetails[]>(
    landingPageData?.products?.allProducts?.products || [],
  );

  const creatorLimit = landingPageData.products.setting.noOfProductsCreatorCanAdd || 0;
  const isCreatorAddEnabled = landingPageData.products.setting.allowCreatorToAddProducts;

  const calculateRemainingSlots = () => {
    const reservedForCreators = isCreatorAddEnabled ? creatorLimit : 0;
    const maxAdminSlots = MAX_PRODUCTS_LIMIT - reservedForCreators;
    return maxAdminSlots - selectedProducts.length;
  };

  const remainingSlots = calculateRemainingSlots();
  const isAddDisabled = remainingSlots <= 0;

  const getTooltipMessage = () => {
    if (isCreatorAddEnabled) {
      return `${creatorLimit} slots are reserved for creators. You can only add up to ${MAX_PRODUCTS_LIMIT - creatorLimit} products.`;
    }
    return `Maximum product limit of ${MAX_PRODUCTS_LIMIT} reached.`;
  };

  const handleAddProduct = () => {
    if (selectedProducts.length >= MAX_PRODUCTS_LIMIT) {
      message.error(`You can only add up to ${MAX_PRODUCTS_LIMIT} products.`);
      return;
    }
    setIsLocalModalOpen(true);
    setIsFindAndImportOpen(true);
  };

  const handleCloseModal = () => {
    setIsLocalModalOpen(false);
    setIsFindAndImportOpen(false);
  };

  const handleRemoveProduct = (productId: string) => {
    const updatedProducts = selectedProducts.filter((p) => p.id !== productId);

    // Update local state
    setSelectedProducts(updatedProducts);

    // Update context
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        allProducts: {
          ...prev.products.allProducts,
          products: updatedProducts,
        },
      },
    }));
  };

  const getImageSrc = (product: ProductDetails) => product.imgUrl || product.imageUrl || '';

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const reorderedProducts = Array.from(selectedProducts);
    const [removed] = reorderedProducts.splice(source.index, 1);
    reorderedProducts.splice(destination.index, 0, removed);

    // Update indices for all products
    const updatedProducts = reorderedProducts.map((product, index) => ({
      ...product,
      index,
    }));

    // Update local state
    setSelectedProducts(updatedProducts);

    // Update context
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        allProducts: {
          ...prev.products.allProducts,
          products: updatedProducts,
        },
      },
    }));
  };

  const AddProductButton = () => (
    <Button
      variant="default"
      onClick={handleAddProduct}
      disabled={isAddDisabled}
      className={isAddDisabled ? 'opacity-50 cursor-not-allowed' : ''}
    >
      <Plus className="h-3 w-3 mr-2" />
      Add Product
    </Button>
  );

  const sortedProducts = selectedProducts.sort((a, b) => {
    if (a.index === undefined && b.index === undefined) return 0;
    if (a.index === undefined) return 1;
    if (b.index === undefined) return -1;
    return a.index - b.index;
  });

  return (
    <div className="flex flex-col items-start gap-4 px-4">
      <p className="text-sm text-grey-5 font-medium">
        You can add up to
        {' '}
        {MAX_PRODUCTS_LIMIT - creatorLimit}
        {' '}
        products
        {isCreatorAddEnabled && ` (${creatorLimit} slots reserved for creators)`}
        .
      </p>

      {selectedProducts.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="products">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="w-full space-y-3"
              >
                {sortedProducts.map((product, index) => (
                  <Draggable
                    key={product.id}
                    draggableId={product.id}
                    index={index}
                  >
                    {(dragProvided, snapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        className={`flex items-center justify-between p-3 border rounded-lg ${
                          snapshot.isDragging ? 'bg-grey-1 shadow-lg' : ''
                        }`}
                      >
                        <div className="flex items-center gap-3">
                          {/* Drag Handle */}
                          <div
                            {...dragProvided.dragHandleProps}
                            className="cursor-move p-1 hover:bg-grey-1 rounded"
                          >
                            <GripVertical className="h-4 w-4 text-grey-5" />
                          </div>

                          {/* Product Image */}
                          {getImageSrc(product) ? (
                            <img
                              src={getImageSrc(product)}
                              alt={product.title}
                              className="w-12 h-12 object-cover rounded"
                            />
                          ) : (
                            <ImageIcon className="w-12 h-12 text-grey-5" />
                          )}

                          {/* Product Title */}
                          <div>
                            <h3 className="font-medium">{product.title}</h3>
                          </div>
                        </div>

                        {/* Delete Button */}
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleRemoveProduct(product.id)}
                          className="rounded-lg"
                        >
                          <Trash className="h-3 w-3" />
                        </Button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {isAddDisabled ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span>
                <AddProductButton />
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{getTooltipMessage()}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <AddProductButton />
      )}

      {isFindAndImportOpen && isLocalModalOpen && (
        <ApolloProvider client={pfaApolloClient}>
          <FindAndImportProvider>
            <ExportProductModal
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              onClose={handleCloseModal}
            />
          </FindAndImportProvider>
        </ApolloProvider>
      )}
    </div>
  );
};

export default ManageAllProductsContent;
