import React from 'react';
import {
 Input, Modal, Select, Space, Typography,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import { CatalogType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useMessagingContext } from '@frontend/hooks';
import { GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { GET_FEATURED_PRODUCTS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/featuredProducts';
import { pluralize } from '@frontend/app/utils/strings';
import { logger } from '@common';
import styles from './FeaturedProductsSelectionModal.scss';
import { useCreateCatalog } from '../hooks/useCreateCatalog';
import { useCatalogDetailsContext } from '../CatalogDetails/CatalogDetailsContext';
import { pfaV2Routes } from '../../routes';
import { useBasicSelectionContext, useNonDeletedProducts } from '../CatalogSelectionModal/BasicSelectionContext';
import { ProductsTable } from './ProductsTable';
import { useCreateFeaturedProducts } from '../hooks/useCreateFeaturedProducts';
import { reloadPreviewIframe } from '../reloadPreviewIframe';

const { Text } = Typography;

export const FeaturedProductsSelectionModal = () => {
  const {
    search,
    setSearch,
    selectedProducts,
    touchedProductIds,
    resetBasicSelection,
    resetEditing,
    isOpen,
    isEditing,
  } = useBasicSelectionContext();
  const nonDeletedProducts = useNonDeletedProducts();
  const { title, status, description } = useCatalogDetailsContext();
  const history = useHistory();

  const { catalogId } = useParams<{ catalogId: string }>();

  const {
    showErrorMessage,
  } = useMessagingContext();

  const { createCatalog } = useCreateCatalog({
    onCompleted: (data) => {
      history.push(pfaV2Routes.settings.brandCatalogFeaturedProducts.replace(':catalogId', String(data?.createBrandCatalog?.id)));
    },
    onError: (error) => {
      logger.error('Error in createCatalog while adding featured products', error);
      showErrorMessage('Failed to create catalog');
    },
    refetchQueries: [GET_BRAND_CATALOGS_QUERY],
  });
  const { createFeaturedProducts, loading: isCreateFeaturedProductLoading } = useCreateFeaturedProducts({
    onError: (error) => {
      logger.error('Error in createFeaturedProducts while creating featured products', error);
      showErrorMessage(error.message || 'Failed to create featured products');
    },
  });

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const resetDrawer = () => {
    if (isEditing) {
      return resetEditing();
    }
    resetBasicSelection();
  };

  const handleCreateCollectionItem = async () => {
    let newCatalogId = Number(catalogId);
    if (catalogId === 'new') {
      const { data: catalog } = await createCatalog({
        variables: {
          input: {
            name: title,
            type: CatalogType.CUSTOM,
            status,
            description,
          },
        },
      });
      if (!catalog) {
        return;
      }
      newCatalogId = (catalog.createBrandCatalog.id);
    }
    const productsWithOperationType = selectedProducts
      .filter((product) => !!product.operationType)
      .map((product) => ({
        productId: Number(product.productId),
        variantIds: product.variantIds.map((variantId) => Number(variantId)),
        brandCatalogId: newCatalogId,
        isFeatured: true,
        operationType: product.operationType,
      }));

    const isAllProductsSelected = touchedProductIds.size === productsWithOperationType.length && productsWithOperationType.length > 0;

    const touchedProducts = isAllProductsSelected
      ? productsWithOperationType
      : productsWithOperationType.filter((product) =>
          touchedProductIds.has(String(product.productId))
        );

    if (isEditing && touchedProducts.length === 0) {
      resetDrawer();
      return;
    }

    createFeaturedProducts({
      refetchQueries: [GET_FEATURED_PRODUCTS_QUERY],
      variables: {
        catalogId: newCatalogId,
        products: touchedProducts,
      },
      onCompleted: () => {
        resetDrawer();
        reloadPreviewIframe();
      },
      update: (cache) => {
        cache.evict({ fieldName: 'getStatsForAllCatalogs' });
        cache.evict({ fieldName: 'getStatsByCatalogId' });
      },
    });
  };

  return (
    <Modal
      title="Select Products"
      open={isOpen}
      onCancel={resetDrawer}
      onOk={handleCreateCollectionItem}
      okText={`Add ${nonDeletedProducts.length} ${pluralize(nonDeletedProducts.length, 'product')}`}
      confirmLoading={isCreateFeaturedProductLoading}
      cancelText="Cancel"
      width={620}
      className={styles.FeaturedProductsSelectionModal}
      okButtonProps={{
        disabled: !nonDeletedProducts.length || nonDeletedProducts.length > 20,
      }}
    >
      <Space direction="vertical" size="large">
        <Space direction="vertical">
          <Text weight="semibold">Select Products</Text>
          <Input.Group compact>
            <Select defaultValue="productName" className={styles.selectFilters}>
              <Select.Option value="productName">Product Name</Select.Option>
            </Select>
            <Input
              placeholder="Search"
              suffix={<MagnifyingGlassIcon />}
              className={styles.filtersInput}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Input.Group>
        </Space>
        <ProductsTable />
      </Space>
    </Modal>
  );
};
