import * as React from 'react';
import { noop } from 'lodash';

import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Space,
  Tag,
  Typography,
} from '@revfluence/fresh';
import { LazyImage } from '@components';
import useConvertToJpg from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage/utils/useConvertHeicToJpg';
import { TContentSubmittedCard } from '../types';
import { CONTENT_SOURCE } from '../../../../app/utils/getContentReviewMedia';
import { InstagramContent } from '../../../../app/components/EmbeddedContent/InstagramContent';

export const ContentSubmittedCard = ({
  title,
  icon,
  description,
  status,
  media,
  canTakeAction,
  onReviewClick = noop,
  promotedToGCR,
  canPromoteToGCR,
  reUploadedContent,
}: TContentSubmittedCard) => {
  const mediaUrl = useConvertToJpg(media.url);
  return (
    <Card>
      <Row gutter={16} align="middle">
        <Col>
          <Avatar
            size={56}
            shape="square"
            icon={(
              <>
                {media.source == CONTENT_SOURCE.INSTAGRAM_EMBED && (
                  <InstagramContent url={media.url} renderAsImage />
                )}
                {media.source == CONTENT_SOURCE.YOUTUBE_EMBED && (
                  <LazyImage src={media.image} />
                )}
                {media.source == CONTENT_SOURCE.TIKTOK_EMBED && (
                  <LazyImage src={media.image} />
                )}
                {media.type === 'image' && media.source == CONTENT_SOURCE.GCS && (
                  <LazyImage src={mediaUrl || media.image} />
                )}
                {media.type === 'video' && (
                  <LazyImage src={media.image || media.url} />
                )}
              </>
            )}
          />
        </Col>
        <Col flex={1}>
          <Space direction="vertical" size={2}>
            <div style={{
              display: 'flex', alignItems: 'center', fontWeight: 'bold', gap: '4px',
            }}
            >
              {icon}
              {' '}
              {title}
            </div>
            {description && (
              <Typography.Text type="secondary" noMargin>
                {description}
              </Typography.Text>
            )}
            <Tag icon={status.icon} color={status.color}>
              {status.text}
            </Tag>
          </Space>
        </Col>
        {canTakeAction && (
          <Col>
            {promotedToGCR && (
              <>
                {reUploadedContent && (
                  <Button
                    type="primary"
                    onClick={onReviewClick}
                  >
                    Review Content
                  </Button>
                )}
                {!reUploadedContent && (
                  <Button
                    type="primary"
                    onClick={onReviewClick}
                  >
                    Group Content Review
                  </Button>
                )}
              </>
            )}
            {!promotedToGCR && (
              <Button
                type="primary"
                disabled={!canPromoteToGCR}
                onClick={onReviewClick}
              >
                Review Content
              </Button>
            )}
          </Col>
        )}
      </Row>
    </Card>
  );
};
