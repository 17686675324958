import { Alert, AlertDescription } from '@frontend/shadcn/components/ui/alert';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ArrowUpRightFromSquare, Layers, Monitor } from 'lucide-react';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { LANDING_PAGE_HELP_URL } from '../../utils/constant';

interface ActionButton {
  label: string;
  icon: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
}

export default function AlertBar({
  baseUri,
  offerId,
  landingPageUrl,
  isOfferExpired,
}: {
  baseUri: string;
  offerId: number;
  landingPageUrl: string;
  isOfferExpired: boolean;
}): JSX.Element {
  const handleLandingPageNavigation = () => {
    history.push({ ...location, pathname: `${baseUri}/offers/${offerId}/setupLandingPage` });
  };
  const handlePreview = () => {
    window.open(landingPageUrl, '_blank');
  };
  const actionButtons: ActionButton[] = [
    {
      label: 'View Page',
      icon: <ArrowUpRightFromSquare className="h-3 w-3" />,
      onClick: handlePreview,
      disabled: false,
    },
    {
      label: 'Customize',
      icon: <Layers className="h-3 w-3" />,
      onClick: handleLandingPageNavigation,
      disabled: isOfferExpired,

    },
  ];
  const history = useHistory();
  return (
    <Alert className="flex h-12 items-center justify-between px-6 py-2 bg-[#e5e8eb] rounded-none border-none">
      <div className="flex items-center gap-2">
        <Monitor className="h-4 w-4 text-grey-6" />
        <AlertDescription className="text-grey-6 text-sm font-medium">
          CreatorStore is enabled for this offer.
          {' '}
          <button
            className="underline hover:opacity-80"
            onClick={() => {
              window.open(LANDING_PAGE_HELP_URL, '_blank');
            }}
          >
            How to Setup?
          </button>
        </AlertDescription>
      </div>
      <div className="flex items-center gap-4">
        {actionButtons.map((button) => (
          <Button
            key={button.label}
            variant="ghost"
            className="h-8 px-0 py-1 hover:bg-transparent hover:opacity-80"
            onClick={button.onClick}
            disabled={button.disabled}
          >
            <div className="flex items-center gap-2">
              {button.icon}
              <span className="text-neutral-color-palettegray-9 font-semibold text-sm">
                {button.label}
              </span>
            </div>
          </Button>
        ))}
      </div>
    </Alert>
  );
}
