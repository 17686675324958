import { ContentType } from '@frontend/app/types/globalTypes';
import { TDateRequirement } from '../ContentDateRequirements';

type TSocialHandles = {
  blog: string | null;
  facebook: string | null;
  instagram: string | null;
  pinterest: string | null;
  snapchat: string | null;
  tiktok: string | null;
  twitter: string | null;
  youtube: string | null;
};

export type SocialColumnKey = keyof TSocialHandles;

type TContentRequirements = {
  additionalImages: Array<TDateRequirement>;
  additionalVideos: Array<TDateRequirement>;
  blogPostMentions: Array<TDateRequirement>;
  blogPosts: Array<TDateRequirement>;
  customWork: Array<TDateRequirement>;
  facebookPosts: Array<TDateRequirement>;
  instagramPosts: Array<TDateRequirement>;
  instagramReels: Array<TDateRequirement>;
  instagramStories: Array<TDateRequirement>;
  instagramVideos: Array<TDateRequirement>;
  pinterestPins: Array<TDateRequirement>;
  snapchatStories: Array<TDateRequirement>;
  tiktokVideos: Array<TDateRequirement>;
  twitterTweets: Array<TDateRequirement>;
  youtubeProductMentions: Array<TDateRequirement>;
  youtubeVideos: Array<TDateRequirement>;
  youtubeShorts: Array<TDateRequirement>;
  flexibleDeliverable: Array<TDateRequirement>;
};

export type ContentColumnKey = keyof TContentRequirements;

export interface IInputMember extends TSocialHandles {
  // basic member details
  id: number;
  name: string;
  email: string;
  imageUrl: string | null;
  reach: number;
  fields?: TSocialHandles;
}

type TMemberWithContent = IInputMember & TContentRequirements;
export interface IMember extends TMemberWithContent {
  // rewards
  paymentAmount: number | null;
  recommendedPaymentAmount: number | null;

  // used by the UI state
  key: string;
  disabled: boolean;
  selected: boolean;
  comment: string | null;
}

export type ColumnKey = keyof IMember;

export const CONTENT_MEMBER_FIELD_MAP: Record<ContentType, ContentColumnKey> = {
  [ContentType.ADDITIONAL_IMAGES]: 'additionalImages',
  [ContentType.ADDITIONAL_VIDEOS]: 'additionalVideos',
  [ContentType.BLOG_DEDICATED]: 'blogPosts',
  [ContentType.BLOG_MENTION]: 'blogPostMentions',
  [ContentType.FACEBOOK_POST]: 'facebookPosts',
  [ContentType.INSTAGRAM_POST]: 'instagramPosts',
  [ContentType.INSTAGRAM]: 'instagramPosts',
  [ContentType.INSTAGRAM_REEL]: 'instagramReels',
  [ContentType.INSTAGRAM_STORY]: 'instagramStories',
  [ContentType.INSTAGRAM_VIDEO]: 'instagramVideos',
  [ContentType.OTHER]: 'customWork',
  [ContentType.PINTEREST_PIN]: 'pinterestPins',
  [ContentType.PINTEREST]: 'pinterestPins',
  [ContentType.SNAPCHAT_STORY]: 'snapchatStories',
  [ContentType.TIKTOK_VIDEO]: 'tiktokVideos',
  [ContentType.TWITTER_POST]: 'twitterTweets',
  [ContentType.YOUTUBE_DEDICATED]: 'youtubeVideos',
  [ContentType.YOUTUBE_MENTION]: 'youtubeProductMentions',
  [ContentType.YOUTUBE_SHORT]: 'youtubeShorts',
  [ContentType.FLEXIBLE]: 'flexibleDeliverable',
};

export const SOCIAL_MEMBER_FIELD_MAP: Partial<Record<ContentType, SocialColumnKey>> = {
  [ContentType.BLOG_DEDICATED]: 'blog',
  [ContentType.BLOG_MENTION]: 'blog',
  [ContentType.FACEBOOK_POST]: 'facebook',
  [ContentType.INSTAGRAM_POST]: 'instagram',
  [ContentType.INSTAGRAM]: 'instagram',
  [ContentType.INSTAGRAM_REEL]: 'instagram',
  [ContentType.INSTAGRAM_STORY]: 'instagram',
  [ContentType.INSTAGRAM_VIDEO]: 'instagram',
  [ContentType.PINTEREST_PIN]: 'pinterest',
  [ContentType.PINTEREST]: 'pinterest',
  [ContentType.SNAPCHAT_STORY]: 'snapchat',
  [ContentType.TIKTOK_VIDEO]: 'tiktok',
  [ContentType.TWITTER_POST]: 'twitter',
  [ContentType.YOUTUBE_DEDICATED]: 'youtube',
  [ContentType.YOUTUBE_MENTION]: 'youtube',
  [ContentType.YOUTUBE_SHORT]: 'youtube',
};
