import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import React from 'react';
import { Tag } from '@frontend/app/refresh-components';

const statusColor = {
  'Review Edits': {
    bgColor: 'bg-yellow-2',
    textColor: 'text-[#16282D]',
  },
  'Agreed': {
    bgColor: 'bg-green-2',
    textColor: 'text-[#16282D]',
  },
  'Waiting for Agreement': {
    bgColor: 'bg-blue-2',
    textColor: 'text-[#16282D]',
  },
  'Canceled': {
    bgColor: 'bg-[#E5E8EB]',
    textColor: 'text-[#16282D]',
  },
};

export interface TBrief {
  title?: string;
  status?: string;
  createdDate?: string;
  payment?: number;
  updatedDate?: string;
  agreedDate?: string;
  onClickBrief?: () => void;
}

const BriefCard: React.FC<TBrief> = (props) => {
  const {
    title,
    status,
    createdDate,
    payment,
    updatedDate,
    agreedDate,
  } = props;
  return (
    <Card className="p-4 bg-transparent rounded-lg flex flex-col gap-2">
      <div className="text-base font-medium">{title}</div>
      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Status</P>
        {status ? (
          <Tag
            label={status}
            bgColor={statusColor[status]?.bgColor}
            textColor={statusColor[status]?.textColor}
          />
        ) : (
          <P className="text-sm font-normal">-</P>
        )}
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Amount</P>
        <P className="text-sm font-normal">{`$${payment || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Sent Date</P>
        <P className="text-sm font-normal">{createdDate || '-'}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Edited Date</P>
        <P className="text-sm font-normal">{updatedDate || '-'}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Agreed Date</P>
        <P className="text-sm font-normal">{agreedDate || '-'}</P>
      </div>
    </Card>
  );
};

export default BriefCard;
