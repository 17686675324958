import * as React from 'react';
import {
  Checkbox,
  Form,
  Space,
} from '@revfluence/fresh';
import { CLIENT_CONNECTION_STATUS, OFFER_PRICE_RULE_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { FormInstance, Input, Tooltip } from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
 FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions, OfferFormModes,
 PROMO_OFFER_TYPES,
} from '../../../types';

interface IProps {
  disabled: boolean,
  mode: OfferFormModes,
  values: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (value: FormAsyncAction) => void,
  formRef: FormInstance,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
  offerType: string,
}
export const MultipleShopifyStores: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  mode,
  values,
  handleFormAsyncActions,
  formRef,
  connectedAdvertiserForSync,
  offerType,
}) => {
  const clientsForSync = values.clientsForSync;
  const maxClientToSelect: number = 5;
  const rules = [
    {
      validator: (_) => {
        if (clientsForSync.length === 0) {
          return Promise.reject(new Error('Select at least one store to sync the promo codes to. If you want to disable the sync, please toggle off the “Sync Promo Codes” option.'));
        }
        if (clientsForSync.length > maxClientToSelect) {
          return Promise.reject(new Error(`Please select less than ${maxClientToSelect} stores.`));
        }
        return Promise.resolve();
      },
    },
  ];

  const handleCheckboxChnage = (connectedAdvertiserId: number, isChecked: boolean, discountCodeGid?: string) => {
    const currentValues = formRef.getFieldValue('clientsForSync') || [];

    if (discountCodeGid) {
      currentValues.forEach((client) => {
        if (client.advertiserId === connectedAdvertiserId) {
          client.discountCodeGid = discountCodeGid;
        }
      });
      formRef.setFieldsValue({
        clientsForSync: currentValues,
      });
      handleFormAsyncActions({ action: OfferFormAsyncActions.MULTIPLE_STORE_SELECT, payload: { advertiserId: connectedAdvertiserId, isSelected: isChecked, discountCodeGid } });
      return;
    }

    const oldClientIndex = currentValues.findIndex((client) => client.advertiserId === connectedAdvertiserId);
    if (oldClientIndex !== -1) {
      currentValues.splice(oldClientIndex, 1);
    } else {
      currentValues.push({
        advertiserId: connectedAdvertiserId,
        priceRuleAmount: null,
        priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
        status: CLIENT_CONNECTION_STATUS.ACTIVE,
        isSelected: isChecked,
        discountCodeGid: offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER ? discountCodeGid : null,
      });
    }
    formRef.setFieldsValue({
      clientsForSync: currentValues,
    });
    handleFormAsyncActions({ action: OfferFormAsyncActions.MULTIPLE_STORE_SELECT, payload: { advertiserId: connectedAdvertiserId, isSelected: isChecked } });
  };

  const extractGid = (discountCodeGid: string) => {
    if (discountCodeGid) {
      return discountCodeGid.split('/').pop();
    }
    return '';
  };

  return (
    <>
      <Form.Item rules={rules} name="selectedClients">
        <Space direction="vertical" style={{ marginBottom: '8px' }} className="w-full">
          {
            connectedAdvertiserForSync.map(((client) => {
              const isSelected = clientsForSync.find((c) => client.connectedAdvertiserId === c.advertiserId);
              if ((mode === OfferFormModes.Create && client.isConnected) || (mode === OfferFormModes.Edit && (isSelected?.status === CLIENT_CONNECTION_STATUS.ACTIVE || client.isConnected))) {
                return (
                  <>
                    <Checkbox
                      checked={!!isSelected?.isSelected}
                      disabled={disabled}
                      key={client.id}
                      onChange={(e) => handleCheckboxChnage(client.connectedAdvertiserId, e.target.checked)}
                    >
                      {client.label}
                    </Checkbox>
                    { isSelected && offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER && (
                    <Form.Item
                      label={(
                        <span>
                          Shopify Discount Id
                          <Tooltip title="Enter the Shopify Discount ID for your 'Buy X, Get Y' offer. You can find this ID in the offer URL in your Shopify admin under the Discounts section.">
                            <InfoCircleOutlined className="ml-2" />
                          </Tooltip>
                        </span>
                    )}
                    >
                      <Input
                        placeholder="Enter ID of Linked shopify offer"
                        size="large"
                        key={client.id}
                        onChange={(e) => handleCheckboxChnage(client.connectedAdvertiserId, true, e.target.value)}
                        disabled={disabled}
                        {...(mode === OfferFormModes.Edit && {
                          value: extractGid(values.allClientForSync.find((c) => c.advertiserId === client.connectedAdvertiserId)?.discountCodeGid)
                        })}
                      />
                    </Form.Item>
) }
                  </>
                );
              }
              return null;
            }))
          }
        </Space>
      </Form.Item>
    </>
  );
});
MultipleShopifyStores.displayName = 'MultipleShopifyStores';
