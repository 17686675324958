import gql from 'graphql-tag';

export const BULK_MOVE_TO_MUTATION = gql`
  mutation BulkMoveToMutation($workItemIds: [String!]!, $conditionId: String!, $isCancelOrderRequest: Boolean) {
    success: bulkMoveTo(workItemIds: $workItemIds, conditionId: $conditionId, isCancelOrderRequest: $isCancelOrderRequest)
  }
`;

export const BULK_MOVE_DONE_MEMBERS_FLEX_MUTATION = gql`
  mutation BulkMoveDoneMembersFlexMutation($memberIds: [Int!]!, $programId: Int!, $workletSpecKey: String!) {
    success: bulkMoveDoneMembersFlex(memberIds: $memberIds, programId: $programId, workletSpecKey: $workletSpecKey)
  }
`;
