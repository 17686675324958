import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Button, Select } from '@revfluence/fresh';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { SettingsIcon } from '@components';
import { LoadSpinner } from '@components';
import { SackDollarIcon } from '@revfluence/fresh-icons/regular/esm';
import { GetBudgetFiscalSettingsQuery_budgetSettingsResponse } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import { TAB_CONFIG, Tabs as TabsEnum } from './budgetReportingPage.tabs';
import styles from './budgetReportingPage.scss';
import PageSkeleton from '../PageSkeleton';
import Tabs from './components/Tabs';

const renderIcon = () => (
  <div className={styles.iconContainer}>
    <SackDollarIcon className={styles.sackDollar} />
  </div>
);

const getPageContent = (budgetSettings, selectedFiscalYear, handleFiscalYearChange, selectedTab, setSelectedTab, quarterNames) : React.ReactNode => {
  const Component = TAB_CONFIG?.[selectedTab]?.component;
  const isSingleFiscalYear = budgetSettings?.fiscalYears?.length === 1 && budgetSettings?.fiscalYears[0]?.year.toString() === 'N/A';
  return (
    <div className={styles.contentContainer}>
      <div className={styles.tabsHeader}>
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={Object.values(TabsEnum)}
        />
        {!isSingleFiscalYear && (
          <Select value={selectedFiscalYear} onChange={handleFiscalYearChange}>
            {budgetSettings?.fiscalYears?.map((year) => (
              <Select.Option key={year.year} value={year.year}>
                {year.yearName}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
      <div>
        <Component fiscalYear={selectedFiscalYear} quarterNames={quarterNames} />
      </div>
    </div>
  );
};

const BudgetReportingPage: React.FunctionComponent = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.Accounts);
  const [quarterNames, setQuarterNames] = useState({});
  const { budgetSettingsResponse, loading }: { budgetSettingsResponse: GetBudgetFiscalSettingsQuery_budgetSettingsResponse, loading: boolean } = useGetBudgetFiscalSettings({});

  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>();

  const handleFiscalYearChange = useCallback((value) => {
    setSelectedFiscalYear(value);
  }, [setSelectedFiscalYear]);

  useEffect(() => {
    const defaultFiscalYear = budgetSettingsResponse?.fiscalYears.find((fiscalYear) => fiscalYear?.isCurrentYear);
    setSelectedFiscalYear(defaultFiscalYear?.year);
    setQuarterNames(budgetSettingsResponse?.quarterNames ?? {});
  }, [budgetSettingsResponse, setSelectedFiscalYear, setQuarterNames]);

  const isSettingsPage = useMemo(() => location.pathname.includes('settings'), [location.pathname]);
  const paths = useMemo(
    () => ({
      masterBudgetSetup: isSettingsPage ? '/settings/budget/masterBudgetSetup' : '/budget/masterBudgetSetup',
      createBudget: isSettingsPage ? '/settings/budget/createBudget' : '/budget/createBudget',
    }),
    [isSettingsPage],
  );

  const getHeaderRightSection = useCallback(
    () => (
      <div className={styles.headerRightSection}>
        <Link to={{ ...location, pathname: paths.masterBudgetSetup }}>
          <Button className={styles.settings} icon={<SettingsIcon size={14} />} />
        </Link>
        <Link to={{ ...location, pathname: paths.createBudget }}>
          <Button type="primary">+ New Budget</Button>
        </Link>
      </div>
    ),
    [location, paths.masterBudgetSetup, paths.createBudget],
  );

  return (
    <PageSkeleton title="Budget" renderIcon={renderIcon} getHeaderRightSection={getHeaderRightSection}>
      {loading ? <LoadSpinner className={styles.loadSpinner} /> : getPageContent(budgetSettingsResponse, selectedFiscalYear, handleFiscalYearChange, selectedTab, setSelectedTab, quarterNames)}
    </PageSkeleton>
  );
};

export default BudgetReportingPage;
