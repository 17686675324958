/**
 * Utility functions for working with RichTextEditor (react-quill) content
 */

/**
 * Checks if the rich text content is empty (ignoring whitespaces and HTML tags)
 *
 * @param content - The HTML string content from react-quill editor
 * @returns boolean - true if the content is empty or only contains whitespace/HTML tags
 */
export const isRichTextEmpty = (content: string | undefined | null): boolean => {
  if (!content) {
    return true;
  }

  // Create a temporary div element to parse the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;

  // Get the text content (without HTML tags) and trim whitespace
  const textContent = tempDiv.textContent || tempDiv.innerText || '';
  const trimmedContent = textContent.trim();

  // Check for Quill's default empty state which might include <p><br></p> or similar
  const isQuillDefault =
    content === '<p><br></p>' ||
    content === '<p></p>' ||
    content.replace(/\s/g, '') === '<p><br/></p>';

  return trimmedContent === '' || isQuillDefault;
};
