import { useState, useCallback, useEffect } from 'react';
import { useQuery } from 'urql';
import { GetSocialAnalyticsQuery, GetSocialAnalyticsQueryVariables } from '@frontend/gql/social/graphql';
import { getSocialAnalytics } from '../graphql';

type RequiredKeys = Exclude<keyof GetSocialAnalyticsQueryVariables, `include${string}` | 'projectIds' | 'groupIds' | 'segments'>;
type GraphQLQueryVariables = Required<Pick<GetSocialAnalyticsQueryVariables, RequiredKeys>> &
  Partial<Pick<GetSocialAnalyticsQueryVariables, 'projectIds' | 'groupIds' | 'segments'>> & { includeEstimates: boolean };

type IncludeKeys = Exclude<Extract<keyof GetSocialAnalyticsQueryVariables, `include${string}`>, 'includeEstimates'>;
type IncludeOptions = Partial<Pick<GetSocialAnalyticsQueryVariables, IncludeKeys>>;

interface UseAnalyticsDataProps {
  clientId: string | undefined;
  tab: string;
  queryVariables: GraphQLQueryVariables;
  includeOptions?: IncludeOptions;
}

interface UseAnalyticsDataReturn {
  data: GetSocialAnalyticsQuery | undefined;
  fetching: boolean;
  refetching: boolean;
  handleRefresh: () => void;
}

export const useAnalyticsData = ({
  clientId,
  tab,
  queryVariables,
  includeOptions = {},
}: UseAnalyticsDataProps): UseAnalyticsDataReturn => {
  const [refetching, setRefetching] = useState(false);
  const [{ data, fetching }, refetch] = useQuery({
    query: getSocialAnalytics,
    variables: {
      ...queryVariables,
      ...includeOptions,
      // includeUnassigned: !queryVariables?.includeUnassigned || false,
      segments: queryVariables.segments,
    },
    pause: !clientId || tab !== 'members',
  });

  const handleRefresh = useCallback(() => {
    if (tab === 'members') {
      setRefetching(true);
      refetch({ requestPolicy: 'network-only' });
    }
  }, [tab, refetch]);

  useEffect(() => {
    if (!fetching) {
      setRefetching(false);
    }
  }, [fetching]);

  return {
    data,
    fetching,
    refetching,
    handleRefresh,
  };
};
