import * as React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';

interface DateRangeFormPopoverProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  trigger: React.ReactNode;
  children: React.ReactNode;
}

const DateRangeFormPopover = React.forwardRef<HTMLDivElement, DateRangeFormPopoverProps>(
  ({
 open, onOpenChange, trigger, children,
}, ref) => (
  <div ref={ref}>
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent className="p-0 shadow-lg" align="end">
        {children}
      </PopoverContent>
    </Popover>
  </div>
  ),
);

DateRangeFormPopover.displayName = 'DateRangeFormPopover';

export { DateRangeFormPopover };
