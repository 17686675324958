import * as React from 'react';
import {
  Card, Empty, Select, Spinner, Typography,
} from '@revfluence/fresh';
import { Button } from 'antd-v5';
import { BuildingColumnsIcon } from '@revfluence/fresh-icons/solid';
import { useProjectBudgetContext } from '@frontend/app/context/ProjectBudgetContext';
import { useGetBudgetAccounts } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccounts';
import { omit } from 'lodash';
import { useGetBudgetDistributionsForBudgetAccount } from '@frontend/app/hooks/budgetAllocation/useGetBudgetDistributionsForBudgetAccount';
import { useGetBudgetFiscalPeriod } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalPeriod';
import { useGetProjectBudgetDashboardData } from '@frontend/app/hooks/budgetAllocation/useGetProjectBudgetDashboardData';
import {
  DataFormat,
} from '@affiliates/utils';
import { TBudgetFiscalPeriod } from '@frontend/applications/PaymentsApp/types';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import { GetBudgetFiscalSettingsQuery_budgetSettingsResponse } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';
import { IBudgetStat } from './types/IBudgetStat';
import ConnectBudgetAccountDrawer from './components/ConnectBudgetAccountDrawer';
import ConnectBudgetAccountModal from './components/ConnectBudgetAccountModal';
import { BudgetActionMode, IBudget } from './types/IBudget';
import ProjectBudgetTable from './components/ProjectBudgetTable';
import BudgetStatisticsBar from './components/BudgetStatisticsBar';
import styles from './BudgetPage.module.scss';

const { Text, Title } = Typography;
const { Option } = Select;

const { useMemo, useEffect, useState } = React;
interface IProps {
}

const BudgetPage: React.FC<IProps> = () => {
  const [financialYear, setFinancialYear] = useState<TBudgetFiscalPeriod[]>([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState<TBudgetFiscalPeriod | null>(null);
  const [budgets, setBudgets] = useState<IBudget[]>([]);
  const [stats, setStats] = useState<IBudgetStat[]>([]);

  const {
    showModal,
    granularity,
    selectedBudgetId,
    projectId,
    mode,
    setBudgetGranularity,
    setShowModal,
    setShowDrawer,
    setBudgetAccounts,
    setBudgetAccountDistribution,
    setMode,
    selectBudgetId,
    setFiscalYearBudgetBreakDowns,
    setBudgetAccountDistributionConnectedAccount,
  } = useProjectBudgetContext();
  const { budgetAccounts: budgetAccountsForClient, loading: budgetsLoading } = useGetBudgetAccounts({});
  const { loading: budgetFiscalPeriodLoading, budgetFiscalPeriod } = useGetBudgetFiscalPeriod({});
  const { loading: loadingBudgetDistribution, budgetDistributionsForBudgetAccount } = useGetBudgetDistributionsForBudgetAccount({
    variables: {
      budgetAccountId: selectedBudgetId,
    },
    skip: !selectedBudgetId,
    fetchPolicy: 'no-cache',
  });
  const { loading: loadingBudgetDistributionsForConnectedBudgetAccount, budgetDistributionsForBudgetAccount: budgetDistributionsForConnectedBudgetAccount } = useGetBudgetDistributionsForBudgetAccount({
    variables: {
      budgetAccountId: selectedBudgetId,
      programId: parseInt(projectId, 10),
    },
    skip: !selectedBudgetId || !projectId,
    fetchPolicy: 'no-cache',
  });

  const { loading: loadingProjectBudgetDashboardData, projectBudgetDashboardData, refetch } = useGetProjectBudgetDashboardData({
    skip: !selectedFinancialYear || !selectedFinancialYear.fiscalYear,
    variables: selectedFinancialYear && selectedFinancialYear.fiscalYear ? {
      programId: parseInt(projectId, 10),
      fiscalYear: selectedFinancialYear.fiscalYear,
    } : undefined,
    fetchPolicy: 'no-cache',
  });

  const [quarterNames, setQuarterNames] = useState({});
  const { budgetSettingsResponse, loading: fiscalSettingsLoading }: { budgetSettingsResponse: GetBudgetFiscalSettingsQuery_budgetSettingsResponse, loading: boolean } = useGetBudgetFiscalSettings({});

  useEffect(() => {
    setQuarterNames(budgetSettingsResponse?.quarterNames ?? {});
  }, [budgetSettingsResponse, setQuarterNames]);

  useEffect(() => {
    if (!loadingProjectBudgetDashboardData && projectBudgetDashboardData) {
      if (projectBudgetDashboardData?.budgetAccounts?.length) {
        setBudgets(projectBudgetDashboardData.budgetAccounts.map((projectBudget) => ({
          key: projectBudget.budgetId,
          id: projectBudget.budgetId,
          source: projectBudget.budgetName,
          projectBudget: projectBudget.totalAmount,
          totalSpend: projectBudget.spentAmount,
          remainingBudget: projectBudget.availableAmount,
          quarterDistributions: projectBudget.quarterDistributions,
        })));
      } else {
        setBudgets([]);
      }
      const newStats: IBudgetStat[] = [
        {
          value: projectBudgetDashboardData.sourceCount,
          label: 'Budget Accounts',
          dataFormat: DataFormat.Integer,
        },
        {
          value: projectBudgetDashboardData.totalAmount,
          label: "Project's Budget",
          dataFormat: DataFormat.Money,
          prefix: '$',
        },
        {
          value: projectBudgetDashboardData.spentAmount,
          label: 'Spent',
          dataFormat: DataFormat.Money,
          tooltipText: 'The total amount already used from the project’s budget for payments.',
          prefix: '$',
        },
        // will use later
        // {
        //   value: projectBudgetDashboardData?.totalCommittedAmount || 0,
        //   label: 'Total Committed',
        //   dataFormat: DataFormat.Integer,
        //   tooltipText: 'Total Committed',
        //   prefix: '$',
        // },
        // {
        //   value: projectBudgetDashboardData?.unpaidCommittedAmount || 0,
        //   label: 'Unpaid Committed',
        //   dataFormat: DataFormat.Integer,
        //   tooltipText: 'Unpaid Committed',
        //   prefix: '$',
        // },
        {
          value: projectBudgetDashboardData.availableAmount,
          label: 'Available in Project',
          dataFormat: DataFormat.Money,
          tooltipText: 'The remaining budget available for the project after deducting any spent amounts.',
          prefix: '$',
        },
      ];
      setStats(newStats);
    }
  }, [loadingProjectBudgetDashboardData, projectBudgetDashboardData]);
  useEffect(() => {
    if (!budgetFiscalPeriodLoading && budgetFiscalPeriod.length) {
      setFinancialYear(budgetFiscalPeriod);
      setSelectedFinancialYear(budgetFiscalPeriod.find((period) => period.isCurrentYear) || null);
    }
  }, [budgetFiscalPeriodLoading, budgetFiscalPeriod]);
  useEffect(() => {
    if (!budgetsLoading && budgetAccountsForClient.length) {
      const cleanedBudgetAccounts = budgetAccountsForClient.map((account) => omit(account, '__typename'));
      setBudgetAccounts(cleanedBudgetAccounts);
    }
  }, [budgetsLoading, budgetAccountsForClient, setBudgetAccounts]);
  useEffect(() => {
    setFiscalYearBudgetBreakDowns([]);
    if (!loadingBudgetDistribution && budgetDistributionsForBudgetAccount?.length) {
      setBudgetAccountDistribution(budgetDistributionsForBudgetAccount);
    } else {
      setBudgetAccountDistribution([]);
    }
    if (!loadingBudgetDistributionsForConnectedBudgetAccount && budgetDistributionsForConnectedBudgetAccount?.length) {
      setBudgetAccountDistributionConnectedAccount(budgetDistributionsForConnectedBudgetAccount);
    } else {
      setBudgetAccountDistributionConnectedAccount([]);
    }
  }, [
    loadingBudgetDistribution,
    budgetDistributionsForBudgetAccount,
    loadingBudgetDistributionsForConnectedBudgetAccount,
    budgetDistributionsForConnectedBudgetAccount,
    selectedBudgetId,
    setFiscalYearBudgetBreakDowns,
    setBudgetAccountDistribution,
    setBudgetAccountDistributionConnectedAccount,
  ]);

  const emptyBudget = useMemo(() => (
    <>
      <Card bodyStyle={{ padding: '48px 0px' }}>
        <Empty
          size="large"
          image={<BuildingColumnsIcon />}
          description={(
            <>
              <Typography.Title level={5}>
                No budget connected to this project.
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Connecting a project to a campaign budget enables you to track your creators’ payments over time. Connect a campaign budget so you can measure performance in the Project Overview tab.
              </Typography.Paragraph>
            </>
          )}
        />
      </Card>
      <div style={{ height: '24px' }} />
    </>
  ), []);
  const refreshData = () => {
    refetch();
  };
  const pageHeader = (
    <>
      <Card className={styles.budgetPageHeader} bodyStyle={{ padding: '0px' }}>
        <div className={styles.headerPrimaryContainer}>
          <Title level={4}>Budget</Title>
          <div className={styles.headerPrimaryActions}>
            {/* will use later */}
            {/* <Button icon={<ChartPieIcon />} /> */}
            <Button
              type="primary"
              onClick={() => {
                setMode(BudgetActionMode.CREATE);
                setShowDrawer(true);
              }}
            >
              Connect Budget
            </Button>
          </div>
        </div>
        <Text className={styles.headerSecondaryTitle}>View and connect Budget Accounts for this project to track your member's payments over time.</Text>
      </Card>
    </>
  );
  const onClickBudget = async (id: number) => {
      selectBudgetId(id);
      setMode(BudgetActionMode.EDIT);
      setShowDrawer(true);
  };

  if (budgetsLoading || budgetFiscalPeriodLoading || loadingProjectBudgetDashboardData || fiscalSettingsLoading) {
    return <Spinner />;
  }
  if (!financialYear.length) {
    return (
      <div className={styles.budgetPageContainer}>
        <Empty
          description="Please setup budget fiscal year"
        />
      </div>
    );
  }
  const isSingleFiscalYear = financialYear.length === 1 && financialYear[0].fiscalYear.toString() === 'N/A';
  return (
    <div className={styles.budgetPageContainer}>
      {pageHeader}
      <div style={{ height: '24px' }} />
      {
        !!financialYear.length && selectedFinancialYear && (
          <>
            {!isSingleFiscalYear && (
            <div className={styles.budgetActions}>
              <Select value={selectedFinancialYear.fiscalYear} style={{ width: 120 }} onChange={(e) => setSelectedFinancialYear(financialYear.find((fy) => fy.fiscalYear === e))}>
                {
                  financialYear.map((year) => <Option key={year.fiscalYear} value={year.fiscalYear}>{year.fiscalYearLabel}</Option>)
                }
              </Select>
            </div>
            )}
            <div style={{ height: '24px' }} />
            <Card bodyStyle={{ padding: '0px' }}>
              <BudgetStatisticsBar stats={stats} />
              {
                !budgets?.length ? emptyBudget : (
                  <ProjectBudgetTable
                    budgets={budgets}
                    onClickBudget={onClickBudget}
                    quarterNames={quarterNames}
                  />
                )
              }
            </Card>
          </>
        )
      }
      <ConnectBudgetAccountModal
        showModal={showModal}
        setShowModal={setShowModal}
        granularity={granularity}
        setBudgetGranularity={setBudgetGranularity}
        onOk={() => {
          setShowModal(false);
          setShowDrawer(true);
        }}
      />
      <ConnectBudgetAccountDrawer
        title={mode === BudgetActionMode.EDIT ? 'Edit Budget Account' : 'Connect Budget Account'}
        refreshData={refreshData}
        loading={loadingBudgetDistribution || loadingBudgetDistributionsForConnectedBudgetAccount}
        isSingleFiscalYear={isSingleFiscalYear}
      />
    </div>
  );
};
export default BudgetPage;
BudgetPage.displayName = 'BudgetPage';
