import * as React from 'react';
import { InfoCircleFilled, LeftOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import { Button } from '@affiliates/AspireUI';
import { OFFER_PROMO_PREFIX_TYPE } from '@affiliates/types/globalTypes';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './MembersWizard.scss';
import { TSelectedMember } from '../types';

const { useMemo } = React;

interface IProps {
  onClickBack: () => void;
  onDismissWarning: () => void;
  isWorkflow: boolean;
  codeGenerationStrategy: OFFER_PROMO_PREFIX_TYPE,
  isNewFlow?: boolean,
  onClose?: () => void;
  members?: readonly TSelectedMember[] | null;

}

const useStrategyText = (codeGenerationStrategy: OFFER_PROMO_PREFIX_TYPE) => useMemo(() => {
  const text = {
    [OFFER_PROMO_PREFIX_TYPE.FIRST_INITIAL_LAST_NAME]: 'First name and Last name.',
    [OFFER_PROMO_PREFIX_TYPE.FIRST_NAME_LAST_INITIAL]: 'First name and Last name.',
    [OFFER_PROMO_PREFIX_TYPE.FULL_NAME]: 'Full name.',
    [OFFER_PROMO_PREFIX_TYPE.IG_USERNAME]: 'Instagram usernames.',
  };
  const strategyText = codeGenerationStrategy
    ? text[codeGenerationStrategy] : 'required data to generate code.';
  return `All the members don't have ${strategyText}`;
}, [codeGenerationStrategy]);

export const NoMembersWarning: React.FC<Readonly<IProps>> = (props) => {
  const {
    onClickBack,
    onDismissWarning,
    isWorkflow,
    codeGenerationStrategy,
  } = props;
  const showSelectButton = !isWorkflow;
  const strategyText = useStrategyText(codeGenerationStrategy);
  const { isProjects } = useClientFeatures();
  const programLabel = isProjects ? 'Project' : 'Program';
  const NoMemberText = 'All the members are already part of this offer';
  const NoMemberSubText = props.members.length > 0 ? 'You can either give them custom promo codes by clicking the button below or update their data in the Members section of the app.' : 'You have generate promo codes for all the members. Invite or add new members to generate more promo codes.';
  const AddCodeText = props.isNewFlow ? 'Continue to Select members' : 'Add Custom Codes';
  return (
    <div className={styles.membersListWarning}>
      <InfoCircleFilled style={{ color: '#F6CD75', fontSize: 80 }} />
      {
        props.members.length > 0 ? (
          <h2 className={styles.title}>
            {strategyText}
          </h2>
        ) : (
          <h2>{NoMemberText}</h2>
        )
      }
      <p>{NoMemberSubText}</p>
      <div className={styles.buttons}>
        {props.isNewFlow && (
          <Button className={styles.wizardButton} onClick={props.onClose}>
            Close
          </Button>
        )}
        {(showSelectButton && !props.isNewFlow) && (
          <Button className={styles.wizardButton} onClick={onClickBack} type="default">
            <LeftOutlined />
            Select Another
            {' '}
            {capitalize(programLabel)}
          </Button>
        )}
        {props.members.length > 0 && (
          <Button className={styles.wizardButton} onClick={onDismissWarning} type="primary">
            {AddCodeText}
          </Button>
        )}

      </div>
    </div>
  );
};
