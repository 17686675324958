import { graphql } from '@frontend/gql/social';

export const socialAdsAnalyticsFragment = graphql(`
  fragment SocialAdsAnalytics_Query on Query {
    client(id: $clientId) {
      currentInterval: sumInsights(filter: { range: $currentIntervalRange, isAspireSourced: true }) {
        ...TotalSpendCard_CurrentIntervalMetaSumInsights
        ...SalesGeneratedCard_CurrentIntervalMetaSumInsights
        ...RoasCard_CurrentIntervalMetaSumInsights
        ...Clicks_CurrentIntervalMetaSumInsights
        ...Reach_CurrentIntervalMetaSumInsights
        ...Impressions_CurrentIntervalMetaSumInsights
        ...Members_CurrentIntervalMetaSumInsights
        ...Posts_CurrentIntervalMetaSumInsights
      }
      currentIntervalsIntervalInsights: intervalInsights(
        filter: { range: $currentIntervalRange, isAspireSourced: true }
      ) {
        ...AdSpendVsSalesChart_MetaIntervalInsights
        ...RoasOverTimeChart_MetaInsights
      }
      currentIntervalsIntervalInsightsNotAspireSourced: intervalInsights(
        filter: { range: $currentIntervalRange, isAspireSourced: false }
      ) {
        ...AdSpendVsSalesChart_MetaIntervalInsights
        ...RoasOverTimeChart_MetaInsights
      }
      previousInterval: sumInsights(filter: { range: $previousIntervalRange, isAspireSourced: true }) {
        ...TotalSpendCard_PreviousIntervalMetaSumInsights
        ...SalesGeneratedCard_PreviousIntervalMetaSumInsights
        ...RoasCard_PreviousIntervalMetaSumInsights
        ...Clicks_PreviousIntervalMetaSumInsights
        ...Reach_PreviousIntervalMetaSumInsights
        ...Impressions_PreviousIntervalMetaSumInsights
        ...Members_PreviousIntervalMetaSumInsights
        ...Posts_PreviousIntervalMetaSumInsights
      }
      currentIntervalNotAspireSourced: sumInsights(filter: { range: $currentIntervalRange, isAspireSourced: false }) {
        ...TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights
        ...SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsights
        ...RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights
      }
    }
  }
`);

export const insightsByClientId = graphql(`
  query GetInsightsByClientId(
    $clientId: String!
    $currentIntervalRange: RangeInput!
    $previousIntervalRange: RangeInput!
  ) {
    ...SocialAdsAnalytics_Query
  }
`);
