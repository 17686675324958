import * as React from 'react';
import {
  Checkbox,
  Form,
  Space,
} from '@revfluence/fresh';
import { CLIENT_CONNECTION_STATUS, OFFER_PRICE_RULE_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { FormInstance, Input } from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { PROMO_OFFER_TYPES, FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions, OfferFormModes } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { OFFER_FORM_LOCKING_KEY } from '@frontend/applications/AffiliatesApp/types';
import { Tooltip, TooltipTrigger, TooltipContent } from '@frontend/shadcn/components/ui/tooltip';
import { CircleInfoIcon } from '@revfluence/fresh-icons/solid';
import { RefreshMultipleDiscountOptions } from './RefreshMultipleDiscountOptions';

interface IProps {
  disabled: boolean,
  mode: OfferFormModes,
  values: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (value: FormAsyncAction) => void,
  formRef: FormInstance,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
  offerType: string
}
export const RefreshMultipleShopifyStores: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  mode,
  values,
  handleFormAsyncActions,
  formRef,
  connectedAdvertiserForSync,
  offerType,
}) => {
  const clientsForSync = values.clientsForSync;
  const maxClientToSelect: number = 5;
  const rules = [
    {
      validator: (_) => {
        if (clientsForSync.length === 0) {
          return Promise.reject(new Error('Select at least one store to sync the promo codes to. If you want to disable the sync, please toggle off the “Sync Promo Codes” option.'));
        }
        if (clientsForSync.length > maxClientToSelect) {
          return Promise.reject(new Error(`Please select less than ${maxClientToSelect} stores.`));
        }
        return Promise.resolve();
      },
    },
  ];
  const { enableMultipleShopify: isEnabledMultipleShopify } = useClientFeatures();

  const handleCheckboxChnage = (connectedAdvertiserId: number, isChecked: boolean, discountCodeGid?: string) => {
    const currentValues = formRef.getFieldValue('clientsForSync') || [];

    if (discountCodeGid) {
      currentValues.forEach((client) => {
        if (client.advertiserId === connectedAdvertiserId) {
          client.discountCodeGid = discountCodeGid;
        }
      });
      formRef.setFieldsValue({
        clientsForSync: currentValues,
      });
      handleFormAsyncActions({ action: OfferFormAsyncActions.MULTIPLE_STORE_SELECT, payload: { advertiserId: connectedAdvertiserId, isSelected: isChecked, discountCodeGid } });
      return;
    }

    const oldClientIndex = currentValues.findIndex((client) => client.advertiserId === connectedAdvertiserId);
    if (oldClientIndex !== -1) {
      currentValues.splice(oldClientIndex, 1);
    } else {
      currentValues.push({
        advertiserId: connectedAdvertiserId,
        priceRuleAmount: null,
        priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
        status: CLIENT_CONNECTION_STATUS.ACTIVE,
        isSelected: isChecked,
        discountCodeGid: offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER ? discountCodeGid : null,
      });
    }
    formRef.setFieldsValue({
      clientsForSync: currentValues,
    });
    handleFormAsyncActions({ action: OfferFormAsyncActions.MULTIPLE_STORE_SELECT, payload: { advertiserId: connectedAdvertiserId, isSelected: isChecked } });
  };

  return (
    <>
      <Form.Item rules={rules} name="selectedClients">
        <Space direction="vertical" style={{ marginBottom: '8px' }}>
          {connectedAdvertiserForSync.map((client) => {
            const isSelected = clientsForSync.find((c) => client.connectedAdvertiserId === c.advertiserId);
            if (
              (mode === OfferFormModes.Create && client.isConnected) ||
              (mode === OfferFormModes.Edit &&
                (isSelected?.status === CLIENT_CONNECTION_STATUS.ACTIVE || client.isConnected))
            ) {
              return (
                <>
                  <Checkbox
                    checked={!!isSelected?.isSelected}
                    disabled={disabled}
                    key={client.id}
                    onChange={(e) => handleCheckboxChnage(client.connectedAdvertiserId, e.target.checked)}
                  >
                    {client.label}
                  </Checkbox>
                  {isSelected && offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER && (
                    <Form.Item
                      label={
                        <span className="flex items-center gap-2">
                          Enter Shopify Discount Id
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <CircleInfoIcon className="h-3 w-3 text-grey-4 cursor-pointer" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-xs break-words text-center p-4">
                              <span>Enter the Shopify Discount ID for your 'Buy X, Get Y' offer. You can find this ID in the offer URL in your Shopify admin under the Discounts section.</span>
                            </TooltipContent>
                          </Tooltip>
                        </span>
                      }
                    >
                      <Input
                        placeholder="Enter ID of Linked shopify offer"
                        size="large"
                        className="w-full"
                        key={client.id}
                        onChange={(e) => handleCheckboxChnage(client.connectedAdvertiserId, true, e.target.value)}
                      />
                    </Form.Item>
                  )}
                </>
              );
            }
            return null;
          })}
        </Space>
        {isEnabledMultipleShopify && values.isMultipleShopifySyncEnabled && (
          <RefreshMultipleDiscountOptions
            disabled={!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)}
            values={values}
            handleFormAsyncActions={handleFormAsyncActions}
            formRef={formRef}
            connectedAdvertiserForSync={connectedAdvertiserForSync}
          />
        )}
      </Form.Item>
    </>
  );
});
RefreshMultipleShopifyStores.displayName = 'RefreshMultipleShopifyStores';
