import gql from 'graphql-tag';

export const GET_TALENT_AGENTS_BY_AGENT_MEMBER_IDS_QUERY = gql`
  query GetTalentAgentsByAgentMemberIds($memberIds: [Int!]!) {
    talentAgents: talentAgentsByAgentMemberIds(memberIds: $memberIds) {
      id
      name
      email
      agentMemberId
      clientId
      createdDate
      updatedDate
    }
  }
`;
