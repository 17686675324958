import gql from 'graphql-tag';

import { WORK_ITEM_FRAGMENT } from './fragments';

export const GET_WORK_ITEMS = gql`
  query GetWorkItemsQuery($programId: Int!, $specUri: String, $taskId: String) {
    workItems: getAllWorkItems(programId: $programId, specUri: $specUri, taskId: $taskId) {
      ...WorkItemFragment
    }
  }
  ${WORK_ITEM_FRAGMENT}
`;

export const GET_FLEX_COMPLETED_MEMBER_IDS = gql`
  query GetFlexCompletedMemberIdsQuery($programId: Int!, $specKey: String) {
    flexCompletedMemberIds: getFlexMemberIds(programId: $programId, specKey: $specKey)
  }
`;

export const GET_NO_DELIVERABLE_MEMBER_IDS = gql`
  query GetNoDeliverableMemberIdsQuery($programId: Int!, $specKey: String) {
    noDeliverableMemberIds: getNoDeliverableMemberIds(programId: $programId, specKey: $specKey)
  }
`;
