import { DataTable } from '@frontend/app/refresh-components';
import { ColumnDef, PaginationState, Row, SortingFn, SortingState } from '@tanstack/react-table';
import React from 'react';
import { get } from 'lodash';
import { Member, MemberInsights } from '@frontend/gql/social/graphql';
import { CircleInfoIcon } from '@revfluence/fresh-icons/solid/esm';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemValue,
  TooltipArrow,
  TooltipContent as CustomTooltipContent,
} from '../../../components/tooltip';
import MemberColumn from './columnDefs/Member';
import { selectedColumnsToColumnDefInput } from './utils/selectedColumnsToColumnDef';
import Instagram from './columnDefs/Instagram';
import Tags from './columnDefs/Tags';
import Generic from './columnDefs/Generic';
import Currency from './columnDefs/Currency';
import Percentage from './columnDefs/Percentage';
import Skeleton from './columnDefs/Skeleton';
import ActivePosts from './columnDefs/ActivePosts';

export enum RowType {
  DEFAULT,
  MEMBER,
  INSTAGRAM_HANDLE,
  SOCIAL_HANDLES,
  CURRENCY,
  PERCENTAGE,
}

export interface MappedColumn {
  id: string;
  enableSorting: boolean;
  enableGlobalFilter: boolean;
  sortingFn: SortingFn<MemberInsights>,
  meta: {
    greyBackground?: true;
    headerGroupBorder: {
      borderColor?: string;
    };
    onToggleClick: (() => void) | null;
    isColumnExpanded?: boolean;
    isExpandableColumn?: boolean;
    draggable?: boolean;
  };
  header: string;
  rowType: RowType;
  size?: number;
  minSize?: number;
  maxSize?: number;
  accessorFn?: (row) => unknown;
  isSubColumn?: boolean;
  tooltip?: string;
}

export interface ColumnDefinitionInput {
  header: string;
  id: string;
  enableSorting: boolean;
  enableGlobalFiltering?: boolean;
  hidden: boolean;
  borderColor?: string;
  greyBackground?: boolean;
  onToggleClick?: () => void;
  isColumnExpanded?: boolean;
  isExpandableColumn?: boolean;
  size?: number;
  minSize?: number;
  maxSize?: number;
  rowType: RowType;
  subColumns?: ColumnDefinitionInput[];
  order?: number;
  isFixed?: boolean;
  accessorFn?: (row) => unknown;
  tooltip?: string;
}

interface MemberTableProps {
  selectedColumns: ColumnDefinitionInput[];
  globalFilter?: string | number;
  handleExpandColumn(columndId: string): void;
  handleDragEnd(fromIndex: number, toIndex: number, mappedColumnData: MappedColumn[]): void;
  data: MemberInsights[];
  paginationState: PaginationState;
  handleSortingChange: (sorting: SortingState) => void;
  sorting: SortingState;
  updatingColumns: string[];
}

const TOOLTIP_CONTENT = {
  'total.activePosts': 'Number of posts with impressions or interactions within the selected date range.',
  'total.engagementRage': 'Total engagement divided by total impressions (including video views)',
  'total.followerGrowth': " Increase in members' social media followers for posts attributed to branded posts.",
  'total.latestPostDate': 'Date of the most recent post within the selected date range.',
  'total.latestUpdateDate': 'Date and time of the last metrics update for members.',
  'total.numberOfPosts': 'Number of posts created within the selected date range.',
  'total.potentialReach':
    "Total follower count of members' social accounts at the time mentioned your brand in a post.",
};

function getSocialHandles(member: Member): string[] {
  const handles: { [key: string]: keyof Member } = {
    Instagram: 'instagramUsername',
    TikTok: 'tiktokUsername',
    YouTube: 'youtubeUsername',
    Pinterest: 'pinterestUsername',
  };

  return Object.entries(handles)
    .filter(([_, key]) => member[key])
    .map(([platform]) => platform);
}

const MemberTable: React.FC<MemberTableProps> = ({
  selectedColumns,
  handleExpandColumn,
  handleDragEnd,
  data: memberInsights,
  paginationState,
  handleSortingChange,
  sorting,
  globalFilter,
  updatingColumns,
}) => {
  const data = selectedColumnsToColumnDefInput(selectedColumns, handleExpandColumn);
  const columns: ColumnDef<MemberInsights>[] = data.map((data: MappedColumn) => {
    return {
      ...data,
      accessorKey: data.id,
      header: () => {
        const tooltipContent = TOOLTIP_CONTENT[data.id];

        // if we have a tooltip content, we need to render a tooltip
        return (
          <div
            className="font-[system-ui] text-[#1F1F21] flex items-center gap-2"
            role="columnheader"
            aria-sort={
              sorting.find((s) => s.id === data.id)?.desc
                ? 'descending'
                : sorting.find((s) => s.id === data.id)
                ? 'ascending'
                : 'none'
            }
          >
            {data.header}
            {tooltipContent && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <CircleInfoIcon className="h-4 w-4 text-muted-foreground" data-dd-action-name="member-table-header-tooltip-click" />
                  </TooltipTrigger>
                  <CustomTooltipContent>
                    <TooltipHeader>
                      <TooltipTitle>{data.header}</TooltipTitle>
                    </TooltipHeader>
                    <TooltipBody>
                      <TooltipItem>
                        <TooltipItemValue>{tooltipContent}</TooltipItemValue>
                      </TooltipItem>
                    </TooltipBody>
                    <TooltipArrow />
                  </CustomTooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        );
      },
      cell: ({ row }) => {
        if (updatingColumns.includes(data.id)) {
          return (
            <div className="px-2 py-1">
              <Skeleton height="24px" />
            </div>
          );
        }
        return _renderRow(data.rowType, row, data);
      },
    };
  });

  const _renderRow = (type: RowType, row: Row<MemberInsights>, data: MappedColumn) => {
    const member = row.original.member;
    if (!member) return null;

    switch (type) {
      case RowType.MEMBER: {
        return (
          <MemberColumn
            name={member.name || ''}
            image={member.profilePicture || undefined}
            url={`/members/${row.original.memberId}`}
            aria-label={`Member: ${member.name || 'Unknown'}`}
            data-dd-action-name="member-table-member-click"
          />
        );
      }
      case RowType.PERCENTAGE: {
        const percentValue = get(row.original, data.id);
        return <Percentage value={percentValue} aria-label={`${data.header}: ${percentValue || 'Not available'}`} />;
      }
      case RowType.INSTAGRAM_HANDLE: {
        return (
          <Instagram
            handle={member.instagramUsername || ''}
            isVerified
            aria-label={`Instagram handle: ${member.instagramUsername || 'Not set'}`}
          />
        );
      }
      case RowType.SOCIAL_HANDLES: {
        const socialHandles = getSocialHandles(member);
        return (
          <Tags
            labels={socialHandles}
            maxTags={2}
            aria-label={`Social networks: ${socialHandles.length ? socialHandles.join(', ') : 'None'}`}
          />
        );
      }
      case RowType.CURRENCY: {
        const value = get(row.original, data.id);
        return <Currency value={value} aria-label={`${data.header}: ${value || 0}`} />;
      }
      default: {
        const genericValue = get(row.original, data.id);
        // Special handling for active posts column
        if (data.id === 'total.activePosts') {
          return (
            <ActivePosts
              value={genericValue}
              memberId={row.original.memberId}
              aria-label={`${data.header}: ${genericValue || 'Not available'}`}
            />
          );
        }
        return <Generic value={genericValue} aria-label={`${data.header}: ${genericValue || 'Not available'}`} />;
      }
    }
  };

  return (
    <DataTable
      paginationState={paginationState}
      onColumnDragEnd={(fromIndex, toIndex) => handleDragEnd(fromIndex, toIndex, data)}
      columnPinning={{
        left: ['member'],
      }}
      bordered
      draggable
      paginated
      sortable
      filterable
      globalFilter={globalFilter}
      onSortingChange={(updaterOrValue) => {
        const newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
        handleSortingChange(newSorting);
      }}
      sorting={sorting}
      columns={columns}
      wrapperClassName="bg-primary-foreground"
      data={memberInsights}
      maxHeight="calc(100vh - 116px)"
    />
  );
};

export default MemberTable;
