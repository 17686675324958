import { isNull } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';
import { XIcon } from 'lucide-react';
import { P } from '@frontend/shadcn/components/typography/p';
import { Alert, AlertDescription } from '@frontend/shadcn/components/ui/alert';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { CircleInfoIcon } from '@revfluence/fresh-icons/solid';
import { PROMO_OFFER_TYPES } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { RefreshOfferCodeGroupName } from './components/RefreshOfferCodeGroupName';
import { RefreshOfferCodeOption } from './components/RefreshOfferCodeOption';
import { RefreshOfferCodePrefix } from './components/RefreshOfferCodePrefix';
import { RefreshOfferCodeSuffix } from './components/RefreshOfferCodeSuffix';
import { RefreshAddPrefix } from './components/RefreshAddPrefix';
import { RefreshAddSuffix } from './components/RefreshAddSuffix';

const RefreshNamingStrategy = ({ values, disabled, mode }) => {
  const [addSuffixChecked, setAddSuffixChecked] = useState(values.codeSuffix !== '');
  const [addPrefixChecked, setAddPrefixChecked] = useState(
    !(values.codePrefix === '' || values.codePrefix === undefined || values.codePrefix === null),
  );
  // TODO: MAKE THIS TRUE once we have functionality in place
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const codeSuffix = useMemo(() => {
    if (addSuffixChecked) {
      values.isSuffixSelected = true;
      return (
        <div className="w-1/2 ml-4 mt-2">
          <RefreshOfferCodeSuffix disabled={disabled.codeSuffix} name="codeSuffix" required />
        </div>
      );
    } else {
      // values.codeSuffix = '';
      values.isSuffixSelected = false;
    }
    return null;
  }, [addSuffixChecked, disabled.codeSuffix, values]);

  const codePrefix = useMemo(() => {
    if (addPrefixChecked) {
      values.isPrefixSelected = true;
      return (
        <div className="w-1/2 ml-4 mt-2">
          <RefreshOfferCodePrefix disabled={disabled.codePrefix} name="codePrefix" />
        </div>
      );
    } else {
      values.isPrefixSelected = false;
      // values.codePrefix = '';
    }
    return null;
  }, [addPrefixChecked, disabled.codePrefix, values]);

  const enablePrifxAndSuffix = useMemo(() => {
    if (values.prefixType) {
      return false;
    }
    return true;
  }, [values.prefixType]);
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="links-section">
        <AccordionTrigger className="hover:no-underline p-0 mt-2">
          <div className="flex flex-col gap-1">
            <P className="text-grey-6" fontWeight="font-medium">Naming Strategy</P>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <P className="text-sm text-grey-4">Select the naming strategy for the promo codes</P>

          {isAlertVisible && (
            <Alert className="flex items-start gap-2 bg-grey-2 border-grey-5 rounded-2xl mt-3">
              <CircleInfoIcon className="h-4 w-4 text-grey-1" />
              <AlertDescription className="flex-1 text-xs text-grey-6 leading-5">
                By default, we'll add a random three-digit number to promo codes to ensure each creator receives a
                unique code. If you'd like to customize this, please adjust the settings below to suit your preferences.
              </AlertDescription>
              <div
                className="h-3 w-3 pr-3 my-3 hover:bg-transparent cursor-pointer"
                onClick={() => setIsAlertVisible(false)}
              >
                <XIcon className="h-4 w-4 text-grey-6" />
              </div>
            </Alert>
          )}

          <div className="flex flex-col gap-3 mt-3">
            {/* Group Name - 50% width */}
            <div className="w-1/2">
              <label className="flex items-center gap-2 mb-2 text-sm font-medium text-grey-6">

                Promo Code Shopify Group Name
                <Tooltip>
                  <TooltipTrigger asChild>
                    <CircleInfoIcon className="h-3 w-3 text-grey-4 cursor-pointer" />
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs break-words text-center p-4">
                    <span>
                      Promo codes generated in this offer will be added to a Shopify discount group with this name. If
                      using custom dates per code, each code will have its own discount group.
                    </span>
                  </TooltipContent>
                </Tooltip>
              </label>
              <div className="w-full">
                <RefreshOfferCodeGroupName name="groupName" disabled={values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER} />
              </div>
            </div>

            <div className="w-1/2">
              <RefreshOfferCodeOption halfRow={!isNull(codeSuffix)} name="prefixType" mode={mode} />
            </div>

            {/* Prefix/Suffix Checkboxes with aligned info icons */}
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-6">
                <div className="flex-1">
                  <div className="flex items-center gap-1">
                    <RefreshAddPrefix
                      name="codePrefix"
                      disabled={enablePrifxAndSuffix || disabled.codePrefix}
                      onAddPrefixChange={setAddPrefixChecked}
                      checked={addPrefixChecked}
                    />
                  </div>
                  {codePrefix}
                </div>

                <div className="flex-1">
                  <div className="flex items-center gap-1">
                    <RefreshAddSuffix
                      name="codeSuffix"
                      disabled={enablePrifxAndSuffix || disabled.codeSuffix}
                      onAddSuffixChange={setAddSuffixChecked}
                      checked={addSuffixChecked}
                    />
                  </div>
                  {codeSuffix}
                </div>
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default RefreshNamingStrategy;
