import { LandingPageData } from '../context/LandingPageContext';

const KEY_FIELDS = [
  // Basic section
  'basic.title',
  'basic.description',
  'basic.creatorsTag.showCreatorTag',
  'basic.creatorsTag.title',
  'basic.featuredProductDiscount.addHeroFeatureProduct',
  'basic.featuredProductDiscount.showProductRating',
  'basic.featuredProductDiscount.showTag',
  'basic.discountOfferButton.title',

  // Featured Content
  'featuredContent.isFeaturedContentEnabled',
  'featuredContent.urls',
  'featuredContent.settings.allowCreatorToUpload',
  'featuredContent.settings.noOfContentForCreator',

  // Featured Products
  'featureProduct.id',
  'featureProduct.title',
  'featureProduct.description',
  'featureProduct.imageUrl',
  'featureProduct.price',

  // Products
  'products.isFeaturedProductEnable',
  'products.featuredProductHeading',
  'products.showProductRating',
  'products.showTag',
  'products.allProducts.products',
  'products.allProducts.style',
  'products.setting.allowCreatorToAddProducts',
  'products.setting.allowCreatorToUpdateFeaturedProduct',
  'products.setting.noOfProductsCreatorCanAdd',
  'products.setting.creatorProductSelectionType',
  'products.setting.sectionHeading',

  // Style settings
  'style.buttons.outlineButton',
  'style.buttons.solidButton',
  'style.backgroundSetting',
  'style.productCard',
  'style.typography',
] as const;

export function hasContentChanged(current: LandingPageData | null, initial: LandingPageData | null): boolean {
  if (!initial || !current) return true;

  return KEY_FIELDS.some((field) => {
    const currentValue = field.split('.').reduce((obj, key) => obj?.[key], current);
    const initialValue = field.split('.').reduce((obj, key) => obj?.[key], initial);
    return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
  });
}
