import 'css-chunk:src/app/containers/Projects/ProjectsPage/ProspectsList/ProspectsList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "spacing-xxxl": "80px",
  "spacing-xxl": "48px",
  "spacing-xl": "32px",
  "spacing-lg": "24px",
  "spacing-md": "16px",
  "spacing-sm": "12px",
  "spacing-xs": "8px",
  "spacing-xxs": "4px",
  "flex": "_flex_db56w_157",
  "justify-content-space-between": "_justify-content-space-between_db56w_161",
  "tabular-nums": "_tabular-nums_db56w_165",
  "ListDrawer": "_ListDrawer_db56w_355",
  "title": "_title_db56w_355",
  "controls": "_controls_db56w_355",
  "settingsIcon": "_settingsIcon_db56w_355",
  "divider": "_divider_db56w_380",
  "loading": "_loading_db56w_383",
  "drawer": "_drawer_db56w_386",
  "tableWrapper": "_tableWrapper_db56w_408",
  "loadSpinner": "_loadSpinner_db56w_412",
  "empty": "_empty_db56w_415",
  "filterList": "_filterList_db56w_421",
  "membersTable": "_membersTable_db56w_426",
  "tableHeader": "_tableHeader_db56w_441",
  "bodyRow": "_bodyRow_db56w_451",
  "checkboxCell": "_checkboxCell_db56w_454",
  "nameHeaderCell": "_nameHeaderCell_db56w_466",
  "singleCTAButton": "_singleCTAButton_db56w_469",
  "headerCell": "_headerCell_db56w_474",
  "memberDetailsDrawer": "_memberDetailsDrawer_db56w_517",
  "content": "_content_db56w_521",
  "show": "_show_db56w_1"
};