import 'css-chunk:src/app/containers/Public/Onboarding/SignupForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_xbw8h_157",
  "justify-content-space-between": "_justify-content-space-between_xbw8h_161",
  "tabular-nums": "_tabular-nums_xbw8h_165",
  "Submitted": "_Submitted_xbw8h_178",
  "moreInfo": "_moreInfo_xbw8h_178",
  "submitted": "_submitted_xbw8h_178",
  "SignupForm": "_SignupForm_xbw8h_188",
  "label": "_label_xbw8h_188",
  "bold": "_bold_xbw8h_188",
  "checkbox": "_checkbox_xbw8h_198",
  "checkboxLabel": "_checkboxLabel_xbw8h_198",
  "required": "_required_xbw8h_242",
  "input": "_input_xbw8h_245",
  "dateField": "_dateField_xbw8h_246",
  "social": "_social_xbw8h_250",
  "socialPrefix": "_socialPrefix_xbw8h_253",
  "unpaidCollaborationCheckbox": "_unpaidCollaborationCheckbox_xbw8h_271",
  "unpaidDescriptionLabel": "_unpaidDescriptionLabel_xbw8h_278",
  "error": "_error_xbw8h_304",
  "copyright": "_copyright_xbw8h_327",
  "supportEmail": "_supportEmail_xbw8h_334",
  "talentAgentSection": "_talentAgentSection_xbw8h_339",
  "show": "_show_xbw8h_1"
};