import React, { useMemo } from 'react';
import {
 IColumnsType, Input, Space, Spinner, Tooltip, Typography, Button, Modal,
} from '@revfluence/fresh';
import { TableHeader } from '@frontend/applications/AffiliatesApp/components';
import { pluralize } from '@frontend/app/utils/strings';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { filter, includes } from 'lodash';
import { GetBrandCatalogsQuery_getBrandCatalogs } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetBrandCatalogsQuery';
import { LockIcon, TrashIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { CatalogType, Source } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GetStatsForAllCatalogs_getStatsForAllCatalogs } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetStatsForAllCatalogs';
import { FileCsvIcon } from '@revfluence/fresh-icons/solid/esm';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { useDeleteBrandCatalogById } from '@frontend/applications/ProductFulfillmentApp/hooks/useDeleteBrandCatalogById';
import { useMessagingContext } from '@frontend/hooks';
import { ICatalog, ICatalogRow } from '../types';
import { CatalogStatusRenderer } from '../../components/CatalogStatusRenderer/CatalogStatusRenderer';
import { getPaginationProps } from '../../utils';

const { Text } = Typography;

const PAGE_SIZE = 50;

export interface IUseTableProps {
  catalogs: GetBrandCatalogsQuery_getBrandCatalogs[];
  allCatalogStats: GetStatsForAllCatalogs_getStatsForAllCatalogs[];
  isAllCatalogStatsLoading: boolean;
  searchText: string;
  setSearchText: (value: string) => void;
  refetchCatalog: () => void;
  refetchAllCatalogStats: () => void;
}

export const useTableProps = ({
 catalogs, allCatalogStats, searchText, setSearchText, isAllCatalogStatsLoading,
 refetchCatalog,
 refetchAllCatalogStats,
}: IUseTableProps) => {
  const {
    showMessage,
  } = useMessagingContext();
  const { deleteBrandCatalogById, isDeletingBrandCatalogById } = useDeleteBrandCatalogById({
    onCompleted: () => {
      showMessage({
        type: 'success',
        content: 'Catalog deleted successfully',
      });
      refetchCatalog();
      refetchAllCatalogStats();
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        content: error?.message || 'Failed to delete catalog',
      });
    },
  });

  const columnConfig = useMemo<IColumnsType<ICatalogRow>>(
    () => [
      {
        title: 'Catalogs',
        dataIndex: '_raw',
        key: 'title',
        align: 'left',
        width: 375,
        ellipsis: {
          showTitle: false,
        },
        render: (catalog: ICatalog) => (
          <Space>
            <Text>{catalog.title}</Text>
            {catalog.type === CatalogType.DEFAULT && (
              <Tooltip
                title={`This is a system generated
            default catalog. You can not
            add or remove products
            from this.`}
              >
                <LockIcon />
              </Tooltip>
            )}
          </Space>
        ),
      },
      {
        title: 'Products',
        dataIndex: '_raw',
        key: 'productsCount',
        width: 185,
        align: 'left',
        render: ({ productsCount, isAllCatalogStatsLoading }: ICatalog) => {
          if (isAllCatalogStatsLoading) {
            return <Spinner size="small" />;
          }
          return productsCount;
        },
      },
      {
        title: 'Variants',
        dataIndex: '_raw',
        key: 'variantsCount',
        width: 185,
        align: 'left',
        render: ({ variantsCount, isAllCatalogStatsLoading }: ICatalog) => {
          if (isAllCatalogStatsLoading) {
            return <Spinner size="small" />;
          }
          return variantsCount;
        },
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        align: 'left',
        width: 92,
        render: (source) => {
          if (source === Source.CSV) {
            return <FileCsvIcon />;
          }
          return <ShopifyIcon fontSize={16} fill="--var(gray-9)" />;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: 185,
        render: CatalogStatusRenderer,
      },
      {
        title: 'Actions',
        dataIndex: '_raw',
        key: 'actions',
        align: 'left',
        width: 80,
        render: (catalog: ICatalog) => {
          if (catalog.type === CatalogType.DEFAULT) {
            return null;
          }
          return (
            <Tooltip title="Delete catalog">
              <Button
                danger
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.confirm({
                    title: 'Are you sure you want to delete this catalog?',
                    icon: <TriangleExclamationIcon />,
                    okText: 'Yes',
                    okType: 'danger',
                    onOk: () => {
                      deleteBrandCatalogById({ variables: { id: Number(catalog.id) } });
                    },
                  });
                }}
                loading={isDeletingBrandCatalogById}
                icon={<TrashIcon />}
                size="small"
              />
            </Tooltip>
          );
        },
      },
    ],
    [deleteBrandCatalogById, isDeletingBrandCatalogById],
  );

  const tableData = useMemo<ICatalogRow[]>(
    () =>
      catalogs.map((catalog) => {
        const stats = allCatalogStats?.find((stat) => stat.brandCatalogId === catalog.id);
        const transformedData: ICatalog = {
          key: String(catalog.id),
          id: String(catalog.id),
          title: catalog.name,
          productsCount: stats?.productCount || 0,
          variantsCount: stats?.variantCount || 0,
          status: catalog.status,
          type: catalog.type,
          isAllCatalogStatsLoading,
        };
        const catalogRow: ICatalogRow = {
          ...transformedData,
          _raw: transformedData,
        };
        return catalogRow;
      }),
    [allCatalogStats, catalogs, isAllCatalogStatsLoading],
  );

  const filteredData = useMemo(() => {
    if (!searchText) {
      return tableData;
    }
    return filter(tableData, (item: ICatalogRow) => includes(item.title.toLowerCase(), searchText.toLowerCase()));
  }, [tableData, searchText]);

  return {
    columnConfig,
    tableData: filteredData,
    pagination: getPaginationProps({ total: catalogs.length, pageSize: PAGE_SIZE }),
    headerActions: <TableHeader renderCount={() => `${catalogs.length} ${pluralize(catalogs.length, 'Catalog')}`} />,
    searchBox: (
      <Input
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        prefix={<MagnifyingGlassIcon />}
      />
    ),
  };
};
