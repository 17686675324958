import { logger } from '@common';
import { P } from '@frontend/shadcn/components/typography/p';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card } from '@frontend/shadcn/components/ui/card';
import { useToast } from '@frontend/shadcn/hooks/use-toast';
import { cn } from '@frontend/shadcn/lib/utils';
import { CopyIcon } from '@revfluence/fresh-icons/regular/esm';
import React from 'react';

export enum PriceRuleType {
  PERCENT = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}
export interface TOfferPromo {
  promoCode?: string;
  payout?: number;
  cps?: number;
  conversions?: number;
  lastPayoutDate?: string;
  salesAmount?: number;
  averageSalesAmount?: number;
  offerName?: string;
  saleAmount?: number;
  avgSaleAmount?: number;
  discountType?: PriceRuleType;
  discountAmount?: number;
}

const OfferPromoCard: React.FC<TOfferPromo> = (props) => {
  const {
    offerName,
    promoCode,
    conversions,
    payout,
    cps,
    avgSaleAmount,
    saleAmount,
    discountAmount,
    discountType,
  } = props;
  const { toast } = useToast();
  const handleCopy = async () => {
    if (!promoCode) return;
    try {
      await navigator.clipboard.writeText(promoCode);
      toast({
        variant: 'success',
        title: 'Copied to clipboard',
        duration: 1000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
    } catch (err) {
      logger.error('Error copying promo code', err);
    }
  };

  return (
    <Card className="p-4 bg-transparent rounded-lg flex flex-col gap-2">
      <div className="text-base font-medium">{offerName}</div>
      <div className="flex flex-row gap-2 items-center">
        <P className="text-sm font-normal text-grey-6">
          {promoCode.length > 20 ? `${promoCode.slice(0, 20)}...` : promoCode}
        </P>
        <Button variant="outline" size="icon" className="w-7 h-7 rounded-md ml-1" onClick={handleCopy}>
          <CopyIcon className="w-4 h-4" />
        </Button>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Customer Discount</P>
        <P className="text-sm font-normal">
          {
            discountAmount && discountType
            ? `${discountType === PriceRuleType.PERCENT ? `${discountAmount}% off` : `$${discountAmount} off`}`
            : '-'
          }
        </P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Conversions</P>
        <P className="text-sm font-normal">{conversions || 0}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Sales Amount</P>
        <P className="text-sm font-normal">{`$${saleAmount?.toFixed(2) || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Average Sale</P>
        <P className="text-sm font-normal">{`$${avgSaleAmount?.toFixed(2) || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Payout</P>
        <P className="text-sm font-normal">{`$${payout?.toFixed(2) || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">CPS</P>
        <P className="text-sm font-normal">{`$${cps?.toFixed(2) || 0}`}</P>
      </div>
    </Card>
  );
};

export default OfferPromoCard;
