import { Button } from '@revfluence/fresh';
import * as React from 'react';
import {
  isFunction,
} from 'lodash';
import { CloseIcon } from '@components';

import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';

import { IFilter } from './model';

import styles from './FilterItem.scss';
import { useFilterItemRenderValue } from './useFilterItemRenderValue';

const { useMemo } = React;

interface IProps {
  disabled?: boolean;
  fields: IField[];
  filter: IFilter;
  onClick?(filter: IFilter, e: React.MouseEvent<HTMLElement, MouseEvent>): void;
  onClickRemove?(filter: IFilter): void;
}

export const FilterItem: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    disabled,
    fields,
    filter,
    onClick,
    onClickRemove,
  } = props;

  const handleClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isFunction(onClickRemove) && !disabled) {
      onClickRemove(filter);
    }
  };

  const renderedValue = useFilterItemRenderValue(filter, fields);

  const buttonIcon = useMemo(() => {
    if (disabled) {
      return null;
    }

    return (
      <span
        className="anticon"
        role="img"
      >
        <CloseIcon
          onClick={handleClickRemove}
          size={12}
        />
      </span>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, filter]);

  return (
    <Button
      className={styles.FilterItem}
      disabled={disabled}
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onClick(filter, e)}
      icon={buttonIcon}
    >
      {renderedValue}
    </Button>
  );
});

FilterItem.displayName = 'FilterItem';
