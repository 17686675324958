/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_axxph_157 {
  display: flex;
}

._justify-content-space-between_axxph_161 {
  justify-content: space-between;
}

._tabular-nums_axxph_165 {
  font-variant-numeric: tabular-nums;
}

._Favorite_axxph_169 ::selection, ._Invite_axxph_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_axxph_514 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Favorite_axxph_169, ._Invite_axxph_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Favorite_axxph_169 ::-webkit-scrollbar, ._Invite_axxph_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Favorite_axxph_169 ::-webkit-scrollbar-track, ._Invite_axxph_169 ::-webkit-scrollbar-track, ._Favorite_axxph_169 ::-webkit-scrollbar-track-piece, ._Invite_axxph_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Favorite_axxph_169 ::-webkit-scrollbar-thumb, ._Invite_axxph_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_axxph_157 {
  display: flex;
}

._justify-content-space-between_axxph_161 {
  justify-content: space-between;
}

._tabular-nums_axxph_165 {
  font-variant-numeric: tabular-nums;
}

._Favorite_axxph_169 ::selection, ._Invite_axxph_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_axxph_514 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Favorite_axxph_169, ._Invite_axxph_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Favorite_axxph_169 ::-webkit-scrollbar, ._Invite_axxph_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Favorite_axxph_169 ::-webkit-scrollbar-track, ._Invite_axxph_169 ::-webkit-scrollbar-track, ._Favorite_axxph_169 ::-webkit-scrollbar-track-piece, ._Invite_axxph_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Favorite_axxph_169 ::-webkit-scrollbar-thumb, ._Invite_axxph_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Invite_axxph_169 {
  min-width: 10rem;
  max-width: 20rem;
  height: 2.5rem;
  display: flex;
  flex-flow: row nowrap;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_axxph_169._isFetching_axxph_416 {
  opacity: 0.6;
  cursor: wait;
}
._Invite_axxph_169._isFetching_axxph_416 * {
  pointer-events: none;
}
._Invite_axxph_169 ._loading_axxph_423 {
  pointer-events: none;
  border-radius: 0.5rem !important;
}
._Invite_axxph_169._hideButton_axxph_427:not([class*=withButton]) ._select_axxph_427 {
  width: 100%;
  max-width: 100%;
}
._Invite_axxph_169._hideButton_axxph_427:not([class*=withButton]) ._inviteButton_axxph_431, ._Invite_axxph_169._hideButton_axxph_427:not([class*=withButton]) ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._Invite_axxph_169._hideButton_axxph_427:not([class*=withButton]) ._toggleButton_axxph_431 {
  width: 0;
  min-width: 0;
  max-width: 0;
  opacity: 0;
}
._Invite_axxph_169._withButton_axxph_437 {
  min-width: 20rem;
}
._Invite_axxph_169._withButton_axxph_437 ._select_axxph_427 {
  width: calc(100% - 5rem);
  max-width: calc(100% - 5rem);
}
._Invite_axxph_169._withButton_axxph_437 ._select_axxph_427 ._selectButton_axxph_444 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
._Invite_axxph_169._withBorder_axxph_448 ._select_axxph_427 ._selectButton_axxph_444 {
  border: 0.0625rem solid #dadcde;
}
._Invite_axxph_169._withBorder_axxph_448._withButton_axxph_437 ._select_axxph_427 ._selectButton_axxph_444 {
  border-right: 0;
}
._Invite_axxph_169 ._select_axxph_427 {
  width: calc(100% - 5rem);
  max-width: calc(100% - 5rem);
  height: 100%;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_axxph_169 ._select_axxph_427 ._selectButton_axxph_444 {
  padding-left: 1rem;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_axxph_169 ._select_axxph_427 ._selectButton_axxph_444._isFocused_axxph_473 {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
._Invite_axxph_169 ._select_axxph_427 ._selectButton_axxph_444 > [class*=arrow] {
  padding: 0 1rem 0 0.5rem;
}
._Invite_axxph_169 ._inviteButton_axxph_431, ._Invite_axxph_169 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._Invite_axxph_169 ._toggleButton_axxph_431 {
  padding-left: 0;
  padding-right: 0;
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-sizing: border-box;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_axxph_169 ._inviteButton_axxph_431 > [class*=label], ._Invite_axxph_169 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._toggleButton_axxph_431 > [class*=label], ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._Invite_axxph_169 ._toggleButton_axxph_431 > [class*=label] {
  font-weight: 500;
  letter-spacing: 0.03125rem;
}
._Invite_axxph_169 ._inviteButton_axxph_431._inviting_axxph_496, ._Invite_axxph_169 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._inviting_axxph_496._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._Invite_axxph_169 ._inviting_axxph_496._toggleButton_axxph_431 {
  cursor: wait;
}
._Invite_axxph_169 ._inviteButton_axxph_431._wait_axxph_499, ._Invite_axxph_169 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._wait_axxph_499._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._Invite_axxph_169 ._wait_axxph_499._toggleButton_axxph_431 {
  opacity: 0.8;
  cursor: wait;
}
._Invite_axxph_169 ._inviteButton_axxph_431._disabled_axxph_503, ._Invite_axxph_169 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._disabled_axxph_503._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._Invite_axxph_169 ._disabled_axxph_503._toggleButton_axxph_431 {
  cursor: default;
}

._popover_axxph_507 {
  width: 20rem;
  overflow: hidden;
}
._popover_axxph_507 ._popoverContentWrapper_axxph_511 {
  border-radius: 0.5rem;
}
._popover_axxph_507._shown_axxph_514 ._message_axxph_514 span {
  pointer-events: initial;
}

._popover_axxph_507 ._popoverContent_axxph_511 {
  max-height: 15.625rem;
  width: 100%;
  overflow-x: hidden;
}

._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 {
  margin: 0;
  padding: 0.25rem 0.75rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 > [class*=label] {
  height: 100%;
  flex: 1;
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 > [class*=actions] {
  right: 0.25rem;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._noSelect_axxph_544 {
  pointer-events: none;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._communityOption_axxph_547, ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._optionTitle_axxph_547 {
  height: 2.5rem;
  color: #8f8d91;
  font-size: 0.6875rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._communityOption_axxph_547:not(:first-of-type), ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._optionTitle_axxph_547:not(:first-of-type) {
  margin-top: 0.75rem;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._programOption_axxph_557 {
  font-size: 0.875rem;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._programOption_axxph_557._noPrograms_axxph_560 {
  color: #8f8d91;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514 {
  padding: 0;
  height: auto;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514 > [class*=label] div {
  width: 20rem;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514 span {
  padding: 0.375rem;
  width: 100%;
  color: #5c5a5e;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: initial;
  white-space: pre-line;
  overflow: hidden;
  display: block;
  line-height: 1;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514 span ._accountLink_axxph_583 {
  color: #3996e0;
  font-weight: 600;
  text-decoration: none;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514._ineligible_axxph_588 span {
  background-color: #e9e8ea;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514._error_axxph_591 span {
  color: #fdfdfd;
  background-color: #f1515f;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524._message_axxph_514._noCommunities_axxph_595 span {
  font-size: 0.875rem;
  background: transparent;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._inviteButton_axxph_431, ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._toggleButton_axxph_431 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: auto;
  min-width: initial;
  max-width: initial;
  height: 2.25rem;
  border-radius: 624.9375rem;
  box-sizing: border-box;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._inviteButton_axxph_431._inviting_axxph_496, ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._inviting_axxph_496._toggleButton_axxph_431, ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._inviting_axxph_496._toggleButton_axxph_431 {
  padding: 0.5rem;
  min-width: 2.25rem;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._inviteButton_axxph_431._inviting_axxph_496 > [class*=icon], ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._inviting_axxph_496._toggleButton_axxph_431 > [class*=icon], ._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._inviting_axxph_496._toggleButton_axxph_431 > [class*=icon] {
  margin: 0;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._new_axxph_617,
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._invited_axxph_618,
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._approved_axxph_619 {
  margin-right: 0.5rem;
  text-transform: capitalize;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._new_axxph_617,
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._invited_axxph_618 {
  color: #8f8d91;
}
._popover_axxph_507 ._popoverContent_axxph_511 ._option_axxph_524._option_axxph_524 ._approved_axxph_619 {
  color: #3996e0;
}

._tooltip_axxph_631 {
  font-size: 0.75rem;
  line-height: 1.2;
  font-weight: 600;
  color: #d9534f;
}

._ammendAction_axxph_638 {
  font-weight: 600;
  text-decoration: none;
  color: #d9534f;
  cursor: pointer;
  padding-left: 0.25rem;
}

._searchContainer_axxph_646 {
  padding: 0;
}

._searchOption_axxph_650 {
  padding: 0 !important;
  border-bottom: 1px solid #dadcde;
}
._searchOption_axxph_650:hover {
  background-color: transparent !important;
}
._searchOption_axxph_650 [class*=searchIcon],
._searchOption_axxph_650 [class*=clearButton] {
  color: #4F5256 !important;
}

._searchError_axxph_667 {
  color: var(--error-color);
  font-size: 12px;
  padding: 0.75rem 1rem;
  margin: 0;
  width: 100%;
}

._Favorite_axxph_169 {
  width: 4.5rem;
  height: 2.5rem;
  display: flex;
  border: 0.125rem solid #e9e8ea;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #fdfdfd;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Favorite_axxph_169 ._FavoriteButton_axxph_686 {
  width: 2.25rem;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
._Favorite_axxph_169 ._FavoriteButton_axxph_686._HeartButton_axxph_694 {
  border-right: 0.125rem solid #e9e8ea;
}
._Favorite_axxph_169 ._FavoriteButton_axxph_686._FavoriteButtonDisabled_axxph_697 {
  opacity: 0.6;
  pointer-events: none;
  cursor: default;
}
._Favorite_axxph_169 ._FavoriteButton_axxph_686:hover, ._Favorite_axxph_169 ._FavoriteButton_axxph_686._active_axxph_702 {
  background-color: #fafafa;
}
._Favorite_axxph_169._isLoading_axxph_705 {
  opacity: 0.6;
  cursor: wait;
}
._Favorite_axxph_169._isLoading_axxph_705 * {
  pointer-events: none;
}
._Favorite_axxph_169._isDisabled_axxph_712 {
  opacity: 0.6;
}
._Favorite_axxph_169._isDisabled_axxph_712 * {
  pointer-events: none;
}

._optionTitle_axxph_547 {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

._groupOption_axxph_431 ._spinner_axxph_724 {
  background-color: #3996e0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  border-radius: 0.875rem;
  padding: 0.375rem 0.5rem;
}
._groupOption_axxph_431 ._groupOptionAction_axxph_431 {
  font-size: 0.75rem;
  font-weight: 500;
}
._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._groupOptionActionLabel_axxph_737 {
  color: #8f8d91;
  padding-right: 0.75rem;
}
._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._groupOptionActionLabel_axxph_737 span {
  margin-left: 0.25rem;
}
._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._toggleButton_axxph_431 {
  display: none;
}
._groupOption_axxph_431 ._groupOptionAction_axxph_431 ._toggleButton_axxph_431._addButton_axxph_747 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
._groupOption_axxph_431:hover ._groupOptionAction_axxph_431 ._groupOptionActionLabel_axxph_737 {
  display: none;
}
._groupOption_axxph_431:hover ._groupOptionAction_axxph_431 ._toggleButton_axxph_431 {
  display: block;
}

._searchContainer_axxph_646 {
  padding: 0;
}

._searchOption_axxph_650 {
  padding: 0 !important;
  border-bottom: 1px solid #dadcde;
}
._searchOption_axxph_650:hover {
  background-color: transparent !important;
}
._searchOption_axxph_650 [class*=searchIcon],
._searchOption_axxph_650 [class*=clearButton] {
  color: #4F5256 !important;
}

._searchError_axxph_667 {
  color: var(--error-color);
  font-size: 12px;
  padding: 0.75rem 1rem;
  margin: 0;
  width: 100%;
}