import * as React from 'react';

import { logger } from '@common';
import {
  MemberSearchQuery_members as IMember,
  MemberSearchQuery_members,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { useMemberSearchQuery } from '@frontend/app/hooks';

import { FetchPolicy } from '@apollo/client';
import { chunk, flatten } from 'lodash';
import { IMembersTableSearchQuery } from '../MembersTable';

const {
  useCallback,
  useState,
} = React;

interface IOptions {
  searchQuery: IMembersTableSearchQuery;
  fetchPolicy?: FetchPolicy;
}

interface IUseFetchMembers {
  fetchMembers: (queryVariables: IMembersTableSearchQuery) => Promise<void>;
  fetchFlexMembers: (queryVariables: IMembersTableSearchQuery) => Promise<void>;
  isLoading: boolean;
  members: IMember[];
}

export const useFetchMembers = ({
  searchQuery,
  fetchPolicy = 'network-only',
}: IOptions, dynamicQueryFields = new Set([])): IUseFetchMembers => {
  const [members, setMembers] = useState<IMember[]>();

  const [localLoading, setLocalLoading] = useState(false);

  const {
    refetch: refetchMembers,
  } = useMemberSearchQuery({
    variables: {
      query: null,
      ...searchQuery,
    },
    fetchPolicy,
    skip: true,
  }, dynamicQueryFields);

  const fetchMembers = useCallback(
    (searchQuery: IMembersTableSearchQuery): Promise<void> => {
      const memberIds = searchQuery?.query?.includeMemberIds;
      if (memberIds?.length) {
        setLocalLoading(true);
        const memberChunks = chunk(memberIds, 200);
        return Promise.limitedAll<number[], MemberSearchQuery_members[]>(memberChunks, (memberChunk) => {
          return refetchMembers({
            ...searchQuery,
            query: {
              ...searchQuery.query,
              includeMemberIds: memberChunk,
            },
          }).then((result) => {
            setMembers((prevMembers) => [...prevMembers, ...(result?.data?.members || [])]);
            return result?.data?.members;
          }).catch((error) => {
            logger.error(error);
            return [];
          });
        }, 1).then((results) => {
          setMembers(flatten(results));
          setLocalLoading(false);
        }).catch((error) => {
          logger.error(error);
          setMembers([]);
          setLocalLoading(false);
        });
      } else {
        setMembers([]);
        setLocalLoading(false);
      }
    },
    [refetchMembers],
  );

  const fetchFlexMembers = useCallback(
    (queryVariables: IMembersTableSearchQuery): Promise<void> => {
      if (queryVariables) {
        setLocalLoading(true);
        return refetchMembers(queryVariables)
          .then((result) => {
            setMembers(result?.data?.members);
            setLocalLoading(false);
          })
          .catch((error) => {
            logger.error(error);
            setLocalLoading(false);
          });
      } else {
        setMembers([]);
        setLocalLoading(false);
      }
    },
    [refetchMembers],
  );

  return {
    fetchMembers,
    fetchFlexMembers,
    isLoading: localLoading,
    members,
  };
};
