import * as React from 'react';
import {
  isFunction, slice,
} from 'lodash';
import { Input } from '@frontend/shadcn/components/ui/input';

const { useCallback, useImperativeHandle, useRef } = React;

interface IProps {
  value: [number, number];
  isPercentage?: boolean;
  onChange?(value: [number, number]);
}

export interface INumberBetweenHandles {
  focus(): void;
}

const NumberBetweenComponent: React.RefForwardingComponent<INumberBetweenHandles, IProps> = (props, ref) => {
  const {
    value,
    isPercentage,
    onChange,
  } = props;

  // Used to focus the first input field
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const firstInputNumberRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    focus: () => firstInputNumberRef.current.focus(),
  }));

  const handleChangeBetween = useCallback((idx: number, newValue: number) => {
    if (isFunction(onChange) && !Number.isNaN(newValue)) {
      const newBetweenValue = slice(value) as [number, number];
      newBetweenValue[idx] = Number(newValue);
      onChange(newBetweenValue);
    }
  }, [value, onChange]);

  return (
    <div className="flex items-center justify-between gap-2">
      <Input
        value={value?.[0]}
        onChange={(val) => handleChangeBetween(0, Number(val.target.value))}
        ref={firstInputNumberRef}
        min={isPercentage ? 0 : undefined}
        max={isPercentage ? 100 : undefined}
      />
      <span className="text-xs">and</span>
      <Input
        value={value?.[1]}
        onChange={(val) => handleChangeBetween(1, Number(val.target.value))}
        min={isPercentage ? 0 : undefined}
        max={isPercentage ? 100 : undefined}
      />
    </div>
  );
};

export const NumberBetween = React.forwardRef(NumberBetweenComponent);
