import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';

import { IPaymentSources } from './models';

const { useState, useCallback } = React;

interface IRemovePaymentSourceParams {
  remove_card_id: string;
  client_id: string;
}

const removeCard = async (url: string, params: IRemovePaymentSourceParams, backendServerFetchResponse: typeof fetch) => {
  try {
    const resp = await backendServerFetchResponse(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export const useRemovePaymentSource = () => {
  const { backendServerApiEndpoint, clientId } = useApplication();
  const { backendServerFetchResponse } = useBackendServerFetch();

  const url = `${backendServerApiEndpoint}/payment_source`;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaymentSources>(null);
  const [error, setError] = useState<Error | null>(null);

  const remove = useCallback(async (cardId: string) => {
    setLoading(true);

    const params: IRemovePaymentSourceParams = {
      remove_card_id: cardId,
      client_id: clientId,
    };

    let result;

    try {
      result = await removeCard(url, params, backendServerFetchResponse);
      setData(result);
    } catch (err) {
      setError(err);
    }

    setLoading(false);

    return result;
  }, [backendServerFetchResponse, clientId, url]);

  return {
    remove,
    loading,
    data,
    error,
  };
};
