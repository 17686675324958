import * as React from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { filter, first, isNil } from 'lodash';

import {
  LoadSpinner,
} from '@components';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { ApplicationList } from '@frontend/app/containers/Application/ApplicationList';
import { ErrorNotice } from '@frontend/app/components';
import {
  useFetchThread,
  useGetAllProjectsQuery,
  useGetAllUsers,
} from '@frontend/app/hooks';
import { useReactiveVar } from '@apollo/client';
import { EmailMessageList } from './MessageList/EmailMessageList';
import { SystemMessageList } from './MessageList/SystemMessageList';
import { InstagramMessageList } from './MessageList/IGDM/InstagramMessageList';
import { MemberDetails } from '../MemberDetail/components/MemberDetails';
import { MemberDrawer } from '../Members/MemberDrawer';
import {
  expandedMessagesVar,
  needRefetchMessageVar,
  useMessagingContext,
} from './context/MessagingAppContext';
import { isEmailThread, isIGDMThread } from './utils';
import styles from './MessageList.scss';

interface IProps {
  threadId?: string;
  className?: string;
  hideAdditionalActions?: boolean;
  onRequestClose?(): void;
  onBackButtonClicked?(): void;
  fetchMembers?(): void;
}
interface IMatchParams {
  threadId: string;
}

const { useEffect, useState, useCallback } = React;

/**
 * @type {React.FunctionComponent}
 */

export const MessageList: React.FunctionComponent<IProps> = React.memo((props) => {
  const addEvent = useEventContext();

  const { threadId: paramThreadId } = useParams<IMatchParams>();
  const threadId = props.threadId ?? paramThreadId;
  const { hideAdditionalActions = false } = props;
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);
  const needRefetchMessage = useReactiveVar(needRefetchMessageVar);

  const { data: { projects } = {} } = useGetAllProjectsQuery();

  const {
    selectedThread,
    setSelectedThread,
    refetchAndUpdateThreads,
    refetchThreadsCount,
  } = useMessagingContext();

  const { users } = useGetAllUsers();

  const {
    loading,
    thread,
    error,
    refetch,
  } = useFetchThread(threadId, () => {
    needRefetchMessageVar(false);
  });
  useEffect(() => {
    if (thread !== null) {
      addEvent(EventName.ViewThread, { threadType: thread.type });
      expandedMessagesVar([thread.lastMessage.id]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread]);
  useEffect(() => {
    // update the selected thread id
    if (!isNil(thread) && threadId !== selectedThread?.id) {
      setSelectedThread(thread);
      // Update the selected member id for the sidebar
      if (isProfileOpen && selectedMemberId && !thread.members.find((m) => m.id === selectedMemberId)) {
        setSelectedMemberId(first(thread.members).id);
      }
    }
  }, [isProfileOpen, thread, threadId, selectedMemberId, selectedThread, setSelectedThread]);

  useEffect(() => {
    if (needRefetchMessage) {
      refetch().then(() => {
        needRefetchMessageVar(false);
      }).catch(() => {
        console.error('Failed to refetch messages');
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefetchMessage]);

  const onToggleMessageCollapsed = (isCollapsed: boolean, messageId: string) => {
    if (isCollapsed) {
      expandedMessagesVar([...expandedMessagesVar(), messageId]);
    } else {
      expandedMessagesVar(filter(expandedMessagesVar(), (id) => id !== messageId));
    }
  };

  const onOpenOverview = useCallback(() => {
    setIsProfileOpen(true);
  }, []);

  if (error) {
    return <ErrorNotice />;
  }
  return (
    <div className={cx(styles.MessageList, props.className)}>
      {loading && <LoadSpinner />}

      {!loading
        && (
        <div className={styles.messagesContainer}>
          <div className={styles.messageSection}>
            {isEmailThread(thread) && (
              <EmailMessageList
                thread={thread}
                refetch={refetch}
                hideAdditionalActions={hideAdditionalActions}
                onRequestClose={props.onRequestClose}
                onBackButtonClicked={props.onBackButtonClicked}
                onToggleMessageCollapsed={onToggleMessageCollapsed}
                onOpenOverview={onOpenOverview}
                setSelectedMemberId={setSelectedMemberId}
                users={users}
                refetchAndUpdateThreads={refetchAndUpdateThreads}
                refetchThreadsCount={refetchThreadsCount}
                fetchMembers={props?.fetchMembers}
              />
            )}
            {isIGDMThread(thread) && (
              <InstagramMessageList
                thread={thread}
                refetch={refetch}
                onOpenOverview={onOpenOverview}
                setSelectedMemberId={setSelectedMemberId}
              />
            )}
            {!isEmailThread(thread)
              && !isIGDMThread(thread)
              && (
                <SystemMessageList
                  thread={thread}
                  refetch={refetch}
                  onToggleMessageCollapsed={onToggleMessageCollapsed}
                  onOpenOverview={onOpenOverview}
                  setSelectedMemberId={setSelectedMemberId}
                />
            )}
          </div>
          {isProfileOpen && selectedMemberId && (
            <div className={cx(styles.sidebar, { [styles.closed]: !isProfileOpen })}>
              <MemberDetails
                memberId={selectedMemberId}
                isMemberHeader
                projects={projects}
                onClose={() => {
                  setIsProfileOpen(false);
                }}
              />
              <MemberDrawer />
            </div>
          )}
          {thread && first(thread.members) && (
            <ApplicationList
              memberId={first(thread.members).id.toString()}
              hideLauncher
            />
          )}
        </div>
      )}
    </div>
  );
});

MessageList.displayName = 'MessageList';
