import * as React from 'react';
import { Form, Checkbox, Radio } from 'antd';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { Alert } from '@frontend/shadcn/components/ui/alert';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/solid';
import { RefreshTrackPayout } from './RefreshTrackPayout';

const THIRD_PARTY_TRACKING = {
  IMPACT: 'IMPACT',
  AWIN: 'AWIN',
  SHAREASALE: 'SHAREASALE',
};

export const RefreshOfferUrlThirdPartySelection = ({ values, handleFormAsyncActions }) => {
  const { thirdPartyTrackingUrl } = useClientFeatures();

  // Function to reset UTM fields and disable them when third-party tracking is enabled
  const resetUTMFields = () => {
    handleFormAsyncActions({
      action: 'UpdateField',
      payload: {
        key: 'isAdvanceUrlEnable',
        value: '',
      },
    });
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;

    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'isThirdPartyIntegration', value: !!checked },
    });

    if (checked) {
      resetUTMFields();
      handleFormAsyncActions({
        action: 'UpdateField',
        payload: { key: 'thirdPartyTracking', value: THIRD_PARTY_TRACKING.IMPACT },
      });
    } else {
      handleFormAsyncActions({
        action: 'UpdateField',
        payload: { key: 'thirdPartyTracking', value: null },
      });
      handleFormAsyncActions({
        action: 'UpdateField',
        payload: { key: 'trackThirdPartyPayout', value: false },
      });
    }
  };

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;

    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'thirdPartyTracking', value: selectedValue },
    });
  };

  return (
    <div className="mt-3">
      {thirdPartyTrackingUrl && (
        <>
          <Form.Item>
            <Checkbox onChange={handleCheckboxChange} checked={values.isThirdPartyIntegration}>
              <span className="text-grey-6">
                This tracking URL was generated through an affiliate platform outside of Aspire.
              </span>
            </Checkbox>

            {values.isThirdPartyIntegration && (
              <>
                <div className="mt-2 px-6">
                  <Radio.Group onChange={handleRadioChange} value={values.thirdPartyTracking} className="!flex flex-col">
                    <Radio value={THIRD_PARTY_TRACKING.IMPACT} disabled={!values.isThirdPartyIntegration}>
                      Impact
                    </Radio>
                    <Radio value={THIRD_PARTY_TRACKING.AWIN} disabled>
                      {' '}
                      Awin
                    </Radio>
                    <Radio value={THIRD_PARTY_TRACKING.SHAREASALE} disabled>
                      ShareASale
                    </Radio>
                  </Radio.Group>
                  <div className="mt-2">
                    <Alert
                      role="alert"
                      className="w-full bg-yellow-2 flex items-center font-inter text-primary border border-yellow-4"
                    >
                      <div className="flex flex-col md:flex-row md:items-center justify-center items-start w-full pr-6">
                        <div className="flex-grow">
                          <p className="m-0">
                            <span>
                              {' '}
                              <CircleExclamationIcon className="w-4 h-4 mr-2 text-yellow-5" />
                            </span>
                            Please be sure to select the correct platform as this will impact all link generation for this
                            offer.
                          </p>
                        </div>
                      </div>
                    </Alert>
                  </div>
                </div>
                {values.isThirdPartyIntegration && (
                  <RefreshTrackPayout values={values} handleFormAsyncActions={handleFormAsyncActions} />
                )}
              </>
            )}
          </Form.Item>
        </>
      )}
    </div>
  );
};
