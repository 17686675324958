import gql from 'graphql-tag';

export const MEMBER_FRAGMENT = gql`
  fragment MemberFragment on Member {
    id
    email
    name
    profilePicture
    notes
    isImportant
    fields
    source
    tags{
      name
    }
    talentAgents {
      memberId
      agentId
      alwaysCC
      agentName
      agentEmail
      agentMemberId
      createdDate
      updatedDate 
    }
  }
`;
