/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_n0qhm_157 {
  display: flex;
}

._justify-content-space-between_n0qhm_161 {
  justify-content: space-between;
}

._tabular-nums_n0qhm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_n0qhm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._NavItem_n0qhm_240 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
._NavItem_n0qhm_240 button,
._NavItem_n0qhm_240 a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  font-weight: normal;
  height: 3.25rem;
  background-color: transparent !important;
  color: #505256;
}
._NavItem_n0qhm_240 button.ant-dropdown-open {
  background-color: #f4fcff !important;
  color: #1890ff !important;
}
._NavItem_n0qhm_240:hover {
  background-color: #f4fcff;
  color: #1890ff;
}
._NavItem_n0qhm_240._selected_n0qhm_269 {
  background-color: #e6f7ff;
  color: #002766;
}
._NavItem_n0qhm_240 ._navIcon_n0qhm_273 {
  display: inline-block;
  font-size: 1rem;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  line-height: 1;
}
._NavItem_n0qhm_240 ._navIcon_n0qhm_273 + span {
  margin-left: 8px;
}
._NavItem_n0qhm_240 ._arrow_n0qhm_282 {
  font-size: 0.75rem;
}
._NavItem_n0qhm_240 ._badge_n0qhm_285 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1rem;
  border-radius: 6.25rem;
  background: #ff4d4f;
  color: #fdfdfd;
  font-weight: bold;
  font-size: 0.5rem;
  letter-spacing: -0.0375rem;
  transform: translateY(-0.75rem);
}

._divider_n0qhm_301 {
  border-bottom: 1px solid #ececec;
}