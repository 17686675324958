import * as React from 'react';
import {
  // Alert,
  Form,
  Modal,
} from '@revfluence/fresh';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { FormAsyncAction, IShopifyFormElementProps, OfferFormAsyncActions } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { P } from '@frontend/shadcn/components/typography/p';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import styles from '../../../../../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'multiShopifyEnabled'> {
  isMultipleShopifySyncEnabled: boolean;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  hasMembers: boolean;
  isLinkedShopifyOfferSelected: boolean;
  isLandingPageEnabled?: boolean;
}

const { confirm } = Modal;

export const RefreshOfferSync: React.FC<Readonly<IProps>> = React.memo(
  ({
    disabled,
    name,
    isMultipleShopifySyncEnabled,
    handleFormAsyncActions,
    hasMembers,
    isLinkedShopifyOfferSelected,
    isLandingPageEnabled,
  }) => {
    const handleChange = (event) => {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'isMultipleShopifySyncEnabled', value: event },
      });
    };
    const handleSync = (event) => {
      if (hasMembers && event) {
        confirm({
          title: 'Are you sure you want to sync this offer across other stores?',
          icon: <CircleExclamationIcon />,
          content:
            'The offer currently has a flat discount and collection restrictions. These will be removed and you will need to set discount as a percentage value. Offer cannot be restricted to collections when Sync is enabled.',
          onOk() {
            handleChange(event);
          },
          onCancel() {},
          autoFocusButton: null,
          okText: 'Continue',
        });
      } else {
        handleChange(event);
      }
    };
    return (
      <Form.Item
        name={name}
        className={`${styles.offerSyncForm} ${isLandingPageEnabled ? 'opacity-50 !mb-0' : '!mb-0'}`}
        valuePropName="checked"
      >
        <div className="flex gap-2 pt-4">
          <div>
            <Switch
              disabled={disabled}
              onCheckedChange={handleSync}
              checked={isMultipleShopifySyncEnabled}
            />
          </div>
          <div>
            <P className="font-bold">
              {isLinkedShopifyOfferSelected ? 'Enter discount id of secondary stores' : 'Enable Sync'}
            </P>
          </div>
        </div>
        {/* {!isNewFlow && (
        <div className={styles.AlertWrapper}>
          <Alert message="This offer is currently not eligible for syncing with other stores. You must first update and save this offer, after which you can return to enable the syncing feature." type="warning" />
        </div>
      )} */}
      </Form.Item>
    );
  },
);

RefreshOfferSync.displayName = 'RefreshOfferSync';
