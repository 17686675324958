import React from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';
import { usePayment } from '../PaymentContext';

interface TablePaginationProps {
  pageIndex: number
  canPreviousPage: boolean
  canNextPage: boolean
  onPaginationChange: (pageIndex: number) => void
}

export function TablePagination({
  pageIndex,
  canPreviousPage,
  canNextPage,
  onPaginationChange,
}: TablePaginationProps) {
  const { pageSize, totalCount } = usePayment();
  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, totalCount);

  return (
    <div className="flex items-center gap-2">
      <div className="text-sm text-muted-foreground">
        {start}-{end} of {totalCount}
      </div>
      <div className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={() => onPaginationChange(pageIndex - 1)}
          disabled={!canPreviousPage}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={() => onPaginationChange(pageIndex + 1)}
          disabled={!canNextPage}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}
