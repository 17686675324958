import { MemberInsights } from "@frontend/gql/social/graphql";
import { ColumnDefinitionInput, RowType } from "..";
import { generateSubColumnConfig } from "./generateSubColumnConfig";

export const MEMBER_TABLE_COLUMN_CONFIG_VERSION = 4; // must update this value if you change anything below
const defaultColumnsSet = new Set([
  'member',
  'socialAccounts',
  'total.numberOfPosts',
  'total.activePosts',
  'total.latestPostDate',
  'total.engagements',
  'total.engagementRate',
  'total.potentialReach',
  'total.impressions',
  'total.tmv',
]);

export const defaultColumns: ColumnDefinitionInput[] = [
  {
    header: 'Member',
    id: 'member',
    enableSorting: true,
    enableGlobalFiltering: true,
    rowType: RowType.MEMBER,
    hidden: !defaultColumnsSet.has('member'),
    size: 200,
    minSize: 50,
    maxSize: 300,
    order: 1,
    isFixed: true,
    accessorFn: (row: MemberInsights) => row.member?.name ?? ""
  },
  {
    header: 'Social Accounts',
    id: 'socialAccounts',
    enableSorting: false,
    borderColor: '#A2ADB9',
    rowType: RowType.SOCIAL_HANDLES,
    isColumnExpanded: false,
    isExpandableColumn: true,
    minSize: 225,
    hidden: !defaultColumnsSet.has('socialAccounts'),
    order: 2,
    subColumns: [
        {
          header: "Instagram",
          id: "member.instagramUsername",
          enableSorting: true,
          enableGlobalFiltering: true,
          rowType: RowType.DEFAULT,
          hidden: !defaultColumnsSet.has('socialAccounts'),
        },
        {
          header: "YouTube",
          id: "member.youtubeUsername",
          enableSorting: true,
          enableGlobalFiltering: true,
          rowType: RowType.DEFAULT,
          hidden: !defaultColumnsSet.has('socialAccounts'),
        },
        {
          header: "Pinterest",
          id: "member.pinterestUsername",
          enableSorting: true,
          enableGlobalFiltering: true,
          rowType: RowType.DEFAULT,
          hidden: !defaultColumnsSet.has('socialAccounts'),
        },
        {
          header: "Tiktok",
          id: "member.tiktokUsername",
          enableSorting: true,
          enableGlobalFiltering: true,
          rowType: RowType.DEFAULT,
          hidden: !defaultColumnsSet.has('socialAccounts'),
        },
    ]
  },
  {
    header: 'Posts Created',
    id: 'total.numberOfPosts',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    borderColor: 'chocolate',
    hidden: !defaultColumnsSet.has('total.numberOfPosts'),
    order: 3,
    minSize: 185,
    subColumns: generateSubColumnConfig('numberOfPosts', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.numberOfPosts')),
  },
  {
    header: 'Active Posts',
    id: 'total.activePosts',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    borderColor: 'chocolate',
    hidden: !defaultColumnsSet.has('total.activePosts'),
    order: 3,
    minSize: 180,
    subColumns: generateSubColumnConfig('activePosts', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.activePosts')),
  },
  {
    header: 'Last Post Date',
    id: 'total.latestPostDate',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.latestPostDate'),
    isExpandableColumn: false,
    order: 4,
    minSize: 190,
    subColumns: generateSubColumnConfig('latestPostDate', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.latestPostDate')),
  },
  {
    header: 'Last Update Date',
    id: 'total.latestUpdateDate',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.latestUpdateDate'),
    isExpandableColumn: false,
    order: 5,
    minSize: 210,
    subColumns: generateSubColumnConfig('latestUpdateDate', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.latestUpdateDate')),
  },
  {
    header: 'Engagement',
    id: 'total.engagements',
    enableSorting: true,
    hidden: !defaultColumnsSet.has('total.engagements'),
    rowType: RowType.DEFAULT,
    borderColor: 'hsl(var(--refresh-pink-4))',
    order: 6,
    subColumns: generateSubColumnConfig('engagements', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.engagements')),
  },
  {
    header: 'Engagement Rate',
    id: 'total.engagementRate',
    enableSorting: true,
    rowType: RowType.PERCENTAGE,
    hidden: !defaultColumnsSet.has('total.engagementRate'),
    borderColor: 'hsl(var(--refresh-pink-2))',
    order: 7,
    minSize: 190,
    subColumns: generateSubColumnConfig('engagementRate', RowType.PERCENTAGE, undefined, !defaultColumnsSet.has('total.engagementRate')),
  },
  {
    header: 'Total Reach',
    id: 'total.reach',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.reach'),
    borderColor: 'hsl(var(--refresh-blue-5))',
    order: 8,
    subColumns: generateSubColumnConfig('reach', RowType.DEFAULT, ['Pinterest', 'YouTube'], !defaultColumnsSet.has('total.reach')),
  },
  {
    header: 'Potential Reach',
    id: 'total.potentialReach',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.potentialReach'),
    borderColor: 'hsl(var(--refresh-blue-5))',
    order: 9,
    minSize: 195,
    subColumns: generateSubColumnConfig('potentialReach', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.potentialReach')),
  },
  {
    header: 'Impressions',
    id: 'total.impressions',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.impressions'),
    borderColor: 'hsl(var(--refresh-magenta-4))',
    order: 10,
    subColumns: generateSubColumnConfig('impressions', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.impressions')),
  },
  {
    header: 'Views',
    id: 'total.views',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.views'),
    borderColor: 'hsl(var(--refresh-yellow-4))',
    order: 11,
    subColumns: generateSubColumnConfig('views', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.views')),
  },
  {
    header: 'Likes',
    id: 'total.likes',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.likes'),
    borderColor: 'hsl(var(--refresh-orange-3))',
    order: 12,
    subColumns: generateSubColumnConfig('likes', RowType.DEFAULT, ['Pinterest'], !defaultColumnsSet.has('total.likes')),
  },
  {
    header: 'Saves',
    id: 'total.saves',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.saves'),
    borderColor: 'hsl(var(--refresh-lime-3))',
    order: 13,
    subColumns: generateSubColumnConfig('saves', RowType.DEFAULT, ['Pinterest'], !defaultColumnsSet.has('total.saves')),
  },
  {
    header: 'Shares',
    id: 'total.shares',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.shares'),
    borderColor: 'hsl(var(--refresh-purple-2))',
    order: 14,
    subColumns: generateSubColumnConfig('shares', RowType.DEFAULT, ['Pinterest', 'YouTube'], !defaultColumnsSet.has('total.shares')),
  },
  {
    header: 'Comments',
    id: 'total.comments',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.comments'),
    borderColor: 'hsl(var(--refresh-teal-3))',
    order: 15,
    subColumns: generateSubColumnConfig('comments', RowType.DEFAULT, undefined, !defaultColumnsSet.has('total.comments')),
  },
  {
    header: 'TMV',
    id: 'total.tmv',
    enableSorting: true,
    rowType: RowType.CURRENCY,
    hidden: !defaultColumnsSet.has('total.tmv'),
    borderColor: 'hsl(var(--refresh-green-4))',
    order: 16,
    subColumns: generateSubColumnConfig('tmv', RowType.CURRENCY, undefined, !defaultColumnsSet.has('total.tmv')),
  },
  {
    header: 'Follower Growth',
    id: 'total.followerGrowth',
    enableSorting: true,
    rowType: RowType.DEFAULT,
    hidden: !defaultColumnsSet.has('total.followerGrowth'),
    borderColor: 'hsl(var(--refresh-magenta-2))',
    order: 17,
    minSize: 200,
    subColumns: generateSubColumnConfig('followerGrowth', RowType.DEFAULT, ['Pinterest', 'Tiktok', 'YouTube'], !defaultColumnsSet.has('total.followerGrowth')),
  },
];
