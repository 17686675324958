import React, { useCallback, useEffect, useMemo } from 'react';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { SideDrawer } from '@frontend/app/refresh-components/SideDrawer';
import { ImageUpload } from '@frontend/app/refresh-components/ImageUpload';
import { P } from '@frontend/shadcn/components/typography/p';
import { UserAvatar } from '@frontend/app/components';
import { MultiSelect } from '@frontend/app/components/MultiSelect/MultiSelect';
import {
 useArchiveProgramById, useClientFeatureEnabled, useDeleteProgramById, useGetAllProjectsQuery, useGetCampaignByProjectId, useGetCountsForProjectQuery, useGetUsersQuery,
} from '@frontend/app/hooks';
import {
 BoxArchiveIcon, ChevronDownIcon, CircleExclamationIcon, TriangleExclamationIcon, CircleInfoIcon, TrashIcon, CircleCheckIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useAuth } from '@frontend/context/authContext';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@frontend/shadcn/components/ui/collapsible';
import { Button } from '@frontend/shadcn/components/ui/button';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { isNull } from 'lodash';
import { Alert, message, Modal } from '@revfluence/fresh';
import { useHistory } from 'react-router-dom';
import { useCampaign } from './CampaignContext';
import { ProjectsRouteRoot } from '../constants';
import { TProject } from '../types';

interface CampaignActionProps {
  project: TProject;
  setOpen: (open: boolean) => void;
}

const CampaignActions: React.FC<CampaignActionProps> = ({ project, setOpen }) => {
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const history = useHistory();

  const isArchivedProject = useMemo(
    () => !isNull(project?.archivedDate) && !isNull(project?.archivedByUser),
    [project],
  );

  const {
    refetch: refetchProjectCampaign,
  } = useGetCampaignByProjectId(project?.id, {
    skip: !project?.id,
  });

  const { refetch: refetchProjects } = useGetAllProjectsQuery();

  const { data: { counts = {} } = {} } = useGetCountsForProjectQuery({
    variables: {
      projectId: project.id,
    },
  });

  const totalCount = useMemo(() => {
    if (!counts) {
      return 0;
    }
    return counts.all_in_progress + counts.new + counts.invited;
  }, [counts]);

  const [archiveProgramById] = useArchiveProgramById({
    async onCompleted() {
      await refetchProjectCampaign();
      message.success({
        icon: <CircleCheckIcon />,
        content: 'Project Successfully Archived',
        duration: 2,
      });
      history.replace({
        pathname: `${ProjectsRouteRoot}/${project.id}/overview`,
      });
    },
    onError(error) {
      message.error({
        content: error.message,
        duration: 2,
      });
    },
  });

  const { deleteProgramById } = useDeleteProgramById({
    onCompleted() {
      message.success({
        icon: <CircleCheckIcon />,
        content: `${project?.title} has been deleted.`,
        duration: 2,
      });

      refetchProjects();

      history.push('/home');
    },
    onError(error) {
      message.error({
        content: error.message,
        duration: 2,
      });
    },
  });

  const archiveModalText = useMemo(
    () => (
      <>
        <p>
          Are you sure you want to archive this project? All project analytics will still be available but you will not be able to add or accept new members.
        </p>
        <p>
          You will not be able to unarchive at this time.
        </p>
      </>
    ),
    [],
  );

  const showArchiveModal = useCallback(
    () => {
      setOpen(false);
      Modal.confirm({
        title: 'Archive Project',
        icon: <CircleExclamationIcon />,
        content: archiveModalText,
        onOk() {
        archiveProgramById({
          variables: {
            id: project.id,
          },
        });
      },
      autoFocusButton: null,
      okText: 'Archive',
    });
  },
  [
    project?.id,
    archiveModalText,
    archiveProgramById,
    setOpen,
  ],
);

const showDeleteProjectModal = useCallback(
  () => {
    setOpen(false);
    Modal.confirm({
      title: `Permanently Delete ${project?.title}?`,
      content: (
        <>
          <p>Are you sure you want to permanently delete this project? This will have a number of consequences across Aspire:</p>
          <ul>
            <li>Your landing page for this project will be disabled. Any invites you have sent will no longer work.</li>
            <li>
              You will no longer be able to filter reports on content, posts, payments, or others by this project.
            </li>
            {totalCount > 0 && (
              <li>
                {totalCount}
                {' '}
                member
                {totalCount > 1 ? 's' : ''}
                {' '}
                in the workflow will have their workflows cancelled.
              </li>
            )}
          </ul>
        </>
      ),
      async onOk() {
        await deleteProgramById({
          variables: {
            id: project.id,
          },
        });
      },
      okType: 'danger',
      icon: <TriangleExclamationIcon />,
      cancelText: 'Cancel',
      okText: 'Yes, Delete',
    });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    project?.title,
    project?.id,
    totalCount,
    setOpen,
  ],
);

  return (
    <div className="flex flex-col gap-4">
      {isArchiveProjectEnabled && (
        <Collapsible className="w-full rounded-2xl border border-border bg-card">
          <CollapsibleTrigger className="flex w-full items-center justify-between p-4 text-left hover:bg-accent/50">
            <div className="flex items-center gap-2">
              <H3 className="text-sm font-medium mb-0">Archive Campaign</H3>
            </div>
            <ChevronDownIcon className="text-primaryMuted transition-transform duration-200 group-data-[state=open]:rotate-180" />
          </CollapsibleTrigger>
          <CollapsibleContent className="px-4 pb-4">
            {isArchivedProject ? (
              <p className="mb-4 text-sm text-primaryMuted">
                This campaign was deactivated and will not count towards the active campaign cap for your plan. Members
                can still continue to complete their tasks and their data will continue to be available in the campaign. You
                will not be able to unarchive a campaign.
                {' '}
                <a href="https://intercom.help/aspireiq_elevate/en/articles/6523259-how-to-archive-projects" className="text-primary underline" target="_blank">
                  Learn more
                </a>
              </p>
            ) : (
              <>
                <p className="mb-4 text-sm text-primaryMuted">
                  This campaign will be deactivated and will not count towards the active campaign cap for your plan. Members
                  can still continue to complete their tasks and their data will continue to be available in the campaign. You
                  will not be able to unarchive a campaign.
                  {' '}
                  <a href="https://intercom.help/aspireiq_elevate/en/articles/6523259-how-to-archive-projects" className="text-primary underline" target="_blank">
                    Learn more
                  </a>
                </p>
                <Button variant="outline" className="gap-2" onClick={showArchiveModal}>
                  <BoxArchiveIcon className="h-4 w-4" />
                  Archive Campaign
                </Button>
              </>
            )}
          </CollapsibleContent>
        </Collapsible>
      )}

      <Collapsible className="w-full rounded-2xl border border-border bg-card">
        <CollapsibleTrigger className="flex w-full items-center justify-between p-4 text-left hover:bg-accent/50">
          <div className="flex items-center gap-2">
            <H3 className="text-sm font-medium mb-0">Permanently Delete Campaign</H3>
          </div>
          <ChevronDownIcon className="text-primaryMuted transition-transform duration-200 group-data-[state=open]:rotate-180" />
        </CollapsibleTrigger>
        <CollapsibleContent className="px-4 pb-4">
          <p className="mb-4 text-sm text-primaryMuted">
            This will delete your campaign and its data completely. Use caution as this cannot be undone and member
            progress will be lost.
          </p>
          <Button variant="destructive" className="gap-2" onClick={showDeleteProjectModal}>
            <TrashIcon className="h-4 w-4" />
            Delete Campaign
          </Button>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

interface AboutDrawerProps {
  project: TProject;
}

export const AboutDrawer: React.FC<AboutDrawerProps> = ({ project }) => {
  const [open, setOpen] = React.useState(false);
  const {
    campaignOwner,
    listToMarketplace,
    campaignImage,
    setCampaignOwner,
    setListToMarketplace,
    setCampaignImage,
    setCampaignImageFile,
    setFlexibleSpec,
    isFlexibleSpec,
  } = useCampaign();

  const { user } = useAuth();

  const isFlexibleProjectEnabled = useClientFeatureEnabled(ClientFeature.FLEXIBLE_PROJECT);

  const selectedOwner = campaignOwner || user.sub;

  const { data: { users = [] } = {} } = useGetUsersQuery();

  const [tempImageFile, setTempImageFile] = React.useState<File | null>(null);
  const [tempImageSrc, setTempImageSrc] = React.useState<string | null>(campaignImage || null);

  useEffect(() => {
    setTempImageSrc(campaignImage);
  }, [campaignImage]);

  const ownerOptions = React.useMemo(() => {
    if (!users) return [];

    return users.map((user) => ({
      value: user.id,
      label: (
        <div className="flex items-center gap-2">
          <UserAvatar className="h-5 w-5" size={18} name={user.name} />
          <span>{user.name}</span>
        </div>
      ),
      searchValue: user.name,
    }));
  }, [users]);

  const handleSave = () => {
    if (tempImageFile) {
      // Here you would typically upload the image and get the URL
      // For now, we'll just use the local file
      const reader = new FileReader();
      reader.onloadend = () => {
        setCampaignImage(reader.result as string);
        setCampaignImageFile(tempImageFile);
      };
      reader.readAsDataURL(tempImageFile);
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setTempImageFile(null);
    setTempImageSrc(campaignImage);
    setOpen(false);
  };

  const handleOwnerChange = (values: string[]) => {
    setCampaignOwner(values[1] || values[0] || null);
  };

  return (
    <SideDrawer
      open={open}
      onOpenChange={setOpen}
      trigger={<button className="text-primary underline text-left w-fit">Advanced</button>}
      headerProps={{ title: 'About' }}
      footerButtons={[
        { name: 'Save', onClick: handleSave, variant: 'default' },
        { name: 'Cancel', onClick: handleCancel, variant: 'outline' },
      ]}
    >
      <div className="p-6 space-y-6">
        {/* Campaign Image */}
        <div className="space-y-4">
          <h3 className="font-medium">Campaign Image</h3>
          <div className="flex gap-4">
            <ImageUpload
              onFileSelected={setTempImageFile}
              onThumbnailSelected={setTempImageSrc}
              thumbnail={tempImageSrc}
              display="block"
            />
            <div className="flex flex-col gap-2">
              <P className="text-xs text-primaryMuted">
                Upload an image to set as the default hero image for the Creator Marketplace Listing and application page.
              </P>
              <div className="text-primaryMuted">
                <P className="font-medium mb-1 text-xs">File Requirements:</P>
                <P className="text-xs">.png, .jpg, or .jpeg accepted</P>
                <P className="text-xs">24MB max file size</P>
              </div>
            </div>
          </div>
        </div>

        {/* Campaign Owner */}
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <h3 className="font-medium mb-0">Campaign Owner</h3>
            <CircleInfoIcon fontSize={12} className="text-primaryMuted" />
          </div>
          <MultiSelect
            value={[selectedOwner]}
            onChange={handleOwnerChange}
            options={ownerOptions}
            placeholder="Select owner"
            className="w-full"
          />
        </div>

        {/* List to Creator Marketplace */}
        <div className="flex items-center gap-2 p-4 border rounded-lg">
          <Checkbox
            id="marketplace"
            checked={listToMarketplace}
            onCheckedChange={(checked) => setListToMarketplace(checked as boolean)}
          />
          <label htmlFor="marketplace" className="text-sm font-medium">
            List to Creator Marketplace
          </label>
        </div>

        {isFlexibleProjectEnabled && (
          <div className="flex flex-col gap-2 p-4 border rounded-lg">
            <div className="flex items-center gap-2">
              <Checkbox
                id="flexible-project"
                onCheckedChange={(checked) => setFlexibleSpec(checked as boolean)}
                checked={isFlexibleSpec}
                disabled={project?.isFlexibleSpec}
              />
              <label htmlFor="flexible-project" className="text-sm font-medium">
                Enable Flexible Project
              </label>
            </div>
            <Alert
              type="info"
              message="By enabling this option, you are making the project flexible. Please note that this action cannot be undone in the future."
            />
          </div>
        )}

        {project && <CampaignActions project={project} setOpen={setOpen} />}
      </div>
    </SideDrawer>
  );
};
