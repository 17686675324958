import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { cn } from '@frontend/shadcn/lib/utils';
import { Tooltip } from '@revfluence/fresh';
import * as React from 'react';

interface IProps {
  className?: string;
  imageUrl?: string;
  name: string;
  onClick?: () => void;
  showName?: boolean;
}

export const getColorFromName = (name: string) => {
  const colors = ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500', 'bg-pink-500'];
  if (!name || name.length === 0) return colors[0];
  const index = name.charCodeAt(0) % colors.length;
  return colors[index];
};

export const getInitials = (name: string) => {
  if (!name || name.trim() === '') return '?';

  const words = name.split(' ').filter(Boolean);
  return words.length > 1 ? `${words[0][0]}${words[1][0]}`.toUpperCase() : words[0][0].toUpperCase();
};

const NameCell: React.FC<IProps> = React.memo(({ className, imageUrl, name, onClick, showName = true }) => {
  return (
    <div className={cn('flex items-center gap-3', className)}>
      <Avatar className={cn('h-6 w-6 cursor-pointer rounded-full', { 'hover:opacity-80': onClick })} onClick={onClick}>
        {imageUrl && <AvatarImage src={imageUrl} alt={name || ''} className="rounded-full bg-slate-300" />}
        <AvatarFallback
          className={cn(
            'flex items-center justify-center h-full w-full text-white font-semibold text-[10px]',
            getColorFromName(name),
          )}
        >
          {getInitials(name)}
        </AvatarFallback>
      </Avatar>

      {showName && (
        <Tooltip title={name || ''}>
          <div
            className={cn('truncate max-w-[180px] cursor-pointer text-gray-600', { 'hover:underline': onClick })}
            onClick={onClick}
          >
            {name || ''}
          </div>
        </Tooltip>
      )}
    </div>
  );
});

NameCell.displayName = 'NameCell';
export default NameCell;
