/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1g08w_157 {
  display: flex;
}

._justify-content-space-between_1g08w_161 {
  justify-content: space-between;
}

._tabular-nums_1g08w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1g08w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1g08w_157 {
  display: flex;
}

._justify-content-space-between_1g08w_161 {
  justify-content: space-between;
}

._tabular-nums_1g08w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1g08w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._StartProjectButton_1g08w_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._StartProjectButton_1g08w_355 {
  padding-left: 0.9375rem;
}
._StartProjectButton_1g08w_355[disabled] {
  color: #8f8d91;
}
._StartProjectButton_1g08w_355 ._icon_1g08w_371 {
  margin-left: 0.25rem;
}
._StartProjectButton_1g08w_355:focus {
  border-color: transparent !important;
}

._overlay_1g08w_378 {
  background-color: #fff;
}
._overlay_1g08w_378 .ant-dropdown-menu {
  padding: 0 !important;
  margin: 0 !important;
}

._label_1g08w_386 {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  height: 2rem;
}
._label_1g08w_386 ._thumb_1g08w_393 {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}

._menu_1g08w_401 {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 15rem;
  max-height: 23.875rem;
  padding-bottom: 0 !important;
}
._menu_1g08w_401._ant-dropdown-menu_1g08w_381,
._menu_1g08w_401 .ant-dropdown-menu {
  padding: 0 !important;
  margin: 0 !important;
}
._menu_1g08w_401 .ant-dropdown-menu-submenu-expand-icon {
  top: 50%;
  transform: translateY(-50%);
}
._menu_1g08w_401 ._createProjectItem_1g08w_418 {
  position: sticky;
  bottom: 0;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem !important;
  border-top: 0.0625rem solid #ececec;
  background-color: #fdfdfd;
}
._menu_1g08w_401 ._createProjectItem_1g08w_418:first-of-type {
  margin-top: 0;
}
._menu_1g08w_401 ._createProjectItem_1g08w_418:hover {
  color: #167cf4;
}

._projectTitleItem_1g08w_433 {
  max-width: 15rem;
  white-space: normal;
}

._searchSection_1g08w_438 {
  padding: 0.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._searchError_1g08w_445 {
  color: var(--error-color);
  font-size: 12px;
  padding: 0.75rem 1rem;
  margin: 0;
  width: 100%;
}

._divider_1g08w_453 {
  border-bottom: 1px solid #ececec;
}