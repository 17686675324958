import React from 'react';
import { useGetAllWorkletsForParentSpecQuery } from '@frontend/app/hooks';
import { WorkflowStagesSection } from '../sections/WorkflowStagesSection';
import { useInitializeCampaign } from '../hooks/useInitializeCampaign';
import { TProject } from '../../types';
import { SaveCampaignCTA } from '../components/SaveCampaignCTA';

interface Props {
  mode?: 'add' | 'edit';
  project?: TProject;
  onSave?: () => void;
  refetchProjects?: () => void;
}

export const WorkflowTab: React.FC<Props> = ({
  mode = 'edit',
  project,
  onSave,
  refetchProjects,
}) => {
  const {
    data: {
      worklets: defaultWorklets = undefined,
    } = {},
  } = useGetAllWorkletsForParentSpecQuery({
    variables: {
      specKey: project?.specKey,
    },
    skip: !project?.specKey,
    fetchPolicy: 'network-only',
  });

  // Initialize campaign data
  useInitializeCampaign(mode, project);

  return (
    <div className="p-6">
      <div className="max-w-[800px] mx-auto">
        <SaveCampaignCTA
          mode={mode}
          project={project}
          onSave={onSave}
          refetchProjects={refetchProjects}
          title="Workflow Configuration"
        />
        <div className="mt-6">
          <WorkflowStagesSection
            defaultWorklets={defaultWorklets}
            parentSpecKey={project?.specKey}
            projectId={project?.id}
          />
        </div>
      </div>
    </div>
  );
};
