import { isEmpty } from 'lodash';
import * as qs from 'qs';

import { endpoints, ISocialAccount, useInviteContext } from '@components';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

interface IProps {
  apiEndpoint: string;
  id: ISocialAccount['id'];
}

/**
 * Fetch similar creators
 */
export const useSimilarCreators = (props: IProps) => {
  const {
    apiEndpoint,
    id,
  } = props;

  const inviteContext = useInviteContext();
  const { clientId = undefined } = inviteContext || {};

  const url = apiEndpoint && id
    ? `${apiEndpoint}/${endpoints.socialAccountEndpoint}?${qs.stringify({
      client_id: clientId,
      lookalike_account_id: id,
    })}`
    : '';

  const {
    isFetching,
    data,
    error,
  } = useBackendServerQuery<{ data: ISocialAccount[] }>({
    fetchParams: {
      input: url,
    },
    useQueryParams: {
      options: {
        enabled: !!url,
      },
    },
  });

  const similarCreators = !isEmpty(data?.data)
    ? Object.freeze(data?.data)
    : null;

  return {
    similarCreators,
    isFetching,
    error,
  };
};
