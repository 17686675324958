import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';

import { SpinnerThirdIcon } from '@revfluence/fresh-icons/regular/esm';
import * as Tooltip from '@radix-ui/react-tooltip';
import { cn } from '@/shadcn/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90 rounded-lg',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90 rounded-lg',
        outline: 'text-primary border border-input bg-grey-0 hover:bg-grey-1 hover:border-grey-5 hover:text-grey-5 rounded-lg',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded-lg',
        ghost: 'hover:bg-accent hover:text-accent-foreground rounded-lg',
        link: 'text-primary underline-offset-4 hover:underline rounded-lg',
        outlineHeader:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground bg-transparent text-primary-foreground hover:bg-transparent hover:text-primary-foreground/80 rounded-lg hover:bg-opacity-10',
        primary: 'bg-primary text-primary-foreground hover:bg-primary/90 rounded-lg',
        success: 'bg-success text-success-foreground hover:bg-success/80',
        warning: 'bg-warning text-warning-foreground hover:bg-warning/80',
        destructiveOutline: 'border border-destructive text-destructive hover:bg-destructive/10 rounded-lg',
      },
      size: {
        default: 'h-8 px-4 py-1 text-sm',
        xs: 'h-6 rounded px-2 text-xs leading-tight',
        sm: 'h-8 rounded-lg px-3 text-xs leading-tight',
        lg: 'h-10 rounded-lg px-8',
        icon: 'h-9 w-9',
        headerIcon: 'h-8 w-8',
        smallIcon: 'h-6 w-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  tooltip?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    className,
    variant,
    size,
    asChild = false,
    children,
    loading,
    disabled,
    tooltip,
    ...props
  }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const buttonElement = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? <SpinnerThirdIcon className="h-4 w-4 animate-spin" /> : null}
        <Slottable>{children}</Slottable>
      </Comp>
    );

    return tooltip ? (
      <Tooltip.Provider delayDuration={200}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className={disabled ? 'cursor-not-allowed' : ''}>{buttonElement}</div>
          </Tooltip.Trigger>
          <Tooltip.Content className="bg-gray-800 text-white text-xs rounded-md px-2 py-1">
            {tooltip}
            <Tooltip.Arrow className="fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Root>
      </Tooltip.Provider>
    ) : (
      buttonElement
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
