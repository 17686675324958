import React, { useMemo, useEffect } from 'react';
import { FolderUserIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { Link } from 'react-router-dom';
import { useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@/shadcn/components/ui/tooltip';
import { Button } from '@/shadcn/components/ui/button';
import { SecondaryNavProps } from '../../Layout';
import {
    MenuCount,
  MenuHeader,
  MenuHeaderLayout,
  MenuIconWrapper,
  MenuItemContent,
  MenuLayout,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuTitle,
  MenuSearch,
} from '../menuLayout';

export const SecondaryMembersNav = ({ onItemSelected, groups }: SecondaryNavProps) => {
  const {
    searchText,
    inputValue,
    handleSearchChange: handleSearchChangeFromHook,
    error: searchError,
    checkNoResults,
  } = useSearchWithDebounce();

  const handleSearchChange = (value: string) => {
    handleSearchChangeFromHook({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
  };

  const filteredGroups = useMemo(() =>
    groups.filter((group) =>
      group.title?.toLowerCase().includes(searchText.toLowerCase())),
    [groups, searchText]);

  useEffect(() => {
    checkNoResults(filteredGroups.length > 0);
  }, [filteredGroups.length, checkNoResults]);

  return (
    <MenuLayout>
      <MenuHeaderLayout>
        <MenuHeader>Members</MenuHeader>
      </MenuHeaderLayout>
      <MenuList>
        <MenuListItem>
          <MenuLink onClick={onItemSelected} to="/member_table?&switchSourcing=true">
            <MenuIconWrapper>
              <FolderUserIcon className="h-4 w-4 m-auto" />
            </MenuIconWrapper>
            <MenuItemContent>
              <MenuTitle>All Contacts</MenuTitle>
            </MenuItemContent>
          </MenuLink>
        </MenuListItem>
      </MenuList>
      <MenuHeaderLayout>
        <MenuHeader>Groups</MenuHeader>
        <Button
          size="xs"
          variant="outline"
          className="ml-auto text-muted-foreground hover:text-foreground group gap-1.5"
          onClick={onItemSelected}
          asChild
        >
          <Link to="/communities/new">
            <PlusIcon className="h-3 w-3 text-muted-foreground group-hover:text-foreground" />
            <span className="text-muted-foreground group-hover:text-foreground">New</span>
          </Link>
        </Button>
      </MenuHeaderLayout>
      <MenuSearch
        value={inputValue}
        onChange={handleSearchChange}
        placeholder="Search groups..."
        entity="groups"
      />
      <TooltipProvider>
        <MenuList>
          {searchError ? (
            <div
              className="text-error text-xs px-4 py-3 m-0 w-full"
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <MenuListItem>
                <MenuItemContent>
                  <MenuTitle>{searchError}</MenuTitle>
                </MenuItemContent>
              </MenuListItem>
            </div>
          ) : (
            filteredGroups.map((group) => (
              <MenuListItem key={group.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <MenuLink onClick={onItemSelected} to={`/member_table?communityId=${group.id}`}>
                      <Avatar className="h-9 w-9 flex">
                        <AvatarImage src={group?.splashImageUrl} alt="Avatar" className="object-cover" />
                        <AvatarFallback className="rounded-md">{group?.title?.charAt(0)}</AvatarFallback>
                      </Avatar>
                      <MenuItemContent>
                        <MenuTitle>{group?.title}</MenuTitle>
                      </MenuItemContent>
                      <MenuCount>{group?.memberCount}</MenuCount>
                    </MenuLink>
                  </TooltipTrigger>
                  <TooltipContent>{group?.title}</TooltipContent>
                </Tooltip>
              </MenuListItem>
            ))
          )}
        </MenuList>
      </TooltipProvider>
    </MenuLayout>
  );
};
