import React, { ReactNode } from 'react';

interface TimelineItem {
  id: number;
  title: string | ReactNode; // Title can be a string or a ReactNode
  description: string | ReactNode; // Description can be a string or a ReactNode
  icon?: ReactNode; // Optional icon for each event
}

interface TimelineProps {
  items: TimelineItem[];
}

const Timeline: React.FC<TimelineProps> = ({ items }) => (
  <div className="relative">
    {/* Vertical line for the timeline (dashed) */}
    <div className="absolute left-4 top-0 h-full border-l-2 border-grey-3 border-dashed" />
    <ul className="space-y-6">
      {items.map((item) => (
        <li key={item.id} className="relative pl-10">
          {/* Centered icon for each event on the line */}
          <div className="absolute left-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center">
            {item.icon ? (
              <div className="text-blue-500">{item.icon}</div>
            ) : (
              <div className="h-4 w-4 rounded-full bg-gray-300" />
            )}
          </div>

          {/* Event title and description */}
          <div className="flex flex-col gap-1">
            <span className="font-normal text-sm text-grey-6">{item.title}</span>
            <span className="font-normal text-xs text-grey-4">{item.description}</span>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default Timeline;
