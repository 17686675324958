import 'css-chunk:src/applications/ReportsApp/pages/Reporting/Reporting.scss';export default {
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "pagePadding": "_pagePadding_4jcwm_19",
  "chartLabel": "_chartLabel_4jcwm_49",
  "cardLayout": "_cardLayout_4jcwm_57"
};