import * as React from 'react';
import moment from 'moment';
import {
 first, isArray, isFunction, isString, map,
} from 'lodash';
import { localToUTC, utcToLocal } from '@frontend/app/utils/date';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { Button } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { CalendarIcon } from '@revfluence/fresh-icons/regular/esm';
import { format } from 'date-fns';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';

const { useCallback, useMemo } = React;

interface IDatePickerProps {
  value: Date;
  onChange?(value: Date);
}
const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  const handleChangeDate = useCallback((date: moment.Moment) => {
    if (isFunction(onChange)) {
      if (date) {
        onChange(localToUTC(date.toDate()));
      } else {
        onChange(null);
      }
    }
  }, [onChange]);

  const date = useMemo(() => {
    const val = isArray(value) ? first(value) : value;
    return val
      ? moment(utcToLocal(new Date(val)))
      : null;
  }, [value]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'w-full justify-start text-left',
            !date && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date && format(date.toDate(), 'MMM dd')}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-[9999] pointer-events-auto">
        <Calendar
          mode="single"
          selected={date?.toDate()}
          onSelect={(date) => handleChangeDate(moment(date))}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

interface IProps {
  value: [Date, Date];
  onChange?(value: [Date, Date]);
}
export const DateBetween: React.FC<IProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  if (value instanceof Date) {
    onChange([localToUTC(value), localToUTC(value)]);
  } else if (isString(value)) {
    onChange([localToUTC(new Date(value)), localToUTC(new Date(value))]);
  }

  const handleChangeDate = useCallback((idx: number, newDate: moment.Moment) => {
    if (isFunction(onChange)) {
      if (newDate) {
        const newDates = map(value || [], (date) => (
          localToUTC(date)
        ));
        newDates[idx] = localToUTC(newDate.toDate());
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        onChange(newDates);
      } else {
        onChange(null);
      }
    }
  }, [onChange, value]);

  const dates: [moment.Moment, moment.Moment] = useMemo(() => (
    [
      moment(utcToLocal(value?.[0] ? new Date(value[0]) : new Date())),
      moment(utcToLocal(value?.[1] ? new Date(value[1]) : new Date())),
    ]
  ), [value]);

  return (
    <div className="flex items-center gap-2">
      <DatePicker
        value={dates?.[0]?.toDate()}
        onChange={(date) => handleChangeDate(0, moment(date))}
      />
      <span>and</span>
      <DatePicker
        value={dates?.[1]?.toDate()}
        onChange={(date) => handleChangeDate(1, moment(date))}
      />
    </div>
  );
};
