import { toast } from '@frontend/shadcn/hooks/use-toast';
import { cn } from '@frontend/shadcn/lib/utils';
import { logger } from '@common';

/**
 * Exports an HTML element as a PDF document
 * @param selector CSS selector for the element to export (e.g. '.drawer-content')
 * @param filenamePrefix Prefix for the filename (e.g. 'payment-details')
 * @returns Promise that resolves when the PDF has been generated
 */
export async function exportElementAsPdf(selector: string, filenamePrefix: string = 'document') {
  try {
    const element = document.querySelector(selector) as HTMLElement;
    if (!element) {
      throw new Error(`Could not find element with selector "${selector}" for PDF export`);
    }

    const html2canvas = (await import('html2canvas')).default;
    const JsPDF = (await import('jspdf')).default;

    // Save original scroll position and dimensions
    const originalScrollPos = {
      x: window.scrollX,
      y: window.scrollY
    };

    // Temporarily modify the element to ensure full capture
    const originalStyles = {
      maxHeight: element.style.maxHeight,
      overflow: element.style.overflow,
      height: element.style.height,
      position: element.style.position
    };

    // Remove any constraints that might clip content
    element.style.maxHeight = 'none';
    element.style.overflow = 'visible';
    element.style.height = 'auto';
    element.style.position = 'relative';

    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 2, // Higher resolution
      backgroundColor: '#ffffff',
      logging: false,
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
      // Ensure we capture the full element
      onclone: (clonedDoc) => {
        const clonedElement = clonedDoc.querySelector(selector) as HTMLElement;
        if (clonedElement) {
          clonedElement.style.maxHeight = 'none';
          clonedElement.style.overflow = 'visible';
          clonedElement.style.height = 'auto';
          clonedElement.style.position = 'relative';
        }
      }
    });

    // Restore original styles
    element.style.maxHeight = originalStyles.maxHeight;
    element.style.overflow = originalStyles.overflow;
    element.style.height = originalStyles.height;
    element.style.position = originalStyles.position;

    // Restore original scroll position
    window.scrollTo(originalScrollPos.x, originalScrollPos.y);

    // Calculate optimal PDF dimensions
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = imgWidth / imgHeight;

    // Create PDF with proper orientation based on ratio
    const orientation = ratio >= 1 ? 'l' : 'p';
    const pdf = new JsPDF(orientation, 'px', [imgWidth, imgHeight]);

    // Add the image to PDF document
    const imgData = canvas.toDataURL('image/jpeg', 0.95);
    pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);

    // Save the PDF
    pdf.save(`${filenamePrefix}-${new Date().toISOString().split('T')[0]}.pdf`);

    toast({
      variant: 'success',
      title: 'PDF saved',
      duration: 3000,
      className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
    });
  } catch (err) {
    logger.error({ message: err });
  }
}

/**
 * Enhanced PDF export function with additional options
 * @param selector CSS selector for the element to export
 * @param options Configuration options for the PDF export
 */
export async function exportElementAsPdfAdvanced(
  selector: string,
  options: {
    filenamePrefix?: string;
    showPageNumbers?: boolean;
    orientation?: 'portrait' | 'landscape';
    pageFormat?: string;
    title?: string;
    author?: string;
    subject?: string;
  } = {}
) {
  const {
    filenamePrefix = 'document',
    showPageNumbers = false,
    orientation,
    pageFormat = 'a4',
    title,
    author = 'System Generated',
    subject = 'Document Export',
  } = options;

  try {
    const element = document.querySelector(selector) as HTMLElement;
    if (!element) {
      throw new Error(`Could not find element with selector "${selector}" for PDF export`);
    }

    // Save original scroll position and dimensions
    const originalScrollPos = {
      x: window.scrollX,
      y: window.scrollY
    };

    // Temporarily modify the element to ensure full capture
    const originalStyles = {
      maxHeight: element.style.maxHeight,
      overflow: element.style.overflow,
      height: element.style.height,
      position: element.style.position
    };

    // Remove any constraints that might clip content
    element.style.maxHeight = 'none';
    element.style.overflow = 'visible';
    element.style.height = 'auto';
    element.style.position = 'relative';

    const [{ default: html2canvas }, { default: JsPDF }] = await Promise.all([
      import('html2canvas'),
      import('jspdf')
    ]);

    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 2,
      backgroundColor: '#ffffff',
      logging: false,
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
      onclone: (clonedDoc) => {
        const clonedElement = clonedDoc.querySelector(selector) as HTMLElement;
        if (clonedElement) {
          clonedElement.style.maxHeight = 'none';
          clonedElement.style.overflow = 'visible';
          clonedElement.style.height = 'auto';
          clonedElement.style.position = 'relative';
        }
      }
    });

    // Restore original styles
    element.style.maxHeight = originalStyles.maxHeight;
    element.style.overflow = originalStyles.overflow;
    element.style.height = originalStyles.height;
    element.style.position = originalStyles.position;

    // Restore original scroll position
    window.scrollTo(originalScrollPos.x, originalScrollPos.y);

    // Create PDF
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    // Determine orientation if not specified
    const pdfOrientation = orientation || (imgWidth > imgHeight ? 'landscape' : 'portrait');

    const pdf = new JsPDF({
      orientation: pdfOrientation,
      unit: 'mm',
      format: pageFormat,
    });

    // Add metadata
    const now = new Date();
    pdf.setProperties({
      title: title || `${filenamePrefix} - ${now.toLocaleDateString()}`,
      subject,
      author,
      creator: 'PDF Export Utility',
    });

    // Calculate how to fit the image on the page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Scale factor to fit width
    const scaleFactor = pdfWidth / imgWidth;
    const scaledHeight = imgHeight * scaleFactor;

    // If the content fits on one page
    if (scaledHeight <= pdfHeight) {
      pdf.addImage(
        canvas.toDataURL('image/jpeg', 1.0),
        'JPEG',
        0,
        0,
        pdfWidth,
        scaledHeight
      );
    } else {
      // Content needs multiple pages
      let heightLeft = scaledHeight;
      let position = 0;

      // First page
      pdf.addImage(
        canvas.toDataURL('image/jpeg', 1.0),
        'JPEG',
        0,
        position,
        pdfWidth,
        scaledHeight
      );
      heightLeft -= pdfHeight;

      // Additional pages
      while (heightLeft > 0) {
        position = heightLeft - scaledHeight;
        pdf.addPage();
        pdf.addImage(
          canvas.toDataURL('image/jpeg', 1.0),
          'JPEG',
          0,
          position,
          pdfWidth,
          scaledHeight
        );
        heightLeft -= pdfHeight;
      }
    }

    // Add page numbers if requested
    if (showPageNumbers) {
      const pageCount = pdf.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100);
        pdf.text(`Page ${i} of ${pageCount}`, pdfWidth - 30, pdfHeight - 10);
      }
    }

    // Save the PDF
    pdf.save(`${filenamePrefix}-${now.toISOString().split('T')[0]}.pdf`);

    toast({
      variant: 'success',
      title: 'PDF saved',
      duration: 3000,
      className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
    });
  } catch (err) {
    logger.error({ message: err });
  }
}
