import * as React from 'react';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { GetProfileQuery_profile } from '@frontend/app/queries/types/GetProfileQuery';
import { TFormValues } from '@frontend/applications/AffiliatesApp/components';

const { useState } = React;
interface IOfferDetailsContext {
  offer: GetOfferById_offer;
  isOldOffer: boolean;
  setOffer: React.Dispatch<React.SetStateAction<GetOfferById_offer>>;
  isOfferLogDrawerVisible: boolean,
  setIsOfferLogDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  showMigrationModal: boolean,
  setShowMigrationModal: React.Dispatch<React.SetStateAction<boolean>>,
  disableOfferEdit: boolean,
  setDisableOfferEdit: React.Dispatch<React.SetStateAction<boolean>>,
  profile: GetProfileQuery_profile,
  offerFormValue: TFormValues,
  setOfferFormValue: React.Dispatch<React.SetStateAction<TFormValues>>;
}

const { useContext, useEffect } = React;

export const OfferDetailsContext = React.createContext<IOfferDetailsContext>(null);
export const useOfferDetailsContext = () => useContext(OfferDetailsContext);

export const OfferDetailsContextProvider: React.FC<React.PropsWithChildren<{ profile }>> = React.memo(
  ({ children, profile }) => {
    const [offer, setOffer] = useState<GetOfferById_offer>(null);
    const [offerFormValue, setOfferFormValue] = useState<TFormValues>(null);
    const [isOfferLogDrawerVisible, setIsOfferLogDrawerVisible] = useState<boolean>(false);
    const [showMigrationModal, setShowMigrationModal] = useState<boolean>(false);
    const [disableOfferEdit, setDisableOfferEdit] = useState<boolean>(false);
    const [isOldOffer, setIsOldOffer] = useState(false);

    useEffect(() => {
      let isMultiplePayoutOffer: boolean = false;
      let isNewFlowOffer: boolean = false;
      let source: OFFER_SOURCE = null;
      if (offer) {
        if (offer.links?.length) {
          isMultiplePayoutOffer = !!offer.links[0].defaultPayoutId;
          source = OFFER_SOURCE.TUNE;
        }
        if (offer.promos?.length) {
          isMultiplePayoutOffer = !!offer.promos[0].defaultPayoutId;
          isNewFlowOffer = offer.isNewFlow;
          source = OFFER_SOURCE.SHOPIFY;
        }
      }

      if (source === OFFER_SOURCE.SHOPIFY && (!isNewFlowOffer || !isMultiplePayoutOffer)) {
        setIsOldOffer(true);
        return;
      } else if (source === OFFER_SOURCE.TUNE && !isMultiplePayoutOffer) {
        setIsOldOffer(true);
        return;
      }
      setIsOldOffer(false);
    }, [offer]);

    // Set displayName within the functional component
    OfferDetailsContext.displayName = 'OfferDetailsProvider';

    return (
      <OfferDetailsContext.Provider
        value={{
          offer,
          isOldOffer,
          setOffer,
          isOfferLogDrawerVisible,
          setIsOfferLogDrawerVisible,
          showMigrationModal,
          setShowMigrationModal,
          disableOfferEdit,
          setDisableOfferEdit,
          profile,
          offerFormValue,
          setOfferFormValue,
        }}
      >
        {children}
      </OfferDetailsContext.Provider>
    );
  },
);
OfferDetailsContextProvider.displayName = 'OfferDetailsContextProvider';
