import * as React from 'react';

import { useMemberColumns } from '@frontend/app/components/MemberTable/hooks';
import { useParsedRouterSearch } from '@frontend/app/hooks';
import {
  IColumn,
  IColumnVisibility,
} from '@frontend/app/types/Columns';
import {
  TPredefinedSegment,
  TSegment,
} from '@frontend/app/types/MemberList';
import {
  IMemberFilter,
  IMemberSearchQuery,
  Operator,
} from '@frontend/app/types/MemberSearch';

import {
  IField,
  IMemberFieldsWithSources,
} from '../containers/Members/types/MemberFieldsWithSources';
import {
  ISortInfo,
  useColumnVisibility,
  useFilters,
  useMemberFieldsWithSources,
  useSearchQuery,
  useSegmentFilters,
  useSelectedSegment,
} from '../hooks/memberList';

const {
  useContext,
  useEffect,
  useRef,
  useState,
} = React;

type TFilter = IMemberFilter & { id: string };

export interface IMemberListContext {
  appsWithFields: IMemberFieldsWithSources['appsWithFields'];
  columns: IColumn[];
  columnVisibility: IColumnVisibility;
  fieldsWithSources: IField[];
  filters: TFilter[];
  predefinedSegments: TPredefinedSegment[];
  searchQuery: IMemberSearchQuery;
  segment: TSegment | TPredefinedSegment;
  segmentFilters: TFilter[];
  updateColumnVisibility(columnVisibility: IColumnVisibility);
  updateFilters(filters: TFilter[]);
  updateSortDirection(sortInfo: ISortInfo);
  updateSearchQuery(query: IMemberSearchQuery);
  updateSearchText(searchText: string);
  updateLogicalOperator(operator: Operator.AND | Operator.OR);
}

export const MemberListContext = React.createContext<IMemberListContext>(null);

export interface MemberListContextProviderProps {
  communityId?: number;
  sourcingGroupId?: string;
  programId?: number;
  isContact?: boolean;
  shouldUseQuickFilters?: boolean;
}

export const MemberListContextProvider: React.FC<MemberListContextProviderProps> = (props) => {
  const {
    children,
    communityId,
    programId,
    sourcingGroupId,
    isContact,
  } = props;

  const urlSearch = useParsedRouterSearch();
  const urlSearchRef = useRef();
  urlSearchRef.current = urlSearch;

  const {
    predefinedSegments,
    selectedSegment,
  } = useSelectedSegment(
    communityId,
    sourcingGroupId,
    programId,
    urlSearchRef,
    isContact,
  );

  const columns = useMemberColumns(communityId, programId);
  const [columnVisibility, setColumnVisibility] = useColumnVisibility(selectedSegment, columns);
  const segmentFilters = useSegmentFilters(selectedSegment);
  const [filters, setFilters] = useFilters(segmentFilters, urlSearchRef, selectedSegment);
  const [sortInfo, setSortInfo] = useState<ISortInfo>();
  const [searchText, setSearchText] = useState<string>();
  const [logicalOperator, setLogicalOperator] = useState<Operator.AND | Operator.OR>(Operator.AND);
  const filtersQuery = useSearchQuery(
    communityId,
    sourcingGroupId,
    selectedSegment,
    filters,
    sortInfo,
    searchText,
    isContact,
    logicalOperator,
  );
  const [searchQuery, setSearchQuery] = useState<IMemberSearchQuery>({});

  useEffect(() => {
    setSearchQuery(filtersQuery);
  }, [filtersQuery]);

  useEffect(() => {
    setSearchText('');
  }, [communityId, sourcingGroupId, filters, selectedSegment]);

  const {
    appsWithFields,
    fields: fieldsWithSources,
  } = useMemberFieldsWithSources(communityId, programId);

  const value: IMemberListContext = {
    appsWithFields,
    columns,
    columnVisibility,
    fieldsWithSources,
    filters,
    predefinedSegments,
    searchQuery,
    segment: selectedSegment,
    segmentFilters,
    updateColumnVisibility: setColumnVisibility,
    updateFilters: setFilters,
    updateSearchQuery: setSearchQuery,
    updateSortDirection: setSortInfo,
    updateSearchText: setSearchText,
    updateLogicalOperator: setLogicalOperator,
  };

  return (
    <MemberListContext.Provider value={value}>
      {children}
    </MemberListContext.Provider>
  );
};

export const useMemberListContext = () => useContext(MemberListContext);
