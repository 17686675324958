import { graphql } from '@frontend/gql/social';

export const getSocialAnalyticsPosts = graphql(`
  query GetSocialAnalyticsPosts(
    $clientId: String!
    $startDate: Date!
    $endDate: Date!
    $postTypes: [SocialPostType!]!
    $includeEstimates: Boolean!
    $networks: [SocialNetwork!]!
    $projectIds: [Int!]
    $groupIds: [Int!]
    $memberIds: [String!]
    $segments: [Segment!]
  ) {
    client(id: $clientId) {
      id
      socialAnalytics {
        totalInsights(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            memberIds: $memberIds
            segments: $segments
          }
        ) {
          ...MembersCard_TotalInsights
          ...ReachCard_TotalInsights
          ...EngagementCard_TotalInsights
          ...ImpressionsCard_TotalInsights
          ...PostsCard_TotalInsights
          ...TMVCard_TotalInsights
        }
        posts(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            memberIds: $memberIds
            segments: $segments
          }
        ) {
          pagination {
            totalCount
            totalPages
            currentPage
            limit
            hasNextPage
            hasPreviousPage
          }
          results {
            entityId
            postPermalink
            entityType
            entityNetwork
            member {
              id
              name
              instagramUsername
              tiktokUsername
              youtubeUsername
              pinterestUsername
            }
            total {
              reach
              impressions
              likes
              comments
              engagements
              views
              saves
              shares
            }
          }
        }
      }
    }
  }
`);

export const getAllSocialAnalyticsMembers = graphql(`
  query GetSocialAnalyticsMembers(
    $clientId: String!
    $startDate: Date!
    $endDate: Date!
    $postTypes: [SocialPostType!]!
    $includeEstimates: Boolean!
    $networks: [SocialNetwork!]!
    $projectIds: [Int!]
    $groupIds: [Int!]
    $segments: [Segment!]
  ) {
    client(id: $clientId) {
      id
      socialAnalytics {
        members(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            segments: $segments
          }
        ) {
          pagination {
            totalCount
            totalPages
            currentPage
            limit
            hasNextPage
            hasPreviousPage
          }
          results {
            memberId
            member {
              id
              name
              profilePicture
              instagramUsername
              pinterestUsername
              tiktokUsername
              youtubeUsername
            }
            total {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            instagram {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            instagramPost {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            instagramReel {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            instagramStory {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            pinterest {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            pinterestPin {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            tiktok {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }

            tiktokVideo {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            youtube {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }

            youtubeShort {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
            youtubeVideo {
              reach
              impressions
              likes
              comments
              engagements
              engagementRate
              followerGrowth
              numberOfPosts
              activePosts
              saves
              shares
              potentialReach
              latestPostDate
              latestUpdateDate
              tmv
              views
            }
          }
        }
      }
    }
  }
`);

export const getSocialAnalytics = graphql(`
  query GetSocialAnalytics(
    $clientId: String!
    $startDate: Date!
    $endDate: Date!
    $postTypes: [SocialPostType!]!
    $includeEstimates: Boolean!
    $networks: [SocialNetwork!]!
    $projectIds: [Int!]
    $groupIds: [Int!]
    $segments: [Segment!]
    # Member basic info fields
    $includeName: Boolean = true
    $includeProfilePicture: Boolean = true
    $includeInstagramUsername: Boolean = true
    $includePinterestUsername: Boolean = true
    $includeTiktokUsername: Boolean = true
    $includeYoutubeUsername: Boolean = true
    # Total metrics
    $includeTotalReach: Boolean = false
    $includeTotalImpressions: Boolean = false
    $includeTotalLikes: Boolean = false
    $includeTotalComments: Boolean = false
    $includeTotalEngagements: Boolean = false
    $includeTotalEngagementRate: Boolean = false
    $includeTotalFollowerGrowth: Boolean = false
    $includeTotalNumberOfPosts: Boolean = false
    $includeTotalActivePosts: Boolean = false
    $includeTotalSaves: Boolean = false
    $includeTotalShares: Boolean = false
    $includeTotalPotentialReach: Boolean = false
    $includeTotalLatestPostDate: Boolean = false
    $includeTotalLatestUpdateDate: Boolean = false
    $includeTotalTmv: Boolean = false
    $includeTotalViews: Boolean = false
    # Instagram metrics
    $includeInstagramReach: Boolean = false
    $includeInstagramImpressions: Boolean = false
    $includeInstagramLikes: Boolean = false
    $includeInstagramComments: Boolean = false
    $includeInstagramEngagements: Boolean = false
    $includeInstagramEngagementRate: Boolean = false
    $includeInstagramFollowerGrowth: Boolean = false
    $includeInstagramNumberOfPosts: Boolean = false
    $includeInstagramActivePosts: Boolean = false
    $includeInstagramSaves: Boolean = false
    $includeInstagramShares: Boolean = false
    $includeInstagramPotentialReach: Boolean = false
    $includeInstagramLatestPostDate: Boolean = false
    $includeInstagramLatestUpdateDate: Boolean = false
    $includeInstagramTmv: Boolean = false
    $includeInstagramViews: Boolean = false
    # Instagram Post metrics
    $includeInstagramPostReach: Boolean = false
    $includeInstagramPostImpressions: Boolean = false
    $includeInstagramPostLikes: Boolean = false
    $includeInstagramPostComments: Boolean = false
    $includeInstagramPostEngagements: Boolean = false
    $includeInstagramPostEngagementRate: Boolean = false
    $includeInstagramPostFollowerGrowth: Boolean = false
    $includeInstagramPostNumberOfPosts: Boolean = false
    $includeInstagramPostActivePosts: Boolean = false
    $includeInstagramPostSaves: Boolean = false
    $includeInstagramPostShares: Boolean = false
    $includeInstagramPostPotentialReach: Boolean = false
    $includeInstagramPostLatestPostDate: Boolean = false
    $includeInstagramPostLatestUpdateDate: Boolean = false
    $includeInstagramPostTmv: Boolean = false
    $includeInstagramPostViews: Boolean = false
    # Instagram Reel metrics
    $includeInstagramReelReach: Boolean = false
    $includeInstagramReelImpressions: Boolean = false
    $includeInstagramReelLikes: Boolean = false
    $includeInstagramReelComments: Boolean = false
    $includeInstagramReelEngagements: Boolean = false
    $includeInstagramReelEngagementRate: Boolean = false
    $includeInstagramReelFollowerGrowth: Boolean = false
    $includeInstagramReelNumberOfPosts: Boolean = false
    $includeInstagramReelActivePosts: Boolean = false
    $includeInstagramReelSaves: Boolean = false
    $includeInstagramReelShares: Boolean = false
    $includeInstagramReelPotentialReach: Boolean = false
    $includeInstagramReelLatestPostDate: Boolean = false
    $includeInstagramReelLatestUpdateDate: Boolean = false
    $includeInstagramReelTmv: Boolean = false
    $includeInstagramReelViews: Boolean = false
    # Instagram Story metrics
    $includeInstagramStoryReach: Boolean = false
    $includeInstagramStoryImpressions: Boolean = false
    $includeInstagramStoryLikes: Boolean = false
    $includeInstagramStoryComments: Boolean = false
    $includeInstagramStoryEngagements: Boolean = false
    $includeInstagramStoryEngagementRate: Boolean = false
    $includeInstagramStoryFollowerGrowth: Boolean = false
    $includeInstagramStoryNumberOfPosts: Boolean = false
    $includeInstagramStoryActivePosts: Boolean = false
    $includeInstagramStorySaves: Boolean = false
    $includeInstagramStoryShares: Boolean = false
    $includeInstagramStoryPotentialReach: Boolean = false
    $includeInstagramStoryLatestPostDate: Boolean = false
    $includeInstagramStoryLatestUpdateDate: Boolean = false
    $includeInstagramStoryTmv: Boolean = false
    $includeInstagramStoryViews: Boolean = false
    # Pinterest metrics
    $includePinterestReach: Boolean = false
    $includePinterestImpressions: Boolean = false
    $includePinterestLikes: Boolean = false
    $includePinterestComments: Boolean = false
    $includePinterestEngagements: Boolean = false
    $includePinterestEngagementRate: Boolean = false
    $includePinterestFollowerGrowth: Boolean = false
    $includePinterestNumberOfPosts: Boolean = false
    $includePinterestActivePosts: Boolean = false
    $includePinterestSaves: Boolean = false
    $includePinterestShares: Boolean = false
    $includePinterestPotentialReach: Boolean = false
    $includePinterestLatestPostDate: Boolean = false
    $includePinterestLatestUpdateDate: Boolean = false
    $includePinterestTmv: Boolean = false
    $includePinterestViews: Boolean = false
    # Pinterest Pin metrics
    $includePinterestPinReach: Boolean = false
    $includePinterestPinImpressions: Boolean = false
    $includePinterestPinLikes: Boolean = false
    $includePinterestPinComments: Boolean = false
    $includePinterestPinEngagements: Boolean = false
    $includePinterestPinEngagementRate: Boolean = false
    $includePinterestPinFollowerGrowth: Boolean = false
    $includePinterestPinNumberOfPosts: Boolean = false
    $includePinterestPinActivePosts: Boolean = false
    $includePinterestPinSaves: Boolean = false
    $includePinterestPinShares: Boolean = false
    $includePinterestPinPotentialReach: Boolean = false
    $includePinterestPinLatestPostDate: Boolean = false
    $includePinterestPinLatestUpdateDate: Boolean = false
    $includePinterestPinTmv: Boolean = false
    $includePinterestPinViews: Boolean = false
    # TikTok metrics
    $includeTiktokReach: Boolean = false
    $includeTiktokImpressions: Boolean = false
    $includeTiktokLikes: Boolean = false
    $includeTiktokComments: Boolean = false
    $includeTiktokEngagements: Boolean = false
    $includeTiktokEngagementRate: Boolean = false
    $includeTiktokFollowerGrowth: Boolean = false
    $includeTiktokNumberOfPosts: Boolean = false
    $includeTiktokActivePosts: Boolean = false
    $includeTiktokSaves: Boolean = false
    $includeTiktokShares: Boolean = false
    $includeTiktokPotentialReach: Boolean = false
    $includeTiktokLatestPostDate: Boolean = false
    $includeTiktokLatestUpdateDate: Boolean = false
    $includeTiktokTmv: Boolean = false
    $includeTiktokViews: Boolean = false
    # TikTok Video metrics
    $includeTiktokVideoReach: Boolean = false
    $includeTiktokVideoImpressions: Boolean = false
    $includeTiktokVideoLikes: Boolean = false
    $includeTiktokVideoComments: Boolean = false
    $includeTiktokVideoEngagements: Boolean = false
    $includeTiktokVideoEngagementRate: Boolean = false
    $includeTiktokVideoFollowerGrowth: Boolean = false
    $includeTiktokVideoNumberOfPosts: Boolean = false
    $includeTiktokVideoActivePosts: Boolean = false
    $includeTiktokVideoSaves: Boolean = false
    $includeTiktokVideoShares: Boolean = false
    $includeTiktokVideoPotentialReach: Boolean = false
    $includeTiktokVideoLatestPostDate: Boolean = false
    $includeTiktokVideoLatestUpdateDate: Boolean = false
    $includeTiktokVideoTmv: Boolean = false
    $includeTiktokVideoViews: Boolean = false
    # YouTube metrics
    $includeYoutubeReach: Boolean = false
    $includeYoutubeImpressions: Boolean = false
    $includeYoutubeLikes: Boolean = false
    $includeYoutubeComments: Boolean = false
    $includeYoutubeEngagements: Boolean = false
    $includeYoutubeEngagementRate: Boolean = false
    $includeYoutubeFollowerGrowth: Boolean = false
    $includeYoutubeNumberOfPosts: Boolean = false
    $includeYoutubeActivePosts: Boolean = false
    $includeYoutubeSaves: Boolean = false
    $includeYoutubeShares: Boolean = false
    $includeYoutubePotentialReach: Boolean = false
    $includeYoutubeLatestPostDate: Boolean = false
    $includeYoutubeLatestUpdateDate: Boolean = false
    $includeYoutubeTmv: Boolean = false
    $includeYoutubeViews: Boolean = false
    # YouTube Short metrics
    $includeYoutubeShortReach: Boolean = false
    $includeYoutubeShortImpressions: Boolean = false
    $includeYoutubeShortLikes: Boolean = false
    $includeYoutubeShortComments: Boolean = false
    $includeYoutubeShortEngagements: Boolean = false
    $includeYoutubeShortEngagementRate: Boolean = false
    $includeYoutubeShortFollowerGrowth: Boolean = false
    $includeYoutubeShortNumberOfPosts: Boolean = false
    $includeYoutubeShortActivePosts: Boolean = false
    $includeYoutubeShortSaves: Boolean = false
    $includeYoutubeShortShares: Boolean = false
    $includeYoutubeShortPotentialReach: Boolean = false
    $includeYoutubeShortLatestPostDate: Boolean = false
    $includeYoutubeShortLatestUpdateDate: Boolean = false
    $includeYoutubeShortTmv: Boolean = false
    $includeYoutubeShortViews: Boolean = false
    # YouTube Video metrics
    $includeYoutubeVideoReach: Boolean = false
    $includeYoutubeVideoImpressions: Boolean = false
    $includeYoutubeVideoLikes: Boolean = false
    $includeYoutubeVideoComments: Boolean = false
    $includeYoutubeVideoEngagements: Boolean = false
    $includeYoutubeVideoEngagementRate: Boolean = false
    $includeYoutubeVideoFollowerGrowth: Boolean = false
    $includeYoutubeVideoNumberOfPosts: Boolean = false
    $includeYoutubeVideoActivePosts: Boolean = false
    $includeYoutubeVideoSaves: Boolean = false
    $includeYoutubeVideoShares: Boolean = false
    $includeYoutubeVideoPotentialReach: Boolean = false
    $includeYoutubeVideoLatestPostDate: Boolean = false
    $includeYoutubeVideoLatestUpdateDate: Boolean = false
    $includeYoutubeVideoTmv: Boolean = false
    $includeYoutubeVideoViews: Boolean = false
  ) {
    client(id: $clientId) {
      id
      socialAnalytics {
        totalInsights(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            segments: $segments
          }
        ) {
          ...MembersCard_TotalInsights
          ...ReachCard_TotalInsights
          ...EngagementCard_TotalInsights
          ...ImpressionsCard_TotalInsights
        }
        publishedPostBreakdown(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            segments: $segments
          }
        ) {
          range {
            precision
            startDate
            endDate
          }
          breakdown {
            date
            network
            count
          }
          ...PostsCard_PublishedPostBreakdown
        }
        activeMemberBreakdown(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            segments: $segments
          }
        ) {
          segment
          count
          ...MemberDistribution_ActiveMemberBreakdown
        }
        members(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
            projectIds: $projectIds
            groupIds: $groupIds
            segments: $segments
          }
        ) {
          pagination {
            totalCount
            totalPages
            currentPage
            limit
            hasNextPage
            hasPreviousPage
          }
          results {
            memberId
            member {
              id
              name @include(if: $includeName)
              profilePicture @include(if: $includeProfilePicture)
              instagramUsername @include(if: $includeInstagramUsername)
              pinterestUsername @include(if: $includePinterestUsername)
              tiktokUsername @include(if: $includeTiktokUsername)
              youtubeUsername @include(if: $includeYoutubeUsername)
            }
            total {
              reach @include(if: $includeTotalReach)
              impressions @include(if: $includeTotalImpressions)
              likes @include(if: $includeTotalLikes)
              comments @include(if: $includeTotalComments)
              engagements @include(if: $includeTotalEngagements)
              engagementRate @include(if: $includeTotalEngagementRate)
              followerGrowth @include(if: $includeTotalFollowerGrowth)
              numberOfPosts @include(if: $includeTotalNumberOfPosts)
              activePosts @include(if: $includeTotalActivePosts)
              saves @include(if: $includeTotalSaves)
              shares @include(if: $includeTotalShares)
              potentialReach @include(if: $includeTotalPotentialReach)
              latestPostDate @include(if: $includeTotalLatestPostDate)
              latestUpdateDate @include(if: $includeTotalLatestUpdateDate)
              tmv @include(if: $includeTotalTmv)
              views @include(if: $includeTotalViews)
            }
            instagram {
              reach @include(if: $includeInstagramReach)
              impressions @include(if: $includeInstagramImpressions)
              likes @include(if: $includeInstagramLikes)
              comments @include(if: $includeInstagramComments)
              engagements @include(if: $includeInstagramEngagements)
              engagementRate @include(if: $includeInstagramEngagementRate)
              followerGrowth @include(if: $includeInstagramFollowerGrowth)
              numberOfPosts @include(if: $includeInstagramNumberOfPosts)
              activePosts @include(if: $includeInstagramActivePosts)
              saves @include(if: $includeInstagramSaves)
              shares @include(if: $includeInstagramShares)
              potentialReach @include(if: $includeInstagramPotentialReach)
              latestPostDate @include(if: $includeInstagramLatestPostDate)
              latestUpdateDate @include(if: $includeInstagramLatestUpdateDate)
              tmv @include(if: $includeInstagramTmv)
              views @include(if: $includeInstagramViews)
            }
            instagramPost {
              reach @include(if: $includeInstagramPostReach)
              impressions @include(if: $includeInstagramPostImpressions)
              likes @include(if: $includeInstagramPostLikes)
              comments @include(if: $includeInstagramPostComments)
              engagements @include(if: $includeInstagramPostEngagements)
              engagementRate @include(if: $includeInstagramPostEngagementRate)
              followerGrowth @include(if: $includeInstagramPostFollowerGrowth)
              numberOfPosts @include(if: $includeInstagramPostNumberOfPosts)
              activePosts @include(if: $includeInstagramPostActivePosts)
              saves @include(if: $includeInstagramPostSaves)
              shares @include(if: $includeInstagramPostShares)
              potentialReach @include(if: $includeInstagramPostPotentialReach)
              latestPostDate @include(if: $includeInstagramPostLatestPostDate)
              latestUpdateDate @include(if: $includeInstagramPostLatestUpdateDate)
              tmv @include(if: $includeInstagramPostTmv)
              views @include(if: $includeInstagramPostViews)
            }
            instagramReel {
              reach @include(if: $includeInstagramReelReach)
              impressions @include(if: $includeInstagramReelImpressions)
              likes @include(if: $includeInstagramReelLikes)
              comments @include(if: $includeInstagramReelComments)
              engagements @include(if: $includeInstagramReelEngagements)
              engagementRate @include(if: $includeInstagramReelEngagementRate)
              followerGrowth @include(if: $includeInstagramReelFollowerGrowth)
              numberOfPosts @include(if: $includeInstagramReelNumberOfPosts)
              activePosts @include(if: $includeInstagramReelActivePosts)
              saves @include(if: $includeInstagramReelSaves)
              shares @include(if: $includeInstagramReelShares)
              potentialReach @include(if: $includeInstagramReelPotentialReach)
              latestPostDate @include(if: $includeInstagramReelLatestPostDate)
              latestUpdateDate @include(if: $includeInstagramReelLatestUpdateDate)
              tmv @include(if: $includeInstagramReelTmv)
              views @include(if: $includeInstagramReelViews)
            }
            instagramStory {
              reach @include(if: $includeInstagramStoryReach)
              impressions @include(if: $includeInstagramStoryImpressions)
              likes @include(if: $includeInstagramStoryLikes)
              comments @include(if: $includeInstagramStoryComments)
              engagements @include(if: $includeInstagramStoryEngagements)
              engagementRate @include(if: $includeInstagramStoryEngagementRate)
              followerGrowth @include(if: $includeInstagramStoryFollowerGrowth)
              numberOfPosts @include(if: $includeInstagramStoryNumberOfPosts)
              activePosts @include(if: $includeInstagramStoryActivePosts)
              saves @include(if: $includeInstagramStorySaves)
              shares @include(if: $includeInstagramStoryShares)
              potentialReach @include(if: $includeInstagramStoryPotentialReach)
              latestPostDate @include(if: $includeInstagramStoryLatestPostDate)
              latestUpdateDate @include(if: $includeInstagramStoryLatestUpdateDate)
              tmv @include(if: $includeInstagramStoryTmv)
              views @include(if: $includeInstagramStoryViews)
            }
            pinterest {
              reach @include(if: $includePinterestReach)
              impressions @include(if: $includePinterestImpressions)
              likes @include(if: $includePinterestLikes)
              comments @include(if: $includePinterestComments)
              engagements @include(if: $includePinterestEngagements)
              engagementRate @include(if: $includePinterestEngagementRate)
              followerGrowth @include(if: $includePinterestFollowerGrowth)
              numberOfPosts @include(if: $includePinterestNumberOfPosts)
              activePosts @include(if: $includePinterestActivePosts)
              saves @include(if: $includePinterestSaves)
              shares @include(if: $includePinterestShares)
              potentialReach @include(if: $includePinterestPotentialReach)
              latestPostDate @include(if: $includePinterestLatestPostDate)
              latestUpdateDate @include(if: $includePinterestLatestUpdateDate)
              tmv @include(if: $includePinterestTmv)
              views @include(if: $includePinterestViews)
            }
            pinterestPin {
              reach @include(if: $includePinterestPinReach)
              impressions @include(if: $includePinterestPinImpressions)
              likes @include(if: $includePinterestPinLikes)
              comments @include(if: $includePinterestPinComments)
              engagements @include(if: $includePinterestPinEngagements)
              engagementRate @include(if: $includePinterestPinEngagementRate)
              followerGrowth @include(if: $includePinterestPinFollowerGrowth)
              numberOfPosts @include(if: $includePinterestPinNumberOfPosts)
              activePosts @include(if: $includePinterestPinActivePosts)
              saves @include(if: $includePinterestPinSaves)
              shares @include(if: $includePinterestPinShares)
              potentialReach @include(if: $includePinterestPinPotentialReach)
              latestPostDate @include(if: $includePinterestPinLatestPostDate)
              latestUpdateDate @include(if: $includePinterestPinLatestUpdateDate)
              tmv @include(if: $includePinterestPinTmv)
              views @include(if: $includePinterestPinViews)
            }
            tiktok {
              reach @include(if: $includeTiktokReach)
              impressions @include(if: $includeTiktokImpressions)
              likes @include(if: $includeTiktokLikes)
              comments @include(if: $includeTiktokComments)
              engagements @include(if: $includeTiktokEngagements)
              engagementRate @include(if: $includeTiktokEngagementRate)
              followerGrowth @include(if: $includeTiktokFollowerGrowth)
              numberOfPosts @include(if: $includeTiktokNumberOfPosts)
              activePosts @include(if: $includeTiktokActivePosts)
              saves @include(if: $includeTiktokSaves)
              shares @include(if: $includeTiktokShares)
              potentialReach @include(if: $includeTiktokPotentialReach)
              latestPostDate @include(if: $includeTiktokLatestPostDate)
              latestUpdateDate @include(if: $includeTiktokLatestUpdateDate)
              tmv @include(if: $includeTiktokTmv)
              views @include(if: $includeTiktokViews)
            }
            tiktokVideo {
              reach @include(if: $includeTiktokVideoReach)
              impressions @include(if: $includeTiktokVideoImpressions)
              likes @include(if: $includeTiktokVideoLikes)
              comments @include(if: $includeTiktokVideoComments)
              engagements @include(if: $includeTiktokVideoEngagements)
              engagementRate @include(if: $includeTiktokVideoEngagementRate)
              followerGrowth @include(if: $includeTiktokVideoFollowerGrowth)
              numberOfPosts @include(if: $includeTiktokVideoNumberOfPosts)
              activePosts @include(if: $includeTiktokVideoActivePosts)
              saves @include(if: $includeTiktokVideoSaves)
              shares @include(if: $includeTiktokVideoShares)
              potentialReach @include(if: $includeTiktokVideoPotentialReach)
              latestPostDate @include(if: $includeTiktokVideoLatestPostDate)
              latestUpdateDate @include(if: $includeTiktokVideoLatestUpdateDate)
              tmv @include(if: $includeTiktokVideoTmv)
              views @include(if: $includeTiktokVideoViews)
            }
            youtube {
              reach @include(if: $includeYoutubeReach)
              impressions @include(if: $includeYoutubeImpressions)
              likes @include(if: $includeYoutubeLikes)
              comments @include(if: $includeYoutubeComments)
              engagements @include(if: $includeYoutubeEngagements)
              engagementRate @include(if: $includeYoutubeEngagementRate)
              followerGrowth @include(if: $includeYoutubeFollowerGrowth)
              numberOfPosts @include(if: $includeYoutubeNumberOfPosts)
              activePosts @include(if: $includeYoutubeActivePosts)
              saves @include(if: $includeYoutubeSaves)
              shares @include(if: $includeYoutubeShares)
              potentialReach @include(if: $includeYoutubePotentialReach)
              latestPostDate @include(if: $includeYoutubeLatestPostDate)
              latestUpdateDate @include(if: $includeYoutubeLatestUpdateDate)
              tmv @include(if: $includeYoutubeTmv)
              views @include(if: $includeYoutubeViews)
            }
            youtubeShort {
              reach @include(if: $includeYoutubeShortReach)
              impressions @include(if: $includeYoutubeShortImpressions)
              likes @include(if: $includeYoutubeShortLikes)
              comments @include(if: $includeYoutubeShortComments)
              engagements @include(if: $includeYoutubeShortEngagements)
              engagementRate @include(if: $includeYoutubeShortEngagementRate)
              followerGrowth @include(if: $includeYoutubeShortFollowerGrowth)
              numberOfPosts @include(if: $includeYoutubeShortNumberOfPosts)
              activePosts @include(if: $includeYoutubeShortActivePosts)
              saves @include(if: $includeYoutubeShortSaves)
              shares @include(if: $includeYoutubeShortShares)
              potentialReach @include(if: $includeYoutubeShortPotentialReach)
              latestPostDate @include(if: $includeYoutubeShortLatestPostDate)
              latestUpdateDate @include(if: $includeYoutubeShortLatestUpdateDate)
              tmv @include(if: $includeYoutubeShortTmv)
              views @include(if: $includeYoutubeShortViews)
            }
            youtubeVideo {
              reach @include(if: $includeYoutubeVideoReach)
              impressions @include(if: $includeYoutubeVideoImpressions)
              likes @include(if: $includeYoutubeVideoLikes)
              comments @include(if: $includeYoutubeVideoComments)
              engagements @include(if: $includeYoutubeVideoEngagements)
              engagementRate @include(if: $includeYoutubeVideoEngagementRate)
              followerGrowth @include(if: $includeYoutubeVideoFollowerGrowth)
              numberOfPosts @include(if: $includeYoutubeVideoNumberOfPosts)
              activePosts @include(if: $includeYoutubeVideoActivePosts)
              saves @include(if: $includeYoutubeVideoSaves)
              shares @include(if: $includeYoutubeVideoShares)
              potentialReach @include(if: $includeYoutubeVideoPotentialReach)
              latestPostDate @include(if: $includeYoutubeVideoLatestPostDate)
              latestUpdateDate @include(if: $includeYoutubeVideoLatestUpdateDate)
              tmv @include(if: $includeYoutubeVideoTmv)
              views @include(if: $includeYoutubeVideoViews)
            }
          }
        }
      }
    }
  }
`);
