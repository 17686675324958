import * as React from 'react';
import cn from 'classnames';

import {
  Typography, Divider, Space, Badge, Tooltip,
} from '@revfluence/fresh';
import { ScaleBalancedIcon, ImageIcon, VideoIcon } from '@revfluence/fresh-icons/regular/esm';

import Statistics from '../../../../../components/Statistics';

import styles from '../TabContent.module.scss';
import { IInvestmentValues, IReportingPageProps } from '../../../Reporting';
import { ValueRow } from '../ValueRow';
import { InvestmentRow } from '../InvestmentRow';

const { Title, Text } = Typography;

interface IContentProps {
  contentValues: IReportingPageProps['contentValues'];
  investmentValue: IInvestmentValues;
  contentTotalValue: number;
}

const Content = ({
  contentValues, investmentValue, contentTotalValue,
}: IContentProps) => (
  <Space direction="vertical" className={cn(styles.TabContent)} size="small">
    <Title level={4}>Content Value</Title>
    <Title level={5}>
      <ScaleBalancedIcon style={{ marginRight: '8px' }} />
      How it's calculated
    </Title>
    <Divider />
    <Space
      direction="vertical"
      size={13}
      style={{
        overflowY: 'scroll', maxHeight: 300, paddingRight: 8, width: '100%',
      }}
    >
      <div>
        <Text type="secondary" weight="semibold">
          <ImageIcon />
          {' '}
          Images
        </Text>
        <ValueRow title="License" value={contentValues.images.broad} />
        <ValueRow title="Limited License" value={contentValues.images.broadLimited} />
        <ValueRow title="Custom License" value={contentValues.images.custom} />
        <ValueRow title="No License" value={contentValues.images.none} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <Tooltip title="Instagram Reels, TikTok videos, and YouTube Shorts">
            <VideoIcon />
            {' '}
            Short Videos
          </Tooltip>
        </Text>
        <ValueRow title="License" value={contentValues.shortVideos.broad} />
        <ValueRow title="Limited License" value={contentValues.shortVideos.broadLimited} />
        <ValueRow title="Custom License" value={contentValues.shortVideos.custom} />
        <ValueRow title="No License" value={contentValues.shortVideos.none} />
      </div>
      <div>
        <Text type="secondary" weight="semibold">
          <Tooltip title="YouTube Videos">
            <VideoIcon />
            {' '}
            Long Videos
          </Tooltip>
        </Text>
        <ValueRow title="License" value={contentValues.longVideos.broad} />
        <ValueRow title="Limited License" value={contentValues.longVideos.broadLimited} />
        <ValueRow title="Custom License" value={contentValues.longVideos.custom} />
        <ValueRow title="No License" value={contentValues.longVideos.none} />
      </div>
    </Space>

    <Divider />
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.blue)} />
        Content Total Value
      </Text>
      <Statistics value={contentTotalValue.toFixed(2)} prefix="$" size="default" />
    </Space>
    <Divider />
    <InvestmentRow values={investmentValue} />
    <Divider />
    <Space>
      <Title level={5}>
        Content Net Value
      </Title>
      <Title level={5}>
        <Statistics value={(contentTotalValue - investmentValue.total).toFixed(2)} prefix="$" size="large" strong />
      </Title>
    </Space>
  </Space>
);

export default Content;
