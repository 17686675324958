import React from 'react';
import { postTypeOptions, PostTypeValue } from '../../filters-form';

export const usePostTypeBadges = ({ postTypes }: { postTypes: PostTypeValue[] }) => {
  const postTypeBadges = React.useMemo(() => postTypeOptions.filter((postType) => postTypes.includes(postType.value)), [
    postTypes,
  ]);

  return {
    postTypeBadgesProps: {
      data: postTypeBadges,
    },
  };
};
