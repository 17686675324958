import React from 'react';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';

export const SectionCard: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Card className="border border-border shadow-none">
    <CardContent className="p-10">
      <div className="flex flex-col gap-6">
        {children}
      </div>
    </CardContent>
  </Card>
);
