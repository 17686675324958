import * as React from 'react';
// import { Select } from '../../containers/PayoutBreakdown/PayoutBreakdown';
import cx from 'classnames';

import { ICard } from '@frontend/applications/PaymentsApp/models';
import { Select, Typography } from '@revfluence/fresh';

import { useEffect, useState } from 'react';
import styles from './CreditCardSelectBox.scss';

const { Option } = Select;
const { Paragraph } = Typography;

interface ICreditCardSelectBox {
  selectedCard: ICard;
  creditCards: ICard[];
  onChange: (value) => void;
  disabled: boolean;
  alert: boolean;
}

export enum CREDIT_CARD_SELECT_OPTIONS {
  ADD_CARD = 'addCard',
}

export const CreditCardSelectBox: React.FC<Readonly<ICreditCardSelectBox>> = (props) => {
  const {
    creditCards,
    onChange,
    alert,
    disabled,
    selectedCard: cardSelected,
  } = props;
  const [selectedCard, setSelectedCard] = useState(cardSelected?.last4);
  useEffect(() => {
    if (cardSelected) {
      setSelectedCard(cardSelected?.last4);
    } else if (creditCards?.length > 0) {
      setSelectedCard(creditCards[0]?.last4);
    }
  }, [creditCards, cardSelected]);
  return (
    <div className={styles.CreditCardSelectBox}>
      <div className={styles.selectBox}>
        <Paragraph strong>
          Charge remaining balance to this card:
        </Paragraph>
        <Select
          className={cx([{ [styles.alertSelect]: alert }, { [styles.select]: !alert }])}
          showSearch
          disabled={disabled}
          style={{ width: 200 }}
          placeholder="Select card"
          optionFilterProp="children"
          onSelect={onChange}
          value={selectedCard}
        >
          <Option value={CREDIT_CARD_SELECT_OPTIONS.ADD_CARD}>Add a new credit card</Option>
          {creditCards.map((card) => (
            <Option key={card.last4} value={card.last4}>{`${card.brand} ending in ${card.last4}`}</Option>
          ))}
        </Select>
      </div>
    </div>
  );
};
