import gql from 'graphql-tag';

export const GET_OFFERS_FOR_SELECTION_QUERY = gql`
  query GetOffersForSelectionQuery($query: OfferSearchQuery) {
    offers(query: $query) {
      id
      createdDate
      imageUrl
      icon
      expired
      programId
      archivedDate
      links {
        id
        status
      }
      name
      promos {
        id
        status
      }
    }
  }
`;
