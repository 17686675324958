import * as React from 'react';

import { useCommunitiesQuery, useFuzzySearchByKeys } from '@frontend/app/hooks';
import { useMemberPageContext } from '@frontend/app/containers/Members/hooks';
import { SelectList } from '../../SelectList';

interface IValue {
  communityIds: number[];
}

interface IProps {
  defaultValue: IValue;
  onChange(value: IValue);
}

export const CommunityInclusion: React.FC<IProps> = (props) => {
  const memberPageContext = useMemberPageContext();

  const {
    communityId,
  } = memberPageContext || {};

  const {
    data: {
      communities: communitiesOptions,
    } = {},
  } = useCommunitiesQuery();

  const handleSearch = useFuzzySearchByKeys(communitiesOptions, ['title']);

  const handleChange = (selectedCommunityIds: number[]) => {
    props.onChange({
      ...props.defaultValue,
      communityIds: selectedCommunityIds,
    });
  };

  type TOption = typeof communitiesOptions[0];

  return communityId ? null : (
    <SelectList
      showSearch
      onSearchRequest={handleSearch}
      options={communitiesOptions}
      defaultSelectedIds={props.defaultValue ? props.defaultValue.communityIds : []}
      mapOptionToId={(option: TOption) => option.id}
      mapOptionToLabel={(option: TOption) => option.title}
      onChange={handleChange}
    />
  );
};
