import React from 'react';
import { SpinnerThirdIcon, ImageIcon } from '@revfluence/fresh-icons/regular/esm';
import { InstagramIcon, TiktokIcon, YoutubeIcon } from '@revfluence/fresh-icons/brands/esm';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemValue,
  TooltipArrow,
  TooltipContent,
} from '../tooltip';
import { useSocialMediaThumbnail } from '../../hooks/useSocialMediaThumbnail';
import { ContentTypeEnum } from '../../utils/getSocialMediaThumbnail';

interface SocialMediaThumbnailProps {
  url: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  alt?: string;
  onLoad?: () => void;
  onError?: (error: string) => void;
  loadThumbnail?: boolean;
}

const PlatformIcon = ({ type, className }: { type: ContentTypeEnum | null; className?: string }) => {
  switch (type) {
    case ContentTypeEnum.INSTAGRAM:
    case ContentTypeEnum.INSTAGRAM_REEL:
      return <InstagramIcon className={className} />;
    case ContentTypeEnum.TIKTOK:
      return <TiktokIcon className={className} />;
    case ContentTypeEnum.YOUTUBE:
    case ContentTypeEnum.YOUTUBE_SHORT:
      return <YoutubeIcon className={className} />;
    case ContentTypeEnum.IMAGE:
      return <ImageIcon className={className} />;
    default:
      return <ImageIcon className={className} />;
  }
};

const getContentTypeLabel = (type: ContentTypeEnum | null): string => {
  switch (type) {
    case ContentTypeEnum.INSTAGRAM:
      return 'Instagram post';
    case ContentTypeEnum.INSTAGRAM_REEL:
      return 'Instagram reel';
    case ContentTypeEnum.YOUTUBE:
      return 'YouTube video';
    case ContentTypeEnum.YOUTUBE_SHORT:
      return 'YouTube short';
    case ContentTypeEnum.TIKTOK:
      return 'TikTok video';
    case ContentTypeEnum.FACEBOOK:
      return 'Facebook post';
    case ContentTypeEnum.PINTEREST:
      return 'Pinterest pin';
    case ContentTypeEnum.IMAGE:
      return 'Image';
    default:
      return 'Content';
  }
};

const ThumbnailTooltip: React.FC<{
  contentType: ContentTypeEnum | null;
  title?: string;
  error?: string | null;
  children: React.ReactNode;
}> = ({ contentType, title, error, children }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger className="block w-full">{children}</TooltipTrigger>
      <TooltipContent>
        <TooltipHeader>
          <TooltipTitle>{title || getContentTypeLabel(contentType)}</TooltipTitle>
        </TooltipHeader>
        <TooltipBody>
          <TooltipItem>
            <TooltipItemValue>
              {error || `Click to view ${getContentTypeLabel(contentType).toLowerCase()}`}
            </TooltipItemValue>
          </TooltipItem>
        </TooltipBody>
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const IconPlaceholder: React.FC<{
  contentType: ContentTypeEnum | null;
  containerStyle: React.CSSProperties;
}> = ({ contentType, containerStyle }) => (
  <div style={containerStyle} className="bg-gray-50 flex items-center justify-center">
    <PlatformIcon type={contentType} className="h-8 w-8 text-gray-400" />
  </div>
);

const ThumbnailImage: React.FC<{
  src: string;
  alt: string;
  className?: string;
  style: React.CSSProperties;
  onLoad?: () => void;
  onError?: () => void;
}> = ({ src, alt, className, style, onLoad, onError }) => (
  <img src={src} alt={alt} className={className} style={style} onLoad={onLoad} onError={onError} />
);

export const SocialMediaThumbnail: React.FC<SocialMediaThumbnailProps> = ({
  url,
  className = '',
  width = 'auto',
  height = 'auto',
  alt = 'Social media thumbnail',
  onLoad,
  onError,
  loadThumbnail = true,
}) => {
  const { thumbnailUrl, isLoading, error, contentType, title } = useSocialMediaThumbnail(url);

  React.useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  const containerStyle = {
    width,
    height,
  };

  if (isLoading) {
    return (
      <div style={containerStyle} className="flex items-center justify-center">
        <SpinnerThirdIcon className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  const shouldShowIcon = !loadThumbnail || error || !thumbnailUrl;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="block w-full">
      <ThumbnailTooltip contentType={contentType} title={title} error={error}>
        {shouldShowIcon ? (
          <IconPlaceholder contentType={contentType} containerStyle={containerStyle} />
        ) : (
          <ThumbnailImage
            src={thumbnailUrl}
            alt={alt}
            className={className}
            style={{ ...containerStyle, objectFit: 'contain' }}
            onLoad={onLoad}
            onError={() => onError?.('Failed to load thumbnail')}
          />
        )}
      </ThumbnailTooltip>
    </a>
  );
};
