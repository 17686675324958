/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1399a_157 {
  display: flex;
}

._justify-content-space-between_1399a_161 {
  justify-content: space-between;
}

._tabular-nums_1399a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1399a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferFormPage_1399a_178 ._title_1399a_178 {
  color: #1a1818;
  font-weight: bold;
  font-size: 1.25rem;
}
._OfferFormPage_1399a_178 ._archiveOfferAlert_1399a_183 {
  margin-bottom: 0.5rem;
}

._updateHistoryWraper_1399a_187 {
  position: fixed;
  bottom: 1.5625rem;
  width: 30.75rem;
  z-index: 99;
}
._updateHistoryWraper_1399a_187 ._changeLogWrapper_1399a_193 .ant-collapse-header {
  border-radius: 6px 6px 0px 0px;
  background: var(--neutral-color-palette-gray-2, #FAFAFA);
  padding: 12px;
}
._updateHistoryWraper_1399a_187 .ant-collapse-content-box {
  padding: 0 16px;
  max-height: 25.625rem;
  overflow: scroll;
  height: auto;
}
._updateHistoryWraper_1399a_187 .ant-collapse-content {
  background: var(--neutral-color-palette-gray-1, #FFF);
  padding: 0px;
}
._updateHistoryWraper_1399a_187 ._updateLogContainer_1399a_208 {
  padding: 16px 0;
  border-bottom: 2px solid #D9D9D9;
}
._updateHistoryWraper_1399a_187 ._updateLogContainer_1399a_208:last-child {
  border-bottom: none;
}
._updateHistoryWraper_1399a_187 ._bottomsec_1399a_215 {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: var(--neutral-color-palette-gray-8, #505256);
}
._updateHistoryWraper_1399a_187 ._logContainer_1399a_221 strong {
  color: #1F1F21;
}

._offerDashboardPage_1399a_225 {
  border: 1px solid var(--gray-4);
  background-color: #f9fafb;
}
._offerDashboardPage_1399a_225 ._offerDashboardHeader_1399a_229 {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}