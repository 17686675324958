import React from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@frontend/shadcn/components/ui/accordion';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import {
  FormAsyncAction,
  IAffiliateLinksFormElementProps,
} from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { Form, Select } from '@revfluence/fresh';
import { OfferInputHeader } from '@frontend/applications/AffiliatesApp/components/OfferForm/FormSections/CommonSection/FormElements/RefreshForm/OfferElementHeader';
import { P } from '@frontend/shadcn/components/typography/p';
import styles from '../../../../../../../OfferForm.scss';

const { Option } = Select;

interface IProps extends IAffiliateLinksFormElementProps<'multipleDomain'> {
  isCreatorDeeplinkAllowed: boolean;
  initialAllowedDomains: string[];
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
}
export function RefreshDeepLink({
  disabled,
  name,
  isCreatorDeeplinkAllowed,
  initialAllowedDomains,
  handleFormAsyncActions,
}: IProps): JSX.Element {
  const handleCheckChange = (value: boolean) => {
    handleFormAsyncActions({ action: 'UpdateField', payload: { key: 'creatorDeeplink', value } });
  };

  return (
    <>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="deep-link">
          <AccordionTrigger className="hover:no-underline p-0 mt-2">
            <div className="flex flex-col gap-1">
              <P className="text-grey-6" fontWeight="font-medium">
                Deep Link Settings
              </P>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex items-center gap-2 mb-2">
              <Checkbox
                id="collection"
                checked={isCreatorDeeplinkAllowed}
                onCheckedChange={(checked) => handleCheckChange(checked === true)}
                disabled={disabled}
              />
              <label htmlFor="collection" className="text-sm font-medium text-primary">
                Allow creators to create deep links
              </label>
            </div>
            {isCreatorDeeplinkAllowed && (
              <>
                <OfferInputHeader
                  text="Additional Domains for deep links"
                  tooltipText="Additional Domains for deep links"
                />
                <Form.Item name={name}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select Additional Domain"
                    optionLabelProp="label"
                    size="middle"
                    className={styles.refreshDomainSelect}
                  >
                    {initialAllowedDomains?.map((domain) => (
                      <Option key={domain} value={domain} className={`${styles.refreshDomainOption} hover:!bg-grey-1`}>
                        {domain}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}
