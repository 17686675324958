import * as React from 'react';
import { DateRangeFormProps } from './date-range-form.types';
import { DateRangeFormTrigger } from './date-range-form-trigger';
import { DateRangeFormPresets } from './date-range-form-presets';
import { DateRangeFormCalendar } from './date-range-form-calendar';
import { DateRangeFormCalendarTrigger } from './date-range-form-calendar-trigger';
import { DateRangeFormPresetItem } from './date-range-form-preset-item';
import { DateRangeFormPopover } from './date-range-form-popover';
import { presetDateTypes } from './date-range-form.types';
import { useDateRangeForm } from './use-date-range-form';

/**
 * DateRangeForm is a composable date range selection component that combines preset options and a calendar picker.
 *
 * Component Structure:
 * - DateRangeFormPopover: Handles the dropdown UI and positioning
 *   - DateRangeFormTrigger: Main button that opens the dropdown
 *   - DateRangeFormPresets: Container for preset date range options
 *     - DateRangeFormPresetItem: Individual preset option buttons (e.g., This Week, Last Month)
 *   - DateRangeFormCalendar: Calendar picker for custom date selection
 *     - DateRangeFormCalendarTrigger: Button to toggle the calendar view
 *
 * State Management:
 * - All form state and handlers are managed by the useDateRangeForm hook
 * - The form supports both preset date ranges and custom date selection
 * - When a preset is selected, it automatically calculates and applies the date range
 * - When dates are selected in the calendar, it sets the type to 'custom'
 */
const DateRangeForm = React.forwardRef<HTMLDivElement, DateRangeFormProps>(
  ({ className, onApply, defaultValues, minDate, ...props }, ref) => {
    // Custom hook handles all form state and callbacks
    const {
      open,
      setOpen,
      calendarOpen,
      dateType,
      dateRange,
      handleOptionClick,
      handleDateRangeSelect,
      handleCalendarOpenChange,
    } = useDateRangeForm({ defaultValues, onApply, minDate });

    return (
      <div ref={ref} className={className} {...props}>
        {/* Main dropdown wrapper */}
        <DateRangeFormPopover
          open={open}
          onOpenChange={setOpen}
          trigger={<DateRangeFormTrigger dateRange={dateRange} open={open} />}
        >
          {/* Preset options section */}
          <DateRangeFormPresets>
            {presetDateTypes.map((type) => (
              <DateRangeFormPresetItem
                key={type}
                dateType={type}
                selected={type === dateType && !dateRange?.from}
                onClick={() => handleOptionClick(type)}
              />
            ))}
          </DateRangeFormPresets>
          {/* Calendar section for custom date selection */}
          <DateRangeFormCalendar
            dateRange={dateRange}
            onDateRangeSelect={handleDateRangeSelect}
            open={calendarOpen}
            onOpenChange={handleCalendarOpenChange}
            minDate={minDate}
          >
            <DateRangeFormCalendarTrigger dateRange={dateRange} open={calendarOpen} />
          </DateRangeFormCalendar>
        </DateRangeFormPopover>
      </div>
    );
  },
);

DateRangeForm.displayName = 'DateRangeForm';

export { DateRangeForm };
