import { Typography } from '@revfluence/fresh';
import { capitalize, map } from 'lodash';
import React, { useMemo } from 'react';
import Tags from './Tags';

const { Title } = Typography;
const { Text } = Typography;

interface MemberTag {
  name: string;
}

interface CreatorInfoProps {
  memberRecord: {
    name?: string;
    fields?: Record<string, string>;
    tags?: MemberTag[];
  };
  socialProfileData: {
    instagram?: { description: string }[];
    tiktok?: { description: string }[];
  } | null;
  applicantReviewFields: {
    Pronouns?: string;
    tags?: { tagName: string; tagColor: string }[];
  } | null;
}

const CreatorInfo: React.FC<CreatorInfoProps> = ({ memberRecord, socialProfileData, applicantReviewFields }) => {
  const tagsList = useMemo(() => map(memberRecord?.tags, (tag) => tag.name) || [], [memberRecord?.tags]);

  const eligibleTagsList = useMemo(() => applicantReviewFields?.tags || [], [applicantReviewFields?.tags]);

  const bioDescription = useMemo(
    () => (socialProfileData?.instagram?.[0]?.description || socialProfileData?.tiktok?.[0]?.description) ?? null,
    [socialProfileData],
  );

  const memberPronoun = useMemo(() => {
    if (!memberRecord || !applicantReviewFields) return null;
    const fieldToShow = applicantReviewFields.Pronouns;
    if (!fieldToShow || !memberRecord.fields?.[fieldToShow]) return null;
    return memberRecord.fields[fieldToShow];
  }, [memberRecord, applicantReviewFields]);

  return (
    <>
      <Title level={3}>
        {memberRecord?.name}
        {memberPronoun && <i>({memberPronoun})</i>}
      </Title>
      <Tags tags={tagsList} eligibleTags={eligibleTagsList} />
      {bioDescription && <Text style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>{capitalize(bioDescription)}</Text>}
    </>
  );
};

export default CreatorInfo;
