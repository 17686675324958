import * as React from 'react';
import cx from 'classnames';
import { Tooltip, Tag } from '@revfluence/fresh';
import { EnvelopeOpenIcon } from '@revfluence/fresh-icons/regular/esm';
import { IconSize } from '@components';
import { formatMessageDateWithTime } from '@frontend/app/utils';
import { Tag as RefreshTag } from '@frontend/app/refresh-components';

import { GetThreadQuery_thread_messages as IMessage } from '@frontend/app/queries/types/GetThreadQuery';
import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';
import { ReplyIcon } from '@frontend/app/components/Icons/ReplyIcon';
import { useLastMessage } from '../../hooks/useLastMessage';

import styles from './LastMessageCell.scss';

export interface LastMessageCellProps {
  messageData: IMessage;
  member: IMember;
  onClick: (threadId: string) => void;
  isFlexTable?: boolean;
}

export const LastMessageCell = React.memo(({
  messageData,
  member,
  onClick,
  isFlexTable,
}: LastMessageCellProps) => {
  const lastMessage = useLastMessage(messageData, member);

  if (!lastMessage) {
    return null;
  }

  const {
    read, replierName, snippet, internalDate,
  } = lastMessage;

  const icon = read ? (
    <EnvelopeOpenIcon className={cx('anticon', styles.readIcon)} />
  ) : (
    <ReplyIcon size={IconSize.EXTRA_SMALL} className={cx('anticon', styles.unreadIcon)} />
  );

  const handleClick = () => {
    onClick(messageData.threadId);
  };

  const formattedDate = formatMessageDateWithTime(internalDate);
  const messageText = `${formattedDate} ${replierName}: ${snippet}`;

  if (isFlexTable) {
    return (
      <RefreshTag
        icon={icon}
        label={formattedDate}
        bgColor={read ? 'bg-grey-1' : 'bg-blue-1'}
        onClick={handleClick}
        tooltip={messageText}
      />
    );
  }

  return (
    <Tooltip title={messageText} placement="top">
      <Tag
        size="small"
        icon={icon}
        className={cx(styles.LastMessageCell, { [styles.unread]: !read })}
        onClick={handleClick}
      >
        {formattedDate}
      </Tag>
    </Tooltip>
  );
});
LastMessageCell.displayName = 'LastMessageCell';
