import {
  CompensationMethod,
  ContentPostTypes,
  DiscountType,
  PreferredCreatorTypes,
  ProgramTimePeriod,
  ValueType,
} from '../models/ProgramMetadata';
import { ISelectedColumns } from './SelectedColumns';
import { IApplicationFormFields } from './ApplicationFormFields';
import { IOnboardingTemplateConfigProperties } from './OnboardingTemplateConfigProperties';

export interface IProgramMetadataInput {
  id?: number;
  programId?: number;
  startDate: Date;
  endDate?: Date;
  budget?: number;
  budgetTimePeriod?: ProgramTimePeriod;
  salesGoal?: number;
  salesGoalTimePeriod?: ProgramTimePeriod;
  creatorDescription?: string[];
  creatorPreference?: PreferredCreatorTypes[];
  contentPrefPostTypes?: ContentPostTypes[];
  compensationMethod?: CompensationMethod;
  discountType?: DiscountType;
  commissionValue?: number;
  commissionValueType?: ValueType;
  discountValue?: number;
  discountValueType?: ValueType;
  creatorCount?: number;
  creatorsTimePeriod?: ProgramTimePeriod;
}

export interface IProgramInput {
  title?: string;
  communityId?: number;
  columns?: ISelectedColumns;
  applicationFormFields?: IApplicationFormFields;
  customLandingPagePath?: string;
  owner?: string;
  inviteEmailSubject?: string;
  inviteEmailText?: string;
  onboardingTemplateConfig?: IOnboardingTemplateConfigProperties;
  description?: string;
  splashImageUrl?: string;
  specKey?: string;
  published?: boolean;
  submittedForReview?: boolean;
  legacyLandingPage?: boolean;
  emailTemplateId?: number;
  hasUnpaidOffer?: boolean;
  gcrEnabled?: boolean;
  gcrApproverIds?: string[];
  gcrHideApprovalAction?: boolean;
  templateName?: string;
  applicationPageTemplateName?: string;
  isFlexibleSpec?: boolean;
  workletSpecKeys?: string[];
  programMetadata?: IProgramMetadataInput;
}
