import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import React from 'react';
import { Tag } from '@frontend/app/refresh-components';

const statusColor = {
  'Pending Request': {
      bgColor: 'bg-blue-2',
      textColor: 'text-[#2D5EA0]',
    },
    'Review Needed': {
      bgColor: 'bg-yellow-2',
      textColor: 'text-[#9C7512]',
    },
    'Processing Order': {
      bgColor: 'bg-blue-2',
      textColor: 'text-[#2D5EA0]',
    },
    'Delivering Order': {
      bgColor: 'bg-green-2',
      textColor: 'text-[#27593B]',
    },
};

export interface TOrder {
  title?: string;
  orderNumber?: string;
  productsCount?: number;
  dateSent?: string;
  totalCost?: number;
  trackingNumber?: string;
  orderStatus?: string;
  onClickOrder?: () => void;
}

const OrderCard: React.FC<TOrder> = (props) => {
  const {
    title,
    orderNumber,
    productsCount,
    dateSent,
    totalCost,
    trackingNumber,
    orderStatus,
  } = props;
  return (
    <Card className="p-4 bg-transparent rounded-lg flex flex-col gap-2">
      <div className="text-base font-medium">{title}</div>
      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Status</P>
        {orderStatus ? (
          <Tag
            label={orderStatus}
            bgColor={statusColor[orderStatus]?.bgColor}
            textColor={statusColor[orderStatus]?.textColor}
          />
        ) : (
          <P className="text-sm font-normal">-</P>
        )}
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Order Number</P>
        <P className="text-sm font-normal">{orderNumber || '-'}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Date Sent</P>
        <P className="text-sm font-normal">{dateSent || '-'}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Number of Products</P>
        <P className="text-sm font-normal">{productsCount || '-'}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Total Cost</P>
        <P className="text-sm font-normal">{`$${totalCost || 0}`}</P>
      </div>

      <div className="flex flex-row justify-between">
        <P className="text-sm font-normal text-grey-5">Tracking Number</P>
        <P className="text-sm font-normal">{trackingNumber || '-'}</P>
      </div>
    </Card>
  );
};

export default OrderCard;
