import { XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { GripVertical } from 'lucide-react';
import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

export interface SubListItem<T = {}> {
  id: string;
  content: ReactNode;
  isFixed?: boolean;
  isRemovable?: boolean;
  data: T;
}

export interface ListItem<T = {}> {
  id: string;
  content: ReactNode;
  isFixed?: boolean;
  isRemovable?: boolean;
  subItems?: SubListItem<T>[];
  data: T;
}

interface DraggableListProps<T = {}> {
  initialItems: ListItem<T>[];
  onItemsChange?: (items: ListItem<T>[]) => void;
  onItemRemoved?: (item: ListItem<T> | SubListItem<T>) => void;
}

const DraggableList = <T,>({ initialItems, onItemsChange, onItemRemoved }: DraggableListProps<T>) => {
  const [items, setItems] = useState<ListItem<T>[]>(initialItems);
  const listRef = useRef<HTMLDivElement>(null);
  const prevItemsLength = useRef(initialItems.length);

  useEffect(() => {
    if (items.length > prevItemsLength.current) {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    }
    prevItemsLength.current = items.length;
  }, [items]);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination, type, draggableId } = result;
    if (!destination) return;

    const draggedItem =
      type === 'item'
        ? items.find((item) => item.id === draggableId)
        : items.flatMap((item) => item.subItems ?? []).find((subItem) => subItem.id === draggableId);

    if (draggedItem?.isFixed) return;

    const updatedItems = [...items];

    if (type === 'item') {
      const destinationItem = items[destination.index];
      if (destinationItem?.isFixed) return;

      const [removed] = updatedItems.splice(source.index, 1);
      updatedItems.splice(destination.index, 0, removed);
      setItems(updatedItems);
      onItemsChange?.(updatedItems);
      return;
    }

    const sourceItemIndex = updatedItems.findIndex((item) => item.id === source.droppableId);
    const destItemIndex = updatedItems.findIndex((item) => item.id === destination.droppableId);

    if (sourceItemIndex === -1 || destItemIndex === -1) return;

    const sourceItem = updatedItems[sourceItemIndex];
    const destItem = updatedItems[destItemIndex];

    if (!sourceItem.subItems || !destItem.subItems) return;

    const destinationSubItem = destItem.subItems[destination.index];
    if (destinationSubItem?.isFixed) return;

    const sourceSubItems = [...sourceItem.subItems];
    const destSubItems = sourceItemIndex === destItemIndex ? sourceSubItems : [...destItem.subItems];

    const [movedItem] = sourceSubItems.splice(source.index, 1);
    destSubItems.splice(destination.index, 0, movedItem);

    updatedItems[sourceItemIndex] = {
      ...sourceItem,
      subItems: sourceSubItems,
    };

    if (sourceItemIndex !== destItemIndex) {
      updatedItems[destItemIndex] = {
        ...destItem,
        subItems: destSubItems,
      };
    }

    setItems(updatedItems);
    onItemsChange?.(updatedItems);
  };

  const removeItem = (id: string) => {
    const removedItem = items.find((item) => item.id === id);
    if (!removedItem) return;

    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    onItemsChange?.(updatedItems);
    onItemRemoved?.(removedItem);
  };

  const removeSubItem = (itemId: string, subItemId: string) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        const removedSubItem = item.subItems?.find((sub) => sub.id === subItemId);
        if (removedSubItem) {
          onItemRemoved?.(removedSubItem);
        }

        const filteredSubItems = item.subItems?.filter((sub) => sub.id !== subItemId) ?? [];
        return {
          ...item,
          subItems: filteredSubItems,
        };
      }
      return item;
    });
    setItems(updatedItems);
    onItemsChange?.(updatedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="items" type="item" direction="vertical">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={(el) => {
              provided.innerRef(el);
              listRef.current = el;
            }}
            className="space-y-2 overflow-auto max-h-96" // Adjust max height as needed
            role="list"
            aria-label="Draggable list"
          >
            {items.map((item, index) => (
              <div key={item.id} className="space-y-2" role="listitem">
                {item.isFixed ? (
                  <div
                    className="group bg-grey-1 px-3 py-2 rounded opacity-75 relative"
                    aria-label={`Fixed item ${index + 1}`}
                  >
                    <div>{item.content}</div>
                    {item.isRemovable && (
                      <button
                        onClick={() => removeItem(item.id)}
                        className="absolute top-1/2 -translate-y-1/2 right-2 w-4 h-4 text-gray-600 hover:text-gray-900 hidden group-hover:block cursor-pointer"
                        aria-label={`Remove item ${index + 1}`}
                        data-dd-action-name="remove-list-item"
                      >
                        <XmarkIcon aria-hidden="true" />
                      </button>
                    )}
                  </div>
                ) : (
                  <Draggable draggableId={item.id} index={index}>
                    {(provided, snapshot) => {
                      const draggableContent = (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`relative flex items-center group ${snapshot.isDragging ? 'opacity-50' : ''}`}
                          style={{
                            ...provided.draggableProps.style,
                            overflow: 'visible',
                          }}
                          role="button"
                          aria-grabbed={snapshot.isDragging}
                          aria-label={`Draggable item ${index + 1}. Press space or enter to start dragging`}
                          data-dd-action-name="drag-list-item"
                        >
                          <GripVertical className="h-4 w-4 text-grey-4" aria-hidden="true" />
                          <div className="px-3 py-2 bg-grey-1 rounded cursor-grab relative flex-grow hover:bg-grey-2">
                            {item.content}
                          </div>
                          {item.isRemovable && (
                            <button
                              onClick={() => removeItem(item.id)}
                              className="absolute top-1/2 -translate-y-1/2 right-2 w-4 h-4 text-black hover:text-gray-900 hidden group-hover:block cursor-pointer"
                              aria-label={`Remove item ${index + 1}`}
                              data-dd-action-name="remove-list-item"
                            >
                              <XmarkIcon aria-hidden="true" />
                            </button>
                          )}
                        </div>
                      );
                      return snapshot.isDragging
                        ? ReactDOM.createPortal(draggableContent, document.body)
                        : draggableContent;
                    }}
                  </Draggable>
                )}

                {(item.subItems ?? []).length > 0 && (
                  <Droppable droppableId={item.id} type={`subItems-${item.id}`} direction="vertical">
                    {(subProvided, _) => (
                      <div
                        ref={subProvided.innerRef}
                        {...subProvided.droppableProps}
                        className="ml-6 space-y-2"
                        style={{ overflow: 'visible' }}
                        role="list"
                        aria-label={`Sub-items for item ${index + 1}`}
                      >
                        {(item.subItems ?? []).map((sub, subIndex) => (
                          <div key={sub.id} className="space-y-2" role="listitem">
                            {sub.isFixed ? (
                              <div
                                className="group bg-grey-1 px-3 py-2 rounded opacity-75 relative"
                                aria-label={`Fixed sub-item ${subIndex + 1}`}
                              >
                                <div>{sub.content}</div>
                                {sub.isRemovable && (
                                  <button
                                    onClick={() => removeSubItem(item.id, sub.id)}
                                    className="absolute top-1/2 -translate-y-1/2 right-2 w-4 h-4 text-gray-600 hover:text-gray-900 hidden group-hover:block cursor-pointer"
                                    aria-label={`Remove sub-item ${subIndex + 1}`}
                                    data-dd-action-name="remove-sub-list-item"
                                  >
                                    <XmarkIcon aria-hidden="true" />
                                  </button>
                                )}
                              </div>
                            ) : (
                              <Draggable draggableId={sub.id} index={subIndex}>
                                {(subDragProvided, subDragSnapshot) => (
                                  <div
                                    ref={subDragProvided.innerRef}
                                    {...subDragProvided.draggableProps}
                                    {...subDragProvided.dragHandleProps}
                                    className={`group bg-grey-1 px-3 py-2 rounded cursor-move relative ${
                                      subDragSnapshot.isDragging ? 'shadow-lg opacity-50' : ''
                                    }`}
                                    style={{
                                      ...subDragProvided.draggableProps.style,
                                      overflow: 'visible',
                                    }}
                                    role="button"
                                    aria-grabbed={subDragSnapshot.isDragging}
                                    aria-label={`Draggable sub-item ${
                                      subIndex + 1
                                    }. Press space or enter to start dragging`}
                                    data-dd-action-name="drag-sub-list-item"
                                  >
                                    <div>{sub.content}</div>
                                    {sub.isRemovable && (
                                      <button
                                        onClick={() => removeSubItem(item.id, sub.id)}
                                        className="absolute top-1/2 -translate-y-1/2 right-2 w-4 h-4 text-gray-600 hover:text-gray-900 hidden group-hover:block cursor-pointer"
                                        aria-label={`Remove sub-item ${subIndex + 1}`}
                                        data-dd-action-name="remove-sub-list-item"
                                      >
                                        <XmarkIcon aria-hidden="true" />
                                      </button>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            )}
                          </div>
                        ))}
                        {subProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
