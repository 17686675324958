import * as React from 'react';
import {
  // Alert,
  Col,
  Form,
  Modal,
  Row,
  Switch,
  Typography,
} from '@revfluence/fresh';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from '../../../OfferForm.scss';
import { FormAsyncAction, IShopifyFormElementProps, OfferFormAsyncActions } from '../../../types';

interface IProps extends IShopifyFormElementProps<'multiShopifyEnabled'> {
  isMultipleShopifySyncEnabled: boolean;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  hasMembers: boolean;
  isLinkedShopifyOfferSelected: boolean;
  isLandingPageEnabled?: boolean;
  isCollection: boolean;
  IsPriceRuleTypeFlat: boolean;
}

const { Title, Text } = Typography;
const { confirm } = Modal;

export const OfferSync: React.FC<Readonly<IProps>> = React.memo(
  ({
    disabled,
    name,
    isMultipleShopifySyncEnabled,
    handleFormAsyncActions,
    hasMembers,
    isLinkedShopifyOfferSelected,
    isLandingPageEnabled,
    isCollection,
    IsPriceRuleTypeFlat,
  }) => {
    const handleChange = (event) => {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'isMultipleShopifySyncEnabled', value: event },
      });
    };
    const handleSync = (event) => {
      if (hasMembers && (isCollection || IsPriceRuleTypeFlat) && event) {
        confirm({
          title: 'Are you sure you want to sync this offer across other stores?',
          icon: <CircleExclamationIcon />,
          content:
            'The offer currently has a flat discount and collection restrictions. These will be removed and you will need to set discount as a percentage value. Offer cannot be restricted to collections when Sync is enabled.',
          onOk() {
            handleChange(event);
          },
          onCancel() { },
          autoFocusButton: null,
          okText: 'Continue',
        });
      } else {
        handleChange(event);
      }
    };
    return (
      <Form.Item
        name={name}
        className={`${styles.offerSyncForm} ${isLandingPageEnabled ? 'opacity-50' : ''}`}
        valuePropName="checked"
      >
        <Row gutter={8}>
          <Col>
            <Switch
              size="default"
              disabled={disabled}
              onChange={handleSync}
              checked={isMultipleShopifySyncEnabled}
            />
          </Col>
          <Col>
            <Title level={5}>
              Enable Sync
            </Title>
          </Col>
        </Row>
        {/* {!isNewFlow && (
        <div className={styles.AlertWrapper}>
          <Alert message="This offer is currently not eligible for syncing with other stores. You must first update and save this offer, after which you can return to enable the syncing feature." type="warning" />
        </div>
      )} */}

        <Text className={isLandingPageEnabled ? 'text-gray-500' : ''}>
          {isLinkedShopifyOfferSelected
            ? ''
            : 'Sync this promo code offer to other connected Shopify stores. This will create discount groups in those Shopify stores as well. In case of syncing of offer to other stores, you can only have discount value in percentage.'}
        </Text>
      </Form.Item>
    );
  },
);

OfferSync.displayName = 'OfferSync';
