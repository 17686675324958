/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ya91_157 {
  display: flex;
}

._justify-content-space-between_1ya91_161 {
  justify-content: space-between;
}

._tabular-nums_1ya91_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ya91_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

._migrationModalContainer_1ya91_377 {
  width: 100% !important;
  padding: 0rem 0rem 1.5rem 0rem !important;
  display: flex;
  flex-direction: column;
}
._migrationModalContainer_1ya91_377 .ant-typography {
  color: #1f1f21;
}
._migrationModalContainer_1ya91_377 .ant-modal-body {
  padding: 1rem 1.5rem;
}
._migrationModalContainer_1ya91_377 ._paragraph_1ya91_389 {
  color: #1f1f21;
  margin-top: 0.5rem;
}
._migrationModalContainer_1ya91_377 ._iconContainer_1ya91_393 {
  display: flex;
  align-items: center;
  justify-content: center;
}
._migrationModalContainer_1ya91_377 ._modalList_1ya91_398 {
  padding: 0.625rem 1.5rem 0rem 1.5rem;
}
._migrationModalContainer_1ya91_377 ._modalList_1ya91_398 ul {
  list-style: disc;
}
._migrationModalContainer_1ya91_377 ._modalList_1ya91_398 ul li {
  margin-bottom: 0.625rem;
}
._migrationModalContainer_1ya91_377 ._modalList_1ya91_398 ul li:last-child {
  margin-bottom: 0;
}
._migrationModalContainer_1ya91_377 ._modalHighlightWrapper_1ya91_410 {
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #faad14;
  background: #fffbe6;
  margin: 1rem 0;
}
._migrationModalContainer_1ya91_377 ._modalHighlightWrapper_1ya91_410 ._highlightWrapper_1ya91_417 {
  padding-top: 16px;
}
._migrationModalContainer_1ya91_377 ._modalHighlightWrapper_1ya91_410 ._highlightWrapper_1ya91_417 .ant-list-split .ant-list-item {
  border-bottom: none;
  padding: 0;
}
._migrationModalContainer_1ya91_377 ._modalHighlightWrapper_1ya91_410 ._highlightWrapper_1ya91_417 .ant-list-split .ant-list-item:first-child {
  margin-bottom: 0.75rem;
}
._migrationModalContainer_1ya91_377 .radioCustom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
._migrationModalContainer_1ya91_377 .radioCustom .ant-radio-wrapper {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 4px;
}
._migrationModalContainer_1ya91_377 .radioCustom .ant-radio-wrapper-checked {
  border: 2px solid #167CF4;
  border-radius: 8px;
  padding: 4px;
  background-color: #F4FCFF;
  z-index: 1;
}
._migrationModalContainer_1ya91_377 .radioCustom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}
._migrationModalContainer_1ya91_377 .radioCustom .ant-radio + * {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
}
._migrationModalContainer_1ya91_377 ._modalHeader_1ya91_456 {
  display: flex;
  gap: 1rem;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 {
  margin-top: 1.5rem;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 ._colorGrey_1ya91_463 {
  color: #8C8C8C;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 ._stepContentHeader_1ya91_466 {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 ._stepContentHeader_1ya91_466 ._text_1ya91_471 {
  font-weight: bold;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 ._radioItemContainer_1ya91_474 {
  padding: 1rem;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 ._radioItemContainer_1ya91_474 ._image_1ya91_477 {
  width: 100%;
}
._migrationModalContainer_1ya91_377 ._stepContainer_1ya91_460 ._radioItemContainer_1ya91_474 ._text_1ya91_471 {
  font-weight: bold;
}

._footerContainer_1ya91_484 {
  display: flex;
  justify-content: space-between;
}
._footerContainer_1ya91_484 ._cancelButton_1ya91_488 {
  margin-right: 0.5rem;
}