import React, { useCallback } from 'react';
import { AboutSection } from '../sections/AboutSection';
import { GoalsSection } from '../sections/GoalsSection';
import { CreatorsSection } from '../sections/CreatorsSection';
import { ContentSection } from '../sections/ContentSection';
import { CompensationSection } from '../sections/CompensationSection';
import { useInitializeCampaign } from '../hooks/useInitializeCampaign';
import { TProject } from '../../types';
import { SaveCampaignCTA } from '../components/SaveCampaignCTA';

interface Props {
  activeSection?: string;
  mode?: 'add' | 'edit';
  project?: TProject;
  onSave?: () => void;
  refetchProjects?: () => void;
}

const sections = [
  { id: 'about', label: 'About', Component: AboutSection },
  { id: 'goals', label: 'Goals', Component: GoalsSection },
  { id: 'creators', label: 'Creators', Component: CreatorsSection },
  { id: 'content', label: 'Content', Component: ContentSection },
  { id: 'compensation', label: 'Compensation', Component: CompensationSection },
];

export const SetupTab: React.FC<Props> = ({
  activeSection = 'about',
  mode = 'edit',
  project,
  onSave,
  refetchProjects,
}) => {
  // Initialize campaign data
  useInitializeCampaign(mode, project);

  const handleSectionClick = useCallback((id: string, e: React.MouseEvent) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <div className="flex h-full">
      {/* Left Sidebar */}
      <div className="w-48 border-r border-gray-200 p-4">
        <nav className="space-y-1">
          {sections.map(({ id, label }) => (
            <a
              key={id}
              href={`#${id}`}
              onClick={(e) => handleSectionClick(id, e)}
              className={`block px-3 py-2 rounded-md text-sm font-medium ${
                activeSection === id
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              }`}
            >
              {label}
            </a>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-auto" id="setup-content">
        <div className="max-w-[800px] mx-auto space-y-6">
          <SaveCampaignCTA
            mode={mode}
            project={project}
            onSave={onSave}
            refetchProjects={refetchProjects}
            title="Campaign Setup"
          />

          {sections.map(({ id, Component }) => (
            <div key={id} id={id} className="scroll-mt-6">
              {/* @ts-ignore */}
              <Component project={project} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
