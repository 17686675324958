import * as React from 'react';
import { find, flatMap, isEmpty } from 'lodash';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useSegmentFoldersQuery, usePredefinedSegmentsQuery } from '@frontend/app/hooks';
import { allSegmentId } from '@frontend/app/utils/segments';
import { TPredefinedSegment, TSegment } from '@frontend/app/types/MemberList';
import { useSegmentFoldersForProgram } from '../useSegmentFoldersForProgram';
import { usePredefinedSegmentsForProgram } from '../usePredefinedSegmentsForProgram';

const { useMemo, useEffect } = React;

export const useSelectedSegment = (
  communityId: number,
  sourcingGroupId: string,
  programId: number,
  urlSearchRef: React.RefObject<{ segmentId?: string }>,
  isContact?: boolean,
) => {
  const defaultSegmentId = useMemo(() => allSegmentId(communityId, sourcingGroupId, programId), [
    communityId,
    sourcingGroupId,
    programId,
  ]);

  const selectedSegmentId = urlSearchRef?.current?.segmentId || defaultSegmentId;

  const { loading: isLoadingCommunityFolders, data } = useSegmentFoldersQuery(communityId, sourcingGroupId);
  const communityFolders = data?.folders;

  const { loading: isLoadingProgramFolders, data: programData } = useSegmentFoldersForProgram(programId);
  const programFolders = programData?.folders;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const folders = communityFolders || programFolders || [];
  const isLoadingFolders = isLoadingCommunityFolders || isLoadingProgramFolders;

  const {
    loading: isLoadingPredefinedCommunitySegments,
    data: { segments: predefinedCommunitySegments = undefined } = {},
  } = usePredefinedSegmentsQuery({
    communityId,
    sourcingGroupId,
    isContact,
  });
  const {
    loading: isLoadingPredefinedProgramSegments,
    data: { segments: predefinedProgramSegments = undefined } = {},
  } = usePredefinedSegmentsForProgram({ programId });

  const predefinedSegments = predefinedCommunitySegments || predefinedProgramSegments;

  const isLoadingPredefinedSegments = isLoadingPredefinedCommunitySegments || isLoadingPredefinedProgramSegments;

  const isPredefinedSegmentsLoaded = !isEmpty(predefinedCommunitySegments) || !isEmpty(predefinedProgramSegments);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const segments = useMemo<TSegment[]>(() => flatMap(folders, (folder) => folder.segments) as any, [folders]);

  const selectedSegment = useMemo<TSegment | TPredefinedSegment | null>(() => {
    if (predefinedSegments) {
      const predefSegment = find(predefinedSegments, { id: selectedSegmentId });
      if (predefSegment) return predefSegment;
    }

    if (segments) {
      const segment = find(segments, { id: Number(selectedSegmentId) });
      if (segment) return segment;
    }

    return null;
  }, [selectedSegmentId, segments, predefinedSegments]);

  const history = useHistory();
  const location = useLocation();

  const isLoading = isLoadingFolders || isLoadingPredefinedSegments;

  useEffect(() => {
    if (!isLoading && isPredefinedSegmentsLoaded && !selectedSegment) {
      // clear segmentId url query
      const newSearch = urlSearchRef.current;
      if (newSearch) {
        delete newSearch.segmentId;
        history.replace({ ...location, search: qs.stringify(newSearch) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedSegment, isPredefinedSegmentsLoaded]);

  return { selectedSegment, predefinedSegments };
};
