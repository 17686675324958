import gql from 'graphql-tag';

import { CLIENT_FEATURE_FRAGMENT } from './fragments';

export const GET_CLIENT_FEATURE_BY_NAME = gql`
  query GetClientFeatureByName($name: String!) {
    clientFeature: getClientFeatureByName(name: $name) {
        ...ClientFeatureFragment
    }
  }
  ${CLIENT_FEATURE_FRAGMENT}
`;

export const IS_CLIENT_FEATURE_ENABLED = gql`
  query IsClientFeatureEnabled($name: String!) {
    isClientFeatureEnabled(name: $name)
  }
`;

export const GET_CLIENT_ALL_FEATURES = gql`
  query GetClientAllFeatures($clientId: String) {
    getClientAllFeatures(clientId: $clientId) {
      id
      name
      state
    }
  }
  `;

export const UPDATE_CLIENT_FF = gql`
  mutation UpdateClientFF($data: ClientFFUpdateInput!) {
    updateClientFF(data: $data)
  }
`;
