import React, { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { GearIcon, ArrowsRotateIcon, CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { useFragment } from '@frontend/gql/social';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Tabs, TabsContent } from '@frontend/shadcn/components/ui/tabs';
import { useAuth } from '@frontend/context/authContext';
import { cn } from '@frontend/shadcn/lib/utils';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import {
 Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import {
 Card, CardContent, CardHeader, CardTitle,
} from '@frontend/shadcn/components/ui/card';
import { Period } from '@frontend/gql/social/graphql';
import TotalSpendCard from './components/ad-highlight-cards/total-spend-card';
import SalesGeneratedCard from './components/ad-highlight-cards/sales-generated-card';
import RoasCard from './components/ad-highlight-cards/roas-card';
import {
  Header,
  HeaderLayout,
  HeaderMainContent,
  HeaderH1,
  HeaderActions,
  HeaderTabs,
  HeaderTabsList,
  HeaderTabsTrigger,
} from '../components/header';
import { Impressions } from './components/ad-highlight-long-card/impressions';
import { Reach } from './components/ad-highlight-long-card/reach';
import { Clicks } from './components/ad-highlight-long-card/clicks';
import AdSpendVsSalesChart from './components/ad-spend-vs-sales-chart';
import RoasOverTimeChart from './components/roas-over-time-chart';
import { Members } from './components/ad-highlight-long-card/members';
import { Posts } from './components/ad-highlight-long-card/posts';
import { getDefaultIntervalRange, useGetPreviousIntervalRange } from './utils';
import { insightsByClientId, socialAdsAnalyticsFragment } from './graphql';

/*
  Component
*/
export const SocialAds = () => {
  const { clientInfo } = useAuth();

  // get value from query params
  // const queryCurrentIntervalRange: RangeInput = {
  //   startDate: undefined,
  //   endDate: undefined,
  // };
  const [currentIntervalRange, _] = useState<{ startDate: string; endDate: string; precision: Period }>({
    ...getDefaultIntervalRange(),
    // ...queryCurrentIntervalRange,
  });
  const previousIntervalRange = useGetPreviousIntervalRange(currentIntervalRange);
  const [refetching, setRefetching] = useState(false);
  const [{ data, fetching }, refetch] = useQuery({
    query: insightsByClientId,
    variables: {
      clientId: clientInfo?.id,
      currentIntervalRange,
      previousIntervalRange,
    },
    pause: !clientInfo?.id,
  });
  useEffect(() => {
    if (!fetching) {
      setRefetching(false);
    }
  }, [fetching]);

  const socialAdsAnalyticsData = useFragment(socialAdsAnalyticsFragment, data);

  const currentInterval = socialAdsAnalyticsData?.client?.currentInterval;
  const currentIntervalsIntervalInsights = socialAdsAnalyticsData?.client?.currentIntervalsIntervalInsights;
  const currentIntervalsIntervalInsightsNotAspireSourced = socialAdsAnalyticsData?.client?.currentIntervalsIntervalInsightsNotAspireSourced;
  const previousInterval = socialAdsAnalyticsData?.client?.previousInterval;
  const currentIntervalNotAspireSourced = socialAdsAnalyticsData?.client?.currentIntervalNotAspireSourced;
  return (
    <TooltipProvider>
      <Tabs defaultValue="instagram">
        <Header>
          <HeaderLayout>
            <HeaderMainContent>
              <HeaderH1>Ads Analytics</HeaderH1>
            </HeaderMainContent>
            <HeaderActions>
              <Button size="headerIcon" variant="outlineHeader">
                <GearIcon />
              </Button>
              <Button size="headerIcon" variant="outlineHeader">
                <GearIcon />
              </Button>
              <Button
                size="default"
                variant="outlineHeader"
                disabled={refetching}
                onClick={() => {
                  setRefetching(true);
                  refetch({ requestPolicy: 'network-only' });
                }}
              >
                <ArrowsRotateIcon className={cn('mr-2', refetching && 'animate-spin')} />
                Refresh
              </Button>
            </HeaderActions>
          </HeaderLayout>
          <HeaderTabs>
            <HeaderTabsList>
              <HeaderTabsTrigger value="instagram">Instagram</HeaderTabsTrigger>
              <HeaderTabsTrigger value="tiktok">TikTok</HeaderTabsTrigger>
              <HeaderTabsTrigger value="youtube">YouTube</HeaderTabsTrigger>
            </HeaderTabsList>
          </HeaderTabs>
        </Header>
        <div className="mt-4">
          <TabsContent value="instagram">
            <div className="p-4">
              <H3>Ads Highlights</H3>
              <P>
                Key metrics define your success, helping you quickly assess what's working and where to dive deeper. Use
                it to identify how your content is generated through Aspire performing as Ads.
              </P>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4">
              <TotalSpendCard
                currentInterval={currentInterval}
                previousInterval={previousInterval}
                currentIntervalNotAspireSourced={currentIntervalNotAspireSourced}
              />
              <SalesGeneratedCard
                currentInterval={currentInterval}
                previousInterval={previousInterval}
                currentIntervalNotAspireSourced={currentIntervalNotAspireSourced}
              />
              <RoasCard
                currentInterval={currentInterval}
                previousInterval={previousInterval}
                currentIntervalNotAspireSourced={currentIntervalNotAspireSourced}
              />
              <div className="col-span-1 lg:col-span-3">
                <Card>
                  <CardHeader />
                  <CardContent>
                    <div className="grid grid-cols-2 lg:grid-cols-5 gap-2">
                      <Clicks currentInterval={currentInterval} previousInterval={previousInterval} />
                      <Reach currentInterval={currentInterval} previousInterval={previousInterval} />
                      <Impressions currentInterval={currentInterval} previousInterval={previousInterval} />
                      <Members currentInterval={currentInterval} previousInterval={previousInterval} />
                      <Posts currentInterval={currentInterval} previousInterval={previousInterval} />
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="p-4">
              <H3>Ads Spend Overview</H3>
              <P>
                Ensure that every dollar is working toward your Ads' goals. Examining how much you spend over time and
                the revenue attributable to ads through content generated through Aspire.
              </P>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 p-4">
              <Card className="col-span-1 lg:col-span-4 flex flex-col">
                <CardHeader>
                  <CardTitle>
                    Ads Spend vs Sales
                    <Tooltip>
                      <TooltipTrigger>
                        <CircleInfoIcon className="ml-1" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[250px]">
                        <P>
                          Monthly Ad Spend and Sales trends, split into Aspire-attributed and non-attributed portions
                          for comparison.
                        </P>
                      </TooltipContent>
                    </Tooltip>
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col justify-between h-full">
                  <Separator className="my-2" />
                  <AdSpendVsSalesChart
                    currentIntervalsIntervalInsights={currentIntervalsIntervalInsights}
                    currentIntervalsIntervalInsightsNotAspireSourced={currentIntervalsIntervalInsightsNotAspireSourced}
                  />
                </CardContent>
              </Card>
              <Card className="col-span-1 lg:col-span-2 flex flex-col">
                <CardHeader>
                  <CardTitle>
                    ROAS Over Time
                    <Tooltip>
                      <TooltipTrigger>
                        <CircleInfoIcon className="ml-1" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[250px]">
                        <P>
                          Total return on ad spend (ROAS) from purchases of items shared between Brand and Retailer.
                          This number is based on information received from one or more retailers' connected Facebook
                          Business Tools. You can see the amount's breakdown is attributed to Aspire vs not attributed
                          to Aspire.
                        </P>
                      </TooltipContent>
                    </Tooltip>
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col justify-between h-full">
                  <Separator className="my-2" />
                  <RoasOverTimeChart
                    currentIntervalsMetaInsights={currentIntervalsIntervalInsights}
                    currentIntervalsMetaInsightsNotAspireSourced={currentIntervalsIntervalInsightsNotAspireSourced}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="p-4">
              <H3>Ads Awareness</H3>
              <P>
                It shows how far your message is spreading and who is seeing it. By analyzing these metrics, you can
                understand the scale of your brand's visibility and fine-tune targeting to ensure your message reaches
                the right audience in the right volume.
              </P>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4">{/* TODO: Add awareness cards */}</div>
          </TabsContent>
          <TabsContent value="tiktok">TikTok</TabsContent>
          <TabsContent value="youtube">YouTube</TabsContent>
        </div>
      </Tabs>
    </TooltipProvider>
  );
};
