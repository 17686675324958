import { useQuery, QueryHookOptions } from '@apollo/client';
import { GetProductMetaFieldValues, GetProductMetaFieldValuesVariables } from '../queries/types/GetProductMetaFieldValues';
import { GET_PRODUCT_META_FIELD_VALUES } from '../queries/productDetails';

type IOptions = QueryHookOptions<GetProductMetaFieldValues, GetProductMetaFieldValuesVariables>;

export function useGetProductMetaFieldValues(options: IOptions) {
  const {
    loading, data, error,
  } = useQuery<GetProductMetaFieldValues, GetProductMetaFieldValuesVariables>(
    GET_PRODUCT_META_FIELD_VALUES,
    options,
  );

  return {
    isProductMetaFieldsLoading: loading,
    productMetaFields: data?.getProductMetaFieldValues,
    productMetaFieldsError: error,
  };
}
