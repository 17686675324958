import * as React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from '@revfluence/fresh';
import { OFFER_PAYOUT_TYPE } from '@affiliates/types/globalTypes';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { PlusIcon, XmarkIcon, CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card } from '@frontend/shadcn/components/ui/card';
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Tag from '@frontend/app/refresh-components/Tag';
import { EllipsisIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { Modal } from '@revfluence/fresh';
import { sortBy } from 'lodash';
import { Input } from '@frontend/shadcn/components/ui/input';
import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { P } from '@frontend/shadcn/components/typography/p';
import { getPayoutTagInitials, getToolTipContent, getUniqueColorForPayout } from '../../../../utils/OfferFormUtils';
import {
  FormAsyncAction, ICommonFormValues, IPayoutVariant, OfferFormAsyncActions, OfferFormModes,
} from '../../../..';

const { confirm } = Modal;

interface IEvents {
  onBlur: () => void;
  onFocus: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

enum PAYOUT_MODE {
  CREATE = 'create',
  EDIT = 'edit'
}

interface IProps {
  events: {
    onBlur: IEvents['onBlur'];
    options: {
      [OFFER_PAYOUT_TYPE.CONVERSION]: IEvents;
      [OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]: IEvents;
      [OFFER_PAYOUT_TYPE.SALE]: IEvents;
      [OFFER_PAYOUT_TYPE.CLICK]: IEvents;
    };
  };
  formattedConversionType: string;
  showCostPerClick: boolean;
  value: ICommonFormValues['payoutType'] | ICommonFormValues['payoutOptions'];
  source: OFFER_SOURCE;
  mode: OfferFormModes;
  formRef: FormInstance;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  disabled: boolean;
}

export const RefreshOfferPayoutOptions: React.FC<Readonly<IProps>> = React.memo(({
  showCostPerClick,
  value,
  source,
  mode,
  formRef,
  handleFormAsyncActions,
  formattedConversionType,
}) => {
  const MAX_PAYOUT_OPTIONS = 10;
  const payouts: IPayoutVariant[] = value as IPayoutVariant[];
  const [tempPayoutTitles, setTempPayoutTitles] = React.useState('');
  const [tempPercentPayout, setTempPercentPayout] = React.useState<number | null>(null);
  const [tempFatPayout, setTempFatPayout] = React.useState<number | null>(null);
  const [tempPayoutType, setTempPayoutType] = React.useState(OFFER_PAYOUT_TYPE.CONVERSION);
  const [editingMode, setEditingMode] = React.useState(false);
  const [payoutMode, setPayoutMode] = React.useState<PAYOUT_MODE>(PAYOUT_MODE.CREATE);
  const [disableAddPayout, setDisableAddPayout] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(0);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [isAdding, setIsAdding] = React.useState(false); // State to control add animation
  const [isEditing, setIsEditing] = React.useState(false); // State to control edit animation
  const getPayoutTag = (payoutType: string) => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.CLICK:
        return 'Cost per Click (CPC)';
      case OFFER_PAYOUT_TYPE.CONVERSION:
        return `Cost per ${formattedConversionType} (CPA)`;
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return 'Both (CPA+CPS)';
      case OFFER_PAYOUT_TYPE.SALE:
        return 'Commission per Sale (CPS)';
      case OFFER_PAYOUT_TYPE.THOUSAND_IMPRESSIONS:
        return 'Cost per Thousand Impression';
      default:
        '';
    }
  };
  const isPayoutValid = () => {
    if (tempPayoutType === OFFER_PAYOUT_TYPE.SALE) {
      return tempPercentPayout !== null && tempPercentPayout >= 0 && tempPercentPayout <= 100;
    }
    if (tempPayoutType === OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE) {
    return (tempFatPayout !== null && tempFatPayout >= 0) &&
             (tempPercentPayout !== null && tempPercentPayout >= 0 && tempPercentPayout <= 100);
    }
    return tempFatPayout !== null && tempFatPayout >= 0;
  };

  React.useEffect(() => {
    const validationErrors = getToolTipContent(tempPayoutTitles, tempFatPayout, tempPercentPayout, payouts, setDisableAddPayout, tempPayoutType, payoutMode);
    const hasInvalidPayout = !isPayoutValid();
    setErrors([...validationErrors, ...(hasInvalidPayout ? ['Payout value must be positive'] : [])]);
    setDisableAddPayout(validationErrors.length > 0 || hasInvalidPayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempPayoutTitles, tempFatPayout, tempPercentPayout, payouts, payoutMode, tempPayoutType]);

  const customPayoutValue = (payoutType) => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return (
          <div className="flex-1 ml-4">
            <div className="flex items-center gap-2">
              {/* Flat Payout */}
              <div className="relative w-full max-w-[272px]">
                <span className="absolute text-grey-4 left-3 top-[5px]">$</span>
                <Input
                  type="number"
                  onKeyDown={UseNaturalNumbers}
                  placeholder="0"
                  className="pl-8 w-full max-w-[272px] bg-primary-foreground"
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : Number(e.target.value);
                    setTempFatPayout(value);
                  }}
                  value={tempFatPayout ?? ''}
                />
              </div>

              <div className="flex items-center justify-center">+</div>

              {/* Percent Payout */}
              <div className="relative w-full max-w-[272px]">
                <span className="absolute text-grey-4 right-3 top-[5px]">%</span>
                <Input
                  type="number"
                  onKeyDown={UseNaturalNumbers}
                  placeholder="0"
                  className="pr-8 w-full max-w-[272px] bg-primary-foreground"
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : Number(e.target.value);
                    setTempPercentPayout(value);
                  }}
                  value={tempPercentPayout ?? ''}
                />
              </div>
            </div>
          </div>
        );

      case OFFER_PAYOUT_TYPE.CLICK:
      case OFFER_PAYOUT_TYPE.CONVERSION:
        return (
          <div className="relative w-full max-w-[272px]">
            <span className="absolute text-grey-4 left-3 top-[5px]">$</span>
            <Input
              type="number"
              onKeyDown={UseNaturalNumbers}
              placeholder="Payout Value"
              className="pl-8 w-full max-w-[272px] bg-primary-foreground"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value);
                setTempFatPayout(value);
              }}
              value={tempFatPayout ?? ''}
            />
          </div>
        );

      case OFFER_PAYOUT_TYPE.SALE:
        return (
          <div className="relative w-full max-w-[272px]">
            <span className="absolute text-grey-4 right-3 top-[5px]">%</span>
            <Input
              type="number"
              onKeyDown={UseNaturalNumbers}
              placeholder="Payout Value"
              className="pr-8 w-full max-w-[272px] bg-primary-foreground"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value);
                setTempPercentPayout(value);
              }}
              value={tempPercentPayout ?? ''}
            />
          </div>
        );

      default:
        return null;
    }
  };

  const remove = (index: number) => {
    const currentValues = payouts || [];
    currentValues.splice(index, 1);
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'payoutOptions', value: currentValues },
    });
  };

  const handleClickPayoutOption = React.useCallback((key, payoutIndex, payout) => {
    switch (key) {
      case 'delete': {
        if (payout[payoutIndex].isMemberAdded) {
          confirm({
            title: 'Can not remove this payout value',
            icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
            content: source === OFFER_SOURCE.SHOPIFY ? 'This payout option cannot be removed because it has members with active promo codes. Please re-assign all existing members to a different payout option, then re-attempt to delete this payout option.' : 'This Payout option cannot be removed because it has members with active offers. Please re-assign all existing members to a different payout option and then re-attempt deleting this payout option.',
            onOk() {
            },
            cancelButtonProps: {
              style: {
                display: 'none',
              },
            },
            okText: 'Close',
          });
        } else if (payouts.length === 1 && payout[payoutIndex].isDefault) {
          confirm({
            title: 'Offer must have at least one payout option.',
            icon: <ExclamationCircleFilled />,
            content: 'You must create another payout option and make it the default for this offer before removing this payout option.',
            onOk() {
            },
            cancelButtonProps: {
              style: {
                display: 'none',
              },
            },
          });
        } else if (payout[payoutIndex].isDefault && mode === OfferFormModes.Create) {
          let selectedIndex = 0;
          if (payoutIndex === 0) selectedIndex = 1;
          const currentValues = payouts || [];
          currentValues.forEach((element) => {
            element.isDefault = false;
          });
          currentValues[selectedIndex].isDefault = true;
          formRef.setFieldsValue({
            payoutOptions: currentValues,
          });
          handleFormAsyncActions({ action: OfferFormAsyncActions.DEFAULT_PAYOUT, payload: { index: selectedIndex } });
          remove(payoutIndex);
        } else if (payout[payoutIndex] && mode === OfferFormModes.Edit) {
          confirm({
            title: source === OFFER_SOURCE.SHOPIFY ? 'Are you sure you want to remove the default payout option for this offer?' : 'Are you sure you want to remove this default payout?',
            icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
            content: source === OFFER_SOURCE.SHOPIFY ? 'If you remove the default payout option, we will automatically select the most recently created payout option as the new default for this offer. You can always change the default option.' : 'The Payout option you are deleting is currently the default option. A new one will be selected automatically. You can change this by going to offer settings. Do you want to proceed to delete "Standard" payout option?"',
            okText: 'Confirm Remove',
            okButtonProps: {
              style: {
                background: 'white',
                borderColor: 'red',
                color: 'red',
              },
            },
            onOk() {
              const sortedArray: IPayoutVariant[] = sortBy(payouts, (obj) => new Date(obj.createdDate));
              let newIndex = payouts.findIndex((obj) => obj.id === sortedArray[0].id);
              if (newIndex === payoutIndex) {
                newIndex = payouts.findIndex((obj) => obj.id === sortedArray[1].id);
              }
              if (newIndex >= 0) {
                const currentValues = payouts || [];
                currentValues.forEach((element) => {
                  element.isDefault = false;
                });
                currentValues[newIndex].isDefault = true;
                formRef.setFieldsValue({
                  payoutOptions: currentValues,
                });
                handleFormAsyncActions({ action: OfferFormAsyncActions.DEFAULT_PAYOUT, payload: { index: newIndex } });
              }
              remove(payoutIndex);
            },
          });
        } else if (!payout[payoutIndex].isMemberAdded && mode === OfferFormModes.Edit) {
          confirm({
            title: 'Are you sure you want to remove this payout option?',
            icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
            content: source === OFFER_SOURCE.SHOPIFY ? 'You will no longer be able to assign members to this payout option.' : 'This payout option will no longer be available to be assigned to members. ',
            okText: 'Confirm Remove',
            okButtonProps: {
              style: {
                background: 'white',
                borderColor: 'red',
                color: 'red',
              },
            },
            onOk() {
              remove(payoutIndex);
            },
            onCancel() {
            },
            cancelText: 'Cancel',
          });
        } else {
          remove(payoutIndex);
        }
        break;
      }
      case 'default': {
        const currentValues = payouts || [];
        currentValues.forEach((element) => {
          element.isDefault = false;
        });
        currentValues[payoutIndex].isDefault = true;
        formRef.setFieldsValue({
          payoutOptions: currentValues,
        });
        handleFormAsyncActions({ action: OfferFormAsyncActions.DEFAULT_PAYOUT, payload: { index: payoutIndex } });
        break;
      }
      default: {
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef, handleFormAsyncActions, mode, source, payouts]);

  const addPayout = () => {
    if (payouts.length < MAX_PAYOUT_OPTIONS) {
      setEditingMode(true);
      setPayoutMode(PAYOUT_MODE.CREATE);
      setIsAdding(true); // Trigger add animation
      setTempPayoutTitles('');
      setTempPercentPayout(null);
      setTempFatPayout(null);
      setTempPayoutType(OFFER_PAYOUT_TYPE.CONVERSION);
    } else {
      message.error(`You have reached the maximum limit of ${MAX_PAYOUT_OPTIONS} payout options for this offer`);
    }
  };

  const handleClickPayoutType = (value: OFFER_PAYOUT_TYPE) => {
    setTempPayoutType(value);
  };

  const handleAddPayout = () => {
    setEditingMode(false);
    setIsAdding(false);
    const newPayout: IPayoutVariant = {
      label: tempPayoutTitles,
      isDefault: payouts.length === 0,
      payoutType: tempPayoutType,
      isMemberAdded: false,
      percentPayout: tempPercentPayout ?? 0,
      flatPayout: tempFatPayout ?? 0,
    };
    const tempPayout: IPayoutVariant[] = [...payouts, newPayout];
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'payoutOptions', value: tempPayout },
    });
    handleClear();
  };

  const handleEditPayout = (index: number) => {
    setEditingMode(false);
    setIsEditing(false);
    const updatedPayout: IPayoutVariant = {
      label: tempPayoutTitles,
      isDefault: payouts[index].isDefault,
      payoutType: tempPayoutType,
      isMemberAdded: payouts[index].isMemberAdded,
      percentPayout: tempPercentPayout ?? 0,
      flatPayout: tempFatPayout ?? 0,
      id: payouts[index].id,
    };
    const tempPayout: IPayoutVariant[] = [...payouts];
    tempPayout[index] = updatedPayout;
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'payoutOptions', value: tempPayout },
    });
    handleClear();
  };

  const handleClear = () => {
    setTempPayoutTitles('');
    setTempPercentPayout(null);
    setTempFatPayout(null);
    setTempPayoutType(OFFER_PAYOUT_TYPE.CONVERSION);
  };

  const handleCancelPayout = () => {
    setEditingMode(false);
    setIsAdding(false); // Cancel add animation
    setIsEditing(false); // Cancel edit animation
    handleClear();
  };

  const handleEdit = (index: number) => {
    setPayoutMode(PAYOUT_MODE.EDIT);
    setEditIndex(index);
    setEditingMode(true);
    setIsEditing(true); // Trigger edit animation
    const selectedPayout = payouts[index];
    setTempPayoutTitles(selectedPayout.label);
    setTempPayoutType(selectedPayout.payoutType as OFFER_PAYOUT_TYPE);
    setTempPercentPayout(selectedPayout.percentPayout);
    setTempFatPayout(selectedPayout.flatPayout);
  };

  return (
    <>
      {payouts.map((payout, index) => (
        <Card
          key={payout.id}
          className={`flex justify-between items-center mb-2 px-3 py-2 rounded-xl h-10 transition-all duration-300 ease-in-out ${editingMode && payoutMode === PAYOUT_MODE.EDIT && editIndex === index
            ? 'opacity-100 scale-100 bg-grey-1'
            : 'opacity-100 scale-100'
            }`}

        >
          {editingMode && payoutMode === PAYOUT_MODE.EDIT && editIndex === index ? (
            <div className="flex items-center w-full gap-x-6 transition-all duration-300 ease-in-out ">
              <div className="flex items-center w-[90%] gap-x-4">
                <Input type="text" maxLength={60} placeholder="Payout Title" className="w-full max-w-[272px] bg-primary-foreground transition-all duration-300 ease-in-out" value={tempPayoutTitles} onChange={(e) => setTempPayoutTitles(e.target.value)} />
                {customPayoutValue(tempPayoutType)}
                <Select value={tempPayoutType} onValueChange={handleClickPayoutType}>
                  <SelectTrigger className={`w-max border-0 font-normal text-primary focus:outline-none focus:ring-0 ${getUniqueColorForPayout(tempPayoutType)} h-6 px-2 transition-all duration-300 ease-in-out`}>
                    <SelectValue placeholder="Select Payout Type">
                      {getPayoutTagInitials(tempPayoutType)}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={OFFER_PAYOUT_TYPE.CONVERSION}>
                      {getPayoutTag(OFFER_PAYOUT_TYPE.CONVERSION)}
                    </SelectItem>
                    <SelectItem value={OFFER_PAYOUT_TYPE.SALE}>
                      {getPayoutTag(OFFER_PAYOUT_TYPE.SALE)}
                    </SelectItem>
                    <SelectItem value={OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE}>
                      {getPayoutTag(OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE)}
                    </SelectItem>
                    {showCostPerClick && (
                      <SelectItem value={OFFER_PAYOUT_TYPE.CLICK}>
                        {getPayoutTag(OFFER_PAYOUT_TYPE.CLICK)}
                      </SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center w-[10%] justify-end gap-x-2">
                <Button variant="outline" size="smallIcon" type="button" onClick={handleCancelPayout} className="cursor-pointer transition-all duration-300 ease-in-out hover:bg-grey-1 hover:border-grey-5 hover:text-grey-5">
                  <XmarkIcon />
                </Button>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      size="smallIcon"
                      type="button"
                      onClick={() => handleEditPayout(index)}
                      disabled={disableAddPayout}
                      className={`cursor-pointer transition-all duration-300 ease-in-out ${disableAddPayout ? '!pointer-events-auto opacity-50' : ''}`}
                    >
                      <CheckIcon />
                    </Button>
                  </TooltipTrigger>
                  {errors.length > 0 && (
                    <TooltipContent>
                      <span>
                        <ul className="text-primary-foreground">
                          {errors.map((error, index) => (
                            <li key={index}>{error} </li>
                          ))}
                        </ul>
                      </span>
                    </TooltipContent>
                  )}
                </Tooltip>
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center w-[90%] gap-x-4">
                <div className="font-medium w-full max-w-[272px]">
                  {payout.label}
                  {payout.isDefault && <Tag className="ml-2" bgColor="bg-teal-4" textColor="text-grey-0" label="Default" />}
                </div>
                <div className="font-medium w-full max-w-[272px]">
                  {
                    payout.payoutType === OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE ? (
                      <>
                        ${payout.flatPayout || 0} + {payout.percentPayout || 0}%
                        <span className="text-grey-4"> commission</span>
                      </>
                    ) : payout.payoutType === OFFER_PAYOUT_TYPE.SALE ? (
                      <>
                        {payout.percentPayout || 0}%
                        <span className="text-grey-4"> commission</span>
                      </>
                    ) : payout.payoutType === OFFER_PAYOUT_TYPE.CLICK || payout.payoutType === OFFER_PAYOUT_TYPE.CONVERSION || payout.payoutType === OFFER_PAYOUT_TYPE.THOUSAND_IMPRESSIONS ? (
                      <>${payout.flatPayout || 0} <span className="text-grey-4"> commission</span></>
                    ) : (
                      ''
                    )
                  }
                </div>
                <div className="flex items-center">
                  <Tag bgColor={getUniqueColorForPayout(payout.payoutType)} label={getPayoutTagInitials(payout.payoutType)} textColor="text-primary" />
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-2">
                <Button variant="outline" size="smallIcon" type="button" onClick={() => handleEdit(index)} className="p-2 cursor-pointer transition-all duration-300 ease-in-out hover:bg-grey-1 hover:border-grey-5 hover:text-grey-5 border-0">
                  <EditOutlined />
                </Button>
                <Select value={payout.payoutType} onValueChange={(payout) => handleClickPayoutOption(payout, index, value)}>
                  <SelectTrigger className="w-max-content border-0  font-normal text-primary p-2 h-6focus:outline-none focus:ring-0 focus:border-none transition-all duration-300 ease-in-out" showIcon={false}>
                    <EllipsisIcon className="w-3 h-3 text-grey-6" />
                  </SelectTrigger>
                  <SelectContent>
                    {!payout.isDefault && (
                      <SelectItem value="default">
                        Make Default
                      </SelectItem>
                    )}
                    <SelectItem value="delete" className="text-red-500">
                      Delete
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
        </Card>
      ))}

      {editingMode && payoutMode === PAYOUT_MODE.CREATE && (
        <Card
          className={`flex items-center justify-between px-3 py-2 bg-grey-1 border border-grey-2 gap-x-6 h-10 rounded-xl mb-2 transition-all duration-300 ease-in-out ${isAdding ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
            }`}
        >
          <div className="flex items-center w-full gap-x-6">
            <div className="flex items-center w-[90%] gap-x-4">
              <Input type="text" maxLength={60} placeholder="Payout Title" className="w-full max-w-[272px] bg-primary-foreground transition-all duration-300 ease-in-out" value={tempPayoutTitles} onChange={(e) => setTempPayoutTitles(e.target.value)} />
              {customPayoutValue(tempPayoutType)}
              <Select value={tempPayoutType} onValueChange={handleClickPayoutType}>
                <SelectTrigger className={`w-max border-0 font-normal text-primary focus:outline-none focus:ring-0 ${getUniqueColorForPayout(tempPayoutType)} h-6 px-2 transition-all duration-300 ease-in-out`}>
                  <SelectValue placeholder="Select Payout Type">
                    {getPayoutTagInitials(tempPayoutType)}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={OFFER_PAYOUT_TYPE.CONVERSION}>
                    {getPayoutTag(OFFER_PAYOUT_TYPE.CONVERSION)}
                  </SelectItem>
                  <SelectItem value={OFFER_PAYOUT_TYPE.SALE}>
                    {getPayoutTag(OFFER_PAYOUT_TYPE.SALE)}
                  </SelectItem>
                  <SelectItem value={OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE}>
                    {getPayoutTag(OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE)}
                  </SelectItem>
                  {showCostPerClick && (
                    <SelectItem value={OFFER_PAYOUT_TYPE.CLICK}>
                      {getPayoutTag(OFFER_PAYOUT_TYPE.CLICK)}
                    </SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center w-[10%] justify-end gap-x-2">
              <Button variant="outline" size="smallIcon" type="button" onClick={handleCancelPayout} className="cursor-pointer transition-all duration-300 ease-in-out hover:bg-grey-1 hover:border-grey-5 hover:text-grey-5">
                <XmarkIcon />
              </Button>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="smallIcon"
                    type="button"
                    onClick={handleAddPayout}
                    disabled={disableAddPayout}
                    className={`cursor-pointer transition-all duration-300 ease-in-out ${disableAddPayout ? '!pointer-events-auto opacity-50' : ''}`}
                  >
                    <CheckIcon />
                  </Button>
                </TooltipTrigger>
                {errors.length > 0 && (
                  <TooltipContent>
                    <span>
                      <ul className="text-primary-foreground">
                        {errors.map((error, index) => (
                          <li key={index}>{error} </li>
                        ))}
                      </ul>
                    </span>
                  </TooltipContent>
                )}
              </Tooltip>
            </div>
          </div>
        </Card>
      )}
      <Button
        variant="ghost"
        className={`flex items-center mb-2 gap-2 p-0 w-max h-auto ${editingMode || isEditing ? 'opacity-50' : ''}`}
        onClick={addPayout}
        disabled={editingMode || isEditing}
      >
        <PlusIcon />
        <P className="text-sm font-medium text-primary underline" fontWeight="font-medium">
          Add Payout
        </P>
      </Button>

    </>
  );
});
RefreshOfferPayoutOptions.displayName = 'RefreshOfferPayoutOptions';
