import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Label } from '@frontend/shadcn/components/ui/label';
import React from 'react';
import { useLandingPageContext } from '../../../../../context/LandingPageContext';

const ProductCard: React.FC = () => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const style = landingPageData?.products?.allProducts?.style;

  const handleCheckboxChange = (key: 'showProductRating' | 'showProductPrice') => {
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        allProducts: {
          ...prev.products?.allProducts,
          style: {
            ...prev.products?.allProducts?.style,
            [key]: !prev.products?.allProducts?.style[key],
          },
        },
      },
    }));
  };

  return (
    <div className="flex flex-col items-start gap-4 px-4 rounded-2xl">
      <div className="flex flex-col w-full gap-2">
        <div className="flex items-center space-x-2">
          <Checkbox
            id="price"
            checked={style?.showProductPrice}
            onCheckedChange={() => handleCheckboxChange('showProductPrice')}
            className="h-[18px] w-[18px] rounded border-grey-6"
          />
          <Label htmlFor="price" className="text-grey-6 text-sm font-medium">
            Show Product Price
          </Label>
        </div>
        {/* TODO: Add back in when we have a way to show the product rating */}
        <div className="items-center space-x-2 hidden">
          <Checkbox
            id="ratings"
            checked={style?.showProductRating}
            onCheckedChange={() => handleCheckboxChange('showProductRating')}
            className="h-[18px] w-[18px] rounded border-grey-6"
          />
          <Label htmlFor="ratings" className="text-grey-6 text-sm font-medium">
            Show Product Ratings
          </Label>
        </div>
        {/* <div className="flex items-center space-x-2">
          <Checkbox
            id="addToCart"
            checked={style?.showAddToCardButton}
            onCheckedChange={() => handleCheckboxChange('showAddToCardButton')}
            className="h-[18px] w-[18px] rounded bg-neutralgrey-6 border-neutralgrey-6"
          />
          <Label htmlFor="addToCart" className="text-neutralgrey-6 text-sm font-medium">
            Show Add to Cart Button
          </Label>
        </div> */}
      </div>
    </div>
  );
};

export default ProductCard;
