import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { WidgetContainer, DataTable, Empty } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { ChartUserIcon, UserIcon } from '@revfluence/fresh-icons/solid/esm';
import { cn } from '@/shadcn/lib/utils';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

const { useState, useEffect } = React;
type RowItem = {
  id: string;
  name: string;
  profileImage: string;
  totalSpend: number;
};

interface TopSpendWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function TopSpendWidget(props: TopSpendWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const [data, setData] = useState<RowItem[]>([]);

  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && budgetSummary?.summary?.memberSpendList) {
      const members: RowItem[] = budgetSummary?.summary?.memberSpendList.map((member, index) => ({
        id: index.toString(),
        name: member.memberName as string,
        profileImage: member?.profilePicture as string,
        totalSpend: member.spentAmount,
      }));
      setData(members);
    }
  }, [budgetSummary, loading]);

  const showEmptyState = useMemo(() => {
    if (!data?.length) {
      return true;
    }
    return false;
  }, [data]);

  const columns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'name',
      header: () => <div className="font-semibold text-[#1F1F21]">Name</div>,
      cell: ({ row }) => {
        const name = row.getValue('name') as string;
        const profileImage = row.original.profileImage as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[20px] w-[20px] rounded-full">
              <AvatarImage
                src={profileImage}
                className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-[20px] text-secondary"
              />
              <AvatarFallback
                className="flex h-full w-full items-center justify-center rounded-lg text-[20px] bg-grey-3"
              >
                <UserIcon className="text-white" />
              </AvatarFallback>
            </Avatar>
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'totalSpend',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Total Spend</div>,
      cell: ({ row }) => {
        const totalSpend = parseFloat(row.getValue('totalSpend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalSpend);
        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
  ];

  const emptyState = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={ChartUserIcon}
        title="No spending on creators yet."
        description="Track your spending on individual creators by assigning budgets and campaigns when sending payments to them."
      />
    </div>
  );

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = <DataTable columns={columns} data={data} />;
  const content = showEmptyState ? emptyState : mainContent;
  return (
    <WidgetContainer
      widgetTitle="Top Spend on"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : content}
    </WidgetContainer>
  );
}
