import React, { useCallback, useEffect, useState } from 'react';
import {
 Checkbox, CheckboxChangeEvent, ITableProps, Space, Spinner, Table, Tooltip, Typography,
} from '@revfluence/fresh';
import { BoxIcon } from '@revfluence/fresh-icons/regular/esm';
import { debounce } from 'lodash';
import { useProductTableProps } from './useProductTableProps';
import { IProductsRow } from '../../Products/types';
import styles from './ProductsTable.scss';
import { useGetProductDetailsLazy } from '../../Products/hooks/useGetProductDetails';
import { useBasicSelectionContext, useIsProductPartiallySelected, useNonDeletedProducts } from '../CatalogSelectionModal/BasicSelectionContext';
import { useGetAllProducts } from '../../Products/hooks/useGetAllProducts';

const { Text } = Typography;

const PAGE_SIZE = 50;

const EmptyScreen = () => (
  <Space direction="vertical" size={0} align="center">
    <BoxIcon fontSize={48} className={styles.boxIcon} />
    <Text type="secondary">Please select a collection to add products </Text>
  </Space>
);

const CheckboxRenderer = ({ value, record }: { value: boolean; record: IProductsRow }) => {
  const { toggleProduct } = useBasicSelectionContext();
  const nonDeletedProducts = useNonDeletedProducts();
  const { fetchProductDetails, loading, product } = useGetProductDetailsLazy({
    variables: {
      productId: Number(record.id),
    },
    notifyOnNetworkStatusChange: true,
  });
  const isPartiallySelected = useIsProductPartiallySelected({
    productId: record.id,
    totalVariants: record.variantsCount ?? 0,
  });
  const onSelectChange = async (e: CheckboxChangeEvent) => {
    if (!product) {
      fetchProductDetails({
        variables: {
          productId: Number(record.id),
        },
        onCompleted: (data) => {
          toggleProduct({
            productId: record.id,
            variantIds: data?.getProductVariantsByProductId?.productVariants?.map((variant) => String(variant.id)) ?? [],
            checked: e.target.checked,
          });
        },
      });
    } else {
      toggleProduct({
        productId: record.id,
        variantIds: product.productVariants?.map((variant) => String(variant.id)) ?? [],
        checked: e.target.checked,
      });
    }
  };

  const disabled = !value && nonDeletedProducts.length >= 20;

  if (loading) {
    return <Spinner size="small" />;
  }

  return (
    <Tooltip title={disabled ? 'You can only add upto 20 featured products in a catalog' : ''}>
      <Checkbox checked={value} onChange={onSelectChange} indeterminate={isPartiallySelected} disabled={disabled} />
    </Tooltip>
  );
};

export const ProductsTable = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [localSearch, setLocalSearch] = useState('');
  const {
    search,
  } = useBasicSelectionContext();
  const nonDeletedProducts = useNonDeletedProducts();

  const { products, loading } = useGetAllProducts({
    variables: {
      page: pageNumber,
      pageSize: PAGE_SIZE,
      searchName: localSearch,
      includeVariants: true,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const handleNextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handleSearch = useCallback(
    (value: string) => {
      setLocalSearch(value);
      setPageNumber(1);
    },
    [],
  );

  const debouncer = useCallback((search) => debounce(() => handleSearch(search), 2000), [handleSearch]);

  useEffect(() => {
    debouncer(search)();
  }, [debouncer, search]);

  const handlePrevPage = () => {
    setPageNumber((prev) => prev - 1);
  };

  const {
 columnConfig, pagination, tableData, expandable,
} = useProductTableProps({
    products: products.products,
    onNextPage: handleNextPage,
    onPreviousPage: handlePrevPage,
    totalProducts: products.totalProducts,
    pageNumber,
    showPagination: true,
  });

  const rowSelection: ITableProps<IProductsRow>['rowSelection'] = {
    selectedRowKeys: nonDeletedProducts.map((product) => product.productId),
    renderCell: (value, record) => <CheckboxRenderer value={value} record={record} />,
  };

  if (!loading && products.totalProducts === 0) {
    return <EmptyScreen />;
  }

  return (
    <div className={styles.ProductsTable}>
      <div className={styles.mainCheckbox}>
        <Text weight="semibold">
          {products.totalProducts}
          {' '}
          Products (
          {nonDeletedProducts.length}
          {' '}
          Selected)
        </Text>
      </div>
      <div className={styles.table}>
        <Table
          columns={columnConfig}
          pagination={pagination}
          dataSource={tableData}
          className="offerTable"
          size="small"
          expandable={expandable}
          rowSelection={rowSelection}
          showHeader={false}
          rowKey="id"
          loading={loading}
        />
      </div>
    </div>
  );
};
