import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { CircleInfoIcon, PhotoFilmIcon } from '@revfluence/fresh-icons/solid/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipBody,
  TooltipContent,
  TooltipHeader,
  TooltipItem,
  TooltipItemValue,
  TooltipTitle,
  TooltipArrow,
} from '@frontend/applications/Social/components/tooltip';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
  HighlightCardHeaderRow,
} from '../../../components/highlight-cards';

export const postsCardFragment = graphql(`
  fragment PostsCard_TotalInsights on TotalInsights {
    activePosts
  }
`);

interface PostsCardProps {
  data: FragmentType<typeof postsCardFragment> | null | undefined;
}

const PostsCard: React.FC<PostsCardProps> = ({ data }) => {
  const totalInsights = useFragment(postsCardFragment, data);
  const value = totalInsights?.activePosts ?? 0;

  return (
    <TooltipProvider>
      <Card>
        <HighlightCardHeader className="bg-yellow-6" role="banner">
          <HighlightCardHeaderRow>
            <HighlightCardTitle>
              <PhotoFilmIcon className="mr-2 text-[1.5rem]" aria-hidden="true" />
              <span className="text-base">Posts</span>
            </HighlightCardTitle>
            <HighlightCardTitle>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    type="button"
                    aria-label="View posts metrics information"
                    data-dd-action-name="view-posts-info"
                  >
                    <CircleInfoIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </TooltipTrigger>
                <TooltipContent role="tooltip" aria-live="polite">
                  <TooltipHeader>
                    <TooltipTitle>Posts</TooltipTitle>
                  </TooltipHeader>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemValue>
                        Active post count, filtered by selected dates, projects, and groups. An active post is defined
                        as a post that received an impression or interaction within the selected date range. Total
                        active posts filtered by the dates, projects, and groups selected.
                      </TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </HighlightCardTitle>
          </HighlightCardHeaderRow>
        </HighlightCardHeader>
        <HighlightCardContent>
          <HighlightCardContentRow>
            <HighlightCardContentColumn>
              <HighlightCardStatRow>
                <HighlightCardStat
                  className="text-3xl leading-none"
                  role="status"
                  aria-label={`Total active posts count: ${formatNumberDefault(value)}`}
                  data-dd-action-name="posts-stat"
                >
                  {formatNumberDefault(value)}
                </HighlightCardStat>
              </HighlightCardStatRow>
              <HighlightCardStatLabel>Total Active Posts</HighlightCardStatLabel>
            </HighlightCardContentColumn>
          </HighlightCardContentRow>
        </HighlightCardContent>

        {/* Hidden table for screen readers with posts metrics */}
        <div className="sr-only">
          <table>
            <caption>Posts Metrics</caption>
            <tbody>
              <tr>
                <th scope="row">Total Active Posts</th>
                <td>{formatNumberDefault(value)} posts filtered by selected dates, projects, and groups</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </TooltipProvider>
  );
};

export default PostsCard;
