import React, { ComponentPropsWithoutRef, useMemo, useState } from 'react';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import { largeIntegerAbbreviator, largeNumberAbbreviatorWithDecimal } from '@frontend/utils';
import { isNil } from 'lodash';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { P } from '@frontend/shadcn/components/typography/p';
import TrendIcon from './TrendIcon';
import { getCurrencySymbol } from '../containers/Settings/ProductFulfillment/utils';

interface MainMetricProps {
  size: 'large' | 'medium' | 'small' | 'xsmall';
  heading?: string;
  value: number;
  icon: React.ReactNode;
  metricType?: 'number' | 'currency' | 'count';
  subHeading?: string;
  totalValue?: number;
  className?: string;
  colorSwatch?: string;
  trend?: number;
  popoverTextPrimary?: string;
  popoverTextSecondary?: string;
  currencyCode?: string;
}

const valueTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-5xl font-medium',
        medium: 'text-4xl font-medium',
        small: 'text-2xl font-medium',
        xsmall: 'text-xl font-medium',
      },
    },
  },
);

const MainMetric: React.FC<MainMetricProps> = ({
  size,
  heading,
  value,
  totalValue,
  metricType = 'number',
  icon,
  subHeading,
  className,
  colorSwatch,
  trend,
  popoverTextPrimary,
  popoverTextSecondary,
  currencyCode,
}) => {
  const totalValueWithAbbreviation = useMemo(() => {
    if (metricType === 'count') {
      return largeIntegerAbbreviator(totalValue || 0);
    }
    return largeNumberAbbreviatorWithDecimal(totalValue || 0);
  }, [metricType, totalValue]);
  const valueWithAbbreviation = useMemo(() => {
    if (metricType === 'count') {
      return largeIntegerAbbreviator(value || 0);
    }
    return largeNumberAbbreviatorWithDecimal(value || 0);
  }, [metricType, value]);

  const trendProps = useMemo<ComponentPropsWithoutRef<typeof TrendIcon>>(() => {
    if (!isNil(trend)) {
      const cleanTrend = Math.round(trend);

      return {
        trend: cleanTrend > 0 ? 'up' : cleanTrend < 0 ? 'down' : 'flat',
        value: `${new Intl.NumberFormat('en-US').format(cleanTrend)}%`,
      };
    }
    return undefined;
  }, [trend]);

  const [isOpen, setIsOpen] = useState(false);

  const currencySymbol = getCurrencySymbol(currencyCode || 'USD');

  return (
    <div className={classNames('flex flex-col gap-[1px]', className)}>
      {heading && (
        <div className="flex gap-2 items-center">
          {colorSwatch && <div className="h-3 w-3 rounded" style={{ backgroundColor: colorSwatch }} />}
          <span>{heading}</span>
        </div>
      )}
      <div
        className="relative"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Popover open={popoverTextPrimary && isOpen}>
          <PopoverTrigger asChild>
            <div className="flex items-baseline gap-[2px]">
              {metricType === 'currency' && <span className={valueTextStyles({ size })}>{currencySymbol}</span>}
              <span className={valueTextStyles({ size })}>{valueWithAbbreviation.value}</span>
              <span className={valueTextStyles({ size })}>{valueWithAbbreviation.symbol}</span>
              {totalValue && (
                <span className="text-gray-500">
                  {`/ ${
                    metricType === 'currency'
                      ? `${currencySymbol}${totalValueWithAbbreviation.value}${totalValueWithAbbreviation.symbol}`
                      : `${totalValueWithAbbreviation.value}${totalValueWithAbbreviation.symbol}`
                  } Total Used`}
                </span>
              )}
            </div>
          </PopoverTrigger>
          {popoverTextPrimary && (
            <PopoverContent
              side="top"
              align="start"
              className="z-[9999] w-60 p-3 text-sm bg-white shadow-lg rounded-md"
            >
              <div className="flex flex-col gap-1">
                <P className="text-grey-6 font-medium">{popoverTextPrimary}</P>
                {popoverTextSecondary && (
                  <P className="text-grey-4">{popoverTextSecondary}</P>
                )}
              </div>
            </PopoverContent>
          )}
        </Popover>
      </div>
      <div className="flex items-center gap-2">
        {icon}
        {trendProps && <TrendIcon {...trendProps} />}
        {subHeading && <span className="text-grey-4">{subHeading}</span>}
      </div>
    </div>
  );
};

export default MainMetric;
