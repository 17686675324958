import * as React from 'react';
import { map } from 'lodash';

import {
  useMemberOwnersQuery, useAssignOwnersToMembersMutation,
  useRemoveOwnersFromMembersMutation,
} from '@frontend/app/hooks';
import { OwnerSelect } from '@frontend/app/components';

const { useState } = React;

interface IProps {
  memberId: number;
  isRefreshUi?: boolean;
}

export const MemberOwners: React.FC<IProps> = React.memo((props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    data: {
      member: {
        owners = null,
      } = {},
    } = {},
    loading: loadingData,
    refetch: refetchMemberOwners,
  } = useMemberOwnersQuery(props.memberId);

  const [assignOwners] = useAssignOwnersToMembersMutation({
    onCompleted() {
      refetchMemberOwners();
    },
  });

  const [removeOwners] = useRemoveOwnersFromMembersMutation();

  const handleChangeOwners = async (ownerIds: string[]) => {
    setIsLoading(true);
    // First remove all owners from this member.
    await removeOwners({
      variables: {
        memberIds: [props.memberId],
        userIds: map(owners, 'id'),
      },
    });

    // Then assign selected owners.
    await assignOwners({
      variables: {
        memberIds: [props.memberId],
        userIds: ownerIds,
      },
    });
    setIsLoading(false);
  };

  return (
    <OwnerSelect owners={owners} onChangeOwners={handleChangeOwners} loading={loadingData || isLoading} refreshUi={props.isRefreshUi} />
  );
});
