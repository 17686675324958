import * as React from 'react';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { cn } from '@frontend/shadcn/lib/utils';
import dayjs from 'dayjs';
import { DateRangeFormCalendarProps } from './date-range-form.types';

const DateRangeFormCalendar = React.forwardRef<HTMLDivElement, DateRangeFormCalendarProps>(
  ({ className, dateRange, onDateRangeSelect, open, onOpenChange, minDate, children, ...props }, ref) => (
    <div ref={ref} className={cn('border-t mt-1 flex items-center justify-center', className)} {...props}>
      <Popover open={open} onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div role="dialog" aria-label="Date range calendar">
            <Calendar
              mode="range"
              selected={{
                from: dateRange?.from ? dayjs(dateRange.from).toDate() : undefined,
                to: dateRange?.to ? dayjs(dateRange.to).toDate() : undefined,
              }}
              onSelect={onDateRangeSelect}
              disabled={(date) => {
                const isAfterToday = date > new Date();
                const isBeforeMinDate = minDate ? date < minDate : false;
                return isAfterToday || isBeforeMinDate;
              }}
              initialFocus
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  ),
);

DateRangeFormCalendar.displayName = 'DateRangeFormCalendar';

export { DateRangeFormCalendar };
