import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import styles from './Button.scss';

/**
 * Button themes:
 * 'primary' | 'info' | 'light' | 'danger'
 */
export type TButtonTheme = 'primary' | 'info' | 'light' | 'lightblue' | 'danger';
type TIconSide = 'left' | 'right';

export interface IButtonProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, ButtonComponent & HTMLDivElement> {
  label: React.ReactNode;

  theme?: TButtonTheme;
  border?: boolean;
  fullWidth?: boolean;
  round?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  iconSide?: TIconSide;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  className?: string;
}

type TDefaultProp =
  | 'theme'
  | 'border'
  | 'fullWidth'
  | 'round'
  | 'disabled'
  | 'icon'
  | 'iconSide'
  | 'forwardedRef';

// Allow passing ref from parent
export const Button = React.forwardRef((props: IButtonProps, ref: React.RefObject<HTMLDivElement>) => <ButtonComponent {...props} forwardedRef={ref} />);

/**
 * @class
 * @extends {React.Component}
 */
class ButtonComponent extends React.Component<IButtonProps> {
  public static defaultProps: Pick<IButtonProps, TDefaultProp> = {
    theme: 'primary',
    border: true,
    fullWidth: false,
    round: true,
    icon: null,
    iconSide: 'left',
    disabled: false,
  };

  private ref: React.RefObject<HTMLDivElement>;

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillMount() {
    this.ref = this.props.forwardedRef || React.createRef();
  }

  private onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onClick, disabled } = this.props;

    if (disabled) {
      event.preventDefault();
      return;
    }

    if (isFunction(onClick)) {
      onClick(event);
    }
  };

  private onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onMouseDown, disabled } = this.props;

    if (disabled) {
      event.preventDefault();
      return;
    }

    if (isFunction(onMouseDown)) {
      onMouseDown(event);
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
      label,
      icon,
      iconSide,
      border,
      theme,
      round,
      fullWidth,
      disabled,
      className,
      onMouseDown, // eslint-disable-line @typescript-eslint/no-unused-vars
      onClick, // eslint-disable-line @typescript-eslint/no-unused-vars
      // Avoid forwardedRef to be passed with ...props
      forwardedRef, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...props
    } = this.props;

    return (
      <div
        className={cx(styles.Button, className, styles[theme], {
          [styles.noBorder]: !border,
          [styles.fullWidth]: fullWidth,
          [styles.round]: round,
          [styles.disabled]: disabled,
        })}
        ref={this.ref}
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        button-theme={`button-${theme}`}
        {...props}
      >
        {icon && iconSide === 'left' && <div className={cx(styles.icon, styles.left)}>{icon}</div>}
        <div className={styles.label}>{label}</div>
        {disabled && <div className={styles.mask} />}
        {icon && iconSide === 'right' && <div className={cx(styles.icon, styles.right)}>{icon}</div>}
      </div>
    );
  }
}

Button.displayName = 'Button';
