import 'css-chunk:src/applications/AffiliatesApp/components/OfferForm/OfferForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_fiycf_157",
  "justify-content-space-between": "_justify-content-space-between_fiycf_161",
  "tabular-nums": "_tabular-nums_fiycf_165",
  "OfferForm": "_OfferForm_fiycf_178",
  "instructions": "_instructions_fiycf_178",
  "title": "_title_fiycf_178",
  "description": "_description_fiycf_225",
  "formSection": "_formSection_fiycf_229",
  "icon": "_icon_fiycf_244",
  "headerSpace": "_headerSpace_fiycf_258",
  "dividerColor": "_dividerColor_fiycf_261",
  "customLabelText": "_customLabelText_fiycf_273",
  "AlertWrapper": "_AlertWrapper_fiycf_276",
  "codeSuffixWrapper": "_codeSuffixWrapper_fiycf_279",
  "halfRow": "_halfRow_fiycf_284",
  "descriptionWrapper": "_descriptionWrapper_fiycf_287",
  "descriptionText": "_descriptionText_fiycf_290",
  "indented": "_indented_fiycf_294",
  "centered": "_centered_fiycf_300",
  "radioGroup": "_radioGroup_fiycf_303",
  "radio": "_radio_fiycf_303",
  "expiredMessage": "_expiredMessage_fiycf_313",
  "datePicker": "_datePicker_fiycf_321",
  "tooltipList": "_tooltipList_fiycf_330",
  "hThreeRem": "_hThreeRem_fiycf_338",
  "hasCharacterCount": "_hasCharacterCount_fiycf_341",
  "tagIcon": "_tagIcon_fiycf_344",
  "fullWidth": "_fullWidth_fiycf_347",
  "archiveOfferAlert": "_archiveOfferAlert_fiycf_350",
  "sharedWrapper": "_sharedWrapper_fiycf_354",
  "multipleConversionWrapper": "_multipleConversionWrapper_fiycf_354",
  "utmWrapper": "_utmWrapper_fiycf_354",
  "urlwrapper": "_urlwrapper_fiycf_368",
  "boxwrapper": "_boxwrapper_fiycf_373",
  "urltitle": "_urltitle_fiycf_380",
  "subheading": "_subheading_fiycf_384",
  "utmexample": "_utmexample_fiycf_388",
  "customText": "_customText_fiycf_395",
  "inputBox": "_inputBox_fiycf_404",
  "Closefield": "_Closefield_fiycf_407",
  "disable-option": "_disable-option_fiycf_415",
  "ant-select-item-option-content": "_ant-select-item-option-content_fiycf_415",
  "promoCodeContainer": "_promoCodeContainer_fiycf_446",
  "descTextColor": "_descTextColor_fiycf_446",
  "customRadioGroup": "_customRadioGroup_fiycf_450",
  "samplePromoContainer": "_samplePromoContainer_fiycf_457",
  "purchaseTypeContainer": "_purchaseTypeContainer_fiycf_463",
  "offerSyncContainer": "_offerSyncContainer_fiycf_471",
  "customSyncText": "_customSyncText_fiycf_474",
  "offerSyncForm": "_offerSyncForm_fiycf_478",
  "syncContainer": "_syncContainer_fiycf_481",
  "offerStatusContainer": "_offerStatusContainer_fiycf_485",
  "CustomTextColor": "_CustomTextColor_fiycf_489",
  "disableRadio": "_disableRadio_fiycf_528",
  "payoutOptionContainer": "_payoutOptionContainer_fiycf_533",
  "startDateBorder": "_startDateBorder_fiycf_537",
  "mPayoutConatiner": "_mPayoutConatiner_fiycf_543",
  "addPayoutButton": "_addPayoutButton_fiycf_548",
  "defaultPayout": "_defaultPayout_fiycf_552",
  "radioCustomContainer": "_radioCustomContainer_fiycf_559",
  "radioItem": "_radioItem_fiycf_564",
  "image": "_image_fiycf_567",
  "marginOnAlert": "_marginOnAlert_fiycf_574",
  "errorMessage": "_errorMessage_fiycf_580",
  "alertContainer": "_alertContainer_fiycf_584",
  "indentationMargin": "_indentationMargin_fiycf_588",
  "marginBottom8": "_marginBottom8_fiycf_593",
  "refreshPurchaseLabel": "_refreshPurchaseLabel_fiycf_597",
  "refreshDomainSelect": "_refreshDomainSelect_fiycf_601",
  "refreshDomainOption": "_refreshDomainOption_fiycf_615",
  "refreshForm": "_refreshForm_fiycf_619",
  "refreshPurchaseRestriction": "_refreshPurchaseRestriction_fiycf_619",
  "show": "_show_fiycf_1"
};