import * as React from 'react';

import { logger } from '@common';
import { Typography } from '@revfluence/fresh';

import styles from './ErrorBoundary.scss';
import { ErrorTrace } from './ErrorTrace';

declare global {
  interface Window {
    DD_RUM?: {
      addError: (error: Error, context?: Record<string, unknown>) => void;
      addAction: (name: string, context?: Record<string, unknown>) => void;
    };
  }
}

interface IState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorInfo: any;
}

interface IProps {
  id?: string; // Optional identifier for this error boundary
  name?: string; // Optional name for this error boundary
}

const { Link, Title } = Typography;

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  private getComponentPath(componentStack: string): string {
    // Extract the first component from the stack trace which is the one that errored
    const firstComponent = componentStack
      ?.split('\n')
      ?.find((line) => line.includes('at '))
      ?.trim()
      ?.replace('at ', '');

    return firstComponent || 'Unknown Component';
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });

    const { id, name } = this.props;
    const componentPath = this.getComponentPath(errorInfo.componentStack);

    const errorContext = {
      error,
      boundaryInfo: {
        id,
        name,
        location: ['Error Boundary', id && `[${id}]`, name && `(${name})`].filter(Boolean).join(' '),
        erroredComponent: componentPath,
        fullComponentStack: errorInfo.componentStack,
      },
    };

    // Log to console and logger with boundary location info
    logger.error('ErrorBoundary: Error caught:', errorContext);

    // Also send to DD RUM if available
    if (window.DD_RUM) {
      window.DD_RUM.addError(error, errorContext);
    }
  }

  public componentDidUpdate(_: IProps, prevState: IState) {
    // Track when error view is shown to user (when error state changes from null to error)
    if (!prevState.error && this.state.error && window.DD_RUM) {
      window.DD_RUM.addAction('error_boundary_view_shown', {
        error: this.state.error,
        errorMessage: this.state.error.message,
        componentStack: this.state.errorInfo?.componentStack,
        boundaryId: this.props.id,
        boundaryName: this.props.name,
        path: window.location.pathname,
        url: window.location.href,
        search: window.location.search,
        hash: window.location.hash,
      });
    }
  }

  public render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (error) {
      return (
        <div className={styles.ErrorBoundary}>
          <div className={styles.content}>
            <div className={styles.text}>
              <Title className={styles.whoopsie} level={1}>
                Something went wrong.
              </Title>
              <Title className={styles.subtext} level={4} noMargin>
                This might take a little investigating.
                <br />
                If this issue continues, contact support&nbsp;
                <Link href="mailto:help@aspireiq.com">here</Link>.
              </Title>
            </div>
            <ErrorTrace className={styles.errorTrace} error={error} errorInfo={errorInfo} />
            <img className={styles.image} src="https://storage.googleapis.com/aspirex-static-files/error-art.svg" />
          </div>
        </div>
      );
    }

    return children;
  }
}
