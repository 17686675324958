import * as React from 'react';
import {
  chain,
  countBy,
  filter,
  includes,
  isEmpty,
  isNil,
  keyBy,
  keys,
  map,
  upperFirst,
} from 'lodash';
import { FieldSource } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { MemberFieldSchemasQuery_schemas } from '@frontend/app/queries/types/MemberFieldSchemasQuery';
import { CommunityMemberFieldSchemasQuery_community_memberFieldSchemas } from '@frontend/app/containers/Members/queries/types/CommunityMemberFieldSchemasQuery';
import {
  FieldSource as FieldSourceLabel,
  IApplication,
  IMemberFieldsWithSources,
} from '../../containers/Members/types/MemberFieldsWithSources';
import {
  useInstalledApplicationInstances,
  useGetApplicationsByIds,
} from '..';
import { useMemberColumns } from '../../components/MemberTable/hooks';
import { useCommunityMemberFieldSchemas } from './useCommunityMemberFieldSchemas';

const { useMemo } = React;

const EXCLUDED_APPS = ['AspireIQ Create'];
// https://aspireiq.atlassian.net/browse/COL-9385
const HIDDEN_COLUMNS = [
  'Sales Tracking Offers',
];
// https://aspireiq.slack.com/archives/C01067Q9Z0E/p1711493049450279?thread_ts=1711396861.829239&cid=C01067Q9Z0E
const HIDDEN_PROJECT_COLUMNS = [
  'Mentions',
  'Instagram Recent Posts',
];

export const useMemberFieldsWithSources = (communityId?: number, projectId?: number, enableProjectColumns?: boolean): IMemberFieldsWithSources => {
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);

  const {
    loading: isInstalledApplicationsLoading,
    data: {
      instances: applicationInstances = [],
    } = {},
  } = useInstalledApplicationInstances();
  const {
    loading: isGetApplicationsByIdsLoading,
    data: {
      applications = null,
    } = {},
  } = useGetApplicationsByIds(
    applicationInstances ? map(applicationInstances, 'applicationId') : [],
  );

  const appById = useMemo(() => (
    chain<IApplication>(applications)
      .mapValues((app) => ({
        id: app.id,
        name: app.name.replace(/Application$/, 'App'),
      }))
      .keyBy('id')
      .value()
  ), [applications]);

  const isLoading = isInstalledApplicationsLoading || isGetApplicationsByIdsLoading;

  /**
   * Member field schemas
   */
  const columns = useMemberColumns(communityId, projectId);

  const {
    data: memberFieldSchemas,
    refetch,
  } = useCommunityMemberFieldSchemas(communityId);

  const subColumnData : CommunityMemberFieldSchemasQuery_community_memberFieldSchemas[] = useMemo(() => (Array.isArray(memberFieldSchemas)
      ? memberFieldSchemas
          .flatMap((schema: MemberFieldSchemasQuery_schemas) => {
            if (!schema?.metaData?.subColumns || !Array.isArray(schema.metaData.subColumns)) {
              return [];
            }
            return schema.metaData.subColumns
              .filter((subColumn) => subColumn && !subColumn.metaData?.isSuperColumn)
              .map((subColumn) => ({
                field: String(subColumn.id),
                headerName: upperFirst(subColumn.name),
                type: subColumn.type,
                editable: (!subColumn.applicationId || subColumn.metaData?.editable),
                choices: subColumn.choices,
                schemaId: subColumn.id,
                hidden: true,
                id: subColumn.id,
                applicationId: subColumn.applicationId,
                metaData: subColumn.metaData,
                __typename: subColumn.__typename,
              }));
          })
          .filter(Boolean)
      : []), [memberFieldSchemas]);

  const allColumnsData = useMemo(() => {
    if (!Array.isArray(memberFieldSchemas) || !memberFieldSchemas.length) {
      return [];
    }
    return [...subColumnData, ...memberFieldSchemas];
  }, [subColumnData, memberFieldSchemas]);

  const memberFieldSchemasById = useMemo(() => (
    keyBy(allColumnsData, 'id')
  ), [allColumnsData]);

  const appsWithFields: IMemberFieldsWithSources['appsWithFields'] = useMemo(() => {
    const schemaCountById = countBy(
      filter(memberFieldSchemas, (schema) => schema.applicationId),
      'applicationId',
    );

    if (!isEmpty(appById)) {
      return chain(keys(schemaCountById))
        .map((id) => appById[id])
        .filter((app) => !isNil(app) && !includes(EXCLUDED_APPS, app?.name))
        .sortBy((app) => app.name)
        .value();
    }
  }, [appById, memberFieldSchemas]);

  /**
   * Sources
   */
  const fields: IMemberFieldsWithSources['fields'] = useMemo(() => {
    const relationshipFields = [
      'activations',
      'tags',
      'programs',
      'invitedPrograms',
      'submittedPrograms',
      'rejectedPrograms',
      'communities',
      'owners',
      'highlights',
      'talentAgents',
    ];
    let filtered = filter(columns, (column) => !includes(HIDDEN_COLUMNS, column.headerName));
    // Filter these columns out for project page
    if (projectId) {
      filtered = filter(filtered, (column) => !includes(HIDDEN_PROJECT_COLUMNS, column.headerName) && column?.showInEditColumns !== false);
    }

    // For now, let's remove the project columns from member page, some work(backend sorting, thinking about how we query this) needs to be done to make it work
    if (!enableProjectColumns) {
      filtered = filtered.filter((column) => column.source !== FieldSource.ProjectColumn);
    }
    filtered = filtered.filter((column) => {
      const schema = chain(memberFieldSchemasById)
        .get(column.field)
        .omit('__typename')
        .value();
      const appId = schema?.applicationId;
      if (appById[appId] && includes(EXCLUDED_APPS, appById[appId].name)) {
        return false;
      }
      return true;
    });

    return map(filtered, (column) => {
      const schema = chain(memberFieldSchemasById)
        .get(column.field)
        .omit('__typename')
        .value();
      const isDefault = column.isDefault || schema?.isDefault || false;
      const appId = schema?.applicationId;
      const source = (() => {
        if (includes(relationshipFields, column.field)) {
          return FieldSourceLabel.Relationship;
        } else if (isDefault && isNil(appId)) {
          return FieldSourceLabel.Default;
        } else if (isPFAColumnsEnabled && column.source === FieldSource.ProjectColumn) {
          return FieldSourceLabel.Project;
        } else if (!isNil(appId)) {
          return appById[appId];
        } else {
          return FieldSourceLabel.Custom;
        }
      })();

      return {
        ...column,
        ...schema, // if available
        source,
      };
    });
  }, [appById, columns, projectId, memberFieldSchemasById, isPFAColumnsEnabled, enableProjectColumns]);

  return {
    appsWithFields,
    fields,
    isLoading,
    refetch,
  };
};
