import React from 'react';
import { StarFilled } from '@ant-design/icons';
import { StarIcon } from '@revfluence/fresh-icons/regular';
import { AllProductObject, calculateDiscount, defaultImageUrl } from '../../utils/LandingPagePreviewUtils';

export const LandingPagePreviewAllProductSection = ({ data }: { data: AllProductObject }) => {
  const {
    title,
    products,
    landingPageStyles,
    showProductRating,
    showProductPrice,
    currency,
    offerDiscount,
    discountType,
  } = data;
  return (
    <div className="frame-50 page-width ">
      {products.length > 0 && (
        <div
          data-testid="all-products-title"
          id="all-products-title"
          className="text-wrapper-20"
          style={{
            fontSize: landingPageStyles.typography?.headings?.fontSize, color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.headings?.fontStyle, transform: 'scale(0.8)',
          }}
        >
          {title}
        </div>
      )}
      <div id="products-container" />
      <div className="frame-51 hidden">
        <div className="frame-52">
          {products.map((product, index) => (
            <div data-testid="product-name" className="frame-53" key={index}>
              <img className="image-7" src={product.imageUrl || defaultImageUrl} width="300" height="200" style={{ borderRadius: landingPageStyles.productCard.cornerRadius }} />
              <div className="frame-7">
                <div className="frame-54">
                  <div className="frame-55" style={{ fontFamily: landingPageStyles.typography.headings.fontStyle }}>
                    <div className="text-wrapper-35">{product.title}</div>
                    {showProductPrice && (
                      <div className="frame-34">
                        <div className="text-wrapper-37" style={{ color: landingPageStyles?.typography?.headings?.fontColor, fontFamily: landingPageStyles?.typography?.body?.fontStyle }}>
                          <span className="product-discounted-price">
                            {currency}
                            {Number(product?.price || 0).toFixed(2)}
                          </span>
                          {' '}
                          <span data-testid="product-discounted-price">
                            {currency}{Number(product?.discountedPrice || calculateDiscount(Number(product?.price), Number(offerDiscount), discountType || '')).toFixed(2)}
                          </span>

                        </div>
                      </div>
                    )}
                  </div>
                  {showProductRating && (
                    <div className="div">
                      <div id="favorite-featured-product-rating-container" className="frame-35" style={{ color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                        {Array.from({ length: 5 }).map((_, index) =>
                          (index < Number(product.rating) ? <StarFilled key={index} /> : <StarIcon key={index} />))}
                      </div>
                      <div id="favorite-featured-product-reviews-count" className="text-wrapper-25">
                        (
                        {product.reviewCount || 0}
                        {' '}
                        reviews)
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
