export function largeNumberAbbreviator(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return num; }
  if (num == null) {
    return '0';
  }
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}B`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num.toFixed(0);
}

export function percentage(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return num; }
  return `${Math.round(num)}%`;
}

export function dollar(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return num; }
  return `$${num.toFixed(2)}`;
}

export function largeNumberAbbreviatorWithDecimal(num) {
  num = parseFloat(num.toFixed(2));
  if (isNaN(num)) { return { value: num, symbol: '' }; }
  if (num == null) {
    return { value: '0', symbol: '' };
  }

  // For millions ($1M+)
  if (num >= 1000000) {
    const value = (num / 1000000).toFixed(1).replace(/\.0$/, '');
    return { value, symbol: 'mn' };
  }

  // For thousands ($1,000 - $999,999)
  if (num >= 1000) {
    const value = (num / 1000).toFixed(1).replace(/\.0$/, '');
    return { value, symbol: 'k' };
  }

  // For small amounts ($1-$999)
  return {
    value: num.toFixed(2),
    symbol: ''
  };
}

export function largeIntegerAbbreviator(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return { value: num, symbol: '' }; }
  if (num == null) {
    return { value: '0', symbol: '' };
  }
  if (num >= 1000000000) {
    return { value: num / 1000000000, symbol: 'B' };
  }
  if (num >= 1000000) {
    return { value: num / 1000000, symbol: 'M' };
  }
  return { value: Intl.NumberFormat('en-US').format(num), symbol: '' };
}
