import { useCallback } from 'react';

interface TablePaginationConfig {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

interface TablePaginationReturn {
  paginationProps: {
    currentPage: number;
    limit: number;
    totalCount: number;
  };
  prevButtonProps: {
    onClick: () => void;
    disabled: boolean;
  };
  nextButtonProps: {
    onClick: () => void;
    disabled: boolean;
  };
}

export function useTablePagination({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}: TablePaginationConfig): TablePaginationReturn {
  const totalPages = Math.ceil(totalCount / pageSize);
  const hasNextPage = currentPage < totalPages - 1;
  const hasPreviousPage = currentPage > 0;

  const handlePreviousPage = useCallback(() => {
    if (hasPreviousPage) {
      onPageChange(currentPage - 1);
    }
  }, [hasPreviousPage, currentPage, onPageChange]);

  const handleNextPage = useCallback(() => {
    if (hasNextPage) {
      onPageChange(currentPage + 1);
    }
  }, [hasNextPage, currentPage, onPageChange]);

  return {
    paginationProps: {
      currentPage,
      limit: pageSize,
      totalCount,
    },
    prevButtonProps: {
      onClick: handlePreviousPage,
      disabled: !hasPreviousPage,
    },
    nextButtonProps: {
      onClick: handleNextPage,
      disabled: !hasNextPage,
    },
  };
}
