import { useCallback } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

export const useTabNavigation = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const baseUrl = path.replace(':tab', '');

  const handleTabChange = useCallback(
    (value: string) => {
      history.push({
        pathname: baseUrl + value,
        search: location.search,
      });
    },
    [baseUrl, history, location.search],
  );

  return {
    handleTabChange,
    baseUrl,
  };
};
