import {
  useState, useMemo, KeyboardEvent, useCallback,
} from 'react';
import { debounce } from 'lodash';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

interface UseSearchWithDebounceProps {
  /** Initial value for the search input */
  initialValue?: string;

  /** Delay in milliseconds before the search text updates after typing
   * @default 300
   */
  delay?: number;

  /** Callback fired when the debounced search value changes */
  onChange?: (value: string) => void;

  /** Callback fired when keyboard navigation occurs */
  onNavigate?: (direction: 'up' | 'down' | 'enter') => void;

  /** Callback fired when error state changes */
  onError?: (error: string | null) => void;

  /** Message to display when no results are found
   * @default 'No results found'
   */
  noResultsMessage?: string;

  /** Analytics configuration */
  searchAnalytics?: {
    /** Enable search analytics tracking */
    enabled: boolean;
    /** Identifier for the component using search (e.g., 'projects_menu', 'members_list') */
    searchContext: string;
    /** Optional additional data to include in analytics event */
    metadata?: Record<string, unknown>;
  };
}

/**
 * A custom hook that provides debounced search functionality with keyboard navigation
 * and error handling support.
 *
 * Features:
 * - Debounced search updates
 * - Loading state during typing and filtering
 * - Keyboard navigation (up/down/enter)
 * - No results error handling
 * - Immediate input value updates with delayed search text
 */
export const useSearchWithDebounce = ({
  initialValue = '',
  delay = 300,
  onChange,
  onNavigate,
  onError,
  noResultsMessage = 'No results found',
  searchAnalytics,
}: UseSearchWithDebounceProps = {}) => {
  const [searchText, setSearchText] = useState(initialValue);
  const [inputValue, setInputValue] = useState(initialValue);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const addEvent = useEventContext();

  const checkNoResults = useCallback((hasResults: boolean) => {
    if (searchText && !hasResults) {
      setError(noResultsMessage);
      onError?.(noResultsMessage);
    } else {
      setError(null);
      onError?.(null);
    }
  }, [searchText, onError, noResultsMessage]);

  const debouncedSearch = useMemo(
    () => debounce((val: string) => {
      setSearchText(val);
      onChange?.(val);
      setIsLoading(false);
      if (searchAnalytics?.enabled) {
        addEvent(EventName.SearchTerm, {
          search_term: val,
          search_context: searchAnalytics?.searchContext,
          timestamp: new Date().toISOString(),
          ...(searchAnalytics?.metadata ?? {}),
        });
      }
    }, delay),
    [delay, onChange, searchAnalytics, addEvent],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsLoading(true);
    debouncedSearch(newValue);
    setActiveIndex(-1);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        onNavigate?.('down');
        setActiveIndex((prev) => prev + 1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        onNavigate?.('up');
        setActiveIndex((prev) => Math.max(-1, prev - 1));
        break;
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0) {
          onNavigate?.('enter');
        }
        break;
      case 'Escape':
        e.preventDefault();
        setInputValue('');
        setSearchText('');
        setActiveIndex(-1);
        setIsLoading(false);
        break;
    }
  };

  return {
    searchText,
    inputValue,
    activeIndex,
    handleSearchChange,
    handleKeyDown,
    setSearchText,
    setInputValue,
    setActiveIndex,
    error,
    checkNoResults,
    isLoading,
  };
};
