import * as React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Input } from '@frontend/shadcn/components/ui/input';
import { ChevronDownIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { ScrollArea } from '@frontend/shadcn/components/ui/scroll-area';

interface Option {
  label: React.ReactNode;
  value: string;
  searchValue?: string;
}

interface MultiSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  options: Option[];
  placeholder?: string;
  className?: string;
}

export const MultiSelect = ({
 value = [], onChange, options, placeholder, className,
}: MultiSelectProps) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const containerRef = React.useRef<HTMLDivElement>(null);

  const filteredOptions = React.useMemo(() => options.filter((option) =>
      (option.searchValue || option.label.toString()).toLowerCase().includes(search.toLowerCase())), [options, search]);

  const handleSelect = (optionValue: string) => {
    const newValue = value.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onChange?.(newValue);
  };

  // Close dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className="relative">
      <Button
        variant="outline"
        className={cn('w-full h-auto min-h-8 justify-between items-center px-2 py-0.5 bg-transparent', className)}
        onClick={() => setOpen(!open)}
        type="button"
      >
        <div className="flex flex-wrap gap-1 pr-4">
          {value.map((selectedValue) => {
            const option = options.find((opt) => opt.value === selectedValue);
            return (
              <Badge
                key={selectedValue}
                variant="outline"
                className="my-0.5"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(selectedValue);
                }}
              >
                {option?.label}
                <XmarkIcon className="ml-1 h-3 w-3" />
              </Badge>
            );
          })}
          {!value.length && (
            <span className="text-xs text-primaryMuted my-0.5">{placeholder}</span>
          )}
        </div>
        <ChevronDownIcon className="h-4 w-4 opacity-50" />
      </Button>
      {open && (
        <div className="absolute top-full mt-1 w-full z-50 bg-popover rounded-md border shadow-md">
          <div className="p-2">
            <Input
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="mb-2"
              onClick={(e) => e.stopPropagation()}
              autoComplete="off"
            />
            <ScrollArea className="h-[200px]">
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={cn(
                    'flex items-center px-2 py-1.5 cursor-pointer rounded-sm',
                    'hover:bg-accent hover:text-accent-foreground',
                    value.includes(option.value) && 'bg-accent',
                  )}
                  onClick={() => {
                    handleSelect(option.value);
                    setSearch(''); // Clear search after selection
                  }}
                >
                  {option.label}
                </div>
              ))}
              {!filteredOptions.length && (
                <div className="text-sm text-muted-foreground text-center py-2">
                  No results found
                </div>
              )}
            </ScrollArea>
          </div>
        </div>
      )}
    </div>
  );
};
