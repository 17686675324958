import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { CalendarIcon } from 'lucide-react';
import { IShopifyFormElementProps, OfferFormAsyncActions } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { cx } from 'class-variance-authority';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import moment, { Moment } from 'moment';

interface IProps extends IShopifyFormElementProps<'activeDate'> {
  endDate: Moment;
  value: Moment;
}

export const RefreshOfferActiveDate: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  endDate,
  handleFormAsyncActions,
  value,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(value);

  const handleDateChange = (date: Date | undefined) => {
    if (!date) {
      setSelectedDate(null);
      return;
    }
    const now = moment();
    const momentDate = moment.utc(moment(date).set({
      hour: now.hour(),
      minute: now.minute(),
      second: now.second(),
    }));

    setSelectedDate(momentDate);

    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: {
        key: name,
        value: momentDate,
      },
    });
  };

  return (
    <Form.Item
      name={name}
      rules={[
        { required: true, message: 'Please select a date' },
        {
          validator: (_, _value) => {
            if (selectedDate && moment(selectedDate).isSameOrAfter(endDate, 'minute')) {
              return Promise.reject(new Error('Active date must be before end date'));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cx(
              'w-[250px] justify-start text-left font-normal',
              !selectedDate && 'text-muted-foreground'
            )}
            disabled={disabled}
          >
            {selectedDate ? selectedDate.format('MMM D, YYYY') : 'Pick a date'}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={selectedDate?.toDate()}
            onSelect={handleDateChange}
            disabled={(date) => moment(date).isBefore(moment().startOf('day'))}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </Form.Item>
  );
});

RefreshOfferActiveDate.displayName = 'RefreshOfferActiveDate';
