import React from 'react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@frontend/shadcn/components/ui/accordion';
import { FormAsyncAction, IShopifyPromoCodeFormValues, ProductCollections, TDisabledMap } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { RefreshOfferCodePurchaseRestrictions } from '../../RefreshOfferCodePurchaseRestrictions';
import { RefreshOfferPurchaseType } from '../../RefreshOfferPurchaseType';
import { RefreshOfferRecurringPaymentForSubscriptions } from '../../RefreshOfferRecurringPaymentForSubscriptions';
import { RefreshOfferCodeCollectionOptions } from '../../RefreshOfferCodeCollectionOptions';
import { RefreshOfferCodeLimitNewCustomersOnly } from '../../RefreshOfferCodeLimitNewCustomersOnly';
import { RefreshOfferCodeLimitOnePerCustomer } from '../../RefreshOfferCodeLimitOnePerCustomer';
import { RefreshOfferCodeLimitCodeUses } from '../../RefreshOfferCodeLimitCodeUses';
import { RefreshOfferPromoCodeCombinations } from '../../RefreshOfferPromoCodeCombinations';

interface IProps {
  connectedSubscriptionEnabled: boolean;
  connectedStore: Set<string>;
  collections: ProductCollections[] | null;
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  values: IShopifyPromoCodeFormValues;
  disabled: TDisabledMap<IShopifyPromoCodeFormValues>;
}
const PurchaseRestriction: React.FC<Readonly<IProps>> = ({
  values,
  connectedSubscriptionEnabled,
  connectedStore,
  collections,
  handleFormAsyncActions,
  disabled,
}) => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="links-section">
        <AccordionTrigger className="hover:no-underline p-0 mt-2">
          <div className="flex flex-col gap-1">
            <p className="text-grey-6 text-sm font-medium m-0">Purchase Restrictions</p>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="w-full overflow-x-hidden space-y-6">
            <div>
              <RefreshOfferPurchaseType value={values.purchaseType} disabled={!connectedSubscriptionEnabled} name="purchaseType" handleFormAsyncActions={handleFormAsyncActions} connectedStore={connectedStore} />
              {(values.purchaseType === 'Subscription' || values.purchaseType === 'Both') && (
                <RefreshOfferRecurringPaymentForSubscriptions
                  value={values.offerCodePurchaseRestrictionsRule}
                  disabled={false}
                  name="offerCodePurchaseRestrictionsRule"
                  handleFormAsyncActions={handleFormAsyncActions}
                />
              )}
            </div>
            <div>
              <p className="text-grey-6 text-sm font-medium mb-2">Discount usage limits</p>

              <RefreshOfferCodeLimitOnePerCustomer value={values.specialLimitOnePerSale} disabled={disabled.specialLimitOnePerSale || values.isSecureCodes} name="specialLimitOnePerSale" handleFormAsyncActions={handleFormAsyncActions} secureCodeValue={values.isSecureCodes} />
              <RefreshOfferCodeLimitNewCustomersOnly value={values.specialLimitNewCustomersOnly} disabled={disabled.specialLimitNewCustomersOnly} name="specialLimitNewCustomersOnly" handleFormAsyncActions={handleFormAsyncActions} />
              <RefreshOfferCodeLimitCodeUses
                disabled={values.isSecureCodes || disabled.specialLimitUsageCapEnabled}
                name="specialLimitUsageCapEnabled"
                value={values.specialLimitUsageCapEnabled}
                handleFormAsyncActions={handleFormAsyncActions}
                secureCodeValue={values.isSecureCodes}
                specialLimitUsageCapAmount={values.specialLimitUsageCapAmount}
              />
            </div>
            <div>

              <RefreshOfferCodePurchaseRestrictions disabled={false} name="usageLimitRule" value={values.usageLimitRule} handleFormAsyncActions={handleFormAsyncActions} />
            </div>
            {!values.isMultipleShopifySyncEnabled && (
              <div>
                <RefreshOfferCodeCollectionOptions
                  collections={collections}
                  name="productCollections"
                  disabled={false}
                  handleFormAsyncActions={handleFormAsyncActions}
                  isCollection={!!values?.productCollections?.length}
                />
              </div>
            )}

            <div>
              <RefreshOfferPromoCodeCombinations
                value={values}
                handleFormAsyncActions={handleFormAsyncActions}
              />
            </div>

          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default PurchaseRestriction;
