import { ApolloProvider } from '@apollo/client';
import { FindAndImportProvider } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { useAppContext } from '@frontend/context/AppContext';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { Eye, Plus, X } from 'lucide-react';
import React, { useState } from 'react';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ExportProductModal } from './ExportProductModal';

type ProductDetails = {
  id: string;
  imgUrl?: string;
  title: string;
  imageUrl?: string;
  description?: string;
  productUrl?: string;
};

const FeaturedProductContent: React.FC = (): JSX.Element => {
  const { pfaApolloClient } = useAppContext();
  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();
  const { landingPageData, setLandingPageData, clearFieldError } = useLandingPageContext();
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);

  const handleRemoveProduct = () => {
    setSelectedProduct(null);

    // Clear product from landing page data
    setLandingPageData((prev) => ({
      ...prev,
      featureProduct: null,
    }));
  };

  // Handle updates for featured product discount settings
  const handleInputChange = (key: string, value: boolean) => {
    setLandingPageData((prev) => ({
      ...prev,
      basic: {
        ...prev.basic,
        featuredProductDiscount: {
          ...prev.basic?.featuredProductDiscount,
          [key]: value,
        },
      },
    }));

    // Clear feature product error when disabling the hero feature
    if (key === 'addHeroFeatureProduct' && !value) {
      clearFieldError('basic.featuredProduct');
    }
  };

  const handleSelectProduct = () => {
    setIsLocalModalOpen(true);
    setIsFindAndImportOpen(true);
  };

  const handleCloseModal = () => {
    setIsLocalModalOpen(false);
    setIsFindAndImportOpen(false);
  };

  const { featuredProductDiscount } = landingPageData.basic;
  // Initialize selectedProduct with the existing product object from landingPageData
  const [selectedProduct, setSelectedProduct] = useState<ProductDetails | null>(
    landingPageData?.featureProduct || null,
  );
  return (
    <div className="flex flex-col items-start gap-4 px-4 w-full">
      <div className="flex items-center gap-2 w-full">
        <Switch
          id="hero-feature"
          checked={featuredProductDiscount.addHeroFeatureProduct}
          onCheckedChange={(value: boolean) => handleInputChange('addHeroFeatureProduct', value)}
        />
        <label htmlFor="hero-feature" className="font-medium text-sm text-neutral-color-palettegray-9">
          Add Hero Feature Product
        </label>
      </div>

      {featuredProductDiscount.addHeroFeatureProduct && (
        <>
          {selectedProduct?.id ? (
            <Card className="p-2 w-full">
              <CardContent className="p-0">
                <div className="flex items-center gap-3">
                  <div
                    className="w-[50px] h-[50px] rounded-lg bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${
                        selectedProduct.imageUrl || selectedProduct.imgUrl || '/frame-427320058.png'
                      })`,
                    }}
                  />
                  <div className="flex items-center justify-between flex-1">
                    <h3 className="text-grey-6 text-[14px] font-medium leading-6">{selectedProduct.title}</h3>
                    <div className="flex items-center gap-1">
                      <Button variant="ghost" size="icon" className="h-8 w-8 rounded-lg">
                        <a href={selectedProduct.productUrl} target="_blank" rel="noreferrer" className="flex text-primary hover:text-grey-5">
                          <Eye className="h-4 w-4 text-neutral-color-palettegray-9" />
                        </a>
                      </Button>
                      <Button variant="ghost" size="icon" className="h-8 w-8 rounded-lg" onClick={handleRemoveProduct}>
                        <X className="h-4 w-4 text-neutral-color-palettegray-9" />
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <Card className="w-full border-2 border-dashed border-grey-2 bg-grey-0">
              <CardContent className="flex items-center justify-center h-[66px] p-2">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg">
                    <Plus className="w-4 h-4 text-grey-5" />
                  </div>
                  <span onClick={handleSelectProduct} className="font-medium text-sm text-grey-5 cursor-pointer">
                    Select Product
                  </span>
                </div>
              </CardContent>
            </Card>
          )}
          {/* TODO: Add back in when we have a way to show the product rating */}
          <div className="items-center gap-2 w-full hidden">
            <Checkbox
              id="show-ratings"
              checked={featuredProductDiscount.showProductRating}
              onCheckedChange={(value: boolean) => handleInputChange('showProductRating', value)}
            />
            <label htmlFor="show-ratings" className="font-medium text-sm text-grey-6">
              Show Product Ratings
            </label>
          </div>

          {/* <div className="flex items-center gap-2 w-full">
            <Checkbox
              id="show-tag"
              checked={featuredProductDiscount.showTag}
              onCheckedChange={(value: boolean) => handleInputChange('showTag', value)}
            />
            <label htmlFor="show-tag" className="font-medium text-sm text-grey-6">
              Show Tag
            </label>
          </div> */}
        </>
      )}

      {isFindAndImportOpen && isLocalModalOpen && (
        <ApolloProvider client={pfaApolloClient}>
          <FindAndImportProvider>
            <ExportProductModal
              setSelectedProduct={setSelectedProduct}
              onClose={handleCloseModal}
            />
          </FindAndImportProvider>
        </ApolloProvider>
      )}
    </div>
  );
};

export default FeaturedProductContent;
