import { useMemo } from 'react';
import { Period } from '../../../gql/social/graphql';

const twoWeeksTimeSpan = 2 * 7 * 24 * 60 * 60 * 1000;

// Define valid periods as a const array to use for validation

// Type guard to validate if a string is a valid Period
export const isValidPeriod = (value: string): value is Period => {
  const validPeriods = ['All', 'Day', 'Month', 'Quarter', 'Week', 'Year'];
  return validPeriods.includes(value);
};

// Helper to convert string to Period if valid
export const toPeriod = (value: string): Period | null => {
  if (isValidPeriod(value)) {
    return value;
  }
  return null;
};

export const getPreviousIntervalRange = (currentIntervalRange: { startDate: string; endDate: string }) => {
  const currentIntervalTimeSpan = new Date(currentIntervalRange.endDate).getTime() - new Date(currentIntervalRange.startDate).getTime();
  return {
    startDate: new Date(new Date(currentIntervalRange.startDate).getTime() - currentIntervalTimeSpan)
      .toISOString()
      .split('T')[0],
    endDate: new Date(new Date(currentIntervalRange.endDate).getTime() - currentIntervalTimeSpan)
      .toISOString()
      .split('T')[0],
  };
};

export const getDefaultIntervalRange = () => ({
  startDate: new Date(Date.now() - twoWeeksTimeSpan).toISOString().split('T')[0],
  endDate: new Date().toISOString().split('T')[0],
  precision: 'Day' as Period,
});

export const useGetPreviousIntervalRange = (currentIntervalRange: { startDate: string; endDate: string }) =>
  useMemo(
    () =>
      getPreviousIntervalRange({ startDate: currentIntervalRange.startDate, endDate: currentIntervalRange.endDate }),
    [currentIntervalRange.endDate, currentIntervalRange.startDate],
  );
