import React, { useRef } from 'react';
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerFooter,
    DrawerTitle,
    DrawerDescription,
    DrawerClose,
} from '@frontend/shadcn/components/ui/drawer';
import { XmarkIcon } from '@revfluence/fresh-icons/solid';
import { Button, ButtonProps } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { isEqual } from 'lodash';
import { usePFADashboardContext } from './ProductFulfillmentDashboardContext';
import { FilterContent } from './FilterContent';

interface HeaderProps {
    title?: string;
    description?: string;
    icon?: React.ReactNode;
}

export interface FilterDrawerProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    mask?: boolean;
    headerProps?: HeaderProps;
    footerButtons?: ButtonProps[];
    footerButtonAlignment?: 'left' | 'right' | 'center';
    children: React.ReactNode;
}

export const FilterDrawer: React.FC<FilterDrawerProps> = ({
    open,
    onOpenChange,
    headerProps,
    footerButtons,
    footerButtonAlignment = 'left',
    children,
}) => {
    const headerRef = useRef<HTMLDivElement>(null);

    // Map footer button alignment to justify content class
    const footerAlignmentClass = {
        left: 'justify-start',
        center: 'justify-center',
        right: 'justify-end',
    }[footerButtonAlignment];

    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent
          className={
          cn(
            'fixed left-auto inset-y-0 mt-0 right-0 z-[9999] flex h-full w-[340px] flex-col border-l bg-background rounded-none p-0',
            'duration-300 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
            'bg-background backdrop-blur-sm',
            'top-[var(--header-height,0px)] h-[calc(100vh-var(--header-height,0px))]',
          )
          }
          data-vaul-no-drag
        >
          <div ref={headerRef}>
            <DrawerHeader className="flex justify-between items-center border-b border-grey-3 px-6 py-4">
              <div>
                {headerProps?.title && (
                <DrawerTitle className="font-medium mb-0">
                  {headerProps.icon && <span className="mr-2">{headerProps.icon}</span>}
                  {headerProps.title}
                </DrawerTitle>
                            )}
                {headerProps?.description && <DrawerDescription>{headerProps.description}</DrawerDescription>}
              </div>
              <DrawerClose asChild>
                <Button variant="ghost" size="headerIcon" className="border border-transparent hover:border-grey-6 hover:bg-transparent">
                  <XmarkIcon className="h-4 w-4" />
                </Button>
              </DrawerClose>
            </DrawerHeader>
          </div>
          <div className="flex-1 overflow-y-auto">{children}</div>
          {footerButtons?.length > 0 && (
            <DrawerFooter className={`flex flex-row ${footerAlignmentClass} space-x-2 border-t border-grey-3`}>
              {footerButtons.map(({
 name, onClick, variant, disabled,
}, index) => (
  <Button key={index} onClick={onClick} variant={variant} disabled={disabled}>
    {name}
  </Button>
              ))}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    );
};

export const FilterDrawerWrapper = () => {
  const {
    filterDrawerOpen,
    ownerIds,
    localOwnerIds,
    projectIds,
    localProjectIds,
    fulfillmentStatus,
    localFulfillmentStatus,
    setFilterDrawerOpen,
    setState,
  } = usePFADashboardContext();

  const setFilters = () => {
    setState({
      ownerIds: localOwnerIds,
      projectIds: localProjectIds,
      fulfillmentStatus: localFulfillmentStatus,
      filterDrawerOpen: false,
    });
  };

  const handleCancel = () => {
    setState({
      localOwnerIds: ownerIds,
      localProjectIds: projectIds,
      localFulfillmentStatus: fulfillmentStatus,
      filterDrawerOpen: false,
    });
  };

  const hasChanges = !(
    isEqual(ownerIds.slice().sort(), localOwnerIds.slice().sort())
    && isEqual(projectIds.slice().sort(), localProjectIds.slice().sort())
    && isEqual(fulfillmentStatus.slice().sort(), localFulfillmentStatus.slice().sort())
  );

  return (
    <FilterDrawer
      open={filterDrawerOpen}
      onOpenChange={setFilterDrawerOpen}
      headerProps={{ title: 'Filters' }}
      footerButtons={[
        {
 name: 'Apply', onClick: setFilters, variant: 'primary', disabled: !hasChanges,
},
        { name: 'Cancel', onClick: handleCancel, variant: 'outline' },
      ]}
    >
      <FilterContent />
    </FilterDrawer>
  );
};
