import * as React from 'react';
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Form } from '@revfluence/fresh';
import { OFFER_CONVERSION_TYPE } from '@affiliates/types/globalTypes';
import { IAffiliateLinksFormElementProps, OfferFormAsyncActions } from '../../../../types';
import { OfferInputHeader } from '../../../CommonSection/FormElements/RefreshForm/OfferElementHeader';

interface IProps extends IAffiliateLinksFormElementProps<'conversionType'> { }

export const RefreshOfferConversionType: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  handleFormAsyncActions,
  value,
}) => {
  const onChange = (type: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value: type },
    });
  };
  return (
    <>
      <OfferInputHeader text="Conversion Type" tooltipText="Conversion Type" />
      <Form.Item className="!mb-2">
        <Select value={value} disabled={disabled} onValueChange={onChange}>
          <SelectTrigger className="w-full text-primary text-xs font-normal">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OFFER_CONVERSION_TYPE.CONVERSIONS}>Conversions</SelectItem>
            <SelectItem value={OFFER_CONVERSION_TYPE.DOWNLOADS}>Downloads</SelectItem>
            <SelectItem value={OFFER_CONVERSION_TYPE.EMAIL_SUBMITS}>Email Submits</SelectItem>
            <SelectItem value={OFFER_CONVERSION_TYPE.LEAD_GENERATIONS}>Lead Generations</SelectItem>
          </SelectContent>
        </Select>
      </Form.Item>

    </>

  );
});

RefreshOfferConversionType.displayName = 'RefreshOfferConversionType';
