import * as React from 'react';
import { FormItemProps } from 'antd/lib/form';

import { Form } from '@revfluence/fresh';
import {
  PrefixTypeStrategy,
  IShopifyFormElementProps,
  IShopifyPromoCodeFormValues,
} from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { Input } from '@frontend/shadcn/components/ui/input';

const { useCallback } = React;
interface IProps extends IShopifyFormElementProps<'codeSuffix'> {
  help?: FormItemProps['extra'];
  prefixType?: IShopifyPromoCodeFormValues['prefixType'];
  required?: boolean;
}

export const RefreshOfferCodeSuffix: React.FC<Readonly<IProps>> = React.memo(
  ({
    // disabled,
    help,
    name,
    prefixType,
    required,
  }) => {
    const requiredMsg = required
      ? 'Please input your Suffix'
      : 'Please input a valid suffix or change the prefix type.';
    const isRequired = useCallback(
      (prefixType) => {
        if (required) {
          return true;
        }
        switch (prefixType) {
          case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_PLUS_SUFFIX:
          case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_PLUS_SUFFIX:
          case PrefixTypeStrategy.FULL_NAME_PLUS_SUFFIX:
          case PrefixTypeStrategy.INSTAGRAM_PLUS_SUFFIX:
            return true;
          default:
            return false;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [prefixType, required],
    );
    return (
      <Form.Item
        extra={help}
        name={name}
        rules={[
          {
            message: requiredMsg,
            required: isRequired(prefixType),
          },
        ]}
        className="!mb-0"
      >
        <Input name={name} placeholder="Suffix" className="h-8" required={isRequired(prefixType)} />
      </Form.Item>
    );
  },
);

RefreshOfferCodeSuffix.displayName = 'RefreshOfferCodeSuffix';
