import * as React from 'react';
import {
  Card, Col, Row, Space, Statistic,
} from '@revfluence/fresh';

import { isNil } from 'lodash';
import { getCurrencySymbol } from '@frontend/app/containers/Settings/ProductFulfillment/utils';
import styles from './Statistics.module.scss';

interface IProps {
  maxItems: number;
  selectedItems: number;
  maxMarketValue: number;
  selectedMarketValue: number;
  currencyCode?: string;
}

export const Statistics = ({
  maxItems,
  selectedItems,
  maxMarketValue,
  selectedMarketValue,
  currencyCode,
}: IProps) => (
  <Card>
    <Row justify="space-between">
      <Col span={6} className={styles.statisticContainer}>
        <Space direction="vertical" align="center" size={0}>
          <Statistic
            title="Max Products Per Member"
            value={isNil(maxItems) ? 'N/A' : maxItems}
            precision={0}
          />
        </Space>
      </Col>
      <Col span={6} className={styles.statisticContainer}>
        <Space direction="vertical" align="center" size={0}>
          <Statistic
            title="Total Selected Products"
            value={selectedItems}
            precision={0}
          />
        </Space>
      </Col>
      <Col span={6} className={styles.statisticContainer}>
        <Space direction="vertical" align="center" size={0}>
          <Statistic
            title="Max Mkt. Value Per Member"
            value={isNil(maxMarketValue) ? 'N/A' : maxMarketValue}
            precision={2}
            prefix={getCurrencySymbol(currencyCode)}
          />
        </Space>
      </Col>
      <Col span={6} className={styles.statisticContainer}>
        <Space direction="vertical" align="center" size={0}>
          <Statistic
            title="Total Selected Mkt. Value"
            value={selectedMarketValue}
            precision={2}
            prefix={getCurrencySymbol(currencyCode)}
          />
        </Space>
      </Col>
    </Row>
  </Card>
);
