// Backend keys (used for API communication)
export enum CreatorVariableKey {
  FULL_NAME = 'FULL_NAME',
  FIRST_NAME = 'FIRST_NAME',
  NAME = 'NAME',
}

export enum OfferVariableKey {
  DISCOUNT = 'DISCOUNT',
}

// Frontend display values
export const VariableDisplayMap = {
  [CreatorVariableKey.FULL_NAME]: "Creator's Full Name",
  [CreatorVariableKey.FIRST_NAME]: "Creator's First Name",
  [CreatorVariableKey.NAME]: "Creator's Name",
  [OfferVariableKey.DISCOUNT]: 'Offer Discount',
} as const;

// Utility functions for conversion
export function getVariableDisplay(key: CreatorVariableKey | OfferVariableKey): string {
  return VariableDisplayMap[key];
}

export function getVariableKey(displayValue: string): CreatorVariableKey | OfferVariableKey | null {
  const entry = Object.entries(VariableDisplayMap).find(([_, value]) => value === displayValue);
  return entry ? (entry[0] as CreatorVariableKey | OfferVariableKey) : null;
}

// Helper to convert template strings between BE and FE formats
export function convertTemplateToFrontend(template: string): string {
  return Object.entries(VariableDisplayMap).reduce((result, [key, display]) => result.replace(`{{${key}}}`, `{{${display}}}`), template);
}

export function convertTemplateToBackend(template: string): string {
  return Object.entries(VariableDisplayMap).reduce((result, [key, display]) => result.replace(`{{${display}}}`, `{{${key}}}`), template);
}
