import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import {
  Row, Col, Space, useBreakpoint,
} from '@revfluence/fresh';
import { STAPaymentApp } from '@affiliates/containers';
import { STAPaymentAppContextProvider } from '@affiliates/contexts';
import { DateFilter, IDateRangeSettings } from '@frontend/app/components';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { BackLink } from './components/BackLink';
import { PageTitle } from './components/PageTitle';
import styles from './Affiliates.scss';
import { OfferDetailCard } from './components/OfferDetailCard';
import { RefreshOfferDefaultImage } from '../OfferImage/RefreshOfferDefaultImage';
import NeedHelpDropdown from '../OfferForm/utils/NeedHelpDropdown';
import { TOfferMember } from '../MemberTable/types';

const affiliateIcon = require('@frontend/app/assets/svgs/affiliates_app.svg');

const { useCallback, useMemo } = React;

interface IProps {
  baseUri: string;
  clientId: string;
  clientName: string;
  dateRangeSettings: IDateRangeSettings;
  onClickDashboardLink: () => void;
  deletedMembers?: readonly TOfferMember[];
}

enum STA_TITLE {
  DASHBOARD = 'Sales Tracking',
  NEW_AFFILIATE_APP = 'Create New Affiliate Offer',
  NEW_SHOPIFY_PROMO = 'Create New Promo Offer',
  EDIT_OFFER = 'Sales Tracking',
  CONFIRMATION = 'Offer Confirmation',
  LANDINGPAGE = 'CreatorStore Settings',
}

export const AffiliatesApp: React.FC<Readonly<IProps>> = (props) => {
  const screens = useBreakpoint();
  const history = useHistory();
  const {
    baseUri, clientId, clientName, children, dateRangeSettings, onClickDashboardLink, deletedMembers,
  } = props;
  const { earliestDate } = dateRangeSettings;
  const { refreshUi, archiveOffer, migrateToGraphQL, offerSimplification } = useClientFeatures();

  const location = useLocation();
  const isDashboardPage = useMemo(() => location.pathname.indexOf('/dashboard') !== -1, [location.pathname]);
  const isOfferDetailsPage = useMemo(() => location.pathname.indexOf('/details') !== -1, [location.pathname]);
  const isOfferDetailsPageRef = useRef(isOfferDetailsPage);
  const isEditOfferPage = useMemo(() => location.pathname.indexOf('/edit') !== -1, [location.pathname]);
  const newAffiliateLink = useMemo(() => location.pathname.indexOf('/newAffiliateLink') !== -1, [location.pathname]);
  const newShopifyPromo = useMemo(() => location.pathname.indexOf('/newShopifyPromo') !== -1, [location.pathname]);
  const confirmation = useMemo(() => location.pathname.indexOf('/confirmation') !== -1, [location.pathname]);
  const isLandingPage = useMemo(() => location.pathname.indexOf('/setupLandingPage') !== -1, [location.pathname]);
  const { isOldOffer, offerFormValue } = useOfferDetailsContext();
  const routeToExclude = isLandingPage;
  useEffect(() => {
    isOfferDetailsPageRef.current = isOfferDetailsPage;
  }, [isOfferDetailsPage]);

  const onClickLink = useCallback(
    (e) => {
      e.preventDefault();
      onClickDashboardLink();
    },
    [onClickDashboardLink],
  );
  const ActionsButtons = () => {
    const buttons = [
      (isOfferDetailsPage && (!migrateToGraphQL || (migrateToGraphQL && isOldOffer))) && (
        <Col key="dateFilter">
          <DateFilter earliestDate={earliestDate} settings={dateRangeSettings} />
        </Col>
      ),
      (isDashboardPage || (isOfferDetailsPage && (!migrateToGraphQL || isOldOffer))) && (
        <>
          <Col key="paymentApp">
            <STAPaymentApp
              clientId={clientId}
              clientName={clientName}
              initialDateFilterSettings={dateRangeSettings}
            />
          </Col>
        </>
      ),
    ];
    return <Row gutter={8}>{buttons}</Row>;
  };

  const offersDashboardHeader = [
    <DateFilter earliestDate={earliestDate} settings={dateRangeSettings} refreshUi={refreshUi} key={1} />,
    <STAPaymentApp
      clientId={clientId}
      clientName={clientName}
      initialDateFilterSettings={dateRangeSettings}
      refreshUi={refreshUi}
      key={1}
    />,
  ];

  const goBack = useCallback(() => {
    if (isOfferDetailsPageRef.current) {
      history.push(`${baseUri}/dashboard`);
    } else {
      history.goBack();
    }
  }, [history, baseUri]);

  const getTitle = ({
    isDashboardPage,
    isEditOfferPage,
    newAffiliateLink,
    newShopifyPromo,
    confirmation,
  }) => {
    if (isDashboardPage) return STA_TITLE.DASHBOARD;
    if (newAffiliateLink) return offerFormValue?.name || STA_TITLE.NEW_AFFILIATE_APP;
    if (newShopifyPromo) return offerFormValue?.name || STA_TITLE.NEW_SHOPIFY_PROMO;
    if (isEditOfferPage) return offerFormValue?.name || STA_TITLE.EDIT_OFFER;
    if (confirmation) return STA_TITLE.CONFIRMATION;

    return 'Sales Tracking';
  };
  return (
    <Space direction="vertical" size={0} style={{ width: '100%', overflow: 'hidden' }}>
      <STAPaymentAppContextProvider>
        {!routeToExclude && (refreshUi ? (
          <AppHeader
            title={getTitle({
              isDashboardPage,
              isEditOfferPage,
              newAffiliateLink,
              newShopifyPromo,
              confirmation,
            })}
            offerImage={offerSimplification ? (isEditOfferPage || newAffiliateLink || newShopifyPromo) ? <RefreshOfferDefaultImage className="!w-9 !h-9" imageUrl={offerFormValue?.imageUrl || null} source={offerFormValue?.source} icon={offerFormValue?.icon} iconClassName="h-4 w-4" /> : null : null}
            actions={isDashboardPage ? offersDashboardHeader : (offerSimplification && (isEditOfferPage || newAffiliateLink || newShopifyPromo)) ? [<NeedHelpDropdown key={1} source={offerFormValue?.source} />] : undefined}
            backButtonFunction={
              isEditOfferPage || newAffiliateLink || newShopifyPromo || confirmation
                ? goBack
                : undefined
            }
            customTitle={
              isOfferDetailsPage ? (
                <OfferDetailCard
                  clientId={clientId}
                  clientName={clientName}
                  dateRangeSettings={dateRangeSettings}
                  archiveOffer={archiveOffer}
                  deletedMembers={deletedMembers || []}
                  refreshUi={refreshUi}
                  goBack={goBack}
                  showCta
                />
              ) : undefined
            }
          />
        ) : (
          <Row gutter={0}>
            <Col xs={24}>
              <Row className={styles.appDashboardHeader} gutter={0}>
                <Col>
                  <BackLink baseUri={baseUri} />
                </Col>
                <Col flex={1}>
                  <div className={styles.logoWrap}>
                    {isOfferDetailsPage && migrateToGraphQL && !isOldOffer ? (
                      <OfferDetailCard
                        clientId={clientId}
                        clientName={clientName}
                        dateRangeSettings={dateRangeSettings}
                        archiveOffer={archiveOffer}
                        deletedMembers={deletedMembers || []}
                        showCta
                      />
                    ) : (
                      <a className={styles.appLogoLink} href={baseUri} onClick={onClickLink}>
                        {isDashboardPage && <img className={styles.appIcon} src={affiliateIcon} alt="Affiliate Icon" />}
                        <PageTitle />
                      </a>
                    )}
                  </div>
                </Col>
                <Col style={{ marginTop: screens.xs ? 8 : undefined }}>
                  <Space size={16}>
                    <Route
                      render={({ location: { pathname } }) => {
                        const showActions = pathname.includes('/details') || pathname.includes('/dashboard');
                        return showActions ? <ActionsButtons /> : null;
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
        <Row gutter={0}>
          <Col xs={24}>
            {children}
          </Col>
        </Row>
      </STAPaymentAppContextProvider>

    </Space>
  );
};
