const MILLISECONDS_IN_A_MINUTE = 60000;

export const utcToLocal = (date: Date) => {
  if (!date) {
    return date;
  }
  return new Date(date.getTime() + date.getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE);
};

export const localToUTC = (date: Date) => {
  if (!date) {
    return date;
  }
  return new Date(date.getTime() - date.getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE);
};

export const midnight = (date: Date) => {
  if (!date) {
    return date;
  }
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const formatAnnualDate = (dateTimestamp) => {
  const date = new Date(dateTimestamp);
  const userTimezoneUtcOffsetMs = date.getTimezoneOffset() * 60 * 1000;
  // calculate for offset only when backend already save birthday not in UTC and ISO 8601 format
  const dateInput = date.getUTCHours() === 0
    ? date.getTime()
    : date.getTime() - userTimezoneUtcOffsetMs;
  return new Date(dateInput).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

export const formatDateInYYYYMMDD = (isoDate: string): string => {
  const date = new Date(isoDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
