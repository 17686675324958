import {
 Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@frontend/shadcn/components/ui/accordion';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import React, { useState } from 'react';
import { ProductsProvider } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { cn } from '@frontend/shadcn/lib/utils';
import { CreatorsTagContent } from './components/CreatorsTagContent';
import FeaturedProductContent from './components/FeaturedProductContent';
import DiscountButtonContent from './components/DiscountButtonContent';
import TitleDescriptionContent from './components/TitleDescriptionContent';
import { useLandingPageContext } from '../../../../context/LandingPageContext';

export const Basic = (): JSX.Element => {
  const { fieldErrors } = useLandingPageContext();
  const [openAccordions, setOpenAccordions] = useState<string[]>(['title-description']);

  const accordionItems = [
    {
      value: 'title-description',
      title: 'Title and Description',
      content: <TitleDescriptionContent />,
      hasError: !!(fieldErrors?.basic?.title || fieldErrors?.basic?.description),
    },
    {
      value: 'featured-product',
      title: 'Featured Product Discount',
      badge: 'Recommended',
      content: <FeaturedProductContent />,
      hasError: !!(fieldErrors?.basic?.featuredProduct?.id),
    },
    {
      value: 'discount-button',
      title: 'Discount Offer Button',
      content: <DiscountButtonContent />,
      hasError: !!fieldErrors?.basic?.discountOfferButton?.title,
    },
    {
      value: 'creators-tag',
      title: "Creator's Tag",
      content: <CreatorsTagContent />,
      hasError: !!fieldErrors?.basic?.creatorsTag?.title,
    },
  ];

  return (
    <ProductsProvider>
      <div className="flex flex-col gap-4 py-4">
        {accordionItems.map((item, index) => (
          <React.Fragment key={item.value}>
            <Accordion
              type="multiple"
              value={openAccordions}
              onValueChange={setOpenAccordions}
              className="w-full"
            >
              <AccordionItem
                value={item.value}
                className={cn(
                  'border-0',
                  item.hasError && 'ring-1 ring-red-500 rounded-2xl',
                )}
              >
                <AccordionTrigger className="bg-neutral-color-palettegray-1 rounded-2xl px-4 hover:no-underline py-2">
                  <div className="flex items-center gap-2">
                    <span className={cn(
                      'text-grey-6 text-base font-medium',
                      item.hasError && 'text-red-500',
                    )}
                    >
                      {item.title}
                    </span>
                    {item.badge && (
                      <Badge
                        variant="secondary"
                        className="bg-yellow-2 text-neutral-color-palettegray-9 text-xs font-medium hover:bg-yellow-2"
                      >
                        {item.badge}
                      </Badge>
                    )}
                    {item.hasError && (
                      <Badge
                        variant="destructive"
                        className="bg-red-100 text-red-500 text-xs font-medium"
                      >
                        Error
                      </Badge>
                    )}
                  </div>
                </AccordionTrigger>
                <AccordionContent>{item.content}</AccordionContent>
              </AccordionItem>
            </Accordion>
            {index < accordionItems.length - 1 && (
              <Separator className="w-full h-0.5" />
            )}
          </React.Fragment>
        ))}
      </div>
    </ProductsProvider>
  );
};
