export const MAX_ALLOWED_MEMBERS = 200;
export const LEARN_MORE_URL = 'https://help.shopify.com/en/manual/discounts/combining-discounts';
export const SHOPIFY_ADMIN_BASE_URL = 'https://admin.shopify.com/store/';

export const LINK_FILTERS = [
  { text: 'Active', value: 'Active' },
  { text: 'Deactivated', value: 'Deactivated' },
  { text: 'Expired', value: 'Expired' },
  { text: 'Paused', value: 'Paused' },
  { text: 'Others', value: 'Others' },
];

export const PROMO_FILTERS = [
  { text: 'Active', value: 'Active' },
  { text: 'Deactivated', value: 'Deactivated' },
  { text: 'Failed', value: 'Failed' },
  { text: 'Expired', value: 'Expired' },
  { text: 'Upcoming', value: 'Upcoming' },
  { text: 'Others', value: 'Others' },
];

export const OFFER_LEARN_MORE_URL = 'https://help.aspireiq.com/en/collections/2632994-sales-tracking';

export const LANDING_PAGE_HELP_URL = 'https://help.aspireiq.com/en/articles/10495715-beta-how-to-set-up-creatorstores';
