import * as React from 'react';
import { isFunction, map } from 'lodash';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';

interface IProps {
  value: boolean;
  onChange?(value: boolean);
}

const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const BooleanEqual: React.FC<IProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  /**
   * value will be converted to string for antd <Select>
   * and converted back to boolean for the onChange handler
   */
  return (
    <Select
      value={`${value}`}
      onValueChange={(val) => {
        if (isFunction(onChange)) {
          onChange(val === 'true');
        }
      }}
    >
      <SelectTrigger className="w-full">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="z-[99999]">
        {map(BOOLEAN_OPTIONS, (option) => (
          <SelectItem
            key={`BooleanEqual-${option.value}`}
            value={option.value}
          >
            {option.label}
          </SelectItem>
      ))}
      </SelectContent>
    </Select>
  );
};
