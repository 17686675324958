import React, { useState } from 'react';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { VariableInput } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/VariableInput';
import {
  CreatorVariableKey,
  getVariableDisplay,
  convertTemplateToFrontend,
  convertTemplateToBackend,
} from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/variables';

const VARIABLE_OPTIONS = [
  CreatorVariableKey.FULL_NAME,
  CreatorVariableKey.FIRST_NAME,
].map(getVariableDisplay);

export const CreatorsTagContent: React.FC = () => {
  const {
 landingPageData, setLandingPageData, fieldErrors, clearFieldError,
} = useLandingPageContext();
  const [inputValue, setInputValue] = useState(
    convertTemplateToFrontend(landingPageData.basic.creatorsTag.title || ''),
  );

  const handleSwitchToggle = (value: boolean) => {
    setLandingPageData((prevData) => ({
      ...prevData,
      basic: {
        ...prevData.basic,
        creatorsTag: {
          ...prevData.basic.creatorsTag,
          showCreatorTag: value,
        },
      },
    }));
    clearFieldError('basic.creatorsTag.title');
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setLandingPageData((prevData) => ({
      ...prevData,
      basic: {
        ...prevData.basic,
        creatorsTag: {
          ...prevData.basic.creatorsTag,
          title: convertTemplateToBackend(value),
        },
      },
    }));
  };

  return (
    <Card className="border-none px-4">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Switch
            id="show-tag"
            checked={landingPageData.basic.creatorsTag.showCreatorTag}
            onCheckedChange={handleSwitchToggle}
          />
          <Label htmlFor="show-tag" className="text-grey-6">
            Show Creator's Tag
          </Label>
        </div>
        {landingPageData.basic.creatorsTag.showCreatorTag && (
          <div className="space-y-2">
            <VariableInput
              id="creator-input"
              value={inputValue}
              onChange={handleInputChange}
              variables={VARIABLE_OPTIONS}
              placeholder="Customize creator tag"
              tooltipText="Insert dynamic creator variables"
              error={!!fieldErrors?.basic?.creatorsTag?.title}
            />
            {fieldErrors?.basic?.creatorsTag?.title && (
              <p className="text-sm text-red-500">{fieldErrors.basic.creatorsTag.title}</p>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
