import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { ColorPicker } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/shadColorPicker';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import React, { useRef, useCallback, useState } from 'react';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Upload } from '@revfluence/fresh';
import { useUploadContent } from '@frontend/app/hooks';
import { message } from '@affiliates/AspireUI';
import { Loader2, TrashIcon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { CircleInfoIcon } from '@revfluence/fresh-icons/solid';

const colorPickers = [
  { id: 1, label: 'Page Background Color', key: 'pageBackgroundColor' },
  { id: 2, label: 'Hero Section Background', key: 'heroSectionBackgroundColor' },
];

const defaultBackgroundSettings = {
  pageBackgroundColor: '#232323',
  heroSectionBackgroundColor: '#FFFFFF',
  heroSectionBackgroundImageUrl: {
    desktop: '',
    mobile: '',
  },
};

const PageBackground = (): JSX.Element => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const colorPickerRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [uploadingDevice, setUploadingDevice] = useState<'desktop' | 'mobile' | null>(null);
  const { upload } = useUploadContent({
    serviceName: 'sales_tracking_app',
    parentFolder: 'landing_page',
  });

  const handleColorChange = (key: string, color: string) => {
    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        backgroundSetting: {
          ...(prev.style?.backgroundSetting || defaultBackgroundSettings),
          [key]: color,
        },
      },
    }));
  };

  const handleImageUpload = useCallback(async (info, deviceType: 'desktop' | 'mobile') => {
    const file = info.file.originFileObj;
    setUploadingDevice(deviceType);
    try {
      const imageUrl = await upload(file, 'image');
      setLandingPageData((prev) => ({
        ...prev,
        style: {
          ...prev.style,
          backgroundSetting: {
            ...(prev.style?.backgroundSetting || defaultBackgroundSettings),
            heroSectionBackgroundImageUrl: {
              ...prev.style?.backgroundSetting?.heroSectionBackgroundImageUrl,
              [deviceType]: imageUrl,
            },
          },
        },
      }));
    } catch (err) {
      message.error(`Failed to upload ${deviceType} image.`);
    } finally {
      setUploadingDevice(null);
    }
  }, [setLandingPageData, upload]);

  // Safe access to background settings with fallback
  const getBackgroundColor = (key: string) => landingPageData.style?.backgroundSetting?.[key] || defaultBackgroundSettings[key];

  const ImageUploader = ({ deviceType, label }: { deviceType: 'desktop' | 'mobile'; label: string }): JSX.Element => (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Label className="text-sm font-medium">{label}
        </Label>
        <div className="flex">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild className="cursor-pointer">
                <CircleInfoIcon className="text-grey-4 w-4 h-4" />
              </TooltipTrigger>
              <TooltipContent className="rounded-lg">
                <p>Supported formats: PNG, JPG, JPEG (Max: 24MB)</p>
                <ul>Recommended Sizes:
                  <li>Desktop: 1920x756px (2.5:1)</li>
                  <li>Mobile: 1080x1344px (0.8:1)</li>
                </ul>

              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <Upload
        className="w-full"
        onChange={(info) => handleImageUpload(info, deviceType)}
        accept="image/*"
        showUploadList={false}
        multiple={false}
      >
        <div className="flex items-center justify-center p-4 border-2 border-dashed rounded-lg hover:border-primary cursor-pointer relative">
          {uploadingDevice === deviceType ? (
            <div className="flex flex-col items-center gap-2">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <span className="text-sm text-gray-500">Uploading...</span>
            </div>
          ) : landingPageData.style?.backgroundSetting?.heroSectionBackgroundImageUrl?.[deviceType] ? (
            <div className="flex flex-col items-center gap-2 relative group">
              <img
                src={landingPageData.style.backgroundSetting.heroSectionBackgroundImageUrl[deviceType]}
                alt={`Hero background ${deviceType}`}
                className="max-h-24 object-contain"
              />
              <span className="text-sm text-gray-500">Click to change image</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setLandingPageData((prev) => ({
                    ...prev,
                    style: {
                      ...prev.style,
                      backgroundSetting: {
                        ...(prev.style?.backgroundSetting || defaultBackgroundSettings),
                        heroSectionBackgroundImageUrl: {
                          ...prev.style?.backgroundSetting?.heroSectionBackgroundImageUrl,
                          [deviceType]: '',
                        },
                      },
                    },
                  }));
                }}
                className="absolute -top-2 -right-2 text-red-800 p-1 border border-red-800 rounded-full flex items-center justify-center"
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </div>
          ) : (
            <span className="text-sm text-gray-500">Click to upload {deviceType} image</span>
          )}
        </div>
      </Upload>
    </div>
  );

  return (
    <div className="flex flex-col gap-4 px-4">
      {colorPickers.map((picker, index) => (
        <Card key={picker.id} className="w-full border-none">
          <CardContent className="pt-4 p-2">
            <div className="flex flex-col gap-2">
              <Label className="text-sm font-medium">{picker.label}</Label>
              <ColorPicker
                ref={(el) => (colorPickerRefs.current[index] = el)}
                value={getBackgroundColor(picker.key)}
                onChange={(color) => handleColorChange(picker.key, color)}
                className="inline-flex items-center gap-2.5 pl-1 pr-2 py-1 bg-grey-0 rounded-lg border border-grey-2"
              />
            </div>
          </CardContent>
        </Card>
      ))}

      <Card className="w-full border-none">
        <CardContent className="pt-4 p-2">
          <div className="flex flex-col gap-4">
            <ImageUploader deviceType="desktop" label="Hero Section Desktop Background Image" />
            <ImageUploader deviceType="mobile" label="Hero Section Mobile Background Image" />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PageBackground;
