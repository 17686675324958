import * as React from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  SnapchatIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';
import {
  BlogIcon,
  ImageIcon,
  StarIcon,
  VideoIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { TableColumnType } from 'antd';
import {
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isString,
  trim,
} from 'lodash';

import { TDateRequirement } from '../../types/ContentDateRequirements';
import {
  ContentColumnKey,
  IMember,
  SocialColumnKey,
} from '../../types/Member';
import { DueDateTooltip } from '../../utils/DueDateTooltip';
import { MissingSocialTag } from './MissingSocialTag';

import styles from './ReviewTable.scss';

const getCleanStringValue = <Key extends keyof IMember>(member: IMember, key: Key): string => {
  const value = member[key];
  if (isNil(value)) {
    return '';
  }
  if (!isString(value)) {
    throw new Error(`Key "${key}" is not mapped to a string for members.`);
  }
  return trim(value).toLowerCase();
};

const getCleanNumericValue = <Key extends keyof IMember>(member: IMember, key: Key): number => {
  const value = member[key];
  if (isNil(value)) {
    return 0;
  }

  if (!isNumber(value) || Number.isNaN(value)) {
    throw new Error(`Key "${key}" is not mapped to a number for all members.`);
  }

  return value;
};

// sorts by the given key
// if the values are equal for the given key, falls back to name
// if names are equal, falls back to ID
export const caseInsensitiveStringSort = <Key extends keyof IMember>(
  key: Key,
) => (a: IMember, b: IMember): number => {
  const aClean = getCleanStringValue(a, key);
  const bClean = getCleanStringValue(b, key);

  if (aClean < bClean) {
    return -1;
  }
  if (aClean > bClean) {
    return 1;
  }

  return key === 'name' ? (a.id - b.id) : caseInsensitiveStringSort('name')(a, b);
};

export const numericSort = <Key extends keyof IMember>(
  key: Key,
) => (a: IMember, b: IMember): number => {
  const aClean = getCleanNumericValue(a, key);
  const bClean = getCleanNumericValue(b, key);

  if (aClean === bClean) {
    return caseInsensitiveStringSort('name')(a, b);
  }

  return aClean - bClean;
};

const contentCountSort = <Key extends keyof IMember>(
  key: Key,
) => (a: IMember, b: IMember): number => {
  const aList = a[key];
  const bList = b[key];
  if (!isArray(aList) || !isArray(bList)) {
    throw new Error(`Key "${key}" is not mapped to a content list.`);
  }

  if (aList.length !== bList.length) {
    return aList.length - bList.length;
  }

  return caseInsensitiveStringSort('name')(a, b);
};

export const isSocialColumnKey = (key: string): key is SocialColumnKey => {
  switch (key) {
    case 'blog':
    case 'facebook':
    case 'instagram':
    case 'pinterest':
    case 'snapchat':
    case 'tiktok':
    case 'twitter':
    case 'youtube':
      return true;
    default:
      return false;
  }
};

export const getSocialHandleColumn = (key: SocialColumnKey): TableColumnType<IMember> => {
  let icon: React.ReactNode;
  let title: React.ReactNode;
  switch (key) {
    case 'blog':
      icon = <BlogIcon />;
      title = 'Blog';
      break;
    case 'facebook':
      icon = <FacebookIcon />;
      title = 'Facebook';
      break;
    case 'instagram':
      icon = <InstagramIcon />;
      title = 'Instagram';
      break;
    case 'pinterest':
      icon = <PinterestIcon />;
      title = 'Pinterest';
      break;
    case 'snapchat':
      icon = <SnapchatIcon />;
      title = 'Snapchat';
      break;
    case 'tiktok':
      icon = <TiktokIcon />;
      title = 'TikTok';
      break;
    case 'twitter':
      icon = <TwitterIcon />;
      title = 'Twitter';
      break;
    case 'youtube':
      icon = <YoutubeIcon />;
      title = 'YouTube';
      break;
    default:
      break;
  }
  if (!icon || !title) {
    throw new Error(`Unhandled social column: ${key}`);
  }

  return {
    title: (
      <>
        {icon}
        {title}
      </>
    ),
    dataIndex: key,
    key,
    render: (handle: string | null): React.ReactNode => {
      if (isString(handle) && !isEmpty(handle)) {
        return handle;
      }
      return <MissingSocialTag column={key} />;
    },
    sorter: caseInsensitiveStringSort(key),
    width: 150,
  };
};

export const isContentColumnKey = (key: string): key is ContentColumnKey => {
  switch (key) {
    case 'additionalImages':
    case 'additionalVideos':
    case 'blogPosts':
    case 'blogPostMentions':
    case 'customWork':
    case 'facebookPosts':
    case 'instagramPosts':
    case 'instagramReels':
    case 'instagramStories':
    case 'instagramVideos':
    case 'pinterestPins':
    case 'snapchatStories':
    case 'tiktokVideos':
    case 'twitterTweets':
    case 'youtubeProductMentions':
    case 'youtubeVideos':
    case 'youtubeShorts':
      return true;
    default:
      return false;
  }
};

export const getContentCountColumn = (key: ContentColumnKey): TableColumnType<IMember> => {
  let icon: React.ReactNode;
  let title: React.ReactNode;
  switch (key) {
    case 'additionalImages':
      icon = <ImageIcon />;
      title = 'Images';
      break;
    case 'additionalVideos':
      icon = <VideoIcon />;
      title = 'Videos';
      break;
    case 'blogPosts':
      icon = <BlogIcon />;
      title = 'Posts';
      break;
    case 'blogPostMentions':
      icon = <BlogIcon />;
      title = 'Mentions';
      break;
    case 'customWork':
      icon = <StarIcon />;
      title = 'Custom';
      break;
    case 'facebookPosts':
      icon = <FacebookIcon />;
      title = 'Posts';
      break;
    case 'instagramPosts':
      icon = <InstagramIcon />;
      title = 'Posts';
      break;
    case 'instagramReels':
      icon = <InstagramIcon />;
      title = 'Reels';
      break;
    case 'instagramStories':
      icon = <InstagramIcon />;
      title = 'Stories';
      break;
    case 'instagramVideos':
      icon = <InstagramIcon />;
      title = 'Videos';
      break;
    case 'pinterestPins':
      icon = <PinterestIcon />;
      title = 'Pins';
      break;
    case 'snapchatStories':
      icon = <SnapchatIcon />;
      title = 'Stories';
      break;
    case 'tiktokVideos':
      icon = <TiktokIcon />;
      title = 'Videos';
      break;
    case 'twitterTweets':
      icon = <TwitterIcon />;
      title = 'Tweets';
      break;
    case 'youtubeProductMentions':
      icon = <YoutubeIcon />;
      title = 'Mentions';
      break;
    case 'youtubeVideos':
      icon = <YoutubeIcon />;
      title = 'Videos';
      break;
    case 'youtubeShorts':
      icon = <YoutubeIcon />;
      title = 'Shorts';
      break;
    default:
      break;
  }

  if (!icon || !title) {
    throw new Error(`Unhandled content count column: ${key}`);
  }

  return {
    className: styles.showDisabled,
    dataIndex: key,
    key,
    render: (list: Array<TDateRequirement>, member: IMember): React.ReactElement => {
      if (!member.disabled && list.length > 0) {
        return (
          <DueDateTooltip dueDates={list} placement="left">
            <strong>
              {list.length}
            </strong>
          </DueDateTooltip>
        );
      }
      return (
        <>&mdash;</>
      );
    },
    sorter: contentCountSort(key),
    title: (
      <>
        {icon}
        {title}
      </>
    ),
    width: 130,
  };
};
