import * as React from 'react';

import { Form, Input } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../../../../../types';

interface IProps extends IShopifyFormElementProps<'groupName'> {
}

export const RefreshOfferCodeGroupName: React.FC<Readonly<IProps>> = React.memo(({
  name,
  disabled,
}) => {
  return (
    <Form.Item name={name} className="!m-0">
      <Input
        placeholder={disabled ? 'This field is disabled for linked Shopify offers' : 'e.g. Ambassador Promo Codes'}
        size="middle"
        addonBefore="[Aspire]"
        disabled={disabled}
      />
    </Form.Item>
  );
});
RefreshOfferCodeGroupName.displayName = 'RefreshOfferCodeGroupName';
