// Add a checkbox here similar to @RefreshOfferUrlThirdPartySelection.tsx with key named - trackThirdPartyPayout

import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Form } from '@revfluence/fresh';
import React from 'react';
import { FormAsyncAction } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { CircleInfoIcon } from '@revfluence/fresh-icons/solid/esm';

interface IProps {
  values: {
    trackThirdPartyPayout: boolean;
    thirdPartyTracking: string;
  };
  handleFormAsyncActions: (value: FormAsyncAction) => void;
}

export const RefreshTrackPayout: React.FC<IProps> = ({ values, handleFormAsyncActions }) => {
  const handleCheckboxChange = (checked: boolean) => {
    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'trackThirdPartyPayout', value: checked },
    });
  };

  return (
    <div className="mt-3">
      <Form.Item>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="trackThirdPartyPayout"
            checked={values?.trackThirdPartyPayout || false}
            onCheckedChange={handleCheckboxChange}
          />
          <label
            htmlFor="trackThirdPartyPayout"
            className="text-grey-6 text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Track Payout in Aspire

          </label>
          <Tooltip>
            <TooltipTrigger asChild>
              <CircleInfoIcon className="h-3 w-3 text-grey-4 cursor-pointer" />
            </TooltipTrigger>
            <TooltipContent>
              <p>Enabling this setting will override the commission values in Impact so you can manage commission tiers in Aspire</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </Form.Item>
    </div>
  );
};
