import React from 'react';
import { Form } from '@revfluence/fresh';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import styles from '../../../../OfferForm.scss';
import { FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions } from '../../../../types';
import { LEARN_MORE_URL } from '../../../../../../utils/constant';

interface OfferPromoCodeCombinationsProps {
  value: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  isDiscountByDefaultEnabled?: boolean;
}

export const RefreshOfferPromoCodeCombinations: React.FC<OfferPromoCodeCombinationsProps> = ({
  value,
  handleFormAsyncActions,
  isDiscountByDefaultEnabled,
}) => {
  const [isDiscountEnabled, setIsDiscountEnabled] = React.useState(value.productDiscounts || value.orderDiscounts || value.shippingDiscounts);
  const handleDiscountEnabled = (value: boolean) => {
    setIsDiscountEnabled(value);
    if (!value) {
      handleFormAsyncActions({ action: OfferFormAsyncActions.UPDATE_FIELD, payload: { key: 'productDiscounts', value } });
      handleFormAsyncActions({ action: OfferFormAsyncActions.UPDATE_FIELD, payload: { key: 'orderDiscounts', value } });
      handleFormAsyncActions({ action: OfferFormAsyncActions.UPDATE_FIELD, payload: { key: 'shippingDiscounts', value } });
    }
  };
  const handleChange = (value, key) => {
    handleFormAsyncActions({ action: OfferFormAsyncActions.UPDATE_FIELD, payload: { key, value } });
  };

  return (
    <div className="mt-2 ">
      <div className="flex gap-2 w-full mb-2">
        <Switch checked={isDiscountEnabled} onCheckedChange={handleDiscountEnabled} data-testid="discount-switch" />
        <span className="text-sm font-medium">Combination with other discounts</span>
      </div>
      {(isDiscountEnabled || isDiscountByDefaultEnabled) && (
        <div className="px-[26px]">
          <Form.Item className="!m-0" valuePropName="checked" name="productDiscounts">
            <div className="flex items-center gap-2">
              <Checkbox checked={value?.productDiscounts} onCheckedChange={(e) => handleChange(e, 'productDiscounts')} />
              <label
                htmlFor="productDiscounts"
                className="text-sm font-medium text-primary"
              >
                Product Discount
              </label>
            </div>
            {value?.productDiscounts && (
              <div className={styles.descriptionWrapper}>
                <p className="text-xs font-medium text-grey-5 my-2">
                  If an item is eligible for multiple product discounts, only the largest will apply.
                  <a target="_blank" href={LEARN_MORE_URL} rel="noreferrer" className="text-primary hover:text-primary hover:bg-grey-1 hover:underline underline font-medium">
                    {' '}
                    Learn More
                  </a>
                </p>
              </div>
            )}
          </Form.Item>
          <Form.Item className="!m-0" name="orderDiscounts">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={value?.orderDiscounts}
                onCheckedChange={(e) => handleChange(e, 'orderDiscounts')}
              />
              <label
                htmlFor="OrderDiscounts"
                className="text-sm font-medium text-primary"
              >
                Order Discount
              </label>
            </div>
            {value?.orderDiscounts && (
              <div className={styles.descriptionWrapper}>
                <p className="text-xs font-medium text-grey-5 my-2">
                  To let customers use more than one discount, set up at least one order discount that combines with product
                  discounts.
                  <a target="_blank" href={LEARN_MORE_URL} rel="noreferrer" className="text-primary hover:text-primary underline hover:underline hover:bg-grey-1 font-medium">
                    {' '}
                    Learn More
                  </a>
                </p>
              </div>
            )}
          </Form.Item>
          <Form.Item className="!m-0" name="shippingDiscounts">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={value?.shippingDiscounts}
                onCheckedChange={(e) => handleChange(e, 'shippingDiscounts')}
              />
              <label
                htmlFor="ShippingDiscounts"
                className="text-sm font-medium text-primary"
              >
                Shipping Discount
              </label>
            </div>
            {value?.shippingDiscounts && (
              <div className={styles.descriptionWrapper}>
                <p className="text-xs font-medium text-grey-5 my-2">
                  To let customers use more than one discount, set up at least one shipping discount that combines with
                  product discounts.
                  <a target="_blank" href={LEARN_MORE_URL} rel="noreferrer" className="text-primary hover:text-primary underline hover:underline hover:bg-grey-1 font-medium">
                    {' '}
                    Learn More
                  </a>
                </p>
              </div>
            )}
          </Form.Item>
        </div>)}
    </div>
  );
};
