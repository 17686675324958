export enum TemplateType {
  APPLICANT_REJECTED_FOLLOW_UP = 'APPLICANT_REJECTED_FOLLOW_UP',
  AUTOMATION_REMINDER_INVITE = 'automation_reminder_invite',
  BRIEF_ACCEPTED_FOLLOW_UP = 'BRIEF_ACCEPTED_FOLLOW_UP',
  BRIEF_ACCEPTED_SEND_PFA_ORDER_REQUEST = 'BRIEF_ACCEPTED_SEND_PFA_ORDER_REQUEST',
  BRIEF_REMINDER = 'BRIEF_REMINDER',
  CONTENT_POSTED_FOLLOW_UP = 'CONTENT_POSTED_FOLLOW_UP',
  FIRST_SALES_CONVERSION_FOLLOW_UP = 'FIRST_SALES_CONVERSION_FOLLOW_UP',
  GENERIC = 'generic',
  INVITE = 'invite',
  INVITE_REMINDER = 'INVITE_REMINDER',
  MEMBER_ACCEPTED_FOLLOW_UP = 'MEMBER_ACCEPTED_FOLLOW_UP',
  MEMBER_APPLIED_FOLLOW_UP = 'MEMBER_APPLIED_FOLLOW_UP',
  ORDER_DELIVERED_FOLLOW_UP = 'ORDER_DELIVERED_FOLLOW_UP',
  ORDER_SELECTION_REMINDER = 'ORDER_SELECTION_REMINDER',
  TERMS = 'terms',
}
