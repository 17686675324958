import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatters } from '@frontend/app/components/StatisticsCard/formatters';
import { getTrendTooltip, getLastFullMonths } from '@frontend/applications/ReportsApp/containers/utils';

import StatsComponent from '@frontend/applications/Shared/components/RefreshStatsComponent';
import { Button } from '@frontend/shadcn/components/ui/button';
import { getBackgroundColor } from '@frontend/applications/ReportsApp/utils/getTrendColor';
import { Label } from '@frontend/shadcn/components/ui/label';
import Trend from '../../../../components/Trend';
import { IImpactHomeProps } from '../../Home';
import { getTrendProps } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary';
import { composeImpactValue } from '../../../../utils/reportingFunctions';

interface IImpactProps {
  impactData: IImpactHomeProps['impactData'];
  isError?: boolean;
}

const RefreshImpact = ({ impactData, isError }: IImpactProps) => {
  const history = useHistory();

  const getTrendColor = (color: string) => (color === 'gray' ? 'gray' : 'white');

  const { color } = getTrendProps(impactData.percentDiff || 0);
  return (
    <div className="w-full flex flex-col p-5 gap-6">
      <StatsComponent
        title={(
          <div className="text-primary-foreground font-semibold">
            <div className="text-base leading-normal">
              Impact Value
              {' '}
              <CircleInfoIcon className="ml-2" />
            </div>
          </div>
        )}
        valueRender={(
          <div className={`text-5xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-7xl ${isError ? 'text-grey-4' : 'text-green-2'}`}>
            {`${composeImpactValue(impactData.impact)}x`}
          </div>
        )}
        className="items-center gap-4"
        tooltip="We calculate Impact Value by adding the values for Impression, Engagement, Content, and Sales and divide the total by the amount you have paid creators."
      />
      <StatsComponent
        valueRender={(
          <div className={`px-2 py-1 rounded leading-normal text-primary-foreground ${getBackgroundColor(color)}`}>
            <Trend {...getTrendProps(impactData.percentDiff || 0)} color={getTrendColor(color)} />
            {' '}
            <span className={`${color === 'gray' ? 'text-grey-3' : 'text-primary-foreground'}`}>
              {formatters.default(Math.abs(impactData.percentDiff) || 0, { precision: 0 })}
              %
            </span>
          </div>
        )}
        {...getTrendProps(impactData.percentDiff || 0)}
        className="items-center"
        tooltip={getTrendTooltip(getLastFullMonths(2))}
      />
      <div className="flex flex-col gap-8">
        <div className="flex flex-row gap-8 justify-center">
          <div className="flex flex-row gap-3 justify-center">
            <div className="px-3">
              <StatsComponent
                title={<Label className="text-primary-foreground">Total Value</Label>}
                valueRender={(
                  <div className="text-primary-foreground text-lg leading-relaxed whitespace-nowrap">
                    {impactData.totalValue ? '$' : ''}
                    {impactData.totalValue ? formatters.default(impactData.totalValue) : '-'}
                  </div>
                )}
                className="items-center"
              />
            </div>
            <div className="px-3">
              <StatsComponent
                title={<Label className="text-primary-foreground">Investment</Label>}
                valueRender={(
                  <div className="text-primary-foreground text-lg leading-relaxed whitespace-nowrap">
                    {impactData.investment ? '$' : ''}
                    {impactData.investment ? formatters.default(impactData.investment) : '-'}
                  </div>
                )}
                className="items-center"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <Button onClick={() => history.push('/impact_dashboard')} variant="outlineHeader">
            View Impact Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RefreshImpact;
