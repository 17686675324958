import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import React from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';

export interface TTalentManager {
  memberId: number;
  agentId: string;
  alwaysCC: boolean;
  agentName: string;
  agentEmail: string;
  agentMemberId: number | null;
}

const TalentManagerCard: React.FC<TTalentManager> = (props) => {
  const { agentName, agentEmail, alwaysCC } = props;

  return (
    <Card className="p-0 bg-transparent rounded-lg flex flex-col">
      <div className="p-4 flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <P className="text-sm font-normal text-grey-5">Agent Name</P>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <P className="text-sm font-normal">{agentName?.length > 18 ? `${agentName.substring(0, 16)}..` : agentName || '-'}</P>
              </TooltipTrigger>
              <TooltipContent>
                {agentName || '-'}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <div className="flex flex-row justify-between">
          <P className="text-sm font-normal text-grey-5">Agent Email</P>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <P className="text-sm font-normal">{agentEmail?.length > 8 ? `${agentEmail.substring(0, 16)}..` : agentEmail || '-'}</P>
              </TooltipTrigger>
              <TooltipContent>
                {agentEmail || '-'}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <div className="flex flex-row justify-between">
          <P className="text-sm font-normal text-grey-5">Always CC</P>
          <P className="text-sm font-normal">{alwaysCC ? 'Yes' : 'No'}</P>
        </div>
      </div>
    </Card>
  );
};

export default TalentManagerCard;
