import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { SAVE_TABLE_CONFIGURATION } from '@frontend/app/queries/SaveTableConfigurationMutation'; // Make sure the mutation is defined
import {
  SaveTableConfigurationMutation,
  SaveTableConfigurationMutationVariables,
} from '@frontend/app/queries/types/SaveTableConfigurationMutation';

type IOptions = MutationHookOptions<SaveTableConfigurationMutation, SaveTableConfigurationMutationVariables>;

export const useSaveTableConfigurationMutation = (options: IOptions = {}) =>
  useMutation<SaveTableConfigurationMutation, SaveTableConfigurationMutationVariables>(SAVE_TABLE_CONFIGURATION, options);
