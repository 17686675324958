import * as React from 'react';
import { first, isArray } from 'lodash';
import { Input } from '@frontend/shadcn/components/ui/input';

const { useRef } = React;

interface IProps {
  value: number;
  isPercentage?: boolean;
  onChange?(value: number);
}

export interface INumberEqualHandles {
  focus(): void;
}

const NumberEqualComponent: React.RefForwardingComponent<INumberEqualHandles, IProps> = (props, _) => {
  const {
    value,
    onChange,
    isPercentage,
  } = props;

  if (isArray(value)) {
    onChange(first(value));
  }

  const inputNumberRef = useRef();

  const handleChange = (value: string) => {
    if (Number.isNaN(Number(value))) {
      return;
    }
    onChange(Number(value));
  };

  return (
    <Input
      autoFocus
      value={isArray(value) ? first(value) : value}
      onChange={(e) => handleChange(e.target.value)}
      ref={inputNumberRef}
      min={isPercentage ? 0 : undefined}
      max={isPercentage ? 100 : undefined}
    />
  );
};

export const NumberEqual = React.forwardRef(NumberEqualComponent);
