import React from 'react';
import { Badge } from '@frontend/shadcn/components/ui/badge';

interface TagsProps extends React.HTMLAttributes<HTMLDivElement> {
  labels: string[];
  maxTags: number;
}

const Tags: React.FC<TagsProps> = ({ labels, maxTags }) => {
  const extraLabelCount = labels.length - maxTags;
  const badgeLabels = extraLabelCount > 0 ? [...labels.slice(0, maxTags), `+ ${extraLabelCount}`] : labels;
  return (
    <div className="flex flex-wrap gap-1 items-center px-2 whitespace-nowrap font-[system-ui]">
      {badgeLabels.map((label) => (
        <Badge key={label} variant="secondary" className="font-normal">
          {label}
        </Badge>
))}

    </div>
  );
};

export default Tags;
