import React, { useMemo, useCallback } from 'react';
import { useForm, Controller, FieldErrors } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@frontend/shadcn/components/ui/collapsible';
import { ChevronUpIcon } from '@radix-ui/react-icons';
import { cn } from '@frontend/shadcn/lib/utils';
import { FormMessage } from '@frontend/shadcn/components/ui/form';
import { Select } from '@revfluence/fresh';
import { FiltersFormProps } from './types';

export const filtersFormSchema = z
  .object({
    filterType: z.enum(['network', 'posts']).default('network'),
    networks: z.array(z.enum(['INSTAGRAM', 'TIKTOK', 'PINTEREST', 'YOUTUBE'])).default([]),
    projectIds: z.array(z.number()).default([]),
    groupIds: z.array(z.number()).default([]),
    postTypes: z
      .array(
        z.enum([
          'INSTAGRAM_POST',
          'INSTAGRAM_REEL',
          'INSTAGRAM_STORY',
          'TIKTOK_VIDEO',
          'YOUTUBE_VIDEO',
          'YOUTUBE_SHORT',
          'PINTEREST_PIN',
        ]),
      )
      .default([]),
    includeEstimates: z.boolean().default(false),
    // includeUnassigned: z.boolean().default(false),
    segments: z.array(z.enum(['brandFans', 'macro', 'mega', 'micro', 'midTier', 'nano', 'unknown'])).default([]),
  })
  .refine((data) => !(data.networks.length > 0 && data.postTypes.length > 0), {
    message: 'Please select either Networks or Post Types, but not both',
    path: ['network'],
  });
export type FiltersFormData = z.infer<typeof filtersFormSchema>;

export type NetworkValue = z.infer<typeof filtersFormSchema>['networks'][number];
export type PostTypeValue = z.infer<typeof filtersFormSchema>['postTypes'][number];

export const networkOptions: Array<{ value: NetworkValue; label: string }> = [
  { value: 'INSTAGRAM', label: 'Instagram' },
  { value: 'TIKTOK', label: 'TikTok' },
  { value: 'PINTEREST', label: 'Pinterest' },
  { value: 'YOUTUBE', label: 'YouTube' },
];

export const postTypeOptions: Array<{ value: PostTypeValue; label: string }> = [
  { value: 'INSTAGRAM_POST', label: 'Instagram Post' },
  { value: 'INSTAGRAM_REEL', label: 'Instagram Reel' },
  { value: 'INSTAGRAM_STORY', label: 'Instagram Story' },
  { value: 'TIKTOK_VIDEO', label: 'TikTok Video' },
  { value: 'YOUTUBE_VIDEO', label: 'YouTube Video' },
  { value: 'YOUTUBE_SHORT', label: 'YouTube Short' },
  { value: 'PINTEREST_PIN', label: 'Pinterest Pin' },
];

export const FiltersForm: React.FC<FiltersFormProps> = ({
  projects,
  groups,
  isFetchingProjects,
  isFetchingGroups,
  defaultValues,
  onSubmit,
  onCancel,
}) => {
  const form = useForm<z.infer<typeof filtersFormSchema>>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      networks: defaultValues?.networks || [],
      postTypes: defaultValues?.postTypes || [],
      includeEstimates: defaultValues?.includeEstimates || false,
      filterType: defaultValues?.postTypes?.length ? 'posts' : 'network',
      projectIds: defaultValues?.projectIds || [],
      groupIds: defaultValues?.groupIds || [],
      // includeUnassigned: defaultValues?.includeUnassigned || false,
      segments: defaultValues?.segments || [],
    },
  });

  const projectOptions = useMemo(() => projects.map((project) => ({ value: project.id, label: project.name })), [
    projects,
  ]);

  const groupOptions = useMemo(() => groups.map((group) => ({ value: group.id, label: group.name })), [groups]);

  const filterOption = useCallback((search, option) => option.label.toLowerCase().includes(search.toLowerCase()), []);

  const handleSubmit = (data: z.infer<typeof filtersFormSchema>) => {
    onSubmit(data);
  };

  const handleError = (errors: FieldErrors<z.infer<typeof filtersFormSchema>>) => {
    console.error('Form validation errors:', errors);
  };

  const networks = form.watch('networks');
  const postTypes = form.watch('postTypes');

  // Clear the other field when one is selected
  React.useEffect(() => {
    if (networks.length > 0) {
      form.setValue('postTypes', []);
    }
  }, [networks, form]);

  React.useEffect(() => {
    if (postTypes.length > 0) {
      form.setValue('networks', []);
    }
  }, [postTypes, form]);

  return (
    <form onSubmit={form.handleSubmit(handleSubmit, handleError)} className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        <Collapsible defaultOpen className="border-b">
          <CollapsibleTrigger
            className="flex w-full items-center justify-between py-6"
            data-dd-action-name="toggle-assignment-section"
          >
            <span className="font-medium">Assignment</span>
            <ChevronUpIcon className="h-4 w-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className="pb-6 space-y-4">
            <div className="space-y-2">
              <Label>Projects</Label>
              <Controller
                name="projectIds"
                control={form.control}
                render={({ field }) => (
                  <div>
                    <Select
                      mode="multiple"
                      loading={isFetchingProjects}
                      options={projectOptions}
                      value={(field.value as unknown) as { [key: number]: string }}
                      onChange={(values: { [key: number]: string }) => {
                        const projectIds = Object.values(values).map(Number);
                        field.onChange(projectIds);
                      }}
                      placeholder="All projects"
                      filterOption={filterOption}
                      showSearch
                      showArrow
                      popupClassName="relationships-popup"
                      style={{ width: '100%' }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      data-dd-action-name="select-projects"
                      aria-label="Select projects"
                    />
                  </div>
                )}
              />
            </div>
            <div className="space-y-2">
              <Label>Groups</Label>
              <Controller
                name="groupIds"
                control={form.control}
                render={({ field }) => (
                  <div>
                    <Select
                      mode="multiple"
                      loading={isFetchingGroups}
                      options={groupOptions}
                      value={(field.value as unknown) as { [key: number]: string }}
                      onChange={(values: { [key: number]: string }) => {
                        const groupIds = Object.values(values).map(Number);
                        field.onChange(groupIds);
                      }}
                      placeholder="All groups"
                      filterOption={filterOption}
                      showSearch
                      showArrow
                      popupClassName="relationships-popup"
                      style={{ width: '100%' }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      data-dd-action-name="select-groups"
                      aria-label="Select groups"
                    />
                  </div>
                )}
              />
            </div>
            {/* <div className="flex items-center space-x-2">
              <Controller
                name="includeUnassigned"
                control={form.control}
                render={({ field }) => (
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id="includeUnassigned"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        data-dd-action-name="toggle-include-unassigned-posts"
                        aria-label="Include unassigned posts"
                      />
                      <label
                        htmlFor="includeUnassigned"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Include unassigned posts
                      </label>
                    </div>
                  </div>
                )}
              />
            </div> */}
          </CollapsibleContent>
        </Collapsible>

        <Collapsible defaultOpen className="border-b">
          <CollapsibleTrigger
            className="flex w-full items-center justify-between py-6"
            data-dd-action-name="toggle-social-metrics-section"
          >
            <span className="font-medium">Social Metrics</span>
            <ChevronUpIcon className="h-4 w-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className="pb-6 space-y-6">
            <Controller
              name="filterType"
              control={form.control}
              render={({ field }) => (
                <RadioGroup
                  className="space-y-4"
                  value={field.value}
                  onValueChange={(value: 'network' | 'posts') => {
                    field.onChange(value);
                    if (value === 'network') {
                      form.setValue('postTypes', []);
                    } else {
                      form.setValue('networks', []);
                    }
                  }}
                  data-dd-action-name="select-filter-type"
                  aria-label="Select filter type"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="network" id="network" data-dd-action-name="select-network-filter" />
                    <Label htmlFor="network">Network</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="posts" id="posts" data-dd-action-name="select-posts-filter" />
                    <Label htmlFor="posts">Posts</Label>
                  </div>
                </RadioGroup>
              )}
            />

            <div className="space-y-4">
              <Controller
                name="networks"
                control={form.control}
                render={({ field }) => (
                  <div className={cn('space-y-2', form.watch('filterType') === 'posts' && 'hidden')}>
                    <div className="pl-2 space-y-2">
                      {networkOptions.map((option) => (
                        <div key={option.value} className="flex items-center space-x-2">
                          <Checkbox
                            id={option.value}
                            checked={field.value.includes(option.value)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                field.onChange([...field.value, option.value]);
                              } else {
                                field.onChange(field.value.filter((v) => v !== option.value));
                              }
                            }}
                            data-dd-action-name={`toggle-network-${option.value.toLowerCase()}`}
                            aria-label={`Toggle ${option.label} network`}
                          />
                          <label htmlFor={option.value} className="text-sm">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {form.formState.errors.networks && (
                      <FormMessage>{form.formState.errors.networks.message}</FormMessage>
                    )}
                  </div>
                )}
              />

              <Controller
                name="postTypes"
                control={form.control}
                render={({ field }) => (
                  <div className={cn('space-y-2', form.watch('filterType') === 'network' && 'hidden')}>
                    <div className="pl-2 space-y-2">
                      {postTypeOptions.map((option) => (
                        <div key={option.value} className="flex items-center space-x-2">
                          <Checkbox
                            id={option.value}
                            checked={field.value.includes(option.value)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                field.onChange([...field.value, option.value]);
                              } else {
                                field.onChange(field.value.filter((v) => v !== option.value));
                              }
                            }}
                            data-dd-action-name={`toggle-post-type-${option.value.toLowerCase()}`}
                            aria-label={`Toggle ${option.label} post type`}
                          />
                          <label htmlFor={option.value} className="text-sm">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {form.formState.errors.postTypes && (
                      <FormMessage>{form.formState.errors.postTypes.message}</FormMessage>
                    )}
                  </div>
                )}
              />
            </div>
          </CollapsibleContent>
        </Collapsible>

        <Collapsible defaultOpen className="border-b">
          <CollapsibleTrigger
            className="flex w-full items-center justify-between py-6"
            data-dd-action-name="toggle-data-source-section"
          >
            <span className="font-medium">Data Source</span>
            <ChevronUpIcon className="h-4 w-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className=" pb-6">
            <Controller
              name="includeEstimates"
              control={form.control}
              render={({ field }) => (
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="includeEstimates"
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                      data-dd-action-name="toggle-estimated-data"
                      aria-label="Include estimated data"
                    />
                    <label htmlFor="includeEstimates" className="text-sm">
                      Include estimated data
                    </label>
                  </div>
                </div>
              )}
            />
          </CollapsibleContent>
        </Collapsible>
      </div>

      <div className="flex justify-start gap-2 p-4 mt-auto mb-4 border-t bg-background mx-[-1.5rem]">
        <Button
          type="submit"
          variant="primary"
          size="sm"
          data-dd-action-name="apply-filters"
          aria-label="Apply filters"
        >
          Apply
        </Button>
        <Button
          type="button"
          variant="ghost"
          size="sm"
          onClick={onCancel}
          data-dd-action-name="cancel-filters"
          aria-label="Cancel filters"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
