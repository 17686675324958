import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_PAYMENT_DETAILS_BY_IDS from '@frontend/app/queries/GetPaymentDetailsByIdsQuery';
import {
  GetPaymentDetailsByIdsQuery,
  GetPaymentDetailsByIdsQueryVariables,
} from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';

type IOptions = QueryHookOptions<GetPaymentDetailsByIdsQuery, GetPaymentDetailsByIdsQueryVariables>;

export const useGetPaymentDetailsByIdsQuery = (options: IOptions = {}) =>
  useQuery<GetPaymentDetailsByIdsQuery, GetPaymentDetailsByIdsQueryVariables>(GET_PAYMENT_DETAILS_BY_IDS, options);
