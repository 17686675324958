import moment from 'moment';
import * as React from 'react';
import { usePostHog } from 'posthog-js/react';

import { EventProperties, EventName } from '@common';

import { useAuth } from '@frontend/context/authContext';
import {
  useClientFeatureEnabled,
  useGetCurrentClient,
  useGetCurrentClientMetadata,
  useGetCurrentUserMetadata,
  useGetProfile,
} from '@frontend/app/hooks';
import { isNil } from 'lodash';
import { OverlaySpinner } from '@components';
import { ClientFeature } from '@frontend/app/constants';
// import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js';
import { useIdleTimer } from 'react-idle-timer';

const { useCallback, useEffect, useState, useRef } = React;

interface IEventUser {
  email: string;
  name: string;
  userType: string;
  created_date?: string;
  createdAt?: string;
  user_cohort_day?: number;
  clientId: string;
  companyId?: string;
  company_Id?: string;
}

interface IEventClient {
  client_id: string;
  client_name: string;
  date_created: string;
  client_cohort_day?: number;
  client_activated_date?: Date;
  companyId?: string;
  company_Id?: string;
}

type IEventState = {
  full_story_session_url: string;
  user_cohort_day: number;
  email?: string;
} & IEventClient;

type IEventContext = <E extends EventProperties, K extends keyof E>(eventName: K, properties: E[K]) => void;

const EventContext = React.createContext<IEventContext>(null);
export const useEventContext = () => React.useContext(EventContext);
export const EventProvider = ({ children }) => {
  const auth = useAuth();
  const { user: auth0User, isAuthenticated, logout } = auth || {};
  const [userIdentified, setUserIdentified] = useState(false);

  const posthog = usePostHog();

  const { client } = useGetCurrentClient({ skip: !auth0User });

  const { profile } = useGetProfile({ skip: !auth0User });

  const { clientMetadata } = useGetCurrentClientMetadata({ skip: !auth0User });

  const { userMetadata } = useGetCurrentUserMetadata({ skip: !auth0User });

  const unProcessedEvents = useRef([]);

  const eventTrackingDisabled = useClientFeatureEnabled(ClientFeature.EVENT_TRACKING_DISABLED, { skip: !auth0User });

  const [eventState, setEventState] = React.useState<IEventState>(null);

  const idleTimeoutEnabled = useClientFeatureEnabled(ClientFeature.CLIENT_IDLE_TIMEOUT, { skip: !auth0User });
  const onIdle = () => {
    // If the user is authenticated and client timeout feature is enabled
    if (auth0User && isAuthenticated && idleTimeoutEnabled) {
      logout();
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 60 * 60 * 1000, // 60 minutes
  });

  useEffect(() => {
    // Only add these properties if it is a signed in user completing the event
    if (!auth0User || !client || !profile || !posthog) {
      return;
    }
    if (userIdentified) {
      return;
    }

    const userFirstLoginDate = userMetadata?.firstLoginDate;
    const userCohortDay = userFirstLoginDate ? moment().diff(moment(userFirstLoginDate), 'days', true) : 0;

    const user_properties: IEventUser = {
      email: auth0User.email,
      name: auth0User.name,
      userType: 'Brand',
      createdAt: profile?.createdDate,
      created_date: profile?.createdDate,
      user_cohort_day: userCohortDay,
      clientId: client.id,

      // aliases for the Planhat
      companyId: client.id,
      company_Id: client.id,
    };

    const clientActivatedDate = clientMetadata?.activationDate;

    const clientCohortDay = (() => {
      const clientOnboardingCompletedDate = clientMetadata?.onboardingCompletedDate;
      const clientFirstLoginDate = clientMetadata?.firstLoginDate;
      if (clientOnboardingCompletedDate) {
        return moment().diff(moment(clientOnboardingCompletedDate), 'days', true);
      } else if (clientFirstLoginDate) {
        return moment().diff(moment(clientFirstLoginDate), 'days', true);
      }
      return 0;
    })();

    const client_properties: IEventClient = {
      client_id: client.id,
      client_name: client.name,
      date_created: client.createdDate,
      client_cohort_day: clientCohortDay,
      client_activated_date: clientActivatedDate,

      // aliases for the Planhat
      companyId: client.id,
      company_Id: client.id,
    };

    posthog?.identify(auth0User.sub, user_properties);
    posthog?.group('client', client.id, client_properties);
    posthog ? setUserIdentified(true) : null;

    const event_properties: IEventState = {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      full_story_session_url: (window as any).__fullstorySessionUrl,
      user_cohort_day: user_properties.user_cohort_day,
      email: auth0User.name,
      ...client_properties,
    };

    setEventState(event_properties);
  }, [auth0User, profile, client, clientMetadata, userMetadata, userIdentified, posthog]);

  const addEvent = useCallback(
    <E extends EventProperties, K extends keyof E>(eventName: K, properties: E[K]): void => {
      const trackProperties = eventState ? { ...properties, ...eventState } : properties;

      if (!eventTrackingDisabled) {
        if (unProcessedEvents.current.length > 0) {
          unProcessedEvents.current.forEach((event) => {
            window?.analytics?.track(event.eventName, event.trackProperties);
            posthog?.capture(event.eventName, event.trackProperties);
          });
          unProcessedEvents.current = [];
        }
        posthog?.capture(eventName as string, trackProperties);
      } else {
        console.group('addEvent');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        console.log(`analytics not loaded for "${eventName}" event`);
        console.dir(trackProperties);
        console.groupEnd();
      }
    },
    [eventState, eventTrackingDisabled, posthog],
  );

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const addWidgetsEventLog = useCallback(
    (eventName: string, params: any) => {
      const { network, term: searchTerm } = params;

      switch (eventName) {
        case 'search':
          return addEvent(EventName.CreatorSearch, {
            network,
            searchTerm,
          });
        default:
      }
    },
    [addEvent],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (window as any).addEventLog = addWidgetsEventLog;
    }
  }, [addWidgetsEventLog]);

  if (isNil(eventState) && !isNil(auth0User)) {
    return <OverlaySpinner />;
  } else {
    return (
      <>
        <EventContext.Provider value={addEvent}>{children}</EventContext.Provider>
      </>
    );
  }
};
