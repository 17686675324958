import React, { useCallback, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Tabs, TabsContent } from '@frontend/shadcn/components/ui/tabs';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import { useAuth } from '@frontend/context/authContext';
import { SlidersIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@frontend/shadcn/components/ui/sheet';
import { z } from 'zod';
import { useGetCurrentClient } from '@frontend/app/hooks/client/useGetCurrentClient';
import { CalendarIcon } from '@revfluence/fresh-icons/regular/esm';
import { HeaderTabsList, HeaderTabsTrigger } from '../components/header';
import { SocialNetwork, SocialPostType } from '../../../gql/social/graphql';
import { useTabNavigation } from './hooks/useTabNavigation';
import { useAnalyticsData } from './hooks/useAnalyticsData';
import { useAnalyticsParams } from './hooks/useAnalyticsParams';
import { QueryVariables, DateType } from './types';
import { calculateDatesFromType } from '../utils/dates';
import { SummaryTab } from './components/tabs/SummaryTab';
import { PostsTab } from './components/tabs/PostsTab';
import { MembersTab } from './components/tabs/MembersTab';
import { NetworksTab } from './components/tabs/NetworksTab';
import { AnalyticsHeader } from './components/AnalyticsHeader';
import { FiltersForm, filtersFormSchema } from './components/filters-form';
import { DateRangeForm, DateRangeFormData } from './components/date-range-form';
import { useFilterFormData } from './components/filters-form/hooks/useFilterFormData';
import { SocialAnalyticsFilterBadges } from './components/filter-badges';
import {
  useGroupBadges,
  useIncludeEstimates,
  useNetworkBadges,
  usePostTypeBadges,
  useProjectBadges,
  useSegmentBadge,
  // useIncludeUnassignedBadge,
} from './components/filter-badges/hooks';
import { defaultColumns } from './components/table/config/defaultColumns';
import { Spinner } from './components/ui/spinner';
import { generateIncludeOptions } from './utils/columnUtils';
import { usePostsData } from './hooks/usePostsData';
import { defaultPostColumns } from './components/table/config/defaultPostTableColumns';
import { useMembersTab } from './hooks/useMembersTab';
import { usePostsTab } from './hooks/usePostsTab';

const defaultPostTypes: SocialPostType[] = [];
const defaultNetworks: SocialNetwork[] = [];

const DEFAULT_VARIABLES: Omit<QueryVariables, 'clientId'> = {
  dateType: 'last6Months' as DateType,
  postTypes: defaultPostTypes,
  includeEstimates: true,
  networks: defaultNetworks,
  projectIds: [],
  groupIds: [],
  memberIds: [],
  segments: [],
};

export const SocialAnalytics = () => {
  const { tab } = useParams<{ tab: string }>();
  const [selectedPostColumns, setSelectedPostColumns] = useState(defaultPostColumns);
  const { clientInfo } = useAuth();
  const { client } = useGetCurrentClient();
  const clientId = clientInfo?.id;
  const clientCreatedDate = client?.createdDate;
  const location = useLocation();
  const { handleTabChange } = useTabNavigation();
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);

  const { projects, groups, isFetchingProjects, isFetchingGroups, activeProjectIds } = useFilterFormData();

  const { queryVariables, handleApplyFilters, handleDateRangeSubmit, updateUrlParams } = useAnalyticsParams({
    clientId,
    defaultVariables: DEFAULT_VARIABLES,
    clientCreatedDate,
  });

  const { projectBadgesProps } = useProjectBadges({ projectIds: queryVariables.projectIds });
  const { groupBadgesProps } = useGroupBadges({ groupIds: queryVariables.groupIds });
  const { networkBadgesProps } = useNetworkBadges({ networks: queryVariables.networks });
  const { postTypeBadgesProps } = usePostTypeBadges({ postTypes: queryVariables.postTypes });
  const { includeEstimatesBadgeProps } = useIncludeEstimates({ includeEstimates: queryVariables.includeEstimates });
  const { segmentBadgeProps } = useSegmentBadge({ segments: queryVariables.segments });
//   const { includeUnassignedBadgeProps } = useIncludeUnassignedBadge({
//     includeUnassigned: queryVariables.includeUnassigned,
//   });

  // Initialize URL params if they don't exist
  React.useEffect(() => {
    if (!location.search && clientId && !isFetchingProjects) {
      const dates = calculateDatesFromType(
        DEFAULT_VARIABLES.dateType,
        clientCreatedDate
          ? (() => {
              const d = new Date(clientCreatedDate);
              d.setUTCHours(0, 0, 0, 0);
              return d;
            })()
          : undefined,
      );
      const initialVariables = {
        ...DEFAULT_VARIABLES,
        clientId,
        startDate: dates.startDate.toISOString().split('T')[0],
        endDate: dates.endDate.toISOString().split('T')[0],
        projectIds: activeProjectIds,
      };
      updateUrlParams(initialVariables);
    }
  }, [location.search, updateUrlParams, clientId, clientCreatedDate, activeProjectIds, isFetchingProjects]);

  const { data: postsData, fetching: fetchingPosts, handleRefresh: handleRefreshPosts } = usePostsData({
    clientId,
    tab,
    queryVariables,
  });

  // Move the analytics data hook before its usage
  const includeOptions = useMemo(() => generateIncludeOptions(defaultColumns, selectedColumns), [selectedColumns]);
  const { data, fetching, refetching, handleRefresh: handleRefreshMembers } = useAnalyticsData({
    clientId,
    tab,
    queryVariables,
    includeOptions,
  });

  const { memberTabProps } = useMembersTab({
    queryVariables,
    setSelectedColumns,
    selectedColumns,
    fetching,
    data,
    handleApplyFilters,
  });

  const { postsTabProps } = usePostsTab({
    queryVariables,
    setSelectedColumns: setSelectedPostColumns,
    selectedColumns: selectedPostColumns,
    fetching: fetchingPosts,
    data: postsData,
  });

  // Update the refresh handler to handle both members and posts
  const handleRefresh = useCallback(() => {
    if (tab === 'members') {
      handleRefreshMembers();
    } else if (tab === 'posts') {
      handleRefreshPosts();
    }
  }, [tab, handleRefreshMembers, handleRefreshPosts]);

  const handleCancelFilters = () => {
    setIsFiltersOpen(false);
  };

  const handleOpenFilters = () => {
    setIsFiltersOpen(true);
  };

  const handleApplyFiltersAndClose = (formData: z.infer<typeof filtersFormSchema>) => {
    handleApplyFilters(formData);
    memberTabProps.setCurrentPage(0);
    postsTabProps.setCurrentPage(0);
    setIsFiltersOpen(false);
  };

  const handleRemoveFilters = useCallback(
    (type: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      const badgeData: z.infer<typeof filtersFormSchema> = {
        postTypes: queryVariables.postTypes,
        includeEstimates: queryVariables.includeEstimates,
        networks: queryVariables.networks,
        projectIds: queryVariables.projectIds,
        groupIds: queryVariables.groupIds,
        filterType: queryVariables?.postTypes?.length ? 'posts' : 'network',
        // includeUnassigned: queryVariables.includeUnassigned,
        segments: queryVariables.segments,
      };

      switch (type) {
        case 'projectIds':
        case 'groupIds':
        case 'networks':
        case 'postTypes':
          handleApplyFilters({ ...badgeData, [type]: [] });
          break;
        case 'includeEstimates':
          handleApplyFilters({ ...badgeData, includeEstimates: !queryVariables.includeEstimates });
          break;
        // case 'includeUnassigned':
        //   handleApplyFilters({ ...badgeData, includeUnassigned: !queryVariables.includeUnassigned });
        //   break;
        case 'segments':
          handleApplyFilters({ ...badgeData, segments: undefined });
          break;
        default:
          break;
      }
    },
    [handleApplyFilters, queryVariables],
  );

  const handleDateRangeChange = (dateRange: DateRangeFormData) => {
    memberTabProps.setCurrentPage(0);
    postsTabProps.setCurrentPage(0);
    handleDateRangeSubmit(dateRange);
  };

  const headerActions = (
    <>
      <Button
        size="headerIcon"
        variant="outlineHeader"
        disabled={refetching || (tab !== 'members' && tab !== 'posts')}
        onClick={handleRefresh}
        data-testid="refresh-button"
        data-dd-action-name="refresh-analytics-data"
        aria-label={refetching ? 'Refreshing data' : 'Refresh data'}
        aria-busy={refetching}
        className="flex items-center justify-center"
      >
        <Spinner size="sm" spinning={refetching} />
      </Button>
      <DateRangeForm
        onApply={handleDateRangeChange}
        defaultValues={{
          dateType: queryVariables.dateType,
          dateRange:
            queryVariables.startDate && queryVariables.endDate
              ? {
                  from: queryVariables.startDate,
                  to: queryVariables.endDate,
                }
              : undefined,
        }}
        minDate={
          clientCreatedDate
            ? (() => {
                const d = new Date(clientCreatedDate);
                d.setUTCHours(0, 0, 0, 0);
                return d;
              })()
            : undefined
        }
        aria-label="Date range selector"
        className="hidden sm:block"
      />
      <Button
        size="headerIcon"
        variant="outlineHeader"
        className="sm:hidden"
        aria-label="Date range selector"
      >
        <CalendarIcon className="h-4 w-4" aria-hidden="true" />
      </Button>
      <Sheet open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
        <SheetTrigger asChild>
          <Button
            size="headerIcon"
            variant="outlineHeader"
            aria-label="Open filters"
            aria-expanded={isFiltersOpen}
            data-dd-action-name="open-analytics-filters"
          >
            <SlidersIcon aria-hidden="true" />
          </Button>
        </SheetTrigger>
      </Sheet>
    </>
  );

  const headerTabs = (
    <HeaderTabsList role="tablist" aria-label="Social analytics sections">
      {/* <HeaderTabsTrigger
        value="summary"
        data-action="summary-tab-click"
        data-dd-action-name="summary-tab-click"
        aria-controls="summary-tab"
        role="tab"
        aria-current={tab === 'summary' ? 'page' : undefined}
        className="hidden"
      >
        Summary
      </HeaderTabsTrigger> */}
      <HeaderTabsTrigger
        value="posts"
        data-action="posts-tab-click"
        data-dd-action-name="posts-tab-click"
        aria-controls="posts-tab"
        role="tab"
        aria-current={tab === 'posts' ? 'page' : undefined}
      >
        Posts
      </HeaderTabsTrigger>
      <HeaderTabsTrigger
        value="members"
        data-action="members-tab-click"
        data-dd-action-name="members-tab-click"
        aria-controls="members-tab"
        role="tab"
        aria-current={tab === 'members' ? 'page' : undefined}
      >
        Members
      </HeaderTabsTrigger>
      {/* <HeaderTabsTrigger
        value="networks"
        data-action="networks-tab-click"
        data-dd-action-name="networks-tab-click"
        aria-controls="networks-tab"
        role="tab"
        aria-current={tab === 'networks' ? 'page' : undefined}
        className="hidden"
      >
        Networks
      </HeaderTabsTrigger> */}
    </HeaderTabsList>
  );

  return (
    <div className="flex h-full flex-col">
      <TooltipProvider>
        <Tabs value={tab} onValueChange={handleTabChange}>
          <AnalyticsHeader actions={headerActions} tabs={headerTabs} />

          <Sheet open={isFiltersOpen} onOpenChange={setIsFiltersOpen} aria-expanded={isFiltersOpen}>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Filters</SheetTitle>
              </SheetHeader>
              <FiltersForm
                projects={projects}
                groups={groups}
                isFetchingProjects={isFetchingProjects}
                isFetchingGroups={isFetchingGroups}
                defaultValues={queryVariables}
                onSubmit={handleApplyFiltersAndClose}
                onCancel={handleCancelFilters}
              />
            </SheetContent>
          </Sheet>

          <div className="mt-4">
            <div className="grid grid-cols-1 px-4">
              <div className="inline-flex items-center flex-wrap">
                <SocialAnalyticsFilterBadges
                  projectBadgesProps={projectBadgesProps}
                  groupBadgesProps={groupBadgesProps}
                  networkBadgesProps={networkBadgesProps}
                  postTypeBadgesProps={postTypeBadgesProps}
                  includeEstimatesBadgeProps={includeEstimatesBadgeProps}
                  segmentBadgeProps={segmentBadgeProps}
                  // includeUnassignedBadgeProps={includeUnassignedBadgeProps}
                  onOpenFilters={handleOpenFilters}
                  onRemoveFilters={handleRemoveFilters}
                />
              </div>
            </div>
            <TabsContent value="summary" id="summary-tab" role="tabpanel" aria-live="polite" aria-busy={fetching}>
              <SummaryTab />
            </TabsContent>
            <TabsContent value="posts" id="posts-tab" role="tabpanel" aria-live="polite" aria-busy={fetchingPosts}>
              {client && <PostsTab {...postsTabProps} />}
            </TabsContent>
            <TabsContent value="members" id="members-tab" role="tabpanel" aria-live="polite" aria-busy={fetching}>
              <MembersTab {...memberTabProps} />
            </TabsContent>
            <TabsContent value="networks" id="networks-tab" role="tabpanel" aria-live="polite" aria-busy={fetching}>
              <NetworksTab />
            </TabsContent>
          </div>
        </Tabs>
      </TooltipProvider>

      {/* Status announcer for screen readers */}
      <output aria-live="polite" className="sr-only">
        {fetching && !memberTabProps.isPartialLoading && 'Loading data...'}
        {refetching && 'Refreshing data...'}
        {memberTabProps.isPartialLoading && 'Updating table...'}
      </output>
    </div>
  );
};
