export interface IPFAOrderFields {
  orderId: string;
  clientId: string;
  fulfillmentStatus: string;
  memberName: string;
  memberId: string;
  orderCreatedAt: string;
  updatedDate: string;
  creatorOrderRequest: any;
  cost: string;
  raw: any;
  workItemId: string;
  trackingCompany: string;
  trackingId: string;
  trackingUrls: string;
  type: string;
  shipmentStatus: string;
  trackingNumber: string;
}

export interface IMemberOrderData {
  memberId: string;
  memberName: string;
  orderNumber?: string;
  dateSent?: string;
  productsCount?: number;
  totalCost?: number;
  trackingNumber?: string;
  shipmentStatus?: string;
  currentSubStage?: string;
  orderStatus?: string;
  productsRequested?: string[]
}

export enum PFAProjectConfigType {
  Shopify = 'shopify',
  NoCatalogue = 'no_catalogue',
  ProductCatalog = 'PRODUCT_CATALOG',
}
