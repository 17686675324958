import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { Input } from '@frontend/shadcn/components/ui/input';
import { MemoIcon } from '@revfluence/fresh-icons/regular/esm';
import { DatePickerWithRange } from '@frontend/app/refresh-components/DateRangePicker';
import { ValueWithPeriodInput } from '@frontend/app/refresh-components/ValueWithPeriodInput';
import { SectionCard } from '../components/SectionCard';
import { AboutDrawer } from '../AboutDrawer';
import { useCampaign } from '../CampaignContext';
import { ProgramTimePeriod } from '../CampaignContext';
import { TProject } from '../../types';

interface Props {
  project?: TProject;
}

export const AboutSection: React.FC<Props> = ({ project }) => {
  const {
    campaignName,
    startDate,
    endDate,
    budget,
    budgetTimePeriod,
    setDates,
    setBudget,
    setCampaignName,
  } = useCampaign();

  const handleCampaignNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value.trim()) {
      setCampaignName('');
      return;
    }
    if (/^[a-zA-Z0-9\s]+$/.test(value)) {
      setCampaignName(value);
    }
  };

  return (
    <SectionCard>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <MemoIcon />
            <P>About</P>
          </div>
          <H3 className="font-medium mb-0">Provide more information about your campaign.</H3>
        </div>

        <div className="flex flex-col gap-4">
          {/* Campaign Name */}
          <div className="flex items-center gap-10">
            <div className="flex flex-col gap-2 flex-1">
              <label className="font-medium">Campaign Name</label>
              <Input
                placeholder="Enter campaign name"
                value={campaignName}
                onChange={handleCampaignNameChange}
              />
            </div>

            {/* Campaign Dates */}
            <div className="flex flex-col gap-2 flex-1">
              <label className="font-medium">Dates</label>
              <DatePickerWithRange
                startDate={startDate}
                endDate={endDate}
                onRangeChange={({ from, to }) => setDates(from, to)}
              />
            </div>
          </div>

          {/* Campaign Budget */}
          <div className="flex items-center gap-10">
            <div className="flex flex-col gap-2 flex-1">
              <label className="font-medium">Campaign Budget</label>
              <ValueWithPeriodInput
                value={budget}
                period={budgetTimePeriod || ProgramTimePeriod.PER_MONTH}
                onValueChange={setBudget}
              />
            </div>
            <div className="flex flex-col gap-2 flex-1" />
          </div>

          {/* Advanced Settings */}
          <AboutDrawer project={project} />
        </div>
      </div>
    </SectionCard>
  );
};
