import React from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ArrowDownToLineIcon } from '@revfluence/fresh-icons/regular/esm';
import { PaypalIcon } from '@revfluence/fresh-icons/brands/esm';
import { P } from '@frontend/shadcn/components/typography/p';
import { NameCell } from '@frontend/app/refresh-components';
import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';

interface TopSectionProps {
  paymentDetails: GetPaymentDetailsByIdsQuery_payments[];
  onClickDownload: () => void;
}

export const TopSection: React.FC<TopSectionProps> = ({ paymentDetails, onClickDownload }) => {
  const [isHovering, setIsHovering] = React.useState(false);

  if (!paymentDetails?.length) {
    return null;
  }
  if (paymentDetails.length === 1) {
    const selectedPayment = paymentDetails?.[0];
    return (
      <div className="flex justify-between items-center bg-grey-1 px-4 min-h-[48px]">
        <NameCell name={selectedPayment?.member?.name || 'N/A'} imageUrl="" />
        <div className="flex flex-row gap-2">
          <PaypalIcon className="h-6 w-6 text-[#00457C]" />
          <P>{selectedPayment?.paypalEmail || 'N/A'}</P>
        </div>
        <Button variant="outline" size="icon" onClick={onClickDownload} className="h-8 w-8">
          <ArrowDownToLineIcon className="h-3 w-3" />
        </Button>
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center bg-grey-1 px-4 min-h-[48px]">
      <div className="flex flex-row gap-2">
        <P>{paymentDetails.length} Payments Selected</P>
      </div>
      <div className="relative" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <P className="text-grey-6 underline cursor-pointer">View Payments</P>
        {isHovering && (
          <div className="absolute z-50 right-0 mt-1 mb-4 w-60 p-4 bg-white shadow-lg rounded-md border">
            <P className="font-medium text-sm text-grey-6">{paymentDetails.length} Payments</P>
            <div className="flex flex-col gap-1 mt-2">
              {paymentDetails.map((payment) => (
                <div key={payment.id} className="flex flex-row justify-between">
                  <P className="text-xs font-medium text-grey-6">{payment.member?.name}</P>
                  <P className="text-xs font-normal text-grey-5">{`$${payment.amount}`}</P>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
