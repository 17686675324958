import React from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerClose,
} from '@frontend/shadcn/components/ui/drawer';
import { XmarkIcon } from '@revfluence/fresh-icons/solid';
import { Button } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@frontend/shadcn/components/ui/collapsible';
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { CoinsIcon, FileIcon, TagsIcon } from '@revfluence/fresh-icons/solid/esm';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { getUniqueRefreshColor } from '@frontend/utils';
import { Input } from '@frontend/shadcn/components/ui/input';
import { PaymentStatus, PaymentType } from '../../constants';
import { PaymentFilter, usePayment } from '../PaymentContext';

const paymentTypeIcon: Record<PaymentType, JSX.Element> = {
  [PaymentType.PAID_FOR_BRIEFS]: <FileIcon className="w-4 h-4 text-[#339FDE]" />,
  [PaymentType.PAID_FOR_COMMISSIONS]: <TagsIcon className="w-4 h-4 text-[#4F5ABA]" />,
  [PaymentType.OTHERS]: <CoinsIcon className="w-4 h-4 text-[#5DB8B3]" />,
};

interface FilterSectionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  showSearch?: boolean;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  title,
  children,
  defaultOpen = true,
  showSearch = false,
  searchValue = '',
  onSearchChange
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  return (
    <>
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleTrigger className="flex gap-2 pr-3 py-2 w-full mx-0 cursor-pointer rounded-md font-medium text-foreground text-base leading-relaxed items-center">
          <span className="text-base">{title}</span>
          {isOpen ? (
            <ChevronUpIcon className="ml-auto h-4 w-4" />
          ) : (
            <ChevronDownIcon className="ml-auto h-4 w-4" />
          )}
        </CollapsibleTrigger>
        <CollapsibleContent>
          {showSearch && (
            <div className="relative mb-2">
              <MagnifyingGlassIcon className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search Project"
                className="pl-8"
                value={searchValue}
                onChange={(e) => onSearchChange?.(e.target.value)}
              />
            </div>
          )}
          <div className="flex flex-col gap-2 max-h-[150px] overflow-y-auto">
            {children}
          </div>
        </CollapsibleContent>
      </Collapsible>
      <Separator />
    </>
  );
};

interface CheckboxItemProps {
  id: string | number;
  label: string;
  icon?: JSX.Element;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({ id, label, icon, checked, onCheckedChange }) => (
  <div className="flex items-center space-x-2">
    <Checkbox
      id={String(id)}
      checked={checked}
      onCheckedChange={onCheckedChange}
    />
    {icon}
    <span>{label?.length > 30 ? `${label.slice(0, 30)}...` : label}</span>
  </div>
);

interface TagCheckboxItemProps {
  id: string | number;
  name: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

const TagCheckboxItem: React.FC<TagCheckboxItemProps> = ({ id, name, checked, onCheckedChange }) => (
  <div className="flex items-center space-x-2">
    <Checkbox
      id={String(id)}
      checked={checked}
      onCheckedChange={onCheckedChange}
    />
    <Badge
      className={`rounded-md font-normal ${getUniqueRefreshColor(name)}`}
      variant="secondary"
    >
      {name}
    </Badge>
  </div>
);

export interface FilterDrawerProps {}

export const FilterDrawer: React.FC<FilterDrawerProps> = () => {
  const [projectSearch, setProjectSearch] = React.useState('');
  const [tempFilter, setTempFilter] = React.useState<PaymentFilter>({
    status: [],
    paymentTypes: [],
    programIds: [],
    tagIds: [],
    budgetIds: [],
    userIds: [],
  });

  const {
    isFilterOpen,
    setIsFilterOpen,
    users,
    programs,
    tags,
    budgets,
    filter,
    setFilter,
  } = usePayment();

  React.useEffect(() => {
    if (isFilterOpen) {
      setTempFilter(filter);
    }
  }, [isFilterOpen, filter]);

  const filteredPrograms = React.useMemo(() => {
    return programs
      ?.filter((program) =>
        program.title.toLowerCase().includes(projectSearch.toLowerCase())
      )
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [programs, projectSearch]);

  const handleApply = () => {
    setFilter(tempFilter);
    setIsFilterOpen(false);
  };

  const handleCancel = () => {
    setTempFilter(filter);
    setIsFilterOpen(false);
  };

  const handleStatusChange = (status: PaymentStatus, checked: boolean) => {
    setTempFilter((prev) => ({
      ...prev,
      status: checked
        ? [...prev.status, status]
        : prev.status.filter((s) => s !== status)
    }));
  };

  return (
    <Drawer open={isFilterOpen} onOpenChange={setIsFilterOpen} direction="right">
      <DrawerContent
        className={cn(
          'fixed left-auto inset-y-0 mt-0 right-0 z-[9999] flex h-full w-[360px] flex-col border-l bg-background rounded-none p-0',
          'duration-300 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
          'bg-background backdrop-blur-sm',
          'top-[var(--header-height,0px)] h-[calc(100vh-var(--header-height,0px))]',
          'bg-white',
        )}
        data-vaul-no-drag
      >
        <DrawerHeader className="flex justify-between items-center border-b border-grey-3 px-6 py-4">
          <DrawerTitle className="font-medium mb-0">Filter</DrawerTitle>
          <DrawerClose asChild>
            <Button
              variant="ghost"
              size="headerIcon"
              className="border border-transparent hover:border-grey-6 hover:bg-transparent"
            >
              <XmarkIcon className="h-4 w-4" />
            </Button>
          </DrawerClose>
        </DrawerHeader>

        <div className="flex flex-col gap-3 px-4 overflow-y-scroll">
          {/* Payment Status Section */}
          <FilterSection title="Payment Status">
            {Object.values(PaymentStatus).map((status) => (
              <CheckboxItem
                key={status}
                id={status}
                label={status}
                checked={tempFilter.status.includes(status)}
                onCheckedChange={(checked) => handleStatusChange(status, checked)}
              />
            ))}
          </FilterSection>

          {/* Payment Type Section */}
          <FilterSection title="Payment Type">
            {Object.values(PaymentType).map((type) => (
              <CheckboxItem
                key={type}
                id={type}
                label={type}
                icon={paymentTypeIcon[type as PaymentType]}
                checked={tempFilter.paymentTypes.includes(type)}
                onCheckedChange={(checked) => {
                  setTempFilter((prev) => ({
                    ...prev,
                    paymentTypes: checked
                      ? [...prev.paymentTypes, type]
                      : prev.paymentTypes.filter((t) => t !== type)
                  }));
                }}
              />
            ))}
          </FilterSection>

          {/* Project Section */}
          <FilterSection
            title="Project"
            showSearch
            searchValue={projectSearch}
            onSearchChange={setProjectSearch}
          >
            {filteredPrograms?.length ? (
              filteredPrograms.map((program) => (
                <CheckboxItem
                  key={program.id}
                  id={program.id}
                  label={program.title}
                  checked={tempFilter.programIds.includes(program.id)}
                  onCheckedChange={(checked) => {
                    setTempFilter((prev) => ({
                      ...prev,
                      programIds: checked
                        ? [...prev.programIds, program.id]
                        : prev.programIds.filter((id) => id !== program.id)
                    }));
                  }}
                />
              ))
            ) : (
              <div className="py-2 text-muted-foreground text-xs text-center">No Project</div>
            )}
          </FilterSection>

          {/* Tags Section */}
          <FilterSection title="Tags">
            {tags?.map((tag) => (
              <TagCheckboxItem
                key={tag.id}
                id={tag.id}
                name={tag.name}
                checked={tempFilter.tagIds.includes(tag.id)}
                onCheckedChange={(checked) => {
                  setTempFilter((prev) => ({
                    ...prev,
                    tagIds: checked
                      ? [...prev.tagIds, tag.id]
                      : prev.tagIds.filter((id) => id !== tag.id)
                  }));
                }}
              />
            ))}
          </FilterSection>

          {/* Budget Accounts Section */}
          <FilterSection title="Budget Accounts">
            {budgets?.map((budget) => (
              <CheckboxItem
                key={budget.id}
                id={budget.id}
                label={budget.name}
                checked={tempFilter.budgetIds.includes(budget.id)}
                onCheckedChange={(checked) => {
                  setTempFilter((prev) => ({
                    ...prev,
                    budgetIds: checked
                      ? [...prev.budgetIds, budget.id]
                      : prev.budgetIds.filter((id) => id !== budget.id)
                  }));
                }}
              />
            ))}
          </FilterSection>

          {/* Initiated By Section */}
          <FilterSection title="Initiated By">
            {users?.map((user) => (
              <CheckboxItem
                key={user.id}
                id={user.id}
                label={user.name}
                checked={tempFilter.userIds.includes(String(user.id))}
                onCheckedChange={(checked) => {
                  setTempFilter((prev) => ({
                    ...prev,
                    userIds: checked
                      ? [...prev.userIds, String(user.id)]
                      : prev.userIds.filter((id) => id !== String(user.id))
                  }));
                }}
              />
            ))}
          </FilterSection>
        </div>

        <DrawerFooter className="flex flex-row gap-2 space-x-2 border-t border-grey-3">
          <Button onClick={handleApply}>Apply</Button>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
