import React from 'react';
import {
  FacebookEmbed, InstagramEmbed, PinterestEmbed, TikTokEmbed, YouTubeEmbed,
} from 'react-social-media-embed';
import { StarFilled } from '@ant-design/icons';
import { StarIcon } from '@revfluence/fresh-icons/regular';
import { Carousel, CarouselItem } from './Carousel';
import {
  CarouselItem as LandingPageCarouselItem, getInitials, getMappedText, HeroSectionObject, defaultImageUrl,
} from '../../utils/LandingPagePreviewUtils';
import { detectContentType } from '../../utils/contentUtils';
import { ContentTypeEnum } from '../LeftFrame/components/FeaturedContent/components/FeaturedContentModal';

export const LandingPageHeroSection = ({ data }: { data: HeroSectionObject }) => {
  const {
    creatorTag,
    offerTitle,
    offerDescription,
    heroImage,
    heroProductName,
    showProductRating,
    heroProductRating,
    heroProductReviews,
    heroProductPrice,
    heroProductDiscountedPrice,
    discountButtonText,
    discountButtonLink,
    carouselData,
    offerDiscount,
    creatorName,
    isFeaturedProductEnable,
    isFeaturedContentEnabled,
    backgroundColor,
    landingPageStyles,
    currency,
    heroProductUrl,
    heroSectionBackgroundImage
  } = data;
  const dynamicFontFamily = landingPageStyles.typography?.body?.fontStyle;
  const wrappedOfferDescription = offerDescription.replace(
    /<(p|strong|em|b|i|span|ul|li)([^>]*)>/gi,
    (_match, p1, p2) => `<${p1} style="font-family: ${dynamicFontFamily};${p2 || ''}">`,
  );
  const renderCarouselItem = (item: LandingPageCarouselItem, _index: number) => {
    const contentType = detectContentType(item.src);
    const embedProps = {
      url: item.src,
      width: '300px',
      height: '600px',
      style: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
      },
    };

    const containerStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
    };

    switch (contentType) {
      case ContentTypeEnum.INSTAGRAM:
        return <div style={containerStyle}><InstagramEmbed {...embedProps} captioned={false} /></div>;
      case ContentTypeEnum.YOUTUBE:
        return <div style={containerStyle}><YouTubeEmbed {...embedProps} /></div>;
      case ContentTypeEnum.TIKTOK:
        return <div style={containerStyle}><TikTokEmbed {...embedProps} /></div>;
      case ContentTypeEnum.FACEBOOK:
        return <div style={containerStyle}><FacebookEmbed {...embedProps} /></div>;
      case ContentTypeEnum.PINTEREST:
        return <div style={containerStyle}><PinterestEmbed {...embedProps} /></div>;
      case ContentTypeEnum.IMAGE:
      default:
        return (
          <img
            src={item.src}
            alt="Featured content"
            className="w-full h-full object-cover"
          />
        );
    }
  };

  return (
    <div
      className="header-background"
      style={{
        backgroundImage: heroSectionBackgroundImage ?
          `url(${heroSectionBackgroundImage})` : 'none',
        backgroundColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="frame-wrapper page-width">
        <div className="frame-4">
          <div className="div-wrapper">
            <div className="frame-5">
              <div className={`${isFeaturedContentEnabled ? 'frame-6' : 'frame-6 !items-center'}`}>
                {creatorTag && creatorTag.showCreatorTag && (
                  <div id="creator-tag" className="frame ">
                    <div className="div" style={{ height: '100%' }}>
                      <div
                        id="creator-initial"
                        className="ellipse creator-initial"
                        style={{
                          background: landingPageStyles?.buttons?.solidButton?.backgroundColor, fontSize: landingPageStyles.typography?.body?.fontSize, color: landingPageStyles?.buttons?.solidButton?.labelColor, fontFamily: landingPageStyles.typography?.body?.fontStyle,
                        }}
                      >
                        {getInitials(creatorName || '')}
                      </div>
                    </div>
                    <div data-testid="creator-tag" id="loved-by-creator" className="loved-by-lokesh" style={{ fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                      {getMappedText(creatorTag.title || '', creatorName || '').toUpperCase()}
                    </div>
                  </div>
                )}
                <div className="frame-7">
                  <div className="frame-8">
                    <div className={`${isFeaturedContentEnabled ? 'frame-9' : 'frame-9 !items-center'}`}>
                      <div data-testid="offer-title" id="offer-title" className="text-wrapper-20" style={{ fontSize: landingPageStyles.typography?.headings?.fontSize, color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.headings?.fontStyle }}>
                        {offerTitle}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="offer-description" style={{ fontSize: landingPageStyles.typography?.body?.fontSize, color: landingPageStyles.typography?.body?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: wrappedOfferDescription }}
                  />
                </div>
              </div>
              <div className={`${isFeaturedContentEnabled ? '' : 'flex justify-center items-center'}`}>
                {(isFeaturedProductEnable && heroProductName) ? (
                  <div id="hero-feature-product" className="frame-10 ">
                    <div className="frame-11">
                      <img
                        id="hero-featured-product-image"
                        className="img"
                        src={heroImage || defaultImageUrl}
                        width="100"
                        height="100"
                      />
                      <div className="frame-12">
                        <div className="frame-13">
                          <div className="frame-14">
                            <div data-testid="hero-featured-product-name" id="hero-featured-product-name" className="text-wrapper-2" style={{ fontFamily: landingPageStyles.typography?.headings?.fontStyle, color: landingPageStyles.typography?.headings?.fontColor }}>
                              {heroProductName}
                            </div>
                          </div>
                          {(showProductRating) && (
                            <div className="frame-15">
                              <span id="hero-featured-product-rating" className="text-wrapper-4" style={{ color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                                {Array.from({ length: 5 }).map((_, index) =>
                                  (index < Number(heroProductRating) ? <StarFilled key={index} /> : <StarIcon key={index} />))}
                              </span>
                              <span id="hero-feature-product-reviews-count" style={{ color: landingPageStyles.typography?.body?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                                (
                                {heroProductReviews}
                                {' '}
                                reviews)
                              </span>
                            </div>
                          )}
                          <div className="frame-16">
                            <div id="hero-featured-product-price" className="text-wrapper-5" style={{ color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                              {currency}
                              {heroProductDiscountedPrice}
                              {' '}
                              <span className="product-discounted-price">
                                {currency}
                                {heroProductPrice}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {discountButtonText && discountButtonLink && (
                      <div data-testid="hero-featured-discount-button" id="discount-button-container" className="span" style={{ background: landingPageStyles?.buttons?.solidButton?.backgroundColor, borderRadius: landingPageStyles?.buttons?.solidButton?.cornerRadius }}>
                        <a
                          id="hero-featured-product-discount-button"
                          target="_blank"
                          href={heroProductUrl}
                          rel="noreferrer"
                          className="text-wrapper-7"
                        >
                          <span style={{ fontSize: landingPageStyles?.buttons?.solidButton?.labelSize, color: landingPageStyles?.buttons?.solidButton?.labelColor }}>{getMappedText(discountButtonText || '', offerDiscount || '')}</span>
                        </a>
                      </div>
                    )}
                  </div>
                ) : (discountButtonText && discountButtonLink && (
                  <div id="hero-feature-product" className="frame-10 !p-0" style={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>
                    <div data-testid="hero-featured-discount-button" id="discount-button-container" className="span" style={{ background: landingPageStyles?.buttons?.solidButton?.backgroundColor, borderRadius: landingPageStyles?.buttons?.solidButton?.cornerRadius }}>
                      <a
                        id="hero-featured-product-discount-button"
                        target="_blank"
                        href={heroProductUrl}
                        rel="noreferrer"
                        className="text-wrapper-7"
                      >
                        <span style={{ fontSize: landingPageStyles?.buttons?.solidButton?.labelSize, color: landingPageStyles?.buttons?.solidButton?.labelColor }}>{getMappedText(discountButtonText || '', offerDiscount || '')}</span>
                      </a>
                    </div>
                  </div>
                )
                )}
              </div>
            </div>
          </div>
          {(isFeaturedContentEnabled && !!carouselData.length) && (
            <div id="featured-content-carousel" className="frame-19">
              <div className="carousel-wrapper">
                <Carousel>
                  {carouselData.map((item, index) => (
                    <CarouselItem key={index}>
                      {renderCarouselItem(item, index)}
                    </CarouselItem>
                  ))}
                </Carousel>
              </div>
            </div>
          )}
          {(isFeaturedContentEnabled && carouselData.length === 0) && (
            <div id="featured-content-carousel" className="frame-19">
              <div className="carousel-wrapper flex items-center justify-center p-2" style={{ background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.1))' }}>
                <p className="text-grey-4 font-normal text-lg text-center" style={{ fontFamily: landingPageStyles.typography?.body?.fontStyle }}>Add Content to preview. Creators specific content will be visible in their CreatorStore directly.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
