import * as React from 'react';
import cx from 'classnames';
import {
  map,
  find,
  size,
  isEmpty,
  upperCase,
  isNil,
  includes,
  filter,
  isFunction,
  trim,
  some,
  noop,
  forEach,
} from 'lodash';
import { ApolloError } from 'apollo-client';
import { useHotkeys } from 'react-hotkeys-hook';
import { RawDraftContentState } from 'draft-js';

import { SubmitButton } from '@components';
import { Tooltip } from 'antd';

import { usePrevious } from '@frontend/utils/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { useAuth } from '@frontend/context/authContext';
import { useResourceContext } from '@frontend/app/context';
import { MessageTemplate } from '@frontend/app/hooks';
import { IResource } from '@frontend/app/hooks';

import { IEditor } from '@frontend/app/components';

import { useEventContext } from '@frontend/app/context/EventContext';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { EventName, logger } from '@common';

import {
  IContent,
  TContentType,
  useSendMessage,
  useSendBulkMessage,
  useUploadContent,
  useGetVariables,
} from '@frontend/app/hooks';
import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import { MessageType, SendMessageInput, BackupType } from '@frontend/app/types/globalTypes';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { Button } from '@revfluence/fresh';
import { PaperPlaneTopIcon } from '@revfluence/fresh-icons/regular/esm';
import { getMemberFieldValue } from '@frontend/app/utils';
import { IEmailEditorState } from './EmailEditor';
import { SendEmailForm } from './SendEmailForm';

import { reducer } from './attachments/reducer';
import { initialState, IAttachment } from './attachments/model';
import { actions } from './attachments/actions';
import { isTooLarge, sizeLimit } from './attachments/utils';

import styles from './EmailComposer.scss';

const {
 useState, useReducer, useEffect, useMemo, useRef, useImperativeHandle, useCallback,
} = React;
const MAX_RECIPIENTS_PER_BULK_MESSAGE = 450;

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
// this is a hack to check if rich text message is empty
const isMessageEmpty = (message: string) => !message || message === '<p></p>';

const reachedBulkLimit = (count: number) => count > MAX_RECIPIENTS_PER_BULK_MESSAGE;

const containsRealMembers = (members) => {
  if (!members) {
    return false;
  }

  const validMembers = filter(members, (m) => !!m.id && !isEmpty(m.email));
  return !isEmpty(validMembers);
};

const isUploadingAttachments = (contents: IContent[]) => {
  for (const content of contents) {
    const percentage = content.progress?.percentage;
    if (percentage > 0 && percentage < 100) {
      return true;
    }
  }

  return false;
};

const ANCHOR_REGEX = /<a [^<>]*href="[^<>"]+"[^<>]*>.*<\/a>/g;

interface IProps {
  onMessageSent?(): void | Promise<void>;
  beforeMessageSent?(): void | Promise<void>;
  customSend?(messageParams: SendMessageInput): void | Promise<void>;
  sendButtonText?: string;

  threadId?: string;
  messageId?: string;
  internalMessageId?: string;

  resourceId?: number;
  members?: IMember[];
  subject?: string;
  hideSubject?: boolean;
  subjectIsEditable?: boolean;
  className?: string;
  expandHeader?: boolean;
  initialMessage?: string | RawDraftContentState;
  currentValue?: string | RawDraftContentState;
  isInitialMessageHTML?: boolean;
  linkRequired?: boolean;
  previewOnly?: boolean;
  hasTopBorder?: boolean;
  replaceTemplateVariables?: boolean;
  hasFixedHeight?: boolean;
  initialAttachments?: IAttachment[];
  onSubjectChange?: (subject: string) => void;
  onEditorStateChange?: (content: IEmailEditorState) => void;
  onAdditionalCcStateChange?: (members: Partial<IMember>[]) => void;
  onAttachmentsChanged?: (contents: IAttachment[]) => void;

  // allow sending as separate emails
  allowSendingAsSeparate?: boolean;

  isReplyAll?: boolean; // is replyAll currently selected
  canReplyAll?: boolean; // display the selection to toggle to reply all

  // sending from members page
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;

  onMembersChange?: (members: IMember[]) => void;

  // trigger preview
  onPreview?(config: IPreviewConfig): void;
  onStatusUpdate?(status: { canSend: boolean; sending: boolean }): void;

  // used for event logs
  source?: string;

  // used for closing modal if no recipient is left
  onHideComposer?(): void;

  // used to hide send email button when email composer is integrated in bulk terms modal
  hideSendButton?: boolean;

  hideActions?: boolean;

  // notify parent
  onResourceIdChange?(resourceId: number): void;
  onChangeReplyAll?(isReplyAll: boolean): void;

  additionalVariables?: { [key: string]: { label: string } };

  onClose?: () => void;
  isSourceEditOffer?: boolean;
  disableEmailInput?: boolean;
  enableExpandingEmailEditor?: boolean;
  isHeaderForcedClose?: boolean;
}

export interface IEmailComposer {
  sendMessage(bulk?: boolean, excludeMemberIds?: readonly number[]): Promise<unknown>;
  reviewMessage(): void;
  backToEdit(): void;
}

export interface IPreviewConfig {
  resource: IResource;
  subject: string;
  editorState: IEmailEditorState;

  members?: IMember[];
}

/**
 * @type {React.FunctionComponent}
 */
const _EmailComposer: React.RefForwardingComponent<IEmailComposer, IProps> = (props, ref) => {
  const {
    hasFixedHeight = false,
    hasTopBorder = false,
    members: initialMembers,
    onEditorStateChange,
    onSubjectChange,
    onChangeReplyAll,
    isReplyAll = false,
    canReplyAll = false,
    currentValue,
    hideSendButton,
    onResourceIdChange,
    onAdditionalCcStateChange,
    onMembersChange = noop,
    onAttachmentsChanged,
    additionalVariables = {},
    customSend,
    isSourceEditOffer,
    disableEmailInput,
    enableExpandingEmailEditor,
    isHeaderForcedClose,
    initialAttachments,
    hideActions,
  } = props;

  const { user } = useAuth();
  const { showMessage, showError } = useMessagingContext();
  const addEvent = useEventContext();

  // TODO - the PFA using proxy so need to pass via context
  const productFulfillmentContext = useProductFulfillmentContext();
  const pfaVariables = productFulfillmentContext?.usePfaContextVariables && productFulfillmentContext?.variables;
  const { variables: queryVariables } = useGetVariables({ skip: !!pfaVariables });
  const [isEditorExpanded, setIsEditorExpanded] = useState<boolean>(false);
  const [isCallbackLoading, setIsCallbackLoading] = useState<boolean>(false);

  const variables = useMemo(() => {
    if (pfaVariables) {
      return {
        ...pfaVariables,
        additionalVariables,
      };
    }
    return {
      ...queryVariables,
      additionalVariables,
    };
  }, [pfaVariables, queryVariables, additionalVariables]);

  const { activeEmailResources: resources } = useResourceContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const editorRef = useRef<IEditor>(null);
  const { contents } = state;

  const { content, upload: uploadContent, error } = useUploadContent({
    serviceName: 'message',
    isTemp: true,
  });

  useEffect(() => {
    if (content && content.progress) {
      dispatch(actions.updateProgress(content.id, content.progress));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content && content.progress]);

  useEffect(() => {
    forEach(initialAttachments, (attachment) => {
      dispatch(actions.addContent(attachment));
    });
  }, [initialAttachments]);

  useEffect(() => {
    if (content && content.fileUrl) {
      dispatch(actions.updateFileUrl(content.id, content.fileUrl));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content && content.fileUrl]);

  useEffect(() => {
    if (isFunction(onAttachmentsChanged)) {
      onAttachmentsChanged(contents);
    }
  }, [contents, onAttachmentsChanged]);

  useEffect(() => {
    if (content && content.id) {
      dispatch(actions.addContent(content));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content && content.id]);

  useEffect(() => {
    if (error && content) {
      dispatch(actions.removeContent(content.id));

      // Don't show any error if upload is aborted
      if (!error.isUploadAborted) {
        dispatch(actions.setErrorMessage(error.message));
      }
    }
  }, [error, content]);

  useHotkeys(
    'enter',
    () => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    },
    [editorRef],
  );

  const deleteContents = () => {
    dispatch(actions.removeContents());
  };
  const onDeleteAttachment = (id: string) => {
    dispatch(actions.removeContent(id));
  };

  const handleSelectedFilesChange = async (files: FileList) => {
    const configs = Array.from(files).map((file) => ({
      file,
      type: file.type.split('/')[0] as TContentType,
    }));
    // Upload content one at a time beacuse currently
    // uploadContent do not allow parallelization
    for (const config of configs) {
      await uploadContent(config.file, config.type);
    }
  };

  const { sendMessage, loading: sendingMessage } = useSendMessage({
    async onCompleted() {
      if (isFunction(props.onMessageSent)) {
        try {
          setIsCallbackLoading(true);
          await props.onMessageSent();
          setIsCallbackLoading(false);
        } catch (e) {
          logger.error('Error during onMessageSent callback', e);
          showMessage({
            type: 'warning',
            content: 'There was an issue during this operation. Please contact support.',
          });
          setIsCallbackLoading(false);
        }
      }

      showMessage({
        type: 'info',
        content: 'Message sent.',
      });

      if (props.threadId) {
        setEditorState(null);
        editorRef.current?.reset();

        deleteContents();
      }
      if (props.memberCount || props.memberQuery || size(initialMembers) > 1) {
        addEvent(EventName.CompleteBulkSendMessage, {
          member_count: size(initialMembers),
          attachments: size(contents),
          length: size(editorState?.html),
          as_one_message: false,
          source: props.source,
        });
      }
    },
    onError(error: ApolloError) {
      showError(error);
    },
  });

  const { sendBulkMessage, loading: sendingBulkMessage } = useSendBulkMessage({
    async onCompleted() {
      if (isFunction(props.onMessageSent)) {
        try {
          setIsCallbackLoading(true);
          await props.onMessageSent();
          setIsCallbackLoading(false);
        } catch (e) {
          logger.error('Error during onMessageSent callback', e);
          showMessage({
            type: 'warning',
            content: 'There was an issue during this operation. Please contact support.',
          });
          setIsCallbackLoading(false);
        }
      }
      if (isSourceEditOffer) {
        showMessage({
          type: 'success',
          content: 'Members Notified',
        });
      } else {
        showMessage({
          type: 'info',
          content: 'Messages are sending. This may take a few minutes.',
        });
      }
      if (props.threadId) {
        setEditorState(null);

        editorRef.current?.reset();

        deleteContents();
      }
      if (props.memberCount || props.memberQuery || size(initialMembers) > 1) {
        addEvent(EventName.CompleteBulkSendMessage, {
          member_count: size(initialMembers),
          attachments: size(contents),
          length: size(editorState?.html),
          as_one_message: true,
          source: props.source,
        });
      }
    },
    onError(error: ApolloError) {
      showError(error);
    },
  });

  const sendButtonLoading = sendingMessage || sendingBulkMessage || isCallbackLoading;
  const [additionalCc, setAdditionalCc] = useState<Partial<IMember>[]>([]);
  const [members, setMembers] = useState<IMember[]>(initialMembers);
  const [resourceId, setResourceId] = useState<number>(null);
  const previousResourceId = usePrevious(props.resourceId);
  const [subject, setSubject] = useState(props.subject || '');
  const [editorState, setEditorState] = useState<IEmailEditorState>(null);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const sendWithMemberQuery = useMemo(
    () => isEmpty(members) && !isNil(props.memberCount) && !isNil(props.memberQuery),
    [members, props.memberCount, props.memberQuery],
  );
  const hasVariable = useMemo(() => !!find(editorState?.raw?.entityMap, (value) => value.type === 'variable'), [
    editorState,
  ]);
  const hasLink = useMemo(() => !!editorState?.html?.match(ANCHOR_REGEX), [editorState]);
  const resource = useMemo(() => find(resources, (resource) => resource.id === resourceId), [resources, resourceId]);
  useEffect(() => {
    if (initialMembers) {
      setMembers(initialMembers);
    }
  }, [initialMembers]);

  const signaturePreview = useMemo(() => {
    switch (resource?.config.signature?.mode) {
      case 'html':
        return resource.config.signature?.fromHTML;
      case 'editor':
        return resource.config.signature?.fromEditor;
    }
  }, [resource]);

  const backupPlansRemoveInvalid = filter(editorState?.backupPlans, (p) => p.backupType === BackupType.REMOVE_INVALID);
  const validMembers = useMemo(() => {
    const membersWithValidPlans = isEmpty(backupPlansRemoveInvalid)
      ? members
      : filter(members, (member) => {
          for (const plan of backupPlansRemoveInvalid) {
            const field = variables?.member[plan.field]?.field;
            const fieldValue = getMemberFieldValue(member, field);

            if (isNil(fieldValue) || isEmpty(fieldValue)) {
              return false;
            }
          }

          return true;
        });

    return filter(membersWithValidPlans, (m) => !isEmpty(m.email));
  }, [backupPlansRemoveInvalid, members, variables]);

  const canSend = resourceId !== null
    && (sendWithMemberQuery || containsRealMembers(members))
    && !reachedBulkLimit(sendWithMemberQuery ? props.memberCount : size(members))
    && subject
    && !isMessageEmpty(editorState?.html)
    && !editorState?.html?.match(/\[Replace with [^\]]*\]/i)
    && !isUploadingAttachments(contents)
    && (!props.linkRequired || hasLink);

    const tooltipMessage = resourceId === null
    ? 'Please choose which email to send your message from.'
    : !(sendWithMemberQuery || containsRealMembers(members))
      ? 'Please add the recipient as a member first. You can only send emails to members.'
      : reachedBulkLimit(sendWithMemberQuery ? props.memberCount : size(members))
        ? `Due to GMail/Outlook limits, messages are limited to ${MAX_RECIPIENTS_PER_BULK_MESSAGE} recipients.`
        : !subject
          ? 'Subject cannot be empty.'
          : isMessageEmpty(editorState?.html)
            ? 'Please enter a message body.'
            : isUploadingAttachments(contents)
              ? 'Please wait for attachments to be uploaded.'
              : '';

  const onSend = async (bulk?: boolean, excludeMemberIds: number[] = []) => {
    if (!resource) {
      return;
    }

    const hasTooLargeAttachments = some(contents, (content) => isTooLarge(content.size, resource.type));
    if (hasTooLargeAttachments) {
      showMessage({
        type: 'warning',
        content: `The file selected exceeds the supported file size limit for email. Please limit file size to under ${sizeLimit(
          resource.type,
        )}.`,
      });
      return;
    }

    if (isFunction(props.beforeMessageSent)) {
      try {
        setIsCallbackLoading(true);
        await props.beforeMessageSent();
        setIsCallbackLoading(false);
      } catch (e) {
        logger.error('Error during beforeMessageSent callback', e);
        showMessage({
          type: 'warning',
          content: e.message,
        });
        setIsCallbackLoading(false);
        return;
      }
    }

    addEvent(EventName.ActOnThread, { threadType: resource.type, actionType: 'sendMessage' });

    let message = editorState?.html;
    if (resource.config.signature?.mode === 'html' && !!resource.config.signature?.fromHTML) {
      message = trim(`${message}<div><br></div>${resource.config.signature?.fromHTML.replace(/\n/g, '<br />')}`);
    } else if (resource.config.signature?.mode === 'editor' && !!resource.config.signature?.fromEditor) {
      message = trim(`${message}<div><br></div>${resource.config.signature?.fromEditor}`);
    }

    const isNewSender = previousResourceId && previousResourceId != resourceId;

    const params: SendMessageInput = {
      threadId: isNewSender ? null : props.threadId,
      messageId: isNewSender ? null : props.messageId,
      internalMessageId: isNewSender ? null : props.internalMessageId,
      resourceId,
      type: upperCase(resource.type) as MessageType,
      subject,
      message,
      backupPlans: editorState?.backupPlans,
      attachments: map(contents, (content) => ({
        href: content.fileUrl,
        type: content.type,
        size: content.size,
        filename: content.name,
        filenameInStorage: content.uuid,
      })),
      user,
      additionalCc: additionalCc.map((member) => member.email),
    };

    if (sendWithMemberQuery) {
      params.membersSearchQuery = {
        ...props.memberQuery,
        excludeMemberIds,
      };
    } else {
      const filteredValidMembers = filter(
        validMembers,
        (m) =>
          !includes(excludeMemberIds, m.id)
          && !includes(
            map(additionalCc, (r) => r.email),
            m.email,
          ),
      );
      params.members = map(filteredValidMembers, (member) => ({ id: isNaN(Number(member.id)) ? member.id : Number(member.id), email: member.email }));
    }

    if (isFunction(customSend)) {
      try {
        setIsCallbackLoading(true);
        await customSend(params);
        setIsCallbackLoading(false);
      } catch (e) {
        logger.error('Error during customSend callback', e);
        showMessage({
          type: 'warning',
          content: e.message,
        });
        setIsCallbackLoading(false);
      }
      return;
    }
    const sendMethod = bulk ? sendBulkMessage : sendMessage;

    return sendMethod({
      variables: {
        message: params,
      },
    });
  };

  const sendButtonCount = (memberCount: number): React.ReactElement[] => {
    if (hideSendButton) {
      return null;
    }
    const sendMessageButton = (
      <Tooltip
        key="submit"
        overlayStyle={{
          display: canSend ? 'none' : undefined,
        }}
        title={tooltipMessage}
      >
        <Button
          key="submit"
          type={memberCount < 2 || !props.allowSendingAsSeparate ? 'primary' : 'text'}
          onClick={() => onSend(false)}
          disabled={!canSend || sendButtonLoading}
          loading={sendButtonLoading}
        >
          <PaperPlaneTopIcon />
          {sendButtonLoading
            ? 'Sending...'
            : props.sendButtonText || (props.allowSendingAsSeparate ? 'Send as 1 Email' : 'Send')}
        </Button>
      </Tooltip>
    );
    if (memberCount < 2 || !props.allowSendingAsSeparate) {
      return [sendMessageButton];
    }

    return [
      sendMessageButton,
      <Tooltip
        key="submitBulk"
        overlayStyle={{
          display: canSend ? 'none' : undefined,
        }}
        title={tooltipMessage}
      >
        <SubmitButton
          key="submitBulk"
          onClick={() => onSend(true)}
          className={styles.bulkComposeButton}
          theme="primary"
          disabled={!canSend || sendButtonLoading}
          isSubmitting={sendButtonLoading}
          label={props.sendButtonText || `Send as ${sendWithMemberQuery ? props.memberCount : size(members)} Emails`}
          submittingLabel="Sending..."
        />
      </Tooltip>,
    ];
  };

  useImperativeHandle(ref, () => ({
    sendMessage: onSend,
    reviewMessage: () => {
      props.onPreview({
        resource,
        subject,
        editorState,
        members,
      });
      setPreviewVisible(true);
    },
    backToEdit: () => {
      props.onPreview(null);
      setPreviewVisible(false);
    },
  }));

  useEffect(() => {
    if (props.onStatusUpdate) {
      props.onStatusUpdate({
        canSend,
        sending: sendingMessage || sendingBulkMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSend, sendingMessage, sendingBulkMessage]);

  const handleSubjectChange = useCallback(
    (subject: string) => {
      // Do not update subject if this thread already exist
      // otherwise we will create a new thread
      if (props.threadId) {
        return;
      }
      setSubject(subject);
      if (isFunction(onSubjectChange)) {
        onSubjectChange(subject);
      }
    },
    [onSubjectChange, setSubject, props.threadId],
  );

  const handleTemplateSelected = useCallback(
    (template: MessageTemplate) => {
      handleSubjectChange(template.subject);
      forEach(template.attachments, (attachment) => {
        dispatch(actions.addContent(attachment));
      });
    },
    [handleSubjectChange],
  );

  const handleChangeEditorState = useCallback(
    (editorStateContent: IEmailEditorState) => {
      setEditorState(editorStateContent);
      if (onEditorStateChange) {
        onEditorStateChange(editorStateContent);
      }
    },
    [onEditorStateChange],
  );

  // additionalCc can also be a raw email that is not a member
  const handleChangedAdditionalCc = useCallback(
    (additionalCc: Partial<IMember>[]) => {
      setAdditionalCc(additionalCc);
      if (isFunction(onAdditionalCcStateChange)) {
        onAdditionalCcStateChange(additionalCc);
      }
    },
    [onAdditionalCcStateChange],
  );

  const handleResourceIdChange = useCallback(
    (resourceId: number) => {
      setResourceId(resourceId);
      if (isFunction(onResourceIdChange)) {
        onResourceIdChange(resourceId);
      }
    },
    [onResourceIdChange],
  );

  useEffect(() => {
    if (!isNil(initialMembers) && size(members) === 0) {
      if (isFunction(props.onHideComposer)) {
        props.onHideComposer();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, initialMembers]);

  const toggleExpandEditor = useCallback(() => {
    setIsEditorExpanded((prev) => !prev);
  }, [setIsEditorExpanded]);

  return (
    <div
      className={cx(styles.EmailComposer, props.className, {
        [styles.editorExpanded]: isEditorExpanded,
      })}
    >
      <SendEmailForm
        expandHeader={props.expandHeader}
        additionalVariables={props.additionalVariables}
        resourceId={props.resourceId}
        hasTopBorder={hasTopBorder}
        hasFixedHeight={hasFixedHeight}
        validMembers={validMembers}
        members={initialMembers}
        memberCount={props.memberCount}
        memberQuery={props.memberQuery}
        sendWithMemberQuery={sendWithMemberQuery}
        onAttachmentsSelected={handleSelectedFilesChange}
        attachments={contents}
        resourceType={resource?.type}
        onDeleteAttachment={onDeleteAttachment}
        editorRef={editorRef}
        disabled={sendingMessage}
        actionsDisabled={!canSend || sendButtonLoading}
        disableEmailInput={disableEmailInput}
        initialValue={props.initialMessage}
        currentValue={currentValue}
        isInitialMessageHTML={props.isInitialMessageHTML}
        signaturePreview={signaturePreview}
        subject={props.subject}
        hideSubject={props.hideSubject}
        subjectIsEditable={props.subjectIsEditable}
        onResourceIdChange={handleResourceIdChange}
        enableExpandingEmailEditor={enableExpandingEmailEditor}
        toggleExpandEditor={toggleExpandEditor}
        onMembersChange={(members) => {
          setMembers(members);
          onMembersChange(members);
        }}
        onAdditionalCcChange={handleChangedAdditionalCc}
        onChangeReplyAll={onChangeReplyAll}
        isReplyAll={isReplyAll}
        canReplyAll={canReplyAll}
        clearBackupPlans={editorState?.clearBackupPlans}
        onSubjectChange={handleSubjectChange}
        onEditorStateChange={handleChangeEditorState}
        enableVariableReplacement={isFunction(props.onPreview)}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        enableMessageTemplate
        replaceTemplateVariables={props.replaceTemplateVariables}
        onTemplateSelected={handleTemplateSelected}
        onClose={props.onClose}
        isHeaderForcedClose={isHeaderForcedClose}
        actions={
          hideActions
            ? []
            : hasVariable || props.previewOnly
            ? [
              <Tooltip
                key="submit"
                overlayStyle={{
                    display: canSend ? 'none' : undefined,
                  }}
                title={tooltipMessage}
              >
                <Button
                  key="submit"
                  type="primary"
                  loading={sendButtonLoading}
                  disabled={!canSend || sendButtonLoading}
                  onClick={() => {
                      props.onPreview({
                        resource,
                        subject,
                        editorState,
                        members,
                      });
                      setPreviewVisible(true);
                    }}
                >
                  {sendButtonLoading ? 'Sending...' : 'Next'}
                </Button>
              </Tooltip>,
              ]
            : sendButtonCount(sendWithMemberQuery ? props.memberCount : size(members))
        }
      />
    </div>
  );
};

export const EmailComposer = React.forwardRef(_EmailComposer);
