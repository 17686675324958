import * as React from 'react';

import { Form } from '@revfluence/fresh';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { FormAsyncAction, IShopifyFormElementProps, OfferFormAsyncActions } from '../../../../types';

interface IProps extends IShopifyFormElementProps<'specialLimitNewCustomersOnly'> {
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  value: boolean;
  disabled: boolean;
}

export const RefreshOfferCodeLimitNewCustomersOnly: React.FC<Readonly<IProps>> = React.memo(({
  name,
  handleFormAsyncActions,
  value,
  disabled,
}) => {
  const [isChecked, setIsChecked] = React.useState(value);

  const onCheckChanges = (value: boolean) => {
    setIsChecked(value);
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value },
    });
  };
  return (
    <Form.Item className="!m-0 flex items-center gap-2" name={name} valuePropName="checked">
      <div className="flex items-center gap-2">
        <Checkbox
          id="limitNewCustomer"
          checked={isChecked}
          onCheckedChange={(checked) => onCheckChanges(checked === true)}
          disabled={disabled}
        />
        <label
          htmlFor="limitNewCustomer"
          className="text-sm font-medium text-primary pointer-events-none"
        >
          Limit usage to new customers only
        </label>
      </div>
    </Form.Item>
  );
});

RefreshOfferCodeLimitNewCustomersOnly.displayName = 'RefreshOfferCodeLimitNewCustomersOnly';
