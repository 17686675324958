import * as React from 'react';
import {
 Upload,
 Modal,
 Typography,
 Tooltip,
} from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import { PlusIcon, FileVideoIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  EyeOutlined,
} from '@ant-design/icons';
import {
  FileTypePdf,
  FileTypeDoc,
} from '@components';

import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import { useUploadContent, TContentType } from '@frontend/app/hooks';
import { getBase64 } from '../../BulkTerms/utils';
import styles from './PostAttachments.scss';

const {
  useState,
  useCallback,
  useEffect,
  useMemo,
} = React;
const { Title } = Typography;

interface IProps {
  id?: number;
  attachments: Array<Attachment>;
  onAddAttachment: (
    attachment: Attachment,
    id: number,
  ) => void;
  onRemoveAttachment: (
    attachments: Array<Attachment>,
    id: number,
  ) => void;
}

interface ISettings {
  previewVisible: boolean;
  preview: string;
  previewTitle: string;
  type: string;
}

const isImage = (type: string): boolean => type.split('/')[0] === 'image';
const isVideo = (type: string): boolean => type.split('/')[0] === 'video';
const isFile = (type: string): boolean => type.split('/')[0] === 'application';
const isPdf = (type: string): boolean => type.split('/')[1] === 'pdf';
const isDoc = (type: string): boolean => type.split('/')[1] === 'doc' || type.split('/')[1] === 'docx';

type UploadFile = Attachment & { status: string, name?: string };

const PostAttachments: React.FC<IProps> = React.memo((props) => {
  const {
    attachments,
    onAddAttachment,
    onRemoveAttachment,
    id,
  } = props;
  const [settings, setSettings] = useState<ISettings>({
    previewVisible: false,
    preview: '',
    previewTitle: '',
    type: '',
  });

  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (isEmpty(attachments)) {
      setFiles([]);
      return;
    }
    const uploadFiles: UploadFile[] = attachments.map((attachment: Attachment) => ({
      ...attachment,
      status: 'done',
      response: '',
    }));
    setFiles(uploadFiles);
  }, [attachments]);

  const {
    content,
    upload: uploadContent,
    isUploading,
    error,
  } = useUploadContent({
    serviceName: 'message',
    isTemp: true,
    keepFileName: true,
  });

  useEffect(() => {
    if (!isUploading && error) {
      setFiles((prevState) => (
        [
          ...prevState,
          {
            __typename: 'GuidelineAttachment',
            filename: '',
            url: '',
            type: '',
            status: 'error',
            name: 'Upload Error',
          },
        ]
      ));
    }
  }, [error, isUploading]);

  useEffect(() => {
    if (!isUploading && content) {
      const { fileUrl: url, name: filename, type } = content;
      onAddAttachment(
      {
        url,
        filename,
        type: type.concat('/').concat(filename.split('.').pop()),
        __typename: 'GuidelineAttachment',
      },
      id,
    );
    }
  },
  [
    onAddAttachment,
    id,
    content,
    isUploading,
  ]);

  const handleCancel = useCallback(() => setSettings((prevState) => ({
    ...prevState,
    previewVisible: false,
  })), []);

  const handlePreview = useCallback(async (file) => {
    let preview;
    if (!file.url && !file.preview && file.originFileObj) {
      preview = await getBase64(file.originFileObj);
    }
    setSettings((prevState) => ({
      ...prevState,
      preview: file.url || preview,
      previewVisible: true,
      previewTitle:
      file.name || file.filename,
      type: file.type,
    }));
  }, []);

  const getFileContentType = useCallback((fileType: string): TContentType => {
    if (isVideo(fileType)) {
      return 'video';
    }
    if (isImage(fileType)) {
      return 'image';
    }
    return 'application';
  }, []);

  const handleChange = useCallback(({ file, fileList }) => {
    if (file.status === 'removed') {
      const attachmentFiles: Attachment[] = fileList.map((uploadFile) => ({
        filename: uploadFile.filename,
        url: uploadFile.url,
        type: uploadFile.type,
      }));
      onRemoveAttachment(attachmentFiles, id);
    } else {
      uploadContent(file, getFileContentType(file.type));
    }
  }, [onRemoveAttachment, uploadContent, getFileContentType, id]);

  const {
    previewVisible,
    preview,
    previewTitle,
    type,
  } = settings;

  const uploadedFile = useMemo(() => {
    if (isImage(type)) {
      return <img alt={previewTitle} style={{ width: '100%' }} src={preview} />;
    }
    return null;
  },
  [
    preview,
    type,
    previewTitle,
  ]);

  const shouldPreviewUploadedFile = useMemo(
    () => isImage(type) || (content?.type && isImage(content?.type)),
    [type, content?.type],
  );

  const uploadButton = (
    <div>
      <PlusIcon className="ant-upload-select-icon" />
      <p className="ant-upload-text">Upload</p>
    </div>
  );

  return (
    <>
      <Title
        level={5}
        style={{ fontSize: '14px' }}
      >
        Attachments
      </Title>
      <Upload
        className={styles.uploadBox}
        listType="picture-card"
        accept=".pdf,.doc,.docx,image/*,video/*"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        fileList={files}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false}
        showUploadList={{
          previewIcon: (file: UploadFile) => {
            if (isImage(file.type)) {
              return <EyeOutlined />;
            }
            return null;
          },
          showRemoveIcon: true,
          showDownloadIcon: true,
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        itemRender={
          (
            item: React.ReactElement,
            file: UploadFile,
          ) => <Tooltip title={file.filename}>{item}</Tooltip>
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        iconRender={
          (file: UploadFile) => {
            if (isVideo(file.type)) return <FileVideoIcon className={styles.black} />;
            if (isFile(file.type) && isPdf(file.type)) return <FileTypePdf className={styles.black} />;
            if (isFile(file.type) && isDoc(file.type)) return <FileTypeDoc className={styles.black} />;
            if (file.status === 'error') return <TriangleExclamationIcon />;
            return null;
          }
        }
      >
        { uploadButton }
      </Upload>
      { shouldPreviewUploadedFile && (
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          {uploadedFile}
        </Modal>
      )}
    </>
  );
});

PostAttachments.displayName = 'PostAttachments';

export default PostAttachments;
