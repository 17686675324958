import * as React from 'react';
import { BookText, FileText, Flag, Globe, Plus, Radio } from 'lucide-react';
import { Form, Drawer } from 'antd';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormValues,
  OfferFormAsyncActions,
} from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { RefreshUtmFields } from './RefreshUtmFields';
import { RefreshUtmCustomFields } from './RefreshUtmCustomFields';

interface UtmSheetProps {
  values: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (action: FormAsyncAction) => void;
  isOpen: boolean;
  onClose: () => void;
}

export function UtmSheet({ values, handleFormAsyncActions, isOpen, onClose }: UtmSheetProps) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsDrawerOpen(false);
    onClose();
  };

  const checkAdvancedUrlEnabled = () => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isAdvancedUrlEnabled', value: true },
    });
  };

  const generateUtmEvents = (key: string) => ({
    onBlur: () => { /* empty */ },
    onFocus: () => { /* empty */ },
    onMouseEnter: () => { /* empty */ },
    onMouseLeave: () => { /* empty */ },
    onChange: (value: string) => { handleUtmFieldChange(key, value); checkAdvancedUrlEnabled(); },
  });

  const handleUtmFieldChange = (key: string, value: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: {
        key,
        value,
      },
    });
  };

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={handleClose} title="Add UTMs to Link" width={600}>
        <div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-grey-4 font-medium-75-labels">Parameters</span>
            <span className="text-grey-4 font-medium-75-labels w-[232px]">Values</span>
            <span className="text-grey-4 font-medium-75-labels">Insert Variable</span>
          </div>
          <Form.Item noStyle>
            <div className="flex flex-col gap-4">
              <RefreshUtmFields
                name="advanceUrlSettingType"
                label="Source"
                keyName="utmSource"
                events={generateUtmEvents('utmSource')}
                onChange={checkAdvancedUrlEnabled}
                icon={<Globe className="h-4 w-4" />}
              />
              <RefreshUtmFields
                name="advanceUrlSettingType"
                label="Medium"
                keyName="utmMedium"
                events={generateUtmEvents('utmMedium')}
                onChange={checkAdvancedUrlEnabled}
                icon={<Radio className="h-4 w-4" />}
              />
              <RefreshUtmFields
                name="advanceUrlSettingType"
                label="Campaign"
                keyName="utmCampaign"
                events={generateUtmEvents('utmCampaign')}
                onChange={checkAdvancedUrlEnabled}
                icon={<Flag className="h-4 w-4" />}
              />
              <RefreshUtmFields
                name="advanceUrlSettingType"
                label="Content"
                keyName="utmContent"
                events={generateUtmEvents('utmContent')}
                onChange={checkAdvancedUrlEnabled}
                icon={<BookText className="h-4 w-4" />}
              />
              <RefreshUtmFields
                name="advanceUrlSettingType"
                label="Term"
                keyName="utmTerm"
                events={generateUtmEvents('utmTerm')}
                onChange={checkAdvancedUrlEnabled}
                icon={<FileText className="h-4 w-4" />}
              />
              <Form.List name="customUTMParameters" initialValue={values.customUTMParameters}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, index) => (
                      <RefreshUtmCustomFields
                        name="advanceUrlSettingType"
                        key={key}
                        customName={name}
                        disabled={false}
                        events={generateUtmEvents('utmTerm')}
                        index={index}
                        remove={remove}
                      />
                    ))}
                    {fields.length < 3 ? (
                      <Form.Item name="button">
                        <Button
                          type="button"
                          variant="ghost"
                          className="h-auto p-0 text-grey-6 font-medium-100"
                          onClick={() => add({ key: '', value: '' })}
                          disabled={values.isSecureCodes}
                        >
                          <Plus className="h-3 w-3 mr-2" />
                          <span className="underline">Add Custom UTM</span>
                        </Button>
                        <div className="text-sm text-grey-4 mt-2">You can add up to 3 custom parameters</div>
                      </Form.Item>
                    ) : (
                      <div>You can add up to 3 custom parameters only</div>
                    )}
                  </>
                )}
              </Form.List>
            </div>
          </Form.Item>
        </div>
      </Drawer>
    </>
  );
}
