import * as React from 'react';

import { Form } from '@revfluence/fresh';
import { Textarea } from '@frontend/shadcn/components/ui/textarea';
import { MinusIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { OfferInputHeaderWithBtn } from './OfferElementHeader';
import { FormAsyncAction, ICommonFormElementProps, OfferFormAsyncActions } from '../../../../types';

interface IProps extends ICommonFormElementProps<'description'> {

  value: string;
  handleFormAsyncActions?: (value: FormAsyncAction) => void,

}

export const RefreshOfferDescription: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  handleFormAsyncActions,
}) => {
  const [showDescription, setShowDescription] = React.useState(!!value);
  const onChange = () => {
    const newValue = showDescription ? '' : value;
    setShowDescription(!showDescription);

    handleFormAsyncActions?.({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value: newValue },
    });
  };
  return (
    <>
      <OfferInputHeaderWithBtn text={showDescription ? 'Remove Description' : 'Add Description'} onClick={onChange} icon={showDescription ? <MinusIcon /> : <PlusIcon />} className="mt-2 mb-4" />
      {showDescription && (
        <Form.Item name={name}>
          <Textarea
            disabled={disabled}
            data-testid="Enter Description"
            className="w-full rounded-lg"
          />
        </Form.Item>
      )}
    </>
  );
});
RefreshOfferDescription.displayName = 'RefreshOfferDescription';
