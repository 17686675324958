import { useCallback } from 'react';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ListItem } from '@frontend/app/refresh-components/DraggableList';
import { ColumnDefinitionInput } from '../../table';

interface UseColumnDialogConfig {
  selectedColumns: ColumnDefinitionInput[];
  setSelectedColumns: (
    columns: ColumnDefinitionInput[] | ((prev: ColumnDefinitionInput[]) => ColumnDefinitionInput[]),
  ) => void;
  onSave: (columns: ColumnDefinitionInput[]) => Promise<void>;
}

// Helper function to recursively toggle all children
const toggleAllChildren = (subColumns: ColumnDefinitionInput[], hidden: boolean): ColumnDefinitionInput[] => {
  return subColumns.map((sub) => ({
    ...sub,
    hidden,
    subColumns: sub.subColumns ? toggleAllChildren(sub.subColumns, hidden) : sub.subColumns,
  }));
};

export const useColumnDialog = ({ selectedColumns, setSelectedColumns, onSave }: UseColumnDialogConfig) => {
  const toggleColumnVisibility = useCallback(
    (columns: ColumnDefinitionInput[], id: string, parentVisible: boolean = true): ColumnDefinitionInput[] => {
      return columns.map((col) => {
        if (col.id === id) {
          const newHidden = !col.hidden;

          return {
            ...col,
            hidden: newHidden,
            subColumns: col.subColumns ? toggleAllChildren(col.subColumns, newHidden) : col.subColumns,
          };
        }

        if (col.subColumns) {
          const updatedSubColumns: ColumnDefinitionInput[] = toggleColumnVisibility(col.subColumns, id, !col.hidden);

          // Ensure parent is visible if any child is visible
          const anyChildVisible = updatedSubColumns.some((sub) => !sub.hidden);

          return {
            ...col,
            hidden: anyChildVisible ? false : col.hidden,
            subColumns: updatedSubColumns,
          };
        }

        return {
          ...col,
          hidden: parentVisible ? col.hidden : true, // Inherit visibility from parent
        };
      });
    },
    [],
  );

  const toggleAllColumnsVisibility = useCallback(
    (columns: ColumnDefinitionInput[], shouldShow: boolean): ColumnDefinitionInput[] => {
      const toggleColumns = (cols: ColumnDefinitionInput[]): ColumnDefinitionInput[] => {
        return cols.map((col) => ({
          ...col,
          hidden: !col.isFixed && !shouldShow,
          subColumns: col.subColumns ? toggleColumns(col.subColumns) : col.subColumns,
        }));
      };
      return toggleColumns(columns);
    },
    [],
  );

  const onToggleColumnCheckBox = useCallback(
    (id: string) => {
      setSelectedColumns((prevColumns: ColumnDefinitionInput[]) => toggleColumnVisibility(prevColumns, id));
    },
    [setSelectedColumns, toggleColumnVisibility],
  );

  const onToggleSelectall = useCallback(
    (checked: CheckedState) => {
      setSelectedColumns((prevColumns: ColumnDefinitionInput[]) =>
        toggleAllColumnsVisibility(prevColumns, checked === true),
      );
    },
    [setSelectedColumns, toggleAllColumnsVisibility],
  );

  const handleDragEndModalColumn = useCallback(
    (items: ListItem<ColumnDefinitionInput>[]) => {
      if (items.length !== selectedColumns.length) {
        // the underlying component calls itemChanged on itemRemoved and i hate it, this is the only way to determine the difference
        return;
      }
      setSelectedColumns((prevColumns: ColumnDefinitionInput[]) => {
        // Extract the reordered visible columns
        const updatedVisibleColumns = items.map((item) => item.data);

        // Maintain the original positions of hidden columns
        let visibleIndex = 0;
        const updatedColumns = prevColumns.map((col: ColumnDefinitionInput) => {
          if (col.hidden) {
            return col;
          }
          return updatedVisibleColumns[visibleIndex++];
        });

        return updatedColumns;
      });
    },
    [selectedColumns, setSelectedColumns],
  );

  return {
    columnDialogProps: {
      selectedColumns,
      handleDragEndModalColumn,
      onToggleColumnCheckBox,
      onSave,
      onToggleSelectall,
    },
  };
};
