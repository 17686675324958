import * as React from 'react';
import { LineChartROI } from '@frontend/app/components/Charts/components/ROI/LineChart';
import Card from '@frontend/applications/ReportsApp/components/Card';
import { ChartLineUpIcon, CommentDollarIcon } from '@revfluence/fresh-icons/regular/esm';
import {
 HowItsCalculated, IInvestmentValues, IReportingPageProps, ReportingLayout,
} from '../../Reporting';
import { Label } from '../../../../hooks';
import { AlertError } from '../../../Home/components/HomeError/HomeError';
import MetricsSecondary from '../MetricsSecondary';
import MetricsTabs from '../MetricsTabs';

const emptyTrend = {
  percentDiff: null,
  value: 0,
};

const emptyValue = {
  value: 0,
  dollarMultiplier: 0,
  dollarValue: 0,
};
const trends: IReportingPageProps['trends'] = {
  content: emptyTrend,
  cpe: emptyTrend,
  cpm: emptyTrend,
  tmv: emptyTrend,
  engagements: emptyTrend,
  impressions: emptyTrend,
  posts: emptyTrend,
  conversions: emptyTrend,
  creators: emptyTrend,
  reach: emptyTrend,
  views: emptyTrend,
};
const impact: IReportingPageProps['impact'] = {
  roi: emptyTrend,
  content: emptyTrend,
  impressions: emptyTrend,
  engagements: emptyTrend,
  sales: emptyTrend,
  investment: emptyTrend,
  total: emptyTrend,
};
const contentValues: IReportingPageProps['contentValues'] = {
  images: {
    broad: emptyValue,
    broadLimited: emptyValue,
    custom: emptyValue,
    none: emptyValue,
  },
  longVideos: {
    broad: emptyValue,
    broadLimited: emptyValue,
    custom: emptyValue,
    none: emptyValue,
  },
  shortVideos: {
    broad: emptyValue,
    broadLimited: emptyValue,
    custom: emptyValue,
    none: emptyValue,
  },
};
const impressionsValues: IReportingPageProps['impressionsValues'] = {
  instagramPosts: {
    reach: emptyValue,
    views: emptyValue,
  },
  instagramReels: {
    reach: emptyValue,
    views: emptyValue,
  },
  instagramStories: {
    impressions: emptyValue,
    estimatedImpressions: emptyValue,
  },
  instagramVideos: {
    reach: emptyValue,
    views: emptyValue,
  },
  tiktok: {
    views: emptyValue,
  },
  youtube: {
    views: emptyValue,
  },
  youtubeShorts: {
    views: emptyValue,
  },
  pinterest: {
    impressions: emptyValue,
  },
  twitter: {
    reach: emptyValue,
  },
};
const engagementValues: IReportingPageProps['engagementValues'] = {
  instagramPosts: {
    comments: emptyValue,
    likes: emptyValue,
  },
  instagramReels: {
    comments: emptyValue,
    likes: emptyValue,
  },
  instagramStories: {
    comments: emptyValue,
    likes: emptyValue,
  },
  instagramVideos: {
    comments: emptyValue,
    likes: emptyValue,
  },
  tiktok: {
    shares: emptyValue,
    comments: emptyValue,
    likes: emptyValue,
  },
  youtube: {
    views: emptyValue,
    comments: emptyValue,
    likes: emptyValue,
  },
  youtubeShorts: {
    views: emptyValue,
    comments: emptyValue,
    likes: emptyValue,
  },
  pinterest: {
    clicks: emptyValue,
    closeups: emptyValue,
    favorites: emptyValue,
  },
  twitter: {
    shares: emptyValue,
    comments: emptyValue,
    likes: emptyValue,
  },
};
const investmentValue: IInvestmentValues = {
  payments: 0,
  productCost: 0,
  total: 0,
};
const salesValues: IReportingPageProps['salesValues'] = {
  links: {
    sales: {
      value: 0,
    },
    conversions: {
      value: 0,
    },
  },
  promos: {
    sales: {
      value: 0,
    },
    conversions: {
      value: 0,
    },
  },
};
export const PageError = ({
  startDate,
  endDate,
  Header,
}: {
  startDate: Date;
  endDate: Date;
  Header: React.ReactNode;
}) => {
  const dateRange: [Date, Date] = [startDate, endDate];
  const timeLabel = 'All Time';
  return (
    <ReportingLayout
      Alert={<AlertError refreshUi={false} />}
      Header={Header}
      ImpactChart={(
        <LineChartROI
          data={[]}
          type="monthly"
          filter={undefined}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      ChartLabel={(
        <Label type="" dateRange={dateRange} timeLabel={timeLabel} />
      )}
      HowItsCalculated={(
        <HowItsCalculated
          contentValues={contentValues}
          engagementValues={engagementValues}
          filter=""
          impact={impact}
          impressionsValues={impressionsValues}
          investmentValues={investmentValue}
          salesValues={salesValues}
        />
      )}
      MetricsSecondary={(
        <Card title="Trends" subtitle={timeLabel} icon={<ChartLineUpIcon />} dark>
          <MetricsSecondary trends={trends} />
        </Card>
      )}
      MetricsTabs={(
        <Card title="Your Impact" subtitle={timeLabel} icon={<CommentDollarIcon />}>
          <MetricsTabs
            impact={impact}
            filter=""
          />
        </Card>
      )}
    />
  );
};
