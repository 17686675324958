import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import { P } from '@frontend/shadcn/components/typography/p';
import { SlidersIcon } from '@revfluence/fresh-icons/regular/esm';
import * as React from 'react';
import { DateFilter } from '@frontend/app/components';
import { Link } from 'react-router-dom';
import { PAYMENT_APP_ID } from '@frontend/app/constants/applicationIds';
import { CircleDollarIcon } from '@revfluence/fresh-icons/solid/esm';
import { usePayment } from '../PaymentContext';

function formatCurrency(amount: number | string): string {
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  return numericAmount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const Header: React.FunctionComponent = () => {
  const { dateRangeSettings, setIsFilterOpen, availableBalance } = usePayment();
  return (
    <div className="px-8 py-4 bg-primary text-white flex justify-between items-center">
      <div className="flex items-baseline gap-4">
        <P className="text-xl font-medium">Payments</P>

        <Link to={`/settings/${PAYMENT_APP_ID}`} className="text-white flex gap-2 items-center flex-row">
          <div className="flex items-center gap-2 text-white bg-green-4 px-2 py-[1px] rounded-lg h-6">
            <CircleDollarIcon className="w-3 h-3" />
            <P className="text-sm font-normal">Balance:</P>
            <P className="text-sm font-semibold">{`$${formatCurrency(availableBalance)} USD`}</P>
          </div>
        </Link>
      </div>
      <div className="flex items-center gap-2">
        <DateFilter earliestDate={dateRangeSettings.earliestDate} settings={dateRangeSettings} refreshUi />
        <HeaderButton icon={<SlidersIcon className="h-4 w-4" />} onClick={() => setIsFilterOpen(true)} />
      </div>
    </div>
  );
};

export default Header;
