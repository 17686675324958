/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import cx from 'classnames';
import {
  every,
  filter,
  find,
  findIndex,
  forEach,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  isString,
  isUndefined,
  map,
  reduce,
  size,
  some,
} from 'lodash';

import {
  SubmitButton,
  ICommunity,
  IToastRefHandles,
  Toast,
  ISocialAccount,
  IProspect,
  IMemberProgram,
  IMemberProgramMap,
  TProgram,
  InviteIcon,
  IOption,
  ISelectProps,
  Select,
  Loading,
  Tooltip,
} from '@components';

import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';

import { useInvite } from './hooks';
import { useInviteContext } from './context/InviteContext';
import {
  getProgramStatusOfAccount,
  getCommunitiesAndProgramsOfProspect,
  getProgramStatus,
  getRecentlyCreatedProgramId,
  getSocialAccountsFromProspects,
  mapProgramsToCommunities,
} from './utils';

const {
  useEffect, useRef, useState, useMemo, useCallback,
} = React;

import styles from './Invite.scss';

type TInviteMode = 'default' | 'withButton';

enum Reason {
  IsFetching = 'IsFetching',
  NoProspect = 'NoProspect',
  NoPrograms = 'NoPrograms',
  NoSelectedProgram = 'NoSelectedProgram',
  NoPublishedApplicationPage = 'NoPublishedApplicationPage',
  NoResource = 'NoResource',
  NotContactable = 'NotContactable',
  HasStatus = 'HasStatus',
  Inviting = 'Inviting',
  InvitingThisProspect = 'InvitingThisProspect', // Used for main button
  InvitingThisProspectToSelectedProgram = 'InvitingThisProspectToSelectedProgram', // Used for button in list
}

interface IReasonDescriptionMessage {
  singular: string;
  plural: string;
}

interface IReasonDescription {
  message: string | IReasonDescriptionMessage;
  ammendFilter?: (prospects: IProspect[], programId?: TProgram['id'], memberPrograms?: IMemberProgramMap) => IProspect[];
  showOnlyOnListMode?: boolean;
  hideFromDropdown?: boolean;
}

export const ReasonsDescriptions: Record<Reason, IReasonDescription> = {
  [Reason.IsFetching]: {
    message: 'loading...',
  },
  [Reason.NoProspect]: {
    message: 'Please select at least one creator to invite.',
  },
  [Reason.NoPrograms]: {
    message: 'Please create at least one project to invite creators',
  },
  [Reason.NoSelectedProgram]: {
    message: {
      singular: 'Please select a project to invite creator.',
      plural: 'Please select a project to invite creators.',
    },
  },
  [Reason.NoPublishedApplicationPage]: {
    message: 'Cannot invite creators because project\'s application page is not published.',
  },
  [Reason.NoResource]: {
    message: 'Your email is not connected. Please connect or re-connect your Gmail or Outlook.',
  },
  [Reason.NotContactable]: {
    message: 'Creator cannot be invited because they cannot be contacted by email or Instagram Marketplace.',
    ammendFilter: (prospects) => filter(
      prospects,
      (prospect) => {
        const socialAccount: ISocialAccount = prospect;
        return (socialAccount.email && socialAccount.can_contact);
      },
    ),
    showOnlyOnListMode: true,
  },
  [Reason.HasStatus]: {
    message: 'Creator has been previously invited to this project.',
  },
  [Reason.Inviting]: {
    message: 'Inviting selected creators. This may take a few moments.',
  },
  [Reason.InvitingThisProspect]: {
    message: 'Inviting selected creators. This may take a few moments.',
  },
  [Reason.InvitingThisProspectToSelectedProgram]: {
    message: 'Inviting selected creators. This may take a few moments.',
  },
};

const getBlockedReasonMessage = (reason: Reason, prospectsCount?: number): string => {
  const reasonDescription = ReasonsDescriptions[Reason[reason]];

  if (isString(reasonDescription.message)) {
    return reasonDescription.message;
  }

  const message = reasonDescription.message as IReasonDescriptionMessage;
  if (prospectsCount > 1) {
    return message.plural;
  }
  return message.singular;
};

export interface IInviteProps {
  className?: string;
  /**
   * Prospect
   */
  prospect: IProspect | IProspect[];
  /**
   * Pre-load all programs of these prospects
   */
  preloadProspects?: IProspect | IProspect[];
  /**
   * Invite mode
   */
  mode?: TInviteMode;
  /**
   * Show border
   */
  withBorder?: boolean;
  /**
   * Props forwarded to <Select>
   */
  selectProps?: Omit<Partial<ISelectProps>, 'options'>;
  /**
   * Ref to Toast
   * if not provided, Invite will have its own Toast
   */
  toastRef?: React.RefObject<IToastRefHandles>;
  /**
   * Callback when popover is shown/hidden
   */
  onPopoverShown?: (shown: boolean) => void;
  /**
   * Callback when a prospect is invited
   */
  onInvite?: (prospect: IProspect | IProspect[], programs: TProgram[]) => void;
  /**
   * Callback when a program is selected
   */
  onProgramSelect?: (programId: TProgram['id']) => void;
  onInviteStatusUpdate?: (isInviting: boolean) => void;

  communities: ICommunity[];
  isFetchingCommunities: boolean;
  projects: IProject[];
  isFetchingAllPrograms: boolean;
}

export interface IInviteStatus {
  className?: string;
  message: React.ReactChild;
  shortMessage?: string;
}

const areProspectsContactable = (prospectAccounts: Partial<ISocialAccount[]>): boolean => (
  every(prospectAccounts, (account) => !isEmpty(account.email) || !!account.is_in_instagram_marketplace)
);

/**
 * Invite a member to a program
 */
export const Invite: React.FC<IInviteProps> = (props: IInviteProps) => {
  const {
    className,
    mode = 'default',
    onInvite: onInviteProp,
    onPopoverShown = () => undefined,
    onProgramSelect = () => undefined,
    preloadProspects = props.prospect,
    prospect: prospects,
    selectProps = {},
    toastRef: toastRefProp,
    withBorder = false,
    onInviteStatusUpdate = () => undefined,
    communities,
    projects,
    isFetchingCommunities,
    isFetchingAllPrograms,
  } = props;

  const isListView = mode !== 'default';

  // Force rerender on invite
  const onInvite = (prospect: IProspect | IProspect[], programs: TProgram[]) => {
    if (isFunction(onInviteProp)) {
      onInviteProp(prospect, programs);
    }
    forceUpdate((x) => !x);
  };

  const {
    memberPrograms,
    selectedProgramId,
    updateSelectedProgramId,
    isWorkflowEnabled,
    updatedSelectedSocialAccounts,
  } = useInviteContext();

  const {
    sendInvite,
    error,
    inviteStatus,
    isFetching,
    programs,
    resourceId,
    allPrograms,
  } = useInvite({
    prospect: prospects,
    preloadProspects,
    onInvite,
    communities,
    projects,
    isFetchingCommunities,
    isFetchingAllPrograms,
  });

  const projectApplicationPagePublished: { [id: IProject['id']]: boolean } = useMemo(() => {
    if (isEmpty(allPrograms)) {
      return {};
    }

    return reduce(
      allPrograms,
      (acc, program: IProject) => ({ ...acc, ...{ [program.id]: program.published } }),
      { },
    );
  }, [allPrograms]);

  const [, forceUpdate] = useState(false);
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const selectRef = useRef<Select>(); // Used to control the selected option
  const mainContainerRef = useRef<HTMLDivElement>(); // Used to make popover mount on this component's root container
  const toastRef = useRef<IToastRefHandles>(toastRefProp ? toastRefProp.current : null);
  const submitButtonRef = useRef<HTMLDivElement>();
  const {
    searchText,
    inputValue,
    handleSearchChange,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'projects',
      metadata: {
        source: 'creator_search_profile_card',
      },
    },
  });

  // Get social accounts of provided prospect/s
  const prospectAccounts = isArray(prospects)
    ? getSocialAccountsFromProspects(...prospects)
    : getSocialAccountsFromProspects(prospects);

  // Only used for 'default' (single account) mode
  const programStatusOfProspect = prospectAccounts.length > 0
    ? getProgramStatusOfAccount(
      prospectAccounts[0],
      selectedProgramId,
      memberPrograms,
    )
    : null;

  /**
   * Get a TProgram[] from all accounts
   */
  const allProgramsForProspect: any = useMemo(() => {
    const activePrograms = filter(
      allPrograms,
      (program: IProject) => isEmpty(program.archivedDate) && isEmpty(program.deletedDate),
    );

    if (isEmpty(prospectAccounts)) {
      return activePrograms;
    }

    const { id: socialAccountId } = prospectAccounts[0];

    if (!socialAccountId) {
      return activePrograms;
    }

    const prospectPrograms = memberPrograms[socialAccountId];
    return map(activePrograms, (program): TProgram => {
      const { id } = program;

      const prospectProgram: IMemberProgram = find(
        prospectPrograms,
        (prospectProgram) => prospectProgram.id === id,
      );

      return prospectProgram || program;
    });
  }, [prospectAccounts, allPrograms, memberPrograms]);

  // Used to disable invite button when inviting
  const blockedReasons: Reason[] = useMemo(() => {
    const reasons: Reason[] = [];
    if (isFetching) {
      reasons.push(Reason.IsFetching);
    }
    if (isEmpty(prospects)) {
      reasons.push(Reason.NoProspect);
    }
    if (!isNumber(resourceId)) {
      reasons.push(Reason.NoResource);
    }
    if (!areProspectsContactable(prospectAccounts)) {
      reasons.push(Reason.NotContactable);
    }
    if (!isNil(programStatusOfProspect)) {
      reasons.push(Reason.HasStatus);
    }

    if (isEmpty(allProgramsForProspect)) {
      reasons.push(Reason.NoPrograms);
    }

    if (!selectedProgramId) {
      reasons.push(Reason.NoSelectedProgram);
    } else if (!projectApplicationPagePublished[selectedProgramId]) {
      reasons.push(Reason.NoPublishedApplicationPage);
    }

    if (inviteStatus) {
      if (inviteStatus.isInviting) {
        reasons.push(Reason.Inviting);
        if (isEqual(inviteStatus.prospect, prospects)) {
          reasons.push(Reason.InvitingThisProspect);
          if (selectedProgramId === inviteStatus.programId) {
            reasons.push(Reason.InvitingThisProspectToSelectedProgram);
          }
        }
      }
    }
    return reasons;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetching,
    prospects,
    resourceId,
    prospectAccounts,
    programStatusOfProspect,
    projectApplicationPagePublished,
    selectedProgramId,
    inviteStatus,
    allProgramsForProspect,
  ]);

  const isInviteBlocked = useMemo(() => blockedReasons.length > 0, [blockedReasons.length]);

  const shouldHideInviteButton = useMemo(() => (
    every(
      blockedReasons,
      (reason) => ![Reason.NoSelectedProgram, Reason.HasStatus].includes(reason),
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  /**
   * Render button element
   */
  const isSelectingDisabled = some(blockedReasons, (reason) => {
    switch (reason) {
      case Reason.IsFetching:
      case Reason.NoResource:
      case Reason.NotContactable:
        return true;
      default:
        return false;
    }
  });

  // Update whichever's listening to popover opened/closed status
  useEffect(() => {
    onPopoverShown(isPopoverShown);
  }, [isPopoverShown, onPopoverShown]);

  useEffect(() => {
    onInviteStatusUpdate(inviteStatus.isInviting);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteStatus.isInviting]);

  /**
   * On 'withButton' or mass invite mode, show default list
   * On solo prospect mode, show its own programs with respective statuses
   */
  const communitiesAndPrograms: readonly ICommunity[] = (
    mode === 'withButton' || isArray(prospects) || isEmpty(prospects)
      ? mapProgramsToCommunities(communities, programs)
      : getCommunitiesAndProgramsOfProspect(communities, memberPrograms, programs, prospects)
  );

  /**
   * Show error message
   */
  useEffect(() => {
    if (error) {
      toastRef.current.showMessage({
        type: 'error',
        content: error.toString(),
      });
    }
  }, [error]);

  /**
   * Status message shown at the top of the list
   */

  const filterProspects = (filterFn: IReasonDescription['ammendFilter']): void => {
    const newProspects = filterFn(
      isArray(prospects) ? prospects : [prospects],
      selectedProgramId,
      memberPrograms,
    );

    const socialAccounts: ISocialAccount[] = map(newProspects, 'socialAccount');
    updatedSelectedSocialAccounts(socialAccounts);
  };

  const getExplainedBlockedReason = (reason: Reason): IInviteStatus => {
    const {
      showOnlyOnListMode = false,
      ammendFilter,
    } = ReasonsDescriptions[reason];

    if ((showOnlyOnListMode && !isListView) || ReasonsDescriptions[reason].hideFromDropdown) {
      return;
    }

    let formattedMessage: React.ReactElement = <>{getBlockedReasonMessage(reason, size(prospects))}</>;

    if (isListView && isFunction(ammendFilter)) {
      formattedMessage = (
        <>
          {formattedMessage}
          <strong
            className={styles.ammendAction}
            onClick={() => filterProspects(ammendFilter)}
          >
            Remove uninvitable creators.
          </strong>
        </>
      );
    }

    return {
      message: formattedMessage,
      className: (styles as any).formattedBlockedReason,
    };
  };

  const statusMessage: IInviteStatus = useMemo(() => {
    /** We only show one status message. Grab the first one that has description. */
    const firstBlockedReason: Reason = find(
      blockedReasons.filter(Boolean),
      (reason: Reason) => !isUndefined(ReasonsDescriptions[reason]),
    );

    if (firstBlockedReason) {
      const explainedReason = getExplainedBlockedReason(firstBlockedReason);
      if (explainedReason) {
        return explainedReason;
      }
    }

    if (!isNumber(resourceId)) {
      return {
        message: ReasonsDescriptions[Reason.NoResource as string],
      };
    } else if (isEmpty(communitiesAndPrograms)) {
      return {
        message: 'There are no groups yet',
        className: styles.noCommunities,
      };
    }

    if (
      !isArray(prospects)
        && mode === 'default'
        && includes(blockedReasons, Reason.NotContactable)
    ) {
      return {
        message: (
          <>
            Creators cannot be invited because they cannot be contacted by email or Instagram Marketplace.
            Instead, you may directly reach out to them &nbsp;
            <a
              className={styles.accountLink}
              href={prospectAccounts[0].link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                window.open(prospectAccounts[0].link, '_blank');
              }}
            >
              {prospectAccounts[0].username ? `at @${prospectAccounts[0].username}` : 'here'}
            </a>
            .
          </>
        ),
        className: styles.ineligible,
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    communitiesAndPrograms,
    resourceId,
    prospects,
    prospectAccounts,
    blockedReasons,
  ]);

  const searchOption = useMemo(() => ({
    value: 'search',
    label: (
      <div
        className={styles.searchContainer}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <SearchInput
          value={inputValue}
          onChange={handleSearchChange}
          placeholder="Search projects..."
          isLoading={isSearchLoading}
        />
      </div>
    ),
    optionClass: cx(styles.option, styles.searchOption),
    onSelect: () => false,
  }), [inputValue, handleSearchChange, isSearchLoading]);

  /**
   * Generate the list option for a program
   */
  const getProgramOption = (program: TProgram): IOption => {
    const status = getProgramStatus(program);
    const isInvitingToThisProgram = inviteStatus.isInviting && program.id === inviteStatus.programId;

    const actions: React.ReactChild[] = [];
    if (mode === 'default') {
      if (status === 'invited' || status === 'new' || status === 'approved') {
        // Member status ('rejected' not being shown)
        actions.push(
          <div key={status} className={styles[status]}>
            {(() => {
              if (status === 'approved') {
                return 'Member';
              } else if (status === 'new') {
                return 'Applied';
              }
              return status;
            })()}
          </div>,
        );
      } else if (!status && !shouldHideInviteButton) {
        // Invite button
        actions.push(
          <SubmitButton
            className={cx(styles.inviteButton, {
              [styles.inviting]: isInvitingToThisProgram,
              [styles.wait]: includes(blockedReasons, Reason.Inviting),
            })}
            key="inviteButton"
            isSubmitting={isInvitingToThisProgram}
            label="Invite"
            submittingLabel=""
            icon={<InviteIcon />}
            onClick={() => {
              if (!includes(blockedReasons, Reason.Inviting)) {
                sendInvite(program.id);
              }
            }}
          />,
        );
      }
    }

    return {
      value: program.id,
      label: program.title,
      optionClass: cx(styles.programOption, styles.option, {
        [styles.noSelect]: isSelectingDisabled,
      }),
      showActions: mode === 'default' && (isInvitingToThisProgram || !isNil(status)),
      actions,
    };
  };

  /**
   * Build all items for <Select>
   */
  const getSelectOptions = () => {
    const options: IOption[] = [];
    options.push(searchOption);

    if (!isEmpty(statusMessage)) {
      options.push({
        value: null,
        label: <span>{statusMessage.message}</span>,
        optionClass: cx(statusMessage.className, styles.message, styles.option, styles.noSelect),
        onSelect: () => false,
      });
    }

    // Filter communities and programs based on search
    const filteredCommunities = communitiesAndPrograms.map((community) => ({
      ...community,
      programs: community.programs?.filter((program) =>
        !searchText || program.title.toLowerCase().includes(searchText.toLowerCase())),
    })).filter((community) =>
      !searchText
      || community.title.toLowerCase().includes(searchText.toLowerCase())
      || community.programs?.length > 0);

    forEach(filteredCommunities, (community) => {
      if (!isWorkflowEnabled) {
        options.push({
          value: null,
          label: community.title,
          optionClass: cx(styles.communityOption, styles.option, styles.noSelect),
          onSelect: () => false,
        });
      }

      if (isEmpty(community.programs)) {
        if (!isWorkflowEnabled) {
          options.push({
            value: null,
            label: 'No programs yet',
            optionClass: cx(styles.noPrograms, styles.programOption, styles.option, styles.noSelect),
          });
        }
      } else {
        options.push(
          ...map(community.programs, (program) => (
            getProgramOption(program)
          )),
        );
      }
    });
    return options;
  };

  /**
   * Build select options for all programs
   * This is used for isWorkflowEnabled === true
   */
  const getAllProgramsSelectOptions = () => {
    const options: IOption[] = [];
    options.push(searchOption);

    if (!isEmpty(statusMessage)) {
      options.push({
        value: null,
        label: <span>{statusMessage.message}</span>,
        optionClass: cx(statusMessage.className, styles.message, styles.option, styles.noSelect),
        onSelect: () => false,
      });
    }

    // Filter programs based on search
    const filteredPrograms = allProgramsForProspect.filter((program) =>
      !searchText || program.title.toLowerCase().includes(searchText.toLowerCase()));

    // Add error message if there are no results
    if (searchText && !filteredPrograms.length) {
      options.push({
        value: 'no-results',
        label: (
          <div className={styles.searchError}>
            No results found
          </div>
        ),
        optionClass: cx(styles.option, styles.noSelect),
      });
      return options;
    }

    options.push(
      ...map(filteredPrograms, (program) => (
        getProgramOption(program)
      )),
    );

    return options;
  };

  /**
   * Render select element
   */
  const selectOptions = isWorkflowEnabled ? getAllProgramsSelectOptions() : getSelectOptions();
  const defaultProgramId = selectedProgramId || getRecentlyCreatedProgramId(programs);
  const defaultSelectedIndex = isNumber(defaultProgramId)
    ? findIndex(selectOptions, (option) => option.value === defaultProgramId)
    : null;

  // Update <Select>'s state if needed
  useEffect(() => {
    if (
      isNumber(defaultSelectedIndex)
      && selectRef.current
      && selectRef.current.state.selectedIndex !== defaultSelectedIndex
    ) {
      selectRef.current.selectOptionAtIndex(defaultSelectedIndex);
    }
  }, [defaultSelectedIndex]);

  const onMenuOpen = useCallback(() => {
    setIsPopoverShown(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setIsPopoverShown(false);
    if (selectRef.current) {
      selectRef.current.selectOptionAtIndex(null);
    }
  }, []);

  const renderSelect = () => (
    <>
      <Select
        {...selectProps}
        ref={selectRef}
        className={cx(styles.select, selectProps.className)}
        buttonClassName={cx(styles.selectButton, {
          [styles.isFocused]: isPopoverShown,
        })}
        hintText="Invite to ..."
        options={selectOptions}
        defaultSelectedIndex={defaultSelectedIndex}
        onChange={(value, index) => {
          if (value === 'search') {
            // If search is "selected", restore the previous selection
            if (selectRef.current?.state.selectedIndex !== null) {
              selectRef.current.selectOptionAtIndex(selectRef.current.state.selectedIndex);
            }
          } else {
            if (selectProps && isFunction(selectProps.onChange)) {
              selectProps.onChange(value, index);
            }
            onProgramSelect(value);
            updateSelectedProgramId(value);
            setIsPopoverShown(false);
          }
        }}
        anchorLocation={selectProps.anchorLocation || 'button'}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        popoverProps={{
          className: cx(styles.popover, { [styles.shown]: isPopoverShown }),
          contentWrapperClassName: styles.popoverContentWrapper,
          contentClassName: styles.popoverContent,
          anchorOrigin: 'middle',
          showArrow: false,
          shadowSize: 'large',
          minWidth: styles.popoverWidth as any,
          maxWidth: styles.popoverWidth as any,
          mountRef: mainContainerRef,
          ...selectProps.popoverProps,
        }}
      />
    </>
  );

  const renderButton = () => (
    <div ref={submitButtonRef}>
      <SubmitButton
        className={cx(styles.inviteButton, {
          [styles.inviting]: includes(blockedReasons, Reason.InvitingThisProspect),
          [styles.wait]: includes(blockedReasons, Reason.Inviting),
          [styles.disabled]: isInviteBlocked,
        })}
        disabled={isInviteBlocked}
        isSubmitting={inviteStatus && inviteStatus.isInviting}
        submittingLabel=""
        label={programStatusOfProspect === 'invited' ? 'Invited' : 'Invite'}
        title={(() => {
          if (includes(blockedReasons, Reason.InvitingThisProspect)) {
            return 'Inviting...';
          }
        })()}
        onClick={() => !isInviteBlocked && sendInvite()}
      />
    </div>
  );

  const renderTooltip = () => {
    const firstBlockedReason = find(blockedReasons, (reason) => !isUndefined(ReasonsDescriptions[reason]));

    if (!isInviteBlocked || !firstBlockedReason) {
      return null;
    }

    return (
      <Tooltip
        mountRef={submitButtonRef}
      >
        <div className={styles.tooltip}>
          {getBlockedReasonMessage(firstBlockedReason, size(prospects))}
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      className={cx(
        styles.Invite,
        (styles as any).placeholderShimmy,
        className,
        {
          [styles.hideButton]: isPopoverShown,
          [styles.withButton]: mode === 'withButton',
          [styles.withBorder]: withBorder,
        },
      )}
      ref={mainContainerRef}
    >
      {renderTooltip()}
      <Loading
        className={styles.loading}
        show={isFetching}
        theme="blue"
        rounded
      />
      {renderSelect()}
      {renderButton()}
      {!toastRefProp ? <Toast useFresh ref={toastRef} /> : null}
    </div>
  );
};

Invite.displayName = 'Invite';
