import * as React from 'react';
import { ConfigProvider, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Form, IFormProps as FormProps } from '@revfluence/fresh';
import cx from 'classnames';

import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { CheckCircleFilled, ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import Text from '@frontend/app/refresh-components/Text';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { useHistory } from 'react-router-dom';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import moment from 'moment';
import styles from './OfferForm.scss';
import {
  AdjustExpirationDateButton,
} from './FormButtons';
import {
  IAffiliateLinksFormValues,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  TDisabledMap,
  TFormValues,
  TFormOptionData,
  FormAsyncAction,
} from './types';
import { GetConnectedShopify_clientConnections } from '../../queries/types/GetConnectedShopify';
import { RefreshCommonSection } from './FormSections/CommonSection/RefreshCommonSection';
import { compareValues } from './utils/OfferFormUtils';

enum SaveStatus {
  UNSAVED = 'unsaved',
  SAVED = 'saved',
}

const { useRef, useState } = React;

interface IProps {
  disabledFields: TDisabledMap<TFormValues>;
  formRef: FormInstance;
  hasAffiliateLink: boolean;
  initialValues: TFormValues;
  isDeleted: boolean;
  isExpired: boolean;
  isSaving: boolean;
  mode: OfferFormModes;
  onDelete: () => void;
  onFieldFocused: (type: string, selected: boolean) => void;
  onFinish: () => void;
  onUnDelete: () => void;
  onValuesChange: FormProps['onValuesChange'];
  values: TFormValues;
  requiredFields: React.ReactNode;
  formOptionData?: TFormOptionData;
  hasMembers?: boolean
  isNewFlow: boolean;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
  isMigrationEnabled?: boolean;
  isSubscriptionEnable?: boolean;
  handleFormAsyncActions?: (value: FormAsyncAction) => void,
  connectedClients?: GetConnectedShopify_clientConnections[],
  isOfferArchived?: boolean;
  initialAllowedDomains?: string[];
  shopifyStoreName?: string;
  onChangeImage?: (file: File) => void;
  setOfferImage: (url: string) => void;
}
const ConfirmDelete = (onOk: () => void) => (Modal.confirm({
  title: 'You are about to permanently delete this Offer. This action cannot be undone. Are you sure you want to proceed?',
  okText: 'Delete',
  okButtonProps: {
    type: 'primary',
    danger: true,
  },
  onOk,
}));
export const RefreshOfferForm: React.FC<Readonly<IProps>> = ({
  disabledFields,
  formRef,
  hasAffiliateLink,
  initialValues,
  isDeleted,
  isSaving,
  mode,
  onDelete,
  onFieldFocused,
  onFinish,
  onUnDelete,
  onValuesChange,
  requiredFields,
  values,
  formOptionData,
  connectedAdvertiserForSync,
  isMigrationEnabled,
  isSubscriptionEnable,
  handleFormAsyncActions,
  hasMembers,
  isNewFlow,
  connectedClients,
  isOfferArchived,
  initialAllowedDomains,
  shopifyStoreName,
  onChangeImage,
  setOfferImage,
}) => {
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.UNSAVED);
  const isNewOfferExpired = moment((values as IShopifyPromoCodeFormValues).endDate) < moment();

  const triggerRef = useRef();
  const { setOfferFormValue } = useOfferDetailsContext();
  const history = useHistory();
  React.useEffect(() => {
    const hasUnsavedChanges = compareValues(initialValues, values);
    setSaveStatus(hasUnsavedChanges ? SaveStatus.UNSAVED : SaveStatus.SAVED);
    setOfferFormValue(values);
  }, [initialValues, values, setOfferFormValue]);
  return (
    <div className={cx('max-w-[800px] mx-auto py-6 xl:px-0 px-6', styles.refreshForm)} ref={triggerRef}>
      <ConfigProvider getPopupContainer={() => triggerRef.current}>
        <Form
          form={formRef}
          initialValues={initialValues}
          labelCol={{ span: 24 }}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          requiredMark={false}
          size="small"
        >
          <div className="sticky top-0 z-10 flex justify-between items-center bg-grey-1 py-2">
            <div className="flex gap-2">
              <H3 fontWeight="font-medium" className="text-primary m-0">Offer Setup</H3>
              <span className="items-center flex gap-1" key="save-status">
                {isSaving ? (
                  <>
                    <LoadingOutlined style={{ color: '#4F7DBA' }} />
                    <Text visualVariant="caption" className="text-blue-5 text-xl">
                      Saving...
                    </Text>
                  </>
                ) : saveStatus === SaveStatus.UNSAVED ? (
                  <>
                    <ExclamationCircleFilled style={{ color: '#E2AB23' }} />
                    <Text visualVariant="caption" className="text-yellow-5">
                      Unsaved Changes
                    </Text>
                  </>
                ) : (
                  mode === OfferFormModes.Edit &&
                  (
                    <>
                      <CheckCircleFilled style={{ color: '#5A9672' }} />
                      <Text visualVariant="caption" className="text-green-5">
                        Saved Changes
                      </Text>
                    </>)
                )}
              </span>
            </div>
            <div className="flex gap-2">
              {mode === OfferFormModes.Create
                && (
                  <>
                    <Button type="button" variant="outline" onClick={() => history.goBack()}>Cancel</Button>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="inline-block">
                          <Button variant="primary" type="submit" disabled={!!requiredFields || isSaving}>Create Offer</Button>
                        </div>
                      </TooltipTrigger>
                      {requiredFields && (
                        <TooltipContent>
                          <span>{requiredFields}</span>
                        </TooltipContent>
                      )}
                    </Tooltip>

                  </>
                )}

              {mode === OfferFormModes.Edit && !isDeleted && !hasAffiliateLink && !isOfferArchived
                && (
                  <Button onClick={() => ConfirmDelete(onDelete)} variant="ghost" type="button" disabled={isSaving} className="!text-primary !font-semibold">Delete Offer</Button>
                )}
              {mode === OfferFormModes.Edit && isDeleted
                && (
                  <Button onClick={onUnDelete} variant="primary" type="submit" disabled={isSaving || isNewOfferExpired}>Un-delete Offer</Button>
                )}
              {mode === OfferFormModes.Edit && !isDeleted && !isOfferArchived
                && (
                  <>
                    <Button type="button" variant="outline" onClick={() => history.goBack()}>Cancel</Button>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="inline-block">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={!!requiredFields || isSaving || isNewOfferExpired}
                          >
                            Update Offer
                          </Button>
                        </div>
                      </TooltipTrigger>
                      {requiredFields && (
                        <TooltipContent>
                          <span>{requiredFields}</span>
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </>
                )}
            </div>
          </div>
          {isNewOfferExpired && <AdjustExpirationDateButton />}
          <RefreshCommonSection
            disabled={disabledFields}
            disabledAffiliatesLinks={disabledFields as TDisabledMap<IAffiliateLinksFormValues>}
            disabledShopify={disabledFields as TDisabledMap<IShopifyPromoCodeFormValues>}
            onFieldFocused={onFieldFocused}
            showCostPerClick={values.source === OFFER_SOURCE.TUNE}
            values={values}
            isNewFlow={isNewFlow}
            mode={mode}
            formRef={formRef}
            handleFormAsyncActions={handleFormAsyncActions}
            initialValues={initialValues}
            hasMembers={hasMembers}
            onChangeImage={onChangeImage}
            source={values.source}
            initialUrl={(initialValues as IAffiliateLinksFormValues).url}
            affiliateLinkData={values as IAffiliateLinksFormValues}
            shopifyData={values as IShopifyPromoCodeFormValues}
            formOptionData={formOptionData}
            isMigrationEnabled={isMigrationEnabled}
            isSubscriptionEnable={isSubscriptionEnable}
            connectedAdvertiserForSync={connectedAdvertiserForSync}
            connectedClients={connectedClients}
            shopifyStoreName={shopifyStoreName}
            hasAffiliateLink={hasAffiliateLink}
            isDeleted={isDeleted}
            setOfferImage={setOfferImage}
            initialAllowedDomains={initialAllowedDomains}
            saveStatus={saveStatus}
          />
        </Form>
      </ConfigProvider>
    </div>
  );
};
