import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { omit, groupBy } from 'lodash';

import { LoadSpinner } from '@components';
import { useMessagingContext } from '@frontend/hooks';
import { useClientFeatureEnabled, useCommunitiesQuery, useGetAllProjectsQuery } from '@frontend/app/hooks';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ClientFeature } from '@frontend/app/constants';
import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';
import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

import { useFetchPostData, useFetchPostsData, ISocialPostArtifact } from '../useFetchSocialPostData';
import { SocialPostAssignment } from './SocialPostAssignment';
import { SocialPostsList } from './SocialPostsList';

const { useEffect, useState, useMemo } = React;

import styles from './MemberSocialPostList.scss';

const PAGE_SIZE = 500;

const useIgEstimationDataFeatureFlag = (data: ISocialPostArtifact[], loading: boolean): { data: ISocialPostArtifact[], loading: boolean } => {
  const featureFlag = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);
  return React.useMemo(() => {
    if (featureFlag === undefined || loading) {
      return { data: [], loading: true };
    }
    if (!featureFlag) {
      // remove estimated impressions
      return { data: (data || []).map((d) => omit(d, 'estimated_impressions')), loading };
    }
    return { data, loading };
  }, [data, loading, featureFlag]);
};

const MemberSocialPostList: React.FunctionComponent = () => {
  const {
    showGenericErrorMessage,
  } = useMessagingContext();

  const [postToAssign, setPostToAssign] = useState<ISocialPostArtifact>(null);
  const [postIdToAssign, setPostIdToAssign] = useState<string>();
  const history = useHistory();
  const location = useLocation();

  // Handle deep linking
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const params = query.get('postId');
    if (params) {
      try {
        setPostIdToAssign(JSON.parse(params));
      } catch (e) {
        console.error('Failed to parse postId parameter:', e);
      }
    }
  }, [location]);

  useEffect(() => () => {
      const query = new URLSearchParams(location.search);
      // Remove postId from URL so that it doesn't get used in the next page load
      query.delete('postId');
      history.replace({
        search: query.toString(),
      });
      console.log('postIdToAssign', postIdToAssign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Runs only once when the component mounts

  const { backendServerApiEndpoint, memberId, clientId } = useApplication();
  const { loading: loadingProjects, data: projectsData } = useGetAllProjectsQuery({
    fetchPolicy: 'cache-only',
  });
  const { loading: loadingCommunities, data: communitiesData } = useCommunitiesQuery({
    fetchPolicy: 'cache-only',
  });

  const projectsDataDic = useMemo(() => groupBy((projectsData?.projects || []), 'id'), [projectsData]);
  const communitiesDataDic = useMemo(() => groupBy((communitiesData?.communities || []), 'id'), [communitiesData]);

  const {
    data: postsData, loading: loadingPosts, error, refetchData,
  } = useFetchPostsData(
    `${backendServerApiEndpoint}/social_mention`, {
      clientId,
      memberId,
      pageSize: PAGE_SIZE,
    },
  );

  const { data: postData } = useFetchPostData(
    `${backendServerApiEndpoint}/social_mention`,
    clientId,
    postIdToAssign,
  );

  const mapPostData = (post: ISocialPostArtifact, projectsDataDic: Record<string, IProject[]>, communitiesDataDic: Record<string, ICommunity[]>) => {
    post.post_type = post.post_type?.toLowerCase();

    const programNames = [];
    (post.program_ids || []).forEach((id) => {
      const [project] = projectsDataDic[id] || [];
      if (project) {
        programNames.push(project.title);
      }
    });
    post.program_names = programNames;

    const communityNames = [];
    (post.community_ids || []).forEach((id) => {
      const [community] = communitiesDataDic[id] || [];
      if (community) {
        communityNames.push(community.title);
      }
    });
    post.community_names = communityNames;
    return post;
  };

  // Add loaded postData into state
  useEffect(() => {
    if (!postToAssign && postData && postIdToAssign) {
      const post = mapPostData(postData as ISocialPostArtifact, projectsDataDic, communitiesDataDic);
      setPostToAssign(post);
    }
  }, [postData, postToAssign, postIdToAssign, projectsDataDic, communitiesDataDic]);

  const requestLoading = useMemo(
    () => loadingPosts || loadingProjects || loadingCommunities,
    [loadingCommunities, loadingProjects, loadingPosts],
  );

  const requestData = useMemo(
    () => (postsData || []).map(
      (post: ISocialPostArtifact) => mapPostData(post, projectsDataDic, communitiesDataDic),
    ), [communitiesDataDic, postsData, projectsDataDic],
  );

  const { data, loading } = useIgEstimationDataFeatureFlag(requestData || [], requestLoading);
  useEffect(() => {
    if (error) {
      showGenericErrorMessage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onAssignSuccess = () => {
    setPostToAssign(null);
    setPostIdToAssign(null);
    refetchData();
  };

  const assignPost = (post: ISocialPostArtifact) => {
    setPostToAssign(post);
    setPostIdToAssign(post.post_id);
  };

  const renderContent = () => {
    if (loading && !postToAssign) {
      return (
        <div>
          <LoadSpinner centered />
        </div>
      );
    }

    if (postToAssign) {
      return (
        <SocialPostAssignment
          memberId={memberId}
          post={postToAssign}
          onSuccess={onAssignSuccess}
        />
      );
    }

    return (
      <SocialPostsList
        posts={data}
        postDisplayName="post"
        onPostClick={assignPost}
      />
    );
  };

  return (
    <div className={styles.MemberSocialPostList}>
      {renderContent()}
    </div>
  );
};

export default MemberSocialPostList;
