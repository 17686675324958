import {
 Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@frontend/shadcn/components/ui/accordion';
import React, { useState } from 'react';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import Typography from './components/Typography';
import Button from './components/Button';
import ProductCard from './components/ProductCard';
import PageBackground from './components/PageBackground';

const Styles: React.FC = () => {
  const [openAccordions, setOpenAccordions] = useState<string[]>(['typography']);

  const accordionItems = [
    {
      value: 'typography',
      title: 'Typography',
      content: <Typography />,
    },
    {
      value: 'button',
      title: 'Button',
      content: <Button />,
    },
    {
      value: 'product-card',
      title: 'Product Card',
      content: <ProductCard />,
    },
    {
      value: 'pageBackground',
      title: 'Background Settings',
      content: <PageBackground />,
    },
  ];

  const handleAccordionChange = (value: string[]) => {
    setOpenAccordions(value);
  };

  return (
    <div className="flex flex-col gap-4 py-4">
      {accordionItems.map((item, index) => (
        <React.Fragment key={item.value}>
          <Accordion
            type="multiple"
            value={openAccordions}
            onValueChange={handleAccordionChange}
            className="w-full py-0"
          >
            <AccordionItem value={item.value} className="border-0">
              <AccordionTrigger className="bg-neutral-color-palettegray-1 rounded-2xl px-4 hover:no-underline py-2">
                <div className="flex items-center gap-2">
                  <span className="text-grey-6 text-base font-medium">{item.title}</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>{item.content}</AccordionContent>
            </AccordionItem>
          </Accordion>
          {index < accordionItems.length - 1 && <Separator className="w-full h-0.5" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Styles;
