/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-strict-ignore
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';
import {
  isEmpty,
  isNil,
  size,
  isObject,
  filter,
  map,
  toString,
  toLower,
  first,
  isUndefined,
  isArray,
  includes,
  keyBy,
  get,
  find,
  uniqBy,
  maxBy,
} from 'lodash';
import numeral from 'numeral';
import { TWorklet, TWorkItem } from '@frontend/app/containers/Projects/types';
import { Button, Tag, TagGroup, Tooltip, Modal, Avatar } from '@revfluence/fresh';
import { ImageIcon, PlusIcon, UserIcon } from '@revfluence/fresh-icons/regular/esm';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import { UserAvatar } from '@frontend/app/components';
import { GetAllCompletedMembersQuery_members } from '@frontend/app/queries/types/GetAllCompletedMembersQuery';
import { ColumnKey } from '@frontend/app/containers/Projects/constants';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import { SocialProfileCell } from '@frontend/app/components/MemberTable/CustomCells/SocialProfileCell';
import { TNetworkIdentifier } from '@components';
import { LastMessageCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/LastMessageCell';
import { OwnersCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/OwnersCell';
import { hasError, hasNotification } from '@frontend/app/utils/task';
import { useMessagingContext } from '@frontend/hooks';
import {
  IMember,
  useClientFeatureEnabled,
  useRemoveMembersFromTagsMutation,
  useModifyCommunityMembersMutation,
  useModifyProgramMembersMutation,
  IThread,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import {
  MemberSearchQuery_members_owners as IOwners,
  MemberSearchQuery_members,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';
import { ICounts } from '@frontend/app/containers/Projects/hooks';
import { CustomFieldType } from '@frontend/app/types/globalTypes';
import { useMembershipSources } from '@frontend/app/containers/Projects/hooks';
import { pfaV2Routes } from '@frontend/app/containers/Settings/ProductFulfillment/routes';
import { ColumnDef } from '@tanstack/react-table';
import { NameCell, Tag as RefreshTag } from '@frontend/app/refresh-components';
import { EllipsisLabel as RefEllipsisLabel } from '@frontend/app/refresh-components/EllipsisLabel';
import {
  FacebookIcon,
  InstagramIcon,
  PinterestPIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { ApolloError } from '@apollo/client';
import { useMemberColumns } from '../../MemberTable/hooks';
import { formatDate } from '../util';
import { CellModalType, MSG_DURATION } from '../MembersTable';
import styles from '../MembersTable.scss';
import { ColumnHeader } from './useFlexMembersTableColumns';
import { MemberTableRow } from './useMembersTableColumns';
import { ComplexColumns } from '../FlexMembersTable';

const { useMemo, useCallback } = React;

type FlexColumnData = {
  key: string;
  color: string;
};

const flexColumnDataMap: Record<string, FlexColumnData> = {
  'Promo Code': { key: 'promoCode', color: '#EB6192' },
  'Promo Total Payout': { key: 'promoTotalPayout', color: '#EB6192' },
  'Promo CPS': { key: 'promoCps', color: '#EB6192' },
  'Promo Last Payout Date': { key: 'promoLastPayoutDate', color: '#EB6192' },
  'Promo Conversions': { key: 'promoConversions', color: '#EB6192' },
  'Sales Link': { key: 'link', color: '#FFA500' },
  'Total Payout': { key: 'payout', color: '#FFA500' },
  Clicks: { key: 'clicks', color: '#FFA500' },
  'Total Click Rate %': { key: 'clickRate', color: '#FFA500' },
  CPS: { key: 'cps', color: '#FFA500' },
  CPC: { key: 'cpc', color: '#FFA500' },
  'Last Payout Date': { key: 'lastPayoutDate', color: '#FFA500' },
  'Order Number': { key: 'pfaOrderNumber', color: '#55A2DF' },
  'Products Requested': { key: 'pfaProductsRequested', color: '#55A2DF' },
  'Products Count': { key: 'pfaProductsCount', color: '#55A2DF' },
  'Date Sent': { key: 'pfaDateSent', color: '#55A2DF' },
  'Total Cost': { key: 'pfaTotalCost', color: '#55A2DF' },
  'Tracking Number': { key: 'pfaTrackingNumber', color: '#55A2DF' },
  Order: { key: 'pfaOrderStatus', color: '#55A2DF' },
  Brief: { key: 'briefStatus', color: '#C274DE' },
  'Brief Date Sent': { key: 'briefSentDate', color: '#C274DE' },
  'Brief Deliverables Count': { key: 'briefDeliverableCount', color: '#C274DE' },
  'Brief Payment': { key: 'briefPayment', color: '#C274DE' },
  'Brief Last Reviewer': { key: 'briefLastReviewer', color: '#C274DE' },
  'Brief Usage Rights': { key: 'briefUsageRight', color: '#C274DE' },
  'Brief Last Activity': { key: 'briefLastActivity', color: '#C274DE' },
  'Brief Accepted Date': { key: 'briefAcceptedDate', color: '#C274DE' },
  Deliverable: { key: 'deliverable', color: '#5DB884' },
  'Deliverable Post Date': { key: 'deliverablePostDate', color: '#5DB884' },
  'Deliverable Last Activity': { key: 'deliverableLastActivity', color: '#5DB884' },
  'Deliverable Live Link': { key: 'deliverableLiveLink', color: '#5DB884' },
  'Deliverable Next Due Date': { key: 'deliverableNextDueDate', color: '#5DB884' },
  'Deliverable Last Reviewer': { key: 'deliverableLastReviewer', color: '#5DB884' },
  'Deliverable Ads Code': { key: 'deliverableAdsCode', color: '#5DB884' },
};

export type FlexMemberTableRow = GetAllCompletedMembersQuery_members & {
  id: string;
  name: string;
  member?: any;
  fields?: any;
  email: string;
  owners?: IOwners[];
  [ColumnKey.DaysInStage]: number;
  [ColumnKey.CTA]?: any;
  [ColumnKey.DateAdded]?: number;
  [ColumnKey.DateCompleted]?: number;
  [ColumnKey.TaskName]?: ITask;
  [ColumnKey.WorkletName]?: ITask;
  [ColumnKey.WorkletTaskName]?: ITask;
  [ColumnKey.PendingContracts]?: string;
  [ColumnKey.PaypalAddress]?: string;
  [ColumnKey.LastPaymentSent]?: Date;
  [ColumnKey.Message]?: string;
  [ColumnKey.Deliverables]?: string;
  [ColumnKey.Price]?: number;
  [ColumnKey.NextDeadline]?: Date;
  [ColumnKey.OpenDeliverables]?: number;
  [ColumnKey.CompletedDeliverables]?: number;
  [ColumnKey.Address1]?: string;
  [ColumnKey.Address2]?: string;
  [ColumnKey.City]?: string;
  [ColumnKey.State]?: string;
  [ColumnKey.PostalCode]?: string;
  [ColumnKey.Country]?: string;
  [ColumnKey.ShippingCountry]?: string;
  [ColumnKey.OrderStatus]?: string;
  [ColumnKey.Quantity]?: string;
  [ColumnKey.Products]?: string;
  [ColumnKey.ShipmentStatus]?: string;
  [ColumnKey.TrackingNumber]?: string;
  [ColumnKey.PromoCode]?: string;
  [ColumnKey.PromoCodeStatus]?: string;
  [ColumnKey.AffiliateLink]?: string;
};

const statusColors = {
  Order: {
    'Pending Request': {
      bgColor: 'bg-blue-2',
      textColor: 'text-[#2D5EA0]',
    },
    'Review Needed': {
      bgColor: 'bg-yellow-2',
      textColor: 'text-[#9C7512]',
    },
    'Processing Order': {
      bgColor: 'bg-blue-2',
      textColor: 'text-[#2D5EA0]',
    },
    'Delivering Order': {
      bgColor: 'bg-green-2',
      textColor: 'text-[#27593B]',
    },
  },
  Brief: {
    'Review Edits': {
      bgColor: 'bg-yellow-2',
      textColor: 'text-[#16282D]',
    },
    Agreed: {
      bgColor: 'bg-green-2',
      textColor: 'text-[#16282D]',
    },
    'Waiting for Agreement': {
      bgColor: 'bg-blue-2',
      textColor: 'text-[#16282D]',
    },
    Canceled: {
      bgColor: 'bg-[#E5E8EB]',
      textColor: 'text-[#16282D]',
    },
  },
  Deliverable: {
    'Review Needed': {
      bgColor: 'bg-yellow-2',
      textColor: 'text-[#16282D]',
    },
    'In GCR': {
      bgColor: 'bg-yellow-2',
      textColor: 'text-[#16282D]',
    },
    Complete: {
      bgColor: 'bg-green-2',
      textColor: 'text-[#16282D]',
    },
    'Pending Submission': {
      bgColor: 'bg-blue-2',
      textColor: 'text-[#16282D]',
    },
  },
};
const getSocialChannelIcon = (channel: string) => {
  switch (channel?.toLowerCase()) {
    case 'pinterest':
      return <PinterestPIcon className="w-3 h-3 text-grey-6" />;
    case 'twitter':
      return <TwitterIcon className="w-3 h-3 text-grey-6" />;
    case 'facebook':
      return <FacebookIcon className="w-3 h-3 text-grey-6" />;
    case 'instagram':
      return <InstagramIcon className="w-3 h-3 text-grey-6" />;
    case 'youtube':
      return <YoutubeIcon className="w-3 h-3 text-grey-6" />;
    case 'tiktok':
      return <TiktokIcon className="w-3 h-3 text-grey-6" />;
    default:
      return <ImageIcon className="w-3 h-3 text-grey-6" />;
  }
};

const SOCIAL_FIELD_NAMES = ['Instagram', 'TikTok', 'YouTube', 'Pinterest', 'Facebook', 'Twitter', 'Blog'];

const TAG_COLORS = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'mauve',
  'coral',
  'apricot',
  'marigold',
  'butter',
  'apple',
  'asparagus',
  'tiffany',
  'baby',
  'carolina',
  'rose',
  'watermelon',
  '#f50',
  '#2db7f5',
  '#87d068',
  '#108ee9',
];

export const useFlexTableSelectedColumns = ({
  selectedColumns,
  cellActionsByColumn,
  counts,
  worklets,
  onTagRemoved,
  onGroupRemoved,
  onProjectRemoved,
  onCellModalOpen,
  projectId,
  toggleColumns,
  setToggleColumns,
  members,
  handleOpenDeliverables,
  excludeColumns,
}: {
  worklets: TWorklet[];
  counts: ICounts;
  cellActionsByColumn?: {
    name: (memberId: IMember['id']) => void;
    lastMessage: (threadId: IThread['id']) => void;
    cta: (workItem: TWorkItem) => void;
    task: THandleTaskSelected;
    onComplexColumnsClick: (member: MemberTableRow, complexColumn: ComplexColumns) => void;
  };
  projectId?: number;
  selectedColumns: IField[];
  onTagRemoved: (tagId: number, memberId: number, member: IMember) => void;
  onGroupRemoved: (groupId: number, memberId: number) => void;
  onProjectRemoved: (projectId: number, memberId: number) => void;
  onCellModalOpen: (cellModalType: CellModalType, member: IMember) => void;
  toggleColumns: {
    [key: string]: boolean;
  };
  setToggleColumns: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  members: MemberSearchQuery_members[];
  handleOpenDeliverables: (memberId: number) => void;
  excludeColumns?: ColumnKey[];
}) => {
  const {
    [ClientFeature.FLEX_EXPANDABLE_COLUMN]: isFlexExpandableColumnEnabled,
    [ClientFeature.RENAME_TERMS]: isRenameTermsEnabled,
  } = useClientFeatures();
  const isPFAColumnsEnabled =
    useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS) || isFlexExpandableColumnEnabled;
  const [removeMemberFromTag] = useRemoveMembersFromTagsMutation();
  const [removeMemberFromCommunity] = useModifyCommunityMembersMutation('remove');
  const [removeMemberFromProgram] = useModifyProgramMembersMutation('remove');
  const memberColumns = useMemberColumns(undefined, undefined, excludeColumns);
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();
  const history = useHistory();

  const schemasByName = React.useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );

  const socialSchemaIds = useMemo(
    () =>
      new Set<string>(
        map(
          filter(memberColumns, (column) => SOCIAL_FIELD_NAMES.includes(column.headerName)),
          (column) => toString(column.field),
        ),
      ),
    [memberColumns],
  );

  const deleteTagFromMember = useCallback(
    async (tagId: number, memberId: number, member: IMember) => {
      try {
        await removeMemberFromTag({
          variables: {
            memberIds: [memberId],
            tagIds: [tagId],
          },
        });
        onTagRemoved(tagId, memberId, member);
        showSuccessMessage('Tag removed from member', MSG_DURATION);
      } catch (error) {
        showErrorMessage(getErrorMessageFromGraphQL(error as ApolloError), MSG_DURATION);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSuccessMessage, showErrorMessage],
  );

  const deleteProjectFromMember = useCallback(
    async (programId: number, memberId: number) => {
      try {
        await removeMemberFromProgram({
          variables: {
            memberIds: [memberId],
            programIds: [programId],
          },
        });
        onProjectRemoved(programId, memberId);
        showSuccessMessage('Member removed from project', MSG_DURATION);
      } catch (error) {
        showErrorMessage(getErrorMessageFromGraphQL(error as ApolloError), MSG_DURATION);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSuccessMessage, showErrorMessage],
  );

  const deleteGroupFromMember = useCallback(
    async (groupId: number, memberId: number) => {
      try {
        await removeMemberFromCommunity({
          variables: {
            memberIds: [memberId],
            communityIds: [groupId],
          },
        });
        onGroupRemoved(groupId, memberId);
        showSuccessMessage('Member removed from group', MSG_DURATION);
      } catch (error) {
        showErrorMessage(getErrorMessageFromGraphQL(error as ApolloError), MSG_DURATION);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSuccessMessage, showErrorMessage],
  );

  const handleProjectClick = useCallback(
    (projectId) => {
      history.push(`/projects/${projectId}`);
    },
    [history],
  );

  const handleGroupClick = useCallback(
    (groupId) => {
      history.push({
        pathname: '/member_table',
        search: qs.stringify({
          communityId: groupId,
        }),
      });
    },
    [history],
  );

  const { sourceConfigs } = useMembershipSources();

  const specialRenders = useCallback(
    (col: IField, iteration: number | null = null) => {
      if (col?.field === ColumnKey.Name) {
        return {
          id: ColumnKey.Name,
          cell: (rowData) => (
            <NameCell
              imageUrl={rowData.row.original.profilePicture}
              name={rowData.row.original.name}
              onClick={() => {
                cellActionsByColumn?.name?.(rowData.row.original.id);
              }}
            />
          ),
          size: 220,
        };
      }
      if (col?.field === 'appliedDate') {
        return {
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            const membershipData = find(
              member.programMembershipsLogs,
              (d) => d.status === 'new' && d.programId === projectId,
            );
            if (!membershipData?.createdDate) {
              return null;
            }
            return formatDate(membershipData.createdDate);
          },
        };
      }
      if (col?.field === ColumnKey.DateCompleted) {
        return {
          cell: (rowData) => {
            return formatDate(rowData.row.original.work_item__date_completed);
          },
          sortingFn: (rowA, rowB, columnId) => {
            const dateA = new Date(rowA.getValue(columnId));
            const dateB = new Date(rowB.getValue(columnId));
            return dateA.getTime() - dateB.getTime();
          },
        };
      }
      if (col?.field === ColumnKey.LastMessage) {
        return {
          size: 220,
          cell: (rowData) => (
            <div className={styles.fixedCell}>
              <LastMessageCell
                messageData={rowData.row.original?.lastMessage}
                member={rowData.row.original}
                onClick={() => {
                  cellActionsByColumn?.lastMessage?.(rowData.row.original.lastMessage.threadId);
                }}
                isFlexTable
              />
            </div>
          ),
        };
      }
      if (col?.field === ColumnKey.CTA) {
        return {
          cell: (rowData) => {
            const ctaData = rowData.row.original?.[ColumnKey.CTA];
            const isFixTerms = ctaData?.taskMetaData?.bulkCTADeepLink?.includes('fix_terms');
            let label = ctaData?.taskMetaData?.singleCTAText;
            if (isFixTerms) {
              label = `Move to Send ${isRenameTermsEnabled ? 'Briefs' : 'Terms'}`;
            }
            if (!label) {
              return null;
            }
            return (
              <div className={styles.fixedCell}>
                <Tooltip title={isFixTerms ? "Please fix the errors in creator's social account and try again" : ''}>
                  <Button
                    type="link"
                    onClick={() => {
                      cellActionsByColumn?.cta?.(ctaData?.workItem);
                    }}
                    style={{ padding: 0 }}
                  >
                    {label || 'Mark as Done'}
                  </Button>
                </Tooltip>
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.WorkletTaskName) {
        return {
          cell: (rowData) => {
            const task = rowData.row.original?.[ColumnKey.WorkletTaskName];
            if (isNil(task?.taskName)) return null;

            const error = hasError(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
            const notification = hasNotification(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
            let color = 'processing'; // Blue
            if (error) {
              color = 'error'; // red
            } else if (notification) {
              color = 'warning'; // Yellow
            }

            return (
              <RefreshTag
                onClick={() => cellActionsByColumn?.task?.(task.workletSpecUri, task.taskId)}
                label={task.taskName}
                bgColor={color === 'processing' ? 'bg-blue-2' : color === 'error' ? 'bg-red-2' : 'bg-yellow-2'}
                textColor={color === 'processing' ? 'text-blue-6' : color === 'error' ? 'text-red-6' : 'text-yellow-6'}
              />
            );
          },
        };
      }
      if (col?.field === ColumnKey.WorkletName) {
        return {
          cell: (rowData) => {
            const data = rowData.row.original?.[ColumnKey.WorkletName];
            return (
              <Button
                type="link"
                onClick={() => {
                  cellActionsByColumn?.task?.(data?.workletSpecUri, data.taskId);
                }}
                style={{ padding: 0 }}
              >
                {getWorkletNameBySpecURI(data?.workletSpecUri, worklets)}
              </Button>
            );
          },
        };
      }
      if (col?.field === ColumnKey.Owners) {
        return {
          cell: (rowData) => {
            const { owners } = rowData.row.original?.member || {};
            return (
              <div className={styles.fixedCell}>
                <OwnersCell owners={owners} />
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.Tags) {
        return {
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            const tags = member?.[ColumnKey.Tags];
            const max = size(tags[0]?.name) + size(tags[1]?.name);
            return (
              <div className={styles.tagsCell}>
                <TagGroup maxCharCount={max}>
                  {map(tags, (tag) => {
                    const color = TAG_COLORS[tag.id % size(TAG_COLORS)];
                    return (
                      <Tag
                        onClose={() => deleteTagFromMember(Number(tag.id), Number(member.id), member)}
                        closable
                        color={color}
                      >
                        {tag[col?.itemProp || 'name']}
                      </Tag>
                    );
                  })}
                </TagGroup>
                <Tooltip title="Add Tag">
                  <Button
                    className={styles.addTagIcon}
                    icon={<PlusIcon />}
                    size="small"
                    onClick={() => {
                      onCellModalOpen('tags', member);
                    }}
                  />
                </Tooltip>
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.Programs) {
        return {
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            const projects = member?.[ColumnKey.Programs];
            const max = size(projects[0]?.title);
            return (
              <div className={styles.tagsCell}>
                <TagGroup maxCharCount={max}>
                  {map(projects, (project) => (
                    <Tag
                      color="processing"
                      className={styles.clickableTag}
                      onClose={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                          open: false,
                          title: 'Remove Member from Project?',
                          onOk: () => deleteProjectFromMember(Number(project.id), Number(member.id)),
                          okText: 'Remove',
                          okButtonProps: { danger: true },
                          cancelText: 'Cancel',
                        });
                      }}
                      onClick={() => handleProjectClick(project.id)}
                      closable
                    >
                      {project[col?.itemProp || '']}
                    </Tag>
                  ))}
                </TagGroup>
                <Tooltip title="Add to Project">
                  <Button
                    className={styles.addTagIcon}
                    icon={<PlusIcon />}
                    size="small"
                    onClick={() => {
                      onCellModalOpen('projects', member);
                    }}
                  />
                </Tooltip>
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.Communities) {
        return {
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            const groups = member?.[ColumnKey.Communities];
            const max = size(groups[0]?.title);
            return (
              <div className={styles.tagsCell}>
                <TagGroup maxCharCount={max}>
                  {map(groups, (group) => (
                    <Tag
                      color="processing"
                      className={styles.clickableTag}
                      onClose={() => deleteGroupFromMember(Number(group.id), Number(member.id))}
                      onClick={() => handleGroupClick(group.id)}
                      closable
                    >
                      {group[col?.itemProp || 'title']}
                    </Tag>
                  ))}
                </TagGroup>
                <Tooltip title="Add to Group">
                  <Button
                    className={styles.addTagIcon}
                    icon={<PlusIcon />}
                    size="small"
                    onClick={() => {
                      onCellModalOpen('groups', member);
                    }}
                  />
                </Tooltip>
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.OrderFulfillmentStatus) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (isEmpty(orders)) return null;

            const programOrders = filter(orders, (order) => order.programId === projectId);

            if (isEmpty(programOrders)) return null;

            const fulfillmentStatuses = map(orders, (order) => order?.fulfillmentStatus);
            const firstFulfillmentStatus = fulfillmentStatuses[0];
            return (
              <TagGroup maxCharCount={size(firstFulfillmentStatus)}>
                {map(fulfillmentStatuses, (fulfillmentStatus) => (
                  <Tag>{fulfillmentStatus}</Tag>
                ))}
              </TagGroup>
            );
          },
        };
      }
      if (col?.field === ColumnKey.OrderShipmentStatus) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (isEmpty(orders)) return null;

            const programOrders = filter(orders, (order) => order.programId === projectId);

            if (isEmpty(programOrders)) return null;

            const shipmentStatuses = map(programOrders, (order) => order?.shipmentStatus);
            const firstShipmentStatus = shipmentStatuses[0];
            return (
              <TagGroup maxCharCount={size(firstShipmentStatus)}>
                {map(shipmentStatuses, (shipmentStatus) => (
                  <Tag>{shipmentStatus}</Tag>
                ))}
              </TagGroup>
            );
          },
        };
      }
      if (col?.field === ColumnKey.OrderTrackingNumber) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (isEmpty(orders)) return null;

            const programOrders = filter(orders, (order) => order.programId === projectId);

            if (isEmpty(programOrders)) return null;

            const trackingNumbers = map(programOrders, (order) => order?.trackingNumber);
            const firstTrackingNumber = trackingNumbers[0];
            return (
              <TagGroup maxCharCount={size(firstTrackingNumber)}>
                {map(trackingNumbers, (trackingNumber) => (
                  <Tag>{trackingNumber}</Tag>
                ))}
              </TagGroup>
            );
          },
        };
      }
      if (col?.field === ColumnKey.OrderDate) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            const programOrders = filter(orders, (order) => order.programId === projectId);

            if (isEmpty(programOrders)) return null;

            const orderDates = map(programOrders, (order) => order?.orderDate);
            const firstOrderDate = formatDate(orderDates[0]);
            return (
              <TagGroup maxCharCount={size(firstOrderDate)}>
                {map(orderDates, (orderDate) => (
                  <Tag>{formatDate(orderDate)}</Tag>
                ))}
              </TagGroup>
            );
          },
        };
      }
      if (col?.field === ColumnKey.TalentAgents) {
        return {
          cell: (rowData) => {
            const talentAgents = rowData.row.original?.[ColumnKey.TalentAgents];
            if (isEmpty(talentAgents)) return null;
            const max = size(talentAgents[0]?.agentName) + size(talentAgents[1]?.agentName);
            return (
              <div className={styles.tagsCell}>
                <TagGroup maxCharCount={max}>
                  {map(talentAgents, (talentAgent) => (
                    <Tag icon={<UserIcon />}>{talentAgent.agentName}</Tag>
                  ))}
                </TagGroup>
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.OrderNumber) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (isEmpty(orders)) return null;

            const programOrders = filter(orders, (order) => order.programId === projectId);

            if (isEmpty(programOrders)) return null;

            const orderNumbers = map(programOrders, (order) => order?.orderNumber);

            const firstOrderNumber = orderNumbers[0];

            return (
              <TagGroup maxCharCount={size(toString(firstOrderNumber))}>
                {map(orderNumbers, (orderNumber) => (
                  <Tag>{toString(orderNumber)}</Tag>
                ))}
              </TagGroup>
            );
          },
        };
      }
      if (col?.field === ColumnKey.TotalOrderedProductsInPending) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (!orders) return null;
            let productsCount = 0;
            orders.forEach((order) => {
              order?.productsOrderedInPending?.forEach((product) => {
                productsCount += product?.quantity || 1;
              });
            });
            return <>{productsCount}</>;
          },
        };
      }
      if (col?.field === ColumnKey.Products) {
        return {
          title: 'Product Selected',
          size: 100,
          cell: (rowData) => (
            <RefEllipsisLabel align="left" width={col?.width || 84}>
              {!isEmpty(rowData.row.original?.[ColumnKey.Products]) &&
                rowData.row.original?.[ColumnKey.Products].join(', ')}
            </RefEllipsisLabel>
          ),
        };
      }
      if (col?.field === ColumnKey.ApplicantSource) {
        return {
          header: () => <ColumnHeader title="Applicant Source" />,
          cell: (rowData) => {
            const programMemberships = rowData.row.original?.programMemberships;
            let projectMembership;
            if (projectId) {
              projectMembership = filter(programMemberships, (membership) => membership.programId === projectId)[0];
            } else {
              projectMembership = first(programMemberships);
            }
            const sourceConfig = get(sourceConfigs, projectMembership?.utmSource);
            const sourceName = sourceConfig ? sourceConfig.name : 'Other';

            return <div>{sourceName}</div>;
          },
        };
      }
      if (col?.field === ColumnKey.TotalOrderedMarketValueInPending) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (isEmpty(orders)) return null;
            // TODO what if we have mutliple currencies?
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: orders[0]?.productsOrderedInPending?.[0]?.price_set?.presentment_money?.currency_code || 'USD',
            });
            let totalOrdersCost = 0;
            orders.forEach((order) => {
              order?.productsOrderedInPending?.forEach((product) => {
                totalOrdersCost += product?.price * (product?.quantity || 1) || 0;
              });
            });
            return <>{formatter.format(totalOrdersCost)}</>;
          },
        };
      }

      if (col?.field === ColumnKey.TotalShippedProducts) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (!orders) return null;
            let productsCount = 0;
            orders.forEach((order) => {
              order?.productsOrderedShopify?.forEach((product) => {
                productsCount += product?.quantity || 1;
              });
              order?.productsOrdered?.forEach((product) => {
                // this might not be the right value
                productsCount += product?.quantity || 1;
              });
            });
            return <>{productsCount}</>;
          },
        };
      }
      if (col?.field === ColumnKey.TotalShippedMarketValue) {
        return {
          cell: (rowData) => {
            const orders = rowData.row.original?.orders;
            if (isEmpty(orders)) return null;
            // TODO what if we have mutliple currencies?
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: orders[0]?.productsOrderedShopify?.[0]?.price_set?.presentment_money?.currency_code || 'USD',
            });
            let totalOrdersCost = 0;
            orders.forEach((order) => {
              order?.productsOrderedShopify?.forEach((product) => {
                totalOrdersCost += product?.price * (product?.quantity || 1) || 0;
              });
              order?.productsOrdered?.forEach((product) => {
                // this might not be the right value
                totalOrdersCost += product?.price * (product?.quantity || 1) || 0;
              });
            });
            return <>{formatter.format(totalOrdersCost)}</>;
          },
        };
      }
      if (col?.field === ColumnKey.Address) {
        return {
          size: 200,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            const address1 =
              schemasByName && member && member.fields[schemasByName[ColumnKey.Address1]?.schemaId || ''];
            const address2 =
              schemasByName && member && member.fields[schemasByName[ColumnKey.Address2]?.schemaId || ''];
            const city = schemasByName && member && member.fields[schemasByName[ColumnKey.City]?.schemaId || ''];
            const state = schemasByName && member && member.fields[schemasByName[ColumnKey.State]?.schemaId || ''];
            const postalCode =
              schemasByName && member && member.fields[schemasByName[ColumnKey.PostalCode]?.schemaId || ''];
            const country = schemasByName && member && member.fields[schemasByName[ColumnKey.Country]?.schemaId || ''];

            const combinedAddress = `${address1 || ''} ${address2 || ''} ${city || ''} ${state || ''} ${postalCode || ''
              } ${country || ''}`;
            return (
              <RefEllipsisLabel align="left" width={184} tooltip={combinedAddress}>
                {combinedAddress}
              </RefEllipsisLabel>
            );
          },
        };
      }
      if (col?.field === ColumnKey.ShippingCountry) {
        return {
          cell: (rowData) => (
            <RefEllipsisLabel align="left" width={col?.width || 100}>
              {rowData.row.original?.[ColumnKey.ShippingCountry]}
            </RefEllipsisLabel>
          ),
        };
      }
      if (socialSchemaIds.has(col?.field)) {
        return {
          cell: (rowData) => {
            const member = rowData.row.original._raw;
            return (
              <SocialProfileCell
                isEditable={false}
                isEditing={false}
                network={toLower(col?.headerName) as TNetworkIdentifier}
                onChangeValue={() => undefined}
                onToggleIsEditing={() => undefined}
                username={member?.[col?.field]}
                member={member}
              />
            );
          },
        };
      }
      if (col?.field === ColumnKey.SentCatalog) {
        return {
          cell: (rowData) => {
            const member = rowData.row.original;
            return (
              <Button
                type="link"
                onClick={
                  () =>
                    history.push(
                      pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', member?.sentCatalog?.id),
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                className={styles.leftAlignedButton}
              >
                {member?.sentCatalog?.name}
              </Button>
            );
          },
        };
      }
      if (col?.field === ColumnKey.SentSelectionRule) {
        return {
          cell: (rowData) => {
            const member = rowData.row.original;
            return (
              <Button
                type="link"
                onClick={() => {
                  history.push({
                    pathname: pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', member?.sentCatalog?.id),
                    search: `?${new URLSearchParams({ screen: 'selection-criteria' }).toString()}`,
                  });
                }}
                className={styles.leftAlignedButton}
              >
                {member?.sentSelectionRule?.name}
              </Button>
            );
          },
        };
      }
      if (col?.dbName === ColumnKey.OfferPromoCode || col?.name === ColumnKey.OfferPromoCode) {
        return {
          size: 200,
          cell: (rowData) => {
            const code = rowData.row.original?.promoCode;

            if (code) {
              return (
                <RefEllipsisLabel align="left" width={184} tooltip={code}>
                  {code}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.OfferSalesLink || col?.name === ColumnKey.OfferSalesLink) {
        return {
          size: 200,
          cell: (rowData) => {
            const link = rowData.row.original?.link;

            if (link) {
              return (
                <RefEllipsisLabel align="left" width={184} tooltip={link}>
                  {link}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.Order || col?.name === ColumnKey.Order) {
        return {
          size: 500,
          cell: (rowData) => {
            const orderStatus = rowData.row.original?.[`pfaOrderStatus_${iteration}`];
            const textColor = statusColors?.Order?.[orderStatus]?.textColor;
            const bgColor = statusColors?.Order?.[orderStatus]?.bgColor;
            if (orderStatus) {
              return <RefreshTag label={orderStatus} bgColor={bgColor} textColor={textColor} />;
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.TotalCost || col?.name === ColumnKey.TotalCost) {
        return {
          size: 150,
          cell: (rowData) => {
            const totalCost = rowData.row.original?.[`pfaTotalCost_${iteration}`];

            if (totalCost) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {`$${totalCost}`}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.ProductCount || col?.name === ColumnKey.ProductCount) {
        return {
          size: 150,
          cell: (rowData) => {
            const productCount = rowData.row.original?.[`pfaProductsCount_${iteration}`];

            if (productCount) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {productCount}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (
        (col?.dbName === ColumnKey.DateSent || col?.name === ColumnKey.DateSent) &&
        col?.metaData?.superColumnName === 'PFA'
      ) {
        return {
          size: 100,
          cell: (rowData) => {
            const dateSent = rowData.row.original?.[`pfaDateSent_${iteration}`];

            if (dateSent) {
              return (
                <RefEllipsisLabel align="center" width={84}>
                  {dateSent}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.Brief || col?.name === ColumnKey.Brief) {
        return {
          size: 200,
          maxSize: 200,
          minSize: 200,
          cell: (rowData) => {
            const briefStatus = rowData.row.original?.[`briefStatus_${iteration}`];
            const textColor = statusColors?.Brief?.[briefStatus]?.textColor;
            const bgColor = statusColors?.Brief?.[briefStatus]?.bgColor;
            if (briefStatus) {
              return <RefreshTag label={briefStatus} bgColor={bgColor} textColor={textColor} />;
            }
            return null;
          },
        };
      }
      if (
        (col?.dbName === ColumnKey.BriefDateSent || col?.name === ColumnKey.BriefDateSent) &&
        col?.metaData?.superColumnName === 'Brief'
      ) {
        return {
          size: 150,
          cell: (rowData) => {
            const dateSent = rowData.row.original?.[`briefSentDate_${iteration}`];

            if (dateSent) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {dateSent}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (
        (col?.dbName === ColumnKey.BriefAcceptedDate || col?.name === ColumnKey.BriefAcceptedDate) &&
        col?.metaData?.superColumnName === 'Brief'
      ) {
        return {
          size: 150,
          cell: (rowData) => {
            const agreedDate = rowData.row.original?.[`briefAcceptedDate_${iteration}`];

            if (agreedDate) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {agreedDate}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.BriefDeliverablesCount || col?.name === ColumnKey.BriefDeliverablesCount) {
        return {
          size: 150,
          cell: (rowData) => {
            const count = rowData.row.original?.[`briefDeliverableCount_${iteration}`];

            if (count) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {count}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.BriefPayment || col?.name === ColumnKey.BriefPayment) {
        return {
          size: 110,
          cell: (rowData) => {
            const payment = rowData.row.original?.[`briefPayment_${iteration}`];

            if (payment) {
              return (
                <RefEllipsisLabel align="right" width={84}>
                  {`$${payment}`}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (
        (col?.dbName === ColumnKey.BriefLastActivity || col?.name === ColumnKey.BriefLastActivity) &&
        col?.metaData?.superColumnName === 'Brief'
      ) {
        return {
          size: 150,
          cell: (rowData) => {
            const lastActivityDate = rowData.row.original?.[`briefLastActivity_${iteration}`];

            if (lastActivityDate) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {lastActivityDate}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (
        (col?.dbName === ColumnKey.BriefLastReviewer || col?.name === ColumnKey.BriefLastReviewer) &&
        col?.metaData?.superColumnName === 'Brief'
      ) {
        return {
          size: 150,
          cell: (rowData) => {
            const lastReviewer = rowData.row.original?.[`briefLastReviewer_${iteration}`];
            if (lastReviewer) {
              return (
                <Avatar.Group maxCount={3}>
                  <UserAvatar name={lastReviewer} tooltipTitle={lastReviewer} />
                </Avatar.Group>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.DeliverablePostDate || col?.name === ColumnKey.DeliverablePostDate) {
        return {
          size: 150,
          cell: (rowData) => {
            const postDate = rowData.row.original?.[`deliverablePostDate_${iteration}`];

            if (postDate) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {postDate}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.DeliverableLastActivity || col?.name === ColumnKey.DeliverableLastActivity) {
        return {
          size: 200,
          cell: (rowData) => {
            const lastActivity = rowData.row.original?.[`deliverableLastActivity_${iteration}`];

            if (lastActivity) {
              return (
                <RefEllipsisLabel align="right" width={184}>
                  {lastActivity}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.DeliverableLiveLink || col?.name === ColumnKey.DeliverableLiveLink) {
        return {
          size: 150,
          cell: (rowData) => {
            const liveLink = rowData.row.original?.[`deliverableLiveLink_${iteration}`];

            if (liveLink) {
              return (
                <RefEllipsisLabel align="left" width={134}>
                  {liveLink !== '-' ? (
                    <a href={liveLink} target="_blank" rel="noopener noreferrer" className="text-grey-6 underline">
                      {liveLink}
                    </a>
                  ) : (
                    '-'
                  )}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.DeliverableNextDueDate || col?.name === ColumnKey.DeliverableNextDueDate) {
        return {
          size: 150,
          cell: (rowData) => {
            const nextDueDate = rowData.row.original?.[`deliverableNextDueDate_${iteration}`];

            if (nextDueDate) {
              return (
                <RefEllipsisLabel align="right" width={134}>
                  {nextDueDate}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.DeliverableAdsCode || col?.name === ColumnKey.DeliverableAdsCode) {
        return {
          size: 100,
          cell: (rowData) => {
            const adsCode = rowData.row.original?.[`deliverableAdsCode_${iteration}`];

            if (adsCode) {
              return (
                <RefEllipsisLabel align="left" width={84}>
                  {adsCode}
                </RefEllipsisLabel>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.DeliverableLastReviewer || col?.name === ColumnKey.DeliverableLastReviewer) {
        return {
          size: 150,
          cell: (rowData) => {
            const lastReviewer = rowData.row.original?.[`deliverableLastReviewer_${iteration}`];

            if (lastReviewer) {
              return (
                <Avatar.Group maxCount={3}>
                  <UserAvatar name={lastReviewer} tooltipTitle={lastReviewer} />
                </Avatar.Group>
              );
            }
            return null;
          },
        };
      }
      if (col?.dbName === ColumnKey.Deliverable || col?.name === ColumnKey.Deliverable) {
        return {
          size: 800,
          cell: (rowData) => {
            const deliverableStatus = rowData.row.original?.[`deliverable_${iteration}`];
            const deliverableSocialChannel = rowData.row.original?.[`deliverableSocialChannel_${iteration}`] || 'other';

            const textColor = statusColors?.Deliverable?.[deliverableStatus]?.textColor;
            const bgColor = statusColors?.Deliverable?.[deliverableStatus]?.bgColor;
            const socialIcon = deliverableStatus != '-' ? getSocialChannelIcon(deliverableSocialChannel) : null;

            if (deliverableStatus) {
              return (
                <RefreshTag
                  className="cursor-pointer"
                  label={deliverableStatus}
                  bgColor={bgColor}
                  textColor={textColor}
                  icon={socialIcon}
                  onClick={() => handleOpenDeliverables(rowData.row.original?.id)}
                />
              );
            }
            return null;
          },
        };
      }
      if (col?.field === ColumnKey.ComplexBriefs) {
        return {
          id: 'briefs',
          header: () => <ColumnHeader title="Briefs" />,
          size: 200,
          cell: (rowData) => {
            const briefs = rowData.row.original?.allBriefs || [];
            const visibleBriefs = briefs.slice(0, 2);
            const remainingCount = briefs.length - visibleBriefs.length;

            return (
              <div
                className="flex flex-wrap gap-1 cursor-pointer"
                onClick={() => cellActionsByColumn.onComplexColumnsClick(rowData.row.original, ComplexColumns.Briefs)}
              >
                {visibleBriefs.map((_, index) => (
                  <RefreshTag
                    key={index}
                    label={`Brief ${index + 1}`}
                    tooltip={`Brief ${index + 1}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                ))}
                {remainingCount > 0 && (
                  <RefreshTag
                    label={`+${remainingCount}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                )}
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.ComplexOrders) {
        return {
          id: 'orders',
          header: () => <ColumnHeader title="Product Orders" />,
          size: 200,
          cell: (rowData) => {
            const orders = rowData.row.original?.allOrders || [];
            const visibleOrders = orders.slice(0, 2);
            const remainingCount = orders.length - visibleOrders.length;

            return (
              <div
                className="flex flex-wrap gap-1 cursor-pointer"
                onClick={() => cellActionsByColumn?.onComplexColumnsClick(rowData.row.original, ComplexColumns.Orders)}
              >
                {visibleOrders.map((_, index) => (
                  <RefreshTag
                    key={index}
                    label={`Order ${index + 1}`}
                    tooltip={`Order ${index + 1}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                ))}
                {remainingCount > 0 && (
                  <RefreshTag
                    label={`+${remainingCount}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                )}
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.ComplexOffers) {
        return {
          id: 'offers',
          header: () => <ColumnHeader title="Offers" />,
          size: 300,
          cell: (rowData) => {
            const offerLink = rowData.row.original?.allOfferLinks || [];
            const offerPromo = rowData.row.original?.allOfferPromos || [];

            return (
              <div
                className="flex flex-wrap gap-1 cursor-pointer"
                onClick={() => cellActionsByColumn?.onComplexColumnsClick(rowData.row.original, ComplexColumns.Offers)}
              >
                {!!offerLink?.length &&
                  offerLink.map((link, index) => (
                    <RefreshTag
                      key={index}
                      label={link.link.length > 12 ? `${link.link.substring(0, 10)}..` : link.link}
                      tooltip={link.link}
                      className="py-2"
                      bgColor="bg-grey-2"
                      textColor="text-grey-6"
                    />
                  ))}
                {!!offerPromo?.length &&
                  offerPromo.map((promo, index) => (
                    <RefreshTag
                      key={index}
                      label={promo.promoCode.length > 12 ? `${promo.promoCode.substring(0, 10)}..` : promo.promoCode}
                      tooltip={promo.promoCode}
                      className="py-2"
                      bgColor="bg-grey-2"
                      textColor="text-grey-6"
                    />
                  ))}
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.ComplexDeliverables) {
        return {
          id: 'deliverables',
          header: () => <ColumnHeader title="Deliverables" />,
          size: 350,
          cell: (rowData) => {
            const deliverables = rowData.row.original?.allDeliverables || [];
            const visibleDeliverables = deliverables.slice(0, 2);
            const remainingCount = deliverables.length - visibleDeliverables.length;

            return (
              <div
                className="flex flex-wrap gap-1 cursor-pointer"
                onClick={
                  () => cellActionsByColumn?.onComplexColumnsClick(rowData.row.original, ComplexColumns.Deliverables)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                {visibleDeliverables.map((_, index) => (
                  <RefreshTag
                    key={index}
                    label={`Deliverable ${index + 1}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                ))}
                {remainingCount > 0 && (
                  <RefreshTag
                    label={`+${remainingCount}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                )}
              </div>
            );
          },
        };
      }
      if (col?.field === ColumnKey.ComplexTalentManager) {
        return {
          id: 'talentManagers',
          header: () => <ColumnHeader title="Talent Managers" />,
          size: 300,
          cell: (rowData) => {
            const talentManagers = rowData.row.original?.allTalentManagers || [];
            const visibleTalentManagers = talentManagers.slice(0, 2);
            const remainingCount = talentManagers.length - visibleTalentManagers.length;

            return (
              <div
                className="flex flex-wrap gap-1 cursor-pointer"
                onClick={
                  () => cellActionsByColumn?.onComplexColumnsClick(rowData.row.original, ComplexColumns.TalentManagers)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                {visibleTalentManagers.map((talentManager, index) => (
                  <RefreshTag
                    key={index}
                    label={
                      talentManager.agentName.length > 12
                        ? `${talentManager.agentName.substring(0, 10)}..`
                        : talentManager.agentName
                    }
                    tooltip={talentManager.agentName}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                ))}
                {remainingCount > 0 && (
                  <RefreshTag
                    label={`+${remainingCount}`}
                    className="py-2"
                    bgColor="bg-grey-2"
                    textColor="text-grey-6"
                  />
                )}
              </div>
            );
          },
        };
      }
      return null;
    },
    [
      onCellModalOpen,
      handleProjectClick,
      handleGroupClick,
      deleteTagFromMember,
      deleteGroupFromMember,
      deleteProjectFromMember,
      handleOpenDeliverables,
      socialSchemaIds,
      counts,
      worklets,
      cellActionsByColumn,
      projectId,
      schemasByName,
      sourceConfigs,
      history,
      isRenameTermsEnabled,
    ],
  );
  const configureFlexColumns = useCallback(
    (
      col: IField | undefined,
      result: ColumnDef<FlexMemberTableRow, unknown>[],
      iteration: number | null = null,
    ): void => {
      if (col?.hidden) return;
      const transformedKey: Record<string, ColumnKey> = {
        ShippingCountry: ColumnKey.ShippingCountry,
      };
      const field = iteration ? `${col?.field}_${iteration}` : col?.field;
      const isExpandableColumn = col?.metaData?.isSuperColumn || false;
      const isExpanded = (field && toggleColumns[field]) || false;
      const columnData = (col?.dbName && flexColumnDataMap[col?.dbName]) ||
        (col?.name && flexColumnDataMap[col?.name]) || { key: col?.field, color: 'transparent' };
      const columnKey = iteration
        ? `${columnData.key}_${iteration}`
        : (columnData?.key && transformedKey[columnData.key as keyof typeof transformedKey]) || columnData.key;
      const columnColor = columnData.color;
      const columnId = iteration ? `${col?.headerName}_${iteration}`.toString() : col?.headerName.toString();
      const props: Partial<ColumnDef<FlexMemberTableRow, unknown>> = {
        id: columnId,
        accessorKey: columnKey,
        header: () => (
          <ColumnHeader
            title={iteration ? `${col?.headerName || 'Column'} ${iteration}` : col?.headerName || 'Column'}
          />
        ),
        size: col?.width,
        minSize: col?.minWidth,
        maxSize: col?.maxWidth,
      };
      const specialRender = col && specialRenders(col, iteration);
      let rendered: ColumnDef<FlexMemberTableRow, unknown> = {
        ...props,
        ...(specialRender || {}),
        meta: isExpandableColumn
          ? {
            onToggleClick: () => {
              setToggleColumns((prev) => ({
                ...prev,
                [field as string]: !prev[field as string],
              }));
            },
            isColumnExpanded: isExpanded,
            isExpandableColumn,
            headerGroupBorder: {
              borderColor: columnColor,
            },
          }
          : {},
      } as ColumnDef<FlexMemberTableRow, unknown>;
      if (!specialRender) {
        rendered = {
          ...rendered,
          size: col?.width || 180,
          cell: (val) => {
            let value = val.row.original?.[props.accessorKey as keyof FlexMemberTableRow];
            if (isObject(value) && !isArray(value)) {
              if (!Number.isNaN(+(col?.field ?? ''))) {
                value = (value as any)?.fields?.[col?.field ?? ''] as string;
              } else {
                value = (value as any)?.[col?.field ?? ''] as string;
              }
            }
            // Format value based on column type
            if (col?.type === CustomFieldType.BOOLEAN) {
              value = value ? 'YES' : 'NO';
            } else if (col?.type === CustomFieldType.PERCENTAGE && !isNil(value)) {
              value = numeral(value).format('0.[00]%');
            } else if (col?.type === CustomFieldType.NUMBER && !isNil(value)) {
              value = (value as number).toLocaleString('en-US');
            } else if (includes([CustomFieldType.DATE, CustomFieldType.ANNUAL], col?.type)) {
              value = formatDate(value as string | number);
            } else if (col?.type === CustomFieldType.ARRAY && isArray(value)) {
              value = value.join(', ');
            } else if (isUndefined(value) || isObject(value) || isArray(value)) {
              value = '';
            }
            return (
              <RefEllipsisLabel align="left" width={col?.width ? col.width - 16 : 164} tooltip={value as string}>
                {value as string}
              </RefEllipsisLabel>
            );
          },
        };
      }
      result.push(rendered);
      if (isExpanded && col?.subColumns) {
        col.subColumns.forEach((subCol) => {
          if (subCol.id === col.id) return;
          const isSelectedSubcolumn = selectedColumns.find((selectedCol) => selectedCol?.id === subCol?.id);
          if (!isSelectedSubcolumn) return;
          const subColumnData = (subCol?.dbName && flexColumnDataMap[subCol.dbName]) ||
            (subCol?.name && flexColumnDataMap[subCol.name]) || { key: col?.field, color: 'transparent' };
          const subColumnKey = iteration ? `${subColumnData.key}_${iteration}` : subColumnData.key;
          const subColumnColor = subColumnData.color;
          const subColumnId = iteration
            ? `${subCol?.id || 'unknown'}_${iteration}`.toString()
            : (subCol?.id || 'unknown').toString();
          const subColumnProps: Partial<ColumnDef<FlexMemberTableRow, unknown>> = {
            id: subColumnId,
            accessorKey: subColumnKey,
            header: () => <ColumnHeader title={subCol?.headerName} />,
            size: subCol?.width || 150,
          };
          const subColumnSpecialRender = specialRenders(subCol, iteration);
          let subRendered: ColumnDef<FlexMemberTableRow, unknown> = {
            ...subColumnProps,
            ...(subColumnSpecialRender || {}),
            meta: {
              headerGroupBorder: {
                borderColor: subColumnColor,
              },
              greyBackground: true,
            },
          } as ColumnDef<FlexMemberTableRow, unknown>;
          if (!subColumnSpecialRender) {
            subRendered = {
              ...subRendered,
              cell: (val) => (
                <RefEllipsisLabel align="left" width={subCol?.width || 134}>
                  {val.row.original?.[subColumnKey as keyof FlexMemberTableRow] as string}
                </RefEllipsisLabel>
              ),
            };
          }
          result.push(subRendered);
        });
      }
    },
    [selectedColumns, toggleColumns, setToggleColumns, specialRenders],
  );

  const columns: ColumnDef<FlexMemberTableRow, unknown>[] = useMemo(() => {
    if (!isPFAColumnsEnabled || isEmpty(selectedColumns)) return [];
    const result: any = [];
    const maxPfaOrders = maxBy(members, (member) => member.pfaOrders?.length || 0)?.pfaOrders?.length || 0;
    const maxBriefs = maxBy(members, (member) => member.briefs?.length || 0)?.briefs?.length || 0;
    const maxDeliverables = maxBy(members, (member) => member.deliverables?.length || 0)?.deliverables?.length || 0;

    // Filter out excluded columns
    const filteredSelectedColumns = selectedColumns.filter((col) => !excludeColumns?.includes(col?.field as ColumnKey));

    filteredSelectedColumns.forEach((col) => {
      if (col?.name === 'Order' && maxPfaOrders >= 1) {
        for (let i = 1; i <= maxPfaOrders; i++) {
          configureFlexColumns(col, result, i);
        }
      } else if (col?.name === 'Brief' && maxBriefs >= 1) {
        for (let i = 1; i <= maxBriefs; i++) {
          configureFlexColumns(col, result, i);
        }
      } else if (col?.name === 'Deliverable' && maxDeliverables >= 1) {
        for (let i = 1; i <= maxDeliverables; i++) {
          configureFlexColumns(col, result, i);
        }
      } else {
        configureFlexColumns(col, result);
      }
    });
    return uniqBy(result, 'id') as any;
  }, [selectedColumns, isPFAColumnsEnabled, members, configureFlexColumns, excludeColumns]);

  return columns;
};
