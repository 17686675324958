import { DataTable } from '@frontend/app/refresh-components';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import React from 'react';
import { PostInsights } from '@frontend/gql/social/graphql';

export enum RowType {
  DEFAULT,
  MEMBER,
  INSTAGRAM_HANDLE,
  SOCIAL_HANDLES,
  CURRENCY,
  PERCENTAGE,
}

interface PostTableProps {
  selectedColumns: ColumnDef<Omit<PostInsights, 'memberId' | 'accountEntityId'>>[];
  globalFilter?: string | number;
  data: Omit<PostInsights, 'memberId' | 'accountEntityId'>[];
  paginationState: PaginationState;
  handleSortingChange: (sorting: SortingState) => void;
  sorting: SortingState;
}

const PostTable: React.FC<PostTableProps> = ({
  selectedColumns,
  data: postsInsights,
  paginationState,
  handleSortingChange,
  sorting,
  globalFilter,
}) => {
  return (
    <DataTable
      paginationState={paginationState}
      bordered
      paginated
      selectable
      sortable
      filterable
      onSelectionChange={(rows) => console.log(rows)}
      globalFilter={globalFilter}
      onSortingChange={(updaterOrValue) => {
        const newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
        handleSortingChange(newSorting);
      }}
      sorting={sorting}
      columns={selectedColumns}
      wrapperClassName="bg-primary-foreground"
      data={postsInsights}
      maxHeight="calc(100vh - 116px)"
    />
  );
};

export default PostTable;
