import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { CircleCheckIcon } from '@revfluence/fresh-icons/solid/esm';
import { TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip';
import { Tooltip } from '@frontend/shadcn/components/ui/tooltip';
import styles from '../../../../OfferForm.scss';
import { FormAsyncAction, IShopifyFormElementProps, OfferFormAsyncActions, PurchaseType } from '../../../../types';

interface IProps extends IShopifyFormElementProps<'purchaseType'> {
  handleFormAsyncActions: (action: FormAsyncAction) => void;
  value: string;
  connectedStore: Set<string>;
}

export const RefreshOfferPurchaseType: React.FC<Readonly<IProps>> = React.memo(
  ({ disabled, name, handleFormAsyncActions, value, connectedStore }) => {
    const rules = [{ required: true, message: 'Please select Purchase Type' }];

    const onchange = (type: string) => {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: name, value: type },
      });
    };

    return (
      <>
        <p className="text-grey-6 text-sm font-medium mb-2">Choose a purchase type for this offer</p>
        <Form.Item rules={disabled ? [] : rules} className="!m-0">
          <RadioGroup
            value={value}
            className="flex space-x-2 "
            onValueChange={(value) => onchange(value)}
          >
            {Object.values(PurchaseType).map((type) => (
              <label key={type} className={`${type === value ? `!border-grey-6 + ${styles.refreshPurchaseLabel}` : ''} flex items-center text-primary font-medium cursor-pointer border border-grey-2 rounded-lg h-8 px-4 relative`}>
                {type === value && (
                  <CircleCheckIcon className="bg-primary-foreground text-primary absolute top-[-5px] right-[-3px]" />)}

                {(disabled && (type === PurchaseType.SUBSCRIPTION || type === PurchaseType.BOTH)) ?
                  (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <RadioGroupItem
                          value={type}
                          disabled={disabled}
                          className="absolute border-none w-full h-full shadow-none"
                        />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs break-words text-center bg-grey-6 text-grey-0 p-[6px] rounded-lg">
                        <p> To create subscription-based offer, your connected Shopify store
                          {' '}
                          <strong>{Array.from(connectedStore).join(', ')}</strong>
                          {' '}
                          must have the subscription feature
                          enabled.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  )
                  :
                  (<RadioGroupItem
                    value={type}
                    disabled={disabled}
                    className="absolute border-none w-full h-full shadow-none"
                  />)}
                {type}
              </label>
            ))}
          </RadioGroup>

        </Form.Item>
      </>
    );
  }
);

RefreshOfferPurchaseType.displayName = 'RefreshOfferPurchaseType';
