import {
    Button, Form, Space, Tooltip, Input
} from 'antd';
import React, { useState, useCallback, useMemo, MouseEvent, useEffect } from 'react';
import { castArray } from 'lodash';
import {
    DeleteOutlined,
    CopyOutlined,
    SearchOutlined,
    InstagramOutlined,
    YoutubeOutlined,
    FacebookOutlined,
    TwitterOutlined,
    PictureOutlined,
    VideoCameraOutlined,
    FileTextOutlined,
    LinkOutlined,
    GlobalOutlined,
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import { useFindAllContentFields } from '@frontend/app/hooks/contentFields/useFindAllContentField';
import { useDeleteContentField } from '@frontend/app/hooks/contentFields/useDeleteContentField';
import { ContentFieldEnum, ContentType } from '@frontend/app/types/globalTypes';

import { useCreateContentField } from '@frontend/app/hooks/contentFields/useCreateContentField';
import { useUpdateContentField } from '@frontend/app/hooks/contentFields/useUpdateContentField';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CONTENT_FIELD_MUTATION } from '@frontend/app/queries';
import { LoadSpinner } from '@components';
import { useProgramsQuery } from '@frontend/app/hooks';
import Tag from '@frontend/app/refresh-components/Tag';
import { DataTable } from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import { MutationHookOptions } from '@apollo/react-hooks';
import {
    UpdateContentFieldMutation,
    UpdateContentFieldMutationVariables,
} from '@frontend/app/queries/types/UpdateContentFieldMutation';
import ContentFieldDrawer from './ContentFieldDrawer';
import { ContentFieldsFormValue, GetProgramsQuery_programs } from './types';

interface IProps {
    clientId: string;
    className?: string;
}

const DEFAULT_VALUE: ContentFieldsFormValue = {
    name: 'New Content Field',
    description: '',
    fieldType: ContentFieldEnum.TEXT,
    selectOptions: [],
    postTypes: [],
    programIds: [],
    isMandatory: true,
    canMarkNa: false,
    createdDate: null,
    updatedDate: null,
    clientId: '',
};

const ContentFields: React.FC<IProps> = React.memo((props) => {
    const { clientId } = props;
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState<ContentFieldsFormValue>(DEFAULT_VALUE);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const MAX_POST_TYPE_LIMIT = 10;

    const { loading: deletingContentField, deleteContentField } = useDeleteContentField();
    const { loading: updatingContentField, updateContentField } = useUpdateContentField();

    const { loading: loadingContentFields, contentFields, refetch: refetchContentFields } = useFindAllContentFields({
        variables: {
            data: {
                clientId,
            },
        },
    });

    const { loading: loadingPrograms, data: {
        programs,
    } = {} } = useProgramsQuery();

    // For deletion: use a custom hook that doesn't show success messages
    const useSilentUpdateContentField = (options: MutationHookOptions<UpdateContentFieldMutation, UpdateContentFieldMutationVariables> = {}) => {
        return useMutation<UpdateContentFieldMutation, UpdateContentFieldMutationVariables>(
            UPDATE_CONTENT_FIELD_MUTATION,
            options
        );
    };

    const [silentUpdateContentField, { loading: silentlyUpdatingContentField }] = useSilentUpdateContentField();

    const handleDelete = useCallback(
        async (e, record) => {
            e.stopPropagation();
            try {
                const cleanRecord = { ...record };
                delete (cleanRecord).__typename;
                delete cleanRecord.id;
                delete cleanRecord.createdDate;
                delete cleanRecord.updatedDate;

                await silentUpdateContentField({
                    variables: {
                        id: record.id,
                        data: {
                            ...cleanRecord,
                            name: `${record.name}_deleted_${Date.now()}`,
                            clientId,
                        },
                    },
                });
                await deleteContentField({
                    variables: {
                        id: record.id,
                    },
                });
            } catch (error) {
                console.error('Error during delete operation:', error);
            }
            await refetchContentFields();
        },
        [deleteContentField, silentUpdateContentField, refetchContentFields, clientId],
    );

    const handleDuplicate = useCallback((e, record) => {
        e.stopPropagation();
        const newRecord = {
            ...record,
            id: undefined,
            name: `${record.name} (copy)`,
        };
        delete newRecord.id;
        setFormData(newRecord);
        setVisible(true);
    }, [setFormData, setVisible]);

    const getRestrictedPostTypes = useCallback((contentFields) => {
        // @ts-ignore: Parameter 'counts' implicitly has an 'any' type
        const postTypeCounts = contentFields.reduce((counts, { postTypes = [] }) => {
            postTypes.forEach((postType) => {
                counts[postType] = (counts[postType] || 0) + 1;
            });
            return counts;
        }, Object.fromEntries(Object.keys(ContentType).map((field) => [field, 0])));
        // @ts-ignore: Parameter 'item' implicitly has an 'any' type
        const allPostTypeFields = contentFields.filter((item) => item.postTypes.length === 0).length;
        Object.keys(postTypeCounts).forEach((postType) => {
            postTypeCounts[postType] += allPostTypeFields;
        });
        const result = Object.keys(postTypeCounts).filter((postType) => postTypeCounts[postType] >= MAX_POST_TYPE_LIMIT);
        return result as ContentType[];
    }, []);

    const filteredContentFields = useMemo(() => {
        if (!searchText.trim()) return contentFields;

        return contentFields.filter((field) =>
            field.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (field.description && field.description.toLowerCase().includes(searchText.toLowerCase()))
        );
    }, [contentFields, searchText]);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            pageIndex: currentPage - 1,
        }));
    }, [currentPage]);

    const paginatedData = useMemo(() => {
        const startIndex = pagination.pageIndex * pagination.pageSize;
        return filteredContentFields.slice(startIndex, startIndex + pagination.pageSize);
    }, [filteredContentFields, pagination.pageIndex, pagination.pageSize]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    };

    const handleRowClick = useCallback((record: ContentFieldsFormValue) => {
        setVisible(true);
        setFormData(record);
    }, [setVisible, setFormData]);

    const columns: ColumnDef<ContentFieldsFormValue>[] = useMemo(() => ([
        {
            id: 'name',
            accessorKey: 'name',
            header: 'Content Field',
            cell: ({ row }) => (
              <a
                onClick={() => handleRowClick(row.original)}
                className="cursor-pointer text-primary hover:underline hover:text-primary"
              >
                {row.original.name}
              </a>
            ),
        },
        {
            id: 'postTypes',
            accessorKey: 'postTypes',
            header: 'Post Type',
            size: 210,
            minSize: 210,
            maxSize: 210,
            cell: ({ row }) => {
                const postTypes = row.original.postTypes;
                if (!postTypes) return null;
                const displayedTypes = postTypes.slice(0, 2);
                const remainingCount = postTypes.length - 2;
                return (
                  <div onClick={() => handleRowClick(row.original)} className="flex gap-2 cursor-pointer flex-wrap">
                    {displayedTypes.map((text: string) => {
                            const label = text
                                ?.split('_')
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ');

                            // Determine the appropriate icon based on content type
                            let icon = null;
                            const lowerText = text.toLowerCase();

                            if (lowerText.includes('instagram')) {
                                icon = <InstagramOutlined />;
                            } else if (lowerText.includes('youtube')) {
                                icon = <YoutubeOutlined />;
                            } else if (lowerText.includes('facebook')) {
                                icon = <FacebookOutlined />;
                            } else if (lowerText.includes('twitter')) {
                                icon = <TwitterOutlined />;
                            } else if (lowerText.includes('tiktok')) {
                                icon = <VideoCameraOutlined />;
                            } else if (lowerText.includes('pinterest')) {
                                icon = <PictureOutlined />;
                            } else if (lowerText.includes('blog')) {
                                icon = <FileTextOutlined />;
                            } else if (lowerText.includes('image')) {
                                icon = <PictureOutlined />;
                            } else if (lowerText.includes('video')) {
                                icon = <VideoCameraOutlined />;
                            } else if (lowerText === 'other') {
                                icon = <LinkOutlined />;
                            } else if (lowerText === 'flexible') {
                                icon = <GlobalOutlined />;
                            }

                            return (
                              <Tag
                                key={text}
                                bgColor="bg-grey-2"
                                textColor="text-primary"
                                icon={icon}
                                label={label.length > 15 ? `${label?.substring(0, 15)}...` : label}
                                tooltip={label}
                              />
                            );
                        })}
                    {remainingCount > 0 && (
                    <Tag
                      bgColor="bg-grey-2"
                      textColor="text-primary"
                      label={`+ ${remainingCount}`}
                      tooltip={postTypes.slice(2).map((type) =>
                                    type.split('_')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                        .join(' ')
                                ).join(', ')}
                    />
                        )}
                  </div>
                );
            },
        },
        {
            id: 'programIds',
            accessorKey: 'programIds',
            header: 'Project',
            cell: ({ row }) => {
                const programIds = row.original.programIds;
                if (!programIds || programIds.length === 0) return null;

                return (
                  <div onClick={() => handleRowClick(row.original)} className="flex gap-2 cursor-pointer flex-wrap">
                    {programIds.length > 0 && (() => {
                            const firstProgramId = programIds[0];
                            const firstProgram = programs?.find((p) => p.id === firstProgramId) as GetProgramsQuery_programs;
                            if (!firstProgram) return null;

                            const firstProgramTitle = firstProgram.title;
                            const displayTitle = firstProgramTitle.length > 15
                                ? `${firstProgramTitle.substring(0, 15)}...`
                                : firstProgramTitle;

                            return (
                              <Tag
                                bgColor="bg-grey-2"
                                textColor="text-primary"
                                label={displayTitle}
                                tooltip={firstProgramTitle}
                              />
                            );
                        })()}

                    {programIds.length > 1 && (() => {
                            const remainingCount = programIds.length - 1;
                            const remainingPrograms = programIds
                                .slice(1)
                                .map((id) => programs?.find((p) => p.id === id) as GetProgramsQuery_programs)
                                .filter(Boolean)
                                .map((p) => p.title);

                            const tooltipContent = remainingPrograms.join(', ');

                            return (
                              <Tag
                                bgColor="bg-grey-2"
                                textColor="text-primary"
                                label={`+ ${remainingCount}`}
                                tooltip={tooltipContent}
                              />
                            );
                        })()}
                  </div>
                );
            },
        },
        {
            id: 'fieldType',
            accessorKey: 'fieldType',
            header: 'Type',
            cell: ({ row }) => {
                const text = row.original.fieldType;
                return (
                  <div onClick={() => handleRowClick(row.original)} style={{ cursor: 'pointer' }}>
                    {(text as string)
                            ?.split('_')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')}
                  </div>
                );
            },
        },
        {
            id: 'actions',
            header: '',
            cell: ({ row }) => {
                const record = row.original;
                return (
                  <Space size="middle">
                    <Tooltip title="Copy">
                      <Button type="link" icon={<CopyOutlined />} onClick={(e) => handleDuplicate(e, record)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                                    await handleDelete(e, record);
                                }}
                      />
                    </Tooltip>
                  </Space>
                );
            },
        },
    ]), [handleDelete, handleDuplicate, programs, handleRowClick]);

    const showDrawer = useCallback(() => {
        setVisible(true);
    }, [setVisible]);

    const onClose = useCallback(() => {
        setVisible(false);
        form.resetFields();
        setFormData(DEFAULT_VALUE);
    }, [form, setVisible, setFormData]);

    const { loading: creatingContentField, createContentField } = useCreateContentField();

    const onFinish = useCallback(async (values: ContentFieldsFormValue) => {
        if (values.selectOptions) {
            values.selectOptions = values.selectOptions
                .map((option) => option?.trim())
                .filter((option) => option !== '' && option !== null && option !== undefined);
        }
        if (values.postTypes) {
            values.postTypes = values.postTypes.filter((type) => type !== null && type !== undefined);
        }
        if (formData.id) {
            await updateContentField({
                variables: {
                    id: formData.id,
                    data: {
                        ...values,
                        clientId,
                    },
                },
            });
        } else {
            await createContentField({
                variables: {
                    data: {
                        ...values,
                        clientId,
                    },
                },
            });
        }
        await refetchContentFields();
        form.resetFields();
        setFormData(DEFAULT_VALUE);
        onClose();
    }, [createContentField, updateContentField, refetchContentFields, form, formData, clientId, onClose]);

    const onFormChange = useCallback((changedValues: ContentFieldsFormValue) => {
        const selectOptions: string[] = castArray(changedValues.selectOptions || formData.selectOptions || []);
        const postTypes: ContentType[] = castArray(changedValues.postTypes || formData.postTypes || []);
        const programIds = changedValues.programIds || formData.programIds || [];
        const filteredSelectOptions = selectOptions.reduce((acc: string[], option) => {
            if (option === undefined || option === null || option === '') {
                if (acc.length === 0 || acc[acc.length - 1] !== option) {
                    acc.push(option);
                }
            } else {
                acc.push(option);
            }
            return acc;
        }, [] as string[]);

        changedValues = {
            ...changedValues,
            selectOptions: filteredSelectOptions,
            postTypes,
            programIds,
        };

        setFormData({
            ...formData,
            ...changedValues,
        });
    }, [formData, setFormData]);

    if (loadingContentFields || deletingContentField || updatingContentField || silentlyUpdatingContentField || loadingPrograms) {
        return (
          <div className="flex justify-center items-center h-full w-full">
            <LoadSpinner />
          </div>
        );
    }

    // Calculate pagination info
    const totalItems = filteredContentFields.length;
    const startItem = totalItems === 0 ? 0 : pagination.pageIndex * pagination.pageSize + 1;
    const endItem = Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalItems);
    const paginationInfo = `${startItem}-${endItem} of ${totalItems}`;

    return (
      <div className="flex flex-col w-full">
        <div className="flex flex-col rounded-2xl border border-grey-2 overflow-hidden border-b-0">
          <div className="flex items-center justify-between px-4 py-3">
            <div>
              <p className="text-sm text-gray-6 m-0">{filteredContentFields.length} Fields</p>
            </div>
            <div className="flex items-center gap-4">
              <div className="relative w-full max-w-[200px]">
                <Input
                  prefix={<SearchOutlined className="text-primary" />}
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                  className="rounded-full border-gray-300"
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500">{paginationInfo}</span>
                <Button
                  type="text"
                  icon={<LeftOutlined />}
                  disabled={pagination.pageIndex === 0}
                  onClick={() => {
                                    const newPageIndex = pagination.pageIndex - 1;
                                    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
                                    setCurrentPage(newPageIndex + 1);
                                }}
                  className="flex items-center justify-center h-8 w-8 border border-gray-300 rounded"
                />
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  disabled={pagination.pageIndex >= Math.ceil(filteredContentFields.length / pagination.pageSize) - 1}
                  onClick={() => {
                                    const newPageIndex = pagination.pageIndex + 1;
                                    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
                                    setCurrentPage(newPageIndex + 1);
                                }}
                  className="flex items-center justify-center h-8 w-8 border border-gray-300 rounded"
                />
              </div>
              <Button type="primary" onClick={showDrawer}>
                New Field
              </Button>
            </div>
          </div>

          <ContentFieldDrawer
            formData={formData}
            visible={visible}
            onClose={onClose}
            onFinish={onFinish}
            form={form}
            restrictedPostTypes={getRestrictedPostTypes(contentFields)}
            creatingContentField={creatingContentField}
            updatingContentField={updatingContentField}
            loadingContentFields={loadingContentFields}
            onDelete={handleDelete}
            onFormChange={onFormChange}
            programs={programs}
          />

          <DataTable
            key={`content-field-table-${pagination.pageIndex}`}
            columns={columns}
            data={paginatedData}
            bordered
            sortable
            wrapperClassName="content-fields-table last-row-no-border"
            maxHeight="75vh"
          />
          <style dangerouslySetInnerHTML={{
                    __html: `
                    .last-row-no-border tr:last-child td {
                        border-bottom: none !important;
                    }
                ` }}
          />
        </div>
      </div>
    );
});

export default ContentFields;
