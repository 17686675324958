import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { BullseyeArrowIcon } from '@revfluence/fresh-icons/regular/esm';
import { ValueWithPeriodInput } from '@frontend/app/refresh-components/ValueWithPeriodInput';
import { SectionCard } from '../components/SectionCard';
import { useCampaign } from '../CampaignContext';
import { ProgramTimePeriod } from '../CampaignContext';

export const GoalsSection: React.FC = () => {
  const {
    salesGoal,
    salesGoalTimePeriod,
    creatorCount,
    creatorsTimePeriod,
    setSalesGoal,
    setCreatorCount,
  } = useCampaign();

  return (
    <SectionCard>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <BullseyeArrowIcon />
            <P>Goals</P>
          </div>
          <H3 className="font-medium mb-0">How do you want to measure success?</H3>
        </div>

        <div className="flex gap-4">
          <div className="flex flex-col gap-2 flex-1">
            <label className="font-medium">What is your sales goal?</label>
            <ValueWithPeriodInput
              value={salesGoal}
              period={salesGoalTimePeriod || ProgramTimePeriod.PER_MONTH}
              onValueChange={setSalesGoal}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="font-medium">How many creators do you plan to work with?</label>
            <ValueWithPeriodInput
              value={creatorCount}
              period={creatorsTimePeriod || ProgramTimePeriod.PER_MONTH}
              onValueChange={setCreatorCount}
              prefix=""
              placeholder="100"
            />
          </div>
        </div>
      </div>
    </SectionCard>
  );
};
