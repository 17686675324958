import { useCallback } from 'react';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { ColumnDefinitionInput, MappedColumn } from '..';

export interface UseGenericTableConfig<TData> {
  selectedColumns;
  setSelectedColumns;
  data: TData[];
  currentPage: number;
  pageSize: number;
  handleSortingChange: (sorting: SortingState) => void;
  sorting: SortingState;
  globalFilter: string;
  setIsPartialLoading: (isPartialLoading: boolean) => void;
}

export const useGenericTable = <TData,>(config: UseGenericTableConfig<TData>) => {
  const {
    selectedColumns,
    setSelectedColumns,
    data,
    currentPage,
    pageSize,
    handleSortingChange,
    sorting,
    globalFilter,
    setIsPartialLoading,
  } = config;

  const handleExpandColumn = useCallback(
    (columnId: string) => {
      const toggleExpand = (columns: ColumnDefinitionInput[]): ColumnDefinitionInput[] => {
        return [...columns].map((column: ColumnDefinitionInput) => {
          if (column.id === columnId) {
            return { ...column, isColumnExpanded: !column.isColumnExpanded };
          }
          if (column.subColumns?.length) {
            return { ...column, subColumns: toggleExpand(column.subColumns) };
          }
          return column;
        });
      };
      setIsPartialLoading(true);
      setSelectedColumns((prevColumns: ColumnDefinitionInput[]) => toggleExpand(prevColumns));
    },
    [setSelectedColumns, setIsPartialLoading]
  );

  const handleDragEnd = useCallback(
    (fromIndex: number, toIndex: number, mappedColumnData: MappedColumn[]) => {
      if (fromIndex === toIndex) return;

      const movedColumnId = mappedColumnData[fromIndex].id;
      const movedColumn = selectedColumns.find((col) => col.id === movedColumnId);
      if (!movedColumn) {
        console.error('Column not found in selectedColumns');
        setSelectedColumns((prev) => [...prev]);
        return;
      }

      setSelectedColumns((prevColumns) => {
        const updatedColumns = [...prevColumns];
        const originalIndex = updatedColumns.findIndex((col) => col.id === movedColumnId);
        updatedColumns.splice(originalIndex, 1);

        let nextNonSubColumnIndex = toIndex;
        const requiresAdjustment = mappedColumnData[nextNonSubColumnIndex]?.isSubColumn && (fromIndex < toIndex);
        while (
          nextNonSubColumnIndex < mappedColumnData.length &&
          mappedColumnData[nextNonSubColumnIndex]?.isSubColumn
        ) {
          nextNonSubColumnIndex++;
        }

        const nextNonSubColumnId = mappedColumnData[nextNonSubColumnIndex]?.id;
        let targetIndexInPrevColumns = updatedColumns.findIndex((col) => col.id === nextNonSubColumnId);

        if (requiresAdjustment) targetIndexInPrevColumns--;

        if (targetIndexInPrevColumns >= 0) {
          updatedColumns.splice(targetIndexInPrevColumns, 0, movedColumn);
        } else {
          updatedColumns.push(movedColumn);
        }

        return updatedColumns;
      });
    },
    [setSelectedColumns, selectedColumns]
  );

  const paginationState: PaginationState = {
    pageIndex: currentPage,
    pageSize,
  };

  return {
    tableProps: {
      data,
      selectedColumns,
      handleExpandColumn,
      handleDragEnd,
      paginationState,
      handleSortingChange,
      sorting,
      globalFilter,
    },
  };
};
