import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DateRangeFormData } from '../components/date-range-form';
import { FiltersFormData } from '../components/filters-form';
import { QueryVariables, GraphQLQueryVariables, DateType } from '../types';
import { Segment, SocialNetwork, SocialPostType } from '../../../../gql/social/graphql';
import { calculateDatesFromType } from '../../utils/dates';

interface UseAnalyticsParamsProps {
  clientId?: string;
  defaultVariables: Omit<QueryVariables, 'clientId'>;
  clientCreatedDate?: string;
}

const isValidSocialPostType = (value: string): value is SocialPostType =>
  [
    'INSTAGRAM_POST',
    'INSTAGRAM_REEL',
    'INSTAGRAM_STORY',
    'TIKTOK_VIDEO',
    'YOUTUBE_DEDICATED',
    'YOUTUBE_VIDEO',
    'YOUTUBE_SHORT',
    'PINTEREST_PIN',
  ].includes(value as SocialPostType);

const isValidSocialNetwork = (value: string): value is SocialNetwork =>
  ['INSTAGRAM', 'TIKTOK', 'PINTEREST', 'YOUTUBE'].includes(value as SocialNetwork);

const isValidSegment = (value: string | null): value is Segment =>
  value !== null && ['brandFans', 'macro', 'mega', 'micro', 'midTier', 'nano', 'unknown'].includes(value as Segment);

const isValidDateType = (value: string): value is DateType =>
  [
    'thisWeek',
    'lastWeek',
    'lastMonth',
    'monthToDate',
    'lastQuarter',
    'quarterToDate',
    'last6Months',
    'yearToDate',
    'allTime',
    'custom',
  ].includes(value as DateType);

const parseNumberArrayParam = (value: string | null, defaultValue: number[]): number[] => {
  if (!value) return defaultValue;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const parseArrayParam = <T extends string>(
  value: string | null,
  isValid: (v: string) => v is T,
  defaultValue: T[],
): T[] => {
  if (!value) return defaultValue;
  const values = value.split(',');
  return values.filter(isValid);
};

const parseStringArrayParam = (value: string | null, defaultValue: string[]): string[] => {
  if (!value) return defaultValue;
  return value.split(',').filter(Boolean);
};

export const useAnalyticsParams = ({ clientId, defaultVariables, clientCreatedDate }: UseAnalyticsParamsProps) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateUrlParams = useCallback(
    (variables: QueryVariables) => {
      const params = new URLSearchParams();
      params.set('dateType', variables.dateType);
      if (variables.dateType === 'custom') {
        if (variables.startDate) params.set('startDate', variables.startDate);
        if (variables.endDate) params.set('endDate', variables.endDate);
      }
      if (variables.postTypes.length) params.set('postTypes', variables.postTypes.join(','));
      if (variables.includeEstimates !== undefined) {
        params.set('includeEstimates', variables.includeEstimates.toString());
      }
      if (variables.networks.length) params.set('networks', variables.networks.join(','));
      if (variables.projectIds.length) params.set('projectIds', variables.projectIds.join(','));
      if (variables.groupIds.length) params.set('groupIds', variables.groupIds.join(','));
      if (variables.memberIds?.length) params.set('memberIds', variables.memberIds.join(','));
      // if (variables.includeUnassigned !== undefined) params.set('includeUnassigned', variables.includeUnassigned.toString());
      if (variables.segments?.length) params.set('segments', variables.segments.join(','));
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location.pathname],
  );

  const handleApplyFilters = useCallback(
    (formData: FiltersFormData) => {
      if (!clientId) return;

      const currentParams = Object.fromEntries(searchParams.entries());
      const dateType = isValidDateType(currentParams.dateType) ? currentParams.dateType : defaultVariables.dateType;

      const variables: QueryVariables = {
        clientId,
        dateType,
        postTypes: formData.postTypes.filter(isValidSocialPostType),
        includeEstimates: formData.includeEstimates,
        networks: formData.networks.filter(isValidSocialNetwork),
        ...(dateType === 'custom'
          ? {
              startDate: currentParams.startDate,
              endDate: currentParams.endDate,
            }
          : {}),
        projectIds: formData.projectIds,
        groupIds: formData.groupIds,
        // includeUnassigned: formData.includeUnassigned,
        segments: formData.segments,
      };
      updateUrlParams(variables);
    },
    [clientId, updateUrlParams, searchParams, defaultVariables],
  );

  const handleDateRangeSubmit = useCallback(
    (data: DateRangeFormData) => {
      if (!clientId) return;

      const currentIncludeEstimates = searchParams.get('includeEstimates');
      const includeEstimates =
        currentIncludeEstimates === undefined ? defaultVariables.includeEstimates : currentIncludeEstimates === 'true';

      const variables: QueryVariables = {
        clientId,
        dateType: data.dateType as DateType,
        postTypes: parseArrayParam(searchParams.get('postTypes'), isValidSocialPostType, defaultVariables.postTypes),
        includeEstimates,
        networks: parseArrayParam(searchParams.get('networks'), isValidSocialNetwork, defaultVariables.networks),
        projectIds: parseNumberArrayParam(searchParams.get('projectIds'), defaultVariables.projectIds),
        groupIds: parseNumberArrayParam(searchParams.get('groupIds'), defaultVariables.groupIds),
        segments: parseArrayParam(searchParams.get('segments'), isValidSegment, defaultVariables.segments),
      };

      if (data.dateType === 'custom' && data.dateRange?.from && data.dateRange?.to) {
        variables.startDate = data.dateRange.from;
        variables.endDate = data.dateRange.to;
      }

      updateUrlParams(variables);
    },
    [clientId, searchParams, updateUrlParams, defaultVariables],
  );

  const currentParams = Object.fromEntries(searchParams.entries());
  const dateType = isValidDateType(currentParams.dateType) ? currentParams.dateType : defaultVariables.dateType;

  const includeEstimates =
    currentParams.includeEstimates === undefined
      ? defaultVariables.includeEstimates
      : currentParams.includeEstimates === 'true';

  const urlVariables = useMemo<QueryVariables>(
    // eslint-disable-next-line no-confusing-arrow
    () =>
      clientId
        ? {
            clientId,
            dateType,
            postTypes: parseArrayParam(currentParams.postTypes, isValidSocialPostType, defaultVariables.postTypes),
            includeEstimates,
            networks: parseArrayParam(currentParams.networks, isValidSocialNetwork, defaultVariables.networks),
            ...(dateType === 'custom'
              ? {
                  startDate: currentParams.startDate,
                  endDate: currentParams.endDate,
                }
              : {}),
            projectIds: parseNumberArrayParam(currentParams.projectIds, defaultVariables.projectIds),
            groupIds: parseNumberArrayParam(currentParams.groupIds, defaultVariables.groupIds),
            memberIds: parseStringArrayParam(currentParams.memberIds, defaultVariables.memberIds || []),
            // includeUnassigned: currentParams.includeUnassigned === 'true',
            segments: parseArrayParam(currentParams.segments, isValidSegment, defaultVariables.segments),
          }
        : {
            clientId: '',
            ...defaultVariables,
          },
    [clientId, dateType, currentParams, defaultVariables, includeEstimates],
  );

  // Convert URL variables to GraphQL variables by ensuring dates are set
  const queryVariables = useMemo<GraphQLQueryVariables>(() => {
    const dates =
      urlVariables.dateType === 'custom' && urlVariables.startDate && urlVariables.endDate
        ? {
            startDate: urlVariables.startDate,
            endDate: urlVariables.endDate,
          }
        : calculateDatesFromType(
            urlVariables.dateType,
            clientCreatedDate
              ? (() => {
                  const d = new Date(clientCreatedDate);
                  d.setUTCHours(0, 0, 0, 0);
                  return d;
                })()
              : undefined,
          );

    return {
      ...urlVariables,
      startDate: dates.startDate instanceof Date ? dates.startDate.toISOString().split('T')[0] : dates.startDate,
      endDate: dates.endDate instanceof Date ? dates.endDate.toISOString().split('T')[0] : dates.endDate,
    };
  }, [urlVariables, clientCreatedDate]);

  return {
    queryVariables,
    handleApplyFilters,
    handleDateRangeSubmit,
    updateUrlParams,
  };
};
