import * as React from 'react';

import { Form } from '@revfluence/fresh';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { FormAsyncAction, IShopifyFormElementProps, OfferFormAsyncActions } from '../../../../types';

interface IProps extends IShopifyFormElementProps<'specialLimitOnePerSale'> {
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  value: boolean;
  secureCodeValue?: boolean;
}

export const RefreshOfferCodeLimitOnePerCustomer: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  handleFormAsyncActions,
  secureCodeValue,
}) => {
  const onCheckChanges = (value: boolean) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value },
    });
  };
  return (
    <Form.Item className="!m-0 " name={name} valuePropName="checked">
      <div className="flex items-center gap-2">
        <Checkbox
          id="limitNewCustomer"
          checked={secureCodeValue || value}
          onCheckedChange={(checked) => onCheckChanges(checked === true)}
          disabled={disabled}
        />
        <label
          htmlFor="limitNewCustomer"
          className="text-sm font-medium text-primary"
        >
          Limit to one use per customer
        </label>
      </div>
    </Form.Item>
  );
});

RefreshOfferCodeLimitOnePerCustomer.displayName = 'RefreshOfferCodeLimitOnePerCustomer';
