import * as React from 'react';
import {
  Button,
  IButtonProps as ButtonProps,
  Progress,
  Space,
  Tooltip,
} from '@revfluence/fresh';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@revfluence/fresh-icons/solid/esm';

import styles from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard.scss';

interface IHiddenFooterProps {
  hideFooter: true;
}
interface IShowFooterProps {
  hideFooter: false;
  nextButtonDisabled: boolean;
  prevButtonDisabled: boolean;
  nextButtonLabel: React.ReactNode;
  prevButtonLabel: React.ReactNode;
  nextButtonLoading: boolean;
  nextButtonOnClick: ButtonProps['onClick'];
  prevButtonOnClick: ButtonProps['onClick'];
  progress: {
    current: number;
    total: number;
  };
  showNextButtonIcon: boolean;
  showPrevButtonIcon: boolean;
  showTooltipMessage: boolean;
  showProgress: boolean;
  tooltipMessage: React.ReactNode;
  showPrevButton: boolean;
  showContinueWithoutMessageButton?: boolean;
  continueWithoutMessageButtonLabel?: React.ReactNode;
  continueWithoutMessageButtonOnClick?: ButtonProps['onClick'];
  continueWithoutMessageButtonDisabled?: boolean;
  continueWithoutMessageButtonLoading?: boolean;
}

export type TProps = IHiddenFooterProps | IShowFooterProps;

export const TermsWizardFooter: React.FC<Readonly<TProps>> = React.memo((props) => {
  if (props.hideFooter) {
    return null;
  }

  const {
    nextButtonDisabled,
    prevButtonDisabled,
    nextButtonLabel,
    prevButtonLabel,
    nextButtonLoading,
    nextButtonOnClick,
    prevButtonOnClick,
    progress: {
      current: currentProgress,
      total: totalProgress,
    },
    showNextButtonIcon,
    showPrevButtonIcon,
    showTooltipMessage,
    tooltipMessage,
    showProgress,
    showPrevButton,
    showContinueWithoutMessageButton = false,
    continueWithoutMessageButtonLabel = 'Continue without Message',
    continueWithoutMessageButtonOnClick,
    continueWithoutMessageButtonDisabled = false,
    continueWithoutMessageButtonLoading = false,
  } = props as IShowFooterProps;

  const percent = parseFloat(
    ((currentProgress / (totalProgress)) * 100).toFixed(),
  );

  return (
    <div
      className={styles.footer}
      style={!showProgress ? { flex: '0 0 100px' } : {}}
    >
      { showProgress && (
      <div className={styles.progressWrapper}>
        <Progress
          className={styles.progress}
          percent={percent}
          showInfo={false}
        />
        <span className={styles.progressStatus}>
          {currentProgress}
          {' / '}
          {totalProgress - 1}
        </span>
      </div>
      )}
      <Space direction="horizontal" align="center" size="small">
        { showPrevButton && (
          <Button
            className={styles.footerButton}
            disabled={prevButtonDisabled}
            onClick={prevButtonOnClick}
            type="default"
          >
            {showPrevButtonIcon && (
              <ChevronLeftIcon />
            )}
            <span>{prevButtonLabel}</span>
          </Button>
        )}
        { showContinueWithoutMessageButton && (
          <Button
            className={styles.footerButton}
            disabled={continueWithoutMessageButtonDisabled}
            loading={continueWithoutMessageButtonLoading}
            onClick={continueWithoutMessageButtonOnClick}
            type="default"
          >
            <span>{continueWithoutMessageButtonLabel}</span>
          </Button>
        )}
        {
          showTooltipMessage && (
            <Tooltip placement="top" title={tooltipMessage}>
              <div>
                <Button
                  className={styles.footerButton}
                  disabled={nextButtonDisabled}
                  loading={nextButtonLoading}
                  onClick={nextButtonOnClick}
                  type="primary"
                >
                  <span>{nextButtonLabel}</span>
                  {showNextButtonIcon && (
                    <ChevronRightIcon />
                  )}
                </Button>
              </div>
            </Tooltip>
          )
        }
        { !showTooltipMessage && (
          <Button
            className={styles.footerButton}
            disabled={nextButtonDisabled}
            loading={nextButtonLoading}
            onClick={nextButtonOnClick}
            type="primary"
          >
            <span>{nextButtonLabel}</span>
            {showNextButtonIcon && (
              <ChevronRightIcon />
            )}
          </Button>
        )}
      </Space>
    </div>
  );
});

TermsWizardFooter.displayName = 'TermsWizardFooter';
