import React from 'react';
import { Tag } from '@frontend/app/refresh-components';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { Button } from '@frontend/shadcn/components/ui/button';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { useHistory } from 'react-router-dom';
import { TProject } from '../../types';
import { useCampaignForm } from '../hooks/useCampaignForm';
import { useCampaign } from '../CampaignContext';

interface Props {
  mode?: 'add' | 'edit';
  project?: TProject;
  onSave?: () => void;
  refetchProjects?: () => void;
  title?: string;
}

const useTooltipText = () => {
  const { campaignName, worklets } = useCampaign();

  if (!campaignName) {
    return 'Campaign name is required';
  }

  if (!worklets?.length) {
    return 'At least one workflow stage is required';
  }

  return null;
};

export const SaveCampaignCTA: React.FC<Props> = ({
  mode = 'add',
  project,
  onSave,
  refetchProjects,
  title,
}) => {
  const history = useHistory();
  const { handleCreateOrUpdateCampaign, isSubmitting } = useCampaignForm(
    mode,
    project,
    onSave,
    refetchProjects,
  );

  const { hasUnsavedChanges } = useCampaign();
  const tooltipText = useTooltipText();

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        <H3 className="mb-0 font-medium">
          {title || (mode === 'add' ? 'Setup a New Campaign' : 'Edit Campaign')}
        </H3>
        {hasUnsavedChanges && (
          <Tag
            label={(
              <div className="flex items-center gap-1">
                <CircleExclamationIcon />
                Unsaved Changes
              </div>
            )}
            textColor="text-warning"
          />
        )}
      </div>
      <div className="flex gap-2">
        <Button variant="outline" onClick={() => history.goBack()}>Cancel</Button>
        <Button
          onClick={handleCreateOrUpdateCampaign}
          disabled={!!tooltipText || !hasUnsavedChanges}
          loading={isSubmitting}
          tooltip={tooltipText}
        >
          {mode === 'add' ? 'Create Campaign' : 'Save Changes'}
        </Button>
      </div>
    </div>
  );
};
