import * as React from 'react';

import {
  IStatisticsCardData,
  OfferStatisticCardComponent,
} from '@affiliates/components';
import { TOfferStats } from '@affiliates/components/OfferDetails/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { DataFormat } from '@affiliates/utils';
import { includes } from 'lodash';
import { useEffect, useState } from 'react';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from '../OfferDetails/OfferDetails.scss';

const { useMemo } = React;

interface IProps {
  source: OFFER_SOURCE;
  stats: TOfferStats;
  isPromoLink?: boolean
}
export const OfferStatisticsCard: React.FC<Readonly<IProps>> = (props) => {
  const {
    source,
    stats,
    isPromoLink,
  } = props;

  const [showCurrency, setShowcurrency] = useState<boolean>(false);
  const { multiCurrency: isMulticurrency } = useClientFeatures();
  useEffect(() => {
    setShowcurrency(isMulticurrency && source === OFFER_SOURCE.SHOPIFY && stats.currencies.length === 1 && !includes(stats.currencies, 'USD'));
  }, [source, stats, isMulticurrency]);

  const cardData: IStatisticsCardData[] = useMemo(() => {
    if (stats === null) {
      return [];
    }
    const cardData = [
      ...(showCurrency ? [{
        title: 'Sales',
        prefix: '$',
        value: {
          valueInUsd: stats.sales,
          valueInBase: stats.salesBase,
          currency: stats.currencies[0],
        },
        format: DataFormat.Money,
      },
      {
        title: 'Avg. Sale',
        prefix: '$',
        value: {
          valueInUsd: stats.avgSale,
          valueInBase: stats.avgSaleBase,
          currency: stats.currencies[0],
        },
        format: DataFormat.Money,
      },
      {
        title: 'Commissions Earned ',
        prefix: '$',
        value: {
          valueInUsd: stats.payoutEarned,
          valueInBase: stats.payoutEarnedBase,
          currency: stats.currencies[0],
        },
        format: DataFormat.Money,
      }] : [
        {
          title: 'Sales',
          prefix: '$',
          value: stats.sales,
          format: DataFormat.Money,
        },
        {
          title: 'Avg. Sale',
          prefix: '$',
          value: stats.avgSale,
          format: DataFormat.Money,
        },
        {
          title: 'Commissions Earned ',
          prefix: '$',
          value: stats.payoutEarned,
          format: DataFormat.Money,
        },
      ]),
      {
        title: 'Payout',
        prefix: '$',
        value: stats.payoutMade,
        format: DataFormat.Money,
      },
      {
        title: 'Conversions',
        value: stats.conversions,
        format: DataFormat.Integer,
      },
    ];
    if (source === OFFER_SOURCE.TUNE || isPromoLink) {
      cardData.push({
        title: 'Clicks',
        value: stats.clicks,
        format: DataFormat.Integer,
      });
    }
    return cardData;
  }, [showCurrency, source, stats, isPromoLink]);
  return (
    <div className={styles.OfferStatisticsCard}>
      <OfferStatisticCardComponent className={styles.statsCard} data={cardData} showCurrency={showCurrency} />
    </div>
  );
};
