import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography, Row, Card, Space, Popover, Checkbox, Button,
} from '@revfluence/fresh';
import { CalendarCheckIcon, CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import moment from 'moment';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import { CalendarIcon } from 'lucide-react';
import styles from '../ManageOffer.scss';
import { TMember } from '../../MembersWizard/types';

const { Title, Text } = Typography;

type ActiveDate = {
  startDate: Date | null;
  endDate: Date | null;
  showEndDate: boolean;
};

interface IProps {
  data: readonly TMember[];
  activeDates: ActiveDate;
  handleActiveDatesChange: (key: string, value: Date | boolean) => void;
  setIsError: (value: boolean) => void;
}

const OfferBulkDateAction: React.FC<IProps> = ({ data, activeDates, handleActiveDatesChange, setIsError }) => {
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const now = moment();
  useEffect(() => {
    if (activeDates.startDate && activeDates.endDate) {
      const startDate = moment(activeDates.startDate);
      const endDate = moment(activeDates.endDate);

      if (startDate.isAfter(endDate)) {
        setIsError(true);
        setErrorMessage('Start date must be before end date.');
      } else if (startDate.isSame(endDate, 'day')) {
        setIsError(true);
        setErrorMessage('Start and end date cannot be the same.');
      } else {
        setIsError(false);
        setErrorMessage(null);
      }
    }
  }, [activeDates.startDate, activeDates.endDate, setIsError]);

  const disabledEndDate = useMemo(() => {
    const givenDate = activeDates.startDate;
    return (date: Date) => givenDate && moment(date).isBefore(moment(givenDate).startOf('day'));
  }, [activeDates]);

  return (
    <div className={styles.manageOfferContainer}>
      <Title level={5}>Select active dates</Title>
      <Text>You can choose the active dates for all the selected members</Text>
      <Row className={styles.memberCount}>
        <Text className={styles.boldText}>
          {data.length} {data.length === 1 ? 'Member' : 'Members'} Selected
        </Text>
      </Row>
      <Row className={styles.activeDateContainer}>
        <Card size="small" className={styles.card}>
          <Space className={styles.dateHeader}>
            <Text className={styles.boldText}>
              <CalendarCheckIcon style={{ marginRight: '8px' }} />
              Set Active Dates
            </Text>
            <Popover
              content="Now you can directly set active/end dates for all the selected members in bulk."
              title="Set Dates in Bulk"
              overlayStyle={{ maxWidth: '200px' }}
              placement="right"
            >
              <CircleInfoIcon />
            </Popover>
          </Space>
          <Space direction="vertical" className={styles.dateContainer}>
            <Text>Active Date</Text>
            <Popover
              trigger="click"
              open={openStartDate}
              onOpenChange={setOpenStartDate}
              content={(
                <Calendar
                  mode="single"
                  selected={activeDates.startDate || undefined}
                  onSelect={(date) => {
                    const momentDate = moment.utc(moment(date).set({
                      hour: now.hour(),
                      minute: now.minute(),
                      second: now.second(),
                    }));
                    handleActiveDatesChange('startDate', momentDate.toDate());
                    setOpenStartDate(false);
                  }}
                  disabled={(date) => moment(date).isBefore(moment().startOf('day'))}
                  initialFocus
                />
              )}
            >
              <Button className="!flex justify-between w-full !font-normal">
                {activeDates.startDate ? moment(activeDates.startDate).format('MMM D, YYYY') : 'Select Date'}
                <CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
              </Button>
            </Popover>
            <Checkbox
              onChange={() => handleActiveDatesChange('showEndDate', !activeDates.showEndDate)}
              checked={activeDates.showEndDate}
            >
              Set End Date
            </Checkbox>
            {activeDates.showEndDate && (
              <>
                <Text>End Date</Text>
                <Popover
                  trigger="click"
                  open={openEndDate}
                  onOpenChange={setOpenEndDate}
                  content={(
                    <Calendar
                      mode="single"
                      selected={activeDates.endDate || undefined}
                      onSelect={(date) => {
                        const momentDate = moment.utc(moment(date).set({
                          hour: now.hour(),
                          minute: now.minute(),
                          second: now.second(),
                        }));
                        handleActiveDatesChange('endDate', momentDate.toDate());
                        setOpenEndDate(false);
                      }}
                      disabled={disabledEndDate}
                      initialFocus
                    />
                  )}
                >
                  <Button className="!flex justify-between w-full !font-normal">
                    {activeDates.endDate ? moment(activeDates.endDate).format('MMM D, YYYY') : 'Select Date'}
                    <CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
                  </Button>
                </Popover>
              </>
            )}
            {errorMessage && <p className="text-xs text-danger">{errorMessage}</p>}
          </Space>
        </Card>
      </Row>
    </div>
  );
};

export default OfferBulkDateAction;
