import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CURRENCY_CODE } from '../queries';
import { GetCurrencyCode } from '../queries/types/GetCurrencyCode';

type IOptions = QueryHookOptions<GetCurrencyCode>;

export function useGetCurrencyCode(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetCurrencyCode>(
    GET_CURRENCY_CODE,
    options,
  );

  return {
    isCurrencyCodeLoading: loading,
    currencyCode: data?.getCurrencyCode,
    currencyCodeError: error,
    refetchCurrencyCode: refetch,
  };
}
