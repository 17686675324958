import { PaymentStatus } from '../constants';

export const statusColorMapper = {
  [PaymentStatus.PAID]: {
    bg: 'bg-green-2',
    text: 'text-[#27593B]',
    border: 'border-green-2',
    cardShadeColor: 'bg-[#F3FDF2]',
  },
  [PaymentStatus.CANCELED]: {
    bg: 'bg-[#F4CDBC]',
    text: 'text-[#AC2B31]',
    border: 'border-[#F4CDBC]',
    cardShadeColor: 'bg-[#FDF6F2]',
  },
  [PaymentStatus.PROCESSING]: {
    bg: 'bg-yellow-2',
    text: 'text-[#9C7512]',
    border: 'border-yellow-2',
    cardShadeColor: 'bg-[#FDFBF1]',
  },
  [PaymentStatus.PENDING]: {
    bg: 'bg-blue-2',
    text: 'text-[#4F7DBA]',
    border: 'border-blue-2',
    cardShadeColor: 'bg-[#F1FAFE]',
  },
};

export enum BudgetSource {
  PROJECT = 'project',
  OTHER = 'other',
}
