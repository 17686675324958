import React, { useCallback, useEffect, useMemo } from 'react';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Button } from '@frontend/shadcn/components/ui/button';
import { P } from '@frontend/shadcn/components/typography/p';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { ArrowDownToLineIcon, CoinsIcon, FileIcon, TagsIcon } from '@revfluence/fresh-icons/solid/esm';
import { ExpandableSearch, NameCell, Tag } from '@frontend/app/refresh-components';
import { PenToSquareIcon, TagIcon } from '@revfluence/fresh-icons/regular/esm';
import { TagAction } from '@frontend/app/types/globalTypes';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { ExportToCsv } from 'export-to-csv';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { getUniqueRefreshColor } from '@frontend/utils';
import { ChevronDownIcon, ChevronUpIcon, ArrowDownArrowUpIcon } from '@revfluence/fresh-icons/regular/esm';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { ColumnSelectorModal } from './ColumnSelectorModal';
import { usePayment, Transaction } from '../PaymentContext';
import { TablePagination } from './TablePagination';
import { DataTable } from './Table';
import { statusColorMapper } from '../constants';
import { CellWithTooltip } from './CellWithTooltip';
import { formatPaymentDate } from '../utils/formatPaymentDate';

// Add this type near the top of the file
type TableColumnDef = ColumnDef<Transaction> & {
  accessorKey?: string;
  meta?: {
    label: string;
  };
};

// Update cell renderers for type, budgets, and tags
const renderLimitedBadges = (
  items: { id?: number; name?: string }[],
  limit: number = 2,
  maxNameLength: number = 10,
) => {
  const displayed = items.slice(0, limit);
  const remaining = items.length - limit;

  return (
    <div className="flex gap-1 flex-wrap">
      {displayed.map((item) => {
        const name = item.name || '';
        const displayName = name.length > maxNameLength ? `${name.substring(0, maxNameLength)}...` : name;

        return (
          <Tag
            key={item.id}
            label={displayName}
            className="py-2"
            bgColor="bg-grey-2"
            textColor="text-grey-6"
            tooltip={name}
          />
        );
      })}
      {remaining > 0 && <Tag label={`+${remaining}`} className="py-2" bgColor="bg-grey-2" textColor="text-grey-6" />}
    </div>
  );
};

// Updated SortButton with right-aligned sort icons
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const SortButton = ({ column, children }: { column: any; children: React.ReactNode }) => {
  const isSorted = column.getIsSorted();
  const [isHovering, setIsHovering] = React.useState(false);

  const handleSort = () => {
    if (isSorted === 'asc') {
      column.toggleSorting(true); // Switch to desc
    } else if (isSorted === 'desc') {
      column.clearSorting(); // Clear sorting (no sort)
    } else {
      column.toggleSorting(false); // Switch to asc
    }
  };

  return (
    <Button
      variant="ghost"
      className="px-2 hover:bg-transparent w-full"
      onClick={handleSort}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex w-full justify-between items-center">
        <span>{children}</span>
        <span className="h-3 w-3 flex items-center justify-center">
          {isSorted === 'asc' && <ChevronUpIcon className="h-3 w-3" />}
          {isSorted === 'desc' && <ChevronDownIcon className="h-3 w-3" />}
          {!isSorted && isHovering && <ArrowDownArrowUpIcon className="h-3 w-3" />}
        </span>
      </div>
    </Button>
  );
};

// Add this component before the columns definition
function TypeCell({ type }: { type: Transaction['type'] }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const iconMappper = {
    Brief: <FileIcon className="w-4 h-4 text-[#339FDE]" />,
    Other: <CoinsIcon className="w-4 h-4 text-[#5DB8B3]" />,
    Commission: <TagsIcon className="w-4 h-4 text-[#4F5ABA]" />,
  };
  const icon = iconMappper[type] || null;

  return (
    <div
      className="relative"
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Popover open={isOpen}>
        <PopoverTrigger asChild>{icon}</PopoverTrigger>
        <PopoverContent side="top" align="center" className="z-[9999] w-30 p-3 text-sm bg-white shadow-lg rounded-md">
          {/* <div className="flex flex-col gap-2">
            <P className="text-grey-4">Paid from sales tracking</P>
            <div className="flex gap-3 items-center">
              <Avatar className="h-[32px] w-[32px] rounded-lg">
                <AvatarImage
                  src="https://i.pravatar.cc/300"
                  className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-[20px] text-secondary"
                />
                <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg text-[20px] bg-grey-3">
                  <UserIcon className="text-white" />
                </AvatarFallback>
              </Avatar>
              <P className="text-grey-6 font-medium text-base">Evergreen Sale</P>
              <UpRightFromSquareIcon className="w-4 h-4 text-[#167CF4]" />
            </div>
          </div> */}
          <P className="text-grey-6 font-medium text-sm">{type}</P>
        </PopoverContent>
      </Popover>
    </div>
  );
}

// Move the exportTransactionsToCSV function outside if it doesn't need component state
const exportTransactionsToCSV = (transactions: Transaction[]) => {
  const formattedTransactions = transactions.map((transaction) => ({
    member: transaction.member?.name || '',
    paymentDate: transaction.paymentDate ? formatPaymentDate(transaction.paymentDate) : '',
    amount: transaction.amount ?? '',
    status: transaction.status || '',
    paypalEmail: transaction.paypalEmail || '',
    project: transaction.project?.name || '',
    type: transaction.type || '',
    budgets: transaction.budgets?.map((b) => b.name).join(', ') || '',
    tags: transaction.tags?.map((t) => t.name).join(', ') || '',
    initiatedBy: transaction.paymentInitiatedByUser?.name || '',
    notes: transaction.notes || '',
  }));

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Payments',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(formattedTransactions);
};

export default exportTransactionsToCSV;

export function BudgetTable() {
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const {
    pageIndex,
    isLoading,
    data,
    pageCount,
    rowSelection,
    selectedRows,
    setIsEditPaymentDrawerOpen,
    setPageIndex,
    setSorting,
    setRowSelection,
    columnVisibility,
    setColumnVisibility,
    setFilter,
    assignUnassignTagsDirectly,
    fetchPayments,
    openTagDialog,
  } = usePayment();

  // Move columns definition here and wrap in useMemo
  const columns = useMemo<TableColumnDef[]>(
    () => [
      {
        accessorKey: 'member',
        header: ({ column }) => <SortButton column={column}>Member</SortButton>,
        meta: { label: 'Member' },
        cell: ({ row }) => {
          const member = row.getValue<Transaction['member']>('member');
          return <CellWithTooltip value={member?.name || ''} />;
        },
        size: 200,
        minSize: 200,
      },
      {
        accessorKey: 'paymentDate',
        header: ({ column }) => <SortButton column={column}>Payment Date</SortButton>,
        meta: { label: 'Payment Date' },
        cell: ({ row }) => formatPaymentDate(row.getValue<string>('paymentDate')),
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: 'amount',
        header: ({ column }) => <SortButton column={column}>Amount</SortButton>,
        meta: { label: 'Amount' },
        cell: ({ row }) => <div className="text-right">${row.getValue<number>('amount').toLocaleString()}</div>,
        size: 120,
        minSize: 120,
      },
      {
        accessorKey: 'status',
        header: ({ column }) => <SortButton column={column}>Status</SortButton>,
        meta: { label: 'Status' },
        cell: ({ row }) => {
          const status = row.getValue<Transaction['status']>('status');
          const statusBgColor = statusColorMapper[status].bg;
          const statusTextColor = statusColorMapper[status].text;

          return (
            <Tag
              label={status.charAt(0).toUpperCase() + status.slice(1)}
              bgColor={statusBgColor}
              textColor={statusTextColor}
            />
          );
        },
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: 'paypalEmail',
        header: ({ column }) => <SortButton column={column}>PayPal Email</SortButton>,
        meta: { label: 'PayPal Email' },
        cell: ({ row }) => <CellWithTooltip value={row.getValue<string>('paypalEmail')} />,
        size: 200,
        minSize: 200,
      },
      {
        accessorKey: 'project',
        header: ({ column }) => <SortButton column={column}>Project</SortButton>,
        meta: { label: 'Project' },
        cell: ({ row }) => {
          const project = row.getValue<Transaction['project']>('project');
          return <CellWithTooltip value={project?.name || ''} />;
        },
        size: 200,
        minSize: 200,
      },
      {
        accessorKey: 'type',
        header: ({ column }) => <SortButton column={column}>Type</SortButton>,
        meta: { label: 'Type' },
        cell: ({ row }) => {
          const type = row.getValue<Transaction['type']>('type');
          return <TypeCell type={type} />;
        },
        size: 80,
        minSize: 80,
      },
      ...(isBudgetAllocationEnabled
        ? [
            {
              accessorKey: 'budgets',
              header: 'Budgets',
              meta: { label: 'Budgets' },
              cell: ({ row }) => {
                const budgets = row.getValue('budgets') as Transaction['budgets'];
                if (!budgets?.length) {
                  return '-';
                }
                return renderLimitedBadges(row.getValue('budgets') as Transaction['budgets'], 2);
              },
              size: 300,
              minSize: 300,
            },
          ]
        : []),
      {
        accessorKey: 'tags',
        header: 'Tags',
        meta: { label: 'Tags' },
        cell: ({ row }) => {
          const tags = row.getValue<Transaction['tags']>('tags');
          const paymentId = row.original.paymentId;
          const maxTagLength = 10;
          if (!tags?.length) {
            return '-';
          }
          return (
            <div className="flex gap-1 flex-wrap">
              {tags.slice(0, 2).map((tag) => {
                const name = tag.name || '';
                const displayName = name.length > maxTagLength ? `${name.substring(0, maxTagLength)}...` : name;

                return (
                  <Tag
                    key={tag.id}
                    label={displayName}
                    className="py-2"
                    bgColor={getUniqueRefreshColor(tag.name)}
                    tooltip={name}
                    isRemovable
                    onRemove={() => {
                      assignUnassignTagsDirectly([paymentId], [tag.id], TagAction.UNASSIGN);
                      fetchPayments();
                    }}
                  />
                );
              })}
              {tags.length > 2 && (
                <Tag
                  label={`+${tags.length - 2}`}
                  className="py-2"
                  bgColor={getUniqueRefreshColor('more')}
                  tooltip={`${tags.length - 2} more tags`}
                />
              )}
            </div>
          );
        },
        size: 250,
        minSize: 250,
      },
      {
        accessorKey: 'paymentInitiatedByUser',
        header: ({ column }) => <SortButton column={column}>Initiated By</SortButton>,
        meta: { label: 'Initiated By' },
        cell: ({ row }) => {
          const user = row.getValue<Transaction['paymentInitiatedByUser']>('paymentInitiatedByUser');
          return user?.name ? (
            <NameCell name={user?.name || ''} imageUrl={user?.profilePicture} showName={false} />
          ) : (
            '-'
          );
        },
        size: 120,
        minSize: 120,
      },
      {
        accessorKey: 'notes',
        header: ({ column }) => <SortButton column={column}>Notes</SortButton>,
        meta: { label: 'Notes' },
        cell: ({ row }) => <CellWithTooltip value={row.getValue<string>('notes')} />,
        size: 200,
        minSize: 200,
      },
    ],
    [assignUnassignTagsDirectly, fetchPayments, isBudgetAllocationEnabled],
  ); // Empty dependency array since columns don't depend on any state/props
  // Initialize column visibility on mount
  useEffect(() => {
    if (Object.keys(columnVisibility).length === 0) {
      const initialVisibility = columns.reduce(
        (acc, column) => ({
          ...acc,
          [column.accessorKey || '']: true,
        }),
        {},
      );
      setColumnVisibility(initialVisibility);
    }
  }, [columnVisibility, setColumnVisibility, columns]);

  // Handle column toggle
  const handleColumnToggle = useCallback(
    (columnId: string) => {
      setColumnVisibility((prev) => ({
        ...prev,
        [columnId]: !prev[columnId],
      }));
    },
    [setColumnVisibility],
  );

  // Filter visible columns - now depends on the memoized columns
  const visibleColumns = useMemo(() => columns.filter((column) => columnVisibility[column.accessorKey || '']), [
    columnVisibility,
    columns,
  ]);

  const handlePaginationChange = useCallback(
    (newPageIndex: number) => {
      setPageIndex(newPageIndex);
    },
    [setPageIndex],
  );

  const handleSortingChange = useCallback(
    (newSorting: SortingState) => {
      setSorting(newSorting);
      setPageIndex(0);
    },
    [setSorting, setPageIndex],
  );

  // Handle search
  const handleSearch = useCallback(
    (query: string, selectedOption?: string) => {
      const searchByMapping: Record<string, string> = {
        Member: 'member',
        'Paypal Email': 'paypalEmail',
      };

      const searchBy = searchByMapping[selectedOption];

      // Only update filter if we have a valid search query or if clearing the search
      if (query !== undefined) {
        setFilter((prev) => ({
          ...prev,
          searchBy,
          searchQuery: query,
        }));

        // Reset to first page when searching
        setPageIndex(0);
      }
    },
    [setFilter, setPageIndex],
  );

  // Add this function to handle row clicks
  const handleRowClick = useCallback(
    (row: Transaction) => {
      // Clear any existing selection
      setRowSelection({});
      // Select only the clicked row
      setRowSelection({ [row.id]: true });
      // Open the edit drawer
      setIsEditPaymentDrawerOpen(true);
    },
    [setRowSelection, setIsEditPaymentDrawerOpen],
  );

  return (
    <div className="w-full h-[calc(100vh-280px)] border-grey-2 rounded-2xl overflow-hidden border-solid border-[1px] flex flex-col">
      <div className="w-full bg-white flex justify-between items-center px-4 py-2 border-b border-grey-2">
        <div className="flex items-center gap-2">
          <P className="text-sm font-normal">{selectedRows.length} selected</P>
          <Tooltip>
            <TooltipTrigger>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button disabled={!selectedRows.length} size="icon" variant="ghost">
                    <TagIcon />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem
                    onClick={() => {
                      openTagDialog(selectedRows?.map((row) => row.paymentId) || [], TagAction.ASSIGN, fetchPayments);
                    }}
                  >
                    Assign Tags
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      openTagDialog(selectedRows?.map((row) => row.paymentId) || [], TagAction.UNASSIGN, fetchPayments);
                    }}
                  >
                    Unassign Tags
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TooltipTrigger>
            <TooltipContent>Tags</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <Button
                disabled={!selectedRows.length}
                size="icon"
                variant="ghost"
                onClick={() => {
                  setIsEditPaymentDrawerOpen(true);
                }}
              >
                <PenToSquareIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Update Payment in Bulk</TooltipContent>
          </Tooltip>
        </div>
        <div className="flex items-center gap-2">
          <ExpandableSearch
            options={['Member', 'Paypal Email']}
            placeholder="Search..."
            tooltipText="Search"
            onSearch={handleSearch}
            initialOption="Member"
          />
          <Tooltip>
            <TooltipTrigger>
              <Button disabled={false} size="icon" variant="ghost" onClick={() => exportTransactionsToCSV(data)}>
                <ArrowDownToLineIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Export</TooltipContent>
          </Tooltip>
          <ColumnSelectorModal
            columns={columns.map((column) => ({
              id: column.accessorKey || '',
              label: column.meta?.label || 'Column',
              isVisible: columnVisibility[column.accessorKey || ''],
            }))}
            onColumnToggle={handleColumnToggle}
          />
          <TablePagination
            pageIndex={pageIndex}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < pageCount - 1}
            onPaginationChange={handlePaginationChange}
          />
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <DataTable
          columns={visibleColumns}
          data={data}
          onSortingChange={handleSortingChange}
          isLoading={isLoading}
          enableColumnResizing
          enableRowSelection
          rowSelection={rowSelection}
          onRowSelectionChange={setRowSelection}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          className="cursor-pointer h-full"
          pinnedLeft={['select', 'member']}
        />
      </div>
    </div>
  );
}
