import { useMutation, MutationHookOptions } from '@apollo/client';

import {
    UPDATE_CLIENT_FF,
} from '@frontend/app/queries';
import {
    UpdateClientFF,
    UpdateClientFFVariables,
} from '@frontend/app/queries/types/UpdateClientFF';

type IOptions = MutationHookOptions<UpdateClientFF, UpdateClientFFVariables>;

export const useUpdateClientFF = (options: IOptions = {}) => useMutation<UpdateClientFF, UpdateClientFFVariables>(UPDATE_CLIENT_FF, options);
