import React from 'react';
import { useState } from 'react';
import {
 includes, map, reject, slice,
} from 'lodash';
import { message } from '@revfluence/fresh';
import {
 useCommunitiesQuery, useGetAllProjectsQuery,
} from '@frontend/app/hooks';
import { GetKlaviyoSyncSettings_getSyncSettings_config } from '@frontend/app/queries/types/GetKlaviyoSyncSettings';
import { ColumnDef } from '@tanstack/react-table';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { TABLE_PAGE_SIZE } from '@frontend/app/constants';
import { GetAllProjectsQuery_projects } from '@frontend/app/queries/types/GetAllProjectsQuery';
import { GetCommunitiesQuery_communities } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { Button } from '@frontend/shadcn/components/ui/button';

export type ISyncSettings = Omit<GetKlaviyoSyncSettings_getSyncSettings_config, '__typename'>;
export const useGetTableData = ({
  startSync,
  config,
  updateSettingsMutation,
  isSettingsLoading,
}: {
  startSync: (projects: number[], groups: number[]) => Promise<void>,
  config: ISyncSettings,
  updateSettingsMutation,
  isSettingsLoading: boolean,
}) => {
  const [currentGroupPage, setCurrentGroupPage] = useState(1);
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const [syncingProjects, setSyncingProjects] = useState([]);
  const [syncingGroups, setSyncingGroups] = useState([]);
  const {
    loading: isCommunitiesLoading,
      data: {
          communities = null,
      } = {},
  } = useCommunitiesQuery();

  const {
      data: {
      projects = undefined,
      } = {},
      loading: isProjectsLoading,
  } = useGetAllProjectsQuery({
  variables: {
    withSpecsOnly: true,
  },
  });
  if (isProjectsLoading || isCommunitiesLoading || isSettingsLoading) {
    return {
      loading: true,
      rows: null,
    };
  }
  const groupRows = _groupsToRows(communities, config);
  const projectRows = _projectsToRows(projects, config);
  const syncProject = async (projectId: number) => {
    // Add the project to the syncing list
    setSyncingProjects((prev) => [...prev, projectId]);
    await startSync([projectId], []);
    // Remove the project from the syncing list
    setSyncingProjects((prev) => prev.filter((num) => num !== projectId));

    message.success({
      content: 'Successfully synced project',
    });
  };
  const syncGroup = async (groupId: number) => {
    setSyncingGroups((prev) => [...prev, groupId]);
    await startSync([], [groupId]);
    setSyncingGroups((prev) => prev.filter((num) => num !== groupId));

    message.success({
      content: 'Successfully synced group',
    });
  };
  const addProject = (projectId: number) => {
    const projects = [
      ...config.projects,
      projectId,
    ];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects,
},
},
    });
  };
  const removeProject = (projectId: number) => {
    const projects = reject(config.projects, (id) => id === projectId);
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects,
},
},
    });
  };
  const addGroup = (groupId: number) => {
    const groups = [
      ...config.groups,
      groupId,
    ];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, projects: config.projects, groups,
},
},
    });
  };
  const removeGroup = (groupId: number) => {
    const groups = reject(config.groups, (id) => id === groupId);
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, projects: config.projects, groups,
},
},
    });
  };
  const toggleSelectAllGroups = (selectAll: boolean) => {
    const groups = selectAll ? map(communities, 'id') : [];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, projects: config.projects, groups,
},
},
    });
  };
  const toggleSelectAllProjects = (selectAll: boolean) => {
    const selectedProjects = selectAll ? map(projects, 'id') : [];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects: selectedProjects,
},
},
    });
  };
  return {
    loading: false,
    groupRows: slice(groupRows, (currentGroupPage - 1) * TABLE_PAGE_SIZE, Math.min(currentGroupPage * TABLE_PAGE_SIZE, groupRows.length)),
    projectRows: slice(projectRows, (currentProjectPage - 1) * TABLE_PAGE_SIZE, Math.min(currentProjectPage * TABLE_PAGE_SIZE, projectRows.length)),
    totalProjectCount: projectRows.length,
    totalGroupCount: groupRows.length,
    currentGroupPage,
    setCurrentGroupPage,
    pageSize: TABLE_PAGE_SIZE,
    currentProjectPage,
    setCurrentProjectPage,
    projectColumnDef: getTableColumnDef({
      addToCollection: addProject,
      removeFromCollection: removeProject,
      toggleSelectAll: toggleSelectAllProjects,
      allItemsSelected: config.projects.length === projects.length,
      syncSingleItem: syncProject,
      typeLabel: 'Project',
      syncingItems: syncingProjects,
    }),
    groupColumnDef: getTableColumnDef({
      addToCollection: addGroup,
      removeFromCollection: removeGroup,
      toggleSelectAll: toggleSelectAllGroups,
      allItemsSelected: config.groups.length === communities.length,
      syncSingleItem: syncGroup,
      typeLabel: 'Group',
      syncingItems: syncingGroups,
    }),
  };
};

const _projectsToRows = (projects: GetAllProjectsQuery_projects[], config: ISyncSettings) => map(projects, (project) => ({
      name: project.title,
      id: project.id,
      keepSynced: includes(config.projects, project.id),
    }));

const _groupsToRows = (groups: GetCommunitiesQuery_communities[], config: ISyncSettings) => map(groups, (group) => ({
      name: group.title,
      id: group.id,
      keepSynced: includes(config.groups, group.id),
    }));

    const getTableColumnDef = ({
      addToCollection,
      removeFromCollection,
      toggleSelectAll,
      allItemsSelected,
      syncSingleItem,
      typeLabel,
      syncingItems,
    }: {
      addToCollection: (id: number) => void;
      removeFromCollection: (id: number) => void;
      toggleSelectAll: (selected: boolean) => void;
      allItemsSelected: boolean;
      syncSingleItem: (itemId: number) => void;
      typeLabel: string;
      syncingItems: number[]
    }): ColumnDef<{ name: string, id: number, keepSynced: boolean }>[] => [
      {
        accessorKey: 'keepSynced',
        enableSorting: false,
        header: () => (
          <div className="flex gap-2">
            <Switch
              checked={allItemsSelected}
              onCheckedChange={toggleSelectAll}
            />
          </div>
        ),
        cell: ({ row }) => {
          const rowData = row.original;
          const isSyncing = rowData.keepSynced;
          const id = rowData.id;
          const switchChanged = (switchValue: boolean) => (switchValue ? addToCollection(id) : removeFromCollection(id));
          return (
            <div className="flex gap-1 items-center px-2">
              <Switch
                checked={isSyncing}
                onCheckedChange={switchChanged}
              />
            </div>
          );
        },
        size: 50,
      },
    {
      accessorKey: 'name',
      header: () => <div className="font-semibold text-[#1F1F21]">{typeLabel}</div>,
      cell: ({ row }) => (
        <div className="flex gap-1 items-center px-2">
          {row.original.name}
        </div>
        ),
      size: 400,
    },
    {
      accessorKey: 'id',
      header: () => <div className="font-semibold text-[#1F1F21]">Actions</div>,
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex gap-1 items-center">
          <Button
            className="underline w-20"
            loading={syncingItems.includes(row.original.id)}
            onClick={() => syncSingleItem(row.original.id)}
          >
            Sync
          </Button>
        </div>
      ),
      size: 80,
    },
  ];
