import * as React from 'react';
import cx from 'classnames';
import InstagramEmbed from 'react-instagram-embed';

import { ViewIcon } from '@components';
import { LazyImage } from '@components';
import { Video } from '@components';
import { ILicensedContent } from '@components';
import { getDetailedMediaUrl, getMediaTypeForContent, MediaTypes } from '@components';
import { getSafeURL } from '@components';

import { encodeUrlFilename } from '@frontend/applications/TermsApp/components/BulkTerms/utils/contentTypeUtils';
import useConvertToJpg from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage/utils/useConvertHeicToJpg';
import styles from './Media.scss';

const { useState } = React;

// New component to handle HEIC to JPG conversion
interface IConvertedImageProps {
  url: string;
  className?: string;
  alt?: string;
}

const ConvertedImage: React.FC<IConvertedImageProps> = ({ url, className, alt }) => {
  const encodedUrl = encodeUrlFilename(url);
  const convertedUrl = useConvertToJpg(encodedUrl);

  return (
    <LazyImage
      src={convertedUrl || encodedUrl}
      className={className}
      alt={alt}
    />
  );
};

interface IProps {
  content: ILicensedContent;
  className: string;
}

export const Media: React.FunctionComponent<IProps> = (props) => {
  const {
    className,
    content,
  } = props;

  const [useInstagramEmbed, setUseInstagramEmbed] = useState(true);
  const [useVideoThumbnail, setUseVideoThumbnail] = useState(false);

  const mediaType = getMediaTypeForContent(content);

  let contentNode;
  switch (mediaType) {
    case MediaTypes.MEDIA_IMAGE: {
      const contentType = content.downloadable_media?.[0]?.content_type;
      const isHEIC = contentType === 'image/heic' || contentType === 'image/heif';
      const url = getDetailedMediaUrl(content, !isHEIC);

      contentNode = <ConvertedImage key={url} url={url} className={styles.mediaImage} />;
      break;
    }

    case MediaTypes.MEDIA_VIDEO:
      contentNode = (
        <Video
          key={content.video}
          src={content.video}
          loop
          controls
          classNames={[styles.mediaVideo]}
          poster={content.image}
        />
      );
      break;

    case MediaTypes.SOCIAL_POST_YOUTUBE:
      contentNode = (
        <>
          <iframe
            width="560"
            height="315"
            src={getSafeURL(content.social_post.embed_link)}
            frameBorder="0"
            allowFullScreen
          />
          <ul className={styles.engagementList}>
            <li className={styles.engagementItem}>
              <ViewIcon />
              <span>
                {content.social_post.engagement}
                {' '}
                Views
              </span>
            </li>
          </ul>
        </>
      );
      break;

    case MediaTypes.SOCIAL_POST_INSTAGRAM:
      if (useInstagramEmbed && content.social_post.post_type !== 'InstagramStory') {
        contentNode = (
          <InstagramEmbed
            url={content.social_post.link}
            clientAccessToken="491285117675871|b23dbfa4e920b87abd9f925864958104"
            maxWidth={320}
            hideCaption
            containerTagName="div"
            protocol=""
            injectScript
            onFailure={() => { setUseInstagramEmbed(false); }}
          />
        );
      } else if (content.social_post.media_type === 'video' && !useVideoThumbnail) {
        // instagram videoes may be expired story cdn links, in this scenario, display thumbnail
        contentNode = (
          <Video
            key={content.social_post.link}
            src={content.social_post.link}
            loop
            autoPlay
            controls
            classNames={[styles.mediaVideo]}
            onError={() => setUseVideoThumbnail(true)}
            poster={content.social_post.image}
          />
        );
      } else {
        contentNode = (
          <ConvertedImage
            key={content.image}
            url={content.image}
            className={styles.mediaImage}
          />
        );
      }
      break;

    case MediaTypes.SOCIAL_POST_SNAPCHAT:
      contentNode = (
        <>
          {content.social_post.media_type === 'video' ? (
            <Video
              key={content.social_post.link}
              src={content.social_post.link}
              loop
              autoPlay
              controls
              classNames={[styles.mediaVideo]}
              poster={content.social_post.image}
            />
          ) : (
            <LazyImage
              key={content.social_post.link}
              src={content.social_post.link}
              className={styles.mediaImage}
            />
          )}
          <ul className={styles.engagementList}>
            <li className={styles.engagementItem}>
              <ViewIcon />
              <span>{content.social_post.engagement || 'N/A'}</span>
            </li>
          </ul>
        </>
      );
      break;

    default:
      return null;
  }

  return (
    <div
      className={cx(styles.Media, className, {
        [styles.instagram]: !!useInstagramEmbed,
        [styles.youtube]: mediaType === MediaTypes.SOCIAL_POST_YOUTUBE,
      })}
    >
      {contentNode}
    </div>
  );
};

Media.displayName = 'Media';
