import * as React from 'react';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import styles from './MembersWizardIcon.scss';
import { OfferImage } from '../../../OfferImage';

interface AddMembersProps {
  offerSource: OFFER_SOURCE;
}

export const AddMembers: React.FC<Readonly<AddMembersProps>> = (props) => {
  const { offerSource } = props;
  return (
    <div>
      <OfferImage
        className={styles.offerImage}
        imageUrl={null}
        iconName={null}
        source={offerSource}
      />
    </div>
  );
};

AddMembers.displayName = 'AddMembersIcon';
