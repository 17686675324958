import * as React from 'react';
import { Form, IFormProps as FormProps, message } from '@revfluence/fresh';

import {
  isEmpty,
  isNull,
  size,
  map,
  pull,
} from 'lodash';
import moment from 'moment';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormValues,
  MultiplePayoutAsyncActionPayload,
  OfferFormModes,
  OfferFormTooltip,
  TFormOptionData,
  TFormValues,
  UpdateFieldAsyncActionPayload,
  OfferFormAsyncActions,
  MultipleDiscountCheckboxAsyncActionPayload,
  ClientForSync,
} from '@affiliates/components';
import { OFFER_ACTIONS } from '@affiliates/constants/offers';
import {
  CLIENT_CONNECTION_STATUS,
  OFFER_PRICE_RULE_TYPE,
  OFFER_SOURCE, OFFER_TRACKING_TYPE, UserInfoInput,
} from '@affiliates/types/globalTypes';
import { IShopifyCredentials } from '@affiliates/hooks';
import { getRequiredFields } from '@affiliates/components/OfferForm/utils';
import styles from '@affiliates/components/OfferForm/OfferForm.scss';
import { useLocation } from 'react-router-dom';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { IBaseProps, IChildArgs } from './types';
import {
  useDisabledMap,
  useOfferFormSubmit,
  useOfferFormTooltip,
  useOfferImage,
} from './hooks';
import { GetConnectedShopify_clientConnections } from '../../queries/types/GetConnectedShopify';
import { OFFER_FORM_LOCKING_KEY } from '../../types';

const { useCallback, useEffect, useState } = React;

interface IProps extends IBaseProps<TFormValues> {
  initialValues: IChildArgs<TFormValues>['initialValues'];
  offerDetails: IChildArgs<TFormValues>['offerDetails'];
  shopifyCredentials?: IShopifyCredentials;
  formOptionData?: TFormOptionData;
  onSubmit: (offerId: number, action: OFFER_ACTIONS) => void;
  profile?: UserInfoInput;
  isSubscriptionEnable?: boolean;
  mode?: OfferFormModes
  connectedAdvertiserForSync?: GetConnectedShopify_clientConnections[],
}
interface StateType {
  isNewFlow: boolean;
  isMigrationEnabled: boolean;
}

export const OfferFormContainer: React.FC<Readonly<IProps>> = ({
  children,
  initialValues,
  offerDetails,
  shopifyCredentials,
  onSubmit,
  formOptionData,
  profile,
  isSubscriptionEnable,
  mode,
  connectedAdvertiserForSync,
}) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<IChildArgs<TFormValues>['values']>(initialValues);

  const [isSaving, setIsSaving] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [validateUrl, setValidateUrl] = useState(false);

  const [isExpired, setIsExpired] = useState(offerDetails.isExpired);

  const { handleChangeOfferImage, offerImage } = useOfferImage(setIsTouched);

  const { changeToolTip, tooltipType } = useOfferFormTooltip();

  const location = useLocation<StateType>();
  const state = location?.state;

  const { migrateToGraphQL } = useClientFeatures();

  const { onDelete, onUnDelete, onFinish } = useOfferFormSubmit(
    offerDetails.id,
    offerDetails.linkedId,
    offerImage,
    formValues,
    setIsSaving,
    onSubmit,
    profile,
    state?.isMigrationEnabled,
    migrateToGraphQL,
    isSubscriptionEnable,
    initialValues,
    offerDetails.hasMembers,
    formOptionData,
    isTouched,
    connectedAdvertiserForSync,
  );

  const fieldsToCheck = getRequiredFields(formValues, formValues.source, formValues.source === OFFER_SOURCE.TUNE ? formValues.isAdvanceUrlEnable : false, migrateToGraphQL);

  const requiredFieldMessagesList: string[] = map(fieldsToCheck.requiredFields, (field) => {
    if (field === 'payoutOptions') {
      const payouts = form.getFieldValue('payoutOptions');
      if (payouts?.length) {
        const isValid = payouts.every((payout) => {
          if (!payout || !payout.label) return false;
          const flatPayout = payout.flatPayout != null ? parseInt(payout.flatPayout, 10) : null;
          const percentPayout = payout.percentPayout != null ? parseInt(payout.percentPayout, 10) : null;
          return (flatPayout != null && !isNaN(flatPayout)) || (percentPayout != null && !isNaN(percentPayout));
        });
        if (!isValid) {
          return fieldsToCheck.requiredFieldsErrorText[field] || `${field} is required`;
        }
      }
      return null;
    }

    if (isEmpty(formValues[field])) {
      return (
        fieldsToCheck.requiredFieldsErrorText[field]
        || `${field} is required`
      );
    }
    return null;
  }).filter((errorMessage) => errorMessage !== null);

  let requiredFieldMessages: React.ReactNode;
  if (size(requiredFieldMessagesList) === 1) {
    requiredFieldMessages = requiredFieldMessagesList[0];
  }

  if (size(requiredFieldMessagesList) > 1) {
    requiredFieldMessages = (
      <ul className={styles.tooltipList}>
        {map(requiredFieldMessagesList, (requiredFieldMessages) => (
          <li key={`reason_${requiredFieldMessages}`}>
            {requiredFieldMessages}
          </li>
        ))}
      </ul>
    );
  }

  const disabledFields = useDisabledMap(
    initialValues.source,
    isNull(offerDetails.id) ? OfferFormModes.Create : OfferFormModes.Edit,
    offerDetails.hasMembers,
    offerDetails.isDeleted,
    offerDetails.isExpired,
    shopifyCredentials,
    offerDetails.canEditConversionType,
    offerDetails.isReadOnly,
  );
  const handleFormAsyncActions = (payload: FormAsyncAction): void => {
    switch (payload.action) {
      case OfferFormAsyncActions.DEFAULT_PAYOUT:
        const payoutOptions = formValues.payoutOptions;
        if (payoutOptions) {
          const { index } = payload.payload as MultiplePayoutAsyncActionPayload;
          payoutOptions.forEach((element) => {
            element.isDefault = false;
          });
          payoutOptions[index].isDefault = true;
          setFormValues((fields) => ({
            ...fields,
            payoutOptions,
          }));
        }
        break;
      case OfferFormAsyncActions.PAYOUT_TYPE:
        const { index, payoutType } = payload.payload as MultiplePayoutAsyncActionPayload;
        const payoutOption = formValues.payoutOptions;
        payoutOption[index].payoutType = payoutType;
        setFormValues((fields) => ({
          ...fields,
          payoutOptions: payoutOption,
        }));
        break;
      case OfferFormAsyncActions.UPDATE_FIELD:
        const { key, value } = payload.payload as UpdateFieldAsyncActionPayload;
        if (key === 'isMultipleShopifySyncEnabled' && value && (formValues as IShopifyPromoCodeFormValues).priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT) {
          setFormValues((fields) => ({
            ...fields,
            priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
          }));
          form.setFieldsValue({
            priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
          });
          message.info({
            content: 'Offer discount type has been updated to percent as syncing of this offer to other shopify store is enabled.',
            duration: 5,
          });
        }
        if (key === 'isMultipleShopifySyncEnabled' && value) {
          const connectedAdvertiserForSync: GetConnectedShopify_clientConnections[] = (formValues as IShopifyPromoCodeFormValues).connectedAdvertiserForSync;
          const clientsForSync: ClientForSync[] = [];
          connectedAdvertiserForSync.forEach((c) => {
            if (c.isConnected && !c.isPrimary) {
              clientsForSync.push({
                advertiserId: c.connectedAdvertiserId,
                isSelected: true,
                priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
                status: CLIENT_CONNECTION_STATUS.ACTIVE,
                // discountCodeGid: ,
              });
            }
          });
          setFormValues((fields) => ({
            ...fields,
            clientsForSync,
            isSameDiscountMultipleShopify: true,
          }));
          form.setFieldsValue({
            clientsForSync,
          });
        }
        setFormValues((fields) => ({
          ...fields,
          [key]: value,
        }));
        break;
      case OfferFormAsyncActions.MULTIPLE_STORE_SELECT:
        const { advertiserId, isSelected, discountCodeGid } = payload.payload as MultipleDiscountCheckboxAsyncActionPayload;
        const clientsForSync: ClientForSync[] = (formValues as IShopifyPromoCodeFormValues).clientsForSync;
        const oldClientIndex = clientsForSync.findIndex((c) => c.advertiserId === advertiserId);
        if (!isSelected && (oldClientIndex !== -1)) {
          clientsForSync.splice(oldClientIndex, 1);
        } else if (oldClientIndex !== -1) {
          clientsForSync[oldClientIndex].isSelected = isSelected;
          clientsForSync[oldClientIndex].discountCodeGid = discountCodeGid;
        } else {
          clientsForSync.push({
            advertiserId,
            isSelected,
            priceRuleAmount: Number((formValues as IShopifyPromoCodeFormValues).priceRuleAmount),
            status: CLIENT_CONNECTION_STATUS.ACTIVE,
            discountCodeGid,
          });
        }
        setFormValues((fields) => ({
          ...fields,
          clientsForSync,
        }));
        break;
      default:
    }
  };
  const onChangeValues: FormProps['onValuesChange'] = useCallback(
    (changedValues, allValues) => {
      if (formValues.source === OFFER_SOURCE.TUNE && changedValues.payoutType) {
        const payoutType = changedValues.payoutType;
        const currentValues = form.getFieldValue('payoutOptions') || [];
        currentValues.forEach((payout) => {
          payout.payoutType = payoutType;
          payout.flatPayout = payout?.flatPayout || undefined;
          payout.percentPayout = payout?.percentPayout || undefined;
        });
        form.setFieldsValue({
          payoutOptions: currentValues,
        });
        setFormValues((fields) => ({
          ...fields,
          payoutOptions: [...form.getFieldValue('payoutOptions')],
        }));
      }
      setFormValues((fields) => ({
        ...fields,
        ...changedValues,
      }));
      if (changedValues.payoutOptions) {
        setFormValues((fields) => ({
          ...fields,
          payoutOptions: [...allValues.payoutOptions],
        }));
      }
      if (changedValues.clientsForSync) {
        setFormValues((fields) => ({
          ...fields,
          clientsForSync: [...allValues.clientsForSync],
        }));
      }
      if (changedValues.customUTMParameters) {
        setFormValues((fields) => ({
          ...fields,
          customUTMParameters: [...allValues.customUTMParameters],
        }));
      }
      if (changedValues.usageLimitRule) {
        setFormValues((fields) => ({
          ...fields,
          ...changedValues,
          usageLimitAmount: null,
        }));
        form.setFieldsValue({
          usageLimitAmount: null,
        });
      }

      if (changedValues.expirationDate) {
        setIsExpired(changedValues.expirationDate < moment());
      }
      if (!changedValues.isEndDateEnable) {
        setIsExpired(false);
      }

      if (changedValues.endDate) {
        setIsExpired(changedValues.endDate < moment());
      }

      if (changedValues.conversionTrackingType && !isEmpty(form.getFieldValue('url'))) {
        setValidateUrl(true);
      }
      setIsTouched(true);
    },
    [form, setFormValues, setIsExpired, setIsTouched, setValidateUrl, formValues],
  );
  useEffect(() => {
    if (validateUrl) {
      // manually trigger validating the URL field
      form.validateFields(['url']);
      setValidateUrl(false);
    }
  }, [form, setValidateUrl, validateUrl]);

  let trackingType: OFFER_TRACKING_TYPE | null = null;
  if (formValues.source === OFFER_SOURCE.TUNE) {
    trackingType = formValues.conversionTrackingType;
  }
  // Need some code cleaning
  useEffect(() => {
    if (initialValues.source === OFFER_SOURCE.SHOPIFY && mode === OfferFormModes.Edit && initialValues.isNewFlow) {
      Object.keys(formValues).forEach((formValuesKey) => {
        if (formValuesKey === 'isUngrouped') {
          let lockEditing = (formValues as IShopifyPromoCodeFormValues).lockEditing;
          const isInitialValue = initialValues.isUngrouped === (formValues as IShopifyPromoCodeFormValues).isUngrouped;
          if (isInitialValue && lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)) {
            lockEditing = pull([...lockEditing], OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY);
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          } else if (!isInitialValue && !lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)) {
            lockEditing = [...lockEditing, OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY];
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          }
        }
        if (formValuesKey === 'isMultipleShopifySyncEnabled') {
          let lockEditing = (formValues as IShopifyPromoCodeFormValues).lockEditing;
          const isInitialValue = initialValues.isMultipleShopifySyncEnabled === (formValues as IShopifyPromoCodeFormValues).isMultipleShopifySyncEnabled;
          if (isInitialValue && lockEditing.includes(OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_MULTIPLE_SHOPIFY_ENABLED)) {
            lockEditing = pull([...lockEditing], OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_MULTIPLE_SHOPIFY_ENABLED);
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          } else if (!isInitialValue && !lockEditing.includes(OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_MULTIPLE_SHOPIFY_ENABLED)) {
            lockEditing = [...lockEditing, OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_MULTIPLE_SHOPIFY_ENABLED];
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          }
        }
        if (formValuesKey === 'isSameDiscountMultipleShopify') {
          let lockEditing = (formValues as IShopifyPromoCodeFormValues).lockEditing;
          const isInitialValue = initialValues.isSameDiscountMultipleShopify === (formValues as IShopifyPromoCodeFormValues).isSameDiscountMultipleShopify;
          if (isInitialValue && lockEditing.includes(OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_SAME_DISCOUNT_MS)) {
            lockEditing = pull([...lockEditing], OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_SAME_DISCOUNT_MS);
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          } else if (!isInitialValue && !lockEditing.includes(OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_SAME_DISCOUNT_MS)) {
            lockEditing = [...lockEditing, OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_SAME_DISCOUNT_MS];
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          }
        }
        if (formValuesKey === 'clientsForSync') {
          let lockEditing = (formValues as IShopifyPromoCodeFormValues).lockEditing;
          const clientsForSync = (formValues as IShopifyPromoCodeFormValues).clientsForSync;
          const initialClientsForSync = initialValues.clientsForSync;
          let isNotChanged = initialClientsForSync.length === clientsForSync.length;
          if (isNotChanged) {
            for (const client of clientsForSync) {
              const oldClient = initialClientsForSync.find((initialClient) => initialClient.advertiserId === client.advertiserId);
              if (oldClient && (oldClient.isSelected !== client.isSelected || oldClient.priceRuleAmount != client.priceRuleAmount)) {
                isNotChanged = false;
                break;
              } else if (!oldClient) {
                isNotChanged = false;
                break;
              }
            }
          }
          if (isNotChanged && lockEditing.includes(OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_CLIENTS_FOR_SYNC)) {
            lockEditing = pull([...lockEditing], OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_CLIENTS_FOR_SYNC);
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          } else if (!isNotChanged && !lockEditing.includes(OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_CLIENTS_FOR_SYNC)) {
            lockEditing = [...lockEditing, OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_CLIENTS_FOR_SYNC];
            setFormValues((fields) => ({
              ...fields,
              lockEditing,
            }));
          }
        }
      });
    }
  }, [formValues, initialValues, mode]);

  const args: IChildArgs<TFormValues> = {
    actions: {
      handleChangeOfferImage,
      onChangeValues,
      onDelete,
      onUnDelete,
      onFieldFocused: changeToolTip,
      onFinish,
    },
    disabledFields,
    formOptionData,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Fix in Node upgrade typing bash!
    formRef: form,
    initialValues,
    isSaving,
    isTouched,
    offerDetails: {
      ...offerDetails,
      isExpired,
    },
    requiredFields: requiredFieldMessages,
    tooltip: <OfferFormTooltip trackingType={trackingType} type={tooltipType} />,
    values: formValues,
    handleFormAsyncActions,
  };

  return children(args);
};
