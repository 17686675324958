import 'css-chunk:src/applications/ReportsApp/components/Statistics/Statistics.module.scss';export default {
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "statistic": "_statistic_115j4_19",
  "default": "_default_115j4_33",
  "large": "_large_115j4_37",
  "padded": "_padded_115j4_41",
  "bordered": "_bordered_115j4_46",
  "strong": "_strong_115j4_52",
  "green": "_green_115j4_56",
  "red": "_red_115j4_60",
  "gray": "_gray_115j4_64"
};