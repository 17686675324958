import * as React from 'react';
import cx from 'classnames';

import { Button, Tooltip, Modal as AntdModal } from '@revfluence/fresh';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RawDraftContentState } from 'draft-js';
import {
  isArray, isEmpty, isFunction, size, isEqual,
} from 'lodash';

import { EmailComposerIcon } from '@frontend/app/components';
import { Modal } from '@components';
import { IMember, useGetMembersByIdsQuery } from '@frontend/app/hooks';
import { useResourceContext } from '@frontend/app/context';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { Space } from '@revfluence/fresh';

import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import {
 TooltipProvider, Tooltip as ShadCnTooltip, TooltipTrigger, TooltipContent,
} from '@frontend/shadcn/components/ui/tooltip';
import { Button as ShadCNButton } from '@/shadcn/components/ui/button';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogOverlay,
  AlertDialogPortal,
} from '@/shadcn/components/ui/alert-dialog';
import { EmailPreviewer } from './EmailPreviewer';
import { EmailComposer, IEmailComposer, IPreviewConfig } from './EmailComposer';
import { useExcludeMemberIds } from './useExcludeMemberIds';

const {
  useCallback, useState, useEffect, useMemo, useRef,
} = React;

import styles from './EmailComposerModal.scss';

interface IProps {
  label?: string;
  members?: IMember[];
  allowSendingAsSeparate?: boolean;
  initialMessage?: string | RawDraftContentState;
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;
  className?: string;
  source?: string;
  customButton?: (onClick: () => void) => React.ReactElement;
  isOpen?: boolean;
  /** @deprecated */
  isWorkflowEnabled?: boolean;
  disabled?: boolean;
  openComposer?: () => void;
  subject?: string;
  onMessageSent?: () => void;
  onClose?: () => void;
  hideLabel?: boolean;
  icon?: React.ReactElement;
  isSourceEditOffer?: boolean;
  hideComposerButton?: boolean;
  enableExpandingEmailEditor?: boolean;
  refreshUi?: boolean;
  isNewTableHeader?: boolean;
  hideButton?: boolean;
}

const DEFAULT_FORM_STATE = {
  attachments: [],
  memberIds: '',
  subject: '',
  additionalCc: [],
  html: '<br />',
};

/**
 * @type {React.FunctionComponent}
 */
export const EmailComposerModal: React.FunctionComponent<IProps> = React.memo(({
  allowSendingAsSeparate,
  className,
  customButton,
  isOpen,
  disabled,
  initialMessage,
  label,
  memberCount: memberCountProp,
  memberQuery,
  members,
  source,
  subject,
  openComposer,
  onMessageSent,
  onClose,
  hideLabel,
  hideComposerButton,
  enableExpandingEmailEditor,
  icon,
  isSourceEditOffer,
  refreshUi,
  isNewTableHeader,
  hideButton = false,
}) => {
  const addEvent = useEventContext();
  const { refetch: refetchResources } = useResourceContext();
  const [isComposerOpen, setIsComposerOpen] = useState(false);
  const openCompose = isOpen || isComposerOpen;
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const getDefaultEmailForm = useCallback(() => {
    let defaultForm = DEFAULT_FORM_STATE;
    if (!isEmpty(members)) {
      defaultForm = {
        ...defaultForm,
        // Need to use JSON.stringify to compare because isEqual is not working for some reason
        memberIds: JSON.stringify(members.map((member) => member.id)),
      };
    }
    return defaultForm;
  }, [members]);

  const [hasChangedForm, setHasChangedForm] = useState(getDefaultEmailForm());
  useEffect(() => {
    setHasChangedForm(getDefaultEmailForm());
  }, [members, getDefaultEmailForm]);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const messageFormRef = useRef<IEmailComposer>(null);

  const { excludeMemberIds, onExcludeMember, resetExcludeMemberIds } = useExcludeMemberIds();

  const [receipients, setReceipients] = useState<IMember[]>([]);

  const memberCount = useMemo(() => receipients.length || (members ? size(members) : memberCountProp) - size(excludeMemberIds), [members, memberCountProp, excludeMemberIds, receipients]);

  const addMessageEventLog = () => {
    if (members || memberQuery) {
      addEvent(
        EventName.AttemptBulkSendMessage,
        {
          member_count: memberCount,
          source,
        },
      );
    }
  };

  useEffect(() => {
    if (!openCompose) {
      setPreviewConfig(null);
      resetExcludeMemberIds();
    }
  }, [openCompose, setPreviewConfig, resetExcludeMemberIds]);
  useEffect(() => {
    if (openCompose) {
      refetchResources();
    }
  }, [openCompose, refetchResources]);

  const agentMemberIds = members?.flatMap((member) =>
    member.talentAgents?.map((agent) => agent.agentMemberId) || []
  ).filter(Boolean);

  // Get members for agent member IDs
  const { data: agentMembersData } = useGetMembersByIdsQuery(agentMemberIds, {
    skip: !members?.length || !agentMemberIds?.length,
  });

  const filteredMembers = useMemo(() => {
    if (!members) return undefined;

    const validMembers = members.filter((member) => member.email);
    // For members without email, add their agent members
    if (agentMembersData?.members) {
      const membersWithoutEmail = members.filter((member) => !member.email);
      const agentMembers = membersWithoutEmail.flatMap((member) =>
        member.talentAgents?.map((agent) =>
          agentMembersData.members.find((m) => m.id === agent.agentMemberId)
        )
      ).filter(Boolean);

      const emailSet = new Set(validMembers.map((member) => member.email));
      const uniqueAgentMembers = agentMembers.filter((agent) => !emailSet.has(agent.email));

      return [...validMembers, ...uniqueAgentMembers];
    }

    return validMembers;
  }, [members, agentMembersData?.members]);

  const close = useCallback(() => {
    setHasChangedForm(getDefaultEmailForm());
    setIsConfirmationModalOpen(false);
    setIsComposerOpen(false);

    if (isFunction(onClose)) {
      onClose();
    }
  }, [onClose, getDefaultEmailForm]);

  const handleClose = () => {
    if (!isEqual(getDefaultEmailForm(), hasChangedForm)) {
      setIsConfirmationModalOpen(true);
    } else {
      close();
    }
  };

  const onEditorStateChange = useCallback((content) => {
    setHasChangedForm((prev) => {
      if (isEqual(prev?.html, content?.html)) {
        return prev;
      }
      return {
        ...prev,
        html: content?.html,
      };
    });
  }, []);

  const onAttachmentsChanged = useCallback((files) => {
    setHasChangedForm((prev) => {
      if (isEqual(prev?.attachments, files)) {
        return prev;
      }
      return {
        ...prev,
        attachments: files,
      };
    });
  }, []);

  const onAdditionalCcStateChange = useCallback((additionalCc) => {
    setHasChangedForm((prev) => {
      if (isEqual(prev?.additionalCc, additionalCc)) {
        return prev;
      }
      return {
        ...prev,
        additionalCc,
      };
    });
  }, []);

  const onMembersChange = useCallback((members) => {
    setReceipients(members);
    const memberIds = members.map((member) => member.id);
    setHasChangedForm((prev) => {
      if (isEqual(prev?.memberIds, memberIds)) {
        return prev;
      }
      return {
        ...prev,
        memberIds: JSON.stringify(memberIds),
      };
    });
  }, []);

  const onSubjectChange = useCallback((subject) => {
    setHasChangedForm((prev) => {
      if (isEqual(prev?.subject, subject)) {
        return prev;
      }
      return {
        ...prev,
        subject,
      };
    });
  }, []);

  const onClick = useCallback(() => {
    if (isArray(members) && !isEmpty(members)) {
      if (filteredMembers.length === 0) {
        AntdModal.confirm({
          title: members.length === 1 ? 'Cannot email member' : 'Cannot email members',
          icon: <ExclamationCircleOutlined />,
          content: members.length === 1
            ? 'The member you selected does not have an email address or associated talent agents. Add their email to send your message.'
            : `${members.length}/${members.length} members you selected do not have email addresses or associated talent agents. Add their emails to send your message.`,
          okText: 'Okay',
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
        });
        return;
      } else if (filteredMembers.length < members.length) {
        AntdModal.confirm({
          title: 'Some members don’t have email',
          icon: <ExclamationCircleOutlined />,
          content: `${members.length - filteredMembers.length}/${members.length} members you selected do not have email 
          addresses. These members will not receive an email.`,
          okText: 'Continue',
          cancelText: 'Cancel',
          onOk: () => {
            setIsComposerOpen(true);
            addMessageEventLog();
          },
        });
        return;
      }
    }

    if (isFunction(openComposer)) {
      openComposer();
    } else {
      setIsComposerOpen(true);
    }
    addMessageEventLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMessageEventLog, setIsComposerOpen, members]);

  const onMessageSendSuccess = useCallback(() => {
    close();
    if (onMessageSent) {
      onMessageSent();
    }
  }, [close, onMessageSent]);

  const renderButton = () => {
    if (hideComposerButton) {
      return;
    }
    if (isFunction(customButton)) {
      return customButton(onClick);
    }
    return refreshUi ? (
      <TooltipProvider delayDuration={0}>
        <ShadCnTooltip>
          <TooltipTrigger>
            <span className={cx(styles.wrapper, className, { [styles.disabled]: disabled })}>
              <HeaderButton
                icon={icon || <EmailComposerIcon size={16} />}
                onClick={onClick}
                label={label}
                size={isNewTableHeader ? 'headerIcon' : 'sm'}
                variant={isNewTableHeader ? 'ghost' : 'outline'}
                disabled={disabled}
                iconPosition="before"
              />
            </span>
          </TooltipTrigger>
          {!label && (
            <TooltipContent>
              <span>Compose</span>
            </TooltipContent>
          )}
        </ShadCnTooltip>
      </TooltipProvider>
    ) : (
      <Tooltip title={label ? null : 'Compose'}>
        <span className={cx(styles.wrapper, className, { [styles.disabled]: disabled })}>
          <Button
            disabled={disabled}
            onClick={onClick}
            className={styles.workflowButton}
            icon={icon || <EmailComposerIcon size={16} />}
          >
            {!hideLabel && label}
          </Button>
        </span>
      </Tooltip>
    );
  };
  const templateHeaderText = isSourceEditOffer ? 'Notify Member' : previewConfig ? 'Preview Personalizations' : 'New Message';
  return (
    <>
      {!hideButton && renderButton()}
      <Modal
        show={openCompose}
        showCloseIcon={false}
        onRequestClose={handleClose}
        className={styles.ComposeModal}
        dialogClassName={styles.dialog}
        contentClassName={styles.content}
      >
        <div className={styles.title}>

          <Space className={styles.composerSpace}>
            {templateHeaderText}
            <Button type="text" onClick={handleClose}><XmarkIcon /></Button>
          </Space>
        </div>
        {openCompose && (
          <>
            <EmailComposer
              ref={messageFormRef}
              className={cx(styles.messageForm, {
                [styles.hide]: !!previewConfig,
              })}
              hasTopBorder
              expandHeader
              onHideComposer={handleClose}
              onMessageSent={onMessageSendSuccess}
              initialMessage={initialMessage}
              allowSendingAsSeparate={allowSendingAsSeparate}
              members={filteredMembers}
              memberCount={memberCount}
              memberQuery={memberQuery}
              onMembersChange={onMembersChange}
              onPreview={(config) => setPreviewConfig(config)}
              source={source}
              hasFixedHeight
              subject={subject}
              enableExpandingEmailEditor={enableExpandingEmailEditor}
              isSourceEditOffer={isSourceEditOffer}
              onClose={handleClose}
              onSubjectChange={onSubjectChange}
              onAdditionalCcStateChange={onAdditionalCcStateChange}
              onAttachmentsChanged={onAttachmentsChanged}
              onEditorStateChange={onEditorStateChange}
            />
            {previewConfig && (
              <EmailPreviewer
                excludeMemberIds={excludeMemberIds}
                onExcludeMember={onExcludeMember}
                previewConfig={previewConfig}
                onBack={() => setPreviewConfig(null)}
                onConfirm={() => {
                  setPreviewConfig(null);
                  messageFormRef.current.sendMessage(true, excludeMemberIds);
                }}
                memberCount={memberCount}
                memberQuery={memberQuery}
              />
            )}
          </>
        )}
      </Modal>
      <AlertDialog
        open={isConfirmationModalOpen}
        onOpenChange={setIsConfirmationModalOpen}
      >
        <AlertDialogPortal>
          <AlertDialogOverlay className={styles.confirmationModalOverlay} />
          <AlertDialogContent className={styles.confirmationModalContent}>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure you want to close?</AlertDialogTitle>
              <AlertDialogDescription>
                If you close this modal, you will lose your progress, Do you want to continue?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <ShadCNButton onClick={close}>Yes</ShadCNButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </>
  );
});

EmailComposerModal.displayName = 'EmailComposerModal';
