import React from 'react';
import { Card, CardContent, CardHeader } from '@frontend/shadcn/components/ui/card';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { H4 } from '@frontend/shadcn/components/typography/h4';
import { P } from '@frontend/shadcn/components/typography/p';
import { ChevronUpIcon, ChevronDownIcon, LayerGroupIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { useGetAllProjectsQuery, useGetAllSpecificationTemplatesQuery, useGetAllWorkletsForParentSpecQuery } from '@frontend/app/hooks';
import { useClientFeatureEnabled } from '@frontend/app/hooks/useClientFeatureEnabled';
import { ClientFeature } from '@frontend/app/constants';
import {
 Tabs, TabsContent, TabsList, TabsTrigger,
} from '@frontend/shadcn/components/ui/tabs';
import { GetAllProjectsQuery_projects } from '@frontend/app/queries/types/GetAllProjectsQuery';
import { format } from 'date-fns';
import { TEMPLATE_TO_CAMPAIGN_MAP } from '../constants';
import { CustomProjectTemplateName } from '../../constants';
import { TEMPLATES_FOR_SALES } from '../../TemplatesPage/TemplatesPage';
import { useCampaign } from '../CampaignContext';
import { getCampaignDescription, getCampaignIcon } from '../utils';
import { SectionCard } from '../components/SectionCard';

const ProjectCard: React.FC<{
  project: GetAllProjectsQuery_projects;
  onClick: () => void;
  isSelected: boolean;
}> = ({ project, onClick, isSelected }) => (
  <Card
    key={project.id}
    onClick={onClick}
    className={isSelected ? 'border-primary bg-gray-50 shadow-md' : ''}
  >
    <CardContent className="flex items-center gap-3 p-3 rounded-2xl hover:bg-grey-1 cursor-pointer">
      <img
        src={project.splashImageUrl || 'https://storage.googleapis.com/aspirex-static-files/new_logo.png'}
        alt={`${project.title} campaign logo`}
        className="w-10 h-10 rounded-lg object-cover"
      />
      <div className="flex-1">
        <h4 className="text-sm font-medium text-grey-6 mb-0">{project.title}</h4>
        {project.programMetadata?.startDate && (
          <p className="text-xs text-primaryMuted mb-0">
            {format(new Date(project.programMetadata?.startDate), 'MMM d, yyyy')}
            {project.programMetadata?.endDate && ` - ${format(new Date(project.programMetadata?.endDate), 'MMM d, yyyy')}`}
          </p>
        )}
      </div>
    </CardContent>
  </Card>
);

const CampaignDuplicator: React.FC = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const { setWorklets, setDuplicateCampaignId, duplicateCampaignId } = useCampaign();

  const { data: projectsData } = useGetAllProjectsQuery();

  const {
    refetch: refetchWorklets,
  } = useGetAllWorkletsForParentSpecQuery({
    skip: true,
  });

  const activeProjects = React.useMemo(() => projectsData?.projects
      .filter((project) => project.status === 'active')
      .sort((a, b) => a.title.localeCompare(b.title)), [projectsData]);

  const archivedProjects = React.useMemo(() => projectsData?.projects
      .filter((project) => project.status === 'archived')
      .sort((a, b) => a.title.localeCompare(b.title)), [projectsData]);

  const handleProjectSelect = async (parentSpecKey: string, projectId: number) => {
    const { data: workletsData } = await refetchWorklets({
      specKey: parentSpecKey,
    });
    setWorklets(workletsData?.worklets || [], true);
    setDuplicateCampaignId(projectId);
  };

  return (
    <Card className="w-full border-none">
      <CardHeader className="cursor-pointer p-0" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-base font-medium">Duplicate an Existing Campaign</h3>
            <p className="text-xs text-grey-5">Launch your new campaign by duplicating an existing campaign.</p>
          </div>
          {isExpanded ? <ChevronUpIcon className="text-grey-5" /> : <ChevronDownIcon className="text-grey-5" />}
        </div>
      </CardHeader>
      {isExpanded && (
        <CardContent className="px-0">
          <Tabs defaultValue="active" className="w-full">
            <TabsList className="w-full grid grid-cols-2 bg-grey-1 h-8 py-0 px-0.5">
              <TabsTrigger value="active" className="data-[state=active]:bg-white h-7">
                Active
              </TabsTrigger>
              <TabsTrigger value="archived" className="data-[state=active]:bg-white h-7">
                Archived
              </TabsTrigger>
            </TabsList>
            <TabsContent value="active" className="mt-4">
              <div className="space-y-3 max-h-[300px] overflow-y-auto">
                {activeProjects?.map((project) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    onClick={() => handleProjectSelect(project.specKey, project.id)}
                    isSelected={duplicateCampaignId === project.id}
                  />
                ))}
              </div>
              {!activeProjects?.length && (
                <div className="h-32 flex items-center justify-center text-grey-5">No active campaigns</div>
              )}
            </TabsContent>
            <TabsContent value="archived" className="mt-4">
              <div className="space-y-3 max-h-[300px] overflow-y-auto">
                {archivedProjects?.map((project) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    onClick={() => handleProjectSelect(project.specKey, project.id)}
                    isSelected={duplicateCampaignId === project.id}
                  />
                ))}
              </div>
              {!archivedProjects?.length && (
                <div className="h-32 flex items-center justify-center text-grey-5">No archived campaigns</div>
              )}
            </TabsContent>
          </Tabs>
        </CardContent>
      )}
    </Card>
  );
};

const CampaignCard: React.FC<{
  name: string;
  description: string;
  icon: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
}> = ({
 name, description, icon, isSelected, onClick,
}) => (
  <Card
    onClick={onClick}
    className={cn(
        'cursor-pointer border border-border hover:shadow-lg transition-all overflow-hidden',
        isSelected && 'border-primary bg-gray-50 shadow-md',
      )}
  >
    <CardContent className="flex p-0">
      {/* Icon with Dark Background */}
      <div className="flex items-center justify-center w-12 bg-foreground text-background">
        {icon}
      </div>
      <div className="flex flex-col p-4">
        <H4 className="font-medium mb-0">{name}</H4>
        <p className="text-sm text-muted-foreground mb-0">{description}</p>
      </div>
    </CardContent>
  </Card>
);

export const CampaignTypeSection: React.FC = () => {
  const { templateName, setTemplateDetails } = useCampaign();
  const isDemoAccount = useClientFeatureEnabled(ClientFeature.DEMO);
  const { data: templatesData } = useGetAllSpecificationTemplatesQuery();

  const campaignTypes = React.useMemo(() => {
    const templates = isDemoAccount
      ? TEMPLATES_FOR_SALES
      : (templatesData?.templates || []);

    return templates
      .concat({
        templateName: CustomProjectTemplateName,
        templateLogoURL: 'https://storage.googleapis.com/aspirex-static-files/new_logo.png',
        workletSpecKeys: [],
      })
      .map((template, index) => ({
        id: index + 1,
        name: TEMPLATE_TO_CAMPAIGN_MAP[template.templateName],
        description: getCampaignDescription(template.templateName),
        icon: getCampaignIcon(template.templateName),
      }));
  }, [templatesData, isDemoAccount]);

  const handleCampaignTypeSelect = (campaign: typeof campaignTypes[0]) => {
    setTemplateDetails(
      campaign.name,
      [],
    );
  };

  return (
    <SectionCard>
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <LayerGroupIcon />
          <P>Campaign Type</P>
        </div>
        <H3 className="font-medium mb-0">What type of campaign do you want to run?</H3>
      </div>
      <div className="flex flex-col gap-4">
        {campaignTypes.map((campaign) => (
          <CampaignCard
            key={campaign.id}
            name={campaign.name}
            description={campaign.description}
            icon={campaign.icon}
            isSelected={templateName === campaign.name}
            onClick={() => handleCampaignTypeSelect(campaign)}
          />
        ))}
      </div>
      <CampaignDuplicator />
    </SectionCard>
  );
};
