import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';

export const NetworksTab = () => (
  <div className="p-4">
    <H3>Networks Analytics</H3>
    <P>
      Evaluate performance across different social networks. Compare metrics and engagement across platforms to
      optimize your social media presence.
    </P>
  </div>
  );
