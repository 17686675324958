import React from 'react';
import { SideDrawer } from '@frontend/app/refresh-components/SideDrawer';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { TeamMemberSelect } from '@frontend/app/components/TeamMemberSelect/TeamMemberSelect';
import { Card } from '@frontend/shadcn/components/ui/card';
import { ArrowUpRightFromSquareIcon } from '@revfluence/fresh-icons/regular/esm';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { ClientFeature } from '@frontend/app/constants';
import { useCampaign } from './CampaignContext';

export const ContentAdvancedDrawer: React.FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
}> = ({ open, onOpenChange }) => {
  const {
    contentReviewEnabled,
    contentReviewers,
    gcrHideApprovalAction,
    setContentReviewEnabled,
    setContentReviewers,
    setGcrHideApprovalAction,
  } = useCampaign();

  const { [ClientFeature.GCR_HIDE_APPROVAL_ACTION]: isGCRHideApprovalActionEnabled } = useClientFeatures();

  return (
    <SideDrawer
      open={open}
      onOpenChange={onOpenChange}
      headerProps={{ title: 'Content Advanced Settings' }}
      footerButtons={[
        { name: 'Save', onClick: () => onOpenChange(false), variant: 'default' },
        { name: 'Cancel', onClick: () => onOpenChange(false), variant: 'outline' },
      ]}
    >
      <div className="space-y-6 p-6">
        {/* Content Guidelines */}
        <div>
          <div className="flex items-center justify-between">
            <h3 className="font-medium">Content Guidelines</h3>
            <ArrowUpRightFromSquareIcon className="h-4 w-4 text-muted-foreground cursor-pointer" />
          </div>
          <p className="text-xs text-primaryMuted mb-3">
            To edit and add content guidelines, visit Content Settings.
          </p>
        </div>

        {/* Group Content Review */}
        <div>
          <h3 className="font-medium mb-1.5">Group Content Review</h3>
          <p className="text-xs text-primaryMuted mb-3">
            Group Content Review enables you to review content with outside team members.
          </p>
          <Card className="p-5">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Enable Group Content Review</span>
              <Switch
                checked={contentReviewEnabled}
                onCheckedChange={setContentReviewEnabled}
              />
            </div>

            {contentReviewEnabled && (
              <div className="space-y-1 mt-4">
                <label className="text-sm block">Additional Content Reviewers</label>
                <TeamMemberSelect
                  value={contentReviewers}
                  onChange={setContentReviewers}
                  includeGuests
                  placeholder="Select team members"
                  className="w-full"
                />
                <p className="text-xs text-primaryMuted">
                  Users selected as Additional Content Reviewers will be able to approve or reject content directly
                </p>
              </div>
            )}
          </Card>
          {contentReviewEnabled && isGCRHideApprovalActionEnabled && (
            <Card className="p-5 mt-4">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Hide Approval Action</span>
                <Switch
                  checked={gcrHideApprovalAction}
                  onCheckedChange={setGcrHideApprovalAction}
                />
              </div>

              <div className="space-y-1 mt-4">
                <p className="text-xs text-primaryMuted">
                  When Approval action is hidden, the Content Reviewers can only either Reject or Approve with Comments. In both these scenarios, you will be able to review their decision and comments and manage the communications to the creators.
                </p>
              </div>
            </Card>
          )}
        </div>
      </div>
    </SideDrawer>
  );
};
