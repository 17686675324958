/**
 * Formats an ISO date string to MM/DD/YY format
 * @param isoDateString ISO date string (e.g., 2025-03-12T06:34:52.499Z)
 * @returns Formatted date string (e.g., 03/12/25)
 */
export function formatPaymentDate(isoDateString: string): string {
    if (!isoDateString) return '';

    const date = new Date(isoDateString);

    // Handle invalid date
    if (isNaN(date.getTime())) return '';

    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    // Get last 2 digits of year
    const year = date.getFullYear() % 100;
    const yearStr = String(year).padStart(2, '0');

    return `${month}/${day}/${yearStr}`;
}
