import * as React from 'react';
import { Link } from 'react-router-dom';
import xss from 'xss';
import cx from 'classnames';
import { Alert } from 'antd';
import { useGetCurrentClient, useProjectByIdQuery } from '@frontend/app/hooks';
import { Input } from '@revfluence/fresh';

import { RichTextEditor } from '@frontend/app/components';
import { emailRegex } from '@components';
import { FormFields } from '../FormFields';
import {
  IApplicationFormFields,
  TTemplateProps,
} from '../../types';

import styles from './Form.scss';
import { ApplicationPageBuilderComponent, isBuilderComponentUsedByTemplate, ProjectApplicationPageTemplateName } from '../../../applicationPageUtils';

const { useCallback, useMemo, useState } = React;

export type TFormProps = TTemplateProps['application'];

interface IProps {
  applicationFormFields?: IApplicationFormFields;
  formProps?: TFormProps;
  onChange?(values: TFormProps);
  onError?(hasError: boolean);
  onChangeApplicationFormFields?(formFields: IApplicationFormFields);
  onToggleSeeAllFields?();
  projectId: number;
  template?: ProjectApplicationPageTemplateName;
}

export const Form: React.FC<IProps> = (props) => {
  const {
    projectId,
    formProps,
    onChange,
    onError,
    applicationFormFields,
    onChangeApplicationFormFields,
    onToggleSeeAllFields,
    template,
  } = props;

  const [hasError, setHasError] = useState<boolean>(false);

  const {
    data: {
      project = undefined,
    } = {},
  } = useProjectByIdQuery({
    variables: {
      id: projectId || undefined,
    },
    skip: !projectId,
  });

  const {
    client: {
      id: clientId,
    },
  } = useGetCurrentClient();

  const handleChange = useCallback((values: Partial<TFormProps>) => {
    if (onChange) {
      onChange({ ...formProps, ...values });
    }
  }, [formProps, onChange]);

  const handleChangeDescription = useCallback((value: string) => {
    handleChange({ description: value });
  }, [handleChange]);

  const handleChangeSupportEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const supportEmail = e.target.value;
    if (supportEmail && !emailRegex.test(supportEmail)) {
      setHasError(true);
      onError(true);
    } else {
      setHasError(false);
      onError(false);
    }
    handleChange({ support_email: supportEmail });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  const handleChangeApplyLabel = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ apply_label: e.target.value });
  }, [handleChange]);

  const handleChangeUnpaidLabel = useCallback((value: string) => {
    handleChange({ unpaidLabel: value });
  }, [handleChange]);

  const handleChangeUnpaidDescription = useCallback((value: string) => {
    handleChange({ unpaidDescription: value });
  }, [handleChange]);

  const handleChangeFormTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ title: e.target.value });
  }, [handleChange]);

  const iframeUrl = useMemo(() => {
    const isLocal = window?.location?.hostname === 'localhost';

    const baseUrl = isLocal
      ? window.location.origin
      : 'https://community.aspireiq.com';

    const pageUrl = project?.customLandingPagePath;

    return `${baseUrl}/onboarding_form/${pageUrl}?clientId=${clientId}`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, project]);

  const unpaidMessage = (
    <span className={styles.unpaidAlert}>
      You have "Mark as Unpaid" enabled in
      {' '}
      <Link to={`/projects/${projectId}/settings/details`}> Project Settings</Link>
    </span>
  );

  return (
    <div className={styles.Form}>
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.FormJoinTitle,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Join Us Title</div>
            <Input
              value={formProps?.title}
              placeholder="Join Us"
              onChange={handleChangeFormTitle}
            />
          </div>
        )
      }
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.FormJoinDescription,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Join description</div>
            <RichTextEditor
              placeholder="We would love for you to consider joining our family. Apply Now!"
              value={xss(formProps?.description)}
              onChange={handleChangeDescription}
              boundSelector="#steps"
            />
          </div>
        )
      }
      <div className={cx(styles.row, styles.fields)}>
        <div className={styles.title}>Form</div>
        <FormFields
          applicationFormFields={applicationFormFields}
          onChange={onChangeApplicationFormFields}
          onClickSeeAllFields={onToggleSeeAllFields}
        />
      </div>
      {project?.hasUnpaidOffer && (
        <>
          <div className={cx(styles.subtitle, styles.row)}>Unpaid offer indicator</div>
          <Alert message={unpaidMessage} showIcon type="warning" />
          <div className={cx(styles.subtitle, styles.row)}>Checkbox label for unpaid indicator</div>
          <Input
            value={formProps?.unpaidLabel}
            onChange={(e) => handleChangeUnpaidLabel(e.target.value)}
          />
          <div className={cx(styles.subtitle, styles.row)}>Checkbox description for unpaid indicator</div>
          <RichTextEditor
            value={xss(formProps?.unpaidDescription)}
            onChange={handleChangeUnpaidDescription}
            boundSelector="#steps"
          />
        </>
      )}
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.FormJoinButtonLabel,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Button Label</div>
            <Input
              value={formProps?.apply_label}
              onChange={handleChangeApplyLabel}
              placeholder="Apply"
            />
          </div>
        )
      }
      <div className={styles.row}>
        <div className={styles.subtitle}>Support email address</div>
        <Input
          value={formProps?.support_email}
          onChange={handleChangeSupportEmail}
          placeholder="Your brand's support email"
          status={hasError ? 'error' : ''}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.title}>
          Embed Form
        </div>
        <div className={styles.subtitle}>
          Use this code to embed just the Form Section on your website, so you can recruit prospects on your own channels
        </div>
        <Input.TextArea
          value={`<iframe src="${iframeUrl}" style="width:300px;" title="Embedded"></iframe>`}
          autoSize
        />
      </div>
    </div>
  );
};
