import * as React from 'react';

import imageCompression, { Options as ImageCompressionOptions } from 'browser-image-compression';
import { trim, uniqueId } from 'lodash';

import { logger } from '@common';
import { useGetFileUploadURL } from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import { IAttachment } from '@frontend/app/components';

const {
 useCallback, useState, useMemo, useRef,
} = React;

export const DEFAULT_IMAGE_COMPRESSION_ENABLED = false;
export const DEFAULT_IMAGE_COMPRESSION_OPTIONS: ImageCompressionOptions = {
  maxWidthOrHeight: 1024, // in pixels, maintains aspect ratio
  initialQuality: 0.8, // 0 to 1, 1 being the best quality
};

export interface IUploadConfig {
  serviceName?: string;
  parentFolder?: string;
  isTemp?: boolean;
  keepFileName?: boolean;
}

export interface IUploadOptions {
  imageCompressionEnabled?: boolean;
  imageCompressionOptions?: ImageCompressionOptions;
}

export interface IUploadProgress {
  // bytes
  uploaded: number;
  percentage: number;
}

export type TContentType = 'image' | 'video' | 'application';

export interface IContent {
  type: TContentType | string;
  file?: File;
  id?: string;
  name?: string;
  uuid?: string;
  size?: number;
  localSrc?: string;
  progress?: IUploadProgress;
  // set file url after content is uploaded
  fileUrl?: string;
  // whether to disable removing content
  disableRemove?: boolean;
  // xhr
  xhr?: XMLHttpRequest;
}

interface IErrorParams {
  isUploadAborted?: boolean;
  message: string;
}

export class UploaderError extends Error {
  public static DefaultMessage = `There was an issue uploading your file.
    If this continues to happen, please email help@aspireiq.com'`;

  private static paramDefaults: IErrorParams = {
    isUploadAborted: false,
    message: UploaderError.DefaultMessage,
  };

  public isUploadAborted: boolean;

  constructor(params: IErrorParams = UploaderError.paramDefaults) {
    // Calling parent constructor of base Error class.
    super();

    const { isUploadAborted, message } = { ...UploaderError.paramDefaults, ...params };

    // Sets the error properties.
    this.name = 'UploaderError';
    this.isUploadAborted = isUploadAborted;
    this.message = message;

    if (typeof Error.captureStackTrace === 'function') {
      // Capturing stack trace, excluding constructor call from it.
      Error.captureStackTrace(this, UploaderError);
    } else {
      this.stack = new Error().stack;
    }
  }
}

type TUpdateContentCallback = React.Dispatch<React.SetStateAction<IAttachment>>;

const uploadContent = (uploadUrl: string, href: string, content: IContent, onUpdateContent: TUpdateContentCallback) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', uploadUrl);

  xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
    const { lengthComputable, total, loaded } = event;
    if (lengthComputable) {
      const percentage = Math.floor((loaded / total) * 100);
      logger.debug(content.id, percentage);

      const progress: IUploadProgress = {
        uploaded: loaded,
        percentage,
      };

      onUpdateContent((c) => ({ ...c, progress }));
    }
  });

  const promise = new Promise<string>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        // if status is 0, operation could be aborted
        if (xhr.status === 0) {
          reject(
            new UploaderError({
              isUploadAborted: true,
              message: UploaderError.DefaultMessage,
            }),
          );
          return;
        }

        // response could be empty
        // or check for xhr.status != 200
        if (!xhr.responseURL || ![200, 204].includes(xhr.status)) {
          reject(new UploaderError());
          return;
        }

        resolve(href);
      }
    };
  });

  onUpdateContent((c) => ({ ...c, xhr }));

  // Send the raw file data instead of FormData
  xhr.setRequestHeader('Content-Type', content.file.type);
  xhr.send(content.file);

  return promise;
};

export const useUploadContent = (config: IUploadConfig) => {
  const { serviceName, isTemp = false, parentFolder, keepFileName = false } = config;

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [content, setContent] = useState<IContent>();
  const [error, setError] = useState<UploaderError>();

  const { token } = useAuth();
  const inProgressUploadRef = useRef<Promise<string>>();
  const getFileUploadURL = useGetFileUploadURL(
    serviceName,
    parentFolder,
    isTemp,
  );

  const processUpload = useCallback(
    async (file: File, type: TContentType = 'image', options?: IUploadOptions) => {
      if (file === null) return;

      setIsUploading(true);
      setContent(undefined);
      setError(undefined);

      const uploadId = `${uniqueId(type)}-${Math.floor(Math.random() * 1000000)}`;
      const uploadFilename = trim(file.name.replace(/[^\w\s.-]+/g, ''));
      const imageCompressionEnabled = options?.imageCompressionEnabled ?? DEFAULT_IMAGE_COMPRESSION_ENABLED;
      const imageCompressionOptions = options?.imageCompressionOptions ?? DEFAULT_IMAGE_COMPRESSION_OPTIONS;

      let sourceFile = file;
      if (type === 'image' && imageCompressionEnabled) {
        sourceFile = await imageCompression(file, imageCompressionOptions);
      }

      // 'File' constructor has excellent browser support, safe to use without exception handling
      // https://caniuse.com/mdn-api_file_file
      const uploadFile = new File([sourceFile], uploadFilename, { type: file.type });

      try {
        const { signedUrl, href, filenameInStorage } = await getFileUploadURL(token, keepFileName ? uploadFile.name : undefined);
        const initialContent: IAttachment = {
          id: uploadId,
          file: uploadFile,
          localSrc: URL.createObjectURL(uploadFile),
          name: uploadFile.name,
          size: uploadFile.size,
          uuid: filenameInStorage,
          type,
        };

        setContent(initialContent);
        const fileUrl = await uploadContent(signedUrl, href, initialContent, setContent);
        setContent((content) => ({ ...content, fileUrl }));
        return fileUrl;
      } catch (err) {
        // if error is not an instance of UploaderError, make it one
        if (!err || !(err instanceof UploaderError)) {
          err = new UploaderError();
        }

        setError(err);
        throw err;
      } finally {
        setIsUploading(false);
      }
    },

    [getFileUploadURL, token, keepFileName],
  );

  const upload = useCallback(
    async (file: File, type: TContentType = 'image', options?: IUploadOptions) => {
      if (inProgressUploadRef.current) {
        await inProgressUploadRef.current;
      }

      const uploadTask = processUpload(file, type, options);
      inProgressUploadRef.current = uploadTask;
      return uploadTask;
    },
    [inProgressUploadRef, processUpload],
  );

  return useMemo(
    () => ({
      upload,
      error,
      content,
      isUploading,
    }),
    [upload, error, content, isUploading],
  );
};
