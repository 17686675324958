import React, { KeyboardEvent } from 'react';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { WandSparkles } from 'lucide-react';
import { cn } from '@frontend/shadcn/lib/utils';

interface VariableInputProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  variables: string[];
  placeholder?: string;
  tooltipText?: string;
  className?: string;
  error?: boolean;
}

export const VariableInput: React.FC<VariableInputProps> = ({
  id,
  value,
  onChange,
  variables,
  placeholder,
  tooltipText = 'Insert variable',
  className,
  error,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const insertVariable = (variable: string) => {
    const cursorPosition = (document.getElementById(id) as HTMLInputElement)?.selectionStart || 0;
    const updatedValue = `${value.slice(0, cursorPosition)}{{${variable}}}${value.slice(cursorPosition)}`;
    onChange(updatedValue);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      const cursorPosition = (e.target as HTMLInputElement).selectionStart || 0;
      const beforeCursor = value.slice(0, cursorPosition);
      const match = beforeCursor.match(/\{\{[^}]*\}\}$/);

      if (match) {
        e.preventDefault();
        const newValue = value.slice(0, cursorPosition - match[0].length) + value.slice(cursorPosition);
        onChange(newValue);
      }
    }
  };

  const isVariableUsed = (variable: string) => {
    const pattern = new RegExp(`\\{\\{${variable}\\}\\}`);
    return pattern.test(value);
  };

  return (
    <div className="flex items-center border border-grey-2 rounded-lg w-full">
      <Input
        id={id}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className={cn(
          'border-none shadow-none focus:ring-0 focus:outline-none focus-visible:ring-0',
          error && 'border-red-500 focus-visible:ring-red-500',
          className,
        )}
      />
      <TooltipProvider>
        <Tooltip delayDuration={50}>
          <Popover>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  aria-label="Insert Variable"
                  className="relative hover:bg-transparent"
                >
                  <WandSparkles className="h-4 w-4" />
                </Button>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent>
              <p>{tooltipText}</p>
            </TooltipContent>
            <PopoverContent className="w-fit">
              <div className="flex flex-col gap-2">
                {variables.map((variable) => {
                  const used = isVariableUsed(variable);
                  return (
                    <Button
                      key={variable}
                      variant="ghost"
                      size="sm"
                      onClick={() => insertVariable(variable)}
                      disabled={used}
                      className={cn(
                        used && 'opacity-50 cursor-not-allowed',
                      )}
                    >
                      {variable}
                    </Button>
                  );
                })}
              </div>
            </PopoverContent>
          </Popover>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
