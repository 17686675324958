import React, {
 useCallback, useState, useEffect, useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Row, Form, Button, Typography, Alert,
 } from '@revfluence/fresh';
import { CalendarIcon } from '@revfluence/fresh-icons/regular/esm';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import { GetBudgetFiscalSettingsQuery_budgetSettingsResponse } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';
import { SackDollarIcon } from '@revfluence/fresh-icons/regular/esm';
import { useSetupBudgetFiscalSettingsMutation } from '@frontend/app/hooks/useSetupBudgetFiscalSettingsMutation';
import { LoadSpinner } from '@components';
import { Select } from '@revfluence/fresh';
import PageSkeleton from '../PageSkeleton';
import styles from './fiscalYearSettings.scss';
import FiscalYears from './components/FiscalYears';
import { monthOptions } from './fiscalYearSettings.months';
import { FiscalYearSettingsField } from './fiscalYearSettings.fields';
import { FiscalYear } from './components/FiscalYears/components/FiscalYear';

const { Option } = Select;
const { Title, Text } = Typography;

const renderIcon = () => (
  <div className={styles.iconContainer}>
    <SackDollarIcon className={styles.icon} />
  </div>
);

export interface FormValues {
  startMonth?: string;
  fiscalYears: FiscalYear[];
  quarterNames: string[];
}

function mapToFormValues(data: GetBudgetFiscalSettingsQuery_budgetSettingsResponse): FormValues {
  // @ts-ignore
  const generateIds = (fiscalYears: object[]): FiscalYear[] => fiscalYears.map((year: object) => ({
      id: uuidv4(),
      ...year,
      // @ts-ignore
      year: year.year?.toString() || 'N/A',
    }));

  // Add "Default Period" fiscal year if not present
  const hasAllYears = data?.fiscalYears?.some((year: any) => year.year === 'N/A');
  const fiscalYearsWithAll = hasAllYears ? data.fiscalYears : [
    {
      id: uuidv4(),
      yearName: 'Default Period',
      year: 'N/A',
      isArchived: false,
      isCurrentYear: false,
    },
    ...(data?.fiscalYears || []),
  ];

  // Ensure only one year is marked as current year
  const fiscalYearsWithSingleCurrent = fiscalYearsWithAll.map((year: any, index: number) => ({
    ...year,
    isCurrentYear: year.isCurrentYear && !fiscalYearsWithAll.slice(0, index).some((y: any) => y.isCurrentYear),
  }));

  return {
    startMonth: data?.startMonth || 'January',
    fiscalYears: fiscalYearsWithSingleCurrent ? generateIds(fiscalYearsWithSingleCurrent) : [],
    quarterNames: data?.quarterNames ? Object.values(data.quarterNames).map((value: string) => value || `Q${Object.keys(data.quarterNames).indexOf(value) + 1}`) : ['Q1', 'Q2', 'Q3', 'Q4'],
  };
}

function mapFormValuesToInput(values: FormValues) {
  return {
    startMonth: values?.startMonth ?? 'January',
    fiscalYears: values?.fiscalYears.map((year) => ({
      ...year,
      year: year?.year?.toString(),
      id: undefined,
      isNew: undefined,
    })),
    quarterNames: Object.keys(values?.quarterNames).reduce((acc, key) => {
      acc[`Q${parseInt(key, 10) + 1}`] = values?.quarterNames[key];
      return acc;
    }, {}),
  };
}

const getPreviousMonth = (month: string) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentMonthIndex = monthNames.indexOf(month);
  const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
  return monthNames[previousMonthIndex];
};

const FiscalYearSettings: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const { budgetSettingsResponse, loading }: { budgetSettingsResponse: GetBudgetFiscalSettingsQuery_budgetSettingsResponse, loading: boolean } = useGetBudgetFiscalSettings({});

  const { setupBudgetFiscalSettings, loading: savingSettings } = useSetupBudgetFiscalSettingsMutation();

  const [values, setValues] = useState<FormValues | null>({
    startMonth: 'January',
    fiscalYears: [],
    quarterNames: ['Q1', 'Q2', 'Q3', 'Q4'],
  } as FormValues);

  useEffect(() => {
    if (budgetSettingsResponse) {
      setValues(mapToFormValues(budgetSettingsResponse));
    }
  }, [budgetSettingsResponse]);

  const isSettingsPage = useMemo(() => location.pathname.includes('settings'), [location.pathname]);

  const handleCancel = useCallback(() => {
    history.push(`${isSettingsPage ? '/settings' : ''}/budget/masterBudgetSetup`);
  }, [history, isSettingsPage]);

  const onFinish = useCallback(async () => {
    try {
      const response = await setupBudgetFiscalSettings({
        variables: {
          budgetFiscalSettings: {
            ...mapFormValuesToInput(values),
          },
        },
      });
      setValues(mapToFormValues(response?.data?.BudgetSettingsResponse));
    } catch (e) {
      console.log(e);
    }
  }, [values, setupBudgetFiscalSettings]);

  const renderSuffixIconForMonthPicker = useCallback(() => (
    <CalendarIcon className={styles.calendarIcon} />
  ), []);

  const onChangeHandlerMap: { [key: string]: (e: string[] | number | string | FiscalYear[]) => void } = {
    [FiscalYearSettingsField.START_MONTH]: (e: string) => setValues((prev) => ({ ...prev, startMonth: e })),
    [FiscalYearSettingsField.FISCAL_YEARS]: (e: FiscalYear[]) => setValues((prev) => ({ ...prev, fiscalYears: e })),
    [FiscalYearSettingsField.QUARTER_NAMES]: (e: string[]) => setValues((prev) => ({ ...prev, quarterNames: e })),
  };
  const [form] = Form.useForm();

  const noCurrentYearPresent = useMemo(() =>
    values.fiscalYears.every((year) => !year.isCurrentYear),
    [values.fiscalYears]
  );

  if (loading) return <LoadSpinner />;

  return (
    <PageSkeleton
      title="Fiscal Year Settings"
      renderIcon={renderIcon}
      backRedirectLink={`${isSettingsPage ? '/settings' : ''}/budget/masterBudgetSetup`}
    >
      <Row
        wrap={false}
        style={{
 padding: '32px 72px', backgroundColor: '#FAFAFA', height: '100%', display: 'flex', justifyContent: 'start', gap: '60px', width: '100%',
}}
      >
        <div style={{
 display: 'flex', flexDirection: 'column', gap: '24px', flexGrow: '7', maxWidth: '640px',
}}
        >
          <Form
            name="fiscalYearSettings"
            onFinish={onFinish}
            layout="vertical"
            form={form}
            initialValues={values}
            style={{
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                padding: '24px',
              }}
          >
            <Title level={4}>Manage your Fiscal Year</Title>
            <Text className={styles.referenceText}>
              Define how your brand tracks the fiscal year so that our calculations are in sync with your business operations.
              This will determine how budget data is displayed and reported across the platform.
            </Text>
            <div className={styles.fiscalYearStartMonth}>
              <div className={styles.startMonthFieldContainer}>
                <Text>Select the month your Fiscal Year starts from</Text>
                <Select
                  value={values.startMonth}
                  onChange={(value) => onChangeHandlerMap[FiscalYearSettingsField.START_MONTH](value)}
                  placeholder="Select a month"
                  suffixIcon={renderSuffixIconForMonthPicker()}
                >
                  {monthOptions.map((option) => (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <p className={styles.referenceText}>
                Fiscal Year will end after period of 12 months i.e.,
                {getPreviousMonth(values.startMonth)}
                {' '}
                of
                {' '}
                {values.startMonth == 'January' ? 'this ' : 'next '}
                {' '}
                year.
              </p>
            </div>
            <FiscalYears fiscalYears={values.fiscalYears} handleFiscalYearsChange={onChangeHandlerMap[FiscalYearSettingsField.FISCAL_YEARS]} />
            { !isEmpty(values?.fiscalYears) && noCurrentYearPresent && (<Alert message="Please mark some year as the Current Year" type="error" showIcon className={styles.currentYearAlert} />)}
            {/* <Form.Item name="quarterNames">
              <div className={styles.quarterNamingStyleContainer}>
                <Text className={styles.quartersNamingStyleTitle}>Select Quarters Naming Style</Text>
                <div className={styles.quartersNamingStyleTitleContainer}>
                  {[0, 1, 2, 3].map((quarterIndex) => (
                    <div key={quarterIndex} className={styles.quarterNameCell}>
                      <Input
                        suffix="2024"
                        value={values.quarterNames[quarterIndex]}
                        disabled
                        onChange={(e) => {
                        const updatedQuarterNames = [...values.quarterNames];
                        updatedQuarterNames[quarterIndex] = e.target.value;
                        onChangeHandlerMap[FiscalYearSettingsField.QUARTER_NAMES](updatedQuarterNames);
                      }}
                      />
                    </div>
                ))}
                </div>
                <Text className={styles.quarterNamingStyleInfo}>Each Fiscal Year will be divided into four quarters starting from the month your Fiscal Year starts.</Text>
              </div>

            </Form.Item> */}
            <Form.Item>
              <Button
                loading={savingSettings}
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                disabled={noCurrentYearPresent || isEmpty(values?.fiscalYears)}
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" onClick={handleCancel} style={{ borderColor: 'transparent', background: 'transparent', width: '100%' }}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Row>
    </PageSkeleton>
  );
};

export default FiscalYearSettings;
