import * as React from 'react';
import { useActivationsQuery, useFuzzySearchByKeys } from '@frontend/app/hooks';
import { SelectList } from '../../SelectList';

interface IProps {
  defaultValue: number[];
  onChange(value: number[]);
}

const SEARCH_KEYS = ['name'];

export const ActivationInclusion: React.FC<IProps> = (props) => {
  const {
    activations,
  } = useActivationsQuery();

  const handleSearch = useFuzzySearchByKeys(activations, SEARCH_KEYS);

  type TOption = typeof activations[0];

  return (
    <SelectList
      showSearch
      onSearchRequest={handleSearch}
      options={activations}
      defaultSelectedIds={props.defaultValue}
      mapOptionToId={(option: TOption) => option.id}
      mapOptionToLabel={(option: TOption) => option.name}
      onChange={props.onChange}
    />
  );
};
