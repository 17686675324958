/* eslint-disable quote-props */
import React, { useRef } from 'react';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Card } from '@frontend/shadcn/components/ui/card';
import { GetSocialAnalyticsQuery, SocialNetwork, SocialPostType } from '@frontend/gql/social/graphql';
import { useMessagingContext } from '@frontend/hooks';
import { useGetTableConfigurationByName, useSaveTableConfigurationMutation } from '@frontend/app/hooks';
import { ArrowDownToLineIcon, ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/solid';
import { ColumnsIconV3 } from '@frontend/app/components/Icons';
import { SortingState } from '@tanstack/react-table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { logger } from '@common';
import { SearchInput } from '@frontend/app/refresh-components';
import MembersCard from '../members-card';
import ReachCard from '../reach-card';
import ImpressionsCard from '../impressions-card';
import EngagementCard from '../engagement-card';
import PostsCard from '../posts-card';
import MemberDistributionCard from '../member-distribution-card';
import { MEMBER_TABLE_COLUMN_CONFIG_VERSION } from '../table/config/defaultColumns';
import MemberTable, { ColumnDefinitionInput } from '../table';
import { Spinner } from '../ui/spinner';
import {
  TableHeader,
  TableHeaderSection,
  TableHeaderLayout,
  TableHeaderLabel,
  TableHeaderIconButton,
  TableHeaderPagination,
  useTablePagination,
} from '../table-header';
import ColumnDialog from '../columnSelectorDialog';
import { useColumnDialog } from '../columnSelectorDialog/hooks/useColumnDialog';
import { useMemberTable } from '../table/hooks/useMemberTable';
import { useExportMembersCsv } from '../../hooks/useExportMembersCsv';
import { useExportFullMembersCsv } from '../../hooks/useExportFullMembersCsv';
import { getColumnIds } from '../../utils/columnUtils';

const { useCallback, useEffect, useState } = React;

const TABLE_PAGE_SIZE = 50;

type ExportFormat = 'current' | 'full';

interface MembersTabProps {
  data: GetSocialAnalyticsQuery | undefined;
  isLoading: boolean;
  isPartialLoading: boolean;
  setIsPartialLoading: (isLoading: boolean) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  sorting: SortingState;
  setSorting: (sorting: SortingState) => void;
  selectedColumns: ColumnDefinitionInput[];
  setSelectedColumns: (
    columns: ColumnDefinitionInput[] | ((prev: ColumnDefinitionInput[]) => ColumnDefinitionInput[]),
  ) => void;
  queryVariables: {
    startDate: string;
    endDate: string;
    postTypes: SocialPostType[];
    includeEstimates: boolean;
    networks: SocialNetwork[];
    projectIds: number[];
    groupIds: number[];
  };
  handleSelectSegment?: (segment: string) => void;
}

export const MembersTab = ({
  data,
  isLoading,
  isPartialLoading,
  setIsPartialLoading,
  currentPage,
  setCurrentPage,
  sorting,
  setSorting,
  selectedColumns,
  setSelectedColumns,
  queryVariables,
  handleSelectSegment,
}: MembersTabProps) => {
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();
  const [updatingColumns, setUpdatingColumns] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  const updateGlobalFilterText = (text: string) => {
    setSearchText(text);
    setCurrentPage(0);
  };
  const totalInsights = data?.client?.socialAnalytics?.totalInsights;
  const memberInsights = data?.client?.socialAnalytics?.members?.results;
  const [saveTableConfiguration] = useSaveTableConfigurationMutation();
  const [editModeColumns, setEditModeColumns] = useState<ColumnDefinitionInput[]>(selectedColumns);
  const previousColumnsRef = useRef(getColumnIds(selectedColumns));

  const { data: tableConfigData, loading: tableConfigLoading } = useGetTableConfigurationByName({
    variables: { tableName: 'social-data-members-table' },
  });

  // Track column changes
  useEffect(() => {
    const currentColumnIds = getColumnIds(selectedColumns);
    const previousColumnIds = previousColumnsRef.current;

    // Find new columns that weren't in the previous set
    const newColumns = currentColumnIds.filter((id) => !previousColumnIds.includes(id));

    if (newColumns.length > 0) {
      // Merge new columns with existing updating columns
      setUpdatingColumns((prev) => [...new Set([...prev, ...newColumns])]);
    }

    // Update the ref for next comparison
    previousColumnsRef.current = currentColumnIds;
  }, [selectedColumns]);

  useEffect(() => {
    if (!isLoading) {
      setIsPartialLoading(false);
      setUpdatingColumns([]); // Clear updating columns when load completes
    }
  }, [isLoading, setIsPartialLoading]);

  useEffect(() => {
    if (
      !tableConfigLoading &&
      tableConfigData?.getTableConfigurationByName?.columnDefinitions &&
      tableConfigData?.getTableConfigurationByName?.version === MEMBER_TABLE_COLUMN_CONFIG_VERSION
    ) {
      const newColumns = tableConfigData.getTableConfigurationByName.columnDefinitions;
      setSelectedColumns(newColumns);
      setEditModeColumns(newColumns);
    }
  }, [tableConfigLoading, tableConfigData, setSelectedColumns]);

  const onSaveColumnInfo = useCallback(
    async (columns: ColumnDefinitionInput[]) => {
      try {
        await saveTableConfiguration({
          variables: {
            data: {
              version: MEMBER_TABLE_COLUMN_CONFIG_VERSION,
              tableName: 'social-data-members-table',
              columnDefinitions: columns,
            },
          },
        });
        setSelectedColumns(columns);
        setIsPartialLoading(true);
        showSuccessMessage('Columns successfully updated');
      } catch (error) {
        showErrorMessage('err');
      }
    },
    [saveTableConfiguration, showSuccessMessage, showErrorMessage, setSelectedColumns, setIsPartialLoading],
  );

  const { columnDialogProps } = useColumnDialog({
    selectedColumns: editModeColumns,
    setSelectedColumns: setEditModeColumns,
    onSave: onSaveColumnInfo,
  });

  const { handleExportCsv, isExporting } = useExportMembersCsv({
    memberInsights: memberInsights || [],
    selectedColumns,
  });

  const { handleExportFullCsv, isExporting: isExportingFull } = useExportFullMembersCsv({
    clientId: data?.client?.id || '',
    queryVariables,
  });

  const isAnyExporting = isExporting || isExportingFull;

  const { paginationProps, prevButtonProps, nextButtonProps } = useTablePagination({
    currentPage,
    totalCount: memberInsights?.length || 0,
    pageSize: TABLE_PAGE_SIZE,
    onPageChange: setCurrentPage,
  });

  const { memberTableProps } = useMemberTable({
    selectedColumns,
    setSelectedColumns,
    data: memberInsights || [],
    currentPage,
    pageSize: TABLE_PAGE_SIZE,
    globalFilter: searchText,
    handleSortingChange: setSorting,
    sorting,
    setIsPartialLoading,
  });

  const publishedPostBreakdown = data?.client?.socialAnalytics?.publishedPostBreakdown;
  const activeMemberBreakdown = data?.client?.socialAnalytics?.activeMemberBreakdown?.length
    ? data?.client?.socialAnalytics?.activeMemberBreakdown
    : [];

  const handleExport = async (format: ExportFormat) => {
    try {
      if (format === 'current') {
        handleExportCsv();
        showSuccessMessage('Current view exported successfully');
      } else {
        await handleExportFullCsv();
        showSuccessMessage('Full data exported successfully');
      }
    } catch (error) {
      showErrorMessage('Failed to export data');
      logger.error('Export failed:', error);
    }
  };

  return (
    <>
      <section
        className="grid grid-cols-1 lg:grid-cols-4 gap-4 p-4"
        aria-busy={isLoading}
        aria-label="Analytics overview cards"
      >
        {isLoading && !isPartialLoading ? (
          <>
            <Skeleton className="h-[110px] rounded-xl" aria-label="Loading members card" />
            <Skeleton className="h-[110px] rounded-xl" aria-label="Loading reach card" />
            <Skeleton className="h-[110px] rounded-xl" aria-label="Loading impressions card" />
            <Skeleton className="h-[110px] rounded-xl" aria-label="Loading engagement card" />
            <Skeleton className="h-[400px] rounded-xl lg:col-span-3" aria-label="Loading posts breakdown" />
            <Skeleton className="h-[400px] rounded-xl lg:col-span-1" aria-label="Loading member distribution" />
          </>
        ) : (
          <>
            <MembersCard data={totalInsights} />
            <ReachCard data={totalInsights} />
            <ImpressionsCard data={totalInsights} />
            <EngagementCard data={totalInsights} />
            <PostsCard
              data={publishedPostBreakdown}
              className="lg:col-span-3 flex flex-col justify-between"
              aria-label="Posts breakdown by network"
            />
            <MemberDistributionCard
              data={activeMemberBreakdown}
              handleSelectSegment={handleSelectSegment}
              className="lg:col-span-1"
              aria-label="Member distribution by activity"
            />
          </>
        )}
      </section>
      {isLoading && !isPartialLoading ? (
        <Skeleton className="mx-4 mb-4 rounded-xl" aria-label="Loading members table">
          <div className="p-4">
            <Skeleton className="h-[60px] w-full rounded-lg pb-4" />
            <div className="space-y-2 pt-4">
              {Array.from({ length: 10 }, (_, i) => (
                <Skeleton key={`skeleton-row-${currentPage}-${i}`} className="h-[48px] w-full rounded-lg" />
              ))}
            </div>
          </div>
        </Skeleton>
      ) : (
        memberInsights && (
          <Card className="mx-4 mb-4" aria-label="Members table">
            <TableHeader>
              <TableHeaderLayout>
                <TableHeaderSection>
                  <TableHeaderLabel>
                    <div className="flex items-center gap-2">
                      <span aria-live="polite">{paginationProps.totalCount} members</span>
                      {isPartialLoading && <Spinner size="sm" spinning={isPartialLoading} />}
                    </div>
                  </TableHeaderLabel>
                </TableHeaderSection>
                <TableHeaderSection>
                  <SearchInput searchText={searchText} setSearchText={updateGlobalFilterText} />
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <TableHeaderIconButton
                        disabled={isAnyExporting || memberInsights?.length === 0}
                        data-dd-action-name="export-members-data"
                        aria-label={isAnyExporting ? 'Export in progress...' : 'Export member data'}
                        aria-busy={isAnyExporting}
                      >
                        <div className="flex items-center">
                          <ArrowDownToLineIcon className="h-4 w-4" aria-hidden="true" />
                        </div>
                      </TableHeaderIconButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem
                        onClick={() => handleExport('current')}
                        disabled={isAnyExporting}
                        data-dd-action-name="export-current-view"
                        aria-label="Export current view of member data"
                      >
                        Export current view
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => handleExport('full')}
                        disabled={isAnyExporting}
                        data-dd-action-name="export-full-data"
                        aria-label="Export all member data fields"
                      >
                        Export all fields
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <ColumnDialog {...columnDialogProps}>
                    <TableHeaderIconButton
                      data-dd-action-name="open-column-selector"
                      aria-label="Select columns to display"
                    >
                      <ColumnsIconV3 size={16} aria-hidden="true" />
                    </TableHeaderIconButton>
                  </ColumnDialog>
                  <nav aria-label="Table navigation" className="flex items-center">
                    <TableHeaderIconButton
                      {...prevButtonProps}
                      data-dd-action-name="members-table-previous-page"
                      aria-label="Previous page"
                      aria-disabled={currentPage === 0}
                    >
                      <ChevronLeftIcon aria-hidden="true" />
                    </TableHeaderIconButton>
                    <TableHeaderPagination
                      {...paginationProps}
                      aria-label={`Page ${currentPage + 1} of ${Math.ceil(
                        (memberInsights?.length || 0) / TABLE_PAGE_SIZE,
                      )}`}
                    />
                    <TableHeaderIconButton
                      {...nextButtonProps}
                      data-dd-action-name="members-table-next-page"
                      aria-label="Next page"
                      aria-disabled={currentPage === Math.ceil((memberInsights?.length || 0) / TABLE_PAGE_SIZE) - 1}
                    >
                      <ChevronRightIcon aria-hidden="true" />
                    </TableHeaderIconButton>
                  </nav>
                </TableHeaderSection>
              </TableHeaderLayout>
            </TableHeader>
            <MemberTable
              {...memberTableProps}
              updatingColumns={isPartialLoading ? updatingColumns : []}
              aria-busy={isLoading}
              aria-label="Members data table"
            />
          </Card>
        )
      )}
    </>
  );
};
