import { startCase } from 'lodash';

import { TPostType } from '../models';
import { getNetworkByPostType } from './getNetworkByPostType';

export default function (count: number, postType: TPostType): string {
  const network = getNetworkByPostType(postType);
  const networkName = startCase(network);

  let description = null;

  const plural = count !== 1;
  switch (postType) {
    case 'youtube_mention':
    case 'blog_mention':
      description = `${networkName} Mention${plural ? 's' : ''}`;
      break;
    case 'additional_images':
      description = `Additional Image${plural ? 's' : ''}`;
      break;
    case 'additional_videos':
    case 'instagram_video':
      description = `${networkName} Video${plural ? 's' : ''}`;
      break;
    case 'youtube_dedicated':
      description = `Dedicated Youtube Video${plural ? 's' : ''}`;
      break;
    case 'youtube_short':
      description = `YouTube Short${plural ? 's' : ''}`;
      break;
    case 'instagram':
    case 'twitter_post':
    case 'facebook_post':
      description = `${networkName} Post${plural ? 's' : ''}`;
      break;
    case 'instagram_story':
      description = plural ? 'Instagram Stories' : 'Instagram Story';
      break;
    case 'snapchat_story':
      description = plural ? 'Snapchat Stories' : 'Snapchat Story';
      break;
    case 'instagram_reel':
      description = plural ? 'Instagram Reels' : 'Instagram Reel';
      break;
    case 'blog_dedicated':
      description = `Dedicated Blog Post${plural ? 's' : ''}`;
      break;
    case 'pinterest':
      description = `Pinterest Pin${plural ? 's' : ''}`;
      break;
    case 'tiktok_video':
      description = `TikTok Video${plural ? 's' : ''}`;
      break;
    case 'other':
      description = `Piece${plural ? 's' : ''} of custom work`;
      break;
  }

  return `${count} ${description}`;
}
