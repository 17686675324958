interface IncludeEstimatesBadge {
  includeEstimates: boolean;
}

export const useIncludeEstimates = ({ includeEstimates }: IncludeEstimatesBadge) => {
  return {
    includeEstimatesBadgeProps: {
      data: [{
        value: includeEstimates?.toString() || 'false',
        label: includeEstimates ? 'True' : 'False'
      }],
    }
  };
};
