import { H3 } from '@frontend/shadcn/components/typography/h3';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Dialog, DialogContent } from '@frontend/shadcn/components/ui/dialog';
import { Progress } from '@frontend/shadcn/components/ui/progress';
import { CircleExclamationIcon, CircleCheckIcon } from '@revfluence/fresh-icons/regular/esm';
import React from 'react';

export type DeleteStageStatus = 'none' | 'progress' | 'failed' | 'success';

interface DeleteStageModalProps {
  open: boolean
  count: number
  onRequestClose(): void
  onDelete(): void
  status: DeleteStageStatus
  percentage: number
  isFlexibleSpec?: boolean
}

export function NewDeleteStageModal({
  status,
  percentage,
  onDelete,
  open,
  onRequestClose,
  count,
  isFlexibleSpec,
}: DeleteStageModalProps) {
  const message = isFlexibleSpec
    ? `You currently have ${count} members in this stage.`
    : `You currently have ${count} members in this stage that will be moved to the next step.`;

  return (
    <Dialog open={open} onOpenChange={onRequestClose}>
      <DialogContent className="p-8 rounded-none max-w-[650px]">
        <div className="flex items-center gap-4 mb-2">
          <CircleExclamationIcon className="text-warning" fontSize={24} />
          <H3 className="text-base mb-0">Delete Stage</H3>
        </div>

        <div className="pl-9">
          <p className="text-base mb-2">Are you sure you want to delete this stage?</p>

          <ul className="pl-5 mb-8 text-sm space-y-1 list-disc text-muted-foreground">
            <li>{message}</li>
            <li>
              If you delete, they will not lose deliverables such as content, terms, contracts, payment data, etc.
            </li>
            <li>Members in this stage will not receive any notification of this occurrence.</li>
          </ul>

          {status === 'none' && (
            <div className="flex justify-end gap-4">
              <Button variant="outline" onClick={onRequestClose} className="text-primary hover:text-primary">
                Keep Stage
              </Button>
              <Button onClick={onDelete}>Delete Stage</Button>
            </div>
          )}

          {status === 'progress' && (
            <div className="mb-8 overflow-hidden">
              <p className="mb-2 font-medium">Removing Stage...</p>
              <Progress value={percentage} className="h-2 [&>div]:bg-blue-4" />
            </div>
          )}

          {status === 'failed' && (
            <>
              <div className="mb-8 overflow-hidden">
                <p className="mb-2 font-medium text-red-5">Removing stage failed</p>
                <p className="mb-4 text-muted-foreground">
                  Please contact
                  {' '}
                  <a href="mailto:help@aspireiq.com" className="text-primary hover:underline">
                    help@aspireiq.com
                  </a>
                  {' '}
                  or try again.
                </p>
                <Progress value={percentage} className="h-2" />
              </div>
              <div className="flex justify-end gap-4">
                <Button variant="outline" onClick={onRequestClose} className="text-primary hover:text-primary">
                  Keep Stage
                </Button>
                <Button onClick={onDelete}>Try Again</Button>
              </div>
            </>
          )}

          {status === 'success' && (
            <>
              <div className="mb-8 overflow-hidden">
                <div className="flex items-center gap-2 mb-2">
                  <CircleCheckIcon className="text-green-5" />
                  <p className="font-medium text-green-5 mb-0">Stage removed!</p>
                </div>
                <Progress value={100} className="h-2" />
              </div>
              <div className="flex justify-end">
                <Button onClick={onRequestClose}>Close</Button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
