import {
  SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment,
  SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment,
  SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment,
} from '@frontend/gql/social/graphql';
import { formatCurrency } from '../../../../../utils/formatCurrency';

export const calculateSpendMetrics = ({
  currentInterval,
  previousInterval,
  currentIntervalNotAspireSourced,
}: {
  currentInterval: SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment | null | undefined;
  previousInterval: SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment | null | undefined;
  currentIntervalNotAspireSourced:
    | SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment
    | null
    | undefined;
}): {
  formattedCurrentInterval: string | number | null | undefined;
  formattedCurrentIntervalNotAspireSourced: string | number | null | undefined;
  percentChange: number | 'NA';
} => {
  const currentIntervalAmount = currentInterval?.totalPurchase?.amount;
  const currency = currentInterval?.totalPurchase?.currency;
  const previousIntervalAmount = previousInterval?.totalPurchase?.amount;
  const formattedCurrentInterval = currentIntervalAmount && formatCurrency(currentIntervalAmount, currency || 'USD');

  const currentIntervalNotAspireSourcedAmount = currentIntervalNotAspireSourced?.totalPurchase?.amount;
  const formattedCurrentIntervalNotAspireSourced = currentIntervalNotAspireSourcedAmount && formatCurrency(currentIntervalNotAspireSourcedAmount, currency || 'USD');
  // calculate the percent change from previous month
  const percentChange = (currentIntervalAmount
      && previousIntervalAmount
      && ((currentIntervalAmount - previousIntervalAmount) / previousIntervalAmount) * 100)
    || 'NA';

  return {
    formattedCurrentInterval,
    formattedCurrentIntervalNotAspireSourced,
    percentChange,
  };
};
