import gql from 'graphql-tag';

import { TABLE_CONFIGURATION_FRAGMENT } from './fragments'; // Import fragments if necessary

export const GET_TABLE_CONFIGURATION_BY_NAME_QUERY = gql`
  query GetTableConfigurationByNameQuery($tableName: String!) {
    getTableConfigurationByName(tableName: $tableName) {
      ...TableConfigurationFragment
    }
  }
  ${TABLE_CONFIGURATION_FRAGMENT}
`;
