import React from 'react';
import { BadgeProps } from '@frontend/shadcn/components/ui/badge';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Tooltip, TooltipTrigger, TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemKey,
  TooltipItemValue,
  TooltipContent,
} from '../../../components/tooltip';
import { DismissableBadge } from './dismissable-badge';
import { PostTypeValue } from '../filters-form';

interface PostTypeBadgesProps {
  data: Array<{ value: PostTypeValue; label: string }>
  onDismiss: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type CombinedProps = BadgeProps & PostTypeBadgesProps;

const PostTypeBadges = React.forwardRef<HTMLDivElement, CombinedProps>(({ children, className, data, onDismiss, ...props }, ref) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <DismissableBadge
              ref={ref}
              key={data.length > 0 ? data[0].value : 'posts-pill'}
              variant="secondary"
              className="mr-2 mb-2"
              aria-label="Post Type filter"
              role="button"
              data-dd-action-name="open-analytics-filters"
              {...props}
            >
              Posts:<span className="capitalize ml-1">{data.length > 0 ? data[0].label : 'All'}</span>
              { data.length > 1 && <span className="ml-1">+{data.length - 1}</span> }
              { data.length > 0 && (
                <button
                  type="button"
                  onClick={onDismiss}
                  aria-label="Remove posts filter"
                  className="flex ml-1 p-1 focus:outline-none focus:ring"
                  data-dd-action-name="remove-analytics-filters"
                >
                  <Cross2Icon className="h-3 w-3" />
                </button>
              )}
              <span className="sr-only">{data.length > 0 ? 'Remove' : 'Add'} post type filter for {data.length > 0 ? data[0].label : 'posts'}</span>
            </DismissableBadge>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipHeader>
            <TooltipTitle>Post Type Filter</TooltipTitle>
          </TooltipHeader>
          <TooltipBody>
            <TooltipItem>
              <TooltipItemKey>Selected Post Types</TooltipItemKey>
              <TooltipItemValue>
                {data.length === 0 ? 'All Post Types' : data.map((postType) => postType.label).join(', ')}
              </TooltipItemValue>
            </TooltipItem>
          </TooltipBody>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});

PostTypeBadges.displayName = 'PostTypeBadges';

export { PostTypeBadges };
