import { useState, useEffect } from 'react';
import { getSocialMediaThumbnail, ContentTypeEnum } from '../utils/getSocialMediaThumbnail';

interface UseSocialMediaThumbnailResult {
  thumbnailUrl: string | null;
  isLoading: boolean;
  error: string | null;
  contentType: ContentTypeEnum | null;
  title?: string;
}

export const useSocialMediaThumbnail = (url: string): UseSocialMediaThumbnailResult => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [contentType, setContentType] = useState<ContentTypeEnum | null>(null);
  const [title, setTitle] = useState<string | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;

    const fetchThumbnail = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await getSocialMediaThumbnail(url);

        if (isMounted) {
          setThumbnailUrl(response.thumbnailUrl);
          setContentType(response.contentType);
          setTitle(response.title);
          if (response.error) {
            setError(response.error);
          }
        }
      } catch (err) {
        if (isMounted) {
          setError('Failed to fetch thumbnail');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchThumbnail();

    return () => {
      isMounted = false;
    };
  }, [url]);

  return { thumbnailUrl, isLoading, error, contentType, title };
};
