import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';
import { Upload } from 'antd';

import {
  Button,
  CheckCircleIcon,
  IToastRefHandles,
  SpinnerIcon,
  Toast,
  XCircleIcon,
} from '@components';

import {
  CSVToArray,
  EventName,
  logger,
} from '@common';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { IWizardStepHandles } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context/EventContext';

import { ClientFeature } from '@frontend/app/constants';
import { useUploadCSVContext } from '../hooks/useUploadCSVModalContext';

const {
 useCallback, useImperativeHandle, useRef, useState,
} = React;

import styles from './UploadFile.scss';

const womanWorkingSvg = 'https://storage.googleapis.com/aspirex-static.aspireiq.com/app/public/images/a5d18880e0cc8f6d4c1e0aa1b45ddd78.svg';

interface IProps {
  nextStep: () => void;
  uploadIdentifier: string;
}

const MAX_FILE_SIZE_MB = 2;
const MAX_FILE_ROW_COUNT = 5000;

export const UploadFile = React.forwardRef<IWizardStepHandles, IProps>((props, ref) => {
  const {
    nextStep,
    uploadIdentifier,
   } = props;

   const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const { setFile } = useUploadCSVContext();
  const addEvent = useEventContext();
  const toastRef = useRef<IToastRefHandles>();
  const [isUploading, setIsUploading] = useState(false);
  const [isFileTypeError, setFileTypeError] = useState<boolean>();
  const [isFileSizeError, setFileSizeError] = useState<boolean>();
  const [isFileRowCountError, setFileRowCountError] = useState<boolean>();
  const [isDuplicateColumnError, setDuplicateColumnError] = useState<boolean>();

  const resetErrors = useCallback(
    () => {
      setFileSizeError(undefined);
      setFileTypeError(undefined);
      setFileRowCountError(undefined);
      setDuplicateColumnError(undefined);
    },
    [setFileTypeError, setFileSizeError, setFileRowCountError, setDuplicateColumnError],
  );

  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        setIsUploading(false);
        resetErrors();
      },
    }),
    [resetErrors, setIsUploading],
  );

  const success = useCallback(
    (file: File) => {
      setFile(file);
      if (isFunction(nextStep)) {
        // Pause for a while, then proceed to the next step
        setTimeout(() => {
          setIsUploading(false);
          nextStep();
        }, 1000);
      }
    },
    [nextStep, setFile, setIsUploading],
  );

  const verifyFile = useCallback(
    async (file: File) => {
      let isCorrectSize = true;

      // Check file type
      const regex = /^.+(\.csv)$/g;
      const isCSV = file.type === 'text/csv' || regex.test(file.name);

      if (!isCSV) {
        const errorMessage = 'File format must be .csv';
        logger.error(errorMessage);
        setFileTypeError(true);
        addEvent(EventName.CSVUploadLoadFileCompleted, {
          uploadIdentifier,
          success: false,
          isCSV,
          isCorrectSize,
        });
        return false;
      } else {
        setFileTypeError(false);
      }

      // Check file size
      const fileSizeLimit = file.size < MAX_FILE_SIZE_MB * 1024 * 1024;
      if (!fileSizeLimit) {
        const errorMessage = `File cannot exceed ${MAX_FILE_SIZE_MB} MB`;
        logger.error(errorMessage);
        isCorrectSize = false;
        setFileSizeError(true);
        return false;
      } else {
        setFileSizeError(false);
      }

      // Check file row count and headers
      const { rowCount, duplicateColumns } = await new Promise<{ rowCount: number; duplicateColumns: string[] }>((resolve) => {
        let duplicateColumns: string[] = [];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            const csvContent = event.target.result.toString();
            const csvArray = CSVToArray(csvContent);
            if (csvArray) {
              logger.debug(`CSV has ${csvArray.length} lines, including the header.`);

              // Check for duplicate column names (case-insensitive)
              const columnNames: string[] = csvArray[0];
              const lowercaseColumnNames: string[] = columnNames.map((col: string) => col.toLowerCase()).filter((col: string) => col !== '');
              const uniqueColumnNames = new Set(lowercaseColumnNames);
              if (uniqueColumnNames.size !== columnNames.length) {
                duplicateColumns = lowercaseColumnNames.filter((col: string, index: number) =>
                  lowercaseColumnNames.indexOf(col) !== index
                );
              }

              resolve({ rowCount: csvArray.length - 1, duplicateColumns });
              return;
            }
          }
          resolve({ rowCount: 0, duplicateColumns });
        };
      });

      if (rowCount > MAX_FILE_ROW_COUNT) {
        const formatter = new Intl.NumberFormat();
        const formattedMax = formatter.format(MAX_FILE_ROW_COUNT);
        const errorMessage = `File row count cannot exceed ${formattedMax}`;
        logger.error(errorMessage);
        setFileRowCountError(true);
        return false;
      } else {
        setFileRowCountError(false);
      }

      if (duplicateColumns.length > 0) {
        const errorMessage = `Duplicate column names found: ${duplicateColumns.join(', ')}`;
        logger.error(errorMessage);
        setDuplicateColumnError(true);
        return false;
      } else {
        setDuplicateColumnError(false);
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addEvent, setFileSizeError, setFileTypeError],
  );

  const handleBeforeUpload = useCallback(
    async (file: File) => {
      setIsUploading(true);
      resetErrors();

      if (!(await verifyFile(file))) {
        setIsUploading(false);
        return false;
      }

      addEvent(EventName.CSVUploadLoadFileCompleted, {
        uploadIdentifier,
        success: true,
        isCSV: true,
        isCorrectSize: true,
      });

      success(file);
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetErrors, setIsUploading, success, verifyFile],
  );

  return (
    <div className={styles.UploadFile}>
      <img
        src={womanWorkingSvg}
        className={styles.art}
      />
      <h4>Upload your .csv file</h4>
      <p>Before you upload, make sure that your file meets these requirements</p>
      <ul className={styles.requirements}>
        <li
          className={cx({
              [styles.hasError]: isFileTypeError,
            })}
        >
          Uploaded file is a .csv file
          {isFileTypeError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isFileTypeError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
        <li
          className={cx({
            [styles.hasError]: isFileSizeError,
          })}
        >
          Uploaded file size is under
          {' '}
          {MAX_FILE_SIZE_MB}
          {' '}
          MB
          {isFileSizeError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isFileSizeError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
        <li
          className={cx({
            [styles.hasError]: isFileRowCountError,
          })}
        >
          Number of rows is less than
          {' '}
          {new Intl.NumberFormat().format(MAX_FILE_ROW_COUNT)}
          {isFileRowCountError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isFileRowCountError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
        <li
          className={cx({
            [styles.hasError]: isDuplicateColumnError,
          })}
        >
          Each column name is unique
          {isDuplicateColumnError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isDuplicateColumnError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
      </ul>
      {isUploading
        ? (
          <div className={styles.loading}>
            <SpinnerIcon className={styles.spinnerIcon} />
          </div>
        )
        : (
          <>
            <Upload
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              className={styles.upload}
            >
              <Button
                label="Begin csv upload"
                disabled={isUploading}
                theme="primary"
                fullWidth={false}
                className={styles.button}
              />
            </Upload>
            <p className={styles.downloadTemplateLink}>
              <a
                href={workflowEnabled ? (
                'https://storage.googleapis.com/aspirex-static-files/AspireIQ%20Member%20CSV%20Template.csv'
              ) : (
                'https://storage.googleapis.com/aspirex-static-files/Member%20CSV%20Template.csv'
              )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download our template
              </a>
            &nbsp;to jumpstart your file
            </p>
          </>
)}
      <Toast ref={toastRef} />
    </div>
  );
});

UploadFile.displayName = 'UploadFile';
