import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import PaymentListPage from './PaymentListPage';
import { ReFreshPayment } from '../ReFreshPayment';
import { PaymentProvider } from '../ReFreshPayment/PaymentContext';

interface IProps {
  refreshUi: boolean;
  paymentV2: boolean;
}
const application = {
  displayName: 'Payments',
  iconUrl: 'https://storage.googleapis.com/aspirex-static-files/payment.svg',
};

const navSettings = [
  {
    route: 'reports',
    displayName: 'My Payments',
    component: PaymentListPage,
  },
];

const PaymentAppDashboard: React.FunctionComponent<IProps> = ({ refreshUi, paymentV2 }) => (
  paymentV2 ? (
    <PaymentProvider>
      <TooltipProvider>
        <ReFreshPayment />
      </TooltipProvider>
    </PaymentProvider>
  ) : refreshUi ? (
    <>
      <AppHeader
        title={application.displayName}
      />
      <div className="px-6" style={{ padding: 24 }}>
        <PaymentListPage />
      </div>
    </>
  ) : (
    <AppDashboardNav
      application={application}
      navLinks={navSettings}
      defaultRoute="reports"
      hideTabs
    />
  )
);

export default PaymentAppDashboard;
