import * as React from 'react';
import cx from 'classnames';
import {
 first, find, size, chain, map, isEmpty, slice,
} from 'lodash';

import { Select } from '@components';

import { IMember, useGetVariables } from '@frontend/app/hooks';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { ListItem } from './ListItem';
import { IPreviewConfig } from '../EmailComposer';
import { getPreviewValue } from '../EmailEditor';

const { useState, useEffect, useMemo } = React;

import styles from './ListView.scss';

const MAX_DISPLAY_FIELDS = 5;

function isGlobalVariable(field: string) {
  return ['GLOBAL_TODAY', 'GLOBAL_TOMORROW', 'GLOBAL_YESTERDAY'].includes(field);
}

interface IProps {
  previewConfig: IPreviewConfig;
  members: IMember[];
  onExcludeMember(memberId: number): void;
  disableRemoveRecipient?: boolean;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ListView: React.FC<IProps> = React.memo((props) => {
  const { previewConfig, members, disableRemoveRecipient } = props;
  const { raw, backupPlans } = previewConfig.editorState;
  // TODO - the PFA using proxy so need to pass via context
  const pfaContextVariables = useProductFulfillmentContext()?.variables;
  const pfaVariables = useProductFulfillmentContext()?.usePfaContextVariables && pfaContextVariables;
  const { variables: queryVariables } = useGetVariables({ skip: !!pfaVariables });
  const variables = pfaVariables || queryVariables;
  const [field, setField] = useState<string>(null);
  const fields = useMemo(() => chain(raw.entityMap)
      .filter((m) => m.type === 'variable' && !isGlobalVariable(m.data.data.field))
      .map((m) => m.data.data.field)
      .uniq()
      .value(), [raw]);
  const displayFields = slice(fields, 0, MAX_DISPLAY_FIELDS);
  const hiddenFields = slice(fields, MAX_DISPLAY_FIELDS);
  useEffect(() => {
    if (!field) {
      setField(first(fields));
    }
  }, [fields, field, setField]);
  const backupPlanForField = find(backupPlans, (plan) => plan.field === field);

  return (
    <div className={cx(styles.ListView, props.className)}>
      {!isEmpty(displayFields) && (
        <div className={styles.header}>
          {map(displayFields, (f) => {
            const label = variables?.member?.[f]?.label || `Field ${f}`;

            return (
              <div
                key={f}
                className={cx(styles.item, {
                  [styles.active]: field === f,
                })}
                onClick={() => setField(f)}
              >
                {label}
              </div>
            );
          })}
          {!isEmpty(hiddenFields) && (
            <Select
              className={cx(styles.moreFields, {
                [styles.active]: hiddenFields.includes(field),
              })}
              buttonClassName={styles.button}
              hintText={`+${size(hiddenFields)} More`}
              selectedIndex={hiddenFields.indexOf(field)}
              options={map(hiddenFields, (f) => ({ label: variables?.member?.[f]?.label || `Field ${f}`, value: f }))}
              onChange={(f) => setField(f)}
            />
          )}
        </div>
      )}
      <div className={styles.list}>
        {map(members, (member, index) => (
          <ListItem
            key={index}
            className={styles.item}
            member={member}
            value={getPreviewValue({
              field,
              variables,
              member,
              backupPlan: backupPlanForField,
            })}
            onExcludeMember={props.onExcludeMember}
            disableRemoveRecipient={disableRemoveRecipient}
          />
        ))}
      </div>
    </div>
  );
});
