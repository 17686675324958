import React from 'react';
import { InstagramIcon, TiktokIcon } from '@revfluence/fresh-icons/brands/esm';
import CreatorInfo from './CreatorInfo';
import CreatorAttribute from './CreatorAttribute';
import CreatorAvatar from './CreatorAvatar';
import styles from './CreatorProfile.scss';
import { generateLinkFromUsername } from '../GcrUtils';

interface MemberFieldSchema {
  id: number;
  name: string;
}

interface CreatorProfileProps {
  memberRecord: {
    id: number;
    name?: string;
    profilePicture?: string;
    metaFields: {
      instagram?: string;
      tiktok?: string;
      [key: string]: unknown;
    };
    fields?: Record<string, string>;
  };
  socialProfileData: Record<string, unknown> | null;
  applicantReviewFields: Record<string, unknown> | null;
  memberFieldSchemas?: MemberFieldSchema[] | null;
}

const CreatorProfile: React.FC<CreatorProfileProps> = ({
  memberRecord,
  socialProfileData,
  applicantReviewFields,
  memberFieldSchemas,
}) => (
  <div className={styles.creatorProfile}>
    <div className={styles.profileRow}>
      <div className={styles.profileInfo}>
        {/* profile image */}
        <CreatorAvatar memberRecord={memberRecord} />
        <div className={styles.info}>
          {/* profile name description */}
          <CreatorInfo
            memberRecord={memberRecord}
            socialProfileData={socialProfileData}
            applicantReviewFields={applicantReviewFields}
          />
          <div className={styles.socialHandle}>
            {memberRecord?.metaFields?.instagram && (
              <a href={generateLinkFromUsername('instagram', memberRecord?.metaFields?.instagram)} target="_blank">
                <InstagramIcon /> {`@${memberRecord?.metaFields?.instagram}`}
              </a>
            )}
            {memberRecord?.metaFields?.tiktok && (
              <a href={generateLinkFromUsername('tiktok', `@${memberRecord?.metaFields?.tiktok}`)} target="_blank">
                <TiktokIcon /> {`@${memberRecord?.metaFields?.tiktok}`}
              </a>
            )}
          </div>
        </div>
      </div>
      <div className={styles.statusSocialAnalytics}>
        {/* handle social demographics better in case of no status action */}
        <CreatorAttribute
          memberRecord={memberRecord}
          applicantReviewFields={applicantReviewFields}
          memberFieldSchemas={memberFieldSchemas}
        />
      </div>
    </div>
  </div>
);

export default CreatorProfile;
