import React, { useCallback, useState } from 'react';
import {
 isEmpty, noop, size, times,
} from 'lodash';

import {
 Avatar, Card, Typography, Button, Col, Divider, Row, Space, Tag,
} from '@revfluence/fresh';
import { useAuth } from '@frontend/context/authContext';
import { ContentFieldEnum } from '@frontend/app/types/globalTypes';
import { useFindAllContentFields } from '@frontend/app/hooks';
import { List, Checkbox, Input } from 'antd';
import { CheckIcon } from '@revfluence/fresh-icons/solid/esm';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { TContent, TContentIssues } from '../../types';

const { Text, Link } = Typography;

import styles from './Issues.module.scss';

export const Issues = ({
  issues,
  contentFieldValues,
  acceptedPostTypes,
  programId,
  onClose = noop,
  onSendToGCR = noop,
  onRequestChanges = noop,
  loading,
}: TContent) => {
  const [rejecting, setRejecting] = useState(false);
  const [comment, setComment] = useState('');
  const [checkOffs, setCheckOffs] = useState<boolean[]>(times(size(issues), () => false));

  const auth = useAuth();

  const { contentSubmissionForm } = useClientFeatures();

  const { contentFields } = useFindAllContentFields({
    variables: {
      data: {
        clientId: auth?.clientInfo?.id,
        postTypes: acceptedPostTypes,
        programIds: [programId],
      },
    },
  });

  const renderContentFieldValue = useCallback((field, value) => {
    const displayValue = value || '-';

    // Only render this field in edit mode if it is currently being edited
    if (displayValue === 'N/A' || displayValue === '-') return <Text>{displayValue}</Text>;

    switch (field.fieldType) {
      case ContentFieldEnum.TEXT:
      case ContentFieldEnum.NUMBER:
        return <Text>{displayValue}</Text>;

      case ContentFieldEnum.URL:
        return (
          <Link href={displayValue !== '-' ? (displayValue as string) : '#'} target="_blank" rel="noopener noreferrer">
            {displayValue}
          </Link>
        );

      case ContentFieldEnum.MULTIPLE_SELECT:
      case ContentFieldEnum.SINGLE_SELECT:
        return (
          <Space size="small" wrap>
            {displayValue !== '-' ? (
              Array.isArray(displayValue) ? displayValue.map((option: string) => <Tag key={option}>{option}</Tag>)
              : typeof displayValue === 'string' ? [displayValue].map((option: string) => <Tag key={option}>{option}</Tag>)
              : <Text>-</Text>
            ) : (
              <Text>-</Text>
            )}
          </Space>
        );

      case ContentFieldEnum.DATE:
        const date = new Date(displayValue as string);
        const formattedDate = displayValue !== '-' && !Number.isNaN(date.getTime()) ? new Intl.DateTimeFormat('en-US').format(date) : '-';
        return <Text>{formattedDate}</Text>;

      default:
        return <Text>{displayValue}</Text>;
    }
  }, []);

  const onGuidelineChecked = useCallback((index: number, checked: boolean) => {
    setCheckOffs((checkOffs) => {
      const newCheckOffs = [...checkOffs];

      newCheckOffs[index] = checked;

      return newCheckOffs;
    });
  }, []);

  return (
    <>
      <Card className={styles.Issues} bodyStyle={{ padding: '24px 24px 8px' }}>
        <Typography.Title level={5} style={{ fontSize: '14px' }}>
          {rejecting
            ? 'Select which guidelines are not met:'
            : 'This content should meet these agreed upon guidelines:'}
        </Typography.Title>
        {!rejecting && (
          <>
            <List
              dataSource={issues}
              renderItem={({ id, description }: TContentIssues) => (
                <List.Item key={id}>
                  <div className={styles.item}>
                    <Avatar
                      className={styles.avatar}
                      style={{ backgroundColor: '#FFC53D' }}
                      icon={<CheckIcon />}
                      size={24}
                    />
                    <div className={styles.info}>
                      <Typography.Text>{description}</Typography.Text>
                      <Typography.Link onClick={() => setRejecting(true)}>Request Changes</Typography.Link>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </>
        )}
        {rejecting && (
          <>
            <List
              dataSource={issues}
              renderItem={({ id, description }: TContentIssues, index) => (
                <List.Item key={id}>
                  <Checkbox checked={checkOffs[index]} onChange={(e) => onGuidelineChecked(index, e.target.checked)}>
                    {description}
                  </Checkbox>
                </List.Item>
              )}
            />
            <Divider />
            <Typography.Title level={5} style={{ fontSize: '14px' }}>
              Add a friendly comment about the changes you want:
            </Typography.Title>
            <Input.TextArea
              placeholder="Enter a comment about your requested changes..."
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={(e) => setComment(e.target.value)}
            />
            <Divider />
            <div style={{ backgroundColor: 'inherit', paddingBottom: '24px' }}>
              <Row justify="center" align="middle" gutter={[16, 24]}>
                <Col span={6}>
                  <Button block onClick={() => setRejecting(false)}>
                    Cancel
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    danger
                    block
                    loading={loading}
                    disabled={isEmpty(comment)}
                    onClick={() => onRequestChanges(comment, checkOffs)}
                  >
                    Request Changes
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Card>
      <Card className={styles.Issues} bodyStyle={{ padding: '24px 24px 8px' }}>
        <Typography.Title level={5} style={{ fontSize: '14px' }}>
          Additional Information
        </Typography.Title>
        <Space size="middle" direction="vertical" style={{ paddingTop: '16px' }}>
          {contentSubmissionForm && contentFields
            && contentFields.map((field) => (
              <Space key={field.id} size="small" direction="vertical">
                <Text style={{ color: 'black' }}>
                  {field.name}
                  :
                </Text>
                <Space size="small" direction="horizontal">
                  {renderContentFieldValue(field, contentFieldValues?.[field.id])}
                </Space>
              </Space>
            ))}
        </Space>
      </Card>
      <div style={{ backgroundColor: 'inherit', paddingBottom: '24px', paddingTop: '24px' }}>
        <Row justify="center" align="middle" gutter={[16, 24]}>
          <Col span={6}>
            <Button block onClick={onClose}>
              Close
            </Button>
          </Col>
          <Col span={6}>
            <Button type="primary" block loading={loading} onClick={onSendToGCR}>
              Send to Group Review
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
