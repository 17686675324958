import { ContentTypeEnum } from '../components/LeftFrame/components/FeaturedContent/components/FeaturedContentModal';

const urlPatterns = {
  [ContentTypeEnum.INSTAGRAM]: /instagram\.com\/(p|reel)\/[\w-]+/,
  [ContentTypeEnum.YOUTUBE]: /(?:youtube\.com\/(?:shorts\/|[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
  [ContentTypeEnum.TIKTOK]: /tiktok\.com\/@[\w.-]+\/video\/\d+/,
  [ContentTypeEnum.FACEBOOK]: /facebook\.com\/[\w.]+\/(?:posts|videos)\/[\w-]+/,
  [ContentTypeEnum.PINTEREST]: /pinterest\.com\/pin\/[\w-]+/,
  [ContentTypeEnum.IMAGE]: /\.(jpg|jpeg|png|gif|webp)(\?.*)?$/i,
  [ContentTypeEnum.GOOGLE_DRIVE]: /drive\.google\.com\/file\/d\/([\w-]+)\/view/,
};

export function detectContentType(url: string): ContentTypeEnum | null {
  for (const [platform, pattern] of Object.entries(urlPatterns)) {
    if (pattern.test(url)) {
      return platform as ContentTypeEnum;
    }
  }
  return null;
}
