import 'css-chunk:src/app/containers/Projects/ProjectsPage/WorkItemsList/WorkItemsList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "spacing-xxxl": "80px",
  "spacing-xxl": "48px",
  "spacing-xl": "32px",
  "spacing-lg": "24px",
  "spacing-md": "16px",
  "spacing-sm": "12px",
  "spacing-xs": "8px",
  "spacing-xxs": "4px",
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "flex": "_flex_10l9v_157",
  "justify-content-space-between": "_justify-content-space-between_10l9v_161",
  "tabular-nums": "_tabular-nums_10l9v_165",
  "ListDrawer": "_ListDrawer_10l9v_355",
  "title": "_title_10l9v_355",
  "controls": "_controls_10l9v_355",
  "settingsIcon": "_settingsIcon_10l9v_355",
  "divider": "_divider_10l9v_380",
  "loading": "_loading_10l9v_383",
  "drawer": "_drawer_10l9v_386",
  "tableWrapper": "_tableWrapper_10l9v_408",
  "loadSpinner": "_loadSpinner_10l9v_412",
  "empty": "_empty_10l9v_415",
  "filterList": "_filterList_10l9v_421",
  "membersTable": "_membersTable_10l9v_426",
  "tableHeader": "_tableHeader_10l9v_441",
  "bodyRow": "_bodyRow_10l9v_451",
  "checkboxCell": "_checkboxCell_10l9v_454",
  "nameHeaderCell": "_nameHeaderCell_10l9v_466",
  "singleCTAButton": "_singleCTAButton_10l9v_469",
  "headerCell": "_headerCell_10l9v_474",
  "newWorkItem": "_newWorkItem_10l9v_522",
  "customTaskNotice": "_customTaskNotice_10l9v_526",
  "listHeader": "_listHeader_10l9v_534",
  "workItemBoxContainer": "_workItemBoxContainer_10l9v_538",
  "content": "_content_10l9v_561",
  "lastMessageColumn": "_lastMessageColumn_10l9v_571",
  "spacer": "_spacer_10l9v_586",
  "memberDetailsDrawer": "_memberDetailsDrawer_10l9v_590",
  "searchIcon": "_searchIcon_10l9v_602",
  "show": "_show_10l9v_1"
};