import { MainMetric, WidgetContainer } from '@frontend/app/refresh-components';
import { CircleDollarIcon, ClockIcon, FileIcon, SackDollarIcon, TagsIcon } from '@revfluence/fresh-icons/solid/esm';
import React from 'react';
import { formatDistanceStrict } from 'date-fns';
import { IDateRangeSettings } from '@frontend/app/components';
import { isNil } from 'lodash';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { usePayment } from '../PaymentContext';

interface StatCardProps {
  title: string;
  bgColor: string;
  widgetIcon: React.ReactNode;
  dateRangeSettings: IDateRangeSettings;
  value: number;
  metricType: 'number' | 'currency' | 'count';
  trendIncrease?: number;
  subHeading?: string;
  primaryPopoverText?: string;
  secondaryPopoverText?: string;
  isLoading?: boolean;
}

function StatCard({
  title,
  bgColor,
  widgetIcon,
  dateRangeSettings,
  value,
  metricType,
  trendIncrease,
  subHeading,
  primaryPopoverText,
  secondaryPopoverText,
  isLoading,
}: StatCardProps) {
  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const distanceInWords = startDate && endDate ? formatDistanceStrict(startDate, endDate) : '';
  const loadingContent = (
    <div className="flex flex-col gap-2">
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-full" />
    </div>
  );
  const mainContent = (
    <MainMetric
      value={value}
      size="small"
      icon={null}
      metricType={metricType}
      trend={trendIncrease}
      subHeading={
        subHeading || (!isNil(trendIncrease) && distanceInWords ? `in last ${distanceInWords}` : undefined)
      }
      className="p-2 pt-0 -mt-2"
      popoverTextPrimary={primaryPopoverText}
      popoverTextSecondary={secondaryPopoverText}
    />
  );

  return (
    <WidgetContainer widgetTitle={title} bgColor={bgColor} widgetIcon={widgetIcon}>
      {isLoading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}

export function BudgetStats() {
  const { dateRangeSettings, paymentDashboardData, isPaymentDashboardLoading } = usePayment();

  const totalPayment = paymentDashboardData?.paymentSummary?.totalPayment?.amount || 0;
  const totalAmountSpent = paymentDashboardData?.paymentSummary?.totalAmountSpent?.amount || 0;
  const processingPayments = paymentDashboardData?.paymentSummary?.processingPayments?.amount || 0;
  const paidForBriefs = paymentDashboardData?.paymentSummary?.paidForBriefs?.amount || 0;
  const paidFromSalesTracking = paymentDashboardData?.paymentSummary?.paidFromSalesTracking?.amount || 0;

  // const totalPaymentTrend = paymentDashboardData?.paymentSummary?.totalPayment?.percentChange || 0;
  // const totalAmountSpentTrend = paymentDashboardData?.paymentSummary?.totalAmountSpent?.percentChange || 0;
  // const paidForBriefsTrend = paymentDashboardData?.paymentSummary?.paidForBriefs?.percentChange || 0;
  // const paidFromSalesTrackingTrend = paymentDashboardData?.paymentSummary?.paidFromSalesTracking?.percentChange || 0;

  const totalPaymentCount = paymentDashboardData?.paymentSummary?.totalPayment?.count || 0;
  const totalAmountSpentCount = paymentDashboardData?.paymentSummary?.totalAmountSpent?.count || 0;
  const processingPaymentsCount = paymentDashboardData?.paymentSummary?.processingPayments?.count || 0;
  const paidForBriefsCount = paymentDashboardData?.paymentSummary?.paidForBriefs?.count || 0;
  const paidFromSalesTrackingCount = paymentDashboardData?.paymentSummary?.paidFromSalesTracking?.count || 0;

  return (
    <div className="grid grid-cols-1 gap-4 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
      <StatCard
        title="Total Payment"
        bgColor="bg-[#003F5B]"
        widgetIcon={<CircleDollarIcon className="text-white" fontSize={16} />}
        dateRangeSettings={dateRangeSettings}
        value={totalPayment}
        subHeading={`${totalPaymentCount} payments`}
        metricType="currency"
        primaryPopoverText={`$${totalPayment.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        isLoading={isPaymentDashboardLoading}
      />
      <StatCard
        title="Total Amount Spent"
        bgColor="bg-[#2F7772]"
        widgetIcon={<SackDollarIcon className="text-white" fontSize={16} />}
        dateRangeSettings={dateRangeSettings}
        value={totalAmountSpent}
        metricType="currency"
        subHeading={`${totalAmountSpentCount} payments paid`}
        primaryPopoverText={`$${totalAmountSpent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        isLoading={isPaymentDashboardLoading}
      />
      <StatCard
        title="Processing Payments"
        bgColor="bg-[#D1A34F]"
        widgetIcon={<ClockIcon className="text-white" fontSize={16} />}
        dateRangeSettings={dateRangeSettings}
        value={processingPayments}
        metricType="currency"
        subHeading={`${processingPaymentsCount} payments in processing`}
        primaryPopoverText={`$${processingPayments.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        isLoading={isPaymentDashboardLoading}
      />
      <StatCard
        title="Paid For Briefs"
        bgColor="bg-[#339FDE]"
        widgetIcon={<FileIcon className="text-white" fontSize={16} />}
        dateRangeSettings={dateRangeSettings}
        value={paidForBriefs}
        metricType="currency"
        subHeading={`${paidForBriefsCount} payments for briefs`}
        primaryPopoverText={`$${paidForBriefs.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        isLoading={isPaymentDashboardLoading}
      />
      <StatCard
        title="Paid From Sales Tracking"
        bgColor="bg-[#4F5ABA]"
        widgetIcon={<TagsIcon className="text-white" fontSize={16} />}
        dateRangeSettings={dateRangeSettings}
        value={paidFromSalesTracking}
        metricType="currency"
        subHeading={`${paidFromSalesTrackingCount} payments from sales tracking`}
        primaryPopoverText={`$${paidFromSalesTracking.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        isLoading={isPaymentDashboardLoading}
      />
    </div>
  );
}
