import React from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Button } from '@frontend/shadcn/components/ui/button';
import { XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { Spinner } from '@revfluence/fresh';
import { v4 as uuidv4 } from 'uuid';
import { IEditPayments } from './EditPaymentDrawer';
import { usePayment } from '../PaymentContext';

interface OtherBudgetPeriodEntryProps {
  periodData: {
    key: string;
    budgetId?: number;
    amount?: number;
    granularityLabelForPayload?: string;
    periodId?: number;
  };
  setEditPayments: React.Dispatch<React.SetStateAction<IEditPayments>>;
  onRemove: () => void;
}

export const OtherBudgetPeriodEntry: React.FC<OtherBudgetPeriodEntryProps> = ({
  periodData,
  setEditPayments,
  onRemove
}) => {
  const { budgets } = usePayment();

  const { periodDetails, loading: loadingBudgetPeriodDetails } = useGetBudgetPeriodDetails({
    variables: {
      budgetAccountId: periodData.budgetId || null,
      programId: null,
    },
  });

  const handleBudgetChange = (value: string) => {
    setEditPayments((prev) => ({
      ...prev,
      otherBudgetPeriod: prev.otherBudgetPeriod.map((period) =>
        (period.key === periodData.key
          ? {
              ...period,
              budgetId: parseInt(value, 10),
              // Reset period when budget changes
              periodId: undefined,
              granularityLabelForPayload: undefined,
              key: `budget-${value}-${uuidv4()}`
            }
          : period)
      ),
    }));
  };

  const handlePeriodChange = (value: string) => {
    const selectedPeriod = financialPeriods.find((period) => period.key === value);
    if (selectedPeriod) {
      setEditPayments((prev) => ({
        ...prev,
        otherBudgetPeriod: prev.otherBudgetPeriod.map((period) =>
          (period.key === periodData.key
            ? {
                ...period,
                key: `budget-${period.budgetId}-${value}-${uuidv4()}`,
                periodId: selectedPeriod.id,
                granularityLabelForPayload: selectedPeriod.granularityLabelForPayload
              }
            : period)
        ),
      }));
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditPayments((prev) => ({
      ...prev,
      otherBudgetPeriod: prev.otherBudgetPeriod.map((period) =>
        (period.key === periodData.key
          ? { ...period, amount: parseFloat(e.target.value) }
          : period)
      ),
    }));
  };

  const financialPeriods = React.useMemo(() => {
    if (periodDetails?.length) {
      const fiscalPeriod = [];
      periodDetails.forEach((period) => {
        fiscalPeriod.push({
          key: `FY${period.fiscalYearPeriodDefinitionId}`,
          id: period.fiscalYearPeriodDefinitionId,
          label: period.fiscalYearLabel,
          granularityLabelForPayload: 'FY',
        });
        if (period.quarterDistributions?.length) {
          period.quarterDistributions.forEach((quarter) => {
            fiscalPeriod.push({
              key: quarter.quarterKey + quarter.quarterPeriodDefinitionId,
              id: quarter.quarterPeriodDefinitionId,
              label: quarter.quarterLabel,
              granularityLabelForPayload: quarter.quarterKey,
            });
          });
        }
      });
      return fiscalPeriod;
    }
    return [];
  }, [periodDetails]);

  // Get the current period key for the select value
  const currentPeriodKey = React.useMemo(() => {
    if (!periodData.key) return '';
    // If the key is in the new format (contains budget-), get the fiscal period part
    if (periodData.key.includes('budget-')) {
      const parts = periodData.key.split('-');
      return parts[2] || '';
    }
    // If it's the old format, return the key as is
    return periodData.key;
  }, [periodData.key]);

  if (loadingBudgetPeriodDetails) {
    return (
      <div className="flex flex-col gap-3 border rounded-lg p-3 mt-1 relative">
        <div className="flex justify-center items-center h-24">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 border rounded-lg p-3 mt-1 relative">
      <Button
        variant="ghost"
        size="icon"
        className="absolute -top-2 -right-2 h-6 w-6 rounded-full bg-white border hover:bg-gray-100"
        onClick={onRemove}
      >
        <XmarkIcon className="h-4 w-4" />
      </Button>

      {/* Budget Account Selection */}
      <div className="flex justify-between items-center">
        <span className="text-gray-400">Budget Account</span>
        <Select
          value={periodData.budgetId?.toString() || ''}
          onValueChange={handleBudgetChange}
        >
          <SelectTrigger className="w-[170px] h-7 text-sm">
            <SelectValue placeholder="Select Budget" />
          </SelectTrigger>
          <SelectContent className="z-[9999]">
            <SelectGroup>
              {budgets.map((budget) => (
                <SelectItem key={budget.id} value={budget.id.toString()}>
                  {budget.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {/* Fiscal Period Selection */}
      <div className="flex justify-between items-center">
        <span className="text-gray-400">Fiscal Year Period</span>
        <Select
          value={currentPeriodKey}
          onValueChange={handlePeriodChange}
          disabled={!periodData.budgetId}
        >
          <SelectTrigger className="w-[170px] h-7 text-sm">
            <SelectValue placeholder={periodData.budgetId ? 'Select Fiscal Period' : 'Select Budget First'} />
          </SelectTrigger>
          <SelectContent className="z-[9999]">
            <SelectGroup>
              {financialPeriods.length > 0 ? (
                financialPeriods.map((period) => (
                  <SelectItem key={period.key} value={period.key}>
                    {period.label}
                  </SelectItem>
                ))
              ) : (
                <SelectItem disabled value="no-period">
                  No Period
                </SelectItem>
              )}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {/* Amount */}
      <div className="flex justify-between">
        <span className="text-gray-400">Amount</span>
        <div className="bg-white rounded-lg overflow-hidden">
          <Input
            type="number"
            className="w-[170px] h-7"
            value={periodData.amount || ''}
            onChange={handleAmountChange}
          />
        </div>
      </div>
    </div>
  );
};
