import {
  IDemographicsReport,
  ISocialInsightValue,
  SocialInsightAge,
  SocialInsightGender,
  SocialInsightTimeframe,
  countries,
} from '@frontend/components/common';
import {
  every,
  filter,
  includes,
  isEmpty,
  map,
  sortBy,
  sum,
} from 'lodash';
import { IData } from '../../charts/PieChart/PieChart';

const PAST_14_DAYS_LABEL = 'Last 14 Days';
const PAST_30_DAYS_LABEL = 'Last 30 Days';
const PAST_90_DAYS_LABEL = 'Last 90 Days';
const ESTIMATED_LABEL = 'Estimated Data';
const ALL_TIME_LABEL = 'All Time';

const PAST_14_DAYS_TOOLTIP = 'Data from the last 14 days will be displayed for creators that have authenticated their accounts.';
const PAST_30_DAYS_TOOLTIP = 'Data from the last 30 days will be displayed for creators that have authenticated their accounts.';
const PAST_90_DAYS_TOOLTIP = 'Data from the last 90 days will be displayed for creators that have authenticated their accounts.';
const ESTIMATED_TOOLTIP = 'Estimated data will be displayed for creators that have not authenticated their accounts.';
const ALL_TIME_TOOLTIP = 'All time data will be displayed for creators that have authenticated their accounts.';

export const cityDataToIDataList = (demographicsReport: IDemographicsReport, cityNameOnly: boolean): IData[] => {
  if (isEmpty(demographicsReport) || isEmpty(demographicsReport.city)) {
    return [];
  }

  const cityData = demographicsReport.city[0].values;

  return sortBy(
    map(cityData, (city) => ({
      label: cityNameOnly ? city.dimension_values[0].split(',')[0] : city.dimension_values[0],
      value: city.percentage * 100,
    } as IData)),
    (city) => -city.value,
  );
};

export const stateDataToIDataList = (demographicsReport: IDemographicsReport): IData[] => {
  if (isEmpty(demographicsReport) || isEmpty(demographicsReport.state)) {
    return [];
  }

  const stateData = demographicsReport.state[0].values;

  return sortBy(
    map(stateData, (state) => ({
      label: state.dimension_values[0],
      value: state.percentage * 100,
    } as IData)),
    (state) => -state.value,
  );
};

export const countryDataToIDataList = (demographicsReport: IDemographicsReport, fullname: boolean): IData[] => {
  if (isEmpty(demographicsReport) || isEmpty(demographicsReport.country)) {
    return [];
  }

  const countryData = demographicsReport.country[0].values;

  return sortBy(
    map(countryData, (country) => ({
      label: fullname ? countries[country.dimension_values[0]] : country.dimension_values[0],
      value: country.percentage * 100,
    } as IData)),
    (country) => -country.value,
  );
};

export const ageDataToIDataList = (demographicsReport: IDemographicsReport, sortByLabel: boolean): IData[] => {
  if (isEmpty(demographicsReport) || isEmpty(demographicsReport.age)) {
    return [];
  }

  const ageData = demographicsReport.age[0].values;

  return sortBy(
    map(ageData, (age) => ({
      label: getAgeLabel(age.dimension_values[0]),
      value: age.percentage * 100,
    } as IData)),
    (age) => (sortByLabel ? age.label : -age.value),
  );
};

export const genderDataFromDemographicsReport = (demographicsReport: IDemographicsReport) => {
  /* Returns an object with the following shape:
    {
      male: number,
      female: number,
      percentage: number,
    }
  */

  if (isEmpty(demographicsReport)) {
    return {};
  }

  let values = null;
  if (!isEmpty(demographicsReport.gender)) {
    values = demographicsReport.gender[0].values;
  } else if (!isEmpty(demographicsReport.gender_age)) {
    values = demographicsReport.gender_age[0].values;
  }

  if (isEmpty(values)) {
    return {};
  }

  const malePercentage = getPercentageForDimensionValue([SocialInsightGender.MALE], values);
  const femalePercentage = getPercentageForDimensionValue([SocialInsightGender.FEMALE], values);
  const unknownPercentage = getPercentageForDimensionValue([SocialInsightGender.UNKNOWN], values);

  return {
    male: malePercentage,
    female: femalePercentage,
    unknown: unknownPercentage,
  };
};

const getPercentageForDimensionValue = (dimensionValues: (string | SocialInsightGender)[], values: ISocialInsightValue[]): number => {
  const validValues = filter(values, (value) => every(dimensionValues, (dimensionValue) => includes(value.dimension_values, dimensionValue)));
  return sum(map(validValues, (value) => value.percentage)) || 0.0;
};

export const genderAgeDataFromDemographicsReport = (demographicsReport: IDemographicsReport) => {
  /* Returns an object with the following shape:
  [
    {
      age: string,
      male: number,
      female: number,
    },
    ...
  ]
  */
  if (isEmpty(demographicsReport) || isEmpty(demographicsReport.gender_age)) {
    return [];
  }

  // Create an object to store transformed data
  const transformedData = {};

  // Iterate through the input data
  map(demographicsReport.gender_age[0].values, (genderAge) => {
    // Extract properties
    const age = getAgeLabel(filter(genderAge.dimension_values, (dimensionValue) => (includes(SocialInsightAge, dimensionValue)))[0]);
    const gender = filter(genderAge.dimension_values, (dimensionValue) => (includes(SocialInsightGender, dimensionValue)))[0];
    const value = genderAge.percentage;

    // Check if gender is known and not "UNKNOWN"
    if (gender && gender !== SocialInsightGender.UNKNOWN) {
      // Create a key based on age if it doesn't exist
      if (!transformedData[age]) {
        transformedData[age] = {
          male: 0.0,
          female: 0.0,
        };
      }

      // Assign the value to the corresponding gender key
      transformedData[age][gender.toLowerCase()] = value;
    }
  });

  // Convert the object into an array of objects
  const result = Object.keys(transformedData).map((age) => ({
    age,
    ...transformedData[age],
  }));

  return sortBy(result, (res) => res.age);
};

export const getAgeLabel = (value: SocialInsightAge | string): string => {
  const mapping = {
    [SocialInsightAge.AGE_13_17]: '13-17',
    [SocialInsightAge.AGE_18_24]: '18-24',
    [SocialInsightAge.AGE_25_34]: '25-34',
    [SocialInsightAge.AGE_35_PLUS]: '35+',
    [SocialInsightAge.AGE_35_44]: '35-44',
    [SocialInsightAge.AGE_45_54]: '45-54',
    [SocialInsightAge.AGE_45_64]: '45-64',
    [SocialInsightAge.AGE_55_64]: '55-64',
    [SocialInsightAge.AGE_65_PLUS]: '65+',
    [SocialInsightAge.AGE_UNKNOWN]: 'Unknown',
  };
  return mapping[value];
};

export const getDataTimeframeLabel = (timeframe: SocialInsightTimeframe, dateLastUpdated: Date | null): string => {
  if (!dateLastUpdated) {
    dateLastUpdated = new Date();
  }

  const now = new Date();
  const daysSinceUpdate = Math.floor((now.getTime() - dateLastUpdated.getTime()) / (1000 * 60 * 60 * 24));

  // Check if data is older than the timeframe
  switch (timeframe) {
    case SocialInsightTimeframe.PAST_14_DAYS:
      return daysSinceUpdate > 14 ? ESTIMATED_LABEL : PAST_14_DAYS_LABEL;
    case SocialInsightTimeframe.PAST_30_DAYS:
      return daysSinceUpdate > 30 ? ESTIMATED_LABEL : PAST_30_DAYS_LABEL;
    case SocialInsightTimeframe.PAST_90_DAYS:
      return daysSinceUpdate > 90 ? ESTIMATED_LABEL : PAST_90_DAYS_LABEL;
    case SocialInsightTimeframe.ESTIMATED:
      return ESTIMATED_LABEL;
    case SocialInsightTimeframe.ALL:
      return ALL_TIME_LABEL;
    default:
      return '';
  }
};

export const getDataTimeframeTooltip = (timeframe: SocialInsightTimeframe | null, dateLastUpdated: Date | null): string => {
  if (!dateLastUpdated) {
    dateLastUpdated = new Date();
  }

  const now = new Date();
  const daysSinceUpdate = Math.floor((now.getTime() - dateLastUpdated.getTime()) / (1000 * 60 * 60 * 24));

  switch (timeframe) {
    case SocialInsightTimeframe.PAST_14_DAYS:
      return daysSinceUpdate > 14 ? PAST_14_DAYS_TOOLTIP : ESTIMATED_TOOLTIP;
    case SocialInsightTimeframe.PAST_30_DAYS:
      return daysSinceUpdate > 30 ? PAST_30_DAYS_TOOLTIP : ESTIMATED_TOOLTIP;
    case SocialInsightTimeframe.PAST_90_DAYS:
      return daysSinceUpdate > 90 ? PAST_90_DAYS_TOOLTIP : ESTIMATED_TOOLTIP;
    case SocialInsightTimeframe.ESTIMATED:
      return ESTIMATED_TOOLTIP;
    case SocialInsightTimeframe.ALL:
      return ALL_TIME_TOOLTIP;
    default:
      return '';
  }
};
