import React from 'react';
import { LazyImage } from '@components';
import useConvertHeicToJpg from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage/utils/useConvertHeicToJpg';
import { Spinner } from '@revfluence/fresh';
import { Content, IContentProps } from './Content';
import styles from './ImageContent.scss';

const ImageContent: React.FC<IContentProps> = ({ content, classNames = [], ...props }) => {
  const convertedThumbnailUrl = useConvertHeicToJpg(content.src);
  if (!convertedThumbnailUrl) return <Spinner />;
  return (
    <Content content={content} classNames={[...classNames, styles.ImageContent]} {...props}>
      <LazyImage className={styles.image} src={convertedThumbnailUrl} key={content.src} />
    </Content>
  );
};

export default ImageContent;
