import * as React from 'react';
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';

import {
  Form,
} from '@revfluence/fresh';
import { OFFER_PRICE_RULE_TYPE } from '@affiliates/types/globalTypes';
import {
  FormAsyncAction, IShopifyFormElementProps, IShopifyPromoCodeFormValues, OfferFormAsyncActions, OfferFormModes,
} from '../../../../types';
import { RefreshOfferCodeDiscountAmount } from './RefreshOfferCodeDiscountAmount';
import { RefreshOfferCodeDiscountPercentAmount } from './RefreshOfferCodeDiscountPercentAmount';

const { useMemo } = React;

interface IProps extends IShopifyFormElementProps<'priceRuleType'> {
  value: IShopifyPromoCodeFormValues['priceRuleType'];
  isMultipleShopifyEnabled?: boolean;
  mode?: OfferFormModes,
  handleFormAsyncActions: (value: FormAsyncAction) => void;
}

export const RefreshOfferCodeDiscountOptions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  isMultipleShopifyEnabled,
  handleFormAsyncActions,
}) => {
  const rules = [
    { required: true, message: 'Please select a discount option!' },
  ];

  const handlePriceRuleChange = (type: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value: type },
    });
  };
  const renderAmount = useMemo(() => {
    switch (value) {
      case OFFER_PRICE_RULE_TYPE.AMOUNT:
        return (
          <RefreshOfferCodeDiscountAmount
            disabled={false}
            name="priceRuleAmount"
          />
        );
      case OFFER_PRICE_RULE_TYPE.PERCENTAGE:
        return (
          <RefreshOfferCodeDiscountPercentAmount
            disabled={false}
            name="priceRuleAmount"
          />
        );
      default:
        return null;
    }
  }, [value]);
  return (
    <div className="relative">
      <div className="flex items-start">
        <div>
          <Form.Item rules={rules} className="!mb-0" noStyle>
            <Select value={value} onValueChange={handlePriceRuleChange} disabled={disabled || isMultipleShopifyEnabled}>
              <SelectTrigger className="w-full min-w-[125px] border-r border-grey-2 rounded-r-none">
                <SelectValue placeholder="Select Price Rule Type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={OFFER_PRICE_RULE_TYPE.PERCENTAGE}>Percentage</SelectItem>
                {!isMultipleShopifyEnabled && (
                  <SelectItem value={OFFER_PRICE_RULE_TYPE.AMOUNT}>Flat</SelectItem>
                )}
              </SelectContent>
            </Select>
          </Form.Item>
        </div>
        <div className="flex-1">
          {renderAmount}
        </div>
      </div>
    </div>
  );
});
