import { LandingPageData } from '../context/LandingPageContext';

  interface FieldErrors {
    basic?: {
      title?: string;
      description?: string;
      creatorsTag?: {
        title?: string;
      };
      discountOfferButton?: {
        title?: string;
      };
      featuredProduct?: {
        id?: string;
      };
      // featuredProductDiscount?: {
      //   featureProduct?: {
      //     id?: string;
      //   };
      // };
    };
    featuredContent?: {
      urls?: string;
      settings?: {
        noOfContentForCreator?: string;
      };
    };
    products?: {
      setting?: {
        noOfProductsCreatorCanAdd?: string;
        creatorProductSelectionType?: string;
      };
      featuredProduct?: {
        id?: string;
      };
    };
  }

  export function validateLandingPageData(data: LandingPageData): FieldErrors {
    const errors: FieldErrors = {};

    // Basic section validations
    if (!data.basic.title) {
      errors.basic = {
        ...errors.basic,
        title: 'Title is required',
      };
    }
    if (!data.basic.description) {
      errors.basic = {
        ...errors.basic,
        description: 'Description is required',
      };
    }
    if (!data.basic.discountOfferButton.title) {
      errors.basic = {
        ...errors.basic,
        discountOfferButton: {
          title: 'Discount offer button title is required',
        },
      };
    }

    // Creator tag validation
    if (data.basic.creatorsTag.showCreatorTag && !data.basic.creatorsTag.title) {
      errors.basic = {
        ...errors.basic,
        creatorsTag: {
          title: 'Creator tag title is required when enabled',
        },
      };
    }

    // Featured product validation for hero section
    if (data.basic?.featuredProductDiscount?.addHeroFeatureProduct === true && !data.featureProduct) {
      errors.basic = {
        ...errors.basic,
        featuredProduct: {
          id: 'Featured product is required when hero feature is enabled',
        },
      };
    }

    // Featured content validation
    if (data.featuredContent.isFeaturedContentEnabled) {
      if (!data.featuredContent.urls.length) {
        errors.featuredContent = {
          ...errors.featuredContent,
          urls: 'At least one featured content URL is required when featured content is enabled',
        };
      }
      if (data.featuredContent.settings.allowCreatorToUpload
          && (!data.featuredContent.settings.noOfContentForCreator
           || data.featuredContent.settings.noOfContentForCreator < 1)) {
        errors.featuredContent = {
          ...errors.featuredContent,
          settings: {
            noOfContentForCreator: 'Number of content for creator must be specified when creator upload is enabled',
          },
        };
      }
    }

    // Products validation
    if (data.products.setting.allowCreatorToAddProducts) {
      if (!data.products.setting.noOfProductsCreatorCanAdd) {
        errors.products = {
          ...errors.products,
          setting: {
            noOfProductsCreatorCanAdd: 'Number of products creator can add must be specified when creator products are enabled',
          },
        };
      }
      if (!data.products.setting.creatorProductSelectionType) {
        errors.products = {
          ...errors.products,
          setting: {
            creatorProductSelectionType: 'Creator product selection type must be specified when creator products are enabled',
          },
        };
      }
    }

    // Featured product validation for products section
    if (data.products?.isFeaturedProductEnable === true && !data.featureProduct) {
      errors.products = {
        ...errors.products,
        featuredProduct: {
          id: 'Featured product is required when product feature is enabled',
        },
      };
    }

    return errors;
  }
