/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --code-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  --font-size-paragraph: 16px;
  --font-size-base: 14px;
  --font-size-xl: 20px;
  --font-size-lg: 16px;
  --font-size-sm: 12px;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
}

:root {
  --border-radius-max: 9999px;
  --border-radius-lg: 12px;
  --border-radius-base: 6px;
  --border-radius-sm: 4px;
  --border-radius-xs: 2px;
  --height-lg: 40px;
  --height-base: 32px;
  --height-sm: 24px;
}

._agentPanelContent_13s3j_286 {
  background-color: white;
}
._agentPanelContent_13s3j_286 .ant-collapse-content {
  background-color: white;
  padding-top: 10px;
}
._agentPanelContent_13s3j_286 .ant-collapse-header {
  padding-right: 12px !important;
}
._agentPanelContent_13s3j_286 label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
}
._agentPanelContent_13s3j_286 .ant-collapse-header-text {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
}
._agentPanelContent_13s3j_286 .ant-collapse-content-box {
  padding-bottom: 0px !important;
}
._agentPanelContent_13s3j_286 .ant-form-item {
  padding-bottom: 8px;
  margin: 0;
}
._agentPanelContent_13s3j_286 ._userIcon_13s3j_313 {
  color: #1f1f21;
  font-size: 14px;
}
._agentPanelContent_13s3j_286 ._pencilIcon_13s3j_317 {
  display: none;
  font-size: 14px;
  color: #167cf4;
  margin-left: auto;
}
._agentPanelContent_13s3j_286 :hover ._pencilIcon_13s3j_317 {
  display: block;
}
._agentPanelContent_13s3j_286 ._agentName_13s3j_326 {
  color: #8c8c8c;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
._agentPanelContent_13s3j_286 ._hidden_13s3j_334 {
  display: none;
}
._agentPanelContent_13s3j_286 ._lastFormButton_13s3j_337 {
  margin-left: auto !important;
}