import React from 'react';
import { StarFilled } from '@ant-design/icons';
import { StarIcon } from '@revfluence/fresh-icons/regular';
import { defaultImageUrl, FeaturedProductObject, getMappedText } from '../../utils/LandingPagePreviewUtils';

export const LandingPageFeaturesProductSection = ({ data }: { data: FeaturedProductObject }) => {
  const {
    featureProductName,
    featureProductDesc,
    featureProductImage,
    featureProductRating,
    featureProductReviews,
    featureProductPrice,
    heroProductDiscountedPrice,
    featuredProductTitle,
    creatorName,
    landingPageStyles,
    showProductRating,
    currency,
    heroProductUrl,
  } = data;

  return (
    <div className="frame-27 hidden page-width" id="creators-favourite-product">
      <div
        data-testid="creator-favourite"
        id="creator-favourite"
        className="text-wrapper-20"
        style={{
 fontSize: landingPageStyles.typography?.headings?.fontSize, color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.headings?.fontStyle, transform: 'scale(0.8)',
}}
      >
        {getMappedText(featuredProductTitle, creatorName || '')}
      </div>
      <div className="frame-28">
        <div className="frame-29">
          <div className="frame-30">
            <div className="frame-31">
              <img
                id="favorite-featured-product-image"
                className="image-6"
                src={featureProductImage || defaultImageUrl}
                width="400"
                height="500"
                style={{ borderRadius: landingPageStyles.productCard.cornerRadius }}
              />
            </div>
          </div>
          <div className="frame-32">
            <div className="frame-7">
              <div className="frame-33">
                <div className="frame-9">
                  <div data-testid="favorite-featured-product-name" id="favorite-featured-product-name" className="text-wrapper-20 hero-featured-product-name" style={{ fontSize: landingPageStyles.typography?.headings?.fontSize, color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.headings?.fontStyle }}>
                    {featureProductName}
                  </div>
                  <div className="frame-34">
                    <div id="favorite-featured-product-discounted-price" className="text-wrapper-23" style={{ color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                      {currency}
                      {heroProductDiscountedPrice}
                      {' '}
                      <span className="product-discounted-price">
                        {currency}
                        {featureProductPrice}
                      </span>
                    </div>
                  </div>
                  {showProductRating && (
                    <div className="div">
                      <div id="favorite-featured-product-rating-container" className="frame-35" style={{ color: landingPageStyles.typography?.headings?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>
                        {Array.from({ length: 5 }).map((_, index) =>
                          (index < Number(featureProductRating) ? <StarFilled key={index} /> : <StarIcon key={index} />))}
                      </div>
                      <div id="favorite-featured-product-reviews-count" className="text-wrapper-25">
                        (
                        {featureProductReviews}
                        {' '}
                        reviews)
                      </div>
                    </div>
                  )}
                  <div id="favorite-featured-product-description" className="p" style={{ fontSize: landingPageStyles.typography?.body?.fontSize, color: landingPageStyles.typography?.body?.fontColor, fontFamily: landingPageStyles.typography?.body?.fontStyle }}>{featureProductDesc}</div>
                </div>
              </div>
            </div>
            <div className="frame-49 span-2" style={{ background: landingPageStyles?.buttons?.solidButton?.backgroundColor, borderRadius: landingPageStyles?.buttons?.solidButton?.cornerRadius }}>
              <a id="featured-product-shop-now" href={heroProductUrl} target="_blank" rel="noreferrer" className="text-wrapper-7">
                <span style={{ fontSize: landingPageStyles?.buttons?.solidButton?.labelSize, color: landingPageStyles?.buttons?.solidButton?.labelColor }}>Shop now</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
