import React, { useEffect, useMemo, useCallback } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerClose,
} from '@frontend/shadcn/components/ui/drawer';
import { XmarkIcon } from '@revfluence/fresh-icons/solid';
import { Button } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';
import { useGetData } from '@frontend/applications/TermsApp/hooks/useGetData';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '@common';
import { toast } from '@frontend/shadcn/hooks/use-toast';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { useAuth } from '@frontend/context/authContext';
import { BulkAssignBudgetDataInput } from '@frontend/app/types/globalTypes';
import { useAssignBudgetToBulkPayments } from '@frontend/app/hooks/budgetAllocation/useAssignBudgetToBulkPayments';
import { Spinner } from '@revfluence/fresh';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { IUpdatePaymentAssignemntRequest, updatePaymentData } from '../../savePayment';
import { fetchPaymentById } from '../../fetchPaymentById';
import { BudgetSource } from '../constants';
import { TopSection } from './TopSection';
import { ConnectionsSection, Mode } from './ConnectionsSection';
import { PaymentStatusSection } from './PaymentStatusSection';
import { PaymentDetailSection } from './PaymentDetailSection';
import { TimelineSection } from './TimelineSection';
import { useGetPaymentDetailsByIdsQuery } from '../hooks';
import { usePayment } from '../PaymentContext';
import { exportElementAsPdf } from '../utils/pdfExport';
import { ExpandableTextInput } from './ExpandableTextInput';

export interface IEditPayments {
  isLoading: boolean;
  notes: string;
  mode: Mode;
  groupId: number | null;
  projectId: number | null;
  briefId: number | null;
  budgetSource: BudgetSource;
  otherBudgetPeriod: {
    key: string;
    budgetId?: number;
    amount?: number;
    granularityLabelForPayload?: string;
    periodId?: number;
  }[];
  projectBudgetPeriod?: {
    key: string;
    budgetId?: number;
    amount?: number;
    granularityLabelForPayload?: string;
    periodId?: number;
  }[];
}

export interface IFiscalPeriod {
  key: string;
  id: number;
  label: string;
  granularityLabelForPayload: string;
}

// Main component
export const EditPaymentDrawer: React.FC = () => {
  const {
    isEditPaymentDrawerOpen,
    setIsEditPaymentDrawerOpen,
    selectedRows,
    programs,
    groups,
    fetchPayments,
    setRowSelection,
  } = usePayment();
  const { budgetAllocation } = useClientFeatures();
  const [editPayments, setEditPayments] = React.useState<IEditPayments>({
    isLoading: false,
    notes: '',
    mode: Mode.VIEW,
    groupId: null,
    projectId: null,
    briefId: null,
    budgetSource: BudgetSource.PROJECT,
    otherBudgetPeriod: [],
    projectBudgetPeriod: [],
  });
  // Add this state to track note changes
  const [originalNotes, setOriginalNotes] = React.useState('');

  const { clientInfo } = useAuth();
  const [paymentDetails, setPaymentDetails] = React.useState<GetPaymentDetailsByIdsQuery_payments[] | null>([]);

  const isSinglePayment = paymentDetails.length === 1;

  // Extract payment details
  const memberId = paymentDetails?.[0]?.member?.id;
  const projectId = editPayments?.projectId || paymentDetails?.[0]?.project?.id;
  const shouldSkipFetch = !isSinglePayment && !(memberId && projectId);

  const { data: paymentDetailsData, loading: isPaymentDetailsLoading } = useGetPaymentDetailsByIdsQuery({
    variables: { paymentIds: selectedRows.map((row) => row.paymentId) },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (paymentDetailsData?.payments) {
      setPaymentDetails(paymentDetailsData.payments);
    }
  }, [paymentDetailsData]);

  const { data: terms = [], loading: isTermsLoading } = useGetData<IAgreement[]>(
    `${backendServerApiEndpoint()}/agreements?member_id=${memberId}&program_id=${projectId}`,
    shouldSkipFetch,
  );

  const { backendServerFetchResponse } = useBackendServerFetch();
  const [bulkReassignBudget] = useAssignBudgetToBulkPayments();

  const briefs = useMemo(() => terms?.map((term) => term) || [], [terms]);

  useEffect(() => {
    if (isSinglePayment) {
      const singlePayment = paymentDetails[0];
      let budgetPeriod = singlePayment?.budgets?.map((budget) => ({
        key: budget.fiscalGranularityLabel + budget.budgetPeriodDefinitionId,
        budgetId: budget.budgetId,
        amount: budget.amount,
        granularityLabelForPayload: budget.fiscalGranularityLabel,
        periodId: budget.budgetPeriodDefinitionId,
      }));

      if (singlePayment?.paidFromProjectBudget) {
        const periodForProjectBudget = singlePayment?.budgets?.[0];
        budgetPeriod = [
          {
            key: periodForProjectBudget.fiscalGranularityLabel + periodForProjectBudget.budgetPeriodDefinitionId,
            budgetId: null,
            amount: singlePayment.amount,
            granularityLabelForPayload: periodForProjectBudget.fiscalGranularityLabel,
            periodId: periodForProjectBudget.budgetPeriodDefinitionId,
          },
        ];
      }
      setEditPayments((prev) => ({
        ...prev,
        groupId: singlePayment?.paymentGroup?.id || null,
        projectId: singlePayment?.project?.id || null,
        briefId: singlePayment?.brief?.id || null,
        notes: singlePayment?.notes || '',
        budgetSource: singlePayment?.paidFromProjectBudget ? BudgetSource.PROJECT : BudgetSource.OTHER,
        otherBudgetPeriod: singlePayment?.paidFromProjectBudget ? [] : budgetPeriod,
        projectBudgetPeriod: singlePayment?.paidFromProjectBudget ? budgetPeriod : [],
      }));
      setOriginalNotes(singlePayment?.notes || '');
    } else {
      // For multiple payments, ensure only one period entry
      const totalAmount = paymentDetails.reduce((sum, payment) => sum + payment.amount, 0);

      setEditPayments((prev) => ({
        ...prev,
        // Default to Other for multiple payments
        budgetSource: BudgetSource.OTHER,
        otherBudgetPeriod: [
          {
            key: `multiple-${uuidv4()}`,
            amount: totalAmount,
            budgetId: undefined,
            periodId: undefined,
            granularityLabelForPayload: undefined,
          },
        ],
        projectBudgetPeriod: [
          {
            key: `multiple-${uuidv4()}`,
            amount: totalAmount,
            budgetId: undefined,
            periodId: undefined,
            granularityLabelForPayload: undefined,
          },
        ],
      }));
    }
  }, [isSinglePayment, paymentDetails]);

  const totalPaymentSum = useMemo(() => {
    if (!paymentDetails?.length) return 0;
    return paymentDetails.reduce((sum, payment) => sum + payment.amount, 0);
  }, [paymentDetails]);

  const getValidationError = useMemo(() => {
    if (!budgetAllocation || editPayments.mode !== Mode.EDIT) return null;

    if (!editPayments.projectId) {
      return 'Please select a project';
    }

    if (editPayments.budgetSource === BudgetSource.PROJECT) {
      if (!editPayments.projectBudgetPeriod.length) {
        return 'Please select a fiscal period';
      }
      const projectPeriod = editPayments.projectBudgetPeriod[0];
      if (!projectPeriod.periodId || !projectPeriod.granularityLabelForPayload) {
        return 'Please select a fiscal period, if not change project or select other budget';
      }
    } else {
      if (!editPayments.otherBudgetPeriod.length) {
        return 'Please add at least one budget period';
      }

      for (const period of editPayments.otherBudgetPeriod) {
        if (!period.budgetId) {
          return 'Please select a budget for all periods';
        }
        if (!period.periodId || !period.granularityLabelForPayload) {
          return 'Please select a valid fiscal period for all budgets';
        }
        if (!period.amount || period.amount <= 0) {
          return 'Please enter a valid amount for all periods';
        }
      }

      const totalAmount = editPayments.otherBudgetPeriod.reduce((sum, period) => sum + (period.amount || 0), 0);
      if (Math.abs(totalAmount - totalPaymentSum) > 0.01) {
        return 'Total budget amounts must equal the payment amount';
      }
    }

    return null;
  }, [
    budgetAllocation,
    editPayments.mode,
    editPayments.projectId,
    editPayments.budgetSource,
    editPayments.otherBudgetPeriod,
    editPayments.projectBudgetPeriod,
    totalPaymentSum,
  ]);

  const onSubmit = async () => {
    setEditPayments((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      if (editPayments.mode !== Mode.EDIT && editPayments.notes !== originalNotes) {
        await updateNotes();
      } else {
        for (const paymentId of selectedRows.map((row) => row.paymentId)) {
          try {
            const payment = await fetchPaymentById(paymentId, clientInfo.id, backendServerFetchResponse);
            const selectedProgram = programs.find((program) => program.id === editPayments?.projectId);
            const selectedGroup = groups.find((group) => group.id === editPayments?.groupId);

            const url = `${backendServerApiEndpoint()}/payment`;
            const updatePayload: IUpdatePaymentAssignemntRequest = {
              program_ids: selectedProgram ? [selectedProgram.id] : payment.program_ids,
              program_names: selectedProgram ? [selectedProgram.title] : payment.program_names,
              community_ids: selectedGroup ? [selectedGroup.id] : payment.community_ids,
              activation_ids: payment.activation_ids,
              activation_names: payment.activation_names,
              assigned: payment.assigned,
              client_id: payment.client_id,
              note: editPayments.notes,
            };
            if (isSinglePayment) {
              updatePayload.project_id = editPayments?.briefId ? briefs?.find((brief) => brief.id === editPayments.briefId)?.project_id : null;
              updatePayload.assign_payment_to_brief = true;
            }
            await updatePaymentData(`${url}/${payment.id}`, updatePayload);
          } catch (err) {
            logger.error({ message: err });
          }
        }

        if (budgetAllocation) {
          const splitPayment = editPayments?.otherBudgetPeriod?.map((period) => ({
            budgetAccountId: period.budgetId,
            fiscalGranularityLabel: period.granularityLabelForPayload,
            budgetPeriodDefinitionId: period.periodId,
            amount: isSinglePayment ? period.amount : null,
          }));
          const bulkAssignBudgetData: BulkAssignBudgetDataInput = {
            paymentIds: paymentDetails?.map((payment) => payment.paymentId) || [],
            source: editPayments?.briefId ? 'Term' : null,
            ...(editPayments.budgetSource === BudgetSource.OTHER
              ? {
                  accountsPaymentBudgetInput: {
                    splitInfo: splitPayment,
                  },
                }
              : {
                  projectPaymentBudgetInput: {
                    programId: editPayments.projectId,
                    programSplitFiscalInfo: editPayments.projectBudgetPeriod[0]
                      ? {
                          budgetPeriodDefinitionId: editPayments.projectBudgetPeriod[0].periodId,
                          fiscalGranularityLabel: editPayments.projectBudgetPeriod[0].granularityLabelForPayload.split(
                            ' ',
                          )[0],
                        }
                      : null,
                    overflow: [],
                  },
                }),
          };
          await bulkReassignBudget({
            variables: { bulkAssignBudgetData },
            onError(error) {
              logger.error({ message: error });
              throw error;
            },
          });
        }
      }

      toast({
        variant: 'success',
        title: editPayments.mode === Mode.EDIT ? 'Payment Updated' : 'Notes Updated',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
    } catch (error) {
      const customMessage =
        error.message == 'No valid logs to insert' ? 'Please select a project or others budget' : '';
      toast({
        variant: 'error',
        title: customMessage || 'Error updating payment',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
      logger.error({ message: error });
    } finally {
      setIsEditPaymentDrawerOpen(false);
      setRowSelection({});
      fetchPayments();
      setEditPayments((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const updateNotes = async () => {
    try {
      for (const paymentId of selectedRows.map((row) => row.paymentId)) {
        try {
          const payment = await fetchPaymentById(paymentId, clientInfo.id, backendServerFetchResponse);
          const url = `${backendServerApiEndpoint()}/payment`;
          const updatePayload: IUpdatePaymentAssignemntRequest = {
            client_id: payment.client_id,
            community_ids: payment.community_ids,
            program_ids: payment.program_ids,
            program_names: payment.program_names,
            activation_ids: payment.activation_ids,
            activation_names: payment.activation_names,
            assigned: payment.assigned,
            note: editPayments.notes,
          };
          await updatePaymentData(`${url}/${payment.id}`, updatePayload);
        } catch (err) {
          logger.error({ message: err });
        }
      }
      toast({
        variant: 'success',
        title: 'Updated Notes',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
    } catch (error) {
      logger.error({ message: error });
    }
  };

  const isLoadingEditPayments = isPaymentDetailsLoading || isTermsLoading;
  const title = selectedRows.length === 1 ? 'Payment Overview' : 'Update Payments in Bulk';

  const captureComponentScreenshot = () => {
    exportElementAsPdf('.drawer-content', 'payment-details');
  };

  const handleClose = useCallback(() => {
    setIsEditPaymentDrawerOpen(false);
    setRowSelection({});
    fetchPayments();
    // Reset editPayments state
    setEditPayments({
      isLoading: false,
      notes: '',
      mode: Mode.VIEW,
      groupId: null,
      projectId: null,
      briefId: null,
      budgetSource: BudgetSource.OTHER,
      otherBudgetPeriod: [],
      projectBudgetPeriod: [],
    });
    setOriginalNotes('');
  }, [setIsEditPaymentDrawerOpen, setRowSelection, fetchPayments]);
  console.log('editPayments', editPayments);
  return (
    <Drawer
      open={isEditPaymentDrawerOpen}
      onOpenChange={(open) => {
        if (!open) handleClose();
      }}
      onClose={handleClose}
      direction="right"
    >
      <DrawerContent
        className={cn(
          'fixed left-auto inset-y-0 mt-0 right-0 z-[9999] flex h-full w-[572px] flex-col border-l bg-background rounded-none p-0',
          'duration-300 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
          'bg-background backdrop-blur-sm',
          'top-[var(--header-height,0px)] h-[calc(100vh-var(--header-height,0px))]',
          'bg-white',
        )}
        data-vaul-no-drag
      >
        <DrawerHeader className="flex justify-between items-center border-b border-grey-3 px-6 py-4">
          <DrawerTitle className="font-medium mb-0">{title}</DrawerTitle>
          <DrawerClose asChild>
            <Button
              variant="ghost"
              size="headerIcon"
              className="border border-transparent hover:border-grey-6 hover:bg-transparent"
              onClick={handleClose}
            >
              <XmarkIcon className="h-4 w-4" />
            </Button>
          </DrawerClose>
        </DrawerHeader>

        <div className="drawer-content flex flex-col overflow-y-scroll w-full h-full">
          {isPaymentDetailsLoading ? (
            <div className="flex justify-center items-center h-full w-full">
              <Spinner size="large" />
            </div>
          ) : (
            <>
              <TopSection paymentDetails={paymentDetails} onClickDownload={captureComponentScreenshot} />

              <div className="flex flex-col gap-4 p-4">
                <PaymentStatusSection paymentDetails={paymentDetails} />
                <div className="flex flex-col gap-2">
                  <ExpandableTextInput
                    value={editPayments.notes}
                    onChange={(value) => {
                      setEditPayments((prevState) => ({
                        ...prevState,
                        notes: value,
                      }));
                    }}
                    label="Notes"
                    placeholder="Add notes.."
                  />
                </div>
                <TimelineSection paymentDetails={paymentDetails} />
                <ConnectionsSection
                  editPayments={editPayments}
                  setEditPayments={setEditPayments}
                  paymentDetails={paymentDetails}
                  briefs={briefs}
                  isLoading={isLoadingEditPayments}
                />
                <PaymentDetailSection paymentDetails={paymentDetails} />
              </div>
            </>
          )}
        </div>

        {(editPayments.mode === Mode.EDIT || editPayments.notes !== originalNotes) && (
          <DrawerFooter className="flex flex-row gap-2 space-x-2 border-t border-grey-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Button
                      disabled={
                        isPaymentDetailsLoading ||
                        editPayments.isLoading ||
                        (editPayments.mode === Mode.EDIT && !!getValidationError)
                      }
                      onClick={onSubmit}
                      loading={editPayments.isLoading}
                    >
                      {editPayments.mode === Mode.EDIT ? 'Save Changes' : 'Update Notes'}
                    </Button>
                  </span>
                </TooltipTrigger>
                {getValidationError && <TooltipContent>{getValidationError}</TooltipContent>}
              </Tooltip>
            </TooltipProvider>
            <Button variant="outline" onClick={handleClose} disabled={editPayments.isLoading}>
              Close
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
