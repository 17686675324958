import { useState } from 'react';
import { SortingState } from '@tanstack/react-table';
import { GetSocialAnalyticsQuery, Segment } from '@frontend/gql/social/graphql';
import { ColumnDefinitionInput } from '../components/table';
import { GraphQLQueryVariables } from '../types';
import { FiltersFormData } from '../components/filters-form';

interface useMembersTabProps {
  queryVariables: GraphQLQueryVariables,
  fetching: boolean,
  data: GetSocialAnalyticsQuery,
  setSelectedColumns: React.Dispatch<React.SetStateAction<ColumnDefinitionInput[]>>,
  selectedColumns: ColumnDefinitionInput[],
  handleApplyFilters: (formData: FiltersFormData) => void,
}

export const useMembersTab = ({
    queryVariables,
    fetching,
    data,
    setSelectedColumns,
    selectedColumns,
    handleApplyFilters,
}: useMembersTabProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPartialLoading, setIsPartialLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    {
        desc: true,
        id: 'total.engagements',
    },
  ]);

  const handleSelectSegment = (segment: string) => {
    // Check if segments array already has this segment
    const segmentExists = queryVariables.segments?.includes(segment as Segment);

    // If segment exists in array, remove it; otherwise add it
    const newSegments = segmentExists
      ? queryVariables.segments?.filter((s) => s !== segment)
      : [...(queryVariables.segments || []), segment as Segment];

    // Only keep valid segments
    const validSegments = newSegments?.filter((seg) =>
      ['brandFans', 'macro', 'mega', 'micro', 'midTier', 'nano', 'unknown'].includes(seg)
    );

    // Apply the new segments array
    handleApplyFilters({ ...queryVariables, segments: validSegments.length ? validSegments : [] });
  };

  const handleSortingChange = (sorting: SortingState) => {
    setCurrentPage(0);
    setSorting(sorting);
  };

  return {
    memberTabProps: {
        data,
        isLoading: fetching,
        isPartialLoading: fetching && isPartialLoading,
        setIsPartialLoading,
        currentPage,
        setCurrentPage,
        sorting,
        setSorting: handleSortingChange,
        selectedColumns,
        setSelectedColumns,
        queryVariables,
        handleSelectSegment,
    }
  };
};
