import {
 first, isEmpty, isObject, keys, reduce, isNumber,
} from 'lodash';

import {
  GetOfferById_offer, GetOfferById_offer_links, GetOfferById_offer_promos,
} from '@affiliates/queries/types/GetOfferById';
import {
  GetOffersBySearchQuery_offers, GetOffersBySearchQuery_offers_links, GetOffersBySearchQuery_offers_promos,
} from '@affiliates/queries/types/GetOffersBySearchQuery';
import {
  OFFER_PAYOUT_TYPE, OFFER_PAYOUT_TYPE_PROMO, OFFER_PRICE_RULE_TYPE, SEND_STA_TASK_ID,
} from '@affiliates/types/globalTypes';
import {
  DataFormat, formatValue, SendTask,
} from '@affiliates/utils';

export const replaceVariables = (template: string, variables?: { [key: string]: string }): string => {
  if (!isObject(variables) || isEmpty(variables)) {
    return template;
  }
  return reduce(
    keys(variables),
    (prev: string, nextKey: string): string => prev.replaceAll(nextKey, variables[nextKey]),
    template,
  );
};

const getPayoutLabelPromo = (promo: GetOfferById_offer_promos | GetOffersBySearchQuery_offers_promos): string => {
  const withoutValueMessage = "Your unique promo code is used only for tracking and doesn't provide any additional compensation.";
  const withValueMessage = (value: string) => `You will receive ${value} associated with this promo code.`;
  switch (promo.payoutType) {
    case OFFER_PAYOUT_TYPE_PROMO.CONVERSION:
      if (!promo.flatPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(`$${formatValue(DataFormat.Money, promo.flatPayout)} per conversion`);
    case OFFER_PAYOUT_TYPE_PROMO.SALE:
      if (!promo.percentPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(`${formatValue(DataFormat.None, promo.percentPayout)}% per sale`);
    case OFFER_PAYOUT_TYPE_PROMO.CONVERSION_AND_SALE:
      if (!promo.flatPayout || !promo.percentPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(
        `$${formatValue(DataFormat.Money, promo.flatPayout)} per conversion + `
        + `$${formatValue(DataFormat.None, promo.percentPayout)}% per sale`,
      );
  }
};

const getPayoutLabelLink = (link: GetOfferById_offer_links | GetOffersBySearchQuery_offers_links): string => {
  const withoutValueMessage = "Your unique link is used only for tracking and doesn't provide any additional compensation. Please use the link to direct people to our site.";
  const withValueMessage = (value: string) => `You will receive ${value} associated with this tracking link.`;
  switch (link.payoutType) {
    case OFFER_PAYOUT_TYPE.CLICK:
      if (!link.flatPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(`$${formatValue(DataFormat.Money, link.flatPayout)} per click`);
    case OFFER_PAYOUT_TYPE.CONVERSION:
      if (!link.flatPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(`$${formatValue(DataFormat.Money, link.flatPayout)} per conversion`);
    case OFFER_PAYOUT_TYPE.SALE:
      if (!link.percentPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(`${formatValue(DataFormat.None, link.percentPayout)}% per sale`);
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      if (!link.flatPayout && !link.percentPayout) {
        return withoutValueMessage;
      }
      return withValueMessage(
        `$${formatValue(DataFormat.Money, link.flatPayout)} per conversion + `
        + `$${formatValue(DataFormat.None, link.percentPayout)}% per sale`,
      );
  }
};

const getDiscountLabelPromo = (promo: GetOfferById_offer_promos | GetOffersBySearchQuery_offers_promos): string => {
  if (!isNumber(promo.priceRuleAmount)) {
    return '';
  }

  switch (promo.priceRuleType) {
    case OFFER_PRICE_RULE_TYPE.PERCENTAGE:
      return `${formatValue(DataFormat.None, promo.priceRuleAmount)}%`;
    case OFFER_PRICE_RULE_TYPE.AMOUNT:
      return `$${formatValue(DataFormat.Money, promo.priceRuleAmount)}`;
  }
};

export const getVariables = (offer: GetOfferById_offer | GetOffersBySearchQuery_offers): { [key: string]: string } => {
  if (!isEmpty(offer.links) && !offer.isPromoLink) {
    const link = first(offer.links);
    return {
      __PAYOUT_LABEL__: getPayoutLabelLink(link),
    };
  }
  if (!isEmpty(offer.promos)) {
    const promo = first(offer.promos);
    return {
      __PAYOUT_LABEL__: getPayoutLabelPromo(promo),
      __DISCOUNT_LABEL__: getDiscountLabelPromo(promo),
    };
  }
  return {};
};

export const getObjectForTask = (taskId: SEND_STA_TASK_ID | SendTask, isPlural: boolean) => {
  let subject: string;
  switch (taskId) {
    case SEND_STA_TASK_ID.send_link_and_promo_task:
      subject = isPlural ? 'promo codes and links' : 'promo code and link';
      break;
    case SEND_STA_TASK_ID.send_link_task:
      subject = isPlural ? 'links' : 'link';
      break;
    case SEND_STA_TASK_ID.send_promo_task:
      subject = isPlural ? 'promo codes' : 'promo code';
      break;
    default:
      break;
  }
  return subject;
};
