._RichTextEditor_1k8pm_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--gray-5);
  border-radius: 6px;
}
._RichTextEditor_1k8pm_1 ._input_1k8pm_8 {
  width: 100%;
  border: none !important;
}
._RichTextEditor_1k8pm_1 .ql-toolbar {
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid var(--gray-5);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats {
  margin-right: 4px;
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button {
  transition: all 0.15s ease;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  width: 24px;
  height: 24px;
  padding: 4px;
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button .ql-stroke {
  stroke: var(--gray-8);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button .ql-fill {
  fill: var(--gray-8);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button:hover {
  background-color: var(--gray-3);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button.ql-active {
  background-color: var(--blue-7);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button.ql-active .ql-stroke {
  stroke: var(--gray-1);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats button.ql-active .ql-fill {
  fill: var(--gray-1);
}
._RichTextEditor_1k8pm_1 .ql-toolbar .ql-formats:not(:last-child) {
  border-right: 1px solid var(--gray-5);
}
._RichTextEditor_1k8pm_1 .ql-container {
  border: none !important;
}
._RichTextEditor_1k8pm_1 .ql-editor {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  color: var(--gray-8);
  font-size: 14px;
  line-height: 1.6;
  border: none !important;
}
._RichTextEditor_1k8pm_1 .ql-editor a {
  color: var(--ant-primary);
  text-decoration: none;
}
._RichTextEditor_1k8pm_1 .ql-editor.ql-blank::before {
  color: var(--gray-6);
  font-style: normal;
}
._RichTextEditor_1k8pm_1 .ql-tooltip {
  background: var(--gray-1);
  border-radius: 6px;
  border: 0;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  transform: translate(10px, 10px);
}
._RichTextEditor_1k8pm_1 .ql-tooltip input {
  border-radius: 6px;
  border-color: var(--blue-5) !important;
  border-width: 1px !important;
  outline: 0 !important;
}
._RichTextEditor_1k8pm_1 .ql-tooltip input::placeholder {
  color: var(--gray-1);
}
._RichTextEditor_1k8pm_1 .ql-tooltip .ql-action {
  color: var(--ant-primary);
}