import React from 'react';
import {
  Button,
  Drawer,
  Form,
  Popover,
  Select,
  Spinner,
  Tooltip,
  Typography,
  message,
} from '@revfluence/fresh';
import BudgetBreakdown from '@frontend/app/components/FiscalYearBudgetBreakdown/components/BudgetBreakdown/BudgetBreakdown';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { v4 as uuidv4 } from 'uuid';
import { useProjectBudgetContext } from '@frontend/app/context/ProjectBudgetContext';
import { useUpsertProjectBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useUpsertProjectBudgetAccountDistribution';
import { BudgetGranularity } from '@frontend/app/types/Budget';
import { logger } from '@common';
import { ChartSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import FiscalYearProperties from '../FiscalYearProperties';
import styles from './ConnectBudgetAccountDrawer.module.scss';
import { BudgetActionMode } from '../../types/IBudget';
import BudgetQuickInfoContent from '../BudgetQuickInfoContent';

const { Option } = Select;
const { Text } = Typography;
const { useEffect, useMemo, useCallback } = React;

interface IProps {
  title: string;
  refreshData: () => void;
  loading: boolean;
  isSingleFiscalYear: boolean;
}

const ConnectBudgetAccountDrawer: React.FC<IProps> = (props) => {
  const { title, refreshData, loading, isSingleFiscalYear } = props;
  const {
    loading: upsertBudgetLoader,
    showDrawer,
    budgetAccounts,
    selectedBudgetId,
    budgetAccountDistribution,
    fiscalYearBudgetBreakDowns,
    budgetAccountDistributionConnectedAccount,
    setShowDrawer,
    selectBudgetId,
    setFiscalYearBudgetBreakDowns,
    projectId,
    setMode,
    mode,
    resetBudgetAccount,
    setLoading,
  } = useProjectBudgetContext();

  const [upsertProjectBudget] = useUpsertProjectBudgetAccountDistribution();
  const [form] = Form.useForm();

  const fiscalYearsOptions = useMemo(() => {
    if (budgetAccountDistribution) {
      return budgetAccountDistribution.map((distribution) => {
        const fiscalYearStr = distribution.fiscalYear?.toString();
        return {
          label: distribution.fiscalYearLabel || (fiscalYearStr === 'N/A' ? 'Default Period' : fiscalYearStr),
          value: fiscalYearStr === 'N/A' ? 'N/A' : Number(fiscalYearStr),
        };
      });
    }
    return [];
  }, [budgetAccountDistribution]);

  const budgetQuickInfo = useMemo(() => {
    if (budgetAccountDistribution) {
      const selectedBudget = budgetAccounts?.find((budget) => budget.id === selectedBudgetId);
      return {
        budgetName: selectedBudget?.name,
        fiscalYears: budgetAccountDistribution?.map((budget) => ({
          fiscalYear: budget?.fiscalYearLabel,
          totalBudget: budget?.totalAmount,
          availableBudget: budget?.availableAmount,
        })),
      };
    }
    return {};
  }, [budgetAccountDistribution, budgetAccounts, selectedBudgetId]);

  const addFiscalYear = useCallback(
    (fiscalYear: string, budgetGranularity: BudgetGranularity) => {
      const newBreakDown = {
        id: uuidv4(),
        granularity: budgetGranularity,
        fiscalYear,
        totalAmount: 0,
        availableAmount: 0,
        quarterBudgets: [0, 0, 0, 0],
        monthBudgets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        mode: null,
      };
      setFiscalYearBudgetBreakDowns([...fiscalYearBudgetBreakDowns, newBreakDown]);
    },
    [fiscalYearBudgetBreakDowns, setFiscalYearBudgetBreakDowns],
  );

  useEffect(() => {
    if (budgetAccountDistributionConnectedAccount.length) {
      const fiscalDistibution = [];
      for (const distribution of budgetAccountDistributionConnectedAccount) {
        fiscalDistibution.push({
          id: uuidv4(),
          granularity: distribution.fiscalGranularity,
          fiscalYear: distribution.fiscalYear,
          totalAmount: distribution.totalAmount,
          availableAmount: distribution.availableAmount,
          quarterBudgets: distribution.quarterDistributions.map((quarter) => quarter.totalAmount),
          monthBudgets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          mode: BudgetActionMode.EDIT,
        });
      }
      setFiscalYearBudgetBreakDowns(fiscalDistibution);
    } else if (budgetAccountDistribution.length) {
      addFiscalYear(
        budgetAccountDistribution?.[0]?.fiscalYear?.toString(),
        budgetAccountDistribution?.[0]?.fiscalGranularity as BudgetGranularity,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetAccountDistribution, budgetAccountDistributionConnectedAccount, setFiscalYearBudgetBreakDowns]);

  const assignAutoFiscalYearBudgetBreakDowns = () => {
    const notUsedDistribution = budgetAccountDistribution.find(
      (distribution) =>
        !fiscalYearBudgetBreakDowns.find((fyBreakdown) => fyBreakdown.fiscalYear === distribution.fiscalYear),
    );
    if (notUsedDistribution) {
      addFiscalYear(notUsedDistribution.fiscalYear?.toString(), notUsedDistribution.fiscalGranularity as BudgetGranularity);
    } else {
      message.info('All fiscal years added');
    }
  };
  const handleFiscalYearChange = (updateFiscalYearBudget) => {
    setFiscalYearBudgetBreakDowns(
      fiscalYearBudgetBreakDowns.map((fiscalYear) => {
        if (fiscalYear.id === updateFiscalYearBudget.id) {
          return updateFiscalYearBudget;
        }
        return fiscalYear;
      }),
    );
  };
  const deleteFiscalYear = (id: string) => {
    setFiscalYearBudgetBreakDowns(fiscalYearBudgetBreakDowns.filter((budget) => budget.id !== id));
  };
  const updateAmountForFiscalYear = (id: string, amount: number) => {
    const fiscalYearBreakDowns = fiscalYearBudgetBreakDowns.map((budget) => {
      if (budget.id === id) {
        return {
          ...budget,
          totalAmount: amount,
        };
      }
      return budget;
    });
    setFiscalYearBudgetBreakDowns(fiscalYearBreakDowns);
  };

  const updateGranularityForFiscalYear = (id: string, granularity: BudgetGranularity) => {
    const fiscalYearBreakDowns = fiscalYearBudgetBreakDowns.map((budget) => {
      if (budget.id === id) {
        return {
          ...budget,
          granularity,
        };
      }
      return budget;
    });
    setFiscalYearBudgetBreakDowns(fiscalYearBreakDowns);
  };

  const updateFiscalYear = (id: string, fiscalYear: number | string) => {
    const distribution = budgetAccountDistribution.find((distribution) => distribution.fiscalYear === fiscalYear);
    const fiscalYearBreakDowns = fiscalYearBudgetBreakDowns.map((budget) => {
      if (budget.id === id) {
        return {
          ...budget,
          fiscalYear,
          granularity: distribution?.fiscalGranularity as BudgetGranularity || BudgetGranularity.YEARLY,
        };
      }
      return budget;
    });
    setFiscalYearBudgetBreakDowns(fiscalYearBreakDowns);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await upsertProjectBudget({
        variables: {
          projectBudgetAccountInput: {
            programId: parseInt(projectId, 10),
            fiscalYearsBudget: fiscalYearBudgetBreakDowns,
            budgetId: selectedBudgetId,
          },
        },
        onCompleted() {
          message.success(
            mode === BudgetActionMode.CREATE ? 'Sucessfully connected the budget' : 'Sucessfully updated the budget',
          );
          onClose();
          selectBudgetId(null);
        },
        onError(error) {
          const errorMessage = error?.graphQLErrors?.map((error) => error.message).reduce((error, msg) => error + msg, '');
          message.error(errorMessage || 'Could not update the project');
          onClose();
          selectBudgetId(null);
          logger.error(`Could not update the project ${error}`, error);
        },
      });
    } catch (error) {
      logger.error(`Could not update the project ${error}`, error);
    } finally {
      setLoading(false);
    }
    refreshData();
  };
  const onClose = () => {
    resetBudgetAccount();
    setMode(BudgetActionMode.CREATE);
    setShowDrawer(false);
  };
  const filterFiscalYearsOptions = useCallback(
    (fiscalYear) => {
      if (fiscalYear) {
        return fiscalYearsOptions.filter(
          (option) =>
            option.value === fiscalYear
            || !fiscalYearBudgetBreakDowns.find((fyBreakDown) => fyBreakDown.fiscalYear === option.value),
        );
      }
      return [];
    },
    [fiscalYearsOptions, fiscalYearBudgetBreakDowns],
  );
  const disableSubmitButton = useMemo(() => {
    if (fiscalYearBudgetBreakDowns.length === 0) {
      return true;
    }

    return fiscalYearBudgetBreakDowns.some(
      (breakdown) =>
        breakdown.granularity === BudgetGranularity.QUARTERLY
        && breakdown.totalAmount < breakdown.quarterBudgets.reduce((sum, amount) => sum + amount, 0),
    );
  }, [fiscalYearBudgetBreakDowns]);

  return (
    <Drawer
      title={<Text className={styles.budgetAccountDrawerTitle}>{title}</Text>}
      placement="right"
      onClose={onClose}
      open={showDrawer}
      size="large"
      footer={(
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={() => onSubmit()} disabled={disableSubmitButton} loading={upsertBudgetLoader}>
            {mode === BudgetActionMode.EDIT ? 'Update Account' : 'Connect Account'}
          </Button>
        </>
      )}
      className={styles.budgetAccountDrawerContainer}
    >
      {!loading ? (
        <>
          <Text className={styles.budgetAccountDrawerDescription}>
            Select the budget account below to add budget to your project.
          </Text>
          <Form form={form} layout="vertical">
            <div style={{ display: 'flex', gap: '4px' }}>
              <div className={styles.budgetAccount}>
                <Text>Select a Budget Account</Text>
                <Select
                  value={selectedBudgetId}
                  onChange={(e) => selectBudgetId(e)}
                  style={{ width: '100%' }}
                  disabled={mode === BudgetActionMode.EDIT}
                  placeholder="Select Budget Account"
                >
                  {budgetAccounts.map((option) => (
                    <Option value={option.id} key={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <Popover
                  content={(
                    <BudgetQuickInfoContent
                      budgetName={budgetQuickInfo?.budgetName}
                      fiscalYears={budgetQuickInfo?.fiscalYears}
                    />
                  )}
                  trigger="hover"
                  placement="topRight"
                >
                  <Button
                    type="text"
                    className={styles.chartIcon}
                    icon={<ChartSimpleIcon style={{ color: '#167CF4', fontSize: 12 }} />}
                  />
                </Popover>
              </div>
            </div>
            {fiscalYearBudgetBreakDowns.map((budgetBreakDown, _) => (
              <div className={styles.fiscalYearItem} key={budgetBreakDown.id}>
                <FiscalYearProperties
                  id={budgetBreakDown.id}
                  fiscalYear={budgetBreakDown.fiscalYear}
                  totalBudget={budgetBreakDown.totalAmount}
                  granularity={budgetBreakDown.granularity}
                  fiscalYearsOptions={filterFiscalYearsOptions(budgetBreakDown.fiscalYear)}
                  deleteFiscalYear={deleteFiscalYear}
                  updateAmountForFiscalYear={updateAmountForFiscalYear}
                  updateFiscalYear={updateFiscalYear}
                  showDelete={false}
                  mode={budgetBreakDown.mode}
                  updateGranularityForFiscalYear={updateGranularityForFiscalYear}
                  showFiscalYear={!isSingleFiscalYear}
                />
                <BudgetBreakdown
                  id={budgetBreakDown.id}
                  granularity={budgetBreakDown.granularity}
                  totalAmount={budgetBreakDown.totalAmount}
                  monthlyBudgets={budgetBreakDown.monthBudgets}
                  quarterBudgets={budgetBreakDown.quarterBudgets}
                  fiscalYear={budgetBreakDown.fiscalYear}
                  handleFiscalYearChange={handleFiscalYearChange}
                />
              </div>
            ))}
          </Form>

          {!!fiscalYearBudgetBreakDowns.length && !isSingleFiscalYear && (
            <div>
              <Button type="link" icon={<PlusIcon />} onClick={assignAutoFiscalYearBudgetBreakDowns}>
                Add Fiscal Year
              </Button>
              <Tooltip title="Add another fiscal year for selected budget account">
                <CircleInfoIcon />
              </Tooltip>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </Drawer>
  );
};
export default ConnectBudgetAccountDrawer;
ConnectBudgetAccountDrawer.displayName = 'ConnectBudgetAccountDrawer';
