import React from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@frontend/shadcn/components/ui/dialog';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { TableColumnsIcon } from '@revfluence/fresh-icons/solid/esm';

interface ColumnSelectorModalProps {
  columns: {
    id: string
    label: string
    isVisible: boolean
  }[]
  onColumnToggle: (columnId: string) => void
}

export function ColumnSelectorModal({ columns, onColumnToggle }: ColumnSelectorModalProps) {
  return (
    <TooltipProvider>
      <Dialog>
        <Tooltip>
          <TooltipTrigger asChild>
            <DialogTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 hover:bg-gray-100 dark:hover:bg-gray-800"
              >
                <TableColumnsIcon className="h-4 w-4" />
              </Button>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>
            Edit Columns
          </TooltipContent>
        </Tooltip>

        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader className="pb-0">
            <DialogTitle className="text-lg font-semibold">Table Columns</DialogTitle>
          </DialogHeader>
          <div className="grid gap-0.5 pb-4 px-1 max-h-[60vh] overflow-y-auto">
            {columns.map((column) => (
              <div
                key={column.id}
                className="flex items-center space-x-2 rounded-lg p-1 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
              >
                <Checkbox
                  id={column.id}
                  checked={column.isVisible}
                  onCheckedChange={() => onColumnToggle(column.id)}
                  className="h-4 w-4"
                />
                <label
                  htmlFor={column.id}
                  className="text-sm font-medium leading-none cursor-pointer select-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {column.label}
                </label>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </TooltipProvider>
  );
}
