import React from 'react';
import {
 WidgetContainer, MainMetric,
} from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { useGetCreatorOrdersSummary } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCreatorOrdersSummary';
import { isNil } from 'lodash';
import { formatDistanceStrict } from 'date-fns';
import { useGetCurrencyCode } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCurrencyCode';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

interface PFACreatorOrdersProps {
  className?: string;
}

export default function PFACreatorOrders({ className }: PFACreatorOrdersProps) {
  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { currencyCode } = useGetCurrencyCode();

  const distanceInWords = formatDistanceStrict(startDate, endDate);

  const { creatorOrdersSummary, isCreatorOrdersSummaryLoading } = useGetCreatorOrdersSummary({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex items-center justify-between">
      <MainMetric
        heading="Number of Creators"
        value={creatorOrdersSummary?.totalMembersCount}
        size="small"
        icon={null}
        metricType="count"
        className="flex-1"
        trend={creatorOrdersSummary?.totalMembersCountTrend}
        subHeading={isNil(creatorOrdersSummary?.totalMembersCountTrend) ? '' : `in last ${distanceInWords}`}
      />
      <MainMetric
        heading="Average Orders Per Creator"
        value={creatorOrdersSummary?.avgOrdersPerMember}
        size="small"
        icon={null}
        metricType="count"
        className="flex-1"
        trend={creatorOrdersSummary?.avgOrdersPerMemberTrend}
        subHeading={isNil(creatorOrdersSummary?.avgOrdersPerMemberTrend) ? '' : `in last ${distanceInWords}`}
      />
      <MainMetric
        heading="Average Cost Per Creator"
        value={creatorOrdersSummary?.avgCostPerMember}
        size="small"
        icon={null}
        metricType="currency"
        className="flex-1"
        trend={creatorOrdersSummary?.avgCostPerMemberTrend}
        subHeading={isNil(creatorOrdersSummary?.avgCostPerMemberTrend) ? '' : `in last ${distanceInWords}`}
        currencyCode={currencyCode}
      />
      <MainMetric
        heading="Average Products Per Creator"
        value={creatorOrdersSummary?.avgProductCountPerMember}
        size="small"
        icon={null}
        metricType="count"
        className="flex-1"
        trend={creatorOrdersSummary?.avgProductCountPerMemberTrend}
        subHeading={isNil(creatorOrdersSummary?.avgProductCountPerMemberTrend) ? '' : `in last ${distanceInWords}`}
      />
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Creator’s Orders "
      className={`${className}`}
      bgColor="bg-primary-foreground"
      textColor="text-primary"
    >
      {isCreatorOrdersSummaryLoading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
