import React, { useEffect } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@frontend/shadcn/components/ui/dialog';
import { Button } from '@frontend/shadcn/components/ui/button';

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  afterClose?: () => void;
  cancelText?: string;
  okText?: string;
  onOk?: () => void;
}

const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    title,
    isOpen,
    onClose,
    afterClose,
    cancelText = 'Cancel',
    okText = 'Ok',
    onOk,
  } = props;

  // Handle afterClose effect
  useEffect(() => {
    if (!isOpen && afterClose) {
      afterClose();
    }
  }, [isOpen, afterClose]);

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose && onClose()}>
      <DialogContent className="sm:max-w-md p-0 gap-0 z-[10000]">
        <DialogHeader className="p-4">
          <DialogTitle className="m-0">
            <span className="font-medium text-[20px]">{title}</span>
          </DialogTitle>
        </DialogHeader>
        <div className="border-solid border-[1px]" />
        <div className="p-4">{children}</div>
        <div className="border-solid border-[1px]" />
        <DialogFooter className="flex justify-end p-4 space-x-2">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              {cancelText}
            </Button>
          </DialogClose>
          {onOk && (
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                onOk();
                if (onClose) onClose();
              }}
            >
              {okText}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
