import {
    QueryHookOptions,
    useQuery,
  } from '@apollo/client';

  import { GET_TABLE_CONFIGURATION_BY_NAME_QUERY } from '@frontend/app/queries';
  import {
    GetTableConfigurationByNameQuery,
    GetTableConfigurationByNameQueryVariables,
  } from '@frontend/app/queries/types/GetTableConfigurationByNameQuery';

  type IOptions = QueryHookOptions<GetTableConfigurationByNameQuery, GetTableConfigurationByNameQueryVariables>;

  export const useGetTableConfigurationByName = (options: IOptions = {}) => (
    useQuery<GetTableConfigurationByNameQuery, GetTableConfigurationByNameQueryVariables>(
      GET_TABLE_CONFIGURATION_BY_NAME_QUERY,
      options
    )
  );
