import * as React from 'react';
import { first, size } from 'lodash';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import { IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import {
  IStepInfo as Step,
  WizardContainer as Wizard,
} from '@frontend/applications/Shared/Wizard/container';
import { SettingFilled } from '@ant-design/icons';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import PaymentAppDashboard from './PaymentAppDashboard';
import PaymentAppModal from './PaymentAppModal';

import { usePayments } from '../hooks/usePayments';
import { PaymentCreationSource } from '../types';

const { useEffect } = React;

interface IProps {
  modalView: boolean;
  clientId: string;
  clientName: string;
  memberId?: string;
  workflowActionParameters?: IWorkflowActionParameters;
  closeModal?: () => void;
}

const PaymentApp: React.FunctionComponent<IProps> = (props) => {
  const {
    modalView, workflowActionParameters, memberId, clientId, clientName, closeModal,
  } = props;
  const addEvent = useEventContext();
  const memberIdForPayments = workflowActionParameters ? first(workflowActionParameters.memberIds) : memberId;
  const applicationProps = {
    backendServerApiEndpoint: backendServerApiEndpoint(),
    analyticsServerApiEndpoint: analyticsServerApiEndpoint(),
    backendServerWebEndpoint: backendServerWebEndpoint(),
    clientId,
    clientName,
    memberId: first(workflowActionParameters?.memberIds)?.toString() || memberId,
    workflowActionParameters,
  };

  const { state, steps } = usePayments({
    memberId: memberIdForPayments ? memberIdForPayments.toString() : null,
    paymentCreationSource: workflowActionParameters ? PaymentCreationSource.PROJECT : PaymentCreationSource.MEMBER,
    applicationProps,
    closeModal,
  });
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const { paymentV2 } = useClientFeatures();

  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'payment' });

    if (workflowActionParameters && size(workflowActionParameters.memberIds) !== 1) {
      console.error('Payment app only accepts single member ID. Received IDs:', workflowActionParameters.memberIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isBudgetAllocationEnabled && modalView) {
    const contents = (
      <ApplicationProvider
        backendServerApiEndpoint={backendServerApiEndpoint()}
        analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
        backendServerWebEndpoint={backendServerWebEndpoint()}
        clientId={clientId}
        clientName={clientName}
        memberId={first(workflowActionParameters?.memberIds)?.toString() || memberId}
        workflowActionParameters={workflowActionParameters}
      >
        <Wizard
          hideNavigation={false}
          icon={<SettingFilled />}
          step={state.currentStep}
          stepsInfo={steps as Step[]}
          noHeader
          noBodyPadding
        />
      </ApplicationProvider>
    );

    return (
      <>
        {contents}
      </>
    );
  }

  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={clientId}
      clientName={clientName}
      memberId={first(workflowActionParameters?.memberIds)?.toString() || memberId}
      workflowActionParameters={workflowActionParameters}
    >
      <div>{modalView ? <PaymentAppModal /> : <PaymentAppDashboard refreshUi={refreshUi} paymentV2={paymentV2} />}</div>
    </ApplicationProvider>
  );
};

export default PaymentApp;
