import * as React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faTags } from '@fortawesome/free-solid-svg-icons';
import { iconOptions } from '../OfferForm/FormSections/CommonSection/FormElements/RefreshForm/iconMapping';

interface IProps {
  className?: string;
  imageUrl: string | null;
  source: OFFER_SOURCE;
  icon?: string | null;

  iconClassName?: string;
}

export const RefreshOfferDefaultImage: React.FC<Readonly<IProps>> = (props) => {
  const {
    className = '!w-9 !h-9',
    imageUrl,
    source,
    icon,
    iconClassName = 'h-10 w-10',
  } = props;

  // Helper function to get icon option from name
  const getIconOptionByName = (name: string | null) => {
    if (!name) return undefined;
    return iconOptions.find((opt) => opt.name === name);
  };

  // If there's an image URL, show it
  if (!isNil(imageUrl) && imageUrl !== '') {
    return (
      <div className={`${cx(className)} flex items-center justify-center mx-2`}>
        <img
          src={imageUrl}
          alt="Preview"
          className="w-full h-full object-contain !rounded-xl"
        />
      </div>
    );
  }

  // If there's an icon, show it
  if (icon) {
    const iconOption = getIconOptionByName(icon);
    if (iconOption) {
      return (
        <div className={`${cx(className)} p-2 m-2 flex items-center justify-center !rounded-xl ${source === OFFER_SOURCE.SHOPIFY ? 'bg-blue-4' : 'bg-magenta-4'}`}>
          <FontAwesomeIcon
            icon={iconOption.icon}
            className={cx(iconClassName, 'text-grey-0')}
          />
        </div>
      );
    }
  }

  // Fall back to default source-based icons
  return (
    <>
      {source === OFFER_SOURCE.SHOPIFY && (
        <div className={`${cx(className)} bg-blue-4 flex items-center justify-center !rounded-xl text-grey-0 p-2 m-2`}>
          <FontAwesomeIcon
            icon={faTags}
            className={cx(iconClassName, 'text-grey-0')}
          />
        </div>
      )}
      {source === OFFER_SOURCE.TUNE && (
        <div className={`${cx(className)} bg-magenta-4 flex items-center justify-center !rounded-xl text-grey-0 p-2 m-2`}>
          <FontAwesomeIcon
            icon={faLink}
            className={cx(iconClassName, 'text-grey-0')}
          />
        </div>
      )}
    </>
  );
};
