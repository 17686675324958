import * as React from 'react';
import cx from 'classnames';

import {
  SelectDropdown,
} from '@components';
import { map } from 'lodash';
import { DateFilter } from './DateFilter';
import { NetworkFilter } from './NetworkFilter';
import { SourceFilter } from './SourceFilter';
import { CountryFilter } from './CountryFilter/CountryFilter';
import { CreatorFilter } from './CreatorFilter/CreatorFilter';
import { OwnerFilter } from './OwnerFilter/OwnerFilter';
import { BrandFilter } from './BrandFilter/BrandFilter';
import { PaymentFilter } from './MonetaryFilters/PaymentFilter';
import { ProductCostFilter } from './MonetaryFilters/ProductCostFilter';
import { PaymentAndProductCostFilter } from './MonetaryFilters/PaymentAndProductCostFilter';
import { ISelectFilterOptions } from './FilterBox';

import styles from './Filters.scss';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { AssignmentFilter } from './AssignmentFilter';

const AdditionalFilters = (filters, setFilters, isDisabled) => ({
  country: (props) => (
    <CountryFilter
      filters={filters}
      setFilters={setFilters}
      key="country"
      isDisabled={isDisabled}
      {...props}
    />
  ),
  creator: (props) => (
    <CreatorFilter
      filters={filters}
      setFilters={setFilters}
      key="creator"
      isDisabled={isDisabled}
      {...props}
    />
  ),
  owner: (props) => (
    <OwnerFilter
      filters={filters}
      setFilters={setFilters}
      key="owner"
      isDisabled={isDisabled}
      {...props}
    />
  ),
  payment: (props) => (
    <PaymentFilter
      filters={filters}
      setFilters={setFilters}
      key="payment"
      isDisabled={isDisabled}
      {...props}
    />
  ),
  productCost: (props) => (
    <ProductCostFilter
      filters={filters}
      setFilters={setFilters}
      key="productCost"
      isDisabled={isDisabled}
      {...props}
    />
  ),
  paymentAndProductCost: (props) => (
    <PaymentAndProductCostFilter
      filters={filters}
      setFilters={setFilters}
      key="paymentAndProductCost"
      isDisabled={isDisabled}
      {...props}
    />
  ),
});

interface IFilterRowProps {
  filters: any;
  setFilters: (filters: any) => void;
  aspirexFilterOptions: ISelectFilterOptions[];
  onNoData: any;
  setFiltersCallback: (identifier: string, values: string[]) => void;
  removeFilter: (dispatch: any) => (filter: any) => void;
  addFilter: (dispatch: any) => (filter: any) => void;
  isComparing: boolean;
  isDisabled: boolean;
  hide?: boolean;
  tempYoutubeDemoAccountMode?: boolean;
  toggleInsights: (value: boolean) => void;
}

type TBrandAndNetworkFiltersProps = Pick<IFilterRowProps, 'filters' | 'setFilters' | 'isDisabled' | 'onNoData'>;

const BrandAndNetworkFilters = (
  {
    filters,
    setFilters,
    isDisabled,
    onNoData,
  }: TBrandAndNetworkFiltersProps,
) => (
  <>
    <BrandFilter
      filters={filters}
      setFilters={setFilters}
      isDisabled={isDisabled}
      onNoData={onNoData}
    />
    <NetworkFilter
      filters={filters}
      setFilters={setFilters}
      isDisabled={isDisabled}
    />
  </>
);

type TAspirexFiltersProps = Pick<IFilterRowProps, 'setFiltersCallback' | 'isDisabled' | 'aspirexFilterOptions'>;

const AspirexFilters = (
  {
    aspirexFilterOptions,
    isDisabled,
    setFiltersCallback,
  }: TAspirexFiltersProps,
) => (
  <>
    {map(aspirexFilterOptions, (filterOptions) => {
      const showSearch = ['Groups', 'Projects'].includes(filterOptions.title);

      return (
        <SelectDropdown
          className={cx(styles.filter, {
            [styles.disabled]: isDisabled,
          })}
          key={filterOptions.filtersKey}
          title={filterOptions.title}
          options={filterOptions.objects}
          identifier={filterOptions.filtersKey}
          setValue={setFiltersCallback}
          isDisabled={isDisabled}
          showSearch={showSearch}
          searchPlaceholder={`Search ${filterOptions.title.toLowerCase()}...`}
        />
      );
    })}
  </>
);

type TSituationalFiltersProps = Pick<IFilterRowProps, 'filters' | 'setFilters' | 'setFiltersCallback' | 'isDisabled' | 'aspirexFilterOptions' | 'onNoData'>;
const SituationalFilters = (props: TSituationalFiltersProps) => {
  const { aspirexFilterOptions } = props;
  return aspirexFilterOptions ? <AspirexFilters {...props} /> : <BrandAndNetworkFilters {...props} />;
};

export const FilterRow: React.FC<IFilterRowProps> = (props) => {
  const isSourceFilterEnabled = useClientFeatureEnabled(ClientFeature.SOCIAL_ANALYTICS_SOURCE_FILTER);
  const isManualAssignmentFilterEnabled = useClientFeatureEnabled(ClientFeature.SOCIAL_ANALYTICS_MANUAL_ASSINGMENT_FILTER);

  const {
    filters,
    setFilters,
    removeFilter,
    isComparing,
    isDisabled,
    hide = false,
    toggleInsights,
  } = props;

  if (hide) {
    return null;
  }
  return (
    <div className={styles.FilterRow}>
      <div
        className={cx({
          [styles.filters]: !isComparing,
          [styles.compareFilters]: isComparing,
          [styles.disabled]: isDisabled,
        })}
      >
        <DateFilter
          filters={filters}
          setFilters={setFilters}
          isDisabled={isDisabled}
          toggleInsights={toggleInsights}
        />
        <SituationalFilters {...props} />
        <NetworkFilter {...props} />
        {isSourceFilterEnabled && <SourceFilter {...props} />}
        {isManualAssignmentFilterEnabled && <AssignmentFilter {...props} />}
        {filters.meta.additionalFilters.map((filter) =>
          AdditionalFilters(
            filters,
            setFilters,
            isDisabled,
          )[filter]({
            removeFilter: removeFilter(setFilters),
          }))}
      </div>
    </div>
  );
};
