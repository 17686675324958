import { Input, Typography, message, Button } from '@revfluence/fresh';
import React, { useEffect, useState } from 'react';
import { find, split } from 'lodash';
import { useUpdateMemberMutation } from '@frontend/app/hooks';
import { logger } from '@common';
import styles from './Note.scss';
import { formatField } from '../GcrUtils';

const { Title } = Typography;

interface Field {
  id: number;
  name: string;
}

interface NoteProps {
  memberRecord: {
    id: number;
    metaFields: {
      brand_note?: string;
      [key: string]: unknown;
    };
  };
  fields?: Field[];
  refetchMemberQuery: () => void;
  setbrandNoteError: (error: boolean) => void;
  userProfile?: {
    name: string;
  };
  refetchUserProfile: () => void;
  applicantReviewFields: Record<string, unknown> | null;
}

const Note: React.FC<NoteProps> = ({
  memberRecord,
  fields,
  refetchMemberQuery,
  setbrandNoteError,
  userProfile,
  refetchUserProfile,
  applicantReviewFields,
}) => {
  const [brandNoteField, setBrandNoteField] = useState<Field | null>(null);
  const [noteInput, setNoteInput] = useState(memberRecord.metaFields.brand_note || '');
  const [showEdit, setShowEdit] = useState<boolean>(!!memberRecord.metaFields.brand_note);

  useEffect(() => {
    if (applicantReviewFields) {
      const fieldForNotes = find(fields, (field) => field.id === applicantReviewFields.Notes);
      if (fieldForNotes) {
        if (formatField(fieldForNotes.name) === 'brand_note') {
          setBrandNoteField(fieldForNotes);
        }
      }
    }
  }, [applicantReviewFields, memberRecord, fields]);

  useEffect(() => {
    if (memberRecord?.metaFields?.brand_note) {
      setNoteInput(memberRecord.metaFields.brand_note);
    }
  }, [memberRecord]);

  const [memberMutation] = useUpdateMemberMutation();
  const saveBrandNote = async () => {
    try {
      if (!brandNoteField) {
        setbrandNoteError(true);
        setNoteInput('');
        setShowEdit(true);
        throw new Error('Brand Note not configured');
      }
      await memberMutation({
        variables: {
          id: memberRecord.id,
          member: {
            fields: {
              [brandNoteField.id]: `${noteInput} (Last Edited by ${userProfile?.name})`,
            },
          },
        },
      });

      setNoteInput(`${noteInput} (Last Edited by ${userProfile?.name})`);
      refetchUserProfile();
      refetchMemberQuery();
      setShowEdit(true);
      message.success('Note saved successfully');
    } catch (error) {
      logger.error(error);
    }
  };

  const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteInput(e.target.value);
  };

  const onCancelClick = () => {
    setNoteInput(memberRecord?.metaFields?.brand_note ?? '');
    setShowEdit(true);
  };

  const updateBrandNote = () => {
    const note = memberRecord?.metaFields?.brand_note ?? '';
    const splitNote = split(note, '(Last Edited by')[0];
    setNoteInput(splitNote);
    setShowEdit(false);
  };

  return (
    <div className={styles.commentsWrapper}>
      <Title level={5}> Notes </Title>
      <Input.TextArea
        rows={3}
        placeholder="Add your notes here"
        value={noteInput}
        onChange={onNotesChange}
        disabled={showEdit}
      />
      <div className={styles.buttons}>
        {!showEdit ? (
          <>
            <Button type="primary" onClick={saveBrandNote} disabled={!noteInput}>
              Save{' '}
            </Button>
            <Button onClick={onCancelClick}>Cancel</Button>
          </>
        ) : (
          <Button onClick={updateBrandNote}>Edit</Button>
        )}
      </div>
    </div>
  );
};

export default Note;
