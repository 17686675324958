import React, { useState, useEffect, useMemo } from 'react';
import {
 filter, first, find, isEmpty,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { CircleInfoIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { Select, Button, Tooltip } from '@revfluence/fresh';
import {
  TAutomationActionOption,
  useGetMessageTemplateById,
  MessageTemplate,
  MessageTemplateInput,
} from '@frontend/app/hooks';
import { LoadSpinner } from '@components';
import { TemplateTransportType, TemplateBodyType, TemplateType } from '@frontend/app/types/globalTypes';
import FormHeader from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormHeader';
import { ArrowRightIcon } from '@revfluence/fresh-icons/regular/esm';
import FormFooter from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormFooter';
import { TActionNode, TSelectedNode, AutomationBlueprintName } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { AutomationNodeType } from '@frontend/app/types/globalTypes';
import { useAuth } from '@frontend/context/authContext';
import { useGetCatalogs } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { useGetSelectionCriteria } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetSelectionCriteria';
import { BookIcon, CartShoppingIcon } from '@revfluence/fresh-icons/regular/esm';
import { useAppContext } from '@frontend/context/AppContext';
import { ApolloProvider } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { GET_PROJECT_CONFIG_BY_TYPE_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GetProjectConfigByProjectIdAndType } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProjectConfigByProjectIdAndType';
import { useHistory } from 'react-router-dom';
import { pfaV2Routes } from '@frontend/app/containers/Settings/ProductFulfillment/routes';
import styles from './ConfigDrawer.scss';
import { MessageTemplateEditor } from './MessageTemplateEditor';

const { Option } = Select;
type TProps = {
  onClose: () => void;
  onUpdateActionNode: (targetNodeId: string, actionNode: TActionNode) => void;
  onUpdateMessageTemplate: (updatedTemplate: MessageTemplate | MessageTemplateInput) => void;
  actionOptions: TAutomationActionOption[];
  selectedNode: TSelectedNode;
  resetMessageTemplate: boolean;
};

const CatalogSelectionSection: React.FC<{
  selectedCatalog: number | undefined;
  setSelectedCatalog: (value: number) => void;
  selectedRule: number | undefined;
  setSelectedRule: (value: number | null) => void;
  projectId: number;
}> = ({
  selectedCatalog,
  setSelectedCatalog,
  selectedRule,
  setSelectedRule,
  projectId,
}) => {
  const apolloClient = useApolloClient();
  const { catalogs = [], loading: catalogsLoading } = useGetCatalogs() || {};
  const history = useHistory();

  // Get project config to check for available catalogs
  const projectConfigFromCache = apolloClient.readQuery<GetProjectConfigByProjectIdAndType>({
    query: GET_PROJECT_CONFIG_BY_TYPE_QUERY,
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
  });

  const { projectConfig, loading: configLoading } = useGetProjectConfigByType({
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !projectId,
  }) || {};

  // Use cache or fetched config
  const availableCatalogIds = useMemo(() =>
    projectConfigFromCache?.projectConfig?.brandCatalogs ||
    projectConfig?.brandCatalogs ||
    [],
    [projectConfigFromCache?.projectConfig?.brandCatalogs, projectConfig?.brandCatalogs]
  );

  // Filter catalogs based on project config
  const filteredCatalogs = useMemo(() =>
    catalogs.filter((catalog) => availableCatalogIds.includes(catalog.id)),
    [catalogs, availableCatalogIds]
  );

  const { criteria = [], loading: isCriteriaLoading } = useGetSelectionCriteria({
    variables: {
      brandCatalogId: selectedCatalog,
    },
    skip: !selectedCatalog,
  }) || {};

  const isLoading = catalogsLoading || configLoading;

  // Auto-select effects
  useEffect(() => {
    if (filteredCatalogs.length === 1 && !selectedCatalog) {
      setSelectedCatalog(Number(filteredCatalogs[0].id));
    }
  }, [filteredCatalogs, selectedCatalog, setSelectedCatalog]);

  useEffect(() => {
    if (criteria?.length === 1 && !selectedRule && selectedCatalog) {
      setSelectedRule(Number(criteria[0].id));
    }
  }, [criteria, selectedRule, selectedCatalog, setSelectedRule]);

  const handleSetupCatalogClick = () => {
    history.push(pfaV2Routes.projectSettings.productCatalogs.replace(':projectId', String(projectId)));
  };

  return (
    <>
      <div className={styles.actionConfigItem}>
        <div>
          <BookIcon />
          <span> Send Product Catalog</span>
        </div>
        {isLoading ? (
          <LoadSpinner className={styles.loadingSpinner} centered />
        ) : filteredCatalogs.length > 0 ? (
          <Select
            placeholder="Select a Catalog"
            value={selectedCatalog}
            onChange={(value) => {
              setSelectedCatalog(value);
              setSelectedRule(null);
            }}
          >
            {filteredCatalogs.map((catalog) => (
              <Option
                key={catalog.id}
                value={catalog.id}
              >
                {catalog.name}
              </Option>
            ))}
          </Select>
        ) : (
          <div className="p-6 bg-[#FFF9F0] rounded-lg">
            <div className="flex items-start gap-3 mb-2">
              <CircleInfoIcon className="w-6 h-6 text-[#F5A623] flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Setup Catalog
                </h3>
                <p className="text-gray-600 mb-4">
                  Create your order request form and provide instructions for how to choose products for this collaboration.
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                type="primary"
                className="bg-[#4285F4] hover:bg-[#3367D6] text-white px-6 py-2 rounded-lg"
                onClick={handleSetupCatalogClick}
              >
                Set up Catalog
              </Button>
            </div>
          </div>
        )}
      </div>

      {selectedCatalog && (
        <div className={styles.actionConfigItem}>
          <div>
            <CartShoppingIcon />
            <span> Send Product Selection Rule</span>
          </div>
          <Select
            value={selectedRule}
            onChange={(value) => setSelectedRule(value)}
            disabled={!selectedCatalog || (!isCriteriaLoading && !criteria?.length)}
            placeholder={isCriteriaLoading ? 'Loading...' : !selectedCatalog || criteria?.length ? 'Select a Rule' : 'No rules available'}
            loading={isCriteriaLoading}
          >
            {criteria?.map((rule) => (
              <Option
                key={rule.id}
                value={Number(rule.id)}
              >
                {rule.label}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </>
  );
};

const ActionForm = (props: TProps) => {
  const {
    actionOptions,
    onUpdateActionNode,
    onClose,
    selectedNode,
    onUpdateMessageTemplate,
    resetMessageTemplate,
  } = props;

  const brandCatalogId = selectedNode?.actionNode?.brandCatalogId;
  const selectionRuleId = selectedNode?.actionNode?.selectionRuleId;

  const { user } = useAuth();
  const [templateId, setTemplateId] = useState<number>();
  const [resourceId, setResourceId] = useState<number>();
  const [fromPreviousSender, setFromPreviousSender] = useState<boolean>();
  const [templateValidationError, setTemplateValidationError] = useState<string>('');
  const [resourceValidationError, setResourceValidationError] = useState<string>('');
  const [newTemplate, setNewTemplate] = useState<MessageTemplateInput>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | MessageTemplateInput>(null);
  const [createNewTemplateDisabled, setCreateNewTemplateDisabled] = useState<boolean>(false);
  const { pfaApolloClient } = useAppContext();
  const [selectedCatalog, setSelectedCatalog] = useState<number>(brandCatalogId);
  const [selectedRule, setSelectedRule] = useState<number>(selectionRuleId);
  const [catalogValidationError, setCatalogValidationError] = useState<string>('');
  const [ruleValidationError, setRuleValidationError] = useState<string>('');

  const resources = useMemo(() => (
    actionOptions && actionOptions[0]?.emailResources.filter(
      (resource) => (
        resource.userId === user.sub
        || resource.id === resourceId
        || resource.displayName === 'Previous Sender'
      ),
    )
  ), [actionOptions, user.sub, resourceId]);
  const selectedResourceExist = useMemo(() => !isEmpty(find(resources, { id: resourceId })), [resources, resourceId]);

  // TODO: refactor to move the state to the root component and managed by useReducer
  // to separate the state from the component. Then pass the state down to avoid using useEffect.
  // Same to DelayForm and ConditionForm
  useEffect(() => {
    if (selectedNode && selectedNode.nodeType === AutomationNodeType.ACTION) {
      setTemplateId(selectedNode.actionNode.templateId);
      setResourceId(selectedNode.actionNode.resourceId);
      setFromPreviousSender(selectedNode.actionNode.fromPreviousSender);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (resetMessageTemplate) {
      setCreateNewTemplateDisabled(false);
      setNewTemplate(null);
    }
  }, [resetMessageTemplate]);

  const actionNodeDefaultTemplateData = useMemo(() => {
    if (!actionOptions) return null;
    const filtered = filter(
      actionOptions[0]?.messageTemplates,
      (template) => template.messageTemplateType !== TemplateType.GENERIC,
    );
    return first(filtered);
  }, [actionOptions]);

  const {
    data: {
      template: defaultAutomationTemplate = null,
    } = {},
   } = useGetMessageTemplateById({
    variables: {
      templateId: actionNodeDefaultTemplateData?.id,
    },
    skip: !actionNodeDefaultTemplateData?.id,
  });

  const {
    data: {
      template = null,
    } = {},
    loading: isTemplateLoading,
   } = useGetMessageTemplateById({
    variables: {
      templateId,
    },
    skip: !templateId,
  });

  useEffect(() => {
    setSelectedTemplate(template || newTemplate);
  }, [template, newTemplate]);

  const handleCreateNewTemplate = () => {
    setCreateNewTemplateDisabled(true);
    setTemplateId(null);
    setNewTemplate({
      id: uuidv4(),
      title: 'New Template',
      subject: 'Enter the subject here',
      text: defaultAutomationTemplate?.text,
      transportType: TemplateTransportType.EMAIL,
      bodyType: TemplateBodyType.HTML,
      type: TemplateType.GENERIC,
    });
  };

  const handleTemplateChange = (templateId) => {
    setCreateNewTemplateDisabled(false);
    setTemplateId(templateId);
  };

  const validateAction = (actionNode: TActionNode) => {
    const noTemplateError = 'Please select an email template';
    const noResourceError = 'Please select an email';
    const noCatalogError = 'Please select a catalog';
    const noRuleError = 'Please select a rule';
    const noError = '';

    const templateError = (!actionNode.templateId && !newTemplate) ? noTemplateError : noError;
    const resourceError = (!actionNode.resourceId && !actionNode.fromPreviousSender) || !selectedResourceExist ? noResourceError : noError;

    // Add validation for catalog and rule if needed
    const catalogError = !actionNode.brandCatalogId ? noCatalogError : noError;
    const ruleError = !actionNode.selectionRuleId ? noRuleError : noError;

    setTemplateValidationError(templateError);
    setResourceValidationError(resourceError);

    // validate the catalog error if the blueprint name is send product order request after brief is accepted
    if (selectedNode.bluePrintName === AutomationBlueprintName.SendProductOrderRequestAfterBriefIsAccepted) {
      // Add new validation error states if needed
      setCatalogValidationError(catalogError);
      setRuleValidationError(ruleError);
      return templateError === noError &&
        resourceError === noError &&
        catalogError === noError &&
        ruleError === noError;
    }
    return templateError === noError && resourceError === noError;
  };

  const onSaveAction = () => {
    const actionNode: TActionNode = {
      templateId,
      resourceId,
      fromPreviousSender,
      brandCatalogId: selectedCatalog,
      selectionRuleId: selectedRule,
    };
    if (!validateAction(actionNode)) return;
    onUpdateActionNode(selectedNode.targetNodeId, actionNode);
  };

  const onEmailResourceUpdate = (resourceId, resource) => {
    setResourceId(resourceId);
    setFromPreviousSender(resource.data.fromPreviousSender);
  };

  const handleCancel = () => {
    setCreateNewTemplateDisabled(false);
    setNewTemplate(null);
    onClose();
  };

  return (
    <div className={styles.ConfigDrawer}>
      <div>
        <FormHeader
          title="Edit Action"
          description="Select what action you would like to automate after previous steps are complete."
          onClose={onClose}
          icon={<ArrowRightIcon fontSize={20} />}
        />
        <div className={styles.configBody}>
          {selectedNode.bluePrintName === AutomationBlueprintName.SendProductOrderRequestAfterBriefIsAccepted && (
            <ApolloProvider client={pfaApolloClient}>
              <CatalogSelectionSection
                selectedCatalog={selectedCatalog}
                setSelectedCatalog={setSelectedCatalog}
                selectedRule={selectedRule}
                setSelectedRule={setSelectedRule}
                projectId={selectedNode?.projectId}
              />
            </ApolloProvider>
          )}

          <div className={styles.actionConfigItem}>
            <div>Send email from</div>
            <Select
              value={selectedResourceExist ? resourceId : undefined}
              onChange={onEmailResourceUpdate}
            >
              {resources.map((resource) => (
                <Option
                  key={resource.id}
                  value={resource.id}
                  label={resource.displayName}
                  data={resource}
                >
                  {resource.displayName}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.validationError}>{templateValidationError}</div>
          <div className={styles.actionConfigItem}>
            <div>Email Template</div>
            <div className={styles.editTemplateActions}>
              <Select
                className={styles.selectTemplate}
                showSearch
                optionFilterProp="label"
                value={templateId || newTemplate?.title}
                onChange={handleTemplateChange}
              >
                {actionOptions[0].messageTemplates.map((template) => (
                  <Option
                    key={template.id}
                    value={template.id}
                    label={template.displayName}
                  >
                    {template.displayName}
                  </Option>
                ))}
              </Select>
              <Tooltip
                placement="topRight"
                title="Create new template"
              >
                <Button
                  onClick={handleCreateNewTemplate}
                  className={styles.createNewTemplate}
                  disabled={createNewTemplateDisabled}
                >
                  <PlusIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
          {!selectedTemplate && isTemplateLoading && (
            <LoadSpinner className={styles.loadingSpinner} centered />
          )}
          {selectedTemplate && (
            <MessageTemplateEditor
              messageTemplate={selectedTemplate}
              onUpdateMessageTemplate={onUpdateMessageTemplate}
              resetMessageTemplate={resetMessageTemplate}
            />
          )}
          <div className={styles.validationError}>{resourceValidationError}</div>
          <div className={styles.validationError}>{catalogValidationError}</div>
          <div className={styles.validationError}>{ruleValidationError}</div>
        </div>
      </div>
      <FormFooter
        onSave={onSaveAction}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default ActionForm;
