import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { size } from 'lodash';

import { ComposeButton } from '@affiliates/components/MemberTable/Buttons';
import { ISTAComposerMemberFieldIds } from '@affiliates/hooks';
import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { OFFER_SOURCE, UserInfoInput } from '@affiliates/types/globalTypes';
import { EmailComposerModal } from '@frontend/app/components';
import { useAppContext } from '@frontend/context/AppContext';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { useGetInitialEmailMessage } from './useGetInitialEmailMessage';
import { NotifyMemberButton } from '../../components/MemberTable/Buttons/NotifyMemberButton';

const { useCallback, useMemo } = React;

interface IProps {
  disableCompose: boolean;
  includeMemberIds: number[];
  offer: GetOffersBySearchQuery_offers;
  source: OFFER_SOURCE;
  staComposerMemberFieldIds: ISTAComposerMemberFieldIds;
  isSourceEditOffer?: boolean;
  profile?: UserInfoInput;
  brandName?: string;

}

export const EmailComposeButton: React.FC<Readonly<IProps>> = (props) => {
  const {
    disableCompose,
    includeMemberIds,
    offer,
    source,
    staComposerMemberFieldIds,
    profile,
    isSourceEditOffer,
    brandName,
  } = props;
  const { apolloClient } = useAppContext();
  const { migrateToGraphQL, MemberPortal: memberPortalFeatureFlagEnabled } = useClientFeatures();
  const { content, subject } = useGetInitialEmailMessage(
    memberPortalFeatureFlagEnabled,
    offer,
    source,
    staComposerMemberFieldIds,
    profile,
    isSourceEditOffer,
    brandName,
    migrateToGraphQL,
  );
  const customButton = useCallback((onClick) => (
    <>
      {isSourceEditOffer ? (
        <NotifyMemberButton disabled={disableCompose} onClick={onClick} />
      ) : (
        <ComposeButton disabled={disableCompose} onClick={onClick} />
      )}

    </>

  ), [disableCompose, isSourceEditOffer]);
  const memberQuery = useMemo(() => ({
    communityIds: [-999],
    includeMemberIds,
  }), [includeMemberIds]);
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Fix in Node upgrade typing bash!
    <ApolloProvider client={apolloClient}>
      <EmailComposerModal
        customButton={customButton}
        memberQuery={memberQuery}
        memberCount={size(includeMemberIds)}
        initialMessage={content}
        allowSendingAsSeparate
        source="salestracking"
        label="Compose"
        subject={subject}
        isSourceEditOffer={isSourceEditOffer}
      />
    </ApolloProvider>
  );
};
