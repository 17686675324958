import React from 'react';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { P } from '@frontend/shadcn/components/typography/p';
import { GetPaymentDetailsByIdsQuery_payments } from '@frontend/app/queries/types/GetPaymentDetailsByIdsQuery';
import { GetTagsQuery_tags } from '@frontend/app/queries/types/GetTagsQuery';
import { MoneyBillsSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tag } from '@frontend/app/refresh-components';
import { getUniqueRefreshColor } from '@frontend/utils';
import { useAssignUnassignTag, useFetchTagsByEntityIdAndType } from '@frontend/app/hooks';
import { TagAction, TagEntityType } from '@frontend/app/types/globalTypes';
import { usePayment } from '../PaymentContext';
import { statusColorMapper } from '../constants';
import { PaymentStatus } from '../../constants';

const paidIcon = require('@frontend/app/assets/images/Paid.png');
const cancelledIcon = require('@frontend/app/assets/images/Cancelled.png');
const processingIcon = require('@frontend/app/assets/images/Processing.png');
const pendingIcon = require('@frontend/app/assets/images/Pending Info.png');

const ImageMapper = {
  [PaymentStatus.PAID]: paidIcon,
  [PaymentStatus.CANCELED]: cancelledIcon,
  [PaymentStatus.PROCESSING]: processingIcon,
  [PaymentStatus.PENDING]: pendingIcon,
};

interface PaymentStatusSectionProps {
  paymentDetails: GetPaymentDetailsByIdsQuery_payments[];
}

export const PaymentStatusSection: React.FC<PaymentStatusSectionProps> = ({ paymentDetails }) => {
  const [assignUnassignTag] = useAssignUnassignTag();
  const { openTagDialog } = usePayment();
  const { loading: _, data: assignedTags, refetch } = useFetchTagsByEntityIdAndType({
    variables: {
      entityType: TagEntityType.PAYMENT,
      entityIds: paymentDetails.map((payment) => payment.paymentId) || [],
    },
    fetchPolicy: 'no-cache',
  });

  const renderTags = () => {
    const tagItems = [];
    if (assignedTags?.tags) {
      assignedTags?.tags?.forEach((tag) => {
        tagItems.push(
          <Tag
            key={tag.id}
            label={tag.name}
            bgColor={getUniqueRefreshColor(tag.name)}
            isRemovable
            onClick={() => handleTagClick(tag)}
            onRemove={() => handleTagRemove(tag)}
          />,
        );
      });
    }

    tagItems.push(
      <Tag
        key="action"
        label={
          <div className="flex items-center gap-1">
            <PlusIcon className="h-3 w-3 font-semibold" />
            <span className="font-semibold text-[12px]">Add Tag</span>
          </div>
        }
        bgColor="bg-white"
        borderDashed
        onClick={handleAddTag}
      />,
    );

    return tagItems;
  };

  const handleTagClick = (tag: GetTagsQuery_tags) => {
    // Implement tag click functionality
    console.log('Tag clicked:', tag.name);
    // You can filter by tag or perform some other action
  };

  const handleTagRemove = async (tag: GetTagsQuery_tags) => {
    try {
      await assignUnassignTag({
        variables: {
          entityType: TagEntityType.PAYMENT,
          entityIds: paymentDetails.map((payment) => payment.paymentId) || [],
          tagIds: [tag.id],
          action: TagAction.UNASSIGN,
        },
      });
      refetch();
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleAddTag = () => {
    openTagDialog(
      paymentDetails.map((payment) => payment.paymentId),
      TagAction.ASSIGN,
      refetch
    );
  };

  if (!paymentDetails?.length) {
    return null;
  }

  if (paymentDetails?.length == 1) {
    const selectedPayment = paymentDetails?.[0];
    const totalAmount = selectedPayment?.amount;
    const formattedAmount = totalAmount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    const status = selectedPayment?.status;
    const statusStyles = statusColorMapper[status];

    return (
      <section className={`flex flex-col p-4 border ${statusStyles.border} rounded-xl ${statusStyles.cardShadeColor}`}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-col gap-2">
            <Badge
              className={`w-fit ${statusStyles.bg} ${statusStyles.text} hover:${statusStyles.bg} hover:${statusStyles.text}`}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Badge>
            <P className="text-3xl font-normal">{`${formattedAmount}`}</P>
          </div>
          <img src={ImageMapper[status]} alt={status} className="w-[74px] h-[74px]" />
        </div>
        <div className="flex flex-wrap gap-2 mt-1">{renderTags()}</div>
      </section>
    );
  }
  const totalAmountForMuliplePayments = paymentDetails.reduce((acc, payment) => acc + payment.amount, 0);
  const formattedTotalAmount = totalAmountForMuliplePayments?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return (
    <section className="flex flex-col p-4 border rounded-xl bg-grey-1">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <MoneyBillsSimpleIcon className="w-4 h-4 text-grey-5" />
            <P className="text-sm font-medium text-grey-5">Total Payment Amount for selected Payments</P>
          </div>
          <P className="text-3xl font-normal">{`${formattedTotalAmount}`}</P>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mt-1">{renderTags()}</div>
    </section>
  );
};
