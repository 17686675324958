import { useEffect, useState } from 'react';
import { logger } from '@common';

const isHeic = async (file: Blob) => {
  const buffer = await file.arrayBuffer();
  const slicedBuffer = buffer.slice(8, 12);
  const brandMajor = new TextDecoder('utf-8')
    .decode(slicedBuffer)
    .replace('\0', ' ')
    .trim();

  switch (brandMajor) {
    case 'mif1':
      return true; // {ext: 'heic', mime: 'image/heif'};
    case 'msf1':
      return true; // {ext: 'heic', mime: 'image/heif-sequence'};
    case 'heic':
    case 'heix':
      return true; // {ext: 'heic', mime: 'image/heic'};
    case 'hevc':
    case 'hevx':
      return true; // {ext: 'heic', mime: 'image/heic-sequence'};
  }

  return false;
};

const useConvertToJpg = (imageUrl: string) => {
  const [convertedImageUrl, setConvertedImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const convertToJpg = async () => {
      if (!imageUrl) return;

      try {
        const response = await fetch(`${imageUrl}?t=${Date.now()}`); // Prevent caching
        const blob = await response.blob();
        const mimeType = blob.type;

        // If the image is NOT HEIC, return the original URL
        if (mimeType !== 'image/heic' && mimeType !== 'image/heif') {
          if (!await isHeic(blob)) {
            setConvertedImageUrl(imageUrl);
            return;
          }
        }

        const heicConvert = (await import('heic-convert/browser')).default;

        // Convert HEIC to JPEG
        const arrayBuffer = await blob.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        const output = await heicConvert({
          buffer: uint8Array,
          format: 'JPEG',
          quality: 1.0,
        });

        const finalBlob = output instanceof Blob ? output : new Blob([output], { type: 'image/jpeg' });

        const jpgUrl = URL.createObjectURL(finalBlob);
        setConvertedImageUrl(jpgUrl);
      } catch (error) {
        logger.error('Error converting HEIC to JPEG', error);
        setConvertedImageUrl(imageUrl);
      }
    };

    convertToJpg();
  }, [imageUrl]);

  return convertedImageUrl;
};

export default useConvertToJpg;
