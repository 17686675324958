import React from 'react';
import { Badge, BadgeProps } from '@frontend/shadcn/components/ui/badge';
import { cn } from '@/shadcn/lib/utils';

const DismissableBadge = React.forwardRef<HTMLDivElement, BadgeProps>(({ children, className, ...props }) => (
  <Badge
    className={cn(
      'flex items-center space-x-1 rounded-lg bg-[#E5E8EB] whitespace-nowrap cursor-pointer',
      className
    )}
    {...props}
  >
    {children}
  </Badge>
));

DismissableBadge.displayName = 'DismissableBadge';

export {
  DismissableBadge,
};
