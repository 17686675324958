/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10vm3_157 {
  display: flex;
}

._justify-content-space-between_10vm3_161 {
  justify-content: space-between;
}

._tabular-nums_10vm3_165 {
  font-variant-numeric: tabular-nums;
}

._Invite_10vm3_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_10vm3_311 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Invite_10vm3_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Invite_10vm3_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Invite_10vm3_169 ::-webkit-scrollbar-track, ._Invite_10vm3_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Invite_10vm3_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Invite_10vm3_169 {
  min-width: 10rem;
  max-width: 20rem;
  height: 2.5rem;
  display: flex;
  flex-flow: row nowrap;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_10vm3_169._isFetching_10vm3_213 {
  opacity: 0.6;
  cursor: wait;
}
._Invite_10vm3_169._isFetching_10vm3_213 * {
  pointer-events: none;
}
._Invite_10vm3_169 ._loading_10vm3_220 {
  pointer-events: none;
  border-radius: 0.5rem !important;
}
._Invite_10vm3_169._hideButton_10vm3_224:not([class*=withButton]) ._select_10vm3_224 {
  width: 100%;
  max-width: 100%;
}
._Invite_10vm3_169._hideButton_10vm3_224:not([class*=withButton]) ._inviteButton_10vm3_228 {
  width: 0;
  min-width: 0;
  max-width: 0;
  opacity: 0;
}
._Invite_10vm3_169._withButton_10vm3_234 {
  min-width: 20rem;
}
._Invite_10vm3_169._withButton_10vm3_234 ._select_10vm3_224 {
  width: calc(100% - 5rem);
  max-width: calc(100% - 5rem);
}
._Invite_10vm3_169._withButton_10vm3_234 ._select_10vm3_224 ._selectButton_10vm3_241 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
._Invite_10vm3_169._withBorder_10vm3_245 ._select_10vm3_224 ._selectButton_10vm3_241 {
  border: 0.0625rem solid #dadcde;
}
._Invite_10vm3_169._withBorder_10vm3_245._withButton_10vm3_234 ._select_10vm3_224 ._selectButton_10vm3_241 {
  border-right: 0;
}
._Invite_10vm3_169 ._select_10vm3_224 {
  width: calc(100% - 5rem);
  max-width: calc(100% - 5rem);
  height: 100%;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_10vm3_169 ._select_10vm3_224 ._selectButton_10vm3_241 {
  padding-left: 1rem;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_10vm3_169 ._select_10vm3_224 ._selectButton_10vm3_241._isFocused_10vm3_270 {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
._Invite_10vm3_169 ._select_10vm3_224 ._selectButton_10vm3_241 > [class*=arrow] {
  padding: 0 1rem 0 0.5rem;
}
._Invite_10vm3_169 ._inviteButton_10vm3_228 {
  padding-left: 0;
  padding-right: 0;
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-sizing: border-box;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Invite_10vm3_169 ._inviteButton_10vm3_228 > [class*=label] {
  font-weight: 500;
  letter-spacing: 0.03125rem;
}
._Invite_10vm3_169 ._inviteButton_10vm3_228._inviting_10vm3_293 {
  cursor: wait;
}
._Invite_10vm3_169 ._inviteButton_10vm3_228._wait_10vm3_296 {
  opacity: 0.8;
  cursor: wait;
}
._Invite_10vm3_169 ._inviteButton_10vm3_228._disabled_10vm3_300 {
  cursor: default;
}

._popover_10vm3_304 {
  width: 20rem;
  overflow: hidden;
}
._popover_10vm3_304 ._popoverContentWrapper_10vm3_308 {
  border-radius: 0.5rem;
}
._popover_10vm3_304._shown_10vm3_311 ._message_10vm3_311 span {
  pointer-events: initial;
}

._popover_10vm3_304 ._popoverContent_10vm3_308 {
  max-height: 15.625rem;
  width: 100%;
  overflow-x: hidden;
}

._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 {
  margin: 0;
  padding: 0.25rem 0.75rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 > [class*=label] {
  height: 100%;
  flex: 1;
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 > [class*=actions] {
  right: 0.25rem;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._noSelect_10vm3_341 {
  pointer-events: none;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._communityOption_10vm3_344 {
  height: 2.5rem;
  color: #8f8d91;
  font-size: 0.6875rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._communityOption_10vm3_344:not(:first-of-type) {
  margin-top: 0.75rem;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._programOption_10vm3_354 {
  font-size: 0.875rem;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._programOption_10vm3_354._noPrograms_10vm3_357 {
  color: #8f8d91;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311 {
  padding: 0;
  height: auto;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311 > [class*=label] div {
  width: 20rem;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311 span {
  padding: 0.375rem;
  width: 100%;
  color: #5c5a5e;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: initial;
  white-space: pre-line;
  overflow: hidden;
  display: block;
  line-height: 1;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311 span ._accountLink_10vm3_380 {
  color: #3996e0;
  font-weight: 600;
  text-decoration: none;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311._ineligible_10vm3_385 span {
  background-color: #e9e8ea;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311._error_10vm3_388 span {
  color: #fdfdfd;
  background-color: #f1515f;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321._message_10vm3_311._noCommunities_10vm3_392 span {
  font-size: 0.875rem;
  background: transparent;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._inviteButton_10vm3_228 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: auto;
  min-width: initial;
  max-width: initial;
  height: 2.25rem;
  border-radius: 624.9375rem;
  box-sizing: border-box;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._inviteButton_10vm3_228._inviting_10vm3_293 {
  padding: 0.5rem;
  min-width: 2.25rem;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._inviteButton_10vm3_228._inviting_10vm3_293 > [class*=icon] {
  margin: 0;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._new_10vm3_414,
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._invited_10vm3_415,
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._approved_10vm3_416 {
  margin-right: 0.5rem;
  text-transform: capitalize;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._new_10vm3_414,
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._invited_10vm3_415 {
  color: #8f8d91;
}
._popover_10vm3_304 ._popoverContent_10vm3_308 ._option_10vm3_321._option_10vm3_321 ._approved_10vm3_416 {
  color: #3996e0;
}

._tooltip_10vm3_428 {
  font-size: 0.75rem;
  line-height: 1.2;
  font-weight: 600;
  color: #d9534f;
}

._ammendAction_10vm3_435 {
  font-weight: 600;
  text-decoration: none;
  color: #d9534f;
  cursor: pointer;
  padding-left: 0.25rem;
}

._searchContainer_10vm3_443 {
  padding: 0;
}

._searchOption_10vm3_447 {
  padding: 0 !important;
  border-bottom: 1px solid #dadcde;
}
._searchOption_10vm3_447:hover {
  background-color: transparent !important;
}
._searchOption_10vm3_447 [class*=searchIcon],
._searchOption_10vm3_447 [class*=clearButton] {
  color: #4F5256 !important;
}

._searchError_10vm3_464 {
  color: var(--error-color);
  font-size: 12px;
  padding: 0.75rem 1rem;
  margin: 0;
  width: 100%;
}