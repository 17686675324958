import { useCallback } from 'react';
import { first, isEmpty } from 'lodash';

import { OFFER_ACTIONS } from '@affiliates/constants/offers';
import {
  useCreateOfferMutation,
  useUpdateOfferMutation,
} from '@affiliates/hooks';
import { CreateOfferInput, UpdateOfferInput } from '@affiliates/types/globalTypes';
import { EventName, TSalesTrackingEditOfferProperties, logger } from '@common';
import { useAddProjectEvent } from '@frontend/app/containers/Projects/hooks';
import { useEventContext } from '@frontend/app/context/EventContext';
import { message } from '@revfluence/fresh';

const mapPayloadToEventStructure = (
  payload: CreateOfferInput | UpdateOfferInput,
): TSalesTrackingEditOfferProperties => {
  if (!isEmpty(payload.links)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const link = first(payload.links as any) as any;
    return {
      conversionTrackingType: link.conversionTrackingType,
      conversionType: link.conversionType,
      description: payload.description,
      expirationDate: payload.expirationDate,
      flatPayout: link.flatPayout,
      imageUrl: payload.imageUrl,
      name: payload.name,
      payoutType: link.payoutType,
      percentPayout: link.percentPayout,
      source: 'TUNE',
      status: link.status,
      url: link.url,
      programId: payload.programId,
      createdFrom: payload.programId ? 'workflow' : 'sales tracking',
    };
  }
  if (!isEmpty(payload.promos)) {
    const promo = first(payload.promos);
    return {
      codeSuffix: promo.codeSuffix,
      description: payload.description,
      flatPayout: promo.flatPayout,
      imageUrl: payload.imageUrl,
      payoutType: promo.payoutType,
      percentPayout: promo.percentPayout,
      prefixType: promo.prefixType,
      priceRuleAmount: promo.priceRuleAmount,
      priceRuleType: promo.priceRuleType,
      name: payload.name,
      source: 'SHOPIFY',
      specialLimitNewCustomersOnly: promo.specialLimitNewCustomersOnly,
      specialLimitOnePerSale: promo.specialLimitOnePerSale,
      specialLimitUsageCapAmount: promo.specialLimitUsageCapAmount,
      specialLimitUsageCapEnabled: promo.specialLimitUsageCapEnabled,
      status: promo.status,
      usageLimitAmount: promo.usageLimitAmount,
      usageLimitRule: promo.usageLimitRule,
      programId: payload.programId,
      createdFrom: payload.programId ? 'workflow' : 'sales tracking',
      discountCombination: promo?.discountCombination,
    };
  }
};

const handleRedirectForAppInstall = () => {
  let countdown = 5;
  const messageKey = 'redirect-countdown';
  message.info({
    key: messageKey,
    content: `Aspire Affiliates Shopify app is either not installed or needs an update. Redirecting you to the Shopify App Store in ${countdown}...`,
    duration: 0,
  });

  const countdownInterval = setInterval(() => {
    countdown -= 1;

    if (countdown > 0) {
      message.info({
        key: messageKey,
        content: `Aspire Affiliates Shopify app is either not installed or needs an update. Redirecting you to the Shopify App Store in ${countdown}...`,
        duration: 0,
      });
    } else {
      clearInterval(countdownInterval);
      message.destroy(messageKey);
      window.open('https://apps.shopify.com/aspire-2-0?show_store_picker=1', '_blank');
    }
  }, 1000);
};

export const useOfferPersistence = (
  id: number | null,
  setIsSaving: (saving: boolean) => void,
  onSuccess: (offerId: number, mode: OFFER_ACTIONS) => void,
) => {
  const addEvent = useEventContext();
  const { addProjectEvent } = useAddProjectEvent();

  // create new offer
  const [createOffer] = useCreateOfferMutation({
    onCompleted(data) {
      setIsSaving(false);
      onSuccess(data.offer.id, OFFER_ACTIONS.CREATED);
    },
    onError(error) {
      if (error.message === 'Offer name already exists') {
        message.error(error.message);
      } else if (error.message === 'Offer URL length cannot be longer than 2048 characters including UTM parameters.') {
        message.error(error.message);
      } else if (error.message === 'This Discount code is already linked in Aspire') {
        message.error(error.message);
      } else if (error.message === 'Discount code not found in Shopify') {
        message.error(error.message);
      } else if (error.message === 'The type of discount is not of BXGY') {
        message.error(error.message);
      } else if (error.message === 'Redirect URL should match with Shopify store URL') {
        message.error(error.message);
      } else if (error.message === 'Landing page is only applicable for promo link offers') {
        message.error(error.message);
      } else if (error.message === 'Aspire Affiliates App is not installed') {
        handleRedirectForAppInstall();
      } else if (error.message === 'We are unable create landing page, please update the Aspire Affiliates App!') {
        handleRedirectForAppInstall();
      } else {
        message.error('Error in Creating Offer');
      }
      setIsSaving(false);
      logger.error(error);
    },
  });
  const createOfferSubmit = useCallback((payload: CreateOfferInput) => {
    try {
      createOffer({
        variables: {
          data: payload,
        },
      });
    } catch (e) {
      logger.error(e);
    }

    if (payload.programId) {
      addProjectEvent(EventName.SalesTrackingCreateOffer, mapPayloadToEventStructure(payload));
    } else {
      addEvent(EventName.SalesTrackingCreateOffer, mapPayloadToEventStructure(payload));
    }
  }, [addEvent, addProjectEvent, createOffer]);

  // update existing offer
  const [editOffer] = useUpdateOfferMutation({
    onCompleted(data) {
      setIsSaving(false);
      onSuccess(data.offer.id, OFFER_ACTIONS.UPDATED);
    },
    onError(error) {
      if (error.message === 'Offer name already exists') {
        message.error(error.message);
      }
      if (error.message === 'Offer URL length cannot be longer than 2048 characters including UTM parameters.') {
        message.error(error.message);
      }
      if (error.message === 'Expiration date cannot start before today.') {
        message.error(error.message);
      }
      if (error.message === 'Redirect URL should match with Shopify store URL') {
        message.error(error.message);
      }
      if (error.message === 'Subscription Eligibility Error') {
        message.destroy();
        message.error('All selected store must have subscription enabled to create and sync subscription based offer on them.');
      }
      if (error.message === 'Aspire Affiliates App is not installed') {
        handleRedirectForAppInstall();
      }
      if (error.message === 'Error in creating landing page, Please check App Permissions!') {
        message.error(error.message);
      }
      if (error.message === 'Invalid Shopify Redirect URL') {
        message.error(error.message);
      }
      if (error.message === 'We are unable create landing page, please update the Aspire Affiliates App!') {
        handleRedirectForAppInstall();
      }
      setIsSaving(false);
      logger.error(error);
    },
  });
  const updateOfferSubmit = useCallback((payload: UpdateOfferInput) => {
    try {
      editOffer({
        variables: {
          id,
          data: payload,
        },
      });
    } catch (e) {
      logger.error(e);
    }

    addEvent(EventName.SalesTrackingEditOffer, mapPayloadToEventStructure(payload));
  }, [addEvent, editOffer, id]);

  // delete existing offer
  const [deleteOffer] = useUpdateOfferMutation({
    onCompleted(data) {
      setIsSaving(false);
      onSuccess(data.offer.id, OFFER_ACTIONS.DELETED);
    },
    onError(error) {
      setIsSaving(false);
      logger.error(error);
    },
  });
  const deleteOfferSubmit = useCallback((payload: UpdateOfferInput) => {
    try {
      deleteOffer({
        variables: {
          id,
          data: payload,
        },
      });
    } catch (e) {
      logger.error(e);
    }

    addEvent(EventName.SalesTrackingEditOffer, mapPayloadToEventStructure(payload));
  }, [addEvent, deleteOffer, id]);

  // re-activate existing offer
  const [restoreOffer] = useUpdateOfferMutation({
    onCompleted(data) {
      setIsSaving(false);
      onSuccess(data.offer.id, OFFER_ACTIONS.RESTORED);
    },
    onError(error) {
      setIsSaving(false);
      logger.error(error);
    },
  });
  const restoreOfferSubmit = useCallback((payload: UpdateOfferInput) => {
    try {
      restoreOffer({
        variables: {
          id,
          data: payload,
        },
      });
    } catch (e) {
      logger.error(e);
    }

    addEvent(EventName.SalesTrackingEditOffer, mapPayloadToEventStructure(payload));
  }, [addEvent, id, restoreOffer]);

  return {
    createOffer: createOfferSubmit,
    deleteOffer: deleteOfferSubmit,
    restoreOffer: restoreOfferSubmit,
    updateOffer: updateOfferSubmit,
  };
};
