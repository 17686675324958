import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { Input } from '@frontend/shadcn/components/ui/input';

interface IProps extends IShopifyFormElementProps<'codePrefix'> {}

export const RefreshOfferCodePrefix: React.FC<Readonly<IProps>> = React.memo(
  ({
    // disabled,
    name,
  }) => {
    const rules = [{ required: true, message: 'Please input your Prefix' }];
    return (
      <>
        <Form.Item
          name={name}
          rules={rules}
          className="!mb-0"
        >
          <Input name={name} placeholder="Prefix" className="h-8" />
        </Form.Item>
      </>
    );
  },
);

RefreshOfferCodePrefix.displayName = 'RefreshOfferCodePrefix';
