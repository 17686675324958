/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_a4srl_157 {
  display: flex;
}

._justify-content-space-between_a4srl_161 {
  justify-content: space-between;
}

._tabular-nums_a4srl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_a4srl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CommunityDropdown_a4srl_178 {
  cursor: pointer;
}
._CommunityDropdown_a4srl_178._disabled_a4srl_181 {
  background-color: transparent;
  cursor: default;
}
._CommunityDropdown_a4srl_178._disabled_a4srl_181 > div:last-child {
  background-color: transparent !important;
}

._isAddNew_a4srl_189 {
  margin-top: 0.625rem;
  position: relative;
}
._isAddNew_a4srl_189::before {
  content: "";
  height: 0.0625rem;
  top: -0.3125rem;
  left: 0;
  right: 0;
  background-color: #e9e8ea;
  position: absolute;
}

._searchOption_a4srl_203 {
  padding: 4px !important;
}
._searchOption_a4srl_203:hover {
  background-color: transparent !important;
}

._menu_a4srl_210 {
  min-width: 200px;
}

._searchContainer_a4srl_214 {
  width: 100%;
  padding: 0;
  margin: 0;
}
._searchContainer_a4srl_214 [class*=searchIcon],
._searchContainer_a4srl_214 [class*=clearButton] {
  color: #4F5256 !important;
}
._searchContainer_a4srl_214 .ant-input-affix-wrapper {
  width: 100% !important;
  min-width: 100% !important;
}
._searchContainer_a4srl_214 .ant-input-affix-wrapper[aria-invalid=true] {
  border-color: var(--error-color);
}

._searchError_a4srl_231 {
  color: #4f5256;
  font-size: 12px;
  padding: 0.75rem 1rem;
  margin: 0;
  width: 100%;
}