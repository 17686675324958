import { ISource } from '@frontend/app/components';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Operator } from '@frontend/app/types/MemberSearch';
import { IOperator } from '../../FilterList/FilterFormPopover';

interface Filter {
  id: string
  source?: ISource
  field?: IField
  operator?: IOperator
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  isEditing?: boolean
  isSelected?: boolean
}
export interface MembersTableFiltersContextState {
  filters: Filter[];
  logicalOperator: Operator.AND | Operator.OR;
  isDrawerOpen: boolean;
}

export interface MembersTableFiltersContextSetters {
  addFilter: (filter: Filter) => void;
  setSource: (filterId: string, source: ISource) => void;
  setField: (filterId: string, field: IField) => void;
  setOperator: (filterId: string, operator: IOperator) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (filterId: string, value: any) => void;
  setIsEditing: (filterId: string, isEditing: boolean) => void;
  removeFilter: (filterId: string) => void;
  setIsSelected: (filterId: string, isSelected: boolean) => void;
  setLogicalOperator: (logicalOperator: Operator.AND | Operator.OR) => void;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  clearAllFilters: () => void;
}

const initialState: MembersTableFiltersContextState = {
  filters: [
    {
      id: uuidv4(),
      isEditing: true,
      source: null,
      field: null,
      operator: null,
      value: null,
      isSelected: false,
    },
  ],
  logicalOperator: Operator.AND,
  isDrawerOpen: false,
};

export enum Actions {
  AddFilter = 'ADD_FILTER',
  SetSource = 'SET_SOURCE',
  SetField = 'SET_FIELD',
  SetOperator = 'SET_OPERATOR',
  SetValue = 'SET_VALUE',
  SetIsEditing = 'SET_IS_EDITING',
  RemoveFilter = 'REMOVE_FILTER',
  SetIsSelected = 'SET_IS_SELECTED',
  SetLogicalOperator = 'SET_LOGICAL_OPERATOR',
  SetIsDrawerOpen = 'SET_IS_DRAWER_OPEN',
  ClearAllFilters = 'CLEAR_ALL_FILTERS',
}

type IActions = {
  type: Actions.AddFilter;
  payload: Filter;
} | {
  type: Actions.SetSource;
  payload: { filterId: string, source: ISource };
} | {
  type: Actions.SetField;
  payload: { filterId: string, field: IField };
} | {
  type: Actions.SetOperator;
  payload: { filterId: string, operator: IOperator };
} | {
  type: Actions.SetValue;
  payload: { filterId: string, value: unknown };
} | {
  type: Actions.SetIsEditing;
  payload: { filterId: string, isEditing: boolean };
} | {
  type: Actions.RemoveFilter;
  payload: { filterId: string };
} | {
  type: Actions.SetIsSelected;
  payload: { filterId: string, isSelected: boolean };
} | {
  type: Actions.SetLogicalOperator;
  payload: Operator.AND | Operator.OR;
} | {
  type: Actions.SetIsDrawerOpen;
  payload: boolean;
} | {
  type: Actions.ClearAllFilters;
};

type MembersTableFiltersContextProps = MembersTableFiltersContextState & MembersTableFiltersContextSetters;

export const MembersTableFiltersContext = createContext<MembersTableFiltersContextProps>(initialState as MembersTableFiltersContextProps);

export const MembersTableFiltersProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state: MembersTableFiltersContextState, action: IActions): MembersTableFiltersContextState => {
      switch (action.type) {
        case Actions.AddFilter:
          return { ...state, filters: [...state.filters, action.payload] };
        case Actions.SetSource:
          return {
            ...state,
            filters: state.filters.map((filter) => (filter.id === action.payload.filterId ? { ...filter, source: action.payload.source } : filter)),
          };
        case Actions.SetField:
          return {
            ...state,
            filters: state.filters.map((filter) => (filter.id === action.payload.filterId ? { ...filter, field: action.payload.field } : filter)),
          };
        case Actions.SetOperator:
          return {
            ...state,
            filters: state.filters.map((filter) => (filter.id === action.payload.filterId ? { ...filter, operator: action.payload.operator } : filter)),
          };
        case Actions.SetValue:
          return {
            ...state,
            filters: state.filters.map((filter) => (filter.id === action.payload.filterId ? { ...filter, value: action.payload.value } : filter)),
          };
        case Actions.SetIsEditing:
          return {
            ...state,
            filters: state.filters.map((filter) => (filter.id === action.payload.filterId ? { ...filter, isEditing: action.payload.isEditing } : filter)),
          };
        case Actions.RemoveFilter:
          return { ...state, filters: state.filters.filter((filter) => filter.id !== action.payload.filterId) };
        case Actions.SetIsSelected:
          return {
            ...state,
            filters: state.filters.map((filter) => (filter.id === action.payload.filterId ? { ...filter, isSelected: action.payload.isSelected } : filter)),
          };
        case Actions.SetLogicalOperator:
          return { ...state, logicalOperator: action.payload };
        case Actions.SetIsDrawerOpen:
          return { ...state, isDrawerOpen: action.payload };
        case Actions.ClearAllFilters:
          return {
            ...state,
            filters: state.filters.map((filter) => ({
              ...filter,
              isSelected: false,
            })),
          };
        default:
          return state;
      }
    },
    initialState,
  );

  const actions = useMemo<MembersTableFiltersContextSetters>(
    () => ({
      addFilter: (filter) => dispatch({ type: Actions.AddFilter, payload: filter }),
      setSource: (filterId, source) => dispatch({ type: Actions.SetSource, payload: { filterId, source } }),
      setField: (filterId, field) => dispatch({ type: Actions.SetField, payload: { filterId, field } }),
      setOperator: (filterId, operator) => dispatch({ type: Actions.SetOperator, payload: { filterId, operator } }),
      setValue: (filterId, value) => dispatch({ type: Actions.SetValue, payload: { filterId, value } }),
      setIsEditing: (filterId, isEditing) => dispatch({ type: Actions.SetIsEditing, payload: { filterId, isEditing } }),
      removeFilter: (filterId) => dispatch({ type: Actions.RemoveFilter, payload: { filterId } }),
      setIsSelected: (filterId, isSelected) => dispatch({ type: Actions.SetIsSelected, payload: { filterId, isSelected } }),
      setLogicalOperator: (logicalOperator) => dispatch({ type: Actions.SetLogicalOperator, payload: logicalOperator }),
      setIsDrawerOpen: (isDrawerOpen) => dispatch({ type: Actions.SetIsDrawerOpen, payload: isDrawerOpen }),
      clearAllFilters: () => dispatch({ type: Actions.ClearAllFilters }),
    }),
    [],
  );

  const memoizedValue = useMemo<MembersTableFiltersContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <MembersTableFiltersContext.Provider value={memoizedValue}>{children}</MembersTableFiltersContext.Provider>;
};

export const useMembersTableFiltersContext = () => useContext(MembersTableFiltersContext);
