// Add a checkbox here similar to @RefreshOfferUrlThirdPartySelection.tsx with key named - trackThirdPartyPayout

import { Checkbox, Tooltip } from 'antd';
import { Form } from '@revfluence/fresh';
import React from 'react';
import { FormAsyncAction } from '@frontend/applications/AffiliatesApp/components/OfferForm/types';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IProps {
  values: {
    trackThirdPartyPayout: boolean;
    thirdPartyTracking: string;
  };
  handleFormAsyncActions: (value: FormAsyncAction) => void;
}

export const TrackPayout: React.FC<IProps> = ({ values, handleFormAsyncActions }) => {
  const handleCheckboxChange = (e: { target: { checked: boolean } }) => {
    const checked = e.target.checked;
    handleFormAsyncActions({
      action: 'UpdateField',
      payload: { key: 'trackThirdPartyPayout', value: checked },
    });
  };

  return (
    <div className="mt-3">
      <Form.Item>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="trackThirdPartyPayout"
            checked={values?.trackThirdPartyPayout || false}
            onChange={handleCheckboxChange}
          >
            Track Payout in Aspire
          </Checkbox>
          <Tooltip title="Enabling this setting will override the commission values in Impact so you can manage commission tiers in Aspire">
            <InfoCircleOutlined className="text-grey-4 cursor-help" />
          </Tooltip>
        </div>
      </Form.Item>
    </div>
  );
};
