import gql from 'graphql-tag';
import { PROGRAM_FRAGMENT } from './fragments/ProgramFragment';

export const GET_PROGRAM_BY_ID = gql`
  query ProgramByIdQuery($id: Int!, $getWorkItems: Boolean) {
    program: programById(id: $id, getWorkItems: $getWorkItems) {
      ...ProgramFragment
      specKey
      isFlexibleSpec
      workletSpecKeys
      workItems {
        specURI
      }
    }
  }
  ${PROGRAM_FRAGMENT}
`;
