import React from 'react';
import { useFilterFormData } from '../../filters-form/hooks/useFilterFormData';

interface UseGroupBadges {
  groupIds: number[];
}

export const useGroupBadges = ({ groupIds }: UseGroupBadges) => {
  const { groups } = useFilterFormData();

  const groupBadges = React.useMemo(() => groups?.filter((group) => groupIds.includes(group.id)), [
    groups,
    groupIds,
  ]);

  return {
    groupBadgesProps: {
      data: groupBadges,
    }
  };
};
