import React from 'react';

interface SpinnerIconProps {
  className?: string;
  size?: number;
  'aria-hidden'?: boolean | 'true' | 'false'; // Updated type to allow string values
}

export const SpinnerIcon: React.FC<SpinnerIconProps> = ({
  className,
  size = 16,
  'aria-hidden': ariaHidden,
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={ariaHidden}
  >
    <path
      d="M8 1.5A6.5 6.5 0 1014.5 8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 8 8"
        to="360 8 8"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);
