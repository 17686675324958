import * as React from 'react';
import {
 map, pickBy, values, orderBy, groupBy,
 reduce,
} from 'lodash';

import { RouteComponentProps } from 'react-router-dom';
import { logger } from '@common';

import {
  Select, OverlaySpinner, LoadSpinner, Button, Notice, IconSelect, Toast,
  IToastRefHandles, Checkbox,
} from '@components';
import { cx } from 'class-variance-authority';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useActivationsQuery, useCommunitiesQuery, useProgramsQuery } from '@frontend/app/hooks';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { ClientFeature } from '@frontend/app/constants';
import { AssignPostTile } from '../components/AssignPostTile';
import { useFetchPostsData, ISocialPostArtifact } from '../useFetchSocialPostData';
import { saveSocialData } from '../saveSocialPostData';

import styles from './SocialAppDashboardPosts.scss';
import { NO_ASSIGNMENT } from '../appFields';

const {
 useState, useMemo, useEffect, useRef,
} = React;
const PAGE_SIZE = 20;

const SocialAppDashboardPosts: React.FunctionComponent<RouteComponentProps> = () => {
  const addEvent = useEventContext();
  const isProjects = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const [selectedState, setSelectedState] = useState<{ [key: string]: boolean }>({});
  const [isImageFailed, setIsImageFailed] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>(null);
  const [postsCache, setPostsCache] = useState<{ [key: string]: ISocialPostArtifact }>({});
  const [page, setPage] = useState<number>(0);
  const [saveResultData, setSaveResultData] = useState<ISocialPostArtifact[]>([]);
  const [lastSelectedState, setLastSelectedState] = useState<{ [key: string]: boolean }>(null);

  const toastRef = useRef<IToastRefHandles>(null);

  const {
    backendServerApiEndpoint,
    clientId,
    userId,
  } = useApplication();
  const [byUserId, setByUserId] = useState<string>(userId);
  // const { data: projectsData, loading: loadingProjects } = useGetAllProjectsQuery({
  //   fetchPolicy: 'cache-only',
  // });

  const { backendServerFetchResponse } = useBackendServerFetch();

  // Data fetch
  const { data: postsFetch, loading: postsLoading, error: postsError } = useFetchPostsData(
    `${backendServerApiEndpoint}/social_mention`, {
    clientId,
    memberId: null,
    unassigned: false,
    userId: byUserId,
    page,
    pageSize: PAGE_SIZE,
  },
  );

  const {
    error: activationsError,
    activations,
  } = useActivationsQuery();

  const {
    data: projectsData,
  } = useProgramsQuery();

  const {
    data: communitiesData,
  } = useCommunitiesQuery();

  const projectsDirNames = useMemo(() => groupBy((projectsData?.programs || []), 'id'), [projectsData]);
  const communitiesDirNames = useMemo(() => groupBy((communitiesData?.communities || []), 'id'), [communitiesData]);
  const postsData = useMemo(() => (postsFetch || []).map((post) => {
    post.post_type = post.post_type?.toLowerCase();

    const programNames = [];
    (post.program_ids || []).forEach((id) => {
      const [project] = projectsDirNames[id] || [];
      if (project) {
        programNames.push(project.title);
      }
    });
    post.program_names = programNames;

    const communityNames = [];
    (post.community_ids || []).forEach((id) => {
      const [community] = communitiesDirNames[id] || [];
      if (community) {
        communityNames.push(community.title);
      }
    });
    post.community_names = communityNames;
    return post;
  }), [postsFetch, projectsDirNames, communitiesDirNames]);

  const isSelected = (mentionId: number) => !!selectedState[mentionId];

  // Data processing
  const selectedCount = useMemo(() => Object.keys(pickBy(selectedState)).length, [selectedState]);

  const activationOptions = useMemo(() => [
      {
        value: null,
        label: 'No Activation',
      },
      ...map(activations, (activation) => ({
          value: activation.id,
          label: activation.name,
        })),
    ], [activations]);

    const projectOptions = useMemo(() => [
      ...map(projectsData && projectsData.programs, (project) => ({
        value: project.id,
        label: project.title,
      })),
      { value: null, label: NO_ASSIGNMENT, optionClass: cx(styles.separator) },
    ], [projectsData]);

  const isAnyPostSelected = () => selectedCount > 0;

  const communityOptions = useMemo(() => [
    ...map(communitiesData && communitiesData.communities, (community) => ({
      value: community.id,
      label: community.title,
    })),
    { value: null, label: NO_ASSIGNMENT, optionClass: cx(styles.separator) },
  ], [communitiesData]);

  // Functions to manage local posts cache based on changes
  useEffect(() => {
    if (!postsData) {
      return;
    }

    const newPosts = { ...postsCache };
    for (const post of postsData) {
      newPosts[post.mention_id] = post;
    }
    setPostsCache(newPosts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsData]);

  useEffect(() => {
    if (!saveResultData) {
      return;
    }

    const newPosts = { ...postsCache };
    for (const post of saveResultData) {
      const names = reduce(
        post.program_ids,
        (acc, id) => (projectsDirNames[id] && projectsDirNames[id][0]
          ? [...acc, projectsDirNames[id][0].title]
          : acc),
        [],
      );

      post.program_names = names;
      newPosts[post.mention_id] = post;
    }
    setPostsCache(newPosts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveResultData, projectsDirNames]);

  const data: ISocialPostArtifact[] = useMemo(() => orderBy(values(postsCache), ['datetime_posted'], ['desc']), [postsCache]);

  const projectFiterOptions = useMemo(
    () => [...new Set(data.flatMap((post) => post.program_names))].map((project) => ({ value: project, label: project })),
   [data]);

  const groupFiterOptions = useMemo(
    () => [...new Set(data.flatMap((post) => post.community_names))].map((group) => ({ value: group, label: group })),
   [data]);

  const [filteredPosts, setFilteredPosts] = useState<ISocialPostArtifact[]>(data);

  const [projectFilter, setProjectFilter] = useState<string>(null);
  const [groupFilter, setGroupFilter] = useState<string>(null);

  useEffect(() => {
    setFilteredPosts(data);
  }, [data]);

  const onProjectFilterSelected = (val:string) => {
    setGroupFilter(null);
    setProjectFilter(val);
    if (val === null) {
      setFilteredPosts(data.filter((post) => post.program_names.length === 0));
    } else {
      setFilteredPosts(data.filter((post) => post.program_names.includes(val)));
    }
  };

  const onGroupFilterSelected = (val:string) => {
    setProjectFilter(null);
    setGroupFilter(val);
    if (val === null) {
      setFilteredPosts(data.filter((post) => post.community_names.length === 0));
    } else {
      setFilteredPosts(data.filter((post) => post.community_names.includes(val)));
    }
  };

  // Helper functions
  const resetCache = () => {
    setPostsCache({});
    setSelectedState({});
    setLastSelectedState(null);
    setPage(0);
  };

  const showSuccessToast = () => {
    const toast = toastRef.current;
    if (toast) {
      const count = Object.keys(pickBy(selectedState)).length;
      const plural = count !== 1 ? 's' : '';
      toast.showMessage({
        content: `${count} post${plural} were updated`,
        type: 'success',
      });
    }
  };

  // User Callbacks
  const onSelectedChange = (selected: boolean, mentionId: number) => {
    setSelectedState({
      ...selectedState, [mentionId]: selected,
    });
    setLastSelectedState(null);
  };

  const loadMorePosts = () => {
    if (postsLoading) {
      return;
    }

    const newPage = page + 1;
    setPage(newPage);
  };

  const onSelectUserFilterValue = (value) => {
    if (value !== byUserId) {
      // invalidate the cache if changing filters
      resetCache();
    }

    setByUserId(value);
  };

  // eslint-disable-next-line
  const onActivationSelected = (_: any, index: number) => {
    savePosts(index);
  };
  // eslint-disable-next-line
  const onProjectSelected = (_: any, index: number) => {
    savePostsProjects(index);
  };
  const onSelectAllChanged = (selected: boolean) => {
    // Basically how this works: If a post is selected,
    // then allow to deslect all posts using this. If
    // user deselects all posts, they can immediately restore selections.
    // As soon as they select more posts, they can no longer
    // restore previous selection state.
    if (!selected) {
      setLastSelectedState({ ...selectedState });
      setSelectedState({});
    } else if (lastSelectedState) {
      setSelectedState({ ...lastSelectedState });
    }
  };

  // eslint-disable-next-line
  const onCommunitySelected = (_: any, index: number) => {
    savePostsCommunity(index);
  };

  const savePostsCommunity = (communityIndex: number) => {
    setSaving(true);

    const group = communityOptions[communityIndex];
    const params = {
      mention_ids: Object.keys(pickBy(selectedState)),
      community_ids: group.label !== NO_ASSIGNMENT && group.value ? [group.value] : [],
      manual_assignment: true,
    };

    saveSocialData(`${backendServerApiEndpoint}/social_mention`, params, backendServerFetchResponse)
      .then((result) => onSaveSuccess(result, params))
      .catch((error) => {
        setSaveError(error);
      })
      .finally(() => setSaving(false));
  };

  // Save new activation settings
  const savePosts = (activationIndex: number) => {
    const activation = activationOptions[activationIndex];
    const activationName = activation.label;
    const params = {
      mention_ids: Object.keys(pickBy(selectedState)),
      activation_names: activation.value ? [activationName] : [],
      activation_ids: activation.value ? [activation.value] : [],
      manual_assignment: true,
    };

    setSaving(true);

    saveSocialData(`${backendServerApiEndpoint}/social_mention`, params, backendServerFetchResponse)
      .then((result) => onSaveSuccess(result, params))
      .catch((error) => {
        setSaveError(error);
      })
      .finally(() => setSaving(false));
  };
  const savePostsProjects = (projectIndex: number) => {
    setSaving(true);

    const program = projectOptions[projectIndex];
    const programName = program.label;

    const params = {
      mention_ids: Object.keys(pickBy(selectedState)),
      program_names: program.label !== NO_ASSIGNMENT && program.value ? [programName] : [],
      program_ids: program.label !== NO_ASSIGNMENT && program.value ? [program.value] : [],
      manual_assignment: true,
    };

    saveSocialData(`${backendServerApiEndpoint}/social_mention`, params, backendServerFetchResponse)
      .then((result) => onSaveSuccess(result, params))
      .catch((error) => {
        setSaveError(error);
      })
      .finally(() => setSaving(false));
  };

  const unassignPost = (mentionId: number) => {
    setSaving(true);

    const params = {
      mention_ids: [mentionId.toString()],
      program_names: [],
      program_ids: [],
      manual_assignment: true,
    };

    saveSocialData(`${backendServerApiEndpoint}/social_mention`, params, backendServerFetchResponse)
      .then((result) => onSaveSuccess(result, params))
      .catch((error) => {
        setSaveError(error);
      })
      .finally(() => setSaving(false));
  };

  const unassignGroup = (mentionId: number) => {
    setSaving(true);

    const params = {
      mention_ids: [mentionId.toString()],
      community_ids: [],
      manual_assignment: true,
    };
    saveSocialData(`${backendServerApiEndpoint}/social_mention`, params, backendServerFetchResponse)
    .then((result) => onSaveSuccess(result, params))
    .catch((error) => {
      setSaveError(error);
    })
    .finally(() => setSaving(false));
  };

  const onSaveSuccess = (result, params) => {
    // We must update the cache with the results data so that
    // user gets immediate feedback that the activations have been
    // saved
    setSaveResultData(result);
    showSuccessToast();

    // Reset all check marks
    setSelectedState({});
    setLastSelectedState(null);

    for (const assigned_post of result) {
      try {
        addEvent(
          EventName.SocialPostAssigned,
          {
            post_id: assigned_post.post_id,
            post_type: assigned_post.post_type,
            network: assigned_post.network,
            mention_id: assigned_post.mention_id,
            member_id: assigned_post.member_id,
            reach: assigned_post.reach,
            datetime_posted: assigned_post.datetime_posted,
            profile_image_url: assigned_post.profile_image_url,
            social_account_name: assigned_post.social_account_name,
            social_account_link: assigned_post.social_account_link,
            image_url: assigned_post.image_url,
            link: assigned_post.link,
            creator: assigned_post.creator,
            post_text: assigned_post.post_text,
            activation_ids: params.activation_ids,
          },
        );
      } catch (error) {
        logger.error(error);
      }
    }
  };

  const error = (postsError && postsError.error_msg)
  // eslint-disable-next-line
    || (saveError && (saveError as any).error_msg) || activationsError;

  return (
    <div className={styles.SocialAppDashboardPosts}>
      <div className={styles.headerSection}>
        <div className={styles.header}>Posts</div>
        <div className={styles.subHeader}>View and assign your posts. Associated content will share the same assignments as these posts.</div>
        <div className={styles.actionSection}>
          {(isAnyPostSelected() || lastSelectedState) && (
            <Checkbox
              className={styles.checkbox}
              checked={isAnyPostSelected()}
              onChange={onSelectAllChanged}
            />
          )}
          <div className={styles.selectedCount}>
            <div>
              {selectedCount}
              {' '}
              post
              {selectedCount !== 1 ? 's' : ''}
              {' '}
              selected
            </div>
          </div>
          {!isProjects
            && (
            <div className={styles.selector}>
              <Select
                hintText="Assign to Activation"
                selectedIndex={null}
                onChange={onActivationSelected}
                options={activationOptions}
              />
            </div>
)}
          {isProjects
            && (
            <div className={styles.selector}>
              <Select
                hintText="Assign to Project"
                selectedIndex={null}
                onChange={onProjectSelected}
                options={projectOptions}
              />
            </div>
)}
          <div className={styles.selector}>
            <Select
              hintText="Assign to Group"
              selectedIndex={null}
              onChange={onCommunitySelected}
              options={communityOptions}
            />
          </div>
          <div className={styles.selector}>
            <Select
              hintText={projectFilter || 'Projects'}
              selectedIndex={null}
              onChange={onProjectFilterSelected}
              options={[...projectFiterOptions, { value: null, label: 'Unassigned' }]}
              round
            />
          </div>
          <div className={styles.selector}>
            <Select
              hintText={groupFilter || 'Groups'}
              selectedIndex={null}
              onChange={onGroupFilterSelected}
              options={[...groupFiterOptions, { value: null, label: 'Unassigned' }]}
              round
            />
          </div>
          <IconSelect
            // eslint-disable-next-line
            classNames={styles.userFilterSelect as any}
            options={[
              { icon: ' You ', value: userId },
              { icon: ' All ', value: null },
            ]}
            selectedOption={byUserId}
            onChange={onSelectUserFilterValue}
          />
        </div>
      </div>
      <div className={styles.postsSection}>
        {error && <Notice>{error}</Notice>}
        {saving && <OverlaySpinner />}
        {map(filteredPosts, (post) => (
          <AssignPostTile
            onError={() => setIsImageFailed(true)}
            isFailed={isImageFailed}
            memberId={post.member_ids[0]}
            clientId={clientId}
            post={post}
            selected={isSelected(post.mention_id)}
            key={post.mention_id}
            onSelectedChange={(selected) => onSelectedChange(selected, post.mention_id)}
            onUnassignPost={() => unassignPost(post.mention_id)}
            onUnassignGroup={() => unassignGroup(post.mention_id)}
          />
        ))}

        {postsLoading && <LoadSpinner className={styles.postsSpinner} />}
        {!postsLoading && data.length === 0 && (
          <Notice className={styles.noPostsNotice} type="disabled">
            {byUserId === null ? 'You haven\'t received any posts yet. Ensure that your account is connected properly in the Settings tab'
              : 'No posts have been assigned to you. Click on \'All\' to see all posts for your brand'}
          </Notice>
        )}

        {postsData && postsData.length === PAGE_SIZE && !postsLoading && (
          <div className={styles.loadMoreButton}>
            <Button
              label="Load More"
              theme="light"
              onClick={loadMorePosts}
            />
          </div>
        )}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default SocialAppDashboardPosts;
