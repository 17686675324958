import React, { useCallback, useMemo } from 'react';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@frontend/shadcn/components/ui/sheet';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { Tag, Timeline } from '@frontend/app/refresh-components';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Label } from '@frontend/shadcn/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';

import {
  useAssignUnassignTag,
  useCommunitiesQuery,
  useFetchTagsByEntityIdAndType,
  useProgramsQuery,
} from '@frontend/app/hooks';
import { Input } from '@frontend/shadcn/components/ui/input';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { useGetBudgetAccounts } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccounts';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { v4 as uuidv4 } from 'uuid';
import { PaymentStatus } from '@frontend/applications/PaymentsApp/constants';
import { BulkAssignBudgetDataInput, TagAction, TagEntityType } from '@frontend/app/types/globalTypes';
import { useAssignBudgetToBulkPayments } from '@frontend/app/hooks/budgetAllocation/useAssignBudgetToBulkPayments';
import { fetchPaymentById } from '@frontend/applications/PaymentsApp/fetchPaymentById';
import { useAuth } from '@frontend/context/authContext';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { IUpdatePaymentAssignemntRequest, updatePaymentData } from '@frontend/applications/PaymentsApp/savePayment';
import { logger } from '@common';
import { MoneyBillsSimpleIcon, PencilIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  CircleCheckIcon,
  ClockIcon,
  PlusIcon,
  WalletIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { useGetData } from '@frontend/applications/TermsApp/hooks/useGetData';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { LoadSpinner } from '@components';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { cn } from '@frontend/shadcn/lib/utils';
import { getUniqueRefreshColor } from '@frontend/utils';
import { Textarea } from '@frontend/shadcn/components/ui/textarea';
import { Payment } from './PaymentHistoryTab';
import { useToast } from '@/shadcn/hooks/use-toast';
import { PaymentTagDialog } from './PaymentTagDialog';

const { useState, useEffect } = React;
interface EditPaymentsDrawerProps {
  payments: Payment[];
  selectedPaymentIds: number[];
  isEditPaymentDrawerOpen: boolean;
  onCloseDrawer: (isRefetchRequired: boolean) => void;
}

const EditPaymentsDrawer: React.FC<EditPaymentsDrawerProps> = (props) => {
  const { clientInfo } = useAuth();
  const { toast } = useToast();
  const {
    selectedPaymentIds,
    payments,
    isEditPaymentDrawerOpen,
    onCloseDrawer,
  } = props;
  const [singleSelectedPayment, setSingleSelectedPayment] = useState<Payment | null>(null);
  const [editPayments, setEditPayments] = useState({
    isActive: false,
    budgetSource: 'project',
    programId: null,
    groupId: null,
    budgetId: null,
    periodKey: null,
    projectBudgetPeriodKey: null,
    briefId: null,
    memberId: null,
    isLoading: false,
  });
  const [notes, setNotes] = useState('');
  const [showNotes, setShowNotes] = useState(false);
  const [editTags, setEditTags] = useState(false);
  const [bulkReassignBudget] = useAssignBudgetToBulkPayments();
  const { data: clientPrograms, loading: isProgramsLoading } = useProgramsQuery();
  const { loading: isCommunityLoading, data: communityData } = useCommunitiesQuery();
  const [assignUnassignTag] = useAssignUnassignTag();
  const { budgetAccounts, loading: isBudgetsLoading } = useGetBudgetAccounts({
    variables: {},
  });
  const { loading: isLoadingBudgetPeriod, periodDetails } = useGetBudgetPeriodDetails({
    variables: {
      budgetAccountId: editPayments.budgetSource === 'others' ? editPayments?.budgetId : null,
    },
  });

  const { loading: loadingGetTagsByPaymentIds, data: assignedTags, refetch } = useFetchTagsByEntityIdAndType({
    variables: {
      entityType: TagEntityType.PAYMENT,
      entityIds: selectedPaymentIds,
    },
    fetchPolicy: 'no-cache',
  });

  const { loading: isLoadingProjectBudgetPeriod, periodDetails: projectPeriodDetails } = useGetBudgetPeriodDetails({
    variables: {
      programId: editPayments.budgetSource === 'project' ? editPayments?.programId : null,
    },
  });

  const { data: terms = [], loading: isTermsLoading } = useGetData<IAgreement[]>(
    `${backendServerApiEndpoint()}/agreements?member_id=${editPayments?.memberId}&program_id=${
      editPayments?.programId
    }`,
    !(editPayments?.programId || editPayments?.memberId),
  );

  useEffect(() => {
    if (selectedPaymentIds?.length === 1) {
      setSingleSelectedPayment(payments?.find((payment) => payment.id === selectedPaymentIds[0]));
      setNotes(payments?.find((payment) => payment.id === selectedPaymentIds[0])?.note);
      setShowNotes(!!payments?.find((payment) => payment.id === selectedPaymentIds[0])?.note);
    }
  }, [payments, selectedPaymentIds]);

  useEffect(() => {
    if (singleSelectedPayment) {
      setEditPayments((prevState) => ({
        ...prevState,
        groupId: singleSelectedPayment?.communityId || null,
        programId: singleSelectedPayment?.programId || null,
        briefId: singleSelectedPayment?.projectId || null,
        memberId: singleSelectedPayment?.memberId || null,
      }));
    }
  }, [singleSelectedPayment]);

  const briefs = useMemo(() => {
    if (!isTermsLoading && terms) {
      return terms
        .filter((term) => term?.agreed_ts)
        .map((term) => ({
          id: term.project_id,
          amount: term.price,
          date: new Date(term.agreed_ts * 1000).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        }));
    } else {
      return [];
    }
  }, [terms, isTermsLoading]);

  const programs = useMemo(() => {
    if (!isProgramsLoading && clientPrograms?.programs?.length) {
      return clientPrograms.programs
        .map((program) => ({
          id: program.id,
          programName: program.title,
        }))
        .sort((firstProgram, secondProgram) => firstProgram.programName.localeCompare(secondProgram.programName));
    } else {
      return [];
    }
  }, [clientPrograms, isProgramsLoading]);

  const groups = useMemo(() => {
    if (!isCommunityLoading && communityData?.communities?.length) {
      return communityData.communities.map((community) => ({
        id: community.id,
        groupName: community.title,
      }));
    } else {
      return [];
    }
  }, [isCommunityLoading, communityData]);

  const budgets = useMemo(() => {
    if (!isBudgetsLoading && budgetAccounts?.length) {
      return budgetAccounts.map((budget) => ({
        id: budget.id,
        budgetName: budget.name,
      }));
    } else {
      return [];
    }
  }, [isBudgetsLoading, budgetAccounts]);

  const financialPeriods = useMemo(() => {
    if (!isLoadingBudgetPeriod && periodDetails?.length) {
      const fiscalPeriod = [];
      periodDetails?.forEach((period) => {
        fiscalPeriod.push({
          key: uuidv4(),
          id: period.fiscalYearPeriodDefinitionId,
          label: period.fiscalYearLabel,
          granularityLabelForPayload: 'FY',
        });
        if (period.quarterDistributions?.length) {
          period.quarterDistributions.forEach((quarter) => {
            fiscalPeriod.push({
              key: uuidv4(),
              id: quarter.quarterPeriodDefinitionId,
              label: quarter.quarterLabel,
              granularityLabelForPayload: quarter.quarterKey,
            });
          });
        }
      });
      setEditPayments((prevState) => ({
        ...prevState,
        periodKey: null,
      }));
      return fiscalPeriod;
    } else {
      return [];
    }
  }, [isLoadingBudgetPeriod, periodDetails]);

  const financialProjectPeriods = useMemo(() => {
    if (!isLoadingProjectBudgetPeriod && projectPeriodDetails?.length) {
      const fiscalPeriod = [];
      projectPeriodDetails?.forEach((period) => {
        fiscalPeriod.push({
          key: uuidv4(),
          id: period.fiscalYearPeriodDefinitionId,
          label: period.fiscalYearLabel,
          granularityLabelForPayload: 'FY',
        });
        if (period.quarterDistributions?.length) {
          period.quarterDistributions.forEach((quarter) => {
            fiscalPeriod.push({
              key: uuidv4(),
              id: quarter.quarterPeriodDefinitionId,
              label: quarter.quarterLabel,
              granularityLabelForPayload: quarter.quarterKey,
            });
          });
        }
      });
      setEditPayments((prevState) => ({
        ...prevState,
        projectBudgetPeriodKey: null,
      }));
      return fiscalPeriod;
    } else {
      return [];
    }
  }, [isLoadingProjectBudgetPeriod, projectPeriodDetails]);

  const onClickEditToggle = () => {
    setEditPayments((prevState) => ({
      ...prevState,
      isActive: !prevState.isActive,
    }));
  };

  const onChangeEditFields = (fieldName: string, value: string | number) => {
    setEditPayments((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    if (fieldName === 'programId') {
      setEditPayments((prevState) => ({
        ...prevState,
        briefId: null,
        budgetSource: 'project',
        periodKey: null,
        projectBudgetPeriodKey: null,
        budgetId: null,
      }));
    }
  };

  const formatDate = (date) =>
    (date
      ? date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '');

    const { backendServerFetchResponse } = useBackendServerFetch();

    const onSubmit = async () => {
    setEditPayments((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      for (const paymentId of selectedPaymentIds) {
        try {
          const payment = await fetchPaymentById(paymentId, clientInfo.id, backendServerFetchResponse);
          const selectedProgram = programs.find((program) => program.id === editPayments?.programId);
          const selectedGroup = groups.find((group) => group.id === editPayments?.groupId);

          const url = `${backendServerApiEndpoint()}/payment`;
          const updatePayload: IUpdatePaymentAssignemntRequest = {
            program_ids: selectedProgram ? [selectedProgram.id] : payment.program_ids,
            program_names: selectedProgram ? [selectedProgram.programName] : payment.program_names,
            community_ids: selectedGroup ? [selectedGroup.id] : payment.community_ids,
            activation_ids: payment.activation_ids,
            activation_names: payment.activation_names,
            assigned: payment.assigned,
            client_id: payment.client_id,
            note: notes,
          };
          if (singleSelectedPayment) {
            updatePayload.project_id = editPayments?.briefId ? editPayments.briefId : null;
            updatePayload.assign_payment_to_brief = true;
          }
          await updatePaymentData(`${url}/${payment.id}`, updatePayload);
        } catch (err) {
          logger.error({ message: err });
        }
      }
      let selectedPeriod = null;
      if (editPayments.budgetSource === 'others') {
        selectedPeriod = financialPeriods?.find((period) => period.key === editPayments.periodKey);
      } else {
        selectedPeriod = financialProjectPeriods?.find((period) => period.key === editPayments.projectBudgetPeriodKey);
      }
      const splitPayment = [
        {
          budgetAccountId: editPayments?.budgetId,
          fiscalGranularityLabel: selectedPeriod?.granularityLabelForPayload,
          budgetPeriodDefinitionId: selectedPeriod?.id,
        },
      ];
      const bulkAssignBudgetData: BulkAssignBudgetDataInput = {
        paymentIds: selectedPaymentIds,
        source: editPayments?.briefId ? 'Term' : null,
        ...(editPayments.budgetSource === 'others'
          ? {
              accountsPaymentBudgetInput: {
                splitInfo: splitPayment,
              },
            }
          : {
              projectPaymentBudgetInput: {
                programSplitFiscalInfo: {
                  budgetPeriodDefinitionId: selectedPeriod?.id,
                  fiscalGranularityLabel: selectedPeriod?.granularityLabelForPayload.split(' ')[0],
                },
                overflow: [],
              },
            }),
      };
      if (!selectedPeriod) {
        throw new Error('Please select budget and fiscal period');
      }
      await bulkReassignBudget({
        variables: { bulkAssignBudgetData },
        onError(error) {
          logger.error({ message: error });
          throw error;
        },
      });
      toast({
        variant: 'success',
        title: 'Payment Updated',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
    } catch (err) {
      const customMessage = err.message == 'No valid logs to insert' ? 'Please select a project or others budget' : '';
      toast({
        variant: 'error',
        title: customMessage || 'Error updating payment',
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
      logger.error({ message: err });
    } finally {
      setEditPayments((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      onCloseDrawer(true);
    }
  };

  const totalPaymentSum = useMemo(
    () =>
      selectedPaymentIds.reduce((sum, paymentId) => {
        const payment = payments.find((p) => p.id === paymentId);
        return payment ? sum + payment.amountPaid : sum;
      }, 0),
    [selectedPaymentIds, payments],
  );

  const paymentToUpdate = useMemo(() => payments.find((payment) => payment.id === singleSelectedPayment?.id), [
    payments,
    singleSelectedPayment,
  ]);

  const selectedGroup = useMemo(() => groups?.find((group) => group.id === editPayments.groupId), [
    groups,
    editPayments.groupId,
  ]);

  const selectedBrief = useMemo(() => briefs?.find((brief) => brief.id === editPayments.briefId), [
    briefs,
    editPayments.briefId,
  ]);

  const timelineItems = useMemo(() => {
    if (!singleSelectedPayment) {
      return [];
    } else {
      const timeLines = [
        {
          id: 2,
          title: 'Payment Processing',
          description: `${formatDate(singleSelectedPayment?.paymentDate)}`,
          icon: <ClockIcon className="w-4 h-4 bg-white text-grey-3" />,
        },
        {
          id: 1,
          title: singleSelectedPayment?.paymentInitiatedBy
            ? `Payment Initiated by ${singleSelectedPayment?.paymentInitiatedBy}`
            : 'Payment Initiated',
          description: `${formatDate(singleSelectedPayment.paymentDate)}`,
          icon: <WalletIcon className="w-4 h-4 bg-white text-grey-3" />,
        },
      ];

      if (singleSelectedPayment?.paymentTransferDate) {
        timeLines.push({
          id: 3,
          title: 'Payment Sent',
          description: `${formatDate(singleSelectedPayment?.paymentTransferDate)}`,
          icon: <CircleCheckIcon className="w-4 h-4 bg-white text-grey-3" />,
        });
      }
      return timeLines.sort((timelineA, timelineB) => timelineB.id - timelineA.id);
    }
  }, [singleSelectedPayment]);

  const status = useMemo(() => {
    const statusMapping = {
      [PaymentStatus.PENDING]: {
        label: 'Pending Info',
        bgColor: '#D48806',
        textColor: '#FFF',
      },
      [PaymentStatus.PAID]: {
        label: 'Paid',
        bgColor: '#5DB884',
        textColor: '#FFF',
      },
      [PaymentStatus.CANCELED]: {
        label: 'Canceled',
        bgColor: '#CF1322',
        textColor: '#FFF',
      },
      [PaymentStatus.PROCESSING]: {
        label: 'Processing',
        bgColor: '#096DD9',
        textColor: '#FFF',
      },
    };
    return statusMapping[singleSelectedPayment?.paymentStatus];
  }, [singleSelectedPayment?.paymentStatus]);

  const onRemoveTag = useCallback(
    (paymentIds: number, entityId: number) => {
      assignUnassignTag({
        variables: {
          entityType: TagEntityType.PAYMENT,
          entityIds: [entityId],
          tagIds: [paymentIds],
          action: TagAction.UNASSIGN,
        },
      })
        .then(() => {
          toast({
            variant: 'success',
            title: 'Tag Removed',
            duration: 3000,
            className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
          });
          refetch();
        })
        .catch((err) => {
          logger.error({ message: err });
        });
    },
    [assignUnassignTag, toast, refetch],
  );

  const tagList = useMemo(() => {
    const tags = [];
    if (assignedTags?.tags && assignedTags.tags.length) {
      assignedTags?.tags.forEach((tag) => {
        tags.push(
          <Tag
            key={tag.id}
            label={tag.name}
            bgColor={getUniqueRefreshColor(tag.name)}
            isRemovable
            onClick={() => setEditTags(true)}
            onRemove={() => onRemoveTag(tag.id, singleSelectedPayment.id)}
          />,
        );
      });
    }
    tags.push(
      <Tag
        key="action"
        label={(
          <div className="flex items-center gap-1">
            <PlusIcon className="h-3 w-3 font-semibold" />
            <span className="font-semibold text-[12px]">Add Tag</span>
          </div>
        )}
        bgColor="bg-white"
        borderDashed
        onClick={() => setEditTags(true)}
      />,
    );
    return tags;
  }, [assignedTags, onRemoveTag, singleSelectedPayment]);

  const onCloseTagDialog = () => {
    setEditTags(false);
    refetch();
  };
  const topMainContent = !singleSelectedPayment ? (
    <section className="flex justify-between mt-2">
      <div className="flex flex-col flex-1">
        <div className="flex gap-2 text-grey-5 items-center font-semibold">
          <MoneyBillsSimpleIcon className="text-grey-5" />
          Payment
        </div>
        <div className="flex gap-1 font-semibold items-baseline">
          <span className="text-[24px]">$</span>
          <span className="text-[32px]">{totalPaymentSum.toFixed(2)}</span>
          <span className="text-[24px]">USD</span>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex gap-2 text-gray-400 items-center font-semibold"># Number of Payments</div>
        <div className="flex gap-1 font-bold items-end">
          <span className="text-[32px]">{selectedPaymentIds.length}</span>
        </div>
      </div>
    </section>
  ) : (
    <section className="flex flex-col gap-1 mt-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 text-grey-5 items-center font-medium text-sm">
          <MoneyBillsSimpleIcon className="text-grey-5" fontSize={18} />
          Payment
        </div>
        <Badge
          className="rounded-md font-normal"
          style={{ color: status.textColor, backgroundColor: status.bgColor }}
          variant="secondary"
        >
          {status.label}
        </Badge>
      </div>
      <div className="w-full bg-grey-1 rounded-xl p-4">
        <div className="flex gap-1 font-semibold items-baseline">
          <span className="text-[24px]">$</span>
          <span className="text-[32px]">{totalPaymentSum.toFixed(2)}</span>
          <span className="text-[24px]">USD</span>
        </div>
        <div className="flex flex-wrap gap-2 mt-1">{tagList}</div>
      </div>
      <div>
        {notes || showNotes ? (
          <div>
            <Button
              className="p-0"
              variant="link"
              onClick={() => {
                setShowNotes(false);
                setNotes('');
                setEditPayments((prevState) => ({
                  ...prevState,
                  isActive: true,
                }));
              }}
            >
              - Remove Notes
            </Button>
            <Textarea
              value={notes}
              onChange={(e) => {
                setEditPayments((prevState) => ({
                  ...prevState,
                  isActive: true,
                }));
                setNotes(e.target.value);
              }}
              className="w-full"
            />
          </div>
        ) : (
          <Button
            className="p-0"
            onClick={() => {
              setShowNotes(true);
              setEditPayments((prevState) => ({
                ...prevState,
                isActive: true,
              }));
            }}
            variant="link"
          >
            + Add Notes
          </Button>
        )}
      </div>
    </section>
  );
  const memberInfo = singleSelectedPayment ? (
    <section>
      <p className="m-0 text-sm font-semibold">Member Info</p>
      <Separator className="my-2" />
      <div className="flex flex-row justify-between gap-2 max-w-full">
        {[
          { label: 'Member', value: singleSelectedPayment.member },
          { label: 'Email', value: singleSelectedPayment.email },
          { label: 'PayPal Address', value: singleSelectedPayment.paypal },
        ].map(({ label, value }, index) => (
          <div key={index} className="flex flex-col justify-start flex-1 min-w-0">
            <span className="text-grey-4">{label}</span>
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="truncate overflow-hidden whitespace-nowrap">{value}</span>
              </TooltipTrigger>
              <TooltipContent>{value}</TooltipContent>
            </Tooltip>
          </div>
        ))}
      </div>
    </section>
  ) : null;

  const timelineContent = singleSelectedPayment ? (
    <section>
      <p
        className="m-0 text-sm font-semibold"
      >
        Payment Timeline
      </p>
      <Separator className="my-2" />
      <Timeline items={timelineItems} />
    </section>
  ) : null;

  const missingDetailMessage = useMemo(() => {
    if (!editPayments.budgetSource) return '';

    if (!editPayments?.programId && editPayments.budgetSource === 'project') {
      return 'Please select a project';
    }

    if (editPayments.budgetSource === 'project' && editPayments.projectBudgetPeriodKey === null) {
      if (financialProjectPeriods?.length === 0) {
        return 'No fiscal period available for the project, either select a different project or select others';
      }
      return 'Please select a fiscal period for the project';
    }

    if (editPayments.budgetSource === 'others' && (editPayments.periodKey === null || editPayments.budgetId === null)) {
      return 'Please select budget and fiscal period';
    }

    return '';
  }, [
    financialProjectPeriods,
    editPayments,
  ]);
  const isLoading = !selectedPaymentIds.length
    || isProgramsLoading
    || isCommunityLoading
    || isTermsLoading
    || isLoadingBudgetPeriod
    || loadingGetTagsByPaymentIds
    || isBudgetsLoading;
  const loadingContent = isLoading ? (
    <div className="h-full w-full relative">
      <LoadSpinner />
    </div>
  ) : null;
  return (
    <>
      <Sheet open={isEditPaymentDrawerOpen} onOpenChange={() => onCloseDrawer(true)}>
        <SheetContent side="right" className="min-w-[572px] flex flex-col h-full  ">
          <SheetHeader>
            <SheetTitle className="text-[20px] font-medium m-0">
              {!singleSelectedPayment ? 'Bulk Assign Payments' : 'Payment Overview'}
            </SheetTitle>
          </SheetHeader>
          {!isLoading ? (
            <>
              <div className="flex flex-col gap-4 flex-grow overflow-y-auto">
                <Separator />
                {topMainContent}
                {memberInfo}
                {timelineContent}
                <section>
                  <div className="flex justify-between">
                    <p className="m-0 text-sm font-semibold">Connections</p>
                    <div
                      className="flex items-center gap-1 hover:cursor-pointer text-blue-600"
                      onClick={() => onClickEditToggle()}
                    >
                      <PencilIcon className="h-3 w-3" />
                      Edit
                    </div>
                  </div>
                  <Separator className="my-2" />
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between">
                      <span className="text-gray-400">Project</span>
                      {editPayments.isActive ? (
                        <div className="bg-white rounded-lg overflow-hidden">
                          <Select
                            value={editPayments?.programId?.toString()}
                            onValueChange={(value) => onChangeEditFields('programId', parseInt(value, 10))}
                          >
                            <SelectTrigger className="w-[170px] h-8 text-sm">
                              <SelectValue placeholder="Select Project" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {programs.map((program) => (
                                  <SelectItem key={program.id} value={program.id.toString()}>
                                    {program.programName}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      ) : (
                        <span>{paymentToUpdate?.programName}</span>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-400">Group</span>
                      {editPayments.isActive ? (
                        <div className="bg-white rounded-lg overflow-hidden">
                          <Select
                            value={editPayments?.groupId?.toString()}
                            onValueChange={(value) => onChangeEditFields('groupId', parseInt(value, 10))}
                          >
                            <SelectTrigger className="w-[170px] h-8 text-sm">
                              <SelectValue placeholder="Select Group" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {groups.map((group) => (
                                  <SelectItem key={group.id} value={group.id.toString()}>
                                    {group.groupName}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      ) : (
                        <span>{`${selectedGroup ? selectedGroup.groupName : '-'}`}</span>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-400">Brief</span>
                      {editPayments.isActive && singleSelectedPayment ? (
                        <div className="bg-white rounded-lg overflow-hidden">
                          <Select
                            value={editPayments?.briefId?.toString()}
                            onValueChange={(value) => onChangeEditFields('briefId', parseInt(value, 10))}
                          >
                            <SelectTrigger className="w-[170px] h-8 text-sm">
                              <SelectValue placeholder="Select Brief" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {briefs.length > 0 ? (
                                  briefs.map((brief) => (
                                    <SelectItem key={brief.id} value={brief.id.toString()}>
                                      <div className="flex gap-3 justify-between">
                                        <span>{brief.date}</span>
                                        <span className="font-semibold">{`$${brief.amount}`}</span>
                                      </div>
                                    </SelectItem>
                                  ))
                                ) : (
                                  <SelectItem disabled value={null}>
                                    No brief
                                  </SelectItem>
                                )}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      ) : (
                        <span>
                          {`${
                            !singleSelectedPayment
                              ? 'Connected automatically if only one brief is present'
                              : selectedBrief
                              ? selectedBrief.date
                              : '-'
                          }`}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-400">Assigned to Budget</span>
                      {editPayments.isActive ? (
                        <RadioGroup
                          value={editPayments?.budgetSource}
                          onValueChange={(value) => onChangeEditFields('budgetSource', value)}
                          className="flex flex-row space-x-4"
                        >
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="project" id="project" />
                            <Label htmlFor="project">Project’s Budget</Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="others" id="others" />
                            <Label htmlFor="others">Others</Label>
                          </div>
                        </RadioGroup>
                      ) : (
                        <div className="flex items-center gap-1">
                          {!paymentToUpdate?.budgetAccounts?.length && <span>-</span>}
                          {paymentToUpdate?.budgetAccounts.slice(0, 2).map((budget, index) => (
                            <Badge
                              key={index}
                              className="rounded-md font-normal text-xl"
                              style={{ backgroundColor: '#F5F5F5' }}
                              variant="secondary"
                            >
                              {budget}
                            </Badge>
                          ))}
                          {paymentToUpdate?.budgetAccounts.length > 2 && (
                            <Badge
                              className="rounded-md font-normal text-xl"
                              style={{ backgroundColor: '#F5F5F5' }}
                              variant="secondary"
                            >
                              {`+${paymentToUpdate.budgetAccounts.length - 2}`}
                            </Badge>
                          )}
                        </div>
                      )}
                    </div>
                    {editPayments.isActive && (
                      <div className="flex justify-between">
                        <Card className="rounded-md w-full p-3 shadow-none flex flex-col gap-2">
                          <div className="flex justify-between">
                            <span className="text-gray-400">Budget Account</span>
                            {editPayments?.budgetSource === 'project' ? (
                              <div className="bg-white rounded-lg overflow-hidden">
                                <Select disabled>
                                  <SelectTrigger className="w-[170px] h-8 text-sm">
                                    <SelectValue placeholder="Project’s Total Budget" />
                                  </SelectTrigger>
                                </Select>
                              </div>
                            ) : (
                              <div className="bg-white rounded-lg overflow-hidden">
                                <Select
                                  value={editPayments?.budgetId?.toString()}
                                  onValueChange={(value) => onChangeEditFields('budgetId', parseInt(value, 10))}
                                >
                                  <SelectTrigger className="w-[170px] h-8 text-sm">
                                    <SelectValue placeholder="Select Budget" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {budgets.map((budget) => (
                                        <SelectItem key={budget.id} value={budget.id.toString()}>
                                          {budget.budgetName}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            )}
                          </div>
                          <div className="flex justify-between">
                            <span className="text-gray-400">Fiscal Year Period</span>
                            <div className="bg-white rounded-lg overflow-hidden">
                              {editPayments?.budgetSource === 'project' ? (
                                <Select
                                  value={editPayments?.projectBudgetPeriodKey?.toString()}
                                  onValueChange={(value) => onChangeEditFields('projectBudgetPeriodKey', value)}
                                >
                                  <SelectTrigger className="w-[170px] h-8 text-sm">
                                    <SelectValue placeholder="Select Fiscal Period" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {financialProjectPeriods.length > 0 ? (
                                        financialProjectPeriods.map((period) => (
                                          <SelectItem key={period.key} value={period.key}>
                                            {period.label}
                                          </SelectItem>
                                        ))
                                      ) : (
                                        <SelectItem disabled value={null}>
                                          No Period
                                        </SelectItem>
                                      )}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              ) : (
                                <Select
                                  value={editPayments?.periodKey?.toString()}
                                  onValueChange={(value) => onChangeEditFields('periodKey', value)}
                                >
                                  <SelectTrigger className="w-[170px] h-8 text-sm">
                                    <SelectValue placeholder="Select Fiscal Period" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {financialPeriods.length > 0 ? (
                                        financialPeriods.map((period) => (
                                          <SelectItem key={period.key} value={period.key}>
                                            {period.label}
                                          </SelectItem>
                                        ))
                                      ) : (
                                        <SelectItem disabled value={null}>
                                          No Period
                                        </SelectItem>
                                      )}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <span className="text-gray-400">Total Amount</span>
                            <div className="bg-white rounded-lg overflow-hidden">
                              <Input
                                type="number"
                                className="w-[170px]"
                                disabled
                                value={!singleSelectedPayment ? totalPaymentSum : paymentToUpdate?.amountPaid}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                  </div>
                </section>

                {singleSelectedPayment && (
                  <section>
                    <p className="m-0 text-sm font-semibold">Payment Details</p>
                    <Separator className="my-2" />
                    <div className="flex flex-col gap-2">
                      <div className="flex justify-between">
                        <span className="text-gray-400">Amount</span>
                        <span>{`$${singleSelectedPayment.amountPaid} USD`}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Processed By</span>
                        <span>{singleSelectedPayment?.paymentInitiatedBy || '-'}</span>
                      </div>
                      {/* Will enable when data is ready from api */}

                      {/* <div className="flex justify-between">
                      <span className="text-gray-400">Payment Method</span>
                      <span>VISA (2342)</span>
                    </div> */}
                      <div className="flex justify-between">
                        <span className="text-gray-400">Payment ID</span>
                        <span>{singleSelectedPayment.id}</span>
                      </div>
                    </div>
                  </section>
                )}
              </div>

              {editPayments.isActive && (
                <SheetFooter>
                  <div className="w-full flex gap-6 justify-start">
                    <Button
                      variant="primary"
                      onClick={onSubmit}
                      className="rounded-lg px-6"
                      loading={editPayments.isLoading}
                      tooltip={missingDetailMessage}
                      disabled={!!missingDetailMessage}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => onCloseDrawer(false)}
                      className="rounded-lg px-6"
                      disabled={editPayments.isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                </SheetFooter>
              )}
            </>
          ) : (
            loadingContent
          )}
        </SheetContent>
      </Sheet>
      {editTags && (
        <PaymentTagDialog
          isOpen={editTags}
          onClose={onCloseTagDialog}
          selectedPaymentIds={selectedPaymentIds}
          action={TagAction.ASSIGN}
        />
      )}
    </>
  );
};

export default EditPaymentsDrawer;
