import React from 'react';
import { ArrowUpRightFromSquareIcon } from 'lucide-react';
import { HeroSelectionTypeEnum, useLandingPageContext } from '../../../../context/LandingPageContext';

const heroTypes = [
  {
    id: HeroSelectionTypeEnum.TYPE1,
    image: 'https://c.animaapp.com/vPpg9YvB/img/frame-2501@2x.png',
    label: 'Type 1',
  },
  {
    id: HeroSelectionTypeEnum.TYPE2,
    image: 'https://c.animaapp.com/vPpg9YvB/img/frame-2501-1@2x.png',
    label: 'Type 2',
  },
  {
    id: HeroSelectionTypeEnum.TYPE3,
    image: 'https://c.animaapp.com/vPpg9YvB/img/frame-2501-2@2x.png',
    label: 'Type 3',
  },
];

const HeroType: React.FC = () => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();

  // Handle hero section type change
  const handleHeroTypeChange = (type: HeroSelectionTypeEnum) => {
    setLandingPageData((prevData) => ({
      ...prevData,
      heroSectionType: type,
    }));
  };

  // const handleSelect = (id: number) => {
  //   setLandingPageData((prevData) => ({
  //     ...prevData,
  //     heroType: id,
  //   }));
  // };

  return (
    <div className="flex flex-col gap-4 px-4 mt-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-[16px] font-medium text-grey-6 leading-7">
          Hero Section Type
        </h2>
        <p className="text-sm leading-6">
          <span className="text-[#a2adb9]">
            Select a hero section type. You can always change them.
            {' '}
          </span>
          <span className="text-[#16282d] underline cursor-pointer">
            Help Center
          </span>
          <ArrowUpRightFromSquareIcon className="w-4 h-4 inline-block ml-1 text-[#16282d]" />
        </p>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {heroTypes.map((type) => (
          <label
            key={type.id}
            className="flex flex-col gap-2 cursor-pointer"
            onClick={() => handleHeroTypeChange(type.id)}
          >
            <input
              type="radio"
              name="heroType"
              value={type.id}
              className="hidden"
              checked={landingPageData.heroSectionType === type.id}
              onChange={() => handleHeroTypeChange(type.id)}
            />
            <div
              className={`h-[159px] rounded-2xl border ${
                landingPageData.heroSectionType === type.id ? 'border-[#16282d] ring-1 ring-[#16282d]' : 'border-[#e5e8eb]'
              } bg-cover bg-center`}
              style={{ backgroundImage: `url(${type.image})` }}
            />
            <span
              className={`text-center text-xs ${
                landingPageData.heroSectionType === type.id
                  ? 'text-[#16282d] font-medium'
                  : 'text-grey-5'
              }`}
            >
              {type.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default HeroType;
