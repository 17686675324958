import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';

export const SummaryTab = () => (
  <div className="p-4">
    <H3>Analytics Summary</H3>
    <P>
      Get a comprehensive summary of your social media analytics. View key metrics, trends, and insights across all
      platforms and campaigns.
    </P>
  </div>
  );
