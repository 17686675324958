import * as React from 'react';
import { Col, Row, message } from '@revfluence/fresh';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useCommunityByIdQuery } from '@frontend/app/containers/Communities/AddOrEditCommunity/hooks/useCommunityByIdQuery';
import {
  useGetCurrentClient,
  useGetCurrentClientMetadata,
  useGetMemberQuery,
  useGetProfile,
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { logger } from '@common';
import styles from './GroupCreatorDetail.scss';
import ContentHighlight from './ContentHighlight';
import CreatorProfile from './CreatorProfile';
import { formatMember } from '../GcrUtils';
import CreatorTopbar from './CreatorTopbar';
import { fetchSocialDataForMember } from './useFetchSocialDataForMember';
import SocialMetrics from './SocialMetrics';

interface IProps {
  data: string;
}

interface MetaFields {
  instagram?: string;
  tiktok?: string;
  [key: string]: unknown;
}

interface MemberRecord {
  id: number;
  metaFields: MetaFields;
  [key: string]: unknown;
}

export const GroupCreatorDetail: React.FC<IProps> = React.memo(() => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const groupId = params.get('groupId');
  const { client } = useGetCurrentClient();
  const [socialProfileData, setSocialProfile] = useState<Record<string, unknown> | null>(null);
  const { backendServerFetchResponse } = useBackendServerFetch();
  /**
   * socialProfileData = {
   * instagram: [{data: {}}],
   * tiktok: [{data: {}}]
   * }
   */

  const [brandApprovalError, setBrandApprovalError] = useState<boolean>(false);
  const [brandNoteError, setbrandNoteError] = useState<boolean>(false);

  const { profile, refetch: refetchUserProfile } = useGetProfile();
  const { data: { member = null } = {}, refetch: refetchMemberQuery } = useGetMemberQuery(
    Number(params.get('creatorId')),
  );
  // getting selected group
  const { data: { community = null } = {} } = useCommunityByIdQuery(Number(groupId), {
    skip: !groupId,
  });
  const { data: { schemas: memberFieldSchemas = null } = {} } = useMemberFieldSchemasQuery();
  const { clientMetadata: { applicantReviewFields = {} } = {} } = useGetCurrentClientMetadata({
    fetchPolicy: 'cache-first',
  });

  const memberRecord = React.useMemo((): MemberRecord | null => {
    if (!member) return null;
    return formatMember(member, community?.memberFieldSchemas || memberFieldSchemas);
  }, [member, community?.memberFieldSchemas, memberFieldSchemas]);

  const clientId = client?.id;
  const userRole = React.useMemo(() => {
    if (!clientId) return '';
    return profile?.auth0User?.appMetadata?.clients?.[clientId]?.roles?.[0] || '';
  }, [profile, clientId]);

  useEffect(() => {
    if (brandApprovalError) {
      message.error('Brand Approval not configured');
      setBrandApprovalError(false);
    }
    if (brandNoteError) {
      message.error('Brand Note not configured');
      setbrandNoteError(false);
    }
  }, [brandApprovalError, brandNoteError]);

  const updateSocialProfile = useCallback(async () => {
    if (!client?.id || !memberRecord) return;

    const fetchSocialData = async (network: 'instagram' | 'tiktok', username: string) => {
      try {
        const response = await fetchSocialDataForMember({
          url: `${backendServerApiEndpoint()}/social_account`,
          clientId: client.id,
          username: network === 'tiktok' ? `@${username}` : username,
          network,
          include_social_profile_data: true,
          backendServerFetchResponse,
        });
        return response.data;
      } catch (error) {
        logger.error(`Error fetching ${network} data:`, error);
        return null;
      }
    };

    const { instagram, tiktok } = memberRecord.metaFields;
    if (!instagram && !tiktok) {
      setSocialProfile(null);
      return;
    }

    const [instagramData, tiktokData] = await Promise.all([
      instagram ? fetchSocialData('instagram', instagram) : null,
      tiktok ? fetchSocialData('tiktok', tiktok) : null,
    ]);

    setSocialProfile((prev) => ({
      ...(prev || {}),
      ...(instagramData && { instagram: instagramData }),
      ...(tiktokData && { tiktok: tiktokData }),
    }));
  }, [memberRecord, client, backendServerFetchResponse]);

  useEffect(() => {
    if (memberRecord && client) {
      updateSocialProfile();
    }
  }, [client, memberRecord, updateSocialProfile]);

  return (
    memberRecord && ( // {}
      <div className={styles.creatorDetail}>
        {/* head section */}
        <CreatorTopbar
          memberRecord={memberRecord}
          groupId={groupId}
          history={history}
          community={community}
          setBrandApprovalError={setBrandApprovalError}
          refetchMemberQuery={refetchMemberQuery}
          isContentApprover={userRole === 'manager:content_approver'}
        />

        <div className={styles.creatorProfileWrapper}>
          {/* profile detail */}
          <CreatorProfile
            memberRecord={memberRecord}
            socialProfileData={socialProfileData}
            applicantReviewFields={applicantReviewFields}
            memberFieldSchemas={memberFieldSchemas}
          />

          {/* content highlight and social metrics */}
          <div className={styles.contentHighlightSocialMetrics}>
            <Row gutter={16}>
              <Col xl={12} md={24}>
                <ContentHighlight
                  socialProfileData={socialProfileData}
                  memberRecord={memberRecord}
                  refetchMemberQuery={refetchMemberQuery}
                  community={community}
                  setbrandNoteError={setbrandNoteError}
                  userProfile={profile}
                  refetchUserProfile={refetchUserProfile}
                  applicantReviewFields={applicantReviewFields}
                />
              </Col>
              <Col xl={12} md={24} xs={24}>
                <SocialMetrics memberRecord={memberRecord} socialProfileData={socialProfileData} client={client} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  );
});
