/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14ptx_157 {
  display: flex;
}

._justify-content-space-between_14ptx_161 {
  justify-content: space-between;
}

._tabular-nums_14ptx_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_14ptx_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TopNavbarDropdown_14ptx_209 {
  z-index: 10001;
  animation-duration: 0.1s;
}
._TopNavbarDropdown_14ptx_209 .ant-dropdown-menu {
  transform: translateY(-0.5rem);
}

._TopNavbarSubmenu_14ptx_217 {
  padding: 0 !important;
  overflow: hidden;
}
._TopNavbarSubmenu_14ptx_217 ._userMenuContent_14ptx_221 {
  padding: 0.25rem 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}
._TopNavbarSubmenu_14ptx_217 ._userMenuContent_14ptx_221 ._userEmail_14ptx_227 {
  color: #8f8d91;
}
._TopNavbarSubmenu_14ptx_217 ._userMenuContent_14ptx_221 ._switchAccounts_14ptx_230 {
  margin-top: 0.25rem;
  pointer-events: auto;
  cursor: pointer;
  color: #167cf4;
}
._TopNavbarSubmenu_14ptx_217 .px-2 .relative {
  display: flex;
  flex-direction: column;
}

._TopNavbarSubmenuLong_14ptx_241 {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

._TopNavbarSubmenuImage_14ptx_247 {
  margin-right: 8px;
  border-radius: 4px;
}

._TopNavbarSubmenuItemCreate_14ptx_252 {
  height: 48px;
  border-top: 1px solid #ececec;
  color: inherit;
}
._TopNavbarSubmenuItemCreate_14ptx_252:hover {
  color: #167cf4;
}

._TopNavbarSubmenuText_14ptx_261 {
  display: inline-flex;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._UserClientMenu_14ptx_269 {
  max-height: 400px;
  overflow-y: auto;
}
._UserClientMenu_14ptx_269 ._client_14ptx_273 {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.375rem;
  will-change: background-color;
  transition: background-color 0.1s ease-out;
}
._UserClientMenu_14ptx_269 ._client_14ptx_273._active_14ptx_280 {
  pointer-events: none;
  color: #167cf4;
}
._UserClientMenu_14ptx_269 ._client_14ptx_273:hover {
  background-color: #eff5f9;
}

._TopNavbarSubmenuItem_14ptx_252 {
  padding: 0 1rem !important;
  font-size: 0.875rem;
  line-height: 3.25rem;
}
._TopNavbarSubmenuItem_14ptx_252:hover {
  background-color: #f4fcff;
  color: #1890ff;
}
._TopNavbarSubmenuItem_14ptx_252._selected_14ptx_297 {
  background-color: #e6f7ff;
  color: #002766;
}

._searchError_14ptx_302 {
  color: var(--error-color);
  font-size: 12px;
  padding: 0.75rem 1rem;
  margin: 0;
  width: 100%;
}