/* eslint-disable max-len */
import * as React from 'react';

import {
  map, sortBy, size, orderBy, isNil, filter,
} from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

import { message, Modal } from '@revfluence/fresh';
import { CircleExclamationIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { LoadSpinner } from '@components';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { ContentGuidelineScope } from '@frontend/app/types/globalTypes';
import { useState } from '@frontend/applications/TermsApp/components/ContentGuidelines/hooks/useState/useState';
import { ActionTypes } from '@frontend/applications/TermsApp/components/ContentGuidelines/types/actionTypes';
import { TemplateType } from '@frontend/applications/TermsApp/components/ContentGuidelines/types/state';
import {
  useGetAllProjectsQuery,
  useGetContentGuidelineTemplatesForProject,
  useDeleteContentGuidelinesById,
  useDuplicateContentGuidelinesIntoProjectMutation,
  useSaveContentGuidelinesForProjectMutation,
  useQueryParams,
} from '@frontend/app/hooks';

import ContentGuidelinesContent from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesContent/ContentGuidelinesContent';
import ContentGuidelinesEmpty from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesEmpty/ContentGuidelinesEmpty';
import { TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment } from '@frontend/app/queries/types/TermsConfigsQuery';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import { Card } from '@frontend/shadcn/components/ui/card';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';

import omitDeep from 'omit-deep-lodash';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@frontend/shadcn/components/ui/tabs';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import ContentFields from './ContentField';

const {
  useMemo, useEffect, useCallback, useState: ReactUseState,
} = React;

const { confirm } = Modal;

const orderContentGuidelines = (contentGuidelines: Array<TContentGuideline>): Array<TContentGuideline> => orderBy(contentGuidelines, ['priority'], ['asc']);

interface ContentGuidelinesProps {
  clientId: string;
}

const ContentGuidelines = React.memo(
  ({ clientId }: ContentGuidelinesProps): React.ReactElement => {
    const addEvent = useEventContext();
    const query = useQueryParams();
    const { data, loading } = useGetAllProjectsQuery();
    const { state, dispatch } = useState();
    const history = useHistory();
    const [currentPath, setCurrentPath] = ReactUseState(useLocation().pathname);
    const { manageContentFields: isManageContentFields } = useClientFeatures();

    useEffect(() => {
      const projectId = +query.get('projectId');
      if (projectId && isNil(state.projectId)) {
        dispatch({ type: ActionTypes.SelectProject, projectId });
      }
    }, [state.projectId, query, dispatch]);

    const { loading: loadingContentGuidelines, data: guidelinesData } = useGetContentGuidelineTemplatesForProject({
      skip: !state.projectId,
      variables: {
        programId: state.projectId,
      },
      fetchPolicy: 'no-cache',
    });

    const [deleteContentGuidelineById, { loading: deleting, error: deletingError }] = useDeleteContentGuidelinesById();

    const [
      duplicateContentGuidelines,
      { loading: duplicating, error: duplicatingError },
    ] = useDuplicateContentGuidelinesIntoProjectMutation();

    const [
      saveContentGuideline,
      { loading: saving, error: savingError },
    ] = useSaveContentGuidelinesForProjectMutation();

    const handleSelectProject = useCallback(
      (selectedProjectId: number) => {
        if (state.isEditMode) {
          confirm({
            title: 'You have unsaved changes',
            icon: <CircleExclamationIcon />,
            content: 'Are you sure you want to navigate away?',
            onOk: () => dispatch({ type: ActionTypes.SelectProject, projectId: selectedProjectId }),
            onCancel: () => { },
            autoFocusButton: null,
            okText: 'Continue Without Saving',
            cancelText: 'Cancel',
          });
        } else {
          dispatch({ type: ActionTypes.SelectProject, projectId: selectedProjectId });
        }
      },
      [state.isEditMode, dispatch],
    );

    const handleSelectOtherProject = useCallback(
      (selectedOtherProjectId: number) => {
        dispatch({ type: ActionTypes.SelectOtherProject, otherProjectId: selectedOtherProjectId });
      },
      [dispatch],
    );

    useEffect(() => {
      const unListen = history.listen((location) => {
        if (state.isEditMode && location.pathname !== currentPath) {
          history.push(currentPath);
          setCurrentPath(location.pathname);
          confirm({
            title: 'You have unsaved changes',
            icon: <CircleExclamationIcon />,
            content: 'Are you sure you want to navigate away?',
            onOk: () => history.push(location.pathname),
            onCancel: () => setCurrentPath(currentPath),
            autoFocusButton: null,
            okText: 'Continue Without Saving',
            cancelText: 'Cancel',
          });
        }
      });
      return () => {
        unListen();
      };
    }, [history, currentPath, state.isEditMode, setCurrentPath]);

    useEffect(() => {
      if (!loadingContentGuidelines && guidelinesData) {
        const { contentGuidelines, defaultContentGuidelines, globalContentGuidelines } = guidelinesData.guidelines;
        dispatch({
          type: ActionTypes.LoadContentGuidelines,
          contentGuidelines: omitDeep(orderContentGuidelines(contentGuidelines), '__typename'),
          defaultContentGuidelines: omitDeep(orderContentGuidelines(defaultContentGuidelines), '__typename'),
          globalContentGuidelines: omitDeep(orderContentGuidelines(globalContentGuidelines), '__typename'),
        });
      }
    }, [loadingContentGuidelines, guidelinesData, dispatch]);

    useEffect(() => {
      if (deletingError) {
        message.error({
          icon: <TriangleExclamationIcon />,
          className: 'closable',
          content: <>{deletingError.message}</>,
        });
      }
      if (savingError || duplicatingError) {
        message.error({
          icon: <TriangleExclamationIcon />,
          className: 'closable',
          content: <>An error has occurred. Please contact support if this problem persists.</>,
        });
      }
    }, [deletingError, savingError, duplicatingError]);

    const activeProjects = useMemo(() => filter(data?.projects, (project) => project.status === ProjectStatus.Active), [
      data?.projects,
    ]);

    const sortedProjects = useMemo(() => {
      if (!activeProjects) {
        return [];
      }
      return sortBy(activeProjects, [(project) => project?.title?.toLowerCase()]);
    }, [activeProjects]);

    const projects = useMemo(
      () => map(sortedProjects, (project) => ({
        id: project.id,
        title: project.title,
        splashImageUrl: project.splashImageUrl,
      })),
      [sortedProjects],
    );

    const hasProjects = size(projects) > 0;

    const handleDuplicateContentGuideline = useCallback(
      async (contentGuideline: TContentGuideline) => {
        dispatch({
          type: ActionTypes.UpdateShowHideTemplatesModal,
          isTemplatesModalVisible: false,
        });
        const response = await duplicateContentGuidelines({
          variables: {
            guidelinesId: contentGuideline.id,
            programId: state.projectId,
            isCopy: contentGuideline.isCopy,
          },
        });
        addEvent(EventName.ContentGuidelineCreated, {
          Content_Type: response?.data?.guidelines?.type,
          Template_created_using: state.templateType,
        });
        dispatch({
          type: ActionTypes.DuplicateContentGuideline,
          contentGuideline: omitDeep(response.data.guidelines, '__typename'),
        });
      },
      [state.templateType, state.projectId, addEvent, duplicateContentGuidelines, dispatch],
    );

    const handleDeleteContentGuideline = useCallback(
      async (contentGuideline: TContentGuideline) => {
        await deleteContentGuidelineById({
          variables: {
            id: contentGuideline.id,
          },
        });
        dispatch({ type: ActionTypes.DeleteContentGuideline, contentGuideline });
      },
      [dispatch, deleteContentGuidelineById],
    );

    const handleUpdateContentGuidelineInstruction = useCallback(
      (contentGuidelineInstruction: IContentGuidelineInstruction) => dispatch({
        type: ActionTypes.UpdateContentGuidelineInstruction,
        contentGuidelineInstruction,
      }),
      [dispatch],
    );

    const handleSortContentGuideline = useCallback(
      (id: number, sortableGuideline: ISortableGuideline) => dispatch({
        type: ActionTypes.SortContentGuideline,
        id,
        sortableGuideline,
      }),
      [dispatch],
    );

    const handleAddContentGuidelineAttachment = useCallback(
      (attachment: Attachment, id: number) => dispatch({
        type: ActionTypes.AddContentGuidelineAttachment,
        id,
        attachment,
      }),
      [dispatch],
    );

    const handleDeleteContentGuidelineAttachment = useCallback(
      (attachments: Array<Attachment>, id: number) => dispatch({
        type: ActionTypes.DeleteContentGuidelineAttachment,
        id,
        attachments,
      }),
      [dispatch],
    );

    const handleSelectTemplateType = useCallback(
      (templateType: TemplateType) => dispatch({
        type: ActionTypes.SelectTemplateType,
        templateType,
      }),
      [dispatch],
    );

    const handleShowHideTemplatesModal = useCallback(
      (isTemplatesModalVisible: boolean) => dispatch({
        type: ActionTypes.UpdateShowHideTemplatesModal,
        isTemplatesModalVisible,
      }),
      [dispatch],
    );

    const handleSaveContentGuideline = useCallback(
      async (contentGuideline: TContentGuideline) => {
        const response = await saveContentGuideline({
          variables: {
            guidelines: omitDeep({ ...contentGuideline, scope: ContentGuidelineScope.PROGRAM }, '__typename'),
            programId: state.projectId,
          },
        });
        dispatch({
          type: ActionTypes.SaveContentGuideline,
          contentGuideline: omitDeep(response.data.guidelines, '__typename'),
        });
      },
      [state.projectId, dispatch, saveContentGuideline],
    );

    const handleNextStepAction = useCallback(() => {
      dispatch({ type: ActionTypes.NextStep });
    }, [dispatch]);

    const handlePrevStepAction = useCallback(() => {
      dispatch({ type: ActionTypes.PrevStep });
    }, [dispatch]);

    const handleUpdateContentGuidelineLabelAction = useCallback(
      (id: number, label: string) => {
        dispatch({
          type: ActionTypes.UpdateContentGuidelineLabel,
          id,
          label,
        });
      },
      [dispatch],
    );

    const handleUpdateContentGuidelineActiveKeyAction = useCallback(
      (defaultActiveKey: number) => {
        dispatch({
          type: ActionTypes.UpdateContentGuidelineActiveKey,
          defaultActiveKey,
        });
      },
      [dispatch],
    );

    const handleUpdateContentGuidelineTitle = useCallback(
      (contentGuidelineTitle: IContentGuidelineTitle) => dispatch({
        type: ActionTypes.UpdateContentGuidelineTitle,
        contentGuidelineTitle,
      }),
      [dispatch],
    );

    if (loading) {
      return <LoadSpinner />;
    }

    return (
      <div className="!bg-primary-foreground">
        {!loading && (
          <Tabs defaultValue="content-guidelines">
            <TabsList className="bg-transparent !rounded-none justify-start w-full px-6 py-4 border-b border-gray-200 relative h-14">
              <TabsTrigger
                value="content-guidelines"
                className="px-5 py-4 text-base text-gray-500 font-semibold !bg-transparent !shadow-none relative rounded-none data-[state=active]:text-primary transition-colors after:content-[''] after:absolute after:h-[2px] after:bg-primary after:bottom-0 after:left-0 after:right-0 after:scale-x-0 data-[state=active]:after:scale-x-100 after:transition-transform"
              >
                Content Guidelines
              </TabsTrigger>
              {isManageContentFields && (
                <TabsTrigger
                  value="content-fields"
                  className="px-5 py-4 text-base text-gray-500 font-semibold !bg-transparent !shadow-none relative rounded-none data-[state=active]:text-primary transition-colors after:content-[''] after:absolute after:h-[2px] after:bg-primary after:bottom-0 after:left-0 after:right-0 after:scale-x-0 data-[state=active]:after:scale-x-100 after:transition-transform"
                >
                  Content Fields
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value="content-guidelines" className="mt-0">
              <Card className="w-full flex h-full !border-0 !shadow-none max-h-[75vh] overflow-y-auto !rounded-none px-6 py-4">
                {hasProjects && (
                  <ContentGuidelinesContent
                    projects={projects}
                    isContentGuidelinesLoading={loadingContentGuidelines || deleting || duplicating || saving}
                    isEditMode={state.isEditMode}
                    onSelectProject={handleSelectProject}
                    onSelectOtherProject={handleSelectOtherProject}
                    onDuplicateContentGuideline={handleDuplicateContentGuideline}
                    onSaveContentGuideline={handleSaveContentGuideline}
                    onDeleteContentGuideline={handleDeleteContentGuideline}
                    onUpdateContentGuidelineInstruction={handleUpdateContentGuidelineInstruction}
                    onSortContentGuideline={handleSortContentGuideline}
                    onAddContentGuidelineAttachment={handleAddContentGuidelineAttachment}
                    onDeleteContentGuidelineAttachment={handleDeleteContentGuidelineAttachment}
                    onSelectTemplateType={handleSelectTemplateType}
                    onShowHideTemplatesModal={handleShowHideTemplatesModal}
                    onUpdateContentGuidelineActiveKey={handleUpdateContentGuidelineActiveKeyAction}
                    onNextStep={handleNextStepAction}
                    onPrevStep={handlePrevStepAction}
                    onUpdateContentGuidelineLabel={handleUpdateContentGuidelineLabelAction}
                    onUpdateContentGuidelineTitle={handleUpdateContentGuidelineTitle}
                    state={state}
                  />
                )}
                {!hasProjects && <ContentGuidelinesEmpty />}
              </Card>
            </TabsContent>
            <TabsContent value="content-fields" className="mt-0">
              <Card className="w-full flex h-full !border-0 !shadow-none min-h-[100vh] max-h-[75vh] overflow-y-auto !rounded-none px-6 py-4">
                <ContentFields clientId={clientId} />
              </Card>
            </TabsContent>
          </Tabs>
        )}
      </div>
    );
  },
);

ContentGuidelines.displayName = 'ContentGuidelines';

export default ContentGuidelines;
