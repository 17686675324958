import gql from 'graphql-tag';

export const SAVE_LANDING_PAGE_DATA = gql`
  mutation CreateOfferLandingPage($config: LandingPageConfigInput!,$offerId: Int!, $status: String) {
    createOfferLandingPage(config: $config, offerId: $offerId, status: $status) {
      id
      status
    }
  }
`;
