import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { Input } from '@frontend/shadcn/components/ui/input';
import { IShopifyFormElementProps } from '../../../../types';
import { validateNonNegativeDecimal } from '../../../../utils';

interface IProps extends IShopifyFormElementProps<'priceRuleAmount'> {}

export const RefreshOfferCodeDiscountPercentAmount: React.FC<Readonly<IProps>> = React.memo(
  ({ disabled, name }) => {
    const rules = [
      { required: true, message: 'Please input your discount amount!' },
      { validator: validateNonNegativeDecimal },
    ];

    return (
      <div className="relative w-full">
        <span className="absolute text-grey-4 right-3 top-[6px]">%</span>
        <Form.Item name={name} rules={rules} className="!m-0">
          <Input
            disabled={disabled}
            onKeyDown={UseNaturalNumbers}
            type="number"
            max={100}
            className="border-l-0 rounded-l-none pr-8"
          />
        </Form.Item>
      </div>
    );
  },
);
