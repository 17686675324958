import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from '@frontend/shadcn/lib/utils';
import { Button } from '@frontend/shadcn/components/ui/button';

type CarouselProps = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  opts?: any
  /* eslint-disable @typescript-eslint/no-explicit-any */
  plugins?: any[]
  orientation?: 'horizontal' | 'vertical'
  children: React.ReactNode
  className?: string
};

type CarouselItemProps = {
  children: React.ReactNode
  className?: string
};

const Carousel = ({
  opts = {},
  plugins = [],
  orientation = 'horizontal',
  className,
  children,
  ...props
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...opts,
    loop: true,
    axis: orientation === 'horizontal' ? 'x' : 'y',
  }, plugins);
  const isSingleItem = React.Children.count(children) <= 1;
  return (
    <div className={cn('relative', className)} {...props}>
      <div ref={emblaRef} className="overflow-hidden rounded-lg">
        <div className="flex gap-4">
          {children}
        </div>
      </div>
      {!isSingleItem && (
        <>
          <Button
            variant="outline"
            size="icon"
            className="absolute left-4 top-1/2 z-10 h-8 w-8 -translate-y-1/2 rounded-full bg-white"
            onClick={() => emblaApi?.scrollPrev()}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            className="absolute right-4 top-1/2 z-10 h-8 w-8 -translate-y-1/2 rounded-full bg-white"
            onClick={() => emblaApi?.scrollNext()}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </>
      )}
    </div>
  );
};

const CarouselItem = ({ className, children, ...props }: CarouselItemProps) => (
  <div
    className={cn(
        'min-w-0 shrink-0 grow-0 basis-full',
        className,
      )}
    {...props}
  >
    {children}
  </div>
  );

export { Carousel, CarouselItem };
