import React from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuTrigger,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from '@/shadcn/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar';
import { Muted } from '@/shadcn/components/typography/muted';
import { Small } from '@/shadcn/components/typography/small';
import { Button } from '@/shadcn/components/ui/button';
import {
  GMAIL_APP_ID, OUTLOOK_APP_ID, PAYMENT_APP_ID, SHOPIFY_APP_ID,
} from '@/app/constants/applicationIds';

export const UserMenu = ({
  user, currentClient, profile, pfaV2Enabled, onSwitchClient, installedApps, logout, newFeaturesPage,
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="outline" size="icon" className="overflow-hidden rounded-full">
        <Avatar>
          <AvatarImage src={user.picture} alt={user.name} className="object-cover" />
          <AvatarFallback>
            {user?.name
              .split(' ')
              .map((n) => n[0])
              .join()}
          </AvatarFallback>
        </Avatar>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent side="right" align="end">
      <DropdownMenuLabel>
        <Small>{currentClient?.name}</Small>
        <Muted>{profile.email}</Muted>
      </DropdownMenuLabel>
      {profile?.clients?.length > 1 && (
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Switch Accounts</DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              {profile.clients.map((client) => (
                <DropdownMenuItem
                  key={client.id}
                  onSelect={() => {
                    onSwitchClient(client);
                  }}
                >
                  {client.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem asChild>
        <Link className="cursor-pointer text-inherit" to="/settings/account">
          My Account
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <Link className="cursor-pointer text-inherit" to={newFeaturesPage ? '/settings/NewFeatures' : `/settings/${PAYMENT_APP_ID}`}>
          Workspace Settings
        </Link>
      </DropdownMenuItem>
      {pfaV2Enabled && installedApps[SHOPIFY_APP_ID] && (
        <DropdownMenuItem asChild>
          <Link className="cursor-pointer text-inherit" to="/settings/products">
            Product Fulfillment
          </Link>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem asChild>
        <Link
          className="cursor-pointer text-inherit"
          to={installedApps[GMAIL_APP_ID] ? `/settings/${GMAIL_APP_ID}` : `/settings/${OUTLOOK_APP_ID}`}
        >
          Integrations
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <Link className="cursor-pointer text-inherit" to="/settings/conversationPrivacy">
          Organization Settings
        </Link>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        onSelect={() => {
          logout();
        }}
      >
        Log Out
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);
