import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { CircleInfoIcon, EyeIcon } from '@revfluence/fresh-icons/solid/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Tooltip, TooltipProvider, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
  HighlightCardHeaderRow,
} from '../../../components/highlight-cards';
import {
  TooltipHeader,
  TooltipTitle,
  TooltipBody,
  TooltipItem,
  TooltipItemValue,
  TooltipArrow,
  TooltipContent,
} from '../../../components/tooltip';

export const impressionsCardFragment = graphql(`
  fragment ImpressionsCard_TotalInsights on TotalInsights {
    impressions
  }
`);

interface ImpressionsCardProps {
  data: FragmentType<typeof impressionsCardFragment> | null | undefined;
}

const ImpressionsCard: React.FC<ImpressionsCardProps> = ({ data }) => {
  const totalInsights = useFragment(impressionsCardFragment, data);
  const value = totalInsights?.impressions ?? 0;

  return (
    <TooltipProvider>
      <Card>
        <HighlightCardHeader className="bg-purple-6" role="banner">
          <HighlightCardHeaderRow>
            <HighlightCardTitle>
              <EyeIcon className="mr-2 text-[1.5rem]" aria-hidden="true" />
              <span className="text-base">Impressions</span>
            </HighlightCardTitle>
            <HighlightCardTitle>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    type="button"
                    aria-label="View impressions metrics information"
                    data-dd-action-name="view-impressions-info"
                  >
                    <CircleInfoIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </TooltipTrigger>
                <TooltipContent role="tooltip" aria-live="polite">
                  <TooltipHeader>
                    <TooltipTitle>Impressions</TooltipTitle>
                  </TooltipHeader>
                  <TooltipBody>
                    <TooltipItem>
                      <TooltipItemValue>Total views of posts and videos across all networks.</TooltipItemValue>
                    </TooltipItem>
                  </TooltipBody>
                  <TooltipArrow />
                </TooltipContent>
              </Tooltip>
            </HighlightCardTitle>
          </HighlightCardHeaderRow>
        </HighlightCardHeader>
        <HighlightCardContent>
          <HighlightCardContentRow>
            <HighlightCardContentColumn>
              <HighlightCardStatRow>
                <HighlightCardStat
                  className="text-3xl leading-none"
                  role="status"
                  aria-label={`Total impressions count: ${formatNumberDefault(value)}`}
                  data-dd-action-name="impressions-stat"
                >
                  {formatNumberDefault(value)}
                </HighlightCardStat>
              </HighlightCardStatRow>
              <HighlightCardStatLabel>Total Impressions</HighlightCardStatLabel>
            </HighlightCardContentColumn>
          </HighlightCardContentRow>
        </HighlightCardContent>

        {/* Hidden table for screen readers with detailed impressions metrics */}
        <div className="sr-only">
          <table>
            <caption>Impressions Metrics by Content Type</caption>
            <tbody>
              <tr>
                <td>Total views of posts and videos across all networks.</td>
              </tr>
              <tr>
                <th scope="row">Total Impressions</th>
                <td>{formatNumberDefault(value)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </TooltipProvider>
  );
};

export default ImpressionsCard;
