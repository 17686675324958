import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card } from '@frontend/shadcn/components/ui/card';
import { CircleInfoIcon, PhotoFilmIcon } from '@revfluence/fresh-icons/regular/esm';
import { CheckIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  InstagramIcon,
  TiktokIcon,
  YoutubeIcon,
  PinterestIcon,
} from '@revfluence/fresh-icons/brands/esm';
import {
  VideoIcon,
  ImageIcon as CustomWorkIcon,
  PencilIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { SectionCard } from '../components/SectionCard';
import { useCampaign } from '../CampaignContext';
import { ContentPostTypes } from '../CampaignContext';
import { ContentAdvancedDrawer } from '../ContentAdvancedDrawer';

const CONTENT_TYPE_OPTIONS = [
  {
    platform: 'Instagram',
    icon: InstagramIcon,
    types: [
      { name: 'Instagram Reel', value: ContentPostTypes.INSTAGRAM_REEL },
      { name: 'Instagram Story', value: ContentPostTypes.INSTAGRAM_STORY },
      { name: 'Instagram Post', value: ContentPostTypes.INSTAGRAM_POST },
    ],
  },
  {
    platform: 'TikTok',
    icon: TiktokIcon,
    types: [
      { name: 'TikTok Video', value: ContentPostTypes.TIKTOK_VIDEO },
    ],
  },
  {
    platform: 'YouTube',
    icon: YoutubeIcon,
    types: [
      { name: 'YouTube Video', value: ContentPostTypes.YOUTUBE_VIDEO },
      { name: 'YouTube Short', value: ContentPostTypes.YOUTUBE_SHORT },
    ],
  },
  {
    platform: 'Pinterest',
    icon: PinterestIcon,
    types: [
      { name: 'Pinterest Pin', value: ContentPostTypes.PINTEREST_PIN },
    ],
  },
  {
    platform: 'Custom Work',
    types: [
      { name: 'Images', value: ContentPostTypes.IMAGES, icon: CustomWorkIcon },
      { name: 'Videos', value: ContentPostTypes.VIDEOS, icon: VideoIcon },
      { name: 'Custom Work', value: ContentPostTypes.CUSTOM_WORK, icon: PencilIcon },
    ],
  },
];

const getContentTypesSummary = (types: ContentPostTypes[]) => {
  if (!types?.length) return 'No post types selected';
  return CONTENT_TYPE_OPTIONS
    .flatMap((platform) => platform.types)
    .filter((type) => types.includes(type.value))
    .map((type) => type.name)
    .join(', ');
};

export const ContentSection: React.FC = () => {
  const {
    contentPrefPostTypes,
    setContentPostTypes,
  } = useCampaign();

  const [isContentTypesExpanded, setIsContentTypesExpanded] = React.useState(false);
  const [isContentAdvancedOpen, setIsContentAdvancedOpen] = React.useState(false);

  return (
    <SectionCard>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <PhotoFilmIcon />
            <P>Content</P>
          </div>
          <H3 className="font-medium mb-0">What type of content are you looking for?</H3>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <label className="font-medium">Select preferred post types</label>
              <CircleInfoIcon fontSize={12} className="text-primaryMuted" />
            </div>
            <Card className="overflow-hidden">
              <div className="p-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <PhotoFilmIcon />
                    <span>{getContentTypesSummary(contentPrefPostTypes || [])}</span>
                  </div>
                  <Button
                    variant="ghost"
                    className="text-primary"
                    onClick={() => setIsContentTypesExpanded(!isContentTypesExpanded)}
                  >
                    {isContentTypesExpanded ? 'Done' : 'Edit'}
                  </Button>
                </div>
              </div>

              {isContentTypesExpanded && (
                <div className="border-t">
                  <div className="p-4 space-y-4">
                    {CONTENT_TYPE_OPTIONS.map((platform) => (
                      <div key={platform.platform} className="space-y-1">
                        <div className="font-medium text-sm text-muted-foreground">
                          {platform.platform}
                        </div>
                        <div className="space-y-1">
                          {platform.types.map((type) => (
                            <div
                              key={type.value}
                              className={cn(
                                'py-2 px-4 border rounded-lg cursor-pointer hover:border-primary transition-colors flex items-center gap-3',
                                (contentPrefPostTypes || []).includes(type.value) && 'border-primary bg-gray-50',
                              )}
                              onClick={() => {
                                const currentTypes = contentPrefPostTypes || [];
                                if (currentTypes.includes(type.value)) {
                                  setContentPostTypes(currentTypes.filter((t) => t !== type.value));
                                } else {
                                  setContentPostTypes([...currentTypes, type.value]);
                                }
                              }}
                            >
                              <Checkbox checked={contentPrefPostTypes?.includes(type.value)} />
                              <div className="flex items-center gap-2">
                                {platform.platform === 'Custom Work' ? (
                                  <CustomWorkIcon className="h-4 w-4" />
                                ) : (
                                  <platform.icon className="h-4 w-4" />
                                )}
                                <span>{type.name}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t">
                    <Button
                      variant="outline"
                      size="smallIcon"
                      onClick={() => {
                        setContentPostTypes([]);
                        setIsContentTypesExpanded(false);
                      }}
                    >
                      <XmarkIcon />
                    </Button>
                    <Button
                      variant="primary"
                      size="smallIcon"
                      onClick={() => setIsContentTypesExpanded(false)}
                    >
                      <CheckIcon />
                    </Button>
                  </div>
                </div>
              )}
            </Card>
          </div>

          <button
            className="text-primary underline text-left w-fit"
            onClick={() => setIsContentAdvancedOpen(true)}
          >
            Advanced
          </button>
        </div>
      </div>

      {/* Content Advanced Drawer */}
      <ContentAdvancedDrawer
        open={isContentAdvancedOpen}
        onOpenChange={setIsContentAdvancedOpen}
      />
    </SectionCard>
  );
};
