import * as React from 'react';
import {
  capitalize, map,
  first, find,
} from 'lodash';
import { format } from 'date-fns';

import {
 Modal, Button, Typography, Switch, Alert, Input,
} from '@revfluence/fresh';
import {
 CircleExclamationIcon, UserGroupIcon, CirclePlusIcon, ArrowsRotateIcon, TrashIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { CircleExclamationIcon as CircleExclamationFilledIcon } from '@revfluence/fresh-icons/solid/esm';

const { Text } = Typography;

import { logger } from '@common';

import { useMessagingContext } from '@frontend/hooks';
import { MenuList } from '@frontend/app/components';
import { useResourceContext } from '@frontend/app/context';
import { useSyncMessages, IResource } from '@frontend/app/hooks';
import { ResourceType, MessageResourceConfigInput } from '@frontend/app/types/globalTypes';
import { useMakeResourceShared } from '@frontend/app/hooks/useMakeResourceShared';
import { EmailSignature } from './EmailSignature';
import { ConnectModal } from './ConnectModal';

import styles from './AccountsPageNotEmpty.scss';

const { useState, useEffect, useMemo } = React;

interface IProps {
  type: ResourceType;
  activeAccounts: IResource[];
}

/**
 * @type {React.FC}
 */
export const AccountsPageNotEmpty: React.FC<IProps> = React.memo((props) => {
  const { type, activeAccounts } = props;
  const {
    refetch,
    addAccount,
    revokeAccess,
    revoking,
    updateResource,
    updating,
  } = useResourceContext();
  const {
    showInfoMessage,
    showErrorMessage,
  } = useMessagingContext();
  const { syncMessages, loading: syncing } = useSyncMessages();
  const [showModal, setShowModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const { makeResourceShared } = useMakeResourceShared({
    variables: {
      resourceId: selectedAccountId,
    },
  });
  const [displayName, setDisplayName] = useState(null);

  const selectedAccount = useMemo(() => {
    if (selectedAccountId) {
      return find(activeAccounts, (account) => account.id === selectedAccountId);
    }

    return null;
  }, [activeAccounts, selectedAccountId]);

  useEffect(() => {
    if (selectedAccount) {
      setDisplayName(selectedAccount.config.displayName);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!selectedAccount) {
      setSelectedAccountId(first(activeAccounts).id);
    }
  }, [activeAccounts, selectedAccount]);

  const isShared = !!selectedAccount?.authProvider.isShared;
  const isAdmin = !!selectedAccount?.isAdmin;

  const onUpdateResourceConfig = async (config: MessageResourceConfigInput) => {
    const { data: { updated } } = await updateResource({
      variables: {
        resourceId: selectedAccountId,
        data: {
          config,
        },
      },
    });

    if (updated) {
      refetch();
    } else {
      showErrorMessage('There was an error when trying to update the account.');
    }
  };

  const onRevokeAccess = () => {
    Modal.confirm({
      title: 'Remove Account',
      content: `Are you sure you want to remove this ${isShared ? 'shared' : 'individual'} email account? All users on your account will no longer have access.`,
      icon: <CircleExclamationIcon />,
      okText: 'Remove',
      onOk: async () => {
        logger.debug('revoking', selectedAccountId);

        const { data: { revoked } } = await revokeAccess({
          variables: {
            resourceId: selectedAccountId,
          },
        });

        if (revoked) {
          refetch();

          setSelectedAccountId(null);
        } else {
          showErrorMessage('There\'s an error when trying to remove the account.');
        }
      },
    });
  };

  const onSyncMessages = async () => {
    console.log('syncing', selectedAccountId);
    showInfoMessage('Start syncing messages, this might take a while...');
    const { data: { succeeded } } = await syncMessages({
      variables: {
        resourceId: selectedAccountId,
      },
    });

    if (succeeded) {
      showInfoMessage('Messages are successfully synced.');
    } else {
      showErrorMessage('There\'s an error when trying to sync the messages.');
    }
  };

  const onMakeShared = () => {
    Modal.confirm({
      title: 'Are you sure you want make this email address shared?',
      content: 'All users on your account will have read and write access. This is not reversible.',
      icon: <CircleExclamationIcon />,
      onOk: async () => {
        await makeResourceShared();
        await refetch();
      },
    });
  };
  const displayNameLabel = type === ResourceType.OUTLOOK ? 'Outlook does not support custom display names. If you wish to change your display name, visit your Microsoft Outlook account settings.'
    : 'This is the name that will appear as the display name next to your email address for emails sent from Aspire.';
  const renderAccountConfig = () => (
    <div className={styles.content}>
      {isShared && (
        <>
          <Alert message="This is a shared email account" type="info" showIcon />
          <div className={styles.spacer} />
        </>
      )}
      <div className={styles.privacy}>
        <div className={styles.title}>Privacy</div>
        <div className={styles.text}>
          {isShared
            ? 'This email address is shared. All users on your account have access to read and write from this group email address.'
            : 'Only you have access to write from this email. All users on your account have read access.'}
        </div>
        {(isAdmin || !isShared) && (
          <div className={styles.actions}>
            {!selectedAccount.authProvider.systemRevokedAccess && (
              <Button icon={<ArrowsRotateIcon />} onClick={onSyncMessages} disabled={syncing} loading={syncing}>
                Resync
              </Button>
            )}
            {!isShared && (
              <Button icon={<UserGroupIcon />} onClick={onMakeShared}>
                Make Shared
              </Button>
            )}
            <Button danger icon={<TrashIcon />} loading={revoking} disabled={revoking} onClick={onRevokeAccess}>
              Remove Account
            </Button>
          </div>
        )}
      </div>
      <div className={styles.displayName}>
        <div className={styles.title}>Display name</div>
        <Input.Group compact>
          <Input
            className={styles.input}
            defaultValue={selectedAccount.config.displayName}
            value={displayName}
            onChange={(ev) => setDisplayName(ev.target.value)}
            disabled={type === ResourceType.OUTLOOK}
          />
          {type !== ResourceType.OUTLOOK &&
            <Button
              onClick={() => onUpdateResourceConfig({ displayName })}
              disabled={updating || !displayName || (displayName === selectedAccount.config.displayName)}
              type="primary"
            >
              Save
            </Button>}
        </Input.Group>
        <div className={styles.note}>
          {displayNameLabel}
        </div>
      </div>
      {(isAdmin || !isShared) && !selectedAccount.authProvider.systemRevokedAccess && (
        <div className={styles.markAsRead}>
          <div className={styles.title}>Mark as read options</div>
          <div className={styles.toggleWrapper}>
            <Switch
              className={styles.switch}
              checked={selectedAccount.config.markSourceInboxRead}
              loading={updating}
              disabled={updating}
              onChange={(checked: boolean) => onUpdateResourceConfig({ markSourceInboxRead: checked })}
            />
            <Text className={styles.text} ellipsis>
              Automatically mark member messages as read in my
              {' '}
              {capitalize(type)}
              {' '}
              inbox
            </Text>
          </div>
          <div className={styles.note}>
            Only emails sent to or from members that were received after
            <span className={styles.date}>{format(new Date(selectedAccount.createdDate), 'MMM d, yyyy HH:mm')}</span>
            will be automatically marked as read.
          </div>
        </div>
      )}
      <EmailSignature account={selectedAccount} />
    </div>
  );

  const renderReconnect = () => (
    <div className={styles.content}>
      <div className={styles.errorNotice}>
        <Alert
          message="We are having trouble connecting your account"
          description="Please try connecting your account again."
          type="error"
          action={(
            <Button
              type="primary"
              danger
              ghost
              onClick={() => addAccount(type, selectedAccount.authProvider.isShared)}
            >
              <ArrowsRotateIcon />
              {' '}
              Reconnect
            </Button>
          )}
        />
      </div>
      {(isAdmin || !isShared) && (
        <Button
          danger
          icon={<TrashIcon />}
          size="large"
          loading={revoking}
          disabled={revoking}
          onClick={onRevokeAccess}
        >
          Remove Account
        </Button>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.left}>
        <div className={styles.header}>
          My accounts
          <Button
            className={styles.addIcon}
            type="text"
            icon={<CirclePlusIcon />}
            onClick={() => setShowModal(true)}
          />
        </div>
        <MenuList
          className={styles.emailsList}
          items={map(activeAccounts, (acct) => ({
            id: acct.id,
            title: (
              <>
                {acct.authProvider.isShared && <UserGroupIcon className={styles.sharedIcon} />}
                <span className={styles.emailText}>{acct.authProvider.userExternalDisplayId}</span>
              </>
            ),
            icon: acct.authProvider.systemRevokedAccess && (
              <CircleExclamationFilledIcon className={styles.alertIcon} />
            ),
          }))}
          selectedId={selectedAccountId}
          onSelectItem={setSelectedAccountId}
        />
      </div>
      <div className={styles.right}>
        {selectedAccount && (
          selectedAccount.authProvider.systemRevokedAccess ? renderReconnect() : renderAccountConfig()
        )}
      </div>
      <ConnectModal
        type={type}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
});

AccountsPageNotEmpty.displayName = 'AccountsPageNotEmpty';

export default AccountsPageNotEmpty;
