import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@frontend/shadcn/components/ui/table';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { cn } from '@frontend/shadcn/lib/utils';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  onSortingChange?: (sorting: SortingState) => void
  isLoading?: boolean
  columnVisibility?: Record<string, boolean>
  onColumnVisibilityChange?: (visibility: Record<string, boolean>) => void
  enableColumnResizing?: boolean
  enableRowSelection?: boolean
  rowSelection?: Record<string, boolean>
  onRowSelectionChange?: (selection: Record<string, boolean>) => void
  getRowId?: (row: TData) => string
  onRowClick?: (row: TData) => void
  className?: string
  pinnedLeft?: string[]
}

const SELECTOR_WIDTH = '32px';

// Add default column size
const DEFAULT_COLUMN_SIZE = 200;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createSelectionColumn<TData>(): ColumnDef<TData, any> {
  return {
    id: 'select',
    size: parseInt(SELECTOR_WIDTH, 10),
    minSize: parseInt(SELECTOR_WIDTH, 10),
    maxSize: parseInt(SELECTOR_WIDTH, 10),
    enableResizing: false,
  };
}

export function DataTable<TData, TValue>({
  columns,
  data,
  onSortingChange,
  isLoading = false,
  columnVisibility = {},
  onColumnVisibilityChange,
  enableColumnResizing = true,
  enableRowSelection = false,
  rowSelection = {},
  onRowSelectionChange,
  getRowId,
  onRowClick,
  className,
  pinnedLeft = [],
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, _] = useState<ColumnFiltersState>([]);

  const allColumns = useMemo(() => {
    if (!enableRowSelection) return columns;
    return [createSelectionColumn<TData>(), ...columns];
  }, [columns, enableRowSelection]);

  const table = useReactTable({
    data,
    columns: allColumns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    onSortingChange: (updater) => {
      const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
      setSorting(newSorting);
      onSortingChange?.(newSorting);
    },
    onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    enableColumnResizing,
    columnResizeMode: 'onChange',
    enableRowSelection,
    onRowSelectionChange,
    defaultColumn: {
      size: DEFAULT_COLUMN_SIZE,
      minSize: 100,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRowId: getRowId || ((_row: any, index) => index.toString()),
  });

  // Calculate number of skeleton rows to show during loading
  const skeletonRowCount = 5;

  // Add ref for the scroll container
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef(0);
  const isScrollingRef = useRef(false);

  // Save scroll position before re-render with debounce
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      // Set scrolling flag
      isScrollingRef.current = true;

      // Save the scroll position
      scrollPositionRef.current = scrollContainer.scrollLeft;

      // Clear previous timeout
      clearTimeout(scrollTimeout);

      // Reset scrolling flag after scrolling stops
      scrollTimeout = setTimeout(() => {
        isScrollingRef.current = false;
      }, 150);
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  // Restore scroll position after data changes
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer || isScrollingRef.current) return;

    // Use requestAnimationFrame for smooth scroll restoration
    requestAnimationFrame(() => {
      if (scrollContainer && scrollPositionRef.current > 0) {
        scrollContainer.scrollLeft = scrollPositionRef.current;
      }
    });
  }, [data, sorting, columnVisibility]); // Only restore on specific updates

  return (
    <div
      className={cn(
        'rounded-md flex flex-col h-full',
        className
      )}
    >
      <div
        ref={scrollContainerRef}
        className="flex-1 overflow-auto relative"
      >
        <Table className="w-full">
          <TableHeader className="sticky top-0 z-30 bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => {
                  const isPinned = pinnedLeft.includes(header.column.id);
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        width: header.getSize(),
                        minWidth: header.column.columnDef.minSize,
                        maxWidth: header.column.columnDef.maxSize,
                        ...(isPinned && {
                          position: 'sticky',
                          left: header.column.id === 'select' ? 0 : SELECTOR_WIDTH,
                          zIndex: 40,
                          backgroundColor: 'white',
                          borderRight: '1px solid #e5e7eb',
                          top: 0,
                        }),
                      }}
                      className={cn(
                        'relative border-r last:border-r-0 bg-white',
                        isPinned && 'sticky left-0',
                        header.column.id === 'select' && 'cursor-pointer'
                      )}
                      onClick={(e) => {
                        if (header.column.id === 'select') {
                          e.stopPropagation();
                          table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected());
                        }
                      }}
                    >
                      {header.column.id === 'select' ? (
                        <Checkbox
                          checked={table.getIsAllPageRowsSelected()}
                          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                          aria-label="Select all"
                        />
                      ) : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                      )}
                      {enableColumnResizing && header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`absolute right-0 top-0 h-full w-1 cursor-col-resize select-none touch-none bg-slate-200 opacity-0 hover:opacity-100
                            ${header.column.getIsResizing() ? 'bg-slate-400 opacity-100' : ''}`}
                        />
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="bg-white">
            {isLoading ? (
              Array(skeletonRowCount)
                .fill(0)
                .map((_, index) => (
                  <TableRow key={`skeleton-row-${index}`}>
                    {table.getHeaderGroups()[0]?.headers.map((header) => (
                      <TableCell
                        key={`skeleton-cell-${header.id}-${index}`}
                        className="border-r last:border-r-0 p-2"
                        style={{
                          width: header.getSize(),
                          minWidth: header.column.columnDef.minSize,
                          maxWidth: header.column.columnDef.maxSize,
                        }}
                      >
                        {header.column.id === 'select' ? (
                          <Skeleton className="h-4 w-4 rounded" />
                        ) : (
                          <Skeleton className="h-5 w-full rounded" />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={(e) => {
                    // Check if there's any selected text
                    const selection = window.getSelection();
                    if (selection && selection.toString().length > 0) {
                      return; // Don't trigger row click if text is selected
                    }

                    // Check if the click was on a checkbox cell
                    const isCheckboxCell = (e.target as HTMLElement).closest('[data-checkbox-cell="true"]');
                    if (isCheckboxCell) {
                      return; // Don't trigger row click if clicking checkbox
                    }

                    onRowClick?.(row.original);
                  }}
                  className="hover:bg-grey-1"
                >
                  {row.getVisibleCells().map((cell) => {
                    const isPinned = pinnedLeft.includes(cell.column.id);
                    return (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          'border-r last:border-r-0',
                          isPinned && 'sticky bg-white hover:bg-grey-1',
                          cell.column.id === 'select' && 'cursor-pointer'
                        )}
                        data-checkbox-cell={cell.column.id === 'select'}
                        onClick={(e) => {
                          if (cell.column.id === 'select') {
                            e.stopPropagation();
                            row.toggleSelected(!row.getIsSelected());
                          }
                        }}
                        style={{
                          ...(cell.column.id === 'select'
                            ? {
                                width: SELECTOR_WIDTH,
                                minWidth: SELECTOR_WIDTH,
                                maxWidth: SELECTOR_WIDTH,
                              }
                            : {
                                width: cell.column.getSize(),
                                minWidth: cell.column.columnDef.minSize,
                                maxWidth: cell.column.columnDef.maxSize,
                              }),
                          ...(isPinned && {
                            left: cell.column.id === 'select' ? 0 : SELECTOR_WIDTH,
                            zIndex: 20,
                            borderRight: '1px solid #e5e7eb',
                          }),
                        }}
                      >
                        {cell.column.id === 'select' ? (
                          <Checkbox
                            checked={row.getIsSelected()}
                            onCheckedChange={(value) => row.toggleSelected(!!value)}
                            aria-label="Select row"
                          />
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center border-b"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
