import cx from 'classnames';
import * as React from 'react';
import { useMemo, useEffect } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Avatar, Button, Dropdown, Menu,
} from 'antd';
import { chain, isNumber } from 'lodash';
import { Link } from 'react-router-dom';

import { EllipsisLabel } from '@frontend/app/components';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import { TopNavItem } from '../../types/TopNavItem';

import styles from './NavItem.scss';
import dropdownStyles from './TopbarDropdown.scss';

export interface INavItemProps {
  item: TopNavItem;
  onClick: (id: string) => void;
}

export const NavItem: React.FC<INavItemProps> = ({ item, onClick }) => {
  const { icon: IconComponent, text, children } = item;
  const isNavItemProject = item.id === 'projects';
  const {
    searchText,
    inputValue,
    handleSearchChange,
    error,
    checkNoResults,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'projects',
      metadata: {
        source: 'top_nav_bar',
      },
    },
  });

  const menuItems = useMemo(() => {
    let items = chain(children);

    if (isNavItemProject) {
      items = items.filter((child) => {
        if (!searchText) return true;
        return child.text.toLowerCase().includes(searchText.toLowerCase());
      });
      items = items.sortBy((child) => child.text.toLocaleLowerCase());
    }

    return items;
  }, [children, searchText, isNavItemProject]);

  useEffect(() => {
    if (isNavItemProject) {
      checkNoResults(menuItems.value().length > 0);
    }
  }, [isNavItemProject, menuItems, checkNoResults]);

  const menu = useMemo(
    () => (
      <Menu className={cx(dropdownStyles.TopNavbarSubmenu)}>
        {isNavItemProject ? (
          <>
            <div className={cx({ [dropdownStyles.TopNavbarSubmenuLong]: isNavItemProject })}>
              <div className="px-2 py-1">
                <div className="relative w-full">
                  <SearchInput
                    value={inputValue}
                    onChange={handleSearchChange}
                    placeholder="Search projects..."
                    aria-invalid={!!error}
                    aria-errormessage={error ? 'nav-project-search-error' : undefined}
                    isLoading={isSearchLoading}
                  />
                </div>
              </div>
              <div className={styles.divider} />
              {error && (
                <div
                  className={dropdownStyles.searchError}
                  id="nav-project-search-error"
                  role="alert"
                >
                  {error}
                </div>
              )}
              {!error && menuItems
                .map((subItem, index) => (
                  <Menu.Item
                    className={cx(dropdownStyles.TopNavbarSubmenuItem, { [dropdownStyles.selected]: subItem.selected })}
                    key={subItem.id + index}
                  >
                    <Link to={subItem.route}>
                      {subItem.imageUrl && (
                        <Avatar src={subItem.imageUrl} className={dropdownStyles.TopNavbarSubmenuImage} />
                      )}
                      <div className={dropdownStyles.TopNavbarSubmenuText}>
                        <EllipsisLabel align={[20, 0]} textOnlyTitle tooltipPlacement="right">
                          {subItem.node || subItem.text}
                        </EllipsisLabel>
                      </div>
                    </Link>
                  </Menu.Item>
                ))
                .value()}
              <Menu.Item icon={<PlusIcon />} className={dropdownStyles.TopNavbarSubmenuItemCreate}>
                <Link to="/projects/new/templates">Create New Project</Link>
              </Menu.Item>
            </div>
          </>
        ) : (
          <div>
            {menuItems
              .map((subItem, index) => (
                <Menu.Item
                  className={cx(dropdownStyles.TopNavbarSubmenuItem, { [dropdownStyles.selected]: subItem.selected })}
                  key={subItem.id + index}
                >
                  <Link to={subItem.route}>
                    {subItem.imageUrl && (
                      <Avatar src={subItem.imageUrl} className={dropdownStyles.TopNavbarSubmenuImage} />
                    )}
                    <div className={dropdownStyles.TopNavbarSubmenuText}>
                      <EllipsisLabel align={[20, 0]} textOnlyTitle tooltipPlacement="right">
                        {subItem.node || subItem.text}
                      </EllipsisLabel>
                    </div>
                  </Link>
                </Menu.Item>
              ))
              .value()}
          </div>
        )}
      </Menu>
    ),
    [menuItems, inputValue, handleSearchChange, error, isNavItemProject, isSearchLoading],
  );

  if (children || isNavItemProject) {
    return (
      <div className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
        <Dropdown
          overlay={menu}
          overlayClassName={dropdownStyles.TopNavbarDropdown}
          placement="bottomLeft"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.05}
          trigger={['hover']}
        >
          <Button type="text" onClick={() => isNavItemProject && onClick(item.id)}>
            {IconComponent && <IconComponent className={styles.navIcon} />}
            <span>{text}</span>
            <DownOutlined className={styles.arrow} />
          </Button>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
      <Link to={item.route} className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
        {IconComponent && <IconComponent className={styles.navIcon} />}
        <span>{text}</span>
        {isNumber(item.badge) && item.badge !== 0 && <div className={styles.badge}>{item.badge}</div>}
      </Link>
    </div>
  );
};
