import { useEffect, useMemo, useState } from 'react';
import {
  filter, find, first, includes, isEmpty, isUndefined, map, reduce, trim,
} from 'lodash';

import { memberIsPending } from '@affiliates/components';
import {
  IAffiliateLinkMember,
  MemberStatus,
  OfferMemberStatus,
  TMemberTableCSVRow,
  TOfferMember,
  TSelectedAffliate,
} from '@affiliates/components/MemberTable/types';
import { TOfferStats } from '@affiliates/components/OfferDetails/types';
import { useSTAPaymentAppContext } from '@affiliates/contexts';
import {
  IHeader,
  useAdvertiserShopifyScopeCheck,
  useExportToCsv,
  useGetOfferByIdQuery,
} from '@affiliates/hooks';
import {
  GetOfferById,
  GetOfferById_offer_links_affiliateStats,
  GetOfferById_offer_promos_affiliates,
} from '@affiliates/queries/types/GetOfferById';
import { TMember, TMode } from '@affiliates/components/MembersWizard/types';
import { roundToNearestCent } from '@affiliates/utils';
import { EventName, logger } from '@common';
import { IDateRangeSettings } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context';
import { useMapDateRangeToVariables } from '@frontend/app/hooks';
import { GetMultipleShopifyStats, GetMultipleShopifyStats_storeStats } from '@affiliates/queries/types/GetMultipleShopifyStats';
import { useApolloClient } from '@apollo/client';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { message } from 'antd';
import { OFFER_PROMO_CODE_STATUS, OFFER_SOURCE, AFFILIATE_STATUS } from '../types/globalTypes';
import { AFFILIATE_OFFER_LINK_STATUS } from '../constants/offers';
import { GET_MULTIPLE_SHOPIFY_STATS_QUERY, GET_OFFER_AFFILIATES_STATS_MULTIPLE_SHOPIFY, PAUSE_BULK_AFFILIATE_OFFERS_LINK_ASYNC } from '../queries';
import { GetOfferAffliatesStatsMultipleShopify, GetOfferAffliatesStatsMultipleShopify_affiliatesStats } from '../queries/types/GetOfferAffliatesStatsMultipleShopify';
import { useDeleteAffiliateFromAnOffer } from './useDeleteAffiliateFromAnOffer';

export enum OfferDetailsQueriesStatus {
  Failed,
  Loading,
  Ready,
}

interface IOfferDetailsQueriesLoading {
  status: OfferDetailsQueriesStatus.Loading;
}

interface IOfferDetailsQueriesFailed {
  error: Error;
  status: OfferDetailsQueriesStatus.Failed;
}

interface IOfferDetailsQueriesReady {
  failedPromoCodeErrors: OFFER_PROMO_CODE_STATUS[];
  failedMembers: {
    members: readonly TMember[];
    dismissFailedMembers: () => void;
    showFailedMembers: () => void;
    visible: boolean;
  };
  missingShopifyCredentials: boolean | null;
  offerDetails: GetOfferById['offer'];
  offerMembers: readonly TOfferMember[];
  deletedMembers: readonly TOfferMember[];
  offerStats: TOfferStats;
  multipleShopifyOfferStats: GetMultipleShopifyStats_storeStats[];
  memberTable: {
    buttonActions: {
      addMembers: () => void;
      export: (columns: IHeader[], data: readonly TMemberTableCSVRow[]) => void;
      pause: () => void;
      resume: (refreshDates: boolean) => void;
    };
    disableCompose: boolean;
    onSelectMembers: (memberIds: number[]) => void;
    selectedAffiliateIds: number[];
  };
  refresh: () => void;
  status: OfferDetailsQueriesStatus.Ready;
  updateMembers: {
    mode: TMode;
    onClose: () => void;
    refreshDatesOnly: boolean;
    selectedMembers: readonly TMember[];
    visible: boolean;
  };
  memberConversion: {
    visible: boolean;
    selectedMemberConversion: TSelectedAffliate;
    onClose: () => void;
  };
  updateManageOffer?: {
    mode: TMode;
    onClose: () => void;
    visible: boolean;
    selectedMembers: readonly TMember[];
    setIsVisibleSelectOffer: (isVisibleManageOffer: boolean) => void;
  };
  multipleShopifyAffiliateStats: {
    visible: boolean;
    affiliatesStats: GetOfferAffliatesStatsMultipleShopify_affiliatesStats[];
    toggle: () => void;
  };
  shopifyStoreName: string;
}

type TOfferDetailsQueries = Readonly<
  IOfferDetailsQueriesFailed | IOfferDetailsQueriesLoading | IOfferDetailsQueriesReady
>;
const NULL_DATA = {
  offerStats: {
    avgSale: 0,
    clicks: 0,
    conversionRatio: 0,
    conversions: 0,
    payout: 0,
    payoutEarned: 0,
    payoutMade: 0,
    sales: 0,
  },
  offerMembers: [],
  deletedMembers: [],
};

export const getStatusForAffiliateLinkMember = (member: GetOfferById_offer_links_affiliateStats): OfferMemberStatus => {
  const status = trim(member.linkStatus).toUpperCase();
  switch (status) {
    case AFFILIATE_OFFER_LINK_STATUS.ACTIVE:
      return OfferMemberStatus.ACTIVE;
    case AFFILIATE_OFFER_LINK_STATUS.DISABLED:
      return OfferMemberStatus.PAUSED;
    case AFFILIATE_OFFER_LINK_STATUS.PENDING:
      return OfferMemberStatus.PENDING;
    case AFFILIATE_OFFER_LINK_STATUS.PENDING_UNPAUSE:
      return OfferMemberStatus.PENDING_UPDATE;
    case AFFILIATE_OFFER_LINK_STATUS.PENDING_PAUSE:
      return OfferMemberStatus.PENDING_DELETION;
    case AFFILIATE_OFFER_LINK_STATUS.FAILED_UPDATE:
      return OfferMemberStatus.FAILED_UPDATE;
    case AFFILIATE_OFFER_LINK_STATUS.DELETING:
      return OfferMemberStatus.DELETING;
  }
  return OfferMemberStatus.UNKNOWN;
};

export const getStatusForPromoCodeMember = (member: GetOfferById_offer_promos_affiliates): OfferMemberStatus => {
  switch (member.status) {
    case OFFER_PROMO_CODE_STATUS.PENDING:
      return OfferMemberStatus.PENDING;
    case OFFER_PROMO_CODE_STATUS.PENDING_DELETION:
      return OfferMemberStatus.PENDING_DELETION;
    case OFFER_PROMO_CODE_STATUS.PENDING_UPDATE:
      return OfferMemberStatus.PENDING_UPDATE;
    case OFFER_PROMO_CODE_STATUS.DELETED:
      return OfferMemberStatus.DEACTIVATED;
    case OFFER_PROMO_CODE_STATUS.FAILED_DUPLICATE:
    case OFFER_PROMO_CODE_STATUS.FAILED_OTHER:
    case OFFER_PROMO_CODE_STATUS.FAILED_TOO_LONG:
    case OFFER_PROMO_CODE_STATUS.FAILED_DELETION:
      return OfferMemberStatus.FAILED;
    case OFFER_PROMO_CODE_STATUS.EXPIRED:
      return OfferMemberStatus.EXPIRED;
    case OFFER_PROMO_CODE_STATUS.SUCCESS:
      return OfferMemberStatus.ACTIVE;
  }
  // should not reach here but just in case we receive something else from the
  // server, we should return *something*
  return OfferMemberStatus.UNKNOWN;
};

export const getMemberStatus = (memberStatus: AFFILIATE_STATUS): MemberStatus => {
  switch (memberStatus) {
    case AFFILIATE_STATUS.ACTIVE:
      return MemberStatus.ACTIVE;
    case AFFILIATE_STATUS.DELETED:
      return MemberStatus.DELETED;
  }
  // should not reach here but just in case we receive something else from the
  // server, we should return *something*
  return MemberStatus.ACTIVE;
};

export const useOfferDetailsQueries = (
         offerId: number,
         dateRangeSettings: IDateRangeSettings,
         isEnabledMultipleShopify: boolean,
       ): TOfferDetailsQueries => {
         const [multipleOfferStats, setMultipleOfferStats] = useState<GetMultipleShopifyStats_storeStats[]>(null);
         const [affiliatesStats, setAffiliatesStats] = useState<
           GetOfferAffliatesStatsMultipleShopify_affiliatesStats[]
         >(null);
         const {
           dateFilter,
           setOffers,
           setPaymentsDue,
           setRefreshCurrentDashboard,
           setRefreshCurrentTable,
         } = useSTAPaymentAppContext();
         const paymentsDateRangeVariables = useMapDateRangeToVariables(dateFilter.dateRange);
         const dateRangeVariables = useMapDateRangeToVariables(dateRangeSettings.dateRange);
         const offerQuery = useGetOfferByIdQuery({
           fetchPolicy: 'network-only',
           variables: {
             id: offerId,
             ...dateRangeVariables,
             status: null,
           },
           skip: !offerId,
         });
         const client = useApolloClient();
         const { setDisableOfferEdit } = useOfferDetailsContext();
         useEffect(() => {
           if (isEnabledMultipleShopify && offerQuery.data?.offer?.promos?.[0]?.connectedClientMetadata?.length) {
             client
               .query<GetMultipleShopifyStats>({
                 query: GET_MULTIPLE_SHOPIFY_STATS_QUERY,
                 variables: {
                   offerId,
                   ...dateRangeVariables,
                 },
                 fetchPolicy: 'network-only',
               })
               .then((multipleShopifyStatsData) => {
                 setMultipleOfferStats(multipleShopifyStatsData?.data?.storeStats);
               })
               .catch((e) => {
                 console.log(e);
               });

             client
               .query<GetOfferAffliatesStatsMultipleShopify>({
                 query: GET_OFFER_AFFILIATES_STATS_MULTIPLE_SHOPIFY,
                 variables: {
                   offerId,
                   ...dateRangeVariables,
                 },
                 fetchPolicy: 'network-only',
               })
               .then((multipleShopifyStatsData) => {
                 setAffiliatesStats(multipleShopifyStatsData?.data?.affiliatesStats);
               })
               .catch((e) => {
                 logger.error(e);
               });
           }
         }, [client, dateRangeVariables, offerId, isEnabledMultipleShopify, offerQuery]);
         const paymentStatsQuery = useGetOfferByIdQuery({
           fetchPolicy: 'cache-and-network',
           variables: {
             id: offerId,
             ...paymentsDateRangeVariables,
           },
         });
         const advertiserShopifyScopeCheck = useAdvertiserShopifyScopeCheck({
           skip: offerQuery.loading || !!offerQuery.error || isEmpty(offerQuery.data?.offer?.promos),
         });
         const missingShopifyCredentials = useMemo(() => {
           if (!advertiserShopifyScopeCheck.loading && !advertiserShopifyScopeCheck.error) {
             return !advertiserShopifyScopeCheck.data?.advertiser?.hasShopifyScopes;
           }
           return null;
         }, [advertiserShopifyScopeCheck]);

  useEffect(() => {
    const newRefreshCurrentDashboard = () => {
      offerQuery.refetch({
        id: offerId,
        ...dateRangeVariables,
      });
      paymentStatsQuery.refetch({
        id: offerId,
        ...paymentsDateRangeVariables,
      });
    };
    setRefreshCurrentDashboard(() => newRefreshCurrentDashboard);
    setRefreshCurrentTable(() => newRefreshCurrentDashboard);
  }, [
    dateRangeVariables,
    offerId,
    offerQuery,
    paymentsDateRangeVariables,
    paymentStatsQuery,
    setRefreshCurrentDashboard,
    setRefreshCurrentTable,
  ]);
  useEffect(() => {
    if (paymentStatsQuery.loading || isEmpty(paymentStatsQuery.data)) {
      setPaymentsDue({ state: 'loading' });
      return;
    }
    if (paymentStatsQuery.error) {
      setPaymentsDue({
        errorMessage: paymentStatsQuery.error.message,
        state: 'failed',
      });
      return;
    }
    const { offer: { links, promos, isPromoLink } } = paymentStatsQuery.data;
    // check for offer.isPromoLink
    if (isPromoLink) {
      const { stats: promoStats } = first(promos);
      const { stats: linkStats } = first(links);
      setPaymentsDue({
        paymentsDue: Math.max(0, roundToNearestCent(
          (promoStats.payoutEarned - promoStats.payoutMade) + (linkStats.payoutEarned - linkStats.payoutMade),
        )),
        state: 'ready',
      });
    } else if (!isEmpty(promos)) {
      const { stats } = first(promos);
      setPaymentsDue({
        paymentsDue: Math.max(0, roundToNearestCent(
          stats.payoutEarned - stats.payoutMade,
        )),
        state: 'ready',
      });
    } else if (!isEmpty(links)) {
      const { stats } = first(links);
      setPaymentsDue({
        paymentsDue: Math.max(0, roundToNearestCent(
          stats.payoutEarned - stats.payoutMade,
        )),
        state: 'ready',
      });
    }
  }, [paymentStatsQuery, offerId, setPaymentsDue]);
  useEffect(() => {
    setDisableOfferEdit(true);
    const offer = offerQuery.data?.offer;
    if (!isEmpty(offer)) {
      const contextOffer = {
        id: offer.id,
        name: offer.name,
        source: isEmpty(offer.links) ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE,
      };
      setOffers([contextOffer]);
    }
  }, [offerQuery.data, setOffers, setDisableOfferEdit]);

  let source = OFFER_SOURCE.SHOPIFY;
  if (!offerQuery.loading && !offerQuery.error && offerQuery.data.offer) {
    if (isEmpty(offerQuery.data.offer.promos) && !isEmpty(offerQuery.data.offer.links)) {
      source = OFFER_SOURCE.TUNE;
    }
  }

         const [selectedMemberIds, updatedSelectedMemberIds] = useState<number[]>([]);
         const exportToCsv = useExportToCsv();
         // update members wizard settings
         const [updateWizardMode, setUpdateWizardMode] = useState<TMode>('add');
         const [updateWizardVisible, setUpdateWizardVisible] = useState(false);
         const [isVisibleManageOffer, setIsVisibleSelectOffer] = useState(false);
         const [isVisibleMemberConversion, setIsVisibleMemberConversion] = useState(false);
         const [selectedMemberConversion, setSelectedMemberConversion] = useState<TSelectedAffliate | null>(null);
         const [refreshDatesOnly, setRefreshDatesOnly] = useState(false);
         const [showStoreLevelAffiliateStats, setShowStoreLevelAffiliateStats] = useState(false);
         const [deleteMemberFromAnOffer] = useDeleteAffiliateFromAnOffer();

         const addEvent = useEventContext();
         const buttonActions = useMemo(
           () => ({
             addMembers: () => {
               switch (source) {
                 case OFFER_SOURCE.TUNE:
                   setUpdateWizardMode('add');
                   setUpdateWizardVisible(true);
                   break;
                 case OFFER_SOURCE.SHOPIFY:
                   setRefreshDatesOnly(false);
                   setUpdateWizardMode('add');
                   setUpdateWizardVisible(true);
                   break;
               }
             },
             export: (columns, data) => {
               addEvent(EventName.SalesTrackingTableExport, {
                 exportDate: new Date().toISOString(),
                 exportedTable: 'members',
               });
               exportToCsv(`affiliates-${Date.now()}`, columns, data);
             },
             pause: () => {
               switch (source) {
                 case OFFER_SOURCE.TUNE:
                   setUpdateWizardMode('deactivate');
                   setUpdateWizardVisible(true);
                   break;
                 case OFFER_SOURCE.SHOPIFY:
                   setRefreshDatesOnly(false);
                   setUpdateWizardMode('deactivate');
                   setUpdateWizardVisible(true);
                   break;
               }
             },
             resume: (refreshDates: boolean) => {
               switch (source) {
                 case OFFER_SOURCE.TUNE:
                   setUpdateWizardMode('refresh');
                   setUpdateWizardVisible(true);
                   break;
                 case OFFER_SOURCE.SHOPIFY:
                   setRefreshDatesOnly(refreshDates);
                   setUpdateWizardMode('refresh');
                   setUpdateWizardVisible(true);
                   break;
               }
             },
             refresh: () => {
               setIsVisibleSelectOffer(true);
             },
             openMemberConversionDrawer: (selectedAffliate: TSelectedAffliate) => {
               setIsVisibleMemberConversion(true);
               setSelectedMemberConversion(selectedAffliate);
             },
             storeLevelAffiliateStats: () => {
               setShowStoreLevelAffiliateStats(!showStoreLevelAffiliateStats);
             },
             deleteAffiliatesFromAnOffer: async (selectedMemberIds: number[], offerId: number, offerSource: string, setIsDeletedAction: (isDeletedAction: boolean) => void) => {
              try {
                if (offerSource === 'TUNE') {
                    await client.mutate({
                    mutation: PAUSE_BULK_AFFILIATE_OFFERS_LINK_ASYNC,
                    variables: {
                      ids: selectedMemberIds,
                      removeAffiliates: true,
                    },
                    }).catch((e) => {
                      setIsDeletedAction(false);
                      if (e.graphQLErrors && e.graphQLErrors[0]) {
                        message.error(e.graphQLErrors[0].message);
                      } else if (e.networkError) {
                        message.error(`Network error: ${e.networkError.message}`);
                      } else {
                        message.error('An unexpected error occurred');
                      }
                    });
                } else if (offerSource === 'SHOPIFY') {
                  await deleteMemberFromAnOffer({
                    variables: {
                      id: offerId,
                      affiliates: selectedMemberIds.map((e) => ({ id: e })),
                      removeAffiliates: true,
                    },
                    onError: (e) => {
                     setIsDeletedAction(false);
                     if (e.graphQLErrors && e.graphQLErrors[0]) {
                       message.error(e.graphQLErrors[0].message);
                     } else if (e.networkError) {
                       message.error(`Network error: ${e.networkError.message}`);
                     } else {
                       message.error('An unexpected error occurred');
                     }
                   },
                  });
                }
              } catch (e) {
                setIsDeletedAction(false);
              }
             },
           }),
           [showStoreLevelAffiliateStats, addEvent, exportToCsv, source, deleteMemberFromAnOffer, client],
         );

         const { offerMembers, offerStats, deletedMembers } = useMemo((): {
           offerMembers: IOfferDetailsQueriesReady['offerMembers'];
           offerStats: IOfferDetailsQueriesReady['offerStats'];
           deletedMembers: IOfferDetailsQueriesReady['deletedMembers'];
         } => {
           if (offerQuery.loading || offerQuery.error || !offerQuery.data?.offer) {
             return NULL_DATA;
           }

    switch (source) {
             case OFFER_SOURCE.SHOPIFY:
               if (isEmpty(offerQuery.data.offer.promos)) {
                 return NULL_DATA;
               }
               const promo = first(offerQuery.data.offer.promos);
               const { offer } = offerQuery.data;
        return {
          deletedMembers: map(promo.affiliateStats, (member) => {
            const promoMember = find(promo.affiliates, (affiliate) => affiliate.affiliateId === member.affiliateId);
            if (!isUndefined(promoMember) && promoMember.deletedDate !== null) {
              return {
                affiliateId: member.affiliateId,
                affiliateOfferId: promoMember.id,
                avgSale: member.avgSale,
                code: member.affiliateCode,
                payoutId: promoMember.offerPayoutId,
                codeEndDate: promoMember.endDate,
                codeStartDate: promoMember.startDate,
                conversions: member.conversions,
                email: member.affiliateEmail,
                imageUrl: member.affiliateImageUrl,
                memberId: member.aspirexMemberId,
                name: member.affiliateName,
                payoutEarned: member.payoutEarned,
                payoutMade: member.payoutMade,
                sales: member.sales,
                salesBase: member.salesBase,
                avgSaleBase: member.avgSaleBase,
                payoutEarnedBase: member.payoutEarnedBase,
                currencies: promo.stats.baseCurrencies,
                source: OFFER_SOURCE.SHOPIFY,
                status: promoMember ? getStatusForPromoCodeMember(promoMember) : OfferMemberStatus.UNKNOWN,
                memberStatus: promoMember ? getMemberStatus(promoMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
                promoCodeStatus: promoMember.status,
                externalDiscountCodeGid: promoMember.externalDiscountCodeGId,
                providerMetadata: promoMember.providerMetadata,
                createdDate: promoMember.createdDate,
                deletedDate: promoMember.deletedDate,
              };
            }
          }),
          offerMembers: map(promo.affiliateStats, (member) => {
            const promoMember = find(promo.affiliates, (affiliate) => affiliate.affiliateId === member.affiliateId);
            // if isPromoLink
            const linkMemberStat = offer.isPromoLink
              ? find(
                  first(offer.links).affiliateStats || [],
                  (linkAffiliate) => linkAffiliate.affiliateId === member.affiliateId,
                )
              : null;
            if (!isUndefined(promoMember) && promoMember.deletedDate === null) {
              return {
                affiliateId: member.affiliateId,
                affiliateOfferId: promoMember.id,
                avgSale: member.avgSale,
                code: member.affiliateCode,
                payoutId: promoMember.offerPayoutId,
                codeEndDate: promoMember.endDate,
                codeStartDate: promoMember.startDate,
                conversions: member.conversions,
                email: member.affiliateEmail,
                imageUrl: member.affiliateImageUrl,
                memberId: member.aspirexMemberId,
                name: member.affiliateName,
                payoutEarned: member.payoutEarned,
                payoutMade: member.payoutMade,
                sales: member.sales,
                salesBase: member.salesBase,
                avgSaleBase: member.avgSaleBase,
                payoutEarnedBase: member.payoutEarnedBase,
                currencies: promo.stats.baseCurrencies,
                source: OFFER_SOURCE.SHOPIFY,
                status: promoMember ? getStatusForPromoCodeMember(promoMember) : OfferMemberStatus.UNKNOWN,
                memberStatus: promoMember ? getMemberStatus(promoMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
                promoCodeStatus: promoMember.status,
                externalDiscountCodeGid: promoMember.externalDiscountCodeGId,
                providerMetadata: promoMember.providerMetadata,
                createdDate: promoMember.createdDate,
                deletedDate: promoMember.deletedDate,
                ...(offer.isPromoLink
                  ? {
                      affiliateShortLink:
                        find(
                          first(offer.links)?.affiliates || [],
                          (affiliate) => affiliate.affiliateId === member.affiliateId,
                        )?.affiliateShortLink,
                        affiliateLink:
                        find(
                          first(offer.links)?.affiliateStats || [],
                          (affiliate) => affiliate.affiliateId === member.affiliateId,
                        )?.affiliateLink,
                      clicks:
                        find(
                          first(offer.links)?.affiliateStats || [],
                          (affiliate) => affiliate.affiliateId === member.affiliateId,
                        )?.clicks,
                        conversions: member.conversions + linkMemberStat?.nonDuplicateConversions || 0,
                        payoutEarned: member.payoutEarned + linkMemberStat?.payoutEarned,
                        payoutMade: member.payoutMade + linkMemberStat?.payoutMade,
                        sales: member.sales + linkMemberStat?.sales,
                        avgSale: (member.sales + linkMemberStat?.sales) / (member.conversions + linkMemberStat?.nonDuplicateConversions),
                    }
                  : {}),
              };
            }
          }),
          offerStats:
            isEmpty(offer.links) || !offer.isPromoLink
              ? {
                  avgSale: promo.stats.avgSale,
                  conversions: promo.stats.conversions,
                  payoutEarned: promo.stats.payoutEarned,
                  payoutMade: promo.stats.payoutMade,
                  sales: promo.stats.sales,
                  avgSaleBase: promo.stats.avgSaleBase,
                  salesBase: promo.stats.salesBase,
                  payoutEarnedBase: promo.stats.payoutEarnedBase,
                  currencies: promo.stats.baseCurrencies,
                  clicks: undefined,
                }
              : {
                  clicks: first(offer.links)?.stats?.clicks || 0,
                  conversions: promo.stats.conversions + (first(offer.links)?.stats?.nonDuplicateConversions || 0),
                  payoutEarned: promo.stats.payoutEarned + first(offer.links)?.stats?.payoutEarned || 0,
                  payoutMade: promo.stats.payoutMade + first(offer.links)?.stats?.payoutMade || 0,
                  sales: promo.stats.sales + first(offer.links)?.stats?.sales || 0,
                  avgSale: promo.stats.conversions + (first(offer.links)?.stats?.nonDuplicateConversions || 0) ? (promo.stats.sales + first(offer.links)?.stats?.sales || 0)
                    / (promo.stats.conversions + (first(offer.links)?.stats?.nonDuplicateConversions || 0)) : 0,
                  avgSaleBase: promo.stats.avgSaleBase,
                  salesBase: promo.stats.salesBase,
                  payoutEarnedBase: promo.stats.payoutEarnedBase,
                  currencies: promo.stats.baseCurrencies,
                },
        };
             case OFFER_SOURCE.TUNE:
               if (isEmpty(offerQuery.data.offer.links)) {
                 return NULL_DATA;
               }
               const link = first(offerQuery.data.offer.links);
               return {
                 deletedMembers: map(link.affiliateStats, (member): IAffiliateLinkMember => {
                  const linkMember = find(
                    link.affiliates,
                    (affiliate) => affiliate.affiliateId === member.affiliateId,
                  );
                  if (!isUndefined(linkMember) && linkMember.deletedDate !== null) {
                    return {
                      affiliateId: linkMember.affiliateId,
                      affiliateOfferId: linkMember.id,
                      memberAffiliateOfferId: member.affiliateOfferId,
                      affiliateLink: member.affiliateLink,
                      affiliateShortLink: member.affiliateShortLink,
                      avgSale: member.avgSale,
                      payoutId: linkMember.offerPayoutId,
                      clicks: member.clicks,
                      conversions: member.conversions,
                      email: member.affiliateEmail,
                      imageUrl: member.affiliateImageUrl,
                      linkCreationDate: member.linkCreationDate,
                      memberId: member.aspirexMemberId,
                      name: member.affiliateName,
                      payoutEarned: member.payoutEarned,
                      payoutMade: member.payoutMade,
                      sales: member.sales,
                      source: OFFER_SOURCE.TUNE,
                      status: getStatusForAffiliateLinkMember(member),
                      memberStatus: linkMember ? getMemberStatus(linkMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
                      linkStatusError: linkMember.updateLog,
                      additionalDeepLinks: linkMember.affiliateDeeplinks,
                      defaultDeeplink: linkMember.deeplinkUrl,
                      deletedDate: linkMember.deletedDate,
                    };
                  }
                }),
                 offerMembers: map(
                   link.affiliateStats,
                   (member): IAffiliateLinkMember => {
                     const linkMember = find(
                       link.affiliates,
                       (affiliate) => affiliate.affiliateId === member.affiliateId,
                     );
                     if (!isUndefined(linkMember) && linkMember.deletedDate === null) {
                       return {
                         affiliateId: linkMember.affiliateId,
                         affiliateOfferId: linkMember.id,
                         memberAffiliateOfferId: member.affiliateOfferId,
                         affiliateLink: member.affiliateLink,
                         affiliateShortLink: member.affiliateShortLink,
                         avgSale: member.avgSale,
                         payoutId: linkMember.offerPayoutId,
                         clicks: member.clicks,
                         conversions: member.conversions,
                         email: member.affiliateEmail,
                         imageUrl: member.affiliateImageUrl,
                         linkCreationDate: member.linkCreationDate,
                         memberId: member.aspirexMemberId,
                         name: member.affiliateName,
                         payoutEarned: member.payoutEarned,
                         payoutMade: member.payoutMade,
                         sales: member.sales,
                         source: OFFER_SOURCE.TUNE,
                         status: getStatusForAffiliateLinkMember(member),
                         memberStatus: linkMember ? getMemberStatus(linkMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
                         linkStatusError: linkMember.updateLog,
                         additionalDeepLinks: linkMember.affiliateDeeplinks,
                         defaultDeeplink: linkMember.deeplinkUrl,
                         deletedDate: linkMember.deletedDate,
                       };
                     }
                   },
                 ),
                 offerStats: {
                   ...first(offerQuery.data.offer.links).stats,
                 },
               };
           }
         }, [offerQuery.data, offerQuery.error, offerQuery.loading, source]);
         const filteredOfferMembers = offerMembers.filter((element) => element !== undefined);
         const filteredDeletedMembers = deletedMembers?.filter((element) => element !== undefined);
         /** FIXME: find pending offer count for both links and promos when offer is isPromoLink */
  const pendingMemberCount = useMemo((): number => filteredOfferMembers.filter(memberIsPending).length, [
           filteredOfferMembers,
         ]);
         const pendingLinks = useMemo((): boolean => {
    if (offerQuery.data) {
      const {
        data: { offer },
      } = offerQuery;
      if (offer.isPromoLink) {
        if (offer.links.length && offer.promos.length) {
          const promoAffiliates = first(offer.promos).affiliates;
          const linkAffiliatesStats = first(offer.links).affiliateStats;
          const activeLinks = filter(linkAffiliatesStats, (affiliateStat) => {
            const promoAffiliate = find(promoAffiliates, (affiliate) => affiliate.affiliateId === affiliateStat.affiliateId);
            return affiliateStat.linkStatus !== 'Disabled' && promoAffiliate && promoAffiliate.affiliate.deletedDate === null;
          }).length;
          const activeMembers = filter(
            first(offer.promos).affiliates,
            (affiliateOfferPromo: GetOfferById_offer_promos_affiliates) => affiliateOfferPromo.status === OFFER_PROMO_CODE_STATUS.SUCCESS && affiliateOfferPromo.affiliate.deletedDate === null,
          ).length;
          return activeMembers === activeLinks
            ? offer.links[0].affiliateStats.some((member) => member.linkStatus === 'Pending')
            : true;
        }
      }
    }
    return false;
  }, [offerQuery]);
  useEffect(() => {
           if (pendingMemberCount > 0 || pendingLinks) {
             offerQuery.startPolling(2000);
           } else {
             setDisableOfferEdit(false);
             offerQuery.stopPolling();
           }
         }, [offerQuery, pendingMemberCount, setDisableOfferEdit, pendingLinks]);

         // get the list of selected members for the wizard
         const selectedMembers = useMemo(() => {
           if (isEmpty(selectedMemberIds)) {
             return map(
               filteredOfferMembers,
               (m): TMember => ({
                 affiliateOfferId: m.affiliateOfferId,
                 affiliateId: m.affiliateId,
                 firstName: '',
                 id: m.memberId,
                 inOffer: true,
                 payoutId: m.payoutId,
                 instagramUsername: '',
                 lastName: '',
                 name: m.name,
                 previousCode: {
                   code: m.source === OFFER_SOURCE.SHOPIFY ? m.code : '',
                   end: m.source === OFFER_SOURCE.SHOPIFY ? m.codeEndDate : null,
                   start: m.source === OFFER_SOURCE.SHOPIFY ? m.codeStartDate : null,
                 },
                 forceCheckIn: false,
                 programIds: [],
                 status: m.status,
                 additionalDeeplinks: map((m as IAffiliateLinkMember).additionalDeepLinks, (deeplink) => ({
                   id: deeplink.id,
                   label: deeplink.label,
                   url: deeplink.url,
                 })),
               }),
             );
           }
           return reduce(
             filteredOfferMembers,
             (prev, next) => {
               if (includes(selectedMemberIds, next.memberId)) {
                 prev.push({
                   affiliateOfferId: next.affiliateOfferId,
                   affiliateId: next.affiliateId,
                   firstName: '',
                   id: next.memberId,
                   inOffer: true,
                   payoutId: next.payoutId,
                   instagramUsername: '',
                   lastName: '',
                   name: next.name,
                   programIds: [],
                   previousCode: {
                     end: next.source === OFFER_SOURCE.SHOPIFY ? next.codeEndDate : null,
                     code: next.source === OFFER_SOURCE.SHOPIFY ? next.code : '',
                     start: next.source === OFFER_SOURCE.SHOPIFY ? next.codeStartDate : null,
                   },
                   forceCheckIn: false,
                   status: next.status,
                   additionalDeeplinks: map((next as IAffiliateLinkMember).additionalDeepLinks, (deeplink) => ({
                     id: deeplink.id,
                     label: deeplink.label,
                     url: deeplink.url,
                   })),
                 });
               }
               return prev;
             },
             [] as TMember[],
           );
         }, [filteredOfferMembers, selectedMemberIds]);

         const failedMembers = useMemo(
           (): readonly TMember[] =>
             map(
               filter(filteredOfferMembers, (member: TOfferMember) => member.status === OfferMemberStatus.FAILED),
               (member): TMember => ({
                 affiliateOfferId: member.affiliateOfferId,
                 firstName: '',
                 affiliateId: member.affiliateId,
                 id: member.memberId,
                 payoutId: member.payoutId,
                 inOffer: true,
                 instagramUsername: '',
                 lastName: '',
                 name: member.name,
                 programIds: [],
                 previousCode: {
                   end: member.source === OFFER_SOURCE.SHOPIFY ? member.codeEndDate : null,
                   code: member.source === OFFER_SOURCE.SHOPIFY ? member.code : '',
                   start: member.source === OFFER_SOURCE.SHOPIFY ? member.codeStartDate : null,
                 },
                 forceCheckIn: false,
                 promoCodeStatus: member.promoCodeStatus,
               }),
             ),
           [filteredOfferMembers],
         );

         const failedPromoCodeErrors = useMemo(() => {
           if (!offerQuery.data?.offer) {
             return [];
           }
           switch (source) {
             case OFFER_SOURCE.SHOPIFY:
               const promo = first(offerQuery.data.offer.promos);
               if (!promo) {
                 logger.warn('Offer source is Shopify but missing promo.');
                 return [];
               }
               return promo.affiliates
                 .filter((affiliate) => getStatusForPromoCodeMember(affiliate) === OfferMemberStatus.FAILED)
                 .map(({ status }) => status);
             default:
               return [];
           }
         }, [offerQuery, source]);
         const [fixingFailedMembers, setFixingFailedMembers] = useState(false);

         if (offerQuery.loading) {
           return {
             status: OfferDetailsQueriesStatus.Loading,
           };
         }
         if (offerQuery.error) {
           logger.error(offerQuery.error);
           return {
             error: new Error('Oops! Something went wrong. Please refresh or try again later.'),
             status: OfferDetailsQueriesStatus.Failed,
           };
         }

         return {
           failedPromoCodeErrors,
           failedMembers: {
             members: failedMembers,
             dismissFailedMembers: () => {
               setFixingFailedMembers(false);
             },
             showFailedMembers: () => {
               if (isEmpty(failedMembers)) {
                 return;
               }
               setFixingFailedMembers(true);
             },
             visible: fixingFailedMembers,
           },
           missingShopifyCredentials,
           memberTable: {
             buttonActions,
             disableCompose: isEmpty(filteredOfferMembers) || pendingMemberCount > 0,
             onSelectMembers: updatedSelectedMemberIds,
             selectedAffiliateIds: selectedMemberIds,
           },
           offerDetails: offerQuery.data.offer,
           offerMembers: filteredOfferMembers,
           deletedMembers: filteredDeletedMembers,
           offerStats,
           multipleShopifyOfferStats: isEnabledMultipleShopify && multipleOfferStats ? multipleOfferStats : [],
           refresh: offerQuery.refetch,
           status: OfferDetailsQueriesStatus.Ready,
           updateMembers: {
             mode: updateWizardMode,
             onClose: () => {
               setUpdateWizardVisible(false);
             },
             refreshDatesOnly,
             selectedMembers,
             visible: updateWizardVisible,
           },
           updateManageOffer: {
             mode: updateWizardMode,
             onClose: () => {
               setIsVisibleSelectOffer(false);
             },
             selectedMembers,
             visible: isVisibleManageOffer,
             setIsVisibleSelectOffer,
           },
           memberConversion: {
             visible: isVisibleMemberConversion,
             selectedMemberConversion,
             onClose: () => {
               setIsVisibleMemberConversion(false);
             },
           },
           multipleShopifyAffiliateStats: {
             visible: showStoreLevelAffiliateStats,
             affiliatesStats,
             toggle: () => {
               setShowStoreLevelAffiliateStats(!showStoreLevelAffiliateStats);
             },
           },
           shopifyStoreName: advertiserShopifyScopeCheck.data?.advertiser.shopifyResources[0]?.identifier ?? null,
         };
       };
