import { useMemo } from 'react';

interface UseCurrencyProps {
  currencyFormatted: string;
}

/**
 * Custom hook to format a numeric value as a currency string.
 *
 * @param value - The numeric value to be formatted as currency.
 * @returns An object containing the formatted currency string.
 *
 * @remarks
 * - The hook uses the `Intl.NumberFormat` API to format the value.
 * - The locale is currently hardcoded to 'en-US'.
 * - The currency is set to 'USD' but can be updated to use user settings in the future.
 * - If the value is not provided or is falsy, the formatted string will default to '-'.
 *
 * @example
 * ```typescript
 * const { currencyFormatted } = useCurrency(1234);
 * console.log(currencyFormatted); // "$1,234"
 * ```
 */
export const useCurrency = (value: number): UseCurrencyProps => {
  const usersLocale = 'en-US'; // Intl.DateTimeFormat().resolvedOptions().locale;
  const currencyFormatted = useMemo(() => {
    return value && new Intl.NumberFormat(usersLocale, {
      style: 'currency',
      currency: 'USD', // TODO: Get currency from user settings
      maximumFractionDigits: 0,
    }).format(value) || '-';
  }, [value]);

  return {
    currencyFormatted
  };
};

export default useCurrency;
