import React from 'react';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { Card } from '@frontend/shadcn/components/ui/card';
import { BadgeDollarIcon } from '@revfluence/fresh-icons/solid/esm';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { calculateSpendMetrics } from './utils/calculateSpendMetrics';
import { PercentChangeBadge } from '../../../../components/percent-change-badge';
import {
  AdHighlightCardHeader,
  AdHighlightCardTitle,
  AdHighlightCardContent,
  AdHighlightCardContentRow,
  AdHighlightCardContentColumn,
  AdHighlightCardStatRow,
  AdHighlightCardStat,
  AdHighlightCardStatLabel,
} from '..';

const currentIntervalMetaSumInsightsFragment = graphql(`
  fragment TotalSpendCard_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalSpend {
      amount
      currency
    }
    totalClicks
  }
`);

const previousIntervalMetaSumInsightsFragment = graphql(`
  fragment TotalSpendCard_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalSpend {
      amount
      currency
    }
    totalClicks
  }
`);

const currentIntervalNotAspireSourcedMetaSumInsightsFragment = graphql(`
  fragment TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights on MetaSumInsights {
    totalSpend {
      amount
      currency
    }
  }
`);

type TotalSpendCardProps = {
  currentInterval: FragmentType<typeof currentIntervalMetaSumInsightsFragment> | null | undefined;
  previousInterval: FragmentType<typeof previousIntervalMetaSumInsightsFragment> | null | undefined;
  currentIntervalNotAspireSourced:
    | FragmentType<typeof currentIntervalNotAspireSourcedMetaSumInsightsFragment>
    | null
    | undefined;
};

const TotalSpendCard = ({
  currentInterval,
  previousInterval,
  currentIntervalNotAspireSourced,
}: TotalSpendCardProps) => {
  const currentIntervalData = useFragment(currentIntervalMetaSumInsightsFragment, currentInterval);
  const previousIntervalData = useFragment(previousIntervalMetaSumInsightsFragment, previousInterval);
  const currentIntervalNotAspireSourcedData = useFragment(
    currentIntervalNotAspireSourcedMetaSumInsightsFragment,
    currentIntervalNotAspireSourced,
  );

  const { formattedCurrentInterval, formattedCurrentIntervalNotAspireSourced, percentChange } = calculateSpendMetrics({
    currentInterval: currentIntervalData,
    previousInterval: previousIntervalData,
    currentIntervalNotAspireSourced: currentIntervalNotAspireSourcedData,
  });

  return (
    <Card>
      <AdHighlightCardHeader className="bg-blue-6">
        <AdHighlightCardTitle>
          <BadgeDollarIcon className="mr-2" />
          <span>Ads Spend</span>
        </AdHighlightCardTitle>
      </AdHighlightCardHeader>
      <AdHighlightCardContent>
        <AdHighlightCardContentRow>
          <AdHighlightCardContentColumn className="col-span-3">
            <AdHighlightCardStatRow>
              <AdHighlightCardStat className="text-4xl leading-none">
                {currentIntervalData ? formattedCurrentInterval : <Skeleton className="w-12 h-16" />}
              </AdHighlightCardStat>
              <PercentChangeBadge percent={percentChange} />
            </AdHighlightCardStatRow>

            {/* Label Goes Here */}
            <AdHighlightCardStatLabel>Attributed to Aspire</AdHighlightCardStatLabel>
          </AdHighlightCardContentColumn>
          <AdHighlightCardContentColumn className="col-span-2">
            <AdHighlightCardStatRow>
              <AdHighlightCardStat>
                {currentIntervalNotAspireSourcedData ? (
                  formattedCurrentIntervalNotAspireSourced
                ) : (
                  <Skeleton className="w-12 h-4" />
                )}
              </AdHighlightCardStat>
            </AdHighlightCardStatRow>

            {/* Label Goes Here */}
            <AdHighlightCardStatLabel>Not Attributed to Aspire</AdHighlightCardStatLabel>
          </AdHighlightCardContentColumn>
        </AdHighlightCardContentRow>
      </AdHighlightCardContent>
    </Card>
  );
};

export default TotalSpendCard;
