import React from 'react';
import Generic from './Generic';

interface PercentageProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number | null | undefined;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

/**
 * A React functional component that displays a percentage value.
 *
 * @component
 * @param {PercentageProps} props - The props for the Percentage component.
 * @param {number} props.value - The numeric value to be formatted as a percentage.
 * @param {number} [props.minimumFractionDigits=2] - Optional minimum number of fraction digits to display (default is 2).
 * @param {number} [props.maximumFractionDigits=2] - Optional maximum number of fraction digits to display (default is 2).
 * @returns {JSX.Element} A JSX element that renders the formatted percentage value using the `Generic` component.
 *
 * @remarks
 * This component utilizes `Intl.NumberFormat` to format the provided numeric value into a percentage string.
 */

const Percentage: React.FC<PercentageProps> = ({
  value,
  minimumFractionDigits = 1,
  maximumFractionDigits = 1,
}) => {
  if (value === null || value === undefined) {
    return <Generic value={value} align="right" />;
  }
  // Format the value as a percentage using the provided fraction digits
  const percentageFormatted = new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);

  return <Generic value={percentageFormatted} align="right" />;
};

export default Percentage;
