/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_4op6t_157 {
  display: flex;
}

._justify-content-space-between_4op6t_161 {
  justify-content: space-between;
}

._tabular-nums_4op6t_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_4op6t_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_4op6t_157 {
  display: flex;
}

._justify-content-space-between_4op6t_161 {
  justify-content: space-between;
}

._tabular-nums_4op6t_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_4op6t_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 ._Button_4op6t_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 ._Button_4op6t_355 {
  border-color: #167cf4;
  background-color: #167cf4;
}
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 ._Button_4op6t_355:focus, ._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 ._Button_4op6t_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 ._Button_4op6t_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._WorkflowEmptyOffers_4op6t_355 {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 {
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
  padding-left: 3rem;
}
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 h1 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
  text-align: left;
}
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 p {
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.025rem;
  text-align: left;
}
._WorkflowEmptyOffers_4op6t_355 ._textContainer_4op6t_355 ._Button_4op6t_355 {
  height: 2.5rem;
  width: 215px;
}
._WorkflowEmptyOffers_4op6t_355 ._imageContainer_4op6t_411 {
  padding-right: 3rem;
  padding-left: 3rem;
  display: flex;
}