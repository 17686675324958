import * as React from 'react';
import { Modal } from '@revfluence/fresh';
import { TTemplate, useGetProgramById, useListTemplatesQuery } from '@frontend/app/hooks';
import { isNil } from 'lodash';
import { ProjectsRouteRoot, WorkletSpecUri } from '@frontend/app/containers/Projects/constants';
import { useHistory } from 'react-router-dom';
import { AutomationBlueprintName } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './TemplateModal.scss';
import TemplateItem from './TemplateItem';

type TProps = {
  projectId: number;
  isVisible: boolean;
  onCancel: () => void;
};

const TemplateModal = (props: TProps) => {
  const {
    isVisible,
    onCancel,
    projectId,
  } = props;
  const history = useHistory();

  const { workflowAutomation: isWorkflowAutomationEnabled } = useClientFeatures();

  const {
    data: {
      listTemplates: {
        results: templates,
      },
    } = {
      listTemplates: {
        results: [],
      },
    },
    loading: isLoadingTemplates,
  } = useListTemplatesQuery({
    variables: {
      paging: {
        offset: 0,
        limit: 10,
      },
      context: {
        programId: projectId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const program = useGetProgramById({
    variables: {
      id: projectId,
      getWorkItems: true,
    }
  });

  const isFlexProject = program.data?.program?.isFlexibleSpec || false;
  const doesProductCatalogStageExists = program.data?.program?.workItems?.some((workItem) => workItem.specURI === WorkletSpecUri.SendBrandProductCatalogWorkletSpecification) || false;

  const goToConfig = (templateId: string) => {
    const template = templates.find((t: TTemplate) => t.blueprint.templateId === templateId);
    if (template?.isUsed) return;

    history.push({
      pathname: `${ProjectsRouteRoot}/${projectId}/automation_detail/template/${templateId}`,
    });
  };

  if (isLoadingTemplates || isNil(templates)) return null;

  const checkIfAutomationDisabled = (template: TTemplate) => {
    // Only show PFA order automation for flex projects with product catalog stage
    if (template.blueprint.name === AutomationBlueprintName.SendProductOrderRequestAfterBriefIsAccepted) {
      return !(isFlexProject && doesProductCatalogStageExists);
    }
    return false;
  };

  return (
    <Modal
      title="Add Automation"
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
      width={800}
      className={styles.TemplateModal}
    >
      <div>Select a pre-made automation. You can customize the automation in the next step.</div>
      <div className={styles.templateList}>
        {templates.map((template: TTemplate) => {
          // Skip templates that require the workflow automation flag
          if (template.blueprint.name === AutomationBlueprintName.SendProductOrderRequestAfterBriefIsAccepted &&
            !isWorkflowAutomationEnabled) {
            return null;
          }
          return (
            <TemplateItem
              key={template.blueprint.templateId}
              templateId={template.blueprint.templateId}
              name={template.blueprint.name}
              description={template.blueprint.description}
              icon={template.blueprint.metadata.icon}
              isNavTextTag={template.isUsed}
              isNavTextPersistent={template.isUsed}
              gotoConfig={!template.isUsed ? goToConfig : null}
              navText={template.isUsed ? 'Added' : 'Continue'}
              disabled={checkIfAutomationDisabled(template)}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default TemplateModal;
