import React, { useState } from 'react';
import {
  Alert,
 Button, Card, Checkbox, Col, Collapse, Modal, Row, Skeleton, Space, Switch, Tooltip, Typography,
} from '@revfluence/fresh';
import {
 ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, CircleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import { CatalogType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useMessagingContext } from '@frontend/hooks';
import { logger } from '@common';
import { GET_BRAND_CATALOG_BY_ID_QUERY, GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import styles from './CatalogHomePage.scss';
import { StatCard } from './StatCard';
import { pfaV2Routes } from '../../routes';
import { useGetCatalogStats } from '../hooks/useGetCatalogStats';
import { useGetCatalogDetails } from '../hooks/useGetCatalogDetails';
import { useCreateCatalog } from '../hooks/useCreateCatalog';
import { useUpdateCatalog } from '../hooks/useUpdateCatalog';
import { useCatalogDetailsContext } from './CatalogDetailsContext';
import { reloadPreviewIframe } from '../reloadPreviewIframe';

const { Title, Text } = Typography;
const { confirm } = Modal;

export const CatalogHomePage = () => {
  const history = useHistory();

  const [customizationOpen, setCustomizationOpen] = useState(false);

  const { catalogId } = useParams<{ catalogId: string }>();

  const { title, description, status } = useCatalogDetailsContext();

  const { catalogStats, loading: statsLoading, refetch: refetchCatalogStats } = useGetCatalogStats({
    variables: {
      id: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const { catalog, loading: isCatalogLoading, refetch: refetchCatalog } = useGetCatalogDetails({
    variables: {
      id: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const { showErrorMessage, showSuccessMessage } = useMessagingContext();

  const { createCatalog, loading: isCreateLoading } = useCreateCatalog({
    onCompleted: (data) => {
      showSuccessMessage(data.createBrandCatalog?.settings?.isFeatureProductsEnable ? 'Featured products enabled' : 'Featured products disabled');
      refetchCatalogStats();
      history.push(
        pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', String(data?.createBrandCatalog?.id)),
      );
    },
    onError: (error) => {
      logger.error('Error in createCatalog while enabling featured products', error);
      showErrorMessage(error.message || 'Failed to create catalog');
    },
    refetchQueries: [GET_BRAND_CATALOGS_QUERY],
  });

  const { updateCatalog, loading: isUpdateLoading } = useUpdateCatalog({
    onCompleted: (data) => {
      showSuccessMessage(data.updateBrandCatalog?.settings?.isFeatureProductsEnable ? 'Featured products enabled' : 'Featured products disabled');
      Promise.all([
        refetchCatalog({
          id: data?.updateBrandCatalog?.id,
        }),
        refetchCatalogStats()
      ]).catch((error) => {
        logger.error('Error refetching catalog data', error);
        showErrorMessage('Failed to refresh catalog data');
      });
      reloadPreviewIframe();
    },
    onError: (error) => {
      logger.error('Error in updateCatalog while enabling featured products', error);
      showErrorMessage(error.message || 'Failed to update catalog');
    },
    refetchQueries: [GET_BRAND_CATALOG_BY_ID_QUERY],
  });

  const showCollectionItems = () => {
    history.push(pfaV2Routes.settings.brandCatalogCollections.replace(':catalogId', catalogId));
  };

  const showFeaturedProducts = () => {
    if (catalog?.settings?.isFeatureProductsEnable) {
      history.push(pfaV2Routes.settings.brandCatalogFeaturedProducts.replace(':catalogId', catalogId));
    }
  };

  const showCustomization = () => {
    history.push(pfaV2Routes.settings.brandCatalogsCustomization.replace(':catalogId', catalogId));
  };

  const updateEnableFeaturedProducts = (isEnabled: boolean) => {
    if (catalogId === 'new') {
      createCatalog({
        variables: {
          input: {
            name: title,
            description,
            status,
            type: CatalogType.CUSTOM,
            settings: {
              isFeatureProductsEnable: isEnabled,
            },
          },
        },
      });
    } else {
      // @typescript-eslint/no-unused-vars
      const { __typename, ...oldSettings } = catalog?.settings || {};
      updateCatalog({
        variables: {
          input: {
            id: Number(catalogId),
            name: title,
            settings: {
              ...(oldSettings || {}),
              isFeatureProductsEnable: isEnabled,
            },
          },
        },
      });
    }
  };

  const handleFeaturedProductsSwitch = (checked: boolean) => {
    if (checked) {
      if (window?.localStorage?.getItem('catalogFeaturedProductsWarning') === 'true') {
        updateEnableFeaturedProducts(true);
        return;
      }
      confirm({
        title: 'Add feature products on Homepage',
        icon: <CircleExclamationIcon />,
        content: (
          <Space size="middle" direction="vertical">
            <span>
              By default, products from the added collections are displayed on the homepage. Enabling the 'Featured Products' option will replace them with featured products, while the original collection products will be relocated to the 'View All' Page.
            </span>
            <Checkbox onChange={(e) => window?.localStorage?.setItem('catalogFeaturedProductsWarning', String(e.target.checked))}>
              Don't show this message again
            </Checkbox>
          </Space>),
        onOk: () => updateEnableFeaturedProducts(true),
        autoFocusButton: null,
        okText: 'Add Featured Products',
        cancelText: 'Cancel',
        width: '570px',
      });
    } else {
      updateEnableFeaturedProducts(false);
    }
  };

  const getTooltip = () => {
    if (catalog?.type === CatalogType.DEFAULT) {
      return 'You cannot add featured products in Default Catalog';
    }
    if (!catalog?.settings?.isFeatureProductsEnable) {
      return 'Enable the featured products first';
    }
    return '';
  };

  const loading = isCreateLoading || isUpdateLoading || isCatalogLoading;

  return (
    <Space size="large" direction="vertical" className={styles.CatalogHomePage}>
      <Title level={4} className={styles.title}>
        Catalog Homepage
      </Title>
      {catalog.type === CatalogType.DEFAULT && (
        <Alert
          message="This default catalog includes all products and variants and updates automatically when new products are imported. You can create a new catalog or continue using this one, whichever best suits to your needs."
        />
      )}
      {statsLoading ? <Skeleton /> : (
        <Space size={10} wrap>
          <StatCard title="Collections" count={catalogStats.collectionCount ?? 0} />
          <StatCard title="Products" count={catalogStats.productCount ?? 0} />
          <StatCard title="Variants" count={catalogStats.variantCount ?? 0} />
        </Space>
      )}
      <Space direction="vertical" size="middle" className={styles.collectionSpace}>
        {catalog.type === CatalogType.CUSTOM && <Text>Configure the products you would like for creators to shop from.</Text>}
        <Card className={styles.collectionCard} onClick={showCollectionItems}>
          <Row align="middle" justify="space-between">
            <Col>
              <Text weight="semibold">Collections</Text>
            </Col>
            <Col>
              <Button icon={<ChevronRightIcon />} type="text" />
            </Col>
          </Row>
        </Card>
        <Tooltip title={getTooltip()}>
          <Card className={styles.collectionCard} onClick={showFeaturedProducts}>
            <Row align="middle" justify="space-between">
              <Col>
                <Text weight="semibold">Featured Products</Text>
              </Col>
              <Col>
                <Space align="center">
                  <div onClick={(e) => e.stopPropagation()}>
                    <Switch
                      loading={loading}
                      size="default"
                      disabled={catalog.type === CatalogType.DEFAULT}
                      checked={!!catalog?.settings?.isFeatureProductsEnable}
                      onChange={handleFeaturedProductsSwitch}
                    />
                  </div>
                  <Button icon={<ChevronRightIcon />} type="text" disabled={catalog.type === CatalogType.DEFAULT} />
                </Space>
              </Col>
            </Row>
          </Card>
        </Tooltip>
        {catalogId !== 'new' && (
        <Collapse
          ghost
          className={styles.customizationCollapse}
          onChange={() => setCustomizationOpen((prev) => !prev)}
        >
          <Collapse.Panel
            key="1"
            showArrow={false}
            header={(
              <Space>
                {customizationOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                <Text weight="semibold">Customization</Text>
              </Space>
          )}
          >
            <Card className={styles.collectionCard} onClick={showCustomization}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Text weight="semibold">Page Settings</Text>
                </Col>
                <Col>
                  <Button icon={<ChevronRightIcon />} type="text" />
                </Col>
              </Row>
            </Card>
          </Collapse.Panel>
        </Collapse>
        )}
      </Space>
    </Space>
  );
};
