import React, { forwardRef, useState, useEffect } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { Button, ButtonProps } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { P } from '@frontend/shadcn/components/typography/p';

interface ColorPickerProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  title?: string;
}

const ColorPicker = forwardRef<HTMLInputElement, Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> & ColorPickerProps>(
  ({
    disabled, value, onChange, onBlur, name, className, title, ...props
}) => {
    const [open, setOpen] = useState(false);
    const [localColor, setLocalColor] = useState(value || '#FFFFFF');

    useEffect(() => {
      setLocalColor(value || '#FFFFFF');
    }, [value]);

    const handleColorChange = (newColor: string) => {
      const formattedColor = newColor.startsWith('#') ? newColor : `#${newColor}`;
      setLocalColor(formattedColor);
      onChange(formattedColor);
    };

    return (
      <div>
        <P className="text-grey-5 text-xs font-medium">{title}</P>
        <div className="border p-1 rounded-lg flex items-center gap-2 w-fit">
          <Popover onOpenChange={setOpen} open={open}>
            <PopoverTrigger asChild disabled={disabled} onBlur={onBlur}>
              <Button
                {...props}
                className={cn('bg-transparent hover:bg-white/10 flex items-center gap-2', className)}
                name={name}
                onClick={() => {
                  setOpen(true);
                }}
                size="smallIcon"
                style={{
                  backgroundColor: localColor,
                }}
                variant="outline"
              >
                <div className="w-5 h-5" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full max-w-xs space-y-2" side="right" align="end">
              <div className="flex justify-between items-center">
                <h4 className="font-medium">{title}</h4>
                <HexColorInput color={localColor} onChange={handleColorChange} prefixed className="w-24" />
              </div>
              <HexColorPicker color={localColor} onChange={handleColorChange} className="rounded-md" />
            </PopoverContent>
          </Popover>
          <P className="">{localColor}</P>
        </div>
      </div>
    );
  },
);

ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };
