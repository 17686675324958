import * as React from 'react';
import { OFFER_PRICE_RULE_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Alert } from '@frontend/shadcn/components/ui/alert';
import { Form } from 'antd';
import { Input } from '@frontend/shadcn/components/ui/input';
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { TriangleExclamationIcon, CircleExclamationIcon } from '@revfluence/fresh-icons/solid';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { OfferInputHeader } from '../../CommonSection/FormElements/RefreshForm/OfferElementHeader';
import { RefreshOfferCodeDiscountOptions } from '../FormElements/RefreshForm/RefreshOfferCodeDiscountOptions';
import { OfferFormAsyncActions, OfferFormModes, PROMO_OFFER_TYPES } from '../../../types';
import { IRefreshPayoutProps } from '../../../utils/OfferFormUtils';
import { RefreshOfferPayoutOptions } from '../../CommonSection/FormElements/RefreshForm/RefreshOfferPayoutOptions';

export const RefreshShopifySection: React.FC<Readonly<IRefreshPayoutProps>> = (props) => {
  const {
    shopifyData: values, disabledShopify: disabled, mode, handleFormAsyncActions,
  } = props;
  const { linkShopifyOffer } = useClientFeatures();

  const handleOfferTypeChange = (type: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'offerType', value: type },
    });
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isPromoLink', value: PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER === type },
    });
  };
  return (
    <>
      {values.isMultipleShopifySyncEnabled && values.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT && (
        <Alert role="alert" className="w-full bg-yellow-2 flex items-center font-inter text-primary border border-yellow-4">
          <div className="flex flex-col md:flex-row md:items-center justify-center items-start w-full pr-6">
            <div className="flex-grow">
              <p><span>  <CircleExclamationIcon className="w-4 h-4 mr-2 text-yellow-5" /></span>Discount type have been changed to percentage only. Please update the discount. Changes will not be saved till you update the offer.</p>
            </div>
          </div>
        </Alert>
      )}
      <div className="mb-6">
        <div className="flex justify-between items-start gap-6">
          <div className="w-[50%]">
            <OfferInputHeader text="Offer Type" tooltipText="Offer Type" />
            <Form.Item className="!m-0">
              <Select value={values.offerType} onValueChange={handleOfferTypeChange} disabled={mode === OfferFormModes.Edit}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select offer type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={PROMO_OFFER_TYPES.AMOUNT_OFF}>Amount Off Purchase</SelectItem>
                  {linkShopifyOffer && (<SelectItem value={PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER}>Link Buy X Get Y Discount</SelectItem>)}
                </SelectContent>
              </Select>
            </Form.Item>
          </div>
          <div className="w-[50%]">
            {values.offerType === PROMO_OFFER_TYPES.AMOUNT_OFF && (
              <div>
                <OfferInputHeader text="Discount Value" />
                <div className="flex-1">
                  <RefreshOfferCodeDiscountOptions
                    disabled={disabled.priceRuleType}
                    name="priceRuleType"
                    value={values.priceRuleType}
                    isMultipleShopifyEnabled={values.isMultipleShopifySyncEnabled}
                    mode={mode}
                    handleFormAsyncActions={handleFormAsyncActions}
                  />
                </div>
              </div>
            )}
            {values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER && (
              <>
                <OfferInputHeader text="Enter Shopify Offer ID" tooltipText="Enter the Shopify Discount ID for your 'Buy X, Get Y' offer. You can find this ID in the offer URL in your Shopify admin under the Discounts section." />
                <Form.Item name="linkedShopifyOfferId" className="!m-0">
                  <Input
                    disabled={disabled.linkedShopifyOfferId}
                    placeholder="Enter Shopify Discount ID for Buy X, Get Y Offer"
                  />
                </Form.Item>
              </>
            )}
          </div>
        </div>
        {values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER && (
          <div className="m-2">
            <TriangleExclamationIcon className="w-3 h-3 mr-2 text-yellow-4" />
            <span className="text-grey-4 text-xs font-normal"> Note: Once you start adding members to this offer, you will no longer be able to change or edit it. Please ensure you've selected the correct offer.</span>
          </div>
        )}
      </div>

      <div>
        <OfferInputHeader text="Payouts" />
        <RefreshOfferPayoutOptions
          {...props}
        />
      </div>

    </>
  );
};
