import { EllipsisLabel } from '@frontend/app/refresh-components/EllipsisLabel';
import { isNumber } from 'lodash';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface ActivePostsProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string | number;
  memberId: string;
}

const ActivePosts: React.FC<ActivePostsProps> = ({ value, memberId }) => {
  const location = useLocation();
  const label = value == null ? '-' : isNumber(value) ? value.toLocaleString() : value;
  const isClickable = value && value !== '-';

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('memberIds', memberId);

  return (
    <Link
      to={{
        pathname: '/social/analytics/posts',
        search: searchParams.toString(),
      }}
      className={`block w-full text-right ${!isClickable ? 'pointer-events-none' : ''}`}
      data-dd-action-name="member-table-active-posts-click"
    >
      <EllipsisLabel
        align="right"
        tooltip={label}
        width="auto"
        className={isClickable ? 'text-blue-600 hover:text-blue-800 hover:underline' : ''}
      >
        {label}
      </EllipsisLabel>
    </Link>
  );
};

export default ActivePosts;
