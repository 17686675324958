import * as React from 'react';
import { get, isNull, isUndefined } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

import { LoadSpinner } from '@components';

import {
    Notice,
    PageSection,
} from '@affiliates/AspireUI';
import {
    OfferFormModes,
    TFormValues,
} from '@affiliates/components';
import { EditOfferContainer } from '@affiliates/containers';
import {
    IShopifyCredentials, useApolloClient, useGetOfferByIdQuery, useRedirectOnOfferFormSubmit,
} from '@affiliates/hooks';
import { GetOfferById } from '@affiliates/queries/types/GetOfferById';
import { useEffect, useState } from 'react';
import { Alert } from '@revfluence/fresh';
import moment from 'moment';
import { BoxArchiveIcon } from '@revfluence/fresh-icons/regular';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { IChildArgs } from '../containers/OfferFormContainer/types';
import { UserInfoInput } from '../types/globalTypes';
import styles from './OfferFormPage.scss';
import { useShopifyShopQuery } from '../hooks/useShopifyShopQuery';
import { useMigrateToMultiplePayout } from '../hooks/useMigrateToMultiplePayout';
import { GetConnectedShopify_clientConnections } from '../queries/types/GetConnectedShopify';
import { GET_CONNECTED_SHOPIFY } from '../queries/getConnectedShopifyQuery';
import { RefreshOfferForm } from '../components/OfferForm/RefreshOfferForm';
import { OfferPreviewCard } from '../components/OfferSummaryCard/OfferPreviewCard';

const { useCallback, Suspense } = React;

interface IProps {
    baseUri: string;
    shopifyCredentials: IShopifyCredentials;
    profile: UserInfoInput;
    allowedDomains: string[];
}

interface IParams {
    offerId: string;
}
interface StateType {
    isNewFlow: boolean;
    isMigrationEnabled: boolean;
}

export const RefreshEditOfferPage: React.FC<Readonly<IProps>> = ({
    baseUri, shopifyCredentials, profile, allowedDomains,
}) => {
    const { offerId } = useParams<IParams>();
    const offerQuery = useGetOfferByIdQuery({
        variables: {
            id: Number(offerId),
        },
    });
    const { migrateToGraphQL, enableMultipleShopify: isEnabledMultipleShopify } = useClientFeatures();
    const { refetch } = offerQuery;
    const [migrateOfferToMultiplePayout] = useMigrateToMultiplePayout({
        onCompleted() {
            refetch();
            window.location.reload();
        },
        onError() {
        },
    });

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                if (offerQuery.error) {
                    return;
                }
                const offer = get(offerQuery, ['data', 'offer'], null);
                if (migrateToGraphQL && !isNull(offer) && offer.links.length && offer.links[0].defaultPayoutId === null && !offer.isPromoLink) {
                    await migrateOfferToMultiplePayout({
                        variables: {
                            id: offerQuery?.data?.offer?.id,
                        },
                    });
                }
                if (migrateToGraphQL && !isNull(offer) && offer.promos.length && offer.promos[0].defaultPayoutId === null) {
                    await migrateOfferToMultiplePayout({
                        variables: {
                            id: offerQuery?.data?.offer?.id,
                        },
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [offerQuery, migrateOfferToMultiplePayout, migrateToGraphQL]);

    const { onSubmit } = useRedirectOnOfferFormSubmit(baseUri, migrateToGraphQL);
    const shopifySubscriptionCheck = useShopifyShopQuery();
    const [isSubscriptionEnable, setIsSubscriptionEnable] = useState(false);
    const [connectedShopifyData, setConnectedShopifyData] = useState<GetConnectedShopify_clientConnections[]>([]);
    const [offerImage, setOfferImage] = React.useState<string>(null);
    const staApolloClient = useApolloClient();
    let isMigrationEnabled = false;
    const location = useLocation<StateType | undefined>();
    const stateData = location.state;
    if (!isUndefined(stateData)) {
        isMigrationEnabled = stateData.isMigrationEnabled;
    }
    useEffect(() => {
        if (isEnabledMultipleShopify || offerQuery?.data?.offer.promos[0]?.connectedClientMetadata?.length) {
            (async () => {
                const { data } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
                if (data?.clientConnections) {
                    const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
                    setConnectedShopifyData(allConnections);
                }
            })();
        }
    }, [isEnabledMultipleShopify, staApolloClient, offerQuery?.data?.offer?.promos]);

    React.useEffect(() => {
        if (!shopifySubscriptionCheck.loading) {
            if (shopifySubscriptionCheck?.data && shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions) {
                setIsSubscriptionEnable(shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions);
            }
        }
    }, [shopifySubscriptionCheck]);

    const renderComponents = useCallback(
        ({
            actions,
            disabledFields,
            formRef,
            formOptionData,
            initialValues,
            isSaving,
            offerDetails,
            requiredFields,
            values,
            handleFormAsyncActions,
        }: IChildArgs<TFormValues>) => (
          <div className="flex flex-col xl:flex-row">
            <div className="w-full xl:w-[70%] h-[100vh] overflow-y-auto border-b xl:border-r border-grey-2 space-y-2">
              <RefreshOfferForm
                disabledFields={disabledFields}
                formRef={formRef}
                hasAffiliateLink={offerDetails.hasMembers}
                initialValues={initialValues}
                isDeleted={offerDetails.isDeleted}
                isExpired={offerDetails.isExpired}
                isSaving={isSaving}
                mode={OfferFormModes.Edit}
                onDelete={actions.onDelete}
                onFieldFocused={actions.onFieldFocused}
                onFinish={actions.onFinish}
                onUnDelete={actions.onUnDelete}
                onValuesChange={actions.onChangeValues}
                requiredFields={requiredFields}
                values={values}
                formOptionData={formOptionData}
                isMigrationEnabled={isMigrationEnabled}
                isNewFlow
                isSubscriptionEnable={isSubscriptionEnable}
                handleFormAsyncActions={handleFormAsyncActions}
                connectedAdvertiserForSync={connectedShopifyData.filter((c) => !c.isPrimary)}
                hasMembers={offerDetails.hasMembers}
                connectedClients={connectedShopifyData}
                isOfferArchived={offerDetails.isOfferArchived}
                initialAllowedDomains={allowedDomains}
                onChangeImage={actions.handleChangeOfferImage}
                setOfferImage={setOfferImage}
              />
            </div>
            <div className="border-t xl:border-l border-grey-3" />
            <div className="w-full xl:w-[30%] max-w-[375px] mx-auto">
              <OfferPreviewCard values={values} imageUrl={values.imageUrl || offerImage} icon={values.icon} createdOn={offerDetails.createdDate} />
            </div>
          </div>
        ),
        [
            isMigrationEnabled,
            isSubscriptionEnable,
            connectedShopifyData,
            allowedDomains,
            setOfferImage,
            offerImage,
        ],
    );

    const renderPageContents = (): React.ReactElement => {
        if (offerQuery.loading) {
            return <LoadSpinner />;
        }

        if (offerQuery.error) {
            return <Notice type="error" message={offerQuery.error.message} />;
        }

        const offer: GetOfferById['offer'] | null = get(offerQuery, ['data', 'offer'], null);
        if (isNull(offer)) {
            return <Notice type="error" message="Unable to retrieve offer." />;
        }
        return (
          <div className={styles.OfferFormPage}>
            <PageSection>
              {
                        (offerQuery?.data?.offer.archivedDate)
                        && (
                        <div className={styles.archiveOfferAlert}>
                          <Alert
                            message={`This offer was archived on ${moment(offerQuery?.data?.offer?.archivedDate).format('MM/DD/YY')} by ${offerQuery?.data?.offer?.archivedUser?.name}. All offer insights are still available but you can no longer add new members.`}
                            type="warning"
                            icon={<BoxArchiveIcon />}
                            showIcon
                          />
                        </div>
                        )
                    }
            </PageSection>
            <PageSection>
              <Suspense fallback={<LoadSpinner />}>

                <EditOfferContainer
                  offer={offer}
                  mode={OfferFormModes.Edit}
                  shopifyCredentials={shopifyCredentials}
                  onSubmit={onSubmit}
                  profile={profile}
                  isSubscriptionEnable={isSubscriptionEnable}
                  allowedDomains={allowedDomains}
                >
                  {renderComponents}
                </EditOfferContainer>
              </Suspense>

            </PageSection>

          </div>
        );
    };

    return <div>{renderPageContents()}</div>;
};
