import { useCallback, useState } from 'react';
import { logger } from '@common';
import { exportDataToCsv, SocialInsightsData } from '../components/table/utils/csvExport';
import { selectedColumnsToCSVMetadata } from '../components/table/utils/selectedColumnsToColumnDef';
import { ColumnDefinitionInput } from '../components/table';

interface UseExportMembersCsvProps {
  memberInsights: SocialInsightsData[] | undefined;
  selectedColumns: ColumnDefinitionInput[];
}

export const useExportMembersCsv = ({ memberInsights, selectedColumns }: UseExportMembersCsvProps) => {
  const [isExporting, setIsExporting] = useState(false);

  const handleExportCsv = useCallback(() => {
    if (!memberInsights || isExporting) return;

    setIsExporting(true);

    try {
      // Log a sample record to help with debugging
      logger.debug('Sample record for CSV export:', memberInsights[0]);

      // Generate column metadata for CSV using only visible columns
      // Filter out any hidden columns to match what's shown in the table
      const visibleColumns = selectedColumns.filter((col) => !col.hidden);
      const columnMetadata = selectedColumnsToCSVMetadata(visibleColumns);

      // Format filename with date and time for uniqueness
      const now = new Date();
      const dateStr = now.toISOString().split('T')[0];
      const filename = `Social Analytics Members ${dateStr}.csv`;

      // Ensure we're working with all the member data
      if (memberInsights.length === 0) {
        logger.warn('No member insights data available for export');
      }

      // Add a slight delay to ensure UI updates before potentially resource-intensive export
      setTimeout(() => {
        try {
          // Export the data with all records and properly formatted columns
          exportDataToCsv(memberInsights, columnMetadata, filename);
        } catch (innerError) {
          logger.error('Error during CSV generation:', innerError);
        } finally {
          setIsExporting(false);
        }
      }, 100);
    } catch (error) {
      logger.error('Error preparing CSV export:', error);
      setIsExporting(false);
    }
  }, [memberInsights, selectedColumns, isExporting]);

  return {
    handleExportCsv,
    isExporting,
  };
};
