import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@frontend/shadcn/components/ui/tabs';
import { PaletteIcon } from 'lucide-react';
import React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import HeroType from './components/HeroType';
import FeaturedContent from './components/FeaturedContent';
import Products from './components/Products';
import Others from './components/Others';
import { Basic } from './components/Basic';
import Styles from './components/Styles';
import { useLandingPageContext } from '../../context/LandingPageContext';

const tabItems = [
  { id: 'styles', label: <PaletteIcon className="w-4 h-4 text-grey-4" /> },
  // Uncomment below once we support Hero Types
  // { id: 'hero-type', label: 'Hero Type' },
  { id: 'basic', label: 'Basic' },
  { id: 'featured-content', label: 'Featured Content' },
  { id: 'products', label: 'Products' },
  // { id: 'others', label: 'Others' },
];

export const LeftFrame = (): JSX.Element => {
  const { fieldErrors } = useLandingPageContext();

  // Helper function to check if a tab has errors
  const hasTabErrors = (tabId: string): boolean => {
    switch (tabId) {
      case 'basic':
        return !!(fieldErrors?.basic);
      case 'featured-content':
        return !!fieldErrors?.featuredContent;
      case 'products':
        return !!fieldErrors?.products;
      default:
        return false;
    }
  };

  return (
    <Card className="flex flex-col items-start gap-4 p-4">
      <CardContent className="p-0 w-full">
        <div className="border-b border-[#e5e8eb]">
          <Tabs defaultValue="basic" className="w-full">
            <TabsList className="h-auto bg-transparent border-b border-grey-2 w-full flex flex-wrap justify-between">
              {tabItems.map((tab) => (
                <TabsTrigger
                  key={tab.id}
                  value={tab.id}
                  className={cn(`
                    rounded-none !shadow-none
                    data-[state=active]:border-b-2 data-[state=active]:border-[#16282d] data-[state=active]:font-medium
                    text-neutral-color-palettegray-9
                    px-4 py-3 flex-1 sm:flex-auto
                  `,
                    // Add red color when tab has errors
                    hasTabErrors(tab.id) && 'text-red-500',
                    // Override active state colors when there are errors
                    hasTabErrors(tab.id) && 'data-[state=active]:border-red-500 data-[state=active]:text-red-500')}
                >
                  {tab.label}
                  {hasTabErrors(tab.id) && (
                    <span className="ml-2 w-2 h-2 rounded-full bg-red-500" />
                  )}
                </TabsTrigger>
              ))}
            </TabsList>

            <TabsContent value="styles">
              <Styles />
            </TabsContent>

            <TabsContent value="hero-type">
              <HeroType />
            </TabsContent>
            <TabsContent value="basic">
              <Basic />
            </TabsContent>

            <TabsContent value="featured-content">
              <FeaturedContent />
            </TabsContent>

            <TabsContent value="products">
              <Products />
            </TabsContent>

            <TabsContent value="others">
              <Others />
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
    </Card>
  );
};
