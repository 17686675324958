import gql from 'graphql-tag';

import { TABLE_CONFIGURATION_FRAGMENT } from './fragments';

export const GET_TABLE_CONFIGURATION_QUERY = gql`
  query GetTableConfigurationQuery($id: Int!) {
    getTableConfiguration(id: $id) {
      ...TableConfigurationFragment
    }
  }
  ${TABLE_CONFIGURATION_FRAGMENT}
`;
