import * as React from 'react'; // eslint-disable-line

import { ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';

import { Tag, Tooltip } from '@revfluence/fresh';
import cx from 'classnames';
import { isNull } from 'lodash';
import TemplateIcon from './TemplateIcon';
import styles from './TemplateItem.scss';

type TProps = {
  gotoConfig: (templateId: string) => void;
  icon: string;
  name: string;
  templateId: string;
  description?: string;
  isNavTextTag?: boolean;
  isNavTextPersistent?: boolean;
  navText?: string;
  disabled?: boolean;
};

const TemplateItem = (props: TProps) => {
  const {
    gotoConfig,
    icon,
    name,
    templateId,
    description,
    navText,
    isNavTextTag,
    isNavTextPersistent,
    disabled,
  } = props;

  const onTemplateClick = () => {
    if (disabled || !gotoConfig) return;
    gotoConfig(templateId);
  };

  const content = (
    <div
      className={cx(
        styles.TemplateItem,
        !isNull(gotoConfig) ? styles.actionable : null,
        {
          'opacity-50 cursor-not-allowed': disabled,
          'cursor-pointer': !disabled && !isNull(gotoConfig),
        }
      )}
      onClick={onTemplateClick}
    >
      <div className={styles.contentContainer}>
        <div className={styles.templateIcon}>
          <TemplateIcon icon={icon} />
        </div>
        <div className={styles.templateContent}>
          <div className={styles.title}>{name}</div>
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </div>

      {navText && isNavTextTag && (
        <div className={!isNavTextPersistent ? styles.navTextNonPersistent : null}>
          <Tag color="success">{navText}</Tag>
        </div>
      )}
      {!isNavTextTag && (
        <div className={styles.nav}>
          <div className={cx(styles.navText, !isNavTextPersistent ? styles.navTextNonPersistent : null)}>
            {navText}
          </div>
          <ChevronRightIcon />
        </div>
      )}
    </div>
  );

  // Wrap with tooltip if disabled
  return disabled ? (
    <Tooltip
      title="This automation is not available."
      placement="top"
    >
      {content}
    </Tooltip>
  ) : content;
};

export default TemplateItem;
