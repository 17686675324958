import * as React from 'react';

import { Form, Input } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'groupName'> {
}

export const OfferCodeGroupName: React.FC<Readonly<IProps>> = React.memo(({
  name,
  disabled,
}) => {
  const rules = [
    { required: true, message: 'Please input your promo code group name!' },
  ];
  return (
    <Form.Item label="Promo Code Group Name" name={name} rules={disabled ? [] : rules}>
      <Input
        placeholder={disabled ? 'This field is disabled for linked Shopify offers' : 'e.g. Ambassador Promo Codes'}
        size="large"
        disabled={disabled}
      />
    </Form.Item>
  );
});
