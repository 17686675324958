import gql from 'graphql-tag';
import { OFFER_FRAGMENT } from './fragments/OfferFragment';

export const GET_OFFER_BY_ID_QUERY = gql`
  query GetOfferById($id: Int!, $startDate: DateTime, $endDate: DateTime, $status: [String!]) {
    offer: offerById(id: $id) {
      ...OfferFragment
      links {
        canEditConversionType
        affiliateStats(startDate: $startDate, endDate: $endDate) {
          affiliateEmail
          affiliateId
          affiliateImageUrl
          affiliateLink
          affiliateShortLink
          affiliateName
          affiliateOfferId
          aspirexMemberId
          avgSale
          clicks
          conversionRatio
          conversions
          linkCreationDate
          linkStatus
          payoutEarned
          payoutMade
          sales
          nonDuplicateConversions
        }
        stats(startDate: $startDate, endDate: $endDate, status: $status) {
          avgSale
          clicks
          conversionRatio
          conversions
          payoutEarned
          payoutMade
          sales
          nonDuplicateConversions
        }
      }
      promos {
        affiliateStats(startDate: $startDate, endDate: $endDate) {
          affiliateCode
          affiliateEmail
          affiliateId
          affiliateImageUrl
          affiliateName
          affiliateOfferId
          aspirexMemberId
          avgSale
          conversions
          payoutEarned
          payoutMade
          sales
          avgSaleBase
          salesBase
          payoutEarnedBase
        }
        stats(startDate: $startDate, endDate: $endDate, status: $status) {
          avgSale
          conversions
          payoutEarned
          payoutMade
          sales
          salesBase
          avgSaleBase
          payoutEarnedBase
          baseCurrencies
        }
      }
    }
  }
  ${OFFER_FRAGMENT}
`;
