import * as React from 'react';

import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled, useGetClientFeatureByName } from '@frontend/app/hooks';
import Text from '@frontend/app/refresh-components/Text';
import { H1 } from '@frontend/shadcn/components/typography/h1';
import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { useUpdateClientFF } from '@frontend/app/hooks/useUpdateClientFF';
import { ClientFFState } from '@frontend/app/types/globalTypes';
import { message } from 'antd';
import Spinner from '@frontend/app/refresh-components/Spinner';
import { cn } from '@frontend/shadcn/lib/utils';
import { isUndefined } from 'lodash';
import { OverlaySpinner } from '@components';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Tag } from '@frontend/app/refresh-components';
import { useClientContext } from '@frontend/app/context';
import { useMemo } from 'react';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';

export interface NewFeatureSettingsProps {
  clientId: string;
}

const newFeatureSVG = require('@frontend/app/assets/svgs/newFeature.svg');

const NewFeatureSettings: React.FC<NewFeatureSettingsProps> = ({ clientId }) => {
  const { data: refreshUiFF, loading: fetchingRefreshUiFF } = useGetClientFeatureByName(ClientFeature.REFRESH_UI);
  const { data: creatorStoreFF, loading: creatorStoreLoader } = useGetClientFeatureByName(ClientFeature.SHOPIFY_LANDING_PAGE);

  const clientInfo = useClientContext();
  const isRefreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI, { fetchPolicy: 'no-cache' });
  const isCreatorStore = useClientFeatureEnabled(ClientFeature.SHOPIFY_LANDING_PAGE, { fetchPolicy: 'no-cache' });
  const { flexibleProject, flexExpandableColumns } = useClientFeatures();

  const disableCreatorStore = useMemo(() => (
    creatorStoreFF?.clientFeature?.state === 'new_only' &&
    clientInfo?.client?.createdDate > creatorStoreFF?.clientFeature?.createdDate
  ), [clientInfo, creatorStoreFF]);
  const [successToastVisible, setSuccessToastVisible] = React.useState(false);

  const [updateClientFF, { loading: saving }] = useUpdateClientFF();

  const [loader, setLoader] = React.useState<boolean>(false);

  const loading = fetchingRefreshUiFF || creatorStoreLoader || saving || loader;

  const handleToggle = async (isChecked, featureFlag) => {
    setLoader(true);
    await updateClientFF({
      variables: {
        data: {
          id: clientId,
          featuresUpdate: [
            { id: featureFlag === 'refreshUI' ? refreshUiFF.clientFeature.id : creatorStoreFF.clientFeature.id, state: isChecked ? ClientFFState.ADD : ClientFFState.REMOVE },
          ],
        },
      },
      onCompleted: () => {
        setSuccessToastVisible(true);
        setLoader(false);
        message.success('Setting updated successfully! Page will automatically reload now.', 2, () => {
          setSuccessToastVisible(false);
          window.location.reload();
        });
      },
      onError: (e) => {
        setLoader(false);
        message.error('Something went wrong!', 2);
        console.error(`Failed to save ${featureFlag} setting. Error:`, e.message);
      },
    });
  };
  if (isUndefined(isRefreshUi)) return <OverlaySpinner />;

  // Adding shadcn class here as it must work even without FF
  return (
    <div className={cn('shadcn w-[70%] h-full font-inter relative', isRefreshUi ? 'bg-background' : '')}>
      {
        loader
        && <Spinner placement="global" />
      }
      <H1 className="text-lg font-medium">
        Explore New Features
        <img src={newFeatureSVG} className="ml-2" />
      </H1>
      <P className="text-primary">
        Explore the latest updates designed to improve the experience for your entire team. Activate features that everyone would like to try and share your valuable feedback with us. You can revert to the previous settings whenever you wish.
      </P>
      <Card className="p-2xl w-full flex flex-col gap-base mt-4 ">
        <div className="w-full flex justify-between items-center">
          <Label htmlFor="toggle-refresh-switch">
            <Text visualVariant="h4" className="font-semibold">Explore Our New Look </Text>
            <Tag label="Beta" bgColor="bg-green-3" className="ml-2" />
          </Label>
          <Switch id="toggle-refresh-switch" checked={isRefreshUi} onCheckedChange={(value) => handleToggle(value, 'refreshUI')} disabled={loading || successToastVisible || flexibleProject || flexExpandableColumns} />
        </div>
        <div className="text-textColor-secondary">
          <P>Try our refreshed User Interface (Beta) and experience a more streamlined and refined interaction.</P>
        </div>
        <Button className="w-[140px] h-8 bg-primary-foreground" variant="outline" size="lg">Share Feedback</Button>
      </Card>

      {!disableCreatorStore &&
        <Card className="p-2xl w-full flex flex-col gap-base mt-4">
          <div className="w-full flex justify-between items-center">
            <Label htmlFor="toggle-creator-store-switch">
              <Text visualVariant="h4" className="font-semibold">CreatorStore</Text>
              <Tag label="Beta" bgColor="bg-green-3" className="ml-2" />
            </Label>
            <Switch id="toggle-creator-store-switch" checked={isCreatorStore} onCheckedChange={(value) => handleToggle(value, 'creatorStore')} disabled={loading || successToastVisible} />
          </div>
          <div className="text-textColor-secondary">
            <P> Aspire’s CreatorStores are co-branded, micro shopping experiences - fully hosted on your Shopify site to maximize conversions and first-party data capture.</P>
          </div>
          <Button className="w-[140px] h-8 bg-primary-foreground" variant="outline" size="lg">Share Feedback</Button>
        </Card>}
    </div>
  );
};

export default NewFeatureSettings;
