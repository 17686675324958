import * as React from 'react';
import {
  map, isEmpty, filter, includes, difference,
  each,
  upperFirst,
  isArray,
  size,
  isBoolean,
} from 'lodash';

import { SEARCH_MEMBER_FIELDS, SEARCH_WORD_LENGTH } from '@frontend/app/components/MemberTable/MemberTable';
import { useSearchTextQuery } from '@frontend/app/components/MemberTable/hooks';
import { mergeMemberSearchQueries } from '@frontend/app/components/MemberTable/utils/mergeMemberTableQueries';

import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import {
  IMemberSearchQuery, IMemberFilter, SortDirection, Operator, ProgramMembershipStatusType, IMemberORQuery,
} from '@frontend/app/types/MemberSearch';
import { TSegment, TPredefinedSegment } from '@frontend/app/types/MemberList';

const { useMemo } = React;

type TSortOrder = 'DESC' | 'ASC';

type TFilter = IMemberFilter & { id: string };

const fieldsToReset = [
  'activationIds',
  'tagIds',
  'programId',
  'programIds',
  'programStatus',
  'owners',
  'requirements',
  'hasPrograms',
  'hasActivations',
  'hasCommunities',
  'hasTags',
  'hasHighlights',
  'hasOwners',
];

export interface ISortInfo {
  order: TSortOrder;
  columnKey: string;
}

export const useSearchQuery = (
  communityId: number = null,
  sourcingGroupId: string = null,
  segment: TSegment | TPredefinedSegment = null,
  selectedFilters: TFilter[],
  sortInfo: ISortInfo,
  searchText?: string,
  isContact?: boolean,
  logicalOperator: Operator.AND | Operator.OR = Operator.AND,
): IMemberSearchQuery => {
  const {
    getSearchTextQuery,
  } = useSearchTextQuery({
    memberFields: SEARCH_MEMBER_FIELDS,
  });

  const {
    fieldFilters,
    specialFilters,
  } = useMemo(() => {
    const specialFilterValues = Object.values(SpecialFilters);

    // Filter out special case filters
    const specialFilters = filter(selectedFilters, (filter) => includes(specialFilterValues, filter.column));

    const fieldFilters: Array<IMemberFilter | IMemberORQuery> = difference(selectedFilters, specialFilters);

    // Add sourcing group to field filters.
    if (sourcingGroupId) {
      fieldFilters.push({
        column: SpecialFilters.DATA_SOURCES,
        [Operator.CONTAINS]: sourcingGroupId,
      });
    }

    // // Handle OR filter inside basic AND filter.
    // // NOTE. In case we decide to make UI support for OR filter - this part should be reworked
    if (segment?.filter?.fields?.and) {
      fieldFilters.push(...filter<IMemberORQuery>(segment?.filter?.fields?.and, (queryItem) => !!queryItem.or));
    }

    return {
      fieldFilters,
      specialFilters,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, sourcingGroupId]);

  const orderBy = useMemo(() => {
    if (sortInfo && !sortInfo.order) return null;

    if (sortInfo && sortInfo.columnKey) {
      const result: IMemberSearchQuery['orderBy'] = {
        direction: sortInfo.order === 'ASC' ? SortDirection.ASC : SortDirection.DESC,
      };

      const schemaId = parseInt(sortInfo.columnKey, 10);

      if (!isNaN(schemaId)) {
        result.memberFieldSchemaId = schemaId;
      } else {
        result.column = sortInfo.columnKey;
      }

      return result;
    }
  }, [sortInfo]);

  return useMemo(() => {
    if (!segment) {
      return;
    }

    let result: IMemberSearchQuery = JSON.parse(
      JSON.stringify(segment.filter),
    );
    // Use selected filters instead of segment definition.
    fieldsToReset.forEach((field) => {
      delete result[field];
    });

    if (isEmpty(fieldFilters)) {
      delete result.fields;
    } else {
      // @ts-expect-error
      result.fields = {
        [logicalOperator]: map(fieldFilters, (filter) => {
          const field = filter as IMemberFilter;
          if (field[Operator.IN_THE_NEXT]) {
            field[Operator.IN_THE_NEXT] = `${field[Operator.IN_THE_NEXT]}`;
            field.relativeDateDirection = 'future';
          } else if (field[Operator.IN_THE_LAST]) {
            field[Operator.IN_THE_LAST] = `${field[Operator.IN_THE_LAST]}`;
            field.relativeDateDirection = 'past';
          } else if (field[Operator.CONTAINS] && isArray(field.contains?.choices)) {
            field[Operator.CONTAINS] = field.contains.choices;
          } else if (field[Operator.MORE_THAN_AGO]) {
            field[Operator.MORE_THAN_AGO] = field[Operator.MORE_THAN_AGO];
            field.relativeDateDirection = 'past';
          }
          return field;
        }),
      };
    }

    each(specialFilters, (sf) => {
      if (Operator.EQUAL in sf) {
        switch (sf.column) {
          case SpecialFilters.ACTIVATIONS:
            result.activationIds = sf[Operator.EQUAL];
            break;

          case SpecialFilters.QUICK_FILTER:
            result.quickFilter = sf[Operator.EQUAL];
            break;

          case SpecialFilters.APPLICANT_SOURCE:
            result.applicantSource = {
              operator: Operator.EQUAL,
              types: sf[Operator.EQUAL],
            };
            break;

          case SpecialFilters.TAGS:
            result.tagIds = sf[Operator.EQUAL];
            break;

          case SpecialFilters.COMMUNITIES: {
            const {
              communityIds,
            } = sf[Operator.EQUAL];
            delete result.communityId;
            result.communityIds = communityIds;
            break;
          }

          case SpecialFilters.PROGRAMS: {
            const {
              programIds,
              programStatus,
            } = sf[Operator.EQUAL];
            delete result.programId;
            result.programIds = programIds;
            result.programStatus = programStatus;
            break;
          }

          case SpecialFilters.INVITED_PROGRAMS: {
            const {
              programIds,
            } = sf[Operator.EQUAL];
            delete result.programId;
            result.programIds = programIds;
            result.programStatus = ProgramMembershipStatusType.INVITED;
            break;
          }

          case SpecialFilters.REJECTED_PROGRAMS: {
            const {
              programIds,
            } = sf[Operator.EQUAL];
            delete result.programId;
            result.programIds = programIds;
            result.programStatus = ProgramMembershipStatusType.REJECTED;
            break;
          }

          case SpecialFilters.SUBMITTED_PROGRAMS: {
            const {
              programIds,
            } = sf[Operator.EQUAL];
            delete result.programId;
            result.programIds = programIds;
            result.programStatus = ProgramMembershipStatusType.SUBMITTED;
            break;
          }

          case SpecialFilters.HIGHLIGHTS:
            result.orHighlightIds = sf[Operator.EQUAL];
            break;

          case SpecialFilters.OWNERS:
            result.owners = sf[Operator.EQUAL];
            break;

          case SpecialFilters.OVERDUE_REQUIREMENTS: {
            result.requirements = result.requirements || {};
            result.requirements.hasOverdue = sf[Operator.EQUAL];
            break;
          }

          case SpecialFilters.INCOMPLETE_REQUIREMENTS: {
            result.requirements = result.requirements || {};
            result.requirements.hasIncomplete = sf[Operator.EQUAL];
            break;
          }

          default:
            break;
        }
      }

      if (Operator.NOT_EQUAL in sf) {
        switch (sf.column) {
          case SpecialFilters.ACTIVATIONS:
            result.excludeActivationIds = sf[Operator.NOT_EQUAL];
            break;

          case SpecialFilters.APPLICANT_SOURCE:
            result.applicantSource = {
              operator: Operator.NOT_EQUAL,
              types: sf[Operator.NOT_EQUAL],
            };
            break;

          case SpecialFilters.TAGS:
            result.excludeTagIds = sf[Operator.NOT_EQUAL];
            break;

          case SpecialFilters.COMMUNITIES: {
            const {
              communityIds,
            } = sf[Operator.NOT_EQUAL];
            delete result.communityId;
            result.excludeCommunityIds = communityIds;
            break;
          }

          case SpecialFilters.PROGRAMS: {
            const {
              programIds,
              programStatus,
            } = sf[Operator.NOT_EQUAL];
            delete result.programId;
            result.excludeProgramIds = programIds;
            result.excludeProgramStatus = programStatus;
            break;
          }

          case SpecialFilters.INVITED_PROGRAMS: {
            const {
              programIds,
            } = sf[Operator.NOT_EQUAL];
            delete result.programId;
            result.excludeProgramIds = programIds;
            result.excludeProgramStatus = ProgramMembershipStatusType.INVITED;
            break;
          }

          case SpecialFilters.REJECTED_PROGRAMS: {
            const {
              programIds,
            } = sf[Operator.NOT_EQUAL];
            delete result.programId;
            result.excludeProgramIds = programIds;
            result.excludeProgramStatus = ProgramMembershipStatusType.REJECTED;
            break;
          }

          case SpecialFilters.SUBMITTED_PROGRAMS: {
            const {
              programIds,
            } = sf[Operator.NOT_EQUAL];
            delete result.programId;
            result.excludeProgramIds = programIds;
            result.excludeProgramStatus = ProgramMembershipStatusType.SUBMITTED;
            break;
          }

          case SpecialFilters.HIGHLIGHTS:
            result.excludeHighlightIds = sf[Operator.NOT_EQUAL];
            break;

          case SpecialFilters.OWNERS:
            result.excludeOwners = sf[Operator.NOT_EQUAL];
            break;

          default:
            break;
        }
      }

      if (Operator.NOT_NULL in sf) {
        switch (sf.column) {
          case SpecialFilters.PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.APPROVED;
            result.hasPrograms = true;
            break;

          case SpecialFilters.APPLICANT_SOURCE:
            result.applicantSource = {
              operator: Operator.NOT_NULL,
            };
            break;

          case SpecialFilters.INVITED_PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.INVITED;
            result.hasPrograms = true;
            break;

          case SpecialFilters.REJECTED_PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.REJECTED;
            result.hasPrograms = true;
            break;

          case SpecialFilters.SUBMITTED_PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.SUBMITTED;
            result.hasPrograms = true;
            break;

          default:
            result[`has${upperFirst(sf.column)}`] = true;
            break;
        }
      }

      if (Operator.IS_NULL in sf) {
        switch (sf.column) {
          case SpecialFilters.PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.APPROVED;
            result.hasPrograms = false;
            break;

          case SpecialFilters.APPLICANT_SOURCE:
            result.applicantSource = {
              operator: Operator.IS_NULL,
            };
            break;

          case SpecialFilters.INVITED_PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.INVITED;
            result.hasPrograms = false;
            break;

          case SpecialFilters.REJECTED_PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.REJECTED;
            result.hasPrograms = false;
            break;

          case SpecialFilters.SUBMITTED_PROGRAMS:
            result.programStatus = ProgramMembershipStatusType.SUBMITTED;
            result.hasPrograms = false;
            break;

          default:
            result[`has${upperFirst(sf.column)}`] = false;
            break;
        }
      }
    });

    if (searchText && size(searchText) >= SEARCH_WORD_LENGTH) {
      const searchTextQuery = getSearchTextQuery(searchText);
      result = mergeMemberSearchQueries(result, searchTextQuery);
    }

    if (isBoolean(isContact)) {
      result.isContact = isContact;
    }

    if (orderBy) {
      result.orderBy = orderBy;
    }
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, segment, fieldFilters, specialFilters, orderBy, searchText]);
};
