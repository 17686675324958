import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import CurrencyInput from '@frontend/app/components/CurrencyInput';
import { Collapse, Typography, Alert } from '@revfluence/fresh';
import { BudgetGranularity, FiscalYearBudgetBreakdown } from '@frontend/app/types/Budget';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './BudgetBreakdown.scss';

const { Text } = Typography;

const months = Array.from({ length: 12 }, (_, i) =>
  new Date(0, i).toLocaleString('default', { month: 'short' }));

interface BudgetBreakdownProps {
  id: string;
  granularity: BudgetGranularity;
  totalAmount: number;
  quarterBudgets: number[];
  monthlyBudgets: number[];
  fiscalYear: number | string;
  handleFiscalYearChange: (fiscalYear: FiscalYearBudgetBreakdown) => void;
}

const BudgetBreakdown: React.FC<BudgetBreakdownProps> = ({
  id,
  granularity,
  totalAmount,
  quarterBudgets = [0, 0, 0, 0],
  monthlyBudgets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  fiscalYear,
  handleFiscalYearChange,
}) => {
  const [editableQuarterBudgets, setEditableQuarterBudgets] = useState<number[]>(quarterBudgets);
  const [editableMonthlyBudgets, setEditableMonthlyBudgets] = useState<number[]>(monthlyBudgets);

  useEffect(() => {
    setEditableQuarterBudgets(quarterBudgets);
    setEditableMonthlyBudgets(monthlyBudgets);
  }, [quarterBudgets, monthlyBudgets]);

  const handleQuarterChange = (index: number, value: number) => {
    let updatedBudgets;
    if (editableQuarterBudgets.length === 0) updatedBudgets = [0, 0, 0, 0];
    updatedBudgets = [...editableQuarterBudgets];
    updatedBudgets[index] = value || 0;
    setEditableQuarterBudgets(updatedBudgets);
    handleFiscalYearChange({
      id,
      granularity,
      fiscalYear,
      totalAmount,
      quarterBudgets: updatedBudgets,
      monthBudgets: editableMonthlyBudgets,
    });
  };

  const handleMonthChange = (index: number, value: number) => {
    let updatedMonthlyBudgets;
    if (editableQuarterBudgets.length === 0) updatedMonthlyBudgets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    updatedMonthlyBudgets = [...editableMonthlyBudgets];
    updatedMonthlyBudgets[index] = value || 0;
    setEditableMonthlyBudgets(updatedMonthlyBudgets);
    handleFiscalYearChange({
      id,
      granularity,
      fiscalYear,
      totalAmount,
      quarterBudgets: editableQuarterBudgets,
      monthBudgets: updatedMonthlyBudgets,
    });
  };

  const calculateOthers = (quarterIndex: number) => {
    const startIndex = quarterIndex * 3;
    const endIndex = startIndex + 3;
    const monthlySum = editableMonthlyBudgets?.slice(startIndex, endIndex).reduce((acc, curr) => acc + curr, 0);
    return editableQuarterBudgets?.[quarterIndex] - monthlySum;
  };

  const renderQuarter = (quarterIndex: number) => {
    const startIndex = quarterIndex * 3;
    const endIndex = startIndex + 3;
    const quarterMonths = months.slice(startIndex, endIndex);
    const quarterBackgroundColor = ['#E6F7FF', '#E3F1BB', '#FFE58F', '#FCBBAE'][quarterIndex];
    const monthBackgroundColor = ['#F4FCFF', '#F6FFED', 'rgb(255, 251, 230, 0.4)', 'rgb(240, 218, 205, 0.2)'][quarterIndex];
    const borderColor = ['#91D5FF', '#BDD7C8', '#FFC53D', '#FCBBAE'][quarterIndex];
    const othersAmount = calculateOthers(quarterIndex);
    return (
      <div key={`Q${quarterIndex + 1}`} style={{ borderColor }} className={styles.quarterContainer}>
        <div style={{ background: quarterBackgroundColor }} className={styles.budgetCell}>
          <Text>
            Q
            {quarterIndex + 1}
            {' '}
            {fiscalYear === 'N/A' ? '' : fiscalYear}
          </Text>
          <CurrencyInput
            value={editableQuarterBudgets?.[quarterIndex]}
            onChange={(value) => handleQuarterChange(quarterIndex, value)}
            prefix="$"
            suffix=""
            className={styles.budgetInput}
          />
        </div>
        {granularity == BudgetGranularity.MONTHLY && (
          <>
            {quarterMonths.map((month, index) => (
              <div key={`${month}-${quarterIndex}`} style={{ backgroundColor: monthBackgroundColor }} className={styles.budgetCell}>
                <Text>
                  {month}
                  {' '}
                  {fiscalYear === 'N/A' ? '' : fiscalYear}
                </Text>
                <CurrencyInput
                  value={editableMonthlyBudgets?.[startIndex + index]}
                  onChange={(value) => handleMonthChange(startIndex + index, value)}
                  prefix="$"
                  suffix=""
                  className={styles.budgetInput}
                />
              </div>
          ))}
            <div style={{ backgroundColor: monthBackgroundColor }} className={styles.budgetCell}>
              <Text>Others</Text>
              <CurrencyInput
                value={othersAmount}
                prefix="$"
                suffix=""
                className={styles.budgetInput}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  if (granularity == BudgetGranularity.YEARLY) return null;

  const remainingAmount = totalAmount - editableQuarterBudgets?.reduce((acc, curr) => acc + curr, 0);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const totalQuarterlyAmount = editableQuarterBudgets?.reduce((acc, amount) => acc + amount, 0);
  const isQuarterlyAmountExceeding = totalQuarterlyAmount > totalAmount;

  return (
    <div className={styles.budgetBreakdown}>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel header="Budget Breakdown" key="1" className={styles.budgetBreakdownCollapse}>
          <div className={styles.quarterlyBreakdownContainer}>
            <div className={styles.quarterlyBreakdown}>
              {[0, 1, 2, 3].map((quarterIndex) => renderQuarter(quarterIndex))}
            </div>
            <div className={styles.remainingAmountContainer}>
              <Text className={styles.remainingAmountToDivideText}>Remaining Amount to divide: </Text>
              <Text className={styles.remainingAmountToDivide}>{formatter.format(remainingAmount)}</Text>
              <Tooltip title="Remaining amount to be divided">
                <CircleInfoIcon />
              </Tooltip>
            </div>
            {isQuarterlyAmountExceeding && (
              <Alert
                message="Please ensure that the total quarter budget amount is less than the total fiscal year budget"
                type="error"
                showIcon
              />
            )}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default BudgetBreakdown;
