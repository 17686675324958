import React from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Input } from '@frontend/shadcn/components/ui/input';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { Spinner } from '@revfluence/fresh';
import { IEditPayments } from './EditPaymentDrawer';

interface ProjectBudgetPeriodEntryProps {
  periodData: {
    key: string;
    budgetId?: number;
    amount?: number;
    granularityLabelForPayload?: string;
    periodId?: number;
  };
  projectId?: number;
  setEditPayments: React.Dispatch<React.SetStateAction<IEditPayments>>;
}

export const ProjectBudgetPeriodEntry: React.FC<ProjectBudgetPeriodEntryProps> = ({
  periodData,
  projectId,
  setEditPayments
}) => {
  const { periodDetails, loading: loadingBudgetPeriodDetails } = useGetBudgetPeriodDetails({
    variables: {
      budgetAccountId: null,
      programId: projectId,
    },
  });

  const handlePeriodChange = (value: string) => {
    const selectedPeriod = financialPeriods.find((period) => period.key === value);
    if (selectedPeriod) {
      setEditPayments((prev) => ({
        ...prev,
        projectBudgetPeriod: prev.projectBudgetPeriod.map((period) =>
          (period.key === periodData.key
            ? {
                ...period,
                key: value,
                periodId: selectedPeriod.id,
                granularityLabelForPayload: selectedPeriod.granularityLabelForPayload
              }
            : period)
        ),
      }));
    }
  };

  const financialPeriods = React.useMemo(() => {
    if (periodDetails?.length) {
      const fiscalPeriod = [];
      periodDetails.forEach((period) => {
        fiscalPeriod.push({
          key: `FY${period.fiscalYearPeriodDefinitionId}`,
          id: period.fiscalYearPeriodDefinitionId,
          label: period.fiscalYearLabel,
          granularityLabelForPayload: 'FY',
        });
        if (period.quarterDistributions?.length) {
          period.quarterDistributions.forEach((quarter) => {
            fiscalPeriod.push({
              key: quarter.quarterKey + quarter.quarterPeriodDefinitionId,
              id: quarter.quarterPeriodDefinitionId,
              label: quarter.quarterLabel,
              granularityLabelForPayload: quarter.quarterKey,
            });
          });
        }
      });
      return fiscalPeriod;
    }
    return [];
  }, [periodDetails]);

  if (loadingBudgetPeriodDetails) {
    return (
      <div className="flex flex-col gap-3 border rounded-lg p-3 mt-1">
        <div className="flex justify-center items-center h-24">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 border rounded-lg p-3 mt-1">
      <div className="flex justify-between items-center">
        <span className="text-gray-400">Budget Account</span>
        <span className="text-sm text-gray-500">Using Project Budget</span>
      </div>

      <div className="flex justify-between items-center">
        <span className="text-gray-400">Fiscal Year Period</span>
        <Select
          value={periodData.key || ''}
          onValueChange={handlePeriodChange}
          disabled={!projectId}
        >
          <SelectTrigger className="w-[170px] h-7 text-sm">
            <SelectValue placeholder={projectId ? 'Select Fiscal Period' : 'Select Project First'} />
          </SelectTrigger>
          <SelectContent className="z-[9999]">
            <SelectGroup>
              {financialPeriods.length > 0 ? (
                financialPeriods.map((period) => (
                  <SelectItem key={period.key} value={period.key}>
                    {period.label}
                  </SelectItem>
                ))
              ) : (
                <SelectItem disabled value="no-period">
                  No Period
                </SelectItem>
              )}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-400">Amount</span>
        <div className="bg-white rounded-lg overflow-hidden">
          <Input
            type="number"
            className="w-[170px] h-7"
            value={periodData.amount || ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
