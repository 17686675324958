/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import cx from 'classnames';
import {
  filter,
  find,
  groupBy,
  isArray,
  isEmpty,
  map,
  size,
  sortBy,
  toLower,
  reduce,
  first,
  forEach,
} from 'lodash';
import { logger } from '@common';
import {
  IToastRefHandles,
  Toast,
} from '@components';
import { IProspect } from '@components';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import {
  useProspectsAddToCommunity,
  useProspectsRemoveFromCommunity,
  useUpdateSocialAccountEmails,
} from '@frontend/app/hooks';

import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

import { CheckIcon, SpinnerIcon } from '@components';
import { ChevronDownIcon } from '@components';
import { HeartIcon } from '@components';
import { HeartFilledIcon } from '@components';
import { IOption, Select } from '@components';

import { SocialAccountEmail } from '@services/backend-server';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import { SubmitButton } from '../Button';
import { useGroupsForSocialAccounts } from './hooks/useGroupsForSocialAccounts';
import {
  getProspectFromSocialAccount,
  getSocialAccountsFromProspects,
} from './utils';

import styles from './Favorite.scss';

const {
 useCallback, useEffect, useMemo, useRef, useState,
} = React;

export const MY_FAVORITES_TITLE = 'My Favorites';

export interface IFavoriteProps {
  className?: string;
  prospect: IProspect | IProspect[];
  preloadProspects?: IProspect | IProspect[];
  toastRef?: React.RefObject<IToastRefHandles>;
  onInvite?: (prospect: IProspect | IProspect[], group: ICommunity) => void;
  onRemove?: (prospect: IProspect | IProspect[], group: ICommunity) => void;
  shouldClosePopoverOnActionDone?: boolean;
  communities: ICommunity[];
}

const updatedAccountsCache: Set<number> = new Set<number>();

export const Favorite: React.FC<IFavoriteProps> = (props) => {
  const {
    prospect,
    className,
    toastRef: toastRefProp,
    onInvite,
    onRemove,
    communities: groups,
    shouldClosePopoverOnActionDone = false,
  } = props;

  const heartRef = useRef();

  const [updateSocialAccountEmails] = useUpdateSocialAccountEmails();
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const toastRef = useRef<IToastRefHandles>(toastRefProp ? toastRefProp.current : null);
  const [myFavoritesGroup, setMyFavoritesGroup] = useState<ICommunity>();
  const [sortedGroups, setSortedGroups] = useState<ICommunity[]>([]);
  const [targetGroupId, setTargetGroupId] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [updatedSocialAccountEmails, setUpdatedSocialAccountEmails] = useState<Map<number, string>>(new Map<number, string>());
  const {
    searchText,
    inputValue,
    handleSearchChange,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'groups',
      metadata: {
        source: 'creator_search_profile_card',
      },
    },
  });

  const socialAccounts = isArray(prospect)
    ? getSocialAccountsFromProspects(...prospect)
    : getSocialAccountsFromProspects(prospect);

  const network = useMemo(
    () => (size(socialAccounts) > 0 ? first(socialAccounts).network_identifier : ''),
    [socialAccounts],
  );

  useEffect(() => {
    const updateEmails = async () => {
      if (isEmpty(network)) return;

      const accountIdsToUpdate = map(
        filter(
          socialAccounts,
          (account) => account.has_email && !account.email && !updatedAccountsCache.has(account.id),
        ),
        (account) => account.id,
      );
      if (isEmpty(accountIdsToUpdate)) return;

      try {
        const { data: { socialAccountEmails } = {} } = await updateSocialAccountEmails({
          variables: {
            socialAccountIds: accountIdsToUpdate,
            network,
          },
        });
          setUpdatedSocialAccountEmails(reduce(
          socialAccountEmails,
          (acc, accEmail: SocialAccountEmail) => acc.set(accEmail.social_account_id, accEmail.email),
          new Map<number, string>(),
        ));
      } catch (err) {
        logger.error(err);
      } finally {
        forEach(accountIdsToUpdate, (id) => updatedAccountsCache.add(id));
      }
    };
    updateEmails();
  }, [socialAccounts, network, updateSocialAccountEmails]);

  const [addProspectsToGroup] = useProspectsAddToCommunity();
  const [removeProspectsFromGroup] = useProspectsRemoveFromCommunity();

  const {
    isFetching,
    groups: invitedGroups,
    refetch: refetchGroupMembership,
    error,
  } = useGroupsForSocialAccounts({
    socialAccounts,
  });

  useEffect(() => {
    if (size(groups) > 0) {
      // find my favorites group
      const splitGroups = groupBy(groups, (group) => group.title === MY_FAVORITES_TITLE);
      const [favoritesGroup] = splitGroups.true || [];
      const otherGroups = splitGroups.false || [];

      setMyFavoritesGroup(favoritesGroup);

      const sortedGroups = sortBy(otherGroups, (group) => toLower(group.title));
      // sort by group title and place my favorites group at the top
      setSortedGroups(favoritesGroup ? [favoritesGroup, ...sortedGroups] : sortedGroups);
    }
  }, [groups]);

  const isDisabled = isEmpty(socialAccounts);

  const isGroupAdded = (group: ICommunity) => {
    if (!group) {
      return false;
    }

    return !!find(invitedGroups, ['id', group.id]);
  };

  const isSubmitting = isAdding || isRemoving;

  const getGroupOption = (group: ICommunity): IOption => {
    const isAdded = isGroupAdded(group);

    const { title, id } = group;
    const actions = [];

    if (
      isSubmitting
        && group.id === targetGroupId
    ) {
      actions.push(
        <div
          key={size(actions)}
          className={styles.spinner}
        >
          <SpinnerIcon
            fill="#ffffff"
            size={18}
          />
        </div>,
      );
    } else if (isAdded) {
      actions.push(
        <div
          key={size(actions)}
          className={styles.groupOptionAction}
        >
          <div className={styles.groupOptionActionLabel}>
            <CheckIcon
              size={8}
            />
            <span>Added</span>
          </div>
          <SubmitButton
            className={cx(
              styles.inviteButton,
              styles.toggleButton,
            )}
            key="inviteButton"
            theme="danger"
            label="Remove"
            submittingLabel=""
            isSubmitting={false}
            disabled={isDisabled}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              return toggleGroup(group).then(() => {
                shouldClosePopoverOnActionDone && setIsPopoverShown(false);
              });
            }}
          />
        </div>,
      );
    } else {
      actions.push(
        <div
          key={size(actions)}
          className={styles.groupOptionAction}
        >
          <SubmitButton
            className={cx(
              styles.inviteButton,
              styles.toggleButton,
              styles.addButton,
            )}
            key="inviteButton"
            label="Add"
            submittingLabel=""
            isSubmitting={false}
            disabled={isDisabled}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              return toggleGroup(group).then(() => {
                shouldClosePopoverOnActionDone && setIsPopoverShown(false);
              });
            }}
          />
        </div>,
      );
    }

    return {
      label: title,
      value: id,
      optionClass: cx(
        styles.option,
        styles.programOption,
        styles.groupOption,
        {
          [(styles as any).added]: isAdded,
        },
      ),
      showActions: true,
      actions,
    };
  };

  const groupOptions = useMemo(() => {
    const options: IOption[] = [];

    options.push({
      value: 'search',
      label: (
        <div
          className={styles.searchContainer}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <SearchInput
            value={inputValue}
            onChange={handleSearchChange}
            placeholder="Search groups..."
            isLoading={isSearchLoading}
          />
        </div>
      ),
      optionClass: cx(styles.option, styles.searchOption),
    });

    // Filter groups based on search
    const filteredGroups = sortedGroups.filter((group) =>
      !searchText || group.title.toLowerCase().includes(searchText.toLowerCase()));

    // Add error message if there are no results
    if (searchText && !filteredGroups.length) {
      options.push({
        value: 'no-results',
        label: (
          <div className={styles.searchError}>
            No results found
          </div>
        ),
        optionClass: cx(styles.option, styles.noSelect),
      });
      return options;
    }

    options.push({
      value: 'add_to_your_group',
      label: 'Add to your groups',
      optionClass: cx(styles.option, styles.optionTitle, styles.noSelect),
    });

    const mappedOptions = map(filteredGroups, (group) => getGroupOption(group));
    options.push(...mappedOptions);

    return options;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedGroups, invitedGroups, isSubmitting, isDisabled, searchText, inputValue, handleSearchChange]);

  const add = async (group: ICommunity) => {
    const { id } = group;

    try {
      setIsAdding(true);
      setTargetGroupId(id);
      await addProspectsToGroup({
        variables: {
          prospects: map(
            socialAccounts,
            (account) => getProspectFromSocialAccount(account, updatedSocialAccountEmails.get(account.id)),
          ),
          communityIds: [id],
          source: 'creator_search',
        },
      });
      if (refetchGroupMembership) {
        await refetchGroupMembership();
      }

      const message = group.title === MY_FAVORITES_TITLE
      ? 'Added to My Favorites'
      : `Added to Group "${group.title}"`;

      toastRef?.current?.showMessage({
        type: 'success',
        content: message,
      });

      onInvite?.(prospect, group);
    } catch (error) {
      toastRef?.current?.showMessage({
        type: 'error',
        content: getErrorMessageFromGraphQL(error),
      });
    } finally {
      setTargetGroupId(0);
      setIsAdding(false);
    }
  };

  const remove = async (group: ICommunity) => {
    const { id } = group;

    try {
      setIsRemoving(true);
      setTargetGroupId(id);
      await removeProspectsFromGroup({
        variables: {
          prospects: map(
            socialAccounts,
            (account) => getProspectFromSocialAccount(account, updatedSocialAccountEmails.get(account.id)),
          ),
          communityIds: [id],
        },
      });

      if (refetchGroupMembership) {
        await refetchGroupMembership();
      }

      const message = group.title === MY_FAVORITES_TITLE
        ? 'Removed from My Favorites'
        : `Removed from Group "${group.title}"`;

      toastRef.current.showMessage({
        type: 'success',
        content: message,
      });
      onRemove?.(prospect, group);
    } catch (error) {
      toastRef?.current?.showMessage({
        type: 'error',
        content: getErrorMessageFromGraphQL(error),
      });
    } finally {
      setTargetGroupId(0);
      setIsRemoving(false);
    }
  };

  const isMyFavoritesAdded = useMemo(() => (
    myFavoritesGroup && isGroupAdded(myFavoritesGroup)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [invitedGroups, myFavoritesGroup]);

  const toggleGroup = (group: ICommunity) => {
    const isAdded = isGroupAdded(group);

    return isAdded ? remove(group) : add(group);
  };

  const handleHeartClick = useCallback(() => {
    myFavoritesGroup && toggleGroup(myFavoritesGroup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myFavoritesGroup, invitedGroups]);

  const renderHeart = () => {
    const iconSize = 18;

    const icon = !isMyFavoritesAdded
      ? <HeartIcon size={iconSize} />
      : <HeartFilledIcon size={iconSize} />;

    return (
      <>
        <div
          ref={heartRef}
          className={cx(
            styles.FavoriteButton,
            styles.HeartButton,
            {
              [styles.FavoriteButtonDisabled]: isDisabled,
            },
          )}
          onClick={handleHeartClick}
        >
          {icon}
        </div>
      </>
    );
  };

  const renderDropdown = () => (
    <div
      className={cx(
          styles.FavoriteButton,
          (styles as any).PopoverButton,
          {
            [styles.active]: isPopoverShown,
          },
        )}
    >
      <Select
        onChange={() => null}
        customLabelElement={<ChevronDownIcon />}
        options={groupOptions}
        className={styles.select}
        onMenuOpen={() => setIsPopoverShown(true)}
        onMenuClose={() => setIsPopoverShown(false)}
        isPopoverShown={isPopoverShown}
        popoverProps={{
            className: cx(styles.popover),
            contentWrapperClassName: styles.popoverContentWrapper,
            contentClassName: styles.popoverContent,
            anchorOrigin: 'middle',
            showArrow: false,
            shadowSize: 'large',
            minWidth: styles.popoverWidth as any,
            maxWidth: styles.popoverWidth as any,
          }}
      />
    </div>
    );

  useEffect(() => {
    if (error) {
      toastRef.current.showMessage({
        type: 'error',
        content: error.toString(),
      });
    }
  }, [error]);

  const isLoading = isFetching
    || isAdding
    || isRemoving;

  return (
    <div
      className={cx(
        styles.Favorite,
        className,
        {
          [styles.isLoading]: isLoading,
        },
      )}
    >
      {renderHeart()}
      {renderDropdown()}
      {!toastRefProp ? <Toast useFresh ref={toastRef} /> : null}
    </div>
  );
};
Favorite.displayName = 'Favorite';
