import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { CircleInfoIcon, UserGroupIcon, UsersIcon } from '@revfluence/fresh-icons/regular/esm';
import { CheckIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { SectionCard } from '../components/SectionCard';
import { useCampaign } from '../CampaignContext';
import { PreferredCreatorTypes } from '../CampaignContext';

const CREATOR_TYPE_OPTIONS = [
  { name: 'Customers', description: 'Your brand\'s customers', value: PreferredCreatorTypes.CUSTOMERS },
  { name: 'UGC Creators', description: 'Specializes in original content', value: PreferredCreatorTypes.UGC_CREATORS },
  { name: 'Nano Creators', description: '1K - 10K followers', value: PreferredCreatorTypes.NANO_CREATORS },
  { name: 'Micro Creators', description: '10K - 100K followers', value: PreferredCreatorTypes.MICRO_CREATORS },
  { name: 'Mid Tier Creators', description: '60K - 200K followers', value: PreferredCreatorTypes.MID_TIER_CREATORS },
  { name: 'Macro Creators', description: '200K - 1M followers', value: PreferredCreatorTypes.MACRO_CREATORS },
];

const getCreatorTypesSummary = (types: PreferredCreatorTypes[]) => {
  if (!types?.length) return 'No creator types selected';
  return CREATOR_TYPE_OPTIONS
    .filter((option) => types.includes(option.value))
    .map((option) => option.name)
    .join(', ');
};

export const CreatorsSection: React.FC = () => {
  const {
    creatorDescription,
    creatorPreference,
    setCreatorDescription,
    setCreatorPreference,
  } = useCampaign();

  const [inputValue, setInputValue] = React.useState('');
  const [isCreatorTypesExpanded, setIsCreatorTypesExpanded] = React.useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && inputValue.trim()) {
      e.preventDefault();
      const currentTags = creatorDescription || [];
      if (currentTags.length >= 8) return;

      setCreatorDescription([...currentTags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const removeKeyword = (indexToRemove: number) => {
    const currentTags = creatorDescription || [];
    setCreatorDescription(currentTags.filter((_, index) => index !== indexToRemove));
  };

  return (
    <SectionCard>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <UserGroupIcon />
            <P>Creators</P>
          </div>
          <H3 className="font-medium mb-0">Define your creator experience for this campaign.</H3>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label className="font-medium">Describe your preferred creator with up to 8 keywords or hashtags</label>
            <div className="flex flex-wrap gap-2 min-h-[32px] px-3 rounded-lg border items-center">
              {creatorDescription?.map((keyword, index) => (
                <Badge
                  key={index}
                  className="px-3 py-0.5 rounded-md gap-2 h-max"
                  variant="secondary"
                >
                  {keyword}
                  <XmarkIcon
                    fontSize={12}
                    onClick={() => removeKeyword(index)}
                  />
                </Badge>
              ))}
              <Input
                placeholder={creatorDescription?.length >= 8 ? 'Max tags reached' : 'Enter keywords or hashtags'}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={creatorDescription?.length >= 8}
                className="border-0 outline-none focus-visible:ring-0 focus-visible:ring-offset-0 p-0 h-7 flex-1 min-w-[120px] bg-transparent shadow-none"
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <label className="font-medium">Preferred creator types</label>
              <CircleInfoIcon fontSize={12} className="text-primaryMuted" />
            </div>
            <Card className="overflow-hidden">
              <div className="p-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <UsersIcon />
                    <span>{getCreatorTypesSummary(creatorPreference || [])}</span>
                  </div>
                  <Button
                    variant="ghost"
                    className="text-primary"
                    onClick={() => setIsCreatorTypesExpanded(!isCreatorTypesExpanded)}
                  >
                    {isCreatorTypesExpanded ? 'Done' : 'Edit'}
                  </Button>
                </div>
              </div>

              {isCreatorTypesExpanded && (
                <div className="border-t">
                  <div className="grid grid-cols-3 gap-4 p-4">
                    {CREATOR_TYPE_OPTIONS.map((type) => (
                      <div
                        key={type.value}
                        className={cn(
                          'p-4 border rounded-xl cursor-pointer hover:border-primary transition-colors text-center',
                          (creatorPreference || []).includes(type.value) && 'border-primary bg-gray-50',
                        )}
                        onClick={() => {
                          const currentTypes = creatorPreference || [];
                          if (currentTypes.includes(type.value)) {
                            setCreatorPreference(currentTypes.filter((t) => t !== type.value));
                          } else {
                            setCreatorPreference([...currentTypes, type.value]);
                          }
                        }}
                      >
                        <div className="font-medium">{type.name}</div>
                        <div className="text-sm text-muted-foreground">{type.description}</div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end gap-2 p-4 border-t">
                    <Button
                      variant="outline"
                      size="smallIcon"
                      onClick={() => {
                        setCreatorPreference([]);
                        setIsCreatorTypesExpanded(false);
                      }}
                    >
                      <XmarkIcon />
                    </Button>
                    <Button
                      variant="primary"
                      size="smallIcon"
                      onClick={() => setIsCreatorTypesExpanded(false)}
                    >
                      <CheckIcon />
                    </Button>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </SectionCard>
  );
};
