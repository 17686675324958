import {
  Col, Divider, Drawer, Row, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import React, {
  memo, useCallback, useEffect, useMemo,
} from 'react';
import cx from 'classnames';
import { cloneDeep, find, first } from 'lodash';
import { getConversionDetailsAffliateBlocks } from '@frontend/app/utils/getConversionDetailsBlocks';
import { CoinsIcon } from '@revfluence/fresh-icons/regular/esm';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IDateRangeSettings, UserAvatar } from '@frontend/app/components';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './MemberConversionDrawer.scss';

import { PayoutSummaryCard } from '../PayoutSummaryCard';
import { ConversionTable } from './ConversionTable';
import { useGetMultipleShopifyAffliateStatsQuery } from '../../hooks';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { GetOfferById_offer, GetOfferById_offer_promos, GetOfferById_offer_promos_affiliates } from '../../queries/types/GetOfferById';
import { AffliateLevelStats, OfferMemberStatus, TSelectedAffliate } from '../MemberTable/types';
import { getMultiplePayoutLabel, getPayoutLabel } from '../OfferSummaryCard/hooks';
import { OfferDetailCard } from '../AffiliatesApp/components/OfferDetailCard';
import { useGetAffiliateConversionHistory } from '../../hooks/useGetAffiliateConversionHistory';
import { OFFER_STATUS_TYPE, getTagType } from '../../utils/getTagType';

const { Text, Link } = Typography;
interface IProps {
  offer: GetOfferById_offer,
  source: OFFER_SOURCE,
  isOpen: boolean,
  selectedMemberConversion: TSelectedAffliate,
  onClose: () => void,
  refresh: () => void;
  dateRangeSettings?: IDateRangeSettings;
}

export const MemberConversionDrawer: React.FC<Readonly<IProps>> = memo((props) => {
  const {
    offer, source, isOpen, selectedMemberConversion, onClose, refresh,
    dateRangeSettings,
  } = props;
  const { linkForPromo } = useClientFeatures();
  const [shortLinkForPromo, setShortLinkForPromo] = React.useState<string>(null);
  const { multiCurrency: isMulticurrency } = useClientFeatures();
  const affiliateStatsData = useMemo(() => {
    if (source === OFFER_SOURCE.TUNE) {
      return offer.links[0].affiliateStats.find((link) => link.affiliateOfferId === selectedMemberConversion?.affiliateOfferId) as unknown as AffliateLevelStats;
    } else {
      const affiliateStats = cloneDeep(offer.promos[0]?.affiliateStats.find((promo) => promo.affiliateOfferId === selectedMemberConversion?.affiliateOfferId)) as unknown as AffliateLevelStats;
      if (offer.isPromoLink && linkForPromo && affiliateStats) {
        // add clicks to the stats
        const affiliateId = find(
          offer.promos[0].affiliates,
          (aff: GetOfferById_offer_promos_affiliates) => aff.id === selectedMemberConversion?.affiliateOfferId,
        )?.affiliateId;
        const affiliateOfferLinkStats = (first(offer.links).affiliateStats.find(
          (affiliate) => affiliate.affiliateId === affiliateId,
        ) as unknown) as AffliateLevelStats;
        affiliateStats.clicks = affiliateOfferLinkStats?.clicks || 0;
        affiliateStats.conversions += affiliateOfferLinkStats?.nonDuplicateConversions || 0;
        affiliateStats.payoutEarned += affiliateOfferLinkStats?.payoutEarned || 0;
        affiliateStats.payoutMade += affiliateOfferLinkStats?.payoutMade || 0;
        affiliateStats.sales += affiliateOfferLinkStats?.sales || 0;
        affiliateStats.avgSale = affiliateStats.sales / affiliateStats.conversions;
      }
      return affiliateStats;
    }
  }, [source, selectedMemberConversion, offer.promos, linkForPromo, offer.isPromoLink, offer.links]);

  useEffect(() => {
    if (selectedMemberConversion && offer.isPromoLink && linkForPromo) {
      const affiliateId = find(
        offer.promos[0].affiliates,
        (aff: GetOfferById_offer_promos_affiliates) => aff.id === selectedMemberConversion?.affiliateOfferId,
      )?.affiliateId;

      const affiliateLinkInfo = first(offer.links).affiliates.find((aff) => aff.affiliateId === affiliateId);
      if (affiliateLinkInfo) {
        setShortLinkForPromo(affiliateLinkInfo.affiliateShortLink);
      }
    }
  }, [source, selectedMemberConversion, offer.promos, offer.links, linkForPromo, offer.isPromoLink]);

  const baseCurrencyWithoutMulipleShopify = useMemo(() => {
    if (offer.promos.length) {
      return first(offer.promos).stats.baseCurrencies;
    }
    return [];
  }, [offer.promos]);
  const isMultipleShopifyOffer = useMemo(() => {
    if (offer.promos.length) {
      return first(offer.promos).multiShopifyEnabled;
    }
    return false;
  }, [offer.promos]);
  const multipleShopifyOfferStats = useGetMultipleShopifyAffliateStatsQuery({
    fetchPolicy: 'network-only',
    variables: {
      affiliateOfferPromoId: selectedMemberConversion?.affiliateOfferId,
      startDate: dateRangeSettings?.dateRange?.startDate,
      endDate: dateRangeSettings?.dateRange?.endDate,
    },
    skip: !selectedMemberConversion?.affiliateOfferId,
  });
  // modify request below to use linkForPromo
  const affliateConversionHistory = useGetAffiliateConversionHistory({
    fetchPolicy: 'network-only',
    variables: {
      affiliateOfferId: selectedMemberConversion?.memberAffiliateOfferId,
      offerSource: offer.isPromoLink ? OFFER_SOURCE.PROMO_LINK : source,
    },
    skip: !selectedMemberConversion?.memberAffiliateOfferId,
  });
  const refetchData = useCallback(() => {
    if (selectedMemberConversion?.memberAffiliateOfferId) {
      affliateConversionHistory.refetch({
        affiliateOfferId: selectedMemberConversion?.memberAffiliateOfferId,
        offerSource: !offer.isPromoLink ? source : OFFER_SOURCE.PROMO_LINK,
      });
    }
  }, [selectedMemberConversion?.memberAffiliateOfferId, source, affliateConversionHistory, offer.isPromoLink]);

  const statsSummaryBlocks = useMemo(() => getConversionDetailsAffliateBlocks(affiliateStatsData, source, isMulticurrency, isMultipleShopifyOffer, baseCurrencyWithoutMulipleShopify, offer.isPromoLink && linkForPromo), [source, baseCurrencyWithoutMulipleShopify, isMultipleShopifyOffer, isMulticurrency, affiliateStatsData, offer, linkForPromo]);
  const promoOffer: GetOfferById_offer_promos = offer?.promos?.length ? first(offer.promos) : null;
  return (
    <Drawer
      open={isOpen}
      width={992}
      title={<Text className={styles.drawerHeader}>Member Conversions Summary</Text>}
      bodyStyle={{ paddingTop: 0 }}
      onClose={() => onClose()}
    >
      <Row className={styles.drawerTitle} justify="space-between" align="middle">
        <OfferDetailCard showCta={false} infoButtonClassName />

      </Row>
      <Divider className={styles.divider} />
      <Row className={styles.mainContent} justify="space-between">
        <Row className={styles.memberInfoCard}>
          <Col span={24}>
            {selectedMemberConversion && (
              <Row>
                <Col span={6} className={styles.memberInfoItemContainer}>
                  <Text className={styles.memberInfoItemTitle}>Name</Text>
                  <Row style={{ flexFlow: 'nowrap' }}>
                    <Col>
                      <UserAvatar
                        profilePicture={selectedMemberConversion?.imageUrl}
                        name={selectedMemberConversion.name}
                      />
                    </Col>
                    <Col>
                      <Text className={cx(styles.memberInfoItemValue, styles.memberInfoItemValueMargin)}>
                        {selectedMemberConversion.name}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                {source === OFFER_SOURCE.SHOPIFY ? (
                  !first(offer.promos).isSecureCodes ? (
                    <Col span={6} className={styles.memberInfoItemContainer}>
                      <Text className={styles.memberInfoItemTitle}>Promo Code</Text>
                      <Row align="middle">
                        <Text
                          ellipsis
                          className={cx(styles.memberInfoItemValue, styles.memberInfoPromo, styles.memberInfoItemValue)}
                          copyable
                        >
                          {`${selectedMemberConversion.code} `}
                        </Text>
                      </Row>
                    </Col>
                  ) : null
                ) : (
                  <Col span={6} className={styles.memberInfoItemContainer}>
                    <Text className={styles.memberInfoItemTitle}>Unique Link</Text>
                    <Row align="middle">
                      <Link ellipsis copyable>
                        {`${selectedMemberConversion.uniqueLink} `}
                      </Link>
                    </Row>
                  </Col>
                )}
                {offer.isPromoLink && linkForPromo && (
                  <Col span={6} className={styles.memberInfoItemContainer}>
                    <Text className={styles.memberInfoItemTitle}>Unique Link</Text>
                    <Row align="middle">
                      <Link ellipsis copyable>
                        {`${shortLinkForPromo || ''}`}
                      </Link>
                    </Row>
                  </Col>
                )}
                <Col span={6} className={styles.memberInfoItemContainer}>
                  <Text className={styles.memberInfoItemTitle}>Status</Text>
                  <Row align="middle">
                    <Tag
                      color={getTagType(
                        offer.expired
                          ? OFFER_STATUS_TYPE.EXPIRED
                          : ((selectedMemberConversion.status as unknown) as OFFER_STATUS_TYPE),
                      )}
                    >
                      {offer.expired ? OfferMemberStatus.EXPIRED : selectedMemberConversion.status}
                    </Tag>
                  </Row>
                </Col>
                <Col span={6} className={styles.memberInfoItemContainer}>
                  <Text className={styles.memberInfoItemTitle}>Current Commission</Text>
                  <Row align="middle">
                    <Tag>
                      {selectedMemberConversion?.payout
                        ? getMultiplePayoutLabel(
                            selectedMemberConversion.payout.label,
                            selectedMemberConversion.payout.payoutType,
                            selectedMemberConversion.payout.flatPayout,
                            selectedMemberConversion.payout.percentPayout,
                          )
                        : promoOffer
                        ? getPayoutLabel(promoOffer.payoutType, promoOffer.flatPayout, promoOffer.percentPayout, null)
                        : '-'}
                    </Tag>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className={styles.payoutSummaryContainer}>
          <Col span={24} className={styles.payoutSummaryDescriptionContainer}>
            <div className={styles.payoutSummaryTitle}>
              <CoinsIcon />
              <Text style={{ marginLeft: '8px' }}>Sales Stats</Text>
              {source === OFFER_SOURCE.SHOPIFY && (isMultipleShopifyOffer || isMulticurrency) && (
                <Tooltip title="Sales are calculated based on the currency exchange rates during the time of conversion.">
                  <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              )}
            </div>
          </Col>
          <PayoutSummaryCard
            source={source}
            blocks={statsSummaryBlocks}
            storeStats={multipleShopifyOfferStats?.data?.affliateStats}
            isMemberSummary
          />
        </Row>
        <Row>
          <Col span={24}>
            {affliateConversionHistory?.data?.affliateConversionHistory && (
              <ConversionTable
                affiliateConversionHistory={affliateConversionHistory?.data?.affliateConversionHistory}
                source={!offer.isPromoLink ? source : OFFER_SOURCE.PROMO_LINK}
                refetchData={refetchData}
                refresh={refresh}
              />
            )}
          </Col>
        </Row>
      </Row>
    </Drawer>
  );
});
