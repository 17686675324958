import {
  faBriefcase,
  faTicket,
  faReceipt,
  faMoneyBill,
  // faHandsHoldingDollar,
  faSackDollar,
  faPiggyBank,
  faBullhorn,
  faPercent,
  faDollarSign,
  faShieldHalved,
  faGlobe,
  faLandmark,
  faWallet,
  faHeart,
  faGift,
  faLock,
  faTag,
  faFire,
  faCartShopping,
  faBagShopping,
  // faBagsShopping,
  faShop,
  faRocket,
  faHandHoldingHeart,
  faHandshake,
  faStar,
  faMusic,
  faSpa,
  faSeedling,
  faLink,
  faFile,
  faPenNib,
  faBook,
  faEnvelope,
  faFolderOpen,
  faPaperclip,
  faFeather,
  faBullseye,
  // faCircleHeart,
  faHashtag,
  faUmbrella,
  faCloud,
  faSnowflake,
  faSun,
  faEarthAmericas,
  // faPlanetRing,
  faPaperPlane,
  faFlag,
  faCompass,
  faChartSimple,
  faRankingStar,
  faMedal,
  faUser,
  faPersonBreastfeeding,
  faBed,
  faLightbulb,
  faHouse,
  faChartLine,
  faBoxArchive,
  faScaleUnbalanced,
  faCalendar,
  faClock,
  faHourglassHalf,
  faBell,
  faImage,
  faCamera,
  faVideo,
  faNotesMedical,
  faStarOfLife,
  // faWavePulse,
  faThumbsUp,
  faCreditCard,
  faPodcast,
  faSatelliteDish,
  faCube,
  faPlane,
  faWarehouse,
  faShirt,
  // faStocking,
  faPuzzlePiece,
  // faSpade,
  faChessKnight,
  faCheck,
  faXmark,
  faFishFins,
  // faHatChef,
  faUtensils,
  faMugHot,
  faCookieBite,
  faCakeCandles,
  // faCitrus,
  // faCupcake,
  faLaptop,
  faGlasses,
  faBath,
} from '@fortawesome/free-solid-svg-icons';

export interface IconOption {
  icon: any;
  name: string;
  keywords: string[];
}

export const iconOptions: IconOption[] = [
  { icon: faBriefcase, name: 'briefcase', keywords: ['work', 'business', 'job'] },
  { icon: faTicket, name: 'ticket', keywords: ['event', 'admission', 'pass'] },
  { icon: faReceipt, name: 'receipt', keywords: ['bill', 'invoice', 'payment'] },
  { icon: faMoneyBill, name: 'money-bill', keywords: ['cash', 'payment', 'currency'] },
  // { icon: faHandsHoldingDollar, name: 'hands-holding-dollar', keywords: ['money', 'give', 'receive'] },
  { icon: faSackDollar, name: 'sack-dollar', keywords: ['money', 'bag', 'savings'] },
  { icon: faPiggyBank, name: 'piggy-bank', keywords: ['savings', 'money', 'bank'] },
  { icon: faBullhorn, name: 'bullhorn', keywords: ['announce', 'marketing', 'promotion'] },
  { icon: faPercent, name: 'percent', keywords: ['discount', 'sale', 'offer'] },
  { icon: faDollarSign, name: 'dollar-sign', keywords: ['money', 'price', 'payment'] },
  { icon: faShieldHalved, name: 'shield-halved', keywords: ['security', 'protection'] },
  { icon: faGlobe, name: 'globe', keywords: ['world', 'international', 'web'] },
  { icon: faLandmark, name: 'landmark', keywords: ['bank', 'institution', 'building'] },
  { icon: faWallet, name: 'wallet', keywords: ['money', 'cash', 'purse'] },
  { icon: faHeart, name: 'heart', keywords: ['love', 'like', 'favorite'] },
  { icon: faGift, name: 'gift', keywords: ['present', 'reward', 'package'] },
  { icon: faLock, name: 'lock', keywords: ['security', 'private', 'protected'] },
  { icon: faTag, name: 'tag', keywords: ['label', 'price', 'category'] },
  { icon: faFire, name: 'fire', keywords: ['hot', 'trending', 'popular'] },
  { icon: faCartShopping, name: 'cart-shopping', keywords: ['shopping', 'buy', 'purchase'] },
  { icon: faBagShopping, name: 'bag-shopping', keywords: ['shopping', 'buy', 'purchase'] },
  // { icon: faBagsShopping, name: 'bags-shopping', keywords: ['shopping', 'buy', 'purchase'] },
  { icon: faShop, name: 'shop', keywords: ['store', 'retail', 'purchase'] },
  { icon: faRocket, name: 'rocket', keywords: ['launch', 'space', 'travel'] },
  { icon: faHandHoldingHeart, name: 'hand-holding-heart', keywords: ['love', 'care', 'support'] },
  { icon: faHandshake, name: 'handshake', keywords: ['agreement', 'deal', 'negotiation'] },
  { icon: faStar, name: 'star', keywords: ['rating', 'favorite', 'important'] },
  { icon: faMusic, name: 'music', keywords: ['song', 'audio', 'sound'] },
  { icon: faSpa, name: 'spa', keywords: ['relax', 'beauty', 'treatment'] },
  { icon: faSeedling, name: 'seedling', keywords: ['plant', 'growth', 'nature'] },
  { icon: faLink, name: 'link', keywords: ['url', 'chain', 'connect'] },
  { icon: faFile, name: 'file', keywords: ['document', 'paper', 'new'] },
  { icon: faPenNib, name: 'pen-nib', keywords: ['write', 'edit', 'create'] },
  { icon: faBook, name: 'book', keywords: ['read', 'library', 'education'] },
  { icon: faEnvelope, name: 'envelope', keywords: ['mail', 'email', 'message'] },
  { icon: faFolderOpen, name: 'folder-open', keywords: ['directory', 'files'] },
  { icon: faPaperclip, name: 'paperclip', keywords: ['attachment', 'file'] },
  { icon: faFeather, name: 'feather', keywords: ['light', 'weight', 'feather'] },
  { icon: faBullseye, name: 'bullseye', keywords: ['target', 'aim', 'hit'] },
  // { icon: faCircleHeart, name: 'circle-heart', keywords: ['love', 'favorite', 'important'] },
  { icon: faHashtag, name: 'hashtag', keywords: ['number', 'pound', 'social'] },
  { icon: faUmbrella, name: 'umbrella', keywords: ['rain', 'protection', 'weather'] },
  { icon: faCloud, name: 'cloud', keywords: ['weather', 'storage', 'sky'] },
  { icon: faSnowflake, name: 'snowflake', keywords: ['winter', 'cold', 'freeze'] },
  { icon: faSun, name: 'sun', keywords: ['weather', 'bright', 'day'] },
  { icon: faEarthAmericas, name: 'earth-americas', keywords: ['world', 'planet', 'globe'] },
  // { icon: faPlanetRing, name: 'planet-ring', keywords: ['planet', 'space', 'orbit'] },
  { icon: faPaperPlane, name: 'paper-plane', keywords: ['send', 'message', 'mail'] },
  { icon: faFlag, name: 'flag', keywords: ['report', 'marker', 'notice'] },
  { icon: faCompass, name: 'compass', keywords: ['navigation', 'direction'] },
  { icon: faChartSimple, name: 'chart-simple', keywords: ['statistics', 'analytics'] },
  { icon: faRankingStar, name: 'ranking-star', keywords: ['rating', 'favorite', 'important'] },
  { icon: faMedal, name: 'medal', keywords: ['award', 'achievement', 'honor'] },
  { icon: faUser, name: 'user', keywords: ['person', 'profile', 'account'] },
  { icon: faPersonBreastfeeding, name: 'person-breastfeeding', keywords: ['feed', 'nurse', 'baby'] },
  { icon: faBed, name: 'bed', keywords: ['sleep', 'hotel', 'furniture'] },
  { icon: faLightbulb, name: 'lightbulb', keywords: ['idea', 'bright', 'smart'] },
  { icon: faHouse, name: 'house', keywords: ['home', 'building', 'property'] },
  { icon: faChartLine, name: 'chart-line', keywords: ['statistics', 'analytics'] },
  { icon: faBoxArchive, name: 'box-archive', keywords: ['storage', 'package'] },
  { icon: faScaleUnbalanced, name: 'scale-unbalanced', keywords: ['balance', 'weight', 'scale'] },
  { icon: faCalendar, name: 'calendar', keywords: ['date', 'schedule', 'time'] },
  { icon: faClock, name: 'clock', keywords: ['time', 'watch', 'schedule'] },
  { icon: faHourglassHalf, name: 'hourglass-half', keywords: ['time', 'wait', 'loading'] },
  { icon: faBell, name: 'bell', keywords: ['notification', 'alert', 'alarm'] },
  { icon: faImage, name: 'image', keywords: ['picture', 'photo', 'media'] },
  { icon: faCamera, name: 'camera', keywords: ['photo', 'picture', 'capture'] },
  { icon: faVideo, name: 'video', keywords: ['movie', 'film', 'media'] },
  { icon: faNotesMedical, name: 'notes-medical', keywords: ['health', 'medical', 'note'] },
  { icon: faStarOfLife, name: 'star-of-life', keywords: ['death', 'funeral', 'grave'] },
  // { icon: faWavePulse, name: 'wave-pulse', keywords: ['wave', 'waveform', 'signal'] },
  { icon: faThumbsUp, name: 'thumbs-up', keywords: ['like', 'agree', 'approval'] },
  { icon: faCreditCard, name: 'credit-card', keywords: ['payment', 'card', 'purchase'] },
  { icon: faPodcast, name: 'podcast', keywords: ['audio', 'radio', 'podcast'] },
  { icon: faSatelliteDish, name: 'satellite-dish', keywords: ['satellite', 'communication', 'dish'] },
  { icon: faCube, name: 'cube', keywords: ['box', '3d', 'package'] },
  { icon: faPlane, name: 'plane', keywords: ['travel', 'flight', 'trip'] },
  { icon: faWarehouse, name: 'warehouse', keywords: ['storage', 'inventory'] },
  { icon: faShirt, name: 'shirt', keywords: ['clothing', 'fashion', 'shirt'] },
  // { icon: faStocking, name: 'stocking', keywords: ['clothing', 'fashion', 'sock'] },
  { icon: faPuzzlePiece, name: 'puzzle-piece', keywords: ['game', 'addon'] },
  // { icon: faSpade, name: 'spade', keywords: ['card', 'game', 'deck'] },
  { icon: faChessKnight, name: 'chess-knight', keywords: ['game', 'strategy'] },
  { icon: faCheck, name: 'check', keywords: ['complete', 'done', 'correct'] },
  { icon: faXmark, name: 'xmark', keywords: ['cancel', 'reject', 'deny'] },
  { icon: faFishFins, name: 'fish-fins', keywords: ['fish', 'sea', 'aquatic'] },
  // { icon: faHatChef, name: 'hat-chef', keywords: ['cook', 'chef', 'hat'] },
  { icon: faUtensils, name: 'utensils', keywords: ['food', 'restaurant', 'eat'] },
  { icon: faMugHot, name: 'mug-hot', keywords: ['coffee', 'drink', 'tea'] },
  { icon: faCookieBite, name: 'cookie-bite', keywords: ['bitten', 'cookie', 'bite'] },
  { icon: faCakeCandles, name: 'cake-candles', keywords: ['birthday', 'celebration', 'dessert'] },
  // { icon: faCitrus, name: 'citrus', keywords: ['fruit', 'orange', 'citron'] },
  // { icon: faCupcake, name: 'cupcake', keywords: ['birthday', 'celebration', 'dessert'] },
  { icon: faLaptop, name: 'laptop', keywords: ['computer', 'device'] },
  { icon: faGlasses, name: 'glasses', keywords: ['view', 'vision', 'read'] },
  { icon: faBath, name: 'bath', keywords: ['relax', 'bathroom', 'shower'] },
];
