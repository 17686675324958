/* eslint-disable @typescript-eslint/dot-notation */
import { isNull, isEmpty, omit } from 'lodash';
import moment from 'moment';

import { message } from '@affiliates/AspireUI';
import {
  IAffiliateLinksFormValues,
  IShopifyPromoCodeFormValues,
  PrefixTypeStrategy,
  RecurringCycleLimit,
  TFormValues,
  PurchaseType,
  ClientForSync,
  TFormOptionData,
} from '@affiliates/components';
import { OFFER_ACTIONS } from '@affiliates/constants/offers';
import {
  CreateOfferInput,
  PayoutInput,
  OFFER_PAYOUT_ACTION,
  OFFER_PAYOUT_TYPE,
  OFFER_PAYOUT_TYPE_PROMO,
  OFFER_PROMO_PREFIX_TYPE,
  OFFER_SOURCE,
  OFFER_STATUS,
  ShopifyProductCollectionInput,
  UpdateOfferInput,
  UserInfoInput,
  SHOPIFY_SYNC_ACTION,
  UpdateClientForSync,
  CLIENT_CONNECTION_STATUS,
  OFFER_PRICE_RULE_TYPE,
  CreatePriceRuleInput,
} from '@affiliates/types/globalTypes';
import { useUploadContent } from '@frontend/app/hooks';
import { useQueryParams } from '@frontend/app/hooks';
import { Modal } from '@revfluence/fresh';
import { deepEqual } from '@frontend/applications/AffiliatesApp/utils';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { useOfferPersistence } from './useOfferPersistence';
import { IChildArgs } from '../types';

const isTuneSource = (
  values: IChildArgs<TFormValues>['values'],
): values is IChildArgs<IAffiliateLinksFormValues>['values'] => values.source === OFFER_SOURCE.TUNE;
const isShopifySource = (
  values: IChildArgs<TFormValues>['values'],
): values is IChildArgs<IShopifyPromoCodeFormValues>['values'] => values.source === OFFER_SOURCE.SHOPIFY;

const convertToUpdatePayload = (
  payload: CreateOfferInput,
  values: IChildArgs<TFormValues>['values'],
  linkedIds: number[],
  initialValues: TFormValues,
  status?: OFFER_STATUS,
  profileId?: string,
  profileEmail?: string,
  profileName?: string,
  migrateToNewFlow?: boolean,
): UpdateOfferInput => {
  let updatedPayouts = values?.payoutOptions ?? [];
  updatedPayouts = updatedPayouts.filter((payout) => {
    if (payout?.label) return true;
    return false;
  });
  const initialPayouts = initialValues?.payoutOptions ?? [];
  let newUpdatedPayouts: PayoutInput[] = [];

  updatedPayouts.forEach((payout) => {
    if (!payout?.id) {
      // New Payout
      newUpdatedPayouts.push({
        label: payout.label,
        payoutType: payout.payoutType as OFFER_PAYOUT_TYPE,
        flatPayout: Number(payout.flatPayout),
        percentPayout: Number(payout.percentPayout),
        isDefault: payout.isDefault,
        id: null,
        payoutAction: OFFER_PAYOUT_ACTION.ADD,
      });
    } else {
      // Update Payout
      const payoutToUpdate = initialPayouts.find((p) => p.id === payout.id);
      if (
        payoutToUpdate.label !== payout.label
        || payoutToUpdate.payoutType !== payout.payoutType
        || payoutToUpdate.flatPayout !== payout.flatPayout
        || payoutToUpdate.percentPayout !== payout.percentPayout
        || payoutToUpdate.isDefault !== payout.isDefault
      ) {
        newUpdatedPayouts.push({
          label: payout.label,
          payoutType: payout.payoutType as OFFER_PAYOUT_TYPE,
          flatPayout: Number(payout.flatPayout),
          percentPayout: Number(payout.percentPayout),
          isDefault: payout.isDefault,
          id: payout.id,
          payoutAction: OFFER_PAYOUT_ACTION.UPDATE,
        } as PayoutInput);
      } else {
        newUpdatedPayouts.push({
          label: payoutToUpdate.label,
          payoutType: payoutToUpdate.payoutType as OFFER_PAYOUT_TYPE,
          flatPayout: Number(payoutToUpdate.flatPayout),
          percentPayout: Number(payoutToUpdate.percentPayout),
          isDefault: payoutToUpdate.isDefault,
          id: payoutToUpdate.id,
          payoutAction: OFFER_PAYOUT_ACTION.NOUPDATE,
        });
      }
    }
  });
  initialPayouts.forEach((payout) => {
    const nPayout = newUpdatedPayouts.find((p) => p.id === payout.id);
    if (!nPayout) {
      newUpdatedPayouts.push({
        label: payout.label,
        payoutType: payout.payoutType as OFFER_PAYOUT_TYPE,
        flatPayout: Number(payout.flatPayout),
        percentPayout: Number(payout.percentPayout),
        isDefault: false,
        id: payout.id,
        payoutAction: OFFER_PAYOUT_ACTION.REMOVE,
      });
    }
  });
  newUpdatedPayouts = createAndUpdatePayoutPayload(newUpdatedPayouts);
  let updateClientForSyncInput: UpdateClientForSync[] = [];
  if (isShopifySource(values)) {
    updateClientForSyncInput = updateOfferClientForSync(values.isMultipleShopifySyncEnabled, values.isSameDiscountMultipleShopify, values.priceRuleAmount, values.clientsForSync, (initialValues as IShopifyPromoCodeFormValues).allClientForSync);
  }
  const offer = {
    ...omit(payload, 'isNewFlow'), // removing is new flow in case of update offer
    links: payload.links.map((link, index) => {
      let linkStatus = link.status;
      switch (status) {
        case OFFER_STATUS.ACTIVE:
        case OFFER_STATUS.DELETED:
          linkStatus = status;
          break;
      }
      return {
        ...link,
        id: linkedIds[index],
        status: linkStatus,
        payouts: newUpdatedPayouts,
      };
    }),
    promos: payload.promos.map((promo, index) => {
      let promoStatus = promo.status;
      switch (status) {
        case OFFER_STATUS.ACTIVE:
        case OFFER_STATUS.DELETED:
          promoStatus = status;
          break;
      }
      return {
        ...promo,
        id: linkedIds[index],
        status: promoStatus,
        payouts: newUpdatedPayouts,
        updatedClientsForSync: updateClientForSyncInput,
      };
    }),
    userInfo: {
      clientId: profileId,
      email: profileEmail,
      name: profileName,
    },
    migrateToNewFlow,
  };
  return offer as UpdateOfferInput;
};

export const useOfferFormSubmit = (
  offerId: number | null,
  linkedId: number | null,
  offerImage: File | null,
  values: IChildArgs<TFormValues>['values'],
  setIsSaving: (saving: boolean) => void,
  onSuccess: (offerId: number, mode: OFFER_ACTIONS) => void,
  profile: UserInfoInput,
  migrateToNewFlow: boolean,
  migrateToGraphQL: boolean,
  isSubscriptionEnable: boolean,
  initialValues: TFormValues,
  hasMembers: boolean,
  formOptionData: TFormOptionData,
  isTouched: boolean,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
) => {
  const { upload } = useUploadContent({
    serviceName: 'sales_tracking_app',
    parentFolder: 'offers',
  });
  const query = useQueryParams();
  const {
    createOffer, deleteOffer, restoreOffer, updateOffer,
  } = useOfferPersistence(offerId, setIsSaving, onSuccess);
  const onSubmit = (buttonStatusChange?: OFFER_STATUS) => async () => {
    const allSubscriptionEligible = values.source === OFFER_SOURCE.SHOPIFY && values.isMultipleShopifySyncEnabled && values.clientsForSync.length ? values.clientsForSync.every((advertiser) => {
      const connection = connectedAdvertiserForSync.find((conn) => conn.connectedAdvertiserId === advertiser.advertiserId);
      return connection.isSubscriptionEligible;
    }) && isSubscriptionEnable : isSubscriptionEnable;

    const createPayload = convertToCreatePayload(values, migrateToGraphQL, formOptionData, allSubscriptionEligible);

    setIsSaving(true);
    if (values.source === OFFER_SOURCE.SHOPIFY && !allSubscriptionEligible && values.purchaseType !== PurchaseType.ONE_TIME) {
      message.destroy();
      message.error('All selected store must have subscription enabled to create and sync subscription based offer on them');
      setIsSaving(false);
      return null;
    } else if (
      initialValues.source === OFFER_SOURCE.SHOPIFY
      && values.source === OFFER_SOURCE.SHOPIFY
      && initialValues.purchaseType !== PurchaseType.ONE_TIME
      && values.purchaseType === PurchaseType.ONE_TIME && !allSubscriptionEligible) {
        message.destroy();
        message.error('All selected store must have subscription enabled to create and sync subscription based offer on them');
        setIsSaving(false);
        return null;
    }

    if (!isNull(offerImage)) {
      try {
        const imageUrl = await upload(offerImage, 'image');
        createPayload.imageUrl = imageUrl;
      } catch (err) {
        setIsSaving(false);
        message.error('Failed to upload image.');
        return;
      }
    }

    if (isNull(offerId)) {
      if (query.has('program_id')) {
        createPayload.programId = parseInt(query.get('program_id'), 10);
      }

      return createOffer(createPayload);
    }
    const updatePayload = convertToUpdatePayload(
      createPayload,
      values,
      [linkedId],
      initialValues,
      buttonStatusChange,
      profile.clientId,
      profile.email,
      profile.name,
      migrateToNewFlow,
    );
    const isOfferChanged = isOfferChange(initialValues, values);
    if (!isTouched && !isOfferChanged && ![OFFER_STATUS.ACTIVE, OFFER_STATUS.DELETED].includes(buttonStatusChange)) {
      message.info('There are no changes to update');
      setIsSaving(false);
      return;
    }
    const onOfferFormSubmit = (buttonStatusChange: OFFER_STATUS, updatePayload: UpdateOfferInput) => {
      switch (buttonStatusChange) {
        case OFFER_STATUS.ACTIVE:
          return restoreOffer(updatePayload);
        case OFFER_STATUS.DELETED:
          return deleteOffer(updatePayload);
        default:
          return updateOffer(updatePayload);
      }
    };
    if (values.source === OFFER_SOURCE.SHOPIFY) {
      const updatedClientsForSync = updatePayload?.promos[0]?.updatedClientsForSync || [];
      const isMultipleShopifyChange = updatedClientsForSync.some((client) => client?.action !== SHOPIFY_SYNC_ACTION.NOUPDATE);

      setIsSaving(false);
      if (values.isMultipleShopifySyncEnabled && isMultipleShopifyChange && hasMembers) {
        Modal.confirm({
          title: 'Updating this offer will sync the codes for existing members',
          content: 'This offer contains members with promo codes. Upon updating this offer, these promo codes will also be synced to your other selected stores.',
          okText: 'Update and Sync',
          onOk: async () => {
            setIsSaving(true);
            onOfferFormSubmit(buttonStatusChange, updatePayload);
          },
        });
      } else if (!values.isMultipleShopifySyncEnabled && (initialValues as IShopifyPromoCodeFormValues).isMultipleShopifySyncEnabled && isMultipleShopifyChange && hasMembers) {
        Modal.confirm({
          title: 'Are you sure you want to disable sync of this offer across other stores?',
          content: 'Existing codes will be removed from your connected Shopify stores and no further syncs will occur.',
          okText: 'Continue to Sync',
          onOk: async () => {
            setIsSaving(true);
            onOfferFormSubmit(buttonStatusChange, updatePayload);
          },
        });
      } else if (values.isUngrouped !== (initialValues as IShopifyPromoCodeFormValues).isUngrouped) {
        Modal.confirm({
          type: 'info',
          title: 'Confirm change to promo code dates settings?',
          content: `You are about to change the promo codes date settings from ${values.isUngrouped ? '"Unified Date" to "Individual Dates"' : '"Individual Dates" to "Unified Date"'} . This will regenerate all existing promo codes within the offer, which may take up to 15 minutes to complete. All previous performance statistics will remain unaffected by this change.`,
          okText: 'Confirm',
          onOk: () => {
            setIsSaving(true);
            onOfferFormSubmit(buttonStatusChange, updatePayload);
          },
          onCancel: () => {

          },
        });
      } else {
        setIsSaving(true);
        onOfferFormSubmit(buttonStatusChange, updatePayload);
      }
    } else {
      onOfferFormSubmit(buttonStatusChange, updatePayload);
    }
  };

  return {
    onDelete: onSubmit(OFFER_STATUS.DELETED),
    onFinish: onSubmit(),
    onUnDelete: onSubmit(OFFER_STATUS.ACTIVE),
  };
};

const convertToCreatePayload = (values: IChildArgs<TFormValues>['values'], migrateToGraphQL: boolean, formOptionData: TFormOptionData, allSubscriptionEligible: boolean): CreateOfferInput => {
  let createPayoutInput: PayoutInput[] = [];
  if (migrateToGraphQL && values.payoutOptions) {
    values.payoutOptions.filter((payout) => {
      if (payout?.label) return true;
      return false;
    }).forEach((payout) => {
      createPayoutInput.push({
        label: payout.label,
        payoutType: payout.payoutType as OFFER_PAYOUT_TYPE,
        flatPayout: Number(payout.flatPayout),
        percentPayout: Number(payout.percentPayout),
        isDefault: payout.isDefault,
      });
    });
  }
  createPayoutInput = createAndUpdatePayoutPayload(createPayoutInput);
  if (isTuneSource(values)) {
    let flatPayout = Number(values.flatPayout);
    let percentPayout = Number(values.percentPayout);
    let defaultPayoutType = null;

    if (migrateToGraphQL && createPayoutInput.length) {
      createPayoutInput.forEach((payout) => {
        if (payout.isDefault) {
          flatPayout = Number(payout.flatPayout);
          percentPayout = Number(payout.percentPayout);
          defaultPayoutType = payout.payoutType;
        }
      });
    }
    let expirationDate: Date | moment.Moment | string = values.expirationDate;
    if (expirationDate) {
      if (moment.isMoment(values.expirationDate)) {
        // is moment when you adjust day from form
        expirationDate = new Date(expirationDate.format('YYYY/MM/DD'));
      } else {
        // is string when it is has not been adjusted
        expirationDate = new Date(((expirationDate as unknown) as string).replace(/-/g, '/'));
      }
      expirationDate.setHours(0, 0, 0, 0);
    }
    // Replace these variables with your data source or user input
    const utm_source = values.utmSource;
    const utm_medium = values.utmMedium;
    const utm_campaign = values.utmCampaign;
    const utm_content = values.utmContent;
    const utm_term = values.utmTerm;
    const customUTMParameters = {};

    if (!isEmpty(values?.customUTMParameters)) {
      for (const customParameter of values.customUTMParameters) {
        if (!isEmpty(customParameter.key) && !isEmpty(customParameter.value)) {
          customUTMParameters[customParameter.key] = customParameter.value;
        }
      }
    }
    // Create the data object with non-null values using the spread syntax and ternary operator
    const dataObject = {
      ...(utm_source ? { utm_source } : {}),
      ...(utm_medium ? { utm_medium } : {}),
      ...(utm_campaign ? { utm_campaign } : {}),
      ...(utm_content ? { utm_content } : {}),
      ...(utm_term ? { utm_term } : {}),
      ...(customUTMParameters ? { ...customUTMParameters } : {}),
    };
    let utmObjects = null;
    if (values.isAdvanceUrlEnable) {
      utmObjects = dataObject;
    }
    return {
      description: values.description,
      expirationDate,
      imageUrl: values.imageUrl,
      icon: values.icon,
      name: values.name,
      links: [{
        conversionTrackingType: values.conversionTrackingType,
        conversionType: values.conversionType,
        flatPayout: isNaN(flatPayout) ? null : flatPayout,
        payoutType: defaultPayoutType || values.payoutType,
        percentPayout: isNaN(percentPayout) ? null : percentPayout,
        status: values.status,
        url: values.url,
        utmFields: utmObjects,
        payouts: createPayoutInput,
        creatorDeeplink: values.creatorDeeplink,
        domains: values.multipleDomain,
        allowMultipleConversions: values?.allowMultipleConversions,
        thirdPartyTracking: values?.thirdPartyTracking,
        isThirdPartyIntegration: values?.isThirdPartyIntegration,
        trackThirdPartyPayout: values?.trackThirdPartyPayout,
      }],
      promos: [],
      isNewFlow: migrateToGraphQL,

    };
  }
  if (isShopifySource(values)) {
    let flatPayout = null;
    let percentPayout = null;
    let utmObjects = null;

    if (values.isPromoLink) {
      // Prepare standard UTM parameters
      const standardUTMParameters = {
        utm_source: values.utmSource,
        utm_medium: values.utmMedium,
        utm_campaign: values.utmCampaign,
        utm_content: values.utmContent,
        utm_term: values.utmTerm,
      };

      // Remove empty string UTM parameters dynamically
      const filteredStandardUTMParameters = Object.fromEntries(
        Object.entries(standardUTMParameters).filter(([_, value]) => value && value.trim() !== ''),
      );

      // Handle custom UTM parameters
      const customUTMParameters: Record<string, string> = {};
      if (!isEmpty(values?.customUTMParameters)) {
        for (const customParameter of values.customUTMParameters) {
          if (!isEmpty(customParameter.key) && !isEmpty(customParameter.value)) {
            customUTMParameters[customParameter.key] = customParameter.value.trim();
          }
        }
      }

      // Combine standard and custom UTM parameters
      utmObjects = {
        ...filteredStandardUTMParameters,
        ...customUTMParameters,
      };
    }
    switch (values.payoutType) {
      case OFFER_PAYOUT_TYPE_PROMO.CONVERSION:
        flatPayout = Number(values.flatPayout);
        break;
      case OFFER_PAYOUT_TYPE_PROMO.SALE:
        percentPayout = Number(values.percentPayout);
        break;
      case OFFER_PAYOUT_TYPE_PROMO.CONVERSION_AND_SALE:
        flatPayout = Number(values.flatPayout);
        percentPayout = Number(values.percentPayout);
        break;
    }
    let prefixType = OFFER_PROMO_PREFIX_TYPE.FULL_NAME;
    let codeSuffix = '';
    switch (values.prefixType) {
      case PrefixTypeStrategy.FULL_NAME_ONLY:
        break;
      case PrefixTypeStrategy.FULL_NAME_PLUS_SUFFIX:
        codeSuffix = values.codeSuffix;
        break;
      case PrefixTypeStrategy.INSTAGRAM_PLUS_SUFFIX:
        prefixType = OFFER_PROMO_PREFIX_TYPE.IG_USERNAME;
        codeSuffix = values.codeSuffix;
        break;
      case PrefixTypeStrategy.INSTAGRAM_ONLY:
        prefixType = OFFER_PROMO_PREFIX_TYPE.IG_USERNAME;
        break;
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_PLUS_SUFFIX:
        prefixType = OFFER_PROMO_PREFIX_TYPE.FIRST_INITIAL_LAST_NAME;
        codeSuffix = values.codeSuffix;
        break;
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY:
        prefixType = OFFER_PROMO_PREFIX_TYPE.FIRST_INITIAL_LAST_NAME;
        break;
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_PLUS_SUFFIX:
        prefixType = OFFER_PROMO_PREFIX_TYPE.FIRST_NAME_LAST_INITIAL;
        codeSuffix = values.codeSuffix;
        break;
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY:
        prefixType = OFFER_PROMO_PREFIX_TYPE.FIRST_NAME_LAST_INITIAL;
        break;
    }
    let recurringCycleLimitAmount;
    switch (values.offerCodePurchaseRestrictionsRule) {
      case RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT:
        recurringCycleLimitAmount = 1;
        break;
      case RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS:
        recurringCycleLimitAmount = values.recurringCycleLimitAmount.toString();
        break;
      case RecurringCycleLimit.DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS:
        recurringCycleLimitAmount = 0;
        break;
      default:
        recurringCycleLimitAmount = 0;
        break;
    }

    let isOneTime = false;
    let isSubscription = false;
    if (allSubscriptionEligible) {
      switch (values.purchaseType) {
        case PurchaseType.ONE_TIME:
          isOneTime = true;
          isSubscription = false;
          break;
        case PurchaseType.SUBSCRIPTION:
          isSubscription = true;
          isOneTime = false;
          break;
        case PurchaseType.BOTH:
          isOneTime = true;
          isSubscription = true;
          break;
        default:
          break;
      }
    }
    let defaultPayoutType = null;
    if (migrateToGraphQL && createPayoutInput.length) {
      createPayoutInput.forEach((payout) => {
        if (payout.isDefault) {
          flatPayout = Number(payout.flatPayout);
          percentPayout = Number(payout.percentPayout);
          defaultPayoutType = payout.payoutType;
        }
      });
    }
    const newProdList: ShopifyProductCollectionInput[] = [];
    const productCollections = formOptionData.productCollectionOptions;
    const productCollectionsIds = values?.productCollections || [];
    for (const collectionId of productCollectionsIds) {
      const collection = productCollections.find((productCollection) => productCollection.id === collectionId);
      if (collection) {
        newProdList.push({
          id: collection.id,
          title: collection.title,
        });
      }
    }

    // To create payload for multiple shopify price rule
    let clientsForSyncInput: CreatePriceRuleInput[] = [];
    if (values?.clientsForSync?.length) {
      const clientsForSync = values?.clientsForSync || [];
      clientsForSync.forEach((client) => {
        clientsForSyncInput.push({
          advertiserId: client.advertiserId,
          priceRuleAmount: Number(client.priceRuleAmount),
          priceRuleType: client.priceRuleType ?? OFFER_PRICE_RULE_TYPE.PERCENTAGE,
          discountCodeGid: client.discountCodeGid,
        });
      });
      if (values.isSameDiscountMultipleShopify && values.priceRuleAmount) {
        clientsForSyncInput = clientsForSyncInput.map((client) => ({ ...client, priceRuleAmount: Number(values.priceRuleAmount) }));
      }
    }
    const expirationDate = new Date(2100, 0, 1, 0, 0, 0);
    return {
      description: values.description,
      expirationDate: values.isEndDateEnable ? values.endDate : expirationDate,
      imageUrl: values.imageUrl,
      icon: values.icon,
      name: values.name,
      links: values.isPromoLink
        ? [
            {
              utmFields: values.isAdvancedUrlEnabled && !isEmpty(utmObjects) ? utmObjects : null,
              conversionTrackingType: null,
              conversionType: null,
              payoutType: null,
              payouts: null,
              status: null,
              url: '',
            },
          ]
        : [],
      promos: [
        {
          codeSuffix: values.isSuffixSelected ? values.codeSuffix : codeSuffix,
          flatPayout,
          payouts: createPayoutInput,
          name: values.groupName,
          payoutType: defaultPayoutType || values.payoutType,
          percentPayout,
          prefixType,
          priceRuleAmount: Number(values.priceRuleAmount),
          priceRuleType: values.isMultipleShopifySyncEnabled ? OFFER_PRICE_RULE_TYPE.PERCENTAGE : values.priceRuleType,
          source: OFFER_SOURCE.SHOPIFY,
          specialLimitProductCollections: !isEmpty(values?.productCollections) || values?.productCollections == null,
          productCollections: values.isMultipleShopifySyncEnabled ? [] : newProdList,
          specialLimitNewCustomersOnly: !!values.specialLimitNewCustomersOnly,
          specialLimitOnePerSale: !!values.specialLimitOnePerSale,
          specialLimitUsageCapAmount: Number(values.specialLimitUsageCapAmount) || null,
          specialLimitUsageCapEnabled: !!values.specialLimitUsageCapEnabled,
          usageLimitAmount: Number(values.usageLimitAmount) || null,
          usageLimitRule: values.usageLimitRule,
          status: values.status,
          isUngrouped: values.isUngrouped,
          recurringCycleLimit: parseFloat(recurringCycleLimitAmount),
          isSubscription,
          isOneTime,
          codePrefix: values.isPrefixSelected ? values.codePrefix : '',
          startDate: values.activeDate,
          endDate: values.isEndDateEnable ? values.endDate : null,
          multiShopifyEnabled: values.isMultipleShopifySyncEnabled,
          clientsForSync: values.isMultipleShopifySyncEnabled ? clientsForSyncInput : [],
          discountCombination: {
            productDiscounts: values.productDiscounts,
            orderDiscounts: values.orderDiscounts,
            shippingDiscounts: values.shippingDiscounts,
          },
          isSecureCodes: values.isSecureCodes,
        },
      ],
      isNewFlow: migrateToGraphQL,
      isReadOnly: !!values.linkedShopifyOfferId,
      ...(!!values.linkedShopifyOfferId && {
        discountCodeGId: `gid://shopify/DiscountCodeNode/${values.linkedShopifyOfferId}`,
      }),
      isPromoLink: values.isPromoLink || false,
      isLandingPageEnabled: values.isLandingPageEnabled || false,
      shopifyRedirectUrl: values.isPromoLink ? values.shopifyRedirectUrl : null,
    };
  }
};

const createAndUpdatePayoutPayload = (payouts: PayoutInput[]): PayoutInput[] => payouts.map((payout) => {
  const { flatPayout, percentPayout, payoutType } = payout;
  switch (payoutType) {
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      return {
        ...payout,
        flatPayout,
        percentPayout,
      };
    case OFFER_PAYOUT_TYPE.CLICK:
      return {
        ...payout,
        flatPayout,
        percentPayout: null,
      };
    case OFFER_PAYOUT_TYPE.SALE:
      return {
        ...payout,
        flatPayout: null,
        percentPayout,
      };
    case OFFER_PAYOUT_TYPE.CONVERSION:
      return {
        ...payout,
        flatPayout,
        percentPayout: null,
      };
    default:
      return {
        ...payout,
      };
  }
});

const updateOfferClientForSync = (isMultipleShopifySyncEnabled: boolean, isSameDiscountMultipleShopify: boolean, priceRuleAmount: string, currentClientSync: ClientForSync[], initalClientSync: ClientForSync[]) => {
  let clineSyncUpdated: UpdateClientForSync[] = [];
  if (isSameDiscountMultipleShopify) {
    currentClientSync.forEach((client) => {
      client.priceRuleAmount = Number(priceRuleAmount);
      client.priceRuleType = OFFER_PRICE_RULE_TYPE.PERCENTAGE;
    });
  }
  if (!isMultipleShopifySyncEnabled) {
    for (const currentSync of initalClientSync) {
      clineSyncUpdated.push({
        advertiserId: currentSync.advertiserId,
        priceRuleAmount: currentSync.priceRuleAmount,
        priceRuleType: currentSync.priceRuleType,
        action: currentSync.status === CLIENT_CONNECTION_STATUS.DISABLED ? SHOPIFY_SYNC_ACTION.NOUPDATE : SHOPIFY_SYNC_ACTION.REMOVE,
      });
    }
    return clineSyncUpdated;
  }
  for (const currentSync of currentClientSync) {
    const oldSync = initalClientSync.find((i) => i.advertiserId === currentSync.advertiserId);
    if (oldSync) {
      clineSyncUpdated.push({
        advertiserId: oldSync.advertiserId,
        priceRuleAmount: currentSync.priceRuleAmount,
        priceRuleType: oldSync.priceRuleType,
        action: currentSync.status === CLIENT_CONNECTION_STATUS.ACTIVE && oldSync.status === CLIENT_CONNECTION_STATUS.DISABLED ? SHOPIFY_SYNC_ACTION.UPDATE : currentSync.priceRuleAmount === oldSync.priceRuleAmount ? SHOPIFY_SYNC_ACTION.NOUPDATE : SHOPIFY_SYNC_ACTION.UPDATE,
      });
    } else {
      clineSyncUpdated.push({
        advertiserId: currentSync.advertiserId,
        priceRuleAmount: currentSync.priceRuleAmount,
        priceRuleType: currentSync.priceRuleType,
        action: SHOPIFY_SYNC_ACTION.ADD,
      });
    }
  }

  for (const initialSync of initalClientSync) {
    const oldSync = currentClientSync.find((i) => i.advertiserId === initialSync.advertiserId);
    if (!oldSync) {
      clineSyncUpdated.push({
        advertiserId: initialSync.advertiserId,
        priceRuleAmount: initialSync.priceRuleAmount,
        priceRuleType: initialSync.priceRuleType,
        action: initialSync.status === CLIENT_CONNECTION_STATUS.DISABLED ? SHOPIFY_SYNC_ACTION.NOUPDATE : SHOPIFY_SYNC_ACTION.REMOVE,
      });
    }
  }
  clineSyncUpdated = clineSyncUpdated.map((client) => ({ ...client, priceRuleAmount: Number(client.priceRuleAmount) }));
  return clineSyncUpdated;
};

const isOfferChange = (initialValues: TFormValues, values: TFormValues): boolean => {
  if (values.source === OFFER_SOURCE.TUNE) {
    return !deepEqual<TFormValues>(initialValues, values, []);
  }

  if (values.source === OFFER_SOURCE.SHOPIFY) {
    return !deepEqual<TFormValues>(initialValues, values, ['isPrefixSelected', 'isSuffixSelected']);
  }
  return true;
};
