import React, { useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@frontend/shadcn/components/ui/dialog';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';

import DraggableList, { ListItem } from '@frontend/app/refresh-components/DraggableList';
import clsx from 'clsx';
import { CheckedState } from '@radix-ui/react-checkbox';
import { Input } from '@frontend/shadcn/components/ui/input';
import { logger } from '@common';
import { ColumnDefinitionInput } from '../table';

const { useState, useMemo } = React;

const renderSubColumns = (subColumns?: ColumnDefinitionInput[], depth: number = 0) => {
  if (!subColumns?.length) return null;

  return (
    <ul className={clsx(depth > 0 ? 'pl-5 list-disc' : '')}>
      {subColumns.map((subColumn) => (
        <li key={subColumn.id} className="text-xs text-gray-800">
          <div>{subColumn.header}</div>
          {renderSubColumns(subColumn.subColumns, depth + 1)}
        </li>
      ))}
    </ul>
  );
};

const columnToListItem = (column: ColumnDefinitionInput): ListItem<ColumnDefinitionInput> => {
  return {
    id: column.id,
    content: (
      <div className="flex flex-col gap-2">
        <div className="text-gray-600 text-sm">{column.header}</div>
        {renderSubColumns(column.subColumns)}
      </div>
    ),
    data: column,
    isFixed: column.isFixed,
    isRemovable: !column.isFixed,
  };
};

interface ColumnDialogProps {
  selectedColumns: ColumnDefinitionInput[];
  handleDragEndModalColumn: (items: ListItem<ColumnDefinitionInput>[]) => void;
  onToggleColumnCheckBox: (id: string) => void;
  onSave: (columns: ColumnDefinitionInput[]) => Promise<void>;
  onToggleSelectall: (checked: CheckedState) => void;
  children?: React.ReactNode;
}
const renderColumn = (
  column: ColumnDefinitionInput,
  onToggleColumnCheckBox: (id: string) => void,
  depth: number = 1,
  isParentChecked: boolean = true,
) => {
  // Calculate the number of selected subcolumns (hidden = false means selected)
  const selectedSubColumnsCount = column.subColumns?.filter((subColumn) => !subColumn.hidden).length ?? 0;

  // Check if the parent column is checked, if not, disable the subcolumns
  const isColumnChecked = !column.hidden && isParentChecked;
  const isDisabled = !isParentChecked; // If the parent is unchecked, disable the subcolumns

  return (
    <li key={column.id} className="space-y-1">
      <label className={`flex items-center space-x-2 ${isDisabled ? 'text-gray-400' : ''}`}>
        <Checkbox
          checked={isColumnChecked}
          onCheckedChange={() => onToggleColumnCheckBox(column.id)}
          className="rounded border-gray-300"
          disabled={isDisabled || column.isFixed}
          data-dd-action-name={`toggle-column-${column.header}`}
          aria-label={`Toggle ${column.header} column`}
        />
        <span>
          {column.header}
          {/* Only show count if there are subcolumns */}
          {column.subColumns && column.subColumns.length > 0 && ` (${selectedSubColumnsCount})`}
        </span>
      </label>

      {/* Render subcolumns with increasing indentation */}
      {(column.subColumns ?? []).length > 0 && (
        <ul className={`ml-${depth * 2} space-y-2`}>
          {(column.subColumns ?? []).map((subColumn) =>
            renderColumn(subColumn, onToggleColumnCheckBox, depth + 1, isColumnChecked),
          )}
        </ul>
      )}
    </li>
  );
};
const ColumnDialog: React.FC<ColumnDialogProps> = ({
  onToggleSelectall,
  selectedColumns,
  handleDragEndModalColumn,
  onToggleColumnCheckBox,
  onSave,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const areAllColumnsVisible = selectedColumns.every((col) => !col.hidden);
  const areAnyColumnsVisible = selectedColumns.some((col) => !col.hidden && !col.isFixed);
  // Filter columns based on search query
  const filteredColumns = selectedColumns.filter(
    (column) =>
      column.header.toLowerCase().includes(searchQuery.toLowerCase()) ||
      column.subColumns?.some((subColumn) => subColumn.header.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  const saveColumns = useCallback(async () => {
    setIsSaving(true);
    try {
      await onSave(selectedColumns);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSaving(false);
      setIsOpen(false);
    }
  }, [onSave, selectedColumns]);

  const leftColumns = useMemo(() => {
    return filteredColumns
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .map((column) => renderColumn(column, onToggleColumnCheckBox));
  }, [filteredColumns, onToggleColumnCheckBox]);

  const selectAllState = areAllColumnsVisible ? true : areAnyColumnsVisible;
  const totalSelectedColumns = useMemo(() => {
    const countSelectedColumns = (columns: ColumnDefinitionInput[]): number => {
      return columns.reduce((count, column) => {
        const selectedSubColumnsCount = column.subColumns ? countSelectedColumns(column.subColumns) : 0;
        return count + (!column.hidden ? 1 : 0) + selectedSubColumnsCount;
      }, 0);
    };

    return countSelectedColumns(selectedColumns);
  }, [selectedColumns]);

  const selectAllLabel = `${selectAllState ? 'Unselect All' : 'Select All'} (${totalSelectedColumns})`;

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="bg-white flex flex-col w-[600px] max-w-full max-h-full">
        <DialogHeader className="border-b pb-2">
          <DialogTitle>Edit Columns</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <div>Your changes will apply to this table for all users.</div>
          <div className="grid grid-cols-2 gap-4 pt-4 flex-grow">
            {/* Left Column */}
            <div className="pr-4">
              <h3 className="text-sm font-medium mb-2">Add Columns</h3>

              <Input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full border rounded-md px-3 py-2 mb-2"
                data-dd-action-name="search-columns"
                aria-label="Search columns"
              />
              <div className="pr-4 max-h-[344px] overflow-y-auto">
                <ul className="space-y-2">
                  <li key="selectAll" className="space-y-1">
                    <label className="flex items-center space-x-2">
                      <Checkbox
                        checked={selectAllState}
                        onCheckedChange={(checked) => onToggleSelectall(!!checked)}
                        className="rounded border-gray-300"
                        data-dd-action-name="toggle-all-columns"
                        aria-label={selectAllLabel}
                      />
                      <span>{selectAllLabel}</span>
                    </label>
                  </li>
                  {leftColumns}
                </ul>
              </div>
            </div>

            {/* Right Column with Draggable List */}
            <div className="border-l pl-4">
              <h3 className="text-sm font-medium mb-2">Selected Columns</h3>

              <div className="max-h-96 overflow-y-auto">
                <DraggableList
                  initialItems={selectedColumns.filter((col) => !col.hidden).map(columnToListItem)}
                  onItemsChange={handleDragEndModalColumn}
                  onItemRemoved={(item) => onToggleColumnCheckBox(item.id)}
                  data-dd-action-name="reorder-columns"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Footer with Cancel and Save */}
        <DialogFooter className="border-t flex justify-end gap-2">
          <DialogTrigger asChild>
            <Button variant="outline" data-dd-action-name="cancel-column-changes">
              Cancel
            </Button>
          </DialogTrigger>
          <Button
            loading={isSaving}
            onClick={() => {
              saveColumns();
            }}
            data-dd-action-name="save-column-changes"
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ColumnDialog;
