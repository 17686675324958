import * as React from 'react';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';

import { H3 } from '@frontend/shadcn/components/typography/h3';
import {
  IAffiliateLinksFormValues,
  IShopifyPromoCodeFormValues,
  PROMO_OFFER_TYPES,
  PurchaseType,
  RecurringCycleLimit,
} from '@affiliates/components';
import { ShieldCheckIcon, TagIcon } from '@revfluence/fresh-icons/solid/esm';
import { format } from 'date-fns';
import LinkSimpleIcon from '@revfluence/fresh-icons/regular/esm/LinkSimpleIcon';
import {
  Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@frontend/shadcn/components/ui/accordion';
import { capitalize } from 'lodash';
import { TvIcon } from '@revfluence/fresh-icons/regular';
import Tag from '@frontend/app/refresh-components/Tag';
import { Tooltip } from '@frontend/shadcn/components/ui/tooltip';
import { TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip';
import { OFFER_PRICE_RULE_TYPE, OFFER_PROMO_USAGE_LIMIT_RULE, OFFER_SOURCE } from '../../types/globalTypes';
import { RefreshOfferDefaultImage } from '../OfferImage/RefreshOfferDefaultImage';
import { generateLinkUrl, getDiscountCombinationMessage, getOfferStatusColor, getPayoutLabel, getUniqueRefreshTagColor, getUsageLimitMessage, getSamplePromoCode } from '../OfferForm/utils/OfferFormUtils';
import { OfferInputHeader } from '../OfferForm/FormSections/CommonSection/FormElements/RefreshForm/OfferElementHeader';

interface IProps {
  values: IAffiliateLinksFormValues | IShopifyPromoCodeFormValues;
  imageUrl: string;
  icon: string | null;
  createdOn: string;
}

export const OfferPreviewCard: React.FC<Readonly<IProps>> = React.memo((props) => {
  const { values, imageUrl, icon, createdOn } = props;
  return (
    <div className="py-6">
      <H3 fontWeight="font-medium" className="text-primary m-0 mb-4">Preview</H3>
      {/* SHOPIFY OFFER PREVIEW CARD */}

      {values.source === OFFER_SOURCE.SHOPIFY && (
        <Card className="p-6">
          <CardContent className="p-0">
            <RefreshOfferDefaultImage
              imageUrl={imageUrl}
              source={values.source}
              className="w-full h-48"
              icon={icon}
            />
            <div className="border-b border-grey-2 space-y-2 py-3">
              <div className="flex justify-between">
                {values.name ? (<p className="text-sm text-grey-6 font-normal m-0">{values.name}</p>) :
                  (<p className="text-sm text-grey-4 font-normal m-0">Name of your offer</p>)}
                <Tag bgColor={getOfferStatusColor(values.status)} textColor="text-grey-0" label={capitalize(values.status)} />

              </div>
              <div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="m-0 text-grey-4 text-xs font-medium break-all">
                      {values.description.length > 150
                        ? `${values.description.slice(0, 150)}...`
                        : values.description}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs break-words text-center bg-grey-6 text-grey-0 p-[6px] rounded-lg"><span>{values.description}</span></TooltipContent>
                </Tooltip>
              </div>
              <div>
                {values.offerType === PROMO_OFFER_TYPES.AMOUNT_OFF && (
                  <p className="text-[24px] text-primary font-medium m-0">{values.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT ? '$' : ''}{values.priceRuleAmount}{values.priceRuleType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '%' : ''} Discount</p>

                )}
                {values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER && (
                  <p className="text-[24px] text-primary font-medium m-0">Buy X Get Y offer</p>
                )}
              </div>
              <div>
                {values.isMultipleShopifySyncEnabled && (
                  <Tag bgColor="bg-grey-6" textColor="text-grey-0" className="rounded-lg" label={<><ShopifyIcon />{' '}<span>Promo Code Sync Enabled</span></>} />
                )}
              </div>
              <div>
                <p className="text-primary font-medium text-sm m-0">
                  Created on
                  {' '}
                  {format(new Date(createdOn), 'MMM dd, yyyy')}
                </p>
              </div>
            </div>
            <div className="border-b border-grey-2 py-3">
              {values.payoutOptions && values.payoutOptions.length > 0 && (
                <>
                  <p className="m-0 text-xs text-primary font-medium">Commissions</p>
                  <div className="flex gap-2 flex-wrap">
                    {values.payoutOptions.map((payout, index) => {
                      const label = getPayoutLabel(payout);
                      if (!label) return null;

                      return (
                        <div
                          key={index}
                          className={`${getUniqueRefreshTagColor(index)} text-grey-0 rounded-lg h-[22px] px-2 w-max`}
                        >
                          {label}
                        </div>
                      );
                    })}
                  </div>

                </>
              )}
            </div>
            <div className="border-b border-grey-2 py-3">
              <div className="flex items-center gap-2">
                <TagIcon fontSize={16} className="text-yellow-4" />
                <OfferInputHeader
                  text={`Naming Strategy: Creator ${values.codePrefix ? `${values.codePrefix} +` : ''} ${values.prefixType ? `${values.prefixType} ` : ''} ${values.codeSuffix ? `+ ${values.codeSuffix}` : ''}`}
                  tooltipText="You can customize the default naming strategy for promo codes based on your campaign needs in the additional settings."
                  className="!m-0"
                />
              </div>
              <p className="text-xs text-grey-4 font-medium block">
                e.g. For John Doe, code will be{' '}
                {getSamplePromoCode(values)}
              </p>
              {values.isSecureCodes && (
                <div className="flex items-center gap-2 bg-green-0 border border-dashed border-green-3 py-2 px-3 rounded-lg">
                  <div className="p-[6px] bg-green-4 rounded-lg flex items-center justify-center">
                    <ShieldCheckIcon fontSize={20} className="text-grey-0" />
                  </div>
                  <div>
                    <p className="m-0 text-grey-6 font-normal text-xs">
                      A single-use promo code with a string of random digits for security will be generated when customers click the link.
                    </p>
                  </div>
                </div>)}
            </div>
            {(values.isPromoLink || values.isLandingPageEnabled) && (
              <div className="border-b border-grey-2 py-3">
                {values.isPromoLink && (
                  <div className="flex items-center gap-2">
                    <LinkSimpleIcon fontSize={16} className="text-magenta-4" />
                    <OfferInputHeader text="Creators will receive a shareable link" tooltipText="Creators will receive a shareable link" className="!m-0" />
                  </div>
                )}
                {values.isLandingPageEnabled && (
                  <div className="flex items-center gap-2">
                    <TvIcon fontSize={16} className="text-blue-4" />
                    <OfferInputHeader text="Personalized CreatorStore " tooltipText="Personalized CreatorStore " className="!m-0" />
                  </div>
                )}
              </div>
            )}

            <div className="py-3">
              <p className="text-sm text-primary font-medium m-0">Offer Details</p>
              <ul className="list-disc list-inside text-primary text-sm font-normal">
                <li className="text-primary text-sm font-normal">
                  Applies to
                  {' '}
                  {values.purchaseType === PurchaseType.ONE_TIME ? 'one-time' : values.purchaseType === PurchaseType.SUBSCRIPTION ? 'Subscription' : 'Subscription and one-time'}
                  {' '}
                  purchase
                </li>
                {values.purchaseType === PurchaseType.SUBSCRIPTION && (
                  <li className="text-primary text-sm font-normal">
                    {values.offerCodePurchaseRestrictionsRule === RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT ? 'Limited to 1 recurring payment' : values.offerCodePurchaseRestrictionsRule === RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS ? `Limited to ${values.recurringCycleLimitAmount} recurring payments` : 'For all recurring payments'}
                  </li>
                )}
                {values.usageLimitRule === OFFER_PROMO_USAGE_LIMIT_RULE.NONE && (<li className="text-primary text-sm font-normal">No minimum purchase requirements</li>)}
                {values.usageLimitRule === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT && (
                  <li className="text-primary text-sm font-normal">
                    Minimum purchase of $
                    {' '}
                    {Number(values.usageLimitAmount).toFixed(2)}
                  </li>
                )}
                {values.usageLimitRule === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT && (
                  <li className="text-primary text-sm font-normal">
                    Minimum purchase of
                    {' '}
                    {values.usageLimitAmount}
                    {' '}
                    items
                  </li>
                )}
                <li className="text-primary text-sm font-normal">
                  {getUsageLimitMessage(
                    values.specialLimitOnePerSale,
                    values.specialLimitUsageCapEnabled,
                    values.specialLimitNewCustomersOnly,
                    values.specialLimitUsageCapAmount
                  )}
                </li>
                <li className="text-primary text-sm font-normal">
                  {getDiscountCombinationMessage(
                    values.productDiscounts,
                    values.orderDiscounts,
                    values.shippingDiscounts
                  )}
                </li>
                {values.productCollections && values.productCollections.length > 0 && (
                  <li className="text-primary text-sm font-normal">Applies to specific collections</li>
                )}
                {values.activeDate && (
                  <li className="text-primary text-sm font-normal">
                    Active from{' '}{format(new Date(values.activeDate.toString()), 'MMM dd, yyyy')}

                    {values.endDate && (
                      <>
                        {' '}
                        until{' '}
                        {format(new Date(values.endDate.toString()), 'MMM dd, yyyy')}
                      </>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </CardContent>
        </Card>
      )}
      {/* TUNE OFFER PREVIEW CARD */}
      {values.source === OFFER_SOURCE.TUNE && (
        <Card className="p-4">
          <CardContent className="p-0 flex flex-col">
            <RefreshOfferDefaultImage
              imageUrl={imageUrl}
              source={values.source}
              className="w-full h-48"
              icon={icon}
            />
            <div className="border-b border-grey-2 space-y-2 py-3">
              <div className="flex justify-between">
                {values.name ? (<p className="text-sm text-grey-6 font-normal m-0">{values.name}</p>) :
                  (<p className="text-sm text-grey-4 font-normal m-0">Name of your offer</p>)}
                <Tag bgColor={getOfferStatusColor(values.status)} textColor="text-grey-0" label={capitalize(values.status)} />
              </div>
              <div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="m-0 text-grey-4 text-xs font-medium break-all">
                      {values.description.length > 150
                        ? `${values.description.slice(0, 150)}...`
                        : values.description}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs break-words text-center bg-grey-6 text-grey-0 p-[6px] rounded-lg"><span>{values.description}</span></TooltipContent>
                </Tooltip>
              </div>
              <div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="text-sm text-primary font-medium underline m-0 break-all cursor-pointer">
                      {generateLinkUrl(values, true)}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-[500px] break-words text-center bg-grey-6 text-grey-0 p-[6px] rounded-lg">
                    <span>{generateLinkUrl(values, false)}</span>
                  </TooltipContent>
                </Tooltip>
              </div>
              <div>
                <p className="text-primary font-medium text-sm m-0">
                  Created on
                  {' '}
                  {format(new Date(createdOn.toString()), 'MMM dd, yyyy')}
                </p>
              </div>
            </div>
            {values.payoutOptions && values.payoutOptions.length > 0 && (
              <div className="border-b border-grey-2 py-3">
                <p className="m-0 text-xs text-primary font-medium">Commissions</p>
                <div className="flex gap-2 flex-wrap">
                  {values.payoutOptions.map((payout, index) => {
                    const label = getPayoutLabel(payout);
                    if (!label) return null;

                    return (
                      <div
                        key={index}
                        className={`${getUniqueRefreshTagColor(index)} text-grey-0 rounded-lg h-[22px] px-2 w-max`}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div>

              </div>
            )}
            {/* <div className="border-b border-grey-2 py-3 flex items-center gap-2">
              <LinkSimpleIcon fontSize={16} className="text-magenta-4" />
              <OfferInputHeader text="Creators will receive a shareable link" tooltipText="Creators will receive a shareable link" className="!m-0" />
            </div> */}
            <div className="py-3">
              <p className="text-sm text-primary font-medium m-0">Offer Details</p>
              <ul className="list-disc list-inside text-primary text-sm font-normal">
                <li className="text-primary text-sm font-normal">Offer ends on {format(new Date(values.expirationDate.toString()), 'MMM dd, yyyy')}</li>
                {values.creatorDeeplink
                  && (<li className="text-primary text-sm font-normal">Creators are allowed to create custom deeplnks.</li>)}
                {values.creatorDeeplink && values.multipleDomain && values.multipleDomain.length > 0 && (
                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="multipleDomain">
                      <AccordionTrigger className="text-primary p-0 font-normal ">
                        <li>Other supported domains</li>
                      </AccordionTrigger>
                      <AccordionContent className="pl-5 text-grey-700">
                        <ul className="list-disc list-inside pl-4">
                          {values.multipleDomain.map((domain, index) => (
                            <li key={index}>{domain}</li>
                          ))}
                        </ul>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                )}
              </ul>
            </div>
          </CardContent>
        </Card>
      )}

    </div>
  );
});
OfferPreviewCard.displayName = 'OfferPreviewCard';
