import React from 'react';
import { WidgetContainer, DataTable, Empty } from '@frontend/app/refresh-components';
import { ColumnDef } from '@tanstack/react-table';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@frontend/shadcn/components/ui/avatar';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import {
  BuildingColumnsIcon,
  ArrowUpRightFromSquareIcon,
  ArrowRightIcon,
  CoinsIcon,
} from '@revfluence/fresh-icons/solid/esm';
import {
  TriangleExclamationIcon,
  FileArrowDownIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { Link } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv';
import { cn } from '@/shadcn/lib/utils';
import { useGetProjectBudgetDashboardData } from '../hooks/budgetAllocation/useGetProjectBudgetDashboardData';

const { useMemo } = React;
type RowItem = {
  id: number;
  budgetName: string;
  budgetImage: string;
  proportion: number;
  totalBudget: number;
  briefAllocated: number;
  remaining: number;
  spend: number;
};

interface BudgetsAssignedToProjectWidgetProps {
  projectId?: number;
  fiscalYear?: string;
  className?: string;
}
export default function BudgetsAssignedToProjectWidget(props: BudgetsAssignedToProjectWidgetProps) {
  const { projectId, fiscalYear, className } = props;
  const isParemetersAbsent = !projectId || !fiscalYear;

  const { projectBudgetDashboardData, loading } = useGetProjectBudgetDashboardData({
    variables: {
      fiscalYear,
      programId: projectId,
    },
    skip: isParemetersAbsent,
    fetchPolicy: 'no-cache',
  });
  const data: RowItem[] = useMemo(() => {
    if (!loading && projectBudgetDashboardData?.budgetAccounts) {
      const totalBudget = projectBudgetDashboardData.budgetAccounts?.reduce(
        (sum, budget) => sum + budget.totalAmount,
        0,
      );

      const dataArray: RowItem[] = projectBudgetDashboardData.budgetAccounts?.map((budget) => ({
        id: budget.budgetId,
        budgetName: budget.budgetName,
        budgetImage: '',
        totalBudget: budget.totalAmount,
        proportion: !totalBudget ? 1 : budget.totalAmount === 0 ? 0 : budget.totalAmount / totalBudget,
        briefAllocated: 0,
        spend: budget.spentAmount,
        remaining: budget.totalAmount - budget.spentAmount,
      }));
      return dataArray;
    } else {
      return [];
    }
  }, [loading, projectBudgetDashboardData]);

  const showEmptyState = useMemo(() => {
    if (!data?.length) {
      return true;
    }
    return false;
  }, [data]);

  const emptyState = (
    <Empty
      icon={CoinsIcon}
      title="No budget allocated to projects yet."
      description="Start by allocating a budget to a project."
    />
  );

  const exportBudgetData = () => {
    const options = {
      headers: ['Name', 'Total Budget', 'Proportion', 'Spent', 'Remaining'],
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Project Budget Report',
    };

    const csvExporter = new ExportToCsv(options);
    const dataToExport = data.map((budgetData) => ({
      name: budgetData.budgetName,
      totalBudget: budgetData.totalBudget,
      proportion: (budgetData.proportion * 100).toFixed(2),
      spent: budgetData.spend,
      remaining: budgetData.remaining,
    }));

    csvExporter.generateCsv(dataToExport);
  };

  const columns: ColumnDef<RowItem>[] = [
    {
      accessorKey: 'budgetName',
      header: () => <div className="font-semibold text-[#1F1F21]">Source</div>,
      cell: ({ row }) => {
        const budgetName = row.getValue('budgetName') as string;
        const budgetImage = row.original.budgetImage as string;
        return (
          <div className="flex gap-1 items-center">
            <Avatar className="h-[24px] w-[24px] rounded-none">
              {budgetImage ? (
                <AvatarImage src={budgetImage} className="flex h-full w-full items-center justify-center rounded-lg" />
              ) : (
                <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-sm text-secondary">
                  <BuildingColumnsIcon />
                </AvatarFallback>
              )}
            </Avatar>
            <span>{budgetName}</span>
          </div>
        );
      },
      size: 250,
    },
    {
      accessorKey: 'totalBudget',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Budget</div>,
      cell: ({ row }) => {
        const totalBudget = parseFloat(row.getValue('totalBudget'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalBudget);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'proportion',
      header: () => <div className="text-center font-semibold text-[#1F1F21] w-full">% Proportional</div>,
      cell: ({ row }) => {
        const proportion = (parseFloat(row.getValue('proportion')) * 100).toFixed(2);
        return (
          <div className="text-center font-medium">
            <Badge variant="outline" className="text-[#1E79A9]">{`${proportion} %`}</Badge>
          </div>
        );
      },
    },
    // Will enable later with rollup
    // {
    //   accessorKey: 'briefAllocated',
    //   header: () => <div className="text-right font-semibold text-[#1F1F21]">Allocated (Briefs)</div>,
    //   cell: ({ row }) => {
    //     const briefAllocated = parseFloat(row.getValue('briefAllocated'));
    //     const formatted = new Intl.NumberFormat('en-US', {
    //       style: 'currency',
    //       currency: 'USD',
    //     }).format(briefAllocated);

    //     return <div className="text-right font-medium">{formatted}</div>;
    //   },
    // },
    {
      accessorKey: 'spend',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Total Spend</div>,
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend);

        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: 'remaining',
      header: () => <div className="text-right font-semibold text-[#1F1F21] w-full">Remaining</div>,
      cell: ({ row }) => {
        const remaining = parseFloat(row.getValue('totalBudget')) - parseFloat(row.getValue('spend'));
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(remaining);

        return (
          <div className={`flex gap-1 justify-end items-center ${remaining < 0 ? 'text-[#D48806]' : 'text-right'}`}>
            {formatted}
            {remaining < 0 && <TriangleExclamationIcon fontSize={16} />}
          </div>
        );
      },
    },
    {
      id: 'actions',
      header: () => null,
      cell: () => (
        <div className="text-center">
          <Link to={`/projects/${projectId}/settings/budget`}>
            <ArrowUpRightFromSquareIcon className="h-4 w-4" />
          </Link>
        </div>
      ),
    },
  ];

  const widgetActions = (
    <div className="flex items-center gap-2">
      <Button size="icon" variant="outline" onClick={exportBudgetData}>
        <FileArrowDownIcon className="h-4 w-4" />
      </Button>
    </div>
  );

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <>
      <DataTable columns={columns} data={data} />
      <div className="flex justify-end mt-1">
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/budget/dashboard?tab=DetailedView">
              <span className="flex gap-1 items-center">
                Detailed View
                <ArrowRightIcon fontSize={16} />
              </span>
            </Link>
          </TooltipTrigger>
          <TooltipContent>Detailed View</TooltipContent>
        </Tooltip>
      </div>
    </>
  );
  const content = showEmptyState ? emptyState : mainContent;
  return (
    <WidgetContainer
      widgetTitle="Source"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : content}
    </WidgetContainer>
  );
}
