import { H4 } from '@frontend/shadcn/components/typography/h4';
import { P } from '@frontend/shadcn/components/typography/p';
import { Alert, AlertTitle } from '@frontend/shadcn/components/ui/alert';
import { Button } from '@frontend/shadcn/components/ui/button';
import { XmarkIcon } from '@revfluence/fresh-icons/regular';
import { TriangleExclamationIcon, CircleInfoIcon } from '@revfluence/fresh-icons/solid';
import React, { useState, MouseEventHandler } from 'react';

interface IAlertProps {
  title: string;
  description?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  buttonText?: string;
  closeable?: boolean;
  buttonSize?: 'sm' | 'lg' | 'default' | 'xs' | 'icon' | 'headerIcon';
  type?: 'error' | 'info';
}

const alertStyles = {
  error: 'bg-alert-error border-red-4 text-primary',
  info: 'bg-grey-2 border-grey-5 text-primary',
};

const alertIcons = {
  error: <TriangleExclamationIcon className="w-4 h-4 mr-2 text-destructive" />,
  info: <CircleInfoIcon className="w-4 h-4 mr-2 text-primary" />,
};

const ShadCnAlert: React.FC<IAlertProps> = ({
  title,
  description,
  onClick,
  buttonText,
  closeable,
  buttonSize = 'default',
  type = 'error',
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Alert role="alert" className={`w-full flex items-center font-inter text-primary border ${alertStyles[type]}`}>
      {closeable && (
        <div className="cursor-pointer absolute right-2 top-2" onClick={handleClose}>
          <XmarkIcon className="w-4 h-4" />
        </div>
      )}
      <div className="flex flex-col md:flex-row md:items-center justify-center items-start w-full pr-6">
        <div className="flex-grow">
          <AlertTitle className="flex items-center">
            {alertIcons[type]}
            <H4 className="m-0">{title}</H4>
          </AlertTitle>
          <P>{description}</P>
        </div>
        {onClick && (
          <Button variant="outline" size={buttonSize} onClick={onClick} className="m-0 text-primary rounded-lg border border-grey-3 md:ml-4">
            {buttonText}
          </Button>
        )}
      </div>
    </Alert>
  );
};

export default ShadCnAlert;
