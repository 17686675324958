import React, { useState } from 'react';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Button } from '@frontend/shadcn/components/ui/button';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';

interface SearchInputProps {
  searchText: string;
  setSearchText: (text: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ searchText, setSearchText }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return isSearchOpen ? (
    <div className="relative">
      <Input
        value={searchText}
        placeholder="Search..."
        onChange={handleSearchChange}
        style={{ width: 200 }}
        autoFocus
        className="pr-6"
      />
      <span className="absolute top-1/2 -translate-y-1/2 right-2">
        <MagnifyingGlassIcon />
      </span>
    </div>
  ) : (
    <Button size="headerIcon" variant="ghost" onClick={() => setIsSearchOpen(true)}>
      <MagnifyingGlassIcon />
    </Button>
  );
};

export default SearchInput;
