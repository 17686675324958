import * as React from 'react';
import moment from 'moment';
import { first, isArray } from 'lodash';
import { isFunction } from 'lodash';
import { localToUTC, utcToLocal } from '@frontend/app/utils/date';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { Button } from '@frontend/shadcn/components/ui/button';
import { CalendarIcon } from '@revfluence/fresh-icons/regular/esm';
import { format } from 'date-fns';
import { cn } from '@frontend/shadcn/lib/utils';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';

const { useCallback, useMemo } = React;

interface IProps {
  value: Date;
  onChange?(value: Date);
}

export const DateEqual: React.FC<IProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  if (isArray(value)) {
    const date = new Date(first(value));
    onChange(localToUTC(date));
  }

  const handleChangeDate = useCallback((date: moment.Moment) => {
    if (isFunction(onChange)) {
      if (date) {
        onChange(localToUTC(date.toDate()));
      } else {
        onChange(null);
      }
    }
  }, [onChange]);

  const date = useMemo(() => {
    const val = isArray(value) ? first(value) : value;
    return val
      ? moment(utcToLocal(new Date(val)))
      : null;
  }, [value]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'w-full justify-start text-left',
            !date && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date.toDate(), 'PPP') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-[9999] pointer-events-auto">
        <Calendar
          mode="single"
          selected={date?.toDate()}
          onSelect={(date) => handleChangeDate(moment(date))}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};
