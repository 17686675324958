import gql from 'graphql-tag';

export const GET_SELECTION_CRITERIA = gql`
  query GetCatalogSelectionRuleByCatalogId($brandCatalogId: Int!) {
    getCatalogSelectionRuleByCatalogId(brandCatalogId: $brandCatalogId) {
      id
      brandCatalogId
      label
      description
      minQuantity
      maxQuantity
      maxPrice
      conditions {
        conditionName
        isRequired
        quantityType
        quantity
        conditionOperation
        collections {
          id
          name
        }
        vendors
        productTypes
        priceMin
        priceMax
        tags
        categories
        options {
          name
          values
        }
      }
    }
  }
`;

export const GET_SELECTION_CRITERIA_BY_ID = gql`
  query GetCatalogSelectionRuleById($id: Int!) {
    getCatalogSelectionRuleById(id: $id) {
      id
      brandCatalogId
      label
      description
      minQuantity
      maxQuantity
      maxPrice
      conditions {
        id
        conditionName
        isRequired
        quantityType
        quantity
        conditionOperation
        collections {
          id
          name
        }
        vendors
        productTypes
        priceMin
        priceMax
        tags
        categories
        options {
          name
          values
        }
        productMetaFields {
          metaFieldId
          name
          values
        }
        variantMetaFields {
          metaFieldId
          name
          values
        }
      }
      settings {
        showDescription
      }
    }
  }
`;

export const CREATE_SELECTION_CRITERIA = gql`
  mutation CreateCatalogSelectionRule($input: CreateCatalogSelectionRuleInput!) {
    createCatalogSelectionRule(input: $input) {
      id
      brandCatalogId
      label
      description
      minQuantity
      maxQuantity
      maxPrice
    }
  }
`;

export const UPDATE_SELECTION_CRITERIA = gql`
  mutation UpdateCatalogSelectionRule($id: Int!, $input: CreateCatalogSelectionRuleInput!) {
    updateCatalogSelectionRule(id: $id, input: $input) {
      id
      brandCatalogId
      label
      description
      minQuantity
      maxQuantity
      maxPrice
    }
  }
`;

export const GET_PRODUCT_ATTRIBUTES_BY_CLIENT_ID = gql`
  query GetProductAttributesByClientId($brandCatalogId: Int) {
    getProductAttributesByClientId(brandCatalogId: $brandCatalogId) {
      id
      clientId
      collections {
        id
        name
        internalId
      }
      vendors
      productTypes
      tags
      categories
      options {
        name
        values
      }
      productMetaFields {
        metaFieldId
        name
        values
      }
      variantMetaFields {
        metaFieldId
        name
        values
      }
    }
  }
`;

export const DELETE_SELECTION_CRITERIA_BY_ID = gql`
  mutation DeleteCatalogSelectionRule($id: Int!) {
    deleteCatalogSelectionRule(id: $id) {
      label
    }
  }
`;
