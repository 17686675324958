import React, { useEffect, useState } from 'react';
import { useFindAndImportContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { Pagination } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/Pagination';
import { useGetImportedProductsStats } from '@frontend/app/containers/Settings/ProductFulfillment/Products/hooks/useGetAllProductsStats';
import { useGetProductsFromShopify } from '@frontend/app/containers/Settings/ProductFulfillment/Products/hooks/useGetProductsFromShopify';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { pluralize } from '@frontend/app/utils/strings';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import {
  Empty, Col, Row, Image, Modal, Space, Typography,
} from '@revfluence/fresh';
import { Spin } from 'antd';
import { BoxesStackedIcon } from '@revfluence/fresh-icons/solid';
import styles from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImport.scss';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { FindContainer } from './FindandImport';

const { Title, Text } = Typography;

const PAGE_SIZE = 50;

export const ExportProductModal = ({
  setSelectedProduct,
  onClose,
}: {
  setSelectedProduct: (product) => void;
  onClose: () => void;
}) => {
  const {
    pageNumber,
    visited,
    setPageNumber,
    setVisited,
    resetState,
    screen,
    isFindClicked,
  } = useFindAndImportContext();
  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();

  const { setLandingPageData, landingPageData } = useLandingPageContext();

  const { clientConfig } = useGetClientConfig();

  const { data: productStats, refetch: refetchStats } = useGetImportedProductsStats();
  const discount = Number(landingPageData?.offerDiscount?.toString().replace('%', '').trim()) || 0;
  const {
    fetchShopifyProducts,
    isShopifyProductsLoading,
    shopifyProductsResp,
    fetchMoreShopifyProducts,
  } = useGetProductsFromShopify({
    notifyOnNetworkStatusChange: true,
  });

  const paginatedShopifyProducts = shopifyProductsResp?.shopifyProducts?.slice(
    (pageNumber - 1) * PAGE_SIZE,
    pageNumber * PAGE_SIZE,
  );

  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const handleProductSelect = (product) => {
    setSelectedProductId(product.id);
  };

  const handleSave = () => {
    const productToSave = paginatedShopifyProducts?.find((p) => p.id === selectedProductId);
    if (productToSave) {
      setSelectedProduct(productToSave);
      setLandingPageData((prev) => ({
        ...prev,
        featureProduct: {
          id: productToSave.id,
          title: productToSave.title,
          description: productToSave.description,
          imageUrl: productToSave.imgUrl,
          price: Number(productToSave.price),
          discountedPrice: Math.round(Number(productToSave.price) * (100 - discount)) / 100,
          productUrl: productToSave.productUrl,
          // handle: productToSave.handle,
        },
        basic: {
          ...prev.basic,
          featuredProductDiscount: {
            ...prev.basic.featuredProductDiscount,
            addHeroFeatureProduct: true,
          },
        },
      }));
      setIsFindAndImportOpen(false);
      resetState();
      onClose();
    }
  };

  useEffect(() => {
    refetchStats();
  }, [isFindAndImportOpen, refetchStats]);

  const importLimit = clientConfig?.importSyncSettings?.productsImportLimit || 50_000;

  const availableProductLimit = importLimit - (productStats?.shopifyProductsCount || 0);

  const hasNextPage = pageNumber * PAGE_SIZE < shopifyProductsResp?.totalCount;
  const hasPreviousPage = pageNumber > 1;

  const handleNextPage = async () => {
    if (hasNextPage && shopifyProductsResp?.pageInfo?.endCursor) {
      if (!visited[pageNumber + 1]) {
        await fetchMoreShopifyProducts({
          variables: {
            afterCursor: shopifyProductsResp.pageInfo.endCursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              getProductsFromShopify: {
                ...fetchMoreResult.getProductsFromShopify,
                shopifyProducts: [
                  ...prev.getProductsFromShopify.shopifyProducts,
                  ...fetchMoreResult.getProductsFromShopify.shopifyProducts,
                ],
              },
            };
          },
        });
        setVisited(pageNumber + 1);
      }
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const handleClose = () => {
    setIsFindAndImportOpen(false);
    resetState();
    onClose();
  };

  let cantImportReason = '';
  if (availableProductLimit - (shopifyProductsResp?.totalCount || 0) < 0) {
    cantImportReason = 'Found products are more than available product limit. Please adjust your filters and try again.';
  } else if (!shopifyProductsResp?.totalCount) {
    cantImportReason = 'No products found';
  }

  return (
    <Modal
      title={(
        <Space className={styles.header}>
          <Title level={4}>Select Featured Product</Title>
        </Space>
      )}
      open={isFindAndImportOpen}
      onCancel={handleClose}
      width={699}
      className={styles.FindAndImport}
      footer={null}
    >
      {screen === 'find' && (
        <Space direction="vertical">
          <FindContainer
            fetchShopifyProducts={fetchShopifyProducts}
            isShopifyProductsLoading={isShopifyProductsLoading}
            cantImportReason={cantImportReason}
          />
          {isShopifyProductsLoading ? (
            <Row justify="center" align="middle" className={styles.loaderContainer}>
              <Col>
                <Space direction="vertical" className={styles.space}>
                  <Spin size="large" />
                  <Text>Fetching the products...</Text>
                </Space>
              </Col>
            </Row>
          ) : (
            isFindClicked
            && (shopifyProductsResp?.totalCount ? (
              <>
                <Space className={styles.paginationContainer}>
                  <Text weight="semibold">
                    {shopifyProductsResp?.totalCount}
                    {' '}
                    {pluralize(shopifyProductsResp?.totalCount || 0, 'Product')}
                    {' '}
                    (
                    {selectedProductId ? 1 : 0}
                    {' '}
                    selected)
                  </Text>
                  <Pagination
                    page={pageNumber}
                    pageSize={PAGE_SIZE}
                    itemsOnPage={paginatedShopifyProducts?.length}
                    items={shopifyProductsResp?.totalCount}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    onNext={handleNextPage}
                    onPrev={handlePrevPage}
                  />
                </Space>
                <Space direction="vertical" className={`${styles.collectionTable} max-h-[400px] overflow-y-auto`}>
                  {paginatedShopifyProducts?.map((product) => (
                    <div className={styles.collectionListItem} key={product.id}>
                      <RadioGroup
                        value={selectedProductId}
                        onValueChange={() => handleProductSelect(product)}
                      >
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <RadioGroupItem
                                  value={product.id}
                                  id={`product-${product.id}`}
                                  disabled={product.totalInventory === 0}
                                />
                              </div>
                            </TooltipTrigger>
                            {product.totalInventory === 0 && (
                              <TooltipContent>
                                <p>Product is out of stock</p>
                              </TooltipContent>
                            )}
                          </Tooltip>
                        </TooltipProvider>
                      </RadioGroup>
                      <Image
                        src={
                          product.imgUrl
                          || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'
                        }
                        width={40}
                        height={40}
                        preview={false}
                      />
                      <div className={styles.collectionListItemTitle}>
                        <Text weight="semibold">{product.title}</Text>
                        <Text type="secondary">
                          {product.variantCount}
                          {' '}
                          {pluralize(product.variantCount, 'Variant')}
                        </Text>
                      </div>
                    </div>
                  ))}
                </Space>
                {isFindClicked && shopifyProductsResp?.totalCount > 0 && (
                  <div className="sticky bottom-0 bg-white py-4 border-t border-gray-200 mt-auto z-10 shadow-sm">
                    <Row justify="end">
                      <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={!selectedProductId || !shopifyProductsResp?.totalCount}
                      >
                        Save
                      </Button>
                    </Row>
                  </div>
                )}
              </>
            ) : (
              <Row justify="center" align="middle" className={styles.loaderContainer}>
                <Col>
                  <Empty
                    size="small"
                    description={(
                      <Space direction="vertical">
                        <span style={{ fontSize: '14px' }}>No products found.</span>
                        <Text type="secondary">Please adjust your filters and try again.</Text>
                      </Space>
                    )}
                    image={<BoxesStackedIcon />}
                  />
                </Col>
              </Row>
            ))
          )}
        </Space>
      )}
    </Modal>
  );
};
