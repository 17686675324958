import * as React from 'react';
import { Form } from '@revfluence/fresh';
import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { OFFER_PROMO_USAGE_LIMIT_RULE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Input } from '@frontend/shadcn/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import cx from 'classnames';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { FormAsyncAction, IShopifyFormElementProps, IShopifyPromoCodeFormValues, OfferFormAsyncActions } from '../../../../types';
import { validatePositiveInteger } from '../../../../utils';
import styles from '../../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'usageLimitRule'> {
  value: IShopifyPromoCodeFormValues['usageLimitRule'];
  handleFormAsyncActions: (value: FormAsyncAction) => void;
  isDefaultOpen?: boolean;
}
export const RefreshOfferCodePurchaseRestrictions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  handleFormAsyncActions,
  isDefaultOpen,
}) => {
  const [isPurchaseRequirement, setIsPurchaseRequirement] = React.useState(value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT || value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT || false);
  const handlePurchaseRequirement = (value: boolean) => {
    setIsPurchaseRequirement(value);
    if (!value) {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: name, value: OFFER_PROMO_USAGE_LIMIT_RULE.NONE },
      });
    } else {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: name, value: OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT },
      });
    }
  };
  const rules = {
    [OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT]: [
      {
        message: 'Please enter a value!',
        required: value !== OFFER_PROMO_USAGE_LIMIT_RULE.NONE,
      },
      {
        validator: validatePositiveInteger,
      },
    ],
    [OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT]: [
      {
        message: 'Please enter a value!',
        required: value !== OFFER_PROMO_USAGE_LIMIT_RULE.NONE,
      },
      {
        validator: validatePositiveInteger,
      },
    ],
  };

  const onChange = (value: string) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: name, value },
    });
  };
  return (
    <div className={cx('mt-2', styles.refreshPurchaseRestriction)}>
      <div className="flex gap-2 w-full mb-2">
        <Switch checked={isPurchaseRequirement} onCheckedChange={handlePurchaseRequirement} />
        <span className="text-sm font-medium">Set minimum purchase requirements</span>
      </div>
      {(isPurchaseRequirement || isDefaultOpen) && (
        <div className="px-[26px]">
          <Form.Item className="!m-0">
            <RadioGroup disabled={disabled} name={name} onValueChange={(value) => onChange(value)} value={value}>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <RadioGroupItem value={OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT} id="minPurchase" />
                  <label htmlFor="minPurchase" className="text-sm font-normal text-primary">Minimum Purchase Amount</label>
                </div>
                <div className="px-[26px] max-w-[180px]">
                  {value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT && (
                    <div className="relative flex items-center">
                      <span className="absolute text-grey-4 left-3">$</span>
                      <Form.Item
                        className="!m-0"
                        name="usageLimitAmount"
                        rules={rules[OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT]}
                      >
                        <Input
                          defaultValue={0}
                          disabled={disabled}
                          onKeyDown={UseNaturalNumbers}
                          prefix="$"
                          type="number"
                          min={1}
                          className="pl-8"
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <RadioGroupItem value={OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT} id="minItems" />
                  <label htmlFor="minItems" className="text-sm font-normal text-primary">Minimum Quantity of Items</label>
                </div>
                <div className="px-[26px] max-w-[180px]">
                  {value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT && (
                    <div className=" relative flex items-center">
                      <span className="absolute text-grey-4 left-3">#</span>
                      <Form.Item
                        name="usageLimitAmount"
                        className="!m-0"
                        rules={rules[OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT]}
                      >
                        <Input
                          disabled={disabled}
                          min={1}
                          placeholder="0"
                          type="number"
                          className="pl-8"
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              </div>
            </RadioGroup>
          </Form.Item>
        </div>
      )}
    </div>
  );
});
RefreshOfferCodePurchaseRestrictions.displayName = 'RefreshOfferCodePurchaseRestrictions';
