import React, { createContext, useContext, useState } from 'react';

export enum HeroSelectionTypeEnum {
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
  TYPE3 = 'TYPE3',
}

export enum FeaturedContentTypeEnum {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export enum CreatorUploadTypeEnum {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum ProductCardTypeEnum {
  SIMPLE = 'SIMPLE',
  DETAILED = 'DETAILED',
}

export enum CreatorProductSelectionTypeEnum {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

interface FieldErrors {
  basic?: {
    title?: string;
    description?: string;
    creatorsTag?: {
      title?: string;
    };
    discountOfferButton?: {
      title?: string;
    };
  };
  featuredContent?: {
    urls?: string;
    settings?: {
      noOfContentForCreator?: string;
    };
  };
  products?: {
    setting?: {
      noOfProductsCreatorCanAdd?: string;
      creatorProductSelectionType?: string;
    };
  };
}

// Add this interface for products
export interface Product {
  id?: string;
  title?: string;
  description?: string | null;
  imgUrl?: string;
  imageUrl?: string;
}

export interface FeaturedContent {
  type: FeaturedContentTypeEnum;
  src: string;
  index?: number;
  uploadedBy?: string;
}

// Update the LandingPageData interface to use Product type
export interface LandingPageData {
  heroSectionType: HeroSelectionTypeEnum;
  basic: {
    creatorsTag: {
      showCreatorTag: boolean;
      title: string;
    };
    title: string;
    description: string;
    featuredProductDiscount: {
      addHeroFeatureProduct: boolean;
      showProductRating: boolean;
      showTag: boolean;
    };
    discountOfferButton: {
      title: string;
    };
  };
  featureProduct: Product[];
  featuredContent: {
    isFeaturedContentEnabled: boolean;
    urls: FeaturedContent[];
    settings: {
      allowCreatorToUpload: boolean;
      noOfContentForCreator: number;
    };
  };
  products: {
    isFeaturedProductEnable: boolean;
    featuredProductHeading: string;
    showProductRating: boolean;
    showTag: boolean;
    allProducts: {
      products: Product[];
      style: {
        showProductRating: boolean;
        showProductPrice: boolean;
      };
    };
    setting: {
      allowCreatorToAddProducts: boolean;
      allowCreatorToUpdateFeaturedProduct: boolean;
      noOfProductsCreatorCanAdd: number;
      creatorProductSelectionType: CreatorProductSelectionTypeEnum;
      sectionHeading: string;
    };
  };
  style: {
    buttons: {
      outlineButton: {
        backgroundColor: string;
        cornerRadius: number;
        labelSize: number;
        labelColor: string;
      };
      solidButton: {
        backgroundColor: string;
        cornerRadius: number;
        labelSize: number;
        labelColor: string;
      };
    };
    backgroundSetting: {
      heroSectionBackgroundColor: string;
      pageBackgroundColor: string;
      heroSectionBackgroundImageUrl: {
        desktop: string;
        mobile: string;
      };
    };
    productCard: {
      textAlignment: string;
      cornerRadius: number;
    };
    typography: {
      body: {
        fontColor: string;
        fontSize: number;
        fontStyle: string;
      };
      headings: {
        fontColor: string;
        fontSize: number;
        fontStyle: string;
      }
    }
  };
}

const LandingPageContext = createContext(null);

// Provider
export const LandingPageProvider = ({ children }) => {
  const [landingPageData, setLandingPageData] = useState<LandingPageData>({
    heroSectionType: HeroSelectionTypeEnum.TYPE1,
    basic: {
      creatorsTag: {
        showCreatorTag: false,
        title: '',
      },
      title: '',
      description: '',
      featuredProductDiscount: {
        addHeroFeatureProduct: false,
        showProductRating: false,
        showTag: false,
      },
      discountOfferButton: {
        title: '',
      },
    },
    featureProduct: null,
    featuredContent: {
      isFeaturedContentEnabled: false,
      urls: [],
      settings: {
        allowCreatorToUpload: true,
        noOfContentForCreator: 1,
      },
    },
    products: {
      isFeaturedProductEnable: false,
      featuredProductHeading: '',
      showProductRating: false,
      showTag: false,
      allProducts: {
        products: [],
        style: {
          showProductRating: false,
          showProductPrice: false,
        },
      },
      setting: {
        allowCreatorToAddProducts: false,
        allowCreatorToUpdateFeaturedProduct: false,
        noOfProductsCreatorCanAdd: 0,
        creatorProductSelectionType: CreatorProductSelectionTypeEnum.MANUAL,
        sectionHeading: '',
      },
    },
    style: {
      buttons: {
        outlineButton: {
          backgroundColor: '#234323',
          cornerRadius: 8,
          labelSize: 14,
          labelColor: '#234323',
        },
        solidButton: {
          backgroundColor: '#234323',
          cornerRadius: 8,
          labelSize: 14,
          labelColor: '#234323',
        },
      },
      backgroundSetting: {
        heroSectionBackgroundColor: '#FFFFFF',
        pageBackgroundColor: '#FFFFFF',
        heroSectionBackgroundImageUrl: {
          desktop: '',
          mobile: '',
        },
      },
      productCard: {
        textAlignment: 'left',
        cornerRadius: 8,
      },
      typography: {
        body: {
          fontColor: '#234323',
          fontSize: 14,
          fontStyle: 'normal',
        },
        headings: {
          fontColor: '#234323',
          fontSize: 14,
          fontStyle: 'normal',
        },
      },
    },
  });
  const [initialData, setInitialData] = useState<LandingPageData | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const clearFieldErrors = () => setFieldErrors({});

  const clearFieldError = (path: string) => {
    setFieldErrors((prev) => {
      const newErrors = { ...prev };
      const parts = path.split('.');
      let current = newErrors;

      // Navigate to the parent object
      for (let i = 0; i < parts.length - 1; i++) {
        if (!current[parts[i]]) break;
        current = current[parts[i]];
      }

      // Delete the specific error
      const lastPart = parts[parts.length - 1];
      if (current && current[lastPart]) {
        delete current[lastPart];
      }

      return newErrors;
    });
  };

  return (
    <LandingPageContext.Provider
      value={{
        landingPageData,
        setLandingPageData,
        initialData,
        setInitialData,
        fieldErrors,
        setFieldErrors,
        clearFieldErrors,
        clearFieldError,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};

export const useLandingPageContext = () => useContext(LandingPageContext);
