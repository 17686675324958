import * as React from 'react';
import cx from 'classnames';
import { map, keyBy, filter } from 'lodash';

import {
  Button,
  Menu,
  Dropdown,
  Typography,
  Skeleton,
} from '@revfluence/fresh';
import {
  ChevronLeftIcon,
  AngleDownIcon,
  StarIcon,
  EnvelopeIcon,
  PlusIcon,
  TrashIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { StarIcon as StarFilledIcon } from '@revfluence/fresh-icons/solid/esm';

import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';
import { MemberOwners, UserAvatar } from '@frontend/app/components';

import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import { AppHeader, HeaderTabItem } from '@frontend/app/refresh-components/AppHeader';
import { IMemberDetails, IApplication } from '../types';
import styles from './Header.scss';

const { useMemo, useCallback } = React;
const { Title } = Typography;

interface IProps {
  apps: IApplication[];
  member: IMemberDetails;
  isInProject?: boolean;
  isImportant: boolean;
  className?: string;
  loading: boolean;
  onBackButtonClicked(): void;
  onAddToProjectAction(): void;
  onComposeAction(): void;
  onAddToGroupAction(): void;
  onRemoveFromProjectAction(): void;
  onFavoriteAction(): void;
  onAppClickAction(app: IApplication | null, params?: unknown): void;
  refreshUi?: boolean;
  tabs?: HeaderTabItem[];
  handleTabClick?: (tab: string) => void;
}

export const Header: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    member,
    apps,
    isInProject,
    isImportant,
    className,
    loading,
    onBackButtonClicked,
    onFavoriteAction,
    onComposeAction,
    onAddToGroupAction,
    onAddToProjectAction,
    onRemoveFromProjectAction,
    onAppClickAction,
    refreshUi,
  } = props;
  const filteredApplications = useMemo(() => filter(apps, (application) => application.visibility?.showOnMember), [apps]);
  const appsById = useMemo(() => keyBy(filteredApplications, 'id'), [filteredApplications]);

  const handleActionClick = useCallback(
    (target) => {
      const { key } = target;
      switch (key) {
        case 'important':
          onFavoriteAction();
          break;
        case 'compose':
          onComposeAction();
          break;
        case 'addToGroup':
          onAddToGroupAction();
          break;
        case 'addToProject':
          onAddToProjectAction();
          break;
        case 'RemoveFromProject':
          onRemoveFromProjectAction();
          break;
        default:
          onAppClickAction(appsById[key] || null);
          break;
      }
    },
    [
      appsById,
      onFavoriteAction,
      onComposeAction,
      onAddToGroupAction,
      onAddToProjectAction,
      onRemoveFromProjectAction,
      onAppClickAction,
    ],
  );

  const menu = useMemo(() => (
    <Menu onClick={handleActionClick}>
      <Menu.Item
        key="important"
        icon={isImportant ? <StarFilledIcon /> : <StarIcon />}
      >
        Important Member
      </Menu.Item>
      <Menu.Item
        key="compose"
        icon={<EnvelopeIcon />}
      >
        Compose Email
      </Menu.Item>
      <Menu.Item
        key="addToGroup"
        icon={<PlusIcon />}
      >
        Add to Group
      </Menu.Item>
      <Menu.Item
        key="addToProject"
        icon={<PlusIcon />}
      >
        Add to Project
      </Menu.Item>
      {isInProject && (
        <Menu.Item
          danger
          key="RemoveFromProject"
          icon={<TrashIcon />}
        >
          Remove From Project
        </Menu.Item>
      )}
      <Menu.Divider />
      {map(filteredApplications, (app) => (
        <Menu.Item
          key={app.id}
          icon={<AppIcon iconUrl={app.icon} />}
        >
          {app.name}
        </Menu.Item>
      ))}
    </Menu>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [filteredApplications, isImportant, isInProject]);

  if (loading) {
    return (
      <div className={styles.Header}>
        <Skeleton.Button active />
        <div className={styles.info}>
          <Skeleton.Avatar className={styles.avatar} active />
          <Skeleton.Input active size="small" />
        </div>
        <div className={styles.owner}>
          <Skeleton.Input active size="small" />
        </div>
        <Skeleton.Button active />
      </div>
    );
  }
  return (
    refreshUi ? (

      <AppHeader
        title={member?.name}
        userImage={member?.profilePicture}
        memberName={member?.name}
        backButtonFunction={onBackButtonClicked}
        actions={[
          <Dropdown overlay={menu} trigger={['click']} key={1}>
            <ShadCnBtn
              size="sm"
              variant="outline"
              className="bg-transparent text-white hover:bg-transparent hover:text-white"
              onClick={(e) => e.preventDefault()}
            >
              Member Actions
              <AngleDownIcon className="ml-2" />
            </ShadCnBtn>
          </Dropdown>,
        ]}
        tabs={props.tabs}
        handleTabClick={props.handleTabClick}
        defaultTab="MESSAGES"
      />
    ) : (
      <div className={cx(styles.Header, className)}>
        <Button
          className={styles.backButton}
          onClick={onBackButtonClicked}
          icon={<ChevronLeftIcon className={styles.arrow} />}
        />
        <div className={styles.info}>
          <UserAvatar
            className={styles.avatar}
            name={member.name}
            profilePicture={member.profilePicture}
          />
          <Title
            level={4}
            className={styles.name}
          >
            {member.name}
          </Title>
        </div>
        <div className={styles.owner}>
          <MemberOwners memberId={member.id} />
        </div>
        <Dropdown
          overlay={menu}
          trigger={['click']}
        >
          <Button onClick={(e) => e.preventDefault()}>
            Member Actions
            <AngleDownIcon />
          </Button>
        </Dropdown>
      </div>
    )
  );
});

Header.displayName = 'Deliverables';
