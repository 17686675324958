import React, { useEffect } from 'react';
import cx from 'classnames';
import { Handle, Position } from 'reactflow';
import { useGetNodeOptionsQuery } from '@frontend/app/hooks';
import { AutomationNodeData, AutomationBlueprintName } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { isEmpty } from 'lodash';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card } from '@frontend/shadcn/components/ui/card';
import { ArrowRight } from 'lucide-react';
import { ArrowRightIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tag } from '@revfluence/fresh';
import { useGetCatalogs } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { useAppContext } from '@frontend/context/AppContext';
import { ApolloProvider } from '@apollo/client';
import styles from './AutomationNode.scss';

const ActionNode = (props: { data: AutomationNodeData }) => {
  const {
    data: {
      isEditing,
      isLeafNode,
      metadata,
      nodeType,
      nodeId,
      automationId,
      templateId,
      refreshNodeOptions,
      onNodeItemClick,
      bluePrintName,
    },
  } = props;

  const { pfaApolloClient } = useAppContext();
  const handleBackground = isEditing ? '#BDD7C8' : '#389E0D';

  const {
    data: { getNodeOptions: { actionOptions } } = {
      getNodeOptions: {
        actionOptions: [],
      },
    },
    refetch: refetchNodeOptions,
  } = useGetNodeOptionsQuery({
    variables: {
      context: {
        automationId,
        templateId,
      },
    },
    skip: !automationId && !templateId,
  });

  useEffect(() => {
    if (refreshNodeOptions) {
      refetchNodeOptions();
    }
  }, [refreshNodeOptions, refetchNodeOptions]);

  const onActionClick = () => {
    if (!isEditing) return;
    onNodeItemClick({
      nodeType,
      targetNodeId: nodeId,
      bluePrintName,
      actionNode: {
        fromPreviousSender: metadata.fromPreviousSender,
        templateId: metadata.templateId,
        resourceId: metadata.resourceId,
        brandCatalogId: metadata.brandCatalogId,
        selectionRuleId: metadata.selectionRuleId,
      },
    });
  };

  const selectedTemplate = actionOptions[0]?.messageTemplates.find((template) => template.id === metadata.templateId);
  const selectedResourceEmail = actionOptions[0]?.emailResources.find(
    (resource) => resource.id === metadata.resourceId,
  );

  const showAlert = isEmpty(selectedTemplate) || isEmpty(selectedResourceEmail);

  // find the catalog name from metadata brandCatalogId
  const CatalogInfo = () => {
    const { catalogs = [] } = useGetCatalogs() || {};
    const catalogInfo = catalogs.find((catalog) => catalog.id === metadata.brandCatalogId);
    const catalogName = catalogInfo?.name || 'Select Catalog';

    return (
      <Button
        size="sm"
        variant="secondary"
        onClick={onActionClick}
        className={cx(
          'bg-gray-100 text-gray-900 font-semibold px-3 py-1 rounded-md hover:bg-gray-200',
          { 'cursor-pointer': isEditing, 'cursor-default': !isEditing }
        )}
      >
        {catalogName}
      </Button>
    );
  };

  return (
    <>
      <Handle type="target" isConnectable={false} position={Position.Top} style={{ background: 'transparent' }} />
      <div className={styles.nodeDecorator}>
        <div className={styles.actionNodeIcon}>
          <ArrowRightIcon />
        </div>
      </div>
      <div className={styles.nodeContent}>
        <div>Then...</div>
        {bluePrintName !== AutomationBlueprintName.SendProductOrderRequestAfterBriefIsAccepted && (
          <div className={styles.actionContent}>
            <div>Send email:</div>
            <Tag
              className={cx(styles.actionNodeTag, { [styles.alert]: showAlert })}
              onClick={onActionClick}
            >
              <div className={styles.actionContent}>
                {
                showAlert && (
                  <div><TriangleExclamationIcon style={{ color: '#D48806' }} /></div>
                )
              }
                <div className={styles.actionResource}>
                  <div className={styles.emailTemplate}>
                    {selectedTemplate?.displayName || 'Select Template'}
                  </div>
                  <div className={styles.resourceEmail}>
                    <span className={styles.attribute}>From: </span>
                    <span>
                      {selectedResourceEmail?.displayName || 'Select email address'}
                    </span>
                  </div>
                </div>
              </div>
            </Tag>
          </div>)}
      </div>
      {bluePrintName === AutomationBlueprintName.SendProductOrderRequestAfterBriefIsAccepted && (
        <Card className="border-none shadow-none w-full">
          <div className="flex flex-col">
            {/* Header Section */}
            <div className="flex items-center justify-between py-2">
              <div className="flex items-center gap-1">
                <div className="flex w-10 items-center justify-center">
                  <div className="bg-gray-900 rounded p-1">
                    <ArrowRight className="h-4 w-4 text-[#BDD7C8]" />
                  </div>
                </div>
                <h2 className="font-semibold text-gray-900 text-sm">
                  Send Product Catalog
                </h2>
              </div>
            </div>

            {/* Separator */}
            <div className="h-px bg-gray-200 my-2" />

            {/* Content Section */}
            <div className="px-6 py-4">
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2">
                  <ApolloProvider client={pfaApolloClient}>
                    <CatalogInfo />
                  </ApolloProvider>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}

      {!isLeafNode && (
        <Handle
          type="source"
          isConnectable={false}
          position={Position.Bottom}
          style={{ background: handleBackground }}
        />
      )}
    </>
  );
};

export default ActionNode;
