import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  NeedsShopifyPermissions,
  OfferFormModes,
  TFormValues,
} from '@affiliates/components';
import { useEffect, useState } from 'react';
import { NewAffiliateOfferContainer, NewShopifyCodeContainer } from '@affiliates/containers';
import { IShopifyCredentials, useApolloClient, useRedirectOnOfferFormSubmit } from '@affiliates/hooks';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { IChildArgs } from '../containers/OfferFormContainer/types';
import { useShopifyShopQuery } from '../hooks/useShopifyShopQuery';
import { GetConnectedShopify_clientConnections } from '../queries/types/GetConnectedShopify';
import { GET_CONNECTED_SHOPIFY } from '../queries/getConnectedShopifyQuery';
import { RefreshOfferForm } from '../components/OfferForm/RefreshOfferForm';
import { OfferPreviewCard } from '../components/OfferSummaryCard/OfferPreviewCard';

const { useCallback } = React;
interface IProps {
  baseUri: string;
  hasShopifyCredentials: boolean;
  shopifyCredentials: IShopifyCredentials;
  shopifyAppId: string;
  source: OFFER_SOURCE;
  isUTMEnabled: boolean;
  allowedDomains: string[];
  shopifyStoreName?: string,
}

export const RefreshNewOfferPage: React.FC<Readonly<IProps>> = ({
  baseUri,
  hasShopifyCredentials,
  shopifyAppId,
  shopifyCredentials,
  source,
  allowedDomains,
  shopifyStoreName,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { onSubmit } = useRedirectOnOfferFormSubmit(baseUri);
  const { enableMultipleShopify: isEnabledMultipleShopify, migrateToGraphQL } = useClientFeatures();
  const shopifySubscriptionCheck = useShopifyShopQuery();
  const [isSubscriptionEnable, setIsSubscriptionEnable] = useState(false);
  const [connectedShopifyData, setConnectedShopifyData] = useState<GetConnectedShopify_clientConnections[]>([]);
  const [offerImage, setOfferImage] = React.useState<string>(null);
  const staApolloClient = useApolloClient();
  useEffect(() => {
    if (isEnabledMultipleShopify) {
      (async () => {
        const { data } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
        if (data?.clientConnections) {
          const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
          setConnectedShopifyData(allConnections);
        }
      })();
    }
  }, [isEnabledMultipleShopify, staApolloClient]);

  useEffect(() => {
    if (!shopifySubscriptionCheck.loading) {
      if (shopifySubscriptionCheck?.data && shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions) {
        setIsSubscriptionEnable(shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions);
      }
    }
  }, [shopifySubscriptionCheck, isSubscriptionEnable, setIsSubscriptionEnable]);
  const renderComponents = useCallback(({
    actions,
    disabledFields,
    formRef,
    initialValues,
    isSaving,
    requiredFields,
    values,
    formOptionData,
    handleFormAsyncActions,
    offerDetails
  }: IChildArgs<TFormValues>) => (
    <div className="flex flex-col xl:flex-row">
      <div className="w-full xl:w-[70%] h-[100vh] overflow-y-auto border-b xl:border-r border-grey-2 space-y-2">
        <RefreshOfferForm
          disabledFields={disabledFields}
          formRef={formRef}
          hasAffiliateLink={false}
          initialValues={initialValues}
          isDeleted={false}
          isExpired={false}
          isSaving={isSaving}
          formOptionData={formOptionData}
          mode={OfferFormModes.Create}
          onDelete={actions.onDelete}
          onFieldFocused={actions.onFieldFocused}
          onFinish={actions.onFinish}
          onUnDelete={actions.onUnDelete}
          onValuesChange={actions.onChangeValues}
          requiredFields={requiredFields}
          isNewFlow={migrateToGraphQL}
          values={values}
          isSubscriptionEnable={isSubscriptionEnable}
          handleFormAsyncActions={handleFormAsyncActions}
          connectedAdvertiserForSync={connectedShopifyData.filter((c) => !c.isPrimary)}
          connectedClients={connectedShopifyData}
          initialAllowedDomains={allowedDomains}
          shopifyStoreName={shopifyStoreName}
          onChangeImage={actions.handleChangeOfferImage}
          setOfferImage={setOfferImage}
        />
      </div>
      <div className="border-t xl:border-l border-grey-3" />
      <div className="w-full xl:w-[30%] max-w-[375px] mx-auto">
        <OfferPreviewCard values={values} imageUrl={offerImage} icon={values.icon} createdOn={offerDetails.createdDate} />
      </div>
    </div>

  ), [migrateToGraphQL, isSubscriptionEnable, connectedShopifyData, allowedDomains, shopifyStoreName, setOfferImage, offerImage]);

  const renderContainer = useCallback(() => {
    switch (source) {
      case OFFER_SOURCE.TUNE:
        return <NewAffiliateOfferContainer onSubmit={onSubmit}>{renderComponents}</NewAffiliateOfferContainer>;
      case OFFER_SOURCE.SHOPIFY:
        return (
          <NewShopifyCodeContainer
            shopifyCredentials={shopifyCredentials}
            onSubmit={onSubmit}
            isSubscriptionEnable={isSubscriptionEnable}
            connectedAdvertiserForSync={connectedShopifyData}
          >
            {renderComponents}
          </NewShopifyCodeContainer>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, renderComponents, source, isSubscriptionEnable, shopifyCredentials, connectedShopifyData]);

  if (source === OFFER_SOURCE.SHOPIFY && !hasShopifyCredentials) {
    return (
      <NeedsShopifyPermissions
        onClick={() => {
          history.push({
            ...location,
            pathname: `/settings/${encodeURIComponent(shopifyAppId)}`,
          });
        }}
      />
    );
  }
  return (
    <div className="bg-grey-1">
      {renderContainer()}
    </div>
  );
};

RefreshNewOfferPage.displayName = 'SimplifiedNewOfferPage';
