export type DateType =
  | 'custom'
  | 'thisWeek'
  | 'lastWeek'
  | 'lastMonth'
  | 'monthToDate'
  | 'lastQuarter'
  | 'quarterToDate'
  | 'last6Months'
  | 'yearToDate'
  | 'allTime';

// Calculate dates based on date type
export const calculateDatesFromType = (type: DateType, minDate?: Date): { startDate: Date; endDate: Date } => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  switch (type) {
    case 'thisWeek': {
      const start = new Date(today);
      start.setDate(today.getDate() - today.getDay()); // Start of week (Sunday)
      return { startDate: start, endDate: today };
    }
    case 'lastWeek': {
      const end = new Date(today);
      end.setDate(today.getDate() - today.getDay() - 1); // Last Saturday
      const start = new Date(end);
      start.setDate(end.getDate() - 6); // Last Sunday
      return { startDate: start, endDate: end };
    }
    case 'lastMonth': {
      const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const end = new Date(today.getFullYear(), today.getMonth(), 0);
      return { startDate: start, endDate: end };
    }
    case 'monthToDate': {
      const start = new Date(today.getFullYear(), today.getMonth(), 1);
      return { startDate: start, endDate: today };
    }
    case 'lastQuarter': {
      const currentQuarter = Math.floor(today.getMonth() / 3);
      const start = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1);
      const end = new Date(today.getFullYear(), currentQuarter * 3, 0);
      return { startDate: start, endDate: end };
    }
    case 'quarterToDate': {
      const currentQuarter = Math.floor(today.getMonth() / 3);
      const start = new Date(today.getFullYear(), currentQuarter * 3, 1);
      return { startDate: start, endDate: today };
    }
    case 'last6Months': {
      const start = new Date(today);
      start.setMonth(today.getMonth() - 6);
      return { startDate: start, endDate: today };
    }
    case 'yearToDate': {
      const start = new Date(today.getFullYear(), 0, 1);
      return { startDate: start, endDate: today };
    }
    case 'allTime': {
      // Use minDate if provided, otherwise fallback to a reasonable start date
      const start = minDate || new Date(2020, 0, 1);
      return { startDate: start, endDate: today };
    }
    default:
      return { startDate: today, endDate: today };
  }
};
