/* eslint-disable no-confusing-arrow */
import { LazyImage } from '@components';
import { Button } from '@revfluence/fresh';
import * as React from 'react';
import {
  NavLink, Redirect, Route, Switch, useRouteMatch, useHistory,
  useLocation,
} from 'react-router-dom';

import {
  has,
  includes,
  isUndefined,
  last,
} from 'lodash';

import { ChevronLeftIcon } from '@revfluence/fresh-icons/regular/esm';

import { LoadSpinner } from '@frontend/app/components';
import { useProjectByIdQuery, useClientFeatureEnabled, useGetTermsName } from '@frontend/app/hooks';
import BasicAdvancedTermsSettingsPage from '@frontend/app/containers/Projects/SettingsPage/BasicAdvancedTermsSettingsPage';
import TermsSettingsPage from '@frontend/app/containers/Projects/SettingsPage/TermsSettingsPage';
import { AutomationPage } from '@frontend/app/containers/Projects/AutomationPage/AutomationPage';

import { ClientFeature } from '@frontend/app/constants';
import { ProjectBudgetProvider } from '@frontend/app/context/ProjectBudgetContext';
import { useAuth } from '@frontend/context/authContext';
import { AppHeader, HeaderTabItem } from '@frontend/app/refresh-components/AppHeader';
import { useState } from 'react';
import { LandingPages } from './LandingPages';
import { InviteEmailPage } from './InviteEmailPage';

import styles from './SettingsPage.scss';
import { EditPage } from '../ProjectDetailsForm/EditPage';
import { ProjectsRouteRoot } from '../constants';
import { ProjectApplicationPagePicker } from '../ProjectApplicationPagePicker/ProjectApplicationPagePicker';
import { useProjectsApp } from '../hooks';
import { formatApplicationPageURL, isApplicationPageEditable } from '../utils';
import { ProductCatalogs } from './ProductCatalogs/ProductCatalogs';
import BudgetPage from '../BudgetPage';
import { CampaignProvider } from '../CampaignPage/CampaignContext';
import { SetupTab } from '../CampaignPage/tabs/SetupTab';
import { WorkflowTab } from '../CampaignPage/tabs/WorkflowTab';

const settingsTabKey = {
  setup: 'Setup',
  workflow: 'Workflow',
  automation: 'Automations',
  application_page: 'Application Page',
  select_template: 'Application Page',
  landing_page: 'Application Page',
  invite_email: 'Invite Email',
  product_catalog: 'Product Catalogs',
  history: 'Automations',
};
const getActiveKeyFromPathname = (pathname) => {
  const lastPart = pathname.split('/').pop();
  return settingsTabKey[lastPart] || 'Project Details';
};

const { useCallback, useMemo } = React;

interface IRouteProps {
  /**
   * Project ID
   */
  projectId?: string;
}

export const SettingsPage: React.FC = React.memo(() => {
  const {
    params: { projectId },
    url,
  } = useRouteMatch<IRouteProps>();

  const history = useHistory();
  const { clientInfo } = useAuth();
  const isBasicTermsEnabled = useClientFeatureEnabled(ClientFeature.BASIC_TERMS);
  const isPfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const isBudgetAllocation = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(getActiveKeyFromPathname(location.pathname));

  const { pluralTermsName } = useGetTermsName();

  React.useEffect(() => {
    setActiveKey(getActiveKeyFromPathname(location.pathname));
  }, [location]);

  /**
   * Fetch project
   */
  const { loading, data: { project = undefined } = {}, refetch: refetchProject } = useProjectByIdQuery({
    variables: {
      id: projectId ? parseInt(projectId, 10) : undefined,
    },
    skip: !projectId,
  });

  const isFlexEnabled = useClientFeatureEnabled(ClientFeature.FLEXIBLE_PROJECT);

  const { tasks, refetchData } = useProjectsApp({
    defaultProjectId: +projectId,
  });
  const hasTermStage = useMemo(() => tasks?.some((task) => task.taskId === 'send_terms_task'), [tasks]);
  const hasPfaV2Stage = useMemo(() => tasks?.some((task) => task.taskId.includes('pfa_v2')), [tasks]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const isApplicationPageActive = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (location: any) =>
      includes(['duplicate_applications', 'landing_page', 'select_template'], last(location?.pathname?.split('/'))),
    [],
  );

  const applicationPageURL = useMemo(() => formatApplicationPageURL(ProjectsRouteRoot, project, refreshUi), [project, refreshUi]);
  const onBackButtonClick = () => {
    if (project?.published) {
      history.replace({
        pathname: `${ProjectsRouteRoot}/${project.id}/find_creators`,
      });
    } else if (has(history.location, 'landing_page')) {
      history.replace({
        pathname: `${ProjectsRouteRoot}/${project.id}/settings/select_tempalate`,
      });
    } else {
      history.goBack();
    }
  };

  const renderBackButton = () => (
    <Button className={styles.backButton} onClick={onBackButtonClick} icon={<ChevronLeftIcon />}>
      Back
    </Button>
  );

  const handleDeleteApplicationPage = useCallback(() => {
    history.replace({
      pathname: `${url}/select_template`,
    });

    refetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchProject, url]);

  const handleSave = useCallback(async () => {
    await refetchProject();
    await refetchData();
  }, [refetchData, refetchProject]);

  const renderNav = () => (
    <nav className={styles.nav}>
      <NavLink activeClassName={styles.active} replace to={`${url}/details`}>
        <Button className={styles.button} type="link">
          Project Details
        </Button>
      </NavLink>

      <NavLink
        activeClassName={styles.active}
        replace
        to={applicationPageURL}
        isActive={(_, location) => isApplicationPageActive(location)}
      >
        <Button className={styles.button} type="link">
          Application Page
        </Button>
      </NavLink>
      {hasTermStage && (
        <NavLink activeClassName={styles.active} replace to={`${url}/terms`}>
          <Button className={styles.button} type="link">
            {pluralTermsName}
          </Button>
        </NavLink>
      )}
      {isApplicationPageEditable(project) && (
        <NavLink activeClassName={styles.active} replace to={`${url}/invite_email`}>
          <Button className={styles.button} type="link">
            Invite Email
          </Button>
        </NavLink>
      )}
      <NavLink activeClassName={styles.active} replace to={`${url}/automation`}>
        <Button className={styles.button} type="link">
          Automations
        </Button>
      </NavLink>
      {isPfaV2Enabled && hasPfaV2Stage && (
        <NavLink activeClassName={styles.active} replace to={`${url}/product_catalog`}>
          <Button className={styles.button} type="link">
            Product Catalogs
          </Button>
        </NavLink>
      )}
      {isBudgetAllocation && (
        <NavLink activeClassName={styles.active} replace to={`${url}/budget`}>
          <Button className={styles.button} type="link">
            Budget
          </Button>
        </NavLink>
      )}
    </nav>
  );

  const renderRoutes = () => (
    <Switch>
      {isFlexEnabled && (
        <>
          <Route
            exact
            path={`${url}/setup`}
            render={() => (
              <CampaignProvider>
                <SetupTab
                  mode="edit"
                  project={project}
                  onSave={handleSave}
                  refetchProjects={refetchData}
                />
              </CampaignProvider>
            )}
          />
          <Route
            exact
            path={`${url}/workflow`}
            render={() => (
              <CampaignProvider>
                <WorkflowTab
                  mode="edit"
                  project={project}
                  onSave={handleSave}
                  refetchProjects={refetchData}
                />
              </CampaignProvider>
            )}
          />
        </>
      )}
      {!isFlexEnabled && (
        <Route
          exact
          path={`${url}/details`}
          render={() => (
            <EditPage
              onSave={handleSave}
              project={project}
              refetchProjects={refetchData}
            />
          )}
        />
      )}
      <Route
        exact
        path={`${url}/landing_page`}
        render={() => (
          <LandingPages
            onSave={refetchProject}
            onDeleteApplicationPage={handleDeleteApplicationPage}
            project={project}
          />
        )}
      />
      <Route
        exact
        path={`${url}/budget`}
        render={() => (
          <ProjectBudgetProvider initialState={{ clientId: clientInfo.id, projectId }}>
            <BudgetPage />
          </ProjectBudgetProvider>
        )}
      />
      <Route exact path={`${url}/select_template`} render={() => <ProjectApplicationPagePicker project={project} />} />
      {hasTermStage && (
        <Route
          exact
          path={`${url}/terms`}
          render={() =>
            isBasicTermsEnabled ? (
              <BasicAdvancedTermsSettingsPage project={project} />
            ) : (
              <TermsSettingsPage project={project} />
            )}
        />
      )}
      <Route exact path={`${url}/invite_email`} render={() => <InviteEmailPage project={project} />} />
      <Route path={`${url}/automation`} render={() => <AutomationPage projectId={parseInt(projectId, 10)} />} />
      {isPfaV2Enabled && (
        <Route
          exact
          path={`${url}/product_catalog`}
          render={() => <ProductCatalogs programId={parseInt(projectId, 10)} />}
        />
      )}
      <Redirect from={`${url}`} to={isFlexEnabled ? `${url}/setup` : `${url}/details`} />
    </Switch>
  );

  const navSettings: HeaderTabItem[] = useMemo(() => {
    const baseNavSettings = isFlexEnabled ? [
      {
        name: 'Project Details',
        label: 'Setup',
        content: (
          <CampaignProvider>
            <SetupTab
              mode="edit"
              project={project}
              onSave={handleSave}
              refetchProjects={refetchData}
            />
          </CampaignProvider>
        ),
      },
      {
        name: 'Workflow',
        label: 'Workflow',
        content: (
          <CampaignProvider>
            <WorkflowTab
              mode="edit"
              project={project}
              onSave={handleSave}
              refetchProjects={refetchData}
            />
          </CampaignProvider>
        ),
      },
      {
        name: 'Application Page',
        label: 'Application Page',
        content: (isApplicationPageEditable(project) || location.pathname.split('/').pop() === 'landing_page')
          ? <LandingPages onSave={refetchProject} onDeleteApplicationPage={handleDeleteApplicationPage} project={project} />
          : <ProjectApplicationPagePicker project={project} />,
      },
    ] : [
      {
        name: 'Project Details',
        label: 'Project Details',
        content: <EditPage onSave={handleSave} project={project} refetchProjects={refetchData} />,
      },
      {
        name: 'Application Page',
        label: 'Application Page',
        content: (isApplicationPageEditable(project) || location.pathname.split('/').pop() === 'landing_page')
          ? <LandingPages onSave={refetchProject} onDeleteApplicationPage={handleDeleteApplicationPage} project={project} />
          : <ProjectApplicationPagePicker project={project} />,
      },
    ];

    // Add common tabs
    baseNavSettings.push(
      {
        name: 'Automations',
        label: 'Automations',
        content: <AutomationPage projectId={parseInt(projectId, 10)} />,
      },
      {
        name: 'Budget',
        label: 'Budget',
        content: (
          <ProjectBudgetProvider initialState={{ clientId: clientInfo.id, projectId }}>
            <BudgetPage />
          </ProjectBudgetProvider>
        ),
      },
    );

    if (hasTermStage) {
      baseNavSettings.splice(2, 0, {
        name: pluralTermsName,
        label: pluralTermsName,
        content: isBasicTermsEnabled ? (
          <BasicAdvancedTermsSettingsPage project={project} />
        ) : (
          <TermsSettingsPage project={project} />
        ),
      });
    }
    if (isApplicationPageEditable(project)) {
      baseNavSettings.splice(2, 0, {
        name: 'Invite Email',
        label: 'Invite Email',
        content: <InviteEmailPage project={project} />,
      });
    }
    if (isPfaV2Enabled && hasPfaV2Stage) {
      baseNavSettings.splice(4, 0, {
        name: 'Product Catalogs',
        label: 'Product Catalogs',
        content: <ProductCatalogs programId={parseInt(projectId, 10)} />,
      });
    }
    return baseNavSettings;
  }, [project, projectId, clientInfo.id, handleSave, refetchData, refetchProject, handleDeleteApplicationPage, hasTermStage, isBasicTermsEnabled, pluralTermsName, isPfaV2Enabled, hasPfaV2Stage, location.pathname, isFlexEnabled]);

  const handleClick = (tabName) => {
    setActiveKey(tabName);
  };
  const ActiveComponent = navSettings.find((tab) => tab.name === activeKey)?.content;

  if (isUndefined(refreshUi)) {
    return null;
  }

  return (
    <div className={styles.SettingsPage}>
      {refreshUi ? (
        <>
          <AppHeader
            title="Settings"
            imgIconUrl={project?.splashImageUrl}
            backButtonFunction={onBackButtonClick}
            tabs={navSettings}
            handleTabClick={handleClick}
            defaultTab={activeKey}
          />
          {(loading || !project || (history.location.pathname.includes('terms') && !tasks)) ? (
            <LoadSpinner className={styles.loadSpinner} />
          ) : (
            ActiveComponent
          )}
        </>
      ) : (
        <>
          <header className={styles.header}>
            {renderBackButton()}
            <LazyImage className={styles.image} src={project?.splashImageUrl} />
            <h1 className={styles.title}>Settings</h1>
            {renderNav()}
          </header>
          {(loading || !project || (history.location.pathname.includes('terms') && !tasks)) ? (
            <LoadSpinner className={styles.loadSpinner} />
          ) : (
            renderRoutes()
          )}
        </>
      )}
    </div>
  );
});
