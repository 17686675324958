import * as React from 'react';
import { useGetTags, useFuzzySearchByKeys } from '@frontend/app/hooks';

import { IncludeSelect } from './IncludeSelect';

interface IProps {
  defaultValue: number[];
  onChange(value: number[]);
  visible?: boolean;
}

const SEARCH_KEYS = ['name'];

export const TagInclusion: React.FC<IProps> = (props) => {
  const {
    tags,
  } = useGetTags({
    skip: !props.visible,
  });

  const handleSearch = useFuzzySearchByKeys(tags, SEARCH_KEYS);

  type TOption = typeof tags[0];

  return (
    <IncludeSelect
      showSearch
      onSearchRequest={handleSearch}
      options={tags}
      defaultSelectedIds={props.defaultValue}
      mapOptionToId={(option: TOption) => option.id}
      mapOptionToLabel={(option: TOption) => option.name}
      onChange={props.onChange}
    />
  );
};
