import 'css-chunk:src/app/containers/Projects/TermsPage/components/TermsLoading.module.scss';export default {
  "spacing-xxxl": "80px",
  "spacing-xxl": "48px",
  "spacing-xl": "32px",
  "spacing-lg": "24px",
  "spacing-md": "16px",
  "spacing-sm": "12px",
  "spacing-xs": "8px",
  "spacing-xxs": "4px",
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "TermsLoadingLayout": "_TermsLoadingLayout_bxoo1_41"
};