import React from 'react';

const Others: React.FC = () => (
  <div className="flex flex-col gap-4 px-4 mt-4">
    <div className="flex flex-col gap-2">
      <h2 className="text-[16px] font-medium text-grey-6 leading-7">Others Section UI</h2>
      <div className="text-sm text-grey-5">This UI is for the Others section.</div>
    </div>
  </div>
    );

export default Others;
