import React, { CSSProperties, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  PaginationOptions,
  ColumnPinningState,
  Column,
  SortingState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import ReactDragListView from 'react-drag-listview';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@frontend/shadcn/components/ui/table';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownLeftAndUpRightToCenterIcon,
  UpRightAndDownLeftFromCenterIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Button } from '@frontend/shadcn/components/ui/button';

const CHECKBOX_WIDTH = 40;

const Z_INDEX = {
  TOOLTIP: 100,
  CHECKBOX: 50,
  PINNED_HEADER: 40,
  HEADER: 30,
  PINNED_CELL: 20,
  CELL: 0,
  HEADER_CHECKBOX: 999999,
  ROW_CHECKBOX: 1,
} as const;

const getColumnPinningStyles = <TData,>(column: Column<TData, unknown>, selectable: boolean): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left') + (selectable ? CHECKBOX_WIDTH : 0)}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? Z_INDEX.PINNED_HEADER : Z_INDEX.HEADER,
    width: column.getSize(),
    backgroundColor: isPinned ? 'hsl(var(--primary-foreground))' : undefined,
  };
};

const getCellPinningStyles = <TData,>(column: Column<TData, unknown>, selectable: boolean): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left') + (selectable ? CHECKBOX_WIDTH : 0)}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? Z_INDEX.PINNED_CELL : Z_INDEX.CELL,
    backgroundColor: isPinned ? 'hsl(var(--primary-foreground))' : undefined,
  };
};

export interface HeaderGroupBorder {
  borderColor?: string;
}

export interface ColumnMetaType {
  headerGroupBorder?: HeaderGroupBorder;
  onToggleClick?: (column: string) => void;
  isColumnExpanded?: boolean;
  greyBackground?: boolean;
  draggable?: boolean;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sortable?: boolean;
  filterable?: boolean;
  paginated?: boolean;
  paginationState?: PaginationState;
  paginationOptions?: PaginationOptions;
  wrapperClassName?: string;
  columnPinning?: ColumnPinningState;
  draggable?: boolean;
  selectable?: boolean;
  rowPinning?: boolean;
  bordered?: boolean;
  maxHeight?: string;
  onColumnDragEnd?: (fromIndex: number, toIndex: number) => void;
  onSelectionChange?: (selectedRows: TData[]) => void;
  clearSelection?: boolean;
  rowHeight?: number; // Optional row height for virtualization
  onSortingChange?: (updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => void;
  sorting?: SortingState;
  globalFilter?: string | number;
}

export default function DataTable<TData, TValue>({
  columns: initialColumns,
  data,
  paginated,
  paginationState,
  paginationOptions,
  wrapperClassName,
  sortable = true,
  filterable = false,
  columnPinning: initialColumnPinning,
  draggable = false,
  selectable = false,
  rowPinning = false,
  bordered = false,
  maxHeight = '500px',
  onColumnDragEnd,
  onSelectionChange,
  clearSelection,
  rowHeight = 45, // Default row height in pixels
  onSortingChange,
  sorting,
  globalFilter,
}: DataTableProps<TData, TValue>) {
  const [columns, setColumns] = useState(initialColumns);
  const [rowSelection, setRowSelection] = useState({});
  const [internalSorting, setInternalSorting] = useState<SortingState>([]);

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  useEffect(() => {
    if (clearSelection) {
      setRowSelection({});
    }
  }, [clearSelection]);

  const handleColumnDragEnd = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newColumns = [...columns];
      const draggedColumn = newColumns.splice(fromIndex, 1)[0];
      newColumns.splice(toIndex, 0, draggedColumn);
      setColumns(newColumns);

      if (onColumnDragEnd) {
        onColumnDragEnd(fromIndex, toIndex);
      }
    },
    [columns, onColumnDragEnd],
  );

  const columnPinning = useMemo(() => initialColumnPinning || {}, [initialColumnPinning]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: sortable ? getSortedRowModel() : undefined,
    getPaginationRowModel: paginated ? getPaginationRowModel() : undefined,
    onSortingChange: (updater) => {
      if (onSortingChange) {
        onSortingChange(updater);
      } else {
        setInternalSorting(updater);
      }
    },
    getFilteredRowModel: filterable ? getFilteredRowModel() : undefined,
    globalFilterFn: 'includesString',
    state: {
      rowSelection,
      columnPinning,
      ...(paginationState && { pagination: paginationState }),
      sorting: sorting ?? internalSorting,
      ...(sorting && { sorting }),
      ...(filterable && { globalFilter }),
    },
    onRowSelectionChange: setRowSelection,
    ...(paginationOptions || {}),
  });

  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original);
    onSelectionChange?.(selectedRows);
  }, [rowSelection, onSelectionChange, table]);

  // Add reference to the scrollable container
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Get rows from the table
  const { rows } = table.getRowModel();

  // Set up virtualizer
  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => rowHeight,
    overscan: 10, // Render additional rows above and below the visible area for smoother scrolling
  });

  // Get virtually rendered rows
  const virtualRows = virtualizer.getVirtualItems();

  // Calculate total size
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0 ? virtualizer.getTotalSize() - virtualRows[virtualRows.length - 1].end : 0;

  // Create a single table with a scrollable container
  const tableContent = (
    <div className={cn('relative overflow-hidden', bordered ? 'rounded-md border-t border-b' : '')}>
      <Table
        wrapperRef={tableContainerRef}
        style={{
          minWidth: table.getTotalSize() + (selectable ? CHECKBOX_WIDTH : 0),
          width: '100%',
          tableLayout: 'fixed',
          position: 'relative',
        }}
        wrapperProps={{
          style: {
            maxHeight,
          },
        }}
        className={cn(
          'font-mono tabular-nums relative',
          // Keep internal borders for cells
          bordered && '[&_th]:border-r [&_th]:border-b [&_td]:border-r [&_td]:border-b [&_tr:last-child_td]:border-b',
        )}
      >
        <TableHeader className="sticky top-0 z-30 bg-background">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {selectable && (
                <TableHead
                  className={cn('sticky left-0', bordered && 'border-l-0 last:border-r-0')}
                  style={{
                    width: CHECKBOX_WIDTH,
                    minWidth: CHECKBOX_WIDTH,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: Z_INDEX.HEADER_CHECKBOX,
                    backgroundColor: 'hsl(var(--primary-foreground))',
                    borderLeft: 'none',
                  }}
                >
                  <div className="sticky left-0 top-0">
                    <Checkbox
                      checked={table.getIsAllRowsSelected()}
                      aria-label="Select all"
                      onCheckedChange={(checked) => {
                        table.toggleAllRowsSelected(!!checked);
                      }}
                    />
                  </div>
                </TableHead>
              )}
              {headerGroup.headers.map((header, index) => {
                const isFirstColumn = index === 0 && !selectable;
                const isLastColumn = index === headerGroup.headers.length - 1;
                const isGroupedHeader = header.subHeaders?.length > 0;
                const meta = header.column.columnDef.meta as ColumnMetaType | undefined;
                const width = header.column.getSize();

                return (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn(
                      'relative',
                      isFirstColumn && 'border-l-0', // Remove left border for first column
                      isLastColumn && 'border-r-0', // Remove right border for last column
                      sortable && header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      draggable && (meta?.draggable !== false) ? 'draggable cursor-grab' : '',
                      meta?.greyBackground && 'bg-[#F9FAFB]',
                    )}
                    style={{
                      width: isGroupedHeader ? undefined : width,
                      minWidth: width,
                      maxWidth: width * 1.5,
                      ...getColumnPinningStyles(header.column, selectable),
                      position: header.column.getIsPinned() || rowPinning ? 'sticky' : undefined,
                      top: 0, // Ensure header sticks to top
                      // Use the custom border color if provided, otherwise use the default bordered style
                      borderBottom: meta?.headerGroupBorder?.borderColor
                        ? `2px solid ${meta.headerGroupBorder.borderColor}`
                        : bordered
                        ? '1px solid hsl(var(--border))'
                        : undefined,
                      borderLeft: isFirstColumn ? 'none' : undefined, // Remove left border for first column
                      borderRight: isLastColumn ? 'none' : undefined, // Remove right border for last column
                      zIndex: header.column.getIsPinned() ? Z_INDEX.PINNED_HEADER : Z_INDEX.HEADER,
                      opacity: 1,
                    }}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div
                        className={cn('flex items-center gap-2 w-full', sortable && 'cursor-pointer')}
                        onClick={sortable && !isGroupedHeader ? header.column.getToggleSortingHandler() : undefined}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {!isGroupedHeader && header.column.getIsSorted() && (
                          <div className="h-4 w-4">
                            {{
                              asc: <ChevronUpIcon />,
                              desc: <ChevronDownIcon />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </div>

                      {meta?.onToggleClick && (
                        <div
                          className="cursor-pointer transition-transform duration-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            meta.onToggleClick?.(header.id);
                          }}
                          title={meta?.isColumnExpanded ? 'Collapse columns' : 'Expand columns'}
                          data-dd-action-name={`table-header-${meta?.isColumnExpanded ? 'collapse' : 'expand'}-column`}
                        >
                          {meta?.isColumnExpanded ? (
                            <Button variant="ghost" className="w-6 h-6 flex justify-center items-center" size="icon">
                              <DownLeftAndUpRightToCenterIcon className="text-xs text-grey-5" />
                            </Button>
                          ) : (
                            <Button variant="ghost" className="w-6 h-6 flex justify-center items-center" size="icon">
                              <UpRightAndDownLeftFromCenterIcon className="text-xs text-grey-5" />
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {rows.length > 0 ? (
            <>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}

              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];
                if (!row) return null;

                return (
                  <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                    {selectable && (
                      <TableCell
                        className={cn('sticky left-0', bordered && 'border-l-0')}
                        style={{
                          width: CHECKBOX_WIDTH,
                          minWidth: CHECKBOX_WIDTH,
                          position: 'sticky',
                          left: 0,
                          zIndex: Z_INDEX.ROW_CHECKBOX,
                          backgroundColor: 'hsl(var(--primary-foreground))',
                          borderLeft: 'none',
                        }}
                      >
                        <Checkbox
                          checked={row.getIsSelected()}
                          aria-label="Select row"
                          onCheckedChange={row.getToggleSelectedHandler()}
                        />
                      </TableCell>
                    )}
                    {row.getVisibleCells().map((cell, index) => {
                      const isFirstColumn = index === 0 && !selectable;
                      const isLastColumn = index === row.getVisibleCells().length - 1;
                      const width = cell.column.getSize();
                      const meta = cell.column.columnDef.meta as ColumnMetaType | undefined;

                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            'p-2',
                            isFirstColumn && 'border-l-0',
                            isLastColumn && 'border-r-0',
                            meta?.greyBackground && 'bg-[#F9FAFB]',
                          )}
                          style={{
                            width,
                            minWidth: width,
                            maxWidth: width * 1.5,
                            ...getCellPinningStyles(cell.column, selectable),
                            borderLeft: isFirstColumn ? 'none' : undefined,
                            borderRight: isLastColumn ? 'none' : undefined,
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + (selectable ? 1 : 0)} className="h-24 text-center">
                <div className="w-screen flex justify-center items-center">No results.</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );

  if (draggable) {
    return (
      <ReactDragListView.DragColumn nodeSelector="th" onDragEnd={handleColumnDragEnd} lineClassName="drag-line">
        <div className={wrapperClassName}>{tableContent}</div>
      </ReactDragListView.DragColumn>
    );
  }

  return <div className={wrapperClassName}>{tableContent}</div>;
}
