import React, { useCallback, useMemo } from 'react';
import { Tag } from '@revfluence/fresh';
import { find, isBoolean, isNull, map, pick } from 'lodash';
import styles from './CreatorAttribute.scss';

interface MemberFieldSchema {
  id: number;
  name: string;
}

interface CreatorAttributeProps {
  memberRecord: {
    fields?: Record<string, any>;
  };
  applicantReviewFields?: Record<string, unknown> | null;
  memberFieldSchemas?: MemberFieldSchema[] | null;
}

const CreatorAttribute: React.FC<CreatorAttributeProps> = ({
  memberRecord,
  applicantReviewFields = {},
  memberFieldSchemas = [],
}) => {
  const attributeFields = useMemo(
    () => ['Attribute 1', 'Attribute 2', 'Attribute 3', 'Attribute 4', 'Attribute 5'],
    [],
  );

  const matchedFields = useMemo(() => {
    if (isNull(applicantReviewFields)) {
      return {};
    }
    return pick(applicantReviewFields, attributeFields) as Record<string, string>;
  }, [applicantReviewFields, attributeFields]);

  const getDisplayForAttribute = useCallback(
    (fieldId: string): JSX.Element | null => {
      const fieldValue = memberRecord.fields?.[fieldId];
      if (!fieldValue) return null;

      const displayValue = isBoolean(fieldValue)
        ? find(memberFieldSchemas, ({ id }) => String(id) === fieldId)?.name
        : fieldValue;

      return displayValue ? (
        <Tag size="default" key={fieldId}>
          {displayValue}
        </Tag>
      ) : null;
    },
    [memberRecord.fields, memberFieldSchemas],
  );

  return (
    <div className={styles.socialDemographics}>
      {map(matchedFields, (fieldId, _key) => getDisplayForAttribute(fieldId))}
    </div>
  );
};

export default CreatorAttribute;
