import { useGetUsersQuery, useProgramsQuery } from '@frontend/app/hooks';
import { FulfillmentStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useAppContext } from '@frontend/context/AppContext';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@frontend/shadcn/components/ui/collapsible';
import { Skeleton } from '@revfluence/fresh';
import { ChevronDownIcon } from '@revfluence/fresh-icons/regular/esm';
import React from 'react';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { usePFADashboardContext } from './ProductFulfillmentDashboardContext';
import { fulfillmentStatusLabels } from './constants';

export const FilterContent = () => {
  const { apolloClient } = useAppContext();

  const { data: users, loading: isUsersLoading } = useGetUsersQuery({
    client: apolloClient,
  });

  const { data: programs, loading: isProgramsLoading } = useProgramsQuery({
    client: apolloClient,
  });

  const {
    localOwnerIds,
    localProjectIds,
    localFulfillmentStatus,
    setLocalOwnerIds,
    setLocalProjectIds,
    setLocalFulfillmentStatus,
  } = usePFADashboardContext();

  const handleFulfillmentStatusChange = (checked: boolean, status: FulfillmentStatus) => {
    if (checked) {
      setLocalFulfillmentStatus([...localFulfillmentStatus, status]);
    } else {
      setLocalFulfillmentStatus(localFulfillmentStatus.filter((s) => s !== status));
    }
  };

  const handleUserIdsChange = (checked: boolean, id: string) => {
    if (checked) {
      setLocalOwnerIds([...localOwnerIds, id]);
    } else {
      setLocalOwnerIds(localOwnerIds.filter((ownerId) => ownerId !== id));
    }
  };

  const handleProjectIdsChange = (checked: boolean, id: number) => {
    if (checked) {
      setLocalProjectIds([...localProjectIds, id]);
    } else {
      setLocalProjectIds(localProjectIds.filter((projectId) => projectId !== id));
    }
  };

  return (
    <div className="flex flex-col gap-3 p-6">
      <Collapsible defaultOpen>
        <CollapsibleTrigger className="flex gap-2 pr-3 py-2 w-full mx-0 cursor-pointer rounded-md font-medium text-foreground text-base leading-relaxed items-center">
          <span className="text-base">Fulfillment Status</span>
          <ChevronDownIcon className="ml-auto h-4 w-4" />
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col gap-2 max-h-[150px] overflow-y-auto">
            {Object.entries(fulfillmentStatusLabels).map(([status, label]) => (
              <div key={status} className="flex items-center space-x-2">
                <Checkbox
                  key={status}
                  checked={localFulfillmentStatus.includes(status as FulfillmentStatus)}
                  onCheckedChange={(checked) =>
                    handleFulfillmentStatusChange(
                      checked === 'indeterminate' ? false : checked,
                      status as FulfillmentStatus,
                    )}
                />
                <span>{label}</span>
              </div>
            ))}
          </div>
        </CollapsibleContent>
      </Collapsible>
      <Separator />
      <Collapsible defaultOpen>
        <CollapsibleTrigger className="flex gap-2 pr-3 py-2 w-full mx-0 cursor-pointer rounded-md font-medium text-foreground text-base leading-relaxed items-center">
          <span className="text-base">Owner</span>
          <ChevronDownIcon className="ml-auto h-4 w-4" />
        </CollapsibleTrigger>
        <CollapsibleContent>
          {isUsersLoading ? (
            <Skeleton active />
          ) : (
            <div className="flex flex-col gap-2 max-h-[150px] overflow-y-auto">
              {users?.users?.map((user) => (
                <div key={user.id} className="flex items-center space-x-2">
                  <Checkbox
                    key={user.id}
                    checked={localOwnerIds.includes(user.id)}
                    onCheckedChange={(checked) =>
                      handleUserIdsChange(checked === 'indeterminate' ? false : checked, user.id)}
                  />
                  <span>{user.name}</span>
                </div>
              ))}
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
      <Separator />
      <Collapsible defaultOpen>
        <CollapsibleTrigger className="flex gap-2 pr-3 py-2 w-full mx-0 cursor-pointer rounded-md font-medium text-foreground text-base leading-relaxed items-center">
          <span className="text-base">Project</span>
          <ChevronDownIcon className="ml-auto h-4 w-4" />
        </CollapsibleTrigger>
        <CollapsibleContent>
          {isProgramsLoading ? (
            <Skeleton active />
          ) : (
            <div className="flex flex-col gap-2 max-h-[150px] overflow-y-auto">
              {programs?.programs
                ?.slice() // Create a shallow copy of the array
                .sort((a, b) => a.title.localeCompare(b.title)) // Sort the copied array
                .map((program) => (
                  <div key={program.id} className="flex items-center space-x-2">
                    <Checkbox
                      key={program.id}
                      checked={localProjectIds.includes(program.id)}
                      onCheckedChange={(checked) =>
                        handleProjectIdsChange(checked === 'indeterminate' ? false : checked, program.id)}
                    />
                    <span>{program.title}</span>
                  </div>
                ))}
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};
