import gql from 'graphql-tag';

export const GET_AFFLIATE_OFFER_CONVERSION_HISTORY_QUERY = gql`
  query GetAffiliateConversionHistory($offerSource: OFFER_SOURCE!, $affiliateOfferId: Int!) {
    affliateConversionHistory: affiliateConversionHistory(
      offerSource: $offerSource
      affiliateOfferId: $affiliateOfferId
    ) {
      status
      conversionId
      conversionDate
      sale
      salesBase
      currency
      payoutEarned
      exchangeRate
      storeName
      id
      isPaid
      affiliatInfoOne
      tuneOrderId
      discountCode
      source
      isNewCustomer
    }
  }
`;
