import 'css-chunk:src/components/widgets/Invite/Favorite.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "buttonWidth": "80",
  "popoverWidth": "320",
  "flex": "_flex_axxph_157",
  "justify-content-space-between": "_justify-content-space-between_axxph_161",
  "tabular-nums": "_tabular-nums_axxph_165",
  "Favorite": "_Favorite_axxph_169",
  "Invite": "_Invite_axxph_169",
  "isFetching": "_isFetching_axxph_416",
  "loading": "_loading_axxph_423",
  "hideButton": "_hideButton_axxph_427",
  "select": "_select_axxph_427",
  "inviteButton": "_inviteButton_axxph_431",
  "groupOption": "_groupOption_axxph_431",
  "groupOptionAction": "_groupOptionAction_axxph_431",
  "toggleButton": "_toggleButton_axxph_431",
  "withButton": "_withButton_axxph_437",
  "selectButton": "_selectButton_axxph_444",
  "withBorder": "_withBorder_axxph_448",
  "isFocused": "_isFocused_axxph_473",
  "inviting": "_inviting_axxph_496",
  "wait": "_wait_axxph_499",
  "disabled": "_disabled_axxph_503",
  "popover": "_popover_axxph_507",
  "popoverContentWrapper": "_popoverContentWrapper_axxph_511",
  "shown": "_shown_axxph_514",
  "message": "_message_axxph_514",
  "popoverContent": "_popoverContent_axxph_511",
  "option": "_option_axxph_524",
  "noSelect": "_noSelect_axxph_544",
  "communityOption": "_communityOption_axxph_547",
  "optionTitle": "_optionTitle_axxph_547",
  "programOption": "_programOption_axxph_557",
  "noPrograms": "_noPrograms_axxph_560",
  "accountLink": "_accountLink_axxph_583",
  "ineligible": "_ineligible_axxph_588",
  "error": "_error_axxph_591",
  "noCommunities": "_noCommunities_axxph_595",
  "new": "_new_axxph_617",
  "invited": "_invited_axxph_618",
  "approved": "_approved_axxph_619",
  "tooltip": "_tooltip_axxph_631",
  "ammendAction": "_ammendAction_axxph_638",
  "searchContainer": "_searchContainer_axxph_646",
  "searchOption": "_searchOption_axxph_650",
  "searchError": "_searchError_axxph_667",
  "FavoriteButton": "_FavoriteButton_axxph_686",
  "HeartButton": "_HeartButton_axxph_694",
  "FavoriteButtonDisabled": "_FavoriteButtonDisabled_axxph_697",
  "active": "_active_axxph_702",
  "isLoading": "_isLoading_axxph_705",
  "isDisabled": "_isDisabled_axxph_712",
  "spinner": "_spinner_axxph_724",
  "groupOptionActionLabel": "_groupOptionActionLabel_axxph_737",
  "addButton": "_addButton_axxph_747",
  "show": "_show_axxph_514"
};