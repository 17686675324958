import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { Tooltip } from '@revfluence/fresh';

interface TagProps {
  label: string | ReactNode;
  bgColor?: string; // Background color for the tag
  textColor?: string; // Text color for the tag
  isRemovable?: boolean;
  onRemove?: () => void;
  onClick?: () => void;
  borderDashed?: boolean; // New prop to control dashed border
  className?: string;
  tooltip?: string; // Tooltip text
  icon?: ReactNode; // Icon to display before the label
}

const Tag: React.FC<TagProps> = ({
  label,
  bgColor = 'bg-grey-1', // Default background color
  textColor = 'text-grey-9', // Default text color
  isRemovable = false,
  onRemove,
  onClick,
  borderDashed = false,
  className = '',
  tooltip,
  icon,
}) => {
  const baseClasses = 'inline-flex items-center h-6 px-2 py-0.5 rounded-lg whitespace-nowrap';
  const borderClasses = borderDashed ? 'border border-dashed border-gray-400' : '';

  // Check if label is a string and apply default font size
  const labelClasses = typeof label === 'string' ? 'text-xs font-normal' : '';

  const tagContent = (
    <div
      className={cn(baseClasses, bgColor, textColor, borderClasses, className, {
        'cursor-pointer hover:bg-opacity-80': onClick,
      })}
      onClick={onClick}
    >
      {icon && <span className="mr-1 leading-none flex-shrink-0">{icon}</span>}
      <span className={classNames(labelClasses, 'leading-none truncate')}>{label}</span>
      {isRemovable && (
        <button
          type="button"
          className="ml-1 text-grey-5 hover:text-grey-7/80 leading-none flex items-center flex-shrink-0"
          onClick={(e) => {
            e.stopPropagation();
            onRemove && onRemove();
          }}
        >
          <XmarkIcon className="h-3 w-3" />
        </button>
      )}
    </div>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="top">
      {tagContent}
    </Tooltip>
  ) : (
    tagContent
  );
};

export default Tag;
