import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { ArrowDownToLineIcon, ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/solid';
import { GetSocialAnalyticsPostsQuery, PostInsights } from '@frontend/gql/social/graphql';
import { Card } from '@frontend/shadcn/components/ui/card';
import { SearchInput } from '@frontend/app/refresh-components';
import { SortingState } from '@tanstack/react-table';
import MembersCard from '../members-card';
import ReachCard from '../reach-card';
import EngagementCard from '../engagement-card';
import ImpressionsCard from '../impressions-card';
import PostsCard from '../post-card';
import TMVCard from '../tmv-card';
import {
  TableHeader,
  TableHeaderSection,
  TableHeaderLayout,
  TableHeaderLabel,
  TableHeaderIconButton,
  TableHeaderPagination,
  useTablePagination,
} from '../table-header';
import { usePostTable } from '../table/hooks/usePostsTable';
import PostTable from '../table/PostTable';
import { PostTableColumn } from '../table/config/defaultPostTableColumns';

const { useState } = React;

interface PostsTabProps {
  data: GetSocialAnalyticsPostsQuery | undefined;
  isLoading: boolean;
  isPartialLoading: boolean;
  selectedColumns: PostTableColumn<PostInsights>[];
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSelectedColumns: (columns: PostTableColumn<PostInsights>[]) => void;
  sorting: SortingState;
  setSorting: (sorting: SortingState) => void;
}

const TABLE_PAGE_SIZE = 50;

export const PostsTab = ({
  data,
  isLoading,
  isPartialLoading,
  selectedColumns,
  setSelectedColumns,
  sorting,
  setSorting,
  setCurrentPage,
  currentPage,
}: PostsTabProps) => {
  const posts = data?.client?.socialAnalytics?.posts?.results || [];
  const totalInsights = data?.client?.socialAnalytics?.totalInsights;
  const [searchText, setSearchText] = useState('');

  const updateGlobalFilterText = (text: string) => {
    setSearchText(text);
    setCurrentPage(0);
  };
  const { postTableProps } = usePostTable({
    selectedColumns,
    setSelectedColumns,
    data: posts,
    currentPage,
    pageSize: TABLE_PAGE_SIZE,
    globalFilter: searchText,
    handleSortingChange: setSorting,
    sorting,
    setIsPartialLoading: null, // do we do partial loading for this table?
  });

  const { paginationProps, prevButtonProps, nextButtonProps } = useTablePagination({
    currentPage,
    totalCount: posts?.length || 0,
    pageSize: TABLE_PAGE_SIZE,
    onPageChange: setCurrentPage,
  });
  if (isLoading && !isPartialLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <section className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-6 gap-4 p-4">
        <PostsCard data={totalInsights} />
        <MembersCard data={totalInsights} />
        <ImpressionsCard data={totalInsights} />
        <EngagementCard data={totalInsights} />
        <ReachCard data={totalInsights} />
        <TMVCard data={totalInsights} />
      </section>
      <Card className="mx-4 mb-4" aria-label="Members table">
        <TableHeader>
          <TableHeaderLayout>
            <TableHeaderSection>
              <TableHeaderLabel>
                <div className="flex items-center gap-2">
                  <span aria-live="polite">{paginationProps.totalCount} members</span>
                </div>
              </TableHeaderLabel>
            </TableHeaderSection>
            <TableHeaderSection>
              <SearchInput searchText={searchText} setSearchText={updateGlobalFilterText} />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <TableHeaderIconButton data-dd-action-name="export-members-data">
                    <div className="flex items-center">
                      <ArrowDownToLineIcon className="h-4 w-4" aria-hidden="true" />
                    </div>
                  </TableHeaderIconButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    data-dd-action-name="export-current-view"
                    aria-label="Export current view of member data"
                  >
                    Export current view
                  </DropdownMenuItem>
                  <DropdownMenuItem data-dd-action-name="export-full-data" aria-label="Export all member data fields">
                    Export all fields
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              {/* <ColumnDialog>
                <TableHeaderIconButton
                  data-dd-action-name="open-column-selector"
                  aria-label="Select columns to display"
                >
                  <ColumnsIconV3 size={16} aria-hidden="true" />
                </TableHeaderIconButton>
              </ColumnDialog> */}
              <nav aria-label="Table navigation" className="flex items-center">
                <TableHeaderIconButton
                  {...prevButtonProps}
                  data-dd-action-name="posts-table-previous-page"
                  aria-label="Previous page"
                  aria-disabled={currentPage === 0}
                >
                  <ChevronLeftIcon aria-hidden="true" />
                </TableHeaderIconButton>
                <TableHeaderPagination
                  {...paginationProps}
                  aria-label={`Page ${currentPage + 1} of ${Math.ceil(
                    (posts?.length || 0) / TABLE_PAGE_SIZE,
                  )}`}
                />
                <TableHeaderIconButton
                  {...nextButtonProps}
                  data-dd-action-name="posts-table-next-page"
                  aria-label="Next page"
                  aria-disabled={currentPage === Math.ceil((posts?.length || 0) / TABLE_PAGE_SIZE) - 1}
                >
                  <ChevronRightIcon aria-hidden="true" />
                </TableHeaderIconButton>
              </nav>
            </TableHeaderSection>
          </TableHeaderLayout>
        </TableHeader>
        <PostTable
          {...postTableProps}
          aria-busy={isLoading}
          aria-label="Members data table"
        />
      </Card>
    </>
  );
};
