import * as React from 'react';

import { WizardContainer } from '@frontend/applications/Shared/Wizard/container';
import { OfferImage } from '@affiliates/components';

import { OFFER_SOURCE } from '../../types/globalTypes';
import { SelectOffersTable, ISelectOfferTableRow } from '../SelectOffersTable/SelectOffersTable';

import styles from './SelectOffers.scss';

export interface ISelectOffers {
  offerData: ISelectOfferTableRow[];
  onSelectedOfferChange: (key: React.Key) => void;
  onUseSelectedOffer: () => void;
  onCreateNewOffer: () => void;
  offerSource: OFFER_SOURCE;
  isSetup?: boolean;
}
export const SelectOffers: React.FC<ISelectOffers> = ({
  offerData,
  onSelectedOfferChange,
  onUseSelectedOffer,
  onCreateNewOffer,
  offerSource,
  isSetup,
}) => {
  const [offerSelected, setOfferSelected] = React.useState(false);

  const onSelectedRowChange = (selectedRowKeys: React.Key[], _: ISelectOfferTableRow[]) => {
    setOfferSelected(!!selectedRowKeys[0]);
    onSelectedOfferChange(selectedRowKeys[0]);
  };

  const selectOffersTable = <SelectOffersTable offerData={offerData} onChange={onSelectedRowChange} />;

  const getTitleAndDescription = () => {
    let title;
    let description;
    if (isSetup && offerSource === OFFER_SOURCE.TUNE) {
      title = 'Change the offer you associate with this project.';
      description = 'If you change this associated offer, make sure to add the new tracking snippet to the conversion page on your website and test a sale. Then you can send these people their unique links.';
    } else if (isSetup && offerSource === OFFER_SOURCE.SHOPIFY) {
      title = 'Change the offer you associate with this project.';
      description = 'These are all the active sales tracking promo code based offers you have available. If you change this offer and haven’t used it before, it would most likely be good to make a test sale.';
    } else if (offerSource === OFFER_SOURCE.TUNE) {
      title = 'Sales Tracking Link Setup';
      description = 'These are all the link tracking based offers you have available that aren\'t already assigned to a project. Once you set up or choose an offer, make sure to add the tracking snippet to the conversion page on your website and test a sale. Then you can send these people their unique links.';
    } else if (offerSource === OFFER_SOURCE.SHOPIFY) {
      title = 'Sales Tracking Promo Code Setup';
      description = 'These are all the promo code based offers you have available that aren\'t already assigned to a project.';
    }
    return { title, description };
  };

  const offerType = offerSource === OFFER_SOURCE.SHOPIFY ? 'promo code' : 'link tracking';
  const { title, description } = getTitleAndDescription();

  const stepsInfo = [
    {
      title,
      stepNum: 1,
      instructions: {
        title: `You need to set up or choose an existing ${offerType} offer.`,
        description,
      },
      actionComponents: selectOffersTable,
      nextButtonConfig: {
        text: 'Use Selected Offer',
        action: onUseSelectedOffer,
        className: styles.nextButton,
        disabled: !offerSelected,
      },
      previousButtonConfig: {
        text: 'Create New Offer',
        action: onCreateNewOffer,
      },
    },
  ];

  return (
    <div className={styles.SelectOffers}>
      <WizardContainer
        icon={<OfferImage source={offerSource} imageUrl={null} rounded iconName={null} />}
        noBodyPadding
        noHeader
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        stepsInfo={stepsInfo as any}
      />
    </div>
  );
};
SelectOffers.defaultProps = {
  isSetup: false,
};
