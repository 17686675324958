import * as React from 'react';
import cx from 'classnames';
import {
 filter, isFunction, map, size,
} from 'lodash';

import {
 Col, Row, Select, Typography, Popover, Tag,
} from '@revfluence/fresh';

import { useQuery } from '@apollo/client';
import { emailRegex } from '@components';
import { IMemberSearchQuery, Operator } from '@frontend/app/types/MemberSearch';
import { SEARCH_MEMBERS_QUERY } from '@frontend/app/queries';
import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import { useGetTalentAgentsByAgentMemberIds } from '@frontend/app/hooks';
import {
  SearchMembersQuery,
  SearchMembersQueryVariables,
} from '@frontend/app/queries/types/SearchMembersQuery';

import styles from './AutocompleteMemberInput.scss';

const {
  useMemo, useState, useEffect, useCallback,
} = React;

type SelectMember = IMember & { label: string, value: string };

interface IProps {
  validMembers?: IMember[];
  onChange(members: IMember[]);
  clearBackupPlans?(): void;
  disableInput?: boolean;
  members?: IMember[];
  debounce?: number;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const AutocompleteMemberInput: React.FunctionComponent<IProps> = React.memo((props) => {
  const [text, setText] = useState<string>('');
  const [recipientsByLabel, setRecipientsByLabel] = useState<Record<string, IMember>>({});
  const { data: talentAgentsData } = useGetTalentAgentsByAgentMemberIds({
    variables: {
      memberIds: props.validMembers?.map((member) => member.id).filter((id) => id && id > props.validMembers?.length),
    },
    skip: !props.validMembers?.length,
  });

  const agentMemberMap = useMemo(() => {
    if (!talentAgentsData?.talentAgents) return {};

    return talentAgentsData.talentAgents.reduce((acc, agent) => {
      acc[agent.agentMemberId] = true;
      return acc;
    }, {} as Record<number, boolean>);
  }, [talentAgentsData?.talentAgents]);

  const query: IMemberSearchQuery = {
    fields: {
      and: [
        {
          or: [
            { column: 'email', [Operator.CONTAINS]: text },
            { column: 'name', [Operator.CONTAINS]: text },
          ],
        },
        { column: 'email', [Operator.NOT_NULL]: true },
      ],
    },
  };

  const {
    data: {
      members: membersData = [],
    } = {},
    loading: isSearching,
  } = useQuery<SearchMembersQuery, SearchMembersQueryVariables>(SEARCH_MEMBERS_QUERY, {
    variables: {
      query,
      take: 10,
      skip: 0,
    },
    skip: !text,
    fetchPolicy: 'network-only',
  });

  const memberIdsSet = useMemo(() => {
    const result = new Set<number>();
    props.validMembers.forEach((validMember) => {
      if (validMember.id && validMember.id > props.validMembers?.length) {
        result.add(validMember.id);
      }
    });
    membersData.map((member) => result.add(member.id));
    return result;
  }, [membersData, props.validMembers]);

  const defaultRecipients = useMemo(() => (
    props.validMembers.map(
      (member) => {
        const talentAgentsCC = filter(member.talentAgents, 'alwaysCC');
        return {
          ...member,
          label: member.name
          ? (
            member.name + (size(talentAgentsCC) > 0 ? (` + ${size(talentAgentsCC)}`) : '')
          ) : member.email,
          value: member.email,
        };
      },
    )
  ), [props.validMembers]);

  const [selectedRecipients, setSelectedRecipients] = useState<SelectMember[]>(defaultRecipients);

  useEffect(() => {
    setSelectedRecipients(defaultRecipients);
  }, [defaultRecipients]);

  useEffect(() => {
    setRecipientsByLabel(
      selectedRecipients.reduce((acc, recipient) => {
        acc[recipient.label] = recipient;
        return acc;
      }, {}),
    );
  }, [selectedRecipients]);

  const handleSelect = (email) => {
    if (!emailRegex.test(email)) {
      return;
    }

    let newRecipients = [];
    setSelectedRecipients((prevValue) => {
      const member = membersData.find((m) => m.email === email);
      if (member) {
        const talentAgentsCC = filter(member.talentAgents, 'alwaysCC');
        newRecipients = [
          ...prevValue,
          {
            label: member.name + (size(talentAgentsCC) > 0 ? ` + ${size(talentAgentsCC)}` : ''),
            value: email,
            ...member,
          },
        ];
      } else {
        newRecipients = [...prevValue, { label: email, value: email, email }];
      }

      props.onChange(newRecipients as SelectMember[]);
      if (isFunction(props.clearBackupPlans)) {
        props.clearBackupPlans();
      }
      return newRecipients;
    });
  };

  const handleDeSelect = (email) => {
    let filtered = [];
    setSelectedRecipients((prevValue) => {
      filtered = prevValue?.filter((obj) => obj.value !== email);
      props.onChange(filtered);
      return filtered;
    });
  };

  const suggestedRecipients = useMemo(() => {
    if (!text?.trim()) {
      return [];
    }

    if (membersData?.length > 0) {
      return membersData.map((member) => ({
        label: member.name || member.email,
        value: member.email,
      }));
    }

    if (emailRegex.test(text)) {
      return [{
        label: text,
        value: text,
      }];
    }

    return [];
  }, [text, membersData]);

  const handleSearch = useCallback((value: string) => {
    setText(value?.trim() || '');
  }, []);
  const renderPopoverContent = (label: string) => {
    const recipient = recipientsByLabel[label];
    const isMemberRecord = memberIdsSet.has(recipient?.id);
    const isMember = isMemberRecord && !agentMemberMap[recipient?.id];

    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={styles.recipientPopoverContent}
      >
        {isMemberRecord && (
          <div className={styles.section}>
            <h4>{recipient?.id && agentMemberMap[recipient?.id] ? 'Talent Manager' : 'Member'}</h4>
            <p>{recipient?.name}</p>
            <p>{recipient?.email}</p>
          </div>
        )}
        {!isMemberRecord && (
          <div className={styles.section}>
            <h4>Email</h4>
            <p>{recipient?.email}</p>
          </div>
        )}
        {isMember && map(filter(recipient?.talentAgents, 'alwaysCC'), (agent, i) => (
          <div className={styles.section}>
            <h4>
              Talent Manager #
              {i + 1}
            </h4>
            <p>{agent.agentName}</p>
            <p>{agent.agentEmail}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderTag = (props) => (
    <Popover
      content={renderPopoverContent(props.label)}
      placement="bottom"
    >
      <Tag
        closable={props.closable}
        onClose={props.onClose}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={styles.recipientTag}
      >
        {props.label}
      </Tag>
    </Popover>
  );

  return (
    <div className={cx(props.className)}>
      <Select
        className={styles.recipientsSelect}
        mode="multiple"
        placeholder={props.placeholder || 'Select recipients'}
        tagRender={renderTag}
        value={selectedRecipients}
        onSelect={handleSelect}
        onDeselect={handleDeSelect}
        onSearch={handleSearch}
        autoFocus={selectedRecipients.length === 0}
        disabled={props.disableInput}
        filterOption={false}
        notFoundContent={
          text ? (
            isSearching ? (
              <div className={styles.loadingText}>
                Searching...
              </div>
            ) : (
              'No results found'
            )
          ) : null
        }
      >
        {suggestedRecipients.map((member) => (
          <Select.Option
            value={member.value}
            key={member.value}
            label={member.value}
          >
            <Row justify="space-between">
              <Col>
                <Typography.Text>
                  {member.label === member.value ? '' : member.label}
                  {` <${member.value}>`}
                </Typography.Text>
              </Col>
            </Row>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
});

AutocompleteMemberInput.defaultProps = {
  debounce: 500,
};
