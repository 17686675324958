export function formatNumber(currentIntervalAmount: number | null | undefined) {
  if (!currentIntervalAmount) return 0;

  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(currentIntervalAmount);
}

export function formatNumberDefault(currentIntervalAmount: number | null | undefined) {
  if (!currentIntervalAmount) return 0;

  // Use compact notation for large numbers (1K, 1M, etc.)
  if (currentIntervalAmount >= 1000) {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
    }).format(currentIntervalAmount);
  }

  // Use standard formatting for smaller numbers
  return new Intl.NumberFormat('en-US').format(currentIntervalAmount);
}
