import * as React from 'react';
import moment from 'moment';
import cx from 'classnames';
import {
  filter,
  find,
  findIndex,
  isBoolean,
  map,
  some,
  isEmpty,
  upperFirst,
  first,
  size,
  slice,
  trim,
} from 'lodash';

import {
  Button,
  Checkbox,
  Input,
  IOption,
  RoundAddCircleIcon,
  Select,
  RadioGroup,
  TrashcanIcon,
  SubmitButton,
} from '@components';
import { EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import {
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';

import { useResourceContext } from '@frontend/app/context';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import {
  ApplicationMemberFieldSchemaInput,
} from '@frontend/app/types/globalTypes';
import { IEmailEditorState } from '@frontend/app/components/MessageComposer/EmailEditor';
import { IPreviewConfig } from '@frontend/app/components/MessageComposer//EmailComposer';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';
import { MemberFieldSchemasQuery_schemas } from '@frontend/app/queries/types/MemberFieldSchemasQuery';
import { useSaveRequestsBatchMutation } from '@frontend/app/hooks/useSaveRequestsBatchMutation';
import { subjectLineForClientAndProgram } from '@frontend/applications/Shared/utils';
import { GetMemberQuery_member as IMember } from '@frontend/app/queries/types/GetMemberQuery';
import { ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { ClientFeature } from '@frontend/app/constants';
import { Tooltip } from 'antd';
import MessageComposer from './MessageComposer';
import AccordionHeader from './AccordionHeader';

import styles from './NewBulkUpdateRequest.scss';

// Add imports for shadcn components
import { Button as ShadcnButton } from '@/shadcn/components/ui/button';

const { useMemo, useEffect, useState } = React;

interface IProps {
  className?: string;
  closeModal(): void;
  members: IMember[];
  memberQueryJson?: string;
  membersCount: number;
}

/**
 * View model for the form fields
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
interface IFormField extends ApplicationMemberFieldSchemaInput, Partial<Omit<any, 'id'>> {
  uuid?: string;
}

const DEFAULT_DB_COLUMNS: readonly IFormField[] = Object.freeze([
  {
 schemaId: null, name: 'email', label: 'Email', isDefault: true,
},
]);

const LINK_PLACEHOLDER = 'https://landingpageurl/';

// to only show user created fields
const DEFAULT_DATE_FIELDS = ['Last Sent Message', 'Last Received Message', 'Last Engaged'];

enum STEPS {
  SELECT_FIELDS = 'select_fields',
  COMPOSE_MESSAGE = 'compose_message',
  UPDATE_FIELD = 'update_field',
  DONE = 'done',
}

const DEFAULT_SUBJECT = 'Please confirm your information';

const NewBulkUpdateRequest: React.FunctionComponent<IProps> = ((props) => {
  const { workflowActionParameters, clientName } = useApplication();
  const [step, setStep] = useState(STEPS.SELECT_FIELDS);
  const [isNewDateField, setIsNewDateField] = useState(true);
  const [requestedFields, setRequestedFields] = useState([]);
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const [subject, setSubject] = useState(DEFAULT_SUBJECT);
  const [editorState, setEditorState] = useState<IEmailEditorState>(null);
  const [skipUpdateField, setSkipUpdateField] = useState(false);
  const [skipMessage, setSkipMessage] = useState(false);
  const [existingDateField, setExistingDateField] = useState(null);
  const [createDateField, setCreateDateField] = useState({ schemaId: -1, label: `Member info update ${moment().format('M-D-YY')}` });

  const { [ClientFeature.FLEXIBLE_PROJECT]: isFlexEnabled } = useClientFeatures();

  const [resourceId, setResourceId] = useState<number>(null);

  const { activeEmailResources: resources } = useResourceContext();

  const updateField = isNewDateField ? createDateField : existingDateField;

  const resource = useMemo(
    () => find(resources, (resource) => resource.id === resourceId),
    [resources, resourceId],
  );

  useEffect(() => {
    if (!isEmpty(resources)) {
      setResourceId(first(resources).id);
    }
  }, [resources]);

  useEffect(() => {
    if (workflowActionParameters?.programName && subject === DEFAULT_SUBJECT) {
      setSubject(subjectLineForClientAndProgram(clientName, workflowActionParameters?.programName, 'Info needed'));
    }
  }, [workflowActionParameters?.programName, subject, setSubject, clientName]);

  const {
    showError,
  } = useMessagingContext();

  const addEvent = useEventContext();
  const [saveRequestsBatch, { loading: sendingRequest }] = useSaveRequestsBatchMutation();

  const {
    data: {
      schemas: memberFields = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const hasLink = editorState?.html?.includes(LINK_PLACEHOLDER);

  const editableFieldSchemas = useMemo(() => filter(memberFields, (schema) => (!schema.applicationId || schema.metaData?.editable)), [memberFields]);

  const fields: IFormField[] = useMemo(() => {
    const filteredMemberFieldSchemas = map(editableFieldSchemas, (schema: MemberFieldSchemasQuery_schemas) => ({
      schemaId: schema.id,
      name: schema.name,
      type: schema.type,
      required: false,
    } as IFormField));
    return [
      ...DEFAULT_DB_COLUMNS,
      ...filteredMemberFieldSchemas,
    ];
  }, [editableFieldSchemas]);

  useEffect(() => {
    addEvent(EventName.DataRequestOpened, { member_count: props.membersCount });
  }, [props.membersCount, addEvent]);

  useEffect(() => {
    const programId = workflowActionParameters?.programId;
    let initialFields = [];
    if (programId) {
      initialFields = [
        { name: 'Address First Name', required: true },
        { name: 'Address Last Name', required: true },
        { name: 'Address1', required: true },
        { name: 'Address2', required: false },
        { name: 'City', required: true },
        { name: 'State', required: true },
        { name: 'PostalCode', required: true },
        { name: 'Country', required: true },
      ];
    } else {
      initialFields = [
        { name: 'First Name', required: false },
        { name: 'Last Name', required: false },
      ];
    }

    setRequestedFields(fields
      .reduce((filtered, field) => {
        const temp = initialFields.find((f) => f.name === field.name);
        if (temp) {
          filtered.push({ ...field, required: temp.required });
        }
        return filtered;
      }, [])
      .sort((f1, f2) => initialFields.indexOf(f1.name) - initialFields.indexOf(f2.name)));
  }, [fields, workflowActionParameters]);

  const fieldsOptions = useMemo<IOption[]>(() => (
    map(fields, (field) => ({
      label: field.name === 'email' ? upperFirst(field.name) : field.name,
      value: field.schemaId,
    }))
      .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()))
  ), [fields]);

  const dateFieldsOptions = useMemo<IOption[]>(() => (
    filter(fields, (field) => field.type === 'DATE' && !DEFAULT_DATE_FIELDS.includes(field.name)).map((field) => ({
      label: field.name,
      value: field.schemaId,
    }))
      .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()))
  ), [fields]);

  const nextStep = (createFieldUpdate?: boolean) => {
    switch (step) {
      case STEPS.SELECT_FIELDS:
        // filter unset fields
        setRequestedFields(
          filter(requestedFields, (schema) => schema.schemaId !== -1),
        );
        return setStep(STEPS.COMPOSE_MESSAGE);
      case STEPS.COMPOSE_MESSAGE:
        return setStep(STEPS.UPDATE_FIELD);
      default:
        // eslint-disable-next-line no-template-curly-in-string
        let message = editorState.html.replace(LINK_PLACEHOLDER, '${landingPageUrl}');
        if (resource.config.signature?.mode === 'html' && !!resource.config.signature?.fromHTML) {
          message = trim(`${message}<div><br></div>${resource.config.signature?.fromHTML.replace(/\n/g, '<br />')}`);
        } else if (resource.config.signature?.mode === 'editor' && !!resource.config.signature?.fromEditor) {
          message = trim(`${message}<div><br></div>${resource.config.signature?.fromEditor}`);
        }
        const programId = workflowActionParameters?.programId;
        const workItemIds = map(workflowActionParameters?.workItems, (w) => w.id);

        saveRequestsBatch({
          variables: {
            batch: {
              memberIds: props.members.map((m) => m.id),
              memberQueryJson: props.memberQueryJson && JSON.parse(props.memberQueryJson),
              memberFieldSchemas: requestedFields.map((f) => ({ schemaId: f.schemaId, label: f.label || f.name, required: f.required })),
              message: skipMessage ? undefined : { subject, message },
              updateField: createFieldUpdate ? updateField : null,
              programId,
              workItemIds,
            },
            resourceId,
          },
        }).then(({ data }) => {
          setStep(STEPS.DONE);
          addEvent(EventName.DataRequestSent, {
            member_count: props.membersCount,
            fields_count: requestedFields.length,
            fields: requestedFields.map((f) => f.schemaId),
            used_last_update_column: createFieldUpdate,
            batch_id: data?.batchResponse?.batch.id,
          });
        }).catch(showError);
    }
  };

  const handleAddFormField = () => {
    setRequestedFields([
      ...requestedFields,
      { schemaId: -1 },
    ]);
  };

  const handleDeleteFormField = (schemaId: number) => {
    setRequestedFields(
      filter(requestedFields, (schema) => schema.schemaId !== schemaId),
    );
  };

  const handleChangeFormFieldType = (index: number, schemaId: number) => {
    setRequestedFields(
      map(requestedFields, (field, i) => {
        if (index === i) {
          const fieldDef = find(fields, (f) => f.schemaId === schemaId);
          return {
            schemaId,
            name: fieldDef.name,
            required: fieldDef.required,
          };
        }
        return field;
      }),
    );
  };

  const handleChangeFormFieldLabel = (schemaId: number, label: string) => {
    const updatedFieldIndex = findIndex(requestedFields, (f) => f.schemaId === schemaId);
    const field = requestedFields[updatedFieldIndex];
    setRequestedFields([
      ...slice(requestedFields, 0, updatedFieldIndex),
      {
        ...field,
        label,
      },
      ...slice(requestedFields, updatedFieldIndex + 1),
    ]);
  };

  const handleChangeFormFieldRequired = (schemaId: number, required: boolean) => {
    setRequestedFields(
      map(requestedFields, (schema) => {
        if (schema.schemaId === schemaId) {
          return {
            ...schema,
            required,
          };
        }
        return schema;
      }),
    );
  };

  const text = useMemo(() => {
    const requestWord = props.membersCount > 1 ? 'requests' : 'request';
    return `Success! We are sending your ${props.membersCount} ${requestWord}`;
  }, [props.membersCount]);

  if (step === STEPS.DONE) {
    return (
      <div className={cx(styles.BulkUpdateRequest, props.className)}>
        <p className={styles.successHeader}>
          {text}
        </p>
        <p className={styles.subheader}>This could take up to a few minutes to finish sending all of them.</p>
        <div className={styles.summary}>
          <div className={styles.summarySection}>
            <p className={styles.stepTitle}>Fields you selected for member confirmation</p>
            {map(requestedFields, (f) => (<p key={f.name}>{f.name}</p>))}
          </div>
          <div className={styles.summarySection}>
            <p className={styles.stepTitle}>Your message</p>
            {
              editorState
              && !skipMessage
              && (
              <MessageComposer
                readOnly
                subject={subject}
                onSubjectChange={setSubject}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                memberCount={props.membersCount}
              />
)
            }
          </div>
          {
            !skipUpdateField && (
              <div className={styles.summarySection}>
                <p className={styles.stepTitle}>Custom date field name</p>
                <p>{updateField.label}</p>
              </div>
            )
          }
          <div className={styles.summarySection}>
            <Button label="Done" onClick={props.closeModal} />
          </div>
        </div>
      </div>
    );
  }

  // Should simply return error message if there are no resources as data update depends on
  // emailing members
  if (size(resources) === 0) {
    return (
      <div className={cx(styles.BulkUpdateRequest, props.className)}>
        <div className={styles.noResourceWarning}>
          Requesting info from members works by sending an email to your members to ask them to provide info.
          In order to use info update, please connect a GMail or Outlook email account first.
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.BulkUpdateRequest, props.className)}>
      <p className={styles.subheader}>Let members know what information you have on file for them while allowing them to update it.</p>

      <div className={styles.container}>
        {
          step !== STEPS.SELECT_FIELDS && (
            <AccordionHeader
              onClick={() => setStep(STEPS.SELECT_FIELDS)}
              title="1. Select member fields"
            />
          )
        }
        {
          step === STEPS.UPDATE_FIELD && (
            <AccordionHeader
              onClick={() => setStep(STEPS.COMPOSE_MESSAGE)}
              title={isFlexEnabled ? '2. Compose your message (optional)' : '2. Compose your message'}
            />
          )
        }
        <div className={styles.card}>
          {
            step == STEPS.SELECT_FIELDS && (
              <>
                <p className={styles.stepTitle}>1. Select member data fields</p>
                <div className={styles.requestFields}>
                  {map(requestedFields, (schema, i) => {
                    // Filter out 'email' and fields that are already selected
                    const filteredFieldsOptions = fieldsOptions.filter((option) => (
                      option.label !== 'Email' && (
                        option.value === schema.schemaId
                        || !some(requestedFields, (s) => s.schemaId === option.value)
                      )
                    ));

                    const selectedIndex = findIndex(filteredFieldsOptions, (o) => o.value === schema.schemaId) || 0;
                    const schemaDef = find(fields, (f) => f.schemaId === schema.schemaId);

                    return (
                      <div className={styles.row} key={`field-${i}-id-${schema.schemaId}`}>
                        <Select
                          className={styles.fieldDropdown}
                          options={filteredFieldsOptions}
                          selectedIndex={selectedIndex}
                          onChange={(schemaId) => handleChangeFormFieldType(i, schemaId)}
                          popoverProps={{
                            minWidth: 240,
                          }}
                          round
                        />
                        <Input
                          placeholder={schemaDef?.name || 'Field title'}
                          defaultValue={schema.label || schemaDef?.name}
                          onChange={(label) => handleChangeFormFieldLabel(schema.schemaId, label)}
                          disabled={selectedIndex === -1}
                        />
                        <Checkbox
                          label="Required"
                          checked={isBoolean(schema.required) ? schema.required : null}
                          className={styles.fieldCheckbox}
                          onChange={(checked) => handleChangeFormFieldRequired(schema.schemaId, checked)}
                          disabled={selectedIndex === -1}
                        />
                        <Button
                          theme="light"
                          label=""
                          className={styles.fieldDelete}
                          icon={<TrashcanIcon size={18} />}
                          onClick={() => handleDeleteFormField(schema.schemaId)}
                        />
                      </div>
                    );
                  })}
                  <Button
                    theme="info"
                    label="Add Field"
                    icon={<RoundAddCircleIcon size={16} />}
                    onClick={handleAddFormField}
                  />
                </div>
              </>
            )
}

          {
            step == STEPS.COMPOSE_MESSAGE && (
              <>
                <p className={styles.stepTitle}>
                  {isFlexEnabled ? '2. Compose your message (optional)' : '2. Compose your message'}
                </p>
                <MessageComposer
                  className={cx(styles.messageForm, {
                    [styles.hide]: !!previewConfig,
                  })}
                  resource={resource}
                  onResourceChange={setResourceId}
                  subject={subject}
                  onSubjectChange={setSubject}
                  editorState={editorState}
                  onEditorStateChange={(state) => {
                    setEditorState(state);
                  }}
                  members={props.members}
                  memberCount={props.membersCount}
                />
                {previewConfig && (
                  <div className={styles.previewContainer}>
                    <EmailPreviewer
                      previewConfig={previewConfig}
                      onBack={() => setPreviewConfig(null)}
                      memberCount={props.membersCount}
                    />
                  </div>
                )}
              </>
            )
          }

          {
            step == STEPS.UPDATE_FIELD && (
              <>
                <p className={styles.stepTitle}>3. Record time when members update their info (optional)</p>
                <p>Use a custom date field so you can filter and identify members who completed this request.</p>
                {/* is there a better way rather than using two RadioGroup? */}
                <RadioGroup
                  classNames={[styles.radioOption]}
                  options={[{ label: 'Create a new date field', value: 'new' }]}
                  selectedIndex={isNewDateField ? 0 : -1}
                  onChange={() => { setIsNewDateField(true); }}
                />
                {
                  isNewDateField && (
                    <Input
                      className={styles.indentField}
                      value={createDateField?.label}
                      onChange={(value) => {
                        setCreateDateField({ ...createDateField, label: value });
                    }}
                    />
                  )
                }
                <RadioGroup
                  classNames={[styles.radioOption]}
                  options={[{ label: 'Select existing date field', value: 'existing' }]}
                  selectedIndex={isNewDateField ? -1 : 0}
                  onChange={() => { setIsNewDateField(false); }}
                />
                {
                  !isNewDateField && (
                    <Select
                      className={styles.indentField}
                      options={dateFieldsOptions}
                      onChange={(schemaId) => {
                        const label = fields.find((f) => f.schemaId === schemaId)?.name;
                        setExistingDateField({ schemaId, label });
                      }}
                    />
                  )
                }
              </>
            )
          }
        </div>
      </div>
      {!isFlexEnabled && (
      <div className={styles.footer}>
        {
          step == STEPS.UPDATE_FIELD ? (
            <>
              <SubmitButton
                key="submitWithoutAssignment"
                className={styles.finishWithoutAssignment}
                onClick={() => {
                  setSkipUpdateField(true);
                  nextStep();
                }}
                theme="info"
                disabled={sendingRequest || (!isNewDateField && !existingDateField)}
                isSubmitting={sendingRequest && skipUpdateField}
                label="Finish without Recording"
                submittingLabel="Sending..."
              />
              <SubmitButton
                key="submit"
                onClick={() => {
                  setSkipUpdateField(false);
                  nextStep(true);
                }}
                theme="primary"
                disabled={sendingRequest || (!isNewDateField && !existingDateField)}
                isSubmitting={sendingRequest && !skipUpdateField}
                label={`Finish: Send ${props.membersCount} Requests`}
                submittingLabel="Sending..."
              />
            </>
          )
          : (
            <Tooltip
              title="Cannot send data update request without the link in email."
              overlayStyle={{
              display: step === STEPS.COMPOSE_MESSAGE && !hasLink ? undefined : 'none',
            }}
            >
              <Button
                label="Next"
                disabled={editorState?.hasError || (step === STEPS.COMPOSE_MESSAGE && !hasLink) || subject === ''}
                onClick={() => nextStep()}
              />
            </Tooltip>
          )
        }
      </div>

      )}
      {isFlexEnabled && (
        <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center gap-3 py-4 px-6 border-t border-gray-200 bg-white">
          {
            step == STEPS.UPDATE_FIELD ? (
              <>
                <ShadcnButton
                  key="submitWithoutAssignment"
                  variant="outline"
                  onClick={() => {
                    setSkipUpdateField(true);
                    nextStep();
                  }}
                  disabled={sendingRequest || (!isNewDateField && !existingDateField)}
                  loading={sendingRequest && skipUpdateField}
                >
                  {sendingRequest && skipUpdateField ? 'Sending...' : 'Finish without Recording'}
                </ShadcnButton>
                <ShadcnButton
                  key="submit"
                  variant="primary"
                  onClick={() => {
                    setSkipUpdateField(false);
                    nextStep(true);
                  }}
                  disabled={sendingRequest || (!isNewDateField && !existingDateField)}
                  loading={sendingRequest && !skipUpdateField}
                  className="gap-2"
                >
                  {sendingRequest && !skipUpdateField ? 'Sending...' : `Finish: Send ${props.membersCount} Requests`}
                </ShadcnButton>
              </>
            ) : (
              <>
                <ShadcnButton
                  variant="outline"
                  onClick={() => {
                    props.closeModal();
                  }}
                >
                  Cancel
                </ShadcnButton>
                {step === STEPS.COMPOSE_MESSAGE && (
                  <ShadcnButton
                    variant="outline"
                    onClick={() => {
                      setSkipMessage(true);
                      nextStep();
                    }}
                  >
                    Continue without Message
                  </ShadcnButton>
                )}
                <ShadcnButton
                  variant="primary"
                  disabled={editorState?.hasError || (step === STEPS.COMPOSE_MESSAGE && !hasLink) || subject === ''}
                  onClick={() => nextStep()}
                  className="gap-2"
                  tooltip={step === STEPS.COMPOSE_MESSAGE && !hasLink ? 'Cannot send data update request without the link in email.' : undefined}
                >
                  Next
                  <ChevronRightIcon className="h-4 w-4" />
                </ShadcnButton>
              </>
            )
          }
        </div>
      )}
    </div>

  );
});

export default NewBulkUpdateRequest;
