import * as React from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  SnapchatIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';
import {
  BlogIcon,
  ImagesIcon,
  StarExclamationIcon,
  VideoIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { isEmpty } from 'lodash';

import { ContentType } from '@frontend/app/types/globalTypes';
import { TDateRequirement } from '../../types/ContentDateRequirements';
import { DueDateTooltip } from '../../utils/DueDateTooltip';

import styles from './SummaryItem.scss';

interface IProps {
  contentType: ContentType;
  dueDates: TDateRequirement[];
}

interface IIconAndLabel {
  icon: React.ReactNode;
  label: React.ReactNode;
}

const getIconAndLabel = (
  contentCount: number,
  contentType: ContentType,
): IIconAndLabel => {
  switch (contentType) {
    case ContentType.ADDITIONAL_IMAGES:
      return {
        icon: <ImagesIcon />,
        label: contentCount === 1 ? 'Image' : 'Images',
      };
    case ContentType.ADDITIONAL_VIDEOS:
      return {
        icon: <VideoIcon />,
        label: contentCount === 1 ? 'Video' : 'Video',
      };
    case ContentType.BLOG_DEDICATED:
      return {
        icon: <BlogIcon />,
        label: contentCount === 1 ? 'Post' : 'Posts',
      };
    case ContentType.BLOG_MENTION:
      return {
        icon: <BlogIcon />,
        label: contentCount === 1 ? 'Mention' : 'Mentions',
      };
    case ContentType.FACEBOOK_POST:
      return {
        icon: <FacebookIcon />,
        label: contentCount === 1 ? 'Post' : 'Posts',
      };
    case ContentType.INSTAGRAM_POST:
    case ContentType.INSTAGRAM:
      return {
        icon: <InstagramIcon />,
        label: contentCount === 1 ? 'Post' : 'Posts',
      };
    case ContentType.INSTAGRAM_REEL:
      return {
        icon: <InstagramIcon />,
        label: contentCount === 1 ? 'Reel' : 'Reels',
      };
    case ContentType.INSTAGRAM_STORY:
      return {
        icon: <InstagramIcon />,
        label: contentCount === 1 ? 'Story' : 'Stories',
      };
    case ContentType.INSTAGRAM_VIDEO:
      return {
        label: contentCount === 1 ? 'Video' : 'Videos',
        icon: <InstagramIcon />,
      };
    case ContentType.OTHER:
      return {
        icon: <StarExclamationIcon />,
        label: contentCount === 1 ? 'Item' : 'Items',
      };
    case ContentType.PINTEREST_PIN:
    case ContentType.PINTEREST:
      return {
        icon: <PinterestIcon />,
        label: contentCount === 1 ? 'Pin' : 'Pins',
      };
    case ContentType.SNAPCHAT_STORY:
      return {
        icon: <SnapchatIcon />,
        label: contentCount === 1 ? 'Story' : 'Stories',
      };
    case ContentType.TIKTOK_VIDEO:
      return {
        icon: <TiktokIcon />,
        label: contentCount === 1 ? 'Video' : 'Videos',
      };
    case ContentType.TWITTER_POST:
      return {
        icon: <TwitterIcon />,
        label: contentCount === 1 ? 'Tweet' : 'Tweets',
      };
    case ContentType.YOUTUBE_DEDICATED:
      return {
        icon: <YoutubeIcon />,
        label: contentCount === 1 ? 'Video' : 'Videos',
      };
    case ContentType.YOUTUBE_MENTION:
      return {
        icon: <YoutubeIcon />,
        label: contentCount === 1 ? 'Mention' : 'Mentions',
      };
    case ContentType.YOUTUBE_SHORT:
      return {
        icon: <YoutubeIcon />,
        label: contentCount === 1 ? 'Short' : 'Shorts',
      };
    default:
      throw new Error(`Unhandled content type: "${contentType}"`);
  }
};

export const SummaryContentItem: React.FC<IProps> = React.memo((props) => {
  const {
    contentType,
    dueDates,
  } = props;

  const {
    icon,
    label,
  } = React.useMemo(
    () => getIconAndLabel(dueDates.length, contentType),
    [dueDates, contentType],
  );

  if (isEmpty(dueDates)) {
    return null;
  }

  return (
    <DueDateTooltip dueDates={dueDates} placement="bottom">
      <div className={styles.SummaryItem}>
        {icon}
        <div>
          {dueDates.length.toLocaleString()}
          {' '}
          {label}
        </div>
      </div>
    </DueDateTooltip>
  );
});
SummaryContentItem.displayName = 'SummaryContentItem';
