import { CharCounter } from '@affiliates/components/OfferForm/CharCounter';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { Input } from '@frontend/shadcn/components/ui/input';
import { OfferFormTestIds as testIds } from '@frontend/applications/AffiliatesApp/components/OfferForm/OfferFormTestIds';
import * as React from 'react';
import { Form } from 'antd';
import { useCharCounter } from '@affiliates/hooks';
import { ICommonFormElementProps } from '../../../../types';
import { OfferInputHeader } from './OfferElementHeader';

interface IProps extends ICommonFormElementProps<'name'> {
  charCount: number;
  source: OFFER_SOURCE;
}

export const RefreshOfferName: React.FC<Readonly<IProps>> = React.memo(({
  charCount: initialCharCount,
  disabled,
  name,
  source,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer name!' },
    { max: 60, message: 'Your offer name is longer than 60 characters' },
    {
      validator: (_, value) => {
        if (source === OFFER_SOURCE.TUNE && value && (value.includes('%') || value.includes('&'))) {
          return Promise.reject(new Error('Offer name cannot contain "%" or "&"'));
        }
        return Promise.resolve();
      },
    },
  ];

  const { charCount, handleChange } = useCharCounter(initialCharCount);

  return (
    <>
      <OfferInputHeader text="Offer Name" />
      <Form.Item name={name} rules={rules} className="!m-0">
        <Input
          data-testid={testIds.offerName}
          disabled={disabled}
          onChange={handleChange}
          placeholder="Name your offer"
          className="bg-grey-0 text-grey-6"
          autoFocus
        />
      </Form.Item>
      {charCount >= 60 && (<CharCounter
        charCount={charCount}
        maxCharCount={60}
      />)}
    </>
  );
});
