import React from 'react';
import { Button, ITableProps } from '@revfluence/fresh';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import { logger } from '@common';
import styles from './styles.scss';
import { ShopifyMetaFieldValueType } from './constants';
import { ShopifyRichText } from './components/ShopifyRichText/ShopifyRichText';

export const getPaginationProps = <T extends {}>({
  total,
  pageSize,
  current,
  className = styles.PfaV2Pagination,
  onNextPage = () => {},
  onPreviousPage = () => {},
  disabled = false,
}: {
  total: number;
  pageSize: number;
  current?: number;
  className?: string;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  disabled?: boolean;
}): ITableProps<T>['pagination'] => ({
  total,
  pageSize,
  showSizeChanger: false,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  itemRender: (_current, type, originalElement) => {
    if (type === 'prev') {
      return <Button icon={<ChevronLeftIcon />} type="text" disabled={disabled} />;
    }
    if (type === 'next') {
      return <Button icon={<ChevronRightIcon />} type="text" disabled={disabled} />;
    }
    return originalElement;
  },
  showLessItems: true,
  position: ['topRight'],
  className,
  current,
  onChange: (page) => {
    if (page > current) {
      onNextPage?.();
    } else {
      onPreviousPage?.();
    }
  },
  disabled,
});

export const getCleanedTag = (tag: string) => tag.replace('ASPIRE_', '');

export const getCleanedTags = (tags: string[]) => tags.map((tag) => tag.replace('ASPIRE_', ''));

export const getAspireTags = (tags: string[]) => getCleanedTags(tags.filter((tag) => tag.startsWith('ASPIRE_')));

export const getNonAspireTags = (tags: string[]) => tags.filter((tag) => !tag.startsWith('ASPIRE_'));

export const toAspireTags = (tags: string[]) => tags.map((tag) => `ASPIRE_${tag}`);

export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
};

export const getShopifyMetaFieldFormattedValue = (type: ShopifyMetaFieldValueType, value: string) => {
  try {
    switch (type) {
      case ShopifyMetaFieldValueType.LINK:
        const linkJson = JSON.parse(value);
        return <a href={linkJson.url} target="_blank">{linkJson.text}</a>;
      case ShopifyMetaFieldValueType.RICH_TEXT_FIELD:
        const richTextJson = JSON.parse(value);
        return (
          <ShopifyRichText
            {...richTextJson}
            options={[
              { type: 'bold', className: 'font-bold' },
              { type: 'italic', className: 'italic' },
            ]}
          />
        );
      default:
        return value;
    }
  } catch (e) {
    logger.error('Error formatting shopify metafield value', e);
    return value;
  }
};

export const currencyFormatter = (currency: string) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: currency || 'USD',
});

export const getCurrencySymbol = (currency: string) => {
  return (0).toLocaleString(
    'en-US',
    { style: 'currency', currency: currency || 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
  ).replace(/\d/g, '').trim();
};
