import 'css-chunk:src/app/containers/MemberDetail/components/MemberThreads/Thread.scss';export default {
  "Thread": "_Thread_1jiie_1",
  "automationIcon": "_automationIcon_1jiie_7",
  "content": "_content_1jiie_14",
  "mainMessage": "_mainMessage_1jiie_24",
  "subject": "_subject_1jiie_31",
  "snippet": "_snippet_1jiie_35",
  "reply": "_reply_1jiie_42",
  "replyContent": "_replyContent_1jiie_49",
  "replySnippet": "_replySnippet_1jiie_58",
  "replyDate": "_replyDate_1jiie_59",
  "messageHTMLContent": "_messageHTMLContent_1jiie_64",
  "composerContainer": "_composerContainer_1jiie_69",
  "composer": "_composer_1jiie_69",
  "emailComposer": "_emailComposer_1jiie_80",
  "hide": "_hide_1jiie_83",
  "replyButton": "_replyButton_1jiie_86",
  "mainDate": "_mainDate_1jiie_89",
  "callToAction": "_callToAction_1jiie_93"
};